import React from 'react';
import DropdownItems from '../DropdownItems';
import './styles.css';

const DropdownMenu = (props) => {
  return (
    <div className="dropdownContainer">
      <ul className="dropdown">
        <div className="dropdownRow">
          {props.dropdown &&
            props.dropdown.map((menuItem) => (
              <DropdownItems key={menuItem.id} item={menuItem} onClick={props.onClick} />
            ))}
        </div>
      </ul>
    </div>
  );
};

export default DropdownMenu;
