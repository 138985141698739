const getDynamicForm = (req) => {
  let result = {}
  result[req.formKey] = {
    skipAddInitial: req.skipAddInitial,
    skipAndGo: req.skipAndGo,
    displayName: req.product,
    title: req.product,
    titleImage: req.titleImage,
    formName: req.formName,
  }
  return result
};

export default getDynamicForm;