import { React, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, Banner, Button, Collapse } from '../../Atoms';
import Loader from '../../Atoms/Loader';
import classes from './styles.module.css';
import { cloneDeep } from 'lodash';
import Elementor from '../../Cells/Form/Elementor';
import useFormProgress from '../../Cells/Form/FormGenerator/UseFormProgress';
import ServiceHelper from '../../Utils/Helper/MappingHelper';
import appConfig from '../../../appConfig';
import http from '../../../Common/Services/HttpService'

import { getCoverageBeneficiaryEligibility, getErrorMessages } from '../../Molecules/CustomSection/Helper/MappingHelper';

import { SET_EDIT_FROM_ES, CURRENT_STEP } from '../../../Store/Slices/StepForm';

import { onLoadAPI } from '../../../Store/Slices/Entities/response/index.js';
import { UPDATE_ENROLLMENT_DATA, UPDATE_ENROLLMENT_TYPE, IS_BACK_TO_SUMMARY, UPDATE_ENDORSMENT_DATA, BENIFICIARY_PRODUCT_UPDATE } from '../../../Store/Slices/Enrollment/index';
import { PRODUCT_LIST_IDENTIFIER, PRODUCT_RESOURCES_COVERAGE_TITLE } from '../../../Store/Slices/Process';
import { UPDATE_BENEFICIARIES_PRODUCT, SET_SAVE_BENEFICIARIES, UPDATE_LOADING, SET_ALERT_MESSAGE, SET_COVERAGE_BUIDS, SET_COVERAGE_BENEFICIARY_ELIGIBILITY, REMOVE_CURRENTSTEP_ALERT_MESSAGE } from '../../../Store/Slices/InsuredInfo';
import BeneficiaryModel from '../CustomSection/Component/Beneficiary/BeneficiaryModel';
import { MessageComponent } from '../../Atoms';
import { useNavigationHook } from '../../Molecules/CustomSection/Hooks/NavigationHook';
import eyeIconImage from '../../../Assets/Images/icon-eye.png'; // Directly import the image
import loginImage from '../../../Assets/Images/login_image.png';
import { trimPayload } from '../CustomSection/Helper/commonHelper.js';

import {
  beneficiaryDataFormatter,
  beneficiaryEditFormatted,
  beneficiaryEditOptionsData,
  beneficiaryEditOptionsLegal,
  beneficiaryOptions,
  productNameMappings,
  beneficiaryInfoContent,
} from './utils';

const BeneficiaryActions = (props) => {
  const {
    index,
    isEdit,
    onClickEdit = () => { },
    onClickCancel = () => { },
    onClickSave = () => { },
    isDisabled = false,
    showActions = true,
    disabledSave = false,
    history,
  } = props;
  const [btnLabel, setBtnLabel] = useState('Back to Home')
  const { handleGoToSpecificStep } = useNavigationHook();
  const dispatch = useDispatch();
  const previousStep = useSelector((storeState) => storeState.stepForm.previousStep);
  const {
    currentStep
  } = useSelector(({ stepForm }) => stepForm);
  const handleParentBack = () => {
    if (previousStep === 'EnrollmentSummary') {
      history.push("/enrollment/employeeProfile");
      dispatch(IS_BACK_TO_SUMMARY(true))
      handleGoToSpecificStep(previousStep);
    } else {
      history.push('/enrollment/home')
    }
  };
  useEffect(() => {
    if (previousStep === "EnrollmentSummary") {
      setBtnLabel('Continue to Summary')
    } else {
      setBtnLabel('Back to Home')
    }

  }, [previousStep])

  const hasErrors = getErrorMessages(currentStep);

  return (
    <div>
      {!isEdit && showActions && (
        <div className={`d-flex justify-content-end form-row py-3 px-2`}>
          {index === "1" && (
            <Button
              onClick={() => handleParentBack()}
              outline="outline"
              className={'btn d-flex py-2 btn-outline-primary primaryOutline mr-4'}
            >
              {btnLabel}

            </Button>
          )}
          <Button
            id={`beneficiaryEdit-${index}`}
            key={`beneficiaryEdit-${index}`}
            name={`beneficiaryEdit-${index}`}
            className={'btn btn-primary primaryBackground d-flex'}
            onClick={onClickEdit}
            content="Edit Beneficiaries"
            disabled={isDisabled || hasErrors}
          />
        </div>
      )}
      {isEdit && (
        <div className={`d-flex justify-content-end form-row py-3`}>
          <div className={`d-flex justify-content-end col-1 py-2`}>
            <Button
              id={`beneficiaryCancel-${index}`}
              key={`beneficiaryCancel-${index}`}
              name={`beneficiaryCancel-${index}`}
              className={'w-100'}
              content="Cancel"
              onClick={onClickCancel}
            />
          </div>
          <div className={`col-1 py-2`}>
            <Button
              id={`beneficiarySave-${index}`}
              key={`beneficiarySave-${index}`}
              name={`beneficiarySave-${index}`}
              className={'w-100'}
              content="Save"
              onClick={() => {
                onClickSave(false, [])
              }}
              disabled={disabledSave}
            />
          </div>

        </div>
      )}
    </div>
  );
};

const ManageBeneficiariesTable = (props) => {
  const {
    layoutData,
    hideActions = false,
    showButton = false,
    buttonOptions = {},
    isSummaryPage
  } = props;

  let history = useHistory();
  const serviceHelper = new ServiceHelper();
  const [editBeneficiary, setEditBeneficiary] = useState([]);
  const [editInitialData, setEditInitialData] = useState({});
  const [editCoverageBuid, setEditCoverageBuid] = useState(null);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isEditOptions, setIsEditOptions] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [tempValues, setTempValues] = useState([]);
  const [percentageValidation, setPercentageValidation] = useState({});
  const [beneficiaryErrors, setBeneficiaryErrors] = useState(false);
  const [resetChanges, setResetChanges] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [modalKey, setModalKey] = useState(null);
  const [show, setShow] = useState(false);
  const [productToAdd, setProductToAdd] = useState({});
  const [headerText, setHeaderText] = useState("Add a Beneficiary");
  const [productSelected, setProductSelected] = useState('');
  const [showMaskedSSN, setShowMaskedSSN] = useState([]);
  const goBackStep = useFormProgress()[3];
  let backgroundColor = true;

  let mobileTable = false;
  if (window.innerWidth < 820) {
    mobileTable = true;
  }

  const dispatch = useDispatch();
  const {
    stepKeys = [],
    currentStep,
    previousStep,
  } = useSelector(({ stepForm }) => stepForm);
  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  const employeeProfile = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile);

  useEffect(() => {
    goBackStep(stepKeys.indexOf(previousStep));
  }, [previousStep, currentStep]);

  const {
    response: {
      onLoadResponseList: { PersonalInformation: { personalInformation = '' } = {} } = {},
    } = {},
  } = useSelector(({ entities }) => entities);
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);
  const process = useSelector((storeState) => storeState?.process);
  const beneficiaryKinds = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.[
      `GetSubscribedCoverageBeneficiaryConfigurationFor${productSelected}`
      ]?.['coverageInformation']?.[0]
  );

  const beneficiaryRules = useSelector((storeState) => storeState?.insuredInfo);
  dispatch(SET_EDIT_FROM_ES(false));
  const [productData, setProductData] = useState({});

  useEffect(() => {
    if (tempValues && tempValues.length > 0) {
      const productsData = {};
      tempValues.forEach(product => {
        const primaryHeaders = [];
        const primaryValues = [];
        const contingentHeaders = [];
        const contingentValues = [];
        Object.keys(product.beneficiaries).forEach(beneficiaries => {
          if (product.beneficiaries[beneficiaries].length > 0) {
            product.beneficiaries[beneficiaries].forEach(beneficiary => {
              const dataFormatterObj = {
                layoutData,
                beneficiary,
                type: beneficiaries,
                product,
                actionsFormatted,
                isEdit,
                beneficiaryRules,
              };
              // const generalData = beneficiaryDataFormatter(layoutData, beneficiary, beneficiaries, product, actionsFormatted, isEdit, beneficiaryRules);
              const generalData = beneficiaryDataFormatter(dataFormatterObj);
              if (beneficiaries === 'Primary') {
                primaryHeaders.push(generalData.beneficiaryHeadersObj);
                primaryValues.push(generalData.beneficiaryContingentObj);
              } else if (beneficiaries === 'Contingent') {
                contingentHeaders.push(generalData.beneficiaryHeadersObj);
                contingentValues.push(generalData.beneficiaryContingentObj);
              }
            });
          }
        });

        productsData[product.coverageBuid] = {
          coverageBuid: (product && product.coverageBuid) || '',
          productName: (product && product.productName) || '',
          productNameDescription: (product && product.productNameDescription) || '',
          productTitle: (product && product.productTitle) || '',
          productBuid: (product && product.productBuid) || '',
          productId: (product && product.productId) || '',
          personBuid: product?.personBuid || '',
          primaryHeaders,
          primaryValues,
          contingentHeaders,
          contingentValues,
          data: product?.data || [],
        }
      });
      setProductData(productsData);
    }
  }, [tempValues, isEdit, showMaskedSSN])

  useEffect(() => {

    if (layoutData?.beneficiariesProducts?.length > 0) {
      setTempValues(layoutData?.beneficiariesProducts);
      dispatch(BENIFICIARY_PRODUCT_UPDATE(layoutData?.beneficiariesProducts))
    }
  }, [layoutData && layoutData?.beneficiariesProducts])

  // Method to reset the changes clicking cancel
  useEffect(() => {
    if (resetChanges) {
      if (layoutData?.beneficiariesProducts.length > 0) {
        setTempValues(layoutData.beneficiariesProducts);
        setResetChanges(false);
      }
    }
  }, [resetChanges]);

  // Code to validate percentages in beneficiary and primary required
  useEffect(() => {
    if (tempValues.length > 0) {
      const newPercentage = {};
      let newErrors = false;
      tempValues.forEach(temp => {
        const beneficiaryErrors = {};
        let totalType = 0;
        let totalPrimaryType = 0;
        let totalContingentType = 0;
        let primaryError = false;
        let contingentError = false;
        let hasPrimary = true;
        let primaryPercentageError = false;
        let contingentPercentageError = false;
        temp.beneficiaries && Object.keys(temp.beneficiaries).forEach(beneficiaryType => {
          const hasBeneficiary = temp.beneficiaries[beneficiaryType] && temp.beneficiaries[beneficiaryType].length > 0;
          temp.beneficiaries[beneficiaryType].forEach(item => {
            const isDependent = item?.isDependent || false;
            const getPercentage = isDependent ? item?.beneficiaryPercentage : item?.beneficiaryInformation?.percentage;
            const percentage = getPercentage ? parseInt(getPercentage) : 0;
            if (beneficiaryType === 'Primary') {
              if (percentage === 0) {
                primaryPercentageError = true;
              }
              totalPrimaryType = totalPrimaryType + percentage;
            } else if (beneficiaryType === 'Contingent') {
              if (percentage === 0) {
                contingentPercentageError = true;
              }
              totalContingentType = totalContingentType + percentage;
            }
          });
          // if ( beneficiaryType === 'Primary' && temp.beneficiaries[beneficiaryType].length === 0) {
          // }
          if (hasBeneficiary) {
            if (((totalPrimaryType > 100 || totalPrimaryType < 100) && beneficiaryType === 'Primary') || primaryPercentageError) {
              primaryError = true;
              newErrors = true;
            } else if (((totalContingentType > 100 || totalContingentType < 100) && beneficiaryType === 'Contingent') || contingentPercentageError) {
              contingentError = true;
              newErrors = true;
            }
          }
        });
        beneficiaryErrors.primaryError = primaryError;
        beneficiaryErrors.contingentError = contingentError;
        newPercentage[temp.coverageBuid] = beneficiaryErrors;
      });
      setPercentageValidation(newPercentage);
      setBeneficiaryErrors(newErrors);
    }
  }, [tempValues]);

  let elementsTemp = [
    {
      type: 'readonly',
      disabled: true,

      name: 'firstName',
      value: 'abc',

      label: 'First Name',
      mapping: true,
      labelTitle: 'First Name',

      placement: 'col-md-2 col-sm-6 col-xs-6 col-6 pb-3',
      header: false,
      validations: {
        string: {},
      },
      containerClassName: 'col-12',
      contentClassName: 'col-12',
      styleClass: '',
    },
    {
      type: 'readonly',
      name: 'middleName',
      label: 'Middle Name',
      labelTitle: 'Middle Name',
      disabled: true,
      mapping: true,
      header: false,

      placement: 'col-md-2 col-sm-6 col-xs-6 col-6 pb-3',

      containerClassName: 'col-12',
      contentClassName: 'col-12',
      styleClass: '',
    },
  ];
  const processBuidKey = previousStep === 'EnrollmentSummary' ? 'initEndorsementProcessData' : 'beneficiaryinitEndorsementProcessData';
  const endorsementProcessBuid = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.[processBuidKey]?._Result?.processEntity?.buid
  );
  const enrollmentProcessBUID = useSelector(
    (storeState) => storeState?.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID
  );
  const enrollmentStep = useSelector((storeState) => storeState?.enrollment?.enrollmentStep);
  const beneficiaryEligibleProduct = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.EndorsementListOfEligibleProducts?._Result
  );

  // EnrollmentSummary
  // let currentReduxState = useSelector((storeState) => storeState);
  let enrollmentProcessBuid = endorsementProcessBuid;
  let loggedUserEntity = useSelector((storeState) => storeState?.auth?.loggedUserEntity);
  let subscribeElement = useSelector((storeState) => storeState?.insuredInfo?.manageBeneficiarySubscribeElment);
  let allSelectProduct = useSelector((storeState) => storeState?.insuredInfo?.productsToSave);
  let insuredInfo = useSelector((storeState) => storeState?.insuredInfo);
  const saveBeneficiaries = insuredInfo && insuredInfo.saveBeneficiaries || false;

  const callAPI = async () => {
    dispatch(UPDATE_LOADING(true));
    dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
    return await http
      .post(appConfig.SSP_MID_URL + `/v1/manageBeneficiaries`, {
        endorsementProcessBuid,
        enrollmentStep,
      })
      .then(async (resp) => {
        const data = resp?.data?.data;
        for (const key in data) {
          let result = { _Result: data[key].data }
          let val = {}
          switch (key) {
            case 'moveNext':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'moveNext',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'executeUntilLastStep':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'executeUntilLastStep',
                  show: true,
                  page: currentStep,
                }));
              } else {
                const mergedEnrollmentData = { ...enrollmentData, ...data[key].data };
                dispatch(UPDATE_ENROLLMENT_DATA(mergedEnrollmentData));
              }
              break;
            case 'getListOfEligibleProducts':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getListOfEligibleProducts',
                  show: true,
                  page: currentStep,
                }));
              } else {
                dispatch(onLoadAPI({ ...{ [`EndorsementListOfEligibleProducts`]: result, isAPICallInProgress: false } }));
              }
              break;
            case 'searchPolicyProcessResourcesCoverage':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'searchPolicyProcessResourcesCoverage',
                  show: true,
                  page: currentStep,
                }));
              } else {
                dispatch(PRODUCT_RESOURCES_COVERAGE_TITLE({ productResourcesCoverageTitle: data[key].data }));
                dispatch(onLoadAPI({ productResourcesCoverageTitle: data[key].data }));
              }
              break;
            case 'getSubscribedCoverageBeneficiaryConfiguration':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getSubscribedCoverageBeneficiaryConfiguration',
                  show: true,
                  page: currentStep,
                }));
              } else {
                data[key].data.forEach(CoverageDetails => {
                  const bUIDs = [];
                  bUIDs.push({ "productBuid": CoverageDetails?.productId, "coverageBuid": CoverageDetails?.coverageBuid });
                  const newProductFamily = CoverageDetails?.productFamily || '';
                  if (bUIDs?.length > 0) {
                    dispatch(SET_COVERAGE_BUIDS({ bUIDs, product: newProductFamily }));
                  }
                  dispatch(onLoadAPI({
                    [`GetSubscribedCoverageBeneficiaryConfigurationFor${newProductFamily}`]: CoverageDetails,
                    isAPICallInProgress: false,
                  }));

                  const newCoverageBeneficiaryEligiblity = getCoverageBeneficiaryEligibility(CoverageDetails.coverageInformation, CoverageDetails.coverageBuid);
                  dispatch(SET_COVERAGE_BENEFICIARY_ELIGIBILITY({ eligibility: newCoverageBeneficiaryEligiblity, product: newProductFamily, productPage: true }));
                });
              }
              break;
            case 'beneficiariesData':
              if (data[key]?.success) {
                const beneficiariesData = data[key].data;
                dispatch(onLoadAPI({ 'beneficiariesData': beneficiariesData }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'beneficiariesData',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            default:
              dispatch(onLoadAPI({ [key]: result }));
          }
        }
        dispatch(UPDATE_LOADING(false));
        return true;
      })
      .catch((e) => {

        dispatch(UPDATE_LOADING(false));
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: e?.status || '520',
          id: 'manage_Beneficiaries_Call',
          show: true,
          page: currentStep
        }));
        return false;
      });
  };

  // New UseEffect to call all the different Apis at the beggining
  useEffect(() => {
    const fetchData = async () => {
      if (endorsementProcessBuid && currentStep !== 'EnrollmentSummary' && previousStep !== 'EnrollmentSummary') {
        await callAPI();
      }
    };

    fetchData();
  }, [endorsementProcessBuid]);


  // Code to manage the Update/Save of Manage Beneficiaries
  useEffect(() => {
    const fetchData = async () => {
      const callApi = true;
      if (callApi) {
        if (saveBeneficiaries && allSelectProduct?.length > 0) {
          dispatch(UPDATE_LOADING(true));
          try {
            const resp = await http.post(appConfig.SSP_MID_URL + `/v1/updateManageBeneficiaries`, {
              insuredInfo,
              allSelectProduct,
              enrollmentProcessBuid: endorsementProcessBuid ? endorsementProcessBuid : enrollmentProcessBUID,
              shouldFinalEnrollment: previousStep !== 'EnrollmentSummary',
              subscribeElement,
              loggedUserEntity,
            });
            const data = resp?.data?.data;
            dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
            for (const key in data) {
              let result = { _Result: data[key].data }
              let val = {}
              switch (key) {
                case 'updateSubscribedCoverageInformation':
                  if (data[key]?.success) {
                    if (data[key].data?.length > 0) {
                      dispatch(UPDATE_ENROLLMENT_DATA(data[key].data.slice(-1)[0]));
                      dispatch(UPDATE_ENDORSMENT_DATA(data[key].data.slice(-1)[0]));
                    } else {
                      dispatch(UPDATE_ENROLLMENT_DATA(data[key].data[0]));
                      dispatch(UPDATE_ENDORSMENT_DATA(data[key].data[0]));
                    }
                    dispatch(SET_SAVE_BENEFICIARIES(false));
                  } else {
                    dispatch(SET_ALERT_MESSAGE({
                      type: 'error',
                      errorCode: data[key]?.data?.error?.code || '520',
                      continue: true,
                      id: 'updateSubscribedCoverageInformation',
                      show: true,
                      page: currentStep,
                    }));
                  }
                  break;
                case 'finalEnrollment':
                  if (data[key]?.success) {
                    dispatch(UPDATE_ENROLLMENT_DATA(data[key].data));
                  } else {
                    dispatch(SET_ALERT_MESSAGE({
                      type: 'error',
                      errorCode: data[key]?.data?.error?.code || '520',
                      continue: true,
                      id: 'finalEnrollment',
                      show: true,
                      page: currentStep,
                    }));
                  }
                  break;
                case 'beneficiariesData':
                  if (data[key]?.success) {
                    const beneficiariesData = data[key].data;
                    dispatch(onLoadAPI({ 'beneficiariesData': beneficiariesData }));
                  } else {
                    dispatch(SET_ALERT_MESSAGE({
                      type: 'error',
                      errorCode: data[key]?.data?.error?.code || '520',
                      continue: true,
                      id: 'beneficiariesData',
                      show: true,
                      page: currentStep,
                    }));
                  }
                  break;
                default:
                  dispatch(onLoadAPI({ [key]: result }));
              }
            }
            if (previousStep === 'EnrollmentSummary') {
              dispatch(CURRENT_STEP({ currentStep: "EnrollmentSummary" }));
            }
          } catch (e) {
            // Handle the error here if necessary
            dispatch(SET_ALERT_MESSAGE({
              type: 'error',
              errorCode: '520',
              continue: true,
              id: 'updateManageBeneficiariesApi',
              show: true,
              page: currentStep,
            }));
            console.error(e);
          } finally {
            dispatch(UPDATE_LOADING(false));
          }
        }
      }
    };

    fetchData();
  }, [saveBeneficiaries]);

  const onSaveAction = async () => {
    dispatch(UPDATE_LOADING(true));
    const newValues = tempValues;
    dispatch(UPDATE_BENEFICIARIES_PRODUCT({ insuredInfo: newValues }));
    dispatch(UPDATE_LOADING(false));
    setIsEdit(false);
  };

  const onMoveAction = (values = []) => {
    setTempValues(values);
  };

  const onDeleteAction = (values = []) => {
    setTempValues(values);
  }

  const onEditAction = (data, idSelected, productInformation) => {
    const selectedBeneficiary = tempValues.find(temp => temp?.coverageBuid === data?.coverageBuid);
    const editBeneficiary = selectedBeneficiary?.data?.find(selected => selected?.bUID === idSelected);
    const beneficiaryFormatted = beneficiaryEditFormatted(editBeneficiary, validateLiveAtSameAddress);
    setEditCoverageBuid(data?.coverageBuid);
    setIsEditOptions(beneficiaryFormatted?.beneficiaryOptions);
    setProductSelected(productInformation && productInformation.productName);
    setEditBeneficiary(editBeneficiary);
    setEditInitialData(beneficiaryFormatted);
    setIsEditModal(true);
    setShow(true);
    setModalKey(Date.now()); // Update the key with the current timestamp
    updateHeaderText("edit")
  };

  const actionsFormatted = {
    'onEditAction': onEditAction,
    'onDeleteAction': onDeleteAction,
    'onMoveAction': onMoveAction,
  };

  const checkBeneficiaryEligibleProduct = beneficiaryEligibleProduct?.map((product) => {
    const productBuid = product?.memberOfferedProduct?.bUID
    const productFamily = product?.memberOfferedProduct?.product?.productFamily
    return { productBuid: productBuid, productFamily: productFamily }
  })
  const beneficiaryProductName = checkBeneficiaryEligibleProduct?.map(el => {
    return { productSelection: { productFamily: el?.productFamily, productBUID: el?.productBuid } }
  })

  useEffect(() => {
    if (beneficiaryProductName?.length > 0) {
      dispatch(PRODUCT_LIST_IDENTIFIER(beneficiaryProductName));
    }
  }, [beneficiaryEligibleProduct])

  const updateHeaderText = (action) => {
    if (action === "add") {
      setHeaderText("Add a Beneficiary");
    } else if (action === "edit") {
      setHeaderText("Edit Beneficiary");
    }
  };

  const validateLiveAtSameAddress = (data, key) => {
    let liveAtSameaddress = false;
    if (data[key]) {
      if (data[key]?.address) {
        if (
          employeeProfile?.line1 === data[key]?.address?.line1 &&
          employeeProfile?.line2 === data[key]?.address?.line2 &&
          employeeProfile?.state === data[key]?.address?.state &&
          employeeProfile?.country === data[key]?.address?.country &&
          employeeProfile?.city === data[key]?.address?.zipCodeAndCity?.city &&
          employeeProfile?.zipCode === data[key]?.address?.zipCodeAndCity?.zipCode
        ) {
          liveAtSameaddress = true;
        }
      }
    }
    return liveAtSameaddress;
  };

  const handleSubmit = (beneficiary) => {
    if (isEditModal) {
      handleUpdateEdit(trimPayload(beneficiary), editBeneficiary);
    } else {
      handleUpdate(trimPayload(beneficiary));
    }
    handleClose();
  };


  const handleUpdateEdit = (data, beneficiary) => {
    const newBeneficiary = cloneDeep(beneficiary);
    const liveAtSameaddress = false;
    newBeneficiary.edit = true;
    newBeneficiary.beneficiaryInformation.sSN = data?.SSN?.replace(/-/g, '',) || '';
    newBeneficiary.beneficiaryInformation.birthDate = data?.beneficiaryBirthDate;
    newBeneficiary.beneficiaryInformation.firstName = data?.beneficiaryFirstName;
    newBeneficiary.beneficiaryInformation.lastName = data?.beneficiaryLastName;
    newBeneficiary.beneficiaryInformation.title = data?.beneficiaryGender === 'gMale' ? 'ptMr' : data?.beneficiaryGender === 'gFemale' ? 'ptMrs' : '';
    newBeneficiary.beneficiaryInformation.relationToInsured = data?.beneficiaryRelationship;
    newBeneficiary.beneficiaryInformation.name = data?.legalName || '';
    newBeneficiary.data = data;
    // Validation to update address
    if (data?.mailingAddressOptions === 'no') {
      newBeneficiary.beneficiaryInformation.address = null;
    } else {
      if (newBeneficiary?.beneficiaryInformation?.address) {
        newBeneficiary.beneficiaryInformation.address.line1 = data?.beneficiaryAddressLine1;
        newBeneficiary.beneficiaryInformation.address.line2 = data?.beneficiaryAddressLine2;
        newBeneficiary.beneficiaryInformation.address.state = data?.beneficiaryState;
        newBeneficiary.beneficiaryInformation.address.country = data?.beneficiaryCountry;
        newBeneficiary.beneficiaryInformation.address.zipCodeAndCity.city = data?.beneficiaryCity;
        newBeneficiary.beneficiaryInformation.address.zipCodeAndCity.zipCode = data?.beneficiaryZipCode;
      } else {
        newBeneficiary.beneficiaryInformation.address = {
          "county": "",
          "fIPSCode": 0,
          "state": data?.beneficiaryState || '',
          "line1": data?.beneficiaryAddressLine1 || '',
          "line2": data?.beneficiaryAddressLine2 || '',
          "zipCodeAndCity": {
            "zipCode": data?.beneficiaryZipCode || '',
            "city": data?.beneficiaryCity || '',
          },
          "country": data?.beneficiaryCountry || '',
          "district": "",
          "buid": "",
          "className": "aSPCB_US_PostalAddress"
        };
      }
    }

    const newTempValues = cloneDeep(tempValues);
    const arrayValues = [];
    newTempValues.forEach(temp => {
      if (temp && temp.coverageBuid === editCoverageBuid) {
        const newTemp = cloneDeep(temp);
        let arrayToUpdate = '';
        if (newBeneficiary?.beneficiaryInformation?.beneficiaryRank === 'cPrimary' || newBeneficiary?.beneficiaryInformation?.beneficiaryRank === 'Primary') {
          arrayToUpdate = 'Primary';
        } else {
          arrayToUpdate = 'Contingent'
        }
        const beneficiaryNewArrayType = temp?.beneficiaries[arrayToUpdate] || [];
        const newTypeArray = [];
        beneficiaryNewArrayType.forEach(ben => {
          if (ben?.bUID === newBeneficiary?.bUID) {
            newTypeArray.push(newBeneficiary);
          } else {
            newTypeArray.push(ben);
          }
        });
        newTemp.beneficiaries[arrayToUpdate] = newTypeArray;
        const newBeneficiariesData = [...newTemp['beneficiaries']['Primary'], ...newTemp['beneficiaries']['Contingent']];
        newTemp['data'] = newBeneficiariesData;
        arrayValues.push(newTemp);
      } else {
        arrayValues.push(temp);
      }
    });
    setTempValues(arrayValues);
  };

  const handleClose = () => {
    setShow(false);
    setEditIndex(null);
    setProductToAdd({});
    setProductSelected('');
    // Variables to Edit Beneficiaries
    setEditBeneficiary([]);
    setEditInitialData({});
    setIsEditModal(false);
    updateHeaderText("add")
    setEditCoverageBuid(null);
  };

  const handleShow = (productInformation) => {
    setProductToAdd(productInformation);
    setProductSelected(productInformation && productInformation.productName);
    setShow(true);
    setModalKey(Date.now()); // Update the key with the current timestamp
    updateHeaderText("add")
  };

  const handleSubmitGlobal = (updatedBeneficiaries = []) => {
    const coverageBuid = (productToAdd && productToAdd.coverageBuid) || '';
    let personBuid = '';
    if (productToAdd && productToAdd.contingentValues && productToAdd.contingentValues.length > 0) {
      personBuid = productToAdd.contingentValues && productToAdd.contingentValues[0] && productToAdd.contingentValues[0].personBuid;
    } else if (productToAdd && productToAdd.primaryValues && productToAdd.primaryValues.length > 0) {
      personBuid = productToAdd.primaryValues && productToAdd.primaryValues[0] && productToAdd.primaryValues[0].personBuid;
    }
    const beneficiariesToUpdate = [];
    updatedBeneficiaries.forEach(beneficiary => {
      const birthDate = beneficiary?.beneficiaryInformation?.birthDate || beneficiary?.data?.person?.data?.actor?.birthDate || '';
      const data = {
        SSN: beneficiary?.beneficiaryInformation?.sSN || beneficiary?.data?.person?.data?.actor?.sSN || '',
        beneficiaryAddressLine1: beneficiary?.data?.person?.data?.correspondences?.[0]?.addess?.line1 || '',
        beneficiaryAddressLine2: beneficiary?.data?.person?.data?.correspondences?.[0]?.addess?.line2 || '',
        beneficiaryBirthDate: moment(birthDate).format('MM-DD-YYYY'),
        beneficiaryCity: beneficiary?.data?.person?.data?.correspondences?.[0]?.addess?.zipCodeAndCity?.city || '',
        beneficiaryCountry: beneficiary?.data?.person?.data?.correspondences?.[0]?.addess?.country || '',
        beneficiaryCustomKey: beneficiary?.bUID,
        beneficiaryFirstName: beneficiary?.beneficiaryInformation?.firstName || beneficiary?.beneficiaryFirstName || '',
        beneficiaryGender: beneficiary?.data?.person?.data?.actor?.gender || '',
        beneficiaryLastName: beneficiary?.beneficiaryInformation?.lastName || beneficiary?.beneficiaryLastName || '',
        beneficiaryMiddleName: beneficiary?.beneficiaryMiddleName || '',
        beneficiaryOptions: "individual",
        beneficiaryRelationship: beneficiary?.beneficiaryInformation?.relationToInsured || beneficiary?.beneficiaryRelationship || '',
        beneficiaryState: beneficiary?.data?.person?.data?.correspondences?.[0]?.addess?.state || '',
        beneficiaryZipCode: beneficiary?.data?.person?.data?.correspondences?.[0]?.addess?.zipCodeAndCity?.zipCode || '',
        mailingAddressOptions: "",
      };
      let newBeneficiary = cloneDeep(beneficiary);
      if (!newBeneficiary?.isDependent && newBeneficiary?.beneficiaryInformation) {
        newBeneficiary['beneficiaryInformation']['beneficiaryRank'] = "cPrimary";
        newBeneficiary['beneficiaryInformation']['percentage'] = 0;
        newBeneficiary['personBuid'] = personBuid;
        newBeneficiary['data'] = data;
      }
      beneficiariesToUpdate.push(newBeneficiary);
    });

    const newTempValues = cloneDeep(tempValues);
    const arrayValues = [];
    newTempValues.forEach(temp => {
      if (temp && temp.coverageBuid === coverageBuid) {
        const newBeneficiariesGroup = [...temp.beneficiaries['Primary']];
        newBeneficiariesGroup.push(...beneficiariesToUpdate);
        temp['beneficiaries']['Primary'] = newBeneficiariesGroup;
      }
      arrayValues.push(temp);
    });
    setTempValues(arrayValues);
    handleClose();
  };

  const handleUpdate = (updatedBeneficiary) => {
    const coverageBuid = (productToAdd && productToAdd.coverageBuid) || '';
    let personBuid = '';
    if (productToAdd && productToAdd.contingentValues && productToAdd.contingentValues.length > 0) {
      personBuid = productToAdd.contingentValues && productToAdd.contingentValues[0] && productToAdd.contingentValues[0].personBuid;
    } else if (productToAdd && productToAdd.primaryValues && productToAdd.primaryValues.length > 0) {
      personBuid = productToAdd.primaryValues && productToAdd.primaryValues[0] && productToAdd.primaryValues[0].personBuid;
    }
    const newBuid = updatedBeneficiary && updatedBeneficiary.legalName ? updatedBeneficiary.legalName : `${updatedBeneficiary && updatedBeneficiary.beneficiaryFirstName}${updatedBeneficiary && updatedBeneficiary.beneficiaryLastName}`;
    let address = '';
    let line1 = '';
    let line2 = '';
    let city = '';
    let state = '';
    let zipCode = '';
    let addressObj = {};
    if (updatedBeneficiary?.mailingAddressOptions === "yes") {
      const liveInSameAddress = updatedBeneficiary?.liveInSameAddress || false;
      addressObj = {
        "county": "",
        "fIPSCode": 0,
        "state": liveInSameAddress ? employeeProfile?.state || '' : updatedBeneficiary?.beneficiaryState || '',
        "line1": liveInSameAddress ? employeeProfile?.line1 || '' : updatedBeneficiary?.beneficiaryAddressLine1 || '',
        "line2": liveInSameAddress ? employeeProfile?.line2 || '' : updatedBeneficiary?.beneficiaryAddressLine2 || '',
        "zipCodeAndCity": {
          "zipCode": liveInSameAddress ? employeeProfile?.zipCode || '' : updatedBeneficiary?.beneficiaryZipCode || '',
          "city": liveInSameAddress ? employeeProfile?.city || '' : updatedBeneficiary?.beneficiaryCity || '',
        },
        "country": liveInSameAddress ? employeeProfile?.country || '' : updatedBeneficiary?.beneficiaryCountry || '',
        "district": "",
        "buid": "",
        "className": "aSPCB_US_PostalAddress"
      };
      line1 = updatedBeneficiary?.beneficiaryAddressLine1 || '';
      line2 = updatedBeneficiary?.beneficiaryAddressLine2 ? ` ${updatedBeneficiary?.beneficiaryAddressLine2}` : '';
      city = updatedBeneficiary?.beneficiaryCity ? ` ${updatedBeneficiary?.beneficiaryCity}` : '';
      state = updatedBeneficiary?.beneficiaryState ? ` ${updatedBeneficiary?.beneficiaryState}` : '';
      zipCode = updatedBeneficiary?.beneficiaryZipCode ? ` ${updatedBeneficiary?.beneficiaryZipCode}` : '';
      address = `${line1}${line2}${city}${state}${zipCode}`;
    }
    // We are going to update tempValues based on the information provided
    const newBeneficiary = {
      bUID: `${productToAdd && productToAdd.coverageBuid}-${newBuid}`,
      beneficiary: {
        data: null,
        identifier: null,
      },
      beneficiaryInformation: {
        address: updatedBeneficiary?.mailingAddressOptions === "yes" ? addressObj : null,
        assignedDebt: {
          amount: 0,
          currency: 'USD',
        },
        beneficiaryRank: "cPrimary",
        birthDate: (updatedBeneficiary && updatedBeneficiary.beneficiaryBirthDate) || '',
        buid: `${productToAdd && productToAdd.productId}-${newBuid}`,
        contingentNumber: 0,
        debtAssignment: false,
        fraction: {
          numerator: 0,
          denominator: 1
        },
        irrevocable: (updatedBeneficiary && updatedBeneficiary.beneficiaryIrrevocable) || '',
        perStirpes: false,
        percentage: 0,
        relationToInsured: (updatedBeneficiary && updatedBeneficiary.beneficiaryRelationship) || '',
        sSN: updatedBeneficiary?.SSN?.replace(/-/g, '',) || '',
        title: updatedBeneficiary?.beneficiaryGender === 'gMale' ? 'ptMr' : updatedBeneficiary?.beneficiaryGender === 'gFemale' ? 'ptMrs' : '',
        wholeDebt: false,
      },
      className: "aSPLI_BeneficiaryData",
      dataHistory: [],
      date: null,
      personBuid: personBuid,
      data:
      {
        beneficiaryCustomKey: `${productToAdd && productToAdd.coverageBuid}-${newBuid}`,
        ...updatedBeneficiary,
      }
    };

    if (updatedBeneficiary && updatedBeneficiary.legalName) {
      newBeneficiary.beneficiaryInformation.name = updatedBeneficiary && updatedBeneficiary.legalName;
      newBeneficiary.beneficiaryInformation.className = 'aSPLI_US_LegalEntityBeneficiary';
    } else {
      newBeneficiary.beneficiaryInformation.className = 'aSPLI_US_PersonBeneficiary';
      newBeneficiary.beneficiaryInformation.firstName = (updatedBeneficiary && updatedBeneficiary.beneficiaryFirstName) || '';
      newBeneficiary.beneficiaryInformation.lastName = (updatedBeneficiary && updatedBeneficiary.beneficiaryLastName) || '';
    }
    const newTempValues = cloneDeep(tempValues);
    const arrayValues = [];
    newTempValues.forEach(temp => {
      if (temp && temp.coverageBuid === coverageBuid) {
        const newBeneficiariesGroup = [...temp.beneficiaries['Primary']];
        newBeneficiariesGroup.push(newBeneficiary);
        temp['beneficiaries']['Primary'] = newBeneficiariesGroup;
        const newBeneficiariesData = [...temp['beneficiaries']['Primary'], ...temp['beneficiaries']['Contingent']];
        temp['data'] = newBeneficiariesData;
      }
      arrayValues.push(temp);
    });
    // Code to update the data
    setTempValues(arrayValues);
  };

  return (
    <div>
      {isLoading && (<Loader loading={isLoading} className="loaderUpdateCls" />)}
      <div className={`modal ${show ? 'show' : ''} add-beneficiary`} style={{ display: show ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg" role="document" style={{ maxWidth: '800px', maxHeight: '600px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">{headerText}</div>
              <button type="button" className="close" onClick={handleClose}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <BeneficiaryModel
                key={modalKey} // Use the modalKey here
                onSubmit={handleSubmit}
                onCancel={handleClose}
                onSubmitGlobal={handleSubmitGlobal}
                layoutData={layoutData}
                // beneficiaryOptions={beneficiaries[editIndex]?.beneficiaryOptions}
                beneficiaryOptions={isEditModal ? isEditOptions === 'individual' ? beneficiaryEditOptionsData : beneficiaryEditOptionsLegal : beneficiaryOptions}
                initialValues={
                  isEditModal ?
                    { ...editInitialData }
                    :
                    { beneficiaryOptions: 'existing' }
                }
                allowedBeneficiaryKind={beneficiaryKinds}
                dataSelected={productToAdd}
                beneficiaryDataFormatter={beneficiaryDataFormatter}
                actionsFormatted={actionsFormatted}
                isEdit={isEdit}
              />
            </div>
          </div>
        </div>
      </div>
      {!hideActions && (
        <BeneficiaryActions
          index="0"
          isEdit={isEdit}
          onClickEdit={(e) => {
            setIsEdit(true)
          }}
          onClickCancel={(e) => {
            setIsEdit(false);
            setResetChanges(true);
          }}
          // isDisabled={primaryHeaders.length === 0 && contingentHeaders.length === 0}
          onClickSave={onSaveAction}
          showActions={Object.keys(productData).length > 0 ? true : false}
          disabledSave={beneficiaryErrors}
        />
      )}

      {Object.keys(productData).map(product => {
        const productInformation = productData[product];
        const personBuid = productInformation?.personBuid || '';
        const coverageBuid = productInformation?.coverageBuid || '';
        const newBeneficiaryErrors = percentageValidation && Object.keys(percentageValidation).length > 0 && percentageValidation[productInformation.coverageBuid];
        const hasBeneficiaryErrors = newBeneficiaryErrors && (newBeneficiaryErrors.primaryError || newBeneficiaryErrors.contingentError) ? true : false;
        const productBeneficiaryRules = beneficiaryRules && beneficiaryRules[`${productInformation.productName}`] && beneficiaryRules[`${productInformation.productName}`]['coverageBeneficiaryEligibility'] || [];
        let newRules = {};
        productBeneficiaryRules.forEach(rules => {
          if (rules?.personBuid === personBuid && rules?.coverageBuid === coverageBuid) {
            newRules = rules;
          }
        });
        let isElegible = true;
        if (Object.keys(newRules).length > 0) {
          isElegible = newRules?.isBeneficiaryEligible || true;
        }
        let hasPrimary = null;
        let hasContingent = null;
        let hasBeneficiaries = true;
        // Filling BeneficiaryInfoContent with dynamic information
        //check for coverage title
        beneficiaryInfoContent.models[0].collapses[0].title = productInformation.productTitle ? productInformation.productTitle : productNameMappings[productInformation.productName];
        if (productInformation && productInformation.primaryHeaders && productInformation.primaryHeaders.length > 0) {
          beneficiaryInfoContent.models[0].collapses[0].tables[0].multiConfig.tableRow = productInformation.primaryHeaders;
          beneficiaryInfoContent.models[0].collapses[0].tables[0].multiConfig.tableExpandedRow = productInformation.primaryValues;
          beneficiaryInfoContent.models[0].collapses[0].tables[0].hideTable = false;
          hasPrimary = true;
        } else {
          beneficiaryInfoContent.models[0].collapses[0].tables[0].multiConfig.tableRow = [];
          beneficiaryInfoContent.models[0].collapses[0].tables[0].multiConfig.tableExpandedRow = [];
          beneficiaryInfoContent.models[0].collapses[0].tables[0].hideTable = true;
          hasPrimary = false;
        }
        if (productInformation && productInformation.contingentHeaders && productInformation.contingentHeaders.length > 0) {
          beneficiaryInfoContent.models[0].collapses[0].tables[1].multiConfig.tableRow = productInformation.contingentHeaders;
          beneficiaryInfoContent.models[0].collapses[0].tables[1].multiConfig.tableExpandedRow = productInformation.contingentValues;
          beneficiaryInfoContent.models[0].collapses[0].tables[1].hideTable = false;
          hasContingent = true;
        } else {
          beneficiaryInfoContent.models[0].collapses[0].tables[1].multiConfig.tableRow = [];
          beneficiaryInfoContent.models[0].collapses[0].tables[1].multiConfig.tableExpandedRow = [];
          beneficiaryInfoContent.models[0].collapses[0].tables[1].hideTable = true;
          hasContingent = false;
        }

        if (!hasPrimary && !hasContingent) {
          hasBeneficiaries = false;
          isElegible = productBeneficiaryRules?.[0]?.isBeneficiaryEligible || false;
        }

        let showItem = true

        if (isSummaryPage && !hasBeneficiaries) {
          showItem = false
        }

        return isElegible && showItem && (
          <Banner>
            <div className={classes.card}>
              {beneficiaryInfoContent.models.map((model, i) => (
                <div key={i} className="col-12">
                  <div>
                    <div className={`${classes.collapses} text-bold-collapse`}>
                      {model.collapses.map((collapse, index) => {
                        return (
                          <Collapse
                            className={classes.collapses}
                            key={index}
                            title={collapse.title}
                            isAccordionImageLeftSide={true}
                            downArrow="arrow-down.svg"
                            upArrow="sm-arrow-up.svg"
                            changeBackground="true"
                            id="product-table-to-display-products"
                            defaultOpen={true}
                            showButton={showButton}
                            buttonOptions={buttonOptions}
                            isElegibleBenificiary={isElegible}
                          >
                            <div className={classes.expanded}>
                              {collapse.tables.map((table) => {
                                const expandedRowInfo = (table && table.multiConfig && table.multiConfig.tableExpandedRow) || [];

                                let tableExpandedRow = expandedRowInfo.map((expandedRow, ind) => {
                                  return (
                                    <div>
                                      <div className="d-block d-lg-none d-md-none">
                                        <div
                                          key={expandedRow.id}
                                          id="mobilebenefTable"
                                          className={classes.expanded}
                                        >
                                          <h6 id={`${table.tableId}-${index}-mobile-personal-information-${ind}`}>Personal Information</h6>
                                          {expandedRow.isLegalEntity && (
                                            <>
                                              <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                                                <div className="col-4">
                                                  <div class="row">
                                                    <div className="col-12" id={`${table.tableId}-${index}-mobile-legal-name-${ind}`}>Legal Name</div>
                                                    <div className={`col-12 ${classes.fontStyle}`} id={`${table.tableId}-${index}-mobile-legal-name-value-${ind}`}>
                                                      <b>{expandedRow.legalName}</b>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          {(!expandedRow.isLegalEntity &&
                                            <>
                                              <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                                                <div className="col-4">
                                                  <div class="row">
                                                    <div className="col-12" id={`${table.tableId}-${index}-mobile-first-name-${ind}`}>First Name</div>
                                                    <div className={`col-12 ${classes.fontStyle}`} id={`${table.tableId}-${index}-mobile-first-name-value-${ind}`}>
                                                      <b>{expandedRow.firstName}</b>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-4">
                                                  <div class="row">
                                                    <div className="col-12 ml-0 pl-0">Middle Name</div>
                                                    <div className={`col-12 ${classes.fontStyle}`}>
                                                      <b>{expandedRow.middleName}</b>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-4">
                                                  <div class="row">
                                                    <div className="col-12" id={`${table.tableId}-${index}-mobile-last-name-${ind}`}>Last Name</div>
                                                    <div className={`col-12 ${classes.fontStyle}`} id={`${table.tableId}-${index}-mobile-last-name-value-${ind}`}>
                                                      <b>{expandedRow.lastName}</b>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                                                <div className="col-4">
                                                  <div class="row">
                                                    <div className="col-12">SSN</div>
                                                    <div className={`col-12 mr-0 pr-0 ${classes.fontStyle}`}>
                                                      <b>{expandedRow.ssn}</b>
                                                      {expandedRow.ssn && (
                                                        <img alt='Eye Icon' src={eyeIconImage} onClick={() => { }} />
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-4">
                                                  <div class="row">
                                                    <div className={`col-12 ml-0 pl-0 ${classes.fontStyle}`}>
                                                      Date Of Birth
                                                    </div>
                                                    <div className={`col-12 ml-0 pl-0 ${classes.fontStyle}`}>
                                                      <b>{expandedRow.dateOfBirth}</b>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-4">
                                                  <div class="row">
                                                    <div className="col-12">Relationship</div>
                                                    <div className={`col-12 ${classes.fontStyle}`}>
                                                      {expandedRow.relationship}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                                                <div className="col-4">
                                                  <div class="row">
                                                    <div className="col-12">Gender</div>
                                                    <div className={`col-12 ${classes.fontStyle}`}>
                                                      {expandedRow.gender}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          {expandedRow.hasAddress && (
                                            <>
                                              <hr></hr>
                                              <div className="py-2">
                                                <h6>Address</h6>
                                              </div>

                                              <div className="col-12 px-0">
                                                {expandedRow.address}
                                              </div>
                                            </>
                                          )}
                                          {elementsTemp.map((element) => {
                                            <Elementor
                                              key={element.id}
                                              element={element}
                                              {...props}
                                            />;
                                          })}
                                        </div>
                                      </div>

                                      <div className="d-none dl-lg-block d-md-block">
                                        <div key={expandedRow.id} className={classes.expanded}>
                                          <h6 id={`${table.tableId}-${index}-personal-information-${ind}`}>Personal Information</h6>
                                          {expandedRow.isLegalEntity && (
                                            <>
                                              <div className="row">
                                                <div className="col-2" id={`${table.tableId}-${index}-legal-name-${ind}`}>Legal Name</div>
                                              </div>
                                              <div className="row">
                                                <div className={`col-2 ${classes.fontStyle}`}>
                                                  {expandedRow.legalName}
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          {!expandedRow.isLegalEntity && (
                                            <>
                                              <div className="row">
                                                <div className="col-2" id={`${table.tableId}-${index}-first-name-${ind}`}>First Name</div>
                                                <div className="col-2 ml-0 pl-0">Middle Name</div>
                                                <div className="col-2 ml-0 pl-0" id={`${table.tableId}-${index}-last-name-${ind}`}>Last Name</div>
                                                <div className="col-2 ml-0 pl-0">SSN</div>
                                                <div className="col-2 ml-0 pl-0">Date of Birth</div>
                                                <div className="col-1 ml-0 pl-0">Relationship</div>
                                                <div className="col-1 ">Gender</div>
                                              </div>
                                              <div className="row">
                                                <div className={`col-2 ${classes.fontStyle}`} id={`${table.tableId}-${index}-first-name-value-${ind}`}>
                                                  {' '}
                                                  {expandedRow.firstName}
                                                </div>
                                                <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                  {' '}
                                                  {expandedRow.middleName}
                                                </div>
                                                <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`} id={`${table.tableId}-${index}-last-name-value-${ind}`}>
                                                  {' '}
                                                  {expandedRow.lastName}
                                                </div>
                                                <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                  {showMaskedSSN.includes(expandedRow.buid) ? expandedRow.ssn : expandedRow.ssnMasked}
                                                  {expandedRow.ssn && (


                                                    <img
                                                      src={eyeIconImage}
                                                      role="button"
                                                      alt="eye Icon"
                                                      className="pl-2"
                                                      id="eyeIconImage"

                                                      onClick={() => {
                                                        let showMaskedArray = cloneDeep(showMaskedSSN);
                                                        if (showMaskedArray.includes(expandedRow.buid)) {
                                                          showMaskedArray = showMaskedArray.filter(item => item !== expandedRow.buid)
                                                        } else {
                                                          showMaskedArray.push(expandedRow.buid)
                                                        }
                                                        setShowMaskedSSN(showMaskedArray);
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                                <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                  {' '}
                                                  {expandedRow.dateOfBirth}
                                                </div>
                                                <div className={`col-1 ml-0 pl-0 ${classes.fontStyle}`}>
                                                  {' '}
                                                  {expandedRow.relationship}
                                                </div>
                                                <div className={`col-1 ${classes.fontStyle}`}>
                                                  {' '}
                                                  {expandedRow.gender}
                                                </div>
                                              </div>
                                            </>)}
                                          {expandedRow.hasAddress && (
                                            <>
                                              <hr></hr>
                                              <div className="py-3">
                                                <h6>Address </h6>
                                              </div>

                                              <div className="col-12 px-0">
                                                {expandedRow.address}
                                              </div>
                                            </>
                                          )}
                                          {elementsTemp.map((element) => {
                                            <Elementor
                                              key={element.id}
                                              element={element}
                                              {...props}
                                            />;
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                });
                                return !table.hideTable && (
                                  <Table
                                    key={table.tableId}
                                    tableId="manageBeneficiaries"
                                    isEditManageBeneficiaries={isEdit}
                                    classNameWrapper={classes.table}
                                    headers={table.multiConfig.tableHeader}
                                    body={table.multiConfig.tableRow}
                                    tableExpandedRow={tableExpandedRow}
                                    isExpandedRows={true}
                                    backgroundColor={backgroundColor}
                                    bgColor="true"
                                    temporaryValues={tempValues}
                                    setTemporaryValues={setTempValues}
                                    tableDataInfo={layoutData && layoutData.dependentsBeneficiaryList}
                                    collapseTitle={collapse.title} // Added collapse.title as a prop
                                  />
                                );
                              })}
                              {hasBeneficiaryErrors && (
                                <div className='mb-3'>
                                  <span
                                    style={{
                                      color: '#A61003',
                                      fontFamily: 'NotoSansRegular',
                                      fontSize: '0.75',
                                      paddingLeft: '1rem',
                                    }}
                                  >
                                    The total Primary Beneficiary or Contingent Beneficiary should be 100% to
                                    continue.
                                  </span>
                                </div>
                              )}
                            </div>
                          </Collapse>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
              {isEdit && (
                <div className={`col-12 px-4 mx-3 mb-3`}>
                  <Button
                    id={`manageAddBeneficiary`}
                    key={`manageAddBeneficiary`}
                    name={`manageAddBeneficiary`}
                    className={'btn d-flex btn-outline-primary primaryOutline'}
                    onClick={() => handleShow(productInformation)}
                    content="Add a Beneficiary"
                    //disabled={isDisabled}
                    outline="outline"
                    icon={["fas", "plus"]}
                    // modalPopUp={true}
                    // modalName={"manageAddBeneficiary"}
                    iconStyle={{ marginTop: '4px', marginRight: '4px' }}
                    // rearSector={"manageAddBeneficiary"}
                    header={false}
                    outerDiv={true}
                    shouldShowOnYes={true}
                    displayConfig={{
                      field: "hideOptions1",
                      show: false,
                      valueUpdate: {
                        "addBeneficiaryValidations": true
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </Banner>
        );
      })}
      {!hideActions && (
        <BeneficiaryActions
          index="1"
          isEdit={isEdit}
          onClickEdit={(e) => {
            setIsEdit(true)
          }}
          onClickCancel={(e) => {
            setIsEdit(false);
            setResetChanges(true);
          }}
          // isDisabled={primaryHeaders.length === 0 && contingentHeaders.length === 0}
          onClickSave={onSaveAction}
          showActions={Object.keys(productData).length > 0 ? true : false}
          disabledSave={beneficiaryErrors}
          history={history}
        />)}
    </div>
  );
};

ManageBeneficiariesTable.propTypes = {
  elements: PropTypes.array,
  allFormData: PropTypes.object,
  updateFormData: PropTypes.func,
  tableRowMapper: PropTypes.func,
  tableHeaders: PropTypes.arrayOf(PropTypes.string),
};
ManageBeneficiariesTable.defaultProps = {
  elements: {},
  allFormData: {},
  tableHeaders: [],
  setAllFormData: () => { },
  tableRowMapper: () => { },
};

export default ManageBeneficiariesTable;
