import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.sectionHeader {
  background-color: #F0FAFF;
}

.sectionHeader .title {
  font-size: 1.5rem;
  font-weight: 300 !important;
  color: #202020;
  padding: 1rem 0;
  font-family:  NotoSansRegular; 
}

.sectionHeader ul.breadcrumbs {
  list-style: none;
  padding: 0;
}

.sectionHeader .navHeader > ul.breadcrumbs{
  margin-left: 1rem;
}

.sectionHeader .navHeader>img{
    position: absolute;
    left: 1rem;
    top: 14px;
}

.sectionHeader ul.breadcrumbs li {
  display: inline;
  font-size: 0.875rem;
  color:  ${({ theme }) =>
    theme.molecules &&
    theme.molecules.sectionHeader &&
    theme.molecules.sectionHeader.sectionHeaderBreadcrumbsFontColor}; 
}

.sectionHeader ul.breadcrumbs li a {
  font-size: 0.875rem;
  color: ${({ theme }) =>
    theme.molecules &&
    theme.molecules.sectionHeader &&
    theme.molecules.sectionHeader.sectionHeaderBreadcrumbsFontColor}; 
  font-weight: normal;
  text-decoration: none !important;
}
//Mobile
  @media only screen and (max-width: 576px) {
    .sectionHeader .title {
      padding: 0rem 0;
      padding-top: 0.5rem;
    }
}
`;
