import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';
function Checkbox({ id, name, onChange, checked, disabled, className, label }) {
  return (
    <div className={className}>
      <input
        id={id}
        name={name}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
};

Checkbox.defaultProps = {
  id: '',
  name: '',
  onChange: () => {},
  checked: false,
  disabled: false,
  className: '',
  label: '',
};

export default Checkbox;
