import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Atoms/Loader';
import { SHOW_LOADER } from '../../../../../Store/Slices/Loader/index';
import {errorMessages} from '../../Atoms/FormValidation'
import Mfa from './Mfa';
import {SET_MFA_LOGIN} from '../../../../../Store/Slices/Registeration';
import { apiReceivedMFA, apiReceivedUserTypeAndLanguage } from '../../../../../Store/Slices/Auth/userAuth';
import {maskEmail} from '../../Helper/MFAHelper'
import { apiCall } from '../../Helper/commonHelper';
import { MFAToken } from '../../Helper/commonHelper';

const MfaContainer = (props) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const {loader} = useSelector((state) => state.loader);
    const { userAuth } = useSelector((storeState) => storeState.auth);
    const userType = userAuth?.userType
    const {legalEntityId = '', appCode = '', token = '', login = '', password = '', phoneNumberFromOkta='', preferredChannel=''} = useSelector((storeState) => storeState.registeration);
    const { currentLanguage } = useSelector((storeState) => storeState.language);
    const loginMail = maskEmail(login);
    const mfaOptions = [
        { label: `Email to ${loginMail}`, value: 'email' },
        { label: `Phone call to ${phoneNumberFromOkta}`, value: 'call' },
        { label: `SMS/text to ${phoneNumberFromOkta}`, value: 'sms' },
    ]; 
    
    const [err, setErr] = useState('');
    const [accessToken, setAccessToken] = useState(token);
    const [showOtherMethod, setShowOtherMethod] = useState(false);
    const [method, setMethod] = useState(preferredChannel);
    const [confirmOtp, setConfirmOtp] = useState(preferredChannel)
    useEffect(()=> {
        if(preferredChannel){
            setConfirmOtp(preferredChannel);
            setMethod(preferredChannel)
        }
    },[preferredChannel])
    const submitHandler = async (data) => {
        setErr('');
        dispatch(SHOW_LOADER(true));
        const payload = {
            otp: data.securityCode?.trim(),
            token:accessToken
        }
        apiCall('/stateful/sign-in/2', 'post', payload)
        .then((res) => {
            if (res?.data?.redirectionUrl && appCode !== 'ssp' && appCode !== 'HR') {
                const urlFromResponse = res.data.redirectionUrl;
                urlFromResponse && window.open(urlFromResponse, '_self');
            }
            if(res?.data?.redirectionUrl){
                const MfaFunction = async () => {
                    const tokenDetail = await MFAToken (res?.data?.redirectionUrl);
                    dispatch(SET_MFA_LOGIN({ decryptedEmail: tokenDetail.decrypted, email: tokenDetail.email }));
                    dispatch(apiReceivedMFA(tokenDetail.decrypted));
                    dispatch(apiReceivedUserTypeAndLanguage({ userType: userType?userType:props.type, language: currentLanguage }));
                    const urlFromResponse = res?.data?.redirectionUrl.substring(0, res.data.redirectionUrl.indexOf('?'));
                    const splittedUrl = urlFromResponse.split('/');
                    let welcomePageUrl = splittedUrl[splittedUrl.length - 2] + '/' + splittedUrl[splittedUrl.length - 1];
                    history.push(welcomePageUrl);
                }
                MfaFunction();
            } else {
                setErr(errorMessages({message:'Invalid Passcode/Answer'}, 'mfaotp'));
            }
            dispatch(SHOW_LOADER(false));
            }).catch((error) => {
            setErr(errorMessages({message:'Invalid Passcode/Answer'}, 'mfaotp'));
            dispatch(SHOW_LOADER(false));
        });
    }

    const onResend = (type) => {
        const newType = type ? type : confirmOtp;
        setErr('');
        dispatch(SHOW_LOADER(true));
        const data = {
                channel: newType,
                token: accessToken,
            };
        const url = '/stateful/sign-in/send-otp';
        apiCall(url, 'post', data)
        .then((res) => {
            setConfirmOtp(newType)
            if (res?.data?.token) {
            }else {
                setErr(errorMessages(res, 'mfaotp'));
            }
            dispatch(SHOW_LOADER(false));
        })
        .catch((error) => {
            setConfirmOtp(newType)
            setErr(errorMessages(error, 'mfaotp'));
            dispatch(SHOW_LOADER(false));
        });
        changeMethod(false);
    }
    
    const onChange = (e) => {
        setMethod(e.target.value);
        setErr('');
    }
    const enterOtp = () => {
        setErr('');
    }
    const changeMethod = (val) =>{
        setErr('');
        setShowOtherMethod(val)
    }

    return (
        <>
        {loader && <Loader loading={loader} />}
        <Mfa 
            enterOtp={enterOtp} 
            onChange={onChange} 
            onResend={onResend} 
            submitHandler={submitHandler}
            err={err} 
            setErr={setErr} 
            method={method} 
            mfaOptions={mfaOptions}
            email={loginMail}
            phone={phoneNumberFromOkta}
            showOtherMethod = {showOtherMethod}
            changeMethod = {changeMethod}
            backBtn={()=> history.push(`/enrollment?legalEntityId=${legalEntityId}&appCode=${appCode}`)}
            confirmOtp = {confirmOtp}
            />
        </>
    );
}

export default MfaContainer;
