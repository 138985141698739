import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Table } from '../../../../Atoms';
import classes from './beneficiary-styles.css';
import iconEye from '../../../../../Assets/Images/icon-eye.png';

const validateExistingDependents = (beneficiaries, dependents) => {
  const dependentsToAdd = [];
  dependents.forEach(dependent => {
    const dependentAdded = beneficiaries.find(beneficiary => (beneficiary?.id || beneficiary?.beneficiaryBuid || beneficiary?.tempId || beneficiary?.bUID) === dependent?.id);
    if ( !!!dependentAdded) {
      dependentsToAdd.push({...dependent, isEditable: false });
    }
  });
  return dependentsToAdd;
};

const removeCurrentBeneficiaries = (layoutData, beneficiaryDataFormatter, currentBeneficiaries, globalBeneficiaries, actionsFormatted, isEdit) => {
  const currentData = currentBeneficiaries?.data;
  const newGlobalBeneficiaries = [];
  const tableHeaders = [];
  const tableValues = [];
  
  // Removing duplicates for global beneficiaries
  const filterGlobalBeneficiaries = globalBeneficiaries.reduce((acc, current) => {
    const x = acc.find(item => {
      if (item?.beneficiaryInformation?.name) {
        if ( current?.beneficiaryInformation?.name === item?.beneficiaryInformation?.name) {
          return current;
        }
      } else {
        if (((item?.id || item?.beneficiaryBuid || item?.tempId) === (current?.id || current?.beneficiaryBuid || current?.tempId))) {
          return current;
        }
      }
    });
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  
  filterGlobalBeneficiaries.forEach(global => {
    const newGlobal = cloneDeep(global);
    const isCurrent = currentData.find(current => {
      if ( global?.beneficiaryInformation?.name ) {
        if ( global?.beneficiaryInformation?.name === current?.beneficiaryInformation?.name) {
          return current;
        }
      } else {
        if ( current?.bUID === global?.bUID || current?.beneficiaryBuid === global?.beneficiaryBuid) {
          return current;
        }
      }
    });
    if ( !!!isCurrent ) {
      newGlobal.beneficiarySelected = false;
      newGlobalBeneficiaries.push(newGlobal);
      const dataFormatterObj = {
        layoutData,
        beneficiary: newGlobal,
        type: 'Primary',
        product: currentBeneficiaries,
        isGlobalBeneficiaries: true,
        actionsFormatted,
        isEdit,
      }
      // const beneficiariesFormatted = beneficiaryDataFormatter(layoutData, newGlobal, 'Primary', currentBeneficiaries, true, actionsFormatted, isEdit);
      const beneficiariesFormatted = beneficiaryDataFormatter(dataFormatterObj);
      tableHeaders.push(beneficiariesFormatted.beneficiaryHeadersObj);
      tableValues.push(beneficiariesFormatted.beneficiaryContingentObj);
    }
  });
  return {
    newGlobalBeneficiaries,
    tableHeaders,
    tableValues,
  };
}

const BeneficiaryCollapse = (props) => {
  let {
    setBeneficiariesToAdd,
    layoutData = {},
    dataSelected = {},
    beneficiaryDataFormatter = () => {},
    actionsFormatted = {},
    isEdit = false,
  } = props;
  const [tempValues, setTempValues] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableValues, setTableValues] = useState([]);
  const [showMaskedSSN, setShowMaskedSSN] = useState([]);

  // Function to store the beneficiaries selected to save
  useEffect(() => {
    if ( tempValues?.length > 0 ) {
      const filteredValuesToAdd = tempValues.filter(temp => temp?.beneficiarySelected === true);
      if ( filteredValuesToAdd?.length > 0 ) {
        setBeneficiariesToAdd(filteredValuesToAdd);
      } else {
        setBeneficiariesToAdd([]);
      }
    }
  }, [tempValues]);

  // function to create and update the global beneficiaries list
  useEffect(() => {
    if ( layoutData?.globalBeneficiaries?.length > 0 && dataSelected?.data ) {
      const globalBeneficiaries = layoutData?.globalBeneficiaries;
      const dependentsList = layoutData?.dependentsList || [];
      const dependentsToAdd = validateExistingDependents(globalBeneficiaries, dependentsList);
      const beneficiariesData = removeCurrentBeneficiaries(layoutData, beneficiaryDataFormatter, dataSelected, [...globalBeneficiaries, ...dependentsToAdd], actionsFormatted, isEdit ); 
      setTempValues(beneficiariesData?.newGlobalBeneficiaries);
      setTableHeaders(beneficiariesData?.tableHeaders);
      setTableValues(beneficiariesData?.tableValues);
    }
  }, [layoutData?.globalBeneficiaries, layoutData?.dependentsList])

  const tableInfo = {
    tables: [
      {
        tableId: 'primaryBeneficiaries',
        isMultiple: true,
        hasActions: true,
        dataKey: 'dependentsInfo',
        actionsHeader: 'Actions',
        mobileActionsHeader: '',
        editKey: 'editDependentValidations',
        editModal: 'addDependent',
        tableData: 'dependentsTable',
        actionsStyle: {
          backgroundColor: '#0065AC',
          color: '#FFFFFF',
        },
        hideTable: true,
        multiConfig: {
          maxAdds: 3,
          tableHeaderStyle: {
            backgroundColor: '#0065AC',
          },
          tableHeader: [
            {
              value: '',
              id: 'BENEFICIARYSELECTION',
              config: {
                className: 'col-2 pl-4',
                style: {
                  backgroundColor: '#0065AC',
                  color: '#FFFFFF',
                },
              },
            },
            {
              value: 'BENEFICIARY NAME',
              id: 'BENEFICIARYNAME',
              config: {
                className: 'col-3 pl-4',
                style: {
                  backgroundColor: '#0065AC',
                  color: '#FFFFFF',
                },
              },
            },
            {
              value: 'RELATIONSHIP',
              id: 'BENEFICIARYRELATIONSHIP',
              config: {
                className: 'col-6',
                style: {
                  backgroundColor: '#0065AC',
                  color: '#FFFFFF',
                },
              },
            },
          ],
          tableRow: tableHeaders,
          tableExpandedRow: tableValues,
        },
      },
    ],
  }

  return (
      <div className={classes.expanded}>
        {tableInfo.tables.map((table) => {
          const expandedRowInfo = (table && table.multiConfig && table.multiConfig.tableExpandedRow) || [];
          let tableExpandedRow = expandedRowInfo.map((expandedRow) => {
            return (
                <div>
              <div className="d-block d-lg-none d-md-none">
                <div
                  key={expandedRow.id}
                  id="mobilebenefTable"
                  className={classes.expanded}
                >
                  <h6>Personal Information</h6>
                  {expandedRow.isLegalEntity && (
                    <>
                      <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                        <div className="col-4">
                          <div class="row">
                            <div className="col-12">Legal Name</div>
                            <div className={`col-12 ${classes.fontStyle}`}>
                              <b>{expandedRow.legalName}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {(!expandedRow.isLegalEntity &&
                    <>
                      <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                        <div className="col-4">
                          <div class="row">
                            <div className="col-12">First Name</div>
                            <div className={`col-12 ${classes.fontStyle}`}>
                              <b>{expandedRow.firstName}</b>
                            </div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div class="row">
                            <div className="col-12 ml-0 pl-0">Middle Name</div>
                            <div className={`col-12 ${classes.fontStyle}`}>
                              <b>{expandedRow.middleName}</b>
                            </div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div class="row">
                            <div className="col-12">Last Name</div>
                            <div className={`col-12 ${classes.fontStyle}`}>
                              <b>{expandedRow.lastName}</b>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                        <div className="col-4">
                          <div class="row">
                            <div className="col-12">SSN</div>
                            <div
                              className={`col-12 mr-0 pr-0 ${classes.fontStyle}`}
                            >
                              <b>{expandedRow.ssn}</b>
                              {expandedRow.ssn && (
                                <img
                                  src={iconEye}
                                  onClick={() => { }}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div class="row">
                            <div
                              className={`col-12 ml-0 pl-0 ${classes.fontStyle}`}
                            >
                              Date Of Birth
                            </div>
                            <div
                              className={`col-12 ml-0 pl-0 ${classes.fontStyle}`}
                            >
                              <b>{expandedRow.dateOfBirth}</b>
                            </div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div class="row">
                            <div className="col-12">Relationship</div>
                            <div className={`col-12 ${classes.fontStyle}`}>
                              {expandedRow.relationship}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                        <div className="col-4">
                          <div class="row">
                            <div className="col-12">Gender</div>
                            <div className={`col-12 ${classes.fontStyle}`}>
                              {expandedRow.gender}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {expandedRow.hasAddress && (
                    <>
                      <hr></hr>
                      <div className="py-2">
                        <h6>Address</h6>
                      </div>

                      <div className="col-12 px-0">
                        {expandedRow.address}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="d-none dl-lg-block d-md-block">
                <div key={expandedRow.id} className={classes.expanded}>
                  <h6>Personal Information</h6>
                  {expandedRow.isLegalEntity && (
                    <>
                      <div className="row">
                        <div className="col-2 ">Legal Name</div>
                      </div>
                      <div className="row">
                        <div className={`col-2 ${classes.fontStyle}`}>
                          {expandedRow.legalName}
                        </div>
                      </div>
                    </>
                  )}
                  {!expandedRow.isLegalEntity && (
                    <>
                      <div className="row">
                        <div className="col-2 ">First Name</div>
                        <div className="col-2 ml-0 pl-0">Middle Name</div>
                        <div className="col-2 ml-0 pl-0">Last Name</div>
                        <div className="col-2 ml-0 pl-0">SSN</div>
                        <div className="col-2 ml-0 pl-0">Date of Birth</div>
                        <div className="col-1 ml-0 pl-0">Relationship</div>
                        <div className="col-1 ">Gender</div>
                      </div>
                      <div className="row">
                        <div className={`col-2 ${classes.fontStyle}`}>
                          {' '}
                          {expandedRow.firstName}
                        </div>
                        <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                          {' '}
                          {expandedRow.middleName}
                        </div>
                        <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                          {' '}
                          {expandedRow.lastName}
                        </div>
                        <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                          {showMaskedSSN.includes(expandedRow.buid) ? expandedRow.ssn : expandedRow.ssnMasked}
                          {expandedRow.ssn && (
                            <img
                              role="button"
                              className='pl-2'
                              alt="eye Icon"
                              src={iconEye}
                              onClick={() => {
                                let showMaskedArray = cloneDeep(showMaskedSSN);
                                if (showMaskedArray.includes(expandedRow.buid)) {
                                  showMaskedArray = showMaskedArray.filter(item => item !== expandedRow.buid)
                                } else {
                                  showMaskedArray.push(expandedRow.buid)
                                }
                                setShowMaskedSSN(showMaskedArray);
                              }}
                            />
                          )}
                        </div>
                        <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                          {' '}
                          {expandedRow.dateOfBirth}
                        </div>
                        <div className={`col-1 ml-0 pl-0 ${classes.fontStyle}`}>
                          {' '}
                          {expandedRow.relationship}
                        </div>
                        <div className={`col-1 ${classes.fontStyle}`}>
                          {' '}
                          {expandedRow.gender}
                        </div>
                      </div>
                    </>)}
                  {expandedRow.hasAddress && (
                    <>
                      <hr></hr>
                      <div className="py-3">
                        <h6>Address </h6>
                      </div>

                      <div className="col-12 px-0">
                        {expandedRow.address}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        });

        return tempValues?.length > 0 && (
          <Table
            key={table.tableId}
            tableId="globalBeneficiaries"
            isEditManageBeneficiaries={false}
            classNameWrapper={classes.table}
            headers={table.multiConfig.tableHeader}
            body={table.multiConfig.tableRow}
            tableExpandedRow={tableExpandedRow}
            isExpandedRows={true}
            backgroundColor={true}
            bgColor="true"
            temporaryValues={tempValues}
            setTemporaryValues={setTempValues}
            tableDataInfo={layoutData && layoutData.dependentsBeneficiaryList}
          />
        );
      })}
    </div>
  );
};

export default BeneficiaryCollapse;
