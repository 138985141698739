import React, { useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOADER } from '../../../../../Store/Slices/Loader/index';
import {  SET_MFA_API_PARAM, SET_MFA_BASIC_AUTH_VALUE, SET_USER_TYPE_LANGUAGE,
  SET_MFA_PRIMARY_LOGIN_PAYLOAD,
  SET_MFA_TOKEN,
  SET_MFA_LOGIN,
  SET_PREFERRED_CHANNEL,
  SET_PHONE_FROM_OKTA, CLEAR_REGISTERATION, SET_MFA_URL } from '../../../../../Store/Slices/Registeration';
import Loader from '../../Atoms/Loader';
import appConfig from '../../../../../appConfig';
import http from '../../../../../Common/Services/HttpService/index';
import { onLoadAPI } from '../../../../../Store/Slices/Entities/response/index';
import { updateEnvironment } from '../../../../../Store/Slices/Auth/userAuth/index';
import {
  userTypeMFA,
  setProcessType,
  apiReceivedMFA,
  apiReceivedUserTypeAndLanguage,
  SET_AUTHENTICATED_USER,
} from '../../../../../Store/Slices/Auth/userAuth';
import Login from './Login';
import {errorMessages} from '../../Atoms/FormValidation'
import { MFAToken } from '../../Helper/commonHelper';

const LoginScreen = (props) => {
  const recaptchaRef = useRef({});
  const dispatch = useDispatch();
  let history = useHistory();

  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, SetContent] = useState({});
  const [err, setErr] = useState('');

  useEffect(()=>{
    SetContent(require(`../Content/${currentLanguage}.json`))
  },[currentLanguage])

  const {loader} = useSelector((state) => state.loader);
  const [clientLogo, setClientLogo] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [logo, setLogo] = useState('');

  const { loginConfigInfoLogo, loginConfigInfo } = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList);
  const hideRecaptcha = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.loginConfigInfo?._Result?.hideRecaptcha
  );

  const callLoginConfigAPI = async (legalEntityId) => {
    const newEntityId = legalEntityId?.substring(0, legalEntityId?.indexOf(':'));
    if(!newEntityId) return
    return await http
      .get(appConfig.SSP_MID_URL + `/v1/config/${newEntityId}`)
      .then(async (resp) => {
        const data = resp?.data?.data
        for (const key in data) {
          let result = { _Result: data[key].data }
          dispatch(onLoadAPI({ [key]: result }));
        }
        return true;
      })
      .catch((e) => {
        return false;
      });
  };

  useEffect(() => {
    setClientLogo(loginConfigInfo?._Result?.hideLogo);
    setLogo(loginConfigInfoLogo?._Result);
    const authResult = new URLSearchParams(window.location.search);
    const legalEntityId = authResult.get('legalEntityId');
    const appCode = authResult.get('appCode');
    let paramObject = {
      legalEntityId: legalEntityId,
      appCode: appCode,
    };
    legalEntityId && appCode && dispatch(SET_MFA_API_PARAM(paramObject))
  }, [loginConfigInfoLogo, loginConfigInfo])

  useEffect(() => {
    const authResult = new URLSearchParams(window.location.search);
    const legalEntityId = authResult.get('legalEntityId');
    if(!loginConfigInfo) callLoginConfigAPI(legalEntityId)
  }, [loginConfigInfo]);

  const getBasicAuthHeaderValue = () => {
    let authValue = process.env.REACT_APP_MFA_AUTH_USER;
    // Encoding into base64 using btoa(), which is available in browser environments
    let basicAuthHeaderValue;
    basicAuthHeaderValue = authValue ? btoa(authValue) : '';
    return basicAuthHeaderValue;
  };

  let basicAuthorizationValue = getBasicAuthHeaderValue();
  basicAuthorizationValue && dispatch(SET_MFA_BASIC_AUTH_VALUE(basicAuthorizationValue));

  const handleSubmitRecaptcha = async(formData) =>{
    try {
      dispatch(SHOW_LOADER(true));
      setRecaptchaError(false);
      /* function to submit login credentials and to call userEntity */
      http.setUserType(props.type);
      http.setLanguage(currentLanguage);
      await dispatch(
        updateEnvironment({
          userType: props.type,
        })
      );
      dispatch(SET_USER_TYPE_LANGUAGE({ userType: props.type, language: currentLanguage }));
      props.type && dispatch(userTypeMFA(props.type));
      dispatch(setProcessType('non-sso'))
      const authResult = new URLSearchParams(window.location.search);
      const legalEntityId = authResult.get('legalEntityId');
      const appCode = authResult.get('appCode');
      if (legalEntityId && appCode) {
        dispatch(SET_MFA_API_PARAM({legalEntityId:legalEntityId, appCode:appCode}));
        dispatch(SET_MFA_URL(`?${authResult}`));
      }
      let data = {
        login: formData.username?.trim(),
        password: formData.password?.trim(),
        legalEntityId: legalEntityId,
        appCode: appCode,
      };
      formData.username &&
        formData.password &&
        dispatch(
          SET_MFA_PRIMARY_LOGIN_PAYLOAD({ login: formData.username, password: formData.password })
        );
      let url = '/stateful/sign-in/1';
      const newContent = content[props.type]
      http
        .post(appConfig.SSP_AUTH_URL + `${url}`, data, {
          withCredentials: true,
          headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200),
          },
        })
      .then((res) => {
        
        let authenticated = res?.data?.authenticated;
        if (res?.data?.token) {
          res?.data?.token && dispatch(SET_MFA_TOKEN({ token: res.data.token }));
          dispatch(SET_MFA_TOKEN({ token: res.data.token }));
          if (!authenticated && newContent && newContent.redirectUrl) {
            history.push(newContent.redirectUrl);
          }
        }
        /**handle authenticated user */
        if (res?.data?.redirectionUrl && appCode !== 'ssp' && appCode !== 'HR') {
          const urlFromResponse = res.data.redirectionUrl;
          urlFromResponse && window.open(urlFromResponse, '_self');
        } else {
        const yourFunction = async () => {
          if (res?.data?.redirectionUrl && appCode === 'ssp' || appCode === 'HR') {
            if(appCode === 'HR'){
              http.setAuthTokenUserType('hrcenterMFA');

            }
            dispatch(SET_AUTHENTICATED_USER(formData.username));
            try {
              const tokenDetail = await MFAToken (res?.data?.redirectionUrl);
              dispatch(SET_MFA_LOGIN({ decryptedEmail: tokenDetail.decrypted, email: tokenDetail.email }));
              dispatch(apiReceivedMFA(tokenDetail.decrypted));
              dispatch(apiReceivedUserTypeAndLanguage({ userType: props.type, language: currentLanguage }));
              const urlFromResponse = res.data.redirectionUrl.substring(0, res.data.redirectionUrl.indexOf('?'));
              const splittedUrl = urlFromResponse.split('/');
              let welcomePageUrl = splittedUrl[splittedUrl.length - 2] + '/' + splittedUrl[splittedUrl.length - 1];
              history.push(welcomePageUrl);
            } catch (error) {
              setErr(errorMessages(error, 'login'));
            }
          } else {
            setErr(errorMessages(res, 'login'));
          }
        }
        yourFunction();
        }
        let channel = res?.data?.channel;
        channel && dispatch(SET_PREFERRED_CHANNEL(channel))
        let phoneNumber = res?.data?.phoneNumber;
        phoneNumber && dispatch(SET_PHONE_FROM_OKTA(phoneNumber))
        dispatch(SHOW_LOADER(false));
      })
      .catch((error) => {
        
        setErr(errorMessages(error, 'login'));
        dispatch(SHOW_LOADER(false));
      });
    } catch(e) {
     
      setErr(errorMessages());
      dispatch(SHOW_LOADER(false));
    }
    
  }
  const onSubmit = async (values) => {
    window?.grecaptcha?.reset();
  if(process.env.REACT_APP_RECAPTCHA_AVAILIBLE === 'true' && !hideRecaptcha ){
    const captchaToken = await recaptchaRef.current.executeAsync();
    if (captchaToken) {
      const recaptchaResponse = await http.post(appConfig.SSP_MID_URL + '/recaptchaValidator', { key: captchaToken })
      const successCaptcha = recaptchaResponse && recaptchaResponse.data && recaptchaResponse.data._Result && recaptchaResponse.data._Result.success;
      if ( successCaptcha ) {
        handleSubmitRecaptcha(values)
      } else {
        setRecaptchaError(true);
        recaptchaRef.current.reset();
      }
    } else {
      window?.grecaptcha?.reset();
      setRecaptchaError(true);
      recaptchaRef.current.reset();
    }
  }
  else{
    handleSubmitRecaptcha(values)
  } 
    setRecaptchaError(false);
}
const clearErrMsg = () =>{
  setErr('');
}

const register = () => {
  dispatch(CLEAR_REGISTERATION({ email: '', phone: '' }));
  history.push('/userRegistration');
}

return (
  <>
  {loader && <Loader loading={loader} />}
     <Login onSubmit={onSubmit} hideRecaptcha={hideRecaptcha} recaptchaRef={recaptchaRef} errMsg={err} clrErr={clearErrMsg} clientLogo={clientLogo} logo={logo} title={props.title} register={register} /> 
  </>
);
};

export default LoginScreen;
