import { onLoadAPI } from "../../../../Store/Slices/Entities/response";
import ServiceHelper from "../../../Utils/Helper/MappingHelper";
import { SET_ALERT_MESSAGE } from "../../../../Store/Slices/InsuredInfo";
import { UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment';
export const reworkProductEnrollment = (params, returnResp = false) => async(dispatch) => {
    const serviceHelper = new ServiceHelper();
    let respObj = await serviceHelper.sendApiRequest('/reworkProductEnrollment', {
        ...params
    });
  

    if (respObj.data && respObj.status === 200) {
        dispatch(onLoadAPI({
          ReworkProductEnrollment: respObj.data,
          isAPICallInProgress: false,
        }))

        const processInfo=respObj?.data?._Result??{};
        dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
        return {
            status: true,
            respObj : respObj
        }
    } else {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: respObj?.status || '520',
            id: 'reworkProductEnrollment',
            show: false,
            page: params?.currentStep
          }));
        return {
            status: false,
            respObj : respObj
        }
    }
}