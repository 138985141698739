import React from 'react';
import { Image, Linker } from '../../../Atoms';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';

function SuccessPage(props) {
  let {
    submissionTextBefore,
    submissionTextAfter,
    confirmationMessage,
    confirmationImage = {},
    redirectURL,
    redirectMessage,
    formReferenceLink,
  } = props || {};



  return (
    <React.Fragment>
      {
        <div className="col-12 ">
          <div className="confirmation-container">
            <Image alt={confirmationImage.imgAlt} filename={confirmationImage.imgLink} />
            <h1 className="confirmation_message">{confirmationMessage}</h1>{' '}
           
          </div>
          <div className="row px-3 pr-5 mr-5">
            <div className="col-12">
              <h1 className="confirmation_link">
                {submissionTextBefore}

                <Linker to={redirectURL}>
                  {redirectMessage ? redirectMessage : `${formReferenceLink}'s`}
                </Linker>
                {submissionTextAfter}
              </h1>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default SuccessPage;
