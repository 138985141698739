import { useSelector } from "react-redux";
import PolicyReplacementQuestionsContainer from "../../Container/PolicyQuestionsWrapper"
import { useEffect, useMemo, useState } from "react";

//cab be used as a wrapper function to the existing component of policy replacement section. 
//Ideally all the data required for rendering and updating this component should reside here
export const PolicyReplacementQuestionsSection = (props) => {
    const {
        personBuid = "", 
        currentStep, 
        coverageBuid, 
        totalPolicyQuestionDiffCallback, 
        getTotalQuestionCount, 
        isBeneficiaryMandatory, 
        isBeneficiaryOptional, 
        selectedRows,
    } = props;

    const [showPolicySection, setShowPolicySection] = useState(false)
    const storeState = useSelector((storeState) =>  storeState);

    const onLoadResponseList = storeState?.entities?.response?.onLoadResponseList?.[currentStep];
    const subscribeCoverageBuids = onLoadResponseList?.subscribeCoverageBuids;
    let filteredCoverage;
    let subscribedCoverageConfigurationResponse;

    const policyReplacementQuestions = storeState.insuredInfo[currentStep]?.policyReplacementQuestions
    const policyReplacementAnswer = []
    if (Object.keys(policyReplacementQuestions).length > 0) {
        Object.entries(policyReplacementQuestions).map(([key, value]) => policyReplacementAnswer.push({ fieldName: key, valueCode: value }));
    }

    if(subscribeCoverageBuids && subscribeCoverageBuids.length) {
        filteredCoverage = subscribeCoverageBuids.filter((item) => item.personBuid === personBuid && item.coverageBuid === coverageBuid)
    }

    if(filteredCoverage && filteredCoverage.length) {
        subscribedCoverageConfigurationResponse = filteredCoverage[0]?.subscribedCoverageConfiguration?._Result || {}
    }

    const policyReplacementData = subscribedCoverageConfigurationResponse || {};

    //Existing function: Calculates the total questions to be shown
    const totalRadioQuestions = useMemo(() => {
        let policyReplacementTotalQuestions = policyReplacementData?.additionalDatas?.filter(eachObj => eachObj.fieldCode !== "PlanDesign");
        const totalQuestions = policyReplacementTotalQuestions?.filter(
            (question) => question?.fieldCode !== 'PlanDesign' && question.possibleValues
        );
        getTotalQuestionCount(totalQuestions?.length)
        return totalQuestions;
    }, [policyReplacementData])
    
    //Existing function: Calculates questions vs answer count
    const totalPolicyQuestionDiff = useMemo(() => {
        const totalQuestions = totalRadioQuestions?.length || 0;
        let totalAnswers = [];
        totalRadioQuestions?.forEach(pquestion => {
            const lanswer = policyReplacementAnswer?.find(panswer => {
                return pquestion.fieldCode === panswer.fieldName
            });
            if (lanswer) {
                totalAnswers.push(lanswer);
            }
        })
        return totalQuestions - totalAnswers?.length;
    }, [policyReplacementAnswer])

    useEffect(() => {
        totalPolicyQuestionDiffCallback(totalPolicyQuestionDiff)
    },[totalPolicyQuestionDiff])
    
    return (
        <>
            {totalRadioQuestions?.length > 0 ? <PolicyReplacementQuestionsContainer
                personBuid={personBuid}
                subscribedCoverageConfigurationResponse={subscribedCoverageConfigurationResponse}
            />: null}
        </>
    )
}