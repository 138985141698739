import React from 'react';
import { useFormik } from 'formik';
import {Link} from 'react-router-dom';
import { Button, TextInput, RadioButton } from '../../Atoms';
import StickyNote from '../../Atoms/StickyNote';
import ResendOTPBlock from '../../Atoms/ResendOTPBlock/index';
import { securityCodeSchema } from '../../Atoms/FormValidation';


const Mfa = ({ initialValues, submitHandler, enterOtp, onResend, onChange, err, method, mfaOptions, email, phone, showOtherMethod, changeMethod, backBtn, confirmOtp }) => {
   const formik = useFormik({
        initialValues: initialValues || {
            securityCode: '',
            changeMethod: confirmOtp
        },
        validationSchema: securityCodeSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            submitHandler(values);
        },
    });
  
    const validationMsg = (field) => {
        return (formik.touched[field] && formik.errors[field] && (
            <div className="form-mandatory">{formik.errors[field]}</div>
        ))
    };
    
    return (
        <form onSubmit={(e) => e.preventDefault()} noValidate>
                {!showOtherMethod ? 
                <>
                    <div className="row">
                        <div className="col-12">
                            <h1 className="form-side-header">Multi-factor Authentication</h1>
                            <p className="form-mandatory mb-0">
                                Fields marked with * are mandatory
                            </p>
                            <p> Please enter the security code sent to your {( confirmOtp === 'email') ? `registered email ${email}` : `mobile number ${phone}`} </p>
                            
                        </div>
                    </div>
                    <div className="row py-2">
                        <div className="col-sm-12 col-md-6">
                            <TextInput
                                required
                                id="securityCode"
                                name="securityCode"
                                label="Security Code"
                                onInput={()=>enterOtp()}
                                placeholder="Enter Security Code"
                                className="w-100 inputField p-3"
                                {...formik.getFieldProps('securityCode')}
                            />
                            {validationMsg('securityCode')}
                            {(err && String(err).search('Invalid security code') !== -1) && 
                                <div className="row">
                                    <span style={{ color: '#A61003',  margin:5, paddingLeft:10 }}>{err} </span>
                                </div>
                            }
                        </div>
                        
                        <div className="col-sm-12 col-md-6">
                            <StickyNote
                                title={'Important Note'}
                                children={`The security code will expire in 5 minutes after you receive it.`}
                            />
                        </div>
                    </div>
                    <ResendOTPBlock duration={'45'} onResend={onResend} />
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <p>Unable to receive code?</p>
                            <Link role="button" to="#" onClick={()=>changeMethod(true)}>Try another method</Link>
                        </div>
                    </div>
                </>
                :
                <>
                <div className="row">
                    <div className="col-12">
                        <h1 className="form-side-header">Multi-factor Authentication</h1>
                        <p></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <RadioButton
                            name="changeMethod"
                            options={mfaOptions}
                            onChange={onChange}
                            selectedOption={method ? method : formik.values.changeMethod}
                            label="Change the method of your choice to receive security code."
                            className={'forMFA'}
                        />
                    </div>
                </div>
                </>
                }
                {(err && String(err).search('Invalid security code') === -1) && 
                    <div className="row">
                        <span style={{ color: '#A61003',  margin:5, paddingLeft:10 }}>{err} </span>
                    </div>
                }
                <div className="row py-4 justify-content-between">
                    <div className="col-sm-12 col-md-4 py-2">
                        
                    </div>
                    <div className="col-sm-12 col-md-6 py-2 text-md-right">
                        <Button
                            type="button"
                            className="btn btn-outline-primary primaryOutline btn-outline-primary-contrast mr-4"
                            onClick={()=>showOtherMethod ? changeMethod(false) : backBtn()}
                        >
                            {showOtherMethod ? "Cancel" : "Back"}
                        </Button>
                        <Button
                            type={"button"}
                            className="btn btn-primary primaryBackground btn-primary-contrast"
                            disabled ={showOtherMethod ? !formik.values.changeMethod : (!formik.isValid || !formik.dirty)}
                            onClick={()=>showOtherMethod ? onResend(method) : formik.submitForm() } 
                        >
                            Continue
                        </Button>
                    </div>
                </div>
        </form>
    );
}

export default Mfa;
