import React, { useEffect, useState } from 'react';
import { validationTriggered, onLoadAPIMFA, } from '../../../../Store/Slices/Entities/response/index';
import { handleFormChange } from '../../../../Store/Slices/Entities/response/index';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_LANGUAGE } from '../../../../Store/Slices/Language';
import { STORE_IN_REDUX, STORE_IN_REDUX_WL, STORE_IN_REDUX_MEDICAL_SURVEY } from '../../../../Store/Slices/Process';
import {
  UPDATE_DEPENDENT_LIST,
  UPDATE_BENEFICIARIES_LIST,
  ADD_BENEFICIARIES_LIST,
  ADD_PET_LIST,
  UPDATE_PET_LIST,
  SELECTED_PET,
  RADIO_SELECTED_VALUE_PET_NATION_DATA_ARRAY,
  UPDATE_CART_ITEM,
  SET_ADD_DEPENDENT,
} from '../../../../Store/Slices/EmployeeProfile';
import { DELETE_INSURED_INFO } from '../../../../Store/Slices/InsuredInfo';
import { CHANGE_BUTTON_WIDTH } from '../../../../Store/Slices/DisableButton';
import {
  DELETE_FULL_PET_LIST
} from '../../../../Store/Slices/EmployeeProfile';
import {
  RADIO_SELECTED_VALUE_PET_NATION,
  EDIT_PET_NATION,
} from '../../../../Store/Slices/EmployeeProfile';
import { MODAL_STEP } from '../../../../Store/Slices/DisableButton';
import {
  UNDER_WRITING_QUESTION_ONE,
  UNDER_WRITING_QUESTION_TWO,
  UNDER_WRITING_QUESTION_THREE,
  UNDER_WRITING_QUESTION_FOUR,
  HOSPITAL_SECTION,
} from '../../../../Store/Slices/EmployeeProfile';
// import { CHANGE_THEME } from '../../../../Store/Slices/ThemeValues';
import { CHANGE_THEME, UPDATE_THEME, CONFIGURE_THEME } from '../../../../Store/Slices/Theme';
import appConfig from '../../../../appConfig';
import { useHistory } from 'react-router-dom';
import ServiceHelper from '../../../Utils/Helper/MappingHelper';
import { getErrorMessages } from '../../../Molecules/CustomSection/Helper/MappingHelper';

import TableWrapper from '../../../Molecules/TableWrapper/index';
import http from '../../../../Common/Services/HttpService/index';
import { constantsList as defaultConstants } from '../../../../Common/Constants/index';
import { callConstants } from '../../../../Store/Slices/Entities/constants/index';
import ManipulationService from '../../../../Common/Services/ManipulationService/index';
import { formatter } from '../../../Atoms/Currency/index';
import Modal from '../../../Molecules/Modal';
import ListContainer from '../../../Molecules/ListContainer';
import $ from 'jquery';
import { dataValidation } from '../../../Utils/Validator/DatabaseValidator';
import defaultTheme from '../../../../Theming/Themes/defaultTheme';
import useFormProgress from '../FormGenerator/UseFormProgress';
import {
  HIDE_CONTINUE_BUTTON,
  HIDE_BACK_BUTTON,
  PREVIOUS_STEP,
} from '../../../../Store/Slices/StepForm';
import ProductTable from '../../../Molecules/ProductTable';
import ManageBeneficiariesTable from '../../../Molecules/ManageBeneficiariesTable';
import IFrameGenerator from '../../../Molecules/IFrameGenerator';
import SearchClientList from '../../../Molecules/SearchClientList';
import SearchEmployeeList from '../../../Molecules/SearchEmployeeList';
import EmployeeData from '../../../Molecules/EmployeeData';
import {

  VOYA_API_RESPONSE
} from '../../../../Store/Slices/EmployeeProfile';
// import {  useSelector } from 'react-redux';
// const { contentData, handleSubmit, currentStep, formik } = props;
// const { formData, goForward, goBack, cancel, steps, stepKeys } = contentData;
// import Collapse from '../../../Atoms/Collapse';
import { validateSpecialCharacters } from '../../../Utils/Helper/ValidationHelper';
import {
  STORE_DEPENDENT_ADDRESS,
  HIDE_ROW,
  STORE_LIVE_AT_SAME_ADDRESS,
  HIDE_BENEFICIARY_ADDRESS,
} from '../../../../Store/Slices/HideShowRow';
import {
  APIRequestParallel,
  APIRequestSequential,
  APIRequestSequential2,
  onLoadAPI,
} from '../../../../Store/Slices/Entities/response/index';

import {
  SET_WAIVE_COVERAGE,
  SET_WAIVE_COVERAGE_NATION,
  SET_WAIVE_COVERAGE_VOYA
} from '../../../../Store/Slices/InsuredInfo';
import Loader from '../../../Atoms/Loader';

import {
  Input,
  Select,
  Button,
  RadioButtonGroup,
  Checkbox,
  ReadOnly,
  PanelSideHeader,
  Currency,
  Tile,
  BoxCard,
  TableCard,
  Label,
  Image,
  Linker,
  IconDropdown,
  PanelCard,
  ImageText,
  AccordionList,
  RadioButton,
  ButtonDropDown,
  TextArea,

  Timer,
  ToolTip,
  Collapse,
  TextContainer,
  ListComponent,
  
  StickyNote,
  Sticky,
  TextReadOnlyLabel,
  Line,
  Section,
  FileUpload,
  Cart,
  TableTemp,

} from '../../../Atoms';

import InputColor from '../../../Atoms/InputColor';

import { getValueFromKeyString } from '../../../Utils/Helper/GetValueFromKeyString';
import { indexOf } from 'lodash';
import { isEmpty, get } from 'lodash';
import RegisterationError, {
  ACTIVATE_PHONE, EMAIL_IS_VERIFIED, EMAIL_VERIFIED, HAS_ERROR, HIDE_INPUT_N_SUBMIT, PHONE_IS_VERIFIED, SET_BAD_RESPONSE_ERROR,
  SET_EMAIL_EXIST, SET_EMPLOYEE_VALIDATION_ERROR, SET_MFA_SERVICE_UNAVAILABLE, SET_SEND_EMAIL_CHALLENGE_ERROR,
  SHOW_OTP_ERR
} from '../../../../Store/Slices/RegisterationError';
import { HIDE_MSG, SET_LOGIN_OTP_METHOD, SET_LOGIN_OTP_METHOD_RETRIEVE_USER, SET_MFA_LOGIN_TOKEN, SET_TEMP_LOGIN_OTP_METHOD, SHOW_REGISTER_TIMER_PHONE } from '../../../../Store/Slices/Registeration';
import ContactPreference from '../../../Molecules/CrossSell/ContactPreference';
import CrossSellProducts from '../../../Molecules/CrossSell/Products';
import { UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment';
import { updateStepperNavigatorInfo } from '../../../Utils/Form/StepperHelper';

const Elementor = (props) => {
  const {
    elements,
    element,
    formik,
    formName,
    isMultiple,
    useConter,
    editKey,
    errorFound,
    constantsList,
    result,
    innerFormName,
    showContent = [],
    contentData,
    tableRowMapper,
    updateFormData,
    editRecord = () => { },
    deleteRecord = () => { },
    onChangeCheckBox = () => { },
    displayContent = {},
    excludeValidationElements = [],
    onClickTimer = () => { }
  } = props;

  const { columns, mobileColumns, mobilePanel, mobileRows = [] } = props.element;

  const excludedElements = ['dependentMiddleName', 'dependentAddressLine2'];

  const errorStatusObj = formik && formik.status && formik.status[formName] || {};
  let hasEditError = false;
  let isEditModal = false;

  // const [currentStep, setCurrentStep] = useState(0);
  const [visitedStep, setVisitedStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [coverageLevelValue, setCoverageLevelValue] = useState('');
  const [costPerPayPeriodValue, setCostPerPayPeriodValue] = useState('');
  const [stepModal, setStepModal] = useState(0);
  const modalStep = useSelector((storeState) => storeState?.disableButton?.modalStep);
  const { radioSelectedValuePetNation } = useSelector((storeState) => storeState.employeeProfile);
  const { setWaiveCoverageNation } = useSelector((storeState) => storeState.insuredInfo) || {};
  const { petList } = useSelector((storeState) => storeState.employeeProfile);
  const { userType = '', processType = '' } = useSelector((storeState) => storeState.auth?.userAuth);

  const isYesradioVoyaPremier = useSelector(
    (storeState) => storeState?.employeeProfile?.isYesradioVoyaPremier
  );
  let dateOfBirth = useSelector(
    (storeState) => storeState?.employeeProfile?.employeeProfile?.dateOfBirth
  );
  let ssn = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile?.ssn);
  const formDataFromRedux = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValue
  );
  const {
    disableAnswersRadioStatus = false,
    disableAll = null,
    skipProduct = null,
    DependentInformation,
    eligibilityMessages = [],
    CriticalIllness = {},
    CoverageSelection = {},
    HospitalIndemnity = {},
  } = useSelector((storeState) => storeState.insuredInfo) || {};
  const { email = '', newEmail = '', phoneNumber = '', phoneVerificationMethod = '' } = useSelector((storeState) => storeState.registeration);
  const callCenter = useSelector((storeState) => storeState?.callCenter);

  let len = eligibilityMessages.filter((arr) => arr.includes('No')).length;
  let test;
  // useEffect(() => {
  // }, [DependentInformation[0]?.eligibleForCoverage===false]);
  for (let i = 0; i < len.length; i++)
    eligibilityMessages.filter((arr) =>
      arr.slice(0, 1) === String(i) && arr.includes('No') ? (test[i] = 1) : ''
    );
  let temp = eligibilityMessages[0];
  let empNotEligible = temp && temp[0] && temp[0].includes('No');
  // element.name === "continueProductButton" && eligibilityMessages && eligibilityMessages[0] && alert("e : " + len)
  const {
    onClose = () => { },
    // formName = '',
    content,
  } = props;
  const reduxFunctions = {
    UPDATE_DEPENDENT_LIST: UPDATE_DEPENDENT_LIST,
    STORE_IN_REDUX: STORE_IN_REDUX,
    UPDATE_BENEFICIARIES_LIST: UPDATE_BENEFICIARIES_LIST,
    UPDATE_ENROLLMENT_DATA: UPDATE_ENROLLMENT_DATA,
    ADD_BENEFICIARIES_LIST: ADD_BENEFICIARIES_LIST,
    ADD_PET_LIST: ADD_PET_LIST,
    UPDATE_PET_LIST: UPDATE_PET_LIST,
    SET_ADD_DEPENDENT: SET_ADD_DEPENDENT,
  };

  const { radioSelectedValuePetNationData } = useSelector(
    (storeState) => storeState.employeeProfile
  );
  const {
    underWritingQuestionOne,
    underWritingQuestionTwo,
    underWritingQuestionThree,
    underWritingQuestionFour,
  } = useSelector((storeState) => storeState.employeeProfile);

  const radioSelectedValue = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValue
  );
  const radioSelectedValueIDTheft = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueIDTheft
  );
  const radioSelectedValuePetPlus = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValuePetPlus
  );
  const radioSelectedValueLegalCoverage = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueLegalCoverage
  );
  const radioSelectedValuePetAssure = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValuePetAssure
  );
  const radioSelectedValueTravel = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueTravel
  );
  const radioSelectedValueHospitalIndemnity = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueHospitalIndemnity
  );
  const radioSelectedValueCriticalIllness = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueCriticalIllness
  );
  const radioSelectedValueExcessLiability = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiability
  );
  const radioSelectedValueExcessLiabilityUmUim = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiabilityUmUim
  );
  const { isYesradioDisclosureSelected, isYesradioDisclosureSelectedIdTheft, isYesradioDisclosureSelectedCriticalIllness, isYesradioDisclosureSelectedHospitalIndemnity } = useSelector(
    (storeState) => storeState?.registerationError)

  const currentStep = useSelector((storeState) => storeState.stepForm.currentStep);
  const previousStep = useSelector((storeState) => storeState.stepForm.previousStep);
  const [isEdit, setEdit] = useState(false);
  let { counter } = props;
  if (!useConter) counter = editKey;
  let [tempValue, setTempValue] = useState(element?.defaultValue || '');

  if (formik && formik.values && formik.values[formName] === undefined) {
    //   formik.values[formName] = {};
  }
  const dispatch = useDispatch();
  const serviceHelper = new ServiceHelper();
  const [showLoader, setShowLoader] = useState(false);
  // const navigate = useNavigate();
  const history = useHistory();

  const [isShown, setIsShown] = useState(false);
  const isCheckbox =
    useSelector((storeState) => storeState.disableButton[currentStep]?.isCheckbox) || false;

  //To navigate to next screen
  // nextPath(path) {
  //   this.props.history.push(path);
  // }

  //To update ehen waive is selected in modal to update in screen
  useEffect(() => {
    if (radioSelectedValuePetNation == 'waive') {
      dispatch(SET_WAIVE_COVERAGE_NATION({ status: true, currentStep: currentStep }));
      let waiveObject = { status: true, waiveType: 'radio', isRadio: true, isCheckbox: true };
      dispatch(CHANGE_BUTTON_WIDTH({ currentStep: currentStep, waiveObject }));
      // dispatch(CHANGE_BUTTON_WIDTH({ currentStep: currentStep }));
      // dispatch(SET_WAIVE_COVERAGE_NATION({ status: true, currentStep: currentStep }));


    }


    if (isYesradioVoyaPremier === false) {
      dispatch(SET_WAIVE_COVERAGE_VOYA({ status: true, currentStep: currentStep }));
      // dispatch(SET_WAIVE_COVERAGE_NATION({ status: true, currentStep: currentStep }));
      // let waiveObject = { status: true, waiveType: 'radio', isRadio: true, isCheckbox: true };
      // dispatch(CHANGE_BUTTON_WIDTH({ currentStep: currentStep, waiveObject }));
      // dispatch(CHANGE_BUTTON_WIDTH({ currentStep: currentStep }));
      // dispatch(SET_WAIVE_COVERAGE_NATION({ status: true, currentStep: currentStep }));
    }
    if (radioSelectedValuePetNation == "1" || radioSelectedValuePetNation == "0") {
      dispatch(SET_WAIVE_COVERAGE_NATION({ status: false, currentStep: currentStep }));
    }
  }, [radioSelectedValuePetNation]);
  useEffect(() => {
    setCoverageLevelValue(radioSelectedValuePetNationData.coverageLevel);
  }, [radioSelectedValuePetNationData]);
  useEffect(() => {
    setCostPerPayPeriodValue(radioSelectedValuePetNationData.costPerPayPeriod);
  }, [radioSelectedValuePetNationData]);
  let apiCall = () => { };

  let disabled = false;
  let dynamicButtons = [];
  let loggedUserEntity = useSelector((storeState) => storeState.auth.loggedUserEntity);
  let subscribedcontracts = useSelector(
    (storeState) => storeState.entities.response.onLoadResponseList.subscribedcontracts
  );
  let initNewEnrollmentProcess = useSelector(
    (storeState) => storeState.entities.response.onLoadResponseList.initNewEnrollmentProcess
  );

  let MFAAddEmployeeData = useSelector(
    (storeState) => storeState.entities.response.onLoadResponseList.MFAAddEmployeeData
  );

  let formikFromRedux = useSelector((storeState) => storeState.hideShowRow.dependentAddress);
  //let currentReduxState = useSelector((storeState) => storeState);
  let callCenterRedux = useSelector((storeState) => storeState.callCenter);
  let processRedux = useSelector((storeState) => storeState.process);
  let entitiesRedux = useSelector((storeState) => storeState.entities);
  let enrollmentRedux = useSelector((storeState) => storeState.enrollment);
  let authRedux = useSelector((storeState) => storeState.auth);
  let currentReduxState = {callCenter: callCenterRedux, process: processRedux, entities: entitiesRedux, enrollment: enrollmentRedux, auth: authRedux}
  const employeeProfile = useSelector((storeState) => storeState.employeeProfile.employeeProfile);
  const { legalEntityId = '', appCode = '', basicAuthorizationValue = '', token = '', login = '', password = '', url = '', preferredChannel='', tempLoginOtpMethod='' ,
          loginOtpMethod='',loginOtpMethodRetrieveUser=''} = useSelector((storeState) => storeState.registeration);
  // Validation for multiple form inside of a modal, in this case for CriticalIllness
  let hasErrors = !isEmpty(formik && formik.errors && formik?.errors[formName]);
  if (formName === 'CriticalIllness' && props.elementId === 'CriticalIllness.save') {
    if (excludeValidationElements.length > 0) {
      const formErrors = (formik && formik.errors && formik.errors[formName]) || {};
      const hasFormErrors = excludeValidationElements.some(
        (validationElement) => formErrors[validationElement.key]
      );
      hasErrors = hasFormErrors;
    }
  }

  //Validation to reset formik in modal petNation
  if (formName === 'PetAssureNation' && props.elementId === 'PetAssureNation.petThirdSave') {
    // if (
    //   underWritingQuestionOne == false &&
    //   underWritingQuestionTwo == false &&
    //   underWritingQuestionThree == false &&
    //   underWritingQuestionFour == false
    // ) {
    //   // const formErrors = {};
    //   // const hasFormErrors = excludeValidationElements.some(
    //   //   (validationElement) => formErrors[validationElement.key]
    //   // );
    //   hasErrors = false;
    // }
    if (underWritingQuestionOne == true || underWritingQuestionOne == false) {
      hasErrors = false;
    }
    if (underWritingQuestionTwo == false) {
      hasErrors = false;
    } else if (
      underWritingQuestionTwo == true &&
      formik?.values?.PetAssureNation?.policyNumber == ''
    ) {
      hasErrors = true;
    } else if (
      underWritingQuestionThree == true &&
      formik?.values?.PetAssureNation?.dateAndReason == ''
    ) {
      hasErrors = true;
    } else if (
      underWritingQuestionFour == true &&
      formik?.values?.PetAssureNation?.medicationDetails == ''
    ) {
      hasErrors = true;
    }
    let linearCheckValue;
    if (currentStep == "PetAssureNation") {
      linearCheckValue = validateSpecialCharacters(formik);
    }
    if (
      formik?.values?.PetAssureNation?.petHospital == 'Not one of these' &&
      (formik?.values?.PetAssureNation?.hospitalName == '' ||
        formik?.values?.PetAssureNation?.city == '' ||
        formik?.values?.PetAssureNation?.state == '' || !linearCheckValue)
    ) {
      hasErrors = true;
    }
    if (formik?.values?.PetAssureNation?.petHospital == 'Please Select One') {
      hasErrors = true;
    }
    // if (underWritingQuestionThree == false) {
    //   hasErrors = false;
    // } else if (underWritingQuestionThree == true && val is not empty) {
    //   hasErrors = false;
    // }
    // if (underWritingQuestionFour == false) {
    //   hasErrors = false;
    // } else if (underWritingQuestionFour == true && val is not empty) {
    //   hasErrors = false;
    // }
  }
  let handleChange = (e) => {
    if (!formik) setTempValue(e.target.value);
    if (!isMultiple && typeof props.setSkipAndGo == 'function') {
      props.setSkipAndGo((prevSkipAndGo) => {
        if (!prevSkipAndGo.includes(formName)) {
          prevSkipAndGo.push(formName);
          dispatch(validationTriggered());
        }
        return prevSkipAndGo;
      });
    }
    if (element.skipStepsValue) {
      props.setSkipSteps((prevSkipSteps) => {
        if (e.target.value == element.skipStepsValue) {
          if (prevSkipSteps.indexOf(props.currentStep + 2) < 0)
            prevSkipSteps.push(props.currentStep + 2);

          if (prevSkipSteps.indexOf(props.currentStep + 1) > -1) {
            prevSkipSteps.splice(prevSkipSteps.indexOf(props.currentStep + 1), 1);
          }
        } else {
          if (!prevSkipSteps.includes(props.currentStep + 1))
            prevSkipSteps.push(props.currentStep + 1);

          if (prevSkipSteps.indexOf(props.currentStep + 2) > -1) {
            prevSkipSteps.splice(prevSkipSteps.indexOf(props.currentStep + 2), 1);
          }
        }
        return prevSkipSteps;
      });
    }
    if (props.innerForm) dispatch(handleFormChange(props.parentForm, formik));

    if (element.manipulationServiceConfig) {
      const { manipulationServiceConfig } = element || {};
      let srcValue = formik.values[formName][manipulationServiceConfig.manipulationSRCA] || 0;
      let desValue = parseFloat(
        e.target.value.replace(/,/g, '.').replace(/\s/g, '').replace(/€/g, '')
      );
      srcValue = parseFloat(srcValue).toFixed(2);
      let manipulatedValue = ManipulationService.operatorSelector(
        srcValue,
        desValue,
        manipulationServiceConfig.manipulationFunction
      );
      if (isNaN(manipulatedValue)) {
        manipulatedValue = ``;
      } else {
        if (manipulatedValue <= 0) manipulatedValue = '0';
        if (manipulatedValue == 0) manipulatedValue = '0';
      }
      formik.handleChange(e);
    }
  };

  let handleBlur = (event, element, formik) => {
    if (element.dataMapping) {
      let value = event.target.value;
      if (value) {
        let subSegment = formik.values;
        element.dataMapping.controlObjPath &&
          element.dataMapping.controlObjPath.split('.').forEach((name) => {
            if (name) {
              if (subSegment[name]) {
                subSegment = subSegment[name];
              }
            }
          });
        if (element.dataMapping.mappingComtrol && element.dataMapping.mappingData && subSegment) {
          let mapData;
          mapData = element.dataMapping.mappingData.find((dataObj) => {
            return dataObj[element.dataMapping.sourceControl] == value;
          });
          subSegment[element.dataMapping.mappingComtrol] = mapData
            ? mapData[element.dataMapping.mappingComtrol]
            : '';
        }

        formik.setValues({
          ...formik.values,
          ...subSegment,
        });
      }
    }
  };

  const { availableLanguages, currentLanguage } = useSelector((storeState) => storeState.language);
  var availableLanguagesMobile = [];
  const handleLanguageChange = (event) => {
    dispatch(CHANGE_LANGUAGE(event.target.value));
    http.setLanguage(event.target.value);
    defaultConstants.forEach((element) => {
      dispatch(callConstants(element));
    });
  };

  //Get the available themes from store from which is in config file
  // const { availableThemes, currentTheme } = useSelector((storeState) => storeState.theme);

  //Get the available themes from store from which is in config file
  // const { availableThemes, currentTheme } = useSelector((storeState) => storeState.theme);
  let { availableThemes, currentTheme } = useSelector((storeState) => storeState.theme);
  if (availableThemes.length == 0) {
    //Getting available themes from confi if server is not connected
    availableThemes = appConfig.theme.availableThemes;
  } else {
    //Calling the util function to validate the structure of data
    availableThemes = dataValidation(availableThemes);
  }

  let getAllThemes = useSelector((storeState) => storeState?.theme.selectedThemeData);
  if (Object.keys(getAllThemes).length === 0) {
    getAllThemes = defaultTheme;
  }
  //Handle theme Change
  const handleThemeChange = (event) => {
    dispatch(CHANGE_THEME(event.target.value));
  };

  //Handle update theme
  const handleUpdateThemeChange = (event) => {
    dispatch(UPDATE_THEME(event.target.value));
  };

  //Handle configure theme
  const handleConfigureThemeChange = (event) => {
    dispatch(CONFIGURE_THEME(event.target.value));
  };
  //Handle temporary scenarios - insured section
  const handleScenarioChange = (event) => {
    //dispatch(CONFIGURE_THEME(event.target.value));
  };

  //Handle Sign out
  const handleSignOut = (e, element) => {
    $('#userLogout').modal('show');
  };

  const handleAdminEnrollment = (e, element) => {
    $('#adminEnrollment').modal('show');
  };
  const handleRestUsernamePassword = (e, element) => {
    $('#restUsernamePassword').modal('show');
  };
  const handleRetrirveUsername = (e, element) => {
    $('#userName').modal('show');
  };


  const handleCollapse = (e, element) => {
    $('.collapse').collapse('show');
  };

  //Handle api call
  const onLoadApiCall = (element) => {
    let onLoadCalls = element.onLoadApiCall;
    let data = dispatch(
      APIRequestSequential2(onLoadCalls, {
        data: formik.values,
        loggedUserEntity,
        subscribedcontracts,
        initNewEnrollmentProcess,
      })
    );
  };

  //Handle click to show and hide div
  const handleClick = (event) => {
    // toggle shown state
    setIsShown((current) => !current);

    // or simply set it to true
    // setIsShown(true);
  };

  if (element?.disabled && formik) {
    /* To apply element disabled property in single and multi form*/
    if (isMultiple) {
      if (element.config && element.config.disableValue) {
        if (formik.values[formName][counter][element.disabled] === element.config.disableValue) {
          disabled = true;
        }
      } else {
        if (!formik.values[formName][counter][element.disabled]) {
          disabled = true;
        }
      }
    } else {
      if (element.config && element.config.disableValue) {
        if (formik.values[formName][element.disabled] == element.config.disableValue) {
          disabled = true;
        }
      } else {
        if (!formik.values[formName][element.disabled]) {
          disabled = true;
        }
      }
    }
  }

  if (element?.getDynamicButtons) {
    let buttons = element.buttons || [];
    dynamicButtons = buttons.map((curButton, index) => {
      return { ...curButton, ...{ value: props.value || '' } };
    });
  }

  if (element?.displayConfigInfo) {
    if (
      element.displayConfigInfo.field &&
      element.displayConfigInfo.takenFrom &&
      result[element.displayConfigInfo.takenFrom] &&
      result[element.displayConfigInfo.takenFrom][props.mulitpleIndex] &&
      result[element.displayConfigInfo.takenFrom][props.mulitpleIndex][
      element.displayConfigInfo.field
      ] != (formik && formik.values[formName][element.displayConfigInfo.field])
    ) {
      return '';
    }
  }

  const elementorSwitch = () => {
    /* function to select which element to render*/
    let elementName = '';
    let elementValue;
    let elementId = '';
    let isRequiredValidation = '';

    if (props.innerForm && isMultiple) {
      elementName = `${formName}[${innerFormName}][${counter}].${element.name}`;
      elementValue =
        formik &&
        formik.values &&
        formik.values[formName] &&
        formik.values[formName][innerFormName] &&
        formik.values[formName][innerFormName][counter] &&
        formik.values[formName][innerFormName][counter][element.name];

      isRequiredValidation =
        formik &&
          formik.errors &&
          formik.errors[formName] &&
          formik.errors[formName][innerFormName] &&
          formik.errors[formName][innerFormName][counter] &&
          formik.errors[formName][innerFormName][counter][element.name]
          ? true
          : false;
      elementId = elementName;
    } else if (props.innerForm) {
      elementName = `${formName}[${innerFormName}].${element.name}`;
      elementValue =
        formik &&
        formik.values &&
        formik.values[formName] &&
        formik.values[formName][innerFormName] &&
        formik.values[formName][innerFormName][element.name];
      isRequiredValidation =
        formik &&
          formik.errors &&
          formik.errors[formName] &&
          formik.errors[formName][innerFormName] &&
          formik.errors[formName][innerFormName][element.name]
          ? true
          : false;
      elementId = elementName;
    } else {
      elementName = isMultiple
        ? `${formName}[${counter}].${element.name}`
        : `${formName}.${element.name}`;
      elementValue = isMultiple
        ? formik &&
        formik.values &&
        formik.values[formName] &&
        formik.values[formName][counter] &&
        formik.values[formName][counter][element.name]
        : formik &&
        formik.values &&
        formik.values[formName] &&
        formik.values[formName][element.name];

      elementId = isMultiple
        ? `${formName}[${counter}].${element.name}`
        : `${formName}.${element.name}`;
      isRequiredValidation = isMultiple
        ? formik &&
          formik.errors &&
          formik.errors[formName] &&
          formik.errors[formName][counter] &&
          formik.errors[formName][counter][element.name]
          ? true
          : false
        : formik &&
          formik.errors &&
          formik.errors[formName] &&
          formik.errors[formName][element.name]
          ? true
          : false;
    }

    if (element.valueConfig) {
      if (props.result && Object.keys(props.result).length > 0) {
        if (props.resultIndex >= 0 && props.parentElementName) {
          if (props.result[props.parentElementName]) {
            let sourceElements = element.valueConfig.source.map((elementNameFrArray) => {
              return props.result[props.parentElementName][props.resultIndex]
                ? props.result[props.parentElementName][props.resultIndex][elementNameFrArray]
                : '';
            });
            elementValue = serviceHelper.getSatusUsingDate(sourceElements, element) || '';
          }
        }
      }
    }

    const keyDate = new Date().getTime();
    switch (element.type) {
 

      case 'currency':
        return (
          <Currency
            key={elementId}
            id={elementId}
            type={element.type}
            label={element.label}
            name={elementId}
            value={elementValue}
            requiredValidation={isRequiredValidation}
            disabled={disabled}
            onChange={(e) => {
              if (formik) {
                formik.handleChange(e);
              }
              handleChange(e);
            }}
            onBlur={formik && formik.handleBlur}
            locale={element.locale}
            currency={element.currency}
            decimal={element.decimal}
          />
        );
      case 'panelSideHeader':
        return (
          <PanelSideHeader
            label={element.label}
            formSideHeader={element.formSideHeader}
            formSideBigHeader={element.formSideBigHeader}
            formMandatory={element.formMandatory}
            formSideHeaderContact={element.formSideHeaderContact}
            formSideHeaderWithImage={element.formSideHeaderWithImage}
            mandatory={element.mandatory}
            filename={element.filename}
            hideDefaultClass={element.hideDefaultClass}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            imgStyle={element.imgStyle}
            forRegisteration={element.forRegisteration}
            config={element.config}
            removePading={element.removePading}
          >
            {' '}
          </PanelSideHeader>
        );
      case 'productTable':
        return (
          <ProductTable
            elements={element.elements}
            id={element.id}
            value={element.value}
            {...props}
          />
        );
      case 'manageBeneficiariesTable':
        return (
          <ManageBeneficiariesTable
            elements={element.elements}
            id={element.id}
            value={element.value}
            {...props}
          />
        );

  

      case 'iFrameGenerator':
        return (
          <IFrameGenerator
            elements={element.elements}
            id={element.id}
            value={element.value}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            src={element.src}
            width={element.width}
            height={element.height}
            shouldShowOnYes={element.shouldShowOnYes}
            {...props}
          />
        );

      case 'searchClientList':
        return (
          <SearchClientList
            elements={element.elements}
            id={element.id}
            value={element.value}
            src={element.src}
            {...props}
          />
        );
      case 'searchEmployeeList':
        return (
          <SearchEmployeeList
            elements={element.elements}
            id={element.id}
            value={element.value}
            src={element.src}
            {...props}
          />
        );
      case 'employeeData':
        return (
          <EmployeeData
            elements={element.elements}
            id={element.id}
            value={element.value}
            {...props}
          />
        );
      case 'readOnly':
        return (
          <ReadOnly
            key={element.name}
            label={element.labelTitle}
            image={element.image}
            imageAlt={element.imageAlt}
            imageContent={element.imageContent}
            imageAfter={element.imageAfter}
            // value={!element.appendDataFromRedux ? element.value : element.value + `${user_name}`}
            value={!element.appendDataFromRedux ? element.value : element.value + `${callCenter.email}`}
            display={element.display}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            hidden={element.hidden}
            name={element.name}
            hideLabel={element.hideLabel}
            id={element.id}
            url={element.url}
            path={element.path}
            title={element.title}
            config={element.config}
            containerClassName={element.containerClassName}
            imageAdditionalClass={element.imageAdditionalClass}
            shouldShowOnYes={element.shouldShowOnYes}
            readOnlyClassWrapper={element.readOnlyClassWrapper}
            imageClassWrapper={element.imageClassWrapper}
            modalPopUp={element.modalPopUp}
            modalName={element.modalName}
            editable={element.editable}
            style={element.style}
            showContent={showContent}
            displayContent={displayContent}
            showOnAPISuccess={element.showOnAPISuccess}
            displayAPISuccess={element.displayAPISuccess}
            getContentFromAPI={element.getContentFromAPI}
            isUmUimCoverageTable={element.isUmUimCoverageTable}
            hideOnEmailExist={element.hideOnEmailExist}
            hideOnPhoneVerfied={element.hideOnPhoneVerfied}
            changeLabelOnValidate={element.changeLabelOnValidate}
            showOnModalOnly={element.showOnModalOnly}
            hideOnSubmit={element.hideOnSubmit}
            hideOnClickSendCodeBtn={element.hideOnClickSendCodeBtn}
            hideOnWrongOTP={element.hideOnWrongOTP}
            {...props}
          >
            {' '}
          </ReadOnly>
        );

      case 'tile':
        return (
          <Tile
            {...props}
            style={element.config && element.config.style}
            image={element.image}
            // hidden={element.hidden}
            hidden={
              serviceHelper.hideElement(element, formik, formName)
                ? serviceHelper.hideElement(element, formik, formName)
                : element.hidden
            }
            value={element.value ? element.value : elementValue ? elementValue : props.value}
            className={element.className}
            config={element.config}
          >
            {' '}
          </Tile>
        );

      case 'panelCard':
        return (
          <PanelCard
            {...props}
            key={element.style}
            style={element.style}
            image={element.image}
            className={element.className}
            showContent={showContent}
            id={element.id}
          >
            {' '}
          </PanelCard>
        );


      case 'label':
        return (
          <Label
            key={element.name}
            style={element.style}
            image={element.image}
            value={element.value}
            hidden={
              serviceHelper.hideElement(element, formik, formName)
                ? serviceHelper.hideElement(element, formik, formName)
                : element.hidden
            }
            className={element.className}
            {...props}
          >
            {' '}
          </Label>
        );
    
      case 'textarea':
        return (
          <TextArea
            {...props}
            key={elementId}
            id={elementId}
            type={element.type}
            label={element.label}
            name={elementId}
            placeholder={element.placeholder}
            noFloatingLabel={element.noFloatingLabel}
            shouldShowOnYes={element.shouldShowOnYes}
            element={element}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            disabled={disabled ? disabled : element.disabled}
            rows={element.rows}
            // value={elementValue}
            value={element.value ? element.value : elementValue ? elementValue : props.value}
            requiredValidation={isRequiredValidation}
            onChange={(e) => {
              if (formik && formik.handleChange) {

                formik.handleChange(e);
              }
              handleChange(e);
            }}
            onBlur={(e) => {
              handleBlur(e, element, formik);
              if (formik && formik.handleBlur) {
                formik.handleBlur(e);
              }
            }}
            hidden={element.hidden}
          ></TextArea>
        );

      case 'section':
        return (
          <Section
            {...props}
            key={elementId}
            id={elementId}
            type={element.type}
            label={element.label}
            style={element.style}
            element={element}
            name={elementId}
            divScroll={element.divScroll}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            link={element.link}
            linkText={element.linkText}
            disabled={disabled ? disabled : element.disabled}
            scroll={element.scroll}
            rows={element.rows}
            currentStep={currentStep}
            // value={elementValue}
            value={element.value ? element.value : elementValue ? elementValue : props.value}
            requiredValidation={isRequiredValidation}
            onChange={(e) => {
              if (formik && formik.handleChange) {
                formik.handleChange(e);
              }
              handleChange(e);
            }}
            onBlur={(e) => {
              handleBlur(e, element, formik);
              if (formik && formik.handleBlur) {
                formik.handleBlur(e);
              }
            }}
            hidden={element.hidden}
          ></Section>
        );




      case 'select':
        if (formName === 'DependentInformation' && formik && formik.values && formik.values[formName] && formik.values[formName]['editDependentBUID']) {
          isEditModal = true;
          if (errorStatusObj.hasOwnProperty(element.name)) {
            if (!elementValue) {
              hasEditError = true;
            }
          }
        }
        return (
          <Select
            isEditModal={isEditModal}
            hasEditError={hasEditError}
            key={elementName}
            id={elementName}
            name={element.name}
            label={element.label}
            multipleSelect={element.multipleSelect}
            disabled={disabled}
            dataFromRedux={element.dataFromRedux}
            classNameWrapper={element.classNameWrapper}
            shouldShowOnYes={element.shouldShowOnYes}
            requiredVal={
              element?.validations?.string?.required ||
              element?.validations?.string?.validationRequiredOnTrue
            }
            className={element.className}
            defaultValue={
              element.defaultValue
                ? element.defaultValue
                : // : element.optionKind &&
                //   divisionOptions[element.optionKind] &&
                //   divisionOptions[element.optionKind].options
                // ? divisionOptions[element.optionKind].options[0].value
                ''
            }
            selectedValue={elementValue ? elementValue : tempValue}
            onChange={(e) => {
              const currentValue = e.target.value;
              if (formName === 'DependentInformation' && formik && formik.values && formik.values[formName] && formik.values[formName]['editDependentBUID']) {
                if (errorStatusObj.hasOwnProperty(element.name)) {
                  if (!currentValue) {
                    errorStatusObj[element.name] = true;
                  } else {
                    errorStatusObj[element.name] = false;
                  }
                }
                formik.setStatus({ [formName]: errorStatusObj });
              }
              if (e.target.value == 'cat') {
                //dispatch action here
                dispatch(SELECTED_PET('cat'));
              }
              if (e.target.value == 'dog') {
                //dispatch action here
                // handleLanguageChange(e);
                dispatch(SELECTED_PET('dog'));
              }
              if (element.selectType === 'language') {
                handleLanguageChange(e);
              }
              if (element.selectType === 'theme') {
                handleThemeChange(e);
              }
              if (element.selectType === 'updateTheme') {
                handleUpdateThemeChange(e);
              }
              if (element.selectType === 'configureTheme') {
                handleConfigureThemeChange(e);
              }
              if (formik) {
                formik.handleChange(e);
              }
              if (element.populateDataConfig) {
                serviceHelper.setFormDataMapping(
                  elements,
                  formik,
                  formName,
                  counter,
                  e.target.value
                );
              }

              //serviceHelper.handleChange(props, formName, isMultiple);
              handleChange(e);
              if (element.parentOnChange) {
                props.parentOnChange(e);
              }
            }}
            onBlur={formik && formik.handleBlur}
            requiredValidation={isRequiredValidation}
            options={
              element.populateDataConfig
                ? serviceHelper.generateDataList(formik, element.populateDataConfig)
                : element.options
                  ? element.options
                  : constantsList[element.optionsname]
                    ? constantsList[element.optionsname]
                    : // : element.optionKind &&
                    //   divisionOptions[element.optionKind] &&
                    //   divisionOptions[element.optionKind].options
                    // ? divisionOptions[element.optionKind].options
                    element.selectType === 'language'
                      ? availableLanguages
                      : element.selectType === 'theme'
                        ? availableThemes
                        : element.selectType === 'updateTheme'
                          ? availableThemes
                          : element.selectType === 'configureTheme'
                            ? availableThemes
                            : []
            }
            {...props}
            value={elementValue ? elementValue : props.value ? props.value : tempValue}
            currentStep={currentStep}
          />
        );
   
      case 'radioButton':
        return (
          <RadioButton
            {...props}
            key={element.name}
            id={element.name}
            name={elementId}
            value={elementValue}
            defaultValue={element.defaultValue}
            onChange={(e) => {
              if (formik) {
                formik.handleChange(e);
              }
              //serviceHelper.handleChange(props, formName, isMultiple);
              handleChange(e);
            }}
            onBlur={formik && formik.handleBlur}
            requiredValidation={isRequiredValidation}
            inputs={element.inputs}
            isRadio={element.isRadio}
            buttons={dynamicButtons}
          />
        );

      case 'radioButtonGroup':
        return (
          <RadioButtonGroup
            key={keyDate}
            id={element.name}
            element={element}
            radioLabel={element.label}
            style={element.style}
            columnLayout={element.columnLayout}
            sameLine={element.sameLine}
            required={
              element?.validations?.string?.required ||
              element?.validations?.string?.validationRequiredOnTrue
            }
            containerClass={element.containerClass}
            labelClassName={element.labelClassName}
            name={elementId}
            value={elementValue}
            radioClassWrapper={element.radioClassWrapper}
            hidden={element.hidden}
            defaultValue={element.defaultValue}
            onChange={(e) => {
              if (formik) {

                formik.handleChange(e);
                //Update options
                serviceHelper.updateReadOnly(e.target.value, element, formik, formName, props);
                formik.handleChange(e);
              }
            }}
            onBlur={formik && formik.handleBlur}
            requiredValidation={isRequiredValidation}
            inputs={element.inputs}
            shouldShowOnYes={element.shouldShowOnYes}
            isRadio={element.isRadio}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            radioAdditionalClasses={element.radioAdditionalClasses}
            forRegisteration={element.forRegisteration}
            noNeedAstrik={element.noNeedAstrik}
            currentStep={currentStep}
            isAnswerRadio={element.isAnswerRadio}
            isCoverageSelectionRadio={element.isCoverageSelectionRadio}
            dispatchOnClick={element.dispatchOnClick}
            hideOnClickSendCodeBtn={element.hideOnClickSendCodeBtn}
            needReset={element.needReset}
          />
        );


      case 'button':
        // Validation for edit Modals
        let containsEditErrors = false;
        if (formName === 'DependentInformation' && formik && formik.values && formik.values[formName] && formik.values[formName]['editDependentBUID']) {
          if (element.id === 'saveDependent') {
            isEditModal = true;
            containsEditErrors = Object.keys(errorStatusObj).some(error => errorStatusObj[error]);
          }
        }
        let disableButtonToContinue = false;
        // Validation to handle Errors
        if ( props?.element?.errorHandling) { 
          disableButtonToContinue = getErrorMessages(props?.element?.productPage);
        }

        return (
          <Button
            result={props.result || {}}
            callback={props.parentAction || ''}
            config={element.config || {}}
            mulitpleIndex={props.mulitpleIndex || ''}
            key={element.name}
            id={element.id ? element.id : element.name}
            hidden={serviceHelper.hideOnloadElements(formik, props, element)}
            icon={element.icon}
            modalPopUp={element.modalPopUp}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            className={element.className}
            outline={element.outline}
            contrast={element.contrast}
            data-dismiss={element['data-dismiss']}
            name={elementId}
            disabled={isEditModal ? containsEditErrors : (element.buttonType && hasErrors) || element.disabled || disableButtonToContinue}
            modalName={element.modalName}
            formik={formik}
            formName={formName}
            expression={element.expression}
            link={element.link}
            shouldCallTimer={element.shouldCallTimer}
            timer={element.timer}
            backButton={true}
            iconButton={element.iconButton}
            validationElements={element.validationElements}
            buttonPurpose={element.buttonPurpose}
            onClickApiCall={element.onClickApiCall}
            shouldHideOnEdit={element.shouldHideOnEdit}
            shouldSetAnswersRadio={element.shouldSetAnswersRadio}
            mfaButton={element.mfaButton}
            resetTimerButton={element.resetTimerButton}
            labelConfig={element.labelConfig}
            showOnModalOnly={element.showOnModalOnly}
            hideOnClickSendCodeBtn={element.hideOnClickSendCodeBtn}
            removePadding={element.removePadding}
            onClick={
              (e, notEligible, waive) => {
                onClickTimer && onClickTimer()

                if (element.name == "continueProductButton" && isYesradioVoyaPremier == true && currentStep == 'WLCoverage') {
                  const resp = http
                    .post(appConfig.SSP_MID_URL + '/getVoyaContent', { dateOfBirth: dateOfBirth, ssn: ssn })
                    .then((resp) => {
                      if (resp.status == 200) {
                        // window.location ="https://www.EnrollVB.com/BayStateHealth?employeeId=1234567898&dob=01%2F13%2F1965";
                        dispatch(VOYA_API_RESPONSE(resp));
                      }

                      setShowLoader(false);
                    }).catch((error) => {
                      

                    });
                }


                if (
                  element.name == 'stepperModalPet'


                ) {
                  dispatch(RADIO_SELECTED_VALUE_PET_NATION(""))
                }
                if (
                  element.name == 'continueProductButton' &&

                  currentStep == 'PetAssureNation'
                ) {
                  props.formik.errors = {};
                }

                if (element.name == 'petFirstSave') {
                  dispatch(MODAL_STEP({ modalStep: 1 }));
                  setStepModal(modalStep);
                }

                if (element.name == 'petSecondSave') {
                  dispatch(MODAL_STEP({ modalStep: 2 }));
                  setStepModal(modalStep);
                }

                if (element.name == 'petSecondSave' && radioSelectedValuePetNation == 'waive') {
                  $(`#${element.modalName1}`).modal('toggle');
                  dispatch(DELETE_FULL_PET_LIST());
                  // dispatch(UPDATE_CART_ITEM([]))
                  setTimeout(() => { dispatch(UPDATE_CART_ITEM([])); }, 1000);
                  dispatch(MODAL_STEP({ modalStep: 0 }));
                  setStepModal(modalStep);
                  // props.formik.values.PetAssureNation.petName=""
                  props.formik.setFieldValue(
                    `${props.formName}.petName`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.petAge`,
                    ""
                  );

                  props.formik.setFieldValue(
                    `${props.formName}.underwritingQuestionOne`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.underwritingQuestionTwo`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.underwritingQuestionThree`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.underwritingQuestionFour`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.policyNumber`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.dateAndReason`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.medicationDetails`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.city`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.state`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.petType`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.petBreed`,
                    ""
                  );
                  props.formik.setFieldValue(
                    `${props.formName}.petHospital`,
                    ""
                  );
                }
                if (element.name == 'petFirstCancel') {
                  dispatch(MODAL_STEP({ modalStep: 0 }));
                  setStepModal(modalStep);
                }

                if (element.name == 'petSecondCancel') {
                  dispatch(MODAL_STEP({ modalStep: 0 }));
                  setStepModal(modalStep);
                }

                if (element.name == 'petThirdCancel') {
                  // dispatch(UNDER_WRITING_QUESTION_ONE(false));
                  dispatch(MODAL_STEP({ modalStep: 0 }));
                  setStepModal(modalStep);
                  dispatch(UNDER_WRITING_QUESTION_TWO(""));
                  dispatch(UNDER_WRITING_QUESTION_THREE(""));
                  dispatch(UNDER_WRITING_QUESTION_FOUR(""));
                  dispatch(HOSPITAL_SECTION(false));
                }

                if (element.name == 'petSecondBack') {
                  dispatch(MODAL_STEP({ modalStep: 0 }));
                  setStepModal(modalStep);
                }

                if (element.name == 'petThirdBack') {
                  dispatch(MODAL_STEP({ modalStep: 1 }));
                  setStepModal(modalStep);
                }

                if (element.name == 'petThirdSave') {
                  dispatch(MODAL_STEP({ modalStep: 0 }));
                  onClose(element.modalName, formName, formik);
                  // props.formik.resetForm();

                  setStepModal(modalStep);
                  dispatch(UNDER_WRITING_QUESTION_TWO(false));
                  dispatch(UNDER_WRITING_QUESTION_THREE(false));
                  dispatch(UNDER_WRITING_QUESTION_FOUR(false));
                  dispatch(HOSPITAL_SECTION(false));

                  formik.setFieldValue(
                    `${formName}.petTableCoverage`,
                    radioSelectedValuePetNationData.coverageLevel
                  );

                  dispatch(MODAL_STEP({ modalStep: 0 }));
                  setStepModal(modalStep);
                  // dispatch(
                  //   UPDATE_CART_ITEM({
                  //     id: props.currentStep,
                  //     moreThanOne: true,
                  //     name: props.formData?.accidentInsurance,
                  //     radioSelectedValue: 0,
                  //     coverageList: petList,
                  //   })
                  // );
                }
                if (element.name == 'backProductButton') {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));

                  let skipSteps;
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goBack(props.skipSteps);
                  }

                }

                if (element.name == 'continueFormButton') {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props?.contentData?.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                if (
                  (element.name == 'continueTableCoverageButton' &&
                    radioSelectedValue == 'Waive' &&
                    currentStep === 'CoverageSelection') ||
                  (element.name === 'continueProductButton' && notEligible && !waive)
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                if (
                  (element.name == 'continueProductButton' &&
                    CoverageSelection.waiveInsureCoverage === true &&
                    currentStep === 'CoverageSelection')
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                if (
                  (element.name == 'continueTableCoverageButton' &&
                    radioSelectedValueHospitalIndemnity == 'Waive' &&
                    currentStep === 'HospitalIndemnity')
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                if (
                  (element.name == 'continueProductButton' &&
                    HospitalIndemnity.waiveInsureCoverage === true &&
                    currentStep === 'HospitalIndemnity')
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                if (
                  element.name == 'continueTableCoverageButton' &&
                  radioSelectedValueIDTheft == 'Waive' &&
                  currentStep == 'IdTheft'
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                if (
                  element.name == 'continueTableCoverageButton' &&
                  (radioSelectedValueLegalCoverage == 'Waive' ||
                    radioSelectedValueLegalCoverage >= 0) &&
                  currentStep == 'LegalCoverage'
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }
                //check this step
                // if (
                //   element.name == 'continueTableCoverageButton' &&
                //   radioSelectedValueTravel == 'Waive' &&
                if (
                  element.name == 'continueTableCoverageButton' &&
                  (radioSelectedValuePetAssure == 'Waive' || radioSelectedValuePetAssure >= 0) &&
                  currentStep == 'PetAssure'
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }
                if (
                  element.name == 'continueTableCoverageButton' &&
                  radioSelectedValueTravel == 'Waive' &&
                  currentStep == 'Travel'
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                if (
                  element.name == 'continueTableCoverageButton' &&
                  (radioSelectedValuePetPlus == 'Waive' || radioSelectedValuePetPlus >= 0) &&
                  currentStep == 'PetPlus'
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }
                if (
                  element.name == 'continueTableCoverageButton' &&
                  (radioSelectedValueExcessLiability == 'Waive' //|| 
                    //radioSelectedValueExcessLiabilityUmUim == 'Waive' || 
                    // radioSelectedValueExcessLiabilityUmUim >= 0
                    // radioSelectedValueExcessLiability >= 0

                  ) &&
                  currentStep == 'ExcessLiability'
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }
                if (
                  (element.name == 'continueTableCoverageButton' &&
                    radioSelectedValueCriticalIllness == 'Waive' &&
                    currentStep == 'CriticalIllness')
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                if (element.name === 'continueProductButton' && currentStep === 'PetAssureNation') {
                  updateStepperNavigatorInfo(currentStep, dispatch, { petTable: props.petTable })
                }

                if (
                  (element.name == 'continueProductButton' &&
                    CriticalIllness.waiveInsureCoverage === true &&
                    currentStep == 'CriticalIllness')
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                //condition to move to next product when waived Pet Nation
                if (
                  element.name == 'continueProductButton' &&
                  setWaiveCoverageNation == true &&
                  currentStep == 'PetAssureNation'
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                //WholeLife
                if (element.name == 'continueProductButton' && currentStep == 'WLCoverage') {
                  updateStepperNavigatorInfo(currentStep, dispatch, { isYesradioVoyaPremier })
                }
                if (
                  element.name == 'continueProductButton' &&
                  isYesradioVoyaPremier == false &&
                  currentStep == 'WLCoverage'
                ) {
                  dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
                  dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
                  if (previousStep === 'EnrollmentSummary') {
                    dispatch(PREVIOUS_STEP({ previousStep: '' }));
                    props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                  } else {
                    props.contentData.goForward(
                      3,
                      props.content,
                      formik.dirty,
                      formik.errors,
                      formik.setTouched,
                      formik.touched,
                      formik.formValues,
                      props.skipSteps
                    );
                  }
                }

                let disableConfig;
                if (
                  currentStep == 'CoverageSelection' &&
                  (radioSelectedValue == 'Waive' || radioSelectedValue == -1) &&
                  element.name == 'continueTableCoverageButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep == 'IdTheft' &&
                  (radioSelectedValueIDTheft == 'Waive' || radioSelectedValueIDTheft == -1) &&
                  element.name == 'continueTableCoverageButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep == 'LegalCoverage' &&
                  (radioSelectedValueLegalCoverage == 'Waive' ||
                    radioSelectedValueLegalCoverage == -1 ||
                    radioSelectedValueLegalCoverage == '' ||
                    radioSelectedValueLegalCoverage != -1) &&
                  element.name == 'continueTableCoverageButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep == 'Travel' &&
                  (radioSelectedValueTravel == 'Waive' || radioSelectedValueTravel == -1) &&
                  element.name == 'continueTableCoverageButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep == 'CriticalIllness' &&
                  (radioSelectedValueCriticalIllness == 'Waive' ||
                    radioSelectedValueCriticalIllness == -1) &&
                  element.name == 'continueTableCoverageButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep == 'HospitalIndemnity' &&
                  (radioSelectedValueHospitalIndemnity == 'Waive' ||
                    radioSelectedValueHospitalIndemnity == -1) &&
                  element.name == 'continueTableCoverageButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep == 'PetPlus' &&
                  (radioSelectedValuePetPlus == 'Waive' || radioSelectedValuePetPlus == -1) &&
                  element.name == 'continueTableCoverageButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep == 'PetAssure' &&
                  (radioSelectedValuePetAssure == 'Waive' || radioSelectedValuePetAssure == -1) &&
                  element.name == 'continueTableCoverageButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'CoverageSelection' &&
                  element.name === 'continueProductButton' &&
                  (CoverageSelection.waiveInsureCoverage === true || notEligible === true)
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'HospitalIndemnity' &&
                  element.name === 'continueProductButton' &&
                  (HospitalIndemnity.waiveInsureCoverage === true || notEligible === true)
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'CriticalIllness' &&
                  element.name === 'continueProductButton' &&
                  (CriticalIllness.waiveInsureCoverage === true || notEligible === true)
                ) {
                  disableConfig = true;
                } else if (
                  currentStep == 'ExcessLiability' &&
                  (radioSelectedValueExcessLiability == 'Waive' || radioSelectedValueExcessLiability == -1) &&
                  element.name == 'continueTableCoverageButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep == 'ExcessLiability' &&
                  element.name == 'continueFormButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'CriticalIllness' &&
                  element.name === 'continueFormButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'HospitalIndemnity' &&
                  element.name === 'continueFormButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'CoverageSelection' &&
                  element.name === 'continueProductButton' &&
                  notEligible === true
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'HospitalIndemnity' &&
                  element.name === 'continueProductButton' &&
                  notEligible === true
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'CriticalIllness' &&
                  element.name === 'continueProductButton' &&
                  notEligible === true
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'CriticalIllness' &&
                  element.name === 'continueFormButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'HospitalIndemnity' &&
                  element.name === 'continueFormButton'
                ) {
                  disableConfig = true;
                }
                else if (
                  currentStep === 'ExcessLiability' &&
                  element.name === 'continueProductButton' &&
                  notEligible === true
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'ExcessLiability' &&
                  element.name === 'continueFormButton'
                ) {
                  disableConfig = true;
                }
                else if (
                  currentStep === 'PetAssureNation' &&
                  element.name === 'continueProductButton' &&
                  setWaiveCoverageNation == true
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'PetAssureNation' &&
                  element.name === 'continueFormButton'
                ) {
                  disableConfig = true;
                } else if (
                  currentStep === 'ExcessLiability' &&
                  element.name === 'continueFormButton'
                ) {
                  disableConfig = true;
                }

                else if (
                  currentStep === 'WLCoverage' &&
                  element.name === 'continueFormButton'
                ) {
                  disableConfig = true;
                }

                else if (
                  currentStep === 'WLCoverage' &&
                  element.name === 'continueProductButton' && isYesradioVoyaPremier == false
                ) {
                  disableConfig = true;
                }
                //To toggle elements on button click
                if (!disableConfig && element?.displayConfig) {
                  serviceHelper.showToggleElements(formik, props, element);
                }
                //To toggle elements on continue to next product button click
                if (disableConfig) {
                  serviceHelper.showToggleContinueToNetElements(formik, props, element);
                }

                if (element && element.localStore) {
                  if (element && (element.reduxCall || element.reduxEditCall)) {
                    let storeCall = '';
                    if (formik && formik.values && formik.values[formName] && formik.values[formName].editBUID) {
                      dispatch(
                        RADIO_SELECTED_VALUE_PET_NATION_DATA_ARRAY(radioSelectedValuePetNationData)
                      );
                      storeCall = reduxFunctions[element.reduxEditCall] || '';
                    } else {
                      dispatch(EDIT_PET_NATION(false));
                      dispatch(
                        RADIO_SELECTED_VALUE_PET_NATION_DATA_ARRAY(radioSelectedValuePetNationData)
                      );
                      storeCall = reduxFunctions[element.reduxCall] || '';
                    }
                    dispatch(storeCall(formik.values[formName]));
                    let propsValue = formik.values
                    if ((formik && currentStep === "CriticalIllness") &&
                      (element.reduxEditCall === "UPDATE_BENEFICIARIES_LIST" ||
                        element.reduxEditCall === "ADD_BENEFICIARIES_LIST")) {
                      propsValue.CriticalIllness = {
                        ...formik.values[formName],
                        beneficiaryAddressLine1: "",
                        beneficiaryAddressLine2: "",
                        beneficiaryBirthDate: "",
                        beneficiaryCity: "",
                        beneficiaryCollateral: "",
                        beneficiaryCountry: "",
                        beneficiaryFirstName: "",
                        beneficiaryGender: "",
                        beneficiaryInfo: "",
                        beneficiaryIrrevocable: "",
                        beneficiaryLastName: "",
                        beneficiaryMiddleName: "",
                        beneficiaryOptions: "individual",
                        beneficiaryRelationship: "SP",
                        beneficiaryState: "",
                        beneficiaryZipCode: "",
                        SSN: "",
                        mailingAddressOptions: "no",
                        liveInSameAddress: "",
                        beneficiaryBUID: "",
                        editBUID: "",
                        legalName: "",
                      }
                      formik &&
                        formik.setValues({
                          [formName]: {
                            ...formik.values[formName]
                          },
                          ...propsValue,
                        });
                    }
                  }
                }

                //To make api call
                if (element?.apiCallArray) {

                  function setResponsePayloadData(responseData) {
                    //normal api call
                    if (element && element.reduxCall && !element.mfaButton) {
                      const storeCall = reduxFunctions[element.reduxCall] || '';
                      const response = get(responseData, element.reduxData);
                      // should check before commit
                      // const response =  get(responseData, element.reduxData)  ;
                      if (element?.handleNotFoundError) {
                        if (!response?.data || !response || response?.error || response?.message) {
                          dispatch(HAS_ERROR({ hasError: true }));
                          for (let i = 0; i < element?.apiConfig.formikObjectFields.length; i++)
                            dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: true, clearCount: false, form: element?.apiConfig.objectName, field: element?.apiConfig.formikObjectFields[i] }))
                        }
                        else {
                          for (let i = 0; i < element?.apiConfig.formikObjectFields.length; i++)
                            dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: false, clearCount: false, form: element?.apiConfig.objectName, field: element?.apiConfig.formikObjectFields[i] }))
                        }
                      }
                      else {
                        if (response) {
                          dispatch(storeCall(response));
                          if (element && element.reduxStoreActions && Object.keys(element.reduxStoreActions).length > 0) {
                            Object.keys(element.reduxStoreActions).forEach(storeAction => {
                              const extraAction = reduxFunctions && reduxFunctions[storeAction];
                              if (extraAction) {
                                const extraActionValue = element.reduxStoreActions[storeAction];
                                dispatch(extraAction(extraActionValue))
                              }
                            });
                          }
                          dispatch(PREVIOUS_STEP({ previousStep: "" }));
                          if (currentStep === "DependentInformation" && response) {
                            dispatch(DELETE_INSURED_INFO({ stepForm: ["CoverageSelection", "IdTheft"] }));
                          }
                        }
                      }
                    }
                    else if (element && element.reduxCall && element.mfaButton) {
                      //api call on timer-mfa only
                      const response = !element?.mfaButton ? get(responseData, element.reduxData) : responseData;
                      if (element?.handleNotFoundError)
                        if (response?.error || response?.message || !response?.data || !response || response.status !== 200) {
                          dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: true, clearCount: false, form: element?.apiConfig.objectName }))

                          //dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: false, mfaErrorText: response.message,oktaErrorText:'You do not have permission to access the feature you are requesting', showInputErr: true, redirectToLogin: false}));
                          if (response.message === 'Invalid Passcode/Answer' && response.source === 'Okta') {
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: response.message, showInputErr: true, redirectToLogin: false }));
                            if(element.apiConfig.objectName === 'MultiFactorAuthentication' && element.name === "submitButton")
                              dispatch(SHOW_REGISTER_TIMER_PHONE(true))
                          }
                          if (response.message === 'Request failed with status code 403') {
                            // if (response.error?.message === 'User Locked' && response.error?.source === 'Okta' && element.name === 'submitButton' ){
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: 'User Locked', redirectToLogin: true }));
                          }
                          else {
                            // if (response.error?.message === 'User Locked' && response.error?.source === 'Okta' && element.name === 'submitButton' ){

                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: response.message, redirectToLogin: false }));
                            if (response.message === 'Request failed with status code 500')
                              dispatch(SET_EMAIL_EXIST(true))
                            else
                              dispatch(SET_EMAIL_EXIST(false))
                          }
                          dispatch(HAS_ERROR({ hasError: true }));
                          for (let i = 0; i < element?.apiConfig.formikObjectFields.length; i++)
                            dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({
                              hasError: true,
                              clearCount: false,
                              form: element?.apiConfig.objectName,
                              field: element?.apiConfig.formikObjectFields[i],
                              dependentElementsReadOnly: element?.apiConfig.dependentElementsReadOnly,
                              dependentElementsInput: element?.apiConfig.dependentElementsInput
                            }))
                        }
                        else {
                          dispatch(SET_EMAIL_EXIST(false))
                          if (element.name === 'submitButton' && currentStep === 'MultiFactorAuthentication') {
                            dispatch(PHONE_IS_VERIFIED(true))

                            dispatch(ACTIVATE_PHONE(true));
                          }
                          element.name === 'submitButton' && currentStep === 'UserAccountVerifyEmail' && dispatch(EMAIL_VERIFIED(true))

                          for (let i = 0; i < element?.apiConfig.formikObjectFields.length; i++) {
                            dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: false, clearCount: false, form: element?.apiConfig.objectName, field: element?.apiConfig.formikObjectFields[i] }))
                            if (element.name === 'submitButton' && element.reduxData === 'MultiFactorAuthentication')
                              dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: '', oktaErrorText: '', showInputErr: false, redirectToLogin: false }));
                            else
                              dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: '', oktaErrorText: '', showInputErr: 'unknown', redirectToLogin: false }));


                            // dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: false, mfaErrorText: '', redirectToLogin: false}));
                          }
                        }
                    }
                  }
                  const storePayload = (element && element.storePayload) || false;
                  setShowLoader(true);
                  serviceHelper.apiCallArray(
                    formik,
                    props,
                    element,
                    loggedUserEntity,
                    subscribedcontracts,
                    formName,
                    currentReduxState,
                    storePayload,
                    setResponsePayloadData,
                    legalEntityId,
                    appCode,
                    basicAuthorizationValue
                  )
                }
                // other mfa call
                else if (element?.mfaCallArray) {
                  let forContinue = element.name === 'continueButton' &&  (
                    element.objectName === "EmployeeValidationForgotUserID" ||  element.objectName === "MultiFactorAuthenticationLogin"
                    ||element.objectName === 'MFAForgotPassword')
                  
                  let forResend = element.name ==='resendPhoneSecurityCodeButton' && (
                    currentStep ==='MFAForgotUsername' || currentStep ==='MultiFactorAuthenticationLogin' || currentStep === 'MFAForgotPassword' )
                     
                  // let forRetrieveUserIDCancel = (element.name === 'cancelButton' &&  element.objectName === "EmployeeValidationForgotUserID")

                  if (forContinue) {
                    let loginOTPMethod = tempLoginOtpMethod ||loginOtpMethodRetrieveUser|| preferredChannel || 'sms'
                    dispatch(SET_LOGIN_OTP_METHOD_RETRIEVE_USER(loginOTPMethod));
                    dispatch(SET_TEMP_LOGIN_OTP_METHOD(loginOTPMethod));
                  }

                  if (forResend) {
                    let loginOTPMethod = loginOtpMethod || preferredChannel || 'email'
                    dispatch(SET_LOGIN_OTP_METHOD_RETRIEVE_USER(loginOTPMethod));
                  }

                  function setResponsePayloadData(responseData) {
                    if (element && element.reduxCall && element.mfaButton) {
                      const response = responseData;
                      if (response.success) {
                        dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: '', oktaErrorText: '', showInputErr: true, redirectToLogin: false,oktaReachLimitText:'' }));
                        dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: false, clearCount: false, form: element?.apiConfig.objectName, hideOtpAndSubmitBtn: false }))
                        dispatch(HIDE_INPUT_N_SUBMIT({ hideOtpAndSubmitBtn: false }))
                      }
                      if (element?.handleNotFoundError)
                        // if (!response?.data || !response || response.error) {
                        if (response?.error || response?.message || !response?.data || !response) {
                          if (response.message === 'Request failed with status code 401' && element.name === 'continueButton' && (element.objectName === 'MultiFactorAuthenticationLogin' || element.objectName === 'MFAForgotPassword')) {
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: response.message, oktaErrorText: 'You do not have permission to access the feature you are requesting', showInputErr: true, redirectToLogin: false }));
                          }
                          if (response.message === 'Request failed with status code 400' && element.name === 'continueButton' && element.objectName === 'MultiFactorAuthenticationLogin') {
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: response.message, oktaErrorText: 'Invalid factor call', showInputErr: true, redirectToLogin: false }));
                          }
                          if (response.message === 'Request failed with status code 429') {
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, showError: true, mfaErrorText: response.message, oktaErrorText: 'Please wait 30 seconds before trying again.', showInputErr: true, redirectToLogin: false }));
                            dispatch(HIDE_MSG(false))
                          }
                          else
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, showError: false, mfaErrorText: '', oktaErrorText: '', showInputErr: false, redirectToLogin: false }));

                          if (response.message === 'Request failed with status code 403') {//&& element.name === 'sendCodeBtn'){//&& message === ''  ){
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({
                              serviceUnavailable: false,
                              showError: true,
                              mfaErrorText: response.message,
                              oktaReachLimitText: 'You have reached the limit of requests, please try again later.',
                              showInputErr: true, redirectToLogin: false
                            }));
                            dispatch(HIDE_MSG(false))
                          }
                          if (response.message === 'Request failed with status code 400' && element.name === 'sendCodeBtn' && element.apiConfig.objectName === 'MultiFactorAuthentication') {
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: response.message, oktaErrorText: 'Invalid mobile number', showInputErr: true, redirectToLogin: false }));
                            dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: true, clearCount: false, form: element?.apiConfig.objectName, hideOtpAndSubmitBtn: true }))
                            dispatch(HIDE_INPUT_N_SUBMIT({ hideOtpAndSubmitBtn: true }))
                          }
                          dispatch(HAS_ERROR({ hasError: true }));
                          response?.message && dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: true, clearCount: false, form: element?.apiConfig.objectName, field: element.name }))
                        }
                        else {
                          if (element.name !== 'resendPhoneSecurityCodeButton') {
                            if ((element.name === 'submitButton' || element.name === 'resendPhoneSecurityCodeButton') && element.reduxData === 'MultiFactorAuthentication')
                              dispatch(ACTIVATE_PHONE(true));
                            dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: false, clearCount: false, form: element?.apiConfig.objectName }))
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: response.message, oktaErrorText: '', showInputErr: true, redirectToLogin: false,   oktaReachLimitText: ''}));
                            dispatch(HIDE_MSG(true))
                          }
                        }                      

                    }
                  }
                  const storePayload = (element && element.storePayload) || false;
                  setShowLoader(true);
                  serviceHelper.mfaCallArray(
                    formik,
                    props,
                    element,
                    loggedUserEntity,
                    subscribedcontracts,
                    formName,
                    currentReduxState,
                    storePayload,
                    setResponsePayloadData,
                    email,
                    phoneNumber,
                    phoneVerificationMethod,
                    legalEntityId,
                    appCode,
                    basicAuthorizationValue,
                    token,
                    newEmail,
                    login,
                    preferredChannel,
                    tempLoginOtpMethod,
                    forResend ? loginOtpMethod || preferredChannel || 'email' :
                                  tempLoginOtpMethod ||loginOtpMethodRetrieveUser|| preferredChannel || 'sms'

                  );
                }



                else if (element?.recallValidate) {
                  let forContinue = element.name === 'continueButton' &&  (
                    element.objectName === "EmployeeValidationForgotUserID" ||  element.objectName === "MultiFactorAuthenticationLogin")
                  
                  let forResend = element.name ==='resendPhoneSecurityCodeButton' && (
                    currentStep ==='MFAForgotUsername' || currentStep ==='MultiFactorAuthenticationLogin' )
                     
                  // let forRetrieveUserIDCancel = (element.name === 'cancelButton' &&  element.objectName === "EmployeeValidationForgotUserID")

                  if (forContinue) {
                    let loginOTPMethod = tempLoginOtpMethod ||loginOtpMethodRetrieveUser|| preferredChannel || 'sms'
                    dispatch(SET_LOGIN_OTP_METHOD_RETRIEVE_USER(loginOTPMethod));
                    dispatch(SET_TEMP_LOGIN_OTP_METHOD(loginOTPMethod));
                  }

                  if (forResend) {
                    let loginOTPMethod = loginOtpMethodRetrieveUser || preferredChannel || 'sms'
                    dispatch(SET_LOGIN_OTP_METHOD_RETRIEVE_USER(loginOTPMethod));
                  }
                 
                  function setResponsePayloadData(responseData) {
                    if (element && element.reduxCall && element.mfaButton) {
                      const response = responseData;
                      dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: '', oktaErrorText: '', showInputErr: false, redirectToLogin: false }));
                      if (element?.handleNotFoundError)
                        if (response?.error || response?.message || !response?.data || !response) {
                          if (response.message === 'Request failed with status code 401' && element.name === 'continueButton' && (element.objectName === 'MultiFactorAuthenticationLogin' || element.objectName === 'MFAForgotPassword' || element.objectName === 'EmployeeValidationForgotUserID')) {
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: response.message, oktaErrorText: 'You do not have permission to access the feature you are requesting', showInputErr: true, redirectToLogin: false }));
                          }
                          
                          if (response.message === 'Request failed with status code 429') {
                            dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, showError: true, mfaErrorText: response.message, oktaErrorText: 'Please wait 30 seconds before trying again.', showInputErr: true, redirectToLogin: false }));
                            dispatch(HIDE_MSG(false))
                          }
                          
                          dispatch(HAS_ERROR({ hasError: true }));
                          dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: true, clearCount: false, key: element?.apiConfig.objectName }))
                        }
                        else {
                          dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: false, clearCount: false, key: element?.apiConfig.objectName }))
                        }

                    }
                  }
                  const storePayload = (element && element.storePayload) || false;
                  setShowLoader(true);
                  serviceHelper.mfaCallArrayLogin(
                    formik,
                    props,
                    element,
                    loggedUserEntity,
                    subscribedcontracts,
                    formName,
                    currentReduxState,
                    storePayload,
                    setResponsePayloadData,
                    email,
                    phoneNumber,
                    phoneVerificationMethod,
                    legalEntityId,
                    appCode,
                    basicAuthorizationValue,
                    preferredChannel,
                    tempLoginOtpMethod,
                    // !forRetrieveUserID ? loginOtpMethod :loginOtpMethodRetrieveUser || preferredChannel || 'sms'
                    // !forRetrieveUserID ? loginOtpMethod :tempLoginOtpMethod ||loginOtpMethodRetrieveUser|| preferredChannel || 'sms'
                    //forRetrieveUserID ? loginOtpMethodRetrieveUser || preferredChannel || 'sms' :''
                    //forRetrieveUserID ? tempLoginOtpMethod ||loginOtpMethodRetrieveUser|| preferredChannel || 'sms':''
                    forResend ? loginOtpMethodRetrieveUser || preferredChannel || 'sms' :
                                  tempLoginOtpMethod ||loginOtpMethodRetrieveUser|| preferredChannel || 'sms'
                  );
                }
                else if (element?.reduxPayload) {
                  function setResponsePayloadData(responseData) {
                    if (element && element.reduxCall && element.mfaButton) {
                      const response = responseData;
                      response?.data?.token && dispatch(SET_MFA_LOGIN_TOKEN(response.data.token))
                      if (element?.handleNotFoundError)
                        if (!response?.data || !response) {
                          dispatch(HAS_ERROR({ hasError: true }));
                          dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: true, clearCount: false, key: element?.apiConfig.objectName }))
                        }
                        else {
                          dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: false, clearCount: false, key: element?.apiConfig.objectName }))
                        }

                    }
                  }
                  const storePayload = (element && element.storePayload) || false;
                  setShowLoader(true);
                  serviceHelper.mfaCallArrayLoginPrimary(
                    formik,
                    props,
                    element,
                    loggedUserEntity,
                    subscribedcontracts,
                    formName,
                    currentReduxState,
                    storePayload,
                    setResponsePayloadData,
                    email,
                    phoneNumber,
                    phoneVerificationMethod,
                    legalEntityId,
                    appCode,
                    basicAuthorizationValue,
                    login,
                    password
                  );
                }
                else if (element?.recallValidate) {
                  function setResponsePayloadData(responseData) {
                    if (element && element.reduxCall && element.mfaButton) {
                      const response = responseData;
                      if (element?.handleNotFoundError)
                        if (!response?.data || !response) {
                          dispatch(HAS_ERROR({ hasError: true }));
                          dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: true, clearCount: false, key: element?.apiConfig.objectName }))
                        }
                        else {
                          dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: false, clearCount: false, key: element?.apiConfig.objectName }))
                        }

                    }
                  }
                  const storePayload = (element && element.storePayload) || false;
                  setShowLoader(true);
                  serviceHelper.mfaCallArrayLogin(
                    formik,
                    props,
                    element,
                    loggedUserEntity,
                    subscribedcontracts,
                    formName,
                    currentReduxState,
                    storePayload,
                    setResponsePayloadData,
                    email,
                    phoneNumber,
                    phoneVerificationMethod,
                    legalEntityId,
                    appCode,
                    basicAuthorizationValue
                  );
                }
                else if (element?.reduxPayload) {
                  function setResponsePayloadData(responseData) {
                    if (element && element.reduxCall && element.mfaButton) {
                      const response = responseData;
                      response?.data?.token && dispatch(SET_MFA_LOGIN_TOKEN(response.data.token))
                      if (element?.handleNotFoundError)
                        if (!response?.data || !response) {
                          dispatch(HAS_ERROR({ hasError: true }));
                          dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: true, clearCount: false, key: element?.apiConfig.objectName }))
                        }
                        else {
                          dispatch(SET_SEND_EMAIL_CHALLENGE_ERROR({ hasError: false, clearCount: false, key: element?.apiConfig.objectName }))
                        }

                    }
                  }
                  const storePayload = (element && element.storePayload) || false;
                  setShowLoader(true);
                  serviceHelper.mfaCallArrayLoginPrimary(
                    formik,
                    props,
                    element,
                    loggedUserEntity,
                    subscribedcontracts,
                    formName,
                    currentReduxState,
                    storePayload,
                    setResponsePayloadData,
                    email,
                    phoneNumber,
                    phoneVerificationMethod,
                    legalEntityId,
                    appCode,
                    basicAuthorizationValue,
                    login,
                    password
                  );
                }


                if (element?.apiCall) {

                  let response = '';
                  function setResponsePayload(responseData) {
                    response = responseData;
                    if (element.wl) {
                      dispatch(STORE_IN_REDUX_WL(response));
                    }
                    else if (element.medicalSurvey) {
                      dispatch(STORE_IN_REDUX_MEDICAL_SURVEY(response));
                    }
                    else {
                      dispatch(STORE_IN_REDUX(response));
                    }
                  }

                  setShowLoader(true);
                  serviceHelper.apiCall(
                    formik,
                    props,
                    element,
                    currentReduxState,
                    setResponsePayload
                  );
                }

                if (element?.apiNextCall) {
                  let response = '';
                  function setResponsePayload(responseData) {
                    response = responseData;
                    dispatch(onLoadAPI({ ...{ [element?.apiNextCallURL?.[0].apiconfig.objectName]: response?.[0] } }));
                  }

                  setShowLoader(true);
                  serviceHelper.apiNextCall(
                    formik,
                    props,
                    element,
                    currentReduxState,
                    setResponsePayload
                  );
                }
                if (element?.apiSecondCall && waive === false) {
                  let response = '';
                  function setResponsePayload(responseData) {
                    response = responseData;
                    dispatch(onLoadAPI({ ...{ [element?.apiSecondCallURL?.[0].apiconfig.objectName]: response?.[0] } }));
                  }

                  setShowLoader(true);
                  serviceHelper.apiSecondCall(
                    formik,
                    props,
                    element,
                    currentReduxState,
                    setResponsePayload
                  );
                }

                if (element?.apiUnsubscribeCall &&
                  ((element.name == 'continueTableCoverageButton' && currentStep == 'CoverageSelection' && (CoverageSelection.waiveInsureCoverage === true || radioSelectedValue == 'Waive' || radioSelectedValue == -1)) ||
                    (element.name == 'continueTableCoverageButton' && currentStep == 'IdTheft' && (radioSelectedValueIDTheft == 'Waive' || radioSelectedValueIDTheft == -1)) ||
                    (element.name == 'continueTableCoverageButton' && currentStep == 'LegalCoverage' && (radioSelectedValueLegalCoverage == 'Waive' || radioSelectedValueLegalCoverage == -1)) ||
                    (element.name == 'continueTableCoverageButton' && currentStep == 'Travel' && (radioSelectedValueTravel == 'Waive' || radioSelectedValueTravel == -1)) ||
                    (element.name == 'continueTableCoverageButton' && currentStep == 'PetPlus' && (radioSelectedValuePetPlus == 'Waive' || radioSelectedValuePetPlus == -1)) ||
                    (element.name == 'continueTableCoverageButton' && currentStep == 'PetAssure' && (radioSelectedValuePetAssure == 'Waive' || radioSelectedValuePetAssure == -1)) ||
                    (element.name == 'continueTableCoverageButton' && currentStep == 'HospitalIndemnity' && (HospitalIndemnity.waiveInsureCoverage === true || radioSelectedValueHospitalIndemnity == 'Waive' || radioSelectedValueHospitalIndemnity == -1)) ||
                    (element.name == 'continueTableCoverageButton' && currentStep == 'CriticalIllness' && (CriticalIllness.waiveInsureCoverage === true || radioSelectedValueCriticalIllness == 'Waive' || radioSelectedValueCriticalIllness == -1)) ||
                    (element.name == 'continueTableCoverageButton' && currentStep == 'ExcessLiability' && (radioSelectedValueExcessLiability == 'Waive' || radioSelectedValueExcessLiability == -1)) ||
                    (element.name == 'continueFormButton' && currentStep == 'ExcessLiability' && (radioSelectedValueExcessLiabilityUmUim == 'Waive' || radioSelectedValueExcessLiabilityUmUim == -1)) ||
                    (element.name === 'continueProductButton' && currentStep == 'CoverageSelection' && CoverageSelection.waiveInsureCoverage === true) ||
                    (element.name === 'continueProductButton' && currentStep == 'HospitalIndemnity' && HospitalIndemnity.waiveInsureCoverage === true) ||
                    (element.name === 'continueProductButton' && currentStep == 'CriticalIllness' && CriticalIllness.waiveInsureCoverage === true) ||
                    (element.name === 'continueProductButton' && currentStep == 'PetAssureNation' && (setWaiveCoverageNation == true))
                  )
                ) {
                  let response = '';
                  function setResponsePayload(responseData) {
                    response = responseData;
                    dispatch(onLoadAPI({ ...{ [element?.apiUnsubscribeCallURL?.[0].apiconfig.objectName]: response?.[0] } }));
                  }

                  setShowLoader(true);
                  serviceHelper.apiUnsubscribeCall(
                    formik,
                    props,
                    element,
                    currentReduxState,
                    setResponsePayload
                  );
                }

                if (element?.apiUpdatesubscribeCall &&
                  ((element.name == 'continueTableCoverageButton') &&
                    ((currentStep == 'CoverageSelection' && radioSelectedValue > -1) ||
                      (currentStep == 'IdTheft' && radioSelectedValueIDTheft > -1) ||
                      (currentStep == 'LegalCoverage' && radioSelectedValueLegalCoverage > -1) ||
                      (currentStep == 'Travel' && radioSelectedValueTravel > -1) ||
                      (currentStep == 'PetPlus' && radioSelectedValuePetPlus > -1) ||
                      (currentStep == 'PetAssure' && radioSelectedValuePetAssure > -1) ||
                      (currentStep == 'HospitalIndemnity' && radioSelectedValueHospitalIndemnity > -1) ||
                      (currentStep == 'ExcessLiability' && radioSelectedValueExcessLiability > -1) ||
                      (currentStep == 'CriticalIllness' && radioSelectedValueCriticalIllness > -1)
                    ) || (
                      element.name == 'continueFormButton' &&
                      (currentStep == 'ExcessLiability' && radioSelectedValueExcessLiabilityUmUim > -1)
                    ))
                ) {

                  let response = '';
                  function setResponsePayload(responseData) {
                    response = responseData;
                    dispatch(onLoadAPI({ ...{ [element?.apiUpdatesubscribeCallURL?.[0].apiconfig.objectName]: response?.[0] } }));
                  }

                  setShowLoader(true);
                  serviceHelper.apiUpdatesubscribeCall(
                    formik,
                    props,
                    element,
                    currentReduxState,
                    setResponsePayload
                  );
                }

                if (element?.apiDisclosureAgreeCall && (
                  element.name === 'continueFormButton' && (
                    (currentStep == 'CoverageSelection' && isYesradioDisclosureSelected === false) ||
                    (currentStep == 'IdTheft' && isYesradioDisclosureSelectedIdTheft === false) ||
                    (currentStep == 'CriticalIllness' && isYesradioDisclosureSelectedCriticalIllness === false) ||
                    (currentStep == 'HospitalIndemnity' && isYesradioDisclosureSelectedHospitalIndemnity === false)
                  ))) {
                  let response = '';
                  function setResponsePayload(responseData) {
                    response = responseData;
                    dispatch(onLoadAPI({ ...{ [element?.apiDisclosureCallURL?.[0].apiconfig.objectName]: response?.[0] } }));
                  }

                  setShowLoader(true);
                  serviceHelper.apiDisclosureAgreeCall(
                    formik,
                    props,
                    element,
                    currentReduxState,
                    setResponsePayload
                  );
                }
                else if (element?.apiDisclosureDisAgreeCall && (element.name === 'continueFormButton' && (
                  (currentStep == 'CoverageSelection' && isYesradioDisclosureSelected === true) ||
                  (currentStep == 'IdTheft' && isYesradioDisclosureSelectedIdTheft === true) ||
                  (currentStep == 'CriticalIllness' && isYesradioDisclosureSelectedCriticalIllness === true) ||
                  (currentStep == 'HospitalIndemnity' && isYesradioDisclosureSelectedHospitalIndemnity === true)
                ))) {
                  let response = '';
                  function setResponsePayload(responseData) {
                    response = responseData;
                    dispatch(onLoadAPI({ ...{ [element?.apiDisclosureDisAgreeCallURL?.[0].apiconfig.objectName]: response?.[0] } }));
                  }

                  setShowLoader(true);
                  serviceHelper.apiDisclosureDisAgreeCall(
                    formik,
                    props,
                    element,
                    currentReduxState,
                    setResponsePayload
                  );
                }
                if (element.config && element.config.type === 'redirect') {
                  let redirectURL = element.config.redirectURL || '';
                  let urlFromStore = url ? decodeURIComponent(url) : ''
                  if (redirectURL && urlFromStore && element.name === 'logoutNoButton' && userType !=='HrCenter' && processType !=='HrCenter') {
                    history.push(redirectURL + urlFromStore)
                  }
                  else if (redirectURL && urlFromStore && element.name === 'logoutNoButton' && userType ==='HrCenter' && processType ==='HrCenter') {
                    history.push('/hrcenter/login' + urlFromStore)
                  }
                  else
                    history.push(element.config.redirectURL);
                }

                if (element.download) {
                  serviceHelper.download(formik, props, element);
                }
                if (element.name == 'PetAssureNation.stepperModalPet') {
                  dispatch(RADIO_SELECTED_VALUE_PET_NATION(''));
                  // dispatch(EDIT_PET_NATION(false))
                }

              }
            }
            element={element}
            showContent={showContent}
            buttonForEmail={element.buttonForEmail}
            buttonForPhone={element.buttonForPhone}
            images={element.images}
            buttonName={element.name}
            registerOrRetrieve={element.registerOrRetrieve}
            hideButton={element.hideButton}
            flexPlacement={element.flexPlacement}
            currentStep={currentStep}
          >
            {element.label}
          </Button>
        );

   
      case 'buttonDropDown':
        return (
          <ButtonDropDown
            key={element.name}
            id={element.name}
            className={element.className}
            name={elementId}
            outline={element.outline}
            dropDown={element.dropDown}
            menuList={element.menuList}
          >
            {element.label}
          </ButtonDropDown>
        );
 
      case 'checkbox':
        return (
          <Checkbox
            key={formName[element.name]}
            id={formName[element.name] || element.name}
            label={element.label}
            disabled={element.disabled}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            element={element}
            visibility={element.visibility}
            hidden={element.hidden}
            name={elementId}
            elementStyle={element.style}
            isForInsuredWaive={element.isForInsuredWaive}
            classNameWrapper={element.classNameWrapper}
            value={elementValue != '' ? elementValue : false}
            onChange={(e) => {
              if (formik) {
                e.currentTarget.value = e.currentTarget.checked;
                const isChecked = e.currentTarget.checked;

                formik.handleChange(e);
                if (element.populateCheckBoxValue) {
                  serviceHelper.updatingCheckBox(
                    element,
                    formik,
                    formName,
                    props,
                    e.currentTarget.value,
                    formikFromRedux
                  );
                }
                if (element.populateValue) {
                  // This Method populate the values inside formik
                  let tempAddress = serviceHelper.populateValues(
                    element,
                    formik,
                    formName,
                    props,
                    e.currentTarget.value,
                    formikFromRedux,
                    employeeProfile
                  );
                  const newAddress = {
                    line1: tempAddress.line1,
                    line2: tempAddress.line2,
                    zipCode: tempAddress.zipCode,
                    city: tempAddress.city,
                    state: tempAddress.state,
                    country: tempAddress.country,
                  };
                  const hasAddress =
                    tempAddress &&
                      tempAddress.line1 &&
                      tempAddress.zipCode &&
                      tempAddress.city &&
                      tempAddress.state &&
                      tempAddress.country
                      ? true
                      : false;
                  const hasTempAddress =
                    formikFromRedux &&
                      formikFromRedux.line1 &&
                      formikFromRedux.zipCode &&
                      formikFromRedux.city &&
                      formikFromRedux.state &&
                      formikFromRedux.country
                      ? true
                      : false;
                  // if (name === "DependentInformation.liveInSameAddress" ) {
                  //   if ( element && element.populateValue && element.populateValue.hiddenValues) {
                  //     if ( isChecked ) {
                  //       dispatch(HIDE_FIELDS(element.populateValue.hiddenValues));
                  //     } else {
                  //       dispatch(HIDE_FIELDS([]));
                  //     }
                  //   }
                  //   dispatch(HIDE_ROW(isChecked))
                  // }
                  if (isChecked) {
                    if (hasAddress || hasTempAddress) {
                      const newDependentAddress = hasAddress
                        ? newAddress
                        : hasTempAddress
                          ? formikFromRedux
                          : {};
                      const newLiveAtSameAddress = hasAddress
                        ? newAddress
                        : hasTempAddress
                          ? formikFromRedux
                          : {};

                      if (elementId === 'DependentInformation.liveInSameAddress') {
                        if (formName === 'DependentInformation' && formik && formik.values && formik.values[formName] && formik.values[formName]['editDependentBUID']) {
                          errorStatusObj['dependentAddressLine1'] = false;
                          errorStatusObj['dependentCity'] = false;
                          errorStatusObj['dependentState'] = false;
                          errorStatusObj['dependentZipCode'] = false;
                          formik.setStatus({ [formName]: errorStatusObj });
                        }
                      }
                      // if ( elementId === 'DependentInformation.liveInSameAddress' ) {
                      dispatch(HIDE_ROW(isChecked));
                      dispatch(STORE_DEPENDENT_ADDRESS(newDependentAddress));
                      // } else if (elementId === 'CriticalIllness.liveInSameAddress') {
                      dispatch(HIDE_BENEFICIARY_ADDRESS(isChecked));
                      dispatch(STORE_LIVE_AT_SAME_ADDRESS(newLiveAtSameAddress));
                      // }
                    }
                  } else {
                    if (elementId === 'DependentInformation.liveInSameAddress') {
                      if (formName === 'DependentInformation' && formik && formik.values && formik.values[formName] && formik.values[formName]['editDependentBUID']) {
                        errorStatusObj['dependentAddressLine1'] = true;
                        errorStatusObj['dependentCity'] = true;
                        errorStatusObj['dependentState'] = true;
                        errorStatusObj['dependentZipCode'] = true;
                        formik.setStatus({ [formName]: errorStatusObj });
                      }
                    }
                    // if ( elementId === 'DependentInformation.liveInSameAddress' ) {
                    dispatch(HIDE_ROW(false));
                    dispatch(STORE_DEPENDENT_ADDRESS({}));
                    // } else if (elementId === 'CriticalIllness.liveInSameAddress') {
                    dispatch(HIDE_BENEFICIARY_ADDRESS(false));
                    dispatch(STORE_LIVE_AT_SAME_ADDRESS({}));
                    // if ( elementId === 'DependentInformation.liveInSameAddress' ) {
                    //   formik.setFieldValue('DependentInformation.dependentCountry','USA');
                    // }
                    // }
                  }
                }
              }
              handleChange(e);
            }}
            /*   onChange={(e) => {
                if (element && element.defaultCheck && formik) {
                  let newobj = formik.values[formName];
                  newobj.forEach((obj) => {
                    obj[element.name] = '';
                  });
                  formik.setValues({
                    ...formik.values,
                    [formName]: [...newobj],
                  });
                }
                e.currentTarget.value = e.currentTarget.checked;
                if (formik) {
                  formik.handleChange(e);
                }
                if (element.resetMappingDataConfig && e.currentTarget.value === 'true') {
                  serviceHelper.clearFormDataMapping(
                    elements,
                    element.resetMappingDataConfig,
                    formik,
                    formName,
                    counter
                  );
                }
              }
              handleChange(e);
            }}
            /*   onChange={(e) => {
              if (element && element.defaultCheck && formik) {
                let newobj = formik.values[formName];
                newobj.forEach((obj) => {
                  obj[element.name] = '';
                });
                formik.setValues({
                  ...formik.values,
                  [formName]: [...newobj],
                });
              }
              e.currentTarget.value = e.currentTarget.checked;
              if (formik) {
                formik.handleChange(e);
              }
              if (element.resetMappingDataConfig && e.currentTarget.value === 'true') {
                serviceHelper.clearFormDataMapping(
                  elements,
                  element.resetMappingDataConfig,
                  formik,
                  formName,
                  counter
                );
              }
              //serviceHelper.handleChange(props, formName, isMultiple);
              handleChange(e);
            }} */
            onBlur={formik && formik.handleBlur}

            SetContentData={props.SetContentData}
            content={props.content}
            contentData={props.contentData}
            setLayoutContent={props.setLayoutContent}
            formik={props.formik}
          />
        );

        case 'imageText':
          return (
            <ImageText
              badge={element.badge}
              badgeContainerClassName={element.badgeContainerClassName}
              badgeContainerStyle={element.badgeContainerStyle}
              badgeStyle={element.badgeStyle}
              bagdeClassName={element.bagdeClassName}
              containerClassName={element.containerClassName}
              containerStyle={element.containerStyle}
              fileName={element.fileName}
              imageClassName={element.imageClassName}
              imageStyle={element.imageStyle}
              label={element.label}
              name={element.name}
              subtitle={element.subtitle}
              subtitleClassName={element.subtitleClassName}
              subtitleStyle={element.subtitleStyle}
              textClassName={element.textClassName}
              textStyle={element.textStyle}
              id={element.id}
              showContent={showContent}
              isListofEligibleMemberForHomePage={element.isListofEligibleMemberForHomePage}
              logoBase64={element?.logo}
              contentType={element?.contentType}
              alt={element?.alt}
            />
          );

      //Created this componenent for line
      case 'line':
        return (
          <Line
            {...props}
            style={element.config && element.config.style}
            image={element.image}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            value={element.valueArray}
            valueIn={element.valueIn}
            title={element.title}
            className={element.className}
            config={element.config}
            hidden={element.hidden}
            iconClass={element.iconClass}
            headerClass={element.headerClass}
            listClass={element.listClass}
            olClass={element.olClass}
            id={element.id}
            showContent={showContent}
            color={element.color}
          />
        );

      case 'collapse':
        return (
          <Collapse
            className={element.className}
            key={element.name}
            title={element.title}
            onClick={element.click}
            subOptions={element.options}
            link={element.link}
            notMenu={element.notMenu}
            video={element.video}
            documents={element.documents}
            videoPopUp={element.videoPopUp}
            linksToDownload={element.linksToDownload}
            showFinalDiv={element.showFinalDiv}
            linkAdditionalClass={element.linkAdditionalClass}
            modalName={element.modalName}
            pdfURL={element.pdfURL}

            upArrow={element.upArrow}
            downArrow={element.downArrow}
            content={element.content || []}
            panelLinks={element.panelLinks}
            documentClassName={element.documentClassName}
            hideElement={serviceHelper.hideElement(element, formik, formName)}


            currentStep={currentStep}



            listElementsData={element.listElementsData}
            listHeader={element.listHeader}
            listFooter={element.listFooter}
            sticky={element.sticky}
            stickyDetails={element.stickyDetails}
            listImage={element.listImage}

          />
        );

      case 'image':
        return (
          <Image
            key={element.filename}
            filename={element.filename}
            logo={element.productLogo}
            alt={element.alt}
            className={element.className}
            classNameWrapper={element.classNameWrapper}
            style={element.style}
            hideDefaultClass={element.hideDefaultClass}
            isLeftLabel={element.isLeftLabel ? true : false}
            label={element.label}
            hidden={element.hidden}
            formSideHeaderWithImage={element.formSideHeader}
            toolTip={element.toolTip}
            name={element.name}
            modalName={element.modalName}
            pdfURL={element.pdfURL}
            prodLogo={element.prodLogo}
            imageFileType={element.imageFileType}
            productSelLogo={element.productSelLogo}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            showOnAPISuccess={element.showOnAPISuccess}
            onClick={() => {
              element.onClick ? element.onClick() : history.push(element.to);
            }}
          ></Image>
        );

      case 'link':
        return (
          <Linker
            callback={props.parentAction || ''}
            key={element.name}
            id={element.name}
            label={element.name}
            name={element.name}
            to={element.to}
            title={element.title}
            style={element.style}
            className={element.className}
            hidden={serviceHelper.hideOnloadElements(formik, props, element)}
            moveNextStep={element.moveNextStep}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            role={element.role}
            data-toggle={element['data-toggle']}
            aria-haspopup={element['aria-haspopup']}
            aria-expanded={element['aria-expanded']}
            {...props}
          >
            {element.value}
          </Linker>
        );

    

      case 'iconDropdown':
        //code for profile dropdown
        let availableProfile = [];
        let availableEmployee = [];
        // security setting - header
        if (element.name === 'profileDropdown') {
          let profileDropdownTemp = element.options;
          for (let i = 0; i < profileDropdownTemp.length; i++) {
            var currentProfile = profileDropdownTemp[i];
            if (currentProfile.text === 'Log Out') {
              availableProfile.push({
                ...currentProfile,
                click: (e) => {
                  handleSignOut(e, element);
                },
              });
            } else if (currentProfile.text === 'Contact Us' && userType !=='HrCenter' && processType !=='HrCenter') {
              availableProfile.push({
                ...currentProfile,
                click: (e) => { },
              });
            } else if (currentProfile.text === 'Security Settings') {
              let subOpt = [];
              availableProfile.push({
                ...currentProfile,
                click: (e) => { },
              });
            }
            element.options = availableProfile;

          }
        }
        //call center - search employee
        if (element.name === 'employeeDropdown') {
          let profileDropdownTemp = element.options;
          for (let i = 0; i < profileDropdownTemp.length; i++) {
            var currentProfile = profileDropdownTemp[i];
            if (currentProfile.text === 'Admin Enrollment') {
              availableEmployee.push({
                ...currentProfile,
                click: (e) => {
                  handleAdminEnrollment(e, element);
                },
              });
            } else if (currentProfile.text === 'Reset Username/Password') {
              availableEmployee.push({
                ...currentProfile,
                click: (e) => {
                  handleRestUsernamePassword(e, element)
                },
              });
            } else if (currentProfile.text === 'Retrieve Username') {
              availableEmployee.push({
                ...currentProfile,
                click: (e) => {
                  handleRetrirveUsername(e, element)
                },
              });
            }
          }
        }
        //Code for language icon dropdown in mobile version
        let availableLanguagesTemp = [...availableLanguages];
        for (let i = 0; i < availableLanguagesTemp.length; i++) {
          var currentLanguage = availableLanguagesTemp[i];
          availableLanguagesMobile.push({
            ...currentLanguage,
            click: (e) => {
              dispatch(CHANGE_LANGUAGE(availableLanguagesTemp[i].value));
            },
          });
        }

        return (
          <>
            <IconDropdown
              dropDownType={element.dropDownType}
              filename={element.filename}
              alt={element.alt}
              className={element.className}
              classNameWrapper={element.classNameWrapper}
              iconPrefix={element.iconPrefix}
              iconName={element.iconName}
              dropdownClassName={element.dropdownClassName}
              optionType={element.optionType}
              options={
                element.populateDataConfig
                  ? serviceHelper.generateDataList(formik, element.populateDataConfig)
                  : element.options
                    ? element.options
                    : constantsList[element.optionsname]
                      ? constantsList[element.optionsname]
                      : element.selectType === 'languageMobile'
                        ? availableLanguagesMobile
                        : element.selectType === 'profileDropdown'
                          ? availableProfile
                          : element.selectType === 'employeeDropdown'
                            ? availableEmployee
                            : []
              }
            />
          </>
        );


      case 'color':
        return (
          <InputColor
            {...props}
            key={elementId}
            id={elementId}
            type={element.type}
            label={element.label}
            update={element.update}
            element={element}
            name={elementId}
            disabled={disabled}
            value={elementValue}
            requiredValidation={isRequiredValidation}
            onChange={(e) => {
              if (formik && formik.handleChange) {
                formik.handleChange(e);
              }
              handleChange(e);
            }}
            onBlur={(e) => {
              handleBlur(e, element, formik);
              if (formik && formik.handleBlur) {
                formik.handleBlur(e);
              }
            }}
            hidden={element.hidden}
          />
        );

      case 'timer':
        return (
          <Timer
            {...props}
            key={element.name}
            id={element.name}
            label={element.name}
            name={element.name}
            style={element.config && element.config.style}
            image={element.image}
            value={element.value ? element.value : elementValue ? elementValue : props.value}
            valueIn={element.valueIn}
            className={element.className}
            config={element.config}
            enableElement={element.enableElement}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            elements={element.elements}
            timerForEmail={element.timerForEmail}
            timerForPhone={element.timerForPhone}
            hideOnClickSendCodeBtn={element.hideOnClickSendCodeBtn}
            hideOnWrongOTP={element.hideOnWrongOTP}
          >
            {' '}
          </Timer>
        );
      //Created this componenent for blue information box with image
      case 'stickyNote':
        return (
          <StickyNote
            {...props}
            style={element.config && element.config.style}
            image={element.image}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            value={element.valueArray}
            name={element.name}
            alt={element.alt}
            valueIn={element.valueIn}
            shouldShowOnYes={element.shouldShowOnYes}
            shouldShowOnYes1={element.shouldShowOnYes1}
            title={element.title}
            color={element.color}
            borderColor={element.borderColor}
            secondTitle={element.secondTitle}
            thirdTitle={element.thirdTitle}
            className={element.className}
            config={element.config}
            hidden={element.hidden}
            iconClass={element.iconClass}
            headerClass={element.headerClass}
            listClass={element.listClass}
            olClass={element.olClass}
            dynamic={element.dynamic}
            notNumbered={element.notNumbered}
            MultipleTitleStyles={element.MultipleTitleStyles}
            classNameWrapper={element.classNameWrapper}
            stickyDetails={element.stickyDetails}
            singleValue={element.singleValue}
            hideOnClickSendCodeBtn={element.hideOnClickSendCodeBtn}
          >
            {' '}
          </StickyNote>
        );

      //Created this componenent for blue information box with image
      case 'sticky':
        return (
          <Sticky
            {...props}
            style={element.config && element.config.style}
            image={element.image}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            value={element.valueArray}
            valueIn={element.valueIn}
            shouldShowOnYes={element.shouldShowOnYes}
            shouldShowOnYes1={element.shouldShowOnYes1}
            title={element.title}
            color={element.color}
            borderColor={element.borderColor}
            secondTitle={element.secondTitle}
            thirdTitle={element.thirdTitle}
            className={element.className}
            config={element.config}
            hidden={element.hidden}
            iconClass={element.iconClass}
            headerClass={element.headerClass}
            listClass={element.listClass}
            olClass={element.olClass}
            notNumbered={element.notNumbered}
            MultipleTitleStyles={element.MultipleTitleStyles}
            classNameWrapper={element.classNameWrapper}
          >
            {' '}
          </Sticky>
        );

 

   

      case 'toolTip':
        return (
          <ToolTip
            {...props}
            style={element.config && element.config.style}
            image={element.image}
            value={element.value ? element.value : elementValue ? elementValue : props.value}
            valueIn={element.valueIn}
            className={element.className}
            config={element.config}
            toolTip={element.toolTip}
            filename={element.filename}
            toolTipConfig={element.toolTipConfig}
            hidden={element.hidden}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            showOnAPISuccess={element.showOnAPISuccess}
          >
            {' '}
          </ToolTip>
        );

      case 'table':
        //let tablesArr =[]
        const tables =
          (contentData &&
            contentData.formsList &&
            contentData.formsList[formName] &&
            contentData.formsList[formName].tables) ||
          (props && props.tables) || [];

        let tableProps = {};
        if (tables && element && element.tableId) {
          tables.forEach((table) => {
            if (table && table.tableId === element.tableId) {
              tableProps = table;
            }
          });
        }

        // if (tables && contentData.formsList[formName].tables.multipleTablesInCurrentStep )
        //   for (let i = 0; i < contentData.formsList[formName].tables.length; i++)
        //     tablesArr[i] = tables[i]

        return (
          <TableWrapper
            name={formName}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            updateFormData={updateFormData}
            allFormData={formik.values[formName]}
            elements={[]}
            tableRowMapper={tableRowMapper}
            constantsList={constantsList}
            columns={columns}
            mobileColumns={mobileColumns}
            mobileRows={mobileRows}
            mobilePanel={mobilePanel}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            onChangeCheckBox={onChangeCheckBox}
            // tablesArr={tablesArr}
            // multipleTablesInCurrentStep={contentData.formsList[formName].tables.multipleTablesInCurrentStep}
            {...props}
            {...tableProps}
          />
        );

      case 'textContainer':
        return (
          <TextContainer
            config={element && element.config}
            descriptionContainerStyle={element.descriptionContainerStyle}
            descriptionSubtitle={element.descriptionSubtitle}
            descriptionSubtitleClassName={element.descriptionSubtitleClassName}
            descriptionTitle={element.descriptionTitle}
            descriptionTitleClassName={element.descriptionTitleClassName}
            hasList={element.hasList}
            listClassName={element.listClassName}
            listElements={element.listElements}
            name={element.name}
            title={element.title}
            titleClassName={element.titleClassName}
            titleStyle={element.titleStyle}
            showContent={showContent}
            id={element.id}
            listStyle={element?.listStyle || {}}
          />
        );
    

      case 'listContainer':
        return (
          <ListContainer
            containerStyle={element.containerStyle}
            containerClassName={element.containerClassName}
            elements={element.elements}
            showContent={showContent}
            id={element.id}
          />
        );

      case 'listComponent':
        return (
          <ListComponent
            tabIndex={element.tabIndex}
            elements={element.elements}
            listClassName={element.listClassName}
            listStyle={element.listStyle}
          />
        );

  
      //Created this componenent fortext and readonly
      case 'textReadOnlyLabel':
        return (
          <TextReadOnlyLabel
            {...props}
            key={elementId}
            id={elementId}
            type={element.type}
            format={element.format}
            hidden={element.hidden}
            tile={element.tile}
            tileInfo={element.tileInfo}
            classNameWrapper={element.classNameWrapper}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            label={element.label}
            placeholder={element.placeholder}
            element={element}
            name={elementId}
            disabled={disabled}
            value={isEdit ? tempValue : elementValue}
            errorFound={errorFound}
            requiredValidation={isRequiredValidation}
            optionValues={element.optionValues}
            valueStyle={element.valueStyle}
            showEmailForResetPassword={element.showEmailForResetPassword}
            showEmailForLogin={element.showEmailForLogin}
            showPhoneForResetLogin={element.showPhoneForResetLogin}
            changeLabelDynamically={element.changeLabelDynamically}
            phoneEmailOptions={element.phoneEmailOptions}
            staticText={element.staticText}
            extraValuesFromRedux={element.extraValuesFromRedux}
            onChange={(e) => {
              if (formik && formik.handleChange) {
                setTempValue(e.target.value);
                setEdit(true);
              }
              //serviceHelper.handleChange(props, formName, isMultiple);
              handleChange(e);
            }}
            onBlur={(e) => {
              handleBlur(e, element, formik);
              if (formik && formik.handleBlur) {
                formik.handleBlur(e);
              }
              if (formik && formik.handleChange) {
                formik.handleChange(e);
              }
              handleBlur(e, element, formik);
            }}
          // hidden={element.hidden}
          />
        );
     

   

     

      case 'fileUpload':
        return (
          <FileUpload
            {...props}
            element={element}
            value={elementValue}
            filename={element.filename}
            alt={element.alt}
            className={element.className}
            name={element.name}
            onChange={(e) => {
              formik.setFieldValue(element.name, e.currentTarget.files);
            }}
          ></FileUpload>
        );

      case 'cartSection':
        return <Cart {...props} element={element}></Cart>;

      case 'crossSellProducts':
        return (
          <CrossSellProducts {...props} element={element} />
        )
      case 'contactPreference':
        return (
          <ContactPreference {...props} element={element} />
        )

      default:
        const disableFutureDates = (element && element.disableFutureDates) || false;
        // Validation for edit Modals
        if (formName === 'DependentInformation' && formik && formik.values && formik.values[formName] && formik.values[formName]['editDependentBUID']) {
          if (errorStatusObj.hasOwnProperty(element.name)) {
            isEditModal = true;
            hasEditError = errorStatusObj[element.name];
          }
        }
        return (
          <Input
            {...props}
            key={elementId}
            id={elementId}
            nId={element.id}
            type={element.type}
            format={element.format}
            hidden={element.hidden}
            tile={element.tile}
            tileInfo={element.tileInfo}
            classNameWrapper={element.classNameWrapper}
            hideElement={serviceHelper.hideElement(element, formik, formName)}
            label={element.label}
            placeholder={element.placeholder}
            element={element}
            name={elementId}
            disabled={disabled}
            updateLabel={element.updateLabel}
            // value={isEdit ? tempValue : elementValue}
            isEditModal={isEditModal}
            hasEditError={hasEditError}
            value={elementValue}
            errorFound={isEditModal ? hasEditError : errorFound}
            requiredValidation={isRequiredValidation}
            passwordStrengthBar={element.passwordStrengthBar}
            shouldBeReadOnly={element.shouldBeReadOnly}
            icons={element.icons}
            shouldTriggerError={element.shouldTriggerError}
            shouldClearOnReset={element.shouldClearOnReset}
            className={element.className}
            style={element.style}
            payload={element.payload}
            specialIconLayout={element.specialIconLayout}
            disableFutureDates={disableFutureDates}
            manualDate={element.manualDate}
            iconStyle={element.iconStyle}
            // image={element.image}
            // imageContent={element.imageContent}
            imageAfter={element.imageAfter}
            buttonAfter={element.buttonAfter}
            elementsAfter={element.elementsAfter}
            shouldShowOnYes={element.shouldShowOnYes}
            needAPIValidation={element.needAPIValidation}
            apiErrorMessage={element.apiErrorMessage}
            apiErrorConfig={element.apiErrorConfig}
            classNameWrapperAPIError={element.classNameWrapperAPIError}
            showOnAPISuccess={element.showOnAPISuccess}
            showMatchMessage={element.showMatchMessage}
            matchMessageText={element.matchMessageText}
            matchMessageConfig={element.matchMessageConfig}
            errorClassNameWrapper={element.errorClassNameWrapper}
            hideOnDefaultAndSuccess={element.hideOnDefaultAndSuccess}
            showOnModalOnly={element.showOnModalOnly}
            forAdminEnrollment={element.forAdminEnrollment}
            forAdminEnrollCallCenterOnly={element.forAdminEnrollCallCenterOnly}
            onChange={(e) => {
              if (formik && formik.handleChange) {
                // setTempValue(e.target.value);
                // setEdit(true);
                formik.handleChange(e);
              }
              //serviceHelper.handleChange(props, formName, isMultiple);
              handleChange(e);
            }}
            onBlur={(e) => {
              const currentValue = e.target.value;
              if (formName === 'DependentInformation' && formik && formik.values && formik.values[formName] && formik.values[formName]['editDependentBUID']) {
                if (errorStatusObj.hasOwnProperty(element.name)) {
                  if (!currentValue) {
                    errorStatusObj[element.name] = true;
                  } else {
                    errorStatusObj[element.name] = false;
                  }
                }
                formik.setStatus({ [formName]: errorStatusObj });
              }
              handleBlur(e, element, formik);
              if (formik && formik.handleBlur) {
                formik.handleBlur(e);
              }
              // if (formik && formik.handleChange) {
              //   formik.handleChange(e);
              // }
              handleBlur(e, element, formik);
            }}
          // hidden={element.hidden}
          />
        );



    }
  };

  return elementorSwitch();
};

export default Elementor;