import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Button, TextInput } from '../../Atoms';
import appConfig from '../../../../../appConfig';
import { errorMessages } from '../../Atoms/FormValidation';
import http from '../../../../../Common/Services/HttpService';
import { getBasicAuthHeaderValue } from '../../Helper/MFAHelper'
import { UPDATE_USERNAME_EMAIL } from '../../../../../Store/Slices/Auth/userAuth';
import { SET_NEW_EMAIL, SET_PREFERRED_CHANNEL } from '../../../../../Store/Slices/Registeration';
import { Link } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
});

const EmailInput = ({ initialValues = { email: '' }, onNext, onPrev }) => {
    const dispatch = useDispatch();
    const { userAuth } = useSelector((storeState) => storeState?.auth);
    const isHrCenter = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter") ? true : false
    const formik = useFormik({
        initialValues: initialValues || {
            email: '',
        },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            submitHandler(values);
        },
    });

    const submitHandler = async (data) => {
        const token = getBasicAuthHeaderValue();

        try {
            const resp = await http.post(
                appConfig.SSP_AUTH_URL + "/stateful/user-mgmt/change-email/1",
                data,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Basic ${token}`,
                        'X-REQUEST-ID': Math.random(200),
                    }
                },
            );
            if (resp.status === 200) {
                const channel = resp?.data?.channel;
                if (channel) {
                    dispatch(SET_PREFERRED_CHANNEL(channel))
                    dispatch(SET_NEW_EMAIL({ newEmail: data.email }));
                    dispatch(UPDATE_USERNAME_EMAIL(data.email));
                    onNext();
                    return;
                }
            } else {
                formik.setFieldError('email', errorMessages(resp));
            }
        } catch (e) {
            formik.setFieldError('email', errorMessages(e));
            return { error: e.message };
        }
    }

    const validationMsg = (field) => {
        return (formik.touched[field] && formik.errors[field] && (
            <div className="form-mandatory">{formik.errors[field]}</div>
        ))
    };

    return (
        <form onSubmit={formik.handleSubmit} noValidate>
            <div className="row">
                <div className="col-12">
                    <h1 className="form-side-header">Change MFA Contact</h1>
                    <p className="form-mandatory mb-0">Fields marked with * are mandatory</p>
                    <p>Please change an existing email ID.</p>
                </div>
            </div>
            <div className="row py-2">
                <div className="col-sm-12 col-md-6">
                    <TextInput
                        required
                        id="email"
                        name="email"
                        label="Email ID"
                        placeholder="XXX@domain.com"
                        className="w-100 inputField p-3"
                        {...formik.getFieldProps('email')}
                    />
                    {validationMsg('email')}
                </div>
                <div className="col-sm-12 offset-md-4"></div>
            </div>
            <div className="row py-4 justify-content-between">
                <div className="col-sm-12 col-md-4 py-2">
                    {isHrCenter ? <Link role="link" to={`/hrcenter`}> Back to home</Link> : <Link role="link" to={`/enrollment/welcome`}> Back to home</Link>}
                </div>
                <div className="col-sm-12 col-md-6 py-2 mr-4 text-md-right">
                    <Button type="button" className="btn btn-outline primaryOutline btn-outline-primary-contrast mr-3" onClick={onPrev}>Back</Button>
                    <Button type="submit" className="btn btn-primary primaryBackground btn-primary-contrast">Continue</Button>
                </div>
            </div>
        </form>
    );
}

export default EmailInput;
