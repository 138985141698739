import React, { useState, useEffect, useMemo } from 'react';
import Elementor from '../../Cells/Form/Elementor';
import { useSelector } from 'react-redux';
import { Styles } from './styles';
import { Image } from '../../Atoms';
import $ from 'jquery';
import closeIcon from '../../../Assets/Images/icon_close_new.png';

const Modal = (props) => {
  const {
    confirmationImage,
    confirmationMessage = '',
    elements = [],
    errorMessage = '',
    footerElements = [],
    modalBody = '',
    modalDescription = '',
    modalDialog,
    modalDisclaimer = '',
    modalFooter,
    modalHeader,
    modalId,
    modalTitle = '',
    productSelection,
    editable,
    elementsKeyToDisplay = [],
    elementsToDisplay = [],
    excludeValidations = [],
  } = props.modelData || {};
  const { onClose = () => { }, formName = '', content } = props;
  const modalStep1 = useSelector((storeState) => storeState?.disableButton?.modalStep);
  const [stepModal1, setStepModal1] = useState(0);
  const [hidingErrors, setHidingErrors] = useState(false);


  const underWritingQuestionTwo = useSelector(
    (storeState) => storeState?.employeeProfile?.underWritingQuestionTwo
  );
  const underWritingQuestionThree = useSelector(
    (storeState) => storeState?.employeeProfile?.underWritingQuestionThree
  );
  const underWritingQuestionFour = useSelector(
    (storeState) => storeState?.employeeProfile?.underWritingQuestionFour
  );



  useEffect(() => {
    setStepModal1(modalStep1);
  }, [modalStep1]);

  const { formik = {} } = props;
  const { values = {}, errors = {}, touched = {} } = formik;
  const formikValues = useMemo(() => {
    return values[formName];
  }, [values && values[formName]]);

  const formikErrors = useMemo(() => {
    return errors[formName];
  }, [errors && errors[formName]]);

  const formikTouched = useMemo(() => {
    return touched[formName];
  }, [touched && touched[formName]]);

  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
  const onCloseWindow = () => {
    $('#video1').get(0).pause();
    $('#video1').attr('src', $('#video1').values);
    $('#video1').get(0).load();
  };

  const displayElements = [];
  const excludeValidationElements = [];
  if (elementsKeyToDisplay.length > 0) {
    elementsKeyToDisplay.forEach(elementKey => {
      const keyToDisplay = formikValues && formikValues[elementKey];
      if (keyToDisplay) {
        excludeValidationElements.push(...excludeValidations[keyToDisplay]);
        displayElements.push(...elementsToDisplay[keyToDisplay]);
      }
    })
  }

  return (
    <React.Fragment>
      <Styles />
      <div className="member-container">
        <div className="modal fade" id={modalId} data-backdrop="static" aria-label={modalTitle}>
          <div className={'modal-dialog modal-xl ' + modalDialog} role="document">
            <div className="modal-content">
              <div className={'modal-header ' + modalHeader}>
                <div className="modal-title">{modalTitle}</div>
                {/* Delete onClick={onCloseWindow}
                  Because the onClosewindow, should be outside of the component
                  Please take care of it and add the corresponding change, in other way
                  the video won't work
                */}
                {/* <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() =>
                    productSelection ? onCloseWindow() : onClose(modalId, formName, formik)
                  }
                > */}

                <img
                  src={closeIcon}
                  alt="modal Close"
                  className="closeIcon"
                  style={{ cursor: 'pointer' }}
                  width="100%"
                  height="100%"
                  id={`${modalId}-cancelIcon`}
                  data-dismiss="modal"
                  onClick={() =>
                    productSelection ? onCloseWindow() : onClose(modalId, formName, formik)
                  }
                />
                {/* </button> */}
              </div>
              {modalDisclaimer && (
                <div className="col-12 modalDisclaimer">
                  <p className="modalDisclaimerText">{modalDisclaimer}</p>
                </div>
              )}
              {modalDescription && (
                <div className="col-12 modalDescription">
                  <p className="modalDescriptionText">{modalDescription}</p>
                </div>
              )}
              {confirmationMessage && (
                <div className="row">
                  <div className="w-50 mx-auto my-2">
                    <span className="model_confirmation_content">{confirmationMessage}</span>
                  </div>
                </div>
              )}
              {props.modelSubmitSuccess && confirmationImage && (
                <div className={'modal-body scrollbar ' + modalBody}>
                  <div className="row">
                    <div className="w-50 mx-auto">
                      <Image alt={confirmationImage.imgAlt} filename={confirmationImage.imgLink} />
                    </div>
                  </div>
                </div>
              )}
              {errorMessage && (
                <div className="row">
                  <div className="w-46 mx-auto my-2">
                    <span className="model_confirmation_content">{errorMessage}</span>
                  </div>
                </div>
              )}
              {!props.modelSubmitSuccess && (
                <div className={'modal-body scrollbar ' + modalBody}>
                  {elements.map((elementRow, index) => {
                    const shouldDisplayElement =
                      displayElements.length > 0 && displayElements.includes(index)
                        ? true
                        : displayElements.length === 0
                          ? true
                          : false;
                    return shouldDisplayElement ? (
                      <div className="row" key={elements.indexOf(elementRow)}>
                        {elementRow?.map((element) => {


                          return (
                            <div
                              key={element.name}
                              className={
                                element.hideElement
                                  ? 'hideElement'
                                  : element.placement
                                    ? element.shouldNotBeCol12
                                      ? `col-6  ${element.placement}`
                                      : `col-12  ${element.placement}`
                                    : 'col-12 sdf'
                              }
                            >
                              <Elementor
                                key={element.name}
                                element={element}
                                constantsList={constantsList}
                                elementId={`${formName}.${element.name}`}
                                formName={formName}
                                formik={formik}
                                excludeValidationElements={excludeValidationElements}
                              />
                              {formikErrors &&
                                formikErrors[element.name] &&
                                formikTouched &&
                                formikTouched[element.name] ? (
                                <div
                                  className=" error-message "
                                  // className={(hideError1||hideError2||hideError3)? 'hideElement' : 'error-message'}
                                  style={{
                                    color: content && content.layout && content.layout.errorColor,
                                    fontSize: '0.75rem',
                                  }}
                                >
                                  {formikErrors && formikErrors[element.name]}
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    );
                  })}
                </div>
              )}
              {Array.isArray(footerElements) && footerElements.length > 0 && (
                <div className={'modal-footer ' + modalFooter}>
                  {footerElements.map((elementRow) => {
                    return (
                      <div className="row" key={footerElements.indexOf(elementRow)}>
                        {elementRow.map((element) => {
                          return (
                            <div
                              key={element.name}
                              className={
                                element.hideElement
                                  ? 'hideElement'
                                  : element.placement
                                    ? `col-12 dsfsf ${element.placement}`
                                    : 'col-12 sdf'
                              }
                            >
                              <Elementor
                                key={element.name}
                                element={element}
                                constantsList={constantsList}
                                formName={formName}
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Modal);