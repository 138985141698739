import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/';
import { Styles } from './styles';
import { DISABLE_PROCESS } from '../../../Store/Slices/DisableButton';
import {
  UNDER_WRITING_QUESTION_ONE,
  UNDER_WRITING_QUESTION_TWO,
  UNDER_WRITING_QUESTION_THREE,
  UNDER_WRITING_QUESTION_FOUR,
  RADIO_SELECTED_WHOLE_LIFE
} from '../../../Store/Slices/EmployeeProfile';
import {
  RADIO_YES_SELECTED,
  RADIO_DISCLOSURE_SELECTED,
  RADIO_CLICKED,
  POLICY_RADIO_CLICKED,
  COVERAGE_RADIO_CLICKED,
  COVERAGE_RADIO_HOSPITAL_INDEMNITY_CLICKED,
  RADIO_DISCLOSURE_SELECTED_IDTHEFT,
  RADIO_DISCLOSURE_SELECTED_SUPPLEMENT_HEALTH_INSURANCE,
  RADIO_DISCLOSURE_SELECTED_TRAVEL,
  RADIO_DISCLOSURE_SELECTED_CRITICAL_ILLNESS,
  RADIO_DISCLOSURE_SELECTED_HOSPITAL_INDEMNITY,
  RADIO_DISCLOSURE_SELECTED_PET_NATION,
  POLICY_RADIO_CLICKED_HOSPITAL_INDEMNITY,
  COVERAGE_POLICY_RADIO_CLICKED,
  HI_POLICY_RADIO_CLICKED,
  SET_MFA_SERVICE_UNAVAILABLE
} from '../../../Store/Slices/RegisterationError';
import ServiceHelper from '../../../App/Utils/Helper/MappingHelper';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_CONTINUE_BUTTON, HIDE_BACK_BUTTON } from '../../../Store/Slices/StepForm';
import { ANSWERE_QUESTION, SET_ANSWER_OPTION, SET_QUESTIONS_ANSWERED, SHOWED_ERROR, UPDATE_ANSWERED_QUESTIONS, UPDATE_ANSWERES, UPDATE_ELIGIBLE_ANSWERES }
  from '../../../Store/Slices/InsuredInfo';
import { ADD_PHONE_VERIFICATION_METHOD, SET_LOGIN_OTP_METHOD, SET_MFA_CHANNEL, SET_NEW_MOBILE, SET_TEMP_LOGIN_OTP_METHOD } from '../../../Store/Slices/Registeration';
import { CHANGE_BUTTON_WIDTH } from '../../../Store/Slices/DisableButton';
import { SET_WAIVE_COVERAGE, SET_WAIVE_COVERAGE_NATION, SET_WAIVE_COVERAGE_VOYA } from '../../../Store/Slices/InsuredInfo';
import { UPDATE_CART_ITEM } from '../../../Store/Slices/EmployeeProfile';
import { SEND_CODE_MOBILE_CLICKED, SET_CONTACT_TYPE, SET_OTP, SET_RENDER, STORE_CONTENT, UPDATE_CONTENT, CHANGE_MOBILE_OPTION, ENABLE_SEND_CODE_BTN, MOBILE_OTP_ENTERED } from '../../../Store/Slices/CallCenter';

// import './styles.css';

const RadioButtonGroup = (props) => {
  const {
    inputs,
    name,
    onChange,
    isRadio,
    defaultValue,
    value,
    requiredValidation,
    hidden,
    radioClassWrapper,
    radioLabel,
    labelClassName,
    shouldShowOnYes,
    required,
    hideElement,
    forRegisteration,
    style,
    noNeedAstrik,
    answerRadio,
    //currentStep,
    isAnswerRadio,
    containerClass = '',
    sameLine,
    columnLayout,
    policyType,
    dispatchOnClick,
    hideOnClickSendCodeBtn=false,
    needReset=false,

  } = props;
  let radioClass = isRadio ? 'radioGroup' : 'radioButtonGroup';
  const dispatch = useDispatch();
  const serviceHelper = new ServiceHelper();
  let { codeIsEntered } = useSelector((storeState) => storeState.disableButton);
  const { stepId, currentStep } = useSelector((storeState) => storeState.stepForm);
  const { maskedEmail = '',  mfaChannel=false,phoneNumberFromOkta='' } = useSelector((storeState) => storeState.registeration);
  const callCenter = useSelector((storeState) => storeState.callCenter);

  /** disable continue button if security code not entered - for Registeration */
  forRegisteration && !codeIsEntered && dispatch(DISABLE_PROCESS({ disabled: true }));
  const [selectedName, setSelectedName] = useState('');
  // const { selectedoptionId,  CoverageSelection, IdTheft} = useSelector((storeState) => storeState.insuredInfo);
  const insuredInfo = useSelector((storeState) => storeState.insuredInfo);
  // const medicalSurveys = useSelector(
  //   (storeState) => storeState?.process?.enrollmentProcess[2]?.[0]?._Result.medicalSurveys
  // );

  const medicalSurveys = useSelector(
    (storeState) => storeState?.process?.medicalSurvey[0]?._Result.medicalSurveys
  );
  /** Set initial focus based on defpelementoraultValue */
  useEffect(() => {
    if (defaultValue) {
      onChange && onChange({ target: { name: name, value: value ? value : defaultValue } });
    }
  }, [defaultValue, value, name, onChange]);

 

  const [radioArray, setRadioArray] = useState([false, false]);
  let optionsNew
  if (columnLayout) {
    optionsNew = serviceHelper.getUnderWritingQuestions(name, radioLabel, medicalSurveys);
    // props.radioLabel = optionsNew;
    // props = {...props, radioLabel = optionsNew};

  }

  let mfaRadio = (name ==='MultiFactorAuthenticationLogin.channel' || 
                name === 'MFAForgotPassword.channel' ||
                name ==='MFAForgotUsername.MFAOptions') && mfaChannel

  let mfaRadioInitial = (name ==='MultiFactorAuthenticationLogin.channel' || 
                name === 'MFAForgotPassword.channel' ||
                name === 'MFAForgotUsername.MFAOptions')
  
  function renameKey ( obj, oldKey, newKey ) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
  const handleChange = (event) => {
    onChange && onChange(event);
    let radioDispatch = true;
    if (name === 'CoverageSelection.policyPrefSec') {
      if (event.target.value === 'Yes') {
        let tmpArr = radioArray;
        tmpArr[0] = true;
        setRadioArray(tmpArr);
        dispatch(POLICY_RADIO_CLICKED(true))
        dispatch(COVERAGE_POLICY_RADIO_CLICKED({ valueCode: event.target.value, fieldName: event.target.dataset.fieldname }))

      } else {
        let tmpArr = radioArray;
        tmpArr[0] = false;
        setRadioArray(tmpArr);
        dispatch(POLICY_RADIO_CLICKED(false))
        dispatch(COVERAGE_POLICY_RADIO_CLICKED({ valueCode: event.target.value, fieldName: event.target.dataset.fieldname }))


      }
    }

    if (name === 'HospitalIndemnity.policyPrefSec') {
      if (event.target.value === 'Yes') {
        let tmpArr = radioArray;
        tmpArr[0] = true;
        setRadioArray(tmpArr);
        dispatch(POLICY_RADIO_CLICKED_HOSPITAL_INDEMNITY(true))
        dispatch(HI_POLICY_RADIO_CLICKED({ valueCode: event.target.value, fieldName: event.target.dataset.fieldname }))
      } else {
        let tmpArr = radioArray;
        tmpArr[0] = false;
        setRadioArray(tmpArr);
        dispatch(POLICY_RADIO_CLICKED_HOSPITAL_INDEMNITY(false))
        dispatch(HI_POLICY_RADIO_CLICKED({ valueCode: event.target.value, fieldName: event.target.dataset.fieldname }))

      }
    }

    if (name === 'CoverageSelection.coveragePrefSec') {
      if (event.target.value === 'Yes') {
        let tmpArr = radioArray;
        tmpArr[1] = true;
        setRadioArray(tmpArr);
        dispatch(COVERAGE_RADIO_CLICKED(true))
        dispatch(COVERAGE_POLICY_RADIO_CLICKED({ valueCode: event.target.value, fieldName: event.target.dataset.fieldname }))

      } else {
        let tmpArr = radioArray;
        tmpArr[1] = false;
        // tmpArr[0] = true;
        setRadioArray(tmpArr);
        dispatch(COVERAGE_RADIO_CLICKED(false))
        dispatch(COVERAGE_POLICY_RADIO_CLICKED({ valueCode: event.target.value, fieldName: event.target.dataset.fieldname }))
      }
    }

    if (name === 'HospitalIndemnity.coveragePrefSec') {
      if (event.target.value === 'Yes') {
        let tmpArr = radioArray;
        tmpArr[1] = true;
        setRadioArray(tmpArr);
        dispatch(COVERAGE_RADIO_HOSPITAL_INDEMNITY_CLICKED(true))

      } else {
        let tmpArr = radioArray;
        tmpArr[1] = false;
        // tmpArr[0] = true;
        setRadioArray(tmpArr);
        dispatch(COVERAGE_RADIO_HOSPITAL_INDEMNITY_CLICKED(false))
      }
    }


    if (radioArray[0] == false && radioArray[1] == false) {
      radioDispatch = false;
    }

    dispatch(RADIO_YES_SELECTED(radioDispatch));

    if (name === 'CoverageSelection.termsAndConditions') {
      if (event.target.value === 'No') {
        dispatch(RADIO_DISCLOSURE_SELECTED(true));
      } else if (event.target.value === 'Yes') {
        dispatch(RADIO_DISCLOSURE_SELECTED(false));
      }
    }

    if (name === 'IdTheft.termsAndConditions') {
      if (event.target.value === 'No') {
        dispatch(RADIO_DISCLOSURE_SELECTED_IDTHEFT(true));
      } else if ((event.target.value === 'Yes')) {
        dispatch(RADIO_DISCLOSURE_SELECTED_IDTHEFT(false));
      }
    }

    if (name === 'SupplementalHealthInsurance.termsAndConditions') {
      if (event.target.value === 'No') {
        dispatch(RADIO_DISCLOSURE_SELECTED_SUPPLEMENT_HEALTH_INSURANCE(true));
      } else if ((event.target.value === 'Yes')) {
        dispatch(RADIO_DISCLOSURE_SELECTED_SUPPLEMENT_HEALTH_INSURANCE(false));
      }
    }

    if (name === 'Travel.termsAndConditions') {
      if (event.target.value === 'No') {
        dispatch(RADIO_DISCLOSURE_SELECTED_TRAVEL(true));
      } else if ((event.target.value === 'Yes')) {
        dispatch(RADIO_DISCLOSURE_SELECTED_TRAVEL(false));
      }
    }

    if (name === 'CriticalIllness.termsAndConditions') {
      if (event.target.value === 'No') {
        dispatch(RADIO_DISCLOSURE_SELECTED_CRITICAL_ILLNESS(true));
      } else if ((event.target.value === 'Yes')) {
        dispatch(RADIO_DISCLOSURE_SELECTED_CRITICAL_ILLNESS(false));
      }
    }

    if (name === 'HospitalIndemnity.termsAndConditions') {
      if (event.target.value === 'No') {
        dispatch(RADIO_DISCLOSURE_SELECTED_HOSPITAL_INDEMNITY(true));
      } else if ((event.target.value === 'Yes')) {
        dispatch(RADIO_DISCLOSURE_SELECTED_HOSPITAL_INDEMNITY(false));
      }
    }

    if (name === 'PetAssureNation.termsAndConditions') {
      if (event.target.value === 'No') {
        dispatch(ADD_PHONE_VERIFICATION_METHOD(event.target.value));
        dispatch(RADIO_DISCLOSURE_SELECTED_PET_NATION(true));
      } else if (event.target.value === 'Yes') {
        dispatch(RADIO_DISCLOSURE_SELECTED_PET_NATION(false));
      }
    }



    //check for if questions are selected then dispatch the values
    if (name === 'PetAssureNation.underwritingQuestionOne') {
      if (event.target.value === 'Yes') {
        dispatch(UNDER_WRITING_QUESTION_ONE(true));
      } else if (event.target.value === 'No') {
        dispatch(UNDER_WRITING_QUESTION_ONE(false));
      }

    }
    if (name === 'PetAssureNation.underwritingQuestionTwo') {
      if (event.target.value === 'Yes') {
        dispatch(UNDER_WRITING_QUESTION_TWO(true));
      } else if (event.target.value === 'No') {
        dispatch(UNDER_WRITING_QUESTION_TWO(false));
      }
    }
    if (name === 'PetAssureNation.underwritingQuestionThree') {
      if (event.target.value === 'Yes') {
        dispatch(UNDER_WRITING_QUESTION_THREE(true));
      } else if (event.target.value === 'No') {
        dispatch(UNDER_WRITING_QUESTION_THREE(false));
      }
    }
    if (name === 'PetAssureNation.underwritingQuestionFour') {
      if (event.target.value === 'Yes') {
        dispatch(UNDER_WRITING_QUESTION_FOUR(true));
      } else if (event.target.value === 'No') {
        dispatch(UNDER_WRITING_QUESTION_FOUR(false));
      }
    }

    if (name === 'WLCoverage.member') {


      let waiveObject = {
        status: true,
        waiveType: 'checkBox',
        isRadio: false,
        isCheckbox: true,

      };

      // dispatch(CHANGE_BUTTON_WIDTH({ currentStep: currentStep, waiveObject }));


      // let waiveObject = { status: true, waiveType: 'radio', isRadio: true, isCheckbox: true };
      // let waiveObject = { status: true, waiveType: 'checkbox', isRadio: false, isCheckbox: true };
      if (event.target.value === 'Yes') {
        dispatch(RADIO_SELECTED_WHOLE_LIFE(true));
        let waiveObject = {
          status: false,
          waiveType: 'checkBox',
          isRadio: true,
          isCheckbox: false,

        };
        dispatch(CHANGE_BUTTON_WIDTH({ currentStep: currentStep, waiveObject }));
        dispatch(
          UPDATE_CART_ITEM({
            id: props.currentStep,
            wholeLife: true,
            name: props.formData?.accidentInsurance,
            radioSelectedValue: "waive",
            coverageList: [
              {
                coverageLevel: 'Employee Only',
                planType: 'Low',

                costPerPayPeriod: '$0.00',
              }

            ],
          })
        );

      } else if (event.target.value === 'No') {
        SET_WAIVE_COVERAGE_VOYA({ status: event.target.checked, currentStep: currentStep })
        // dispatch(SET_WAIVE_COVERAGE({status: true,currentStep: currentStep}))
        dispatch(CHANGE_BUTTON_WIDTH({ currentStep: currentStep, waiveObject }));
        dispatch(RADIO_SELECTED_WHOLE_LIFE(false));
        dispatch(
          UPDATE_CART_ITEM({
            id: props.currentStep,
            wholeLife: true,
            name: props.formData?.accidentInsurance,
            radioSelectedValue: 0,
            coverageList: [
              {
                coverageLevel: 'Employee Only',
                planType: 'Low',

                costPerPayPeriod: '$0.00',
              },

            ],
          })
        );

      }
    }



    if (name === 'MultiFactorAuthentication.verificationMethod') {
      dispatch(ADD_PHONE_VERIFICATION_METHOD(event.target.value));
    }

   
    if (dispatchOnClick) {
      dispatch(SET_CONTACT_TYPE(event.target.value));
      let data  = callCenter.content.forms;

      // if(event.target.value === 'mobile'){
      //   let mobile = {"displayName": "Name Change Validation Test" }
      //   let  original = {"EmployeeValidationForgotUserID": {
      //     "displayNamee": "Employee Validation Test"}  }
      //   //dispatch(STORE_CONTENT(mobile))
      //   dispatch(UPDATE_CONTENT({key: 'ContactDetails', data:mobile, original: original}))

      // }
      // else if(event.target.value === 'email'){
      //  // let email = {...callCenter.content.forms, ...callCenter.emailContent.forms}
      //  let email = {"displayName": "Name Change Validation Test" }
      //   let  original = {"EmployeeValidationForgotUserID": {
      //   "displayNamee": "Employee Validation Test"}  }

       
      //   
      //   //dispatch(STORE_CONTENT(email))
      //   dispatch(UPDATE_CONTENT({key: 'ContactDetailsEmail', data:email, original: original}))


      // }
    }
    if(name === 'ContactTypes.contactType'){
      dispatch(SEND_CODE_MOBILE_CLICKED(false));
      dispatch(SET_NEW_MOBILE(''))
      dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: false, mfaErrorText: ''}));
      dispatch(SET_OTP(''))
    }
    /** handle OTP method - MFA login */
    if(name === 'MultiFactorAuthenticationLogin.channel' || 
     name === 'MFAForgotPassword.channel'  || 
     name === 'MFAForgotUsername.MFAOptions'){
      dispatch(SET_TEMP_LOGIN_OTP_METHOD(event.target.value))
     }
    /**disable continue btn on change mobile method */
    if(name === 'ContactDetails.verificationMethod' && callCenter.contactType === 'mobile'){
      dispatch(CHANGE_MOBILE_OPTION(true));
      dispatch(MOBILE_OTP_ENTERED(false))
      dispatch(ENABLE_SEND_CODE_BTN(true))
    }
  };

  const handleClick = (id, index, questionId, isAnswerRadio, inputValue, inputIndex, fieldValue) => {
    if (stepId > 2) {
      let DependentInformation;
      DependentInformation = stepId > 2 ? insuredInfo[currentStep].dependents : []
      DependentInformation = insuredInfo[currentStep].dependents || []

      let answer = id.includes('Yes')
        ? 'Yes'
        : id.includes('No')
          ? 'No'
          : '';
      //mark question as answered
      dispatch(ANSWERE_QUESTION({ index: index, id: questionId, answered: answer, inputId: inputIndex, currentStep: currentStep }))
      dispatch(UPDATE_ELIGIBLE_ANSWERES({ index: index, id: questionId, answered: answer, currentStep: currentStep, fieldValue }));
      let selectedName1 = DependentInformation[index].questionsAnswers ?
        DependentInformation[index].questionsAnswers[questionId].answers.inputs[inputIndex].name : '';
      //to control checked/unchecked for answers for all dependents
      selectedName1 && dispatch(SET_ANSWER_OPTION({
        inputValue: inputValue, inputId: id, selectedIndex: index, selectedQuestionId: questionId,
        selectedoptionId: selectedName1, currentStep: currentStep
      }));
      if (isAnswerRadio) {
        let answered = DependentInformation[index].questionsAnswers[questionId].question.answered;
        let selectedName1 = DependentInformation[index].questionsAnswers[questionId].answers.inputs[inputIndex].name;

        setSelectedName(selectedName1)
        if (!answered)
          dispatch(UPDATE_ANSWERED_QUESTIONS({ index: index, id: questionId, answered: answer, currentStep: currentStep }));
        else
          dispatch(UPDATE_ANSWERES({ index: index, id: questionId, answered: answer, currentStep: currentStep }));
      }
      dispatch(SHOWED_ERROR({ status: false, currentStep: currentStep }))
    }
  }

  const inputComponents = inputs.map((input, index) => {
    // let labelClass = (requiredValidation) ? 'isRequired ' : '';
    let labelClass = requiredValidation ? ' ' : ''; //no required color underline
    //labelClass = required ? 'required ' : '';

    if (index === 0) {
      labelClass += 'roundLeft mr-3';
    } else if (index === inputs.length - 1) {
      labelClass += 'roundRight';
    }
    const keyDate = new Date().getTime();
    let additionalClasses = hideElement ? 'hideElement' : '';
    let hideOnSend = callCenter. sendCodeMobileClicked && hideOnClickSendCodeBtn
    return (
      <>
        <div className={additionalClasses + radioClassWrapper} hidden={hidden || hideOnSend} style={style || {}}>
          <Styles />
          <label className={labelClass} key={input.value} hidden={hidden}>
            {/* <label className={labelClass} key={input.value} hidden={hidden} > */}
            <input
              type="radio"
              id={!answerRadio ? input.id || input.value : input.name}
              key={keyDate}
              name={!answerRadio ? name : input.name}
              hidden={hidden}
              checked={
                !needReset ?

                policyType === "policylist" && Array.isArray(value) && value.find(e => e['valueCode'] === input.value) ?
                  true
                  : !answerRadio ?
                    input.value === value :
                    input.name === selectedName ||
                    (stepId > 2 && insuredInfo[currentStep].selectedoptionId.length > 0 && insuredInfo[currentStep].selectedoptionId.includes(input.name))
                    // (insuredInfo[currentStep].selectedoptionId.length > 0 && insuredInfo[currentStep].selectedoptionId.includes(input.name)  ) 

                    || input.selected :
                    mfaRadio?  
                      input.value === value :
                    false}
              value={input.value}
              data-fieldName={input?.fieldName}
              onChange={handleChange}
              onClick={() => {
                answerRadio && handleClick(input.id, input.index, input.questionId, input.isAnswerRadio, input.value, input.inputIndex, input.fieldValue)
                mfaRadioInitial && dispatch(SET_MFA_CHANNEL(true))
              }}
            />
            <span key={input.icon} className={input.addSpaceClass && input.addSpaceClass}>
              <Icon icon={input.icon} />
              {/* {!input.extraData ? input.label : input.label + ' ' + maskedEmail} */}
              {!input.extraData ? 
                !input.updateLabelFromRedux?
                  input.label : 
                  input.label + ' ' +phoneNumberFromOkta
              :input.label + ' ' + maskedEmail}

            </span>
          </label>
        </div>
      </>
    );
  });
  const radioSelectedValue = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValue
  );
  const radioSelectedValueHospitalIndemnity = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueHospitalIndemnity
  );
  const radioSelectedValueExcessLiability = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiability
  );
  const radioSelectedValueExcessLiabilityUmUim = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiabilityUmUim
  );
  // const currentStep = useSelector(
  //   (storeState) => storeState?.stepForm?.currentStep
  // );

  let hideElementValue = false;
  if ((radioSelectedValue == 'Waive' || radioSelectedValue == -1) && currentStep == "CoverageSelection") {
    hideElementValue = true
  }
  else if ((radioSelectedValueHospitalIndemnity == 'Waive' || radioSelectedValueHospitalIndemnity == -1) && currentStep == "HospitalIndemnity") {
    hideElementValue = true
  }
  else if ((radioSelectedValueExcessLiability == 'Waive' || radioSelectedValueExcessLiability == -1) && currentStep == "ExcessLiability") {
    hideElementValue = true
  }
  else if ((radioSelectedValueExcessLiabilityUmUim == 'Waive' || radioSelectedValueExcessLiabilityUmUim == -1) && currentStep == "ExcessLiability") {
    hideElementValue = true
  }
  const keyDate = new Date().getTime();
  // let additionalClasses = radioClassWrapper ? radioClassWrapper : 'pb-3';
  let additionalClasses = hideElement ? 'hideElement' : '';
  // if (!radioAdditionalClasses )
  return (
    //Check here for not displaying on first row checked issue
    <div>
      {columnLayout && (
        <div className="row">
          <div className="col-8" hidden={hidden}>
            {optionsNew} {required && radioLabel && !noNeedAstrik && <span>*</span>}
          </div>
          <div className="col-4 text-right" hidden={hidden}>
            {sameLine && inputComponents}
          </div>
        </div>
      )}

      {!columnLayout && (
        <div
          className={
            !answerRadio
              ? shouldShowOnYes && hideElementValue
                ? 'hideElement '
                : `${radioClass} w-100 ${containerClass} ${additionalClasses}`
              : isAnswerRadio
                ? ` row pr-xl-2 mr-xl-0 mr-md-2 mr-0 ml-xl-5 ml-md-4 ml-2 pr-0  ${radioClass} w-100 ${additionalClasses}`
                : `row pr-xl-2 mr-xl-2 ${radioClass} w-100 ${additionalClasses}`
          }
          style={answerRadio && { justifyContent: 'end', marginLeft: '2.6rem !importnat', }}
          key={keyDate}
          hidden={hidden}
        >
          <div className="row">
            <div className={labelClassName} hidden={hidden}>
              {radioLabel} {required && radioLabel && !noNeedAstrik && <span>*</span>}{' '}
              {sameLine && inputComponents}
            </div>
          </div>
          {!sameLine && inputComponents}
        </div>
      )}
    </div>
  );
  // else
  // return (
  //   <div className={`${radioClass}  ${additionalClasses} row py-3 no-gutters `} key={keyDate}>

  //    {inputComponents}
  //   </div>
  // );
};
RadioButtonGroup.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  hidden: PropTypes.bool,
  //required: PropTypes.bool,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      label: PropTypes.string,
    })
  ),
};
RadioButtonGroup.defaultProps = {
  inputs: [],
  name: '',
  //required: false,
};

export default RadioButtonGroup;