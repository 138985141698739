import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StrengthMeterHelper from '../../../../Utils/Helper/StrengthMeter';
import constants from '../../Content/Constants.json';
import arrowDown from '../../../../../Assets/Images/arrow-down.svg';
import arrowUp from '../../../../../Assets/Images/sm-arrow-up.svg';

const AccordionItem = ({
  insured,
  additionalQuestions,
  handleAnswerChange,
  InsuredQuestionAnswers,
  currentBuid,
  displayMode,
  disableAccordian = false,
  coverageError = [],
  defaultOpen = "No"
}) => {
  const buid = insured?.personBuid;
  currentBuid && currentBuid(buid);
  let questions = additionalQuestions?.filter(
    (item) => item?.insuredPerson?.bUID === buid && item?.additionalDatas?.length
  ) ?? [];
  if (questions.length) {
    questions = questions[0]?.additionalDatas ?? [];
  }
  let dependentPersonalDetails = {};

  if (insured?.insured) {
    const dependentData = insured?.insured;
    const actor = dependentData?.person?.data?.actor;
    const correspondence = dependentData?.person?.data?.correspondences?.[0];

    if (actor && correspondence) {
      dependentPersonalDetails = {
        beneficiaryFirstName: actor?.shortName,
        beneficiaryGender: actor?.gender,
        beneficiaryLastName: actor?.name,
        beneficiaryMiddleName: actor?.middleName1,
        beneficiaryRelationship: dependentData?.insured?.mainOwnerRelationshipToInsured,
      };
    }
  }

  const getRelationshipLabel = (relationshipValue) => {
    const option = Object.values(constants?.relationship ?? {}).find(
      (option) => option.value === relationshipValue || option.label === relationshipValue
    );
    return option ? option.label : '';
  };

  // const [isOpen, setIsOpen] = useState(questions.length > 0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (disableAccordian === true) {
      setIsOpen(false);
    }
    else if (defaultOpen === "No") {
      setIsOpen(false);
    }
    else if (disableAccordian === false) {
      setIsOpen(true);
    }
  }, [disableAccordian]);

  let errorData = false;
  let eligibilityComment = "";
  coverageError.forEach((pobj) => {
    if (pobj.error && pobj.buid === buid) {
      errorData = true;
      eligibilityComment = pobj.eligibilityComment;
    }
  });

  const handleAccordionToggle = () => {
    if (questions.length > 0) {
      setIsOpen(!isOpen);
    }
  };

  let downArrowSrc, upArrowSrc;
  try {
    downArrowSrc = arrowDown;
  } catch (e) {
    downArrowSrc = '';
  }
  try {
    upArrowSrc = arrowUp;
  } catch (e) {
    upArrowSrc = '';
  }

  // Create an instance of StrengthMeterHelper
  const strengthMeterHelper = new StrengthMeterHelper();

  const totalQuestions = questions?.length ?? 0;

  // Find the answers for the current insured
  const currentDependentAnswers = InsuredQuestionAnswers?.[buid] ?? {};

  // Calculate the number of answered questions
  // const answeredQuestions = currentDependentAnswers
  //   ? Object.values(currentDependentAnswers).filter((answer) => answer).length
  //   : 0;

  const answeredQuestions = currentDependentAnswers
    ? Object.entries(currentDependentAnswers).filter(([question, answer]) => questions.find(item => item.fieldName === question) && answer).length
    : 0;

  const progressPercentage = (answeredQuestions / totalQuestions) * 100;
  // Get the bar colors based on the number of answered questions
  const answerBarStyle = strengthMeterHelper.getAnswerBarColor(answeredQuestions, {
    layout: {
      answerBarStyle: {
        barColors: {
          defaultColor: '#DADADA',
          progressColor: '#57C67A',
        },
        width: '25%',
        height: '0.25rem',
        messageHeight: '1rem',
        progressClasses: {
          rowWrapper: 'row progress-bg ml-0 mr-0',
          colWrapper: 'col-12 progress mt-1 mb-0 mr-0 pr-0',
        },
      },
    },
  });


  return (
    <div>
      <div className="card custom-accordion">
        <div className="card-header">
          <div className='accordionHeader' onClick={questions.length > 0 && disableAccordian === false && !errorData  ? handleAccordionToggle :  () => {}}>
            {questions.length > 0 && disableAccordian === false && !errorData &&  (<img
              src={isOpen ? upArrowSrc : downArrowSrc}
              alt="Arrow Icon"
              style={{
                cursor: "pointer",
              }}
            />)
            }
            <div className="accordion-item">
              <div>{dependentPersonalDetails.beneficiaryFirstName} {` `}  {dependentPersonalDetails.beneficiaryLastName} {` (${getRelationshipLabel(dependentPersonalDetails
                ?.beneficiaryRelationship)})`} </div>
              {/* Other accordion elements */}
            </div>
            {
              errorData ? (
                <div className=""
                  style={{ color: 'Red', width: '100%', textAlign: 'right', paddingTop: '0.5rem', fontSize: '0.83rem' }}>
                  {eligibilityComment}
                </div>
              ) : (
                <>
                  {questions.length > 0 && (
                    <div className="accordion-item status">
                      {/* Other accordion elements */}
                      <div
                        className="progress-bar"
                        style={{
                          backgroundColor:
                            answerBarStyle.barColors?.firstBarColor, // Use the appropriate bar color
                          height: answerBarStyle.height,
                          width: `${progressPercentage}%`,
                        }}
                      ></div>
                    </div>
                  )}
                  {questions.length > 0 && (
                    <div className='statusMessage'> {answeredQuestions} of {' '}  {questions?.length} </div>
                  )}
                </>
              )
            }
          </div>
        </div>

        <div className={isOpen ? "card-body" : "card-body dispnone"}>
          {questions?.map((question, index) => (
            <div key={question?.id} className="d-flex justify-content-between">
              <p className="mb-4">{question?.presentedName}</p>
              <div className="insured-smoker-radio">
                <fieldset>
                  <legend class="lable-sr-only" area-hidden='true'></legend>
                  {question?.possibleValues?.map((option, childIndex) => (
                    <label for={`${index}-${buid}-${option?.valueCode}`} className="form-check form-check-inline" key={option?.valueCode}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`${question?.fieldName}-${buid}`}
                        id={`${index}-${buid}-${option?.valueCode}`}
                        value={option?.valueCode}
                        checked={currentDependentAnswers?.[question?.fieldName] === option?.valueCode ? true : false}
                        onChange={(e) => handleAnswerChange(buid, question?.fieldName, e.target.value)}
                      />
                      <span className="form-check-label" for={`${index}-${buid}-${option?.valueCode}`}>
                        {option?.valueCode}
                      </span>
                    </label>
                  ))}
                </fieldset>
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default AccordionItem;









