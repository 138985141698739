import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.footer {

  min-height:6rem; 
  background: #FFFFFF ;
  box-shadow: 0rem -0.062rem 0rem #DADADA;
  background-color:  #FFFFFF
  opacity: 1;
  background-color:  ${({ theme }) =>
  theme.components && theme.components.footer && theme.components.footer.footerBgColor};
  background-color:  #FFFFFF

}

.copyright {
  text-align: left;
  letter-spacing: 0rem;
  opacity: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  font-family:  NotoSansRegular;
  background-color:${({ theme }) =>
    theme.components && theme.components.footer && theme.components.footer.footerBgColor};
  color:${({ theme }) =>
    theme.components &&
    theme.components.footer &&
    theme.components.footer.footerCopyrightFontColor};
    color: #202020;
    background-color:  #FFFFFF

}

`;
