import React from 'react';
// import './styles.css';
import { Styles } from './styles';
import { Image, Backdrop } from '../../../Atoms';

const NavItem = (props) => {
  let linkClasses = 'nav-item mr-auto';
  let navBackDrop;
  let minusIcon;
  let plusIcon;
  let onItemClick;
  let textIcon;

  if (props.expanded && props.children) {
    navBackDrop = <Backdrop handleOnHover={() => props.handleOnClick(props.item)} />;
  }

  if (!props.sideBarOpen) {
    onItemClick = () => props.handleOnClick(props.item);
  }

  if (props.sideBarOpen && props.children && !props.expanded) {
    plusIcon = (
      <Image
        filename={props.downArrow || "icon_expand_plus.png"}
        className="plusIcon"
        onClick={() => props.handleOnClick(props.item)}
      ></Image>
    );
  }
  if (props.sideBarOpen && props.expanded) {
    linkClasses += ' sidebarLinkConatiner';

    minusIcon = (
      <Image
        filename={props.upArrow || "icon_collapse_minus.png"}
        className="minusIcon"
        onClick={() => props.handleOnClick(props.item)}
      ></Image>
    );
  }
  if (props.filename) {
    textIcon = (
      <Image filename={props.filename} className="textIcon" />
    );
  }

  return (
    <React.Fragment>
      <Styles />
      <div className="navContainer">
        <li className={linkClasses} key={props.item}>
          <div className="nav-link-o" onMouseEnter={onItemClick} onMouseLeave={onItemClick}>
            {textIcon}
            {props.item}
            {minusIcon}
            {props.expanded && props.children}
            {!props.sideBarOpen && navBackDrop}
          </div>
          {plusIcon}
        </li>
      </div>
    </React.Fragment>
  );
};

export default NavItem;
