import ImageLayout from '../../Atoms/ImageLayout';
import MFAImage from '../../../../../Assets/Images/new-mfa.png';
import MfaContainer from './MfaContainer'

const Mfa = (props) => {
    return (
        <ImageLayout image={{ src: MFAImage, alt: "MFA"}} content={''} className={""} >
         <MfaContainer {...props} />
        </ImageLayout>
    )
}
export default Mfa;
