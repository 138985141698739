import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import constants from '../../Content/Constants.json';
import moment from 'moment';

const DependentList = (props) => {
  let { initialDependents, onEdit, onDelete, previousDependentData, disabledData } = props;
  const insuredInfo = useSelector((storeState) => storeState?.insuredInfo);
  let DependentListDetails = insuredInfo?.[props.currentStep]?.updatedDependents?.filter((o) => {
    return o.hasOwnProperty(props.personBuid) ? o : "";
  });

  const [error, setError] = useState('');
  const dispatch = useDispatch();
  let filteredData = initialDependents?.filter(item => {
    return previousDependentData?.every(prevItem => {
      return prevItem?.id === item?.id && prevItem?.mainBuid === item?.mainBuid
    });
  }) || [];
  useEffect(() => {
    if (DependentListDetails?.length > 0) {
      updateRedux(DependentListDetails[0]?.[`${props.personBuid}`])
    }
  }, [DependentListDetails?.length > 0]);

  const updateRedux = (data) => {
    // dispatch(UPDATE_Dependents({ name: props.personBuid, data: deepCopy(data), currentStep: props.currentStep }));
    //  dispatch(UPDATE_UPDATED_Dependents({ name: props.personBuid, data: deepCopy(data) }));
  };


  const getRelationshipLabel = (relationshipValue, dependent) => {
    const option = constants?.dependentRelationship?.find(
      (option) => option.value === relationshipValue || option.label === relationshipValue
    );

    return option ? option.label : '';

  };


  const handleDeleteRow = (index) => {
    if (!initialDependents || index >= initialDependents.length || !onDelete) return;
    const dependentToDelete = initialDependents[index];
    if (!dependentToDelete || !dependentToDelete.mainBuid) return;
    onDelete({ dependentBuid: dependentToDelete.mainBuid });
  };


  const gender = {
    gMale: 'Male',
    gFemale: 'Female',
    gUndisclosed: 'Undisclosed',
  };

  return (
    <div className="table-responsive dependent-table">
      <table className="table table-striped">
        <thead>
          <tr>

            <th>NAME</th>
            <th>RELATIONSHIP</th>
            <th>GENDER</th>
            <th>DATE OF BIRTH</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {initialDependents.map((dependent, index) => {
            const isTrust = dependent.dependentOptions === 'trust';
            const dependentName = !isTrust
              ? `${dependent.dependentFirstName} ${` `} ${dependent.dependentLastName}`
              : dependent.legalName;

            let relationShip = !isTrust
              ? getRelationshipLabel(dependent?.dependentRelationship, dependent)
              : '';

            if (isTrust) relationShip = getRelationshipLabel('TGE');
            let isDelete = false
            if (filteredData?.filter(e => e.mainBuid === dependent.mainBuid).length > 0) {
              isDelete = true
            }
            let isDeleteData = disabledData && isDelete ? true : false
            let isEdit = isDeleteData
            return (
              <tr key={index}>

                <td>{dependentName}</td>

                <td>{relationShip}</td>

                <td>  {gender[dependent?.dependentGender]}</td>
                <td>  {moment(dependent?.dependentBirthDate).format('MM-DD-YYYY')}</td>
                <td>

                  <>
                    <button className="btn-icon"  aria-label="Dependent edit button" name="dependent-edit-button" type="button" onClick={() => onEdit(index, isEdit)}>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="pen"
                        class="svg-inline--fa fa-pen fa-w-16 action"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
                        ></path>
                      </svg>
                    </button>
                    <button disabled={isDeleteData} className="btn-icon" aria-label="Dependent delete button" name="dependent-delete-button" type="button" onClick={() => handleDeleteRow(index)} >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="trash-alt"
                        class="svg-inline--fa fa-trash-alt fa-w-14 action"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path className={`${isDeleteData ? 'disabled' : 'enabled '}`}
                          fill="currentColor"
                          d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                        ></path>
                      </svg>
                    </button>
                  </>

                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

    </div>
  );
};

export default DependentList;
