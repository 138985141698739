import React, { useState, useEffect } from 'react';
import CardSelector from '../CardSelector/index';
import { APIRequestAction, onLoadAPI, onLoginAPI } from '../../../../Store/Slices/Entities/response';

import { APIRequestParallel } from '../../../../Store/Slices/Entities/response/index';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Modal from '../../../Molecules/Modal';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import * as Validator from '../../../Utils/Validator';
import { getKeyValuePairs } from '../../../Utils/Helper/ValidationHelper';
import { validationConstants } from '../../../../Common/Constants/ValidationConstants';
import { reduxConstants } from '../../../../Common/Constants/index';
import $ from 'jquery';
import http from '../../../../Common/Services/HttpService/index';
import { CipherUtils, GetUrlParams } from '../../../Utils/Helper/MFAHelper';
import { apiReceivedMFA, apiReceivedUserTypeAndLanguage, processTypeForSSO, processTypeMFA, RESET_EMP_FILTER, setProcessType, setSSOLoginError, START_PROXY_ENROLL, userTypeMFA } from '../../../../Store/Slices/Auth/userAuth';
import { apiReceived } from '../../../../Store/Slices/Auth/loggedUserEntity';
import { SET_MFA_API_PARAM, SET_MFA_LOGIN, SET_MFA_URL } from '../../../../Store/Slices/Registeration';
import APIRequest from '../../../Utils/Helper/APIHelper';
import appConfig from '../../../../appConfig';
import { useHistory } from 'react-router-dom';
import { SET_CALL_CENTER_USER, SET_REDIRECT_TO_ENROLLMENT } from '../../../../Store/Slices/CallCenter';

const CardGenerator = (props) => {
  const {
    showContent = [],
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};
  const {userAuth} = useSelector((storeState) => storeState?.auth);
  const { clientName='test', callCenterUser=false } = useSelector((storeState) => storeState.callCenter);
  const { content = {} } = props || {};
  const { layout = {} } = props.content || {};

  const [contentData, SetContentData] = useState({
    formData: {},
    steps: {},
    stepsList: {},
    stepKeys: [],
    cardsList: {},
  });
  let buidtemp = '';
  let [eee, SetEee] = useState({});
  const [finishStatus, setfinishStatus] = useState(false);

  const submitMFAToken = async (encrypted, userPrefix) => {
    let decrypted;
    let value = decodeURIComponent(encrypted);
    if (value) {
      try {
        decrypted = await CipherUtils.decrypt(value, process.env.REACT_APP_SSO_PRIVATE_KEY);
      } catch (err) {
        console.error('crypto support is disabled!');
      }
      // const email = process.env.REACT_APP_LOGIN_PREFIX+decrypted//.slice(4);
      let nonSSOUser = userPrefix && userPrefix === 'nonsso'
      const email =nonSSOUser ? 
        process.env.REACT_APP_LOGIN_PREFIX+decrypted :
        process.env.REACT_APP_LOGIN_PREFIX_FOR_SSO+decrypted 
         

      dispatch(SET_MFA_LOGIN({ decryptedEmail: decrypted, email: email }));
      await dispatch(apiReceivedMFA(email));
      dispatch(apiReceivedUserTypeAndLanguage({userType: 'Enrollment', language:'en'}))
      /** Second API call - getUserBUID */
      const userName = { loginName: email };
      // let authPreRequest = dispatch(
      //   APIRequestAction({ userName }, { apiconfig: { url: '/getLoginUser' } })
      // );
      // // if (!authPreRequest) {
      // //   setLoginError(true);
      // // }
      // authPreRequest.then(async (result) => {
      //   let data = {};
      //   result = result?._Result;
      //   Object.assign(data, { person: result });
      //   const firstName = data?.person?.actor?.shortName;
      //   const lastName = data?.person?.actor?.name;
      //   /** store reponse object in store */
      //   await dispatch(apiReceived(data));
      //   let authReqData;
      //   authReqData = result && result ? { buid: result.bUID } : {};
      //     /** Third API call - getUserDetaile */
      //     let loggedUserEntity = await dispatch(
      //       APIRequestAction({ authReqData }, { apiconfig: { url: '/getEmployments' } })
      //     );
          
      //     Object.assign(data, { personDetails: loggedUserEntity });
      //     /** store reponse object in store */
      //     await dispatch(apiReceived(data));
      //     // setUserAuthenticated(true);
      // });
    }
  };

  const submitClient = async (encryptedClient) => {
    let decryptedClient;
    let ClientValue = decodeURIComponent(encryptedClient);
    if (ClientValue) {
      try {
        decryptedClient = await CipherUtils.decrypt(ClientValue, process.env.REACT_APP_SSO_PRIVATE_KEY);
      } catch (err) {
        console.error('crypto support is disabled!');
      }
      decryptedClient && dispatch(SET_MFA_API_PARAM({legalEntityId:decryptedClient}))
    }
  };

  useEffect(() => {
    /* use effect to form yupper and other formik name pair */
    const updateEee = () => {
      if (
        content.layout &&
        content.layout.viewType &&
        props.contentData.panelsData &&
        props.contentData.panelsData[content.layout.viewType] &&
        props.contentData.panelsData[content.layout.viewType].cards
      ) {
        props.contentData.panelsData[content.layout.viewType].cards.map((card) => {
          card.elements &&
            card.elements.map((cardElements) => {
              cardElements.cardMembers.map((cardMember) => {
                let curCardElements = [];
                // In cardMember contains subcard elements
                let loopList = ['panelCard', 'blueBox'];
                switch (cardElements.type) {
                  case 'panelCard':
                    cardMember.cardElements.map((element) => {
                      if (element?.panelHeader?.elements) {
                        curCardElements = [...curCardElements, ...element?.panelHeader?.elements];
                      }
                      if (element?.panelBody?.elements) {
                        element.panelBody.elements.map((cardInnerElement) => {
                          if (
                            loopList.indexOf(cardInnerElement.type) != -1 &&
                            cardInnerElement?.cardMember?.cardElements
                          ) {
                            curCardElements = [
                              ...curCardElements,
                              ...cardInnerElement.cardMember.cardElements,
                            ];
                          } else {
                            curCardElements = [...curCardElements, cardInnerElement];
                          }
                        });
                      }
                      if (element?.panelFooter?.elements) {
                        curCardElements = [...curCardElements, ...element?.panelFooter?.elements];
                      }
                    });
                    break;
                  case 'blueBox':
                    cardMember.cardElements.map((element) => {
                      if (element?.panelHeader?.elements) {
                        curCardElements = [...curCardElements, ...element?.panelHeader?.elements];
                      }
                      if (element?.panelBody?.elements) {
                        curCardElements = [...curCardElements, ...element?.panelBody?.elements];
                      }
                      if (element?.panelFooter?.elements) {
                        curCardElements = [...curCardElements, ...element?.panelFooter?.elements];
                      }
                    });
                    break;
                  default:
                    curCardElements = cardMember?.cardElements && cardMember.cardElements;
                }

                let tempVals = {};
                let tempValidations = {};
                let tempYup = {};
                tempVals = getKeyValuePairs([curCardElements])['nameValuePairs'];
                tempValidations = getKeyValuePairs([curCardElements])['nameValidationPairs'];

                for (let indElement in tempValidations) {
                  // loops through the validation type: string, number, date, array
                  for (let validationType in tempValidations[indElement]) {
                    let indYupper = Yup[validationType]();

                    // loops through the validation function: required, min, max, regex
                    for (let indValidation in tempValidations[indElement][validationType]) {
                      indYupper = Validator[validationConstants[validationType]](
                        Yup,
                        indYupper,
                        indValidation,
                        validationType,
                        indElement,
                        tempValidations
                      );
                    }

                    tempYup = {
                      ...tempYup,
                      [indElement]: indYupper,
                    };
                  }
                }

                SetEee((prevEeee) => {
                  let nameValuePairs = { ...prevEeee.nameValuePairs };
                  let nameValidationPairs = { ...prevEeee.nameValidationPairs };
                  let yupper = { ...prevEeee.yupper };

                  nameValuePairs = {
                    ...nameValuePairs,
                    [cardElements.name]: { ...tempVals },
                  };

                  nameValidationPairs = {
                    ...nameValidationPairs,
                    [cardElements.name]: { ...tempValidations },
                  };

                  yupper = {
                    ...yupper,
                    [cardElements.name]: Yup.object().shape({ ...tempYup }),
                  };

                  return {
                    ...prevEeee,
                    nameValuePairs: { ...nameValuePairs },
                    nameValidationPairs: { ...nameValidationPairs },
                    yupper: { ...yupper },
                  };
                });
              });
              //  cardsValidation = { ...cardsValidation, [cardElements.name]: {} };
            });
        });
      }
    };

    const eeeUpate = async () => {
      await updateEee();
    };
    eeeUpate();
  }, [props.contentData]);
  let [result, setResult] = useState({});
  useEffect(() => {
    /* use effect to get forms which contains element properties */
    setResult(props.result);
  }, [props.result]);

  //sso
  useEffect(() => {
    let processType = userAuth.processType ;
    if(!processType){
      let remoteUserObject = GetUrlParams();
      if(!remoteUserObject.SSOloginError){
        dispatch(setSSOLoginError(false));
        let isEeelectUser = remoteUserObject.processType === 'eelect';
        if (isEeelectUser){
          http.setUserType('Enrollment')
          dispatch(setProcessType(remoteUserObject.processType))
        }
        remoteUserObject.processType === 'eelect' && submitMFAToken(remoteUserObject.userFromUrl, remoteUserObject.userPrefix)
        remoteUserObject.clientId && submitClient(remoteUserObject.clientId) // store client for configurator
      }
      else
        dispatch(setSSOLoginError(true));
    }
  }, []);
  
  useEffect(()=>{
    if(userAuth.processType==='proxyEnrollment')
      submitMFAToken1()
  },[window.location.pathname])
  
  // SSO-SSP :for call center only
  const submitMFAToken1 = async (params) => {
    let remote_user =    window.location.pathname.indexOf('enrollment') === -1 ?
      userAuth.data?.loggedUser:
    userAuth.employeeEmail;

    let decrypted  = `${process.env.REACT_APP_LOGIN_PREFIX}${remote_user}`;
    if (remote_user) {
        window.location.pathname.indexOf('enrollment') === -1 ? http.setUserType('CallCenter') : http.setUserType('Enrollment');
        http.setLanguage('en');
        dispatch(SET_MFA_LOGIN({ decryptedEmail: decrypted, email: remote_user }));
        //await dispatch(apiReceivedMFA(decrypted));
        /** Second API call - getUserBUID */
        // const userName = { loginName: decrypted };
        // let authPreRequest = dispatch(
        //   APIRequestAction({ userName }, { apiconfig: { url: '/getLoginUser' } })
        // );
        // authPreRequest.then(async (result) => {
        //   let data = {};
        //   result = result._Result;
        //   Object.assign(data, { person: result });
        //   /** store reponse object in store */
        //   await dispatch(apiReceived(data));
        //   let authReqData;
        //   authReqData = result && result ? { buid: result.bUID } : {};
        //   /** Third API call - getUserDetaile */
        //   let loggedUserEntity = await dispatch(
        //     APIRequestAction({ authReqData }, { apiconfig: { url: '/getEmployments' } })
        //     //APIRequestAction({ authReqData }, { apiconfig: { url: '/getEmpData' } })
        //   );
        //   let onLoginApiCalls = [
        //     {
        //       apiconfig: {
        //         url: '/gbpForLegalEntity',
        //         APIfn: 'getAPIResponse',
        //         objectName: 'gbpForLegalEntity',
        //         saveInRedux: true,
         //       },
        //     },
        //   ];
        //   Object.assign(data, { personDetails: loggedUserEntity });
        //   if (onLoginApiCalls) {
        //     await APIRequest(onLoginApiCalls, { loggedUserEntity }).then((data) => {
        //       if (data) {
        //         dispatch(onLoginAPI(data));
        //       }
        //     });
        //   }
        //   /** store reponse object in store */
        //   await dispatch(apiReceived(data));
        // });
      }
      dispatch(SET_CALL_CENTER_USER(callCenterUser))
  }  

  // call center only - handle back
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if ((userAuth.currentProcessType==="SearchEmployee" || userAuth.currentProcessType==="EmployeeData")&& userAuth.currentURL ) {
        setfinishStatus(true)
        dispatch(START_PROXY_ENROLL(false))
        dispatch(SET_REDIRECT_TO_ENROLLMENT(false))
        dispatch(RESET_EMP_FILTER(true))

        history.push(userAuth.currentURL);
      } else {
        //history.push('');
        //setfinishStatus(false)
      }
    }
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);
  
 
  
  const dataEmployments = useSelector(
    (storeState) => storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result
  );

  if (dataEmployments?.length > 0) {
    buidtemp = dataEmployments[dataEmployments?.length - 1]?.bUID;
  }

  const personBuid = useSelector(
    (storeState) => storeState?.auth?.loggedUserEntity?.data?.person?.bUID
  );

  const legalEntityBuid = useSelector(
    (storeState) =>
      storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.legalEntity?.identifier
        ?.bUID
    // (storeState) => storeState?.auth?.loggedUserEntity?.data?.person?.personDetails?._Result[2]
  );

  const contractBuid = useSelector((storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.subscribedcontracts?._Result && 
      storeState?.entities?.response?.onLoadResponseList?.subscribedcontracts?._Result[0]?.contract?.buid
  );
  const zipCode = useSelector(

    (storeState) =>
    
      storeState?.employeeProfile?.employeeProfile?.zipCode

  );


  useEffect(() => {
    /* useEffect to decide whether add button has to display or not*/
    const callAPI = async () => {
      if (
        props.content &&
        props.content.layout &&
        props.content.layout.onLoadApiCall &&
        props.content.layout.viewType &&
        Object.keys(loggedUserEntity).length > 0
      ) {
        let onLoadCalls = props.content.layout.onLoadApiCall;
        let data = await dispatch(
          APIRequestParallel(
            onLoadCalls,

            {
              additionalInformation: {
                employeebuid: buidtemp,
                personBuid: personBuid,
                legalEntityBuid: legalEntityBuid,
                contractBuid: contractBuid,
                zipCode:zipCode
              },
            },
            { loggedUserEntity }
          )
        );
        if (data) {
          const { setResult = () => { } } = props;
          let directObject = {};
          onLoadCalls.forEach((apiCalls) => {
            let { objectName } = apiCalls.apiconfig || {};
            let curObj = { [objectName]: data[objectName] };
            directObject = { ...directObject, ...curObj };
            setResult(directObject);
          });
        }
      }
    };

    callAPI();
  }, [props.content, loggedUserEntity]);

  let formik = {};

  const newFormik = useFormik({
    //function to make the submit call based on teh form kind
    enableReinitialize: true,
    validationDisabled: true,
    initialValues: { ...eee.nameValuePairs },
    validationSchema: Yup.object(eee.yupper),
    onSubmit: (values) => {
      return new Promise((res) => res);
    },
  });
  if (!props.formik) {
    formik = newFormik;
  } else {
    formik = props.formik;
  }
  //commented px-md-5 fo fix alignment in MFA screen
  return (
    !userAuth.SSOLoginError && (
    <div className={`${layout.formType === 'searchForm' ? 'searchContainer' : 'container-fluid'}`}>
      {
        <div className="row">
          {/* <div className={`col-sm-9 col-md-12  stepFormWrapper px-md-5  col-lg-12 py-4`}> */}
          <div className={`col-sm-9 col-md-12  stepFormWrapper col-lg-12 py-4 px-md-0`}>
            <>
              {props.content &&
                props.content.layout &&
                props.content.layout.modals &&
                props.content.layout.modals.map((modal, index) => {
                  return (
                    <Modal {...props} key={index} parentAction={() => { }} modelData={modal}>
                      {' '}
                    </Modal>
                  );
                })}
            </>
            <div className={`${props.cardBorder || ''}`}>
              {content.layout &&
                content.layout.viewType &&
                props.contentData.panelsData &&
                props.contentData.panelsData[content.layout.viewType] &&
                props.contentData.panelsData[content.layout.viewType].cards &&
                props.contentData.panelsData[content.layout.viewType].multipleColoumsCard && (
                  <div className="row">
                    {props.contentData.panelsData[content.layout.viewType].cards.map(
                      (cardCol, index, innerIndex) => {
                        return (
                          <div className={cardCol?.coloumConfig?.className}>
                            {cardCol.elements.map((element, innerIndex) => {
                              return (
                                <CardSelector
                                  {...props}
                                  key={`${index} element ${innerIndex}`}
                                  element={element}
                                  result={result}
                                  formik={formik}
                                />
                              );
                            })}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}

              {content &&
                content.layout &&
                content.layout.viewType &&
                props.contentData.panelsData &&
                props.contentData.panelsData[content.layout.viewType] &&
                props.contentData.panelsData[content.layout.viewType].cards &&
                !props.contentData.panelsData[content.layout.viewType].multipleColoumsCard &&
                props.contentData.panelsData[content.layout.viewType].cards.length > 0 &&
                props.contentData.panelsData[content.layout.viewType].cards.map(
                  (element, index, innerIndex) => {
                    const className = (element && element.config && element.config.className) || "";
                    return (
                      <div className={className}>
                        <CardSelector
                          {...props}
                          key={`${index} element ${innerIndex}`}
                          element={element}
                          result={result}
                          formik={formik}
                          showContent={showContent}
                        /></div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      }{' '}
    </div>
    )
  );
};
CardGenerator.propTypes = {};

export default CardGenerator;