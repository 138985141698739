import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ServiceHelper from '../../Utils/Helper/MappingHelper';
// import './styles.css';
import { Styles } from './styles';
import moment from 'moment';
import Checkbox from '../Checkbox';
import Input from '../Input';
import Button from '../Button';
import ReadOnly from '../ReadOnly';
import Select from '../Select';
import { get, cloneDeep } from 'lodash';
import RadioButton from '../RadioButton';
import { RADIO_SELECTED_VALUE, UPDATE_DEPENDENT_BENEFICIARY_INFORMATION } from '../../../Store/Slices/EmployeeProfile';
import Linker from '../Linker';
import {
  CHANGE_BUTTON_WIDTH,
  DISABLE_PRODUCT_SELECTION,
  CHANGE_BG_COLOR_COLLAPSE
} from '../../../Store/Slices/DisableButton';
import imageMap from '../../Molecules/CustomSection/Helper/imageHelper';
import {getFileName} from '../../Molecules/CustomSection/Helper/commonHelper';

const Table = (props) => {
  const {
    body = [],
    tableDataInfo = [],
    headers,
    hasWaiveCoverage,
    setOptionSelected,
    optionSelected,
    hideElement,
    formik = {},
    constantsList,
    tableId = "",
    backgroundColor,
    bgColor,
    key,
    onChangeCheckBox = () => {},
    temporaryValues = [],
    setTemporaryValues,
    optionSelectedUmUim,
    isUmUimCoverageTable = false,
    setOptionSelectedUmUim,
    setSaveChanges = () => { },
    isEditManageBeneficiaries = false,
  } = props;
  const dispatch = useDispatch();
  const serviceHelper = new ServiceHelper();
  let [sortedClicked, setSortedClicked] = useState(false);
  let [rowElements, setRowElements] = useState();
  let [prevIndex, setPrevIndex] = useState(-1);
  let [selectedRows, setSelectedRows] = useState([]);
  const radioSelectedValue = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValue
  );

  const {
    isExpandedRows = false,
    tableExpandedRow = [],
    upArrow = 'sm-arrow-up.svg',
    downArrow = 'arrow-down.svg',
    iconUpArrrow = 'icon-arrow-down.svg',
    iconDownArrrow = 'icon-arrow-up.svg',
    isViewRowDetails,
  } = props;

  // State variable to keep track of all the expanded rows
  // By default, nothing expanded. Hence initialized with empty array.
  const [expandedRows, setExpandedRows] = useState([]);
  // State variable to keep track which row is currently expanded.
  const [expandState, setExpandState] = useState({});
  let upArrowSrc;
  let downArrowSrc;

  try {
    upArrowSrc = imageMap[getFileName(upArrow)];
  } catch (e) {
    upArrowSrc = '';
  }
  try {
    downArrowSrc = imageMap[getFileName(downArrow)];
  } catch (e) {
    downArrowSrc = '';
  }
  const [expandedColor, setExpandedColor] = useState(true);

  const handleExpandRow = (event, id, isExpandedRows, cellId) => {
    setExpandedColor(!expandedColor);
    bgColor && dispatch(CHANGE_BG_COLOR_COLLAPSE({ changeBGColor: expandedColor }));
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(id);

    let obj = {};
    isRowExpanded ? (obj[id] = false) : (obj[id] = true);
    setExpandState((prevObj) => {
      return { ...prevObj, ...obj };
    });

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((row) => row !== id)
      : currentExpandedRows.concat(id);
    setExpandedRows(newExpandedRows);
  };

  const bgColorChange = useSelector((storeState) => storeState?.disableButton?.changeBGColor);

  const radioSelectedValueIDTheft = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueIDTheft
  ); 
  const radioSelectedValueSupplementalHealthInsurance = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueSupplementalHealthInsurance
  );
  const radioSelectedValuePetPlus = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValuePetPlus
  );

  const radioSelectedValueLegalCoverage = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueLegalCoverage
  );
  const radioSelectedValuePetAssure = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValuePetAssure
  );
  const radioSelectedValueTravel = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueTravel
  );
  const radioSelectedValueCriticalIllness = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueCriticalIllness
  );
  const radioSelectedValueHospitalIndemnity = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueHospitalIndemnity
  );
  const radioSelectedValueExcessLiability = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiability
  );
  const radioSelectedValueExcessLiabilityUmUim = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiabilityUmUim
  );
  let waiveRadioSelected =
    radioSelectedValueExcessLiabilityUmUim ||
    radioSelectedValueExcessLiability ||
    radioSelectedValueHospitalIndemnity ||
    radioSelectedValueCriticalIllness ||
    radioSelectedValueTravel ||
    radioSelectedValuePetAssure ||
    radioSelectedValueLegalCoverage ||
    radioSelectedValuePetPlus ||
    radioSelectedValueIDTheft ||
    radioSelectedValueSupplementalHealthInsurance ||
    radioSelectedValue;
  const currentStep = useSelector((storeState) => storeState.stepForm.currentStep);
  const isCheckbox =
    useSelector((storeState) => storeState.disableButton[currentStep]?.isCheckbox) || false;

  useEffect(() => {
    // if (currentStep == 'CoverageSelection') {
    //   setOptionSelected(radioSelectedValue);
    // }

    if (
      currentStep == 'IdTheft' &&
      radioSelectedValueIDTheft !== '' &&
      radioSelectedValueIDTheft !== -1 &&
      radioSelectedValue !== 'Waive'
    ) {
      setOptionSelected(radioSelectedValueIDTheft);
    }
    if (
      currentStep == 'SupplementalHealthInsurance' &&
      radioSelectedValueSupplementalHealthInsurance !== '' &&
      radioSelectedValueSupplementalHealthInsurance !== -1 &&
      radioSelectedValue !== 'Waive'
    ) {
      setOptionSelected(radioSelectedValueIDTheft);
    }
    if (
      currentStep == 'LegalCoverage' &&
      radioSelectedValueLegalCoverage !== '' &&
      radioSelectedValueLegalCoverage !== -1 &&
      radioSelectedValueLegalCoverage !== 'Waive'
    ) {
      setOptionSelected(radioSelectedValueLegalCoverage);
    }

    if (
      currentStep == 'Travel' &&
      radioSelectedValueTravel !== '' &&
      radioSelectedValueTravel !== -1 &&
      radioSelectedValue !== 'Waive'
    ) {
      setOptionSelected(radioSelectedValueTravel);
    }

    if (
      currentStep == 'PetPlus' &&
      radioSelectedValuePetPlus !== '' &&
      radioSelectedValuePetPlus !== -1 &&
      radioSelectedValue !== 'Waive'
    ) {
      setOptionSelected(radioSelectedValuePetPlus);
    }
    if (
      currentStep == 'PetAssure' &&
      radioSelectedValuePetAssure !== '' &&
      radioSelectedValuePetAssure !== -1 &&
      radioSelectedValue !== 'Waive'
    ) {
      setOptionSelected(radioSelectedValuePetAssure);
    }

    if (
      currentStep == 'CriticalIllness' &&
      radioSelectedValueCriticalIllness !== '' &&
      radioSelectedValueCriticalIllness !== -1 &&
      radioSelectedValue !== 'Waive'
    ) {
      setOptionSelected(radioSelectedValueCriticalIllness);
    }

    if (
      currentStep == 'HospitalIndemnity' &&
      radioSelectedValueHospitalIndemnity !== '' &&
      radioSelectedValueHospitalIndemnity !== -1 &&
      radioSelectedValue !== 'Waive'
    ) {
      setOptionSelected(radioSelectedValueHospitalIndemnity);
    }
    if (
      currentStep == 'ExcessLiability' &&
      radioSelectedValueExcessLiability !== '' &&
      radioSelectedValueExcessLiability !== -1 &&
      !isUmUimCoverageTable &&
      radioSelectedValue !== 'Waive'
    ) {
      setOptionSelected(radioSelectedValueExcessLiability);
    }
    if (
      currentStep == 'ExcessLiability' &&
      radioSelectedValueExcessLiabilityUmUim !== '' &&
      radioSelectedValueExcessLiabilityUmUim !== -1 &&
      isUmUimCoverageTable &&
      radioSelectedValue !== 'Waive'
    ) {
      setOptionSelectedUmUim(radioSelectedValueExcessLiabilityUmUim);
    }
  }, []);

  const handleChange = () => {
    setOptionSelected('Waive');
    //waiveRadioSelected==='Waive'
    let waiveObject = { status: true, waiveType: 'radio', isRadio: true, isCheckbox: isCheckbox, isUmUimCoverageTable: isUmUimCoverageTable };
    dispatch(CHANGE_BUTTON_WIDTH({ currentStep: currentStep, waiveObject }));
  };

  function sortIndex(index) {
    if (index !== prevIndex) {
      body.sort(function (cellA, cellB) {
        if (cellA[index].valueType === 'date') {
          return new Date(cellA[index].value) - new Date(cellB[index].value);
        } else if (cellA[index].valueType !== 'checkBox') {
          return cellA[index].value > cellB[index].value
            ? 1
            : cellA[index].value < cellB[index].value
              ? -1
              : 0;
        }
      });
      setPrevIndex(index);
    } else {
      body.sort(function (cellA, cellB) {
        if (cellA[index].valueType === 'date') {
          return new Date(cellB[index].value) - new Date(cellA[index].value);
        } else if (cellA[index].valueType !== 'checkBox') {
          return cellA[index].value > cellB[index].value
            ? -1
            : cellA[index].value < cellB[index].value
              ? 1
              : 0;
        }
      });
      setPrevIndex(-1);
    }

    setSortedClicked(!sortedClicked);
  }

  const handleHeaderCheck = (event) => {
    let headerCheckBox = headers.find((item) => {
      return item.valueType === 'checkBox';
    });
    if (headerCheckBox) {
      headerCheckBox.checked = event.target.checked;
    }

    body.forEach((row, rowIndex) => {
      row.forEach((cellContent, cellIndex) => {
        if (cellContent.valueType === 'checkBox') {
          cellContent.checked = event.target.checked;
        }
      });
    });
    setSortedClicked(!sortedClicked);
  };

  const handleRowCheck = (event, value) => {
    let index = event.target.id ? event.target.id.split(':') : [];
    if (index.length >= 2) {
      body[index[0]][index[1]].checked = event.target.checked;

      let headerCheckBox = headers.find((item) => {
        return item.valueType === 'checkBox';
      });
      let headerCheck = false;
      if (event.target.checked) {
        headerCheck = true;
        body.forEach((row) => {
          if (row[index[1]].checked == false) {
            headerCheck = false;
          }
        });
      }
      if (headerCheckBox) {
        headerCheckBox.checked = headerCheck;
      }
      setSortedClicked(!sortedClicked);
      let newSelectedRows = [...selectedRows];
      if (event.target.checked) {
        if (newSelectedRows.indexOf(value) === -1) {
          props.tableParentAction(event, [...newSelectedRows, value]);
          setSelectedRows([...newSelectedRows, value]);
        }
      } else {
        if (newSelectedRows.indexOf(value) > -1) {
          let key = newSelectedRows.indexOf(value);
          newSelectedRows.splice(key, 1);
          props.tableParentAction(event, [...newSelectedRows]);
          setSelectedRows([...newSelectedRows]);
        }
      }
    }
  };

  const headerElements = headers.map((header, headerIndex) => {
    // function to form table header
    if (header.valueType === 'checkBox') {
      return (
        <th
          className={`header-no-wrap colHeader ${(header.config && header.config.className) || ''}`}
          scope="col"
          style={header.config && header.config.style}
        >
          <Checkbox
            id="headerCheck"
            onChange={handleHeaderCheck}
            value={header.value}
            label={header.label}
          />
        </th>
      );
    } else {
      return (
        <>
          <Styles />
          <th
            className={
              (currentStep == 'ExcessLiability' && header.value != 'LIMIT') ?
                (currentStep == 'LegalCoverage') && header.value != 'COST PER PAY PERIOD'

                  ? `header-no-wrap colHeader custom-style-coverage-selection ${(header.config && header.config.className) || ''
                  } `
                  : `header-no-wrap colHeader ${(header.config && header.config.className) || ''} `
                : `header-no-wrap colHeader ${(header.config && header.config.className) || ''} `
            }
            scope="col"
            style={header.config && header.config.style}
            onClick={(e) => sortIndex(headerIndex)}
          >
            {header.value || ''}
          </th>
        </>
      );
    }
  });
  useEffect(() => {
    let tableData;
    let elements = body.map((row, rowIndex) => {
      const productRules = tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? row[0] && row[0].productRules : {};
      const coverageBuid = tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? row[0] && row[0].coverageBuid : '';
      const productBuid = tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? row[0] && row[0].productBuid : '';
      const beneficiaryType = tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? row[0] && row[0].type : '';
      const tableInfo = tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? row[0] && row[0].mainBuid : tableDataInfo[rowIndex] || {};
      const findProduct = tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? temporaryValues.find(temp => temp.coverageBuid === coverageBuid) : {};
      const showExtraActions = tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? findProduct && findProduct.beneficiaries && findProduct.beneficiaries['Primary'].length > 1 ? true : false : false;
      let tempValue = tableId === 'manageBeneficiaries' ? findProduct && findProduct.beneficiaries[beneficiaryType].find(temp => temp.bUID === tableInfo) : tableId === 'globalBeneficiaries' ? temporaryValues.find(temp => temp.bUID === tableInfo) : temporaryValues.find(temp => temp.mainBuid === tableInfo.mainBuid);
      const isSelected = tableId === 'globalBeneficiaries' ? tempValue?.beneficiarySelected : tempValue?.beneficiarySelected ? tempValue?.beneficiarySelected : tableInfo && tableInfo.beneficiarySelected;
      let key = 1;
      let rowClass;
      let trClass;
      const cellElements = row.map((cellContent, cellIndex) => {
        if (
          cellContent.value === 'Low' ||
          cellContent.value === 'High' ||
          cellContent.value === 'Pro' ||
          cellContent.value === '$15,000.00'
        ) {
          rowClass = 'coverage-data';
        }
        if (
          (currentStep === 'IdTheft' || currentStep === 'CoverageSelection') &&
          (cellContent.value === 'Low' ||
            cellContent.value === 'High' ||
            cellContent.value === 'Pro')
        ) {
          rowClass = 'coverage-data';
          trClass = 'tr-coverage-data';
        } else if (currentStep === 'HospitalIndemnity' && cellContent.value === 'Low') {
          rowClass = 'coverage-data';
          trClass = 'tr-coverage-data';
        } else if (currentStep === 'CriticalIllness' && cellContent.value === '$15,000.00') {
          rowClass = 'coverage-data';
          trClass = 'tr-coverage-data';
        }

        // if (
        //   cellContent.value === 'Low' ||
        //   cellContent.value === 'High' ||
        //   cellContent.value === 'Pro'
        // )

        // Formats the date value
        if (cellContent.valueType === 'date' && cellContent.value) {
          let date = new Date(cellContent.value + 'T00:00:00');
          tableData = moment(date).format(cellContent.format);

          // handleRowCheck(e, cellContent.value, cellContent.elementName);
        } else if (cellContent.valueType === 'readOnly') {
          tableData = (
            <ReadOnly
              key={cellContent.name}
              name={cellContent.name}
              value={cellContent.value}
              id={cellContent.id}
              containerClassName={cellContent.containerClassName}
              editable={cellContent.editable}
            />
          );
        } else if (cellContent.valueType === 'text') {
          tableData = (
            <Input
              element={cellContent}
              key={cellContent.id}
              id={cellContent.id}
              nId={cellContent.id}
              type={cellContent.type}
              format={cellContent.format}
              hidden={cellContent.hidden}
              tile={cellContent.tile}
              tileInfo={cellContent.tileInfo}
              classNameWrapper={cellContent.classNameWrapper}
              containerClassName={cellContent.containerClassName}
              label={cellContent.label}
              placeholder={cellContent.placeholder}
              name={cellContent.name}
              value={tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? get(tempValue, cellContent.source, '') : tempValue && tempValue.hasOwnProperty(cellContent.id) ? tempValue[cellContent.id] : tableInfo && tableInfo[cellContent.id]}
              shouldBeReadOnly={cellContent.shouldBeReadOnly}
              className={cellContent.className}
              style={cellContent.style}
              payload={cellContent.payload}
              specialIconLayout={cellContent.specialIconLayout}
              formik={formik}
              disabled={tableId === 'beneficiaryTable' ? isSelected ? false : true : false}
              onChange={(event) => {
                const newValue = event.target.value;
                let newSelectionObj = tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? {} : { ...tableInfo };
                const actualBuid = tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? tableInfo : '';
                const hasTempValue = tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? findProduct.beneficiaries[beneficiaryType].find(temp => temp.bUID === actualBuid) : temporaryValues.find(temp => temp.mainBuid === newSelectionObj.mainBuid);
                if (hasTempValue) {
                  newSelectionObj = cloneDeep({ ...tempValue });
                  const newValues = [];
                  const newProducts = [];
                  if (tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries') {
                    findProduct.beneficiaries[beneficiaryType].forEach(temp => {
                      if (temp.bUID === tempValue.bUID) {
                        if ( !newSelectionObj?.isDependent ) {
                          newSelectionObj.beneficiaryInformation.percentage = newValue;
                        } else {
                          newSelectionObj.beneficiaryPercentage = newValue;
                        }
                        newValues.push(newSelectionObj);
                      } else {
                        newValues.push(temp);
                      }
                    });
                    temporaryValues.forEach(product => {
                      if (product.coverageBuid === coverageBuid) {
                        // if ( product.productId === productId ) {
                        const productUpdate = cloneDeep(product);
                        productUpdate.data = newValues;
                        productUpdate.beneficiaries[beneficiaryType] = newValues;
                        newProducts.push(productUpdate);
                      } else {
                        newProducts.push(product);
                      }
                    });
                  } else {
                    temporaryValues.forEach(temp => {
                      if (temp.mainBuid === tempValue.mainBuid) {
                        newSelectionObj.beneficiaryPercentage = newValue;
                        newValues.push(newSelectionObj);
                      } else {
                        newValues.push(temp);
                      }
                    });
                  }
                  setSaveChanges(true);
                  setTemporaryValues(tableId === 'manageBeneficiaries' || tableId === 'globalBeneficiaries' ? newProducts : newValues);
                }
              }}
            // onBlur={() => {
            //   if( tableId === 'beneficiaryTable') {
            //     dispatch(UPDATE_DEPENDENT_BENEFICIARY_INFORMATION(tempValue));
            //   }
            // }}
            />
          );
        } else if (cellContent.valueType === 'select') {
          tableData = (
            <Select
              key={cellContent.name}
              id={cellContent.id}
              label={cellContent.label}
              multipleSelect={cellContent.multipleSelect}
              requiredVal={
                cellContent?.validations?.string?.required ||
                cellContent?.validations?.string?.validationRequiredOnTrue
              }
              disabled={tableId === 'beneficiaryTable' || tableId === 'globalBeneficiaries' ? isSelected ? false : true : false}
              className={cellContent.className}
              defaultValue={cellContent.defaultValue ? cellContent.defaultValue : ''}
              selectedValue={tempValue && tempValue.hasOwnProperty(cellContent.id) ? tempValue[cellContent.id] : tableInfo && tableInfo[cellContent.id]}
              onChange={(event) => {
                const newValue = event.target.value;
                let newSelectionObj = { ...tableInfo };
                const hasTempValue = temporaryValues.find(temp => temp.mainBuid === newSelectionObj.mainBuid);

                if (hasTempValue) {
                  newSelectionObj = { ...tempValue };
                  const newValues = [];
                  temporaryValues.forEach(temp => {
                    if (temp.mainBuid === tempValue.mainBuid) {
                      newSelectionObj.beneficiaryType = newValue;
                      newValues.push(newSelectionObj);
                    } else {
                      newValues.push(temp);
                    }
                  });
                  setSaveChanges(true)
                  setTemporaryValues(newValues);
                }
              }}
              options={
                cellContent.populateDataConfig
                  ? serviceHelper.generateDataList(formik, cellContent.populateDataConfig)
                  : cellContent.options
                    ? cellContent.options
                    : constantsList[cellContent.optionsname]
                      ? constantsList[cellContent.optionsname]
                      : []
              }
              {...props}
              value={tempValue && tempValue[cellContent.id]}
              currentStep={currentStep}
            />
          );
        } else if (cellContent.valueType === 'checkBox') {
          tableData = (
            <Checkbox
              id={rowIndex + ':' + cellIndex}
              key={cellIndex}
              classNameComponent={cellContent?.classNameComponent}
              classNameContainer={cellContent?.classNameContainer}
              onChange={tableId === 'beneficiaryTable' ? (e) => {
                const newSelectObj = { ...tableInfo };
                const isChecked = e.currentTarget.checked;
                if (isChecked) {
                  newSelectObj.beneficiarySelected = true;
                } else {
                  newSelectObj.beneficiarySelected = false;
                }
                const hasTemporaryValue = temporaryValues.find(temp => temp.mainBuid === newSelectObj.mainBuid);
                if (!hasTemporaryValue) {
                  setTemporaryValues([...temporaryValues, newSelectObj])
                } else {
                  const newTempValues = [];
                  temporaryValues.forEach(temp => {
                    if (temp.mainBuid === newSelectObj.mainBuid) {
                      newTempValues.push(newSelectObj);
                    } else {
                      newTempValues.push(temp);
                    }
                  });
                  setSaveChanges(true);
                  setTemporaryValues(newTempValues);
                }
              } : tableId === 'globalBeneficiaries' ? (e) => {
                const isChecked = e.currentTarget.checked;
                const actualBuid = tableInfo;
                const newLocalValues = [];
                temporaryValues.forEach(value => {
                  const newValue = cloneDeep(value);
                  if ( value?.bUID === actualBuid) {
                    newValue.beneficiarySelected = isChecked;
                  }
                  newLocalValues.push(newValue);
                });
                setTemporaryValues(newLocalValues);
              } : (e) => handleRowCheck(e, cellContent.value)}
              value={cellContent.value}
              label={cellContent.label}
              checked={tableId === 'beneficiaryTable' || tableId === 'globalBeneficiaries' ? isSelected : cellContent.checked}
            />
          );
        } else if (cellContent.valueType === 'button') {
          tableData = props.tableParentAction(cellContent, cellIndex);
          tableData = (
            <Button
              id={rowIndex + ':' + cellIndex}
              onChange={handleRowCheck}
              value={cellContent.value}
              label={cellContent.label}
              content={'Button'}
            />
          );
        } else if (cellContent.valueType === 'beneficiaryActions') {
          const totalPrimary = findProduct && findProduct.beneficiaries && findProduct.beneficiaries['Primary'] && findProduct.beneficiaries['Primary'].length;
          const totalContingent = findProduct && findProduct.beneficiaries && findProduct.beneficiaries['Contingent'] && findProduct.beneficiaries['Contingent'].length;
          const isMandatory = productRules && productRules.isBeneficiaryMandatory || false;
          const showAction = beneficiaryType === 'Primary' ? !isMandatory && totalPrimary === 1 && totalContingent === 0 ? true : showExtraActions : true;
          const hideMoveAction = beneficiaryType === 'Primary' && !isMandatory && totalPrimary === 1 && totalContingent === 0 ? true : false;
          tableData = (
            <div className={`py-2 w-100 ${!showAction ? 'pt-3' : ''}`}>
              <div className="py-1 px-2">
                {cellContent.value}
              </div>
              {showAction && (
                <div className={`${cellContent.classActionsContainer} ${hideMoveAction ? '' : 'justify-content-between'}`}>
                  {!hideMoveAction && (
                    <Button
                      id={`moveTo${cellContent.moveActionLabel}-${rowIndex}`}
                      key={`moveTo${cellContent.moveActionLabel}-${rowIndex}`}
                      name={`moveTo${cellContent.moveActionLabel}-${rowIndex}`}
                      className={'d-flex btn-sm pr-0'}
                      style={{
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        color: '#002C77'
                      }}
                      onClick={(event) => {
                        let newSelectionObj = tableId === 'manageBeneficiaries' ? {} : { ...tableInfo };
                        const actualBuid = tableId === 'manageBeneficiaries' ? tableInfo : '';
                        const hasTempValue = tableId === 'manageBeneficiaries' ? findProduct.beneficiaries[beneficiaryType].find(temp => temp.bUID === actualBuid) : temporaryValues.find(temp => temp.mainBuid === newSelectionObj.mainBuid);
                        if (hasTempValue) {
                          newSelectionObj = cloneDeep({ ...tempValue });
                          const newValues = [];
                          const newProducts = [];

                          const currentType = tableId === 'manageBeneficiaries' ? beneficiaryType : '';
                          const newValue = currentType === 'Primary' ? 'cContingent' : 'cPrimary';
                          if (tableId === 'manageBeneficiaries') {
                            findProduct.beneficiaries[beneficiaryType].forEach(temp => {
                              if (temp.bUID === tempValue.bUID) {
                                if ( !newSelectionObj?.isDependent ) {
                                  newSelectionObj.beneficiaryInformation.beneficiaryRank = newValue;
                                } else {
                                  newSelectionObj.beneficiaryType = newValue;
                                  newSelectionObj.beneficiaryRank = newValue;
                                }
                                newValues.push(newSelectionObj);
                              } else {
                                newValues.push(temp);
                              }
                            });
                            // Function to split Primary and Contingent Beneficiaries based on the change
                            const contingentArray = [];
                            const primaryArray = [];
                            if (newValues.length > 0) {
                              newValues.forEach(beneficiary => {
                                const beneficiaryRank = beneficiary?.isDependent ? beneficiary?.beneficiaryRank : beneficiary?.beneficiaryInformation?.beneficiaryRank; 
                                if (beneficiaryRank === 'cPrimary') {
                                  primaryArray.push(beneficiary);
                                } else if (beneficiaryRank === 'cContingent') {
                                  contingentArray.push(beneficiary);
                                }
                              });
                            }

                            temporaryValues.forEach(product => {
                              if (product.coverageBuid === coverageBuid) {
                                const productUpdate = cloneDeep(product);
                                productUpdate.data = newValues;
                                productUpdate.beneficiaries['Primary'] = beneficiaryType === 'Primary' ? primaryArray : [...productUpdate.beneficiaries['Primary'], ...primaryArray];
                                productUpdate.beneficiaries['Contingent'] = beneficiaryType === 'Contingent' ? contingentArray : [...productUpdate.beneficiaries['Contingent'], ...contingentArray];
                                newProducts.push(productUpdate);
                              } else {
                                newProducts.push(product);
                              }
                            });
                          }

                          setSaveChanges(true)
                          if (cellContent && cellContent.onMoveAction) {
                            cellContent.onMoveAction(newProducts);
                          }
                        }
                      }}
                      content={`Move To ${cellContent.moveActionLabel}`}
                      outline="outline"
                      // icon={["fas", "plus"]}
                      imgStyle={{ marginTop: '8px', marginRight: '6px' }}
                      imgSrc={beneficiaryType === 'Primary' ? iconDownArrrow : iconUpArrrow }
                      header={false}
                      outerDiv={true}
                      shouldShowOnYes={true}
                      showImage={true}
                    />
                  )}
                  <Button
                    id={`removeBeneficiary`}
                    key={`removeBeneficiary`}
                    name={`removeBeneficiary`}
                    className={'d-flex btn-sm pl-0'}
                    style={{
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                      color: '#002C77'
                    }}
                    onClick={() => {
                      let newSelectionObj = tableId === 'manageBeneficiaries' ? {} : { ...tableInfo };
                      const actualBuid = tableId === 'manageBeneficiaries' ? tableInfo : '';
                      const hasTempValue = tableId === 'manageBeneficiaries' ? findProduct.beneficiaries[beneficiaryType].find(temp => temp.bUID === actualBuid) : temporaryValues.find(temp => temp.mainBuid === newSelectionObj.mainBuid);
                      if (hasTempValue) {
                        newSelectionObj = cloneDeep({ ...tempValue });
                        const newValues = [];
                        const newProducts = [];
                        if (tableId === 'manageBeneficiaries') {
                          findProduct.beneficiaries[beneficiaryType].forEach(temp => {
                            if (temp.bUID !== tempValue.bUID) {
                              newValues.push(temp);
                            }
                          });

                          // Function to split Primary and Contingent Beneficiaries based on the change
                          const contingentArray = [];
                          const primaryArray = [];
                          if (newValues.length > 0) {
                            newValues.forEach(beneficiary => {
                              const beneficiaryRank = beneficiary?.isDependent ? beneficiary?.beneficiaryRank : beneficiary?.beneficiaryInformation?.beneficiaryRank; 
                              if (beneficiaryRank === 'cPrimary') {
                                primaryArray.push(beneficiary);
                              } else if (beneficiaryRank === 'cContingent') {
                                contingentArray.push(beneficiary);
                              }
                            });
                          }

                          temporaryValues.forEach(product => {
                            if (product.coverageBuid === coverageBuid) {
                              const productUpdate = cloneDeep(product);
                              productUpdate.data = newValues;
                              productUpdate.beneficiaries['Primary'] = beneficiaryType === 'Primary' ? primaryArray : [...productUpdate.beneficiaries['Primary'], ...primaryArray];
                              productUpdate.beneficiaries['Contingent'] = beneficiaryType === 'Contingent' ? contingentArray : [...productUpdate.beneficiaries['Contingent'], ...contingentArray];
                              newProducts.push(productUpdate);
                            } else {
                              newProducts.push(product);
                            }
                          });
                        }
                        setSaveChanges(true);
                        if (cellContent && cellContent.onDeleteAction) {
                          cellContent.onDeleteAction(newProducts)
                        }
                      }
                    }}
                    content="Remove"
                    outline="outline"
                    icon={['fas', 'trash-alt']}
                    iconStyle={{ marginTop: '4px', marginRight: '0.5rem' }}
                    header={false}
                    outerDiv={true}
                    shouldShowOnYes={true}
                  />
                  <Button
                    id={`editBeneficiary`}
                    key={`editBeneficiary`}
                    name={`editBeneficiary`}
                    className={`d-flex btn-sm ${hideMoveAction ? 'pl-3' : 'pl-0'}`}
                    style={{
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                      color: '#002C77'
                    }}
                    content="Edit"
                    outline="outline"
                    icon={['fas', 'pen']}
                    iconStyle={{ marginTop: '4px', marginRight: '0.5rem' }}
                    header={false}
                    outerDiv={true}
                    shouldShowOnYes={true}
                    onClick={() => cellContent.onEditAction(cellContent, tableInfo, findProduct)}
                  />
                </div>
              )}
            </div>
          );
        } else {
          tableData = cellContent.value;
        }
        return (
          <td
            className={
              expandedRows.includes(rowIndex) && bgColor
                ? `table-data-no-wrap ${cellContent.className} tableColorUpdate`
                : `table-data-no-wrap ${cellContent.className}`
            }
            // className={bgColorChange ? `table-data-no-wrap ${cellContent.className} tableColorUpdate` :`table-data-no-wrap ${cellContent.className}`}

            name={cellContent.name}
            key={cellIndex}
          >
            {isExpandedRows && cellIndex === 0 && (
              // This id is used in confirmation screen to expand during print 
              <span
                role="button"
                tabIndex={0}
                id={`to-show-more-details${rowIndex}`}
                className="tableExpandImg"
                onClick={(event) => isExpandedRows && handleExpandRow(event, rowIndex)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                      e.preventDefault();
                      isExpandedRows && handleExpandRow(event, rowIndex)
                  }
                }}
              >
                {expandedRows.includes(rowIndex) ? (
                  <img src={upArrowSrc} className="" alt="upArrowSrc" />
                ) : (
                  <img src={downArrowSrc} className="" alt="downArrowSrc" />
                )}
              </span>
            )}

            {tableData ? tableData : ''}
          </td>
        );
      });

      // return <tr key={rowIndex}>{cellElements}</tr>;
      return (
        <>
         <tr key={rowIndex} className={trClass} id={`table-row-${headerElements[0]?.props?.children[1]?.props?.children.replace(/\s/g, '_')}-${rowIndex}`}>
            {cellElements}
          </tr>
          {isExpandedRows && expandState[rowIndex] && (
            <tr>
              <td className={expandedRows.includes(rowIndex) ? `tableColorUpdate` : ''} colSpan={cellElements.length}>
                {tableExpandedRow[rowIndex]}
              </td>
            </tr>
          )}
          {isViewRowDetails && (
            <tr>
              <td
                colSpan={cellElements.length}
                className={expandedRows.includes(rowIndex) && bgColor ? `tableColorUpdate` : ''}
              >
                <div> {expandState[rowIndex] && tableExpandedRow[rowIndex]}</div>
                {/* This id is used in confirmation screen to expand during print */}
                <a id={`to-show-more-details${rowIndex}`} onClick={(event) => isViewRowDetails && handleExpandRow(event, rowIndex)}>
                  {expandState[rowIndex] ? 'Hide Details' : 'View Details'}
                </a>
                {/* <Linker to={'/enrollment/enrollmentSummary'} onClick={(event) => isExpandedRows && handleEpandRow(event, rowIndex)}>{(expandState[rowIndex]) ? 'Hide Details' : 'View Details'}</Linker> */}
              </td>
            </tr>
          )}
        </>
      );
    });
    setRowElements(elements);
  }, [body, sortedClicked, expandState, expandedRows, temporaryValues]);
  const { tableHeaderStyle = {} } = props;
  let additionalClasses = hideElement ? 'hideElement' : ``;

  if (tableId === 'beneficiaryTable') {
    if (radioSelectedValueCriticalIllness == 'Waive' || radioSelectedValueCriticalIllness == -1) {
      additionalClasses = 'hideElement ';
    } else {
      additionalClasses = additionalClasses;
    }
  }

  return (
    <div
      className={
        additionalClasses
          ? additionalClasses
          : `table-responsive scrollbar ${props.classNameWrapper} `
      }
    >
      {/* <table className={`table atomTable ${props.className}`}> */}
      <table
        className={
          hasWaiveCoverage
            ? `table atomCoverageTable ${props.className}`
            : backgroundColor
              ? ` additionalColor table atomTable ${props.className}`
              : `table atomTable ${props.className}`
        }
        id={headerElements[0]?.props?.children[1]?.props?.children.replace(/\s/g, '_')}
        title={props?.collapseTitle?.replace(/\s/g, '')}
      // className={
      //   hasWaiveCoverage
      //     ? `table atomCoverageTable ${props.className}`
      //     : backgroundColor ? " additionalColor  table atomTable ${props.className}": `table atomTable ${props.className}`
      // }
      >
        <thead>
          <tr style={tableHeaderStyle}>{headerElements}</tr>
        </thead>
        <tbody className={bgColor ? 'tableColor' : ''}>{rowElements}</tbody>
        {hasWaiveCoverage && (
          <tr>
            <td className="atomCoverageTableRadio" colSpan={5} align="center">
              <div className="atomCoverageTableRadio">
                <input
                  className=""
                  type="radio"
                  onChange={handleChange}
                  // checked={(radioSelectedValue=="Waive" && currentStep=="CoverageSelection") || (radioSelectedValue=="Waive" && currentStep=="IdTheft")? true : optionSelected == 'Waive'}
                  // checked={radioSelectedValue=="Waive" ? true : optionSelected == 'Waive'}

                  checked={
                    (currentStep == 'CoverageSelection' && radioSelectedValue == 'Waive') ||
                      // (currentStep == 'IdTheft' && radioSelectedValueIDTheft == 'Waive') ||(currentStep == 'LegalCoverage' && radioSelectedValueLegalCoverage == 'Waive')||(currentStep == 'Travel' && radioSelectedValueTravel== 'Waive'||(currentStep == 'CriticalIllness' && radioSelectedValueCriticalIllness== 'Waive'))
                      (currentStep == 'IdTheft' && radioSelectedValueIDTheft == 'Waive') ||
                      (currentStep == 'SupplementalHealthInsurance' && radioSelectedValueSupplementalHealthInsurance == 'Waive') ||
                      (currentStep == 'LegalCoverage' &&
                        radioSelectedValueLegalCoverage == 'Waive') ||
                      (currentStep == 'Travel' && radioSelectedValueTravel == 'Waive') ||
                      (currentStep == 'PetPlus' && radioSelectedValuePetPlus == 'Waive') ||
                      (currentStep == 'PetAssure' && radioSelectedValuePetAssure == 'Waive') ||
                      (currentStep == 'CriticalIllness' &&
                        radioSelectedValueCriticalIllness == 'Waive') ||
                      (currentStep == 'HospitalIndemnity' &&
                        radioSelectedValueHospitalIndemnity == 'Waive') ||
                      (currentStep == 'ExcessLiability' && !isUmUimCoverageTable &&
                        radioSelectedValueExcessLiability == 'Waive') ||
                      (currentStep == 'ExcessLiability' && isUmUimCoverageTable &&
                        radioSelectedValueExcessLiabilityUmUim == 'Waive')
                      ? true

                      : optionSelected == 'Waive'
                  }
                  id="coverageWaiveId"
                />
                <label for="coverageWaiveId" className="ml-3">Waive Coverage</label>
              </div>
            </td>
          </tr>
        )}
      </table>
    </div>
  );
};
Table.propTypes = {
  headers: PropTypes.array,
  body: PropTypes.arrayOf(PropTypes.array),
};
Table.defaultProps = {
  headers: [],
  body: [[]],
};

export default Table;
