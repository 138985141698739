import React from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

import Loader from '../../Atoms/Loader';
import RegisterPhone from './RegisterPhone';
import { Button, RadioButton } from '../../Atoms';
import { maskEmail } from '../../Helper/MFAHelper';
import { preferredChannelSchema } from '../../Atoms/FormValidation';
import securityImage from '../../../../../Assets/Images/Icon Security.png';
import { apiCall } from '../../Helper/commonHelper';
import { Link } from 'react-router-dom';

const RegisterMfa = ({ stepValues, onNext, onPrev }) => {
    const changeOptions = [
        { label: 'Email', value: 'email' },
        { label: 'Text', value: 'sms' },
        { label: 'Phone Call', value: 'call' },
        
    ];
    const { loader } = useSelector((state) => state.loader);
    const email = useSelector((state) => state.registeration.email);
    const { legalEntityId = '', appCode = ''} = useSelector((storeState) => storeState?.registeration);

    const formik = useFormik({
        initialValues: stepValues || { preferredChannel: '', phoneVerified: false },
        validationSchema: preferredChannelSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            onFormSubmit({ preferredChannel: values.preferredChannel });
        }
    });

    const onFormSubmit = (data) => {
        apiCall("/stateful/sign-up/set-preferred-channel", 'put', data).then((res) => {
            if (res.status === 200) {
                onNext({ ...formik.values })
            } else {
               
            }
        }).catch((e) => {
           
        });
    }

    const onRadioChange = (e) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, value);
    }

    const onPhoneVerificationSuccess = (data) => {
        formik.setFieldValue('phoneVerified', true)
    };

    const validationMsg = (field) => {
        return (formik.touched[field] && formik.errors[field] && (
            <div className="form-mandatory">{formik.errors[field]}</div>
        ))
    }

    return (
        <div>
            {loader && <Loader loading={loader} />}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1 className="form-side-header">User Registration</h1>
                        <p className="form-mandatory mb-0">
                            Fields marked with * are mandatory
                        </p>
                        <div className="col-11 panel-sid-cont pl-xl-0 mb-2">
                            <img src={securityImage} name=""
                                className="img-fluid "
                                alt="Security Icon"
                                style={{
                                    minWidth: '2.25rem',
                                    minHeight: '2rem',
                                    marginRight: 10
                                }}
                            />
                            <span>
                                <div className="panel-side-header-img d-inline">
                                    Secure your account with multi factor authentication
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className="col-12">
                        <p>To enable multifactor authentication (MFA), you must more than one contact method and select a preferred method to receive MFA code.</p>
                    </div>
                </div>
                <div className="row py-2">
                    <div className="col-sm-12 col-md-6">
                        <label>Email ID</label>
                        <p>{email}</p>
                    </div>
                </div>
                <div className="dividerline" />
                <RegisterPhone onSuccess={onPhoneVerificationSuccess} />
                <div className="dividerline" />
                <form noValidate onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <RadioButton
                                name="preferredChannel"
                                options={changeOptions}
                                onChange={onRadioChange}
                                selectedOption={formik.values.preferredChannel}
                                label="How do you like to receive MFA security code?"
                                className={'forMFA register'}
                                required={true}
                            />
                            {validationMsg('preferredChannel')}
                        </div>
                    </div>
                    <div className="row py-4 justify-content-between">
                        <div className="col-sm-12 col-md-4 py-2">
                            <Link role="link" to={`/enrollment?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Back to login</Link>
                        </div>
                        <div className="col-sm-12 col-md-6 py-2 text-md-right">
                            <Button type="button" className="btn btn-outline-primary primaryOutline btn-outline-primary-contrast mr-4" onClick={onPrev}>Back</Button>
                            <Button type="submit" name="registerationBtn" className="btn btn-primary primaryBackground btn-primary-contrast mfaBtn" disabled={!formik.values.phoneVerified}>Continue</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegisterMfa;
