import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Elementor from '../Elementor';
import { Icon } from '../../../Atoms';
import TriBox from '../../../Molecules/TriBox';
import SectionPanels from '../../../Molecules/SectionPanels';
import CustomSection from '../../../Molecules/CustomSection'
import { formatter } from '../../../Atoms/Currency/index';
import IFrameGenerator from '../../../Molecules/IFrameGenerator';
import { Image } from '../../../Atoms';
import APIRequest from '../../../Utils/Helper/APIHelper';
import { find } from 'lodash';
import onLoadAPI from '../../../../Store/Slices/Entities/response/index';
import { APIRequestParallel } from '../../../../Store/Slices/Entities/response/index';
//import { Formik, Form, Field, useFormik } from 'formik';
import CrossSell from '../../../Molecules/CrossSell';
import $ from 'jquery';

export const FormBuilder = (props) => {
  const {
    formName,
    content,
    updateFormData,
    elements,
    treeCard,
    formik,
    isMultiple,
    multiConfig,
    contentData,
    SetContentData,
    changeConfig,
    triConfig,
    listElements,
    iFrameElements,
    currentStep,
    innerFormName,
    hasSections,
    hasCustomSection,
    hasCrossSell,
    deleteRecord = () => {},
    editRecord = () => {},
    onChangeCheckBox = () => {},
    showContent = [],
    displayContent = {},
  } = props;

  const dispatch = useDispatch();
  const { stepKeys } = contentData;
  let title, titleImage;
  if (props.innerForm) {
    title = content.forms[innerFormName].title;
    titleImage = content.forms[innerFormName].titleImage;
  } else {
    title = content.forms[formName].title;
    titleImage = content.forms[formName].titleImage;
  }
  let titleImageRight;
  if (props.layout) {
    titleImageRight = content.layout.titleImageRight;
  }
  const { tableRow, maxAdds, tableHeader } = multiConfig || {};
  let [displayType, setDisplayMode] = useState('');
  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
  // let [hideColumn, setHideColumn] = useState(false);

  // let shouldHideCol = hideCol ? 'hideElement' : '';

  const handleChange = (event) => {
    /* function to handle change event of elements */
    if (event.currentTarget) {
      updateFormData(formName, event.currentTarget.name, event.currentTarget.value);
    }
  };
  // const { disableButton } = useSelector((storeState) => storeState); // handle btn width/form
  const isYesradioVoyaPremier = useSelector(
    (storeState) => storeState?.employeeProfile?.isYesradioVoyaPremier
  );

  let largeBtn =false
  if(isYesradioVoyaPremier === true || isYesradioVoyaPremier===""){
    largeBtn =true
  }

  let additionalClass;
  if(formName=="WLCoverage"|| formName=="SearchForClient" || formName=='SearchForClientConfig'|| formName=='SearchForAnEmployee'|| formName=='EmployeeData'){
    additionalClass=true
  }


  const tableRowMapper = (data, rows = [], columns = []) => {
    const definedColumns = columns.length > 0 ? true : false;
    const tableRowsData = rows.length > 0 ? rows : tableRow;
    const filteredRows = [];
    if (definedColumns) {
      tableRowsData.forEach((rowData) => {
        if (!!find(columns, { id: rowData.id })) filteredRows.push(rowData);
      });
    }
    /* function to form table using properties from form josn*/
    let editedtableRow = [];
    const rowsData = definedColumns ? filteredRows : tableRowsData;
    rowsData &&
      rowsData.forEach((item) => {
        if (item.type === 'expression') {
          let { optionsname = '', className = '', valueType = '', format = '' } = item.config || {};
          let displayValue = eval(item.value);
          if (optionsname && constantsList[optionsname]) {
            let obj = constantsList[optionsname].find((o) => o.value === displayValue);
            if (obj) displayValue = obj['text'];
          }

          // editedtableRow.push(displayValue);
          if (item.valuetype === 'currency') {
            displayValue = formatter(item.locale, item.currency).format(displayValue);
          }
          editedtableRow.push({
            className: className,
            value: displayValue,
            valueType: valueType,
            format: format,
          });
        } else if (item.type === 'email') {
          let { className = '' } = item.config || {};
          // editedtableRow.push( <a href={`mailto:${data['email']}`}>{data['email']}</a> );
          editedtableRow.push({
            className: className,
            value: <a href={`mailto:${data['email']}`}>{data['email']}</a>,
          });
        } else if (
          item.type === 'text' ||
          item.type === 'checkBox' ||
          item.type === 'select' ||
          item.type === 'readOnly'
        ) {
          let displayValue = '';
          if (item.hasExpression) {
            let { optionsname = '' } = item.config || {};
            displayValue = eval(item.value);
            if (optionsname && constantsList[optionsname]) {
              let obj = constantsList[optionsname].find((o) => o.value === displayValue);
              if (obj) displayValue = obj['text'];
            }

            if (item.valuetype === 'currency') {
              displayValue = formatter(item.locale, item.currency).format(displayValue);
            }
          }
          editedtableRow.push({
            ...item,
            value: displayValue,
            valueType: item.type,
          });
        }
      });
    return editedtableRow;
  };

  useEffect(() => {
    /* useEffect to decide whether add button has to display or not*/
    if (contentData.displayMode && contentData.displayMode[formName])
      setDisplayMode(contentData.displayMode[formName]);
  }, [contentData.displayMode, formName]);

  useEffect(() => {
    /* useEffect to decide whether add button has to display or not*/

    if (stepKeys[currentStep] && props.content && props.content.forms && props.content.forms) {
      if (
        props.content.forms[stepKeys[currentStep]] &&
        props.content.forms[stepKeys[currentStep]].onLoadApiCall
      ) {
        let onLoadCalls = props.content.forms[stepKeys[currentStep]].onLoadApiCall;
        if (onLoadCalls) {
          dispatch(APIRequestParallel(onLoadCalls));
        }
      }
    }
  }, [contentData.stepKeys]);

  const changeView = (formName) => {
    /* function to change the form view*/
    SetContentData((prevContentData) => {
      let view = prevContentData.displayMode[formName] === 'view' ? 'edit' : 'view';
      setDisplayMode(view);
      return {
        ...prevContentData,
        displayMode: { ...prevContentData.displayMode, [formName]: view },
      };
    });
  };
  return (
    // <div className={`${formName + " row"} ${props.cardBorder || ''}`}>
    // <div className={`${formName + " row"} ${props.cardBorder || ''}`}>

    <div className={`${formName + 'col-12'} ${props.cardBorder || ''}`}>
      {/* // <div className={formName==='CoverageSelection'?"col-md-12":`${formName + " row"} ${props.cardBorder || ''}`}> */}
      <div className="d-flex align-items-start">
        <Image className={`card-header-image align-self-end`} filename={titleImage} />
        <div className="align-self-end">
          {title && <h2 className="header-text m-0 ml-3 mt-sm-4">{title}</h2>}
        </div>

        {!isMultiple &&
          contentData.displayMode &&
          contentData.displayMode[formName] &&
          content.forms[formName] &&
          content.forms[formName].showDisplayMode && (
            <div className="edit-toggle d-none d-sm-block">
              <Icon
                icon={['fas', 'pen']}
                onClick={() => changeView(formName)}
                className="action mr-2"
              />
            </div>
          )}
      </div>
    

      {/* <div className='col-12'>  */}
      <div className={content?.layout?.additionalClass ? 'col-12 px-5' : 'col-12'}>
        {!isMultiple &&
          !changeConfig &&
          !triConfig &&
          !listElements &&
          !hasSections &&
          !hasCustomSection &&
          !treeCard &&
          elements &&
          elements !== [] &&
          elements.map((elementRow) => {
            const keyDate = new Date().getTime();
            return (
              <div
                className={
                  elements.indexOf(elementRow) === 0 && (!additionalClass)
                    ? (formName=='EnrollmentSummary' ?  'form-row py-3 mt-4 px-3 enrollmentWrapper' : 'form-row py-3 mt-4')
                    : formName == 'SearchForClient'|| formName=='SearchForClientConfig'|| formName=='SearchForAnEmployee'|| formName=='EmployeeData'
                    ? ''
                    : formName=='WLCoverage'? 'form-row':`form-row py-3 ${elementRow?.[0]?.parentClassName||""}`
                }
                key={elements.indexOf(elementRow)}
              >
                {elementRow.map((element) => {
                  if (
                    contentData.displayMode &&
                    contentData.displayMode[formName] &&
                    displayType !== 'edit'
                  ) {
                    element = { ...element, type: 'readOnly' };
                  }
                      

                  return (
                    !element.accordingDisplay && (
                      <div
                        key={element.name}
                        // className={
                        //   element.flexPlacement
                        //     ? `col-12 dsfsf ${element.placement} `
                        //     : wideBtn
                        //     ? `col-12 dsfsf ${element.widePlacement} `
                        //     : 'col-12 sdf'
                        // }
                        className={
                          (!largeBtn && !element.flexPlacement && element.name === 'continueProductButton') ? `  col-12 col-md-5 col-lg-4 py-3 py-lg-0 py-md-0 ` :   ` formWrapperDiv ${element.placement} ` 
                        }
                      >
                        <Elementor
                          constantsList={constantsList}
                          editRecord={editRecord}
                          deleteRecord={deleteRecord}
                          onChangeCheckBox={onChangeCheckBox}
                          element={element}
                          handleChange={handleChange}
                          key={element.name}
                          showContent={showContent}
                          displayContent={displayContent}
                          {...props}
                        />
                        {props.formik.errors[formName] &&
                        props.formik.errors[formName][element.name] &&
                        props.formik.touched[formName] &&
                        props.formik.touched[formName][element.name] ? (
                          <div>{props.formik.errors[formName][element.name]}</div>
                        ) : null}

                        {props.formik.errors[formName] &&
                        props.formik.errors[formName][innerFormName] &&
                        props.formik.errors[formName][innerFormName][element.name] &&
                        props.formik.touched[formName] &&
                        props.formik.touched[formName][innerFormName] &&
                        props.formik.touched[formName][innerFormName][element.name] ? (
                          <div>{props.formik.errors[formName][innerFormName][element.name]}</div>
                        ) : null}
                      </div>
                    )
                  );
                })}
              </div>
            );
          })}
      </div>
    

      <div>
        {iFrameElements && (
          <IFrameGenerator
            allFormData={formik.values[formName]}
            constantsList={constantsList}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            onChangeCheckBox={onChangeCheckBox}
            elements={elements}
            iFrameElements={iFrameElements}
            formMode={displayType}
            maxAdds={maxAdds}
            name={formName}
            tableHeaders={tableHeader}
            tableRowMapper={tableRowMapper}
            updateFormData={updateFormData}
            {...props}
          />
        )}
      </div>

      <div className="col-12">
        {triConfig && (
          <TriBox
            allFormData={formik.values[formName]}
            constantsList={constantsList}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            onChangeCheckBox={onChangeCheckBox}
            elements={elements}
            formMode={displayType}
            maxAdds={maxAdds}
            name={formName}
            tableHeaders={tableHeader}
            tableRowMapper={tableRowMapper}
            updateFormData={updateFormData}
            {...props}
          />
        )}
      </div>
      {/*Added below code for sectionPanels */}
      <div>
        {hasSections && !hasCustomSection && (
          <SectionPanels
            allFormData={formik.values[formName]}
            constantsList={constantsList}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            onChangeCheckBox={onChangeCheckBox}
            elements={elements}
            formMode={displayType}
            maxAdds={maxAdds}
            name={formName}
            tableHeaders={tableHeader}
            tableRowMapper={tableRowMapper}
            updateFormData={updateFormData}
            showContent={showContent}
            {...props}
          />
        )}
      </div>
      <div>
        {hasCustomSection && (
          <CustomSection
          tableRowMapper={tableRowMapper}
          tableHeaders={tableHeader}
            {...props}
          />
        )}
      </div>
      <div>
        {hasCrossSell && (
          <CrossSell {...props} elements={props.crossSell} />
        )}
      </div>
      
      {/* <div>
        {changeConfig?.leftCard?.element[0]?.img &&    
         elements.map((elementRow) => {
        return(
        <div
          className={elements.indexOf(elementRow) === 0 ? 'form-row py-3 mt-4' : 'form-row py-3'}
        >
          <ChangeBox
            name={formName}
            updateFormData={updateFormData}
            allFormData={formik.values[formName]}
            elements={elements}
            tableRowMapper={tableRowMapper}
            maxAdds={maxAdds}
            tableHeaders={tableHeader}
            formMode={displayType}
            constantsList={constantsList}
            {...props}
          />
        </div>
        )
         }
         )
        }
      </div> */}

      <div></div>
    </div>
  );
};
