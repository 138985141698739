import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
//Providing the theme as props which contain the data for theming to apply themes on
// current name left card and current address left card
export const Styles = createGlobalStyle`
.headerHome {
    background-color: #F0FAFF; 
    border-bottom: 1px solid #dee2e6 !important;
}
.titleHeaderHome {
    color: #000000;
    font-size: 1.5rem;
    line-height: 2.063rem;
}
.titleRightContainer {
    font-weight: 600;
    color: #0077A0;
    font-size: 1.25rem;
    line-height: 1.688rem;
}







.current-address-left-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  background-color:  ${({ theme }) =>
    theme.components &&
    theme.components.molecules &&
    theme.components.molecules.changeBox &&
    theme.components.molecules.changeBox.changeBoxBgColor};
}

.current-address-right-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
}

.current-name-left-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  background-color:  ${({ theme }) =>
    theme.components &&
    theme.components.molecules &&
    theme.components.molecules.changeBox &&
    theme.components.molecules.changeBox.changeBoxBgColor};
}

.current-name-right-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
}



.changebox-general-items span {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.changebox-general-items-right span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: left;
  color: #00a190;
}

// .left-card-style {
//   margin-left: -15px;
// }

.form-side-header{
  // font-family: Noto Sans;
  // font-size: 32px;
  // font-weight: light;
  // font-stretch: normal;
  // font-style: normal;
  // line-height: 1.73;
  // letter-spacing: normal;
  // text-align: left;
  // color: #1e2e5d;
  
}

.label-header{
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.header-text {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.98;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.error-message{
  margin-top:0rem
  padding-top: 0.001rem
  font-size: 0.75rem;
  font-family: NotoSansRegular
  
}
.panelStyle{
  background-color: #FCFCFC !important;
  border-radius:0.3rem !important;
  padding: 1rem 1rem 1rem 1rem !important;
 border: 0.063rem solid #E1E1E1;
}

.panelStyleNoBorder{
  background-color: #FCFCFC !important;
  // padding: 2rem 0 0 0 !important;
}

.panelStyleItem {
  padding: 1rem 0 !important;
}

.outerDiv{
   background-color: red !important;
   border-radius:0.3rem !important;
    //  border: 0.063rem solid #E1E1E1;

}

.con{
 //padding-top padding-right padding-bottom padding-left
padding-bottom: 3rem 2rem  3rem 4rem !important;
}


.hide-scrollbar{
  overflow-x: hidden;
}

@media (max-width: 600px) {
  .column-1-3 {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) {
.tablet {
  col-12

}
}


`;
