import React from 'react';
import PropTypes from 'prop-types';
// import './styles.css';
import { Styles } from './styles';
import appConfig from '../../../appConfig';
import { useState, useEffect } from 'react';
import { onLoadAPI } from '../../../Store/Slices/Entities/response';
import Loader from '../../Atoms/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { reduxConstants } from '../../../Common/Constants/index';
import Elementor from '../../Cells/Form/Elementor';
import toast, { Toaster } from 'react-hot-toast';
import { HAS_ERROR } from '../../../Store/Slices/RegisterationError';
import http from '../../../Common/Services/HttpService';
import { getElements } from '../../../Store/Slices/Entities/response';

const IFrameGenerator = (props) => {
  const {
    elements,
    //allFormData,
    formik,
    formName,
    triConfig,
    constantsList,
    content,
    hideElement,
    shouldShowOnYes,
    currentStep,
    width,
    height,
    listElements,
    src,
    iFrameElements,
  } = props;
  let errorCount = 0;

  const dispatch = useDispatch();
  const a = [];
  const finalElements = [];
  const [showLoader, setShowLoader] = useState(false);
  const [loadElements, setLoadElements] = useState(false);
  const [title, setTitle] = useState('');
  const isYesradioVoyaPremier = useSelector(
    (storeState) => storeState?.employeeProfile?.isYesradioVoyaPremier
  );

  const voyaAPIResponse = useSelector(
    (storeState) => storeState?.employeeProfile?.voyaAPIResponse
  );
  let dateOfBirth = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile?.dateOfBirth);
  const mmddyyyy = dateOfBirth;
  const [year, month, day] = mmddyyyy.split('-');
  const dob = month + "/" + day + "/" + year;
  let formattedDateOfBirth = encodeURIComponent(dob);
  let employeeId = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.EmploymentInformation?.employmentInformation?.employeeId);
  let isAdminEndorse = useSelector((storeState) => storeState?.callCenter?.isAdmin);
 
if(isAdminEndorse){
  employeeId=useSelector((storeState) => storeState?.callCenter?.selectedEmployeeData?.EmployeeId);
}
  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }
  let hideiFrame = false;
  if (
   
    (currentStep == 'PetAssureNation' && !isYesradioVoyaPremier )
   ) {
    hideiFrame = true;
  }
  let additionalClasses = hideElement || hideiFrame ? 'hideElement' : ' py-0';
let url;
if(voyaAPIResponse?.status==200){
  // https://www.enrollvb.com/api/index.html?bla-bla-bla 
//   groupId: 44831, // Baystate Healt
// lastFour: "6503",
// dateOfBirth: "03/05/1974",
// employeeId: "258965"

url= process.env.REACT_APP_VOYA_IFRAME_URL + `?employeeId=${employeeId}&dob=${formattedDateOfBirth}`
}
else{
  url="/wholeLife"
}
  return (
    <div   className={
      additionalClasses}>
      {/* <h2>HTML Iframes</h2>
  <p>You can use the height and width attributes to specify the size of the iframe:</p> */}

      <iframe id="iframe1" src={url} style={{border:'none'}}   height={height} width={width}  title="Iframe Example"></iframe>
    </div>
  );
};

IFrameGenerator.propTypes = {
  elements: PropTypes.array,
  allFormData: PropTypes.object,
  updateFormData: PropTypes.func,
  tableRowMapper: PropTypes.func,
  tableHeaders: PropTypes.arrayOf(PropTypes.string),
};
IFrameGenerator.defaultProps = {
  elements: {},
  allFormData: {},
  tableHeaders: [],
  setAllFormData: () => {},
  tableRowMapper: () => {},
};

export default IFrameGenerator;
