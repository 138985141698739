import React from 'react';
import './styles.css';
import { Image, Icon } from '../../../Atoms';
import { useSelector } from 'react-redux';
import { reduxConstants } from '../../../../Common/Constants';
import { getValueFromKeyStringAcceptArray } from '../../../Utils/Helper/GetValueFromKeyStringAcceptArray';

const SideDrawer = (props) => {
  let classes = `sideDrawerContainer ${props.className || ''}`;
  if (props.open) {
    classes = `sideDrawerContainer ${props.className || ''} open`;
  }
  const { userAuth } = useSelector((storeState) => storeState.auth);
  const loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.uiPath)) || {};
  let loggedInUser = '';
  /** Display logged user name */
  if (
    userAuth &&
    userAuth.data &&
    userAuth.data.access_token &&
    Object.keys(loggedUserEntity).length
  ) {
    let getFirstName = getValueFromKeyStringAcceptArray(
      loggedUserEntity,
      'person.actor',
      'shortName'
    );
    let getLastName = getValueFromKeyStringAcceptArray(loggedUserEntity, 'person.actor', 'name');
    let firstName = !props?.userName?.firstName ? getFirstName : '';
    let lastName = !props?.userName?.lastName ? getLastName : '';
    let fullName = !props?.userName?.fullName ? firstName + ' ' + lastName : '';
    loggedInUser = fullName;
  }

  return (
    <div className={classes}>
      <div className="sidebarHeader d-flex flex-column">
        <div className="d-flex flex-row-reverse col-12 d-lg-none closeIconContainer">
          <Image
            filename="icon_close_new.png"
            className="closeIcon"
            onClick={props.onClose}
          ></Image>
        </div>
        <div className="d-flex row align-items-center justify-content-center userProfileContainer">
          <div className="col-sm">
            <div className="d-flex flex-column userNameContainer align-items-center">
              <div className="userName">
                <div>{loggedInUser}</div>
              </div>

              <div className="logoutWrapper" onClick={props.onClose}>
                <p className="manageAccount" data-target="#userLogout" data-toggle="modal">
                  <Image filename="Icon_Logout-white.svg" />
                  Log Out
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default SideDrawer;
