import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

function StickyNote(props) {

  const {
    id,
    name,
    children,
    title,
    className,
  } = props;

  return (
    <div className="sticky-note">
      { title!== '' ? <><div className="row">
        <div className="col-2">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAolJREFUSEu1VUFy2kAQnBWqODfjH+BrDGXlBYYXmLzA+AXBhxjdTG6Scwh5QfALIr8g5AWhCjlXyAvi3OyKYdOzq0VCaGVRqWyVSqCdnZ7pme0RVLZevfOo5pyRJA9m/NQT8wm+LcihiGbhbZkLUbjZ9NtE8gp7ePMS3/B/ivd9Ys/7DLivgEgO6e76psjXNkDTH2rn4icJGlLtIaLpyDje9NG67JJ0+rA/wUZE7uN53nYToOWPScozEuIGjvtWx/lQjy77OPMRn6cA6WTPpQAmciHOaRaMC6nz+nUrqKb1K85NKA475rwGMJsc+SzoFddlMML3tyrKOHxdaNPye2DgM+h9T3EAqvErAZjg3SD3wbNHOECR6VjZx2Fxc/De0SCC1xNQdci+BHErOs53cGinhg+q6AjZoZviEIW1LM9v0JOcI5MLdNZIUDNJ3X08qFxUq/dko6mylUwlAHymB2kHbes5011sIOmW7sJuKYZpGFDJGXCPj0vTVjTWUGTud1y6smDWdKLYrjhkAJmtujWy9AI+D7BuWdH5PwDqhosvtFpVrAGntVsGQyU3qga6gKf4c1CpcFVqoO6C8FCrBgCSdIR8Q7Pr6J9rwHLytPcLfj5x45ibvEBK86yGbAClYqY/V5EUdBBNg4UGMBqS3L6tLFRXmNnAAJgBRYKYqsJa01JN0RpyypWnHx/4Ju62NDVzoP/OaloKoA0m8Hr8rC7loXXkLNUCAbazAW6qogJ5iUKbCSUumEdrKmy/3LuCfED8MAFXy24+e9tM5j5mxdzHA7pkpIe8BJhTR1tjHqMNibQmlUxAu65zdH9e9HC4p2jbWoiYgV0xKsvSDpB3qHQeQ2m1vN+lCf4CfSw4o4wcyLEAAAAASUVORK5CYII=" alt="Info Icon" />
        </div>
        <div className="col-9 pt-1 stickyNoteHeader">{title}</div>
      </div>
        <div className="row">
          <div className="col-12">
            <div className="px-3 pt-1 mt-1">{children}</div>
          </div>
        </div> </>
        : <><div className="row">
          <div className="col-2">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAolJREFUSEu1VUFy2kAQnBWqODfjH+BrDGXlBYYXmLzA+AXBhxjdTG6Scwh5QfALIr8g5AWhCjlXyAvi3OyKYdOzq0VCaGVRqWyVSqCdnZ7pme0RVLZevfOo5pyRJA9m/NQT8wm+LcihiGbhbZkLUbjZ9NtE8gp7ePMS3/B/ivd9Ys/7DLivgEgO6e76psjXNkDTH2rn4icJGlLtIaLpyDje9NG67JJ0+rA/wUZE7uN53nYToOWPScozEuIGjvtWx/lQjy77OPMRn6cA6WTPpQAmciHOaRaMC6nz+nUrqKb1K85NKA475rwGMJsc+SzoFddlMML3tyrKOHxdaNPye2DgM+h9T3EAqvErAZjg3SD3wbNHOECR6VjZx2Fxc/De0SCC1xNQdci+BHErOs53cGinhg+q6AjZoZviEIW1LM9v0JOcI5MLdNZIUDNJ3X08qFxUq/dko6mylUwlAHymB2kHbes5011sIOmW7sJuKYZpGFDJGXCPj0vTVjTWUGTud1y6smDWdKLYrjhkAJmtujWy9AI+D7BuWdH5PwDqhosvtFpVrAGntVsGQyU3qga6gKf4c1CpcFVqoO6C8FCrBgCSdIR8Q7Pr6J9rwHLytPcLfj5x45ibvEBK86yGbAClYqY/V5EUdBBNg4UGMBqS3L6tLFRXmNnAAJgBRYKYqsJa01JN0RpyypWnHx/4Ju62NDVzoP/OaloKoA0m8Hr8rC7loXXkLNUCAbazAW6qogJ5iUKbCSUumEdrKmy/3LuCfED8MAFXy24+e9tM5j5mxdzHA7pkpIe8BJhTR1tjHqMNibQmlUxAu65zdH9e9HC4p2jbWoiYgV0xKsvSDpB3qHQeQ2m1vN+lCf4CfSw4o4wcyLEAAAAASUVORK5CYII=" alt="Info Icon" />
          </div>
          <div className="col-10 pt-1">{children}</div>
        </div> </>
      }
    </div>

  );
}

StickyNote.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
};

StickyNote.defaultProps = {
  id: '',
  name: '',
  children: null,
  className: '',
};

export default StickyNote;
