import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ResendOTPBlock = ({ onTimeout, duration = 45, onResend}) => {
  const [seconds, setSeconds] = useState(duration);
  const [isActive, setIsActive] = useState(true);
  const [err, setErr] = useState('');

  useEffect(() => {
    let interval;
    if (isActive) {
      if (seconds > 0) {
        interval = setInterval(() => {
          setSeconds(seconds - 1);
        }, 1000);
      } else {
        setIsActive(false);
        if (onTimeout) {
          onTimeout(); // Call the onTimeout callback when timer is over
        }
      }
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, onTimeout]);

  const clearErrMsg = () => {
    setErr('');
  }

  const handleResend = () => {
    setSeconds(duration);
    setIsActive(true);
    clearErrMsg();
    if (onResend) {
      onResend();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-12">
          <p>You can resend security code in…</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-12 py-1 timerClass">
          <p>{seconds < 10 ? `00:0${seconds}` : `00:${seconds}`} Seconds</p>
          <div className="px-xl-0">
            <button
              role="button"
              name="resendPhoneSecurityCodeButton"
              type="button"
              className="btn btn-link mt-1 px-3 py-1 mb-md-1 px-xl-0"
              onClick={handleResend}
              disabled={isActive && seconds !== 0}
            >
              Resend security code
            </button>
          </div>
        </div>
      </div>
      {err &&
        <div className="row">
          <span className='APIErr'>{err} Resend security code</span>
        </div>
      }
    </>
  );
};

ResendOTPBlock.propTypes = {
  onTimeout: PropTypes.func,
  duration: PropTypes.string,
  onResend: PropTypes.func,
  children: PropTypes.string,
  className: PropTypes.string,
};

export default ResendOTPBlock;