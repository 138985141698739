import React, { useState } from 'react';
import { Banner, Button, Image } from '../../Atoms';
import CustomTable from '../../Molecules/ProductTable/customTable';
import classes from './styles.module.css';
import './styles.css';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import http from '../../../Common/Services/HttpService';
import appConfig from '../../../appConfig';
import $ from 'jquery';
import PrimaryIdetifierHelper from '../../Utils/Helper/PrimaryIdetifierHelper';
import DependentsTable from './dependentsTable';
import ManageBeneficiariesTable from '../ManageBeneficiariesTable';
import FormatHelper from '../../Utils/Helper/FormatHelper';
import { REMOVE_CURRENTSTEP_ALERT_MESSAGE, SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../Store/Slices/InsuredInfo';
import Input from '../../Atoms/Input/index'
import { UPDATE_ENROLLMENT_TYPE } from '../../../Store/Slices/Enrollment/index';
import { SET_EMPLOYEE_PROFILE, UPDATE_PREFERRED_CONTACT, ENROLLMENT_TYPE } from '../../../Store/Slices/EmployeeProfile';
import { PRODUCT_RESOURCES_COVERAGE_TITLE, PRODUCT_RESOURCES_PRODUCT_TITLE, STORE_IN_REDUX, CONTRACT_LINIAR_OR_NON_LINIAR } from '../../../Store/Slices/Process/index';
import { onLoadAPI, } from '../../../Store/Slices/Entities/response/index.js';
import { cloneDeep } from 'lodash';
import Loader from '../../Atoms/Loader';
import { useHistory } from 'react-router-dom';
import EmployeeTable from "./employeeTable"
import Tabs from '../../Molecules/CustomSection/Atoms/Tabs';
import {formatDollarAmountWithDecimals} from '../../Utils/Helper/ValidationHelper';

import {
  SET_PERSON_BUID,
  SET_LEGAL_ENTITY_BUID,
  ENROLLED_PRODUCTS,
  SET_REDIRECT_TO_ENROLLMENT,
  SET_EMPLOYEE_INFO,
  IS_ADMIN,
  SET_LOGIN_DATA,
  IS_HR_CENTER_RE_DIRECTED
} from '../../../Store/Slices/CallCenter';
import { apiReceivedMFAprocessType, processTypeMFA, SELECTED_EMPLOYEE_INFO, SET_CURRENT_PROCESS_TYPE, SET_CURRENT_URL, SET_REDIRECT_URL, START_PROXY_ENROLL } from '../../../Store/Slices/Auth/userAuth';
import { getFormKeyByProductFamily } from '../ProductTable/processData';
import { CALL_CENTER_ENROLL_EMPLOYEE_LOGIN_DATA } from '../../../Store/Slices/CallCenter';
import { PRODUCT_LIST_IDENTIFIER } from '../../../Store/Slices/Process';
import iconAdmin from '../../../Assets/Images/Icon Admin.png';
import iconReset from '../../../Assets/Images/Icon Reset.png';
import iconRetrieve from '../../../Assets/Images/Icon Retrieve.png';
import iconAdminEndorse from '../../../Assets/Images/Icon Admin Endorse.png';
import { initializeForms, initializeFormsData } from '../../../Store/Slices/StepFormNew';

import { useNavigationHook } from '../CustomSection/Hooks/NavigationHook';
import { processAuthTokenType } from '../../../Store/Slices/AuthToken/userAuthToken';
import EnrollmentHistory from '../EnrollmentHistory';
import { isEMpSsnDiff} from '../../../utils/helpers/isEMpSsnDiff';

const EmployeeData = () => {
  let {
    currentStep } = useNavigationHook();
  const formatHelper = new FormatHelper();
  const [showLoader, setShowLoader] = useState(false);
  const [empData, setEmpData] = useState();
  const [empContactData, setContactData] = useState();
  const [empEmploymentData, setEmploymentData] = useState();
  const [empDependentData, setEmpDependentData] = useState();
  const [empContactDataInfo, setEmpContactDataInfo] = useState();
  const [employeeLegalEntity, setEmployeeLegalEntity] = useState();
  const [employerName, setEmployerName] = useState();
  const [employeeId, setEmpolyeeId] = useState();
  const [correspondences, setCorrespondences] = useState();
  const [empDepDataDisplayFinal, setEmpDepDataDisplayFinal] = useState();
  const [empDepDataDisplayFinalProps, setEmpDepDataDisplayFinalProps] = useState();
  const [empBenefData, setEmpBenefData] = useState();
  const [ssnValue, setSSNValue] = useState('');

  // Variable to save the information about beneficiaries
  const [beneficiariesProduct, setBeneficiariesProducts] = useState([]);
  const [empSubscribedProduct, setEmpSubscribedProduct] = useState();
  const [coverageTitleData, setCoverageTitleData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [coverageTitleDataArray, setCoverageTitleDataArray] = useState([]);
  const [dateValue, setDate] = useState("");
  const [activeEffeactiveDate, setActiveEffeactiveDate] = useState("");
  const [backDateMsg, setBackDateMsg] = useState(false);
  const dispatch = useDispatch();
  const [btnSelected, setSelectedBtn] = useState('day');
  const [employeeIdFromRes, setEmployeeIdFromRes] = useState();
  const [employeeBuidFromRes, setBuidFromRes] = useState();
  const [employmentStatus, setEmploymentStatus] = useState();
  const [empInformation, setEmpInformation] = useState();
  const [oneForceData, setOneForceData] = useState({})
  const [getEnrollmentProduct, setEnrollmentproduct] = useState();

  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
  let history = useHistory();
  const primaryIdetifierHelper = new PrimaryIdetifierHelper();
  let processReduxState = useSelector((storeState) => storeState.process);
  const coverageTitle = useSelector(
    (storeState) => storeState?.process?.productResourcesCoverageTitle
  );
  const processReduxData = useSelector((storeState) => storeState?.process);
  const [coverageBuids, setCoverageBuids] = useState([]);
  const entitiesRedux = useSelector((storeState) => storeState?.entities);
  const registerationRedux = useSelector((storeState) => storeState?.registeration);
  const contractData = entitiesRedux?.response?.onLoadResponseList?.getMemberActiveContracts?._Result;
  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  const productResources = useSelector((storeState) => storeState?.process?.productResources);
  const currentLanguage = useSelector((storeState) => storeState.language?.currentLanguage);
  const everGreenEligibleProduct = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.EndorsementListOfEligibleProducts?._Result
  );
  const gbpContractData = useSelector(
    (storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result?.subscribedElements
  );
  const productTitle = useSelector((storeState) => storeState?.process?.productResourcesList);
  const rateInformations = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.RateInformation
  );
  const getCurrerntYearEnrollments = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.getCurrerntYearEnrollments?._Result?.subscribedElements

  );
  const employeeEmail = useSelector((storeState) => storeState.auth.userAuth?.employeeEmail);
  const userAuth = useSelector((storeState) => storeState.auth?.userAuth);
  const isHrCenter = userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter"
  const gbpDetails = processReduxData?.process?.contractsLinearOrNonLinear?._Result;
  const productCovergeCost = rateInformations?.coverageCost
  const productAnualCost = rateInformations?.AnualCost
  const employeeProfile = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile);
  const employeeIdFromRedux = employeeId
  let dataFromReduxPersonalInformation = entitiesRedux?.response?.onLoadResponseList?.PersonalInformation?.personalInformation;
  dataFromReduxPersonalInformation = (dataFromReduxPersonalInformation == undefined ? employeeProfile : dataFromReduxPersonalInformation)
  const legalEntityData = employeeLegalEntity?.identifier?.externalClientId?.externalClientId
  const legalEntityDataBackup = registerationRedux?.legalEntityId;
  const oneCode = legalEntityDataBackup ? (/[^:]*/.exec(legalEntityDataBackup)[0]) : legalEntityData
  let totalCost = 0.0;
  let totalCovergeAmount = productCovergeCost?.rate?.amount?.amount
  let totalAmount = `$${totalCovergeAmount?.toFixed(2) || ''}` || ''
  let anualTotalCost = productAnualCost?.rate?.amount?.amount;
  anualTotalCost = `$${anualTotalCost?.toFixed(2) || ''}` || ''
  const [totalCostMonthly, setCost] = useState(totalAmount);

  let dayCostOnClick = (curDocBUID) => {
    if (curDocBUID === 'annual') {
      setCost(anualTotalCost);
      setSelectedBtn('annual');
    } else {
      setCost(totalAmount);
      setSelectedBtn('day');
    }
  };
  useEffect(() => {
    dayCostOnClick()
  }, [totalAmount])
  let completeCost = {
    totalCost: 'Total Cost: ',
    totalCostMonthly: totalAmount,
    elements: [
      [
        {
          name: 'closeButton',
          type: 'button',
          placement: 'col-md-1',
          className: 'closeButton mr-4',
          label: 'Close',
        },
      ],
    ],
  };

  const employeeBuid = useSelector(
    (storeState) => storeState?.callCenter?.selectedEmployeeData?.buid
  );

  const { clientName = '', clientBuid = '', selectedPage, email = '', redirectToEnrollment = false, extension = '' } = useSelector((storeState) => storeState.callCenter);

  useEffect(() => {
    employeeDashBoardLoad()
    if (isHrCenter) {
      dispatch(processTypeMFA({ processType: 'HrCenter', userType: 'HrCenter', currentProcessType: 'EmployeeData', currentURL: '/hrcenter/employeeData', redirectURL: '/hrcenter' }))
      dispatch(apiReceivedMFAprocessType("HrCenter"));
      dispatch(SET_CURRENT_URL('/hrcenter/employeeData'))
      dispatch(SET_REDIRECT_URL('/hrcenter'))
      dispatch(SET_CURRENT_PROCESS_TYPE('EmployeeData'))
      dispatch(IS_HR_CENTER_RE_DIRECTED(false))
    } else {
      dispatch(processAuthTokenType({ authTokenType: 'callCenter' }))
      dispatch(apiReceivedMFAprocessType("callCenter"));
      dispatch(START_PROXY_ENROLL(false));
      dispatch(SET_CURRENT_URL('/callcenter/employeeData'))
      dispatch(SET_REDIRECT_URL('/callcenter/home'))
      dispatch(SET_CURRENT_PROCESS_TYPE('EmployeeData'))
    }


  }, []);

  const employeeDashBoardLoad = async () => {
    setShowLoader(true)
    return await http
      .post(appConfig.SSP_MID_URL + `/v1/callcenter-admin-dashboard`, {
        employeeBuid: employeeBuid,
        reasonEventDate: dateValue,
      })
      .then(async (resp) => {
        const data = resp?.data?.data
        dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE());
        for (const key in data) {
          const result = { _Result: data[key].data }
          switch (key) {
            case 'gbpcontractsLinearOrNonLinear':
              dispatch(CONTRACT_LINIAR_OR_NON_LINIAR(result))
              break;
            case 'getEmployeeData':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getEmployeeData',
                  show: true,
                  page: currentStep,
                }));
              } else {
                setEmpInformation(result?._Result)
              }
              break;
            case 'getMemberActiveContracts':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getMemberActiveContracts',
                  show: true,
                  page: currentStep,
                }));
              } else {
                dispatch(onLoadAPI({ ...{ [`getMemberActiveContracts`]: result } }));
              }
              break;
            case 'subscribedcontracts':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'subscribedcontracts',
                  show: true,
                  page: currentStep,
                }));
              } else {
                dispatch(onLoadAPI({ ...{ [`subscribedcontracts`]: result } }));
              }
              break;
            case 'getEnrolledProducts':
              let enrolledData = data[key].data;
              setEnrollmentproduct(enrolledData)
              dispatch(ENROLLED_PRODUCTS({ enrolledData }));
              break;
            case 'getEnrollments':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getEnrollments',
                  show: true,
                  page: currentStep,
                }));
              } else {
                dispatch(onLoadAPI({ ...{ [`getCurrerntYearEnrollments`]: result } }));
              }
              break;
            case 'rateInformation':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'rateInformation',
                  show: true,
                  page: currentStep,
                }));
              } else {
                dispatch(onLoadAPI({ ...{ [`RateInformation`]: data[key].data } }));
              }
              break;
            case 'searchPolicyProcessResourcesCoverageCallCenter':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'searchPolicyProcessResourcesCoverageCallCenter',
                  show: true,
                  page: currentStep,
                }));
              } else {
                const productResourcesCoverageTitle = data[key].data;
                setCoverageTitleDataArray(productResourcesCoverageTitle);
                dispatch(PRODUCT_RESOURCES_COVERAGE_TITLE({ productResourcesCoverageTitle }));
                dispatch(onLoadAPI({ productResourcesCoverageTitle }));
              }
              break;
            case 'searchPolicyProcessResourcesCallCenter':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'searchPolicyProcessResourcesCallCenter',
                  show: true,
                  page: currentStep,
                }));
              } else {
                const productResourcesList = data[key].data;
                dispatch(PRODUCT_RESOURCES_PRODUCT_TITLE({ productResourcesList }));
                dispatch(onLoadAPI({ productResourcesList }));
              }
              break;
            default:
              dispatch(onLoadAPI({ [key]: result }));
          }
        }

        setShowLoader(false)
        return true;
      })
      .catch((e) => {
        setShowLoader(false)
        return false;
      });
  };

  useEffect(() => {
    let personData = empInformation?.person?.data?.actor;
    let contactData = empInformation?.person?.data?.correspondences[0]?.address;
    let employmentData = empInformation?.employee;
    let employerData = empInformation?.legalEntity?.identifier?.description?.replace(/\(.*?\)/g, '')?.trim()
    let employerId = empInformation?.employeeId
    let data = empInformation?.person?.data
    let externalClientId = empInformation?.legalEntity?.identifier?.externalClientId?.externalClientId
    setEmployeeIdFromRes(empInformation?.employeeId)
    setBuidFromRes(empInformation?.bUID)
    setCorrespondences(empInformation?.person?.data?.correspondences?.[0])
    setEmpData(personData);
    setContactData(contactData);
    setEmploymentData(employmentData);
    setEmployerName(employerData);
    setEmpolyeeId(employerId);
    setEmployeeLegalEntity(empInformation?.legalEntity)
    let empStatus = empInformation?.employee?.employmentStatus
    setEmploymentStatus(empStatus)
    setSSNValue(formatHelper.getFormatSSN(empInformation?.person?.data?.actor?.sSN));

    let getPayFrequency = employmentData?.additionalDynamicDatas?.find(eachObj => eachObj?.value?.fieldCode === "EMPD_00002") || '';
    let getEmploymentStatus = employmentData?.additionalDynamicDatas?.find(eachObj => eachObj?.value?.fieldCode === "EMPD_00092") || '';
    let payFreqeuncyValueCode = employmentData?.additionalDynamicDatas?.find(eachObj => eachObj?.value?.fieldCode === "EMPD_00002") || '';
    let contactDataInfoData = empInformation?.person?.data?.correspondences[0];
    setEmpContactDataInfo(contactDataInfoData);
    let personalInformation = {
      "firstName": personData?.shortName,
      "middleName": personData?.middleName1 || '',
      "lastName": personData?.name,
      "gender": personData?.gender,
      "ssn": personData?.sSN || '',
      "buid": personData?.buid,
      "dateOfBirth": personData?.birthDate,
      "line1": contactData?.line1,
      "line2": contactData?.line2,
      "zipCode": contactData?.zipCodeAndCity?.zipCode,
      "city": contactData?.zipCodeAndCity?.city,
      "state": contactData?.state,
      "country": contactData?.country,
      "alternateAddressLine1": "",
      "alternateAddressLine2": "",
      "alternateAddressZipCode": "",
      "alternateAddressCity": "",
      "alternateAddressState": "",
      "alternateAddressCountry": "",
      "primaryAddress": contactData?.line1 + ' ' + contactData?.zipCodeAndCity?.city + ' ' + contactData?.state + ' ' + contactData?.zipCodeAndCity?.zipCode + ' ' + contactData?.country,
      "officePhone": empInformation?.person?.data?.correspondences?.[0]?.officePhone,
      "workEmail": empInformation?.person?.data?.correspondences?.[0]?.optionalEmail,
      "registeredEmail": "",
      "personalEmail": empInformation?.person?.data?.correspondences?.[0]?.eMail,
      "personalPhone": empInformation?.person?.data?.correspondences?.[0]?.privatePhone,
      "mediaType": "",
      "liveInSameAddress": personData?.preferredContactChoice,
      "primaryAddressLine1Display": contactData?.line1,
      "primaryAddressLine2Display": "",
      "primaryAddressLine3Display": contactData?.zipCodeAndCity?.city + ' ' + contactData?.state + ' ' + contactData?.zipCodeAndCity?.zipCode + ' ' + contactData?.country,
    }
    let employmentInformation = {
      "employerName": employerData,
      "workLocation": getWorkLocation(employmentData),
      "salary": employmentData?.baseSalaryAmount?.amount,
      "payFrequency": getPayFrequency?.value?.fieldValue,
      "employmentStatus": getEmploymentStatus?.value?.fieldValue,
      "payFreqeuncyValueCode": payFreqeuncyValueCode?.value?.valueCode,
      "employeeId": employeeBuid
    }

    let salesForceData = {
      personalInformation,
      employerId,
      externalClientId
    }
    setOneForceData(salesForceData)
    dispatch(SET_EMPLOYEE_PROFILE(personalInformation));
    dispatch(
      onLoadAPI({
        PersonalInformation: { personalInformation },
        isAPICallInProgress: false,
      })
    );
    dispatch(onLoadAPI({
      EmploymentInformation: { employmentInformation }
    })
    );
    dispatch(CALL_CENTER_ENROLL_EMPLOYEE_LOGIN_DATA({ data: { person: data } }))

    dispatch(
      SET_LEGAL_ENTITY_BUID({
        selectedPersonLegalEntityBUID: empInformation?.legalEntity?.identifier?.bUID,
      })
    );
    dispatch(
      SET_PERSON_BUID({ selectedPersonBUID: empInformation?.person?.data?.actor?.buid })
    );

  }, [empInformation])
  let showBenef;
  useEffect(() => {
    const getEnrollmentProductData = getEnrollmentProduct
    let depData = getEnrollmentProductData?.insureds;
    let enrolledData = getEnrollmentProductData;
    setEmpDependentData(depData);
    let subscribedProducts = getEnrollmentProductData?.subscribedElements;
    setEmpSubscribedProduct(subscribedProducts);
    let benefData = getEnrollmentProductData?.subscribedElements[0]?.coverages[0];
    showBenef = benefData?.insuredPersons[0]?.beneficiaries;
    setEmpBenefData(benefData);
    let depDataDisplay = getEnrollmentProductData?.insureds;
    let depDataDisplayCopy = depDataDisplay?.slice(0);
    setEmpDepDataDisplayFinal(depDataDisplayCopy?.splice(1, 1));
    let depDataDisplayCopy1 = depDataDisplay?.slice(0);
    let dataTemp = {};
    Object.assign(dataTemp, { DependentEmployeeTable: depDataDisplayCopy1?.splice(1, 1) });
    setEmpDepDataDisplayFinalProps(dataTemp);
    let coverageBuidsFinal = [];
    let coverageBuidArray = enrolledData?.insureds;
    for (let i = 0; i < coverageBuidArray?.length; i++) {
      let prodCoverages = coverageBuidArray[i].nonSharedAdditionalDatas;
      for (let j = 0; j < prodCoverages?.length; j++) {
        let buidFinal =
          prodCoverages[j]?.coverageNonSharedAdditionalDatas[0]?.coverageIdentifier?.bUID;

        let data = buidFinal;
        if (coverageBuidsFinal.indexOf(data) === -1) {
          coverageBuidsFinal.push(data);
        }
        //   : ()=>{};
      }
    }
    setCoverageBuids(coverageBuidsFinal);
  }, [getEnrollmentProduct])

  let contractBuid = ""
  let activeContractDatas = contractData && contractData?.filter(e => e?.status === 'cActive');
  useEffect(() => {
    if (activeContractDatas?.length > 0) {
      setActiveEffeactiveDate(activeContractDatas?.[1 - activeContractDatas?.length]?.effectiveDate)
    }
  }, [activeContractDatas])
  if (activeContractDatas?.length > 0) {
    contractBuid = activeContractDatas?.[0]?.contract?.buid
  }

  const inProgressBuid = contractData && contractData?.find(e => e?.status === 'cProject')?.contract?.buid;

  const adminEndorsementProcess = async () => {
    setShowLoader(true)
    return await http
      .post(appConfig.SSP_MID_URL + '/initEndorsementProcess', {
        payload: {
          contractBuid: contractBuid,
          reasonEventDate: dateValue,
          reason: 'ADE',
        }
      })
      .then((resp) => {
        if (resp.data && resp.status === 200) {
          dispatch(onLoadAPI({ ...{ [`AdminEndorsementProcess`]: resp.data, isAPICallInProgress: false } }));
          dispatch(ENROLLMENT_TYPE({adminReasonType: 'ADE'}));
          dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
          dispatch(initializeForms({}));
          dispatch(initializeFormsData({}));
          history.push("/enrollment/employeeProfile");
          if (isHrCenter) {
            dispatch(IS_HR_CENTER_RE_DIRECTED(true))
          }
        }
        setShowLoader(false)
      }).catch(e => {
        setShowLoader(false)
      });
  };

  const adminEnrollmentProcess = async () => {
    setShowLoader(true)
    return await http
      .post(appConfig.SSP_MID_URL + '/initNewEnrollmentProcess', {
        payload: {
          employeeBuid: employeeBuid,
          contractBuid: entitiesRedux?.response?.onLoadResponseList?.subscribedcontracts?._Result[0]?.contract?.buid,
          reasonEventDate: dateValue,
          reason: 'ADE',
        }
      })
      .then(async (resp) => {
        if (resp.data && resp.status === 200) {
          if (resp?.data && resp?.status === 200) {
            let UpdatedValue = []
            UpdatedValue.push(resp?.data)
            dispatch(STORE_IN_REDUX(UpdatedValue))
            dispatch(ENROLLMENT_TYPE({adminReasonType: 'ADE'}));
            dispatch(initializeForms({}));
            dispatch(initializeFormsData({}));
            history.push("/enrollment/employeeProfile");
            if (isHrCenter) {
              dispatch(IS_HR_CENTER_RE_DIRECTED(true))
            }
            await postSalesForceBearerToken()
          }
        }
        setShowLoader(false)
      }).catch(e => {
        setShowLoader(false)
      });
  };



  let salesforceAPITimeout = 10000
  const postSalesForceBearerToken = async () => {
    setShowLoader(true)
    let contactDtailsAPI = {}

    let personalDetails = primaryIdetifierHelper.getPersonalDetails(employeeIdFromRedux, dataFromReduxPersonalInformation, oneCode);

    //personalDetails.oneCode = oneCode
    http
      .post(appConfig.SSP_MID_URL + '/getCapturePreferenceAPI', personalDetails, { timeout: salesforceAPITimeout })
      .then(async (resp) => {
        contactDtailsAPI.personalPhone = resp.data.smsPhoneNumber
        contactDtailsAPI.personalEmail = resp.data.emailAddress
        contactDtailsAPI.preferredContact = resp.data.preferredMethodOfCommunication
        contactDtailsAPI.clientSMSFlag = resp.data.clientSMSFlag
        contactDtailsAPI.clientEmailFlag = resp.data.clientEmailFlag
        contactDtailsAPI.contactId = resp.data.contactId
        contactDtailsAPI.OfferPreferenceUpdate = resp.data.OfferPreferenceUpdate
        dispatch(UPDATE_PREFERRED_CONTACT(contactDtailsAPI))
        setShowLoader(false)
      })
      .catch((e) => {
        setShowLoader(false)
      });
  };
  const adminEnrollmentModel = () => {
    setBackDateMsg(false);
    $('#adminModal').modal('show');
    dispatch(IS_ADMIN(true))
    setDate('')
  };
  const cancelModal = () => {
    setBackDateMsg(false);
    $('#adminModal').modal('hide');
    dispatch(IS_ADMIN(false))
    setDate('')
  };
  const memberContractStatus = ['cProject', 'cDeclined', 'cTerminated', 'cNeverInForce'];
  let activecontract = contractData?.filter(e => e.status === 'cActive')
  let cProjectContract = contractData?.filter(e => memberContractStatus.includes(e.status));
  const isCproject = cProjectContract?.length > 0
  const isActivecontract = activecontract?.length > 0

  // Admin endorement Effactive Date > Active not allowed
  const handleSubmitAdmin = async () => {
    if ((Date.parse(dateValue) < Date.parse(activeEffeactiveDate))) {
      $('#adminModal').modal('show');
      setBackDateMsg(true);
    } else {
      $('#adminModal').modal('hide');
      setBackDateMsg(false);
      if (isActivecontract) {
        adminEndorsementProcess()
      }
      else if (isCproject || (Array.isArray(contractData) &&
        contractData.length === 0)) {
        adminEnrollmentProcess()
      }
    }
  }

  const onDateChange = (event) => {
    setBackDateMsg(false);
    setDate(event.target.value);
  };

  //Code to get work location
  const getWorkLocation = (location) => {
    let locCode = location?.additionalDynamicDatas?.filter(
      (data) => data?.value?.fieldCode == 'EMPD_00058'
    );

    let classValue = locCode && locCode[0]?.value?.className;
    if (locCode == undefined) {
      return '';
    } else {
      if (classValue == 'aSPCB_ClassificationValue') {
        return locCode[0]?.value?.fieldValue;
      } else if (classValue == 'aSPCB_AdditionalDataValue_CString') {
        return locCode[0]?.value?.fieldValue;
      }
    }
  };


  const productFamilySubscribed = entitiesRedux?.response?.onLoadResponseList?.subscribedcontracts?._Result[0]?.subscribedElements

  const productFamilyIdentifiers = productFamilySubscribed?.map((product) => {
    const productBuid = product?.memberOfferedProduct?.bUID
    const productFamily = product?.memberOfferedProduct?.product?.productFamily
    return { productBuid: productBuid, productFamily: productFamily }
  })

  const productFamilyIdentifiersName = productFamilyIdentifiers?.map(el => {

    let details = {};
    details['product'] = { productFamily: el?.productFamily };
    details['bUID'] = el?.productBuid

    return { details: details };
  })
  const beneficiariesFullData = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.beneficiariesData
  ) || {};


  useEffect(() => {
    if (productFamilyIdentifiersName?.length > 0) {
      dispatch(PRODUCT_LIST_IDENTIFIER(productFamilyIdentifiersName));
    }
  }, [productFamilySubscribed])


  const productLists = processReduxState?.productFamilyIdentifier;

  const processData = processReduxState?.productFamilyIdentifier;


  const subscribedElements = empSubscribedProduct;
  // Method to split the information based on beneficiaries type
  const getBeneficiaryType = (beneficiaryArray = []) => {
    const contingentArray = [];
    const primaryArray = [];
    const beneficiaryObj = {
      Primary: [],
      Contingent: [],
    };
    if (beneficiaryArray.length > 0) {
      beneficiaryArray.forEach((beneficiary) => {
        if (
          beneficiary &&
          beneficiary.beneficiaryInformation &&
          beneficiary.beneficiaryInformation.beneficiaryRank === 'cPrimary'
        ) {
          primaryArray.push(beneficiary);
        } else if (
          beneficiary &&
          beneficiary.beneficiaryInformation &&
          beneficiary.beneficiaryInformation.beneficiaryRank === 'cContingent'
        ) {
          contingentArray.push(beneficiary);
        }
      });
    }
    beneficiaryObj['Primary'] = primaryArray;
    beneficiaryObj['Contingent'] = contingentArray;
    return {
      beneficiaryObj,
      hasBeneficiaries: primaryArray.length > 0 || contingentArray.length > 0 ? true : false,
    };
  };

  useEffect(() => {
    dispatch(UPDATE_LOADING(true));

    // setCoverageTitleData(coverageTitle);

    setLoadingData(true);
    dispatch(UPDATE_LOADING(false));
  }, []);
  useEffect(() => {
    if (
      empSubscribedProduct &&
      empSubscribedProduct.length > 0 &&
      coverageTitle?.length > 0

    ) {
      const productsArray = [];
      let i = 0;
      empSubscribedProduct.forEach((product) => {
        const productWaived = product?.subscribedProduct?.status === 'cDeclined' || product?.subscribedProduct?.status === 'cTerminated' || product?.subscribedProduct?.status === 'cNeverInForce';
        if (!productWaived) {
          const productId = product && product.bUID || '';
          const productCode = product?.subscribedProduct?.productCode?.buid || '';
          const productBuid = product && product.subscribedProduct && product.subscribedProduct.productCode && product.subscribedProduct.productCode.buid || '';
          const coverages = product && product.coverages || [];
          const productName = Object.keys(processReduxData.productFamilyIdentifier).find(key => processReduxData.productFamilyIdentifier[key] === productBuid);
          const productNameTitle = getFormKeyByProductFamily(productName);
          if (coverages.length > 0) {
            coverages?.forEach(coverage => {
              const productNameDescription = (coverage && coverage?.subscribedOption && coverage?.subscribedOption.optionName) || '';
              const coverageBuid = coverage && coverage.bUID;
              const newInsureArray = [];
              let newPersonBuid = '';
              coverage && coverage?.insuredPersons?.forEach(insure => {
                const insuredPerson = insure && insure.beneficiariesDescription && insure.beneficiariesDescription.beneficiaries || [];
                if (insuredPerson?.length > 0) {
                  const personBuid = insure && insure.person && insure.person.identifier && insure.person.identifier.bUID || '';
                  newPersonBuid = personBuid;
                  insuredPerson.forEach(insurePerson => {
                    const newInsurePerson = cloneDeep(insurePerson);
                    newInsurePerson['personBuid'] = personBuid;
                    newInsurePerson['fullData'] = beneficiariesFullData[newInsurePerson?.beneficiary?.identifier?.bUID] || {};
                    newInsureArray.push(newInsurePerson);
                  });
                }
              });
              const productObj = {};
              const beneficiariesInformation = getBeneficiaryType(newInsureArray);
              let titleObj = {};
              coverageTitle && coverageTitle?.find(coverage => {

                if (coverage?.productId === productCode && coverage?.insuredPersonId === newPersonBuid) {
                  titleObj = coverage;
                }
              });
              productObj['personBuid'] = newPersonBuid;
              productObj['productId'] = productBuid;
              productObj['productBuid'] = productId;
              productObj['productTitle'] = titleObj?.content || '';
              productObj['productName'] = productNameTitle;
              // productObj['productTitle'] = resource?.content || '';
              productObj['productNameDescription'] = productNameDescription;
              productObj['coverageBuid'] = coverageBuid;
              productObj['beneficiaries'] = beneficiariesInformation && beneficiariesInformation.beneficiaryObj;
              productObj['hasBeneficiaries'] = beneficiariesInformation && beneficiariesInformation.hasBeneficiaries;
              productsArray.push(productObj);
            });
          }
        }
      });
      setBeneficiariesProducts(productsArray);
      // setLoadingData(false)
    }
  }, [empSubscribedProduct, coverageTitle?.length > 0]);
  const getCoverageDetails = empSubscribedProduct;
  const form = {
    'Accident': 'CoverageSelection',
    'Pet Insurance': 'PetAssureNation',
    'Whole Life': 'WLCoverage',
    'ID Theft': 'IdTheft',
    "Supplemental Health Insurance": "SupplementalHealthInsurance",
    'Legal': 'LegalCoverage',
    'Travel Insurance': 'Travel',
    'Pet Insurance - PETPlus': 'PetPlus',
    'Pet Insurance - Pet Assure': 'PetAssure',
    'Hospital Indemnity': 'HospitalIndemnity',
    'Critical Illness': 'CriticalIllness',
    'Liability': 'ExcessLiability',
  };

  /* Endorsement  content */
  let insuredInfoCopy = {};
  ;
  Object?.entries(processData.productFamilyIdentifier || {})?.forEach(([key, value]) => {
    insuredInfoCopy[key] = {
      InsuredQuestionAnswers: {},
      coverageDetails: [],
      coverageBuids: [],
      PolicyReplacementQuestions: [],
      WaiveCoverage: [],
    };
  });
  let productListBuids = getCurrerntYearEnrollments?.map((item) => {
    return {
      productBuid: item?.subscribedProduct?.productCode?.buid
    }
  })
  let endrosemntProductFamilyIdentifier = {};

  //Get Dependent List
  const insureds = empDependentData;



  let filterEverGreenProducts = []
  getCurrerntYearEnrollments?.forEach((obj, index) => {
    let a = getCoverageDetails?.filter((elem) => elem?.subscribedProduct?.productCode?.buid === obj?.memberOfferedProduct?.bUID || elem?.subscribedProduct?.productCode?.buid === obj?.subscribedProduct?.productCode?.buid)
    if (a?.length === 0) { filterEverGreenProducts?.push(obj) }
  });

  let finalEligibleProduct = getCoverageDetails?.concat(filterEverGreenProducts);

  const declinedProductCoverge = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.declinedCoverage?._Result?.contract
        .subscribedElements
  );
  const declinedCoverge = declinedProductCoverge?.map((coverge) => {
    return (
      coverge?.subscribedProduct?.status === 'cDeclined' ||
      coverge?.subscribedProduct?.status === 'cTerminated'
    );
  });


  const costPerCoverage = productCovergeCost?.subscribedElements?.map((element) => {
    return element?.coverages?.map((coverage) => {
      return {
        amount: `$${coverage?.rate?.amount?.amount?.toFixed(2)}`,
        rateProductIdentifier: coverage?.identifier?.bUID,
      };
    });
  });

  let costFlattenedData = costPerCoverage?.flatMap((arr) => arr);
  const anualCostPerCoverage = productAnualCost?.subscribedElements?.map((element) => {
    return element?.coverages?.map((coverage) => {
      return {
        anualAmount: `$${coverage?.rate?.amount?.amount?.toFixed(2)}`,
        anualCostProductIdentifier: coverage?.identifier?.bUID,
      };
    });
  });
  let AnualCostFlattenedData = anualCostPerCoverage?.flatMap((arr) => arr);

  const checkEvergereenAndCancel = gbpContractData?.map((prod) => {
    let items = prod?.subscribedProduct?.additionalDatas;
    const checkevergerrenTrue = items?.some(
      (eachObj) => eachObj?.fieldCode === 'IEGP' && eachObj.fieldValue === false
    );
    const checkCancelCoverage = items?.some(
      (eachObj) => eachObj?.fieldCode === 'DD_00179' && eachObj?.valueCode === 'ATAT'
    );
    return {
      cancelCovBuid: prod?.memberOfferedProduct?.bUID,
      checkevergerrenTrue: checkevergerrenTrue,
      checkCancelCoverage: checkCancelCoverage,
    };
  });
  const checkEverGreen = everGreenEligibleProduct?.map((product) => {
    const checkProduct = product?.subscribedProduct?.additionalDatas;
    const everGreenProductBuid = product?.memberOfferedProduct?.bUID;
    const productFamily = product?.memberOfferedProduct?.product?.productFamily;
    const filterData = checkProduct.find(
      (eachObj) => eachObj?.fieldCode === 'IEGP' && eachObj.fieldValue == true
    );
    // const checkCancelCoverage = checkProduct?.find(eachObj => eachObj?.fieldCode === 'DD_00179' && (eachObj?.valueCode === 'ATAT' || eachObj?.valueCode === 'AQLE' || eachObj?.valueCode === 'ATOE'))
    return {
      filterData: filterData,
      everGreenProductBuid: everGreenProductBuid,
      productName: productFamily,
      // checkCancelCoverage: checkCancelCoverage
    };
  });

  let coverageTypeInformation = []
  if (gbpDetails?.subscribedElements?.length > 0) {
    const eligibleProducts = gbpDetails?.subscribedElements?.map((prod, index) => {
      let coverge = prod?.coverages?.[0]
      if (productTitle?.[index]?.content === 'Critical Illness') {
        let insuredConfiguration = coverge?.memberOfferedCoverage?.option?.insuredConfiguration
        let multiCovered = insuredConfiguration?.coverageMode
        let coveredDependants = insuredConfiguration?.coveredDependants
        let coveredType = multiCovered === 'cmMultiCovered' && (coveredDependants.includes("cCoveredChild") ||
          coveredDependants.includes("cCoveredSpouse") ||
          coveredDependants.includes("cCoveredChild"));
        coverageTypeInformation.push({ coveredInfo: coveredType })
      }
    })
  }
  let productMapdetails = finalEligibleProduct?.map((item) => {
    return item?.coverages?.map((el) => {
      const subscribedOption = el?.subscribedOption?.additionalDatas;
      const coverageBuidCheck = el?.bUID;
      const eligibleProductBuids = item?.memberOfferedProduct?.bUID
      // const subscribedOption = item?.coverages?.subscribedOption || [];
      const checkProduct = item?.subscribedProduct?.additionalDatas;
      const everGreenProductBuid = item?.memberOfferedProduct?.bUID;
      const checkEnroll =
        checkProduct?.find(
          (eachObj) => eachObj?.fieldCode === 'IEGP' && eachObj?.fieldValue == true
        ) && item?.subscribedProduct?.status === 'cActive';
      const notEnrolled = item?.subscribedProduct.status === '' ? true : false
      const waived = el?.subscribedOption?.status === 'cDeclined' || el?.subscribedOption?.status === 'cNeverInForce' ? true : false;
      const waviedTreminated =
        el?.subscribedOption?.status === 'cTerminated' ||
          el?.subscribedOption?.status === 'cNeverInForce'
          ? true
          : false;
      const subscribedProductIdentifier = item?.subscribedProduct?.buid;
      const productBuid = item?.subscribedProduct?.productCode?.buid || '';
      const declinedProduct = item?.subscribedProduct?.status === 'cDeclined' ? true : false;
      // const effectiveDate = getProductDetail?.processEntity?.effectiveDate
      const plannedTerminationDate = el?.subscribedOption?.plannedTerminationDate
      // const secondTermination = effectiveDate > plannedTerminationDate
      const declinedProductTerminated =
        item?.subscribedProduct?.status === 'cTerminated' ? true : false;
      const additionalDatas = subscribedOption || [];
      const fieldCodeObj = additionalDatas?.find((data) => data?.fieldCode === 'PlanDesign')
      const isPlandesignNotPresent = fieldCodeObj === undefined && el?.subscribedOption?.status === 'cActive';
      const coverageLevel = additionalDatas?.find(data => data?.fieldCode === "DD_00175") || {};
      const petName = additionalDatas?.find(data => data?.fieldCode === "DD_00169") || {};
      let productFamily = '';
      let formName = '';
      Object.keys(productLists).forEach(function (key) {
        if (productLists[key] == item?.subscribedProduct?.productCode?.buid) {
          productFamily = key;
          formName = form[key];
        }
      });
      const everGreenData = checkEverGreen?.filter((item) => item?.filterData !== undefined);
      const checkEvergereenEdit =
        everGreenData?.filter(
          (value) => value?.everGreenProductBuid === item?.subscribedProduct?.productCode?.buid
        )?.length > 0
          ? true
          : false;
      // let checkeverGreenProductFamily = everGreenProductName?.find((value) => value?.everGreenProductBuid === item?.memberOfferedProduct?.bUID)
      const filtercancelCoverage = checkEvergereenAndCancel?.filter(
        (value) => value?.cancelCovBuid === item?.subscribedProduct?.productCode?.buid
      );
      const cancelCoverage = filtercancelCoverage?.find(
        (eachObj) => eachObj?.checkCancelCoverage == true && eachObj?.checkevergerrenTrue == true
      )
        ? true
        : false;
      const petProductStatusProject = item?.subscribedProduct?.status === "cProject"
      const petProductStatusActive = item?.subscribedProduct?.status === "cActive"
      const petProductStatusDeclined = item?.subscribedProduct?.status === "cDeclined" || item?.subscribedProduct?.status === "cTerminated" || item?.subscribedProduct.status === "cNeverInForce"
      const PetProductDeclinedTerminated = el?.subscribedOption?.status === "cDeclined" || el?.subscribedOption?.status === "cNeverInForce" || el?.subscribedOption?.status === "cTerminated" ? true : false
      const petProductWaviedStatus = (petProductStatusActive || petProductStatusProject || petProductStatusDeclined) && ((el?.subscribedOption?.status !== "cActive") || (el?.subscribedOption?.status !== "cProject")) && PetProductDeclinedTerminated ? true : false

      let everGreenFormName = '';
      let everGreenProductFamily = '';
      let everGreenProductbuid = '';
      Object.keys(endrosemntProductFamilyIdentifier).forEach(function (key, value) {
        if (
          endrosemntProductFamilyIdentifier[key] === item?.memberOfferedProduct?.bUID ||
          endrosemntProductFamilyIdentifier[key] === item?.subscribedProduct?.productCode.buid
        ) {
          everGreenProductFamily = key;
          everGreenFormName = form[key];
          everGreenProductbuid = item?.memberOfferedProduct?.bUID;
        }
      });
      const costPerPayPeriod = costFlattenedData
        ?.filter((cost) => cost?.rateProductIdentifier === coverageBuidCheck)
        ?.map((el) => el?.amount);
      const anualCost = AnualCostFlattenedData?.filter(
        (cost) => cost?.anualCostProductIdentifier === coverageBuidCheck
      )?.map((el) => el?.anualAmount);
      //check this code
      const productResource =
        productResources[productBuid] || productResources[everGreenProductbuid];
      const resource = productResource?.info?.resources?.find((item) => item.code === 'PRODTITLE');


      const getCoveredMembers = []
      let relationshipWithinsured = [];
      getCoverageDetails?.forEach((subscribedElement) => {
        let productCode = subscribedElement?.subscribedProduct?.productCode?.buid;
        subscribedElement.coverages?.forEach((coverage) => {
          const subscribedCoverageBUIDs = []

          coverage.insuredPersons?.forEach((insuredPerson) => {
            const bUID = insuredPerson.person?.data.bUID;
            subscribedCoverageBUIDs.push(bUID);
          })

          const insuredPersonRelationship = [];
          insureds?.forEach((insured) => {
            const relationshipToInsured = insured.insured?.mainOwnerRelationshipToInsured;
            const bUID = insured.person?.data?.bUID;

            let { shortName: firstName, name: lastName } = insured.person?.data?.actor || {}
            let fullName = firstName + ' ' + lastName;

            let relationship = ''
            if (subscribedCoverageBUIDs.includes(bUID) && relationshipToInsured === "") {
              relationship = 'Employee'
            } else if (subscribedCoverageBUIDs.includes(bUID) && relationshipToInsured === "SP") {
              relationship = 'Spouse'
            }
            else if (subscribedCoverageBUIDs.includes(bUID) && relationshipToInsured === "CH") {
              relationship = 'Child'
            }
            else if (subscribedCoverageBUIDs.includes(bUID) && relationshipToInsured === "  DP") {
              relationship = 'Domestic Partner'
            }
            else if (subscribedCoverageBUIDs.includes(bUID) && relationshipToInsured === "CUP") {
              relationship = 'Civil Union Partner'
            }

            if (relationship) {
              insuredPersonRelationship.push(productCode, { insureRelationShip: `${fullName} (${relationship})` });
              relationshipWithinsured.push({
                buid: coverage.bUID,
                relationship: relationship
              })
            }
          })

          getCoveredMembers.push(insuredPersonRelationship);
        })
      })
      let coverProductBuid = eligibleProductBuids || productBuid || everGreenProductbuid
      return {
        coverageLevel: coverageLevel?.fieldValue ? coverageLevel?.fieldValue : isPlandesignNotPresent ? 'Due to a change in the eligiblity, please call the Contact Center to complete your enrollment' : fieldCodeObj?.nameDescription,
        petName: petName?.fieldValue,
        costPerPayPeriod: costPerPayPeriod,
        anualCost: anualCost,
        productBuid: productBuid,
        everGreenProductbuid: everGreenProductbuid,
        waived: waived,
        waviedTreminated: waviedTreminated,
        declinedProduct: declinedProduct,
        declinedProductTerminated: declinedProductTerminated,
        productFamily: productFamily,
        formName: formName,
        everGreenFormName: everGreenFormName,
        getCoveredMembers: getCoveredMembers,
        checkEvergereenEdit: checkEvergereenEdit,
        everGreenProductFamily: everGreenProductFamily,
        checkEnroll: checkEnroll || false,
        notEnrolled: notEnrolled,
        cancelCoverage: cancelCoverage,
        declinedCoverge: declinedCoverge,
        coverageBuid: coverageBuidCheck,
        resource: resource?.content,
        coverProductBuid: coverProductBuid,
        petProductWaviedStatus: petProductWaviedStatus,
        relationshipWithinsured: relationshipWithinsured,
        coverageTypeInformation: coverageTypeInformation,
        isPlandesignNotPresent: isPlandesignNotPresent
      };
    });
  });

  if (showLoader) {
    return <Loader loading={showLoader} />;
  }
  const getBasicAuthHeaderValue = () => {
    let authValue = process.env.REACT_APP_MFA_AUTH_USER;

    // Encoding into base64 using btoa
    let basicAuthHeaderValue = authValue ? btoa(authValue) : '';

    return basicAuthHeaderValue;
  };

  const validateAndGetEmail = (log) => {
    for (let i = 0; i < log.length; i++) {
      {/** Register required: 
        login: {eid}_{LE}:FO
        name:mfa_{EID}_{LE}:FO
        email:test@gmail.com  or name
     */}

      if (log[i].loginEntity.name.startsWith(process.env.REACT_APP_LOGIN_PREFIX) || log[i].loginEntity.name.startsWith(process.env.REACT_APP_EMAIL_SSO_PREFIX)) {
        let loginWithoutPrefix = log[i].loginEntity.name.split('_')[1]
        return loginWithoutPrefix; // log[i].loginEntity.name.slice(4)
      }
    }
    return false
  }

  const validateAndGetEmailNotRegistered = (log, eid, legalEntity) => {
    //check if it contains contains the legalentiry id different member id and ssn
    // if (isEMpSsnDiff(legalEntity)) {
    //   return `${eid}_${legalEntity}`;
    // }
    {/** Register not required */ }
    for (let i = 0; i < log.length; i++) {
      if (log[i].loginEntity.email) {
        const splittedLogin = log[i].loginEntity.email.split('_');
        let login = splittedLogin[splittedLogin.length - 2] + '_' + splittedLogin[splittedLogin.length - 1];
        let correctEid = splittedLogin[splittedLogin.length - 2] === eid;
        let correctLE = splittedLogin[splittedLogin.length - 1] === legalEntity;
        let correctLogin = correctEid && correctLE;
        if (correctLogin)
          return login
      }
      if (log[i].loginEntity.name) {
        const splittedName = log[i].loginEntity.name.split('_');
        let name = splittedName[splittedName.length - 2] + '_' + splittedName[splittedName.length - 1];
        let correctEid = splittedName[splittedName.length - 2] === eid;
        let correctLE = splittedName[splittedName.length - 1] === legalEntity;
        let correctLogin = correctEid && correctLE;
        if (correctLogin)
          return name
      }
      if (log[i].loginEntity.fullName) {
        const splittedName = log[i].loginEntity.fullName.split('_');
        let name = splittedName[splittedName.length - 2] + '_' + splittedName[splittedName.length - 1];
        let correctEid = splittedName[splittedName.length - 2] === eid;
        let correctLE = splittedName[splittedName.length - 1] === legalEntity;
        let correctLogin = correctEid && correctLE;
        if (correctLogin)
          return name
      }
      if (isEMpSsnDiff(legalEntity) && log[i].loginEntity.name) {
        let name = log[i].loginEntity.name
        return name
      }
    }
    return false
  }

  const handleResetUsernamePassword = (buid, eid, employmentStatus, legalEntityId) => {
    if (employmentStatus === 'esActive') {
      setShowLoader(true);
      let basicAuthorizationValue = getBasicAuthHeaderValue();
      //1. check if user reset or not.
      http
        .get(appConfig.SSP_AUTH_URL + `/stateful/user-mgmt/get-userstatus`, {
          params: {
            employeeId: eid,
            legalEntityId: clientName.substring(0, clientName.indexOf(':')) + extension,//legalEntityId,
          },
          withCredentials: true,
          headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200),
          },
        })
        .then((res) => {
          if (!res.data.disabled && res.data.username) {
            //2. get user email.
            const resp = http
              .post(appConfig.SSP_MID_URL + '/getEmployeeData', {
                //.post(appConfig.SSP_MID_URL + '/getEmpData', {
                additionalInformation: {
                  //employeebuid: buid
                  personBuid: buid
                }
              })
              .then((resp) => {
                let logins = resp.data._Result.person.data.logins;
                let validatedEmail = validateAndGetEmail(logins)
                if (validatedEmail) {
                  //3. reset username/password.
                  let data = { email: validatedEmail }
                  http
                    .post(appConfig.SSP_AUTH_URL + `/stateful/user-mgmt/delete-user`, data, {
                      withCredentials: true,
                      headers: {
                        Authorization: `Basic ${basicAuthorizationValue}`,
                        'X-REQUEST-ID': Math.random(200),
                      },
                    })
                    .then((res) => {
                      setShowLoader(false);
                    })
                    .catch((error) => {
                      setShowLoader(false);
                    });
                }
                else
                  $('#userNameNotFound').modal('show')
                setShowLoader(false);
                $('#restUsernamePassword').modal('show')
              })
              .catch((error) => {
                setShowLoader(false);

              })
          }
          else
            if (res.data.disabled && res.data.username) {
              setShowLoader(false);
              $('#userNamePasswordReset').modal('show')//userResetDone
            }
        })
        .catch((error) => {
          setShowLoader(false);

          if (error.message === "Request failed with status code 503")
            $('#networkError').modal('show')
          else
            $('#empNotRegistered').modal('show')
        });
    }
    else
      $('#employeeNotActive').modal('show')
  }

  const handleRetrieveUsername = (buid, eid, employmentStatus) => {
    if (employmentStatus === 'esActive') {
      dispatch(UPDATE_LOADING(true));

      let basicAuthorizationValue = getBasicAuthHeaderValue();
      const resp = http
        .get(appConfig.SSP_AUTH_URL + `/stateful/user-mgmt/get-userstatus`, {
          params: {
            employeeId: eid,
            legalEntityId: clientName.substring(0, clientName.indexOf(':')) + extension
          },
          withCredentials: true,
          headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200),
          },
        })
        .then((res) => {
          // $('#userName').modal('show')
          if (!res.data.disabled && res.data.username) {
            //2. get employee email
            dispatch(UPDATE_LOADING(true));
            const resp = http
              //  .post(appConfig.SSP_MID_URL + '/getEmpData', {
              .post(appConfig.SSP_MID_URL + '/getEmployeeData', {
                additionalInformation: {
                  // employeebuid: buid
                  personBuid: buid
                }
              })
              .then((resp) => {
                if (resp) {
                  let logins = resp.data._Result.person.data.logins;
                  let validatedEmail = validateAndGetEmail(logins)
                  if (validatedEmail) {
                    dispatch(SET_EMPLOYEE_INFO({ email: validatedEmail, firstName: '', lastName: '' }));
                    dispatch(SELECTED_EMPLOYEE_INFO({ email: validatedEmail, firstName: '', lastName: '' }));
                    setShowLoader(false);
                    $('#userName').modal('show')
                  }
                  else {
                    //setShowLoader(false);
                    $('#userNameNotFound').modal('show')
                  }
                  dispatch(UPDATE_LOADING(false));
                }
              })
              .catch((error) => {

                //setShowLoader(false);
                dispatch(UPDATE_LOADING(false));
              });
            dispatch(UPDATE_LOADING(false));
          }
          else
            if (res.data.disabled) {
              dispatch(UPDATE_LOADING(false));
              $('#empDisabled').modal('show')
            }
        })
        .catch((error) => {

          dispatch(UPDATE_LOADING(false));
          if (error.message === "Request failed with status code 503")
            $('#networkError').modal('show')
          else
            $('#empNotRegistered').modal('show')
        })
    }
    else
      $('#employeeNotActive').modal('show')
  }

  const handleProxyEnrollment = (buid, eid, firstName, lastName, employmentStatus) => {
    if (buid && eid && firstName && lastName)
      if (employmentStatus === 'esActive') {
        setShowLoader(true);
        dispatch(START_PROXY_ENROLL(true));

        let basicAuthorizationValue = getBasicAuthHeaderValue();
        let legalEntity = clientName.substring(0, clientName.indexOf(':')) + extension

        //2. get employee email
        const resp = http
          // .post(appConfig.SSP_MID_URL + '/getEmpData', {
          .post(appConfig.SSP_MID_URL + '/getEmployeeData', {
            additionalInformation: {
              // employeebuid: buid
              personBuid: buid
            }
          })
          .then((resp) => {
            if (resp) {
              let logins = resp.data?._Result?.person?.data?.logins;
              let data = resp?.data?._Result?.person?.data
              logins && dispatch(SET_LOGIN_DATA({ logins: logins, eid: eid, legalEntity: legalEntity }))
              let validatedEmail = validateAndGetEmailNotRegistered(logins, eid, legalEntity)
              if (validatedEmail) {
                dispatch(SET_EMPLOYEE_INFO({ email: validatedEmail, firstName: firstName, lastName: lastName }));
                dispatch(SELECTED_EMPLOYEE_INFO({ email: validatedEmail, firstName: firstName, lastName: lastName }));
                dispatch(processTypeMFA({ processType: 'proxyEnrollment', userType: 'Enrollment', language: currentLanguage }))
                dispatch(SET_REDIRECT_TO_ENROLLMENT({ redirectToEnrollment: true, proxyUser: true }))
                setShowLoader(false);
                dispatch(CALL_CENTER_ENROLL_EMPLOYEE_LOGIN_DATA({ data: { person: data } }))
                if (isHrCenter) {
                  dispatch(IS_HR_CENTER_RE_DIRECTED(true))
                }
                //dispatch(apiReceivedMFAUpdate('mfa_'+validatedEmail))
                history.push('/enrollment/home')
              }
              else {
                setShowLoader(false);
                dispatch(SET_REDIRECT_TO_ENROLLMENT({ redirectToEnrollment: false, proxyUser: false }))
                $('#userNameNotFound').modal('show')
              }
            }
          })
          .catch((error) => {
            setShowLoader(false);

          });
      }
      else
        $('#employeeNotActive').modal('show')
    else
      $('#employeeInfoNotComplete').modal('show')

  }

  if (showLoader) {
    return <Loader loading={showLoader} />;
  }
  let hasBeneficiaries = beneficiariesProduct?.some(product => product?.hasBeneficiaries);
  let beneficiariesEligibleProducts = { beneficiariesProducts: beneficiariesProduct }
  return (
    <>
      {isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />}
      <div className='searchComponentContainer container-fluid px-md-5 py-3'>
        <div className="row mb-4">
          <div className="col-12 col-lg-3 col-md-6  px-5 px-lg-0 px-md-0">
            <div className="emp-container">
              <div className="row">
                <div className="col-12 py-3 text-center">
                  <img
                    src={iconReset}
                    alt="reset-password"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 py-3">
                  <Button className={classes.Button} onClick={() => handleResetUsernamePassword(employeeBuidFromRes, employeeIdFromRes, employmentStatus)}>
                    Reset User Name/Password
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-md-6  px-5 px-lg-0 px-md-0">

            <div class="emp-container">
              <div className="row">
                <div className="col-12 py-3 text-center">
                  <img
                    src={iconRetrieve}
                    alt="reset-username"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 py-3 text-center">
                  <Button className={classes.Button} onClick={() => handleRetrieveUsername(employeeBuidFromRes, employeeIdFromRes, employmentStatus)}>
                    Retrieve Username
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-md-6  px-5 px-lg-0 px-md-0">
            <div class="emp-container">
              <div className="row">
                <div className="col-12 py-3 text-center">
                  <img
                    src={iconAdmin}
                    alt="proxy-enrollment"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 py-3 text-center">
                  <Button className={classes.Button} onClick={() => handleProxyEnrollment(employeeBuidFromRes, employeeIdFromRes, empData?.shortName, empData?.name, employmentStatus)}>
                    Proxy Enrollment
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-md-6  px-5 px-lg-0 px-md-0">
            <div class="emp-container">
              <div className="row">
                <div className="col-12 py-3 text-center">
                  <img
                    src={iconAdminEndorse}
                    alt="admin-endorsement"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 py-3 text-center">
                  <Button className={classes.Button} onClick={() => adminEnrollmentModel()}>
                    Admin Endorsement
                  </Button>
                </div>
              </div >
            </div >
          </div >
        </div >

        <div id="adminModal" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                <div className={classes.modaltitle}>Admin Endorsement</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  // onClick={() => adminEnrollmentModelHide()}

                  onClick={() => {
                    $('#adminModal').modal('hide');
                    dispatch(IS_ADMIN(false))
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <p className={classes.descprition1}>Please enter the life event date. </p>
                <p className={classes.descprition2}> Life Event Date <span className={classes.astentik} >*</span></p>

                <div className="date-section call-center">

                  <Input type='date' value={dateValue} onChange={onDateChange} className={classes.dateSelection} />
                  {backDateMsg && <span style={{ color: '#A61003' }}>{`Invalid date. Please select a date equal to or after ${activeEffeactiveDate}.`} </span>}
                </div>
              </div>
              <div className={classes.modalfooter}>
                <Button className={classes.Button} onClick={() => cancelModal()}>Cancel</Button>
                <Button className={classes.modalButton} data-dismiss="modal" disabled={!dateValue} name='admin-Endorsement' onClick={() => handleSubmitAdmin()}>Continue</Button>
              </div>
            </div>
          </div>
        </div>

        <div id="restUsernamePassword" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                <div className={classes.modaltitle}>Reset Username/Password</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    $('#restUsernamePassword').modal('hide');
                    dispatch(IS_ADMIN(false))
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <p className={classes.descprition4}>Username and Password for this user is successfully reset. User will have to start over creating a new unique username. </p>

              </div>
              <div className={classes.modalfooter}>
                <Button className={classes.Button} data-dismiss="modal" name='restUsernamePasswordButton'>OK</Button>
              </div>
            </div>
          </div>
        </div>

        <div id="employeeNotActive" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                <div className={classes.modaltitle}>Alert</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    $('#employeeNotActive').modal('hide');
                    dispatch(IS_ADMIN(false))
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <p className={classes.descprition3} >Employee not Active. </p>

              </div>
              <div className={classes.modalfooter}>
                <Button className={classes.Button} data-dismiss="modal" name='employeeNotActiveBtn'>OK</Button>
              </div>
            </div>
          </div>
        </div>

        <div id="employeeInfoNotComplete" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                <div className={classes.modaltitle}>Alert</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    $('#employeeInfoNotComplete').modal('hide');
                    dispatch(IS_ADMIN(false))
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <p className="text-center" >Employee Information is Not Complete. </p>

              </div>
              <div className={classes.modalfooter}>
                <Button className={classes.Button} data-dismiss="modal" name='employeeInfoNotCompleteBtn'>OK</Button>
              </div>
            </div>
          </div>
        </div>

        <div id="empNotRegistered" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                <div className={classes.modaltitle}>Alert</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    $('#empNotRegistered').modal('hide');
                    dispatch(IS_ADMIN(false))
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <p className={classes.descprition3} >Employee not registered for MFA login. </p>

              </div>
              <div className={classes.modalfooter}>
                <Button className={classes.Button} data-dismiss="modal" name='userNameNotFoundBtn'>OK</Button>
              </div>
            </div>
          </div>
        </div>

        <div id="empDisabled" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                <div className={classes.modaltitle}>Alert</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    $('#empDisabled').modal('hide');
                    dispatch(IS_ADMIN(false))
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <p className={classes.descprition3} >Employee is Disabled. </p>

              </div>
              <div className={classes.modalfooter}>
                <Button className={classes.Button} data-dismiss="modal" name='empDisabledBtn'>OK</Button>
              </div>
            </div>
          </div>
        </div>

        <div id="userName" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                <div className={classes.modaltitle}>Retrieve User Name</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    $('#userName').modal('hide');
                    dispatch(IS_ADMIN(false))
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <p className={classes.descprition3} >User Name for this user is {employeeEmail} </p>

              </div>
              <div className={classes.modalfooter}>
                <Button className={classes.Button} data-dismiss="modal" name='usernameButton'>OK</Button>
              </div>
            </div>
          </div>
        </div>

        <div id="userNamePasswordReset" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                <div className={classes.modaltitle}>Status</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    $('#userNamePasswordReset').modal('hide');
                    dispatch(IS_ADMIN(false))
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <p className={classes.descprition3} >ALready reset username/password.</p>

              </div>
              <div className={classes.modalfooter}>
                <Button className={classes.Button} data-dismiss="modal" name='usernameButton'>OK</Button>
              </div>
            </div>
          </div>
        </div>

        <div id="userNameNotFound" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                <div className={classes.modaltitle}>Alert</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    $('#userNameNotFound').modal('hide');
                    dispatch(IS_ADMIN(false))
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <p className={classes.descprition3} >Employee Email is Invalid.</p>

              </div>
              <div className={classes.modalfooter}>
                <Button className={classes.Button} data-dismiss="modal" name='userNameNotFoundBtn'>OK</Button>
              </div>
            </div>
          </div>
        </div>

        <div id="networkError" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                <div className={classes.modaltitle}>Alert</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    $('#networkError').modal('hide');
                    dispatch(IS_ADMIN(false))
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <p className={classes.descprition3} >Network Error: Request failed with status code 503.</p>

              </div>
              <div className={classes.modalfooter}>
                <Button className={classes.Button} data-dismiss="modal" name='userNameNotFoundBtn'>OK</Button>
              </div>
            </div>
          </div>
        </div>

        <Tabs tabs={[
          {
            id: 'enrollmentSummary',
            label: 'Enrollment Summary',
            content: () => (
              <>
                <EmployeeTable
                  empData={empData}
                  empContactData={empContactData}
                  empContactDataInfo={empContactDataInfo}
                  empEmploymentData={empEmploymentData}
                  constantsList={constantsList}
                />


                {
                  empSubscribedProduct && productTitle && (
                    <Banner>
                      {productMapdetails?.map((elem, index) => {
                        let item = elem[0];
                        let checkPetProductWaived = elem?.every(item => item?.petProductWaviedStatus || item?.notEnrolled);
                        let allPetProductWaived = checkPetProductWaived ? true : false
                        let filteredCoverageData = (item?.formName === "PetAssureNation" || item?.everGreenFormName === 'PetAssureNation') ? elem?.filter(obj => obj?.petName !== '') : elem;

                        if (filteredCoverageData && filteredCoverageData.length) {
                          filteredCoverageData = filteredCoverageData?.map((dataItem) => {
                            if (dataItem.formName === "UniversalLife") {
                              if (dataItem.relationshipWithinsured && dataItem.relationshipWithinsured.length) {
                                dataItem.relationshipWithinsured.map((obj) => {
                                  if (dataItem.coverageBuid === obj.buid) {
                                    dataItem = {
                                      ...dataItem,
                                      coverageLevel: dataItem.coverageLevel + ` (${obj.relationship})`
                                    }
                                  }
                                })
                              }
                            }
                            if (dataItem.formName === "CriticalIllness") {
                              if (dataItem.relationshipWithinsured && dataItem.relationshipWithinsured.length) {
                                dataItem.relationshipWithinsured.map((obj) => {
                                  if (dataItem.coverageBuid === obj.buid) {
                                    dataItem = {
                                      ...dataItem,
                                      coverageLevel: dataItem?.coverageTypeInformation?.[0]?.coveredInfo === true ? dataItem.coverageLevel : obj.relationship + ' ' + ` (${dataItem.coverageLevel}) `
                                    }
                                  }
                                })
                              }
                            }
                            return dataItem
                          })
                        }
                        return (
                          <div className={classes.card}>
                            {<div className={'product-title'}>{productTitle[index]?.content}</div>}

                            {/* {(item?.waived || item?.declinedProduct) && (
                    <button className={`ml-3 mt-2 ${classes.ButtonWaived}`} disabled>
                      Waived
                    </button>
                  )} */}

                            <>
                              {(item?.productFamily === "Pet Insurance" || item?.everGreenProductFamily === "Pet Insurance") ? (
                                <>
                                  {allPetProductWaived && (
                                    <button className={`ml-3 mt-2 ${classes.ButtonWaived}`} disabled>
                                      Waived
                                    </button>
                                  )}
                                </>
                              ) : (
                                <>
                                  {((item?.waived || item?.declinedProduct || item?.waviedTreminated || item?.declinedProductTerminated || item?.notEnrolled)) && (
                                    <button className={`ml-3 mt-2 ${classes.ButtonWaived}`} disabled>
                                      Waived
                                    </button>
                                  )}
                                </>
                              )}
                            </>

                            <div className="custom-tableWrapper">
                              <CustomTable
                                coverageItem={filteredCoverageData}
                                waived={item?.waived}
                                checkEnroll={item?.checkEnroll}
                                index={index}
                                notEnrolled={item?.notEnrolled}
                                isViewRowDetails={true}
                                getCoveredMembers={item?.getCoveredMembers}
                                declinedProduct={item?.declinedProduct}
                                coverProductBuid={item?.coverProductBuid}
                                allPetProductWaived={allPetProductWaived}
                                isPlandesignNotPresent={item?.isPlandesignNotPresent}
                              // currentBenifitendorsementProcessBuid={currentBenifitendorsementProcessBuid}
                              />
                            </div>
                          </div>
                        );
                      })}

                      <div className={classes.card}>
                        <div className={classes.totalCostCardHeader}>
                          <div className={classes.totalCostLabel}>{completeCost.totalCost}</div>
                          <div className={classes.totalCost}>{formatDollarAmountWithDecimals(totalCostMonthly)}</div>
                        </div>
                        <div className={classes.totalCostBtnAlign}>
                          <Button
                            className={btnSelected === 'day' ? classes.ButtonSelected : classes.ButtonAnnual}
                            onClick={() => dayCostOnClick('day')}
                          >
                            Per Pay Period
                          </Button>
                          <Button
                            className={btnSelected === 'annual' ? classes.ButtonSelected : classes.ButtonAnnual}
                            onClick={() => dayCostOnClick('annual')}
                          >
                            Annual
                          </Button>
                        </div>
                      </div>
                    </Banner>
                  )
                }
                <br />
                {
                  empDepDataDisplayFinal?.length > 0 && (
                    <Banner>
                      <DependentsTable
                        insuredDependents={empDependentData}
                        constantsList={constantsList}
                      />
                    </Banner>
                  )
                }
                <br />
                <div>
                  {isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />}
                  {hasBeneficiaries && (
                    <div className={classes.card}>
                      <div className={classes.cardHeader}>
                        <div className={classes.title}>{'Beneficiary Information'}</div>
                      </div>
                      <ManageBeneficiariesTable
                        hideActions={true}
                        layoutData={beneficiariesEligibleProducts}
                      />
                    </div>
                  )}
                </div>
              </>
            )
          },
          {
            id: 'enrollmentHistory',
            label: 'Enrollment History',
            content: () => (
              <EnrollmentHistory contractBuid={contractBuid} inProgressBuid={inProgressBuid} />
            )
          }
        ]} />
      </div>
    </>
  );
};

export default EmployeeData;