import React ,{useState,useEffect}from 'react';
// import "./styles.css";
import { useSelector } from 'react-redux';
import { Styles } from './styles';
import SectionHeader from '../../../Molecules/SectionHeader';

const TermsOfUse = () => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, setContent] = useState({});
  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => setContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);
  let titleClassName = content?.staticPages?.titleClassName;
  let contentClassName = content?.staticPages?.contentClassName;
  let wrapperClassName = content?.staticPages?.wrapperClassName;
  let termsClassName = content?.staticPages?.termsClassName;

  let handleClose = () => {
    window.close();
  };
 
  return (
    <React.Fragment>
      <Styles />
      <div className='container-fluid '>
        <div className='row '>
          <div className='col-12 ' >
            <div className='row  '>
              <div className='col-11 px-0 '>
                <SectionHeader title="Terms of Use"  current="Terms of Use" />
              </div>
              <div className=' col-1 btnClass border-bottom ' >
                <button type="button" className="close pr-lg-2 " style={{  color: "#202020"}} aria-label="Close" onClick={()=>handleClose()}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div> 
            </div>
          </div>
          <div className='col-12  '>
            <div className='row '>
              <div className={`col-12 ${termsClassName} `}>
                <span>Mercer Health & Benefits Administration LLC Online Terms and Conditions</span>
              </div>
            </div>
          </div>
          <div className={`col-12 px-3 px-md-5  px-lg-5  ${wrapperClassName} `}>
            <div className='row ' >
              {content && content?.staticPages?.termsofUse?.map((element) => {
                return (
                  <>
                    {element?.title &&
                    <div key={element.id} className={`col-12 ${titleClassName} `}>
                      {element.title} 
                    </div>
                    }
                    <div key={element.id} className={`col-12 ${contentClassName} `}>
                      {element.content} 
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TermsOfUse;