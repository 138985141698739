import React, { useState, useEffect } from 'react';
import Icon from '../Icon/';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import './styles.css';

const InputColor = (props) => {
  let {
    id,
    name,
    label,
    update,
    value,
    defaultValue,
    onChange,
    onBlur,
    className,
    classNameWrapper,
    hidden,
    required,
    requiredValidation,
    element,
    type,
    readproperty,
    disabled,
    formik,
    onColorChange,
  } = props;

  const [focus, setFocused] = useState(false);
  // const [handleChangeCheck, setHandleChangeCheck] = useState(false);

  /** Set initial focus based on defaultValue */
  useEffect(() => {
    if (defaultValue) {
      onChange &&
        onChange({
          target: {
            name: name || id,
            value: defaultValue,
          },
        });
    }
  }, [defaultValue]);

  //Getting the theme values of the selected theme to be updated
  // const elementColor = useSelector((storeState) => storeState?.theme.selectedThemeDataToUpdate);

  useEffect(() => {
    if (element.currentDate && !value) {
      onChange &&
        onChange({
          target: {
            name: name || id,
            value: new Date().toISOString().slice(0, 10),
          },
        });
    }
  }, [element.currentDate]);

  useEffect(() => {
    if (value) {
      setFocused(value != null);
    }
  }, [value]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (event) => {
    // setHandleChangeCheck(true);
    onColorChange(true);
    event.preventDefault();
    props.parentInputBlurAction &&
      element.apiCallOnBlur &&
      props.parentInputBlurAction(event, formik.values, formik);
    if (!props.value) setFocused(false);
    onBlur && onBlur(event);
  };
  let classes = disabled ? `elementDisabled ${className}` : className;

  const handleChange = (event) => {
    // setHandleChangeCheck(true);
    onColorChange(true);
    event.preventDefault();
    onChange && onChange(event);
    props.parentInputAction && props.parentInputAction(event, formik.values);
  };

  return (
    <div className={'floatingLabel pb-3 ' + classNameWrapper} hidden={hidden}>
      <input
        id={id || name}
        name={name || id}
        onChange={handleChange}
        type={type}
        update={update}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
        required={required}
        disabled={disabled}
        readproperty={readproperty}
        // className={classes + className}
        className={
          (value && requiredValidation) || !requiredValidation
            ? 'w-100 ' + classes
            : 'w-100 required ' + classes
        }
        data-testid={id}
      />
      {label && (
        <label className={focus || props.type === 'date' ? 'isFocused' : ''} htmlFor={name || id}>
          {label}
        </label>
      )}

      {readproperty && <readproperty>{readproperty}</readproperty>}
      {props.type === 'date' && <Icon icon={['far', 'calendar-alt']} className="calendarIcon" />}
      
    </div>

  );
};
InputColor.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  update: PropTypes.string,
  readproperty: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
};
InputColor.defaultProps = {
  value: '',
  hidden: false,
  className: '',
  classNameWrapper: '',
  type: 'color',
  required: false,
  defaultValue: null,
};

export default InputColor;
