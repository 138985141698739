import React from 'react';

import UserRegistration from '../../../Layout/UserRegistration/AddUserRegistration';
import RegisterScreen from '../../../Molecules/CustomSection/MFA/Registration/index';

export default [
  // {
  //   path: '/userRegistration',
  //   render: (props) => <UserRegistration type="EmployeeRegistration" {...props} />,
  // },
  {
    path: '/userRegistration',
    render: (props) => <RegisterScreen {...props} />,
  },
];
