import React from 'react';
import Content from '../../Content/GenericContent.json';
import { getFileExtension } from '../../Helper/commonHelper';

const HeaderContent = ({ resources = [] }) => {
  const title = resources?.find((data) => data.code == 'PRODTITLE')?.content || '';
  const logo = resources?.find(data => data.code === 'PRODLOGO');

  let fileExtension = getFileExtension(logo?.content)
  if (fileExtension === 'svg') {
    fileExtension = 'svg+xml'
  }
  const productLogo = logo?.binaryContent && fileExtension ? (`data:image/${fileExtension};base64,${logo?.binaryContent}`) : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII="

  return (
    <div className="row justify-content-between">
      <div className="col d-flex align-items-center">
        <h2 className="productTitle">{title}</h2>
      </div>
      <div className="col d-flex justify-content-end">
        <img className="product-logo-fixed-size" src={productLogo} alt="product_logo" />
      </div>
    </div>
  );
};

export default HeaderContent;
