import ServiceHelper from '../../../Utils/Helper/MappingHelper';
import { SUBSCRIBE_COVERAGE_BUIDS, SUBSCRIBE_COVERAGE_BUYUP_BUIDS, SET_COVERAGE_BENEFICIARY_ELIGIBILITY, UPDATE_LOADING, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { getCoverageBeneficiaryEligibility } from '../Helper/MappingHelper';
import { UPDATE_COVERAGE_INFO } from '../../../../Store/Slices/Entities/response';
const serviceHelper = new ServiceHelper();

export const getSubscribedCoverageBeneficiaryConfigurationNew = (enrollmentProcessBuid, coverageBuid, buid, currentStep) => async dispatch => {
  // Add null checks
  if (!enrollmentProcessBuid || !coverageBuid) {
    console.error('Invalid parameters');
    return;
  }

  let resp = null;

  try {
    dispatch(UPDATE_LOADING(true));
    resp = await serviceHelper.sendApiRequest('/getSubscribedCoverageBeneficiaryConfigurationNew', {
      enrollmentProcessBuid: enrollmentProcessBuid,
      coverageBuid: coverageBuid
    });

    if (resp && resp.data && resp.status === 200) {
      if (!buid || !currentStep) {
        console.error('Invalid global variables or parameters');
        return;
      }
      dispatch(SUBSCRIBE_COVERAGE_BUIDS({
        coverageBuid: coverageBuid,
        personBuid: buid,
        product: currentStep
      }));
      dispatch(
        UPDATE_COVERAGE_INFO({
          coverageBuid: coverageBuid,
          personBuid: buid,
          product: currentStep,
          beneficiaryConfiguration: resp.data
        })
      );
      const coverageBenficiaryEligiblity = getCoverageBeneficiaryEligibility(resp?.data?._Result, coverageBuid, buid);
      dispatch(SET_COVERAGE_BENEFICIARY_ELIGIBILITY({ eligibility: coverageBenficiaryEligiblity, product: currentStep }));
    } else {
      dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: resp?.status || '520',
        id: 'getSubscribedCoverageBeneficiaryConfigurationNew',
        show: false,
      }));
    }
    return resp;
  } catch (error) {
    console.error('API request failed: ', error);
    return;
  } finally {
    dispatch(UPDATE_LOADING(false));
  }
  // Check if resp or resp.data is not null
  
};
