import React, { useStat, memo } from 'react';
import Profile from "../Component/Profile/Profile.js"

const ManageProfile = memo((props) => {

  return (
    <div>
       <Profile {...props}></Profile>
    </div>
  );
});
export default ManageProfile;
