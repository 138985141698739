import React from 'react';
import PropTypes from 'prop-types';

function Button(props) {

  const {
    id,
    name,
    onClick,
    children,
    disabled,
    className,
    type,
    style={}
  } = props;

  return (
    <button
      id={id}
      name={name}
      type={type}
      className={className || `btn btn-primary continue-btn primaryBackground`}
      onClick={onClick}
      disabled={disabled}
      role="button"
      style={style}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
  id: '',
  name: '',
  onClick: () => {},
  children: null,
  disabled: false,
  className: '',
  type: 'button',
};

export default Button;
