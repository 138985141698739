import { useEffect } from "react";
import _get from 'lodash/get';
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../Atoms/Input";
import Select from "../../../Atoms/Select";
import RadioButton from "../../../Atoms/RadioButton";
import PanelSideHeader from "../../../Atoms/PanelSideHeader";
import { HIDE_BACK_BUTTON, HIDE_CONTINUE_BUTTON } from "../../../../Store/Slices/StepForm";

import './index.css'

const ContactPreference = (props) => {
    const {
        element = {},
        formName,
        formik,
        formik: { handleChange, setFieldValue, touched, errors, handleBlur, submitCount, values },
    } = props;
    const dispatch = useDispatch();
    const formValues = (values && values[formName] && values[formName].contactPreferences) || {};
    const empProfile = useSelector(state => state?.employeeProfile?.employeeProfile || {});
    const preferredContactDetailsFromRedux = useSelector((state) => state?.employeeProfile?.preferredContactDetails || {});
    const employeeData = useSelector(
        (storeState) => storeState?.entities?.response?.onLoadResponseList?.PersonalInformation?.personalInformation
    );
    const crossSellObject = useSelector((state) => state.enrollment.crossSellObject);
    const crossSellResponseObject = useSelector((state) => state.enrollment.crossSellResponseObject);

    // const aknowledgeFirstLine = crossSellObject?.aknowledgePresentedName?.substring(0, crossSellObject.aknowledgePresentedName.indexOf('.') + 1)
    // const position = aknowledgeFirstLine?.length
    // const aknowledgeSecondLine = crossSellObject?.aknowledgePresentedName?.substring(position)

    const selectedProducts = (values && values[formName] && values[formName].selectedProducts) || []
    const fieldNamePrefix = `${formName}.contactPreferences`;

    // const contactPreferences = { ...values[formName]?.contactPreferences };
    // if (name === 'phonePreference') {
    //     contactPreferences.selectedPhone = value === 'workPhone' ? empProfile.officePhone : empProfile.personalPhone;
    // } else if (name === 'emailPreference') {
    //     contactPreferences.selectedEmail = value === 'workEmail' ? empProfile.workEmail : empProfile.personalEmail;
    // }

    function formatPhone(phone) {
        if (!phone) return '';

        let value = phone.replace(/\D/g, '').slice(0, 11);
        value = (value.length === 11 && value.startsWith('1')) ? value.slice(1, 11) : value;

        return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || '';
    }

    useEffect(() => {
        dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
        dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
    }, [])

    const emailPreferences = element.emailPreferences?.filter(item => (item.value === 'workEmail' && empProfile?.workEmail) || item.value !== 'workEmail')
    const phonePreferences = element.phonePreferences?.filter(item => (item.value === 'workPhone' && empProfile?.officePhone) || item.value !== 'workPhone')
    // const { phonePreference, emailPreference } = formValues;
    // useEffect(() => {
    //     if (selectedProducts?.length > 0 && empProfile) {
    //         if (phonePreference === "personalPhone") {
    //             if (empProfile?.personalPhone) {
    //                 setFieldValue(`${fieldNamePrefix}.phonePreference`, 'personalPhone');
    //                 setFieldValue(`${fieldNamePrefix}.selectedPhone`, empProfile?.personalPhone);
    //             } else /* if (preferredContactDetailsFromRedux?.personalPhone ) */ {
    //                 setFieldValue(`${fieldNamePrefix}.phonePreference`, 'personalPhone');
    //                 setFieldValue(`${fieldNamePrefix}.selectedPhone`, displaypersonalPhone());
    //             }
    //         } else {
    //             setFieldValue(`${fieldNamePrefix}.phonePreference`, 'workPhone');
    //             setFieldValue(`${fieldNamePrefix}.selectedPhone`, empProfile.officePhone);
    //         }

    //         if (emailPreference === "personalEmail") {
    //             setFieldValue(`${fieldNamePrefix}.emailPreference`, 'personalEmail');
    //             if (empProfile?.personalEmail) {
    //                 setFieldValue(`${fieldNamePrefix}.selectedEmail`, empProfile.personalEmail);
    //             } else /* if ( preferredContactDetailsFromRedux?.personalEmail )  */ {
    //                 setFieldValue(`${fieldNamePrefix}.selectedEmail`, displaypersonalEmail());
    //             }
    //         } else {
    //             setFieldValue(`${fieldNamePrefix}.emailPreference`, 'workEmail');
    //             setFieldValue(`${fieldNamePrefix}.selectedEmail`, empProfile.workEmail);
    //         }
    //     }
    // }, [selectedProducts?.length, empProfile, phonePreference, emailPreference]);

    useEffect(() => {
        if (selectedProducts?.length > 0 && empProfile) {
            if (empProfile.workEmail) {
                setFieldValue(`${fieldNamePrefix}.emailPreference`, 'workEmail');
                setFieldValue(`${fieldNamePrefix}.selectedEmail`, empProfile.workEmail);
            } else {
                setFieldValue(`${fieldNamePrefix}.emailPreference`, 'personalEmail');
                setFieldValue(`${fieldNamePrefix}.selectedEmail`, empProfile.personalEmail || employeeData?.personalEmail || preferredContactDetailsFromRedux.personalEmail);
            }

            if (empProfile.officePhone) {
                setFieldValue(`${fieldNamePrefix}.phonePreference`, 'workPhone');
                setFieldValue(`${fieldNamePrefix}.selectedPhone`, formatPhone(empProfile.officePhone));
            } else {
                setFieldValue(`${fieldNamePrefix}.phonePreference`, 'personalPhone');
                setFieldValue(`${fieldNamePrefix}.selectedPhone`, formatPhone(empProfile.personalPhone || employeeData?.personalPhone || preferredContactDetailsFromRedux.personalPhone));
            }
        }
    }, [selectedProducts?.length, empProfile]);

    const onPreferenceChange = (e) => {
        const { name, value } = e.target;
        const field = name.split('.').pop();
        switch (`${field}_${value}`) {
            case 'phonePreference_workPhone':
                setFieldValue(`${fieldNamePrefix}.selectedPhone`, formatPhone(empProfile.officePhone));
                break;
            case 'phonePreference_personalPhone':
                setFieldValue(`${fieldNamePrefix}.selectedPhone`, formatPhone(empProfile.personalPhone || employeeData?.personalPhone || preferredContactDetailsFromRedux.personalPhone));
                break;
            case 'emailPreference_workEmail':
                setFieldValue(`${fieldNamePrefix}.selectedEmail`, empProfile.workEmail);
                break;
            case 'emailPreference_personalEmail':
                setFieldValue(`${fieldNamePrefix}.selectedEmail`, empProfile.personalEmail || employeeData?.personalEmail || preferredContactDetailsFromRedux.personalEmail);
                break;
        }
        handleChange(e);
    }

    const isDisabled = (field) => {
        switch (field) {
            case 'workEmail': return !!empProfile.workEmail;
            case 'workPhone': return !!empProfile.officePhone;
            case 'personalEmail': return !!preferredContactDetailsFromRedux.personalEmail || !!empProfile.personalEmail || !!employeeData?.personalEmail;
            case 'personalPhone': return !!preferredContactDetailsFromRedux.personalPhone || !!empProfile.personalPhone || !!employeeData?.personalPhone;
            default: return false;
        }
    }

    const getError = (field) => {
        return (submitCount > 0 || _get(touched, `${fieldNamePrefix}.${field}`)) && _get(errors, `${fieldNamePrefix}.${field}`);
    }

    if (!selectedProducts?.length) return null;

    return (
        <div className="contact-preference">
            <div className="contact">Contact Preference and Consent Questionnaire</div>
            <PanelSideHeader formMandatory="Fields marked with * are mandatory" />
            <div className="form-group row">
                <div className={`col-12 ${getError('contactMedia') && 'errorClass'}`}>
                    <RadioButton
                        inline
                        required
                        formik={formik}
                        classNameWrapper="mb-0"
                        name={`${fieldNamePrefix}.contactMedia`}
                        onChange={handleChange}
                        value={formValues.contactMedia}
                        buttons={element.contactMedias}
                        label="How do you like to be contacted?"
                    />
                    <div className="error-message">{getError('contactMedia')}</div>
                </div>
            </div>

            {(formValues.contactMedia === 'phone' || formValues.contactMedia === 'both') && (
                <div className="form-group row">
                    <div className="col-md-4">
                        <Select name={`${fieldNamePrefix}.phonePreference`} formik={formik} selectedValue={formValues.phonePreference} requiredVal label="Select Phone Preference" options={phonePreferences} onChange={onPreferenceChange} />
                    </div>
                    <div className={`col-md-4 phone-input-wrapper ${getError('selectedPhone') && 'errorClass'}`}>
                        <Input name={`${fieldNamePrefix}.selectedPhone`} formik={formik} onBlur={handleBlur} disabled={isDisabled(formValues.phonePreference)} value={formValues.selectedPhone || ''} element={{}} label="" required onChange={handleChange} placeholder="Enter Phone" format="phoneNumber" />
                        <span className="error-message">{getError('selectedPhone')}</span>
                    </div>
                </div>
            )}
            {(formValues.contactMedia === 'email' || formValues.contactMedia === 'both') && (
                <div className="form-group row">
                    <div className="col-md-4">
                        <Select name={`${fieldNamePrefix}.emailPreference`} formik={formik} selectedValue={formValues.emailPreference} requiredVal label="Select Email Preference" options={emailPreferences} onChange={onPreferenceChange} />
                    </div>
                    <div className={`col-md-4 email-input-wrapper ${getError('selectedEmail') && 'errorClass'}`}>
                        <Input name={`${fieldNamePrefix}.selectedEmail`} formik={formik} onBlur={handleBlur} disabled={isDisabled(formValues.emailPreference)} value={formValues.selectedEmail || ''} element={{}} label="" required onChange={handleChange} placeholder="Enter Email" />
                        <span className="error-message">{getError('selectedEmail')}</span>
                    </div>
                </div>
            )}
            <div className="form-group row">
                <div className={`col-md-4 ${getError('expirationDate') && 'errorClass'}`}>
                    <Input
                        type="date"
                        currentDate
                        element={{ validations: { string: { required: true } } }}
                        formik={formik}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={`${fieldNamePrefix}.expirationDate`}
                        placement="px-4 col-4"
                        value={formValues.expirationDate || ''}
                        label={crossSellResponseObject.expirationPresentedDate + ' ?'}
                    //"What is the expiration date for your auto or home insurance?"
                    />
                    <span className="error-message">{getError('expirationDate')}</span>
                </div>
            </div>
            <PanelSideHeader mandatory formSideHeaderContact="By entering your information above on this website, including your email address and telephone number(s) (including any cellular telephone number), and clicking the “Yes” button below, you agree that Mercer, or its agents, may send emails or call you using the email address(es) and/or telephone number(s) you entered using an automatic telephone dialing system or by leaving a prerecorded voice message at such telephone number(s), regarding benefits, products or services offered on this website through Mercer and your employer. You are not required to provide your consent to receive such emails or calls as a condition of purchasing any products or services on this website. If you do not wish to provide such consent, you may click the “No” button and then the “Continue” button below to continue enrollment through this website or you may call Mercer and a representative will be available to assist you. If you wish to withdraw your consent in the future, please contact Mercer." />
            <PanelSideHeader formSideHeaderContact={crossSellResponseObject.aknowledgePresentedNameFirst}
            //"I acknowledge that clicking “Yes” shall constitute my electronic signature." 
            />
            <PanelSideHeader formSideHeaderContact={crossSellResponseObject.aknowledgePresentedNameSecond}
            //"By selecting “No”, I do not wish to provide such consent, but I want to continue enrollment." 
            />
            <div className="form-group row">
                <div class={`col-md-4 ${getError('acknowledgement') && 'errorClass'}`}>
                    <RadioButton
                        inline
                        required
                        formik={formik}
                        onChange={handleChange}
                        classNameWrapper="mb-0"
                        name={`${fieldNamePrefix}.acknowledgement`}
                        value={formValues.acknowledgement}
                        buttons={[
                            {
                                "label": "Yes",
                                "value": "Yes"
                            },
                            {
                                "label": "No",
                                "value": "No"
                            }
                        ]}
                    />
                    <span className="error-message">{getError('acknowledgement')}</span>
                </div>
            </div>
        </div>
    )
}

export default ContactPreference;