
import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.navContainer {
}
.nav-item {
  display: flex;
  align-items: center;
  padding: unset;
}

.nav-link-o {
  color:  ${({ theme }) =>
    theme.components &&
    theme.components.navigationBar &&
    theme.components.navigationBar.navigationMainLinksFontColor};
  padding: 1rem 1rem 0.7rem 1rem;
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.iconButton {
  right: 0;
  margin-right: 2rem;
  margin-top: 0.2rem;
  display: flex;
  justify-content: flex-end;
}

.sidebarLinkConatiner {
  background-color: #0c1c48;
  margin-left: -2.5rem !important;
  padding-left: 2.5rem !important;
}

.sideDrawerContainer.open .nav-item {
  border-bottom: solid 0.063rem rgba(0, 0, 0, 0.4);
  margin-left: -2.5rem !important;
  padding-left: 2.5rem !important;
}

.sideDrawerContainer.open .nav-link-o {
  font-size: 1.2rem;
  font-weight: bold;
}
.plusIcon {
  position: absolute;
  right: 0;
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
}

.minusIcon {
  position: absolute;
  right: 0;
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  margin-bottom: 2rem;
}

.navbar .navContainer div:hover {
  background-color: #ffffff;
  color: #000000;
  font-weight: 500;
}

.textIcon {
  left: 1rem;
  width: 20px;
  position: absolute;
  margin-top: 1.5rem;
}
`;
