
import * as Yup from 'yup';
let errorMsg = require('./errorMessage.json')
//Login Validation schema
export const loginSchema = Yup.object().shape({
  username: Yup.string()
    .email('Invalid email address')
    .required('Enter email address'),
  password: Yup.string()
    .required('Enter your password')
});

export const resetUserIdSchema = (config) => Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-ZÀ-ž' -]+$/, 'No special characters allowed')
    .required('Please enter your First Name'),

  lastName: Yup.string()
    .matches(/^[a-zA-ZÀ-ž' -]+$/, 'No special characters allowed')
    .required('Please enter your Last Name'),

  dateofBirth: !config?.birthDateToggle ? Yup.date()
    .required("Please enter your DOB")
    .min(new Date('01/01/1900'), 'Invalid DOB')
    .max(new Date(), 'Invalid DOB') : undefined,

  employeeId: String(config?.employeeIDeditableLabelPlacerholderValue).search('8 digits') !== -1 ?
    Yup.string()
    .matches(/^[0-9]+$/, 'Allowed only numbers')
    .required(`Please enter ${config?.employeeIDeditableLabelPlacerholderValue}`)
    .max(8) 
  : Yup.string().when('ssn', {
    is: (ssn) => !ssn,
    then: Yup.string()
      .required("Please enter your Employee ID")
      .matches(/^[0-9a-zA-ZÀ-ž]+$/, 'No special characters allowed'),
    otherwise: Yup.string().nullable()
  }),

  ssn: Yup.string().when('employeeId', {
    is: (employeeId) => !employeeId,
    then: Yup.string()
      .required('Please enter your SSN')
      .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must be in the format XXX-XX-XXXX'),
    otherwise: Yup.string().nullable()
  }),
}, ['employeeId', 'ssn']);

export const registerPhoneSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("Please enter your Mobile Number")
    .matches(/^[0-9]+$/, 'No special characters allowed'),
  verificationMethod: Yup.string().required('Please enter OTP')
})

export const preferredChannelSchema = Yup.object().shape({
  phoneVerified: Yup.boolean().required(),
  preferredChannel: Yup.string().required("Channel is required")
})

export const phoneValidationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .required("Please enter your Mobile Number")
    .matches(/^[0-9]+$/, 'No special characters allowed'),
});

export const securityCodeSchema = Yup.object().shape({
  securityCode: Yup.string().required('Security Code is required'),
});

export const contactTypeSchema = Yup.object({
  contactType: Yup.string().required("Please choose contact type to continue")
});

export const errorMessages = (err = '', type = '') => {
  const resErrDetails = err?.details;
  const resErr = err?.response?.data?.message;
  const reqErr = err.message;
  let errMsg = '';
  if (resErr === 'Authentication failed' || reqErr === 'Authentication failed') {
    if (resErrDetails === 'LOCKED_OUT') {
      errMsg = errorMsg.lockedUser;
    } else {
      errMsg = errorMsg.incorrectCredentialMsg;
    }
  } else if (resErr === 'Invalid token provided' || resErr === 'Invalid Passcode/Answer' || reqErr === 'Invalid Passcode/Answer') {
    errMsg = errorMsg.InvalidCode; // Mfa otp 
  } else if (resErr === 'Undefined value login' || resErr === 'Undefined value token') {
    errMsg = errorMsg.InvalidLoginDetail; // Mfa otp
  } else if (resErr === 'You do not have permission to access the feature you are requesting') {
    errMsg = resErr; // change method mfa 
  } else if (resErr === 'Request failed with status code 404') {
    errMsg = errorMsg.EmployeeNotFound; // register 
  } else if (resErr === errorMsg.EmployeeNotFound || reqErr === errorMsg.EmployeeNotFound) {  // register
    errMsg = errorMsg.EmployeeNotFound;
  } else if (resErr === errorMsg.userExceeded || reqErr === errorMsg.userExceeded) {  // register
    errMsg = errorMsg.userExceeded;
  } else if (resErr === errorMsg.userNotAllowed || reqErr === errorMsg.userNotAllowed) {  // register
    errMsg = errorMsg.EmployeeNotFound;
  } else if (resErr === errorMsg.userAlreadyAccount || reqErr === errorMsg.userAlreadyAccount) {  // register
    errMsg = errorMsg.userAlreadyAccount;
  } else if (resErr === errorMsg.existAccount || reqErr === errorMsg.existAccount) {  // register
    errMsg = errorMsg.userAlreadyAccount;
  } else if (resErr === errorMsg.invalidLegalId || reqErr === errorMsg.invalidLegalId) {  // register
    errMsg = errorMsg.EmployeeNotFound;
  } else if (resErr === 'Undefined value email' || reqErr === 'Undefined value email') {  // register
    errMsg = errorMsg.validEmailAddress;
  } else if (reqErr === 'Request failed with status code 401') { //common request error
    errMsg = errorMsg.unauthorizedError;
  } else if (reqErr === 'Request failed with status code 503') {
    errMsg = errorMsg.serverUnavailable;
  } else if (reqErr === 'Request failed with status code 500') {
    errMsg = errorMsg.internalServerErr;
  } else if (reqErr === 'Undefined value legalEntityId') {
    errMsg = errorMsg.incorrectURL;
  }else if (resErr && resErr.includes('Resource not found:')) {
    errMsg = errorMsg.invalidUserId;
  }
  else if (reqErr === 'Request failed with status code 504') {
    errMsg = errorMsg.timeOutExceeded;
  } else if (reqErr === 'Api validation failed: password') {
    errMsg = errorMsg.passwordValidation;
  }
  else if (resErr) {
    if (type === 'login') {
      errMsg = errorMsg.incorrectCredentialMsg;
    } else {
      errMsg = resErr;
    }
  } else if (reqErr) {
    errMsg = reqErr;
  }
  else {
    errMsg = errorMsg.unexpectedError;
  }
  return errMsg
}

