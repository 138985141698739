import React from 'react';
import { Button } from '../../../Atoms';
import Modal from '../../../Molecules/Modal/index';
import { isEmpty } from 'lodash';

function MultiSectionForm(props) {
  const { contentData, handleSubmit, currentStep, content,formik,currentForm } = props;
  const { formData, steps, stepKeys } = contentData;

  const hasErrors = !isEmpty(formik.errors[currentForm]);
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          {steps &&
            Object.keys(steps).length > 0 &&
            Object.keys(steps).map((item, index) => <div key={index}>{steps[item][0]}</div>)}
        </div>
        {('hideAddNewBotton' in content.layout ? !content.layout.hideAddNewBotton : true) ? (
          <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 py-2">
            <Button
              className="w-100"
              rounded
              type={'submit'}
              disabled={hasErrors}
              onClick={(e) => {
                // formData = { ...formData, "": "" }
                e.preventDefault();
                handleSubmit(stepKeys[currentStep], formData);
              }}
            >
              {'Submit'}
            </Button>
          </div>
        ) : null}
      </div>
      {props.showErrorModel &&
      props.errorModelElements &&
      props.errorModelElements.length > 0 &&
      props.errorModelElements[0] &&
      props.errorModelElements[0].length > 0 ? (
        <Modal
          {...props}
          modelSubmitSuccess={false}
          formik={formik}
          formName={currentForm}
          modelData={{
            elements: props.errorModelElements,
            modalId: `errorModal`,
            modalBody: `mt-4`,
            modalTitle:
              (props.content && props.content.layout && props.content.layout.errorModelName) ||
              `Error Messages`,
          }}
        >
          {' '}
        </Modal>
      ) : null}
    </React.Fragment>
  );
}

export default MultiSectionForm;
