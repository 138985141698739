export const dataValidation = (availableThemes) => {
  if (availableThemes == null){
    return
  }

  const keys = Object.keys(availableThemes);
  var availableThemeObj = [];
  for (let i = 0; i < keys.length; i++) {
    var themeValue = availableThemes[keys[i]];
    var themeName = themeValue['name'];
    let spaceIndex = themeName?.indexOf(' ');
    var themeNameOnly = themeName?.substring(0, spaceIndex);
    availableThemeObj.push({
      text: themeNameOnly,
      value: themeName,
    });
  }
  
  return availableThemeObj;
};
