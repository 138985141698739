import { React, useEffect, useState } from 'react';
import { Button, Collapse } from '../../Atoms';
import TableWrapper from '../TableWrapper';
import moment from 'moment';
import constants from '../CustomSection/Content/Constants.json'
import classes from './styles.module.css';
const DependentsTable = (props) => {
  const {
    dependentInfoContent = {},
    formName = '',
    tableRowMapper,
    backgroundColor = '',
    layoutData = {},
    insuredDependents,

  } = props;
  const formatSSN = (value) => {
    if (!value) return ''; // return empty string if value is null or undefined
    const cleanSSN = value.replace(/[^\d]/g, '');
    return cleanSSN
      .slice(0, 9)
      .replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
        return [p1, p2, p3].filter((part) => part.length > 0).join('-');
      });
  };
  const dependentsTable = [];
  if (insuredDependents?.length > 0) {
    insuredDependents?.forEach((dependent) => {
      if (!dependent?.insured?.terminationDate) {
        if (dependent?.insured?.mainOwnerRelationshipToInsured) {
          let dependentData = {
            mainBuid: dependent?.bUID,
            id: dependent?.person?.identifier?.bUID || '',
            SSN: formatSSN(dependent?.person?.data?.actor?.sSN) || '',
            dependentAddressLine1:
              dependent?.person?.data?.correspondences[0]?.address?.line1 || '',
            dependentAddressLine2:
              dependent?.person?.data?.correspondences[0]?.address?.line2 || '',
            dependentBirthDate: dependent?.person?.data?.actor?.birthDate || '',
            dependentCity:
              dependent?.person?.data?.correspondences[0]?.address?.zipCodeAndCity?.city || '',
            dependentCountry: dependent?.person?.data?.correspondences[0]?.address?.country || '',
            dependentFirstName: dependent?.person?.data?.actor?.shortName || '',
            dependentGender: dependent?.person?.data?.actor?.gender || '',
            dependentLastName: dependent?.person?.data?.actor?.name || '',
            dependentMiddleName: dependent?.person?.data?.actor?.middleName1 || '',
            dependentRelationship: dependent?.insured?.mainOwnerRelationshipToInsured || '',
            dependentState: dependent?.person?.data?.correspondences[0]?.address?.state || '',
            dependentZipCode:
              dependent?.person?.data?.correspondences[0]?.address?.zipCodeAndCity?.zipCode || '',
            data: { ...dependent },
          };
          dependentsTable?.push(dependentData);
        }
      }
    });
  }

  const getRelationshipLabel = (relationshipValue, dependent) => {
    const option = constants?.relationship?.find(
      (option) => option.value === relationshipValue || option.label === relationshipValue
    );

    return option ? option.label : '';

  };

  const gender = {
    gMale: 'Male',
    gFemale: 'Female',
    gUndisclosed: 'Undisclosed',
  };
  return (
    <>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <div className={classes.title}>{'Dependent Information'}</div>
        </div>
        <div className={classes.dependenttable} >
          <div className=" table-responsive col-md-12 d-md-block d-lg-block d-xl-block" >
            <table className="table ">
              <thead>
                <tr>
                  <th>NAME</th>
                  <th>RELATIONSHIP</th>
                  <th>GENDER</th>
                  <th>DATE OF BIRTH</th>
                  <th>ADDRESS</th>
                  <th>SSN</th>
                </tr>
              </thead>
              <tbody className="">
                {dependentsTable?.map((dependent, index) => {
                  const isTrust = dependent.dependentOptions === 'trust';
                  const dependentName = !isTrust
                    ? `${dependent.dependentFirstName} ${` `} ${dependent.dependentLastName}`
                    : dependent.legalName;
                  let relationShip = !isTrust
                    ? getRelationshipLabel(dependent?.dependentRelationship, dependent)
                    : '';

                  let address = ((dependent?.dependentAddressLine1 || ' ') + ' ' + (dependent?.dependentState || ' '))
                  return (
                    <tr key={index}>
                      <td>{dependentName}</td>
                      <td>{relationShip}</td>
                      <td>{gender[dependent?.dependentGender]}</td>
                      <td>{moment(dependent?.dependentBirthDate).format('MM/DD/YYYY')}</td>
                      <td>{address}</td>
                      <td>{dependent?.SSN}</td>
                    </tr>
                  )
                })
                }
              </tbody >
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DependentsTable;
