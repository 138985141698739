import _map from 'lodash/map';
import _omit from 'lodash/omit';

import appConfig from '../../../../appConfig';
import http from '../../../../Common/Services/HttpService';
import { removeUnwantedProps } from '../Helper/MappingHelper';
import { SET_CURRENT_STEP_NO } from "../../../../Store/Slices/StepForm";
import { getCoverageBeneficiaryEligibility } from '../Helper/MappingHelper';
import { UPDATE_EXTERNAL_STEP_INFO, UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment';
import { onLoadAPI, onLoadProductAPI, UPDATE_COVERAGE_INFO } from "../../../../Store/Slices/Entities/response";
import {
    OFFERED_COVERAGE_BUIDS,
    CHECK_ELIGIBILITY,
    SUBSCRIBE_COVERAGE_BUIDS,
    SET_COVERAGE_BENEFICIARY_ELIGIBILITY,
    UPDATE_LOADING,
    SET_ALERT_MESSAGE,
    REMOVE_CURRENTSTEP_ALERT_MESSAGE
} from '../../../../Store/Slices/InsuredInfo';

import resumeContent from '../Content/ResumeContent.json'

export const insureProductDetails = (params, returnResp = false) => async (dispatch, getState) => {
    dispatch(UPDATE_LOADING(true));
    const storeFromRedux = getState();
    let updatedState = removeUnwantedProps(storeFromRedux, resumeContent.resumeUnwantedProps);
    if (params && params.currentStepInfo) {
        params.currentStepInfo.reduxState = updatedState;
        params.currentStepInfo.enrollmentProcessBuid = storeFromRedux?.process?.enrollmentProcess?.[0]?.[0]?._Result?.processEntity?.buid ||
            storeFromRedux?.entities?.response?.onLoadResponseList?.GetPendingProcess?._Result?.[0]?.bUID
    }
    try {
        let respObj = await http.post(appConfig.SSP_MID_URL + `/v1/insure-load`, {
            enrollmentProcessBuid: params?.enrollmentProcessBuid,
            flowType: params?.flowType,
            personBuid: params?.personBuid,
            productBuid: params?.productBuid,
            personbuids: params?.personbuids,
            isRework: params?.isRework,
            subscribedProductBuid: params?.subscribedProductBuid,
            isPrepare: params?.isPrepare,
            currentStepInfo: params?.currentStepInfo
        });
        if (respObj.data) {
            let currentStep = params?.currentStep
            let previousStep = params?.previousStep
            let insuredInfo = params?.insuredInfo
            let skipForNext = `${currentStep}InsureProductOnLoadtesttt`
            //dispatch(SET_SKIP_NEXT_API_CALL(skipForNext));
            const data = respObj?.data?.data;
            dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
            for (const key in data) {
                let result = { _Result: data[key].data }
                switch (key) {
                    case 'getAllPossibleChoicesConfigurationForProduct':
                        if (data[key]?.success) {
                            let totalList = result?._Result
                            let offeredCoverageBuids = []
                            let enrollmentSummery = []
                            for (let i = 0; i < result?._Result.length; i++) {
                                let personBuid = result?._Result[i]?.possibleInsuredPersons?.map((el) => el?.insuredPerson?.bUID)
                                totalList[i].offeredCoverageBuids = { offeredCoverageBuid: personBuid }
                                offeredCoverageBuids.push({ coverage: result?._Result[i]?.coverage?.bUID, coveragePersonBuid: personBuid })
                                if (previousStep === "EnrollmentSummary" && i === 0) {
                                    enrollmentSummery.push({ coverage: result?._Result[i]?.coverage?.bUID, coveragePersonBuid: personBuid })
                                }
                            }

                            // Format the new data
                            const currentStepData = {
                                [currentStep]: {
                                    getAllPossibleChoicesConfigurationForProduct: { _Result: totalList }
                                }
                            };
                            // Dispatch an action to update the state with the new data
                            dispatch(onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false }));
                            dispatch(OFFERED_COVERAGE_BUIDS({ offeredCoverageBuids: offeredCoverageBuids, product: currentStep }));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'getAllPossibleChoicesConfigurationForProduct',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'searchPolicyProcessResources':
                        if (data[key]?.success) {
                            dispatch(onLoadAPI({ [`SearchPolicyProcessResources${currentStep}`]: result }));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'searchPolicyProcessResources',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'updateProcess':
                        if (result._Result && result._Result.processEntity && result._Result.processEntity.externalStepContext) {
                            let externalStepContext = JSON.parse(result._Result.processEntity.externalStepContext);
                            dispatch(UPDATE_EXTERNAL_STEP_INFO(externalStepContext));
                        }
                        dispatch(onLoadAPI({ key: result, isAPICallInProgress: false, }));
                        break;
                    case 'checkCoveredPersonsEligibilityForCoverage':
                        if (data[key]?.success) {
                            if (insuredInfo[currentStep]?.coverageInsuredEligiblity && insuredInfo[currentStep]?.coverageInsuredEligiblity?.length > 0) {
                                let currentElibilityData = [...insuredInfo[currentStep]?.coverageInsuredEligiblity];
                                data[key].data?.forEach((pobj) => {
                                    currentElibilityData.forEach((pinnrObj, pind) => {
                                        if (pinnrObj.personBuid === pobj.personBuid || pinnrObj.personBuid === undefined) {
                                            currentElibilityData.splice(pind, 1);
                                        }
                                    });
                                });
                                currentElibilityData = [...currentElibilityData, ...data[key].data];
                                dispatch(CHECK_ELIGIBILITY({ response: currentElibilityData, product: currentStep }));
                            } else {
                                dispatch(CHECK_ELIGIBILITY({ response: data[key].data, product: currentStep }));
                            }
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'checkCoveredPersonsEligibilityForCoverage',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'reworkProductEnrollment':
                        if (data[key]?.success) {
                            dispatch(onLoadAPI({
                                ReworkProductEnrollment: result,
                                isAPICallInProgress: false,
                            }))
                            dispatch(onLoadAPI({
                                'ValidateContractData': result,
                            }));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'reworkProductEnrollment',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    default:
                        dispatch(onLoadAPI({ [key]: result }));
                }

            }
            return {
                status: true,
                respObj: respObj
            }
        } else {
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: '520',
                continue: true,
                id: 'insureLoad',
                show: true,
                page: params?.currentStep,
            }));
            return {
                status: false,
                respObj: respObj
            }
        }
    } catch (e) {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'insureLoad',
            show: true,
            page: params?.currentStep,
        }));
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}

export const reworkProductDetails = (params, returnResp = false) => async (dispatch) => {
    dispatch(UPDATE_LOADING(true));
    try {
        let respObj = await http.post(appConfig.SSP_MID_URL + `/v1/rework-load`, {
            enrollmentProcessBuid: params?.enrollmentProcessBuid,
            flowType: params?.flowType,
            isRework: params?.isRework,
            subscribedProductBuid: params?.subscribedProductBuid,
            isPrepare: params?.isPrepare,
            prepareCoverageBuid: params?.prepareCoverageBuid,
            prepareCoveredPersonBuid: params?.prepareCoveredPersonBuid,
        });
        if (respObj.data) {
            const data = respObj?.data?.data
            for (const key in data) {
                let result = { _Result: data[key].data }
                switch (key) {
                    case 'reworkProductEnrollment':
                        if (data[key]?.success) {
                            dispatch(onLoadAPI({
                                ReworkProductEnrollment: result,
                                isAPICallInProgress: false,
                            }))
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'reworkProductEnrollment',
                                show: true,
                                page: params?.currentStep,
                            }));
                        }
                        break;
                    default:
                        dispatch(onLoadAPI({ [key]: result }));
                }

            }
            return {
                status: true,
                respObj: respObj
            }
        } else {
            return {
                status: false,
                respObj: respObj
            }
        }
    } catch (e) {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'reworkLoad',
            show: true,
            page: params?.currentStep,
        }));
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}

export const coverageProductDetails = (params, returnResp = false) => async (dispatch) => {
    dispatch(UPDATE_LOADING(true));
    let currentStep = params?.currentStep
    try {
        dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
        let respObj = await http.post(appConfig.SSP_MID_URL + `/v1/coverage-load`, {
            enrollmentProcessBuid: params?.enrollmentProcessBuid,
            flowType: params?.flowType,
            subscribedProductBuid: params?.subscribedProductBuid,
            personBuid: params?.personBuid,
            prepareCoverageBuid: params?.prepareCoverageBuid,
            prepareCoveredPersonBuid: params?.prepareCoveredPersonBuid,
            currentStepInfo: {
                "portalStep": currentStep
            }
        });
        if (respObj.data) {
            let personBuid = params?.personBuid
            const data = respObj?.data?.data
            let coverageBuid = data['prepareSubscriptionForChoice']?.data?.[0]?.bUID;
            for (const key in data) {
                let result = { _Result: data[key].data }
                switch (key) {
                    case 'validateSubscribedProductData':
                        if (data[key]?.success) {
                            const currentStepData = {
                                [currentStep]: {
                                    ValidateSubscribedProductData: result
                                }
                            };

                            const processInfo = result?._Result ?? {};
                            dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
                            // Dispatch an action to update the state with the new data
                            dispatch(onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false }));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'validateSubscribedProductData',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'prepareSubscriptionForChoice':
                        if (data[key]?.success) {
                            const currentStepData = {
                                [currentStep]: {
                                    prepareSubscriptionForChoice: result
                                }
                            };

                            // Dispatch an action to update the state with the new data
                            dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                                coverageBuid: result._Result?.[0]?.bUID,
                                personBuid: personBuid,
                                product: currentStep,
                            }));
                            dispatch(
                                UPDATE_COVERAGE_INFO({
                                    coverageBuid: result._Result?.[0]?.bUID,
                                    personBuid: personBuid,
                                    product: currentStep,
                                    perpareSubscriptionForChoices: result
                                })
                            );
                            dispatch(onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false }));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'prepareSubscriptionForChoice',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'getSubscribedCoverageBeneficiaryConfiguration':
                        if (data[key]?.success) {
                            dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                                coverageBuid: coverageBuid,
                                personBuid: personBuid,
                                product: currentStep
                            }));
                            dispatch(
                                UPDATE_COVERAGE_INFO({
                                    coverageBuid: coverageBuid,
                                    personBuid: personBuid,
                                    product: currentStep,
                                    beneficiaryConfiguration: result
                                })
                            );


                            const coverageBenficiaryEligiblity = getCoverageBeneficiaryEligibility(result?._Result, coverageBuid, personBuid);
                            dispatch(SET_COVERAGE_BENEFICIARY_ELIGIBILITY({ eligibility: coverageBenficiaryEligiblity, product: currentStep }));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'getSubscribedCoverageBeneficiaryConfiguration',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'getSubscribedCoverageConfiguration':
                        if (data[key]?.success) {
                            dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                                coverageBuid: coverageBuid,
                                personBuid: personBuid,
                                product: currentStep,
                            }));
                            dispatch(
                                UPDATE_COVERAGE_INFO({
                                    coverageBuid: coverageBuid,
                                    personBuid: personBuid,
                                    product: currentStep,
                                    subscribedCoverageConfiguration: result
                                })
                            );
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'getSubscribedCoverageConfiguration',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'getListOfCoveragePlanDesignConfiguration':
                        if (data[key]?.success) {
                            dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                                coverageBuid: coverageBuid,
                                personBuid: personBuid,
                                product: currentStep,
                            }));
                            dispatch(
                                UPDATE_COVERAGE_INFO({
                                    coverageBuid: coverageBuid,
                                    personBuid: personBuid,
                                    product: currentStep,
                                    listOfCoveragePlanDesignConfiguration: result
                                })
                            );
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'getListOfCoveragePlanDesignConfiguration',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    default:
                        dispatch(onLoadAPI({ [key]: result }));
                }

            }
            return {
                status: true,
                respObj: respObj
            }
        } else {
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: '520',
                continue: true,
                id: 'coverageLoad',
                show: true,
                page: currentStep,
            }));
            return {
                status: false,
                respObj: respObj
            }
        }
    } catch (e) {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'coverageLoad',
            show: true,
            page: currentStep,
        }));
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}

export const unsubscribeCoverageDetails = (params, dataStorageKey = "UnsubscribeCoverage", setStep = false) => async (dispatch) => {
    dispatch(UPDATE_LOADING(true));
    let currentStep = params?.currentStep;
    dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
    try {
        let respObj = await http.post(appConfig.SSP_MID_URL + `/v1/unsubscribeCoverage`, {
            enrollmentProcessBuid: params?.enrollmentProcessBuid,
            coverageBuid: params?.coverageBuid,
            coveredPerson: params?.coveredPerson,
            subscribedCoverageBuid: params?.subscribedCoverageBuid,
            isPrepare: params?.isPrepare,
            isMoveNext: params?.isMoveNext,
            subscribedProductBuid: params?.subscribedProductBuid,
            currentStepInfo: {
                "portalStep": currentStep
            }
        });
        if (respObj.data && respObj.status === 200) {
            const data = respObj?.data?.data
            for (const key in data) {
                let result = { _Result: data[key].data }
                switch (key) {
                    case 'prepareSubscriptionForChoice':
                        break;
                    case 'unsubscribeCoverage':
                        if (data[key]?.success) {
                            dispatch(onLoadAPI({
                                [dataStorageKey]: result,
                                isAPICallInProgress: false,
                            }))

                            if (setStep) {
                                dispatch(SET_CURRENT_STEP_NO(result?._Result?.processEntity?.currentStepNb));
                            }

                            const processInfo = result?._Result ?? {};
                            dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'unsubscribeCoverage',
                                show: true,
                                page: currentStep,
                            }));

                        }
                        break;
                    case 'moveNext':
                        if (data[key]?.success) {
                            dispatch(onLoadAPI({ moveNext: result }));
                            dispatch(SET_CURRENT_STEP_NO(""));
                            const processInfo = result ?? {};
                            dispatch(UPDATE_ENROLLMENT_DATA(processInfo?._Result));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'moveNext',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    default:
                        dispatch(onLoadAPI({ [key]: result }));
                }

            }
            return {
                status: true,
                respObj: respObj
            }
        } else {
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: respObj?.status || '520',
                id: 'unsubscribeCoverageApi',
                show: true,
            }));
            return {
                status: false,
                respObj: respObj
            }
        }
    } catch (e) {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'unsubscribeCoverageApi',
            show: true,
            page: currentStep,
        }));
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}

export const updateSubscribedCoverageInformation = (params, setStep = false) => async (dispatch) => {
    dispatch(UPDATE_LOADING(true));
    dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(params?.currentStep));
    let currentStep = params?.currentStep;
    const subscribedElements = _map(params?.subscribedElements, item => ({
        ...item,
        insuredPersons: _map(item?.insuredPersons, ip => _omit(ip, 'person.data'))
    }));
    try {
        let respObj = await http.post(appConfig.SSP_MID_URL + `/v1/updateSubscribedCoverageInformation`, {
            enrollmentProcessBuid: params?.enrollmentProcessBuid,
            subscribedElements,
            productIdentifierBuid: params?.productIdentifierBuid,
            beneficiaryList: params?.beneficiaryList,
            allowedBeneficiaryKind: params?.allowedBeneficiaryKind,
            selectedValue: params?.selectedValue,
            possibleValues: params?.possibleValues,
            policyReplacementAnswer: params?.policyReplacementAnswer,
            loggedUserEntity: params?.loggedUserEntity,
            coverageBeneficiaryEligibility: params?.coverageBeneficiaryEligibility,
            currentStep: params?.currentStep,
            coverageBuid: params?.coverageBuid,
            externalStepContext: params?.externalStepContext,
            isMoveNext: params?.isMoveNext,
            isSubscribeBeneficiary: params?.isSubscribeBeneficiary || false,
            currentStepInfo: {
                "portalStep": currentStep
            }
        });
        if (respObj.data && respObj.status === 200) {
            let coverageBuid = params?.coverageBuid
            let personBuid = params?.personBuid
            const data = respObj?.data?.data
            for (const key in data) {
                let result = { _Result: data[key].data }
                switch (key) {
                    case 'validateSubscribedCoverageData':
                        if (data[key]?.success) {
                            dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                                coverageBuid: coverageBuid,
                                personBuid: personBuid,
                                product: currentStep,
                            }));
                            dispatch(
                                UPDATE_COVERAGE_INFO({
                                    coverageBuid: coverageBuid,
                                    personBuid: personBuid,
                                    product: currentStep,
                                    validateSubscribedCoverageData: result
                                })
                            );
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'validateSubscribedCoverageData',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'getSubscribedCoverageBeneficiaryConfiguration':
                        if (data[key]?.success) {
                            dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                                coverageBuid: coverageBuid,
                                personBuid: personBuid,
                                product: currentStep
                            }));
                            dispatch(
                                UPDATE_COVERAGE_INFO({
                                    coverageBuid: coverageBuid,
                                    personBuid: personBuid,
                                    product: currentStep,
                                    beneficiaryConfiguration: result
                                })
                            );


                            const coverageBenficiaryEligiblity = getCoverageBeneficiaryEligibility(result?._Result, coverageBuid, personBuid);
                            dispatch(SET_COVERAGE_BENEFICIARY_ELIGIBILITY({ eligibility: coverageBenficiaryEligiblity, product: currentStep }));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'getSubscribedCoverageBeneficiaryConfiguration',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'updateSubscribedCoverageInformation':
                        if (data[key]?.success) {
                            dispatch(onLoadAPI({
                                UpdatesubscribeCoverage: result,
                                isAPICallInProgress: false,
                            }))
                            const processInfo = result?._Result ?? {};
                            dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'updateSubscribedCoverageInformation',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'moveNext':
                        if (data[key]?.success) {
                            dispatch(onLoadAPI({ moveNext: result }));
                            const processInfo = result ?? {};
                            console.log('processInfo', processInfo)
                            dispatch(UPDATE_ENROLLMENT_DATA(processInfo?._Result));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'moveNext',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    default:
                        dispatch(onLoadAPI({ [key]: result }));
                }

            }
            return {
                status: true,
                respObj: respObj
            }
        } else {
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: respObj?.status || '520',
                id: 'updateSubscribedCoverageInformationApi',
                show: true,
                page: currentStep,
            }));
            return {
                status: false,
                respObj: respObj
            }
        }
    } catch (e) {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'updateSubscribedCoverageInformationApi',
            show: true,
            page: currentStep,
        }));
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}

export const declineProduct = (params, setStep = false) => async (dispatch) => {
    dispatch(UPDATE_LOADING(true));
    try {
        let respObj = await http.post(appConfig.SSP_MID_URL + `/v1/declineProduct`, {
            enrollmentProcessBuid: params?.enrollmentProcessBuid,
            subscribedProductBuid: params?.subscribedProductBuid,
        });
        if (respObj.data) {
            const data = respObj?.data?.data
            for (const key in data) {
                let result = { _Result: data[key].data }
                switch (key) {
                    case 'declineProduct':
                        if (data[key]?.success) {
                            dispatch(onLoadAPI({ declineProduct: result }));
                            const processInfo = result?._Result ?? {};
                            dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
                        }
                        break;
                    case 'moveNext':
                        if (data[key]?.success) {
                            dispatch(onLoadAPI({ moveNext: result }));
                            const processInfo = result ?? {};
                            dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
                        }
                        break;
                    default:
                        dispatch(onLoadAPI({ [key]: result }));
                }

            }
            return {
                status: true,
                respObj: respObj
            }
        } else {
            return {
                status: false,
                respObj: respObj
            }
        }
    } catch (e) {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'updateSubscribedCoverageInformationApi',
            show: true,
        }));
        
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}

export const getProductConfig = (params) => async (dispatch) => {
    dispatch(UPDATE_LOADING(true));
    try {
        let respObj = await http.post(appConfig.SSP_MID_URL + `/v1/get-product-config`, {
            productName: params?.productName
        })

        if (respObj.data) {
            return {
                status: true,
                data: respObj.data
            }
        } else {
            return {
                status: false,
                data: respObj.data
            }
        }
    } catch (e) {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'updateSubscribedCoverageInformationApi',
            show: true,
        }));
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}