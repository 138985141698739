import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextInput, Button, CustomCheckboxDropdown, SelectDropdown, Checkbox } from '../../../CustomSection/Atoms';
import './styles.css';
import { UPDATE_LOADING } from '../../../../../Store/Slices/InsuredInfo';
import appConfig from '../../../../../appConfig';
import { downloadReport } from './reportDownloadHelper';

const EnrollmentReportForm = ({ setShowAlert }) => {
    const dispatch = useDispatch();
    const products = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.getListOfEligibleProducts);
    let legalEntityId = useSelector((storeState) => storeState.registeration?.legalEntityId);
    let userType = useSelector((storeState) => storeState.registeration?.userType);
    let callcenterHR = useSelector((storeState) => storeState.registeration?.callcenterHR);
    if (legalEntityId && !callcenterHR) {
        legalEntityId = legalEntityId.substring(0, legalEntityId.indexOf(':'))
    }

    // Define initial values
    const initialValues = {
        startDate: '',
        endDate: '',
        productIds: [],
        includeDependents: '',
        reportFormat: '',
        reportType: 'enrollment',
        fullReport: false,
    };

    let includeDependents = {
        "id": "includeDependents",
        "name": "includeDependents",
        "type": "radio",
        "label": "Include Dependents",
        "isRequired": true,
        "options": [
            {
                "id": "includeDependentsTrue",
                "value": true,
                "label": "Yes"
            },
            {
                "id": "includeDependentsFalse",
                "value": false,
                "label": "No"
            }
        ]
    }
    
    
    
    
    // Define validation schema
    const validationSchema = Yup.object({
        startDate: Yup.date()
            .nullable()
            .when("fullReport", {
                is: true,
                then: Yup.date().nullable(),
                otherwise: Yup.date().required('Start Date is required').test(
                    'start-date-test',
                    'The Start Date must be on or before the End Date',
                    function (value) {
                        const { endDate } = this.parent;
                        return !value || !endDate || new Date(value) <= new Date(endDate);
                    }
                )
            }),
        endDate: Yup.date()
            .nullable()
            .when("fullReport", {
                is: true,
                then: Yup.date().nullable(),
                otherwise: Yup.date().required('End Date is required').test(
                    'end-date-test',
                    'The End Date must be on or after the Start Date',
                    function (value) {
                        const { startDate } = this.parent;
                        return !value || !startDate || new Date(value) >= new Date(startDate);
                    }
                )
            }),
        productIds: Yup.array().min(1, 'Selecting at least one product is required'),
        includeDependents: Yup.string().required('Please specify if dependents should be included'),
        reportFormat: Yup.string().required('Report Format is required'),
        fullReport: Yup.boolean(),
    });

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        const currentDate = new Date().toISOString().split('T')[0];
        formik.setFieldValue('fullReport', isChecked);
        formik.setFieldValue('startDate', '');
        formik.setFieldValue('endDate', '');
        formik.setFieldTouched('startDate', false, false);
        formik.setFieldTouched('endDate', false, false);




       
    };


    
    // Define formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const payload = {
                ...values,
                legalEntityId,
                includeDependents: values.includeDependents === 'true',
                startDate: values.fullReport ? '1900-01-01' : values.startDate,
                endDate: values.fullReport ? new Date().toISOString().split('T')[0] : values.endDate,
            };

            setSubmitting(true);
            dispatch(UPDATE_LOADING(true));
            setShowAlert(false);

            const success = await downloadReport(appConfig.SSP_MID_URL + `/v1/get-report`, payload, values.reportFormat);
            if (success) {
                dispatch(UPDATE_LOADING(false));
                setSubmitting(false);
                return true;
            } else {
                setShowAlert(true);
                setSubmitting(false);
                dispatch(UPDATE_LOADING(false));
                return false;
            }
        },
    });

    // Define error message
    const ErrorMessage = (field) => {
        return (
            <>
                {formik.touched[field] && formik.errors[field] ? (
                    <div className="form-mandatory">{formik.errors[field]}</div>
                ) : null}
            </>
        );
    };


    const isButtonDisabled = () => {
        if (formik.values.fullReport) {
            // Only require report format to be set when fullReport is checked
            return !formik.values.reportFormat || formik.isSubmitting;
        }
        // Require all fields when fullReport is unchecked
        return !(formik.values.startDate && formik.values.endDate && formik.values.reportFormat) || formik.isSubmitting;
    };
    useEffect(() => {
        formik.validateForm();
    }, []);

    const productOptions = products?.map(item => ({
        label: item.name,
        value: item.code
    }));

    return (
        <div className="">
            <div className="py-4 filter-title"> Filter Enrollment Records</div>
            <form onSubmit={formik.handleSubmit} className='disable-scroll'>
                <div className="row form-group">
                    <div className="col-md-3">
                        <TextInput
                            label="Start Date"
                            required={!formik.values.fullReport}
                            type='date'
                            onInput={(e) => e.preventDefault()}
                            onChange={formik.handleChange}
                            name="startDate"
                            id="startDate"
                            value={formik.values.startDate}
                            placeholder=""
                            className="w-100 inputField"
                            disabled={formik.values.fullReport}
                            style={formik.values.fullReport ? { backgroundColor: '#e9ecef', color: '#6c757d', cursor: 'not-allowed' } : {}}
                            {...formik.getFieldProps('startDate')}
                        />
                        {ErrorMessage('startDate')}
                    </div>
                    <div className="col-md-3">
                        <TextInput
                            label="End Date"
                            required={!formik.values.fullReport}
                            type='date'
                            onInput={(e) => e.preventDefault()}
                            onChange={formik.handleChange}
                            name="endDate"
                            id="endDate"
                            value={formik.values.endDate}
                            placeholder=""
                            className="w-100 inputField"
                            disabled={formik.values.fullReport}
                            style={formik.values.fullReport ? { backgroundColor: '#e9ecef', color: '#6c757d', cursor: 'not-allowed' } : {}}
                            {...formik.getFieldProps('endDate')}
                        />
                        {ErrorMessage('endDate')}
                    </div>
                    <div className="col-md-3 enrollment-report-select">
                        <SelectDropdown
                            label="Select Report Format"
                            name="reportFormat"
                            id="reportFormat"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.reportFormat}
                            options={[{ label: 'PDF', value: 'pdf' },
                            { label: 'Excel', value: 'excel' }]}
                            {...formik.getFieldProps('reportFormat')}
                        />
                        {ErrorMessage('reportFormat')}
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-md-3">
                        <CustomCheckboxDropdown
                            label="Select Products"
                            name="productIds"
                            id="productIds"
                            required={true}
                            options={productOptions || []}
                            formik={formik}
                            {...formik.getFieldProps('productIds')}
                        />
                        {formik?.values?.productIds?.length <= 0 ? ErrorMessage('productIds') : ""}
                    </div>
                    <div className="col-md-3">
                        <div className="customRadioGroup w-100">
                            <div className="row">
                                <div className="col-12 py-1 mb-2 pb-2">
                                    <span className="custom-control-radio">{includeDependents.label}</span>
                                    {includeDependents.isRequired && (
                                        <span className="form-mandatory"> *</span>
                                    )}
                                </div>
                            </div>
                            <fieldset>
                                <legend className="sr-only" aria-hidden="true"></legend>
                                <div className="radioOptions">
                                    {includeDependents.options.map((option) => (
                                        <label key={option.id} className="radioLabel">
                                            <input
                                                type="radio"
                                                id={option.id}
                                                name={includeDependents.name}
                                                value={option.value}
                                                checked={formik.values.includeDependents === String(option.value)}
                                                onChange={(e) => formik.setFieldValue(includeDependents.name, e.target.value)}
                                            />
                                            {option.label}
                                        </label>
                                    ))}
                                </div>
                            </fieldset>
                        </div>
                        {ErrorMessage('includeDependents')}
                    </div>
                </div>

                {/* Add Checkbox for fullReport */}
                <div className="row form-group">
                    <div className="col-md-5">
                        <Checkbox
                            label="Download full Enrollment Report as of current date"
                            name="fullReport"
                            id="fullReport"
                            checked={formik.values.fullReport}
                            onChange={handleCheckboxChange}
                            className={`custom-checkbox`}
                        />
                    </div>
                </div>

                <div className="col-12 mb-5" style={{ padding: 0 }}>
                    <Button
                        type="submit"
                        disabled={isButtonDisabled()}
                    >
                        Download Report
                    </Button>



                   
                </div>
            </form>
        </div>
    );
};

export default EnrollmentReportForm;
