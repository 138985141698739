import React, { useEffect, useState } from 'react';
import LoginViewUser from '../../Tissues/LoginViewUser';
// import MercerLogin from '../../Tissues/MercerLogin';
import CustomLogin from '../../Tissues/CustomLogin';
import { useSelector } from 'react-redux';
import OldCustomLogin from '../../Tissues/OldCustomLogin';
import PerformanceLogin from '../../Tissues/PerformanceLogin'

const Login = (props) => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, SetContent] = useState({});

  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => SetContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);

  let configuration = false;
  if (props.type == 'Configurator' || props.type == 'CallCenter') {
    configuration = true;
  }

  return configuration ? (
    <LoginViewUser {...props} content={content} />
  ) : props.performance ? (
    <PerformanceLogin {...props} content={content} />
  ) : props.mfa === false ? (
    <OldCustomLogin {...props} content={content} />
  ) : (
    <CustomLogin {...props} content={content} />
  );
  // return <LoginViewUser {...props} content={content} />;

  // return <CustomLogin {...props} content={content} />;
  // return <LoginViewUser {...props} content={content} />;
};

export default Login;
