import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import appConfig from '../../../appConfig';
import { useState, useEffect } from 'react';
import Loader from '../../Atoms/Loader';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../../Common/Services/HttpService';
import { Select } from '../../Atoms';
import { Linker } from '../../Atoms';
import { SET_CLIENT_NAME, SEARCH_LEGAL_ENTITY, SET_REDIRECT_TO_CLIENT_SEARCH } from '../../../Store/Slices/CallCenter';
import { Icon } from '../../Atoms';
import _ from 'lodash';
import { SET_CONFIGURATOR_CLIENT_ID, apiReceivedMFAprocessType, SSO_LOGIN_ERROR, setSSOLoginError, setProcessType, SET_CALL_CENTER_ADMIN, CHECK_LOGIN, SET_CURRENT_PROCESS_TYPE, CONFIG_SSO_LOGIN } from '../../../Store/Slices/Auth/userAuth';
import { APIRequestAction, onLoadAPI, onLoginAPI } from '../../../Store/Slices/Entities/response';
import { PRODUCT_LIST } from '../../../Store/Slices/Process';
import CryptoJS from "crypto-js";
import { CipherUtils } from '../../Utils/Helper/MFAHelper';
import { apiReceivedMFA, apiReceivedMFAFirstName, apiReceivedMFALastName, apiReceivedUserTypeAndLanguage, processTypeMFA, userTypeMFA } from '../../../Store/Slices/Auth/userAuth';
import { SET_MFA_API_PARAM, SET_MFA_LOGIN, SET_USER_TYPE_LANGUAGE,SET_CALL_CENTER_HR } from '../../../Store/Slices/Registeration';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../Store/Slices/InsuredInfo';
import APIRequest from '../../Utils/Helper/APIHelper';
import { apiReceived } from '../../../Store/Slices/Auth/loggedUserEntity/index';
import { useNavigationHook } from '../../../App/Molecules/CustomSection/Hooks/NavigationHook';
import { initializeForms, initializeFormsData } from '../../../Store/Slices/StepFormNew';
import { processAuthTokenType } from '../../../Store/Slices/AuthToken/userAuthToken';

const SearchClientList = (props) => {
  const {
    formik,
    hideElement,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { redirectFromEmpSearch = false, isValidLogin = false, SSOLoginError = false, ssoLoginError = false, processType = '', callCenterAdmin = false, clientId = '', data = {} } = useSelector((storeState) => storeState.auth.userAuth);
  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  const [showLoader, setShowLoader] = useState(false);
  const {
    clientName = '',
    clientBuid = '',
    searchLegalEntity,
    rediretToClientSearch
  } = useSelector((storeState) => storeState.callCenter);
  const { handleSetSkipSteps, handleSetCurrentStep, handleSetStepKeys, handleNextStep } = useNavigationHook();
  const GetUrlParams = () => {
    const authResult = new URLSearchParams(window.location.search);
    const user = authResult.get('email');
    return user;
  };

  const GetUrlParamsFirstName = () => {
    const authResult = new URLSearchParams(window.location.search);
    const firstName = authResult.get('firstName');
    return firstName;
  };
  const GetUrlParamsLastName = () => {
    const authResult = new URLSearchParams(window.location.search);
    const lastName = authResult.get('lastName');
    return lastName;
  };

  const GetUrlParamsprocessType = () => {
    const authResult = new URLSearchParams(window.location.search);
    const processType = authResult.get('processType');
    if (processType) {
      dispatch(apiReceivedMFAprocessType(processType))
      return processType
    }
    if (window.location.pathname.indexOf('configurator/home') !== -1) {
      dispatch(processAuthTokenType({ authTokenType: 'Configurator' }))
      dispatch(apiReceivedMFAprocessType('Configurator'))
      dispatch(userTypeMFA('Configurator'))
      return 'Configuration'
    }
    if (window.location.pathname.indexOf('callcenter/home') !== -1) {
      dispatch(processAuthTokenType({ authTokenType: 'callCenter' }))
      dispatch(apiReceivedMFAprocessType('callCenter'))
      dispatch(userTypeMFA('callCenter'))
      return 'callCenter'
    }

  };
  const GetUrlError = () => {
    const authResult = new URLSearchParams(window.location.search);
    const error = authResult.get('error');
    if (error) {
      dispatch(SSO_LOGIN_ERROR(true))
      return error
    }
    else
      return false
  };

  const userFromOkta = GetUrlParams()
  const firstNameFromOkta = GetUrlParamsFirstName()
  const lastNameFromOkta = GetUrlParamsLastName()
  const processTypeFromOkta = GetUrlParamsprocessType()
  const errorFromOkta = GetUrlError() || 'no'

  const decodeEmail = async (email) => {
    let cipheredEmail
    if (email) {
      try {
        cipheredEmail = await CipherUtils.decrypt(decodeURIComponent(email), process.env.REACT_APP_SSO_PRIVATE_KEY);
        if (cipheredEmail) {
          dispatch(apiReceivedMFA(cipheredEmail));
          callClientsAPI()
        }
      } catch (err) {
        console.error('crypto support is disabled!');
      }
      return cipheredEmail
    }
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userEmail = queryParams.get('email'); 
  useEffect(() => {
    decodeEmail(userEmail);
    if(!userEmail){
      getClientListAPI(searchLegalEntity)
    }
  }, [userEmail]);
  
  const decodeFirstName = async (firstName) => {
    let cipheredFirstName
    if (firstName) {
      try {
        cipheredFirstName = await CipherUtils.decrypt(decodeURIComponent(firstName), process.env.REACT_APP_SSO_PRIVATE_KEY);
      } catch (err) {
        console.error('crypto support is disabled!');
      }
      await dispatch(apiReceivedMFAFirstName(cipheredFirstName));
      return cipheredFirstName
    }
  }
  const decodeLastName = async (lastName) => {
    let cipheredLastName
    if (lastName) {
      try {
        cipheredLastName = await CipherUtils.decrypt(decodeURIComponent(lastName), process.env.REACT_APP_SSO_PRIVATE_KEY);
      } catch (err) {
        console.error('crypto support is disabled!');
      }
      await dispatch(apiReceivedMFALastName(cipheredLastName));
      return cipheredLastName
    }
  }

  const callClientsAPI = () => {
    if (processTypeFromOkta || window.location.pathname.indexOf('configurator/home') !== -1) {
      setShowLoader(true)
      let resp = http
        .post(appConfig.SSP_MID_URL + '/checklogin',)
        .then((resp) => {
          dispatch(CHECK_LOGIN(resp?.status && resp.status === 200))
          if (resp.status === 200) {
            http.setHeaderValue('limitrange', true)

            const resp = http
              .post(appConfig.SSP_MID_URL + '/getLegalEntities', {
                additionalInformation: { clientName: clientName?.trim(), oneCode: formInputData.oneCode?.trim() },
              })
              .then((resp) => {
                if (resp) {
                  ClientListFromAPI = resp?.data?._Result;
                  let clientCountNum = ClientListFromAPI.length
                  setClientCount(clientCountNum)
                  for (let i = 0; i < ClientListFromAPI.length; i++) {
                    const element = ClientListFromAPI[i];
                    clientList.push({
                      name: element.ExClientID + ': ' + element.LegalName,
                      to: 'tooooooooooo',
                      clientBuid: element.buid,
                      clientId: element.ExClientID
                    });
                  }
                  setClientListArray(clientList);
                  setClient(clientList);
                  setPaginatedEmployee(_(clientListArray).slice(0).take(pageSize).value());
                  setLoading(true);
                  setShowLoader(false)
                }
              })
          }
          else { }

        })
        .catch((error) => {
          if(error?.response?.status === 401){
            history.push('/callcenter')
          }
          setShowLoader(false)
        });
    }
    /** handle configurator user */
    else {
      setShowLoader(true)

      const resp = http
        .post(appConfig.SSP_MID_URL + '/getLegalEntities', {
          additionalInformation: { clientName: clientName?.trim(), oneCode: formInputData.oneCode?.trim() },
        })
        .then((resp) => {
          if (resp) {
            ClientListFromAPI = resp?.data?._Result;
            let clientCountNum = ClientListFromAPI.length
            setClientCount(clientCountNum)
            for (let i = 0; i < ClientListFromAPI.length; i++) {
              const element = ClientListFromAPI[i];
              clientList.push({
                name: element.ExClientID + ': ' + element.LegalName,
                to: 'tooooooooooo',
                clientBuid: element.buid,
                clientId: element.ExClientID
              });
            }
            setClientListArray(clientList);
            setClient(clientList);
            setPaginatedEmployee(_(clientListArray).slice(0).take(pageSize).value());
            setLoading(true);
            setShowLoader(false)
          }
        })
    }
  }
  const handleConfigLogin = async () => {

    if (userFromOkta && processTypeFromOkta && processTypeFromOkta === 'Configurator') {

      const authResult = new URLSearchParams(window.location.search);
      let remote_user = authResult.get('email');

      let decrypted;
      let value = decodeURIComponent(remote_user);

      if (remote_user) {
        try {
          decrypted = await CipherUtils.decrypt(value, process.env.REACT_APP_SSO_PRIVATE_KEY);
        } catch (err) {

        }
        const email = decrypted//.slice(4);

        dispatch(SET_MFA_LOGIN({ decryptedEmail: decrypted, email: email }));
        await dispatch(apiReceivedMFA(decrypted));
        /** Second API call - getUserBUID */
        const userName = { loginName: decrypted };

        await dispatch(apiReceivedUserTypeAndLanguage({ userType: processTypeFromOkta, language: 'en' }));
        let authPreRequest = dispatch(
          APIRequestAction({ userName }, { apiconfig: { url: '/getLoginUser' } })
        );
        // if (!authPreRequest) {
        //   setLoginError(true);
        // }
        authPreRequest.then(async (result) => {
          let data = {};
          result = result?._Result;
          Object.assign(data, { person: result });
          /** store reponse object in store */
          await dispatch(apiReceived(data));
          await dispatch(apiReceivedUserTypeAndLanguage({ userType: processTypeFromOkta, language: "en" }));
          let authReqData;
          authReqData = result && result ? { buid: result.bUID } : {};
          /** Third API call - getUserDetaile */
          let loggedUserEntity = await dispatch(
            APIRequestAction({ authReqData }, { apiconfig: { url: '/getEmployments' } })
            // APIRequestAction({ authReqData }, { apiconfig: { url: '/getEmployments' } })
          );
          let onLoginApiCalls = [
            {
              apiconfig: {
                url: '/gbpForLegalEntity',
                APIfn: 'getAPIResponse',
                objectName: 'gbpForLegalEntity',
                saveInRedux: true,
              },
            },
          ];
          Object.assign(data, { personDetails: loggedUserEntity });
          if (onLoginApiCalls) {
            await APIRequest(onLoginApiCalls, { loggedUserEntity }).then((data) => {
              if (data) {
                dispatch(onLoginAPI(data));
              }
            });
          }
          // if (!loggedUserEntity) {
          //   setLoginErrorMFA(true);
          // }
          /** store reponse object in store */
          await dispatch(apiReceived(data));
          // setUserAuthenticated(true);
        });
      }
    }
  };
  useEffect(() => {
    GetUrlError();
    //decodeEmail(userFromOkta)
    decodeFirstName(firstNameFromOkta);
    decodeLastName(lastNameFromOkta);
    GetUrlParamsprocessType()
    const urlPath = window.location.pathname.toLowerCase();
    //This condition was updated to display logged username in callcenter header after layout changes for callcenter
    if (urlPath.indexOf('callcenter') > -1) {
      dispatch(SET_CALL_CENTER_ADMIN(true));
      dispatch(SET_USER_TYPE_LANGUAGE({ userType: 'callCenter', language: "en" }));
      dispatch(CONFIG_SSO_LOGIN({ configuratorSSOLogin: processTypeFromOkta === 'Configurator' }))
    } else if (urlPath.indexOf('configurator') > -1) {
      dispatch(SET_CALL_CENTER_ADMIN(false));
      dispatch(SET_USER_TYPE_LANGUAGE({ userType: 'configurator', language: "en" }));
    }

    // if ((userFromOkta && processTypeFromOkta) || (redirectFromEmpSearch || window.location.pathname.indexOf('configurator/home') !== -1)) { 
    //   dispatch(SET_USER_TYPE_LANGUAGE({ userType: processTypeFromOkta, language: "en" }));
    //   dispatch(SET_CALL_CENTER_ADMIN(processTypeFromOkta==='callCenter'));
    //   dispatch(CONFIG_SSO_LOGIN({configuratorSSOLogin: processTypeFromOkta==='Configurator'}))
    // }

    // else {
    //   dispatch(SET_CALL_CENTER_ADMIN(false));
    //   dispatch(SET_USER_TYPE_LANGUAGE({ userType: "", language: "en" }));
    // }

    if (window.location.pathname.indexOf('configurator/home') !== -1) {
      dispatch(CONFIG_SSO_LOGIN({ configuratorSSOLogin: data?.token_type === "Bearer" ? false : true }))
      //dispatch(CONFIG_SSO_LOGIN({configuratorSSOLogin: true}))
    }
  }, []);

  const [loadElements, setLoadElements] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientCount, setClientCount] = useState(false);
  const options = [
    { "value": "25", "text": "25" },
    { "value": '10', "text": '10' },
    { "value": "15", "text": "15" },
    { "value": "20", "text": "20" }
  ];
  // const [selectedNumber, setSelectedNumber] = useState(options[0].value || 0);
  const [selectedNumber, setSelectedNumber] = useState(25);

  const [pageSize, setPageSize] = useState(options[0].value);
  const [paginatedEmployee, setPaginatedEmployee] = useState(['no']);
  const [client, setClient] = useState();
  const [clientSearch, setClientSearch] = useState(false);
  const [formInputData, setformInputData] = useState({
    clientName: '',
    oneCode: '',
    salary: '',
  });
  const [clientListArray, setClientListArray] = useState([]);


  let ClientListFromAPI;
  let clientList = [];
  let redirectToConfigLinks;
  const pageCount = clientListArray ? Math.ceil(clientListArray.length / pageSize) : 0;
  const pages = _.range(1, pageCount + 1);
  let hideiFrame = false;
  useEffect(() => {
    pagination(1);
  }, [loading]);

  useEffect(() => {
    renderAllData();
  }, [paginatedEmployee, clientListArray, searchLegalEntity, client, tableData, loading]);

  if (props.formName == 'SearchForClientConfig') {
    redirectToConfigLinks = true;
  }

  const itemsPerPage = pageSize;
  const totalItems = clientCount; // Example total items

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  let countText = selectedNumber < clientCount ?
    `${startItem} of ${endItem}` :
    `${clientCount} of ${clientCount}`
  const handleChange = (evnt) => {
    const newInput = (data) => ({ ...data, [evnt.target.name]: evnt.target.value });
    setformInputData(newInput);
    renderAllData();
    dispatch(UPDATE_LOADING(false));
  };

  const getClientListAPI = (searchLegalEntity) => {
    if (processTypeFromOkta || window.location.pathname.indexOf('configurator/home') !== -1) {
      setShowLoader(true);
      let resp = http
        .post(appConfig.SSP_MID_URL + '/checklogin',)
        .then((resp) => {
          dispatch(CHECK_LOGIN(resp?.status && resp.status === 200))
          if (resp.status === 200) {
            setShowLoader(true);
            {
              showLoader && <Loader loading={showLoader} />;
            }
            let clientName;
            if (formInputData.clientName == '' && formInputData.oneCode == '') {
              clientName = `legalName=&ClientId=`;
            } else if (formInputData.clientName == '' && formInputData.oneCode != '') {
              clientName = `ClientId=${formInputData.oneCode}`;
            } else if (formInputData.clientName != '' && formInputData.oneCode == '') {
              clientName = `legalName=${formInputData.clientName}`;
            } else if (formInputData.clientName != '' && formInputData.oneCode != '') {
              clientName = `legalName=${formInputData.clientName}&ClientId=${formInputData.oneCode}`;
            }

            if (searchLegalEntity) {
              clientName = searchLegalEntity;
            } else {
              clientName = `legalName=&ClientId=`;
            }

            const checkEmptyInput = !Object.values(formInputData).every((res) => res === '');
            if (checkEmptyInput) {
              const newData = (data) => [...data, formInputData];
              setTableData(newData);
            }

            http.setHeaderValue('limitrange', true)
            const resp = http
              .post(appConfig.SSP_MID_URL + '/getLegalEntities', {
                additionalInformation: { clientName: clientName?.trim(), oneCode: formInputData.oneCode?.trim() },
              })
              .then((resp) => {
                if (resp) {
                  ClientListFromAPI = resp?.data?._Result;
                  let clientCountNum = ClientListFromAPI.length
                  setClientCount(clientCountNum)
                  for (let i = 0; i < ClientListFromAPI.length; i++) {
                    const element = ClientListFromAPI[i];
                    clientList.push({
                      name: element.ExClientID + ': ' + element.LegalName,
                      to: 'tooooooooooo',
                      clientBuid: element.buid,
                      clientId: element.ExClientID
                    });
                  }
                  setClientListArray(clientList);
                  setClient(clientList);
                  setPaginatedEmployee(_(clientListArray).slice(0).take(pageSize).value());
                  setLoading(true);
                } else {
                  dispatch(SET_ALERT_MESSAGE({
                    type: 'error',
                    errorCode: '520',
                    continue: true,
                    id: 'getLegalEntities',
                    show: true,
                    page: 'Configurator',
                  }));
                  setShowLoader(false);
                }
              })
              .catch((error) => {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: '520',
                  continue: true,
                  id: 'getLegalEntities',
                  show: true,
                  page: 'Configurator',
                }));

              });

            setShowLoader(false);

          }
          else { }

        })
        .catch((error) => {
          if(error?.response?.status === 401){
            history.push('/callcenter')
          }
          setShowLoader(false)
          return null
        });
    }
    else {
      setShowLoader(true);
      {
        showLoader && <Loader loading={showLoader} />;
      }
      let clientName;
      if (formInputData.clientName == '' && formInputData.oneCode == '') {
        clientName = `legalName=&ClientId=`;
      } else if (formInputData.clientName == '' && formInputData.oneCode != '') {
        clientName = `ClientId=${formInputData.oneCode}`;
      } else if (formInputData.clientName != '' && formInputData.oneCode == '') {
        clientName = `legalName=${formInputData.clientName}`;
      } else if (formInputData.clientName != '' && formInputData.oneCode != '') {
        clientName = `legalName=${formInputData.clientName}&ClientId=${formInputData.oneCode}`;
      }

      if (searchLegalEntity && !rediretToClientSearch) {
        clientName = searchLegalEntity;
      } else {
        clientName = `legalName=&ClientId=`;
      }
      const checkEmptyInput = !Object.values(formInputData).every((res) => res === '');
      if (checkEmptyInput) {
        const newData = (data) => [...data, formInputData];
        setTableData(newData);
      }
      const resp = http
        .post(appConfig.SSP_MID_URL + '/getLegalEntities', {
          additionalInformation: { clientName: clientName?.trim(), oneCode: formInputData.oneCode?.trim() },
        })
        .then((resp) => {
          if (resp) {
            ClientListFromAPI = resp?.data?._Result;
            let clientCountNum = ClientListFromAPI.length
            setClientCount(clientCountNum)
            for (let i = 0; i < ClientListFromAPI.length; i++) {
              const element = ClientListFromAPI[i];
              clientList.push({
                name: element.ExClientID + ': ' + element.LegalName,
                to: 'tooooooooooo',
                clientBuid: element.buid,
                clientId: element.ExClientID
              });
            }
            setClientListArray(clientList);
            setClient(clientList);
            setPaginatedEmployee(_(clientListArray).slice(0).take(pageSize).value());
            setLoading(true);
          }
        })
        .catch((error) => {

        });

      setShowLoader(false);
    }
  };
  const pagination = (pageNo) => {
    setCurrentPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    const paginatedEmp = _(clientListArray).slice(startIndex).take(pageSize).value(); // data to display/page
    setPaginatedEmployee(paginatedEmp);
  };
  /** call portal edge */
  const callLoginConfigAPI = async (legalEntity) => {
    if (!legalEntity) return
    return await http
      .get(appConfig.SSP_MID_URL + `/v1/config/${legalEntity}`)
      .then(async (resp) => {
        const data = resp?.data?.data
        for (const key in data) {
          let result = { _Result: data[key].data }
          dispatch(onLoadAPI({ [key]: result }));
        }
        return true;
      })
      .catch((e) => {
        return false;
      });
  };
  const handleFilterOnEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setClientSearch(true)
      handleSubmit(e);
    }
  };

  const handleSubmit = async (evnt) => {
    dispatch(SET_REDIRECT_TO_CLIENT_SEARCH(false));
    setShowLoader(true);
    {
      showLoader && <Loader loading={showLoader} />;
    }
    setLoading(true);
    let clientName;
    if (formInputData.clientName == '' && formInputData.oneCode == '') {
      clientName = `legalName=&ClientId=`;
    } else if (formInputData.clientName == '' && formInputData.oneCode != '') {
      clientName = `ClientId=${formInputData.oneCode}`;
    } else if (formInputData.clientName != '' && formInputData.oneCode == '') {
      clientName = `legalName=${formInputData.clientName}`;
    } else if (formInputData.clientName != '' && formInputData.oneCode != '') {
      clientName = `legalName=${formInputData.clientName}&ClientId=${formInputData.oneCode}`;
    }
    dispatch(SEARCH_LEGAL_ENTITY({ searchLegalEntity: clientName }));
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData).every((res) => res === '');
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData];

      setTableData(newData);
    }
    if (processTypeFromOkta && processTypeFromOkta === 'callCenter')
      http.setHeaderValue('limitrange', true)
    const resp = await http.post(appConfig.SSP_MID_URL + '/getLegalEntities', {
      additionalInformation: { clientName: clientName?.trim(), oneCode: formInputData.oneCode },
    });

    if (resp) {
      ClientListFromAPI = resp?.data?._Result;

      for (let i = 0; i < ClientListFromAPI?.length; i++) {
        const element = ClientListFromAPI[i];

        ClientListFromAPI = resp?.data?._Result;
        let clientCountNum = ClientListFromAPI.length
        setClientCount(clientCountNum)
        clientList.push({
          name: element?.ExClientID + ': ' + element?.LegalName,
          to: 'tooooooooooo',
          clientBuid: element?.buid,
          clientId: element?.ExClientID
        });
      }
      setClientListArray(clientList);
      setClient(clientList);
      setPaginatedEmployee(_(clientList).slice(0).take(pageSize).value());
      setLoading(!loading);
    }
    setShowLoader(false);
  };

  const renderAllData = () => {
    // dispatch(UPDATE_LOADING(true));

    if (showLoader) {
      return <Loader loading={showLoader} />;
    }

    const onClickHandleButton = async (data) =>{
      await dispatch(
        SET_CLIENT_NAME({
          clientName: data?.name,
          clientBuid: data?.clientBuid,
        })
      );
      await dispatch(
        SET_CONFIGURATOR_CLIENT_ID({
          clientId: data.clientId,
        })
      );
      await callLoginConfigAPI(data?.clientId)
      await dispatch(SEARCH_LEGAL_ENTITY({ searchLegalEntity: '' }));
      await dispatch(apiReceivedMFAprocessType('Configurator'))
      handleConfigLogin()
      http.setAuthTokenUserType('callcenterhr');
      await dispatch(SET_MFA_API_PARAM({ legalEntityId: data?.clientId }))
      await dispatch(SET_CALL_CENTER_HR({ callcenterHR: true }))
      
      setTimeout(() => {
        history.push("/hrcenter");
      }, 500);
    }
    
  

    return (
      <>
        {isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />}
        <div className='searchComponentContainer container-fluid px-md-5 py-3'>
          <div className="px-0">
            <div className="py-1 heading"> Filter Client List</div>
            <div className="form-row row">
              <div className="col-12 col-lg-3 col-md-3 py-1">
                Search by Client Name
                <input
                  type="text"
                  onChange={handleChange}
                  value={formInputData.clientName}
                  name="clientName"
                  className="form-control"
                  placeholder="Enter Client Name"
                  onKeyDown={handleFilterOnEnter}
                />
              </div>

              <div className="col-12 col-lg-3 col-md-3 py-1">
                Search by OneCode
                <input
                  type="email"
                  onChange={handleChange}
                  value={formInputData.oneCode}
                  name="oneCode"
                  className="form-control"
                  placeholder="Enter OneCode"
                  onKeyDown={handleFilterOnEnter}
                />
              </div>
              <div className="offset-0 offset-md-0 offset-lg-0 col-12 col-lg-3 col-md-3 py-4">
                <button
                  type="submit"
                  outline={true}
                  rounded={true}
                  onClick={handleSubmit}
                  className="btn  btn-primary primaryBackground  full-width"
                >
                  Go
                </button>
              </div>
            </div>
            {paginatedEmployee.length > 0 && (
              <div className="row  ">
                <div className="col-auto selectContainer">
                  {/* <Select
                  label=""
                  defaultValue={pageSize}
                  onChange={(e) => {
                    dispatch(UPDATE_LOADING(true));
                    setPageSize(e.target.value);
                    setPaginatedEmployee(_(clientListArray).slice(0).take(e.target.value).value());
                    setSelectedNumber(e.target.value)
                    setCurrentPage(1)
                    dispatch(UPDATE_LOADING(false));
                  }}
                  options={options}
                  disabled={true}
                />  */}
                  <select
                    id="selectList"
                    name="selectList"
                    defaultValue={pageSize}
                    className="w-100   selectText "
                    onChange={(e) => {
                      setPageSize(e.target.value);
                      setPaginatedEmployee(_(clientListArray).slice(0).take(e.target.value).value());
                      //setSelectedNumber(paginatedEmployee)
                    }}

                    disabled={true}
                  // {...formik.getFieldProps(formFields.dependentRelationship.name)}
                  >
                    <option value="" hidden disabled></option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                    {/* options={options} */}
                  </select>
                </div>
                <div className="col-auto pb-2 " style={{ alignSelf: 'center' }}>
                  <span style={{ fontFamily: 'NotoSansRegular', color: '#2C6EF2' }}>
                    {countText}
                    {/* {selectedNumber} of {clientCount} records */}
                  </span>
                </div>

                <nav>
                  <ul className="pagination pt-1 my-0">
                    <li class={currentPage === 1 ? 'page-item disabled' : 'page-item '}>
                      <p
                        className="page-link"
                        onClick={() => {
                          pagination(1);
                        }}
                      >
                        FIRST
                      </p>
                    </li>
                    <li class={currentPage === 1 ? 'page-item disabled' : 'page-item '}>
                      <p
                        class="page-link"
                        aria-label="Previous"
                        onClick={() => {
                          if (currentPage !== 1) {
                            pagination(currentPage - 1);
                          }
                        }}
                      >
                        <span>
                          <Icon
                            icon={['fas', 'chevron-left']}
                            style={currentPage === 1 ? { color: '#a5a7ac' } : { color: '#202020' }}
                          />
                        </span>
                        <span class="sr-only">Previous</span>
                      </p>
                    </li>
                    <li class={currentPage === pageCount ? 'page-item disabled' : 'page-item '}>
                      <p
                        className="page-link"
                        aria-label="Next"
                        onClick={() => {
                          if (currentPage !== pageCount) {
                            pagination(currentPage + 1);
                          }
                        }}
                      >
                        <span>
                          <Icon
                            icon={['fas', 'chevron-right']}
                            style={
                              currentPage === pageCount ? { color: '#a5a7ac' } : { color: '#202020' }
                            }
                          />
                        </span>
                        <span class="sr-only">Next</span>
                      </p>
                    </li>
                    <li class={currentPage === pageCount ? 'page-item disabled' : 'page-item '}>
                      <p
                        className="page-link"
                        onClick={() => {
                          pagination(pageCount);
                        }}
                      >
                        LAST
                      </p>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
            <div className="configClass">
              <div className="row py-3">
                <div className="col-12">
                  <div className="row">
                    {paginatedEmployee?.length <= 0 && (
                      <div className="col-12 error-message">No Client records found </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-12">
                      {paginatedEmployee?.length > 0 && (
                        <table className="table">
                          <thead className="headerTableTemp">
                            <tr>
                              <th>CLIENT LIST</th>
                              {!redirectToConfigLinks&&<th>HR PORTAL LINKS</th>}
                            </tr>
                          </thead>
                          <tbody className="tableClient">
                            {paginatedEmployee?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {!redirectToConfigLinks ? (
                                      <>
                                      <Link
                                        to="/callcenter/employeeSearch"
                                        onClick={() => {
                                          dispatch(
                                            SET_CLIENT_NAME({
                                              clientName: data?.name,
                                              clientBuid: data?.clientBuid,
                                            })
                                          );
                                          dispatch(
                                            SET_CONFIGURATOR_CLIENT_ID({
                                              clientId: data?.clientId,
                                            })
                                          );
                                          dispatch(initializeFormsData({}));
                                          // handleSetCurrentStep("SearchForAnEmployee")
                                          // handleSetCurrentStep('SearchForAnEmployee');
                                          callLoginConfigAPI(data?.clientId)
                                          dispatch(SET_MFA_API_PARAM({ legalEntityId: data?.clientId }))
                                          dispatch(SEARCH_LEGAL_ENTITY({ searchLegalEntity: '' }));
                                        }}
                                      >
                                        {data?.name}
                                      </Link>
                                      </>
                                    ) : (
                                      <Link
                                        to="/configurator/configLinks"
                                        onClick={() => {
                                          dispatch(
                                            SET_CLIENT_NAME({
                                              clientName: data?.name,
                                              clientBuid: data?.clientBuid,
                                            })
                                          );
                                          dispatch(
                                            SET_CONFIGURATOR_CLIENT_ID({
                                              clientId: data.clientId,
                                            })
                                          );
                                          dispatch(SEARCH_LEGAL_ENTITY({ searchLegalEntity: '' }));
                                          dispatch(apiReceivedMFAprocessType('Configurator'))
                                          handleConfigLogin()
                                        }}
                                      >
                                        {data?.name}
                                      </Link>
                                    )}
                                  </td>
                                  {!redirectToConfigLinks &&<td>
                                       <button
                                type="button"
                                className="btn btn-link mt-1 px-3 py-1 mb-md-1 px-xl-0"
                                onClick={() =>{onClickHandleButton(data)
                                 }}
                            >
                               Click Here
                            </button> 
                                      
                                      </td>}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4"></div>
              </div>
            </div>
          </div>
        </div>
      </>

    );

  };
  if ((!errorFromOkta || errorFromOkta === 'no') && ((isValidLogin && callCenterAdmin) || !callCenterAdmin)) {

    return (
      <>
        {isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />}
        <div>
          <div className="configClassRow">
            <div className="row py-3">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">{renderAllData()}</div>
                </div>
                <div className="col-sm-4"></div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </>
    );
  }
  else
    return (
      <div>
        <span
          style={{
            color: '#f44336',
            fontFamily: 'NotoSansRegular',
            fontSize: '1.75rem',
            paddingLeft: '0rem',
          }}
        > {!errorFromOkta && errorFromOkta !== 'no' && processTypeFromOkta ?
          'Access denied : User information is incorrect' :
          errorFromOkta && errorFromOkta !== 'no' && processTypeFromOkta ?
            'Access denied : User unauthorized' :
            ''
          }
        </span>
      </div>
    )
};

SearchClientList.propTypes = {
  elements: PropTypes.array,
  allFormData: PropTypes.object,
  updateFormData: PropTypes.func,
  tableRowMapper: PropTypes.func,
  tableHeaders: PropTypes.arrayOf(PropTypes.string),
};
SearchClientList.defaultProps = {
  elements: {},
  allFormData: {},
  tableHeaders: [],
  setAllFormData: () => { },
  tableRowMapper: () => { },
};

export default SearchClientList;
