import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextInput, SelectDropdown, Checkbox, Button } from '../../../CustomSection/Atoms';
import './styles.css';
import { UPDATE_LOADING } from '../../../../../Store/Slices/InsuredInfo';
import appConfig from '../../../../../appConfig';
import { downloadReport } from './reportDownloadHelper';

const CensusReportForm = ({ setShowAlert }) => {
    const dispatch = useDispatch();
    let legalEntityId = useSelector((storeState) => storeState.registeration?.legalEntityId);
    let userType = useSelector((storeState) => storeState.registeration?.userType);
    let callcenterHR = useSelector((storeState) => storeState.registeration?.callcenterHR);

    if (legalEntityId && !callcenterHR) {
        legalEntityId = legalEntityId.substring(0, legalEntityId.indexOf(':'))

    }

    const initialValues = {
        startDate: '',
        endDate: '',
        reportFormat: '',
        fullReport: false,  
        reportType: 'census'



    };

    const validationSchema = Yup.object({
        startDate: Yup.date()
            .nullable()
            .when("fullReport", {
                is: true,
                then: Yup.date().nullable(),
                otherwise: Yup.date().required('Start Date is required').test(
                    'start-date-test',
                    'The Start Date must be on or before the End Date',
                    function (value) {
                        const { endDate } = this.parent;
                        return !value || !endDate || new Date(value) <= new Date(endDate);
                    }
                )
            }),
        endDate: Yup.date()
            .nullable()
            .when("fullReport", {
                is: true,
                then: Yup.date().nullable(),
                otherwise: Yup.date().required('End Date is required').test(
                    'end-date-test',
                    'The End Date must be on or after the Start Date',
                    function (value) {
                        const { startDate } = this.parent;
                        return !value || !startDate || new Date(value) >= new Date(startDate);
                    }
                )
            }),
        reportFormat: Yup.string().required('Report Format is required'),
        fullReport: Yup.boolean()
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const payload = {
                ...values,
                legalEntityId: legalEntityId || "",
                reportFormat: values.reportFormat,
                fullReport: values.fullReport,
                startDate: values.fullReport ? null : values.startDate,
                endDate: values.fullReport ? null : values.endDate
            };

            setSubmitting(true);
            dispatch(UPDATE_LOADING(true));
            setShowAlert(false);

            const success = await downloadReport(`${appConfig.SSP_MID_URL}/v1/get-report`, payload, values.reportFormat);
            if (success) {
                dispatch(UPDATE_LOADING(false));
                setSubmitting(false);
                setShowAlert(false);
                return true;
            } else {
                setShowAlert(true);
                setSubmitting(false);
                dispatch(UPDATE_LOADING(false));
                return false;
            }
        },
    });

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        formik.setFieldValue('fullReport', isChecked);
        formik.setFieldValue('startDate', '');
        formik.setFieldValue('endDate', '');
        // Reset touch state when checkbox changes to avoid unwanted validation messages
        formik.setFieldTouched('startDate', false, false);
        formik.setFieldTouched('endDate', false, false);
    };

    const ErrorMessage = (field) => {
        return (
            <>
                {formik.touched[field] && formik.errors[field] ? (
                    <div className="form-mandatory">{formik.errors[field]}</div>
                ) : null}
            </>
        );
    };

    const isButtonDisabled = () => {
        if (formik.values.fullReport) {
            // Only require report format to be set when fullReport is checked
            return !formik.values.reportFormat || formik.isSubmitting;
        }
        // Require all fields when fullReport is unchecked
        return !(formik.values.startDate && formik.values.endDate && formik.values.reportFormat) || formik.isSubmitting;
    };

    // Define inline styles for disabled inputs
    const disabledInputStyle = {
        backgroundColor: '#e9ecef',
        color: '#6c757d',
        cursor: 'not-allowed'
    };


    return (
        <div>
            <div className="py-4 filter-title">Filter Census Records</div>
            <form onSubmit={formik.handleSubmit} className="census-report-form disable-scroll">
                <div className="row form-group">
                    <div className="col-md-3">
                        <TextInput
                            label="Start Date"
                            required={!formik.values.fullReport}
                            type='date'
                            name="startDate"
                            id="startDate"
                            value={formik.values.startDate}
                            className="w-100 inputField"
                            disabled={formik.values.fullReport}
                            style={formik.values.fullReport ? disabledInputStyle : {}}
                            {...formik.getFieldProps('startDate')}
                        />
                        {ErrorMessage('startDate')}
                    </div>
                    <div className="col-md-3">
                        <TextInput
                            label="End Date"
                            required={!formik.values.fullReport}
                            type='date'
                            name="endDate"
                            id="endDate"
                            value={formik.values.endDate}
                            className="w-100 inputField"
                            disabled={formik.values.fullReport}
                            style={formik.values.fullReport ? disabledInputStyle : {}}
                            {...formik.getFieldProps('endDate')}
                        />
                        {ErrorMessage('endDate')}
                    </div>
                    <div className="col-md-3">
                        <SelectDropdown
                            label="Select Report Format"
                            name="reportFormat"
                            id="reportFormat"
                            required={true}
                            onChange={formik.handleChange}
                            value={formik.values.reportFormat}
                            options={[{ label: 'PDF', value: 'pdf' }, { label: 'Excel', value: 'excel' }]}
                            {...formik.getFieldProps('reportFormat')}
                        />
                        {ErrorMessage('reportFormat')}
                    </div>
                </div>


                <div className="row form-group">
                    <div className="col-md-5">

                        <Checkbox
                            label="Download full Census Report as of current date"
                            name="fullReport"
                            id="fullReport"
                            checked={formik.values.fullReport}
                            onChange={handleCheckboxChange}
                            className={`custom-checkbox`}
                        />







                    </div>
                </div>
                <div className="col-12 mb-5" style={{ padding: 0 }}>
                    <Button type="submit" disabled={isButtonDisabled()}>
                        Download Report
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default CensusReportForm;