import React, { useState, useEffect } from 'react';
import { UPDATE_UPDATED_BENEFICIARIES } from '../../../../../Store/Slices/EmployeeProfile/index';
import { UPDATE_BENEFICIARIES, SET_BENEFICIARIES_TO_UPDATE } from '../../../../../Store/Slices/InsuredInfo';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../Content/Constants.json';
import { SET_REQUIRED_CHECKS_PASSED_BOOLEAN } from '../../../../../Store/Slices/BuyUp';
import { cloneDeep } from 'lodash';

const validateExistingDependents = (beneficiaries, dependents) => {
    const dependentsToAdd = [];
    dependents.forEach(dependent => {
        const dependentAdded = beneficiaries.find(beneficiary => (beneficiary?.id || beneficiary?.beneficiaryBuid || beneficiary?.tempId) === dependent?.id);
        if (!!!dependentAdded) {
            dependentsToAdd.push({ ...dependent, isEditable: false });
        }
    });
    return dependentsToAdd;
};

const removeDuplicates = (beneficiariesList = []) => {
    const uniqueBeneficiaries = beneficiariesList.reduce((acc, current) => {
        const x = acc.find(item => {
            if (item?.beneficiaryOptions === 'trust') {
                if (current?.legalName === item?.legalName && current?.type !== 'beneficiary') {
                    return current;
                }
            } else {
                if (((item?.id || item?.beneficiaryBuid || item?.tempId) === (current?.id || current?.beneficiaryBuid || current?.tempId)) && current?.isMainBeneficiary === false) {
                    return current;
                }
            }
        });
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
    return uniqueBeneficiaries || [];
}

const validateType = (type = '') => {
    let newType = type;
    if (type === 'cPrimary') {
        newType = 'Primary';
    } else if (type === 'cContingent') {
        newType = 'Contingent';
    }
    return newType;
}

const BenificaryList = (props) => {
    let {
        initialBeneficiaries,
        beneficiaries,
        onEdit,
        setBeneficiaries,
        coverageBuid,
        setCanContinueBeneficiaries,
        isBeneficiaryMandatory,
        beneficiaryAdded = false,
        isBeneficiarySectionValidCallback,
    } = props;

    const storeFromRedux = useSelector((storeState) => storeState);
    const { insuredInfo, buyUp } = useSelector((storeState) => storeState);
    let beneficiariesNewList = insuredInfo?.[props.currentStep]?.updatedBeneficiary?.[coverageBuid] || [];

    const [error, setError] = useState('');
    const [canSaveBeneficiaries, setCanSaveBeneficiaries] = useState(false);
    const [btnEnabled, setBtnEnabled] = useState(false);
    const [isInitialBeneficiariesSet, setIsInitialBeneficiariesSet] = useState(false);

    const dispatch = useDispatch();


    // Function to update the beneficiaries Initial List
    useEffect(() => {
        if (!isInitialBeneficiariesSet && initialBeneficiaries && beneficiariesNewList?.length === 0) {
            updateBeneficiaries(
                initialBeneficiaries.map((beneficiary) => ({
                    ...beneficiary,
                    isEditable: false,
                })),
                false
            );
            setIsInitialBeneficiariesSet(true);
        } else if (initialBeneficiaries && beneficiariesNewList?.length > 0) {
            const dependentsToAdd = validateExistingDependents(beneficiariesNewList, initialBeneficiaries);
            const filteredBeneficiaries = removeDuplicates(beneficiariesNewList);
            updateBeneficiaries([...filteredBeneficiaries, ...dependentsToAdd], false);
        }
    }, [initialBeneficiaries, beneficiariesNewList]);

    const handleCheckbox = (e, index, id) => {
        const newBeneficiaries = [];

        if (beneficiaries && beneficiaries.length) {
            beneficiaries.forEach(beneficiary => {
                let newBeneficiary = cloneDeep(beneficiary);
                const beneficiaryId = (beneficiary?.id || beneficiary?.beneficiaryBuid || beneficiary?.tempId);
                if (beneficiaryId === id) {
                    newBeneficiary['beneficiarySelected'] = !beneficiary?.beneficiarySelected;
                }
                newBeneficiaries.push(newBeneficiary);
            });
        }
        updateBeneficiaries(newBeneficiaries);
    }

    // Function to get the correct label of Relationship
    const getRelationshipLabel = (relationshipValue, beneficiary) => {
        const option = constants?.relationship?.find(
            (option) => option.value === relationshipValue || option.label === relationshipValue
        );
        return option ? option.label : '';
    };

    // Function to Manage Delete Beneficiary
    const handleDeleteRow = (id) => {
        const newBeneficiaries = [];
        if (beneficiaries && beneficiaries.length) {
            beneficiaries.forEach(beneficiary => {
                let newBeneficiary = cloneDeep(beneficiary);
                const beneficiaryId = (beneficiary?.id || beneficiary?.beneficiaryBuid || beneficiary?.tempId);
                if (beneficiaryId !== id) {
                    newBeneficiaries.push(newBeneficiary);
                }
            });
        }
        updateBeneficiaries(newBeneficiaries);
    };

    const updateCanSaveBenificiaries = (canSaved) => {
        setCanSaveBeneficiaries(canSaved);
        setCanContinueBeneficiaries && setCanContinueBeneficiaries(canSaved);
    }

    useEffect(() => {
        let isSectionValid = validatePercentage(beneficiaries);
        if (isSectionValid) {
            setBtnEnabled(true)
        } else {
            setBtnEnabled(false)
        }
    }, [beneficiaries])

    const updateRedux = async () => {
        // Method to update Redux updateBeneficiary
        const dependentsList = storeFromRedux?.employeeProfile?.insuredsAsBeneficiaries;
        if (dependentsList && dependentsList.length) {
            let dependentIDArray = dependentsList.map((item) => {
                return item?.id
            })
            if (beneficiaries && beneficiaries.length) {
                let updatedBeneficiaries = beneficiaries.map((item) => {
                    if (dependentIDArray.indexOf(item?.id) > -1) {
                        return {
                            ...item,
                            isDependent: true
                        }
                    } else {
                        return item
                    }
                })
                await dispatch(SET_BENEFICIARIES_TO_UPDATE({ coverageBuid, currentStep: props.currentStep, data: updatedBeneficiaries }))
            } else {
                await dispatch(SET_BENEFICIARIES_TO_UPDATE({ coverageBuid, currentStep: props.currentStep, data: beneficiaries }))
            }
        } else {
            await dispatch(SET_BENEFICIARIES_TO_UPDATE({ coverageBuid, currentStep: props.currentStep, data: beneficiaries }))
        }

        setCanSaveBeneficiaries(false);
        if (isBeneficiarySectionValidCallback) {
            isBeneficiarySectionValidCallback(true)
        }
        setCanContinueBeneficiaries && setCanContinueBeneficiaries(true);
    };

    // Function to Manage Beneficiary Type Change
    const handleTypeChange = (id, value) => {
        const newBeneficiaries = [];
        if (beneficiaries && beneficiaries.length) {
            beneficiaries.forEach(beneficiary => {
                let newBeneficiary = cloneDeep(beneficiary);
                const beneficiaryId = (beneficiary?.id || beneficiary?.beneficiaryBuid || beneficiary?.tempId);
                if (beneficiaryId === id) {
                    newBeneficiary['beneficiaryType'] = value;
                }
                newBeneficiaries.push(newBeneficiary);
            });
        }
        updateBeneficiaries(newBeneficiaries);
    };

    // Function to Manage Beneficiary Percentage Change
    const handlePercentageChange = async (id, value) => {
        const newBeneficiaries = [];
        if (beneficiaries && beneficiaries.length) {
            beneficiaries.forEach(beneficiary => {
                let newBeneficiary = cloneDeep(beneficiary);
                const beneficiaryId = (beneficiary?.id || beneficiary?.beneficiaryBuid || beneficiary?.tempId);
                if (beneficiaryId === id) {
                    newBeneficiary['beneficiaryPercentage'] = value;
                }
                newBeneficiaries.push(newBeneficiary);
            });
        }
        updateBeneficiaries(newBeneficiaries);
    };

    useEffect(() => {
        validatePercentage(beneficiaries)
    }, [beneficiaryAdded])


    // Function to Validate the Total Percentage of Beneficiaries
    const validatePercentage = () => {
        let primaryTotal = 0;
        let contingentTotal = 0;
        let hasPrimary = false;
        let hasContingent = false;
        let primaryPercentageError = false;
        let contingentPercentageError = false;
        const selectedRows = [];
        if (beneficiaries && beneficiaries.length) {
            beneficiaries.forEach(beneficiary => {
                const beneficiaryType = validateType(beneficiary?.beneficiaryType);
                if (beneficiary?.beneficiarySelected) {
                    selectedRows.push(beneficiary);
                    if (beneficiaryType === 'Primary') {
                        // Validate 0 or null value
                        if (beneficiary.beneficiaryPercentage === 0) {
                            primaryPercentageError = true;
                        }
                        primaryTotal += beneficiary.beneficiaryPercentage;
                        hasPrimary = true;
                    } else {
                        // Validate 0 or null value
                        if (beneficiary.beneficiaryPercentage === 0) {
                            contingentPercentageError = true;
                        }
                        contingentTotal += beneficiary.beneficiaryPercentage;
                        hasContingent = true;
                    }
                }
            });
        }

        if (isBeneficiaryMandatory && selectedRows.length === 0) {
            setError(
                'The total Primary Beneficiary or Contingent Beneficiary should be 100% to continue.'
            );
            if (props.updateBeneficiary) {
                props.updateBeneficiary('Error', selectedRows);
            }
            updateCanSaveBenificiaries(false);
            return false;
        }

        if (hasPrimary && (primaryTotal !== 100 || primaryPercentageError)) {
            setError(
                'The total Primary Beneficiary or Contingent Beneficiary should be 100% to continue.'
            );
            if (props.updateBeneficiary) {
                props.updateBeneficiary('Error', selectedRows);
            }
            updateCanSaveBenificiaries(false);
            return false;
        }

        if (hasContingent && (contingentTotal !== 100 || contingentPercentageError)) {
            setError(
                'The total Primary Beneficiary or Contingent Beneficiary should be 100% to continue.'
            );
            if (props.updateBeneficiary) {
                props.updateBeneficiary('Error', selectedRows);
            }
            updateCanSaveBenificiaries(false);
            return false;
        }

        if (!hasPrimary && hasContingent) {
            setError('Cannot save/continue without Primary Beneficiary.');
            if (props.updateBeneficiary) {
                props.updateBeneficiary('Error', selectedRows);
            }
            updateCanSaveBenificiaries(false);
            return false;
        }
        if (props.updateBeneficiary) {
            props.updateBeneficiary('success', selectedRows);
        }
        setError('');
        updateCanSaveBenificiaries(true);
        // isBeneficiarySectionValidCallback(false)
        return true;
    };

    const updateBeneficiaries = async (beneficiaries, validate = true) => {
        await setBeneficiaries(beneficiaries);
        if (validate) {
            if (isBeneficiarySectionValidCallback) {
                isBeneficiarySectionValidCallback(false)
            }
        } else {
            let isSectionValid = validatePercentage(beneficiaries);
            if (isSectionValid) {
                isBeneficiarySectionValidCallback(true)
            }
        }
    }

    return (
        <div className="table-responsive beneficiary-table">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th aria-label="Checkbox Label" aria-hidden="true" scope="col"></th>
                        <th>BENEFICIARY NAME</th>
                        <th>RELATIONSHIP</th>
                        <th>BENEFICIARY TYPE</th>
                        <th>PERCENTAGE OF ALLOCATION</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {beneficiaries.map((beneficiary, index) => {
                        const beneficiaryId = (beneficiary?.id || beneficiary?.beneficiaryBuid || beneficiary?.tempId);
                        const isTrust = beneficiary?.beneficiaryOptions === 'trust';
                        const beneficiaryName = !isTrust
                            ? `${beneficiary?.beneficiaryFirstName} ${` `} ${beneficiary?.beneficiaryLastName}`
                            : beneficiary?.legalName;

                        let relationShip = !isTrust
                            ? getRelationshipLabel(beneficiary?.beneficiaryRelationship, beneficiary)
                            : '';

                        if (isTrust) relationShip = getRelationshipLabel('TGE');

                        return (
                            <tr key={index}>
                                <td>
                                    <input
                                        aria-label="benificiary Checkbox"
                                        type="checkbox"
                                        onChange={(e) => handleCheckbox(e, index, beneficiaryId)}
                                        checked={beneficiary?.beneficiarySelected}
                                        className='benifliciary-list-checkbox'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === 'Space') {
                                                e.preventDefault();
                                                handleCheckbox(e, index, beneficiaryId);
                                            }
                                        }}
                                        tabIndex={0}
                                    />
                                </td>
                                <td>{beneficiaryName}</td>

                                <td>{relationShip}</td>
                                <td>
                                    <select
                                        aria-label={beneficiaryId}
                                        value={validateType(beneficiary?.beneficiaryType)}
                                        disabled={!beneficiary?.beneficiarySelected}
                                        className="beneficiary-type"
                                        onChange={(e) => handleTypeChange(beneficiaryId, e.target.value)}
                                        tabIndex={0}
                                    >
                                        <option value="Primary">Primary</option>
                                        <option value="Contingent">Contingent</option>
                                    </select>
                                </td>
                                <td>
                                    <label for={beneficiaryId} className="lable-sr-only" aria-label="Beneficiary Percentage"></label>
                                    <input
                                        id={beneficiaryId}
                                        type="number"
                                        value={beneficiary?.beneficiaryPercentage}
                                        min="0"
                                        max="100"
                                        placeholder="Enter Value"
                                        disabled={!beneficiary?.beneficiarySelected}
                                        onChange={(e) => handlePercentageChange(beneficiaryId, parseFloat(e.target.value))}
                                    />
                                </td>
                                <td>
                                    {beneficiary?.isEditable && (
                                        <>
                                            <button
                                                className="btn-icon"
                                                onClick={() => onEdit(index)}
                                                aria-label="Edit"
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="pen"
                                                    class="svg-inline--fa fa-pen fa-w-16 action"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
                                                    ></path>
                                                </svg>
                                            </button>
                                            <button
                                                className="btn-icon"
                                                onClick={() => handleDeleteRow(beneficiaryId)}
                                                aria-label="Delete"
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="trash-alt"
                                                    class="svg-inline--fa fa-trash-alt fa-w-14 action"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {error && <div className="alert alert-danger mt-3">{error}</div>}
            <button type="button" className="btn btn-primary benf-saveBtn btn-primary-contrast" disabled={!btnEnabled} onClick={() => {
                // Update Redux 
                updateRedux();
            }
            }>
                Save Changes
            </button>
        </div>
    );
};

export default BenificaryList;
