import availableLanguages from './configs/languages.json';
import availableThemes from './configs/themes.json';

//Added SSP_THEME_BASE_URL for theme new server port
let appConfig = {
  RECAPTCHA_KEY: process.env.REACT_APP_SSP_RECAPTCHA_SITE_KEY || '6Lf-mcAiAAAAAAbsrjKz29j7Mz3TsgBV1GkKt_Qi',
  SSP_MID_URL: process.env.REACT_APP_SSP_MID_URL || 'http://localhost:5000',
  SSP_MID_BASE_URL: process.env.REACT_APP_SSP_MID_BASE_URL || 'http://localhost:5000',
  SSP_MID_BASE_PATH: process.env.REACT_APP_SSP_MID_PUBLIC_URL || '',
  SSP_AUTH_URL: process.env.REACT_APP_SSP_AUTH_API_URL || "http://localhost:3003",
  SSP_API_TIMEOUT :process.env.REACT_APP_SSP_API_TIMEOUT || 30000,
  CHAT_AVAILABILITY_URL : process.env.REACT_APP_CHAT_AVAILABILITY_URL || 'https://mchat-qpi.mercerhrs.com',
  REACT_APP_VOYA_IFRAME_URL:process.env.REACT_APP_VOYA_IFRAME_URL||'https://www.enrollvb.com/BayStateHealth-demo',



  language: {
    availableLanguages: availableLanguages,
    currentLanguage:
      window &&
      window.navigator &&
      window.navigator.language &&
      availableLanguages.includes(window.navigator.language.slice(0, 2))
        ? window.navigator.language.slice(0, 2)
        : 'en',
  },

  //Changed the old "default" value to "Default theme" to get the theme data from Mongo DB
  theme: {
    availableThemes: availableThemes,
    currentTheme: 'Default Theme',
  },
};
export default appConfig;
