import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import PropTypes from 'prop-types';
import { useSelector, useDispatch  } from 'react-redux';
import Button from '../Button'
import Elementor from '../../Cells/Form/Elementor';
import { SHOW_RESET_EMAIL_TIMER, SHOW_RESET_PHONE_TIMER } from '../../../Store/Slices/StartTimer';
import FormGenerator from '../../Cells/Form/FormGenerator';
import { ACTIVATE_PHONE } from '../../../Store/Slices/RegisterationError';

const Timer = (props) => {
  const {
    id,
    name,
    label,
    value,
    className,
    style,
    hideElement,
    elements,
    timerForEmail=false,
    timerForPhone=false,
    formik={},
    hideOnClickSendCodeBtn=false,
    hideOnWrongOTP=false,
    ...rest
  } = props;
const dispatch = useDispatch();
const { start } = useSelector((storeState) => storeState.startTimer);
const { showTimer, otpError, emailVerified,showTimerPhone, showPhoneOtp=false} = useSelector((storeState) => storeState.registeration);
const { currentStep } = useSelector((storeState) => storeState.stepForm);
const { registerationError } = useSelector((storeState) => storeState);
const { registeration } = useSelector((storeState) => storeState);
const { showSpecial=false, contactType='', sendCodeMobileClicked=false } = useSelector((storeState) => storeState.callCenter);

  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);
  // The state for our timer
  const [timer, setTimer] = useState('00:00:00');
  const [btnDisabled, setBtnDisabled] = useState(true);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    if (total === 0 || total < 0) {
      setBtnDisabled(false);
      clearInterval(Ref.current);

      // dispatch(SHOW_RESET_EMAIL_TIMER(!btnDisabled && timerForEmail));
    }
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total > 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        // (hours > 9 ? hours : '0' + hours) +
        //   ':' +
        (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds)
      );
    } else {
      setTimer('00:00');
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer('00:45');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    // }, 1000);
  }, 500);

    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    setBtnDisabled(true);
    // This is where you need to adjust if
    // you entend to add more time
     deadline.setSeconds(deadline.getSeconds() + parseInt(value));
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
    //setTimer('00:00:00')
  }, []);
  useEffect(() => {
    start && clearTimer(getDeadTime());

    //setTimer('00:00:00')
  }, [start]);
  useEffect(() => {
    dispatch(SHOW_RESET_EMAIL_TIMER(!btnDisabled && timerForEmail));
  }, [btnDisabled,timerForEmail]);
  useEffect(() => {
    dispatch(SHOW_RESET_PHONE_TIMER(!btnDisabled && timerForPhone));
  }, [btnDisabled,timerForPhone]);
  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  let formHasErr = registerationError[currentStep]?.hasError
  let showInput1 = registerationError.phoneIsVerified || (registerationError.hidePhoneOnLoad )
  let showInputChange = registerationError.hidePhoneOnLoad 
  let showInput3= registerationError.phoneIsVerified

  let noEr1= !registerationError.hasError || !formHasErr
  let noEr = noEr1 || showInput3
  let submitWoErr = !registerationError[currentStep]?.hasError && registerationError.submitPhoneClicked
  let hideTimerPhoneOtp = (currentStep=== 'MultiFactorAuthentication' && showInput1 && (noEr))
  // let hideTimerChangePhoneOtp = (currentStep=== 'ContactDetails' && showInputChange && (noEr1))

  let hideForRegister = ( (registerationError.emailVerified || !registeration.verifyEmailClicked) && currentStep=== 'UserAccountVerifyEmail'  )
  // let hideTimerPhoneOtp = (registerationError.activateFactor && (registeration.changeMethodClicked ||!registeration.sendPhoneClicked )
  //  && currentStep=== 'MultiFactorAuthentication')
  //(currentStep=== 'MultiFactorAuthentication' ?(!registerationError.mfaErrorText ||!registeration.changeMethodClicked )  ? !registeration.showPhoneOtp :false : true)
  let hideTimer = currentStep=== 'UserAccountVerifyEmail' ? hideForRegister :
  currentStep=== 'MultiFactorAuthentication'? hideTimerPhoneOtp : 
  //currentStep=== 'ContactDetails'? hideTimerChangePhoneOtp : 
  false
  let hideForExistEmail = currentStep==='UserAccountVerifyEmail' && registerationError.emailExist

  // let hideSpecial = name ==='timerEmail' && !showSpecial
  //let hideSpecial = currentStep ==='ContactDetails'  && ( !showSpecial ||!registerationError[currentStep]?.hasError)//&& () &&
  let hideSpecial = currentStep ==='ContactDetails'  &&(  !showSpecial) && contactType === 'email'

  let additionalClasses = !hideTimer? hideElement || hideForExistEmail? 'hideElement' : '' : true;
  const [content, SetContent] = useState({ banner: {} });
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  let hideOnClickSendCode = contactType=== 'mobile' ?  hideOnClickSendCodeBtn && !sendCodeMobileClicked :
  contactType=== 'email' ? !showSpecial :'';
  let hideOnWrongOtp = hideOnWrongOTP && (registerationError.wrongMobile || (!registeration.showTimerPhone && currentStep ==='MultiFactorAuthentication') )
  let hideOnEditEmail = (!registeration.displaySubmitCodeBtn && currentStep ==='UserAccountVerifyEmail') 

 
  return (
    <div 
      className={className + ' ' + additionalClasses + ' timerClass '+ hideForRegister} 
      style={style}
      hidden={hideTimer || registeration.changeMethodClicked ||  hideOnClickSendCode ||hideOnWrongOtp || hideOnEditEmail}>
      <h6>{timer} Seconds</h6>
      {elements && elements.length > 0 ?
        elements?.map((ele, index) => {
           ele = {...ele, ...{disabled: false}}
          return (
            <div className=' px-xl-0 '>
              <Elementor
                key={index}
                id={index}
                name={ele.name}
                element={ele}
                formik={formik}
                disabled={btnDisabled}
                onClickTimer={ onClickReset}
               // {...props}

              />
            </div>
          )
          }): ''}
  
      {/* <Button
        className1="btn btn-link px-0 mt-0 pt-0 timerClass"
        className="btn btn-link px-0 mt-0 pt-0 "
        disabled={btnDisabled}
        link={true}
        onClick={onClickReset}
      >
        Resend security code
      </Button> */}
    </div>
  );
};

Timer.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  valueIn: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
};
Timer.defaultProps = {
  value: '',
  valueIn: '',
  hidden: false,
  className: '',
  classNameWrapper: '',
  type: 'text',
  required: false,
  defaultValue: null,
};

export default Timer;