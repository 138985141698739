import { memo, useMemo, useRef } from 'react';
const d3 = require('d3');

import './index.css';

const PieChart = ({ data, updateColors }) => {
    const svgRef = useRef(null);

    useMemo(() => {
        const width = 250,
            height = 250,
            margin = 20;

        // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
        const radius = Math.min(width, height) / 2 - margin

        try {
            d3.select(svgRef.current)
                .selectAll("*").remove()
        } catch (e) {
            //nothing to clear
        }

        // append the svg object to the div called 'my_dataviz'
        const svg = d3.select(svgRef.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${svgRef.current?.offsetWidth / 2},${height / 2})`);

        // set the color scale
        const color = d3.scaleOrdinal()
            .range(['#006286', '#4ea8c2', '#8246af', "#F375A5", '#76d3ff', "#d1e0ec", '#57c67a', '#a77dc8', '#b8e5ed', '#627798', '#009de0']);

        // Compute the position of each group on the pie:
        const pie = d3.pie().value(d => d[1])
        const data_ready = pie(Object.entries(data))

        const colors = {};
        // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
        svg
            .selectAll('whatever')
            .data(data_ready)
            .join('path')
            .attr('d', d3.arc()
                .innerRadius(50)         // This is the size of the donut hole
                .outerRadius(radius)
            )
            .attr('fill', d => {
                const sColor = color(d.data[0]);
                colors[d.data[0]] = sColor;
                return sColor;
            })
            .style("stroke-width", "2px")
            .style("opacity", 0.7)
        updateColors(colors);
    }, [data]);

    return (
        <div className='pie-chart' ref={svgRef} />
    )

};

export default memo(PieChart);
