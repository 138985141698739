import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { contactTypeSchema } from '../../Atoms/FormValidation'
import { Button, RadioButton } from '../../Atoms';
import PhoneInput from './PhoneInput';
import EmailInput from './EmailInput';
import { Success, VerifyOTP } from '../common';
import { Link } from 'react-router-dom';

const ContactSelection = ({ onNext, onComplete, updateSteps }) => {
    const history = useHistory()
    const { userAuth } = useSelector((storeState) => storeState?.auth);
    const isHrCenter = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter") ? true : false
    const options = [
        { label: 'Change mobile number', value: 'mobile' },
        { label: 'Change email ID', value: 'email' },
    ];

    const formik = useFormik({
        initialValues: { contactType: '' },
        validationSchema: contactTypeSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            onNext();
        },
    });

    const onChange = (e) => {
        const value = e.target.value;
        if (value === 'mobile') {
            updateSteps([
                { component: PhoneInput },
                { component: VerifyOTP, otpMedia: 'phone', flow: 'changeMFAContactPhone', title: 'Change MFA Contact' },
                { component: Success, type: 'MFAContactPhone', hasContinue: false },
            ])
        } else {
            updateSteps([
                { component: EmailInput },
                { component: VerifyOTP, otpMedia: 'email', flow: 'changeMFAContactEmail', title: 'Change MFA Contact' },
                { component: Success, type: 'MFAContactEmail', hasContinue: false },
            ])
        }
        formik.handleChange(e);
    }

    const onCancel = () => {
        history.pop();
    }

    const validationMsg = (field) => {
        return (formik.touched[field] && formik.errors[field] && (
            <div className="form-mandatory">{formik.errors[field]}</div>
        ))
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-12">
                    <h1 className="form-side-header">Change MFA Contact</h1>
                </div>
            </div>
            <RadioButton
                name="contactType"
                options={options}
                onChange={onChange}
                selectedOption={formik.values.contactType}
                label="Change the Multi-factor Authentication contact details."
                className={'forMFA'}
            />
            {validationMsg('contactType')}

            <div className="row mfaBtnWrapper">
                <div className="col-sm-12 col-md-4 py-2">
                    {isHrCenter ? <Link role="button" to={`/hrcenter`}> Back to home</Link> : <Link role="link" to={`/enrollment/home`}> Back to Home</Link>}
                </div>
                <div className="col-sm-12 col-md-6 py-2 mr-4 text-md-right">
                    <Button className="btn btn-outline-primary primaryOutline btn-outline-primary-contrast mr-4" onClick={onCancel}>Cancel</Button>
                    <Button type="submit" className="btn btn-primary primaryBackground btn-primary-contrast" disabled={!formik.isValid}>Continue</Button>
                </div>
            </div>
        </form>
    )
}

export default ContactSelection;
