import React from 'react';
import PropTypes from 'prop-types';
import { Styles } from './styles';
import Elementor from '../../Cells/Form/Elementor';

const MainSection = (props) => {
  const  {
    data,
    showContent = [],
  } = props;

  const {
    elements = [],
    className = '',
    style = {},
  } = data;

  return ( 
    <div className={`bannerHome ${className}`} style={style}>
      { elements.map((element, key) => {
        const {
          config = {},
        } = element;
        return showContent.includes(element.id) || showContent.length === 0 ? (
          <div className={(config && config.className) || ''} key={`${element.name}-key`}>
            <Elementor
              key={element.name}
              element={element}
              showContent={showContent}
              {...props}
            />
          </div>
        ) : null;
      })}
    </div>
  );
};

const ColorBanner = (props) => {
  const {
    content,
    contentData,
    showContent,
  } = props;

  const {
    panelsData,
  } = contentData;

  const {
    layout
  } = content;

  const bannerData = panelsData && layout && layout.viewType && panelsData[layout.viewType] && panelsData[layout.viewType].colorBanner || {};

  return (
    <>
      <Styles />
      <MainSection
        data={bannerData}
        showContent={showContent}
      />
    </>
  );
};

ColorBanner.propTypes = {
  content: PropTypes.object,
  contentData: PropTypes.object,
  showContent: PropTypes.array,
};
ColorBanner.defaultProps = {
  content: {},
  contentData: {},
  showContent: [],
};

export default ColorBanner;
