import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.css';

const CustomCheckboxDropdown = ({ id, label, options, required, formik }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (isOpen && !formik.touched[id]) {
      formik.setTouched({ ...formik.touched, [id]: true });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (isOpen && !formik.touched[id]) {
          formik.setTouched({ ...formik.touched, [id]: true });
        }
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, formik.touched, id]);

  const handleOptionChange = (value) => {
    const currentValues = formik.values[id] || [];
    if (value === 'selectAll') {
      if (currentValues.length === options.length) {
        formik.setFieldValue(id, []);
      } else {
        const allValues = options.map(option => option.value);
        formik.setFieldValue(id, allValues);
      }
    } else {
      const newSelectedOptions = currentValues.includes(value)
        ? currentValues.filter(option => option !== value)
        : [...currentValues, value];
      formik.setFieldValue(id, newSelectedOptions);
    }
  };

  const getSelectedText = () => {
    const selectedOptions = formik.values[id] || [];
    if (selectedOptions.length === 0) {
      return 'Select';
    } else if (selectedOptions.length === 1) {
      const selectedOption = options.find(o => o.value === selectedOptions[0]);
      return selectedOption ? selectedOption.label : 'Select';
    } else if (selectedOptions.length === options.length) {
      return 'All items selected';
    } else {
      return `${selectedOptions.length} items selected`;
    }
  };

  return (
    <div className="custom-checkbox-dropdown" ref={dropdownRef}>
      <label htmlFor={id}>{label}{required && <span className='form-mandatory'> *</span>}</label>
      <div className="dropdown-header" onClick={handleToggle}>
        {getSelectedText()}
        <span className="dropdown-arrow">{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div className="custom-dropdown-menu">
          <label key="selectAll" className="dropdown-option">
            <input
              type="checkbox"
              value="selectAll"
              checked={formik.values[id]?.length === options.length}
              onChange={() => handleOptionChange('selectAll')}
            />
            Select All
          </label>
          {options.map((option) => (
            <label key={option.value} className="dropdown-option">
              <input
                type="checkbox"
                value={option.value}
                checked={formik.values[id]?.includes(option.value)}
                onChange={() => handleOptionChange(option.value)}
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

CustomCheckboxDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

export default CustomCheckboxDropdown;
