import React from 'react';
import SpouseCoverage from '../Component/Flow2/spouseCoverage';
import { useDispatch, useSelector } from 'react-redux';
import { SpouseCoverageBuyUp } from '../Component/BuyUp/spouseCoverageBuyUp';
import { useNavigationHook } from '../Hooks/NavigationHook';

const SpouseCoverageWrapper = (props) => {
  const { spouseIndex } = props;
  const personBuid = props.spouse[spouseIndex]?.personBuid;

  const { currentStep, formsConfig } = useNavigationHook();
  let spouseDependentList = [];
  const insuredInfoCurrentStep = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};
  // Null check before filtering subscribeCoverageBuids
  const filteredSubscribeCoverageBuids = insuredInfoCurrentStep.subscribeCoverageBuids?.filter(item => item.personBuid === personBuid) || [];
  // Null check before assuming that we have at least one object after filtering
  const matchedCoverageBuidObj = filteredSubscribeCoverageBuids.length > 0 ? filteredSubscribeCoverageBuids[0] : null;

  // Extract required details with null checks
  const listOfCoveragePlanDesignConfigurationResponse = matchedCoverageBuidObj?.listOfCoveragePlanDesignConfiguration?._Result || [];
  const subscribedCoverageConfigurationResponse = matchedCoverageBuidObj?.subscribedCoverageConfiguration?._Result || {};
  const beneficiaryConfigurationResponse = matchedCoverageBuidObj?.beneficiaryConfiguration?._Result?.[0] || {};
  const prepareSubscriptionForChoicesResponse = matchedCoverageBuidObj?.prepareSubscriptionForChoices?._Result || {};
  const coverageBuid = matchedCoverageBuidObj?.coverageBuid || "";

  let isSpouseBuyUpEnabled = false;
  isSpouseBuyUpEnabled = useSelector((storeState) => storeState.buyUp.eligibility.spouse);
  let basePlanBoughtCurrentYear = true;
  basePlanBoughtCurrentYear = useSelector((storeState) => storeState.buyUp.basePlanBoughtCurrentYearFlag.spouse);
  let isBuyUpFlow = false;
  let buyUpDeclinedLocally = useSelector((storeState) => storeState.buyUp.basePlanDeclinedLocally.spouse) || false
  if(buyUpDeclinedLocally) {
    isBuyUpFlow = true
  } else {
    isBuyUpFlow = isSpouseBuyUpEnabled ? (basePlanBoughtCurrentYear ? false : true ) : false;
  }

  let productFamily =formsConfig.forms?.[currentStep]?.productFamily

  const dependentsList = useSelector(
    (storeState) => storeState?.employeeProfile?.insuredsAsBeneficiaries
  );
  const filteredInsuredsAsBeneficiaries = dependentsList.filter(
    (beneficiary) => beneficiary.mainBuid !== personBuid
  );
  var beneficiaryObj;
  let updatedBeneficiaryList = [...filteredInsuredsAsBeneficiaries];
  let beneficiaryList = [];

  updatedBeneficiaryList.forEach((beneficiary) => {
    beneficiaryObj = { ...beneficiary };
    if (beneficiaryObj?.beneficiaryRelationship == '') {
      beneficiaryObj.beneficiaryRelationship = 'SP';
    }
    beneficiaryList = [...beneficiaryList, beneficiaryObj];
  });

  const beneficiaryEligibility = useSelector((storeState) => {
    const eligibilityData =
      storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
    const matchedEligibility = eligibilityData.filter(
      (eligibility) => eligibility?.coverageBuid === coverageBuid && eligibility?.personBuid === personBuid
    );
    return matchedEligibility[0]?.isBeneficiaryEligible || false;
  });
  
  const isBeneficiaryMandatory = useSelector((storeState) => {
    const eligibilityData =
      storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
    const matchedEligibility = eligibilityData.filter(
      (eligibility) => eligibility?.coverageBuid === coverageBuid
    );
    return matchedEligibility[0]?.isBeneficiaryMandatory || false;
  });

  const isBeneficiaryOptional = useSelector((storeState) => {
    const eligibilityData =
      storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
    const matchedEligibility = eligibilityData.filter(
      (eligibility) => eligibility?.coverageBuid === coverageBuid
    );
    return matchedEligibility[0]?.isBeneficiaryOptional || false;
  });

  return (
    <div>
      {isBuyUpFlow ? 
      <SpouseCoverageBuyUp 
        flowType={props.flowType}
        showDisclosureStatement={props.showDisclosureStatement}
        spouse={props.spouse}
        children={props.children}
        personBuid={personBuid}  
        customContent={props.customContent}
        displayMode={props.displayMode}
        nextStep={props.nextStep}
        prevStep={props.prevStep}
        onNext={props.onNext}
        productFamily={productFamily}
        dependentsList={beneficiaryList}
        allowedBeneficiaryKind={beneficiaryConfigurationResponse}
      /> : 
      <SpouseCoverage
        {...props}
        displayMode={props.displayMode}
        onCoverageChange={props.handleCoverageChange}
        coverageDetails={listOfCoveragePlanDesignConfigurationResponse}
        personBuid={personBuid}
        allowedBeneficiaryKind={beneficiaryConfigurationResponse}
        coverageBuid={coverageBuid}
        beneficiaryEligibility={beneficiaryEligibility}
        dependentsList={beneficiaryList}
        subscribedCoverageConfigurationResponse={subscribedCoverageConfigurationResponse}
        prepareSubscriptionForChoicesResponse={prepareSubscriptionForChoicesResponse}
        isBeneficiaryMandatory={isBeneficiaryMandatory}
        isBeneficiaryOptional={isBeneficiaryOptional}
      />}
    </div>
  );
};

export default SpouseCoverageWrapper;
