import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepperNavigation from '../StepperNavigation';
import FormSelector from '../FormSelector';
import useFormProgress from './UseFormProgress';
import formService from '../../../../Common/Services/FormService';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  validationTriggered, getElements, APIRequestParallel, APIRequestParallelMFA, APIRequestAction,
  onLoginAPI,
} from '../../../../Store/Slices/Entities/response/index';
import moment from 'moment';
import './styles.css';
import Modal from '../../../Molecules/Modal';
import { reduxConstants } from '../../../../Common/Constants/index';
import SectionHeader from '../../../Molecules/SectionHeader';
import Image from '../../../Atoms/Image';
import errorFile from '../../../../Assets/Images/iconError.svg';
import ModalContainer from '../../../Molecules/ModalContainer';
import { useHistory } from 'react-router-dom';
import { HIDE_CONTINUE_BUTTON, HIDE_BACK_BUTTON } from '../../../../Store/Slices/StepForm';
import LoaderWrapper from '../LoaderWrapper';
import { SHOW_CONTENT_LOADER } from '../../../../Store/Slices/Loader';
import { apiReceived } from '../../../../Store/Slices/Auth/loggedUserEntity';
import APIRequest from '../../../Utils/Helper/APIHelper';
import { CipherUtils } from '../../../Utils/Helper/MFAHelper';
import { SET_MFA_LOGIN, SET_MFA_RETRIEVE_LOGIN, SET_USER_TYPE_LANGUAGE, SET_MFA_NEW_LOGIN } from '../../../../Store/Slices/Registeration';
import {
  apiReceivedMFA,
  apiReceivedUserTypeAndLanguage,
  CHANGE_REMOTE_USER,
  CONFIG_SSO_LOGIN,
  processTypeMFA,
  SET_AUTHENTICATED_USER,
} from '../../../../Store/Slices/Auth/userAuth';
import { processAuthTokenType } from '../../../../Store/Slices/AuthToken/userAuthToken';
import http from '../../../../Common/Services/HttpService/index';
import { Alert } from 'bootstrap';
import { CHANGE_SUCCESS, REQUEST_CHANGE_EMAIL, SEND_CODE_MOBILE_CLICKED, SHOW_AFTER_SEND_CODE, SHOW_SPECIAL } from '../../../../Store/Slices/CallCenter';
import processData from '../../../../App/Molecules/ProductTable/processData';
import { UPDATE_INSURED, UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo/index';
import iconError from '../../../../Assets/Images/iconError.png'; // Directly import the image


import { onLoadAPI } from '../../../../Store/Slices/Entities/response/index.js';
import appConfig from '../../../../appConfig';
const FormGenerator = (props) => {
  //const count = useSelector((state) => state.counter.count);
  const {
    layoutData = {},
    deleteRecord = () => { },
    editRecord = () => { },
    onCloseModal = () => { },
    onChangeCheckBox = () => { },
    showContent = [],
    displayContent = {},
    modalTitle = '',
  } = props;


  let history = useHistory();

  const dispatch = useDispatch();

  const [didRun, setDidRun] = useState(false);
  const [skipAndGo, setSkipAndGo] = useState([]);
  let [skipSteps, setSkipSteps] = useState([]);

  // setTest(authData.data)
  const [currentStep, goForward, goBack, goBackStep, checkStatus, cancel] = useFormProgress();

  let [content, setLayoutContent] = useState(props.content);
  const { layout = {} } = content || {};
  const [contentData, SetContentData] = useState({
    formData: {},
    steps: {},
    stepsList: {},
    stepKeys: [],
    goForward,
    goBack,
    cancel,
    goBackStep,
    checkStatus,
    formsList: {},
    loadingCheck: false,
    isStepFormSubmitted: false,
    isSearchForm: false,
    innerFormContent: {},
    style: {},
  });
  const { userType, language } = useSelector((storeState) => storeState.registeration);
  const {
    legalEntityId = '',
    appCode = '',
    basicAuthorizationValue = '',
    token = '',
    login = '',
    decryptedEmail = '',
    mfaLoginToken = '',
  } = useSelector((storeState) => storeState.registeration);
  const { isSubmitLoading } = useSelector((storeState) => storeState.entities.response);
  const { data: userAuthData, clientId: selectedClientId, callCenterAdmin=false, configuratorSSOLogin=false  } = useSelector(
    (storeState) => storeState.auth.userAuth
  );
  const { clientName = 'test', clientTitle } = useSelector((storeState) => storeState.callCenter);
  const { FirstName = 'test', Name = 'test' ,EmployeeId=''} = useSelector((storeState) => storeState.callCenter.selectedEmployeeData);
  const { callCenter } = useSelector((storeState) => storeState);
  const { registeration } = useSelector((storeState) => storeState);
  const { loadingCheck } = contentData || {};
  const { stepForm } = useSelector((storeState) => storeState);
  let { isAPICallInProgress } = useSelector((storeState) => storeState.entities.response);
  const { registerationError } = useSelector((storeState) => storeState);
  const enrollmentBuid = useSelector(storeState => storeState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID) ?? null;

  const { loader } = useSelector(({ loader }) => loader);
  const { mulitpleFormValidation } = useSelector((storeState) => storeState.entities.response);
  const { stepId } = useSelector(({ stepForm }) => stepForm);

  const currentBenifitendorsementProcessBuid = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.initEndorsementProcessData?._Result?.processEntity?.buid
  );
  const adminEndorsementProcessBuid = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.AdminEndorsementProcess?._Result?.processEntity?.buid
  );
  const qleEndorsementProcessBuid = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.initNewIndividualEndorsementProcess?._Result?.processEntity?.buid
  );

  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};
  const enrollmentType = useSelector((storeState) => storeState?.enrollment?.enrollmentType);
  let currentReduxState = useSelector((storeState) => storeState);
  const updatedEnrollmentType = useSelector(
    (storeState) => storeState?.employeeProfile?.enrollmentType
  );
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);
  const typesOfEnrollements = useSelector(
    (storeState) => storeState?.employeeProfile?.enrollmentType
  );
  const reasonType = useSelector(
    (storeState) => storeState?.employeeProfile?.reasonType
  );
  const currentBenifitButton = useSelector(
    (storeState) => storeState?.employeeProfile?.curreneBenifitButton
  );

  const { contactType = '', sendEmailCode = false } = useSelector((storeState) => storeState.callCenter);

  const [maxLength, setMaxLength] = useState();
  const [data, setData] = useState();

  let userTypeFromStorage = localStorage.getItem("userType");
  let languageFromStorage = localStorage.getItem("language");
  // const [k, setk] = useState(false);


  useEffect(() => {
    setLayoutContent(props.content);
  }, [props.content]);

  // Clear loader after navigation step change
  useEffect(() => {
    if (loader === false) {
      setTimeout(() => {
        dispatch(SHOW_CONTENT_LOADER(false));
      }, 2000);
    }
  }, [stepId, loader]);

  useEffect(() => {
    let tempStepKeys = [];
    let isSearch = false;
    for (let key in content.forms) {
      //replace as DynamicComponent for individual loading
      // if(!content.forms[key].skipStep)
      tempStepKeys = [...tempStepKeys, key];
      let displayMode = (content.forms[key] && content.forms[key].displayMode) || '';
      SetContentData((prevContentData) => {
        if (content.layout && content.layout.formType && content.layout.formType === 'searchForm') {
          isSearch = true;
        }
        return {
          ...prevContentData,
          formData: { ...prevContentData.formData, [key]: {} },
          stepKeys: [...tempStepKeys],
          displayMode: { ...prevContentData.displayMode, [key]: displayMode },
          isSearchForm: { isSearch },
        };
      });
    }
  }, [content.forms, content.layout]);

  useEffect(() => {
    /* use effect to get forms which contains element properties */
    const callForms = async () => {
      await getForms();
    };
    callForms();
  }, [content.forms]);

  /** Fix skip to last step for Reset Password from - security Setting */
  let isResetPasswordForm = (window.location.pathname.indexOf('retrievePassword') !== -1)
  let isChangeMFAContactForm = (window.location.pathname.indexOf('changeMFAContact') !== -1)

  useEffect(() => {
    // Check your condition here
    if (!didRun && enrollmentType == "openEnrollment" && contentData && (!isResetPasswordForm || !isChangeMFAContactForm)) {
      // If the condition is met, navigate to the last step
      const lastIndex = contentData.stepKeys.length - 1;
      if (lastIndex > 1) {
        contentData.goBackStep(lastIndex);
        setDidRun(true);
      }
    }

  }, [enrollmentType, contentData, didRun]);






  useEffect(() => {
    let isCallCenterUser = window.location.pathname.indexOf('callcenter') !== -1  ;
    let isEnrollmentUser = window.location.pathname.indexOf('enrollment') !== -1;
    let isHrCenter = window.location.pathname.indexOf('hrcenter') !== -1;
    let isConfigUser = window.location.pathname.indexOf('configurator') !== -1  ;

    let isMFAFunctionalities =
      stepForm.currentStep === 'EmployeeValidation' ||
      stepForm.currentStep === 'retrievePassword' ||
      stepForm.currentSte === 'retrieveUserID'
    if (isCallCenterUser) {
      dispatch(SET_USER_TYPE_LANGUAGE({
        userType: 'CallCenter',
        language: languageFromStorage
      }));
      http.setUserType('CallCenter')
      http.setAuthTokenUserType('CallCenter');
      dispatch(processTypeMFA({ userType: 'callCenter'}))
      dispatch(processAuthTokenType({ authTokenType: 'callCenter'}))
    } else if (isConfigUser) {
      dispatch(SET_USER_TYPE_LANGUAGE({
        userType: 'Configurator',
        language: languageFromStorage
      }));
      dispatch(processTypeMFA({ userType: 'Configurator',
      language: languageFromStorage}))
      dispatch(processAuthTokenType({ authTokenType: 'Configurator'}))
      http.setAuthTokenUserType('Configurator');
      http.setUserType('Configurator')
    } 
    else if (isEnrollmentUser) {
      http.setUserType('Enrollment')
      dispatch(SET_USER_TYPE_LANGUAGE({
        userType: 'Enrollment',
        language: languageFromStorage
      }));
    }
    else if (isHrCenter) {
      http.setUserType('HrCenter')
      dispatch(SET_USER_TYPE_LANGUAGE({
        userType: 'HrCenter',
        language: languageFromStorage
      }));
    }
    else if (isMFAFunctionalities) {
      http.setUserType('')
      dispatch(SET_USER_TYPE_LANGUAGE({
        userType: '',
        language: languageFromStorage
      }));
    }
    //submitMFAToken1()
  }, [window.location.pathname])
  useEffect(() => {
    const tempSkipSteps = []
    contentData.stepKeys?.forEach((key, index) => {
      if (content.forms[key].skipStep) {
        tempSkipSteps.push(index);
      }
    })
    setSkipSteps(tempSkipSteps);
  }, [content.forms, contentData.stepKeys]);



  useEffect(() => {

  }, [contactType]);
  useEffect(() => {

  }, [callCenter.requestChangeEmail, callCenter.sendOTPEmail])

  const defaultTypeValues = { bool: false, string: '' };
  const getKeyValuePairs = (elements) => {
    /* function to get keyValuePairs forom elements */
    let nameValuePairs = {};
    let nameValidationPairs = {};
    for (let elementRows in elements) {
      for (let elementCols in elements[elementRows]) {
        const element = elements[elementRows][elementCols];
        const { name, value, valueType, validations } = element;
        nameValuePairs = {
          ...nameValuePairs,
          [name]: value ? value : valueType ? defaultTypeValues[valueType] : '',
        };
        nameValidationPairs = { ...nameValidationPairs, [name]: validations };
      }
    }
    return { nameValuePairs, nameValidationPairs };
  };

  const updateFormData = (formName, key, updatedValue, isDelete = false) => {
    /* function to update formData */
    SetContentData((prevContentData) => {
      let prevFormData = { ...prevContentData.formData };
      let selectedForm = { ...prevFormData[formName] };
      if (isDelete) {
        delete selectedForm[key];
      } else {
        selectedForm = { ...selectedForm, [key]: updatedValue };
      }
      prevFormData = {
        ...prevFormData,
        [formName]: selectedForm,
      };

      return {
        ...prevContentData,
        formData: prevFormData,
      };
    });
  };

  //Client Id to send ad payload for getForms
  let clientId = useSelector(
    (storeState) =>
      storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.legalEntity?.identifier
        ?.internalClientId
  );



  const getForms = async (formName) => {
    /* function to get forms from node using formData */
    let forms = {};
    let innerForm = {};
    let index = -1;
    let tempSkipSteps = [];
    for (let itemKey in content.forms) {
      index++;
      if (content.forms[itemKey].skipStep) {
        tempSkipSteps.push(index);
      }

      if (content.forms[itemKey] && content.forms[itemKey] && content.forms[itemKey].innerForm) {

      } else {
        let clientIdByLegalEntity = null
        if (legalEntityId) {
          clientIdByLegalEntity = legalEntityId.split(":")[0]
        }
        forms = await dispatch(
          getElements(
            content.forms[itemKey].formName || itemKey,
            forms,
            (content.layout && content.layout.formLayoutType) || '',
            SetContentData,
            '',
            props.currentLanguage,
            '',
            clientIdByLegalEntity || clientId
          )
        );
        if (content.forms[itemKey].formName) {
          forms[itemKey] = { ...forms[content.forms[itemKey].formName] };
        }
      }
    }
    setSkipSteps(tempSkipSteps);
    for (let itemKey in content.forms) {
      if (forms && forms[itemKey]) {
        for (let elementRow in forms[itemKey].elements) {
          for (let elementCol in forms[itemKey].elements[elementRow]) {
            if (forms[itemKey].elements[elementRow][elementCol].value) {
              SetContentData((prevContentData) => {
                let tempContentFormData = { ...prevContentData.formData };
                tempContentFormData[itemKey] = {
                  ...tempContentFormData[itemKey],
                  [forms[itemKey].elements[elementRow][elementCol].name]:
                    forms[itemKey].elements[elementRow][elementCol].value,
                };
                return {
                  ...prevContentData,
                  formData: {
                    ...prevContentData.formData,
                    ...tempContentFormData,
                  },
                };
              });
            }
          }
        }
        // Code to add elements from modals
        if (forms[itemKey].modals) {
          forms[itemKey].modals.forEach((modal, indexModal) => {
            modal.modalElements.forEach((modalElement, indexModalElement) => {
              for (let elementModalRow in modalElement) {
                for (let elementModalCol in modalElement[elementModalRow]) {
                  if (modalElement[elementModalRow][elementModalCol].value) {
                    SetContentData((prevContentData) => {
                      let tempContentFormData = { ...prevContentData.formData };
                      tempContentFormData[itemKey] = {
                        ...tempContentFormData[itemKey],
                        [modalElement[elementModalRow][elementModalCol].name]:
                          modalElement[elementModalRow][elementModalCol].value,
                      };
                      return {
                        ...prevContentData,
                        formData: {
                          ...prevContentData.formData,
                          ...tempContentFormData,
                        },
                      };
                    });
                  }
                }
              }
            });
          });
        }
      }
    }
    SetContentData((prevContentData) => ({
      ...prevContentData,
      formsList: { ...forms },
      innerFormContent: { ...innerForm },
    }));
  };

  let [eee, SetEee] = useState({});

  useEffect(() => {
    /* use effect to form yupper and other formik name pair */
    const updateEee = () => {
      for (let itemKey in contentData.stepKeys) {
        let currentStep = contentData.stepKeys[itemKey];
        if (
          Object.keys(contentData.formsList).length > 0 &&
          currentStep &&
          ((content.forms && content.forms[currentStep] && content.forms[currentStep].innerForm) ||
            currentStep.includes('AddInsured'))
        ) {
          let lnyepper = {};
          let lnValuePair = {};
          let lnValidationPair = {};
          Object.keys(contentData.formsList[currentStep]).forEach((innerForm) => {
            let elements = contentData.formsList[currentStep][innerForm]
              ? contentData.formsList[currentStep][innerForm].elements
              : null;
            let isMultiple = contentData.formsList[currentStep][innerForm]
              ? contentData.formsList[currentStep][innerForm].isMultiple
              : false;
            let tempVals = getKeyValuePairs(elements)['nameValuePairs'];
            let tempValidations = getKeyValuePairs(elements)['nameValidationPairs'];
            let tempYup = {};

            //loops through each element
            for (let indElement in tempValidations) {
              // loops through the validation type: string, number, date, array
              for (let validationType in tempValidations[indElement]) {
                let indYupper = Yup[validationType]();

                // loops through the validation function: required, min, max, regex
                for (let indValidation in tempValidations[indElement][validationType]) {
                  if (validationType === 'string') {
                    if (indValidation === 'required') {
                      indYupper = indYupper[indValidation](
                        tempValidations[indElement][validationType][indValidation]
                      );
                    }
                    if (indValidation === 'validationRequired') {
                      indYupper = indYupper.when(
                        tempValidations[indElement][validationType][indValidation]['value'],
                        {
                          is: (value) => value === 'true' || value === true,
                          then: Yup.string(),
                          otherwise: indYupper['required'](
                            tempValidations[indElement][validationType][indValidation]['message']
                          ),
                        }
                      );
                    }

                    if (indValidation === 'validationRequiredOnTrue') {
                      indYupper = indYupper.when(
                        tempValidations[indElement][validationType][indValidation]['value'],
                        {
                          is: (value) => value === 'true' || value === true,
                          then: indYupper['required'](
                            tempValidations[indElement][validationType][indValidation]['message']
                          ),

                          otherwise: Yup.string(),
                          //  otherwise: indYupper['required'](tempValidations[indElement][validationType][indValidation]["message"]),
                        }
                      );
                    }
                    if (indValidation === 'matches') {
                      indYupper = indYupper[indValidation](
                        tempValidations[indElement][validationType][indValidation]['regex'],
                        tempValidations[indElement][validationType][indValidation]['message']
                      );
                    }

                    if (indValidation === 'oneOf') {
                      indYupper = indYupper[indValidation](
                        tempValidations[indElement][validationType][indValidation]['ref'],
                        tempValidations[indElement][validationType][indValidation]['message']
                      );
                    }

                    if (indValidation === 'min' || indValidation === 'max') {
                      indYupper = indYupper[indValidation](
                        tempValidations[indElement][validationType][indValidation]['limit'],
                        tempValidations[indElement][validationType][indValidation]['message']
                      );
                    }
                    if (indValidation === 'email') {
                      indYupper = indYupper[indValidation](
                        tempValidations[indElement][validationType][indValidation]
                      );
                    }
                  }
                  // date validations
                  if (validationType === 'date') {
                    if (indValidation === 'required') {
                      indYupper = indYupper[indValidation](
                        tempValidations[indElement][validationType][indValidation]
                      );
                    }
                    if (indValidation === 'min' || indValidation === 'max') {
                      let date = moment().add(
                        tempValidations[indElement][validationType][indValidation]['limit'],
                        tempValidations[indElement][validationType][indValidation]['unit']
                      );
                      indYupper = indYupper[indValidation](
                        date,
                        tempValidations[indElement][validationType][indValidation]['message']
                      );
                    }
                  }
                }

                tempYup = {
                  ...tempYup,
                  [indElement]: indYupper,
                };
              }
            }

            let multipleTempVal = [],
              multipleTempValidations = [],
              mulTempValidations = { ...tempValidations },
              mulTempVal = { ...tempVals };
            if (isMultiple) {
              multipleTempVal = [...multipleTempVal, { ...mulTempVal }];
              multipleTempValidations = [...multipleTempValidations, { ...mulTempValidations }];
            }
            lnValuePair = {
              ...lnValuePair,
              [innerForm]: isMultiple ? [...multipleTempVal] : { ...tempVals },
            };

            lnValidationPair = {
              ...lnValidationPair,
              [innerForm]: isMultiple ? [...multipleTempValidations] : { ...tempValidations },
            };
            lnyepper = {
              ...lnyepper,
              [innerForm]: isMultiple
                ? Yup.array().of(Yup.object().shape({ ...tempYup }))
                : Yup.object().shape({ ...tempYup }),
            };
          });

          SetEee((prevEeee) => {
            let nameValuePairs = { ...prevEeee.nameValuePairs };
            let nameValidationPairs = { ...prevEeee.nameValidationPairs };
            let yupper = { ...prevEeee.yupper };

            yupper = {
              ...yupper,
              [currentStep]: Yup.object().shape({ ...lnyepper }),
            };
            nameValuePairs = {
              ...nameValuePairs,
              [currentStep]: { ...lnValuePair },
            };

            nameValidationPairs = {
              ...nameValidationPairs,
              [currentStep]: { ...lnValidationPair },
            };

            return {
              ...prevEeee,
              nameValuePairs: { ...nameValuePairs },
              nameValidationPairs: { ...nameValidationPairs },
              yupper: { ...yupper },
            };
          });
        } else {
          let elements = contentData.formsList[currentStep]
            ? contentData.formsList[currentStep].elements
            : null;
          const modalElements = [];
          if (contentData.formsList[currentStep] && contentData.formsList[currentStep].modals) {
            contentData.formsList[currentStep].modals.forEach((modal) => {
              modal.modalElements.forEach((element) => {
                modalElements.push(...element);
              });
            });
          }

          if (modalElements.length > 0) {
            elements.push(...modalElements);
          }
          let isMultiple = contentData.formsList[currentStep]
            ? contentData.formsList[currentStep].isMultiple
            : false;
          let tempVals = getKeyValuePairs(elements)['nameValuePairs'];
          let tempValidations = getKeyValuePairs(elements)['nameValidationPairs'];
          let tempYup = {};

          //loops through each element
          for (let indElement in tempValidations) {
            // loops through the validation type: string, number, date, array
            for (let validationType in tempValidations[indElement]) {
              let indYupper = Yup[validationType]();

              // loops through the validation function: required, min, max, regex
              for (let indValidation in tempValidations[indElement][validationType]) {
                // string validations

                if (validationType === 'string') {
                  if (indValidation === 'required') {
                    indYupper = indYupper[indValidation](
                      tempValidations[indElement][validationType][indValidation]
                    );
                  }
                  if (indValidation === 'validationRequired') {
                    indYupper = indYupper.when(
                      tempValidations[indElement][validationType][indValidation]['value'],
                      {
                        is: (value) => value === 'true' || value === true,
                        then: Yup.string(),
                        otherwise: indYupper['required'](
                          tempValidations[indElement][validationType][indValidation]['message']
                        ),
                      }
                    );
                  }
                  if (indValidation === 'validationRequiredOnTrue') {
                    indYupper = indYupper.when(
                      tempValidations[indElement][validationType][indValidation]['value'],
                      {
                        is: (value) => value === 'true' || value === true,
                        then: indYupper['required'](
                          tempValidations[indElement][validationType][indValidation]['message']
                        ),

                        otherwise: Yup.string(),
                        //  otherwise: indYupper['required'](tempValidations[indElement][validationType][indValidation]["message"]),
                      }
                    );
                  }

                  //Check if passoword and confirm password matches

                  if (tempValidations[indElement][validationType][indValidation][0]) {
                  }

                  if (indValidation === 'oneOf') {
                  }

                  if (indValidation === 'matches') {
                    indYupper = indYupper[indValidation](
                      tempValidations[indElement][validationType][indValidation]['regex'],
                      tempValidations[indElement][validationType][indValidation]['message']
                    );
                  }

                  if (indValidation === 'oneOf') {
                    indYupper = indYupper[indValidation](
                      [
                        Yup.ref(tempValidations[indElement][validationType][indValidation]['ref']),
                        null,
                      ],

                      tempValidations[indElement][validationType][indValidation]['message']
                    );
                  }

                  if (indValidation === 'min' || indValidation === 'max') {
                    indYupper = indYupper[indValidation](
                      tempValidations[indElement][validationType][indValidation]['limit'],
                      tempValidations[indElement][validationType][indValidation]['message']
                    );
                  }
                  if (indValidation === 'email') {
                    indYupper = indYupper[indValidation](
                      tempValidations[indElement][validationType][indValidation]
                    );
                  }
                }
                // date validations
                if (validationType === 'date') {
                  if (indValidation === 'required') {
                    indYupper = indYupper[indValidation](
                      tempValidations[indElement][validationType][indValidation]
                    );
                  }
                  if (indValidation === 'min' || indValidation === 'max') {
                    let date = moment().add(
                      tempValidations[indElement][validationType][indValidation]['limit'],
                      tempValidations[indElement][validationType][indValidation]['unit']
                    );
                    indYupper = indYupper[indValidation](
                      date,
                      tempValidations[indElement][validationType][indValidation]['message']
                    );
                  }
                }
              }

              if (skipAndGo.includes(currentStep) || !content.forms[currentStep].skipAndGo) {
                tempYup = {
                  ...tempYup,
                  [indElement]: indYupper,
                };
              }
            }
          }

          SetEee((prevEeee) => {
            let nameValuePairs = { ...prevEeee.nameValuePairs };
            let nameValidationPairs = { ...prevEeee.nameValidationPairs };
            let yupper = { ...prevEeee.yupper };

            let multipleTempVal = [],
              multipleTempValidations = [],
              mulTempValidations = { ...tempValidations },
              mulTempVal = { ...tempVals };
            if (isMultiple) {
              multipleTempVal = [...multipleTempVal, { ...mulTempVal }];
              multipleTempValidations = [...multipleTempValidations, { ...mulTempValidations }];
            }

            nameValuePairs = {
              ...nameValuePairs,
              [currentStep]: isMultiple ? [...multipleTempVal] : { ...tempVals },
            };

            nameValidationPairs = {
              ...nameValidationPairs,
              [currentStep]: isMultiple ? [...multipleTempValidations] : { ...tempValidations },
            };
            yupper = {
              ...yupper,
              [currentStep]: isMultiple
                ? Yup.array().of(Yup.object().shape({ ...tempYup }))
                : Yup.object().shape({ ...tempYup }),
            };

            return {
              ...prevEeee,
              nameValuePairs: { ...nameValuePairs },
              nameValidationPairs: { ...nameValidationPairs },
              yupper: { ...yupper },
            };
          });
        }
      }
    };

    const eeeUpate = async () => {
      await updateEee();
    };
    eeeUpate();
  }, [
    contentData.formsList,
    contentData.formData,
    contentData.stepKeys,
    mulitpleFormValidation,
    // elementColor,
    content,
    props.currentLanguage,
  ]);
  // get remote_user from login api resp
  const getRemoteUserFromResponse = (response) => {
    if (response) {
      let requiredValue = 'remote_user';
      let params = response.split('&');
      for (let i = 0; i < params.length; i++) {
        let value = params[i].split('=');
        if (value[0] === requiredValue) {
          return value[1];
        }
      }
      return false;
    }
  };
  const submitMFAToken = async (redirectionUrl) => {
    let remote_user = getRemoteUserFromResponse(redirectionUrl);
    let decrypted;
    let value = decodeURIComponent(remote_user);

    if (remote_user) {
      try {
        decrypted = await CipherUtils.decrypt(value, process.env.REACT_APP_SSP_PRIVATE_KEY);
      } catch (err) {
        console.error('crypto support is disabled!');
      }
      const email = decrypted.slice(4);
      dispatch(SET_MFA_LOGIN({ decryptedEmail: decrypted, email: email }));
      await dispatch(apiReceivedMFA(decrypted));
      /** Second API call - getUserBUID */
      const userName = { loginName: decrypted };
      await dispatch(apiReceivedUserTypeAndLanguage({ userType: userType, language: language }));
      // let authPreRequest = dispatch(
      //   APIRequestAction({ userName }, { apiconfig: { url: '/getLoginUser' } })
      // );
      // // if (!authPreRequest) {
      // //   setLoginError(true);
      // // }
      // authPreRequest.then(async (result) => {
      //   let data = {};
      //   result = result?._Result;
      //   Object.assign(data, { person: result });
      //   /** store reponse object in store */
      //   await dispatch(apiReceived(data));
      //   await dispatch(apiReceivedUserTypeAndLanguage({ userType: userType, language: language }));
      //   let authReqData;
      //   authReqData = result && result ? { buid: result.bUID } : {};
      //   /** Third API call - getUserDetaile */
      //   let loggedUserEntity = await dispatch(
      //     APIRequestAction({ authReqData }, { apiconfig: { url: '/getEmployments' } })
      //     // APIRequestAction({ authReqData }, { apiconfig: { url: '/getEmployments' } })
      //   );
      //   let onLoginApiCalls = [
      //     {
      //       apiconfig: {
      //         url: '/gbpForLegalEntity',
      //         APIfn: 'getAPIResponse',
      //         objectName: 'gbpForLegalEntity',
      //         saveInRedux: true,
      //       },
      //     },
      //   ];
      //   Object.assign(data, { personDetails: loggedUserEntity });
      //   if (onLoginApiCalls) {
      //     await APIRequest(onLoginApiCalls, { loggedUserEntity }).then((data) => {
      //       if (data) {
      //         dispatch(onLoginAPI(data));
      //       }
      //     });
      //   }
      //   // if (!loggedUserEntity) {
      //   //   setLoginErrorMFA(true);
      //   // }
      //   /** store reponse object in store */
      //   await dispatch(apiReceived(data));
      //   // setUserAuthenticated(true);
      // });
    }
  };

  const submiForm = async (values, innerForm, url) => {
    let apiUrl = '';
    if (innerForm) {
      apiUrl = url;
      /* function to usen user submit form */
      let tempData = await formService.submitForm(
        { values: values, loggedUserEntity: loggedUserEntity },
        userAuthData.access_token,
        SetContentData,
        apiUrl
      );

      if (tempData && (tempData['_Result'] || tempData['result'] || tempData.message)) {
        setData(tempData);
      }
    }
    //Form submit multiple api calls
    else if (
      props.content &&
      props.content.layout &&
      props.content.layout.onSubmitApiCall &&
      // Object.keys(loggedUserEntity).length > 0
      /** Updated by Nisreen according to new structure of loggedUserEntity object */
      // loggedUserEntity &&
      //   loggedUserEntity.data &&
      //   Object.keys(loggedUserEntity.data).length > 0
      ((loggedUserEntity &&
        loggedUserEntity.data &&
        Object.keys(loggedUserEntity.data).length > 0) || props.content.layout.isRegisterationApiCall)
    ) {
      // let onSubmitCalls = props.content.layout.onSubmitApiCall;
      let callSendOTP = callCenter.steps.length > 1 && callCenter.steps[callCenter.steps.length - 1] === 'otp'
      let callChangeEmail = !callCenter.requestChangeEmail && !callSendOTP;

      if (!callCenter.sendCodeMobileClicked && callCenter.otpCodeSent && stepForm.currentStep === 'ContactDetails' && contactType === 'mobile') {
        dispatch(SEND_CODE_MOBILE_CLICKED(true));
      }



      let onSubmitCalls = !props.content.dynamicSubmit || (contactType === 'mobile' && callCenter.otp) ?
        props.content.layout.onSubmitApiCall : // normal api call
        props.content.dynamicSubmit && contactType === 'email' ?
          callChangeEmail ?
            props.content.layout.onSubmitApiCallEmail : //1
            callSendOTP ?
              props.content.layout.onSubmitApiCallSendCodeEmail : '' ://2
          ''
      let callParams = { values: values, loggedUserEntity };

      if (onSubmitCalls.length && onSubmitCalls[0]?.apiconfig?.type === "configurator") {
        SetContentData((prevContentData) => ({
          ...prevContentData,
          loadingCheck: false,
          isStepFormSubmitted: true,
        }));
      }
      if (
        onSubmitCalls.length &&
        (onSubmitCalls[0]?.apiconfig?.isLoginConfiguration ||
          onSubmitCalls[0]?.apiconfig?.isHeaderConfiguration ||
          onSubmitCalls[0]?.apiconfig?.isDependentConfigInfo ||
          onSubmitCalls[0]?.apiconfig?.isEmployeeProfileConfig ||
          onSubmitCalls[0]?.apiconfig?.isCreateRetriveInfoConfig ||
          onSubmitCalls[0]?.apiconfig?.isCreateWelcomeConfig ||
          onSubmitCalls[0]?.apiconfig?.isCreateProductConfig ||
          onSubmitCalls[0]?.apiconfig?.isBeneficiaryConfigInfo)
      ) {
        callParams['additionalInfo'] = { clientId: selectedClientId };



        if (
          onSubmitCalls[0]?.apiconfig?.isBeneficiaryConfigInfo ||
          onSubmitCalls[0]?.apiconfig?.isEmployeeProfileConfig ||
          onSubmitCalls[0]?.apiconfig?.isCreateRetriveInfoConfig ||
          onSubmitCalls[0]?.apiconfig?.isCreateProductConfig ||
          onSubmitCalls[0]?.apiconfig?.isCreateWelcomeConfig ||
          onSubmitCalls[0]?.apiconfig?.isDependentConfigInfo
        ) {
          delete callParams.loggedUserEntity;
          callParams.loggedUserEntity = {
            data: {
              _Result: {
                clientId: {
                  internalClientId: selectedClientId,
                },
              },
            },
          };
        }
      }

      let apiResponse;
      if (!onSubmitCalls[0].callsForMFA)
        apiResponse = await dispatch(APIRequestParallel(onSubmitCalls, callParams));
      else {
        // mfa call 
        callParams['legalEntityId'] = legalEntityId;
        callParams['appCode'] = appCode;
        callParams['basicAuthorizationValue'] = basicAuthorizationValue;
        callParams['token'] = mfaLoginToken || token;
        callParams['email'] = registeration.newEmail || '';
        callParams['otp'] = callCenter.otp;

        apiResponse = await dispatch(APIRequestParallelMFA(onSubmitCalls, callParams));
      }

      let redirectToSSP = apiResponse && apiResponse[onSubmitCalls[0]?.apiconfig?.objectName]?.data?.redirectionUrl && appCode === 'ssp';
      let redirectToPPA = apiResponse && apiResponse[onSubmitCalls[0]?.apiconfig?.objectName]?.data?.redirectionUrl && appCode !== 'ssp';
      let success =
        apiResponse &&
        apiResponse[onSubmitCalls[0].apiconfig.objectName].status === 200 &&
        !redirectToSSP &&
        onSubmitCalls[0].callsForMFA;
      //show otp input for change email
      if (apiResponse[onSubmitCalls[0].apiconfig.objectName].status === 200 &&
        (onSubmitCalls[0].apiconfig.showSpecial) &&
        stepForm.currentStep === 'ContactDetails') {
        dispatch(SHOW_SPECIAL(true))
        dispatch(REQUEST_CHANGE_EMAIL(true))// show otp input and tooltip and timer
        dispatch(SHOW_AFTER_SEND_CODE(true))
      }

      if (apiResponse[onSubmitCalls[0].apiconfig.objectName].status === 200 &&
        stepForm.currentStep === 'ContactDetails') {
      
        if(contactType === 'email' && onSubmitCalls[0].apiconfig.changeEmail){
          dispatch(CHANGE_REMOTE_USER(process.env.REACT_APP_LOGIN_PREFIX+registeration.newEmail))
          dispatch(SET_AUTHENTICATED_USER(registeration.newEmail))
          dispatch(SET_MFA_LOGIN({ decryptedEmail: registeration.newEmail, email: registeration.newEmail }));
          dispatch(SET_MFA_NEW_LOGIN({ LOGIN: registeration.newEmail }));
        }
      }

      //mfa login
      //SK change

      const yourFunction = async () => {
        if (redirectToSSP) {

          try {
            await submitMFAToken(apiResponse[onSubmitCalls[0].apiconfig.objectName].data.redirectionUrl);
            const urlFromResponse = apiResponse[onSubmitCalls[0].apiconfig.objectName].data.redirectionUrl.
              substring(0, apiResponse[onSubmitCalls[0].apiconfig.objectName].data.redirectionUrl.indexOf('?'));
            const splittedUrl = urlFromResponse.split('/');
            let welcomePageUrl = splittedUrl[splittedUrl.length - 2] + '/' + splittedUrl[splittedUrl.length - 1];
            history.push(welcomePageUrl);
          } catch (error) {
            console.error('An error occurred:', error);
            // Handle the error appropriately
          }
        }
      }
      yourFunction();



      if (redirectToPPA) {
        const urlFromResponsePPA = apiResponse[onSubmitCalls[0].apiconfig.objectName].data.redirectionUrl
        urlFromResponsePPA && window.open(urlFromResponsePPA, '_self');
      }

      // if (success && (onSubmitCalls[0]?.apiconfig?.emailOtp  || onSubmitCalls[0].apiconfig.mobileOtp ||onSubmitCalls[0].apiconfig.resetPassword)) {
      if (success && !onSubmitCalls[0]?.apiconfig?.hideSuccessMessage) { //hide success msg on change email API- MFA ONLY
        dispatch(CHANGE_SUCCESS(true))
        //return login
        let objectName = onSubmitCalls[0]?.apiconfig?.objectName;
        objectName === 'MFAForgotUsername' &&
          dispatch(SET_MFA_RETRIEVE_LOGIN(apiResponse?.MFAForgotUsername.data.login));

        let directObject = {};
        onSubmitCalls.forEach((apiCalls) => {
          let { objectName } = apiCalls.apiconfig || {};
          let curObj = { [objectName]: apiResponse[objectName] };
          directObject = { ...directObject, ...curObj };
        });
        SetContentData((prevContentData) => ({
          ...prevContentData,
          loadingCheck: false,
          isStepFormSubmitted: true,
        }));
        if (apiResponse.data || apiResponse.data.message) {
          setData(apiResponse.data);
        }

        setData({
          ...SetContentData,
          loadingCheck: false,
          isStepFormSubmitted: true,
        });
      }
      if (apiResponse?.data?.message || apiResponse?.MFAMultiFactorAuthentication.message) {
        let directObject = {};
        onSubmitCalls.forEach((apiCalls) => {
          let { objectName } = apiCalls.apiconfig || {};
          let curObj = { [objectName]: apiResponse[objectName] };
          directObject = { ...directObject, ...curObj };
          //  props.setResult(directObject);
        });
        SetContentData((prevContentData) => ({
          ...prevContentData,
          loadingCheck: false,
          isStepFormSubmitted: true,
        }));
        if (apiResponse.data || apiResponse.data.message) {
          setData(apiResponse.data);
        }
      }
      else if (!props.content.layout.isRegisterationApiCall) {
        let directObject = {};
        onSubmitCalls.forEach((apiCalls) => {
          let { objectName } = apiCalls.apiconfig || {};
          let curObj = { [objectName]: apiResponse[objectName] };
          directObject = { ...directObject, ...curObj };
          //  props.setResult(directObject);
        });

        SetContentData((prevContentData) => ({
          ...prevContentData,
          loadingCheck: false,
          isStepFormSubmitted: true,
        }));

        setData({
          ...SetContentData,
          loadingCheck: false,
          isStepFormSubmitted: true,
        });

        if (apiResponse || apiResponse.message) {
          // need to confirm after success response
          setData(apiResponse);
        }
      }
      else {
        let directObject = {};
        onSubmitCalls.forEach((apiCalls) => {
          let { objectName } = apiCalls.apiconfig || {};
          let curObj = { [objectName]: apiResponse[objectName] };
          directObject = { ...directObject, ...curObj };
          //  props.setResult(directObject);
        });

        SetContentData((prevContentData) => ({
          ...prevContentData,
          loadingCheck: false,
          isStepFormSubmitted: true,
        }));

        setData({
          ...SetContentData,
          loadingCheck: false,
          isStepFormSubmitted: true,
        });
      }
    }

    else {
      apiUrl = (layout.formSubmit && layout.formSubmit.url) || '';
      // if( !callCenter.sendCodeMobileClicked && callCenter.otpCodeSent &&  stepForm.currentStep=== 'ContactDetails' && contactType==='mobile'){
      // dispatch(SEND_CODE_MOBILE_CLICKED(true));
      // }
      /* function to usen user submit form */

      let tempData = await formService.submitForm(
        { values: values, loggedUserEntity: loggedUserEntity },
        userAuthData.access_token,
        SetContentData,
        apiUrl
      );
      if (tempData && (tempData['_Result'] || tempData['result'] || tempData.message)) {
        setData(tempData);
      }
      if (tempData?.status && (tempData.status === 200 || tempData.status === 201)
        && layout?.formSubmit?.type === "configurator") {
        SetContentData((prevContentData) => ({
          ...prevContentData,
          loadingCheck: false,
          isStepFormSubmitted: true,
        }));
      }

    }
  };

  //make a check before this and add the values from redux before this
  const formik = useFormik({
    //function to make the submit call based on teh form kind
    // validateOnBlur:false,
    enableReinitialize: true,
    validationDisabled: true,
    initialValues: { ...eee.nameValuePairs, ...props.formData },
    validationSchema: Yup.object(eee.yupper),
    onSubmit: (values) => submiForm(values),
  });

  useEffect(() => {
    dispatch(validationTriggered());
  }, [skipAndGo.length]);

  useEffect(() => {
    formik.setValues(formik.values);
  }, [mulitpleFormValidation]);

  // const currentForm = content && content.layout && content.layout.formLayoutType || '';
  const currentForm =
    (contentData && contentData.stepKeys && contentData.stepKeys[currentStep]) || '';
  const formElements =
    (contentData &&
      contentData.formsList &&
      contentData.formsList[currentForm] &&
      contentData.formsList[currentForm].elements) ||
    [];
  const listElements = (contentData &&
    contentData?.formsList &&
    contentData?.formsList[currentForm] &&
    contentData?.formsList[currentForm]?.listElements?.elements) || ['dependentTitle'];

  const iFrameElements = (contentData &&
    contentData?.formsList &&
    contentData?.formsList[currentForm] &&
    contentData?.formsList[currentForm]?.iFrameElements?.elements);
  const modals =
    (contentData &&
      contentData.formsList &&
      contentData.formsList[currentForm] &&
      contentData.formsList[currentForm].modals) ||
    (contentData &&
      contentData.formsList &&
      contentData.formsList[currentForm] &&
      contentData.formsList[currentForm].listElements &&
      contentData.formsList[currentForm].listElements.modals) ||
    [];
  const { values = {}, errors = {}, touched = {} } = formik;
  //To hide the stepForm continue and back Button in ProductSelection
  // Hide from product selection screens

  let blockStepButtonOnThisSteps = ["ProductDetailsSelection", "DependentInformation"];
  const hideButtons = () => {
    if (content?.forms && content?.forms[currentForm]?.productFamily || blockStepButtonOnThisSteps.includes(currentForm)) {
      dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: true }));
      dispatch(HIDE_BACK_BUTTON({ hideBackBtn: true }));
    }
  }


  useEffect(() => {
    hideButtons();
  }, [currentForm]);

  const formikValues = useMemo(() => {
    return values[currentForm];
  }, [values && values[currentForm]]);

  const formikErrors = useMemo(() => {
    return errors[currentForm];
  }, [errors && errors[currentForm]]);

  const formikTouched = useMemo(() => {
    return touched[currentForm];
  }, [touched && touched[currentForm]]);
  let activeScreenElements = [];
  const [stepModal, setStepModal] = useState(0);
  const modalStep = useSelector((storeState) => storeState?.disableButton?.modalStep);


  useEffect(() => {
    setStepModal(modalStep);
  }, [modalStep]);









  let compList = [];
  let tempModalElements = modals[0]?.modalElements[0];
  let tempModalElementsVideo = modals[1]?.modalElements[1];
  let modalName1 = modals[0]?.modalName;
  let modalName2 = modals[1]?.modalName;

  if (modalStep == 0 && tempModalElements?.length > 0) {


    compList.push(
      tempModalElements[0],
      tempModalElements[1],
      tempModalElements[2],
      tempModalElements[3],
      tempModalElements[23],
    );
  }

  if (modalStep == 1 && tempModalElements?.length > 0) {

    // let compList = [];
    compList.push(
      tempModalElements[4],
      tempModalElements[5],
      tempModalElements[6],
      tempModalElements[7],

      tempModalElements[24]
    );
  }

  if (modalStep == 2 && tempModalElements?.length > 0) {

    // let compList = [];
    compList.push(
      tempModalElements[7],
      tempModalElements[8],
      tempModalElements[9],
      tempModalElements[10],
      tempModalElements[11],
      tempModalElements[12],
      tempModalElements[13],
      tempModalElements[14],
      tempModalElements[15],
      tempModalElements[16],
      tempModalElements[17],
      tempModalElements[18],
      tempModalElements[19],
      tempModalElements[20],
      tempModalElements[21],
      tempModalElements[22],

      tempModalElements[25]
    );
  }

  // // compList.splice(0,0,tempModalElements[0],tempModalElements[1],tempModalElements[2],tempModalElements[3])

  let obj = {};
  obj['title'] = 'Add a Pet';
  obj['modalDisplayPetElement'] = 'addPetValidations';
  obj['modalName'] = 'addPet';
  obj['modalElements'] = [compList];

  activeScreenElements[0] = obj;

  activeScreenElements[0] = obj;



  // const ModalComponent = useMemo(() => {
  //   return (
  //     modals.length > 0 && (
  //       <ModalContainer
  //         modals={modals}
  //         formName={currentForm}
  //         content={contentData}
  //         formik={formik}

  //         onCloseModal={onCloseModal}
  //         modalTitle="Add a Pet"
  //       />
  //     )
  //   );
  // }, [modalStep]);


  const ModalComponent = useMemo(() => {
    let selectedModalElements = false;
    //Change this step moving the pet Nation order
    if (activeScreenElements.length > 0 && currentForm == "PetAssureNation") {
      selectedModalElements = true;
    }
    if (selectedModalElements) {
      return (
        modals.length > 0 && (
          <ModalContainer
            modals={activeScreenElements}
            formName={currentForm}
            content={content}
            formik={formik}
            onCloseModal={onCloseModal}
            modalTitle={modalTitle}
          />
        )
      );
    } else {
      return (
        modals.length > 0 && (
          <ModalContainer
            modals={modals}
            formName={currentForm}
            content={content}
            formik={formik}
            onCloseModal={onCloseModal}
            modalTitle={modalTitle}
          />
        )
      );
    }
  }, [modals.length, formikValues, formikErrors, formikTouched, modalStep]);

  let errorFound = '';
  const filename = { errorFile };

  let imgSrc;
  try {
    imgSrc = iconError;
  } catch (e) {
    imgSrc = '';
  }
  let currentYear = new Date().getFullYear()
  const enrollmentDuringOE = typesOfEnrollements === "editNewEnrollmentDuringOE2" && reasonType === "NH" // window open and enroll still days remaining  can edit 
  const enrollmentDuringOEClosed = typesOfEnrollements === "editNewEnrollmentDuringOE" && reasonType === "OE" // window close  cancel covearge and edit the evergereen 
  const case9 = typesOfEnrollements === "case9" && reasonType === "OE" // only evergreen scenarions
  const openreenrollment = typesOfEnrollements === "open-reenrollment" && reasonType === "AE"
  const annualEnrollemtwindowOpen = typesOfEnrollements === "editEnrollmentDuringOE" && reasonType === "AE" && currentBenifitButton === 'undefined.reviewUpdate'  // window open and enroll still days remaining  can edit 
  const anualEnrollmentCurrentBenifit = (typesOfEnrollements === 'editEnrollmentDuringOE' && reasonType === 'AE' && currentBenifitButton === 'undefined.currentYearReviewUpdate') || (typesOfEnrollements === 'open-reenrollment' && reasonType === 'AE' && currentBenifitButton === 'undefined.currentYearReviewUpdate')

  const showAlert = () => {
    return (
      <div className="alert alert-danger alert-dismissible fade show" role="alert">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span
            aria-hidden="true"
            style={{ color: '#002C77', fontSize: '0.875rem', fontFamily: 'NotoSansMedium' }}
          >
            CLOSE
          </span>
        </button>
        {/* <Image filename={filename} alt='alt'/> */}
        <img src={imgSrc} alt="alt" className="img-fluid pr-2 " />
        You have exceeded the maximum number (3) of attempts. Please contact (800)-226-3000 for
        further assistance..
      </div>
    );
  };
  //contentData && 
  let isSearchEmployeeForm =   window.location.pathname.indexOf('callcenter/employeeSearch') !== -1
  let shouldAddMargin = callCenterAdmin && isSearchEmployeeForm

  return (
    <div className="container-fluid">
      {props.showTitle && (
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12  py-4 mt-2 px-md-5">
            <h2 className="title-text">{formik.values && formik.values.legalEntityName}</h2>
          </div>
        </div>
      )}
      {
        !props.content.hideSectionHeader && !props.content.dynamicSectionHeader && (
          <div className="row">
            <SectionHeader
              title={
                content?.forms &&
                content?.forms[currentForm]?.displayName &&
                (['PetPlus', 'Whole Life', 'Pet Assure', 'Identity Theft', 'Legal Coverage', 'Travel', 'Hospital Indemnity', 'Critical Illness', 'Excess Liability'].includes(content?.forms[currentForm]?.displayName)
                  ? content?.forms[currentForm]?.headerTitle
                  : currentBenifitendorsementProcessBuid && ((enrollmentDuringOEClosed && currentBenifitButton === 'undefined.reviewUpdate') || case9 || anualEnrollmentCurrentBenifit || (currentBenifitButton === 'undefined.reviewUpdate' && openreenrollment)) && content?.forms['EnrollmentSummary'] ? content?.forms['EnrollmentSummary']?.currentBenifit
                    : currentBenifitendorsementProcessBuid && enrollmentDuringOE && content?.forms['EnrollmentSummary'] ? `Enrollment Summary`
                      : currentBenifitendorsementProcessBuid && ((annualEnrollemtwindowOpen) || (openreenrollment && currentBenifitButton === 'undefined.Get Started')) && content?.forms['EnrollmentSummary'] ? `OpenEnrollement ${currentYear + 1}`
                        : qleEndorsementProcessBuid && content?.forms['EnrollmentSummary'] ? content?.forms['EnrollmentSummary']?.qle
                          : adminEndorsementProcessBuid && content?.forms['EnrollmentSummary'] ? content?.forms['EnrollmentSummary']?.currentBenifit
                            : content?.forms[currentForm]?.displayName)
              }
              // change the above condition
              style={content.style}
              breadcrumbs={content.breadcrumbs}
              breadcrumbsStepForm={content?.layout?.sectionHeader?.breadcrumbs}
              filename={content.filename}
              specialHeaderWidth={content.specialHeaderWidth}
              className={content.className}
            />
          </div>
        )
        //removed px-md-5 in stepFormWrapper class for alignment issue in MFA
      }
      {  //dynamic section header
        props.content.dynamicSectionHeader && clientTitle && (
          <div className="row">
            <SectionHeader
              title={ currentForm === 'EmployeeData' ? `${FirstName} ${Name} - ${EmployeeId}` : clientTitle}
              style={content.style}
              breadcrumbs={content.breadcrumbs}
              breadcrumbsStepForm={content?.layout?.sectionHeader?.breadcrumbs}
              filename={content.filename}
              specialHeaderWidth={content.specialHeaderWidth}
              className={content.className}
            />
          </div>
        )
        //removed px-md-5 in stepFormWrapper class for alignment issue in MFA
      }
      <div className="row">
        <div
          className={`
        col-sm-9 col-md-12  
        ${layout.formType === 'searchForm' ? 'searchFormWrapper' : 'stepFormWrapper '}
        ${layout.lifeEvent === true ? 'col-lg-12' : ''}
        ${enrollmentType == "openEnrollment" ? 'col-lg-12' : layout.formClassName || 'col-lg-9'}
        ${shouldAddMargin ? ' mx-0 px-5 ' : ''}
    `}
        >
          {
            <>
              <form onSubmit={formik.handleSubmit}>
                <LoaderWrapper>
                  <FormSelector
                    {...props}
                    contentData={contentData}
                    currentStep={currentStep}
                    handleSubmit={formik.handleSubmit}
                    formik={formik}
                    updateFormData={updateFormData}
                    SetContentData={SetContentData}
                    eee={eee}
                    data={data}
                    maxLength={maxLength}
                    setMaxLength={setMaxLength}
                    skipAndGo={skipAndGo}
                    setSkipAndGo={setSkipAndGo}
                    skipSteps={skipSteps}
                    setSkipSteps={setSkipSteps}
                    setLayoutContent={setLayoutContent}
                    submiForm={submiForm}
                    layoutData={layoutData}
                    currentForm={currentForm}
                    formElements={formElements}
                    listElements={listElements}
                    iFrameElements={iFrameElements}
                    deleteRecord={deleteRecord}
                    editRecord={editRecord}
                    onChangeCheckBox={onChangeCheckBox}
                    showContent={showContent}
                    displayContent={displayContent}
                  />
                </LoaderWrapper>
              </form>
            </>
          }
        </div>
        {/* <div>
          {modals.map((modal) => {
            const modalElements = [];
            modal.modalElements.forEach((element) => {
              modalElements.push(...element);
            });
            return (
              <Modal
                key={`modal-${modal.modalName}`}
                formik={formik}
                contentData={contentData}
                {...props}
                elements={modalElements}
                formName={currentForm}
                onClose={() => {
                  let key = currentForm + '.' + modal.modalDisplayDependentElement;
                  let value = formik?.values[currentForm][modal.modalDisplayDependentElement];
                  formik && formik.setFieldValue(key, !value);
                }}
                modelData={{
                  elements: modalElements,
                  modalId: modal.modalName,
                  modalTitle: modal.title,
                  modalDisclaimer: modal.disclaimer,
                  modalDescription: modal.description,
                  modalDisplayDependentElement: modal.modalDisplayDependentElement,
                  modalDialog: 'modalDialog',
                }}
              />
            );
          })}
        </div> */}
        {ModalComponent}
        <div>
          {content &&
            content.layout &&
            content.layout.modals &&
            content.layout.modals.map((modal) => {
              return <Modal {...props} modelData={modal} />;
            })}
        </div>
        {(layout.showNavigation &&
          !isSubmitLoading &&
          !loadingCheck &&
          !props.skipForm &&
          !isAPICallInProgress && !(enrollmentType === "openEnrollment") && (
            <div
              className={
                contentData.apiError ||
                  !contentData.isStepFormSubmitted ||
                  (contentData?.stepKeys?.length &&
                    contentData.stepKeys[currentStep] &&
                    contentData.innerFormContent &&
                    contentData.innerFormContent[contentData.stepKeys[currentStep]])
                  ? 'col-sm-3  col-lg-3 d-none d-lg-block col-lg-pull-3 pr-3 side-content  bs-vertical-wizard'
                  : 'd-none'
              }
            >
              <StepperNavigation
                stepKeys={contentData.stepKeys}
                goBackStep={goBackStep}
                checkStatus={checkStatus}
                currentStep={currentStep}
                formik={formik}
                {...props}
              />
            </div>
          )) ||
          null}
      </div>
    </div>
  );
};
export default FormGenerator;