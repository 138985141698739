import React, { useStat, memo } from 'react';
import Profile from "../Component/Profile/Profile.js"
import SearchClientList from '../../SearchClientList';


const ConfiguratorManageClientSearch = memo((props) => {


  return (
    <div>
       <SearchClientList {...props}></SearchClientList>
    </div>
  );
});
export default ConfiguratorManageClientSearch;
