import React, { useStat, memo } from 'react';
import Dependent from "../Component/Dependent/Dependent.js"


import Loader from '../../../Atoms/Loader';
import { useDispatch, useSelector } from 'react-redux';

const DependentManagement = memo((props) => {

  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);

  return (
    <div>
      {
      isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />      
      } 
       <Dependent {...props}></Dependent>
    </div>
  );
});
export default DependentManagement;
