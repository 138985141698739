import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Banner, Checkbox, Button, Linker } from '../../Atoms';
import Panel from '../../Molecules/Panel/Panel';
import './styles.css';
import { callBearer, callRecaptchaValidator, setProcessType } from '../../../Store/Slices/Auth/userAuth';
import { processAuthTokenType } from '../../../Store/Slices/AuthToken/userAuthToken';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import APIRequest from '../../Utils/Helper/APIHelper';
import { onLoginAPI, onLoadAPI } from '../../../Store/Slices/Entities/response/index';
import { SHOW_LOADER } from '../../../Store/Slices/Loader/index';
import { updateEnvironment } from '../../../Store/Slices/Auth/userAuth/index';
import dataService from '../../../Common/Services/DataService';
import { APIRequestAction } from '../../../Store/Slices/Entities/response';
import { apiReceived } from '../../../Store/Slices/Auth/loggedUserEntity/index';
import Loader from '../../Atoms/Loader';
import { useSelector } from 'react-redux';
import http from '../../../Common/Services/HttpService/index';
import $ from 'jquery';
import Recaptcha from '../../Molecules/Recaptcha';
import appConfig from '../../../appConfig';
import {  CLEAR_REGISTERATION, SET_MFA_API_PARAM, SET_MFA_BASIC_AUTH_VALUE } from '../../../Store/Slices/Registeration';
import { CLEAR_SSN, SET_SSN_VALUE } from '../../../Store/Slices/ControlSSN';
import loginImage from '../../../Assets/Images/login_image.png'; // Directly import the image

const OldCustomlogin = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const { userAuth } = useSelector((storeState) => storeState.auth);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: false,
    environment: '',
  });
  const [content, setContent] = useState({
    title: '',
    subtitle: '',
    panelTitle: '',
    welcomeMessage: '',
    welcomeTitle: '',
    banner: {},
    icon: '',
    authUrl: '',
    redirectUrl: '',
    helpLinks: [],
    inputPlaceHolder: '',
    passwordPlaceHolder: '',
    buttonLable: '',
    rememberMeLable: '',
    titleColor: '',
    msgColor: '',
  });
  const [showLoader, setShowLoader] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [username, setUsername] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [invalidEmailClass, setinvalidEmailClass] = useState('');
  const [invalidLogin, setinvalidLogin] = useState('');
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [logo, setLogo] = useState('');
  const recaptchaErrorMessage = 'Something went wrong during login, try again';
  const [clientLogo, setClientLogo] = useState(false);
  const recaptchaRef = React.useRef({});
  const { clientId } = useSelector((storeState) => storeState.auth.userAuth);
  const { loginConfigInfoLogo, loginConfigInfo } = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList);
  const callLoginConfigAPI = async () => {
    if(!clientId) return
    return await http
      .get(appConfig.SSP_MID_URL + `/v1/config/${clientId}`)
      .then(async (resp) => {
        const data = resp?.data?.data
        for (const key in data) {
          let result = { _Result: data[key].data }
          dispatch(onLoadAPI({ [key]: result }));

        }
        return true;
      })
      .catch((e) => {
        return false;
      });
  };

  useEffect(() => {
    if(!loginConfigInfo) callLoginConfigAPI()
  }, [!loginConfigInfo]);

  useEffect(() => {
    const getLoingConfig = async () => {
      try {
        // const { data: result } = await http.get(
        //   `${appConfig.SSP_MID_URL}/loginConfiguration/${clientId}`
        // );
        const result = loginConfigInfo?._Result
        let contentData = props.content[props.type];
        var clientLogodata = result?.hideLogo;
        setClientLogo(clientLogodata);
        if (contentData) {
          if (result.welcomeTitle) {
            contentData['welcomeTitle'] = result.welcomeTitle;
          }
          if (result.welcomeMsg) {
            contentData['welcomeMessage'] = result.welcomeMsg;
          }
          if (result.titleColor) {
            contentData['titleColor'] = result.titleColor;
          }
          if (result.msgColor) {
            contentData['msgColor'] = result.msgColor;
          }
        }

        setContent(contentData);
      } catch (e) {
        setContent(props.content[props.type]);
      }
    };
    getLoingConfig();
  }, [props.content,loginConfigInfo]);

  useEffect(() => {
    const getLogo = async () => {
      try {
        // const { data: result } = await http.get(
        //   `${appConfig.SSP_MID_URL}/loginConfiguration/logo/${clientId}`
        // );
        const result = loginConfigInfoLogo?._Result
        setLogo(result);
      } catch (e) {
        
      }
    };
    getLogo();
  }, [loginConfigInfoLogo]);
  dispatch(CLEAR_SSN(true));
  dispatch(SET_SSN_VALUE(''));
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    });
    if (event.target.name == 'username') setUsername(event.target.value);
    const regexEmailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(
      $('#username').val()
    );
    setIsValidEmail(regexEmailFormat);
    setinvalidEmailClass(!regexEmailFormat ? '#A61003' : '#DADADA');
  };
  // get param from uri- mfa
  const GetUrlParams = () => {
    const authResult = new URLSearchParams(window.location.search);
    const legalEntityId = authResult.get('legalEntityId');
    const appCode = authResult.get('appCode');
    let paramObject = {
      legalEntityId: legalEntityId,
      appCode: appCode,
    };
    legalEntityId && appCode && dispatch(SET_MFA_API_PARAM(paramObject))
    return paramObject
  };
  GetUrlParams()

  //vreate header auth - mfa
  const getBasicAuthHeaderValue = () => {
    let authValue = process.env.REACT_APP_MFA_AUTH_USER;
    
    // Encoding into base64 using btoa
    let basicAuthHeaderValue = authValue ? btoa(authValue): '';
    
    return basicAuthHeaderValue;
};


  let basicAuthorizationValue = getBasicAuthHeaderValue();
  basicAuthorizationValue && dispatch(SET_MFA_BASIC_AUTH_VALUE(basicAuthorizationValue));
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    // const captchaToken = await recaptchaRef.current.executeAsync();
    // if (captchaToken) {
    //   const recaptchaResponse = await dispatch(
    //     callRecaptchaValidator(captchaToken)
    //   );
    //   const successCaptcha = recaptchaResponse && recaptchaResponse.data && recaptchaResponse.data._Result && recaptchaResponse.data._Result.success;
    //   if ( successCaptcha ) {
    setRecaptchaError(false);
    /* function to submit login credentials and to call userEntity */
    setShowLoader(true);
    dispatch(SHOW_LOADER(true));
    setLoginError(false);
    http.setUserType(props.type);
    http.setLanguage(currentLanguage);
    await dispatch(
      updateEnvironment({
        userType: props.type,
      })
    );
    const bearerResponse = await dispatch(
      callBearer({
        loginDetails: {
          login: formData.username,
          password: formData.password,
        },
        userType: props.type,
        environment: formData.environment,
      })
    );
    setinvalidLogin(!bearerResponse ? '#A61003' : '');
    setinvalidEmailClass(!bearerResponse ? '#A61003' : '#DADADA');
    dispatch(setProcessType('oldEnrollment'))

    let authReqData = {};
    const userName = { [content.userKey]: username };

    if (content.authPreUrl && bearerResponse) {
      // setIsValidLogin(true)
      /** Second API call - getUserBUID */
      let authPreRequest = dispatch(
        APIRequestAction(
          { userName },
          { [content.authPreApiconfig]: { [content.authPreKey]: content.authPreUrl } }
        )
      );

      if (!authPreRequest) {
        setLoginError(true);
      }
      authPreRequest.then(async (result) => {
        let data = {};
        result = result._Result;
        Object.assign(data, { person: result });
        /** store reponse object in store */
        await dispatch(apiReceived(data));
        authReqData = result && result ? { [content.authKey]: result[content.authReqElement] } : {};
        /** Third API call - getUserDetaile */
        let loggedUserEntity = await dispatch(
          APIRequestAction(
            { authReqData },
            { [content.authPreApiconfig]: { [content.authPreKey]: content.authUrl } }
          )
        );

        Object.assign(data, { personDetails: loggedUserEntity });
        if (content.onLoginAPICalls) {
          await APIRequest(content.onLoginAPICalls, { loggedUserEntity }).then((data) => {
            if (data) {
              dispatch(onLoginAPI(data));
            }
          });
        }
        if (!loggedUserEntity) {
          setLoginError(true);
        }
        dispatch(processAuthTokenType({ authTokenType: 'oldEnrollment'}))
        /** store reponse object in store */
        await dispatch(apiReceived(data));
        setUserAuthenticated(true);
      });
    } else {
      setLoginError(true);
      setUserAuthenticated(false);
      setShowLoader(false);
      dispatch(SHOW_LOADER(false));
    }
    // } else {
    //   setRecaptchaError(true);
    //   recaptchaRef.current.reset();
    // }
    // }

    // else if(captchaToken && props.type == "Configurator"){

    // }
  };

  const {
    title,
    subtitle,
    panelTitle,
    welcomeMessage,
    welcomeTitle,
    banner = {},
    icon,
    titleColor,
    msgColor,
  } = content || {};

  useEffect(() => {
    if (userAuthenticated) {
      if (userAuth && userAuth.data && userAuth.data.access_token && !loginError) {
        history.push(content.oldRedirectUrl);
      }
      setShowLoader(false);
      setLoginError(true);
      setUserAuthenticated(false);
    }
  }, [userAuthenticated]);

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  const isDisabled = !(!!formData['username'] && !!formData['password'] && !!isValidEmail);

  return (
    // <Banner desktop={banner.desktop} tablet={banner.tablet}>
    <div className="container-fluid" >
      <div className="row">
        <div className="col-lg-7 col-xl-7 d-none d-md-block px-0 ">
        
          <img
        src={loginImage}
        alt="Four Employees Enrolling"
        className="img-responsive"
        width="100%"
        height="100%"
        id="employees-erolling-client-logo"
      />
          <div className="row overlay-content px-3 mx-5 pt-3 ">
            <div className="col-12">
              <div style={{ color: titleColor ? titleColor : '#0065ac' }} className="bannerTitle">
              {welcomeTitle}
              </div>
            </div>
            <div className="col-12 ">
              <p style={{ color: msgColor ? msgColor : '#202020' }} className="bannerBody">
                {welcomeMessage}
              </p>
            </div>
          </div>
          {/* <Panel
            className="panel d-flex flex-column align-items-center justify-content-between rounded px-3 py-4 "
            title={welcomeTitle}
            body={welcomeMessage}
            // img={icon}
            // hasDivider={false}
          ></Panel> */}
        </div>

        {/* </div> */}
        {/* <div class="col-lg-1 col-xlg-1 col-md-1"></div> */}
        <div className="col-lg-5">
          {' '}
          <form
          // role="form"
          aria-label="form"
            onSubmit={handleSubmit}
            className="offset-xl-1  offset-md-2 offset-lg-0 col-md-8 col-lg-11 d-flex flex-column"
          >
            <div className="row pb-3 px-0 ">
              <div className="col-12 pt-0 pb-3 px-0 ">
                <div className="row text-center ">
                  {!clientLogo && logo && (
                    <div className="col-12 ">
                      <img
                        src={logo ? logo : ""}
                        alt="Client logo"
                        className="img-responsive pt-3 logo-fixed-size"
                        id="client-logo"
                      />
                    </div>
                  )}

                  {title && (
                    <div className="col-12 ">
                      <h3 className="loginHeader ">{title}</h3>
                    </div>
                  )}
                  <div className="col-12 px-0 ">
                    <div className="devider "></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              {' '}
              <div className="col-12   ">
                <h1 className="logInTitle  mb-3 mt-4  ">{subtitle}</h1>
              </div>
              <div className="col-12 ">
                <p className="logInTitleDevider mb-4 "></p>
              </div>
              <div className="col-12 ">
                {/* <span className="inputLable">User ID</span> */}
                <label htmlFor="username" className="inputLable">User ID</label>
                <input
                  id="username"
                  name="username"
                  onChange={handleChange}
                  value={formData.username}
                  placeholder={(content && content.inputPlaceHolder) || ''}
                  className={
                    isValidEmail
                      ? ` w-100 inputField my-3 p-3  `
                      : `w-100 inputField mt-3 mb-1 p-3  `
                  }
                  style={{ borderColor: invalidEmailClass }}
                />
              </div>
              {!isValidEmail && (
                <div className="col-12 mb-3 ">
                  <span style={{ color: invalidLogin || invalidEmailClass }}>
                    {content.emailFormatError}{' '}
                  </span>
                </div>
              )}
              <div className="col-12 ">
                {/* <span className="inputLable">Password</span> */}
                <label htmlFor="password" className="inputLable">Password</label>
                <input
                  id="password"
                  name="password"
                  onChange={handleChange}
                  value={formData.password}
                  placeholder={(content && content.passwordPlaceHolder) || ''}
                  className="w-100 inputField my-2 p-3  "
                  type="password"
                  style={{ borderColor: invalidLogin }}
                />
              </div>
              {loginError && (
                <div className="col-12 mb-3 ">
                  <span style={{ color: invalidLogin }}>{content.loginError} </span>
                </div>
              )}
            </div>
            <div className="row py-2 ">
              {content &&
                content.helpLinks &&
                content.helpLinks.length > 0 &&
                content.helpLinks.map((currentLink) => {
                  return (
                    <Linker
                      to={currentLink.to}
                      key={currentLink.text}
                      className={`${currentLink.className} currentLink`}
                    >
                      {currentLink.text}
                    </Linker>
                  );
                })}
            </div>
            <div className="row ">
              {recaptchaError && (
                <div className="col-12">
                  <span style={{ color: 'rgb(244, 67, 54)' }}>{recaptchaErrorMessage}</span>
                </div>
              )}
              <div className="col-12 ">
                <button
                role="button"
                  className="btn  btn-primary-contrast btn-block my-3 loginContainerButton inputHeight"
                  type="submit"
                  disabled={isDisabled}
                >
                  {(content && content.buttonLable) || ''}
                </button>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-8 px-lg-1 px-xl-0 col-8 col-md-8 col-sm-8 py-3"></div> */}
              <div className="col-lg-8 mx-lg-0 px-xl-3 col-8 col-md-8 col-sm-8 py-3 ">
                <div className=" additionalInfoFirst ">{content?.additionalInfoFirst}</div>
                <div className=" additionalInfoSecond ">{content?.additionalInfoSecond}</div>
              </div>
              <div className="col-lg-4 col-4 col-md-4 col-sm-3 py-3 ">
                <Button
                  className="w-100 "
                  outline
                  role="button"
                  type="submit"
                  data-target={'#' + '/userRegistration'}
                  data-toggle={'/userRegistration'}
                  onClick={() => {
                    dispatch(CLEAR_REGISTERATION({ email: '', phone: '' }));
                    history.push('/userRegistration');
                  }}
                >
                  Register
                </Button>
              </div>
            </div>
            {process.env.REACT_APP_RECAPTCHA_AVAILIBLE === 'true' ? (
              <Recaptcha
                recaptchaRef={recaptchaRef}
                siteKey={appConfig.RECAPTCHA_KEY}
                isVisible={false}
              />
            ) : (
              ''
            )}
          </form>
          <div>
            {/* <Linker className="btn btn-primary" to="/userRegistration">
                      {(content && content.buttonLabel) || ''}
                    </Linker> */}
          </div>
        </div>
        {/* <div class="col-lg-1 col-xlg-1 col-md-1"></div> */}
      </div>
    </div>
    // </Banner>
  );
};
OldCustomlogin.propTypes = {
  type: PropTypes.string,
};

export default OldCustomlogin;
