import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PolicyReplacementQuestions from '../Component/PolicyReplacementQuestions/PolicyQuestions';
import { useNavigationHook } from '../Hooks/NavigationHook'; 

import {UPDATE_POLICY_REPLACEMENT_QUESTIONS }
  from '../../../../Store/Slices/InsuredInfo/index.js';
const PolicyReplacementQuestionsContainer = (props) => {
  const { currentStep} = useNavigationHook();
  let { personBuid = "", subscribedCoverageConfigurationResponse  ={}  } = props || {};

  let policyReplacementData = subscribedCoverageConfigurationResponse;
  let policyReplacementQuestions = policyReplacementData?.additionalDatas?.filter(eachObj => eachObj.fieldCode !== "PlanDesign");



    const dispatch = useDispatch();
    const CriticalIllness = useSelector(  
      state => state.insuredInfo.CriticalIllness
    );
   
    const handleUpdatePolicyReplacementQuestions = updatedFormValues => {
      dispatch(UPDATE_POLICY_REPLACEMENT_QUESTIONS({updatedFormValues , currentStep }));
    };

    const filteredQuestions = policyReplacementQuestions?.filter(
     // (question) => question?.fieldCode !== 'PlanDesign' && question?.fieldCode !== 'DD_00177'
     // Need to check with Deepak - sk
     (question) => question?.fieldCode !== 'PlanDesign'
    );

    return (
      filteredQuestions && filteredQuestions.length > 0?<PolicyReplacementQuestions
        policyReplacementQuestions={policyReplacementQuestions}
        onUpdatePolicyReplacementQuestions={handleUpdatePolicyReplacementQuestions}
        initialFormValues={CriticalIllness.policyReplacementQuestions}
      />:""
    );
  };
  
  export default PolicyReplacementQuestionsContainer;
  