import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../Cells/Header';
import Footer from '../../Cells/Footer';
import LiveChat from '../../Cells/LiveChat';
import Tabs from '../../Molecules/HRCenter/Components/Tabs/Tabs'
import { useLocation } from 'react-router-dom';
export const PrivateRoute = ({ component: Component, render: Render = null, ...rest }) => {
  const userToken = useSelector((storeState) => storeState?.auth?.userAuth?.data?.access_token);
  const { userAuth } = useSelector((storeState) => storeState?.auth);
  const isHrCenter = userAuth?.userType === "HrCenter" ? true : false
  const loginConfigInfo = useSelector(
    (state) => state?.entities?.response?.onLoadResponseList?.loginConfigInfo?._Result
  );
  let location = useLocation();
  const restrictedPaths = [
    '/hrcenter',
    '/hrcenter/employeeSearch',
    '/hrcenter/reports',

  ];
  const shouldShowConsentBanner = restrictedPaths.includes(location.pathname);
  return (
    <React.Fragment>
      <div className="pt-1" style={{ minHeight: '100%' }}>
        <Header />

        {isHrCenter && shouldShowConsentBanner && (
          <Tabs />
        )}

        <Route
          {...rest}
          render={(props) =>
            //localStorage.getItem('user')
            userToken ? (
              Render ? (
                Render(props)
              ) : (
                <>
                  <Component {...props} />
                  {userAuth?.callCenterAdmin || isHrCenter || loginConfigInfo?.showChat ? '' : <LiveChat />}
                </>
              )
            ) : (
              <Redirect to={{ pathname: '/enrollment', state: { from: props.location } }} />
            )
          }
        />
      </div>
      {userToken && !userAuth.SSOLoginError && <Footer />}
    </React.Fragment>
  );
};

export default PrivateRoute;
