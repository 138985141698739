import React ,{useState,useEffect}from 'react';
// import "./styles.css";
import { useSelector } from 'react-redux';
import { Styles } from './styles';
import Pdf from '../../../../Assets/Files/California_Resident_Privacy_Notice.pdf';
import SectionHeader from '../../../Molecules/SectionHeader';
import {  Linker } from '../../../Atoms';
import {useHistory} from 'react-router-dom';

const PrivacyStatement = () => {
  const history = useHistory();
  const [content, setContent] = useState({});
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => setContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);
  let titleClassName = content.staticPages.titleClassName;
  let subTitleClassName = content.staticPages.subTitleClassName;
  let contentClassName = content.staticPages.contentClassName;
  let wrapperClassName = content.staticPages.wrapperClassName;

  let handleClose = () => {
    window.close();
  };
  let size = 'width='+window.screen.width+',height='+window.screen.height; 
  let openWindow = (newWindow) => {
    const updatedWindow = process.env.PUBLIC_URL ?  process.env.PUBLIC_URL + newWindow : newWindow;
    window.open(updatedWindow,'_blank', size)
  }
  
  return (
    <React.Fragment>
      <Styles />
      <div className='container-fluid '>
        <div className= 'row '>
          <div className='col-12 ' >
            <div className='row  '>
              <div className='col-11 px-0 ' >
                <SectionHeader title="Privacy Notice"  current="Privacy Notice" />
              </div>
              <div className='  col-1 btnClass border-bottom  ' >
                <button type="button" className="close pr-lg-2 "  aria-label="Close" onClick={()=>handleClose()}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>

     
      <div className={`col-12 pt-4 px-3 px-md-5  px-lg-5 ${wrapperClassName} `}>
        <div className='row ' >
          {content && content?.staticPages?.privacyStatement?.map((element) => {
            return (
              <>
                <div className='col-12 text-break ' key={element.id} >
                  <div className={`row ${titleClassName} `}>
                    <div className='col-12 '>
                      {element.title} 
                    </div>
                  </div >
                    {element?.subTitle && 
                    <div className={`row ${subTitleClassName} `}>
                      <div className='col-12 '>
                        {element.subTitle}
                      </div>
                    </div>
                    }
                    {element?.content && 
                    <div className={`row ${contentClassName} `}>
                      <div className='col-12 '>
                        {element.content.split(" ").map(text => {
                          return text === "here" ? 
                          // <a  href={Pdf}  without rel="noopener noreferrer" target="_blank">&nbsp;here&nbsp;
                          // </a>: " " +text+ " "
                        <Linker  onClick={() => openWindow(Pdf)} >here </Linker>
                        : " " +text+ " "
                        })}
                      </div>
                    </div>
                    }
                    {element?.subContent ? element.subContent.map((sContent) => {
                      return (
                        <div className='row content ' key={sContent.id}>
                          <div className='col-auto  px-0 mx-0 pl-3 '>{'\u2022'}{' '}</div>
                          <div className='col-11 px-0 mx-0 content pl-2'>{sContent.content}</div>
                        </div>
                      )
                    }) 
                    : ''}
                    {element.paragraphs && element?.paragraphs?.map((paragraph) => {
                      let targetTerms = paragraph.content.indexOf("$$Terms of Use$$" );
                      let targetPrivacy = paragraph.content.indexOf("privacycoordinator@mercer.com" );
                      return   (
                        <div className='row ' key={paragraph.id}>
                          <div className={`col-12 ${contentClassName}  `}>
                            {
                              paragraph.content.includes("$$Terms of Use$$")?
                                <span>{paragraph.content.substring(0,paragraph.content.indexOf("$$Terms of Use$$" ))}
                                  {/** Open link: /termsOfUse */}
                                  <Linker  to= "/termsOfUse">Terms of Use </Linker>
                                  {paragraph.content.substring(targetTerms + "$$Terms of Use$$".length, paragraph.content.length)}
                                </span>
                              :
                              paragraph.content.includes("privacycoordinator@mercer.com")?
                                <span>{paragraph.content.substring(0,paragraph.content.indexOf("privacycoordinator@mercer.com" ))}
                                  <a href="" >{"privacycoordinator@mercer.com"}
                                  </a>{paragraph.content.substring(targetPrivacy + "privacycoordinator@mercer.com".length, paragraph.content.length)}
                                </span>
                              :
                              paragraph.content
                            }
                          </div>
                        </div>
                      )
                    })}
                  
                    {element?.secondContent && 
                      <div className={`row ${contentClassName} `}>
                        <div className='col-12 '>
                          {element.secondContent}
                        </div>
                      </div>
                    }
                    {element?.thirdContent && 
                      <div className={`row ${contentClassName} `}>
                        <div className='col-12 '>
                          {element.thirdContent}
                        </div>
                      </div>
                    }
                    {element?.fourthContent && 
                      <div className={`row ${contentClassName} `}>
                        <div className='col-12 '>
                          {element.fourthContent}
                        </div>
                      </div>
                    }
                    {element?.secondSubTitle && 
                      <div className={`row ${subTitleClassName} `}>
                        <div className='col-12 '>
                          {element.secondSubTitle}
                        </div>
                      </div>
                    }
                    {element?.secondSubContent && 
                      <div className={`row ${contentClassName} `}>
                        <div className='col-12 '>
                          {element.secondSubContent}
                        </div>                     
                      </div>
                    }
                    {element.secSubParagraphs && element?.secSubParagraphs?.map((secSub) => {
                       let targetnetwork = secSub.content.indexOf("www.networkadvertising.org/managing/opt_out.asp" );
                       let targetCookie = secSub.content.indexOf("www.aboutcookies.org" );

                        return (
                          <div className='row ' key={secSub.id} >
                            <div className={`col-12 ${contentClassName} `}>
                              {
                                secSub.content.includes("www.aboutcookies.org")?
                                <span>{secSub.content.substring(0,secSub.content.indexOf("www.aboutcookies.org" ))}
                                  <a href="" >{"www.aboutcookies.org. "}
                                  </a>
                                  { secSub.content.includes("www.networkadvertising.org/managing/opt_out.asp")?
                                    <span>{secSub.content.substring(0,secSub.content.indexOf("www.networkadvertising.org/managing/opt_out.asp" ))}
                                      <a href="" >{"www.networkadvertising.org/managing/opt_out.asp"}
                                      </a>{secSub.content.substring(targetnetwork + "www.networkadvertising.org/managing/opt_out.asp".length, secSub.content.length)}
                                    </span>
                                    :
                                    secSub.content.substring(targetnetwork + "www.networkadvertising.org/managing/opt_out.asp".length, secSub.content.length)}
                                 </span>
                                :
                                secSub.content.includes("www.aboutcookies.org")?
                                  <span>{secSub.content.substring(0,secSub.content.indexOf("www.aboutcookies.org" ))}
                                    <a href=''  without rel="noopener noreferrer" target="_blank">{"www.aboutcookies.org"}
                                    </a>{secSub.content.substring(targetCookie + "www.aboutcookies.org".length, secSub.content.length)}
                                  </span>
                                :
                                secSub.content
                              }
                            </div>
                          </div>
                        )
                    })}
                                       
                    {element.matters && element?.matters?.map((matter) => {
                      let targetHere = matter.content.indexOf("$$here$$" );
                      return (
                        <>
                          <div className={`row ${subTitleClassName} `}>
                            <div className='col-12 '>
                              {matter.title}
                            </div>
                          </div>
                   
                          <div className={`row ${contentClassName} `}>
                          <div className='col-12 '>
                            {
                              matter.content.includes("$$here$$")?
                                <span>{matter.content.substring(0,matter.content.indexOf("$$here$$" ))}
                                  {/** Open pdf document */}
                                  <Linker  to= "/privacyDocument" >here </Linker>
                                  {matter.content.substring(targetHere + "$$here$$".length, matter.content.length)}                                 </span>
                              :
                              matter.content
                            }
                          </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
              </>
            );
          })}
        </div>
      </div>
      </div>
      </div>
    </React.Fragment>
  );
};

export default PrivacyStatement;