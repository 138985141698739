import React, { Suspense, useEffect, useState } from 'react';
import FormGenerator from '../../../Cells/Form/FormGenerator';
import { useDispatch, useSelector } from 'react-redux';
import {
  APIRequestParallel, onLoadAPI
} from '../../../../Store/Slices/Entities/response/index';
import { reduxConstants } from '../../../../Common/Constants/index';
import Loader from '../../../Atoms/Loader';
import formFields from '../Content/ProductConfig.json';
import { retrieveConfig } from '../Actions/retrieveConfig';
import { useFormik } from 'formik';
import { saveProfile } from '../Actions/saveConfig';
import SuccessPage from '../Common/SuccessPage';
import { CLEAR_ALERT_MESSAGES, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { MessageComponent } from '../../../Atoms';
import { getErrorMessages } from '../../../Molecules/CustomSection/Helper/MappingHelper';
import { Checkbox, TextInput, Button, Label } from '../../../Molecules/CustomSection/Atoms';
import '../styles.css';

const ProductConfig = (props) => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, SetContent] = useState({});
  const [layoutData, setLayoutData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const dispatch = useDispatch();

  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};

  let additionalInformation =
    useSelector((store) => eval(reduxConstants.GetAdditionalValues.apiPath)) || {};

  const dataFromReduxEmploymentInformation = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList
  );

  const loginuserName = useSelector((storeState) => storeState?.auth?.userAuth?.data?.user_name);

  const dataFromRedux = useSelector((storeState) => storeState?.auth?.loggedUserEntity?.data);

  let { clientId } = useSelector((storeState) => storeState.auth.userAuth);

  const configDataRedux = useSelector(
    (storeState) => {
      return storeState?.entities?.response?.onLoadResponseList?.empConfigInfo;
    }
  );
  
  let configData = {};

  try{
    configData = {
      premiumFrequencyPlaceholder: configDataRedux?.premiumFrequencyPlaceholder || false,
      premiumFrequencyPlaceholderValue: configDataRedux?.premiumFrequencyPlaceholderValue || "",
    };
  } catch(e){
    configData = {
      premiumFrequencyPlaceholder: false,
      premiumFrequencyPlaceholderValue: "",
    };
  }
  

  const formik = useFormik({
    initialValues: {      
      premiumFrequencyPlaceholder: configData?.premiumFrequencyPlaceholder,
      premiumFrequencyPlaceholderValue: configData?.premiumFrequencyPlaceholderValue,  
    },

    onSubmit: (values, { setSubmitting }) => {
      formik.resetForm();
      setSubmitting(false);
    },
    enableReinitialize: true,
  });



  const userName = { loginName: loginuserName };



  useEffect(() => {
    // //Api Call here
    setShowLoader(true)
    const callAPI = async () => {
      let response = await retrieveConfig(clientId, formFields.apiCall.onload);
      if(!response?.productConfigInformation){
        setShowSuccess(null);
        setTimeout(()=>{
          dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'getProductConfig',
            show: true,
            page: 'Configurator',
          }));
          setShowSuccess(false);
        },50);        
      }
      dispatch(
        onLoadAPI({
          empConfigInfo:  response?.productConfigInformation ? { ...response?.productConfigInformation } : {}, 
          isAPICallInProgress: false,
        })
      );
      setShowLoader(false)
    };
    try {
      callAPI();
    } catch (error) {
      
    }
  }, [loggedUserEntity]);

  const saveProductConfig = async () => {
    let data = {
      ProductInformation: {
        premiumFrequencyPlaceholder: formik?.values?.premiumFrequencyPlaceholder,
        premiumFrequencyPlaceholderValue: formik?.values?.premiumFrequencyPlaceholderValue,
      }
    };
    setShowSuccess(null);
    let saveResponse = await saveProfile(data, clientId, formFields.apiCall.submit, setShowLoader);
    if (saveResponse?.status == 200) {
      setShowSuccess(true);
    } else {            
      dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: '520',
        continue: true,
        id: 'saveProductConfing',
        show: true,
        page: 'Configurator',
      }));
      setShowSuccess(false);
    }
  };

  // useEffect(() => {
  //   const callAPI = async () => {
  //     if (content && content?.layout && content?.layout?.onLoadApiCall) {
  //       let onLoadCalls = content.layout.onLoadApiCall;

  //       await dispatch(
  //         APIRequestParallel(onLoadCalls, {
  //           additionalInformation: {
  //             clientId
  //           },
  //         })
  //       );
  //     }
  //   };
  //   try {
  //     callAPI();
  //   } catch (error) {
  //     
  //   }
  // }, [content, loggedUserEntity]);

  useEffect(()=>{
    dispatch(CLEAR_ALERT_MESSAGES([]));
  },[formik.values]);

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  const hasErrors = getErrorMessages('Configurator');

  

  return (
    <div>
      <MessageComponent currentPage={'Configurator'} setShowLoader={setShowLoader}/>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper">
          {/* <FormGenerator content={content} {...props} layoutData={layoutData} /> */}
          {!showSuccess && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="config">
                <div className="col-12 px-5">
                  <div class="row py-3 px-3">
                    <h4>Product Configuration</h4>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dataFieldProduct.label}
                        inputId={formFields.dataFieldProduct.id}
                      />                      
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editablePlaceholderLabelProduct.label}
                        inputId={formFields.editablePlaceholderLabelProduct.id}
                      />                      
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editablePlaceholderLabelValueProduct.label}
                        inputId={formFields.editablePlaceholderLabelValueProduct.id}
                      />                      
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.premiumFreequenctTxt.label}
                        inputId={formFields.premiumFreequenctTxt.id}
                      />                      
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox 
                        id={formFields.premiumFrequencyPlaceholder.id}
                        name={formFields.premiumFrequencyPlaceholder.name}
                        checked={formik.values.premiumFrequencyPlaceholder}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.premiumFrequencyPlaceholder.name)}
                      />                      
                    </div>
                    <div className="col-3 py-2 inputConfig">
                      <TextInput
                        id={formFields.premiumFrequencyPlaceholderValue.id}
                        name={formFields.premiumFrequencyPlaceholderValue.name}
                        placeholder={formFields.premiumFrequencyPlaceholderValue.placeholder}
                        {...formik.getFieldProps(formFields.premiumFrequencyPlaceholderValue.name)}
                      />                        
                    </div>
                  </div>                  
                </div>
                <div className="row w-100 form-btnGroup py-5">
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 py-2 w-100">
                  {/* <button
                    className="btn btn-primary moreRounded primaryBackground"
                    type="button"                  
                    onClick={saveProductConfig}
                    disabled={hasErrors}
                  >
                    Submit
                  </button> */}
                    <Button className="btn btn-primary  primaryBackground mr-4" 
                      onClick={saveProductConfig}   disabled={hasErrors}>
                        Submit
                    </Button>

                  </div>
                </div>
              </div>
            </form>
          )}
          {showSuccess && <SuccessPage {...formFields.confirmation}></SuccessPage>}
        </div>
      </Suspense>
    </div>
  );
};

export default ProductConfig;