import $ from 'jquery';
import React, { useState } from 'react';
import Image from '../../../../../Atoms/Image';
import StickyNote from '../../../../../Atoms/StickyNote';
import Collapse from '../../../Atoms/Collapse';
import { Link } from 'react-router-dom';

import './styles.css';

const ProductDescription = (props) => {
    const {
        title,
        className,
        upArrow,
        video,
        linkAdditionalClass,
        downArrow,
        panelLinks,
        modalName,
        pdfURL,
        stickyDetails,
        sticky,
        documentClassName,
        resources
    } = props;

    const [iFrameSrc, setiFrameSrc] = useState('');

    const closeModal = () => {
        setiFrameSrc('');
        $('#myModalVideo').modal('hide');
    };

    const handleBrochureDownload = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    // To get the product description
    const productDescription = resources?.find((data) => data.code == 'PRODDESC' && data.contenttype != 'crtNone')?.content || '';

    //To get video URL
    const productVideoURL = resources?.find((data) => data.code == 'FLIMPCODE' && data.contenttype != 'crtNone')?.content || '';

    //Code for getting brouchure
    const productBrouchureURL = resources?.find((data) => data.code == 'PRODBROC' && data.contenttype != 'crtNone')?.content || '';

    //Code for getting forms
    const productFormURL = resources?.filter((data) => data.code == 'PRODFORM') || [];


    const handleClickImage = () => {
        $('#myModalVideo').modal('show');

        const modifiedURL = getUpdateURL(productVideoURL);
        setiFrameSrc(modifiedURL);
    };

    const getUpdateURL = (productVideoURL) => {
        const newObjURL = new URL(productVideoURL);

        if (!newObjURL?.searchParams?.has('autoplay')) {
            newObjURL?.searchParams?.set('autoplay', '1');
        }
        return newObjURL.toString();
    };

    function openPdfInNewTab(pdfUrl) {
        const newWindow = window.open(pdfUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
    }

    return (
        <div className="product-description">
            <Collapse
                id="product_description"
                title={title}
                upArrow={upArrow}
                className={className}
                downArrow={downArrow}
                defaultOpen>

                <div className={'accordion-content-padding'}>
                    <div dangerouslySetInnerHTML={{ __html: productDescription }} />
                    <div className={panelLinks && video && pdfURL ? 'row py-3 mediaContainer' : panelLinks && pdfURL ? 'row py-3 mediaContainer' : ''}>
                        {sticky && (
                            <div className="col-12">
                                <StickyNote
                                    value={stickyDetails.value}
                                    title={stickyDetails.title}
                                    singleValue="true"
                                    placement={stickyDetails.placement}
                                    classNameWrapper={stickyDetails.classNameWrapper}
                                    iconClass={stickyDetails.iconClass}
                                    listClass={stickyDetails.listClass}
                                    olClass={stickyDetails.olClass}
                                    headerClass="col-9 pt-1 px-0"
                                    notArray="true"
                                    color="#FFFAEA"
                                    image={stickyDetails.image}
                                    borderColor="#FFD55A"
                                />
                            </div>
                        )}

                        {productVideoURL && (
                            <div className="col-12 col-lg-4 col-md-4 py-3 py-md-2 py-lg-3 videoMobile">
                                <div className="image-card-border-collapse" onClick={handleClickImage}>
                                    <Image filename="image-video.png" imageName="Thumbnail" onClick={handleClickImage} name="video" />

                                    <div className="play-icon" role='button' tabIndex={0} onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === 'Space') {
                                            e.preventDefault();
                                            handleClickImage();
                                        }
                                    }}>
                                        <Image
                                            onClick={handleClickImage}
                                            filename="icon-video.png"
                                            modalName={modalName}
                                            modalPopUp="true"
                                            imageName="Play Icon"
                                        />
                                    </div>

                                    <div className="text-block">Watch Video</div>
                                </div>
                            </div>
                        )}

                        {productBrouchureURL && (
                            <div className={documentClassName ? documentClassName : 'col-12 col-lg-4 col-md-4 py-md-4 py-0 py-lg-3'}>
                                <div className="image-card-border-collapse" onClick={() => handleBrochureDownload(productBrouchureURL)}>
                                    <Image filename="image-brochure.png" imageName="Brochure Icon" onClick={() => handleBrochureDownload(productBrouchureURL)} name="video" />

                                    <div className="play-icon">
                                        <Image filename="Icon PDF.png" imageName="Pdf Icon" onClick={() => handleBrochureDownload(productBrouchureURL)} name="video" />
                                    </div>
                                    <div className="text-block">Download Brochure</div>
                                </div>
                            </div>
                        )}






                        {productFormURL.length > 0 && (
                            <div className={documentClassName ? documentClassName : 'col-12 col-lg-4 col-md-4 py-3'}>
                                <div id="panel" className="panel pt-3 pl-3 pr-3 pb-4">
                                    Documents and Forms
                                    <div className="panel-links">
                                        {productFormURL.map((url) => (
                                            <div key={url?.name} id={`${url?.name}_urlLink`}>
                                                <a className={linkAdditionalClass} href={url?.content} target="_blank" rel="noopener noreferrer" aria-label={`Open ${url?.name}`}>
                                                    <span>{panelLinks && url?.name}</span>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Collapse>

            <div className="member-container">
                <div id="myModalVideo" className="modal" tabIndex="-1" data-id="my_id_value" aria-label='Product Video'
                    data-backdrop="static"   // This line was added
                    data-keyboard="false">
                    <div className="modal-dialog videoDialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title">Product Video</div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    onClick={closeModal}
                                >
                                    X
                                </button>
                            </div>
                            <div className="modal-body py-3">
                                <iframe
                                    id="iframeProductVideo"
                                    src={iFrameSrc}
                                    height="300"
                                    width="730"
                                    allow="autoplay"
                                    title="Iframe Example"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDescription;
