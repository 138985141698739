import React, { useState, useEffect } from 'react';
// import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.css';
import Icon from '../Icon';
import Image from '../Image/';
import Button from '../Button/';
import Sticky from '../Sticky/';
import StickyNote from '../StickyNote/';
import Linker from '../Linker/';
import $ from 'jquery';
import { RESET_USER_TYPE } from '../../../Store/Slices/Auth/userAuth';
import { useHistory } from 'react-router-dom';
import { CHANGE_SUCCESS, HIDE_SUCCESS, SET_CONTACT_TYPE } from '../../../Store/Slices/CallCenter';
import http from '../../../Common/Services/HttpService/index';
import imageMap from '../../Molecules/CustomSection/Helper/imageHelper';
import { getFileName } from '../../Molecules/CustomSection/Helper/commonHelper';

const CollapseOptions = (props) => {
  const { options } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const SearchPolicyProcessResources = useSelector(
    (storeState) => storeState?.process?.SearchPolicyProcessResources
  );
  const { userAuth } = useSelector((storeState) => storeState.auth);

  const currentUserType = userAuth.userType;

  return (
    <div>
      {options.map((option, indexOption) => {
        return (
          <div
            key={`collapse-option-${indexOption}`}
            className={`${option.className} accordion-item`}
          >
            <div
              onClick={() => {
                option.isResetPasswordCall
                  ? http.setUserType('')
                  : http.setUserType(currentUserType);
                dispatch(CHANGE_SUCCESS(false));
                //dispatch(SET_CONTACT_TYPE(''))
                if (option.redirect) {
                  if (window.location.pathname.indexOf('changeMFAContact') !== -1)
                    dispatch(HIDE_SUCCESS(true));
                  else dispatch(HIDE_SUCCESS(false));
                  history.push(option.to);
                }
                //for proxy enrollment only -call center
                option.resetUserType && dispatch(RESET_USER_TYPE());
              }}
            >
              {option.text}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CollapseContent = (props) => {
  const { content, currentStep, notMenu } = props;
  //Product description
  const description = useSelector((storeState) => {
    let descriptionContent =
      storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result.filter(
        (data) => data.code == 'PRODDESC' && data.contenttype != 'crtNone'
      );
    if (descriptionContent == undefined) {
      return '';
    } else {
      return descriptionContent[0]?.content;
    }
  });
  const { userAuth } = useSelector((storeState) => storeState.auth);

  let isCallCenterUser = userAuth.callCenterAdmin;
  let isConfigUser = (userAuth.userType === "Configurator" || userAuth.processType === "Configurator") && userAuth.configuratorSSOLogin


  return (
    <>
      {!notMenu &&
        content.map((contentInfo, indexContent) => {
          return (
            <div
              key={`collapse=-content-${indexContent}`}
              className={
                contentInfo.hasBottomPadding
                  ? 'accordion-content-item-padding'
                  : 'accordion-content-item'
              }
              hidden={isCallCenterUser || isConfigUser}
            >
              <p className="accordion-content-item-text pl-1 ml-1">
                <span className="accordion-content-item-title">{`${contentInfo.title} `}</span>
                {contentInfo.content}
              </p>
            </div>
          );
        })}

      {notMenu && <div dangerouslySetInnerHTML={{ __html: description }} />}
    </>
  );
};

const Collapse = (props) => {
  const {
    onClick = () => { },
    title,
    subOptions = [],
    className,
    fileName = '',
    to,
    content = [],
    filename,
    upArrow,
    notMenu,
    video,
    documents,
    linksToDownload,
    showFinalDiv,
    linkAdditionalClass,
    downArrow,
    panelLinks,
    modalName,
    hideElement,
    pdfURL,
    isAccordionImageLeftSide = false,
    listElementsData,
    listHeader,
    listFooter,
    stickyDetails,
    sticky,
    listImage,
    currentStep,
    listImageFile,
    documentClassName,
    videoPopUp,
    defaultOpen = false,
    showButton = false,
    buttonOptions = {},
    disabledForCallCenterUser = false,
    disableForSSO = false,
    isElegibleBenificiary
  } = props;
  // setIsActive(!isActive);

  useEffect(() => {
    notMenu && setIsActive(true);
  }, []);

  const [isActive, setIsActive] = useState(defaultOpen);
  const openClasses = isActive ? 'dropdown-item-open' : '';
  const textClasses = fileName ? 'accordion-text' : 'accordion-text-no-image';
  const [iFrameSrc, setiFrameSrc] = useState('');
  // const [iFrameSrc,setiFrameSrc]= useState("");
  let imgSrc;
  let upArrowSrc;
  let downArrowSrc;

  const closeModal = () => {
    setiFrameSrc('');
    $('#myModalVideo').modal('hide');
  };

  const productTitle = useSelector((storeState) => storeState?.process?.productTitle);

  //To get video URL
  const productVideoURL = useSelector((storeState) => {
    let videoContent =
      storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result.filter(
        (data) => data.code == 'FLIMPCODE' && data.contenttype != 'crtNone'
      );
    if (videoContent == undefined) {
      return '';
    } else {
      return videoContent[0]?.content;
    }
  });



  const handleClickImage = () => {
    $('#myModalVideo').modal('show');

    let modifiedURL = getUpdateURL(productVideoURL);
    setiFrameSrc(modifiedURL);
  };

  const getUpdateURL = (productVideoURL) => {
    const newObjURL = new URL(productVideoURL);

    if (!newObjURL?.searchParams?.has('autoplay')) {
      newObjURL?.searchParams?.set('autoplay', '1');
    }
    // setiFrameSrc(updatedURL)
    return newObjURL.toString();
  };

  //Code for getting brouchure
  const productBrouchureURL = useSelector((storeState) => {
    let brochureContent =
      storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result.filter(
        (data) => data.code == 'PRODBROC' && data.contenttype != 'crtNone'
      );
    if (brochureContent == undefined) {
      return '';
    } else {
      return brochureContent[0]?.content;
    }
  });


  //Code for getting forms
  const productFormURL = useSelector((storeState) => {
    let formContent =
      storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result.filter(
        (data) => data.code == 'PRODFORM'
      );
    if (formContent == undefined) {
      return '';
    } else {
      return formContent;
    }
  });

  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  try {
    imgSrc = imageMap[getFileName(filename)];
  } catch (e) {
    imgSrc = '';
  }
  try {
    upArrowSrc = imageMap[getFileName(upArrow)];
  } catch (e) {
    upArrowSrc = '';
  }
  try {
    downArrowSrc = imageMap[getFileName(downArrow)];
  } catch (e) {
    downArrowSrc = '';
  }
  let additionalClasses = hideElement ? 'hideElement' : ``;
  return (
    // <div className={`${className} ${openClasses}` + notMenu? "collapse-border":""}>
    <>
      <div className="member-container">
        <div id="myModalVideo" className="modal" tabIndex="-1" data-id="my_id_value">
          <div className="modal-dialog videoDialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title">Product Video</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body py-3">
                <iframe
                  id="iframeProductVideo"
                  src={iFrameSrc}
                  // style={{ border: 'none' }}
                  // style={{height:'200px', width:'100%',border:'none',overflow:'hidden'}}
                  // style="height:200px;width:100%;border:none;overflow:hidden;"
                  height="300"
                  width="730"
                  allow="autoplay"
                  title="Iframe Example"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id={"product-table-beneficiaries"}
        className={
          additionalClasses
            ? additionalClasses
            : `${className} ${openClasses}  ${notMenu ? 'collapse-border' : ''}`
        }
        disabled={disabledForCallCenterUser || disableForSSO}
      >
        <div
          role="button"
          id={props.id || ''}
          className={`accordion-title ${showButton ? 'align-items-center' : ''}`}
          tabIndex={0}
          onClick={() => {
            if (subOptions.length > 0) setIsActive(!isActive);
            if (content.length > 0) setIsActive(!isActive);
            if (props.children) setIsActive(!isActive);
            /** display modal */
            if (!(subOptions.length > 0) && !(content.length > 0)) {
              onClick();
            } // : undefined;
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Space') {
                e.preventDefault();
                if (subOptions.length > 0) setIsActive(!isActive);
                if (content.length > 0) setIsActive(!isActive);
                if (props.children) setIsActive(!isActive);
                /** display modal */
                if (!(subOptions.length > 0) && !(content.length > 0)) {
                  onClick();
                } // : undefined;
                }
          }}
        >
          <div
            className={`accordion-image-container w-100 ${showButton ? 'align-items-center' : ''}`}
          >
            {filename && <img src={imgSrc} className="" alt="settingIcon" />}
            {notMenu && (
              <div>
                {isActive ? (
                  <img src={upArrowSrc} className="" alt="upArrowSrc" />
                ) : (
                  <img src={downArrowSrc} className="" alt="notfound" />
                )}
              </div>
            )}
            <div
              className={`d-flex justify-content-between w-100 ${showButton ? 'align-items-center' : ''
                }`}
            >
              <div className={notMenu ? 'accordion-text-no-image-panel' : textClasses} name={isElegibleBenificiary ? `Benificiary` : ''}>{title}</div>
              {showButton && (
                <div>
                  <Button
                    id={(buttonOptions && buttonOptions.id) || ''}
                    key={(buttonOptions && buttonOptions.id) || ''}
                    name={(buttonOptions && buttonOptions.name) || ''}
                    className={`btn btn-primary primaryBackground d-flex ${(buttonOptions && buttonOptions.className) || ''
                      }`}
                    onClick={
                      buttonOptions && buttonOptions.onClick ? buttonOptions.onClick : () => { }
                    }
                    content={(buttonOptions && buttonOptions.label) || ''}
                    disabled={(buttonOptions && buttonOptions.disabled) || false}
                    config={(buttonOptions && buttonOptions.config) || {}}
                  />
                </div>
              )}
            </div>
          </div>

          <div
            className={`${isAccordionImageLeftSide ? 'accordion-image-container-left-side' : ''}`}
          >
            {subOptions.length > 0 || content.length > 0 || props.children
              ? !notMenu &&
              (isActive ? (
                <img src={upArrowSrc} className="" alt="upArrowSrc" />
              ) : (
                <img src={downArrowSrc} className="" alt="notfound" />
              ))
              : null}
          </div>
        </div>
        {isActive && subOptions.length > 0 && (
          <div className="accordion-options px-1">
            <CollapseOptions options={subOptions} />
          </div>
        )}

        {isActive && props.children && <div>{props.children}</div>}

        {isActive && content.length > 0 && (
          <div
            className={
              content.hasTopPadding || notMenu ? 'accordion-content-padding' : 'accordion-content'
            }
          >
            <CollapseContent content={content} notMenu={notMenu} currentStep={props.currentStep} />
            {notMenu && (
              <div className="">
                {showFinalDiv && (
                  <div
                    className={
                      panelLinks && video && pdfURL
                        ? 'row py-3 mediaContainer'
                        : panelLinks && pdfURL
                          ? 'row py-3 mediaContainer'
                          : ''
                    }
                  >
                    {sticky && (
                      <div className="col-12">
                        <StickyNote
                          value={stickyDetails.value}
                          title={stickyDetails.title}
                          // MultipleTitleStyles="true"
                          // notNumbered= "true"
                          // notNumbered ="false"
                          singleValue="true"
                          placement={stickyDetails.placement}
                          classNameWrapper={stickyDetails.classNameWrapper}
                          iconClass={stickyDetails.iconClass}
                          listClass={stickyDetails.listClass}
                          olClass={stickyDetails.olClass}
                          // notNumbered = {stickyDetails.notNumbered}
                          headerClass="col-9 pt-1 px-0"
                          notArray="true"
                          color="#FFFAEA"
                          image={stickyDetails.image}
                          borderColor="#FFD55A"
                        />
                      </div>
                    )}

                    {productVideoURL && (
                      <div className="col-12 col-lg-4 col-md-4 py-3 py-md-2 py-lg-3 videoMobile">
                        <div class=" image-card-border-collapse">
                          <Image filename="image-video.png" name="video" />

                          <div className="play-icon">
                            {!videoPopUp && (
                              <Image
                                filename="icon-video.png"
                                modalName={modalName}
                                modalPopUp="true"
                                onClick={handleClickImage}
                              />
                            )}
                            {/* {!videoPopUp &&<Image
                            filename="icon-video.png"
                            modalName={modalName}
                            modalPopUp="true"
                          />} */}
                          </div>

                          <div class="text-block">Watch Video</div>
                        </div>
                      </div>
                    )}
                    {productBrouchureURL && (
                      <div
                        className={
                          documentClassName
                            ? documentClassName
                            : 'col-12 col-lg-4 col-md-4 py-md-4 py-0 py-lg-3 '
                        }
                      >
                        {' '}
                        <div class=" image-card-border-collapse">
                          <Image filename="image-brochure.png" name="video" />

                          <div className="play-icon">
                            <Image
                              filename="Icon PDF.png"
                              name="download"
                              pdfURL={productBrouchureURL}
                            />
                          </div>
                          <div class="text-block">Download Brochure</div>
                        </div>
                      </div>
                    )}
                    {productFormURL.length > 0 && (
                      <div
                        className={
                          documentClassName ? documentClassName : 'col-12 col-lg-4 col-md-4 py-3'
                        }
                      >
                        <div id="panel" className="panel pt-3 pl-3 pr-3 pb-4">
                          Documents and Forms
                          <div className="panel-links">
                            {productFormURL.map((url, index) => (
                              (<div id="urlLink" className={linkAdditionalClass} role='button'>
                                <a target="_blank" rel="noopener noreferrer" href={url?.content}>{panelLinks && url?.name}</a>
                                {/* <a target="_blank" rel="noopener noreferrer" href={url?.content}>{ panelLinks&& productTitle + " " +url?.name}</a> */}


                              </div>)
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Collapse;