import { createSlice } from '@reduxjs/toolkit';
import { apiCallInitiation } from '../../../Middleware/Api/Actions';
import APIRequest, {APIRequestMFA,
  APIRequestSeq,
  APIRequestSeqLoad,
} from '../../../../App/Utils/Helper/APIHelper';
import { useDispatch, useSelector } from 'react-redux';

import dataService from '../../../../Common/Services/DataService';
import formService from '../../../../Common/Services/FormService';
import { SHOW_LOADER } from '../../Loader/index';
import { HAS_ERROR, SET_EMPLOYEE_VALIDATION_ERROR, SET_MFA_SERVICE_UNAVAILABLE, SET_SERVICE_UNAVAILABLE, SET_WYNSURE_SERVICE_UNAVAILABLE, SHOW_ALERT } from '../../RegisterationError';
import { DISABLE_CONTINUE_BUTTON } from '../../DisableButton';
import configureStore from '../../../configureStore';
import { SHOW_SPECIAL } from '../../CallCenter';
import { SET_PASSWORD_API, SET_PASSWORD_FAILED, SET_PREFERRED_CHANNEL, SET_TOLL_NUMBER_FROM_AUTH_MODULE } from '../../Registeration';
import { cloneDeep, isEmpty, isEqual } from 'lodash';

const slice = createSlice({
  name: 'response',
  initialState: {
    isSubmitLoading: false,
    isSubmissionReceived: false,
    completedSteps: [],
    loading: false,
    lastFetch: null,
    forms: {},
    formsData: {},
    mulitpleFormValidation: 0,
    onLoadResponseList: {},
    onNextResponseList: {},
    onLogin: {},
    formValues: {},
    isAPICallInProgress: false,
  },
  reducers: {
    apiRequested: (response, action) => {
      return { ...response, loading: true };
    },

    apiReceived: (response, action) => {
      const forms = {
        ...response.forms,
        [action.payload.requestData.form]: action.payload.responseData,
      };
      return {
        ...response,
        forms: forms,
        loading: false,
        lastFetch: Date.now(),
      };
    },

    apiRequestFailed: (response, action) => {
      return { ...response, loading: false };
    },
    UPDATE_FORM: (response, action) => {
      const { formName, updatedValue } = action.payload;
      const tempStepper = { ...response };
      tempStepper.formsData = {
        ...tempStepper.formsData,
        [formName]: updatedValue,
      };
      // if (tempStepper.completedSteps.indexOf(formName) == -1) {
      //   tempStepper.completedSteps = [...tempStepper.completedSteps, formName];
      // }
      return { ...response, ...tempStepper };
    },

    SUBMIT: (response, action) => {
      return { ...response, isSubmitLoading: true };
    },
    SUBMISSION_RECEIVED: (response, action) => {
      return {
        ...response,
        isSubmitLoading: false,
        isSubmissionReceived: true,
      };
    },

    formValidation: (response, action) => {
      return {
        ...response,
        mulitpleFormValidation: response.mulitpleFormValidation + 1,
      };
    },
    formChange: (response, action) => {
      let changes = { [action.payload.parenFormName]: action.payload.values };
      const newFormValues = {
        ...response.formValues,
        ...changes,
      };
      return {
        ...response,
        formValues: newFormValues,
      };
    },
    onNextAPI: (response, action) => {
      const apiResponseData = {
        ...response.onNextResponseList,
        ...action.payload,
      };
      return {
        ...response,
        onNextResponseList: apiResponseData,
        isAPICallInProgress: action.payload.isAPICallInProgress,
        loading: false,
        lastFetch: Date.now(),
      };
    },
    onLoginAPI: (response, action) => {
      const apiResponseData = {
        ...response.onLogin,
        ...action.payload,
      };
      return {
        ...response,
        onLogin: apiResponseData,
        loading: false,
        lastFetch: Date.now(),
      };
    },
    onLoadAPI: (response, action) => {
      const apiResponseData = {
        ...response.onLoadResponseList,
        ...action.payload,
      };
      return {
        ...response,
        isAPICallInProgress: action.payload.isAPICallInProgress,
        onLoadResponseList: apiResponseData,
        loading: false,
        lastFetch: Date.now(),
      };
    },
    onLoadProductAPI: (response, action) => {
      const currentStep = action.payload.currentStep;
      const newOnLoadResponseData = action.payload.data;
    
      // Check whether currentStep is available in onLoadResponseList, if not add first and add this response.
      let updatedOnLoadResponseList = { ...response.onLoadResponseList };
      if(!updatedOnLoadResponseList[currentStep]){
        updatedOnLoadResponseList[currentStep] = {};
      }
    
   
      updatedOnLoadResponseList[currentStep] = { ...updatedOnLoadResponseList[currentStep] ,  ...newOnLoadResponseData[currentStep]}
          
      return {
        ...response,
        isAPICallInProgress: action.payload.isAPICallInProgress,
        onLoadResponseList: updatedOnLoadResponseList,
        loading: false,
        lastFetch: Date.now(),
      };
    },
    onLoadAPIMFA: (response, action) => {
      const apiResponseData = {
        ...response.onLoadResponseList,
        ...action.payload,
      };
      return {
        ...response,
        isAPICallInProgress: action.payload.isAPICallInProgress,
        onLoadResponseList: apiResponseData,
        loading: false,
        lastFetch: Date.now(),
      };
    },
    updateCallStatus: (response, action) => {
      return {
        ...response,
        isAPICallInProgress: action.payload.isAPICallInProgress,
      };
    }, 
    logoutUserResponse: (response) => {
      return {
        isSubmitLoading: false,
        isSubmissionReceived: false,
        completedSteps: [],
        loading: false,
        lastFetch: null,
        forms: {},
        formsData: {},
        mulitpleFormValidation: 0,
        onLoadResponseList: {},
        onNextResponseList: {},
        onLogin: {},
        formValues: {},
        isAPICallInProgress: false,
      };
    },
    getDataFromResponse: (response) => {
      return {
       response
      };
    },
    RELATIONSHIP_BASED_QUESTION: (response, action) => {
      const { relationship, question } = action.payload;

      const onLoadResponseList = response.onLoadResponseList || {};
      const dependentInformation = onLoadResponseList.dependentInformation || {};
      const relationshipBasedQuestion = dependentInformation.relationshipBasedQuestion || {};

      return {
       ...response,
       onLoadResponseList: {
        ...onLoadResponseList,
        dependentInformation: {
          ...dependentInformation,
          relationshipBasedQuestion: {
            ...relationshipBasedQuestion,
            [relationship]: question
          }
        }
       }
      };
    },
    UPDATE_COVERAGE_INFO: (state, action) => {
      const { 
        product, 
        coverageBuid, 
        personBuid, 
        perpareSubscriptionForChoices = {}, 
        beneficiaryConfiguration = {}, 
        subscribedCoverageConfiguration = {}, 
        listOfCoveragePlanDesignConfiguration = {}, 
        validateSubscribedCoverageData = {} 
      } = action.payload;

      const onLoadResponseList = state.onLoadResponseList || {};
      const productInfo = onLoadResponseList[product] || {};
      const subscribeCoverageBuids = cloneDeep(productInfo.subscribeCoverageBuids || []);
      
      const selectedCoverageIndex = subscribeCoverageBuids.findIndex(coverage => {
        return coverage && coverage.personBuid === personBuid && coverage.coverageBuid === coverageBuid
      })
      
      // If coverage already exists => Update
      if (selectedCoverageIndex >= 0) {
        const selectedCoverage = subscribeCoverageBuids[selectedCoverageIndex];
                const updatedCoverage = {
          ...selectedCoverage,
          perpareSubscriptionForChoices: isEmpty(selectedCoverage.perpareSubscriptionForChoices) ? perpareSubscriptionForChoices : selectedCoverage.perpareSubscriptionForChoices,
          beneficiaryConfiguration: !isEmpty(beneficiaryConfiguration) ? isEmpty(selectedCoverage.beneficiaryConfiguration) || !isEqual(selectedCoverage.beneficiaryConfiguration, beneficiaryConfiguration)? beneficiaryConfiguration : selectedCoverage.beneficiaryConfiguration : selectedCoverage.beneficiaryConfiguration,
          subscribedCoverageConfiguration: isEmpty(selectedCoverage.subscribedCoverageConfiguration) ? subscribedCoverageConfiguration : selectedCoverage.subscribedCoverageConfiguration,
          listOfCoveragePlanDesignConfiguration: !isEmpty(listOfCoveragePlanDesignConfiguration) ? isEmpty(selectedCoverage.listOfCoveragePlanDesignConfiguration) || !isEqual(selectedCoverage.listOfCoveragePlanDesignConfiguration, listOfCoveragePlanDesignConfiguration) ? listOfCoveragePlanDesignConfiguration : selectedCoverage.listOfCoveragePlanDesignConfiguration : selectedCoverage.listOfCoveragePlanDesignConfiguration,
          validateSubscribedCoverageData: isEmpty(selectedCoverage.validateSubscribedCoverageData) ? validateSubscribedCoverageData : selectedCoverage.validateSubscribedCoverageData,
        }

        subscribeCoverageBuids[selectedCoverageIndex] = updatedCoverage;

        return {
          ...state,
          onLoadResponseList: {
            ...onLoadResponseList,
            [product]: {
              ...productInfo,
              subscribeCoverageBuids: subscribeCoverageBuids
            }
          }
        }
      } else {
        // If coverage not available => Add
        subscribeCoverageBuids.push({
          coverageBuid,
          personBuid,
          perpareSubscriptionForChoices: perpareSubscriptionForChoices,
          beneficiaryConfiguration: beneficiaryConfiguration,
          subscribedCoverageConfiguration: subscribedCoverageConfiguration,
          listOfCoveragePlanDesignConfiguration: listOfCoveragePlanDesignConfiguration,
          validateSubscribedCoverageData: validateSubscribedCoverageData,
        });

        return {
          ...state,
          onLoadResponseList: {
            ...onLoadResponseList,
            [product]: {
              ...productInfo,
              subscribeCoverageBuids: subscribeCoverageBuids
            }
          }
        }
      }
    }
  }
});

export const {
  apiReceived,
  apiRequested,
  apiRequestFailed,
  UPDATE_FORM,
  SUBMIT,
  SUBMISSION_RECEIVED,
  onNextAPI,
  onLoadAPI,
  onLoadProductAPI,
  onLoadAPIMFA,
  onLoginAPI,
  updateCallStatus,
  logoutUserResponse,
  getDataFromResponse,
  RELATIONSHIP_BASED_QUESTION,
  UPDATE_COVERAGE_INFO,
} = slice.actions;

export default slice.reducer;
const { formValidation, formChange } = slice.actions;

export const getForm = (url, formName) => (dispatch, getState) => {
  return dispatch(
    apiCallInitiation({
      url: url,
      method: 'POST',
      data: { form: formName },
      onStart: apiRequested.type,
      onSuccess: apiReceived.type,
      onError: apiRequestFailed.type,
    })
  );
};

export const validationTriggered = () => (dispatch, getState) => {
  return dispatch(formValidation());
};
export const handleFormChange = (parenFormName, values) => (dispatch, getState) => {
  return dispatch(
    formChange({
      parenFormName,
      values,
    })
  );
};

export const getElementsDetail = (formName) => (state) => state.forms[formName].elements;

export const APIRequestAction = (args, apiCalls) => async (dispatch) => {
  /**
   *  To make indivudual api all
   *  Example : (call made in Tissues)
   */
  if (!(apiCalls?.apiconfig?.loader === false)) dispatch(SHOW_LOADER(true));
  let response = await dataService.getAPIResponse(args, apiCalls);

  if (apiCalls?.apiconfig?.saveInRedux && apiCalls?.apiconfig?.responseIdentifier)
    //save in redux based on usint reduxIndentifier.
    dispatch(onLoadAPI({ data: { [apiCalls.apiconfig.responseIdentifier]: response } }));
  // checking wheather loader is swiched off in default
  if (!(apiCalls?.apiconfig?.loader === false)) dispatch(SHOW_LOADER(false));
  return response;
};

export const APIRequestParallel = (apiCalls, args, legalEntityId='', appCode='', basicAuthValue='') => async (dispatch) => {
  /** making multiple api calls in parallel
   * example : making apicalls in homepage (contract, invoices and qoute at the same time)
   **/

  dispatch(SHOW_LOADER(true));
  let response = await APIRequest(apiCalls, args);
  dispatch(onLoadAPI({ ...response }));
  dispatch(SHOW_LOADER(false));
  return response;
};
export const APIRequestParallelMFA = (apiCalls, args, legalEntityId='', appCode='', basicAuthValue='') => async (dispatch) => {
  /** making multiple api calls in parallel
   * example : making apicalls in homepage (contract, invoices and qoute at the same time)
   **/
   //
   dispatch(SHOW_LOADER(true));
  let response = await APIRequestMFA(apiCalls, args);
  dispatch(onLoadAPIMFA({ ...response }));
  //mfa emp validation
  for (let i=0; i< apiCalls.length; i++){
    let channel =  response?.[apiCalls[i].apiconfig?.objectName].data?.channel && response?.[apiCalls[i].apiconfig?.objectName].data?.channel;
    channel && dispatch(SET_PREFERRED_CHANNEL(channel))

    let passwordAPI =  apiCalls[i].apiconfig?.objectName ==='UserAccountVerifyEmail' && apiCalls[i].apiconfig?.resetPassword;
    let successResponse = response?.[apiCalls[i].apiconfig?.objectName].status && String(response?.[apiCalls[i].apiconfig?.objectName].status).charAt(0) ==='2'
    if ( apiCalls[i]?.handleNotFoundError)
      if (passwordAPI){
        dispatch(SET_PASSWORD_API(true))
        if (successResponse){
          dispatch(HAS_ERROR({hasError:false}));
          dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: false, mfaErrorText: ''}));
        }
        else  
        // if ( response?.[apiCalls[i].apiconfig?.objectName].message &&
        //   response?.[apiCalls[i].apiconfig?.objectName]?.source === 'Okta')
        {
          dispatch(HAS_ERROR({hasError:true}));
          dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}));
          dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Password does not match Okta policy, please modify and try again'}));
          dispatch(SET_PASSWORD_FAILED(true))
        }
      }
      if ( response?.[apiCalls[i].apiconfig?.objectName].message  === 'Request failed with status code 404' ) {
        dispatch(HAS_ERROR({hasError:true}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}));
        dispatch(SET_WYNSURE_SERVICE_UNAVAILABLE({serviceUnavailable: true, wynsureErrorText: 'Wynsure Server Unavailable'}));
      }
      else if ( response?.[apiCalls[i].apiconfig?.objectName]?.message  === 'Api validation failed: password' &&
        response?.[apiCalls[i].apiconfig?.objectName]?.source === 'Okta'  && apiCalls[i].apiconfig?.objectName === 'UserAccountVerifyEmail') {
          dispatch(HAS_ERROR({hasError:true}));
          dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}));
          if(apiCalls[i].apiconfig?.objectName === 'UserAccountVerifyEmail'){
            dispatch(HAS_ERROR({hasError:true}));
            dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}));
            dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Password validation failed, please modify and try again'}));
            dispatch(SET_PASSWORD_FAILED(true))
          }
      }
      else  if(response?.[apiCalls[i].apiconfig?.objectName].message  === 'Request failed with status code 503'){
        dispatch(SET_WYNSURE_SERVICE_UNAVAILABLE({serviceUnavailable: true, wynsureErrorText: 'Wynsure Server Unavailable'}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
      }
      else  if(response?.[apiCalls[i].apiconfig?.objectName].message  === 'Network Error'){
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Network Error'}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
      }
      else  if(response?.[apiCalls[i].apiconfig?.objectName].message  === 'Undefined value legalEntityId'){
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Please, use valid URI'}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
      }
      else  if(response?.[apiCalls[i].apiconfig?.objectName].message  === 'Request failed with status code 400'){
        if(apiCalls[i].apiconfig?.objectName === 'ContactDetails' && apiCalls[i].apiconfig?.showSpecial  )
          dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Email address is already used'}));
        else  if(apiCalls[i].apiconfig?.objectName === 'ContactDetails' && apiCalls[i].apiconfig?.showSpecial  ) {        
          dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'You have entered wrong OTP'}));
          dispatch(SHOW_SPECIAL(true))
        }
        else if(passwordAPI){
          dispatch(HAS_ERROR({hasError:true}));
          dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}));
          dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Password does not match Okta policy, please modify and try again'}));
          dispatch(SET_PASSWORD_FAILED(true))
        } 
        else
          dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Request failed with status code 400'}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
        
      }
      else  if(response?.[apiCalls[i].apiconfig?.objectName].message  === 'Request failed with status code 401'){
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({
            serviceUnavailable: true, 
            mfaErrorText: 'Request failed with status code 401',
            newOTPText: '' 
          }));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
      }
      else  if(response?.[apiCalls[i].apiconfig?.objectName].message  === 'Request failed with status code 500'){
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'timeout of 30000ms exceeded - Wynsure'}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
      }
      
      else  if(response?.[apiCalls[i].apiconfig?.objectName].message  === 'Network Error'){
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Network Error'}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
      }
      else  if(response?.[apiCalls[i].apiconfig?.objectName].message  === 'Request failed with status code 409' ){
        if(apiCalls[i].apiconfig?.objectName==='UserAccountVerifyEmail' && !passwordAPI)
          dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Request not processed'}));
        else if(passwordAPI){
          dispatch(HAS_ERROR({hasError:true}));
          dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}));
          dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Request not processed, please modify and try again'}));
          dispatch(SET_PASSWORD_FAILED(true))
        }
        if(apiCalls[i].apiconfig?.objectName === 'EmployeeValidation')
          dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Employee already has an account'}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, element: 'password',clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
      }
      else  if(response?.[apiCalls[i].apiconfig?.objectName].message  === 'Authentication failed' &&
              response?.[apiCalls[i].apiconfig?.objectName].details  === 'LOCKED_OUT' &&
              response?.[apiCalls[i].apiconfig?.objectName].source  === 'Okta'){
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Account Locked-Out'}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
      }
      // handle max wrong attempts
      // else if ( response?.[apiCalls[i].apiconfig?.objectName]?.message  === 'Request failed with status code 403' && apiCalls[i].apiconfig?.objectName!=='ContactDetails' ) {
      else if ( response?.[apiCalls[i].apiconfig?.objectName]?.message  === 'User has exceeded the number of attempts (3)' && apiCalls[i].apiconfig?.objectName!=='ContactDetails' ) {

       dispatch(SHOW_ALERT(true));
       dispatch(HAS_ERROR({hasError:true}));
       dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
        if(apiCalls[i].apiconfig?.objectName === 'MultiFactorAuthenticationLogin' ||
        apiCalls[i].apiconfig?.objectName === 'MFAForgotPassword' ||
        apiCalls[i].apiconfig?.objectName === 'MFAForgotUsername'
        ) 
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'User Locked', redirectToLogin: true}));
        dispatch(SET_TOLL_NUMBER_FROM_AUTH_MODULE(response?.[apiCalls[i].apiconfig?.objectName]?.tollNumber))
      }
      else if ( response?.[apiCalls[i].apiconfig?.objectName]?.message  === 'User is not allowed to create a login' && apiCalls[i].apiconfig?.objectName!=='ContactDetails' ) {
        dispatch(HAS_ERROR({hasError:true}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'User is not allowed to create a login', redirectToLogin: false}));
       }
      // else if ( response?.[apiCalls[i].apiconfig?.objectName]?.message  === 'Request failed with status code 403' && apiCalls[i].apiconfig?.objectName==='ContactDetails' ) {
      
      //     dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'You have entered wrong OTP'}));
      //     dispatch(SHOW_SPECIAL(true))
      // }
      else if ( response?.[apiCalls[i].apiconfig?.objectName]?.message  === 'Invalid Passcode/Answer' && 
      //apiCalls[i].apiconfig?.objectName !== 'ContactDetails' &&
               response?.[apiCalls[i].apiconfig?.objectName]?.source === 'Okta') {

        dispatch(HAS_ERROR({hasError:true}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
        if(apiCalls[i].apiconfig?.objectName !== 'ContactDetails' )
            dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Invalid Passcode/Answer'}));
          else
            dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'You have entered wrong OTP'}));

            
        //dispatch(SHOW_SPECIAL(true))

      
      }
      else if ( response?.[apiCalls[i].apiconfig?.objectName]?.message  === 'Employee not found' && 
               response?.[apiCalls[i].apiconfig?.objectName]?.source === 'Wynsure') {
        dispatch(HAS_ERROR({hasError:true}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText:response?.[apiCalls[i].apiconfig?.objectName]?.message }));
      }
      else if ( response?.[apiCalls[i].apiconfig?.objectName]?.message  === 'Api validation failed: password' &&
        response?.[apiCalls[i].apiconfig?.objectName]?.source === 'Okta') {
        dispatch(HAS_ERROR({hasError:true}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText:'Password does not match policy, please modify and try again' }));
      }
      // else if ( response?.[apiCalls[i].apiconfig?.objectName]?.data  === 'Request failed with status code 409' &&
      //         response?.[apiCalls[i].apiconfig?.objectName]?.status === 409 && response?.[apiCalls[i].apiconfig?.objectName]?.statusText === 'Conflict') {
      //   dispatch(HAS_ERROR({hasError:true}));
      //   dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}));
      //   if(apiCalls[i].apiconfig?.objectName === 'UserAccountVerifyEmail')
      //     dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Password Conflict'}));
      //   if(apiCalls[i].apiconfig?.objectName === 'EmployeeValidation')
      //     dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Employee already has an account'}));
      // }
      else  if(response?.[apiCalls[i].apiconfig?.objectName].status  !== 200 ){
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: true, mfaErrorText: 'Error'}));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: true, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
}
      else {
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({hasError: false, clearCount: false, key:  apiCalls[i].apiconfig.objectName}))
        dispatch(SET_WYNSURE_SERVICE_UNAVAILABLE({serviceUnavailable: false, wynsureErrorText: ''}));
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({serviceUnavailable: false, mfaErrorText: ''}));

      }
  }
  dispatch(SHOW_LOADER(false));
  return response;
};
export const APIRequestSequential2 = (apiCalls, args) => async (dispatch) => {
  /** making multiple api calls in parallel
   * example : making apicalls in homepage (contract, invoices and qoute at the same time)
   **/
  dispatch(SHOW_LOADER(true));
  let response = await APIRequestSeqLoad(apiCalls, args);

  dispatch(onLoadAPI({ ...response }));
  dispatch(SHOW_LOADER(false));
  return response;
};

export const APIRequestSequential = (apiCalls, args) => async (dispatch) => {
  /** making multiple api calls in Sequentially
   * example : making apicalls in bills (getAllPossibleAccountsForClient  based on       searchFinancialOperations api call)
   **/
  dispatch(SHOW_LOADER(true));
  let response = await APIRequestSeq(apiCalls, args);
  dispatch(onNextAPI({ ...response }));
  dispatch(SHOW_LOADER(false));
  return response;
};

export const submitForm = (formData, bearerToken, SetContentData, url) => async (dispatch) => {
  dispatch(SHOW_LOADER(true));
  let response = await formService.submitForm(formData, bearerToken, SetContentData, url);
  dispatch(SHOW_LOADER(false));
  return response;
};

export const getOptions = (url, obj) => async (dispatch) => {
  dispatch(SHOW_LOADER(true));
  let response = await formService.getOptions(url, obj);
  dispatch(SHOW_LOADER(false));
  return response;
};

export const getResponseState = (url, obj) => async (dispatch, getState) => {
  // dispatch(SHOW_LOADER(true));
  // let response = await formService.getOptions(url, obj);
  // dispatch(SHOW_LOADER(false));
  return getState;
};

export const getElements =
  (
    formName,
    formElements,
    formLayoutType,
    SetContentData,
    parentFrom,
    currentLanguage = '',
    userType = '',
    clientId
  ) =>
  async (dispatch) => {
    dispatch(SHOW_LOADER(true));
    let response = await dataService.getElements(
      formName,
      formElements,
      formLayoutType,
      SetContentData,
      parentFrom,
      (currentLanguage = ''),
      (userType = ''),
      clientId
    );
    dispatch(SHOW_LOADER(false));
    return response;
  };