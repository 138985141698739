import React, { useState } from 'react';
import FormatHelper from '../../../../Utils/Helper/FormatHelper';

const formatHelper = new FormatHelper();

const EyeIcon = ({ showEye, onClick = ()=> {} }) => (
  <svg role="button" tabIndex={0} onClick={onClick} style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002c77" className={showEye ? "bi bi-eye-slash" : "bi bi-eye"} viewBox="0 0 16 16">
    {showEye ? (
      <>
        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
        <title>Hide SSN</title>
      </>
    ) : (
      <>
        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
        <title>Show SSN</title>
      </>
    )}
    
  </svg>
);

const GetSSN = ({ ssnValue, ssnEyeToggle }) => {
  const ssnFormatted = formatHelper.getFormatSSN(ssnValue);
  const ssnTemp = ssnValue && ssnValue.replace(/[^\d]/g, '');
  const ssnMasked = '***-**-' + (ssnTemp && ssnTemp.slice(5, 9));
  const [showSSN, setShowSSN] = useState(false);

  const handleToggleSSN = () => {
    setShowSSN(!showSSN);
  };

  return (
    <>
      {showSSN ? (
        <>
          {ssnFormatted} {!ssnEyeToggle ? 
            // <button type="button" className="icon-button" onClick={handleToggleSSN}>
              <EyeIcon showEye={true} onClick={handleToggleSSN}/>
            // </button> 
          : ''}
        </>
      ) : (
        <>
          {ssnMasked} {!ssnEyeToggle ? 
            // <button type="button" className="icon-button" onClick={handleToggleSSN}>
              <EyeIcon showEye={false} onClick={handleToggleSSN}/>
            //  </button> 
          : ''}
        </>
      )}
    </>
  );
};

const NewAddress = ({ employeeInformation }) => {
  return (
      <div>
          <div>{employeeInformation?.line1} {employeeInformation?.line2}</div>
          <div>{employeeInformation?.city} {employeeInformation?.state} {employeeInformation?.zipCode} {employeeInformation?.country}</div>
      </div>
  );
}

const Label = ({ newFormat, displayValue, className1, className2, employeeInformation, labelForPhone, ssnEyeToggle, divisionCodeEditable, divisionCodeEditableValue, 
  workLocationEditable, workLocationEditableValue, showField }) => {
  if (!newFormat || showField) {
    return null;
  }

  const colClass = `col-md-2 col-sm-6 col-xs-6 col-6 pb-3 ${className1 ? className1 : labelForPhone ? 'labelForPhone' : ''}`;
  const labelClass = className2 ? className2 : '';

  let labelValue;
  if (divisionCodeEditable && divisionCodeEditableValue) {
    labelValue = divisionCodeEditableValue;
  } else if (workLocationEditable && workLocationEditableValue) {
    labelValue = workLocationEditableValue;
  } else {
    labelValue = newFormat?.label || '';
  }

  let labelContent;
  if (newFormat.name === 'gender') {
    labelContent = displayValue;
  } else if (newFormat.name === 'ssn') {
    labelContent = employeeInformation ? <GetSSN ssnValue={employeeInformation} ssnEyeToggle={ssnEyeToggle} /> : null;
  } else if (!displayValue && newFormat.name !== 'ssn') {
    labelContent = employeeInformation || '';
  }/* else {
    labelContent = employeeInformation ? <NewAddress employeeInformation={employeeInformation}/> : null; 
  } */

  return (
    <div className={colClass}>
      <div className={labelClass}>{labelValue}</div>
      <div className="row">
        <div className="col-12">
          {labelContent}
        </div>
      </div>
    </div>
  );
};


export default Label;

