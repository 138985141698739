import { useState, useEffect } from "react";
import Beneficiary from "../Beneficiary/Beneficiary"
import { useDispatch, useSelector } from "react-redux";
import { SET_REQUIRED_CHECKS_PASSED_BOOLEAN } from "../../../../../Store/Slices/BuyUp";


export const BeneficiarySection = (props) => {
    const { 
        currentStep, 
        personBuid, 
        coverageBuid, 
        relation, 
        dependentsList, 
        allowedBeneficiaryKind, 
        setTotalPercentCallback, 
        setSelectedRowsCallback, 
        isBeneficiaryMandatory, 
        isBeneficiaryOptional,
        setCanContinueBeneficiaries, 
        setUpdatedBeneficiaryList, 
        isBuyUpFlow, 
        basePlanCoverageBuid, 
        isBeneficiarySectionValidCallback
    } = props;
    const dispatch = useDispatch();
    const { insuredInfo, buyUp } = useSelector((storeState) => storeState)
    const [totalPercent, setTotalPersent] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    //TODO
    //Ideally all the data related to beneficiary section should be fetched here
    //For now, passing it as props alike other components

    //dispatch action for updating the beneficiary details in redux
    const dispatchRequiredChecksPassedAction = (boolean) => {
        dispatch(SET_REQUIRED_CHECKS_PASSED_BOOLEAN({
            ...buyUp.requiredChecksPassed,
            beneficiarySection: boolean
        }))
    }

    //test if beneficiary details are updated correctly
    const checkBenificiaryFn = (pstatus, pselectedRows) => {
        let primaryTotal = 0;
        let contingentTotal = 0;
        let hasPrimary = false;
        let hasContingent = false;
        insuredInfo[currentStep]?.updatedBeneficiaries?.forEach(pobj => {
            Object.keys(pobj).forEach((pbeneficiary, pind) => {
                const beneficiary = pobj[pbeneficiary][pind];
                if (beneficiary.beneficiaryType === 'Primary') {
                    primaryTotal += beneficiary.beneficiaryPercentage;
                    hasPrimary = true;
                } else {
                    contingentTotal += beneficiary.beneficiaryPercentage;
                    hasContingent = true;
                }
            })
        });
        let lflag = true;
        if (hasPrimary && primaryTotal !== 100) {
            lflag = false;
        }

        if (hasContingent && contingentTotal !== 100) {
            lflag = false;
        }
        if (pstatus == "success") {
            setTotalPersent(100);
        } else {
            const currentPercent = ((primaryTotal > 0 ? primaryTotal : 0) + (contingentTotal > 0 ? contingentTotal : 0)) * 100 / 200;
            setTotalPersent(0);
        }
        setSelectedRowsCallback([...pselectedRows])
        setSelectedRows([...pselectedRows]);
    }

    //Passing required arguments to the existing beneficiary components
    return (
        <Beneficiary
            currentStep={currentStep}
            personBuid={personBuid}
            coverageBuid={coverageBuid}
            dependentsList={dependentsList}
            relation={relation}
            updateBeneficiary={checkBenificiaryFn}
            allowedBeneficiaryKind={allowedBeneficiaryKind}
            isBeneficiaryMandatory={isBeneficiaryMandatory}
            isBeneficiaryOptional={isBeneficiaryOptional}
            setCanContinueBeneficiaries={setCanContinueBeneficiaries}
            setUpdatedBeneficiaryList={setUpdatedBeneficiaryList}
            isBeneficiarySectionValidCallback={isBeneficiarySectionValidCallback}
            isBuyUpFlow={isBuyUpFlow} 
            basePlanCoverageBuid= {basePlanCoverageBuid}
        />
    )
}