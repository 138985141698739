const FileUpload = (props) => {
    const {
        name,
        onChange,
        id, value, element } = props

    return (
        <>
            {element && element.showFileName && <label>{value} </label>}
            <input name={name || id} id={name || id} onChange={onChange} type="file" />
        </>

    )
}

export default FileUpload;