import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import formFields from '../../Content/PetAssureNationFormFields.json';
import { useDispatch, useSelector } from 'react-redux';
import ServiceHelper from '../../../../Utils/Helper/MappingHelper';
import { addSubscriptionForChoiceNew } from '../../Actions/addSubscriptionForChoiceNew';
// import { updateSubscribedCoverageInformation } from '../../../Actions/updateSubscribedCoverageInformation';
import * as Yup from 'yup';
import { Styles } from './Styles';
import { onLoadAPI } from '../../../../../Store/Slices/Entities/response';
import {
  RADIO_SELECTED_VALUE_PET_NATION,
  RADIO_SELECTED_VALUE_PET_NATION_DATA,
  EDIT_PET_NATION,
} from '../../../../../Store/Slices/EmployeeProfile';
import { ADD_PET_LIST, SET_ALERT_MESSAGE } from '../../../../../Store/Slices/InsuredInfo';
import { getPetCoverages } from '../../Actions/getPetCoverages';
import { getPetMedicalSurveyQuestions } from '../../Actions/getPetMedicalSurveyQuestions';
import { getPetProviders } from '../../Actions/getPetProviders';
import { savePetQuote } from '../../Actions/savePetQuote';
import { getSubscribedCoverageConfigurationNew } from '../../Actions/getSubscribedCoverageConfigurationNew';
import { onLoadProductAPI } from '../../../../../Store/Slices/Entities/response/index.js';
import { updateSubscribedCoverageInformationPet } from '../../Actions/updateSubscribedCoverageInformationPet';
import {
  UPDATE_LOADING,
  SAVE_QUOTE_RES,
  DELETE_FULL_PET_LIST,
} from '../../../../../Store/Slices/InsuredInfo';
import { unsubscribeCoverage } from '../../Actions/unsubscribeCoverage';
import { UPDATE_ENROLLMENT_DATA } from '../../../../../Store/Slices/Enrollment';
import constants from '../../Content/Constants.json';
import { UPDATE_WAIVE_PRODUCT_WITHOUT_PERSONBUID } from '../../../../../Store/Slices/InsuredInfo';
import { reworkProductEnrollment } from '../../Actions/reworkProductEnrollment';
import {
  SET_WAIVE_COVERAGE,
  SET_WAIVE_COVERAGE_NATION,
} from '../../../../../Store/Slices/InsuredInfo';
import { createPetListPayload, shouldWaiveCoverage, coverageLevelMapping } from '../../Helper/PetHelper';
import { roundTo, getCostPerPayPeriod, getFirstFiveDigits } from '../../Helper/PetHelper';
import { updateProcess } from '../../Actions/updateProcess';
import { useNavigationHook } from '../../Hooks/NavigationHook';
import appConfig from '../../../../../appConfig';
import http from '../../../../../Common/Services/HttpService';
import { removeUnwantedProps } from '../../Helper/MappingHelper';
import resumeContent from '../../../../Molecules/CustomSection/Content/ResumeContent.json';
import { formatDollarAmountWithDecimals} from "../../../../Utils/Helper/ValidationHelper"
const PetComponent = (props) => {

  const { currentStep, previousStep, formsConfig, formData, handleNextStep, handlePreviousStep, handleGoToSpecificStep, handleSetStepKeys } = useNavigationHook();
  const [petCoverages, setPetCoverages] = useState([]);
  const [petQuote, setPetQuote] = useState([]);
  const [medicalSurveyQuestions, setMedicalSurveyQuestions] = useState([]);
  const [covIds, setCovIds] = useState([]);
  const [coverIdToAdd, setCoverIdToAdd] = useState([]);
  const [providers, setProviders] = useState([]);
  const [step, setstep] = useState(1);
  const [isAPI5Done, setIsAPI5Done] = useState(false);
  const dispatch = useDispatch();

  const [optionSelectedNation, setOptionSelectedNation] = useState(-1);
  const [optionSelectedNationData, setOptionSelectedNationData] = useState([]);
  const [breeds, setBreeds] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [showHospitalSection, setShowHospitalSection] = useState(false);
  let currentReduxState = useSelector((storeState) => storeState);
  const zipCode = useSelector(
    (storeState) => storeState?.employeeProfile?.employeeProfile?.zipCode
  );
  //Takeing work email
  // const email = useSelector(
  //   (storeState) => storeState?.employeeProfile?.employeeProfile?.workEmail
  // );

  const personalEmail = useSelector((storeState) =>
    storeState?.entities?.response?.onLoadResponseList?.PersonalInformation?.personalInformation?.personalEmail
  );



  const workEmail = useSelector((storeState) =>
    storeState?.entities?.response?.onLoadResponseList?.PersonalInformation?.personalInformation?.workEmail
  );


  const phoneNumber = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.PersonalInformation?.personalInformation?.personalPhone
  );

  const address = useSelector(
    (storeState) => storeState?.employeeProfile?.employeeProfile?.primaryAddressLine3Display
  );
  // const validateContractDataResponse = useSelector(
  //   (storeState) => storeState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements[0]?.coverages
  // );
  const { employeeIndex } = props;
  const buid = props.employee[employeeIndex]?.personBuid;
  let personBuid = buid;
  const employmentInformation = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.EmploymentInformation
        ?.employmentInformation
  );

  let productFamily = formsConfig?.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
  //Get subscribed product buid
  let buidSubscribed = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.[currentStep]
        ?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]?.coverage?.product?.bUID
  );

  const surveyQuestions = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.[currentStep]?.surveyQuestions?._Result
        ?.medicalSurveys
  );




  const { insuredInfo, process } = useSelector((storeState) => storeState);
  let waiveCoverage = insuredInfo[currentStep]?.WaiveCoverage;
  let isWaiveCoverage = false;

  let waiveCoverageDetails = waiveCoverage?.filter((e) => e.personBuid === buid);
  if (waiveCoverageDetails?.length > 0) {
    isWaiveCoverage = waiveCoverageDetails?.[0]?.waiveProduct;
  }



  let getAllPossibleChoicesConfiguration = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]
        ?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]
  );

  const serviceHelper = new ServiceHelper();
  const subscribedCoverageBuid1 = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList[currentStep]?.AddSubscriptionForChoice
        ?.contract?.subscribedElements[0]?.coverages[0]?.bUID
  );

  const {
    radioSelectedValuePetNation,
    radioSelectedValuePetNationData,
    editPetNation,
    selectedPetData,
  } = useSelector((storeState) => storeState.employeeProfile);

  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
  let state = constantsList['StateConstants'];

  let enrollmentProcessBuid = useSelector(
    (storeState) => storeState?.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID
  );
  let coverageBuidToDelete = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]
        ?.AddSubscriptionForChoice?.contract?.subscribedElements[0]?.coverages
  );


  const coverages = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList[currentStep]?.AddSubscriptionForChoice
        ?.contract?.subscribedElements[0]?.coverages
  );


  const validationSchema = Yup.object({
    petName: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual'
        ? schema
          .matches(/^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/, 'No special characters allowed other than space')
          .required('Pet Name is required')
        : Yup.string();
    }),

    petType: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual'
        ? schema
          .matches(/^[a-zA-Z]+$/, 'No special characters allowed')
          .required('Pet Type is required')
        : Yup.string();
    }),
    city: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual'
        ? schema

          .matches(/^(?!.*[ ][ ])^[a-zA-Z0-9 -]+$/, 'No special characters allowed other than space and hyphen')
          .required('City is required')
        : Yup.string();
    }),

    State: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual'
        ? schema
          .required('State is required')
        : Yup.string();
    }),
    hospitalName: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual'
        ? schema
          .required('Hospital Name is required')
        : Yup.string();
    }),

    petAge: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual'
        ? schema
          .matches(/^([0-9]|1[0-9]|20)$/, 'Pet age limit is <1 to 20 years')
          .required('Pet Age is required')
        : Yup.string();
    }),

    petBreed: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual' ? schema.required('Pet Breed is required') : Yup.string();
    }),
    policyNumber: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual' ? schema.required('Policy Number is required').max(20, 'Description exceeds 20 characters') : Yup.string();
    }),

    dateAndReason: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual' ? schema.required('This field is required').max(100, 'Description exceeds 100 characters') : Yup.string();
    }),

    medicationDetails: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual' ? schema.required('This field is required').max(100, 'Description exceeds 100 characters') : Yup.string();
    }),
    });

  const email = personalEmail || workEmail || "";

  const formik = useFormik({
    initialValues: {
      legalName: '',
      underwritingQuestionOne: '',
      underwritingQuestionTwo: '',
      underwritingQuestionThree: '',
      underwritingQuestionFour: '',
      underwritingQuestionFive: '',
      beneficiaryCollateral: '',
      underwritingQuestionFiveInfo: '',
      policyNumber: '',
      dateAndReason: '',
      medicationDetails: '',
      petHospital: '',
      moreOption: '',
      hospitalName: '',
      city: '',
      State: '',
      petName: '',
      beneficiaryOptions: 'individual',
      petType: '',
      petAge: '',
      petBreed: '',
      costPerPayPeriod: '',
      waivedCoverage: false,
      zipCode: getFirstFiveDigits(zipCode),
      email: email,
      phoneNumber: phoneNumber,
      address: address,
      // productId: 'VB25050',
      productId: '',
      petNationCoverageWaive: false,
    },

    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      formik.resetForm();
      setSubmitting(false);
    },

    enableReinitialize: true,
  });

  const getPayCyclesPerYear = (payFrequency) => {
    if (!payFrequency) return 0;
    const frequencyItem = constants?.frequencyList.find(frequency => frequency.fieldValue === payFrequency);
    return frequencyItem ? parseInt(frequencyItem.valueCode) : 0;
  }

  const ValidateContractData =
    useSelector(
      (storeState) =>
        storeState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract
    ) || {};

  // Null check before filtering subscribeCoverageBuids
  const validateContractDataResponse =
    ValidateContractData.subscribedElements?.filter(
      (e) => e.subscribedProduct.productCode.buid == buidSubscribed
    )[0]?.coverages || [];

  //Product status
  const productStatus =
    ValidateContractData.subscribedElements?.filter(
      (e) => e.subscribedProduct.productCode.buid == buidSubscribed
    )[0]?.subscribedProduct?.status || [];

  // Null check before filtering subscribeCoverageBuids
  // const filteredSubscribeCoverageBuids = insuredInfoCurrentStep.subscribeCoverageBuids?.filter(item => item.personBuid === buid) || [];

  //  const validateContractDataResponse = useSelector(
  //   (storeState) => useSelector(storeState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements?.filter(e => e.subscribedProduct.productCode.buid == "318844143_56")[0]?.coverages));

  // let validateContractDataResponse =  useSelector(storeState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements?.filter(e => e.subscribedProduct.productCode.buid == "318844143_56")[0]?.coverages);

  let subCovBuid = [];
  for (let i = 0; i < coverages?.length; i++) {
    subCovBuid.push(coverages[i]?.bUID);
  }

  /*   useEffect(() => {
      petListTableData = [];
      const payload = {
        product: currentStep,
        petList: [],
      };
     // dispatch(DELETE_FULL_PET_LIST(payload));
  
      for (let i = 0; i < validateContractDataResponse?.length; i++) {
        const annualCost = validateContractDataResponse[i]?.subscribedOption?.additionalDatas?.filter(
          (data) => data.fieldCode == 'DD_00164'
        )[0]?.fieldValue?.amount;
  
        let payFrequency = employmentInformation?.payFrequency;
        let payCyclesPerYear = getPayCyclesPerYear(payFrequency);
  
        let costPerPayPeriod = 0;
        if (payCyclesPerYear > 0 && annualCost) {
          costPerPayPeriod = parseFloat((annualCost / payCyclesPerYear).toFixed(2));
        }
  
        let petList = {
          petName: validateContractDataResponse[i]?.subscribedOption?.additionalDatas?.filter(
            (data) => data.fieldCode == 'DD_00169'
          )[0]?.fieldValue,
          petType: validateContractDataResponse[i]?.subscribedOption?.additionalDatas?.filter(
            (data) => data.fieldCode == 'DD_00168'
          )[0]?.fieldValue,
          coverageLevel: validateContractDataResponse[i]?.subscribedOption?.additionalDatas?.filter(
            (data) => data.fieldCode == 'DD_00174'
          )[0]?.fieldValue,
          costPerPayPeriod: costPerPayPeriod,
          coverageId: validateContractDataResponse[i]?.bUID,
  
          waivedCoverage: formik?.values?.waivedCoverage,
          CoverageIdNew: validateContractDataResponse[i]?.bUID,
        };
  
        petListTableData.push(petList);
        if (
          validateContractDataResponse[i]?.subscribedOption?.status == 'cProject' &&
          productStatus == 'cProject'
        ) {
          const payload = {
            product: currentStep,
            petList: petList,
          };
          dispatch(ADD_PET_LIST(payload));
        }
      }
    }, [validateContractDataResponse]); */

  let subscribedCoverageBuid = subCovBuid;

  let coverIdArr = coverageBuidToDelete?.slice();

  let coverId;
  const coverIdLength = coverIdArr?.length;

  const resetStep = () => {
    //Call rework API here if product is already waived

    formik.resetForm();
    setSelectedValue('');
    setOptionSelectedNation(-1);
    dispatch(RADIO_SELECTED_VALUE_PET_NATION(''));
    setShowHospitalSection(false);
    setstep(1);
  };

  const nextStep = () => {
    setstep((prevStep) => prevStep + 1);
  };

  const getCoverages = () => {
    setstep((prevStep) => prevStep + 1);
    getCoveragesData(formik);
  };

  const getMedicalSurveyQuestions = () => {
    setstep((prevStep) => prevStep + 1);
    getPetMedicalSurveyQuestionsData(formik, currentStep);
    getPetProvidersData(formik);
  };
  let petlist = currentReduxState?.insuredInfo?.[`${currentStep}`]?.petList || [];

  const subscribedElementsFromValidateContract = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements
  let subscribedElementsDataFromValidateContract = subscribedElementsFromValidateContract?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
  let subscribedProductBuidFromValidateContract = subscribedElementsDataFromValidateContract?.[0]?.subscribedProduct?.buid;




  const getCoveragesData = async (formik) => {
    let petCoverages = await dispatch(getPetCoverages(formik, currentStep));

    setPetCoverages(petCoverages?.recommendations[0]?.products);
  };
  let medicalSurveyQuestionsArray = [];
  const getPetMedicalSurveyQuestionsData = async (formik, currentStep) => {
    let medicalSurveyQuestions = await dispatch(getPetMedicalSurveyQuestions(formik, currentStep));
    medicalSurveyQuestionsArray = medicalSurveyQuestionsArray?.medicalSurveys;


    // setMedicalSurveyQuestions(medicalSurveyQuestions?._Result);
    let optionsNew = serviceHelper.getUnderWritingQuestionsData(
      medicalSurveyQuestions?.medicalSurveys
    );
    setMedicalSurveyQuestions(optionsNew);
  };

  const getPetProvidersData = async (formik) => {
    let petProviders = await dispatch(getPetProviders(formik, currentStep));
    let optionsNew = serviceHelper.getOptionsFromRedux(petProviders?.Providers);
    setProviders(optionsNew);
  };
  const prevStep = () => {
    setstep((prevStep) => prevStep - 1);
    // setOptionSelectedNation(-1);
  };

  const cancelStep = () => {
    setstep((prevStep) => prevStep - 1);
    setOptionSelectedNation(-1);
  };

  const saveQuote = async () => {
    try {
      dispatch(UPDATE_LOADING(true)); // switch on the loader
      setstep((prevStep) => prevStep + 1);

      const petAssureNation = {
        petName: formik?.values?.petName?.trim(),
        petAge: formik?.values?.petAge,
        email: formik?.values?.email,
        phoneNumber: formik?.values?.phoneNumber,
        petType: formik?.values?.petType,
        petBreed: formik?.values?.petBreed,
        zipCode: formik?.values?.zipCode,
        underwritingQuestionOne: formik?.values?.underwritingQuestionOne,
        underwritingQuestionTwo: formik?.values?.underwritingQuestionTwo,
        underwritingQuestionThree: formik?.values?.underwritingQuestionThree,
        underwritingQuestionFour: formik?.values?.underwritingQuestionFour,
        underwritingQuestionCodes: formik?.values?.underwritingQuestionCodes,
        productId: formik?.values?.productId,
        planCode: formik?.values?.productId,
        primaryAddressLine3Display: formik?.values?.address,
        policyNumber: formik?.values?.policyNumber,
        dateAndReason: formik?.values?.dateAndReason,
        medicationDetails: formik?.values?.medicationDetails,
        providerCode: formik?.values?.petHospital,
        coverageLevel: formik?.values?.coverageLevel,
      };

      // Trim input values
      const trimmedPetAssureNation = Object.keys(petAssureNation).reduce((acc, key) => {
        acc[key] = typeof petAssureNation[key] === 'string' ? petAssureNation[key].trim() : petAssureNation[key];
        return acc;
      }, {});

      const reduxState = removeUnwantedProps(currentReduxState, resumeContent.resumeUnwantedProps);
      const stepContext = { portalStep: currentStep, reduxState };
      const coverageBuid = getAllPossibleChoicesConfiguration?.coverage?.bUID;

      const response = await http.post(appConfig.SSP_MID_URL + '/v1/pet-insurance-save', {
        petAssureNation: trimmedPetAssureNation,
        surveyQuestions,
        coveredPersonBuid: personBuid,
        buidSubscribed,
        coverageBuid,
        enrollmentProcessBuid,
        stepContext
      });



      if (response) {
        // let coverages = respObj?.data?._Result?.contract?.subscribedElements[0]?.coverages;
        let coverages = response?.data?.data?.updateSubscribedCoverageInformation?.contract?.subscribedElements?.filter(
          (e) => e.subscribedProduct.productCode.buid == buidSubscribed
        )[0]?.coverages;
        //Product status
        let productStatus = response?.data?.data?.updateSubscribedCoverageInformation?.contract?.subscribedElements?.filter(
          (e) => e.subscribedProduct.productCode.buid == buidSubscribed
        )[0]?.subscribedProduct?.status;

        const payload = {
          product: currentStep,
          petList: [],
        };
        dispatch(DELETE_FULL_PET_LIST(payload));

        const petListPayload = createPetListPayload(coverages, employmentInformation, response?.data?.data?.updateSubscribedCoverageInformation);
        dispatch(ADD_PET_LIST({ product: currentStep, petList: petListPayload }));

        let waiveProduct = false;
        if (coverages?.length) waiveProduct = shouldWaiveCoverage(coverages, response?.data?.data?.updateSubscribedCoverageInformation);
        dispatch(UPDATE_WAIVE_PRODUCT_WITHOUT_PERSONBUID({ waiveProduct, currentStep }));

      }


      // let quoteRes = await saveQuoteData(formik);

      // debugger;
      // await addSubscriptionForChoiceNew1(quoteRes);
    } catch (error) {
      console.error('Failed to save quote', error);
      dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: '520',
        continue: true,
        id: 'petInsuranceSave',
        show: true,
        page: currentStep,
      }));
    } finally {
      await dispatch(updateProcess({ currentStepInfo: { "portalStep": currentStep } }))
      await dispatch(UPDATE_LOADING(false)); // switch off the loader
    }
  };

  const getCoverageConfiguration = async () => {
    let coverageBuid = getAllPossibleChoicesConfiguration?.coverage?.bUID;
    let personBuid =
      getAllPossibleChoicesConfiguration?.possibleInsuredPersons[0]?.insuredPerson?.bUID;
    let res = await dispatch(
      getSubscribedCoverageConfigurationNew(
        currentStep,
        enrollmentProcessBuid,
        subscribedCoverageBuid1,
        personBuid
      )
    );
    // setIsAPI5Done(false);
    // setIsAPI6Done(true);
  };

  const updateCoverage = async (subscribedElementsCoverages, lastAddedBuid, petQuote) => {
    try {

      dispatch(UPDATE_LOADING(true));



      let data = {
        PetAssureNation: {
          petName: formik?.values?.petName?.trim(),
          petAge: formik?.values?.petAge,
          email: formik?.values?.email,
          phoneNumber: formik?.values?.phoneNumber,
          //check why email is not available in formik
          // email:"test@gmail.com",
          petType: formik?.values?.petType,
          petBreed: formik?.values?.petBreed,
          zipCode: formik?.values?.zipCode,
          underwritingQuestionOne: formik?.values?.underwritingQuestionOne,
          underwritingQuestionTwo: formik?.values?.underwritingQuestionTwo,
          underwritingQuestionThree: formik?.values?.underwritingQuestionThree,

          underwritingQuestionFour: formik?.values?.underwritingQuestionFour,
          underwritingQuestionCodes: "testing",
          productId: formik?.values?.productId,
          primaryAddressLine3Display: formik?.values?.address,
          providerCode: formik?.values?.petHospital,
          planCode: formik?.values?.productId,
          coverageLevel: formik?.values?.coverageLevel,
        },
      };

      let respObj = await updateSubscribedCoverageInformationPet({
        enrollmentProcessBuid,
        subscribedElementsCoverages,
        lastAddedBuid,
        petQuote,
        data,

      });

      dispatch(
        onLoadAPI({
          UpdatesubscribeCoverage: respObj.data,
          isAPICallInProgress: false,
        })
      );
      const processInfo = respObj?.data?._Result ?? {};
      dispatch(UPDATE_ENROLLMENT_DATA(processInfo));

      let coverageBuidToDelete = respObj?.data?._Result?.contract?.subscribedElements[0]?.coverages;

      if (coverageBuidToDelete?.length == 1) {
        coverId = coverageBuidToDelete[0];
      } else if (coverageBuidToDelete && coverageBuidToDelete?.length > 1) {
        coverId = coverageBuidToDelete[coverageBuidToDelete?.length - 1];
      }
      let subscribedCoverageBuidNew = coverId?.bUID;

      if (respObj) {
        // let coverages = respObj?.data?._Result?.contract?.subscribedElements[0]?.coverages;
        let coverages = respObj?.data?._Result?.contract?.subscribedElements?.filter(
          (e) => e.subscribedProduct.productCode.buid == buidSubscribed
        )[0]?.coverages;

        //Product status
        let productStatus = respObj?.data?._Result?.contract?.subscribedElements?.filter(
          (e) => e.subscribedProduct.productCode.buid == buidSubscribed
        )[0]?.subscribedProduct?.status;

        const payload = {
          product: currentStep,
          petList: [],
        };
        dispatch(DELETE_FULL_PET_LIST(payload));

        const petListPayload = createPetListPayload(coverages, employmentInformation, respObj?.data?._Result);
        dispatch(ADD_PET_LIST({ product: currentStep, petList: petListPayload }));

        let waiveProduct = false;
        if (coverages?.length) waiveProduct = shouldWaiveCoverage(coverages, respObj?.data?._Result);
        dispatch(UPDATE_WAIVE_PRODUCT_WITHOUT_PERSONBUID({ waiveProduct, currentStep }));

      }

      return false;
    } catch (e) {
    } finally {
      dispatch(UPDATE_LOADING(false));
    }
    // }
  };

  const addSubscriptionForChoiceNew1 = async (quoteRes) => {


    const enrolmentId = currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
    // const productIdentifierBuid = productIdentifierBuid
    // const subscribedProductBuid =
    //   currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;

    let res = await dispatch(addSubscriptionForChoiceNew(
      enrolmentId,
      getAllPossibleChoicesConfiguration,
      currentStep
    ));

    if (res) {
      let coverages = res?._Result?.contract?.subscribedElements?.filter(
        (e) => e.subscribedProduct.productCode.buid == buidSubscribed
      );

      let coveragevalues = coverages[0].coverages;

      let lastElement = coveragevalues[coveragevalues.length - 1];
      let lastAddedBuid = lastElement?.bUID;

      const currentStepData = {
        [currentStep]: {
          AddSubscriptionForChoice: res?._Result,
        },
      };
      getCoverageConfiguration();

      dispatch(
        onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false })
      );

      await updateCoverage(coveragevalues, lastAddedBuid, quoteRes);
    }
  };

  const saveQuoteData = async (formik) => {

    let saveQuoteResponse = await savePetQuote(formik, surveyQuestions);
    const payload = {
      product: currentStep,
      quoteList: saveQuoteResponse,
    };
    dispatch(SAVE_QUOTE_RES(payload));

    setPetQuote(saveQuoteResponse);

    return saveQuoteResponse;
  };





  const sendData = (element, index) => {
    //dispatch this value in redux insuredinfo

    formik.setFieldValue('coverageLevel', element.coverageLevel);
    formik.setFieldValue('costPerPayPeriod', element.costPerPayPeriod);
    formik.setFieldValue('productId', element.productId);
    formik.setFieldValue('coverageId', element.coverageId);
    formik.setFieldValue('waivedCoverage', false);

    // formik.setFieldValue('waivedCoverage',radioSelectedValuePetNation =="waive" ? true:false);
    dispatch(EDIT_PET_NATION(false));
    setOptionSelectedNation(index);
    setOptionSelectedNationData(element);
    dispatch(RADIO_SELECTED_VALUE_PET_NATION_DATA(element));
    dispatch(RADIO_SELECTED_VALUE_PET_NATION(index));
  };

  const checkBtnOneDisabled = (step, formik) => {
    let btnOneDisabled = false;
    if (
      step == 1 &&
      (formik.values.petName == '' ||
        formik.values.petType == '' ||
        formik.values.petAge == '' ||
        formik.values.petBreed == '' || formik.errors.petName)
    ) {
      btnOneDisabled = true;
    }

    return btnOneDisabled;
  };

  let btnTwoDisabled;
  let btnThreeDisabled;
  if (
    step == 3 &&
    (formik.values.underwritingQuestionOne == '' ||
      formik.values.underwritingQuestionTwo == '' ||
      formik.values.underwritingQuestionThree == '' ||
      formik.values.underwritingQuestionFour == '' ||
      (formik.values.underwritingQuestionTwo == 'Yes' && (formik.values.policyNumber == ''||formik.values.policyNumber.length > 20)) ||
      (formik.values.underwritingQuestionThree == 'Yes' && (formik.values.dateAndReason == ''||formik.values.dateAndReason.length > 100)) ||
      (formik.values.underwritingQuestionFour == 'Yes' && (formik.values.medicationDetails == ''||formik.values.medicationDetails.length > 100)) ||
      formik.values.petHospital == 'Please Select One' ||
      formik.values.petHospital == '' ||
      (formik.values.petHospital == "Not one of these" && (formik.errors.city || formik.errors.State || formik.errors.hospitalName))

    )
  ) {
    btnThreeDisabled = true;
  }




  const getProvidersOptions = (e) => {
    setSelectedValue(e.target.value);
    formik.setFieldValue('petHospital', e.target.value);
    if (e.target.value == 'Not one of these') {
      setShowHospitalSection(true);
    } else {
      setShowHospitalSection(false);
    }
  };



  if (step == 2 && radioSelectedValuePetNation === '') {
    btnTwoDisabled = true;
  }
  let btnSaveShow = false;
  if (step == 2 && radioSelectedValuePetNation == 'waive') {
    btnSaveShow = true;
  }

  let optionsListCat = props.catBreeds;
  let optionsListDog = props.dogBreeds;

  let selectedCoverageId = selectedPetData;

  let optionsNewBreed = [];

  let payFrequency = employmentInformation?.payFreqeuncyValueCode;


  let coverageList = [];




  for (let i = 0; i < (petCoverages?.length || 0); i++) {

    if (petCoverages[i] && petCoverages[i].productId && petCoverages[i].coverages) {
      let productId = petCoverages[i].productId;
      let coverages = petCoverages[i].coverages;

      let coverageLevel = coverageLevelMapping[productId] || '';

      coverageList.push({
        coverageId: 'coverage-' + productId,
        coverageLevel: coverageLevel,
        planName: 'My Pet Protection® ',
        planPercentage: (productId?.slice(-2) || '') + '%',
        costPerPayPeriod: ('$' + getCostPerPayPeriod(coverages.annualPremium, payFrequency)),
        productId: productId,
      });
    }
  }


  const orderedProductIds = Object.keys(coverageLevelMapping);

  coverageList.sort((a, b) => {
    const indexA = a && a.productId ? orderedProductIds.indexOf(a.productId) : -1;
    const indexB = b && b.productId ? orderedProductIds.indexOf(b.productId) : -1;

    // If a.productId or b.productId are not found, it will be placed at the end of the sorted list
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });


  function CoverageLevelWithStyledTrademark({ coverageLevel }) {
    const parts = coverageLevel.split('®');
    return (
      <span>
        {parts[0]}
        <span style={{ fontSize: '0.6em', verticalAlign: 'super' }}>®</span>
        {parts[1]}
      </span>
    );
  }

  const handleChangeSelect = (e) => {
    setSelectedValue(e.target.value);
    formik.setFieldValue('petType', e.target.value);
    if (e.target.value == 'dog') {
      optionsNewBreed = serviceHelper.getOptionsFromReduxDogList(optionsListDog);
      setBreeds(optionsNewBreed);
      formik.values.petBreed = '';
      // {...formik.getFieldProps(formFields.petType.value)}
    } else if (e.target.value == 'cat') {
      optionsNewBreed = serviceHelper.getOptionsFromReduxDogList(optionsListCat);
      setBreeds(optionsNewBreed);
      formik.values.petBreed = '';
    }
  };

  const handleChangeUQTwo = (e) => { };

  return (
    <>
      <Styles />
      <div className="py-3">
        <button
          type="button"
          class="btn btn-outline-primary primaryOutline"
          onClick={resetStep}
          data-toggle="modal"
          data-target="#petNationWideModal"
          disabled={props.isCheckboxChecked}
        >
          Add Pet
        </button>
      </div>

      <div className="member-container-pet">

        <div class="modal fade" id="petNationWideModal" data-backdrop="static" data-keyboard="false">



          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class={'modal-header '}>
                <h4 class="modal-title" id="exampleModalLabel">
                  Add a Pet
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <>
                  {step === 1 && (
                    <>
                      <div class="row">
                        <div class="col-12  col-md-6">
                          <div>
                            <div class="row">
                              <div class="col-md-12">
                                <label class="form-side-big-header">Pet Information </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 sdf">
                          <div>
                            <div class="row">
                              <div class="col-md-12">
                                <label class="form-mandatory beneficiary">
                                  Fields marked with * are mandatory
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        {/* Pet name */}
                        <div className="col-12 col-md-6 col-lg-6 py-2">
                          <div className="floatingLabel">
                            <label htmlFor={formFields.petName.id} className="no-floating">{formFields.petName.label}</label>
                            {formFields.petName.isRequired && <span> *</span>}
                            <div>
                              <input
                                id={formFields.petName.id}
                                name={formFields.petName.name}
                                type={formFields.petName.type}
                                placeholder={formFields.petName.placeholder}
                                className="w-100"
                                {...formik.getFieldProps(formFields.petName.name)}
                              />
                              {formik.touched.petName && formik.errors.petName ? (
                                <div class="form-mandatory">{formik.errors.petName}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        {/* Pet Type */}
                        <div className="col-12 col-md-6 col-lg-6 col-sm-6 py-2">
                          <div className="selectContainer">
                            <label htmlFor={formFields.petType.id}>
                              {formFields.petType.label}
                              {formFields.petType.isRequired && <span> *</span>}
                            </label>
                            {formFields?.petType?.options.length > 0 && (
                              <select
                                id={formFields.petType.id}
                                name={formFields.petType.name}
                                className="w-100 selectText"
                                value={selectedValue}
                                onChange={handleChangeSelect}

                              // onChange={e => handleChangeSelect(e.target.value)}
                              // {...formik.getFieldProps(formFields.petType.value)}
                              >
                                <option value="" hidden disabled></option>
                                {formFields?.petType?.options.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* Pet Age */}

                        <div className="col-12 col-md-6 col-lg-6 col-sm-6 py-2 pb-3 mb-3 px-3">
                          <div className="selectContainer">
                            <label htmlFor={formFields.petAge.id}>
                              {formFields.petAge.label}
                              {formFields.petAge.isRequired && <span> *</span>}
                            </label>
                            {formFields?.petAge?.options?.length > 0 && (
                              <select
                                id={formFields.petAge.id}
                                name={formFields.petAge.name}
                                className="w-100 selectText"
                                {...formik.getFieldProps(formFields.petAge.name)}
                              >
                                <option value="" hidden disabled>Select age</option>
                                {formFields.petAge.options.map(option => (
                                  <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                              </select>
                            )}
                            {formik.touched.petAge && formik.errors.petAge ? (
                              <div className="form-mandatory">{formik.errors.petAge}</div>
                            ) : null}
                          </div>
                        </div>


                        {/* Pet Breed */}
                        {/* Pet Type */}
                        <div className="col-12 col-md-6 col-lg-6 col-sm-6 py-2 pb-3 mb-3 px-3">
                          <div className="selectContainer">
                            <label htmlFor={formFields.petBreed.id}>
                              {formFields.petBreed.label}
                              {formFields.petBreed.isRequired && <span> *</span>}
                            </label>
                            {formFields?.petBreed?.options.length > 0 && (
                              <select
                                id={formFields.petBreed.id}
                                name={formFields.petBreed.name}
                                className="w-100 selectText"
                                {...formik.getFieldProps(formFields.petBreed.name)}
                              >
                                <option value="" hidden disabled></option>
                                {breeds.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          onClick={cancelStep}
                          data-dismiss="modal"
                          class="btn btn-outline-primary primaryOutline"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={getCoverages}
                          disabled={checkBtnOneDisabled(step, formik)}
                          class="btn  btn-primary primaryBackground"
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                </>

                {/* Step 2  */}
                {step === 2 && (
                  <div>
                    <div class="row">
                      <div class="col-12  col-md-12 col-lg-12">
                        <div class="row">
                          <div class="col-md-12">
                            <label class="form-side-big-header">Select Coverage Option</label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 sdf">
                            <div>
                              <div class="row">
                                <div class="col-md-12">
                                  <label class="form-mandatory beneficiary">
                                    Fields marked with * are mandatory
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-lg-12 col-md-12">
                            <label class="">
                              Some exclusions may apply. See Policy documents for complete list of
                              exclusions. Premium vary based on plan type, your pet and your state
                              of residence
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <table class="table table-striped ">
                      <thead className="headerTableTemp">
                        <tr>
                          <th className=""></th>
                          <th className="col-5 col-lg-8 col-md-4">COVERAGE LEVEL</th>
                          <th scope="col-6 col-lg-8 col-md-4 text-right">COST PER PAY PERIOD</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coverageList.map((element, index) => {
                          let q = false;

                          if (selectedCoverageId === element.coverageId) {
                            q = true;
                          }

                          return (
                            <tr>
                              <th scope="row">
                                <input
                                  className=""
                                  type="radio"
                                  id={`petNationCoverage_${index}`}
                                  onChange={() => sendData(element, index)}
                                  checked={optionSelectedNation === index}
                                  name="petNationCoverage"
                                />
                              </th>
                              <td>
                                <label htmlFor={`petNationCoverage_${index}`}>
                                  {element?.coverageLevel || ""}
                                </label>
                              </td>
                              <td className="text-right bold-text">{formatDollarAmountWithDecimals(element.costPerPayPeriod)}</td>
                            </tr>
                          );
                        })}


                      </tbody>
                    </table>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-outline-primary primaryOutline"
                        data-dismiss="modal"
                        onClick={cancelStep}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary primaryOutline"
                        onClick={prevStep}
                      >
                        Previous
                      </button>



                      {!btnSaveShow && (
                        <button
                          type="button"
                          id="medicalSurvey"
                          disabled={btnTwoDisabled}
                          onClick={getMedicalSurveyQuestions}
                          class="btn  btn-primary primaryBackground"
                        >
                          Next{btnSaveShow}
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {/* Step 2  */}
                {step === 3 && (
                  <div>
                    <div className="col-12 col-lg-12 col-md-12">
                      <div>
                        <div className="radioGroup w-100">
                          <div class="row">
                            <div class="col-md-12">
                              <label class="form-side-big-header">Underwriting Questions</label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 sdf">
                              <div>
                                <div class="row">
                                  <div class="col-md-12">
                                    <label class="form-mandatory beneficiary">
                                      All the underwriting questions are mandatory to answer.
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-9 py-3">{medicalSurveyQuestions[0]?.question}</div>
                            <div className="col-3">
                              {formFields.underwritingQuestionOne.options.map((option) => (
                                <label className={option.labelClassName}>
                                  <input
                                    type="radio"
                                    // className='pr-xl-2 mr-xl-0 mr-md-2 mr-0 ml-xl-5 ml-md-4 ml-2 pr-0'
                                    className="px-3"
                                    id={option.id}
                                    name={formFields.underwritingQuestionOne.name}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={formik.values.underwritingQuestionOne === option.value}
                                  />
                                  <span>{option.text}</span>
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 col-md-12">
                      <div>
                        <div className="radioGroup w-100">
                          <div className="row">
                            <div className="col-9 py-3">{medicalSurveyQuestions[1]?.question}</div>
                            <div className="col-3">
                              {formFields.underwritingQuestionTwo.options.map((option) => (
                                <label className={option.labelClassName}>
                                  <input
                                    type="radio"
                                    id={option.id}
                                    name={formFields.underwritingQuestionTwo.name}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={formik.values.underwritingQuestionTwo === option.value}
                                  />
                                  <span>{option.text}</span>
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      {formik.values.underwritingQuestionTwo == 'Yes' && (
                        <div className="row">
                          {/* policyNumber*/}
                          <div className="col-12 col-md-6 col-lg-6 py-2">
                            <div className="floatingLabel">
                              {medicalSurveyQuestions[1]?.supplementQuestion}
                              {formFields.policyNumber.isRequired && <span> *</span>}
                              <div>
                                <input
                                  id={formFields.policyNumber.id}
                                  name={formFields.policyNumber.name}
                                  type={formFields.policyNumber.type}
                                  placeholder={formFields.policyNumber.placeholder}
                                  className="w-100"
                                  {...formik.getFieldProps(formFields.policyNumber.name)}
                                />
                                {formik.touched.policyNumber && formik.errors.policyNumber ? (
                                  <div class="form-mandatory">{formik.errors.policyNumber}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-lg-12 col-md-12">
                      <div>
                        <div className="radioGroup w-100">
                          <div className="row">
                            <div className="col-9 py-3">{medicalSurveyQuestions[2]?.question}</div>
                            <div className="col-3">
                              {formFields.underwritingQuestionThree.options.map((option) => (
                                <label className={option.labelClassName}>
                                  <input
                                    type="radio"
                                    id={option.id}
                                    name={formFields.underwritingQuestionThree.name}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={
                                      formik.values.underwritingQuestionThree === option.value
                                    }
                                  />
                                  <span>{option.text}</span>
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      {formik.values.underwritingQuestionThree == 'Yes' && (
                        <div className="row">
                          {/* dateAndReason*/}
                          <div className="col-12 col-md-12 col-lg-9 py-2">
                            <div className="floatingLabel">
                              {medicalSurveyQuestions[2]?.supplementQuestion}
                              {formFields.dateAndReason.isRequired && <span> *</span>}
                            </div>
                          </div>
                          <div className="floatingLabel col-12 col-md-4 col-lg-9 py-2">
                            <textarea
                              id={formFields.dateAndReason.id}
                              name={formFields.dateAndReason.name}
                              type={formFields.dateAndReason.type}
                              placeholder={formFields.dateAndReason.placeholder}
                              className="w-100"
                              {...formik.getFieldProps(formFields.dateAndReason.name)}
                            />
                            {formik.touched.dateAndReason && formik.errors.dateAndReason ? (
                              <div class="form-mandatory">{formik.errors.dateAndReason}</div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-lg-12 col-md-12">
                      <div>
                        <div className="radioGroup w-100">
                          <div className="row">
                            <div className="col-9 py-3">{medicalSurveyQuestions[3]?.question}</div>
                            <div className="col-3">
                              {formFields.underwritingQuestionFour.options.map((option) => (
                                <label className={option.labelClassName}>
                                  <input
                                    type="radio"
                                    id={option.id}
                                    name={formFields.underwritingQuestionFour.name}
                                    value={option.value}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={
                                      formik.values.underwritingQuestionFour === option.value
                                    }
                                  />
                                  <span>{option.text}</span>
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      {formik.values.underwritingQuestionFour == 'Yes' && (
                        <div className="row">
                          {/* medicationDetails*/}
                          <div className="col-12 col-md-12 col-lg-9 py-2">
                            <div className="floatingLabel">
                            {medicalSurveyQuestions[3]?.supplementQuestion}
                              {formFields.medicationDetails.isRequired && <span> *</span>}
                            </div>
                          </div>
                          <div className="floatingLabel col-12 col-md-4 col-lg-9 py-2">
                            <textarea
                              id={formFields.medicationDetails.id}
                              name={formFields.medicationDetails.name}
                              type={formFields.medicationDetails.type}
                              placeholder={formFields.medicationDetails.placeholder}
                              className="w-100"
                              {...formik.getFieldProps(formFields.medicationDetails.name)}
                            />
                            {formik.touched.medicationDetails && formik.errors.medicationDetails ? (
                              <div class="form-mandatory">{formik.errors.medicationDetails}</div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-12 col-lg-12 col-md-12">
                      <div>
                        <div className="radioGroup w-100">
                          <div className="row">
                            <div className="col-12 py-3">
                              {formFields.underwritingQuestionFive.label}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* medicationDetails*/}
                        <div className="col-12 col-md-12 col-lg-12 py-2">
                          <label htmlFor={formFields.petHospital.id} className="floatingLabel">
                            {formFields.underwritingQuestionFiveInfo.label}
                            {formFields.medicationDetails.isRequired && <span> *</span>}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Pet Hospital */}
                    <div className="col-12 col-md-6 col-lg-6 col-sm-6 py-2 pb-3 mb-3">
                      <div className="selectContainer ml-3">
                        {formFields?.petHospital?.options.length > 0 && (
                          <select
                            id={formFields.petHospital.id}
                            name={formFields.petHospital.name}
                            className="w-100 selectText"
                            onChange={getProvidersOptions}

                          // {...formik.getFieldProps(formFields.petHospital.name)}
                          >
                            <option value="" hidden disabled></option>
                            {providers?.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>

                    {showHospitalSection && (
                      <div className="row px-3 rowClass">
                        {/* medicationDetails*/}
                        <div className="col-12 col-md-12 col-lg-12 py-2">
                          <div className="floatingLabel">{formFields.moreOption.label}</div>
                        </div>
                      </div>
                    )}

                    {showHospitalSection && (
                      <div className="col-12 col-md-6 col-lg-6 py-2">
                        <div className="floatingLabel">
                          {formFields.hospitalName.label}
                          {formFields.hospitalName.isRequired && <span> *</span>}
                          <div>
                            <input
                              id={formFields.hospitalName.id}
                              name={formFields.hospitalName.name}
                              type={formFields.hospitalName.type}
                              placeholder={formFields.hospitalName.placeholder}
                              className="w-100"
                              {...formik.getFieldProps(formFields.hospitalName.name)}
                            />
                            {formik.touched.hospitalName && formik.errors.hospitalName ? (
                              <div class="form-mandatory">{formik.errors.hospitalName}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                    {showHospitalSection && (
                      <div className="row px-3">
                        <div className="col-12 col-md-3 col-lg-3 py-2">
                          <div className="floatingLabel">
                            {formFields.city.label}
                            {formFields.city.isRequired && <span> *</span>}
                            <div>
                              <input
                                id={formFields.city.id}
                                name={formFields.city.name}
                                type={formFields.city.type}
                                placeholder={formFields.city.placeholder}
                                className="w-100"
                                {...formik.getFieldProps(formFields.city.name)}
                              />
                              {formik.touched.city && formik.errors.city ? (
                                <div class="form-mandatory errCity">{formik.errors.city}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        {/* Pet Type */}
                        <div className="col-12 col-md-3 col-lg-3 py-1 pb-3 mb-3">
                          <div className="selectContainer">
                            <label htmlFor={formFields.State.id}>
                              {formFields.State.label}
                              {formFields.State.isRequired && <span> *</span>}
                            </label>
                            {formFields?.State?.options.length > 0 && (
                              <select
                                id={formFields.State.id}
                                name={formFields.State.name}
                                className="w-100 selectText"
                                {...formik.getFieldProps(formFields.State.name)}
                              >
                                <option value="" hidden disabled></option>
                                {state?.map((option) => (
                                  <option key={option.value} label={option.text}>
                                    {option.text}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div class="modal-footer">
                      <button
                        type="button"
                        data-dismiss="modal"
                        class="btn btn-outline-primary primaryOutline"
                        onClick={cancelStep}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary primaryOutline"
                        onClick={prevStep}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        data-dismiss="modal"
                        onClick={saveQuote}
                        disabled={btnThreeDisabled}
                        class="btn  btn-primary primaryBackground"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
</div></div>
</div> */}
    </>
  );
};

export default PetComponent;
