import React, { useState, useEffect } from 'react';
import { Styles } from './Styles';
import appConfig from '../../../../../appConfig';
import http from '../../../../../Common/Services/HttpService';
import { useDispatch, useSelector } from 'react-redux';
import { removeUnwantedProps } from '../../Helper/MappingHelper';
import resumeContent from '../../Content/ResumeContent.json'
import { store } from '../../../../../index';
import {
  UPDATE_LOADING,
  DELETE_FULL_PET_LIST,
  ADD_PET_LIST,
  SET_ALERT_MESSAGE,
} from '../../../../../Store/Slices/InsuredInfo';
import { unsubscribeCoverage } from '../../Actions/unsubscribeCoverage';
import { UPDATE_CART_ITEM } from '../../../../../Store/Slices/EmployeeProfile';
import { UPDATE_WAIVE_PRODUCT_WITHOUT_PERSONBUID } from '../../../../../Store/Slices/InsuredInfo';
import { reworkProductEnrollment } from '../../Actions/reworkProductEnrollment';

import { updateProcess } from '../../Actions/updateProcess';
import { createPetListPayload , shouldWaiveCoverage, petCoverageList, showHideDeleteButton } from '../../Helper/PetHelper';
import $ from 'jquery';
import { useNavigationHook } from '../../Hooks/NavigationHook'; 
import { getConfigHelper } from '../../../../Molecules/CustomSection/Helper/MappingHelper';
import iconDelete from '../../../../../Assets/Images/icon-delete.png'; 
import { formatDollarAmountWithDecimals} from "../../../../Utils/Helper/ValidationHelper"

const PetTable = (props) => {
  <Styles />;
  const [data, setData] = useState([]);
  	
  const { currentStep , formsConfig } = useNavigationHook();
  const dispatch = useDispatch();

  const [optionSelectedNation, setOptionSelectedNation] = useState(-1);
  const [optionSelectedNationData, setOptionSelectedNationData] = useState([]);
  const { employeeIndex } = props;
  const personBuid = props.employee[employeeIndex]?.personBuid;
  let buidSubscribed = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.[currentStep]
        ?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]?.coverage?.product?.bUID
  );
  const employmentInformation = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.EmploymentInformation
        ?.employmentInformation
  );
  const {
    radioSelectedValuePetNation,

  } = useSelector((storeState) => storeState.employeeProfile);
  let currentReduxState = useSelector((storeState) => storeState);
  const { insuredInfo, process } = useSelector((storeState) => storeState);
  let petListRedux = insuredInfo[currentStep]?.petList;
  let enrollmentProcessBuid = process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
  let petlist = insuredInfo?.[`${currentStep}`]?.petList;
  const coverages = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList[currentStep]?.AddSubscriptionForChoice
        ?.contract?.subscribedElements[0]?.coverages
  );
  let productFamily = formsConfig?.forms?.[currentStep]?.productFamily; 
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
  const subscribedElements = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements
 
  let subscribedElementsData = subscribedElements?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
  let subscribedProductBuid = subscribedElementsData?.[0]?.subscribedProduct?.buid;
  let subscribedCoverageBuid1 = [];
  for (let i = 0; i < coverages?.length; i++) {
    subscribedCoverageBuid1.push(coverages[i]?.bUID);
  }


 let coverageBuidToDelete = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.UpdatesubscribeCoverage?._Result
      ?.contract?.subscribedElements[0]?.coverages
  );

  let x = coverageBuidToDelete && coverageBuidToDelete[coverageBuidToDelete?.length - 1];






const getCoveragelevel = (coverageLevel) =>{
  let index = coverageLevel.lastIndexOf(" ");
  let coverageValue = coverageLevel.substring(0, (index - 2))
  let tradeMark = coverageLevel.substring(index, (index - 2))
  let coveragePercentage = coverageLevel.substring(++index)
  const valueArray = [coverageValue,tradeMark,coveragePercentage];
  return(valueArray)
}



  useEffect(() => {
    setOptionSelectedNation(radioSelectedValuePetNation);
  }, [radioSelectedValuePetNation]);

//check this
  useEffect(() => {
    setData(petListRedux);
  }, [petListRedux]);

  const handleDelete = async (subscribedCoverageBuid) => {
    try{
    dispatch(UPDATE_LOADING(true));
    const payload = {
      product: currentStep,
      buid: subscribedCoverageBuid,
    };
    const storeFromRedux = store.getState();;
   // dispatch(DELETE_PET_LIST(payload));
   let updatedState = removeUnwantedProps(storeFromRedux, resumeContent.resumeUnwantedProps);

   //combine API for pet deleting
   const response = await http.post(appConfig.SSP_MID_URL + '/v1/pet-insurance-remove', {
    enrollmentProcessBuid,
    subscribedCoverageBuid,
    subscribedProductIdentifierBuid: subscribedProductBuid,
    externalStepContext:"",
     currentStepInfo: { "portalStep": currentStep ,"reduxState":updatedState,"enrollmentProcessBuid":storeFromRedux?.process?.enrollmentProcess?.[0]?.[0]?._Result?.processEntity?.buid ||
     storeFromRedux?.entities?.response?.onLoadResponseList?.GetPendingProcess?._Result?.[0]?.bUID} 
  });

  if( response?.data?.data?.unSubscribeCoverage?.contract?.subscribedElements){
    let coverages = response?.data?.data?.unSubscribeCoverage?.contract?.subscribedElements?.filter(
      (e) => e.subscribedProduct.productCode.buid == buidSubscribed
    )[0]?.coverages;

 
    const petListPayload = createPetListPayload(coverages,  employmentInformation,  response?.data?.data?.unSubscribeCoverage );
    const payload = {
      product: currentStep,
      petList: [],
    };
    dispatch(DELETE_FULL_PET_LIST(payload));
    dispatch(ADD_PET_LIST({product: currentStep, petList : petListPayload}));

    let waiveProduct = false;
    if (coverages?.length ) waiveProduct =  shouldWaiveCoverage(coverages, response?.data?.data?.unSubscribeCoverage);
    dispatch(updateProcess({ currentStepInfo: { "portalStep": currentStep } }))

    dispatch(UPDATE_LOADING(false));
  }
}
catch (error) 
{
  dispatch(SET_ALERT_MESSAGE({
    type: 'error',
    errorCode: '520',
    continue: true,
    id: 'pet-insurance-remove',
    show: true,
    page: currentStep,
  }));
  dispatch(UPDATE_LOADING(false));
}

  
    
    
  };

  //Configuration for coverage table
  const configDataRedux = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.productConfiguration
    ?._Result
  );
  let hasConfig= false
  if(configDataRedux?.premiumFrequency?.isEditable){
    hasConfig=true 
  }

  let deletedID="";
let coverageUniqueId=""
  return (
    <>

    
      {data?.length == 0 &&<div className="title py-3">Add a pet to select coverage.</div>}
      {data?.length > 0 &&<div className="title-main py-3">Pet and Coverage Details</div>}
      {data?.length > 0 && (
        <table class="table table-striped">
          <thead className="headerTableTemp">
            {/* <tbody> */}

            <tr>
              <th>PET NAME</th>
              <th>PET TYPE</th>
              <th>COVERAGE OPTION</th>
              <th>{hasConfig? configDataRedux?.premiumFrequency?.label:`COST PER PAY PERIOD`}</th>
              <th>ACTION</th>
            </tr>
          </thead>
<tbody>
          {data?.map((item, index) => {
    
            let coverageValueToDisplay = item.coverageLevel;
            
           return(
            <> <tr key={index}>
              <td>
                {item.petName ? 
                    item.petName 
            : 
                    <span style={{ color: '#A61003' }}>Please delete this empty record</span>
                }
            </td>
              <td>{item.petType}</td>
              <td>{item?.coverageLevel || ''}</td>
              <td>{formatDollarAmountWithDecimals(item.costPerPayPeriod)}</td>
              <td>
              { 
                item && 
                // item.petId && 
                (showHideDeleteButton.includes(item.petId[0])||item.petName=="") && 
                <img
                tabIndex={0}
                alt="Delete Pet"
                    onClick={() =>{
                        deletedID = index;
                        
                        $('#deleteModal').modal('show');
                        if(item) {
                            coverageUniqueId = item.coverageId;
                            if(coverageUniqueId) {
                                $('.modal-body #hiddenValue').val(coverageUniqueId);
                            }
                        }
                    }}
                    
                    src={iconDelete}
                /> 
                }
              </td>
            </tr>
              <div id="deleteModal" className="modal" tabIndex="-1" data-id="coverageUniqueId">
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                    <div className="modaltitle">Alert!</div>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        $('#deleteModal').modal('hide');

                      }}
                    >
                      X
                    </button>
                  </div>
                  <div className="modal-body py-3">
                    <p className="descprition">Are you sure you want to delete the pet? </p>
                  

                  </div>
                  <div className="modalfooter">
                    {/* <button className="button" data-dismiss="modal" >No</button> */}
                    <div class="modal-footer-delete">
                        <button
                          type="button"
                        
                          data-dismiss="modal"
                          class="btn btn-outline-primary primaryOutline mr-1"
                        >
                         Cancel
                        </button>

                        <button
                          type="button"
                          onClick={() =>handleDelete(coverageUniqueId)}
                          
                          data-dismiss="modal" 
                          class="btn  btn-primary primaryBackground"
                        >
                          Yes
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            </>
            
            )})}
            </tbody>
        </table>
      )}
    </>
  );
};

export default PetTable;