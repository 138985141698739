import React, { useState, useEffect } from 'react';
import NavItem from '../../Molecules/Navigation/NavItem';
import Dropdown from '../../Molecules/Navigation/Dropdown';
import SideDrawer from '../../Molecules/Navigation/SideDrawer';
import { Icon, Backdrop } from '../../Atoms';
import { useSelector } from 'react-redux';
// import './styles.css';
import { Styles } from './styles';
import { Linker } from '../../Atoms';

const NavigationBar = () => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const { userAuth } = useSelector((storeState) => storeState.auth);

  const [openDrawer, setOpenDrawer] = useState(false);
 
  const [content, SetContent] = useState({});
  const [openDropdown, setOpenDropdown] = useState({
    navBarState: content?.navItemState,
  });



  useEffect(() => {
    if((userAuth.userType || userAuth.processType) && currentLanguage ) {
      import(`./Content/${userAuth.userType || userAuth.processType}/${currentLanguage}.json`)
      .then((module) => {
        SetContent(module.default);
      })
      .catch(error => {
        console.error("Failed to load content:", error);
      });
    }
 
  }, [currentLanguage, userAuth.userType, userAuth.processType]);


  useEffect(() => {
    setOpenDropdown({
      navBarState: content.navItemState,
    });
  }, [content]);
  // navBarState: [
  //   { name: 'GROUP INFORMATION', isExpanded: false },
  //   { name: 'BILLING & PAYMENTS', isExpanded: false },
  //   { name: 'EMPLOYEES', isExpanded: false },
  //   { name: 'QUOTES', isExpanded: false },
  //   { name: 'REPORTS', isExpanded: false },
  //   { name: 'FORMS', isExpanded: false },
  // ]

  const [navContent, setNavContent] = useState(null);

  let isExpanded;
  let menuList = null;
  let backdrop;
  let navBarContent;
  const setExpanded = (curNavItem) => {
    setNavContent(null);
    let tempNavBarState = [...openDropdown.navBarState];

    tempNavBarState.forEach((curNavBarState) => {
      let index = tempNavBarState.indexOf(curNavBarState);
      tempNavBarState[index] = { ...tempNavBarState[index] };
      if (tempNavBarState[index].name === curNavItem) {
        tempNavBarState[index].isExpanded = !tempNavBarState[index].isExpanded;
        if (tempNavBarState[index].isExpanded) {
          setNavContent(curNavItem);
        }
      } else {
        tempNavBarState[index].isExpanded = false;
      }
    });
    setOpenDropdown({ navBarState: tempNavBarState });
  };

  const resetDrawerState = () => {
    setOpenDrawer(false);
    setExpanded();
  };

  if (openDrawer) {
    let content = navContent ? navContent : 'Home';
    backdrop = <Backdrop click={resetDrawerState} />;
    navBarContent = (
      <div className="d-lg-none">
        <NavItem item={content}></NavItem>
      </div>
    );
  }
  const keyDate = new Date().getTime();

  if (!content || Object.keys(content).length === 0) {
    return <div></div>;
}
  return (
    <React.Fragment>
      <Styles />
      <div className="navbarContainer ">
         <nav className="navbar flex-nowrap" key={keyDate}>
          <ul className="navbar-nav flex-row d-none d-lg-flex">
            {content?.navBarLinks?.map((navBarLink, index) => {
              menuList = null;
              openDropdown?.navBarState?.forEach((curState) => {
                if (navBarLink?.navLink === curState.name) {
                  menuList = 'true';
                  isExpanded = curState.isExpanded;
                }
              });
              return (
                <>
                  {menuList && (
                    <NavItem
                      key={navBarLink.navLink}
                      item={navBarLink.navLink}
                      expanded={isExpanded}
                      handleOnClick={setExpanded}
                    >
                      {navBarLink.dropdownMenu.length > 0 && (
                        <Dropdown
                          key={`${index} navBarLink.dropdownMenu[0].id`}
                          dropdown={navBarLink.dropdownMenu}
                        />
                      )}
                    </NavItem>
                  )}
                  {menuList == null && (
                    <Linker
                      key={navBarLink.navLink}
                      className={`root-level-link ${navBarLink.className}`}
                      to={navBarLink.to}
                    >
                      {navBarLink.navLink}
                    </Linker>
                  )}
                </>
              );
            })}
          </ul>
          {navBarContent}
          <div className="d-flex flex-row-reverse col d-lg-none">
            <Icon
              icon={['fas', 'bars']}
              size="2x"
              className="iconContainer "
              onClick={() => {
                setOpenDrawer((prevState) => ({
                  ...prevState,
                  openDrawer: !prevState.openDrawer,
                }));
              }}
            ></Icon>
          </div>
        </nav> 
        <div className="d-lg-none">
          <SideDrawer open={openDrawer} onClose={resetDrawerState}>
            <div className="">
              <ul className="">
                {content?.navBarLinks?.map((navBarLink, index) => {
                  menuList = null;
                  isExpanded = undefined;
                  openDropdown?.navBarState?.forEach((curState) => {
                    if (navBarLink.navLink === curState.name) {
                      menuList = 'true';
                      isExpanded = curState.isExpanded;
                    }
                  });
                  return (
                    <>
                      {' '}
                      {menuList && (
                        <NavItem
                          key={navBarLink.navLink}
                          item={navBarLink.navLink}
                          sideBarOpen={openDrawer}
                          expanded={isExpanded}
                          handleOnClick={setExpanded}
                        >
                          {navBarLink.dropdownMenu.length > 0 && (
                            <Dropdown
                              key={`${index} navBarLink.dropdownMenu[0].id`}
                              dropdown={navBarLink.dropdownMenu}
                              onClick={resetDrawerState}
                            />
                          )}
                        </NavItem>
                      )}
                      {menuList == null && (
                        <Linker
                          key={navBarLink.navLink}
                          className={'root-level-link'}
                          to={navBarLink.to}
                          onClick={resetDrawerState}
                        >
                          {navBarLink.navLink}
                        </Linker>
                      )}{' '}
                    </>
                  );
                })}
              </ul>
            </div>
          </SideDrawer>
          {backdrop}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavigationBar;
