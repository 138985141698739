import { onLoadAPI } from "../../../../Store/Slices/Entities/response";
import { SET_CURRENT_STEP_NO } from "../../../../Store/Slices/StepForm";
import ServiceHelper from "../../../Utils/Helper/MappingHelper";
import { UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment';
import { UPDATE_LOADING, SET_ALERT_MESSAGE } from "../../../../Store/Slices/InsuredInfo";

export const unsubscribeCoverage = (params, dataStorageKey, setStep = false) => async(dispatch) => {
    const serviceHelper = new ServiceHelper();
    dispatch(UPDATE_LOADING(true));
    try{
    let respObj = await serviceHelper.sendApiRequest('/unsubscribeCoverage', {
        ...params
    });

    if (respObj.data && respObj.status === 200) {
        dispatch(onLoadAPI({
          [dataStorageKey]: respObj.data,
          isAPICallInProgress: false,
        }))

        if(setStep) {
            dispatch(SET_CURRENT_STEP_NO(respObj.data?._Result?.processEntity?.currentStepNb));
        }
        
        const processInfo=respObj?.data?._Result??{};
        const multipleWaive = respObj?.data;
        dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
        return multipleWaive
    } else {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: respObj?.status || '520',
            id: 'unsubscribeCoverage',
            show: false,
          }));
        return false
    } }
    catch (e){
        console.error(e)
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}