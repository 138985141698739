import React from 'react';
import { useSelector } from 'react-redux';

import ProductDescription from '../Component/Common/ProductDescription';
import CoverageFlowFive from '../Component/Flow5/CoverageFlowFive';

import Content from '../Content/GenericContent.json';
import {useNavigationHook} from '../Hooks/NavigationHook'

const CoverageFlowFiveWrapper = (props) => {
  const { employeeIndex, employee, handleCoverageChange } = props;
  const { currentStep  } = useNavigationHook();
  const buid = employee[employeeIndex]?.personBuid;
  let personBuid = buid;
  
  const insuredInfoCurrentStep =  useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};
  const searchPolicyProcessResource = useSelector((storeState) => storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result)
  const subscribedCoverageBuids = insuredInfoCurrentStep.subscribeCoverageBuids?.filter((e) => e?.personBuid === personBuid)
  const coverageConfiguration = subscribedCoverageBuids?.[0]?.listOfCoveragePlanDesignConfiguration?._Result || []
  return (
    <div>
      <ProductDescription resources={searchPolicyProcessResource} buid={buid} {...{ ...props.parentProps, ...Content.idealContent }} />
      
      <CoverageFlowFive
        {...props}
        tableData={coverageConfiguration}
        onCoverageChange = {handleCoverageChange}
        personBuid={personBuid}
      />
    </div>
  );
};

export default CoverageFlowFiveWrapper;
