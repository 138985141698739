import React, { useState, useEffect, useRef } from 'react';
import Icon from '../Icon';
import PropTypes from 'prop-types';
import Tile from '../Tile';
import FormatHelper from '../../Utils/Helper/FormatHelper';
import StrengthMeter from '../../Utils/Helper/StrengthMeter';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import imageMap from '../../Molecules/CustomSection/Helper/imageHelper';
import { getFileName } from '../../Molecules/CustomSection/Helper/commonHelper';

import Registeration, {
  ADD_EMAIL,
  ADD_PHONE,
  EDIT_EMAIL,
  EDIT_PHONE,
  CODE_VALUE,
  CLEAR_INPUT,
  EMAIL_ERROR,
  EDIT_ELEMENT_ERROR,
  HIDE_VERIFY_BUTTON,
  HIDE_SEND_CODE_BUTTON,
  HIDE_VERIFY_EMAIL_BUTTON,
  DISABLE_CODE_SUBMIT_BTN,
  SET_PHONE_CODE_SUBMIT,
  DISPLAY_EDIT_PHONE_ICON,
  SET_EMAIL_CODE_SUBMIT,
  SHOW_SEND_CODE_BTN,
  SET_PASSWORD,
  SET_REENTER_PASSWORD,
  SET_EMAIL_CODE,
  SET_EMAIL_CODE_ERR,
  SET_LOGIN_OTP,
  SET_OTP_ERROR,
  SET_LOGIN_CODE_SUBMIT,
  SET_MFA_PRIMARY_LOGIN_PAYLOAD,
  DISABLE_CONTINUE_BTN,
  HIDE_MSG,
  SET_PHONE_OTP_ERROR,
  SET_PHONE_CODE_ERR,
  SET_OLD_EMAIL,
  SET_NEW_MOBILE,
  SET_NEW_EMAIL,
  CLEAR_PASSWORD,
  SET_PASSWORD_FAILED,
  CLEAR_RE_PASSWORD,
  TRIGGER_INPUT_ERROR,
  EMAIL_ENTERED,
  PASTE_PHONE,
  PASTE_SSN,
  ADD_SSN,

} from '../../../Store/Slices/Registeration';
import { EMAIL_IS_VERIFIED, HAS_ERROR, SET_EMAIL_EXIST, SET_EMPLOYEE_VALIDATION_ERROR, SET_MFA_SERVICE_UNAVAILABLE } from '../../../Store/Slices/RegisterationError';
import {
  DISABLE_SUBMIT_BUTTON,
  DISABLE_ENTER_CODE,
  DISABLE_PROCESS,
  DISABLE_ENTER_PHONE,
  DISABLE_SUBMIT_CODE_BTN,
} from '../../../Store/Slices/DisableButton';
import Image from '../Image';
import Button from '../Button';
import {
  STORE_CITY,
  STORE_LINE1,
  STORE_LINE2,
  STORE_ZIP_CODE,
} from '../../../Store/Slices/HideShowRow';
import { Alert } from 'bootstrap';
import { SET_SSN_VALUE } from '../../../Store/Slices/ControlSSN';
import StepForm from '../../../Store/Slices/StepForm';
import ServiceHelper from '../../../App/Utils/Helper/MappingHelper';
import $ from 'jquery';
import { EMAIL_OTP, ENABLE_CONTINUE_BTN, ENABLE_SEND_CODE_BTN, MOBILE_BACK, SET_OTP, SET_SEND_EMAIL_CODE, MOBILE_OTP_ENTERED, EMPTY_EMAIL_OTP } from '../../../Store/Slices/CallCenter';
import { validateEmail } from '../../Utils/Helper/ValidationHelper';
import { CONTINUE_BUTTON_STATE } from '../../../Store/Slices/Atoms/button';
const renderErrorMessage = (isEditModal, hasEditError, editErrorMessage) => {
  return isEditModal && hasEditError ? (
    <div>
      <span
        style={{
          color: '#C53532',
          fontFamily: 'NotoSansRegular',
          fontSize: '0.75rem',
          paddingLeft: '0rem',
        }}
      >
        {editErrorMessage}
      </span>
    </div>
  ) : null;
};

const Input = (props) => {
  const {
    id,
    nId,
    name,
    label,
    value,
    defaultValue,
    onChange,
    onBlur,
    className,
    classNameWrapper,
    containerClassName = '',
    hidden,
    hideElement,
    requiredValidation,
    errorFound,
    placeholder,
    element,
    type,
    disabled,
    formik,
    payload,
    tile,
    tileInfo,
    content,
    format,
    shouldBeReadOnly,
    icons,
    shouldTriggerError,
    shouldClearOnReset,
    specialIconLayout,
    style = {},
    imageAfter,
    elementsAfter,
    shouldShowOnYes,
    disableFutureDates = false,
    manualDate = true,
    iconStyle = {},
    needAPIValidation,
    apiErrorMessage,
    apiErrorConfig,
    classNameWrapperAPIError,
    showOnAPISuccess,
    showMatchMessage,
    matchMessageText,
    matchMessageConfig,
    errorClassNameWrapper,
    hideOnDefaultAndSuccess,
    updateLabel,
    isEditModal = false,
    hasEditError = false,
    forAdminEnrollment = false
  } = props;

  const history = useHistory();

  const dispatch = useDispatch();
  let {
    email,
    phone,
    editEmail,
    editPhone,
    shouldClearInput,
    codeValue,
    emailBeReadOnly,
    phoneBeReadOnly,
    emailError,
    elementHasError,
    elementToCheck,
    displayEditPhoneIcon,
    password,
    reenterPassword,
    isPastePhone,
    isPasteSSN,
    ssn
  } = useSelector((storeState) => storeState.registeration);
  const ur = useSelector((storeState) => storeState?.registeration?.url);


  const { hideRow, isYesradioSelected } = useSelector(
    (storeState) => storeState.registerationError
  );

  const policyRadioChecked = useSelector(
    (storeState) => storeState.registerationError.policyRadioClicked
  );
  const coverageRadioChecked = useSelector(
    (storeState) => storeState.registerationError.coverageRadioClicked
  );
  const policyRadioHospitalIndemnityChecked = useSelector(
    (storeState) => storeState.registerationError.policyRadioHospitalIndemnityClicked
  );
  const coverageRadioHospitalIndemnityChecked = useSelector(
    (storeState) => storeState.registerationError.coverageRadioHospitalIndemnityClicked
  );
  const medicalSurveys = useSelector(
    (storeState) => storeState?.process?.medicalSurvey[0]?._Result.medicalSurveys)
  const { hideEl, dependentAddress, hideFields, hideBeneficiaryAddress, liveAtSameAddress } =
    useSelector((storeState) => storeState.hideShowRow);
  const dependentsInfo = useSelector((storeState) => storeState?.employeeProfile?.dependentsInfo);
  const beneficiariesList = useSelector(
    (storeState) => storeState?.employeeProfile?.beneficiariesList
  );
  const radioSelectedValue = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValue
  );

  const radioSelectedValueHospitalIndemnity = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueHospitalIndemnity
  );
  const currentStep = useSelector((storeState) => storeState?.stepForm?.currentStep);
  const { clearSSN, SSNValue } = useSelector((storeState) => storeState?.controlSSN);
  const registerationError = useSelector((storeState) => storeState?.registerationError);
  const registeration = useSelector((storeState) => storeState?.registeration);

  const stepForm = useSelector((storeState) => storeState?.stepForm);
  let { codeIsEntered = false, phoneIsEntered = false } = useSelector((storeState) => storeState.disableButton);
  const { underWritingQuestionTwo, underWritingQuestionThree, underWritingQuestionFour, hospitalSection } = useSelector((storeState) => storeState?.employeeProfile);
  //Get the count of displaying products
  const productListArrayValues = useSelector((storeState) => storeState?.process);
  const preferredContactFromRedux = useSelector((storeState) => storeState?.employeeProfile?.preferredContactDetails);
  const { showSpecial = false, contactType = '', otp = '', mobileBack = false, sendCodeMobileClicked = false, steps = [], sendCode = false } = useSelector((storeState) => storeState.callCenter);

  const { layout } = content || {};
  const [focus, setFocused] = useState(false);
  /** Strength Meter, Formatted Phone Number variables - By Nisreen-2022 */
  const [passwordBarStyle, setPasswordBarStyle] = useState({});
  const [passwordStrength, setPasswordStrength] = useState('');
  const [formattedPhoneNumber, setformattedPhoneNumber] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [emailReadOnly, setEmailReadOnly] = useState(false);
  const [phoneReadOnly, setPhoneReadOnly] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [inputrEADoNLY, setInputReadOnly] = useState('');
  const [payloadArray, setPayloadArray] = useState('');
  const [formattedSSN, setformattedSSN] = useState('');
  const [hideMsg, setHideMsg] = useState(false);
  const [currentDate, setCurrentDate] = useState(false);
  const [maxDateQLE, setMaxDateQLE] = useState(false);
  const [inputType, setInputType] = useState(''); //for register - email only
  const [dateError, setDateError] = useState(false)
  const [dateValue, setDateValue] = useState('');

  const selectedPrefMethod = preferredContactFromRedux?.preferredContact
  let emailRequired = false;
  const windowDays = useSelector(
    (storeState) => {
      let days =
        storeState.process?.contractsLinearOrNonLinear?._Result?.contract?.additionalDatas?.filter(
          (data) => data.fieldCode == 'DD_00156'
        );

      if (days == undefined) {
        return '';
      } else {
        return days[0].fieldValue;
      }
    }
  );

  useEffect(() => {

  }, [registeration.hideMsg, registerationError.emailExist,
  registerationError.activateFactor, registerationError.hidePhoneOnLoad, registeration.sendPhoneClicked,
  registerationError.submitPhoneClicked,
  registerationError[stepForm.currentStep]?.hasError, registerationError.submitPhoneClicked,
  registerationError.phoneIsVerified, codeIsEntered, phoneIsEntered, registerationError.showOtpErr, registerationError.oktaErrorText,
    phone, ssn
  ]);

  useEffect(() => {
  }, [formattedPhoneNumber, phone]);

  useEffect(() => {
  }, [formattedSSN, ssn]);

  // Function for CriticalIllness.SSN
  useEffect(() => {
    if (props.name === 'CriticalIllness.SSN') {
      setformattedSSN('');
    }
  }, [beneficiariesList, clearSSN]);

  useEffect(() => {
    if (props.name === 'EmployeeValidation.SSN') {
      setformattedSSN('');
    }
  }, [clearSSN]);

  /** Set initial focus based on defaultValue */
  useEffect(() => {
    if (defaultValue) {
      onChange &&
        onChange({
          target: {
            name: name || id,
            value: defaultValue,
          },
        });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (element && element.currentDate && !value) {
      onChange &&
        onChange({
          target: {
            name: name || id,
            value: new Date().toISOString().slice(0, 10),
          },
        });
    }
  }, [element && element.currentDate]);


  useEffect(() => {
    if (element?.payload) {
      element.value[0] = { productListArrayValues };
    }
  }, [productListArrayValues]);

  useEffect(() => {
    if (value) {
      setFocused(value != null);
    }
  }, [value]);

  /** Clear code input for Registeration*/
  useEffect(() => {
    shouldClearInput && setInputVal('');
  }, [shouldClearInput]);
  useEffect(() => {
    shouldClearInput && setInputVal('');
  }, []);

  useEffect(() => {
    if (props.name === 'DependentInformation.SSN') {
      setformattedSSN('');
    }
  }, [dependentsInfo, clearSSN]);

  useEffect(() => {
    let date = getCurrentdate();
    let futureDate = getFuturedate()
    setCurrentDate(date)
    setMaxDateQLE(futureDate)
  }, []);

  //get current data
  const getCurrentdate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  //get current data
  const getFuturedate = () => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate.setDate(currentDate.getDate() - windowDays))
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }






  const editErrorMessage = element && element.validations && element.validations.string && element.validations.string.validationRequiredOnTrue && element.validations.string.validationRequiredOnTrue.message || '';

  let isPasswordMatched = password && reenterPassword && password === reenterPassword;
  const serviceHelper = new ServiceHelper();
  let dependentElements = '';
  if (Array.isArray(registerationError[stepForm.currentStep]?.dependentElementsInput))
    for (
      let i = 0;
      i < registerationError[stepForm.currentStep]?.dependentElementsInput.length;
      i++
    )
      if (registerationError[stepForm.currentStep]?.dependentElementsInput[i] === name)
        dependentElements = registerationError[stepForm.currentStep]?.dependentElementsInput[i];

  let shouldHideOnAPIFail =
    showOnAPISuccess && //password
    registerationError[stepForm.currentStep]?.hasError &&
    name !== 'UserAccountVerifyEmail.password' &&
    name !== 'UserAccountVerifyEmail.reenterPassword'; //&& dependentElements

  let noError =
    showOnAPISuccess &&
    !registerationError[stepForm.currentStep]?.hasError &&
    registerationError[stepForm.currentStep]?.dependentElementsInput === name;

  const shouldResetSSN =
    id === 'DependentInformation.SSN' ||
    id === 'EmployeeValidationForgotUserID.SSN' ||
    id === 'EmployeeValidation.SSN' ||
    id === 'CriticalIllness.SSN';



  let tempVal;
  tempVal = codeValue || value;

  const clear = shouldClearInput === true;
  if (contactType === 'email' && showSpecial) {
    if (name === 'ContactDetails.email')
      element.validations = {
        "string": {
          "validationRequiredOnTrue": {
            "value": "createNewAddress",
            "message": "Email is required"
          },
          "email": "Please enter valid email address",
          "required": "Email is required"
        }
      }
    if (name === 'ContactDetails.otpEmail')
      element.validations = {
        "string": {
          "required": "Code is Required",
          "matches": {
            "regex": "^[0-9]{6,6}$",
            "message": "Invalid security code"
          }
        }
      }
  }
  if (contactType === 'mobile') {
    if (name === 'ContactDetails.phoneNumber')
      element.validations = {
        "string": {
          "validationRequiredOnTrue": {
            "value": "createNewAddress",
            "message": "Phone number is required"
          },
          "min": {
            "withCountryCodelimit": 16,
            "limit": 14,
            "message": "Invalid Mobile Phone Number",
            "color": "red"
          },
          "max": {
            "withCountryCodelimit": 16,
            "limit": 14,
            "message": "Invalid Mobile Phone Number",
            "color": "red"
          },
          "matches": {
            "regexwithCountryCode": "^(?=.*?[0-9\b]+$)(?=.*?[()-]).{16,}$",
            "regex": "^(?=.*?[0-9])(?=.*?[()-]).{14,14}$",
            "message": "Invalid Mobile Phone Number"
          }
        }
      }
    if (name === 'ContactDetails.otpMobile' && otp)
      element.validations = {
        "string": {
          "required": "Code is Required",
          "matchesNotNeeded": {
            "regex": "^[0-9]{6,6}$",
            "message": "You have entered wrong OTP"
          }
        }
      }
  }
  let required =
    element?.validations?.string?.required ||
    element?.validations?.string?.validationRequiredOnTrue;
  let PrefContactFlag = false
  if (formik?.values?.EmployeeProfile && currentStep === 'EmployeeProfile') {
    let selectedPrefMethodFormik = formik.values.EmployeeProfile.preferredContact

    if (selectedPrefMethodFormik == 'Email') {
      if (id === 'EmployeeProfile.personalEmail') {
        required = true
        PrefContactFlag = true
      }
    }
    else if (selectedPrefMethodFormik == 'SMS') {
      if (id === 'EmployeeProfile.personalPhone') {
        required = true
        PrefContactFlag = true
      }
    }
    else if (selectedPrefMethodFormik == 'Both') {
      if (id === 'EmployeeProfile.personalPhone' || id === 'EmployeeProfile.personalEmail') {
        required = true
        PrefContactFlag = true
      }
    }
    else if (selectedPrefMethodFormik == 'None') {
      if (id === 'EmployeeProfile.personalPhone' && id === 'EmployeeProfile.personalEmail') {
        required = false
        PrefContactFlag = true
      }
    }
  }
  else if (currentStep === 'EmployeeProfile' && !PrefContactFlag) {
    if (selectedPrefMethod == 'Email') {
      if (id === 'EmployeeProfile.personalEmail') {
        required = true
      }
    }
    else if (selectedPrefMethod == 'SMS') {
      if (id === 'EmployeeProfile.personalPhone') {
        required = true
      }
    }
    else if (selectedPrefMethod == 'Both') {
      if (id === 'EmployeeProfile.personalPhone' || id === 'EmployeeProfile.personalEmail') {
        required = true
      }
    }
    else if (selectedPrefMethod == 'None') {
      if (id === 'EmployeeProfile.personalPhone' && id === 'EmployeeProfile.personalEmail') {
        required = false
      }
    }
  }

  //ManageProfile

  if (formik?.values?.ManageProfile && currentStep === 'ManageProfile') {
    let selectedPrefMethodFormik = formik.values.ManageProfile.preferredContact

    if (selectedPrefMethodFormik == 'Email') {
      if (id === 'ManageProfile.personalEmail') {
        required = true
        PrefContactFlag = true
      }
    }
    else if (selectedPrefMethodFormik == 'SMS') {
      if (id === 'ManageProfile.personalPhone') {
        required = true
        PrefContactFlag = true
      }
    }
    else if (selectedPrefMethodFormik == 'Both') {
      if (id === 'ManageProfile.personalPhone' || id === 'ManageProfile.personalEmail') {
        required = true
        PrefContactFlag = true
      }
    }
    else if (selectedPrefMethodFormik == 'None') {
      if (id === 'ManageProfile.personalPhone' && id === 'ManageProfile.personalEmail') {
        required = false
        PrefContactFlag = true
      }
    }
  }
  else if (currentStep === 'ManageProfile' && !PrefContactFlag) {
    if (selectedPrefMethod == 'Email') {
      if (id === 'ManageProfile.personalEmail') {
        required = true
      }
    }
    else if (selectedPrefMethod == 'SMS') {
      if (id === 'ManageProfile.personalPhone') {
        required = true
      }
    }
    else if (selectedPrefMethod == 'Both') {
      if (id === 'ManageProfile.personalPhone' || id === 'ManageProfile.personalEmail') {
        required = true
      }
    }
    else if (selectedPrefMethod == 'None') {
      if (id === 'ManageProfile.personalPhone' && id === 'ManageProfile.personalEmail') {
        required = false
      }
    }
  }

  let mfaEmailInput = (id === 'UserAccountVerifyEmail.email' || id == 'ContactDetails.email')

  /** Flag for edit operation */
  let delOrBackspace;
  let newLabel
  if (name == "PetAssureNation.policyNumber") {
    newLabel = serviceHelper.getSupplementQuestions(name, medicalSurveys);
    // props.radioLabel = optionsNew;
    // props = {...props, radioLabel = optionsNew};
  }
  /** Create Helper instances */
  const formatHelper = new FormatHelper();
  const strengthMeter = new StrengthMeter();



  const handleFocus = () => {
    setFocused(true);
    if (currentStep === 'MultiFactorAuthentication')
      dispatch(PASTE_PHONE(false))
    if (currentStep === 'EmployeeValidation')
      dispatch(PASTE_SSN(false))
  };
  let shouldLimitMobileLength =
    props.format === 'phoneNumber' || props.name === 'EmployeeProfile.personalPhone';

  let shouldLimitSSNLength =
    props.name === 'EmployeeValidationForgotUserID.SSN' ||
    props.name === 'EmployeeValidation.SSN' ||
    props.name === 'DependentInformation.SSN' ||
    props.name === 'CriticalIllness.SSN';

  let shouldHideElement = registerationError[stepForm.currentStep]?.dependentElementsInput === name;

  let shouldHideOtpEmail =
    registeration?.hideEmailCodeInput && name === 'UserAccountVerifyEmail.otp'; //&& !shouldHideElement

  let submitError = registerationError[currentStep]?.hasError;
  let clearPassword = (name === 'UserAccountVerifyEmail.password') && registeration.clearPassword;
  let clearRePassword = (name === 'UserAccountVerifyEmail.reenterPassword') && registeration.clearRePassword;

  let needToHandleKeyDown =
    props.format === 'phoneNumber' ||
    props.name === 'EmployeeProfile.personalPhone' ||
    props.name === 'EmployeeValidationForgotUserID.SSN' ||
    props.name === 'EmployeeValidation.SSN' ||
    //props.name === 'DependentInformation.SSN' ||
    //props.name === 'CriticalIllness.SSN' ||
    props.name === 'UserAccountVerifyEmail.password' ||
    props.name === 'UserAccountVerifyEmail.reenterPassword' //||
  //props.name ==='UserAccountVerifyEmail.email'
  /** Capture delete(46)/backspace(8) keys */
  const handleKeyDown = (event) => {
    if (
      props.format === 'phoneNumber' ||
      props.name === 'EmployeeProfile.personalPhone' ||
      props.name === 'EmployeeValidationForgotUserID.SSN' ||
      props.name === 'EmployeeValidation.SSN' ||
      props.name === 'DependentInformation.SSN' ||
      props.name === 'CriticalIllness.SSN' ||
      props.name === 'UserAccountVerifyEmail.password' ||
      props.name === 'UserAccountVerifyEmail.reenterPassword'
    ) {
      delOrBackspace = formatHelper.isDeleteOrBackspace(event);
      if (props.name === 'UserAccountVerifyEmail.password' ||
        props.name === 'UserAccountVerifyEmail.reenterPassword') {
        dispatch(HAS_ERROR({ hasError: false }));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({ hasError: false, clearCount: false, key: 'UserAccountVerifyEmail' }));
        dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: true, mfaErrorText: '' }));
        dispatch(SET_PASSWORD_FAILED(false))
      }
    } else if (props.name === "LifeEvent.effectiveDate") {
      validateQLEDate(event.target.value)
    } else if (id === 'MultiFactorAuthentication.phoneNumber')
      setformattedPhoneNumber(formatHelper.getFormatPhoneNumber(event.target.value))
    else if (id === 'EmployeeValidation.SSN')
      setformattedSSN(formatHelper.getFormatSSN(event.target.value))
  };

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setDateValue(event.target.value)
    // Validation for beneficiary percentage field
    if (id === 'beneficiaryPercentage' && value) {
      if (element && element.validations && element.validations.string && element.validations.string.matches && element.validations.string.matches.regex) {
        const regex = new RegExp(element.validations.string.matches.regex);
        if (!regex.test(value)) {
          return false;
        }
      }
    }
    onChange && onChange(event);
    // if (name==="DependentInformation.dependentFirstName" ) formik.setFieldTouched('DependentInformation.dependentFirstName');

    props.parentInputAction && props.parentInputAction(event, formik.values);

    /** if input is for  password then call password strength meter function */
    if (props.passwordStrengthBar) {
      //strengthMeter.handlePassword(event.target.value);
      let verifiedList = strengthMeter.handlePassword(event.target.value);
      setPasswordBarStyle(strengthMeter.getBarColor(verifiedList, content));
      setPasswordStrength(strengthMeter.getPasswordStrength(verifiedList));
    }
    /** if input is for  mobile number then call phone formatter function */
    // if (props.name == 'MultiFactorAuthentication.mobileNumber')
    // commented by Nisreen fr MFA test - Feb 23
    if (
      (props.format === 'phoneNumber' || props.name === 'EmployeeProfile.personalPhone') &&
      event.target.value.length <= 14
    ) {
      //dispatch(DISABLE_PROCESS({ disabled: false })); commented by Nisreen 02-01-23

      let caret = event.target.selectionStart;
      let shouldCursorJump;
      // setformattedPhoneNumber(formatHelper.getFormatPhoneNumber(event.target.value));

      const element = event.target;
      window.requestAnimationFrame(() => {
        shouldCursorJump = formatHelper.shouldCursorJump(isEdit, event);
        //   /**Determine if cursor need to jump/not if delete/backspace pressed*/
        if (!delOrBackspace) {
          /** 1(234)567 8910 => handle 234 */
          if (formatHelper.handleMobileSecondGroup(event, shouldCursorJump)) {
            element.selectionStart = caret;
            element.selectionEnd = caret;
          }

          /** 1(234)567 8910 => handle 567 */
          if (formatHelper.handleMobileThirdGroup(event, shouldCursorJump)) {
            element.selectionStart = caret + 3;
            element.selectionEnd = caret + 3;
          }
        } else {
          element.selectionStart = caret;
          element.selectionEnd = caret;
        }
      });
      !isPastePhone && setformattedPhoneNumber(formatHelper.getFormatPhoneNumber(event.target.value));
      let valueChange = formatHelper.getFormatPhoneNumber(event.target.value, isPastePhone) // null on normal input
      isPastePhone && setformattedPhoneNumber(valueChange)
      dispatch(ADD_PHONE(valueChange))
    }

    if (
      (props.name === 'EmployeeValidationForgotUserID.SSN' ||
        props.name === 'EmployeeValidation.SSN' ||
        props.name === 'DependentInformation.SSN' ||
        props.name === 'CriticalIllness.SSN') &&
      event.target.value.length <= 11
    ) {
      let caret = event.target.selectionStart;
      let shouldCursorJump;
      setformattedSSN('');
      setformattedSSN(formatHelper.getFormatSSN(event.target.value));
      dispatch(SET_SSN_VALUE(formatHelper.getFormatSSN(event.target.value)));
      const element = event.target;
      window.requestAnimationFrame(() => {
        shouldCursorJump = formatHelper.shouldCursorJumpSSN(isEdit, event);
        //   /**Determine if cursor need to jump/not if delete/backspace pressed*/
        if (!delOrBackspace) {
          /** 1(234)567 8910 => handle 234 */
          if (formatHelper.handleSSNSecondGroup(event, shouldCursorJump)) {
            element.selectionStart = caret;
            element.selectionEnd = caret;
          }

          /** 1(234)567 8910 => handle 567 */
          if (formatHelper.handleSSNThirdGroup(event, shouldCursorJump)) {
            element.selectionStart = caret + 3;
            element.selectionEnd = caret + 3;
          }
        } else {
          element.selectionStart = caret;
          element.selectionEnd = caret;
        }
      });

      if (currentStep === 'EmployeeValidation') {
        !isPasteSSN && setformattedSSN(formatHelper.getFormatSSN(event.target.value));
        let valueChange = formatHelper.getFormatSSN(event.target.value, isPasteSSN) // null on normal input
        isPasteSSN && setformattedSSN(valueChange)
        dispatch(ADD_SSN(valueChange))
      }
    }
    // if (props.name ==='UserAccountVerifyEmail.email') {
    if (mfaEmailInput) {
      setInputType('text')
      const pointer = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
    }
    /** Disable/enable submit code button in registeration */
    //MFA
    if (
      name === 'MultiFactorAuthentication.otp' && stepForm.currentStep === 'MultiFactorAuthentication' &&
      (event.target.value.length === 6 || event.target.value.length === 5)
    ) {
      dispatch(SET_LOGIN_OTP({ otp: event.target.value }));
    }
    /** Name should be replaced by flag */
    if (
      (name === 'MultiFactorAuthentication.otp' ||
        //name === 'UserAccountVerifyEmail.otp' ||
        name === 'MFAForgotUsername.otp' ||
        name === 'MFAForgotPassword.otp' ||
        name === 'ContactDetails.otpMobile' ||
        name === 'ContactDetails.otpEmail') &&
      (event.target.value.length === 6 || event.target.value.length === 5)

    ) {
      dispatch(DISABLE_SUBMIT_BUTTON({ disabled: false }));
      dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: true }));
      dispatch(DISABLE_SUBMIT_CODE_BTN({ disableSubmitPhoneBtn: false }));
      dispatch(MOBILE_OTP_ENTERED(true))
    }
    if (name === 'UserAccountVerifyEmail.otp' && event.target.value.length === 6) {
      dispatch(DISABLE_PROCESS({ disabled: false }));
      dispatch(DISABLE_SUBMIT_BUTTON({ disabled: false }));
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: true }));
      dispatch(DISABLE_SUBMIT_CODE_BTN({ disableSubmitEmailBtn: false }));
      dispatch(SET_EMAIL_CODE(event.target.value));

      //dispatch(DISABLE_PROCESS({ disabled: false, currentStep: currentStep }));
    }
    if (name === 'MultiFactorAuthentication.otp' && (event.target.value.length === 6 || event.target.value.length === 5)
    ) {
      dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: true }));
    }
    if (name === 'ContactDetails.otpMobile' && (event.target.value.length === 6 || event.target.value.length === 5)) {
      dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: true }));
      dispatch(SET_OTP(event.target.value))
    }

    if (name === 'ContactDetails.otpEmail' && (event.target.value.length === 6 || event.target.value.length === 5)) {
      // dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: true }));
      dispatch(SET_OTP(event.target.value))
    }

    //     /** Name should be replaced by flag */
    if (
      (name === 'MultiFactorAuthentication.otp' ||
        // name === 'UserAccountVerifyEmail.otp' ||
        name === 'MFAForgotUsername.otp' ||
        name === 'MFAForgotPassword.otp' ||
        name === 'ContactDetails.otpMobile' ||
        name === 'ContactDetails.otpEmail') &&
      (event.target.value.length !== 6 && event.target.value.length !== 5)
    ) {
      dispatch(DISABLE_SUBMIT_BUTTON({ disabled: true }));
      dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: false }));
      dispatch(DISABLE_SUBMIT_CODE_BTN({ disableSubmitPhoneBtn: true }));

      // dispatch(DISABLE_PROCESS({ disabled: true }));
    }
    if (name === 'UserAccountVerifyEmail.otp' && event.target.value.length !== 6) {
      dispatch(DISABLE_PROCESS({ disabled: true }));
      dispatch(DISABLE_SUBMIT_BUTTON({ disabled: true }));
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
      dispatch(DISABLE_SUBMIT_CODE_BTN({ disableSubmitEmailBtn: true }));
      //dispatch(DISABLE_PROCESS({ disabled: true, currentStep: currentStep }));
    }
    if (name === 'MultiFactorAuthentication.otp' && (event.target.value.length !== 6 && event.target.value.length !== 5)
    ) {
      dispatch(DISABLE_PROCESS({ disabled: true }));
      dispatch(DISABLE_SUBMIT_BUTTON({ disabled: true }));
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
      dispatch(DISABLE_SUBMIT_CODE_BTN({ disableSubmitPhoneBtn: true }));
      //dispatch(DISABLE_PROCESS({ disabled: true, currentStep: currentStep }));
    }
    if ((name === 'ContactDetails.otpMobile' || name === 'ContactDetails.otpEmail') && event.target.value.length !== 6 && event.target.value.length !== 5) {
      dispatch(DISABLE_PROCESS({ disabled: true }));
      dispatch(DISABLE_SUBMIT_BUTTON({ disabled: true }));
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
      dispatch(DISABLE_SUBMIT_CODE_BTN({ disableSubmitPhoneBtn: true }));
      //dispatch(DISABLE_PROCESS({ disabled: true, currentStep: currentStep }));
    }
    /** accept new security code after reset input */
    /** Id should be replaced by flag */
    if (
      id === 'MultiFactorAuthentication.otp' ||
      id === 'UserAccountVerifyEmail.otp' ||
      id === 'MFAForgotUsername.otp' ||
      id === 'MFAForgotPassword.otp' ||
      id === 'ContactDetails.otpMobile' ||
      id === 'ContactDetails.otpEmail'
    )
      setInputVal(event.target.value);
    // need to install library for phone validator
    if ((name === 'MultiFactorAuthentication.phoneNumber' || name === 'ContactDetails.otpMobile')
      && event.target.value.length === 14) {
      // setPhoneNumber(event.target.value);
      // dispatch(DISABLE_CODE_SUBMIT_BTN({disableCodeSubmitBtn: true}));
      // dispatch(DISABLE_CODE_SUBMIT_BTN({disableCodeSubmitBtn: true}));
      dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: true }));
      dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: true }));
      dispatch(DISPLAY_EDIT_PHONE_ICON({ displayEditPhoneIcon: true }));
    } else if ((name === 'MultiFactorAuthentication.phoneNumber' || name === 'ContactDetails.otpMobile') && event.target.value.length < 14) {
      // setPhoneNumber(event.target.value);
      // dispatch(DISABLE_CODE_SUBMIT_BTN({disableCodeSubmitBtn: true}));
      dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: true }));
      dispatch(DISPLAY_EDIT_PHONE_ICON({ displayEditPhoneIcon: false }));
    } else dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: false }));
    dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: false }));
    if (name === 'MultiFactorAuthentication.phoneNumber' && errorFound) {
      dispatch(DISPLAY_EDIT_PHONE_ICON({ displayEditPhoneIcon: false }));
    }
    if (name === 'UserAccountVerifyEmail.otp' && event.target.value.length === 6) {
      dispatch(SET_EMAIL_CODE_SUBMIT({ emailCodeSubmitted: true }));
    } else if (name === 'UserAccountVerifyEmail.otp' && event.target.length !== 6)
      dispatch(SET_EMAIL_CODE_SUBMIT({ emailCodeSubmitted: false }));
    if (name === 'MultiFactorAuthentication.otp' && (event.target.value.length === 6 || event.target.value.length === 5)
    ) {
      dispatch(SET_PHONE_CODE_SUBMIT({ phoneCodeSubmitted: true }));
    }
    else if (name === 'MultiFactorAuthentication.otp' && (event.target.value.length !== 6 && event.target.value.length !== 5))
      if (name === 'ContactDetails.otpMobile' && event.target.value.length === 6) {
        dispatch(SET_PHONE_CODE_SUBMIT({ phoneCodeSubmitted: true }));
      }
      else if (name === 'ContactDetails.otpMobile' && event.target.length !== 6 && event.target.value.length !== 5)
        dispatch(SET_PHONE_CODE_SUBMIT({ phoneCodeSubmitted: false }));
    // store login
    // if (name === 'EmployeeValidationForgotPassword.email' &&
    //   !errorFound) {
    //   dispatch(SET_MFA_PRIMARY_LOGIN_PAYLOAD({ login: event.target.value }));
    // }
    if (stepForm.currentStep === 'EmployeeValidationForgotUserID')
      dispatch(DISABLE_CONTINUE_BTN(errorFound))

    // if (name === 'ContactDetails.phoneNumber' && event.target.value.length === 6) {
    //   dispatch(ENABLE_SEND_CODE_BTN(true));
    // }
    if ((name === 'ContactDetails.email' && event.target.value.length > 0 && !errorFound) ||
      (name === 'ContactDetails.otpMobile' && (event.target.value.length === 6 || event.target.value.length === 5))) {
      dispatch(ENABLE_CONTINUE_BTN(true));
      dispatch(MOBILE_BACK(false));

    }

    //mobile  validation
    if (name === 'ContactDetails.phoneNumber' && event.target.value.length === 14)
      dispatch(ENABLE_SEND_CODE_BTN(true));
    else if (name === 'ContactDetails.phoneNumber' && event.target.length !== 14)
      dispatch(ENABLE_SEND_CODE_BTN(false));

    if (name === 'ContactDetails.otpMobile' && (event.target.value.length === 6 || event.target.value.length === 5)) {
      dispatch(ENABLE_CONTINUE_BTN(true));
      dispatch(EMAIL_OTP(true));

    }
    else if (name === 'ContactDetails.otpMobile' && event.target.length !== 6 && event.target.length !== 5)
      dispatch(ENABLE_CONTINUE_BTN(false));
    if (name === 'ContactDetails.email' && event.target.value) {
      dispatch(SET_NEW_EMAIL({ newEmail: event.target.value }));
    }
    /** handle email - registeration */
    if (name === 'UserAccountVerifyEmail.email') {
      dispatch(EDIT_EMAIL({ editEmail: true, email: event.target.value }));
    }
    /** handle phone - registeration */
    if (name === 'MultiFactorAuthentication.phoneNumber') {
      let plainValue = event.target.value
      let formattedValue = formatHelper.getFormatPhoneNumber(plainValue, isPastePhone)
      let phoneNumber = event.target.value.replace(/[^\d]/g, '')
      if (phoneNumber.length === 10) {
        setPhoneReadOnly(true);
        dispatch(EDIT_PHONE({ editPhone: false }))
        isPastePhone && setformattedPhoneNumber(formattedValue)
        if (currentStep === 'MultiFactorAuthentication')
          formik.values.MultiFactorAuthentication.phoneNumber = formattedValue
        onBlur && onBlur(event);
      }
      else {
        setPhoneReadOnly(false);
        dispatch(EDIT_PHONE({ editPhone: true }))
        dispatch(ADD_PHONE(''));
      }
      if (phoneNumber.length < 10) {
        setPhoneReadOnly(false);
        dispatch(EDIT_PHONE({ editPhone: true }))
        dispatch(ADD_PHONE(''));
      }
    }
    if (name === 'UserAccountVerifyEmail.password') {
      dispatch(CLEAR_PASSWORD(false))
    }
    if (name === 'UserAccountVerifyEmail.reenterPassword') {
      dispatch(CLEAR_RE_PASSWORD(false))
    }
    if (name === 'UserAccountVerifyEmail.email') {
    }
    if (name === 'ContactDetails.email') {
      let isValidEmail = validateEmail(event.target.value)
      dispatch(EMAIL_ERROR(!isValidEmail));
      dispatch(EMAIL_ENTERED(isValidEmail))
    }

    if (props.name === "LifeEvent.effectiveDate") {
      setTimeout(function () {
        validateQLEDate(event.target.value)
      }, 1000);
    }
  };

  const validateQLEDate = (value) => {
    if (new Date(value) < new Date(maxDateQLE) || new Date(value) > new Date(currentDate)) {
      dispatch(CONTINUE_BUTTON_STATE(true))
      setDateError(true)
    } else {
      dispatch(CONTINUE_BUTTON_STATE(false))
      setDateError(false)
    }
  }

  const handleBlur = (event) => {
    event.preventDefault();
    //  if (name==="DependentInformation.dependentFirstName" ) formik.setFieldTouched('DependentInformation.dependentFirstName');
    // if (name==="DependentInformation.dependentFirstName" ) formik.handleBlur(event);

    props.parentInputBlurAction &&
      element.apiCallOnBlur &&
      props.parentInputBlurAction(event, formik.values, formik);
    if (!props.value) setFocused(false);
    onBlur && onBlur(event);
    /** track if input should be read-only */
    if (name === 'UserAccountVerifyEmail.email') {
      dispatch(EDIT_EMAIL({ editEmail: false, email: event.target.value }));

      setEmailReadOnly(true);
    }
    if (shouldBeReadOnly && name === 'MultiFactorAuthentication.phoneNumber') {
      dispatch(EDIT_PHONE({ editPhone: false }));
      setPhoneReadOnly(true);
    }
    if (name === 'MultiFactorAuthentication.phoneNumber') {
      let phoneNumber = event.target.value.replace(/[^\d]/g, '')
      if (phoneNumber.length === 10) {
        let plainValue = event.target.value
        let formattedValue = formatHelper.getFormatPhoneNumber(plainValue, isPastePhone)
        setPhoneReadOnly(true);
        dispatch(EDIT_PHONE({ editPhone: false }))
        if (currentStep === 'MultiFactorAuthentication')
          formik.values.MultiFactorAuthentication.phoneNumber = formattedValue
      }
      else {
        setPhoneReadOnly(false);
        dispatch(EDIT_PHONE({ editPhone: true }))
        dispatch(ADD_PHONE(''));
      }
      if (phoneNumber.length < 10) {
        setPhoneReadOnly(false);
        dispatch(EDIT_PHONE({ editPhone: true }))
        dispatch(ADD_PHONE(''));
      }
    }
    (id === 'UserAccountVerifyEmail.otp' || id === 'MultiFactorAuthentication.otp') &&
      dispatch(CLEAR_INPUT({ shouldClearInput: false }));
    if (name === 'UserAccountVerifyEmail.email' && errorFound) {
      dispatch(EMAIL_ERROR(true));
    } else {
      dispatch(EMAIL_ERROR(false));
    }
    // formik.setTouched({ "DependentInformation.dependentFirstName": true });
    if (name === 'MultiFactorAuthentication.phoneNumber' && errorFound) {
      dispatch(DISPLAY_EDIT_PHONE_ICON({ displayEditPhoneIcon: false }));
    }
    if (name === 'UserAccountVerifyEmail.password' && !errorFound) {
      dispatch(SET_PASSWORD({ password: event.target.value }));
    }
    if (name === 'UserAccountVerifyEmail.reenterPassword' && !errorFound) {
      dispatch(SET_REENTER_PASSWORD({ reenterPassword: event.target.value }));
    }
    if (name === 'UserAccountVerifyEmail.otp')
      if (registerationError[stepForm.currentStep]?.hasError && !registerationError.emailIsVerified)
        dispatch(SET_EMAIL_CODE_ERR(true));
      else
        dispatch(SET_EMAIL_CODE_ERR(showAPIErrorMessage));
    if (name === 'UserAccountVerifyEmail.otp')
      dispatch(SET_OTP_ERROR(registerationError[stepForm.currentStep]?.hasError && !registerationError.emailIsVerified));
    if (name === 'EmployeeValidationForgotPassword.login' &&
      !errorFound) {
      let isValidEmail = validateEmail(event.target.value)
      isValidEmail && dispatch(SET_MFA_PRIMARY_LOGIN_PAYLOAD({ login: event.target.value }));
    }
    if (name === 'MultiFactorAuthentication.otp')
      if (registerationError[stepForm.currentStep]?.hasError && !registerationError.phoneIsVerified)
        dispatch(SET_PHONE_CODE_ERR(true));
      else
        dispatch(SET_PHONE_CODE_ERR(showAPIErrorMessage));
    if (name === 'MultiFactorAuthentication.otp')
      dispatch(SET_PHONE_OTP_ERROR(registerationError[stepForm.currentStep]?.hasError && !registerationError.phoneIsVerified));

    // if(name === 'ContactDetails.otpMobile')
    //   if( registerationError[stepForm.currentStep]?.hasError )
    //     dispatch(SET_PHONE_CODE_ERR(true));
    //   else
    //     dispatch(SET_PHONE_CODE_ERR(showAPIErrorMessage));
    // if (name === 'ContactDetails.otpMobile')
    //   dispatch(SET_PHONE_OTP_ERROR(registerationError[stepForm.currentStep]?.hasError ));

    if (stepForm.currentStep === 'EmployeeValidationForgotUserID')
      dispatch(DISABLE_CONTINUE_BTN(errorFound))
    if (name === 'MultiFactorAuthentication.otp' && (event.target.value.length === 6 || event.target.value.length === 5)
    ) {
      dispatch(DISABLE_ENTER_PHONE({ phoneIsEntered: true }));
    }
    if (name === 'ContactDetails.otpMobile' && event.target.value.length === 6) {
      dispatch(DISABLE_CONTINUE_BTN(true));
    }
    if (name === 'ContactDetails.email' && event.target.value) {
      dispatch(SET_NEW_EMAIL({ newEmail: event.target.value }));
    }
    if (name === 'ContactDetails.phoneNumber' && event.target.value) {
      dispatch(SET_NEW_MOBILE({ newMobile: event.target.value }));
      //dispatch(SET_SEND_EMAIL_CODE(true))
    }
    if (name === 'ContactDetails.email') {
      let isValidEmail = validateEmail(event.target.value)
      dispatch(EMAIL_ERROR(!isValidEmail));
      dispatch(EMAIL_ENTERED(isValidEmail))
    }
  };

  let showAPIErrorMessage = (needAPIValidation && (
    (id !== "MultiFactorAuthentication.otp" && registerationError[stepForm.currentStep]?.hasError) || (
      (id == "MultiFactorAuthentication.otp" && registerationError[stepForm.currentStep]?.hasError && registerationError.mfaErrorText)
    ))
  ) ||
    (stepForm.currentStep === 'ContactDetails' && registerationError[stepForm.currentStep]?.hasError);
  name === 'UserAccountVerifyEmail.otp' &&
    !showAPIErrorMessage &&
    dispatch(EMAIL_IS_VERIFIED(true));
  let ivalidIn =
    registerationError.oktaErrorText !== "You do not have permission to access the feature you are requesting" ?
      stepForm.currentStep !== 'ContactDetails' ?
        errorFound || showAPIErrorMessage ? '#C53532' : '' :
        !mobileBack && stepForm.currentStep === 'ContactDetails' && showSpecial && registerationError[stepForm.currentStep]?.hasError ?
          errorFound || showAPIErrorMessage ?
            ((contactType === 'mobile' && otp && registerationError.mfaErrorText === 'You have entered wrong OTP') || contactType === 'email') ?
              '#C53532' :
              '' : '' : '' : '';

  if (props.name === "LifeEvent.effectiveDate") {
    ivalidIn = dateError ? '#C53532' : '';
  }

  let classes = disabled ? `elementDisabled ${className}` : className;
  let errClassNameWrapper = errorClassNameWrapper && errorFound;
  let noEmailCodeErr = !errorFound && name === 'UserAccountVerifyEmail.otp';
  let shouldHideEmailCode = !registeration.displaySubmitCodeBtn && name === 'UserAccountVerifyEmail.otp';
  let shouldHideEmailCode1 =
    registerationError.hideEmailCodeInput && name === 'UserAccountVerifyEmail.otp';
  let codeSubmitted = codeIsEntered && name === 'UserAccountVerifyEmail.otp'
  let test = (showOnAPISuccess && registerationError[stepForm.currentStep]?.hasError) //hide password
  let inputError = !registerationError[stepForm.currentStep]?.hasError && name === 'UserAccountVerifyEmail.otp';

  let additionalClasses =
    hideElement // || test
      ? 'hideElement'
      : !showAPIErrorMessage
        ? errClassNameWrapper
          ? errorClassNameWrapper
          : classNameWrapper
        : classNameWrapperAPIError;

  let imgSrc;
  if (imageAfter)
    try {
      imgSrc = imageMap[getFileName(imageAfter.imageElement.image)]
    } catch (e) {
      imgSrc = '';
    }

  const maxDate = disableFutureDates ? new Date().toISOString().slice(0, 10) : '9999-12-31';

  let dateFlag;
  if (currentStep == "LifeEvent") {
    dateFlag = true;

  }

  let showElement = false;
  if (currentStep == 'CoverageSelection' && (coverageRadioChecked || policyRadioChecked)) {
    showElement = true;
  }
  if (
    currentStep == 'HospitalIndemnity' &&
    (coverageRadioHospitalIndemnityChecked || policyRadioHospitalIndemnityChecked)
  ) {
    showElement = true;
  }

  if (currentStep == "PetAssureNation" && underWritingQuestionTwo && name == "PetAssureNation.policyNumber") {

    showElement = true
  }

  if (currentStep == "PetAssureNation" && underWritingQuestionThree && name == "PetAssureNation.dateAndReason") {

    showElement = true
  }
  if (currentStep == "PetAssureNation" && underWritingQuestionFour && name == "PetAssureNation.medicationDetails") {

    showElement = true
  }
  if (currentStep == "PetAssureNation" && hospitalSection && (name == "PetAssureNation.city" || name == "PetAssureNation.hospitalName")) {

    showElement = true
  }


  let hideEmailCode = registerationError[stepForm.currentStep] && registerationError[stepForm.currentStep].hasError ? false :
    registerationError.hideEmailCodeInput && name === 'UserAccountVerifyEmail.otp';
  let hidePhoneCode = false //temp commented
  let hideEmailOrPhone = false// hideEmailCode || hidePhoneCode;
  let editPhoneClicked = name === 'MultiFactorAuthentication.otp' && !registeration.hideErrMsg;

  let testt = name === 'UserAccountVerifyEmail.otp' &&
    !registerationError[stepForm.currentStep]?.hasError &&
    registeration.submitEmailClicked
  let testt2 = !registeration.verifyEmailClicked
  let names = //need to test
    ((name === 'UserAccountVerifyEmail.otp' && value.length > 0) ||
      (name === 'ContactDetails.otpMobile' && value.length > 0) ||
      editPhoneClicked) &&
    registerationError[stepForm.currentStep]?.hasError && !registerationError.emailIsVerified;

  let invalidOTPPhone = (name === 'MultiFactorAuthentication.otp') && (registerationError.changeMethodClicked || registerationError[stepForm.currentStep]?.hasError)
  let invalidOTPEmailReg = (name === 'UserAccountVerifyEmail.otp') && (registerationError.changeMethodClicked || registerationError[stepForm.currentStep]?.hasError)
  let invalidOTPEmailUser = (name === 'MFAForgotUsername.otp') && (registerationError.changeMethodClicked || registerationError[stepForm.currentStep]?.hasError)
  let invalidOTPEmailPass = (name === 'MFAForgotPassword.otp') && (
    registerationError.changeMethodClicked || (
      registerationError[stepForm.currentStep]?.hasError && registerationError.oktaErrorText !== "You do not have permission to access the feature you are requesting"
    ))
  let invalidOTPEmailLogin = (name === 'MultiFactorAuthenticationLogin.otp') && (registerationError.changeMethodClicked || registerationError[stepForm.currentStep]?.hasError)

  let invalidOTP = invalidOTPEmailReg || invalidOTPEmailUser
  let invalidOTPEmail = invalidOTP || invalidOTPEmailPass
  invalidOTPEmail = invalidOTPEmail || invalidOTPEmailLogin

  let inputErr = registerationError[stepForm.currentStep]?.hasError;
  let resetPasswordNames =
    name === 'MFAForgotPassword.otp' && inputErr
  let retrieveLoginNames =
    name === 'MFAForgotUsername.otp' && inputErr
  // let loginOtp =
  //   name === 'MultiFactorAuthenticationLogin.otp' && inputErr;
  let validateName = names || resetPasswordNames || retrieveLoginNames
  // let validateNameAndloginOtp = validateName || loginOtp;

  let emailExist = (name === 'UserAccountVerifyEmail.email' &&
    registerationError.mfaErrorText === 'Request failed with status code 500' &&
    currentStep === 'UserAccountVerifyEmail') || false;
  let emailExist1 = (name === 'UserAccountVerifyEmail.otp' &&
    registerationError.mfaErrorText === 'Request failed with status code 500' &&
    currentStep === 'UserAccountVerifyEmail') || false;

  let hideEmail = registerationError.emailExist || registerationError.hideOnLoad



  let hideEmailCodeInput1 = (
    ((hideEmail) && name === 'UserAccountVerifyEmail.otp') ||
    (
      registerationError.emailVerified && registeration.submitEmailClicked &&
      name === 'UserAccountVerifyEmail.otp'));

  //let hidePasswordInput = name === 'UserAccountVerifyEmail.password' && !registerationError.emailVerified;
  //let hideRePasswordInput = name === 'UserAccountVerifyEmail.reenterPassword' && !registerationError.emailVerified
  //let hidePassInput = hidePasswordInput || hideRePasswordInput
  let hideEmailCodeInput = hideEmailCodeInput1 || emailExist1
  hideEmailCodeInput = hideEmailCodeInput

  let hidePhoneCodeInput = (
    (!registeration.sendPhoneClicked && name === 'MultiFactorAuthentication.otp') ||
    (!submitError && registeration.submitPhoneClicked &&
      name === 'MultiFactorAuthentication.otp') ||
    (!registeration.showSubmitCodeBtnPhone && name === 'MultiFactorAuthentication.otp' && !submitError)
  )

  let hideCodeInput = hideEmailCodeInput || hidePhoneCodeInput
  let showOtpErr = showAPIErrorMessage && validateName
  let passwordValidation = name === 'PasswordValidation.password'
  let passwordNotNull = password && reenterPassword

  let isOTP = steps.length > 1 && steps[steps.length - 1] === 'otp'

  let invalidOTPMobile = (name === 'ContactDetails.otpMobile' && registerationError[stepForm.currentStep]?.hasError) && !mobileBack && otp && showSpecial
  let invalidEmailAddress =
    name === 'ContactDetails.email' &&
    registerationError[stepForm.currentStep]?.hasError &&
    registerationError.mfaErrorText === 'Email address is already used'
  let invalidEmailOTP =
    name === 'ContactDetails.otpEmail' &&
    registerationError[stepForm.currentStep]?.hasError &&
    registerationError.mfaErrorText === 'You have entered wrong OTP' && showSpecial && isOTP
  const handlePasteChange = (e) => {
    let isValidEmail = validateEmail(e.target.value)
    isValidEmail && dispatch(SET_MFA_PRIMARY_LOGIN_PAYLOAD({ login: e.target.value }));
  }

  const redirectToLogin = () => {
    setTimeout(() => { window.location = ur ? `/enrollment${decodeURIComponent(ur)}` : '/enrollment' }, 5000)
  }

  const formatPhoneOnPaste = (event) => {
    if (
      (props.format === 'phoneNumber' || props.name === 'EmployeeProfile.personalPhone') &&
      event.target.value.length <= 14
    ) {
      //dispatch(DISABLE_PROCESS({ disabled: false })); commented by Nisreen 02-01-23

      let caret = event.target.selectionStart;
      let shouldCursorJump;
      // setformattedPhoneNumber(formatHelper.getFormatPhoneNumber(event.target.value));

      const element = event.target;
      window.requestAnimationFrame(() => {
        shouldCursorJump = formatHelper.shouldCursorJump(isEdit, event);
        //   /**Determine if cursor need to jump/not if delete/backspace pressed*/
        if (!delOrBackspace) {
          /** 1(234)567 8910 => handle 234 */
          if (formatHelper.handleMobileSecondGroup(event, shouldCursorJump)) {
            element.selectionStart = caret;
            element.selectionEnd = caret;
          }

          /** 1(234)567 8910 => handle 567 */
          if (formatHelper.handleMobileThirdGroup(event, shouldCursorJump)) {
            element.selectionStart = caret + 3;
            element.selectionEnd = caret + 3;
          }
        } else {
          element.selectionStart = caret;
          element.selectionEnd = caret;
        }
      });
      setformattedPhoneNumber(formatHelper.getFormatPhoneNumber(event.target.value));
    }
  }

  let shouldRedirectToLoginMFA = registerationError.redirectToLogin && id === 'MultiFactorAuthenticationLogin.otp';
  let shouldRedirectToLoginRetrieveUser = registerationError.redirectToLogin && id === 'MFAForgotUsername.otp';

  let shouldRedirectToLoginRegister = registerationError.redirectToLogin && id === 'UserAccountVerifyEmail.otp';

  let shouldRedirectToLogin = shouldRedirectToLoginMFA || shouldRedirectToLoginRetrieveUser;

  let shouldRedirectToLoginReg = shouldRedirectToLogin //|| shouldRedirectToLoginRegister;
  let regErr = registerationError.errorCount
  let formHasErr = registerationError[stepForm.currentStep]?.hasError
  let notShowAlert = !registerationError.showAlert
  let formNotPassword = (stepForm.currentStep === 'EmployeeValidationForgotPassword')
  let showInvalidUserIdErr = regErr && formHasErr && notShowAlert && formNotPassword
  let clearForwRONGMFA = id === 'UserAccountVerifyEmail.otp' && registerationError.mfaErrorText === 'Invalid Passcode/Answer'
  let wrongOtp = id === 'UserAccountVerifyEmail.otp' && registerationError.mfaErrorText === 'Invalid Passcode/Answer';
  let hidePhoneInput = !registeration.showPhoneOtp || registeration.changeMethodClicked
  let shouldHideOtp = !registerationError['MultiFactorAuthentication']?.hasError || !registeration.activateFactor
  let hidePhoneOnLoad = registerationError.hidePhoneOnLoad

  let showInput1 = registerationError.phoneIsVerified || (registerationError.hidePhoneOnLoad)
  let showInputChange = (registerationError.hidePhoneOnLoad)

  let showInput2 = formHasErr
  let showInput3 = registerationError.phoneIsVerified
  let showInput = showInput1 //|| showInput2
  let noEr1 = !registerationError.hasError || !formHasErr
  let noEr = noEr1 || showInput3
  let submitWoErr = !registerationError[stepForm.currentStep]?.hasError && registerationError.submitPhoneClicked
  let hidePhoneOtp = ((name === 'MultiFactorAuthentication.otp' && showInput1 &&
    (noEr)) || (registeration.changeMethodClicked && name === 'MultiFactorAuthentication.otp'))
  let hideChangePhoneOtp = ((name === 'ContactDetails.otpMobile' && showInputChange && noEr1))

  let showInput4 = !registerationError.activateFactor || registeration.sendPhoneClicked
  let showPhoneOtp1 = (name === 'MultiFactorAuthentication.otp' &&
    (showInput4)
  )
  let showPhoneOtp = showPhoneOtp1
  let hidePhoneOtp1 = false || registeration.emailExist
  let renderPhoneOTPErr = () => {
    let forWrongMobile = registerationError.oktaErrorText === 'Invalid mobile number'
    $("#otpErrorMsg").delay(30000).hide(1);
    return (
      <div id={!forWrongMobile ? "otpErrorMsg" : "mobileErrorMsg"} hidden={registeration.hideMsg || registeration.changeMethodClicked} >
        <span
          style={{
            color: '#C53532',
            fontFamily: 'NotoSansRegular',
            fontSize: '0.75rem',
            paddingLeft: '0rem',
          }}
        >
          {registerationError.oktaErrorText}
        </span>
      </div>
    )
  }

  let renderPhoneOTPReachLimit = () => {
    return (
      <div >

        <span
          style={{
            color: '#C53532',
            fontFamily: 'NotoSansRegular',
            fontSize: '0.75rem',
            paddingLeft: '0rem',
          }}
        >
          {registerationError.oktaReachLimitText}
        </span>
      </div>
    )
  }
  let showPhoneReadOnly = phone && !editPhone && name === 'MultiFactorAuthentication.phoneNumber' &&
    phoneBeReadOnly && !errorFound

  let hideSpecial = name === 'ContactDetails.otpEmail' && !showSpecial ? true :
    name === 'ContactDetails.email' && showSpecial ? true : false

  let hideSpecialOTPMobile = name === 'ContactDetails.otpMobile' && (!sendCodeMobileClicked) ? true : false
  let hideSpecialMobile = name === 'ContactDetails.phoneNumber' && sendCodeMobileClicked ? true : false
  let hideOnWrongMobile = name === 'MultiFactorAuthentication.otp' && registerationError.wrongMobile ? true : false
  let hidePassword = (name === 'UserAccountVerifyEmail.password' || name === 'UserAccountVerifyEmail.reenterPassword') &&
    //!registerationError['UserAccountVerifyEmail']?.hasError && 
    !registeration.setPasswordFailed &&
    !registerationError.emailVerified &&
    (!registeration.passwordAPI || (registeration.passwordAPI && registerationError['UserAccountVerifyEmail']?.hasError)) ? true : false

  let hideOnEditEmailOTP = (name === 'UserAccountVerifyEmail.otp' && !registeration.displaySubmitCodeBtn)
  const handleOTPMobile = () => {
    return (

      <div >
        <span
          style={{
            color: '#C53532',
            fontFamily: 'NotoSansRegular',
            fontSize: '0.75rem',
            paddingLeft: '0rem',
          }}
        >
          You have entered wrong OTP
        </span>
      </div>
    )
  }

  const handleInvalidEmailAddress = () => {
    // dispatch(ENABLE_CONTINUE_BTN(false))
    return (

      <div >
        <span
          style={{
            color: '#C53532',
            fontFamily: 'NotoSansRegular',
            fontSize: '0.75rem',
            paddingLeft: '0rem',
          }}
        >
          {registerationError.mfaErrorText}
        </span>
      </div>
    )
  }
  const handleInvalidEmailOTP = () => {
    // dispatch(ENABLE_CONTINUE_BTN(false))
    return (

      <div >
        <span
          style={{
            color: '#C53532',
            fontFamily: 'NotoSansRegular',
            fontSize: '0.75rem',
            paddingLeft: '0rem',
          }}
        >
          {registerationError.mfaErrorText}
        </span>
      </div>
    )
  }

  const handleEmailExist = () => {
    //dispatch(TRIGGER_INPUT_ERROR(true))
    return (

      <div hidden={registeration.editEmailClicked}>
        <span
          style={{
            color: '#C53532',
            fontFamily: 'NotoSansRegular',
            fontSize: '0.75rem',
            paddingLeft: '0rem',
          }}
        >
          Email address already exists
        </span>
      </div>
    )
  }

  const maxDateOfBirth = new Date().toISOString().split("T")[0];

  if (currentStep === 'MultiFactorAuthentication') {
    if (phone && isPastePhone)
      formik.values.MultiFactorAuthentication.phoneNumber = phone
    else if (!isPastePhone && formattedPhoneNumber)
      formik.values.MultiFactorAuthentication.phoneNumber = formattedPhoneNumber
    phone !== formattedPhoneNumber && isPastePhone && setformattedPhoneNumber(phone)
  }

  if (currentStep === 'EmployeeValidation') {
    if (ssn && isPasteSSN)
      formik.values.EmployeeValidation.SSN = ssn
    else if (!isPasteSSN && formattedSSN)
      formik.values.EmployeeValidation.SSN = formattedSSN
    ssn !== formattedSSN && isPasteSSN && setformattedSSN(ssn)
  }
  return (
    <>
      {/* (shouldShowOnYes && (radioSelectedValue=='Waive' || radioSelectedValue == -1) ) */}
      {/* <div className={(shouldShowOnYes && !isYesradioSelected) ? 'hideElement ' : 'floatingLabel  ' + additionalClasses } hidden={hidden}> */}
      {/* <div className={((shouldShowOnYes && !isYesradioSelected) || (radioSelectedValue=='Waive' || radioSelectedValue == -1) )? 'hideElement ' + additionalClasses : 'floatingLabel  '+ additionalClasses} hidden={hidden}> */}
      {/* <div className={((shouldShowOnYes && !isYesradioSelected) || (radioSelectedValue=='Waive' || radioSelectedValue == -1) )? 'hideElement ' : ''} hidden={hidden}> */}
      {/* <div className={shouldShowOnYes? (( !isYesradioSelected) || !coverageRadioChecked || !coverageRadioChecked|| ((radioSelectedValue=='Waive' || radioSelectedValue == -1) ))?'hideElement ' + additionalClasses :'floatingLabel '+ additionalClasses: '' } hidden={hidden}> */}
      {/* <div className={shouldShowOnYes?  (!(coverageRadioChecked || policyRadioChecked) || ((radioSelectedValue=='Waive' || radioSelectedValue == -1)))  ?'hideElement ' + additionalClasses :'floatingLabel '+ additionalClasses: '' } hidden={hidden}> */}
      <div
        className={
          shouldShowOnYes
            ? !showElement ||
              (currentStep == 'CoverageSelection' &&
                (radioSelectedValue == 'Waive' || radioSelectedValue == -1)) ||
              (currentStep == 'HospitalIndemnity' &&
                (radioSelectedValueHospitalIndemnity == 'Waive' ||
                  radioSelectedValueHospitalIndemnity == -1))
              ? 'hideElement ' + additionalClasses
              : 'floatingLabel ' + additionalClasses
            : containerClassName
        }
        hidden={hidePhoneOtp || hideCodeInput ||
          shouldHideOnAPIFail ||
          hideChangePhoneOtp || hideSpecial ||
          hideSpecialOTPMobile || hideSpecialMobile || hideOnWrongMobile || hidePassword || hideOnEditEmailOTP || hidden}
      >
        {/* <div className={shouldShowOnYes?  (!(coverageRadioChecked || coverageRadioHospitalIndemnityChecked|| policyRadioChecked||policyRadioHospitalIndemnityChecked ) || ((radioSelectedValue=='Waive' || radioSelectedValue == -1))|| ((radioSelectedValueHospitalIndemnity=='Waive' || radioSelectedValueHospitalIndemnity == -1)))  ?'hideElement ' + additionalClasses :'floatingLabel '+ additionalClasses: '' } hidden={hidden}> */}

        {hideEl &&
          (((id === 'DependentInformation.dependentAddressLine1' ||
            id === 'CriticalIllness.beneficiaryAddressLine1') &&
            liveAtSameAddress.line1) ||
            id === 'DependentInformation.dependentAddressLine2' ||
            id === 'CriticalIllness.beneficiaryAddressLine2' ||
            ((id === 'DependentInformation.dependentCity' ||
              id === 'CriticalIllness.beneficiaryCity') &&
              liveAtSameAddress.city) ||
            ((id === 'DependentInformation.dependentZipCode' ||
              id === 'CriticalIllness.beneficiaryZipCode') &&
              liveAtSameAddress.zipCode)) ? (
          <div className="readOnlyClass">
            {id === 'DependentInformation.dependentAddressLine1' ||
              id === 'CriticalIllness.beneficiaryAddressLine1' ? (
              <>
                <div className=" col-12 col-md-auto px-0 ">
                  <span>{liveAtSameAddress.line1} </span>{' '}
                </div>
                <div className=" col-12 col-md-auto px-0 ">
                  <span>{liveAtSameAddress.line2} </span>
                </div>
                <div className=" col-12 col-md-auto px-0 ">
                  <span>
                    {liveAtSameAddress.city}
                    {'  '}
                    {liveAtSameAddress.state} {liveAtSameAddress.zipCode}
                  </span>{' '}
                </div>{' '}
                <br />
              </>
            ) : (
              <span></span>
            )}
          </div>
        ) : (
          <div className={'floatingLabel  ' + additionalClasses} hidden={hidden}>
            {updateLabel ? newLabel : label}
            {/* {(required || requiredValidation) && label && <span>*</span>} */}
            {required && label && <span> *</span>}
            {/*formik.values[`UserAccount`]?.verified && (
        <span className="tileStyle">
          <Tile
            style={tileInfo?.config?.style}
            value={tileInfo?.value}
            config={tileInfo?.config}
            hidden={tileInfo?.hidden}
          ></Tile>
        </span>
        )*/}
            {!shouldBeReadOnly ? (
              <div style={style}>
                {element?.elementLevelMaxDate &&
                  <>
                    <input
                      id={name}
                      onChange={handleChange}
                      name={name}
                      type={type}

                      placeholder={""}
                      className="w-100"
                      onBlur={handleBlur}
                      max={maxDateOfBirth}
                      aria-label={id || name}
                    />
                  </>}

                {!element?.elementLevelMaxDate &&


                  <input
                    aria-label={id || name}
                    id={id || name}
                    name={name || id}
                    onChange={handleChange}
                    type={type}
                    // max={maxDate}
                    onBlur={handleBlur}
                    // min={dateFlag ? maxDateQLE : " "}
                    max={dateFlag ? currentDate : maxDate}
                    value={!clearPassword ?
                      !clearRePassword ?
                        !shouldClearOnReset
                          ? !shouldResetSSN
                            ? (dateFlag ? dateValue : formattedPhoneNumber || value || '')
                            : SSNValue
                          : inputVal
                        : ''
                      : ''
                    }
                    required={required || requiredValidation}
                    // required={
                    //       ((name === "ContactDetails.otpEmail" && contactType === 'mobile') ||
                    //       (name === "ContactDetails.email" && contactType === 'mobile')
                    //     )
                    //     ? false 
                    //   :required || requiredValidation }
                    disabled={disabled}
                    placeholder={placeholder}
                    className={
                      (value && requiredValidation) || !requiredValidation
                        ? 'w-100  ' + classes
                        : 'w-100 required  ' + classes
                    }
                    data-testid={id}
                    style={{ borderColor: ivalidIn }}
                    //onKeyDown={!manualDate ? (e) => e.preventDefault() : handleKeyDown}
                    onKeyDown={!manualDate && !needToHandleKeyDown ? (e) => e.preventDefault() : handleKeyDown}
                    maxLength={shouldLimitMobileLength ? 14 : shouldLimitSSNLength ? 11 : ''}
                    autoComplete="new-password"
                    onPaste={(e) => {
                      e.persist();
                      if (id === 'EmployeeValidationForgotPassword.login')
                        if (e.target.length === 6 || e.target.length === 5)
                          if (id === 'MultiFactorAuthentication.otp')
                            dispatch(DISABLE_ENTER_PHONE({ login: e.target.value }));
                      if (id === 'UserAccountVerifyEmail.otp')
                        dispatch(DISABLE_ENTER_CODE({ login: e.target.value }));
                      if (id === 'EmployeeValidationForgotPassword.login') {
                        setTimeout(() => { handlePasteChange(e) }, 4)
                      }
                      if (id === 'EmployeeValidation.SSN')
                        dispatch(PASTE_SSN(true))
                    }}

                  />


                }


              </div>
            ) : (
              <>
                {/**  read only for email */}
                {(email &&
                  !editEmail &&
                  !emailError &&
                  name === 'UserAccountVerifyEmail.email' &&
                  !errorFound) ||
                  (email &&
                    !editEmail &&
                    name === 'UserAccountVerifyEmail.email' &&
                    emailBeReadOnly &&
                    !emailError &&
                    !errorFound) ? (
                  <div className="readOnlyClass">{email}</div>
                ) : (phone &&
                  !editPhone &&
                  name === 'MultiFactorAuthentication.phoneNumber' &&
                  !errorFound) ||
                  (phone &&
                    !editPhone &&
                    name === 'MultiFactorAuthentication.phoneNumber' &&
                    phoneBeReadOnly &&
                    !errorFound) ? (
                  <div className="readOnlyClass">{phone}</div>
                ) : (
                  /** below input for register: email, phone  */
                  <input
                    aria-label={id || name}
                    id={id || name}
                    name={name || id}
                    onChange={handleChange}
                    type={!mfaEmailInput ? type : 'text'}

                    // type={type}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    required={required || requiredValidation}
                    //   required={
                    //     ((name === "ContactDetails.otpEmail" && contactType === 'mobile') ||
                    //     (name === "ContactDetails.email" && contactType === 'mobile')
                    //   )
                    //   ? false 
                    // :required || requiredValidation }


                    value={
                      id !== 'MultiFactorAuthentication.phoneNumber' ?
                        !(wrongOtp) ?
                          !shouldClearOnReset
                            ? !shouldResetSSN
                              ? formattedPhoneNumber || value || ''
                              : SSNValue
                            : inputVal
                          : wrongOtp ? '' : ''
                        : (phone === formattedPhoneNumber && !delOrBackspace) || delOrBackspace
                          ? formattedPhoneNumber
                          : formattedPhoneNumber || value || ''
                    }
                    disabled={disabled}
                    placeholder={placeholder}
                    className={
                      (value && requiredValidation) || !requiredValidation
                        ? 'w-100  ' + classes
                        : 'w-100 required ' + classes
                    }
                    data-testid={id}
                    style={{ borderColor: ivalidIn }}
                    onKeyDown={handleKeyDown}
                    maxLength={shouldLimitMobileLength ? 14 : shouldLimitSSNLength ? 11 : ''}
                    autoComplete="new-password"
                    onPaste={(e) => {
                      e.persist();
                      dispatch(PASTE_PHONE(true))
                      setTimeout(() => { handlePasteChange(e) }, 4)
                    }
                    }
                  />
                )}
              </>
            )}
            {/** render password strength meter for  input password only */}
            {
              !clearPassword ?
                props.type === 'password' && !matchMessageText ? (
                  <>
                    <div className={layout?.passwordBarStyle?.progressClasses?.rowWrapper}>
                      <div
                        className={layout?.passwordBarStyle?.progressClasses?.colWrapper}
                        style={{ width: '100%', backgroundColor: 'white' }}
                      >
                        <div className="row mr-0 pr-0 my-1 w-100 ">
                          <div
                            className="col-1  mr-1 mt-1"
                            role="progressbar"
                            style={{
                              backgroundColor: passwordBarStyle?.barColors?.firstBarColor,
                              height: layout?.passwordBarStyle?.height,
                            }}
                          ></div>
                          <div
                            className="col-1  mr-1 mt-1"
                            role="progressbar"
                            style={{
                              backgroundColor: passwordBarStyle?.barColors?.secondBarColor,
                              height: layout?.passwordBarStyle?.height,
                            }}
                          ></div>
                          <div
                            className="col-1  mr-1 mt-1"
                            role="progressbar"
                            style={{
                              backgroundColor: passwordBarStyle?.barColors?.thirdBarColor,
                              height: layout?.passwordBarStyle?.height,
                            }}
                          ></div>
                          <div
                            className="col-1  mr-1 mt-1"
                            role="progressbar"
                            style={{
                              backgroundColor: passwordBarStyle?.barColors?.fourthBarColor,
                              height: layout?.passwordBarStyle?.height,
                            }}
                          ></div>
                          <div
                            className="col-2  mr-0 pr-0 ml-auto mt-1 py-0 "
                            style={!passwordValidation ? {
                              color: passwordBarStyle?.barColors?.firstBarColor,
                              float: 'right',
                              fontSize: '8pt',
                              height: layout?.passwordBarStyle?.messageHeight,
                              paddingLeft: passwordStrength === 'Medium' ? '0.25rem' : '1.4rem',
                              marginLeft: passwordStrength === 'Medium' ? 'auto' : '4rem !important',
                            }
                              : {
                                color: passwordBarStyle?.barColors?.firstBarColor,
                                float: 'right',
                                fontSize: '8pt',
                                height: layout?.passwordBarStyle?.messageHeight,
                                paddingLeft: passwordStrength === 'Medium' ? '0.25rem' : '1.4rem',
                                marginLeft: passwordStrength === 'Medium' ? 'auto' : '4rem !important'
                              }}
                          >
                            {passwordStrength}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : props.type === 'password' && !errorFound ? (
                  <div style={{ textAlign: 'right' }}>
                    <span
                      style={{
                        fontSize: matchMessageConfig.style.fontSize,
                        color: matchMessageConfig.style.color,
                      }}
                    >
                      {/* {isPasswordMatched ? matchMessageText : !isPasswordMatched ? '' : ''} */}
                    </span>
                    {/* {matchMessageText}</span> */}
                  </div>
                ) : errorFound ? (
                  ''
                ) : (
                  ''
                ) : ''}
            {label && (
              <label
                // className={focus || props.type === 'date' ? 'isFocused' : ''}
                className={`visually-hidden ${focus || props.type === 'date' ? 'isFocused' : ''}`}
                htmlFor={name || id}
                aria-label={label}
              >
                {label}
              </label>
            )}
            {props.type === 'date' && (
              <Icon
                icon={['far', 'calendar-alt']}
                className={specialIconLayout ? 'specialCalendarIcon' : 'calendarIcon'}
                style={iconStyle}
              />
            )}{' '}
            {/* {props.type === 'password' && <Icon icon={['far', 'calendar-alt']} className="calendarIcon" />} */}
          </div>
        )}
      </div>
      {renderErrorMessage(isEditModal, hasEditError, editErrorMessage)}
      {
        !registeration.tryAnotherMothod ?
          (invalidOTPEmail && !registeration.changeMethodClicked && !errorFound) && (
            !registerationError.oktaErrorText &&
            <div hidden={hideCodeInput}>
              <span
                style={{
                  color: '#C53532',
                  fontFamily: 'NotoSansRegular',
                  fontSize: '0.75rem',
                  paddingLeft: '0rem',
                }}
              >
                Invalid security code
              </span>
            </div>
          ) : registeration.tryAnotherMothod ? '' : ''
      }

      {
        !registeration.tryAnotherMothod ?
          (invalidOTPPhone && !registeration.changeMethodClicked && !errorFound) &&
          !registerationError.oktaReachLimitText && (
            !registerationError.oktaErrorText && currentStep === 'MultiFactorAuthentication' && registeration.otp && registerationError.mfaErrorText &&
            <div>
              <span
                style={{
                  color: '#C53532',
                  fontFamily: 'NotoSansRegular',
                  fontSize: '0.75rem',
                  paddingLeft: '0rem',
                }}
              >
                Invalid security code
              </span>
            </div>
          ) : registeration.tryAnotherMothod ? '' : ''
      }
      {
        invalidOTPMobile && !errorFound && registerationError.mfaErrorText && registerationError.mfaErrorText === 'You have entered wrong OTP' &&
        handleOTPMobile()
      }
      {
        invalidEmailAddress && !errorFound && handleInvalidEmailAddress()
      }
      {
        invalidEmailOTP && !errorFound && handleInvalidEmailOTP()
      }

      {
        registerationError.oktaErrorText && //registerationError.oktaErrorText !== 'Invalid mobile number' &&
        id === 'MultiFactorAuthentication.otp' && renderPhoneOTPErr()
      }
      {
        registerationError.oktaReachLimitText &&
        id === 'MultiFactorAuthentication.otp' && renderPhoneOTPReachLimit()
      }
      {
        showInvalidUserIdErr && !errorFound ? (
          <div>
            <span
              style={{
                color: '#C53532',
                fontFamily: 'NotoSansRegular',
                fontSize: '0.75rem',
                paddingLeft: '0rem',
              }}
            >
              Invalid User ID
            </span>
          </div>
        ) : ''
      }


      {
        shouldRedirectToLoginReg && (
          <div>
            <span
              style={{
                color: '#C53532',
                fontFamily: 'NotoSansRegular',
                fontSize: '0.75rem',
                paddingLeft: '0rem',
              }}
            >
              Account locked, redirecting to Login Page...
            </span>
          </div>
        )
      }

      {dateError && (
        <div>
          <span
            style={{
              color: '#C53532',
              fontFamily: 'NotoSansRegular',
              fontSize: '0.75rem',
              paddingLeft: '0rem',
            }}
          >
            The enrollment window for this life event has expired.
          </span>
        </div>
      )}
      {shouldRedirectToLoginReg && redirectToLogin()}
      {emailExist && handleEmailExist()}
      {
        shouldRedirectToLoginReg && redirectToLogin()
      }
    </>
  );
};
Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  hideElement: PropTypes.string,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  tile: PropTypes.bool,
};
Input.defaultProps = {
  value: '',
  hidden: false,
  className: '',
  classNameWrapper: '',
  type: 'text',
  required: false,
  defaultValue: null,
};

export default Input;