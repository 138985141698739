import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Atoms/Loader';
import { FormBuilder } from '../FormBuilder';
import MultiSectionForm from './MultiSectionForm';
import StepForm from './StepForm';
import { ElementsCard, Image, Linker } from '../../../Atoms';
import SearchForm from './SearchForm';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import ServiceHelper from '../../../Utils/Helper/MappingHelper';
import SuccessMessagePage from './successMessagePage';
import './styles.css';
import { SHOW_LOADER } from '../../../../Store/Slices/Loader';


import { reduxConstants } from '../../../../Common/Constants/index';

import { callLoggedUserEntity } from '../../../../Store/Slices/Auth/loggedUserEntity';

import { CURRENT_STEP,STEP_KEYS } from '../../../../Store/Slices/StepForm';

function FormSelector(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  let [redirectCallStarted, setRedirectCallStarted] = useState(false);
  let [skipFormToEnd, setSkipFormToEnd] = useState(props.skipForm || false);
  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.uiPath)) || {};
  const { isSubmitLoading } = useSelector((storeState) => storeState.entities.response);
  const {
    contentData,
    currentStep,
    SetContentData,
    updateFormData,
    formik,
    data,
    content,
    deleteRecord = () => { },
    editRecord = () => { },
    onChangeCheckBox = () => {},
    showContent = [],
    displayContent = {},
  } = props;
  const { stepKeys, isStepFormSubmitted, apiError, loadingCheck } = contentData || {};
  const isFirst = currentStep === 0;
  // const isLast = currentStep === stepKeys.length - 1;
  const isLast = currentStep === stepKeys?.length - 1;


  let { isAPICallInProgress } = useSelector((storeState) => storeState.entities.response);
  let { loader } = useSelector((storeState) => storeState.loader);
  let {
    formType,
    confirmation,
    formLayoutType = '',
    displayErrorModel,
  } = (props.content && props.content.layout) || {};
  let {
    submissionTextBefore,
    submissionTextAfter,
    confirmationMessage,
    formReference = [],
    confirmationImage = {},
    redirectURL,
    redirectMessage,

    tipTitle,
    tipMessage,
    tipImage = {},
    formReferenceLinkText,
  } = confirmation || {};

  const { layoutData, currentForm, formElements } = props;
  // const { contentData } = props;

  useEffect(() => {
    $('#errorModal').on('shown.bs.modal', function () { });
  }, []);
  useEffect(() => {
    $('#errorModal').modal('show');
  }, [data]);

  useEffect(() => {
    setSkipFormToEnd(props.skipForm);
  });



  const [handleChangeCheck, setHandleChangeCheck] = useState(false);

  const onColorChange = (isColorSelected) => {
    setHandleChangeCheck(isColorSelected);
  };

  const serviceHelper = new ServiceHelper();

  const elementColor = useSelector((storeState) => storeState?.theme?.selectedThemeDataToUpdate);

  const mappingReduxObject = useSelector(
    (storeState) => storeState?.entities?.response?.loginusername?._Result
  );
  /** timeout slow app flow - Nisreen*/
  useEffect(() => {
    if (currentForm && formElements.length > 0) {
      setTimeout(() => {
        if (Object.keys(layoutData.length > 0)) {
          serviceHelper.dataMappingFromRedux(formElements, layoutData, handleChangeCheck, formik);
          //  serviceHelper.updateReadOnly(value,element, formik, formName,props);
        }
      }, 100);
    }
  }, [layoutData, currentForm, formElements]);

  useEffect(() => {
    let formListNames = Object.keys(contentData.formsList);
    /* useEffect will trigger when form fields value changes */
    for (let itemKey in contentData.formsList) {
      let currentForm = contentData.formsList[itemKey];
      let curStep = formListNames.indexOf(itemKey);
      /* element, isMultiple and multiConfig information are collected from forms which is specific for page */
      let elements = currentForm ? currentForm.elements : null;

      let treeCard = currentForm ? (currentForm.treedata ? true : false) : false;
      let isMultiple = currentForm ? currentForm.isMultiple : false;
      let multiConfig = currentForm && currentForm.isMultiple ? currentForm.multiConfig : {};
      let changeConfig = currentForm && currentForm.changeConfig;
      let triConfig = currentForm && currentForm.triConfig;
      let listElements = currentForm && currentForm.listElements;
      let productTable = currentForm && currentForm.productTable;
      let manageBeneficiariesTable = currentForm && currentForm.manageBeneficiariesTable;
      let iFrameElements = currentForm && currentForm.iFrameElements;
      //added sectionPanels
      let sectionPanels = (currentForm && currentForm.sectionPanels) || {};
      const hasSections = Object.keys(sectionPanels).length > 0;

      let customSection = (currentForm && currentForm.customSection) || {};
      const hasCustomSection = Object.keys(customSection).length > 0;

      const hasCrossSell = !!currentForm && currentForm.crossSell;
      const crossSell = currentForm.crossSell;

      const modals = (currentForm && currentForm.modals) || [];

      //Update theme code
      if (elements && elements[0] && elements[0][0] && elements[0][0].type == 'color') {
        serviceHelper.setFormReduxDataMapping(elements, elementColor, handleChangeCheck, formik);
      }

      //Mapping employee profile
      if (elements && elements[0] && elements[0][0] && elements[0][0].mapping == true) {
        serviceHelper.setFormReduxDataMapping(
          elements,
          mappingReduxObject,
          handleChangeCheck,
          formik
        );
      }

      //Readonly field value
      if (elements) {
        serviceHelper.setReadOnlyValueMapping(elements, formik);
      }

      SetContentData((prevContentData) => {
        if (
          content &&
          content.forms &&
          ((content.forms[itemKey] && content.forms[itemKey].innerForm) ||
            itemKey.includes('AddInsured'))
        ) {
          let innerFormSteps = {};
          for (let innerItemKey in currentForm) {
            let elements = currentForm[innerItemKey] ? currentForm[innerItemKey].elements : null;
            let isMultiple = currentForm[innerItemKey]
              ? currentForm[innerItemKey].isMultiple
              : false;
            let multiConfig =
              currentForm && currentForm[innerItemKey] && currentForm[innerItemKey].isMultiple
                ? currentForm[innerItemKey].multiConfig
                : {};
            let changeConfig =
              currentForm && currentForm[innerItemKey] && currentForm[innerItemKey].changeConfig;
            let triConfig =
              currentForm && currentForm[innerItemKey] && currentForm[innerItemKey].triConfig;
            let listElements =
              currentForm && currentForm[innerItemKey] && currentForm[innerItemKey].listElements;
            let productTable = currentForm && currentForm[innerItemKey] && currentForm[innerItemKey].productTable;
            let manageBeneficiariesTable = currentForm && currentForm[innerItemKey] && currentForm[innerItemKey].manageBeneficiariesTable;
            let iFrameElements =
            currentForm && currentForm[innerItemKey] && currentForm[innerItemKey].iFrameElements;
            //added below code for sectionpanels
            let sectionPanels =
              (currentForm &&
                currentForm[innerItemKey] &&
                currentForm[innerItemKey].sectionPanels) ||
              {};
            innerFormSteps = {
              ...innerFormSteps,
              [innerItemKey]: [
                <FormBuilder //replace as DynamicComponent for component loading
                  //replace as FormBuilder for json loading
                  {...props}
                  key={'FormBuilder' + itemKey}
                  keyInnerForm={'FormBuilder' + itemKey}
                  elements={elements}
                  contentData={contentData}
                  formName={itemKey}
                  formData={contentData.formData[itemKey]}
                  updateFormData={updateFormData}
                  multiConfig={multiConfig}
                  formik={formik}
                  isMultiple={isMultiple}
                  changeConfig={changeConfig}
                  triConfig={triConfig}
                  listElements={listElements}
                  iFrameElements={iFrameElements}
                  innerForm={true}
                  innerFormName={innerItemKey}
                   productTable={productTable}
                   manageBeneficiariesTable={manageBeneficiariesTable}
                  content={
                    itemKey.includes('AddInsured')
                      ? contentData.innerFormContent['AddInsuredInformation']
                      : contentData.innerFormContent[itemKey]
                  }
                  modals={modals}
                  deleteRecord={deleteRecord}
                  editRecord={editRecord}
                  onChangeCheckBox={onChangeCheckBox}
                  showContent={showContent}
                  displayContent={displayContent}
                />,
              ],
            };
          }

          let innerSteps = { [itemKey]: innerFormSteps };
          return {
            ...prevContentData,
            steps: {
              ...prevContentData.steps,
              ...innerSteps,
            },
          };
        } else {
          let tempSteps = {
            ...prevContentData.steps,
            [itemKey]: [
              <FormBuilder //replace as DynamicComponent for component loading
                //replace as FormBuilder for json loading
                {...props}
                key={'FormBuilder' + itemKey}
                keyOuterForm={'FormBuilder' + itemKey}
                elements={elements}
                treeCard={treeCard}
                contentData={contentData}
                formName={itemKey}
                formData={contentData.formData[itemKey]}
                updateFormData={updateFormData}
                multiConfig={multiConfig}
                formik={formik}
                isMultiple={isMultiple}
                changeConfig={changeConfig}
                triConfig={triConfig}
                listElements={listElements}
                iFrameElements={iFrameElements}
                sectionPanels={sectionPanels}
                hasSections={hasSections}
                hasCustomSection={hasCustomSection}
                hasCrossSell={hasCrossSell}
                crossSell={crossSell}
                content={props.content}
                innerForm={false}
                currentStep={curStep}
                data={props.data}
                onColorChange={onColorChange}
                modals={modals}
                deleteRecord={deleteRecord}
                editRecord={editRecord}
                onChangeCheckBox={onChangeCheckBox}
                showContent={showContent}
                displayContent={displayContent}
                 productTable={productTable}
                 manageBeneficiariesTable={manageBeneficiariesTable}
              />,
            ],
          };

          return {
            ...prevContentData,
            steps: tempSteps,
          };
        }
      });
    }
    formik.validateForm();
  }, [
    formik.values,
    formik.errors,
    formik.touched,
    props.contentData.formsList,
    props.result,
    props.elements,
  ]);

  let errorModelElements = [[]];
  let showErrorModel = false;
  
  if ((isStepFormSubmitted && displayErrorModel) || skipFormToEnd) {

    if(props?.content?.layout?.confirmationPanel){
      history.push(props?.content?.layout?.confirmationPanel?.redirectURL);
    }
    let firstName =
      (formik.values &&
        formReference &&
        formReference.length > 0 &&
        formik.values[formReference[0]] &&
        formik.values[formReference[0]].firstName) ||
      '';
    let lastName =
      (formik.values &&
        formReference &&
        formReference.length > 0 &&
        formik.values[formReference[0]] &&
        formik.values[formReference[0]].lastName) ||
      '';

    let name =
      (formik.values &&
        formReference &&
        formReference.length > 0 &&
        formik.values[formReference[0]] &&
        formik.values[formReference[0]].themeName) ||
      '';

    let selectedTheme =
      (formik.values &&
        formReference &&
        formReference.length > 0 &&
        formik.values[formReference[0]] &&
        formik.values[formReference[0]].selectedTheme) ||
      '';
    let formReferenceLink =
      formik.values && formReference && formReference.length > 0
        ? formReference
        : formReferenceLinkText;
    // looping errors with 200 response from API
    if (content?.layout?.formSubmit?.failurePath) {
      let errorData = eval(content.layout.formSubmit.failurePath);
      let mappedErrorData =
        errorData?.[0] &&
        errorData.map((error, index) => {
          return {
            type: 'label',
            name: `error${index}`,
            label: error.message || '',
            placement: '',
            value: error.message || '',
          };
        });
      errorModelElements[0] = mappedErrorData;
    }

    // looping errors with 200 response from API on submit multiple api call
    if (content?.layout?.onSubmitApiCall) {
      let element = content?.layout?.onSubmitApiCall;
      for (let i = 0; i < element.length; i++) {
        if (element[i]?.apiconfig?.failurePath) {
          // let errorData = eval(element[i].apiconfig.failurePath);
          let errorData = eval(element[i].apiconfig.failurePath);
          if (errorData) {
            let mappedErrorData = [
              {
                type: 'label',
                name: `errorData.message`,
                label: errorData.message || '',
                placement: '',
                value: errorData || '',
              },
            ];
            errorModelElements[0] = mappedErrorData;
          }
        }
      }
    }

    // looping errors other than 200 response from API
    if (data && data.message) {
      let mappedErrorData = [
        {
          type: 'label',
          name: `errorMessage`,
          label: data.message || '',
          placement: '',
          value: data.message || '',
        },
      ];
      errorModelElements[0] = mappedErrorData;
    }

    // fallback error
    if (!errorModelElements[0] && content?.layout?.formSubmit?.fallbackErrorMessage) {
      let mappedErrorData = [
        {
          type: 'label',
          name: `errorMessage`,
          label: content?.layout?.formSubmit?.fallbackErrorMessage || '',
          placement: '',
          value: content?.layout?.formSubmit?.fallbackErrorMessage || '',
        },
      ];

      errorModelElements[0] = mappedErrorData;
    }

    if (errorModelElements && errorModelElements[0] && errorModelElements[0].length > 0) {

      showErrorModel = true;
    } else {
      if (content?.layout?.formSubmit?.successPath || skipFormToEnd) {
        if (props.formSuccessAction) {
          return props.formSuccessAction();
        }
        return (
          //To render success page on form submit
          <SuccessMessagePage
            {...confirmation}
            firstName={firstName}
            lastName={lastName}
            name={name}
            selectedTheme={selectedTheme}
          // formReferenceLink={formReferenceLink}
          ></SuccessMessagePage>
          // <div>
          //   <div className="confirmationContainer">
          //     <Image alt={confirmationImage.imgAlt} filename={confirmationImage.imgLink} />
          //     <h1 className="confirmation_message">{confirmationMessage}</h1>
          //   </div>
          //   <div>
          //     <h1 className="confirmation_link">
          //       {submissionTextBefore}
          //       <Linker to={redirectURL}>
          //         {redirectMessage
          //           ? redirectMessage
          //           : `${firstName} ${lastName} ${name} ${selectedTheme}'s`}
          //       </Linker>
          //       {submissionTextAfter}
          //     </h1>
          //   </div>
          //   {/* <pre style={{ textAlign: 'left' }}>{JSON.stringify(contentData.formData, null, 2)}</pre> */}
          // </div>
        );
      }
      //On submit multiple api call
      else if (content?.layout?.onSubmitApiCall[0].apiconfig.successPath || skipFormToEnd) {
        if (props.formSuccessAction) {
          return props.formSuccessAction();
        }

        // return (
        //   <div>
        //     <div className="confirmation-container">
        //       <Image alt={confirmationImage.imgAlt} filename={confirmationImage.imgLink} />
        //       <h1 className="confirmation_message">{confirmationMessage}</h1>
        //     </div>
        //     <div>
        //       <h1 className="confirmation_link">
        //         {submissionTextBefore}
        //         <Linker to={redirectURL}>
        //           {redirectMessage ? redirectMessage : `${firstName} ${lastName} ${name}'s`}
        //         </Linker>
        //         {submissionTextAfter}
        //       </h1>
        //     </div>
        //     {/* <pre style={{ textAlign: 'left' }}>{JSON.stringify(contentData.formData, null, 2)}</pre> */}
        //   </div>
        // );

        return (
          //To render success page on form submit
          <SuccessMessagePage
            {...confirmation}
            firstName={firstName}
            lastName={lastName}
            name={name}
            selectedTheme={selectedTheme}
            formReferenceLink={formReferenceLink}
          ></SuccessMessagePage>
          // <div>
          //   <div class="row">
          //     <div class="col-12 pb-6">
          //       <div className="confirmation-container">
          //         <Image alt={confirmationImage.imgAlt} filename={confirmationImage.imgLink} />
          //         <h1 className="confirmation_message">{confirmationMessage}</h1>{' '}
          //         {submissionTextBefore} <br></br>
          //         <Linker to={redirectURL}>
          //           {redirectMessage ? redirectMessage : `${firstName} ${lastName} ${name}'s`}
          //         </Linker>
          //       </div>
          //     </div>
          //   </div>
          //   <div className="row py-3">
          //     <div className="tip-container">
          //       <div className="col-3 py-3">
          //         {' '}
          //         <Image alt={tipImage.imgAlt} filename={tipImage.imgLink} />
          //       </div>
          //       <div className="col-3">
          //         <p>{tipTitle}</p>
          //      {tipMessage}
          //       </div>
          //     </div>
          //   </div>
          // </div>
          //  {/* <pre style={{ textAlign: 'left' }}>{JSON.stringify(contentData.formData, null, 2)}</pre> */}
        );
      }
      // else if (content?.layout?.formSubmit?.successPath || skipFormToEnd) {
      //   if (props.formSuccessAction) {
      //     return props.formSuccessAction();
      //   }

      //   return (
      //     <div>
      //       <div className="confirmation-container">
      //         <Image alt={confirmationImage.imgAlt} filename={confirmationImage.imgLink} />
      //         <h1 className="confirmation_message">{confirmationMessage}</h1>
      //       </div>
      //       <div>
      //         <h1 className="confirmation_link">
      //           {submissionTextBefore}
      //           <Linker to={redirectURL}>
      //             {redirectMessage ? redirectMessage : `${firstName} ${lastName} ${name}'s`}
      //           </Linker>
      //           {submissionTextAfter}
      //         </h1>
      //       </div>
      //       {/* <pre style={{ textAlign: 'left' }}>{JSON.stringify(contentData.formData, null, 2)}</pre> */}
      //     </div>
      //   );
      // }
    }
  }

  if (isStepFormSubmitted && !apiError) {
    /* update loggedUserEntity after succesfull formSubmit */
    const callUserLegal = async (authReqData) => {
      setRedirectCallStarted(true);
      await dispatch(callLoggedUserEntity(content.layout.updatedLoggedUserEntity, authReqData));
      history.push(content.layout.successRedirect);
    };
    if (
      !redirectCallStarted &&
      content.layout &&
      content.layout.updatedLoggedUserEntity &&
      !content.layout.callBeforeRedirectFromSuccessScreen
    ) {
      let authReqData = '';
      if (content.layout.authReqObj) {
        authReqData = {
          authReqData: loggedUserEntity.clientId ? loggedUserEntity.clientId.buid : '',
        };
      }
      callUserLegal(authReqData);
    }
  }

  switch (formType) {
    /* switch to select type of form to load */
    case 'multiSectionForm':
      return (
        <MultiSectionForm
          {...props}
          formData={contentData.formData}
          steps={contentData.steps}
          stepKeys={contentData.stepKeys}
          showErrorModel={showErrorModel}
          errorModelElements={errorModelElements}
        ></MultiSectionForm>
      );
    case 'stepForm':
      return (
        <StepForm
          isFirst={isFirst}
          isLast={isLast}
          {...props}
          showErrorModel={showErrorModel}
          errorModelElements={errorModelElements}
        >
          {' '}
        </StepForm>
      );
    case 'searchForm':
      return (
        <SearchForm
          {...props}
          formData={contentData.formData}
          steps={contentData.steps}
          stepKeys={contentData.stepKeys}
        >
          {' '}
        </SearchForm>
      );
    default:
      return null;
  }
}

export default FormSelector;