import React from 'react';
import ChildCoverage from '../Component/Flow2/childCoverage';
import { useSelector } from 'react-redux';
import { useNavigationHook } from '../Hooks/NavigationHook';
const ChildCoverageWrapper = (props) => {
  const { childIndex } = props;
  const personBuid = props.children[childIndex]?.personBuid;
  const { insuredInfo, process } = useSelector((storeState) => storeState);
  const { currentStep } = useNavigationHook();




  
  const insuredInfoCurrentStep = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};


  // Null check before filtering subscribeCoverageBuids
  const filteredSubscribeCoverageBuids = insuredInfoCurrentStep.subscribeCoverageBuids?.filter(item => item.personBuid === personBuid) || [];

  // Null check before assuming that we have at least one object after filtering
  const matchedCoverageBuidObj = filteredSubscribeCoverageBuids.length > 0 ? filteredSubscribeCoverageBuids[0] : null;

 
 // Extract required details with null checks
 const listOfCoveragePlanDesignConfigurationResponse = matchedCoverageBuidObj?.listOfCoveragePlanDesignConfiguration?._Result || [];
 const subscribedCoverageConfigurationResponse = matchedCoverageBuidObj?.subscribedCoverageConfiguration?._Result || {};
 const beneficiaryConfigurationResponse = matchedCoverageBuidObj?.beneficiaryConfiguration?._Result?.[0] || {};
 const prepareSubscriptionForChoicesResponse = matchedCoverageBuidObj?.prepareSubscriptionForChoices?._Result || {};
 const coverageBuid = matchedCoverageBuidObj?.coverageBuid || "";
 

  const dependentsList = useSelector(
    (storeState) => storeState?.employeeProfile?.insuredsAsBeneficiaries
  );
  const filteredInsuredsAsBeneficiaries = dependentsList.filter(
    (beneficiary) => beneficiary.mainBuid !== personBuid
  );

  let beneficiaryList = [];
  var beneficiaryObj;
  let updatedBeneficiaryList = [...filteredInsuredsAsBeneficiaries];
  updatedBeneficiaryList.forEach((beneficiary) => {
    beneficiaryObj = { ...beneficiary };
    if (
      beneficiaryObj?.beneficiaryRelationship == '' ||
      beneficiaryObj?.beneficiaryRelationship == 'SP'
    ) {
      if (beneficiaryObj.beneficiaryGender == 'gFemale') {
        beneficiaryObj.beneficiaryRelationship = 'MO';
      } else {
        beneficiaryObj.beneficiaryRelationship = 'FA';
      }
    } else if (beneficiaryObj?.beneficiaryRelationship == 'CH') {
      if (beneficiaryObj.beneficiaryGender == 'gFemale') {
        beneficiaryObj.beneficiaryRelationship = 'SIS';
      } else {
        beneficiaryObj.beneficiaryRelationship = 'BRO';
      }
    }
    beneficiaryList = [...beneficiaryList, beneficiaryObj];
  });


 

  const beneficiaryEligibility = useSelector((storeState) => {
    const eligibilityData =
      storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
    const matchedEligibility = eligibilityData.filter(
      (eligibility) => eligibility?.coverageBuid === coverageBuid
    );
    return matchedEligibility[0]?.isBeneficiaryEligible || false;
  });

  const isBeneficiaryMandatory = useSelector((storeState) => {
    const eligibilityData =
      storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
    const matchedEligibility = eligibilityData.filter(
      (eligibility) => eligibility?.coverageBuid === coverageBuid
    );
    return matchedEligibility[0]?.isBeneficiaryMandatory || false;
  });

  const isBeneficiaryOptional = useSelector((storeState) => {
    const eligibilityData =
      storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
    const matchedEligibility = eligibilityData.filter(
      (eligibility) => eligibility?.coverageBuid === coverageBuid
    );
    return matchedEligibility[0]?.isBeneficiaryOptional || false;
  });

  return (
    <div>
      <ChildCoverage
        {...props}
        onCoverageChange={props.handleCoverageChange}
        coverageDetails={listOfCoveragePlanDesignConfigurationResponse} 
        personBuid={personBuid} 
        allowedBeneficiaryKind = {beneficiaryConfigurationResponse} 
        coverageBuid = {coverageBuid} 
        beneficiaryEligibility = {beneficiaryEligibility}
        dependentsList={beneficiaryList}
        subscribedCoverageConfigurationResponse = {subscribedCoverageConfigurationResponse}
        prepareSubscriptionForChoicesResponse = {prepareSubscriptionForChoicesResponse}
        isBeneficiaryMandatory={isBeneficiaryMandatory}
        isBeneficiaryOptional={isBeneficiaryOptional}
      />
    </div>
  );
};

export default ChildCoverageWrapper;
