

import http from '../../../../Common/Services/HttpService';
import appConfig from '../../../../appConfig';
import { onLoadProductAPI, UPDATE_COVERAGE_INFO, onLoadAPI } from '../../../../Store/Slices/Entities/response/index.js'; 
import { UPDATE_LOADING,
    SUBSCRIBE_COVERAGE_BUIDS,
    SET_COVERAGE_BENEFICIARY_ELIGIBILITY,SET_ALERT_MESSAGE, REMOVE_CURRENTSTEP_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';

import {
    UPDATE_ENROLLMENT_DATA
  } from '../../../../Store/Slices/Enrollment/index';
import { getCoverageBeneficiaryEligibility } from '../Helper/MappingHelper';

export const getPetPlusAssureProductData = (params) => async dispatch => {

    // Make API request to validate subscribed product data
    dispatch(UPDATE_LOADING(true));
    const {
        enrollmentProcessBuid, 
        productIdentifierBuid, 
        currentStep, 
        subscribedElements, 
        expectReturn = false, 
        currentStepInfo,
        personBuid,
        isRework
    } = params;

    try{
        dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep)); 
    const respObj = await http.post(appConfig.SSP_MID_URL + '/v1/pet-plus-load', {
            enrollmentProcessBuid: enrollmentProcessBuid,
            productBuid: productIdentifierBuid,
            subscribedProductBuid: subscribedElements,
            currentStepInfo,
            isRework,
            currentStepInfo: {
                "portalStep": currentStep
              }
      });
      if (respObj.data) {
        const data = respObj?.data?.data
        let coverageBuid = data['prepareSubscriptionForChoice']?.data?.[0]?.bUID
        for (const key in data) {
            let result = { _Result: data[key].data }
            switch (key) {
                case 'searchPolicyProcessResources':
                        if (data[key]?.success) {
                            dispatch(onLoadAPI({ [`SearchPolicyProcessResources${currentStep}`]: result }));
                        } else{
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'searchPolicyProcessResources',
                                show: true,
                                page: currentStep,
                              }));
                        }
                        break;
                case 'validateSubscribedProductData':
                    if (data[key]?.success) {
                        const currentStepData = {
                            [currentStep]: {
                                ValidateSubscribedProductData: result
                            }
                        };

                        const processInfo = result?._Result ?? {};
                        dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
                        // Dispatch an action to update the state with the new data
                        dispatch(onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false }));
                    } else {
                        dispatch(SET_ALERT_MESSAGE({
                            type: 'error',
                            errorCode: data[key]?.data?.error?.code || '520',
                            continue: true,
                            id: 'validateSubscribedProductData',
                            show: true,
                            page: currentStep,
                          }));
                    }
                    break;
                case 'prepareSubscriptionForChoice':
                    if (data[key]?.success) {
                        const currentStepData = {
                            [currentStep]: {
                                prepareSubscriptionForChoice: result
                            }
                        };

                        // Dispatch an action to update the state with the new data
                        dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                            coverageBuid: result._Result?.[0]?.bUID,
                            personBuid: personBuid,
                            product: currentStep,
                        }));
                        dispatch(
                            UPDATE_COVERAGE_INFO({
                                coverageBuid: result._Result?.[0]?.bUID,
                                personBuid: personBuid,
                                product: currentStep,
                                perpareSubscriptionForChoices: result
                            })
                        );
                        dispatch(onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false }));
                    } else {
                        dispatch(SET_ALERT_MESSAGE({
                            type: 'error',
                            errorCode: data[key]?.data?.error?.code || '520',
                            continue: true,
                            id: 'prepareSubscriptionForChoice',
                            show: true,
                            page: currentStep,
                          }));
                    }
                    break;
                case 'getSubscribedCoverageBeneficiaryConfiguration':
                    if (data[key]?.success) {
                        dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                            coverageBuid: coverageBuid,
                            personBuid: personBuid,
                            product: currentStep
                        }));
                        dispatch(
                            UPDATE_COVERAGE_INFO({
                                coverageBuid: coverageBuid,
                                personBuid: personBuid,
                                product: currentStep,
                                beneficiaryConfiguration: result
                            })
                        );


                        const coverageBenficiaryEligiblity = getCoverageBeneficiaryEligibility(result?._Result, coverageBuid, personBuid);
                        dispatch(SET_COVERAGE_BENEFICIARY_ELIGIBILITY({ eligibility: coverageBenficiaryEligiblity, product: currentStep }));
                    } else {
                        dispatch(SET_ALERT_MESSAGE({
                            type: 'error',
                            errorCode: data[key]?.data?.error?.code || '520',
                            continue: true,
                            id: 'getSubscribedCoverageBeneficiaryConfiguration',
                            show: true,
                            page: currentStep,
                          }));
                    }
                    break;
                case 'getSubscribedCoverageConfigurationNew':
                    if (data[key]?.success) {
                        dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                            coverageBuid: coverageBuid,
                            personBuid: personBuid,
                            product: currentStep,
                        }));
                        dispatch(
                            UPDATE_COVERAGE_INFO({
                                coverageBuid: coverageBuid,
                                personBuid: personBuid,
                                product: currentStep,
                                subscribedCoverageConfiguration: result
                            })
                        );
                    } else {
                        dispatch(SET_ALERT_MESSAGE({
                            type: 'error',
                            errorCode: data[key]?.data?.error?.code || '520',
                            continue: true,
                            id: 'getSubscribedCoverageConfigurationNew',
                            show: true,
                            page: currentStep,
                          }));
                    }
                    break;
                case 'getListOfCoveragePlanDesignConfigurationNew':
                    if (data[key]?.success) {
                        dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                            coverageBuid: coverageBuid,
                            personBuid: personBuid,
                            product: currentStep,
                        }));
                        dispatch(
                            UPDATE_COVERAGE_INFO({
                                coverageBuid: coverageBuid,
                                personBuid: personBuid,
                                product: currentStep,
                                listOfCoveragePlanDesignConfiguration: result
                            })
                        );
                    }  else {
                        dispatch(SET_ALERT_MESSAGE({
                            type: 'error',
                            errorCode: data[key]?.data?.error?.code || '520',
                            continue: true,
                            id: 'getListOfCoveragePlanDesignConfigurationNew',
                            show: true,
                            page: currentStep,
                          }));
                    }
                    break;
                default:
                    dispatch(onLoadAPI({ [key]: result }));
            }
        }
        return {
            status: true,
            respObj: respObj
        }
    } else {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: respObj?.status || '520',
            id: 'PetPlusOnload',
            show: false,
            page: currentStep,
          }));
    }
} catch (e) {
    dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: '520',
        continue: true,
        id: 'PetPlusOnload',
        show: true,
        page: currentStep,
      }));
} finally {
    dispatch(UPDATE_LOADING(false));
}
};