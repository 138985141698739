import { onLoadAPI } from "../../../../Store/Slices/Entities/response";
import ServiceHelper from "../../../Utils/Helper/MappingHelper";
import { UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment';
import { UPDATE_LOADING, SET_ALERT_MESSAGE } from "../../../../Store/Slices/InsuredInfo";

export const createOrUpdateInsuredQuestionAnswer = (params) => async (dispatch) => {
    const serviceHelper = new ServiceHelper();
    dispatch(UPDATE_LOADING(true));
    try {
        let resp = await serviceHelper.sendApiRequest('/createOrUpdateInsuredQuestionAnswer', {
            ...params
        });
        if (resp && resp.data && resp.status === 200) {
            dispatch(onLoadAPI({
                CreateOrUpdateInsuredCIGeneral: resp.data,
                isAPICallInProgress: false,
            }))
            const processInfo = resp?.data?._Result ?? {};
            dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
            // return true if response success and action disptached
            return true
        } else {
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: resp?.status || '520',
                id: 'createOrUpdateInsuredQuestionAnswer',
                show: false,
            }));
            //otherwise false
            return false
        }
    } catch (e) {
        console.error(e)
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}
