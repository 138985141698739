import React,{useEffect,useState} from 'react';
// import "./styles.css";
import { useSelector } from 'react-redux';
import SectionHeader from '../../../Molecules/SectionHeader';
import { Styles } from './styles';

const SupportedBrowsers = () => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, setContent] = useState({});
  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => setContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);
  let titleClassName = content?.staticPages?.titleClassName;
  let wrapperClassName = content?.staticPages?.wrapperClassName;
  let subTitleClassName = content?.staticPages?.subTitleClassName;
  let supportedClassName = content?.staticPages?.supportedClassName;
  let enClassName = content?.staticPages?.enClassName;

  let handleClose = () => {
    window.close();
  };
 
  return (
    <React.Fragment>
      <Styles />
        <div className='container-fluid  '>
          <div className='row '>
            <div className='col-12 ' >
              <div className='row  '>
                <div className='col-11 px-0 '>
                  <SectionHeader title="Supported Browsers"  current="Supported Browsers" />
                </div>
                <div className='  col-1 btnClass border-bottom ' >
                  <button type="button" className="close pr-lg-2 "  aria-label="Close" onClick={()=>handleClose()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          <div className={`col-12 px-3 px-md-5  px-lg-5 ${supportedClassName}`}>
          </div>
          <div className={` col-12 px-3 px-md-5  px-lg-5 ${wrapperClassName} `}>
            <div className='row ' >
              {content && content?.staticPages?.supportedBrowsers?.en?.map((element) => {
                return (
                  <>
                    <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.title &&
                        <div className={`row ${titleClassName} `}>
                          <div className='col-12 py-2'>
                            {element.title} 
                          </div>
                        </div>
                      }
                        {element?.subTitle && 
                          <div className={`row ${subTitleClassName} `}>
                            <div className='col-12 py-1'>
                              {element.subTitle}
                            </div>
                          </div>
                        }
                        {element?.content ? element.subContent.map((content) => {
                          return (
                            <div className='row content py-2 ' key={content.id}>
                              <div className='col-auto px-0 mx-0 pl-3 '>{'\u2022'}{' '}</div>
                                <div className='col-11 px-0 mx-0 content pl-2 '>{content.content}</div>
                            </div>
                          )
                        }) 
                        : ''}
                        {element?.subContent ? element.subContent.map((sContent) => {
                          return (
                            <div className='row content py-2 ' key={sContent.id}>
                              <div className='col-auto px-0 mx-0 pl-3 '>{'\u2022'}{' '}</div>
                              <div className='col-11 px-0 mx-0 content pl-2 '>{sContent.content}</div>
                            </div>
                          )
                        }) 
                        : ''}
                        {element?.ques ? element.ques.map((q) => {
                          return (
                            <div className='row content py-2' key={q.id}>
                              <div className='col-12   '>
                                <strong> {q.qcontent} </strong>
                              </div>
                              <div className='col-12   '>
                                {q.acontent}
                              </div>
                            </div>
                          )
                        }) 
                        : ''}
                        <div className='row content '>
                          {element?.secondContent && 
                            <div className='col-12 py-2'>
                              {element.secondContent}
                            </div>
                          } 
                        </div>
                        {element?.secondSubContent ? element.secondSubContent.map((secSubContent) => {
                            return (
                              <div className='row content py-2 ' key={secSubContent.id}>
                                <div className='col-auto px-0 mx-0 pl-3 '>{'\u2022'}{' '}</div>
                                <div className='col-11 px-0 mx-0 content pl-2'>{secSubContent.content}</div>
                              </div>
                            )
                        }) 
                        : ''}
                        <div className='row content'>
                        {element?.secTitle && 
                          <div className={`row sec-title `}>
                            <div className='col-12 '>
                              <strong>{element.secTitle}</strong>
                            </div>
                          </div>
                        }
                        </div>
                        {element?.thirdSubContent ? element.thirdSubContent.map((third) => {
                          return (
                            <div className='row content py-2 ' key={third.id}>
                              <div className='col-auto px-0 mx-0 pl-3 '>{'\u2022'}{' '}</div>
                              <div className='col-11 px-0 mx-0 content pl-2'>{third.content}</div>
                            </div>
                          )
                        }) 
                        : ''}
                        <div className='row content'>
                          {element?.fourthContent && 
                            <div className='col-12 '>
                              {element.fourthContent}
                            </div>
                          }
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          
      </div>
      </div>
    </React.Fragment>
  );
};

export default SupportedBrowsers;