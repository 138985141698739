//Theming for body color,links,background color are added in styles.js
import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
/* html,
body,
#app,
#app > div {
  height: 100%;
  background-color: #f1f2f4;
} */
html {
  height: 100%;
}
body,
#root {
  height: 100%;
  background-color: #fff;
}
/* .main-wrapper {
  /* min-height: 25rem; */
/* padding-bottom: 3.5rem; Footer height */
/* } */


body{
  color:${({ theme }) =>
    theme.components && theme.components.app && theme.components.app.appColor}; 
    font-size: 0.875rem;
}

.col-12 col-md-6 : .error-message +.col-12 col-md-6
{
border-bottom: red;
  border: red;
  }

.error-message {

}

.floatingLabel pb-3 + .error-message
{
color:green
  }

  .hideElement {
    display:none;
  }


.main-wrapper {
  /* min-height: calc(100% - 21.5rem); */
  min-height: calc(100% - 38.5%);
  /* padding-bottom: 3.5rem; */
   background-color: ${({ theme }) =>
     theme.components && theme.components.app && theme.components.app.appBgColor};
}
.groupInfoWrapper {
  background-color: ${({ theme }) =>
    theme.components && theme.components.app && theme.components.app.appBgColor};
}

a {
color:  ${({ theme }) =>
  theme.components && theme.components.link && theme.components.link.linkColor};
}

a:hover {
  color: ${({ theme }) =>
    theme.components && theme.components.link && theme.components.link.hoverColor};
}

.loginView .link {
  color: ${({ theme }) =>
    theme.components && theme.components.link && theme.components.link.linkColor};
}


/* .App {
  position: relative;
  /* min-height: 93.5vh; */
/* height: calc(100% - 11.5rem); */
/* } */
.App {
  position: relative;
  height: calc(100%);
}

@media screen and (min-width: 768px) {
  .main-wrapper {
     /* min-height: calc(100% - 12.15rem); */
     min-height: calc(100% - 25.5%); 
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .main-wrapper {
    /* min-height: calc(100% - 14.35rem); */
     min-height: calc(100% - 22.5%); 
  }
}

@media screen and (min-width: 1200px) {
  .main-wrapper {
    /* min-height: calc(100% - 14.35rem); */
    min-height: calc(100% - 19%);
  }
}

@media screen and (min-height: 1200px) {
  .main-wrapper {
    /* min-height: calc(100% - 14.35rem); */
    min-height: calc(100% - 14.25%);
  }
}
/* @media screen and (min-width: 768px) {
  .App {
    height: calc(100% - 4.25rem);
  }
}
@media screen and (min-width: 768px) and (max-width: 768px) and (max-height: 1024px) and (orientation: portrait) {
  .App {
    height: calc(100% - 6.5rem);
  }
}*/

.font-face-gm {
 font-family: "MMC Display";
}



`;
