import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from '../Image/';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { formatter } from '../..//Atoms/Currency/index';
import FormatHelper from '../../Utils/Helper/FormatHelper';
import ServiceHelper from '../../../App/Utils/Helper/MappingHelper';
import './styles.css';
import { reduxConstants } from '../../../Common/Constants/index';
import Linker from '../Linker';
import $ from 'jquery';
import ToolTip from '../ToolTip';
import {
  PRODUCT_TITLE
} from '../../../Store/Slices/Process';

const ReadOnly = (props) => {
  const serviceHelper = new ServiceHelper();
  const formatHelper = new FormatHelper();

  let {
    id,
    name,
    label,
    filename,
    imageAfter,
    element,
    formik = {},
    value = '',
    hideLabel,
    hideElement,
    hidden,
    shouldShowOnYes,
    image,
    imageAlt,
    readOnlyClassWrapper,
    imageClassWrapper,
    imageAdditionalClass,
    config = {},
    formName,
    modalPopUp,
    modalName,
    styleClass,
    editable,
    divClassName,
    style,
    showContent,
    displayContent = {},
    disabled = false,
    disabledStyle = {},
    showOnAPISuccess,
    displayAPISuccess,
    getContentFromAPI,
    isUmUimCoverageTable = false,
    hideOnEmailExist = false,
    hideOnPhoneVerfied = false,
    title,
    changeLabelOnValidate = false,
    hideOnSubmit = false,
    hideOnClickSendCodeBtn = false
  } = props;

  //element.disable && alert(element.disable)
  let imageHidden = '';
  let formattedDisplayValue = '';

  // TODO: after adding default value functionality in middleware we need to remove adding element.value
  if (!value) value = (element && element.value) || '';
  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.uiPath)) || {};
  const homepageEnrollmentPeriod = useSelector(
    (storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result?.currentEnrollmentPeriod?.enrollmentPeriod
  );

  const processProductTitle = useSelector((storeState) => storeState?.process?.productTitle);

  const enrollmentType = useSelector(
    (storeState) => storeState?.employeeProfile?.enrollmentType
  );

  const dispatch = useDispatch();
  const radioSelectedValueExcessLiabilityUmUim = useSelector((storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiabilityUmUim);
  const radioSelectedValue = useSelector((storeState) => storeState?.employeeProfile?.radioSelectedValue);
  const radioSelectedValueCriticalIllness = useSelector((storeState) => storeState?.employeeProfile?.radioSelectedValueCriticalIllness);
  const radioSelectedValueHospitalIndemnity = useSelector((storeState) => storeState?.employeeProfile?.radioSelectedValueHospitalIndemnity);
  const radioSelectedValueExcessLiability = useSelector((storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiability);
  const currentStep = useSelector((storeState) => storeState.stepForm.currentStep);
 


  const productTitle = useSelector(
    (storeState) => {
      let productTitleContent =
        storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result?.filter(
          (data) => data.code == 'PRODTITLE' && data.contenttype != 'crtNone'
        );
      if (productTitleContent == undefined) {
        return '';
      } else {
        return productTitleContent[0]?.content;
      }
    }
  );

  let { onNextResponseList } = useSelector((storeState) => storeState.entities.response);
  const registerationError  = useSelector((storeState) => storeState?.registerationError);
  const registeration  = useSelector((storeState) => storeState?.registeration);
  const [showLoader, setShowLoader] = useState(false);
 
  const stepForm  = useSelector((storeState) => storeState?.stepForm);
  const { hospitalSection, petList } = useSelector((storeState) => storeState?.employeeProfile
  );

  const callCenter = useSelector((storeState) => storeState?.callCenter);

  const [edit, setEdit] = useState(false);
  let displayValue = (formik.values && formik.values[id] && formik.values[id][name]) || '';
  if (displayValue === '' && value) displayValue = value;




  const [finalDisplayValue, setFinalDisplayValue] = useState('');

  const [imgToggling, setImgToggling] = useState(false);
  const [calendarHeader, setCalendarHeader] = useState(false);

  formattedDisplayValue = displayValue;
  useEffect(() => {
    // getting values for existing response

    setFinalDisplayValue(formattedDisplayValue);
  }, [formattedDisplayValue]);
  //To get title for products
  useEffect(() => {
    if (element?.id === 'calendarHeader' && element?.name === 'calendarHeader') {
      if (enrollmentType === 'caseNotEligible') {
        setCalendarHeader(true);
      } else {
        if (homepageEnrollmentPeriod?.name === '' && (enrollmentType === 'case5' || enrollmentType === 'case6')) {
          setCalendarHeader(false)
        } else {
          if (homepageEnrollmentPeriod?.name === '') {
            setCalendarHeader(true)
          } else {
            setCalendarHeader(false)
          }
        }
      }

    }
  }, [homepageEnrollmentPeriod, enrollmentType]);




  //Check here
  useEffect(() => {
    // getting values for existing response
    if (element && element.valueConfig) {
      let redObj = eval(element.valueConfig.reduxObject) || {};
      displayValue = serviceHelper.getValue(element.valueConfig.mapperObject, redObj);
      formattedDisplayValue = displayValue;
    }
  }, [element, loggedUserEntity, onNextResponseList]);

  useEffect(() => {

  }, [callCenter.showAfterSendCode]);
  //let shouldHideOnAPIFail = showOnAPISuccess && registerationError[stepForm.currentStep]?.hasError;
  let shouldHideOnAPIFail = showOnAPISuccess && registerationError[stepForm.currentStep]?.hasError;

  let formList = (displayValue, config) => {
    return displayValue.map((value) => {
      if (config.optionsname && constantsList && constantsList[config.optionsname]) {
        let obj = constantsList[config.optionsname].find((o) => o.value === value);
        if (obj) value = obj['text'];
      }
      return <li className="list-style">{value}</li>;
    });
  };
  // Formats values in list
  if (Array.isArray(displayValue) && config.displayType && config.displayType === 'list') {
    displayValue = formList(displayValue, config);
  } else if (Array.isArray(displayValue)) {
    let result = '';
    for (let i = 0; i < displayValue.length; i++) {
      let tempString = displayValue[i];

      tempString = formatString(tempString);
      result += tempString;
      if (i !== displayValue.length - 1) {
        result += ', ';
      }
    }
    displayValue = result;
  }

  displayValue = formatString(displayValue);

  formattedDisplayValue = displayValue;

  function formatString(str) {
    let tempString = str;
    // get values
    if (element && element.valueConfig) {
      let redObj = eval(element.valueConfig.reduxObject) || {};
      tempString = serviceHelper.getValue(element.valueConfig.mapperObject, redObj);
      if (formik && formik.values && formik.values[formName]) {
        if (!formik.values[formName][element.name])
          formik.setValues({
            ...formik.values,
            [formName]: {
              ...formik.values[formName],
              ...{ [element.name]: tempString },
            },
          });
      }
    }
    // Formats constants
    if (config && config.optionsname && constantsList[config.optionsname]) {
      let obj = constantsList[config.optionsname].find((o) => o.value === tempString);
      if (obj) tempString = obj['text'];
    }

    // Formats date
    if (element.config && element.config.valueType === 'date' && tempString) {
      //To format date as mm/dd/yyyy to pass it to new date --To fix previous date dispaly issue
      var hypenIndex = value.indexOf("-");
      const year = value.substring(0, hypenIndex);
      value = value.substring(++hypenIndex);
      hypenIndex = value.indexOf("-");
      const month = value.substring(0, hypenIndex);
      const day = value.substring(++hypenIndex);
      value = month + '-' + day + '-' + year;
      let date = new Date(value);

      if (element.config.format && moment(date, element.config.format, true).isValid()) {
        tempString = moment(date).format(element.config.format);
      } else {
        tempString = '';
      }
    }

    // Formats currency
    if (element.config && element.config.valueType === 'currency' && tempString) {
      tempString = formatter(element.config.locale, element.config.currency).format(
        value || tempString
      );
    }

    return tempString;
  }



  if (element && element.formatType === 'SSN') {
    let ssnTemp = displayValue.replace(/[^\d]/g, '');

    formattedDisplayValue = '***-**-' + ssnTemp.slice(5, 9);
  }

  const imageToggler = () => {
    if (!imgToggling) {
      setFinalDisplayValue(formatHelper.getFormatSSN(displayValue));
    } else {
      let ssnTemp = displayValue.replace(/[^\d]/g, '');

      setFinalDisplayValue('***-**-' + ssnTemp.slice(5, 9));
    }

    setImgToggling(!imgToggling);
  };

  const editAPI = () => {
    setEdit(true);
    //  setShowLoader(true);
    if (modalName) {
      if (modalPopUp) {
        $(`#${modalName}`).modal('toggle');
        props.formik &&
          props.formik.setValues({
            [formName]: {
              ...props.formik.values[formName],
              [`${modalName}Validations`]: true,
            },
            ...props.formik.values,
          });
      } else if (!showLoader) {
        setShowLoader(false);
        $(`#${modalName}`).modal('hide');
        props.formik &&
          props.formik.setValues({
            [formName]: {
              ...props.formik.values[formName],
              [`${modalName}Validations`]: false,
              ...props.formik.values,
            },
          });
      }
    }
  };

  const deleteAPI = () => {
    if (element.expression) {
      props.formik &&
        props.formik.setValues({
          [formName]: {
            ...props.formik.values[formName],
          },
          ...props.formik.values,
        });
    }

    for (let i = 0; i < element.formikPath.length; i++) {
      let keyString = element.formikPath[i];
      let elementName = keyString;
      elementName &&
        elementName.split('.').forEach((reduxPropertyName) => {
          elementName = reduxPropertyName;
        });
      let key = props.formName + '.' + elementName;
      let value = '';
      formik.setFieldValue(key, value);
    }
    element.hidden = true;
    hidden = true;
    imageHidden = 'hidden';
  };



  //To get the product title for all products
  // const productTitle =
  //   currentStep === 'CoverageSelection'
  //     ? SearchPolicyProcessResourcesCoverageSelectionTitle
  //     : currentStep === 'HospitalIndemnity'
  //       ? SearchPolicyProcessResourcesHospitalIndemnityTitle
  //       : currentStep === 'IdTheft'
  //         ? SearchPolicyProcessResourcesIdTheftTitle
  //         : currentStep === 'Travel'
  //           ? SearchPolicyProcessResourcesTravelTitle
  //           : currentStep === 'LegalCoverage'
  //             ? SearchPolicyProcessResourcesLegalTitle
  //             : currentStep === 'CriticalIllness'
  //               ? SearchPolicyProcessResourcesCriticalIllnessTitle
  //                 : currentStep === 'UniversalLife'
  //                 ? SearchPolicyProcessResourcesUniversalLifeTitle
  //                   : currentStep === 'ExcessLiability'
  //                     ? SearchPolicyProcessResourcesExcessLiabilityTitle
  //                     : currentStep === 'PetPlus'
  //                       ? SearchPolicyProcessResourcesPetPlusTitle
  //                       : currentStep === 'PetAssure'
  //                         ? SearchPolicyProcessResourcesPetAssureTitle
  //                         : currentStep === 'PetAssureNation'
  //                           ? SearchPolicyProcessResourcesPetAssureNationTitle
  //                           : "";

  // setFinalDisplayValue(title);
  //dispatching produc title to display in douments and forms section
  useEffect(() => {
    if (productTitle && productTitle !== processProductTitle) {
      dispatch(PRODUCT_TITLE(productTitle))
    }
  }, [productTitle, processProductTitle])
  let hideCoverageTableUmUim = (currentStep == 'ExcessLiability' && (
    (isUmUimCoverageTable &&
      (radioSelectedValueExcessLiabilityUmUim == 'Waive' || radioSelectedValueExcessLiabilityUmUim == -1))))

  let nameToHidePhone = name === 'UserAccountVerifyEmail.EmailBar' || hideOnPhoneVerfied
  let hideEmailExist = (currentStep === 'UserAccountVerifyEmail' &&
    hideOnEmailExist && registerationError.emailExist)
  let inputErr = registerationError.showInputErr === true
  let hidePhoneVerfied = (currentStep === 'MultiFactorAuthentication' && !inputErr)
  let shouldHidePhoneVerfied = hidePhoneVerfied && nameToHidePhone
  let hideSpecialMFA = hideEmailExist || shouldHidePhoneVerfied
  let hideOnSubmitOtp = ((hideOnSubmit && registerationError.hidePhoneOnLoad && (
    registerationError[stepForm.currentStep]?.hasError || !registerationError.hasError
  )))
  let additionalClasses = hideElement || shouldHideOnAPIFail || hideCoverageTableUmUim || hideSpecialMFA
    ? 'hideElement'
    : (image && readOnlyClassWrapper ? `row ` + readOnlyClassWrapper : `row `) || ``;

  const contentToShow = displayContent && displayContent[formName] || [];
  let hideReadOnlyField = false
  let changeEmailLabel = changeLabelOnValidate && registerationError.phoneIsVerified
  if (shouldShowOnYes && currentStep == "CoverageSelection") {

    if ((radioSelectedValue == 'Waive' || radioSelectedValue == -1)) {
      hideReadOnlyField = true
    }
  }

  else if (shouldShowOnYes && currentStep == "CriticalIllness") {
    if (radioSelectedValueCriticalIllness == 'Waive' || radioSelectedValueCriticalIllness == -1) {
      hideReadOnlyField = true
    }

  }

  else if (shouldShowOnYes && currentStep == "HospitalIndemnity") {

    if ((radioSelectedValueHospitalIndemnity == 'Waive' || radioSelectedValueHospitalIndemnity == -1)) {
      hideReadOnlyField = true
    }
  }


  else if (shouldShowOnYes && currentStep == "PetAssureNation" && !hospitalSection) {


    hideReadOnlyField = true

  }


  if (shouldShowOnYes && currentStep == "PetAssureNation" && element.name == "tableInformation" && petList.length == 0) {


    hideReadOnlyField = false

  }
  else if (shouldShowOnYes && currentStep == "PetAssureNation" && element.name == "tableHeader" && petList.length > 0) {


    hideReadOnlyField = false

  }
  else if (shouldShowOnYes && currentStep == "ExcessLiability") {
    if ((radioSelectedValueExcessLiability == 'Waive' || radioSelectedValueExcessLiability == -1)) {
      hideReadOnlyField = true
    }
  }
  let hideOnClickSendCode = hideOnClickSendCodeBtn && callCenter.sendCodeMobileClicked;
  let showSpecial = name === 'emailId' && callCenter.showAfterSendCode //? true : // hide
  //(name ==='MFAInfo' || name === '')&& callCenter.showSpecial ? true : false
  // let hideRead =  ((name ==='emailId' || name === 'noteEmail') && !callCenter.showAfterSendCode ) ||
  // (name ==='MFAInfo'  && callCenter.showAfterSendCode )
  const renderEmail = (ele) => {
    return (
      <span>{ele}{'   '}
        <span style={{ color: "#002C77" }}>{registeration.newEmail}</span>
      </span>
    )
  }
  const maskPhone = (phone) => {
    let maskedPhone;
    if (!phone)
      maskedPhone = null
    else {
      phone = phone.replace(/[^\d]/g, '')
      maskedPhone = phone.slice(-4).padStart(phone.length, 'x');//phone.replace(/.(?=.{4})/g, 'x');
    }
    return maskedPhone
  }
  const renderMobile = (ele) => {
    return (
      <span>{ele}{'   '}
        <span style={{ color: "#002C77" }}>{formatHelper.getFormatMaskedPhoneNumber(maskPhone(registeration.newMobile))}</span>
      </span>
    )
  }
  return contentToShow.includes(id) || contentToShow.length === 0 ? (
    (element && element.insideSpan &&
      <span
        disabled={disabled}
        style={style && !element.nameAndRelation ? '' : disabled ? disabledStyle : style}>
        {/* {!element.nameAndRelation ? `${element.content}${displayValue} ` : `${element.nameAndRelation}${displayValue} `} */}


        {
          !element.appendMobileFromRedux ?
            !element.appendEmailFromRedux ?
              !element.nameAndRelation ?
                !getContentFromAPI ?
                  !changeEmailLabel ?
                    `${element.content}${displayValue} ` : `${element.label} ` :
                  registeration.email &&
                  `${registeration.email}${displayValue} ` :
                `${element.nameAndRelation}${displayValue} ` :
              renderEmail(element.content) :
            renderMobile(element.value)}

        {/* { !element.nameAndRelation ? 
        !getContentFromAPI ? 
            `${element.content}${displayValue} ` :
           registeration.email &&
        `${registeration.email}${displayValue} `: 
      `${element.nameAndRelation}${displayValue} `} */}

      </span>)
    || (
      <div className={hideReadOnlyField ? 'hideElement ' : additionalClasses} style={style} hidden={hidden || hideOnSubmitOtp || hideOnClickSendCode}>
        <div className={` ${imageClassWrapper || ``}`}>
          {image && (
            <Image
              key={Math.random()}
              // className={`card-header-image align-self-end element-image ml-3 mr-2`}
              className={`panel-side-image align-self-end element-image ${calendarHeader ? 'hideElement' : ''}`}
              alt={imageAlt || name}
              filename={image || filename}
            />
          )}
        </div>
        <div
          style={{
            display: `${element.display || 'grid'}`,
          }}
          className={` ${'label ' + element.containerClassName + ' ' + element.styleClass || `label `
            }`}
        >
          {!hideLabel && label && (
            <>
              <div className="row">
                <div className="col-12">
                  <div
                    className={`${element.labelClassName ? `ro-div-header ` + element.labelClassName : ''
                      }`}
                  >
                    {label}
                  </div>
                </div>
              </div>
            </>
          )}

          {((formik.values && formik.values[id] && formik.values[id][name]) || value) &&
            ((config && config.displayStyle && config.displayStyle === 'list' && (
              <>{displayValue}</>
            )) || (
                <div class="row">
                  {/* <div className=""> */}
                  {/* <div className={`${element.contentClassName || `label-content col-12`}`}> */}
                  {/*  <div className={`${element.contentClassName || `label-content col-12`}`}> */}
                  <div
                    className={`${element.contentClassName && element.imageClassWrapper
                      ? `ro-label-value ` + element.contentClassName
                      : element.contentClassName || `label-content col-12 ` + '' + element.paddingTop
                      } ${element && element.labelClassName ? element.labelClassName : ""} 
                   `}
                  >
                    <div className={`${element.productTitleClassName}`}>
                    {
                      title ? productTitle : element.htmlText ? (
                        <div dangerouslySetInnerHTML={{ __html: finalDisplayValue }}></div>
                      ) : (finalDisplayValue)

                      // element.htmlText ? (
                      //   <div dangerouslySetInnerHTML={{__html: finalDisplayValue}}></div>
                      // ) : (finalDisplayValue)
                    }
                    {element && element?.imageToggle && (
                      // <div className={`${element.imageClassName || `label-content`}`}>
                      <Image
                        key={Math.random()}
                        className={`clickImageToggle`}
                        hideDefaultClass={true}
                        classNameWrapper={'d-inline pl-1'}
                        filename={element && element.imageToggle}
                        onClick={imageToggler}
                      />
                      // </div>
                    )}
                    </div>
                  </div>
                  {element && element?.imageAfter && (
                    <div
                      style={{
                        display:
                          formik?.values &&
                            formik.values[formName] &&
                            formik.values[formName][element?.name] != ''
                            ? 'inline-block'
                            : 'none',
                      }}
                      className={`${element.imageClassName || `label-content`}`}
                    >
                      <div className="row">
                        {element &&
                          element?.imageAfter &&
                          //add row here
                          imageAfter.map((imageElement, index) => {
                            if (!imageElement.forAddress) {
                              let image = imageElement.image;
                              let functionName = imageElement.functionName;

                              return (
                                <>
                                  <div className={`${element.imageAdditionalClass}`}>
                                    <Image
                                      key={Math.random()}
                                      className={`clickImageToggle`}
                                      // className={`card-header-image align-self-end element-image ml-3 mr-2`}
                                      filename={imageElement.image}
                                      onClick={imageElement.edit ? editAPI : deleteAPI}
                                    />
                                  </div>
                                </>
                              );
                            } else {
                              return (
                                <ToolTip
                                  filename={imageElement.image}
                                  toolTip={imageElement.toolTip}
                                  forAddress={imageElement.forAddress}
                                />
                              );
                            }
                          })}
                      </div>
                    </div>
                  )}
                  {element.urlLink && (
                    <>
                      <div className="font-weight-bold urlLink">
                        <Linker to={element?.urlLink?.url}>{element?.urlLink?.text}</Linker>
                      </div>
                    </>
                  )}
                </div>
                // </div>
              ))}
        </div>
      </div>
    )
  ) : null;
};
ReadOnly.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};
ReadOnly.defaultProps = {
  className: '',
};

export default ReadOnly;