//Theming for Landing panel login button is added here
import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.subTitle {
  font-size: 1rem;
  color: #000;
}

.subTitle {
  color: #1e2e5c;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1e2e5c;
}

.landingPanel .loginButton {
   background-color: ${({ theme }) =>
     theme.atoms && theme.atoms.button && theme.atoms.button.landingPanelLoginButton};
  border-color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.landingPanelLoginButton};
  font-weight: 500;
  color: #fff;
}

.landingPanel a {
  color: ${({ theme }) =>
    theme.components &&
    theme.components.app &&
    theme.components.app.link &&
    theme.components.app.link.color}
  font-size: 1rem;
}
`;
