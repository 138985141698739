import React from 'react';
import './styles.css';
import Image from '../Image/';
import { useDispatch, useSelector } from 'react-redux';
import reducer from '../../../Store/Slices/reducer';
/*This atom is created to display the information which contains a icon,title and list of items  */
const StickyNote = (props) => {
  const {
    children,
    className,
    value,
    image,
    title,
    secondTitle,
    thirdTitle,
    text,
    hideElement,
    hidden,
    classNameWrapper,
    iconClass,
    headerClass,
    listClass,
    olClass,
    notNumbered,
    MultipleTitleStyles,
    shouldShowOnYes,
    color,
    borderColor,
    singleValue,
    name,
    dynamic,
    hideOnClickSendCodeBtn=false,
    alt,
    ...rest
  } = props;
  const currentStep = useSelector((storeState) => storeState.stepForm.currentStep);

  let listItem = '';
  let notes = [];
  // value.forEach(function (listItem, index) {
  const isYesradioDisclosureSelected = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelected
  );
  const isYesradioDisclosureSelectedIdTheft = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelectedIdTheft
  );
  const isYesradioDisclosureSelectedSupplementalHealthInsurance = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelectedIdTheft
  );
  const isYesradioDisclosureSelectedTravel = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelectedTravel
  );
  const isYesradioDisclosureSelectedHospitalIndemnity = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelectedHospitalIndemnity
  );
  const isYesradioDisclosureSelectedCriticalIllness = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelectedCriticalIllness
  );
  const isYesradioDisclosureSelectedPetNation = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelectedPetNation
  );
  const radioSelectedValueCriticalIllness = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueCriticalIllness
  );
  const { stepForm } = useSelector((storeState) => storeState);
  const { registerationError } = useSelector((storeState) => storeState);
  const { showSpecial=false, otp='', contactType='',sendCodeMobileClicked=false } = useSelector((storeState) => storeState.callCenter);
 

  const windowDays= useSelector(
    (storeState) =>
    {
      let days=
        storeState.process?.contractsLinearOrNonLinear?._Result?.contract?.additionalDatas?.filter(
          (data) => data.fieldCode == 'DD_00156'
        );

      if (days== undefined) {
        return '';
      } else {
        return days[0].fieldValue;
      }
    }
  );


  let hideStickyNoteField = false;

 
 
  if (
    (currentStep == 'CoverageSelection' && !isYesradioDisclosureSelected) ||
    (currentStep == 'IdTheft' && !isYesradioDisclosureSelectedIdTheft) ||
    (currentStep == 'SupplementalHealthInsurance' && !isYesradioDisclosureSelectedSupplementalHealthInsurance) ||
    (currentStep == 'Travel' && !isYesradioDisclosureSelectedTravel) ||
    (currentStep == 'CriticalIllness' && !isYesradioDisclosureSelectedCriticalIllness) ||
    (currentStep == 'HospitalIndemnity' && !isYesradioDisclosureSelectedHospitalIndemnity) ||
    (currentStep == 'PetAssureNation' && !isYesradioDisclosureSelectedPetNation && name== "notEligibleWarning")
   ) {
    hideStickyNoteField = true;
  }
  let additionalClasses = hideElement || hideStickyNoteField ? 'hideElement' : classNameWrapper;
  let hideChangePhoneNote = (( name=== 'changeMobileNote' &&   registerationError.hidePhoneOnLoad  && stepForm.currentStep !== 'ContactDetails' && (
    registerationError[stepForm.currentStep]?.hasError || !registerationError.hasError
  )) )
  let hideSpecial = currentStep ==='ContactDetails' &&   !showSpecial && contactType === 'email'
  let hideOnClick = contactType=== 'mobile' ? hideOnClickSendCodeBtn && !sendCodeMobileClicked  :
                    contactType=== 'email' ? !showSpecial :'';


  return (
    // <span className={'statusBadge py-1 px-2 ' + className} {...rest}>
    // <div className={(shouldShowOnYes && !isYesradioSelected)? 'hideElement ' : ''} hidden={hidden}>

    <div
      className={
        !notNumbered
          ? 'sticky-note' + '  ' + additionalClasses
          : 'sticky-note mr-2  ' + additionalClasses
      }
      hidden={hidden || hideChangePhoneNote || hideOnClick }

      style={{ 'background-color': color, fontSize: '0.75rem', border: '1px solid ' + borderColor }}
    >
      {/** MFA */}
      {!notNumbered ? (
        <>
          <div className="row">
            <div className={iconClass}>
              {' '}
              <Image
                key={Math.random()}
                alt={alt}
                // className={`card-header-image align-self-end element-image ml-3 mr-2`}
                className={`card-header-image align-self-end element-image`}
                filename={image}
              />
            </div>
            <div className={headerClass + ' stickyNoteHeader '}>{title}</div>
          </div>
          <div className="row">
            <div className={listClass}>
              {!singleValue && (
                <div>
                  <ol className={olClass} role="list">
                    {value.map((tip, index) => (
                      <li className="pt-1" role="listitem" key={index} tabIndex="0">
                        {tip}
                      </li>
                    ))}
                  </ol>
                </div>
              )}

              {singleValue && (
                <div className="px-3 pt-1 mt-1" tabIndex="0">
                  {value}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        /**registeration*/
        <>
          <div className="row mx-0 ">
            <div className={iconClass}>
              {' '}
              <Image
                key={Math.random()}
                // className={`card-header-image align-self-end element-image ml-3 mr-2`}
                className={`card-header-image align-self-end element-image`}
                filename={image}
              />
            </div>
            <div className={listClass}>
              <div>
                <div className={olClass}>
                  {MultipleTitleStyles ? (
                    <div className="pt-1">
                      <span className="first-title">{title}</span>
                      <span className="second-title">{dynamic?  windowDays +" days "   :secondTitle}</span>
                      <span className="first-title">{thirdTitle}</span>
                    </div>
                  ) : (
                    value.map((tip) => <div className="pt-1">{tip}</div>)
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>

    // {/* </span> */}
  );
};

export default StickyNote;
