




export const getKeyValuePairs = (elements) => {
  /* function to get keyValuePairs forom elements */
  let nameValuePairs = {};
  let nameValidationPairs = {};
  for (let elementRows in elements) {
    for (let elementCols in elements[elementRows]) {
      const element = elements[elementRows][elementCols];
      const { name, value, validations } = element;
      nameValuePairs = { ...nameValuePairs, [name]: value ? value : '' };
      nameValidationPairs = { ...nameValidationPairs, [name]: validations };
    }
  }
  return { nameValuePairs, nameValidationPairs };
};

// To determine linear or nonlinear
export const getValues = (linearValue) => {
  let linear;
  let linearNonLiner= linearValue?.filter(
    (data) => data.fieldValue == 'Linear'
  );

  if (linearNonLiner&&linearNonLiner[0]&& linearNonLiner[0]?.fieldValue=="Linear") {
    linear = true;
  } else {
    linear = false;
  }
  return  linear ;
};

  //To check special characters
  export const validateSpecialCharacters = (formik) => {
     const specialChars = /^[a-zA-Z\d\s]+$/;
    let valueCheck = specialChars.test(formik?.values?.PetAssureNation?.hospitalName);
    let valueCheck1 = specialChars.test(formik?.values?.PetAssureNation?.city);  
    return  valueCheck && valueCheck1;
  };

  export const checkCharOnlyVariable = (value) => {
    return (/^%?[a-zA-ZÀ-ž' -]*%?$/.test(value) || value === "")
  }

   
//To check email format
export const validateEmail = (email) => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  let valueCheck = emailRegex.test(email);
  return  valueCheck 
};

export const minimumValueToReformatCoverageAmount = 1000000

export const formatCurrencyToDesiredDisplay = (amount) => {
    let currencyObj = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        notation: 'compact'
    }).formatToParts(amount);
    
    let amountParts = currencyObj.map((currency) => currency.value);
    return amountParts[0] + amountParts[1] + " " + amountParts[2] + "illion";
}

// Function to format the dollar amount
export const formatDollarAmount = (amount) => {
  // Convert the amount to a number if it is a string
  let amountNew = typeof amount === 'string' ? Number(amount?.replace(/[^0-9.-]+/g,"")) : amount;
  
  // Check if the converted amount is NaN (Not a Number)
  if (isNaN(amountNew)) {
    return '';
  }
  
  if (typeof amount === 'string' && amount?.includes('$')) {
    // Separate the dollar amount from the string
    const dollarAmount = amount?.match(/\$[\d,.]+/);
    if (dollarAmount) {
      // Format the dollar amount
      const formattedDollarAmount = formatDollarAmount(Number(dollarAmount[0].replace(/[^0-9.-]+/g,"")));
      // Append the removed value to the formatted dollar amount
      const formattedAmount = amount?.replace(dollarAmount[0], '') + formattedDollarAmount;
      return formattedAmount;
    }
  }
  
  if (amount >= minimumValueToReformatCoverageAmount) {
    return formatCurrencyToDesiredDisplay(amount);
  } else {
    // Format the amount with the desired currency format
    let formattedAmount = amountNew.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  
    // Return the formatted amount
    return formattedAmount;
  }
}



/**
 * Function to format the dollar amount with decimals.
 * @param {number|string|array} amount - The amount to be formatted.
 * @returns {string} - The formatted dollar amount.
 */
export const formatDollarAmountWithDecimals = (amount) => {
  // Convert the amount to a number if it is a string or an array
  let amountNew = amount && typeof amount === 'string' ? Number(amount?.replace(/[^0-9.-]+/g,"")) : Array.isArray(amount) ? Number(amount[0]?.replace(/[^0-9.-]+/g,"")) : amount;
  
  // Check if the converted amount is NaN (Not a Number)
  if (isNaN(amountNew)) {
    return '';
  }
  
  // Format the amount with the desired currency format
  let formattedAmount = amountNew.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    // Add any additional options for formatting here
  });
  
  // Return the formatted amount
  return formattedAmount;
}



export const removeCurrencyFormatting = ({currency}) => {
  if(currency) {
    return Number(currency.replace(/[^0-9.-]+/g,""))
  } 
}

export const reformatAmountToDisplayValue = ({amount}) => {
  let number =  removeCurrencyFormatting({currency: amount});
  if(number) {
    return formatCurrencyToDesiredDisplay(number)
  }
}

export const reformatAmountToDisplayValueExcessLiability = ({amount}) => {
  if(amount) {
    return reformatAmountToDisplayValue({amount})
  }
}

export const formatDisplayValueForCoverageAmount = ({coverageDetails}) => {
    let updatedCoverageDetails = [];
    if(coverageDetails && coverageDetails.length) {
        coverageDetails.map((item) => {
            if(item.coverageLevel?.amount >= minimumValueToReformatCoverageAmount) {
                updatedCoverageDetails.push({
                  ...item,
                  coverageAmount : formatCurrencyToDesiredDisplay(item.coverageLevel?.amount)
                })
            }
        })
    }
    return updatedCoverageDetails
}


/**
 * Function to format the display value for an amount.
 * @param {number|string} amount - The amount to be formatted.
 * @returns {string} - The formatted display value.
 */
export const formatDisplayValueForAmount = (amount) => {
  if (typeof amount === 'string') {
    // Check if the amount includes 'day'
    if (amount?.includes('day')) {
      // Remove non-numeric characters from the amount
      amount = amount?.replace(/[^0-9.-]+/g,"");
      // Format the amount as a dollar amount
      const formattedAmount = formatDollarAmount(amount);
      // Return the formatted amount with '/ day' appended
      return formattedAmount + " / day";
    }
    else {
      // Return the amount as it is
      return amount;
    }
  }
  else if (amount >= minimumValueToReformatCoverageAmount) {
    // Format the amount as a desired display value for coverage amount
    return formatCurrencyToDesiredDisplay(amount);
  } else {
    // Format the amount as a dollar amount
    return formatDollarAmount(amount);
  }
}

 
