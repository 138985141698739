import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { TextInput, Button, ToolTip } from '../../Atoms';
import http from '../../../../../Common/Services/HttpService';
import appConfig from '../../../../../appConfig';
import { getBasicAuthHeaderValue } from '../../Helper/MFAHelper'
import { SET_MFA_BASIC_AUTH_VALUE } from '../../../../../Store/Slices/Registeration';
import imgSRC from '../../../../../Assets/Images/icon-important.png'
import Loader from '../../Atoms/Loader';
import { SHOW_LOADER } from '../../../../../Store/Slices/Loader/index';
import { errorMessages } from '../../Atoms/FormValidation'
import { Link } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(32, 'Password can not be longer than 32 characters')
        .matches(/[a-z]/, 'Password must have at least one lowercase character')
        .matches(/[A-Z]/, 'Password must have at least one uppercase character')
        .matches(/[0-9]/, 'Password must have at least one number')
        .matches(/^[a-zA-Z0-9@.\-_']+$/, 'Password contains invalid characters')
        .test('no-seq-characters', 'Password cannot contain more than three sequential characters', (value) => {
            return !/((?:abc|bcd|cde|def|efg|hij|jkl|mno|nop|pqr|rst|stu|tuv|uvw|vwx|wxy|xyz|zyx|xwv|wvu|uts|tsr|srq|rqp|pon|onm|nml|lkj|kji|jih|ihg|hgf|gfe|fed|edc|dcb|cba|012|123|234|345|456|567|678|789|890|098|987|876|765|654|543|432|321|210)+)/i.test(value);
        })
        .test('no-repeated-characters', 'Password cannot contain more than three repeating characters', (value) => {
            return !/(.)\1{3,}/.test(value);
        })
        .defined(),

    confirmPassword: Yup
        .string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

const ResetPassword = ({ onPrev, onNext }) => {

    const dispatch = useDispatch();
    const [strength, setStrength] = useState(0);
    const [strengthColor, setStrengthColor] = useState('');
    const [strengthText, setStrengthText] = useState('');
    const { loader } = useSelector((state) => state.loader);
    const [err, setErr] = useState('');
    const { legalEntityId = '', appCode = '' } = useSelector((storeState) => storeState?.registeration);
    const { userAuth } = useSelector((storeState) => storeState?.auth);
    const isHrCenter = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter") ? true : false

    const formik = useFormik({
        initialValues: { password: '', confirmPassword: '' },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            handleChangePassword(values);
        },
    });
    const clearErrMsg = () => {
        setErr('');
    }
    const handleChangePassword = async (values) => {

        dispatch(SHOW_LOADER(true));
        let basicAuthorizationValue = getBasicAuthHeaderValue();
        basicAuthorizationValue && dispatch(SET_MFA_BASIC_AUTH_VALUE(basicAuthorizationValue));
        const reqObj = {
            "password": values.password?.trim(),
        }
        http
            .put(
                appConfig.SSP_AUTH_URL + "/stateful/user-mgmt/set-password",
                reqObj,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Basic ${basicAuthorizationValue}`,
                        'X-REQUEST-ID': Math.random(200),
                    }
                }
            )
            .then((resp) => {
                if (resp.status === 200) {
                    onNext(formik.values);
                } else {
                    setErr(errorMessages(resp));
                }
                dispatch(SHOW_LOADER(false));

            })
            .catch((error) => {
                setErr(errorMessages(error));
                dispatch(SHOW_LOADER(false));
            });
    }

    const handlePasswordChange = (e) => {
        const password = e.target.value;

        let strengthVal = 0;

        // 1. Check length
        if (password.length >= 8 && password.length <= 32) strengthVal++;

        // 2. Uppercase
        if (/[A-Z]/.test(password)) strengthVal++;

        // 3. Lowercase
        if (/[a-z]/.test(password)) strengthVal++;

        // 4. Number
        if (/[0-9]/.test(password)) strengthVal++;

        // 5. Special character
        if (/[@\.\-_']/.test(password)) strengthVal++;

        // 6. Check for sequences and repeated characters
        if ((/(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|zyx|yxw|xwv|wvu|vut|uts|tsr|srq|rqp|pqo|pon|onm|nml|mlk|lkj|kji|jih|ihg|hgf|gfe|fed|edc|dcb|cba|ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|ZYX|YXW|XWV|WVU|VUT|UTS|TSR|SRQ|RQP|QPO|PON|ONM|NML|MLK|LKJ|KJI|JIH|IHG|HGF|GHE|FED|EDC|DCB|CBA|012|123|234|345|456|567|678|789|987|876|765|654|543|432|321|210)+/.test(password))) strengthVal--;

        let color = '';
        let text = '';
        switch (strengthVal) {
            case 0: case 1:
                color = 'red';
                text = 'Weak';
                break;
            case 2: case 3:
                color = 'orange';
                text = 'Moderate';
                break;
            case 4:
                color = 'blue';
                text = 'Good';
                break;
            case 5:
                color = 'green';
                text = 'Strong';
                break;
            default:
                color = '';
                text = '';
        }
        setStrengthColor(color);
        setStrengthText(text);
        formik.handleChange(e);
    };


    return (
        <>
            {loader && <Loader loading={loader} />}
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="row">
                    <div className="col-12">
                        <h1 className="form-side-header">Reset Password</h1>
                        <p className="form-mandatory mb-0">
                            Fields marked with * are mandatory
                        </p>
                    </div>
                </div>
                <div className="row py-2">
                    <div className="col-sm-12 col-md-6 tooltipWrapper">

                        <TextInput
                            label="Enter New Password"
                            type='password'
                            required={true}
                            placeholder="Enter Password"
                            className="w-100 inputField p-3"
                            onChange={handlePasswordChange}
                            onInput={clearErrMsg}
                            name={formik.getFieldProps('password').name}
                            value={formik.getFieldProps('password').value}
                            onBlur={formik.getFieldProps('password').onBlur}
                        />
                        {formik.values.password && (
                            <div className='strengthBarDiv'>
                                <div className="strength-bar">
                                    {[...Array(4)].map((_, i) => {
                                        let bgColor = '';
                                        if (i < 1 && strengthText === 'Weak') bgColor = 'red';
                                        else if (i < 2 && strengthText === 'Moderate') bgColor = 'orange';
                                        else if (i < 3 && strengthText === 'Good') bgColor = 'blue';
                                        else if (i < 4 && strengthText === 'Strong') bgColor = 'green';

                                        return (
                                            <div
                                                key={i}
                                                className={`strength-bar__segment ${strengthColor} ${bgColor ? 'active' : ''}`}
                                            ></div>
                                        );
                                    })}
                                </div>
                                <div className={`strength-text ${strengthColor}`}>
                                    {strengthText}
                                </div>
                            </div>
                        )}


                        <ToolTip
                            tooltipLabel='Password Rules'
                            instructions={` At least one uppercase letter\nAt least one lowercase letter\nAt least one number\nMinimum of 8 characters\nMaximum of 32 characters\nYour username and password cannot match\nCannot contain more than three sequencial characters (ABC, BCA, 123)\nCannot contain more than three repeating characters (AAA,111)\nMay include only these special characters: @.-_'\nYour password must not be the same as your last 10 previous passwords\nPassword cannot be in email format"`}
                        >
                            <span><img src={imgSRC} alt="Tooltip img" /></span>
                        </ToolTip>
                        {formik.touched.password && formik.errors.password && (
                            <div className="form-mandatory">{formik.errors.password}</div>
                        )}

                    </div>
                    <div className="col-sm-12 offset-md-4"></div>
                </div>
                <div className="row py-2">
                    <div className="col-sm-12 col-md-6">
                        <TextInput
                            label="Re-enter New Password"
                            required={true}
                            type='password'
                            placeholder="Re-enter New Password"
                            className="w-100 inputField p-3"
                            onInput={clearErrMsg}
                            {...formik.getFieldProps('confirmPassword')}
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                            <div className="form-mandatory">{formik.errors.confirmPassword}</div>
                        )}
                    </div>
                    <div className="col-sm-12 offset-md-4"></div>
                </div>
                {err &&
                    <div className="row">
                        <span className='APIErr'>{err}</span>
                    </div>
                }
                <div className="row py-4 justify-content-between">
                    <div className="col-sm-12 col-md-4 py-2">
                        {isHrCenter ? <Link role="link" to={`/hrcenter/login?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Back to login</Link> :
                            <Link role="link" to={`/enrollment?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Back to login</Link>
                        }
                    </div>
                    <div className="col-sm-12 col-md-6 py-2 mr-4 text-md-right">
                        <Button
                            type="button"
                            className="btn btn-outline-primary primaryOutline btn-outline-primary-contrast mr-4"
                            onClick={onPrev}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            className="btn btn-primary primaryBackground btn-primary-contrast"
                            disabled={(!formik.isValid || !formik.dirty)}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ResetPassword;
