import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextInput } from '../../Atoms';
import ResendOTPBlock from '../../Atoms/ResendOTPBlock/index';
import CreatePassword from '../Registration/CreatePassword';
import { SET_EMAIL_VERFIED } from '../../../../../Store/Slices/Registeration';
import { apiCall } from '../../Helper/commonHelper';
import { SHOW_LOADER } from '../../../../../Store/Slices/Loader/index';
import {errorMessages} from '../../Atoms/FormValidation'
import Loader from '../../Atoms/Loader';

const validationSchema = Yup.object().shape({
    OTPCode: Yup.string().required('Security Code is required'),
});

const VerifyRegistrationOTP = ({ stepValues = { OTPCode: '' }, onNext, onPrev, clrErr, values, handlePasswordData }) => {
    const dispatch = useDispatch();
    const [otpSuccess, setOtpSuccess] = useState(false);
    const [err, setErr] = useState('');
    const { loader } = useSelector((state) => state.loader);

    const formik = useFormik({
        initialValues: stepValues || {
            OTPCode: '',
        },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            handleOtpVerification(values)
            formik.resetForm();
        },
    });

    const validationMsg = (field) => {
        return (formik.touched[field] && formik.errors[field] && (
            <div className="form-mandatory">{formik.errors[field]}</div>
        ))
    };

    const handleOtpVerification = async(values) => {
        setErr('')
        dispatch(SHOW_LOADER(true));
        const reqObj = {
            "otp": values.OTPCode?.trim()
        }
        apiCall("/stateful/sign-up/verify-challenge",'post', reqObj)
        .then((res) => {
            if (String(res.status).charAt(0) ==='2'){
                dispatch(SET_EMAIL_VERFIED(true))
                setOtpSuccess(true)
            } else {
                setErr(errorMessages(res)) 
            }
            dispatch(SHOW_LOADER(false));
        })
        .catch((error) => {
            setErr(errorMessages(error))
            dispatch(SHOW_LOADER(false));
        });
    }

    const handleResendCode = async (email) => {
            dispatch(SHOW_LOADER(true));
            setErr('')
            apiCall("/stateful/sign-up/send-email-challenge", 'post', {"email": email})
            .then((res) => {
              if (String(res.status).charAt(0) !== '2'){
                setErr(errorMessages(res));
              }
              dispatch(SHOW_LOADER(false));
            }).catch((error) => {
              setErr(errorMessages(error));
              dispatch(SHOW_LOADER(false));
            });
    }
    const handleBlur = () =>{
        
        setErr('');
    }

    return (
        <>
            {loader && <Loader loading={loader} />}
            {!otpSuccess ?
            <form onSubmit={(e) => e.preventDefault()} noValidate>
            <div className="row">
                <div className="col-12 col-md-12">
                    <p>Enter the code sent to the above email.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <TextInput
                        required
                        id="OTPCode"
                        name="OTPCode"
                        label="Enter Code"
                        onInput={()=>setErr('')}
                        onBlur={()=>handleBlur()}
                        placeholder=""
                        className="w-100 inputField p-3"
                        {...formik.getFieldProps('OTPCode')}
                    />
                    {validationMsg('OTPCode')}
                </div>
            </div>
            {err && 
                <div className="row">
                    <span style={{ color: '#A61003',  margin:5, paddingLeft:10 }}>{err} </span>
                </div>
            }
            <div className="row">
                <div className="col-12 col-md-12 py-2">
                    <Button
                        type="submit"
                        className="btn btn-outline-primary primaryBackground btn-outline-primary-contrast mfaBtn"
                        disabled={(!formik.isValid || !formik.dirty)}
                        onClick={formik.submitForm}
                    >
                        Submit
                    </Button>
                </div>
            </div>
            <ResendOTPBlock duration={'45'} onResend={()=>handleResendCode(values.email)} />
            </form>
            :
            <CreatePassword clrErr={clrErr} handlePasswordData={handlePasswordData} />
            }
        </>
    );
}

export default VerifyRegistrationOTP;
