import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProductManagement from './Sections/ProductManagement';
import DependentManagement from './Sections/DependentManagement';
import ProfileManagement from './Sections/ProfileManagement';
import ManageProfile from './Sections/ManageProfile';
import ProductSelection from './Sections/ProductSelection';
import CallCenterManageClientSearch from './Sections/CallCenterManageClientSearch';
import CallCenterManageDashboard from './Sections/CallCenterManageDashboard';
import ConfiguratorManageClientSearch from './Sections/ConfiguratorManageClientSearch';
import CallCenterSearchEmployee from './Sections/CallCenterSearchEmployee';
import { useNavigationHook } from './Hooks/NavigationHook.js'; // Replace with your actual file path
import EnrollmentSummary from './Sections/EnrollmentSummary';
import { REMOVE_ALERT_MESSAGE } from '../../../Store/Slices/InsuredInfo';


const DynamicComponent = (props) => {
    const dispatch = useDispatch();
    const oldCurrentStep = useSelector((storeState) => storeState.stepForm.currentStep);
    const { currentStep, formData } = useNavigationHook();
    let currentSections = formData?.[currentStep]?.customSection?.sectionName || null;
    let callCenterUrl =
        //window.location.pathname.indexOf('/callcenter/home') !== -1 ||
        //window.location.pathname.indexOf('/callcenter/employeeSearch') !== -1 ||
        //window.location.pathname.indexOf('/callcenter/employeeData') !== -1 ||
        window.location.pathname.indexOf('/ManageProfile') !== -1 ||
        window.location.pathname.indexOf('/configurator/home') !== -1

    if (callCenterUrl) {
        currentSections = props?.contentData?.formsList?.[oldCurrentStep]?.customSection?.sectionName || null;
    }

    switch (currentSections) {
        case "DependentManagement":
            return <DependentManagement {...props} />

        case "ProductManagement":
            return <ProductManagement {...props} />

        case "ProductSelection":
            return <ProductSelection {...props} />

        case "ProfileManagement":
            return <ProfileManagement {...props} className='employeeProfileWrapper' />

        case "ManageProfile":
            return <ManageProfile {...props} className='manageProfileWrapper' />

        case "CallCenterManageClientSearch":
            return <CallCenterManageClientSearch {...props} />

        case "CallCenterManageDashboard":
            return <CallCenterManageDashboard {...props} />

        case "ConfiguratorManageClientSearch":
            return <ConfiguratorManageClientSearch {...props} />

        case "CallCenterSearchEmployee":
            return <CallCenterSearchEmployee {...props} className='' />

        case "EnrollmentSummary":
            return <EnrollmentSummary  {...props} />

        default:
            return null;
    }
}

export default DynamicComponent;
