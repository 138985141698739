import appConfig from "../../../../../../appConfig";
import http from "../../../../../../Common/Services/HttpService";
import { getBasicAuthHeaderValue } from "../../../Helper/MFAHelper";
import { SHOW_RESET_PHONE_TIMER } from "../../../../../../Store/Slices/StartTimer";
import { SHOW_LOADER } from '../../../../../../Store/Slices/Loader/index';

export const getOTPText = (flow, method, data) => {
    if (flow === 'changeMFAContactEmail') {
        return `We must validate your ownership of new email ID. Please enter the security code sent to your new email id ${data.newEmail}`
    } else if (flow === 'changeMFAContactPhone') {
        return `We must validate your ownership of new email ID. Please enter the security code sent to your new phone number`
    } else {
        switch (method) {
            case 'email': 
                return (<>To verify your account, please enter the security code sent to your registered email address <span className="emailText">{data.maskedEmail}</span></>);
            case 'sms':
                return "To verify your account, please enter the security code sent to your registered phone number";
            case 'call':
                return "To verify your account, please enter the security code sent to your registered phone number";
            case 'changeMFAEmail':
            default:
                return "To verify your account, please enter the security code";
        }
    }
}

export const resendOTPApi = async (flow, { dispatch, data, successHandler, errorHandler }) => {
    let reqObj;
    let reqURL;
    switch (flow) {
        case 'resetPassword':
            reqObj = {
                "login": data.login,
                "reason": 'password',
                "channel": data.preferredChannel
            }
            reqURL = `${appConfig.SSP_AUTH_URL}/stateful/user-mgmt/send-challenge-ex`;
            dispatch(SHOW_RESET_PHONE_TIMER(false));
            break;
        case 'changeMFAContactEmail':
            reqObj = {}
            reqURL = `${appConfig.SSP_AUTH_URL}/stateful/user-mgmt/resend-challenge`;
            break;
        case 'changeMFAContactPhone':
            reqObj = {
                phoneNumber: data.phone,
                verificationMethod: data.phoneVerificationMethod
            };
            reqURL = `${appConfig.SSP_AUTH_URL}/stateful/user-mgmt/change-phone-number/1`;
            break;
        default:
            reqURL = `${appConfig.SSP_AUTH_URL}/stateful/user-mgmt/send-challenge-ex`;
            reqObj = {
                "login": data.login,
                "reason": 'password',
                "channel": data.preferredChannel
            }
    }

    const basicAuthorizationValue = getBasicAuthHeaderValue();
    const options = {
        withCredentials: true,
        headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200),
        }
    }

    await http.post(reqURL, reqObj, options).then(async (resp) => {
        successHandler && successHandler(resp);
        dispatch(SHOW_LOADER(false));

    }).catch((e) => {
        
        errorHandler && errorHandler(e);
        dispatch(SHOW_LOADER(false));
    });
}

export const validateOTPApi = async (flow, { dispatch, data, successHandler, onNext, errorHandler }) => {
    let reqObj;
    let reqURL;
    dispatch(SHOW_LOADER(true));

    switch (flow) {
        case 'resetPassword':
            reqObj = { "otp": data.MFASecurityCode }
            reqURL = `${appConfig.SSP_AUTH_URL}/stateful/user-mgmt/verify-challenge`
            break;
        case 'changeMFAContactEmail':
            reqObj = { "otp": data.MFASecurityCode }
            reqURL = `${appConfig.SSP_AUTH_URL}/stateful/user-mgmt/change-email/2`
            break;
        case 'changeMFAContactPhone':
            reqObj = { "otp": data.MFASecurityCode }
            reqURL = `${appConfig.SSP_AUTH_URL}/stateful/user-mgmt/change-phone-number/2`
            break;
        default: break;
    }

    const basicAuthorizationValue = getBasicAuthHeaderValue();
    const options = {
        withCredentials: true,
        headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200),
        }
    }

    await http.post(reqURL, reqObj, options)
        .then(async (resp) => {
            successHandler && successHandler(resp);
            dispatch(SHOW_LOADER(false));
        })
        .catch((e) => {
            
            errorHandler && errorHandler(e);
            dispatch(SHOW_LOADER(false));
        });
};
