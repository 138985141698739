import { cloneDeep } from "lodash";
import { UPDATE_BENEFICIARIES_PRODUCTS, UPDATE_SUBSCRIBED_ELEMENTS } from "../../../../Store/Slices/InsuredInfo";
import { store } from "../../../../index";

// List of Product Names
const form = {
  "Accident": "CoverageSelection",
  "Pet Insurance": "PetAssureNation",
  "Whole Life": "WLCoverage",
  "ID Theft": "IdTheft",
  "Supplemental Health Insurance": "SupplementalHealthInsurance",
  "Legal": "LegalCoverage",
  "Travel Insurance": "Travel",
  "Pet Insurance - PETPlus": "PetPlus",
  "Pet Insurance - Pet Assure": "PetAssure",
  "Hospital Indemnity": "HospitalIndemnity",
  "Critical Illness": "CriticalIllness",
  "Universal Life": "UniversalLife",
  "Liability": "ExcessLiability",
};

const resetFields = (beneficiaries= []) => {
  const newBeneficiaries = [];
  beneficiaries.forEach(beneficiary => {
    const newBeneficiary = cloneDeep(beneficiary);
    newBeneficiary['beneficiaryPercentage'] = 0;
    newBeneficiary['beneficiaryType'] = 'cPrimary';
    newBeneficiaries.push(newBeneficiary);
  });
  return newBeneficiaries;
};

export const getFormKeyByProductFamily = (productFamily) => {
  return form[productFamily] ? form[productFamily] : productFamily ? productFamily.replace(/\s/g, '') : '';
};

const getGenderFromTitle = (title) => {
  if(title === 'ptMr') {
    return 'gMale'
  } else if(title === 'ptMrs') {
    return 'gFemale'
  } else {
    return 'gUndisclosed'
  }
}

// Function to get all the information about beneficiaries
export const getBeneficiariesData = (storeFromRedux) => async (dispatch) => {
  const storeState = store.getState()
  const currentStep = storeState?.stepFormNew?.currentStep;
  const subscribedElements = storeState?.enrollment?.enrollmentData?.contract?.subscribedElements;
  const process = storeState?.process;
  const dependentsList = storeState?.employeeProfile?.insuredsAsBeneficiaries;
  let insuredInfo = {};
  const insureds = storeState?.enrollment?.enrollmentData?.contract?.insureds;
  const productsToUpdate = [];
  if (insureds) {
    insureds.forEach(({ insured, person, nonSharedAdditionalDatas }) => {
      const personBUID = person?.identifier?.bUID;
      if (personBUID) {
        // Iterate through subscribedElements to get beneficiaries
        if (subscribedElements) {
          let beneficiariesProduct = [];
          let globalBeneficiaries = [];
          let currentBuid = [];
          subscribedElements?.forEach(({ subscribedProduct, coverages }) => {
            const productBUID = subscribedProduct?.productCode?.buid;
            const productName = Object.keys(process.productFamilyIdentifier).find(key => process.productFamilyIdentifier[key] === productBUID);
            const productNameTitle = getFormKeyByProductFamily(productName);
            if (productName) {
              productsToUpdate.push(productNameTitle);
              // If the productName is not present in insuredInfo, initialize it
              if (!insuredInfo.hasOwnProperty(productNameTitle)) {
                insuredInfo[productNameTitle] = {
                  productBUID: productBUID,
                  InsuredQuestionAnswers: {},
                  coverageDetails: [],
                  coverageBuids: [],
                  PolicyReplacementQuestions: [],
                  WaiveCoverage: [],
                  updatedBeneficiary: {}
                };
              }
              // Iterate through coverages to match personBUID with coveragePersonBUID
              
                coverages?.forEach(({ insuredPersons, bUID }) => {
                  const insuredArray = [];
                  insuredPersons?.forEach(({ person: coveragePerson, beneficiariesDescription }) => {
                    const coveragePersonBUID = coveragePerson?.identifier?.bUID;
                    
                    if (beneficiariesDescription && beneficiariesDescription?.beneficiaries) {
                      let beneficiaryInfo = beneficiariesDescription?.beneficiaries || [];
                      let changedBeneficiaries = beneficiaryInfo.map(beneficiaryObj => {
                        const info = beneficiaryObj?.beneficiaryInformation || {};
                        const actorData = coveragePerson && coveragePerson.data && coveragePerson.data.actor || {};
                        const address = coveragePerson && coveragePerson.data && coveragePerson.data.correspondences && coveragePerson.data.correspondences[0] && coveragePerson.data.correspondences[0].address || {};
                        const beneficiaryPersonBuid = beneficiaryObj?.beneficiary?.identifier?.bUID || '';
                        // const beneficiaryBuid = beneficiaryObj?.bUID || '';                 
                        const beneficiaryBuid = beneficiaryObj?.beneficiary?.identifier?.bUID || beneficiaryObj?.bUID || '';                 
                        const isDependent = dependentsList.find(dependent => dependent?.id === beneficiaryBuid);
                        return {
                          legalName: info.name || '',
                          // legalName: (info.firstName || '') + ' ' + (info.lastName || ''),
                          beneficiaryIrrevocable: info.irrevocable || false,
                          beneficiaryCollateral: false,
                          beneficiaryFirstName: info.firstName || actorData.name || '',
                          beneficiaryMiddleName: actorData.middleName1 || '',
                          beneficiaryLastName: info.lastName || actorData.shortName || '',
                          beneficiaryLastName2: actorData.name || '',
                          //SSN: info.sSN || actorData.sSN || '',
                          SSN: info.sSN ? info.sSN : !!isDependent ? actorData.sSN :'',
                          beneficiaryRelationship: !!isDependent ? isDependent?.beneficiaryRelationship : info.relationToInsured || '',
                          beneficiaryOptions: info?.className === 'aSPLI_US_LegalEntityBeneficiary' ? 'trust' : 'individual',
                          beneficiaryPercentage: info.percentage || 0,
                          beneficiaryType: info.beneficiaryRank || '',
                          //beneficiaryGender: info.gender || actorData.gender || '',
                          beneficiaryGender: info?.title  ? getGenderFromTitle(info?.title) : !!isDependent ? actorData.gender : '',
                          beneficiaryBirthDate: info.birthDate || actorData.birthDate || '',
                          mailingAddressOptions: coveragePerson && coveragePerson.data && coveragePerson.data.mailingAddressOptions || '',
                          liveInSameAddress: coveragePerson && coveragePerson.data && coveragePerson.data.liveInSameAddress || '',
                          beneficiaryAddressLine1: address && address.line1 || '',
                          beneficiaryAddressLine2: address && address.line2 || '',
                          beneficiaryCity: address && address.zipCodeAndCity && address.zipCodeAndCity.city || '',
                          beneficiaryState: address && address.state || '',
                          beneficiaryZipCode: address && address.zipCodeAndCity && address.zipCodeAndCity.zipCode || '',
                          beneficiaryCountry: address && address.country || '',
                          isEditable: !!isDependent ? false : productNameTitle !== currentStep ? false : true,
                          data: coveragePerson && coveragePerson.data,
                          id: !!isDependent ? beneficiaryBuid : '',
                          beneficiaryBuid: !!!isDependent ? beneficiaryBuid : '',
                          beneficiaryPersonBuid: beneficiaryPersonBuid,
                          beneficiarySelected: productNameTitle === currentStep ? true : false,
                          isDependent: !!isDependent,
                          type: !!isDependent ? 'dependentBeneficiary' : productNameTitle !== currentStep ? 'globalBeneficiary' : 'beneficiary',
                          isMainBeneficiary: productNameTitle === currentStep,
                        };
                      });
                      insuredArray.push(...changedBeneficiaries);
                    }
                  });
                  if ( productNameTitle !== currentStep) {
                    globalBeneficiaries = [...globalBeneficiaries, ...resetFields(insuredArray)];
                    insuredInfo[productNameTitle].updatedBeneficiary[bUID] = insuredArray;
                  } else {
                    beneficiariesProduct.push(insuredArray);
                    currentBuid.push(bUID);
                  }
                });
            }
          });
          currentBuid.forEach(( product, index )=> {
            globalBeneficiaries = globalBeneficiaries.filter((item) => {
              return !(item.beneficiaryOptions === "individual" && item.beneficiaryRelationship === "")
            })
            insuredInfo[currentStep].updatedBeneficiary[product] = [...beneficiariesProduct[index], ...globalBeneficiaries];
          });
        }
      }
    });
  }

  const uniqueProducts = [...new Set(productsToUpdate)];

  dispatch(UPDATE_BENEFICIARIES_PRODUCTS({ insuredInfo, productsToUpdate: uniqueProducts }))
  dispatch(UPDATE_SUBSCRIBED_ELEMENTS(subscribedElements))
};