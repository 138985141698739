import ServiceHelper from '../../../Utils/Helper/MappingHelper';
import { SUBSCRIBE_COVERAGE_BUYUP_BUIDS, UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo';
import { UPDATE_COVERAGE_INFO } from '../../../../Store/Slices/Entities/response';
import { getListOfCoveragePlanDesignConfigurationNew } from './getListOfCoveragePlanDesignConfigurationNew';


const serviceHelper = new ServiceHelper();

export const getListOfCoveragePlanDesignConfigurationNewBuyUp = (
    currentStep, 
    enrollmentProcessBuid, 
    buyUpCoverageBuid, 
    personBuid,
    basePlanCoverageBuidArr
) => async dispatch => {
    if ( !currentStep || !enrollmentProcessBuid || !buyUpCoverageBuid || !personBuid || !basePlanCoverageBuidArr.length) {
        console.error("getListOfCoveragePlanDesignConfigurationNew called with missing parameters");
        return;
    }
    dispatch(UPDATE_LOADING(true));
    try{
        if(basePlanCoverageBuidArr.length) {
            let i = 0;
            while (i < basePlanCoverageBuidArr.length) {
                await dispatch(getListOfCoveragePlanDesignConfigurationNew(
                    currentStep, 
                    enrollmentProcessBuid, 
                    basePlanCoverageBuidArr[i], 
                    personBuid
                ))
                i++;
            }
            
            let resp = await serviceHelper.sendApiRequest('/getListOfCoveragePlanDesignConfigurationNew', {
                enrollmentProcessBuid: enrollmentProcessBuid,
                coverageBuid: buyUpCoverageBuid
            }); 
            if (resp && resp.data && resp.status === 200) {
                await dispatch(SUBSCRIBE_COVERAGE_BUYUP_BUIDS({
                    coverageBuid: buyUpCoverageBuid,
                    personBuid: personBuid,
                    product: currentStep,
                }));
                await dispatch(
                    UPDATE_COVERAGE_INFO({
                        coverageBuid: buyUpCoverageBuid,
                        personBuid: personBuid,
                        product: currentStep,
                        listOfCoveragePlanDesignConfiguration: resp.data
                    })
                ); 
            }
        }
    } catch(e) {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: resp?.status || '520',
            id: 'getListOfCoveragePlanDesignConfigurationNew',
            show: false,
            page: currentStep,
        }));
        console.error(e)
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}