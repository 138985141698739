import React from 'react';

import Login from '../../../Layout/Login/Login';
import LoginViewUser from '../../../Tissues/LoginViewUser';
import LoginScreen from '../../../Molecules/CustomSection/MFA/Login';

export default [
  { path: '/client', render: (props) => <Login type="Client" {...props} /> },
  { path: '/employer', render: (props) => <Login type="Employer" {...props} /> },
  { path: '/employee', render: (props) => <Login type="Employee" {...props} /> },
  { path: '/broker', render: (props) => <Login type="Broker" {...props} /> },
  { path: '/customer', render: (props) => <Login type="Customer" {...props} /> },
  //{ path: '/enrollment', render: (props) => <Login type="Enrollment" {...props} /> },
  { path: '/oldenrollment', render: (props) => <Login type="Enrollment" mfa={false} {...props} /> },
  { path: '/performance-login', render: (props) => <Login performance type="Enrollment"  mfa={false} {...props} /> },
  { path: '/hrcenterold/login', render: (props) => <Login type="HrCenter" mfa={false} {...props} /> },
  { path: '/configurator', render: (props) => <Login type="Configurator" {...props} /> },
  { path: '/callcenter', render: (props) => <Login type="CallCenter" {...props} /> },
  {
    path: '/employeeRegistration',
    render: (props) => <Login type="EmployeeRegistration" {...props} />,
  },
  { path: '/loginViewUser', render: (props) => <Login type="Enrollment" {...props} /> },
  { path: '/enrollment', render: (props) => <LoginScreen type="Enrollment" {...props} /> },
  { path: '/hrcenter/login', render: (props) => <LoginScreen type="HrCenter" {...props} /> },
];
