import { useRef, useState } from 'react';
import DefaultArrow from '../../../../../Assets/Images/DefaultArrow.svg';
import DisabledArrow from '../../../../../Assets/Images/DisabledArrow.svg';
import { getFileExtension } from '../../Helper/commonHelper';

import './styles.css';

const ProductSlider = ({ products = [], count = 2 }) => {

    const scrollArea = useRef(null);
    const [currentLeft, setCurrentLeft] = useState(0)
    const itemSize = (290 + 24) * count;

    const onLeftClick = () => {
        const newLeft = currentLeft + itemSize;
        setCurrentLeft(newLeft > 0 ? 0 : newLeft)
    }

    const onRightClick = () => {

        const scrollWidth = scrollArea.current?.scrollWidth;
        const parentWidth = scrollArea.current?.parentElement?.offsetWidth;

        const newLeft = currentLeft - itemSize;
        if (parentWidth - newLeft > scrollWidth) {
            setCurrentLeft(parentWidth - scrollWidth);
        } else {
            setCurrentLeft(newLeft);
        }
    }

    const disableLeft = currentLeft === 0;
    const scrollWidth = scrollArea.current?.scrollWidth;
    const parentWidth = scrollArea.current?.parentElement?.offsetWidth;
    const disableRight = (parentWidth - scrollWidth) >= currentLeft;

    return (
        <div className='product-slider'>
            <button className="slide-btn btn-left" onClick={onLeftClick} disabled={disableLeft} aria-label="Slide Left">
                <img src={disableLeft ? DisabledArrow : DefaultArrow} alt="Left Arrow" />
            </button>
            <div className='product-slides-area'>
                <div className='product-slides-list' ref={scrollArea} style={{ left: currentLeft }}>
                    {products.map((item, index) => {
                        let fileType = getFileExtension(item.logoName) || '';
                        fileType = fileType === 'svg' ? 'svg+xml' : fileType;
                        const logo = item.logo ? "data:image/" + fileType + ";base64," + (item.logo) : '';
                        return (
                            <div key={index} className='product-item'>
                                {logo ? <img alt={item.logoName} className='product-logo' src={logo} /> : <div className='product-dummy' />}
                                <div className='product-name'>{item.name}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <button className="slide-btn" onClick={onRightClick} disabled={disableRight} aria-label="Slide Right">
                <img src={disableRight ? DisabledArrow : DefaultArrow} alt="Right Arrow" />
            </button>
        </div >
    )
}

export default ProductSlider;
