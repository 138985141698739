import React, { useState, useEffect } from 'react';
import BeneficiaryModel from './BeneficiaryModel';
import PropTypes from 'prop-types';
import BenificaryList from './BenificaryList'
import { useDispatch, useSelector } from 'react-redux';
import StickyNote from '../../../../Atoms/StickyNote';
//import MyAccordion from '../../Molecules/CustomSection/Component/InsuredQuestions'
//import PolicyReplacementQuestionsContainer from '../../Molecules/CustomSection/Container/policyQuestions'
//import ParentComponent from '../../Molecules/CustomSection/parentComponent'

import { UPDATE_UPDATED_BENEFICIARIES } from '../../../../../Store/Slices/EmployeeProfile/index';

import './beneficiary-styles.css'
import { useNavigationHook } from '../../Hooks/NavigationHook';

const Beneficiary = (props) => {
    const {
        coverageBuid,
        dependentsList,
        relation,
        personBuid,
        isBeneficiaryMandatory = false,
        isBuyUpFlow = false,
        basePlanCoverageBuid,
        isBeneficiarySectionValidCallback
    } = props;

    const [show, setShow] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [modalKey, setModalKey] = useState(null);
    const [headerText, setHeaderText] = useState("Add a Beneficiary");
    const [beneficiaryAdded, setBeneficiaryAdded] = useState(false);
    const { currentStep } = useNavigationHook();
    const dispatch = useDispatch();
    const handleClose = () => {
        setShow(false);
        setEditIndex(null);
    };
    const handleShow = () => {
        setShow(true);
        setModalKey(Date.now()); // Update the key with the current timestamp
        updateHeaderText("add")
    };


    const handleSubmit = (beneficiary) => {
        if (editIndex !== null) {
            handleUpdate(beneficiary);
        } else {
            const uniqueId = () => {
                const dateString = Date.now().toString(36);
                const randomness = Math.random().toString(36).substr(2);
                return dateString + randomness;
            };
            setBeneficiaries([...beneficiaries, { ...beneficiary, isEditable: true, beneficiarySelected: true, tempId: uniqueId(), beneficiaryType: 'Primary' }])
            if (isBeneficiarySectionValidCallback) {
                isBeneficiarySectionValidCallback(false);
            }
            setBeneficiaryAdded(true)
        }
        handleClose();
    };


    const updateHeaderText = (action) => {
        if (action === "add") {
            setHeaderText("Add a Beneficiary");
        } else if (action === "edit") {
            setHeaderText("Edit Beneficiary");
        }
    };

    const handleEdit = (index) => {
        if (isBeneficiarySectionValidCallback) {
            isBeneficiarySectionValidCallback(false);
        }
        setEditIndex(index);
        handleShow();
        updateHeaderText("edit")
    };

    const handleDelete = (index) => {
        setBeneficiaries(beneficiaries.filter((_, i) => i !== index));

    };

    const handleUpdate = (updatedBeneficiary) => {
        if (isBeneficiarySectionValidCallback) {
            isBeneficiarySectionValidCallback(false);
        }
        setBeneficiaryAdded(true)
        setBeneficiaries(
            beneficiaries.map((beneficiary, i) =>
                i === editIndex ? updatedBeneficiary : beneficiary
            )
        );
    };

    useEffect(() => {
        props.setUpdatedBeneficiaryList && props.setUpdatedBeneficiaryList(beneficiaries)
    }, [beneficiaries])

    return (
        <>
            <div className='beneficiary-note'>
                <h3>Select Beneficiary Information</h3>
                <div className='mb-4'>Kindly select a beneficiary from the list below or add a new one of your choice. You must have one primary beneficiary selected to elect this product.</div>
                <BenificaryList
                    beneficiaries={beneficiaries}
                    coverageBuid={coverageBuid}
                    currentStep={currentStep}
                    initialBeneficiaries={dependentsList}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    personBuid={personBuid}
                    relation={relation}
                    setBeneficiaries={setBeneficiaries}
                    updateBeneficiary={props.updateBeneficiary}
                    setCanContinueBeneficiaries={props.setCanContinueBeneficiaries}
                    isBeneficiaryMandatory={isBeneficiaryMandatory}
                    isBuyUpFlow={isBuyUpFlow}
                    basePlanCoverageBuid={basePlanCoverageBuid}
                    beneficiaryAdded={beneficiaryAdded}
                    isBeneficiarySectionValidCallback={isBeneficiarySectionValidCallback}
                />
                <button type="button" className="btn btn-primary btn-primary-contrast" onClick={handleShow}>
                    Add a Beneficiary
                </button>

                <StickyNote
                    value={"Beneficiary allocation percentage must be equal to 100% for each beneficiary type (Primary and Contingent)"}
                    title={"Note"}
                    hidden={false}
                    singleValue="true"
                    notArray="true"
                    color="#FFFAEA"
                    borderColor="#FFD55A"
                    classNameWrapper="custom-note"
                />
            </div>

            <div className={`modal ${show ? 'show' : ''} add-beneficiary`} style={{ display: show ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg" role="document" style={{ maxWidth: '800px', maxHeight: '600px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title">{headerText}</div>
                            <button type="button" className="close" onClick={handleClose}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <BeneficiaryModel
                                key={modalKey} // Use the modalKey here
                                onSubmit={handleSubmit}
                                onCancel={handleClose}
                                beneficiaryOptions={beneficiaries[editIndex]?.beneficiaryOptions}
                                initialValues={
                                    editIndex !== null ? beneficiaries[editIndex] : undefined
                                }
                                allowedBeneficiaryKind={props.allowedBeneficiaryKind}

                            />
                        </div>
                    </div>
                </div>
            </div>

            {show && (
                <div className="modal-backdrop show" onClick={handleClose}></div>
            )}
        </>
    );
};

Beneficiary.propTypes = {
    coverageBuid: PropTypes.string,
    dependentsList: PropTypes.array,
    personBuid: PropTypes.string,
    relation: PropTypes.string,
};

Beneficiary.defaultProps = {
    coverageBuid: "",
    dependentsList: [],
    personBuid: "",
    relation: "",
};

export default Beneficiary;







