import EnrollEmployee from '../../../Layout/Enrollment/EnrollEmployee';
import HomePage from '../../../Layout/Enrollment/HomePage';
import LifeEvent from '../../../Layout/Enrollment/LifeEvent';
import ReviewOpenEnrollment from '../../../Layout/Enrollment/ReviewOpenEnrollment';
import ManageBeneficiaries from '../../../Layout/Enrollment/ManageBeneficiaries';
import QLEOpenEnrollment from '../../../Layout/Enrollment/QLEOpenEnrollment';
import CrossSell from '../../../Layout/Enrollment/CrossSell';
import EmployeeProfile from '../../../Layout/Enrollment/EmployeeProfile';

export default [
  { path: '/enrollment/enrollEmployee', component: EnrollEmployee },
  { path: '/enrollment/home', component: HomePage },
  { path: '/enrollment/employeeProfile', component: EmployeeProfile },
  { path: '/enrollment/lifeEvent', component: LifeEvent },
  { path: '/enrollment/reviewopenenrollment', component: ReviewOpenEnrollment },
  { path: '/enrollment/manageBeneficiaries', component: ManageBeneficiaries },
  { path: '/enrollment/crossSell', component: CrossSell },
  { path: '/enrollment/qleopenenrollment', component: QLEOpenEnrollment },
];
