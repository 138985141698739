import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import StepForm from '../../Atoms/StepForm';
import ImageLayout from '../../Atoms/ImageLayout';
import ContactSelection from './ContactSelection';
import MFAImage from '../../../../../Assets/Images/mfa.png';

import '../styles.css';

const ChangeMFAContact = () => {
    const history = useHistory();
    const initialSteps = [{ component: ContactSelection, updateSteps, onComplete }];
    const [steps, setSteps] = useState([...initialSteps]);

    function updateSteps(newSteps) {
        setSteps([...initialSteps, ...newSteps])
    }

    function onComplete() {
        history.push('/enrollment/home');
    }

    return (
        <ImageLayout image={{ src: MFAImage, alt: "Change MFA Content" }}>
            <StepForm steps={steps} className={'forMFA'}></StepForm>
        </ImageLayout>
    )
}

export default ChangeMFAContact;
