import React, { useState, useEffect } from 'react';
import EmployeeInsuredQuestionsContent from '../Component/Common/EmployeeInsuredQuestionsContent';
import { UPDATE_COVERAGE_DETAILS } from '../../../../Store/Slices/InsuredInfo';
import Summary from '../Component/Common/Summary'
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_CART_ITEM_NEW } from '../../../../Store/Slices/EmployeeProfile';
import CoverageFlowFourWrapper from '../Container/CoverageFlowFourWrapper';
const Flow4 = (props) => {
  const { currentStep, step, nextStep, prevStep, employee, spouse, children, showDisclosureStatement, validateSubscribedProductData, RadioSelctedValue, declineProduct } = props;
  const dispatch = useDispatch();
  const { insuredInfo } = useSelector((storeState) => storeState);
  let coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids;
  const [currentSelectedValue, setCurrentSelectedValue] = useState('');
  const productTitle = useSelector(
    (storeState) => {
      let x =
        storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result?.filter(
          (data) => data.code == 'PRODTITLE'
        );
      if (x == undefined) {
        return '';
      } else {
        return x[0]?.content;
      }
    }
  );
  let coverageDetailsData = insuredInfo[currentStep]?.coverageDetails;

  useEffect(() => {
    if (coverageDetailsData && productTitle) {
      updateCostBasket()
    }
  }, [coverageDetailsData, productTitle]);
  
  const handleCoverageChange = (personBuid, selectedValue, waivedCoverage, coverageBuid, costPerPayPeriod) => {

    setCurrentSelectedValue(selectedValue)
    dispatch(UPDATE_COVERAGE_DETAILS({ personBuid, selectedValue, waivedCoverage, currentStep, coverageBuid, costPerPayPeriod }));
  };
  
  const updateCostBasket = () => {
    dispatch(
      UPDATE_CART_ITEM_NEW({
        id: currentStep,
        moreThanOne: true,
        name: productTitle,
        coverageList: coverageDetailsData,
      })
    );
  };

  const [skipSpouse, setSkipSpouse] = useState([]);
  const skipSpouseFn = (pskipData) => {
    setSkipSpouse([...pskipData]);
  };

  const [skipChild, setSkipChild] = useState([]);
  const skipChildFn = (pskipData) => {
    setSkipChild([...pskipData]);
  };

  const steps = [
    {
      label: '',
      component: () => <div><EmployeeInsuredQuestionsContent stepsCount={steps.length} employeeIndex="0" displayMode={'Employee'} {...props}></EmployeeInsuredQuestionsContent></div>,
    },
  ];

  if (coverageDetailsForPrepare && coverageDetailsForPrepare.length) {
    coverageDetailsForPrepare.forEach((val, index) => {    
        steps.push(
          {
            label: index === 0 ?'Personal Excess Liability':'Uninsured and Underinsured Limits',
            component: () => <div><CoverageFlowFourWrapper stepsCount={steps.length} coverageTableCount={coverageDetailsForPrepare.length} coverageIndex={index} coverageOption={val?.coverage} {...props} handleCoverageChange={handleCoverageChange}/></div>,
          }
        );
    });
  }

 
  if (showDisclosureStatement) {
    steps.push({
      label: 'Disclosure Statement',
      component: () => <div className='summaryWrapper'><Summary RadioSelctedValue={RadioSelctedValue} showDisclosureStatement={showDisclosureStatement} validateSubscribedProductData={validateSubscribedProductData} declineProduct={declineProduct} {...props}></Summary></div>,
    });
  }

  const currentStepInfo = steps[step - 1];

  if (!currentStepInfo) {
    return null; // or return some other default UI or error message
  }
  return (
    <>
      {currentStepInfo.component()}
    </>
  );
};

export default Flow4;
