import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxConstants } from '../../../../Common/Constants/index';
import Loader from '../../../Atoms/Loader';
import formFields from '../Content/RegisterConfig.json';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveConfig } from '../Actions/saveConfig';
import { retrieveConfig } from '../Actions/retrieveConfig';
import SuccessPage from '../Common/SuccessPage';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response';
import { Checkbox, TextInput, Button, Label } from '../../../Molecules/CustomSection/Atoms';
import '../styles.css';
import { CLEAR_ALERT_MESSAGES, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { getErrorMessages } from '../../../Molecules/CustomSection/Helper/MappingHelper';
import { MessageComponent } from '../../../Atoms';

const { confirmation, formReferenceLinkText } = formFields;
const RegisterConfig = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const dispatch = useDispatch();
  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};

  const configDataRedux = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.userConfigInfo?.[0]
  );

  const configData = {
    birthDateToggle: configDataRedux?.birthDate?.hideableField || false, // hideableField
    employeeIdPlaceholder: configDataRedux?.employeeId?.editableLabelField || false, // nonEditableField
    employeeIdToggle: configDataRedux?.employeeId?.switchField || false,
    employeeIdPlaceholderValue: configDataRedux?.employeeId?.editableLabelPlacerholderValue || '',
  };

  const formik = useFormik({
    initialValues: {
      birthDateToggle: configData?.birthDateToggle,
      employeeIdPlaceholder: configData?.employeeIdPlaceholder,
      employeeIdToggle: configData?.employeeIdToggle,
      employeeIdPlaceholderValue: configData?.employeeIdPlaceholderValue,
    },

    onSubmit: (values, { setSubmitting }) => {
      formik.resetForm();
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  let { clientId } = useSelector((storeState) => storeState.auth.userAuth);

  useEffect(() => {
    dispatch(CLEAR_ALERT_MESSAGES([]));
  }, [formik.values]);

  const saveRegisterConfig = async () => {
    let data = {
      RegisterInformation: {
        birthDateToggle: formik?.values?.birthDateToggle,
        employeeIdPlaceholder: formik?.values?.employeeIdPlaceholder,
        employeeIdToggle: formik?.values?.employeeIdToggle,
        employeeIdPlaceholderValue: formik?.values?.employeeIdPlaceholderValue,
      },
    };
    setShowSuccess(null);
    //Api Call here
    let saveResponse = await saveConfig(data, clientId, formFields.apiCall.submit, setShowLoader);
    if (saveResponse?.status == 201) {
      setShowSuccess(true);
    } else {
      dispatch(
        SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'createRetriveInfoFromDb',
          show: true,
          page: 'Configurator',
        })
      );
      setShowSuccess(false);
    }
  };

  useEffect(() => {
    // //Api Call here
    setShowLoader(true);
    const callAPI = async () => {
      let response = await retrieveConfig(clientId, formFields.apiCall.onload);
      if (response?.status == 200) {
        dispatch(
          onLoadAPI({
            userConfigInfo: response.data,
            isAPICallInProgress: false,
          })
        );
      } else {
        setShowSuccess(null);
        setTimeout(() => {
          dispatch(
            SET_ALERT_MESSAGE({
              type: 'error',
              errorCode: '520',
              continue: true,
              id: 'getRetriveInfoFromDb',
              show: true,
              page: 'Configurator',
            })
          );
          setShowSuccess(false);
        }, 50);
      }
      setShowLoader(false);
    };
    try {
      callAPI();
    } catch (error) {
      
    }
  }, [loggedUserEntity]);

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  const hasErrors = getErrorMessages('Configurator');

  return (
    <div>
      <MessageComponent currentPage={'Configurator'} setShowLoader={setShowLoader} />
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper">
          {!showSuccess && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="config">
                <div className="col-12 px-lg-5 px-md-5 px-sm-0">
                  <div className="row py-3 px-3">
                    <h4>{formFields.panelHeader.label}</h4>
                  </div>

                  <div className="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dataField.label}
                        inputId={formFields.dataField.label}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxToggle.label}
                        inputId={formFields.checkboxToggle.label}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editablePlaceHolderLabel.label}
                        inputId={formFields.editablePlaceHolderLabel.label}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editablePlaceHolderLabelValue.label}
                        inputId={formFields.editablePlaceHolderLabelValue.label}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dateOfBirth.label}
                        inputId={formFields.dateOfBirth.label}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.birthDateToggle.id}
                        name={formFields.birthDateToggle.name}
                        checked={formik.values.birthDateToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.birthDateToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right"></div>
                    <div className="col-3 py-2 no-padding-right"></div>
                  </div>

                  <div className="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.employeeId.label}
                        inputId={formFields.employeeId.label}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.employeeIdToggle.name.id}
                        name={formFields.employeeIdToggle.name.name}
                        checked={formik.values.employeeIdToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.employeeIdToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.employeeIdPlaceholder.id}
                        name={formFields.employeeIdPlaceholder.name}
                        checked={formik.values.employeeIdPlaceholder}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.employeeIdPlaceholder.name)}
                      />
                    </div>
                    <div className="col-3 py-2 inputConfig">
                      <TextInput
                        id={formFields.employeeIdPlaceholderValue.id}
                        name={formFields.employeeIdPlaceholderValue.name}
                        placeholder={formFields.employeeIdPlaceholderValue.placeholder}
                        {...formik.getFieldProps(formFields.employeeIdPlaceholderValue.name)}
                      />

                      {formik.touched.employeeIdPlaceholderValue &&
                      formik.errors.employeeIdPlaceholderValue ? (
                        <div className="form-mandatory">
                          {formik.errors.employeeIdPlaceholderValue}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 py-2 no-padding-right">
                      <Label text={formFields.info.label} inputId={formFields.info.label} />
                    </div>
                  </div>

                  <div className="row w-100 form-btnGroup py-5">
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 py-2 w-100">
                      <Button
                        className="btn btn-primary  primaryBackground mr-4"
                        onClick={saveRegisterConfig}
                        disabled={hasErrors}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}

          {showSuccess && <SuccessPage {...confirmation}></SuccessPage>}
        </div>

        <div class="px-md-5 py-5 container-fluid"></div>
      </Suspense>
    </div>
  );
};

export default RegisterConfig;
