import { createSlice } from '@reduxjs/toolkit';
import http from '../../../../Common/Services/HttpService';
const slice = createSlice({
  name: 'userAuthToken',
  initialState: {
    authTokenType: '',
  },
  reducers: {
    processAuthTokenType: (userAuthToken, action) => {
        userAuthToken.authTokenType =  action.payload.authTokenType;
        if (userAuthToken?.authTokenType) {
            http.setAuthTokenUserType(userAuthToken?.authTokenType);
        }
    }
  },
});
export const { processAuthTokenType } = slice.actions;
export default slice.reducer;
