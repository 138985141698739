import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response/index';
import { reduxConstants } from '../../../../Common/Constants/index';
import Loader from '../../../Atoms/Loader';
import formFields from '../Content/HeaderConfig.json';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveConfig } from '../Actions/saveConfig';
import { retrieveConfig } from '../Actions/retrieveConfig';
import SuccessPage from '../Common/SuccessPage';
import '../styles.css';
import { CLEAR_ALERT_MESSAGES, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { getErrorMessages } from '../../../Molecules/CustomSection/Helper/MappingHelper';
import { MessageComponent } from '../../../Atoms';
import { Checkbox, TextInput, Button, Label } from '../../../Molecules/CustomSection/Atoms';

const { confirmation, formReferenceLinkText } = formFields;

const HeaderConfig = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const dispatch = useDispatch();
  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};

  const configDataRedux = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.headerConfigInfo?.header
  );

  const configData = {
    firstNameToggle: configDataRedux?.firstName || false, // hideableField
    lastNameToggle: configDataRedux?.lastName || false, // nonEditableField
    fullNameToggle: configDataRedux?.fullName || false,
    portalNameToggle: configDataRedux?.displayPortalName || false,
    portalNameEditableValue: configDataRedux?.portalName || '',
  };

  const formik = useFormik({
    initialValues: {
      firstNameToggle: configData?.firstNameToggle,
      lastNameToggle: configData?.lastNameToggle,
      fullNameToggle: configData?.fullNameToggle,
      portalNameToggle: configData?.portalNameToggle,
      portalNameEditableValue: configData?.portalNameEditableValue,
    },

    onSubmit: (values, { setSubmitting }) => {
      formik.resetForm();
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const loginuserName = useSelector((storeState) => storeState?.auth?.userAuth?.data?.user_name);
  const { clientId } = useSelector((storeState) => storeState.auth.userAuth);

  useEffect(() => {
    dispatch(CLEAR_ALERT_MESSAGES([]));
  }, [formik.values]);

  const saveRegisterConfig = async () => {
    let data = {
      firstName: formik?.values?.firstNameToggle,
      lastName: formik?.values?.lastNameToggle,
      fullName: formik?.values?.fullNameToggle,
      portalName: formik?.values?.portalNameEditableValue,
      displayPortalName: formik?.values?.portalNameToggle,
    };
    setShowSuccess(null);
    //Api Call here
    let saveResponse = await saveConfig(data, clientId, formFields.apiCall.submit, setShowLoader);
    if (saveResponse?.status == 201) {
      setShowSuccess(true);
    } else {
      dispatch(
        SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'header',
          show: true,
          page: 'Configurator',
        })
      );
      setShowSuccess(false);
    }
  };

  useEffect(() => {
    // //Api Call here
    setShowLoader(true);
    const callAPI = async () => {
      let response = await retrieveConfig(clientId, formFields.apiCall.onload);
      if (response?.status !== 200) {
        setShowSuccess(null);
        setTimeout(() => {
          dispatch(
            SET_ALERT_MESSAGE({
              type: 'error',
              errorCode: '520',
              continue: true,
              id: 'getHeaderInfoFromDb',
              show: true,
              page: 'Configurator',
            })
          );
          setShowSuccess(false);
        }, 50);
      }
      dispatch(
        onLoadAPI({
          headerConfigInfo: response,
          isAPICallInProgress: false,
        })
      );
      setShowLoader(false);
    };
    try {
      callAPI();
    } catch (error) {
      
    }
  }, [loggedUserEntity]);

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  const hasErrors = getErrorMessages('Configurator');
  return (
    <div>
      <MessageComponent currentPage={'Configurator'} setShowLoader={setShowLoader} />
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper">
          {!showSuccess && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="config">
                <div className="col-12 px-lg-5 px-md-5 px-sm-0">
                  <div className="row py-3 px-3">
                    <h4>{formFields.panelHeader.label}</h4>
                  </div>

                  <div className="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dataField.label}
                        inputId={formFields.dataField.label}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxToggle.label}
                        inputId={formFields.checkboxToggle.label}
                      />
                    </div>

                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editablePlaceHolderLabelValue.label}
                        inputId={formFields.editablePlaceHolderLabelValue.label}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.firstName.label}
                        inputId={formFields.firstName.label}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.firstNameToggle.id}
                        name={formFields.firstNameToggle.name}
                        checked={formik.values.firstNameToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.firstNameToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right"></div>
                    <div className="col-3 py-2 no-padding-right"></div>
                  </div>

                  <div className="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label text={formFields.lastName.label} inputId={formFields.lastName.label} />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.lastNameToggle.id}
                        name={formFields.lastNameToggle.name}
                        checked={formik.values.lastNameToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.lastNameToggle.name)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label text={formFields.fullName.label} inputId={formFields.fullName.label} />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.fullNameToggle.id}
                        name={formFields.fullNameToggle.name}
                        checked={formik.values.fullNameToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.fullNameToggle.name)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.portalName.label}
                        inputId={formFields.portalName.label}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.portalNameToggle.id}
                        name={formFields.portalNameToggle.name}
                        checked={formik.values.portalNameToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.portalNameToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right inputConfig">
                      <TextInput
                        id={formFields.portalNameEditableValue.id}
                        name={formFields.portalNameEditableValue.name}
                        placeholder={formFields.portalNameEditableValue.placeholder}
                        {...formik.getFieldProps(formFields.portalNameEditableValue.name)}
                      />
                    </div>
                  </div>
                  <div className="row w-100 form-btnGroup py-5">
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 py-2 w-100">
                      <Button
                        className="btn btn-primary  primaryBackground mr-4"
                        onClick={saveRegisterConfig}
                        disabled={hasErrors}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}

          {showSuccess && <SuccessPage {...confirmation}></SuccessPage>}
        </div>
      </Suspense>
    </div>
  );
};

export default HeaderConfig;
