import moment from 'moment';
import React, { useState } from 'react';
import arrowLess from '../../../Assets/Images/arrow-up-blue.svg';
import arrowMore from '../../../Assets/Images/arrow-down-blue.svg';
import HistoryCompositeDocument from './HistoryCompositeDocument';

const historyReasons = {
    ADE: 'Administrative Endorsements',
    AE: 'Annual Enrollment',
    AEV: 'Automated Event',
    BC: 'Beneficiary Change',
    BIR: 'Birth, Adoption and Legal Guardianship',
    CCO: 'Cancel Coverage',
    CM: 'Consecutive Miss',
    DEA: 'Death of dependent',
    DNE: 'Dependent no longer eligible',
    DSC: 'Dependent Status Change',
    DIV: 'Divorce, Legal Separation and Annualment',
    MDP: 'Marriage/New Domestic Partner',
    NPE: 'Nationwide Pet Enrollment',
    NH: 'New Hire',
    OE: 'Ongoing Enrollment',
    OP: 'Open Enrollment',
    REI: 'Reinstatement'
}

const HistoryCard = ({ history }) => {

    const [showDescription, setShowDescription] = useState(false);

    const onToggle = () => {
        setShowDescription(!showDescription);
    }

    let historyDate = '--/--/----';
    if (history.effectiveDateOfChange) {
        const [year, month, date] = history.effectiveDateOfChange.split('-');
        historyDate = `${month}/${date}/${year}`;
    }

    let transactionDate = '--/--/----';
    if (history.transactionDate) {
        const [year, month, date] = history.transactionDate.split('-');
        transactionDate = `${month}/${date}/${year}`;
    }

    return (
        <div className="enrollment-history__item" key={history.buid}>
            {history.descriptionOfChange ? (
                <button className="enrollment-history__item-button" onClick={onToggle} aria-label="Toggle Description">
                    <img src={showDescription ? arrowLess : arrowMore} alt="Toggle Description" />
                </button>
            ) : null}

            <div className="enrollment-history__item__col">
                <div className="enrollment-history__item__label">Effective Date of Change</div>
                <div className="enrollment-history__item__value">{historyDate}</div>
            </div>

            <div className="enrollment-history__item__col">
                <div className="enrollment-history__item__label">Change Type</div>
                <div className="enrollment-history__item__value">{history.kind || (history.className === "aSPLI_ContractChange" ? 'Policy Enrollment' : '')}</div>
            </div>

            {history.status === 'asDeclined' ? (
                <div className="enrollment-history__item__col">
                    <div className="enrollment-history__item__label">Status</div>
                    <div className="enrollment-history__item__value">{'Declined'}</div>
                </div>
            ) : (
                <>
                    <div className="enrollment-history__item__col">
                        <div className="enrollment-history__item__label">Event Type</div>
                        <div className="enrollment-history__item__value">{historyReasons[history.reason] || history.reason}</div>
                    </div>

                    <div className="enrollment-history__item__col">
                        <div className="enrollment-history__item__label">Transaction Date</div>
                        <div className="enrollment-history__item__value">{transactionDate}</div>
                    </div>
                </>
            )}

            {showDescription && (
                <div className='enrollment-history__change-description'>
                    <div className="enrollment-history__change-description-title">{history.descriptionOfChange?.title}</div>
                    {history.descriptionOfChange?.composedOf?.map((item, index) => (
                        <HistoryCompositeDocument key={`hcd_${index}`} composition={item} />
                    ))}
                </div>
            )}
        </div>
    )
}

export default HistoryCard;
