
import { useDispatch, useSelector } from "react-redux";
import {
  SET_SKIP_STEPS,
  SET_STEP_KEYS,
  SET_CURRENT_STEP,
  NEXT_STEP,
  PREVIOUS_STEP,
  REMOVE_STEPS_AND_CONTINUE,
  GO_TO_SPECIFIC_STEP
} from '../../../../Store/Slices/StepFormNew/index';


// Define a custom hook for navigation
export const useNavigationHook = () => {

  const dispatch = useDispatch();

  // Get necessary state variables from Redux using destructuring
  const {
    skipSteps,
    stepKeys,
    currentStep,
    formData,
    stepContent,
    formsConfig,
    previousStep,
  } = useSelector((state) => state.stepFormNew);

  // Define a function to dispatch actions to Redux store 
  // It returns a function that takes a payload and dispatches the provided action with this payload
  const handleDispatch = (action) => (payload) => dispatch(action(payload));

  // Define handlers for different actions using the `handleDispatch` function
  const handleNextStep = handleDispatch(NEXT_STEP);
  const handlePreviousStep = handleDispatch(PREVIOUS_STEP);
  const handleFirstStep = handleDispatch(SET_CURRENT_STEP, 0);
  const handleLastStep = handleDispatch(SET_CURRENT_STEP, stepKeys.length - 1);
  const handleSetSkipSteps = handleDispatch(SET_SKIP_STEPS);
  const handleSetStepKeys = handleDispatch(SET_STEP_KEYS);
  const handleSetCurrentStep = handleDispatch(SET_CURRENT_STEP);
  const handleRemoveStepsAndNext = handleDispatch(REMOVE_STEPS_AND_CONTINUE);
  const handleGoToSpecificStep =  handleDispatch(GO_TO_SPECIFIC_STEP);

  // Return the handlers and state variables for use in component
  return {
    handleNextStep,
    handlePreviousStep,
    handleFirstStep,
    handleLastStep,
    handleSetSkipSteps,
    handleSetStepKeys,
    handleSetCurrentStep,
    handleRemoveStepsAndNext,
    handleGoToSpecificStep,
    currentStep,
    stepKeys,
    skipSteps,
    formsConfig,
    formData,
    previousStep,
    stepContent
  };
};
