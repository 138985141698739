import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ParentComponent from '../Flows/index.js';
import { omit } from 'lodash';

import http from '../../../../Common/Services/HttpService/index.js';
import appConfig from '../../../../appConfig/index.js';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response/index.js';
import { UPDATE_INSUREDS_AS_BENEFICIARIES } from '../../../../Store/Slices/EmployeeProfile/index.js';
import { SET_COVERAGE_BUIDS, UPDATE_PRODUCT_LEVEL_ELIGIBILITY } from '../../../../Store/Slices/InsuredInfo/index.js';
import Constants from '../Content/Constants.json';
import { useNavigationHook } from '../Hooks/NavigationHook';

const ProductManagement = (props) => {
    const { currentStep, formsConfig } = useNavigationHook();
    let currentReduxState = useSelector((storeState) => storeState);
    const dispatch = useDispatch();
    const hasRun = useRef(false);
    const [disclosureStatement, setDisclosureStatement] = useState(false);
    let productList = useSelector((storeState) => storeState?.process?.productList || []);
    let productListNeedToRemove = useSelector((storeState) => storeState?.process?.enrollmentProcess[0]?.[1]?._Result);
    if (!productList?.length) {
        productList = productListNeedToRemove
      }

    let insureds = useSelector(state => state.enrollment?.enrollmentData?.contract?.insureds || []);
    const dependentsList = useSelector(state => state.enrollment?.enrollmentData?.contract?.insureds || []);

    const dependentsListsd = useSelector(
        (storeState) => storeState?.employeeProfile?.dependentsList,
    );

    const skipNextApiCall = useSelector(
        (storeState) => storeState?.employeeProfile?.skipNextApiCall
    );
    const additionalQuestions = useSelector(
        (storeState) =>
            storeState?.entities?.response?.onLoadResponseList?.[`InsuredAdditionalDataFor${currentStep}`]?._Result,
    );


    const productLevelEligblity = useSelector(
        (storeState) =>
            storeState?.entities?.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result,
    );

    let subscriptionManager = currentReduxState?.entities?.response?.onLoadResponseList?.[currentStep]?.ValidateSubscribedProductData?._Result?.subscriptionManager || [];

    let productFamily = formsConfig?.forms?.[currentStep]?.productFamily;
    let productBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];

   // let insureds = currentReduxState?.entities?.response?.onLoadResponseList?.[currentStep]?.ValidateSubscribedProductData?._Result?.contract?.insureds || [];

    const enrollmentProcessBuid = currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
    const getAllPossibleChoicesConfigurationForProduct = currentReduxState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]
    let validateContractDataSubscriptionManager = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements;

    useEffect(() => {
      if (productList?.length > 0) {
        // Find the subscribedProduct object
        const subscribedProduct = productList?.find((item) => item.memberOfferedProduct && item.memberOfferedProduct.bUID === productBuid)?.subscribedProduct;

        // Extract the required values from additionalDatas
        const additionalDatas = subscribedProduct?.additionalDatas || [];
        if (additionalDatas?.length) {
          const enableRepQuesValue = additionalDatas.find(data => data.fieldName === "ENABLEREPQUES")?.valueCode || null;
          // Prepare the payload
          const payload = {
            product: currentStep,
            policyReplacementQuestionEnable: enableRepQuesValue
          };

          // Dispatch the action to update insuredInfo
          dispatch(UPDATE_PRODUCT_LEVEL_ELIGIBILITY(payload));
        }
      }
    }, [productList]);

    let flowType = null;
    let hasMultipleCoverages = false;

    const determineFlow = (productBuid, productList) => {
      if(productFamily === "WL Coverage"){
          return 7;
      }
      if (!productBuid || !productList || !Array.isArray(productList)) {
          return null;
      }

      let selectedProduct = null;

      // Loop through productList and find the product with the matching bUID
      for (const product of productList) {
        if (product?.memberOfferedProduct?.bUID === productBuid) {
          selectedProduct = product;
          break;
        }
      }

      // If no matching product is found, return null
      if (!selectedProduct || !selectedProduct.coverages) {
        return null;
      }

      // Check for flow conditions
      const coverages = selectedProduct.coverages;
      const coverageCount = coverages.length;
      hasMultipleCoverages = coverageCount > 1;
      let cmMonoCoveredCount = 0;
      let cmMultiCoveredCount = 0;
      for (const coverage of coverages) {
        const coverageMode = coverage?.memberOfferedCoverage?.option?.insuredConfiguration?.coverageMode;
        if (coverageMode === 'cmMultiCovered') {
          cmMultiCoveredCount++;
        } else if (coverageMode === 'cmMonoCovered') {
          cmMonoCoveredCount++;
        }
      }

      // Flow 4 condition
      if(productFamily==="Liability"){
        return 4;
      }

      if (productFamily === "Pet Insurance") {
        return 6;
      }

      if (productFamily === "Pet Insurance - Pet Assure" || productFamily === "Pet Insurance - PETPlus" ) {
        return 5;
      }

      // Flow 1 condition
      if (coverageCount === 1 && cmMultiCoveredCount === 1) {
        return 1;
      }

      // Flow 2 condition
      if (coverageCount > 1 && cmMonoCoveredCount === coverageCount) {
        return 2;
      }

      // Flow 3 condition
      if (coverageCount > 1 && (cmMonoCoveredCount + cmMultiCoveredCount) === coverageCount) {
        return 3;
      }

      // If none of the flow conditions are met, return 1
      return 1;
    };

    flowType = determineFlow(productBuid, productList);

   function getCoverageBeneficiaryEligibility(response) {
    if (response === null || response === undefined || response === []) {
      return [];
    }

    const coverageBeneficiary = {};
    for (const item of response) {
      const coverageBuid = item?.coverageBuid || '';
      const items = omit(item, 'coverageBuid');
      items && Object.keys(items).forEach(record => {
        const data = items[record];
        const isByWillOrEstatePresent = data?.allowedBeneficiaryKind && data?.allowedBeneficiaryKind.includes("cByWillOrEstate");
        const isDefineBeneficiariesPresent = data?.allowedBeneficiaryKind && data?.allowedBeneficiaryKind.includes("cDefineBeneficiaries");
        const isBeneficiaryEligible = isDefineBeneficiariesPresent;
        const isBeneficiaryOptional = isBeneficiaryEligible && isByWillOrEstatePresent && isDefineBeneficiariesPresent;
        const isBeneficiaryMandatory = isBeneficiaryEligible && !isByWillOrEstatePresent && isDefineBeneficiariesPresent;
        const forCoveredPersonId = data?.forCoveredPerson?.bUID || '';
        if ( forCoveredPersonId ) {
          coverageBeneficiary[forCoveredPersonId] = {
            coverageBuid,
            isBeneficiaryEligible,
            isBeneficiaryOptional,
            isBeneficiaryMandatory
          };
        }
      });
    }
    return coverageBeneficiary;
  }

    const sortDependents = (dependents = [], questions) => {
        const sorted = { employee: null, spouse: null, children: [] };
        if (dependents.length > 0) {
            dependents.forEach((dependent) => {
                const buid = dependent?.person?.identifier?.bUID;
                const question = questions?.find((item) => item?.insuredPerson?.bUID === buid);
                if (question) {
                    const relation = dependent?.insured?.mainOwnerRelationshipToInsured;
                    if (!relation) {
                        sorted.employee = dependent;
                    } else if (Constants.SPOUSE_OR_PARTNER_RELATIONSHIPS.includes(relation)) {
                        sorted.spouse = dependent;
                    } else if (relation === 'CH') {
                        sorted.children.push(dependent);
                    }
                }
            });
        }
        return sorted;
    };
    // need to refactor  for 90 -sk

    const getCoverageDetailsForDependents = async () => {


        let CIBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];

        if (CIBuid) {

            //  let validateContractSM =   k._Result?.subscriptionManager || [];

            let validateContractSM = currentReduxState?.entities?.response?.onLoadResponseList?.[currentStep]?.ValidateSubscribedProductData?._Result?.subscriptionManager || [];

            const filteredSubscriptionManager = validateContractSM.filter(sub => sub?.product?.bUID === CIBuid);

            let subscriptionChoices = filteredSubscriptionManager[0]?.choosenChoices || [];
            let bUIDs = [];
            for (const choice of subscriptionChoices) {
                // const insured = choice.insureds[0];
                const insured = choice?.insureds?.[0] || {};
                const subscribedOption = choice.subscribedOptions[0];
                const bUID = { "personBuid": insured.bUID, "coverageBuid": subscribedOption.bUID }
                bUIDs.push(bUID);
            }
            if (bUIDs.length == 0) {
                let coverageBuid = subscriptionChoices?.[0]?.subscribedOptions?.[0]?.bUID;
                const info = { "productBuid": CIBuid, "coverageBuid": coverageBuid };
                bUIDs.push(info);
            }
            dispatch(SET_COVERAGE_BUIDS({ bUIDs, product: currentStep }));
            if (currentStep === 'CriticalIllness') {
                const resp = await http
                    .post(appConfig.SSP_MID_URL + '/getListOfCoveragePlanDesignConfigurationForCI', {
                        enrollmentProcessBuid: currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID,
                        validateContractCoverageBuid: bUIDs
                    })
                    .then((resp) => {
                        const CoverageDeatails =
                            (resp &&
                                resp.data)
                        if (CoverageDeatails) {
                            dispatch(onLoadAPI({
                                [`CoverageDeatailsFor${currentStep}`]: CoverageDeatails,
                                isAPICallInProgress: false,
                            })

                            );
                        }
                    });

            }
        }
    }

    const updateInsuredsAsBeneficiaries = () => {
      const extractedData = insureds.map((insured) => {
        const person = insured?.person?.data?.actor ?? {};
        return {
          beneficiaryName: (person.shortName ?? '') + " " + (person.name ?? ''),
          id: insured?.person?.identifier?.bUID ?? '',
          mainBuid: insured?.person?.identifier?.bUID ?? '',
          beneficiaryFirstName: person.shortName ?? '',
          beneficiaryMiddleName: person.middleName1 ?? '',
          beneficiaryLastName: person.name ?? '',
          beneficiaryGender: person.gender ?? '',
          beneficiaryDateOfBirth: person.birthDate ?? '',
          beneficiaryLiveInSameAddress: false,
          beneficiaryRelationship: insured?.insured.mainOwnerRelationshipToInsured ?? '',
          beneficiaryType: 'Primary',
          beneficiaryPercentage: '0',
          beneficiarySelected: false,
          showActions: false,
          insured: insured,
        };
      });
      dispatch(UPDATE_INSUREDS_AS_BENEFICIARIES(extractedData));
    }

    const updatedInsureds = { employee: [], spouse: [], children: [] };
    if (insureds) {
      insureds.forEach(insured => {
        if (insured?.insured && (insured?.person?.identifier || insured?.person?.data?.bUID) ) {
          const relationship = insured.insured.mainOwnerRelationshipToInsured;
          const personBuid = insured?.person?.identifier?.bUID || insured?.person?.data?.bUID;
          const dependent = {
            personBuid: personBuid,
            insured: insured
          };
          //if(eligiblePersonsBuidArr.indexOf(personBuid) !== -1){
          if (relationship === "") {
            updatedInsureds.employee.push(dependent);
          } else if (relationship === "CH") {
            updatedInsureds.children.push(dependent);
          } else if (Constants.SPOUSE_OR_PARTNER_RELATIONSHIPS.includes(relationship)) {
            updatedInsureds.spouse.push(dependent);
          }
          //}
        }
      });
    }

    useEffect(() => {
        // checkCoveragePersonEligibility();
        let skipForNext = `${currentStep}Product`
        if (!skipNextApiCall?.includes(skipForNext)) {
            //getSubscribedCoverageBeneficiaryConfiguration();
        }
    }, []);

    useEffect(() => {
        let skipForNext = `${currentStep}Product`
        if (!hasRun.current && subscriptionManager.length) {
            if (!skipNextApiCall?.includes(skipForNext)) {
                if (currentStep === "CriticalIllness" && (flowType == 2 || flowType == 3)) {
                    //getCoverageDetailsForDependents();
                }
                //getSubscribedCoverageConfigurationForDependents();
                //getSubscribedCoverageBeneficiaryConfiguration();
            }
            //getSubscribedCoverageConfigurationForDependents();

            updateInsuredsAsBeneficiaries();

            // Mark the effect as run
            hasRun.current = true;
        }

    }, [subscriptionManager, insureds]);


    //Update dependents when insureds change
    useEffect(() => {
      if ( insureds?.length > 0 ) {
        updateInsuredsAsBeneficiaries();
      }
    }, [insureds])

    useEffect(() => {
      let itemCode = 'DISCSTAT';
      if(currentStep === 'Travel') {
        itemCode = 'TRAVELDISC'
      }
      const disclosureStatement = productLevelEligblity?.some(item => item?.code === itemCode) ?? false;
      setDisclosureStatement(disclosureStatement);

      const payload = {
        productBuid: productBuid,
        product: currentStep,
        disclosureStatement: disclosureStatement
      }
      
      // Dispatch the action to update insuredInfo
      dispatch(UPDATE_PRODUCT_LEVEL_ELIGIBILITY(payload));
    }, [productLevelEligblity]);

    const insuredsQuestions = sortDependents(dependentsList, additionalQuestions);

    useEffect(() => {
      const isProductDataAvailable = productBuid && productList && productList.length && Array.isArray(productList);
      let selectedProduct = null;
      // Loop through productList and find the product with the matching bUID
      if(productList && productList.length) {
        for (const product of productList) {
          if (product?.memberOfferedProduct?.bUID === productBuid) {
            selectedProduct = product;
            break;
          }
        }
      }
      const isCoverageAvailable = selectedProduct && selectedProduct.coverages

      if (isProductDataAvailable && isCoverageAvailable && !flowType) {
        flowType = determineFlow(productBuid, productList);
      }
    }, [productBuid, productList, currentStep])

    return (
      <ParentComponent
        flowType={flowType}
        employee={updatedInsureds.employee}
        spouse={updatedInsureds.spouse}
        children={updatedInsureds.children}
        showDisclosureStatement={disclosureStatement}
        insuredsQuestions={insuredsQuestions}
        updatedInsureds={updatedInsureds}
        hasMultipleCoverages={hasMultipleCoverages}
        {...props}
        currentStep={currentStep}
      />
    );
};

export default ProductManagement;
