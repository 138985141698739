import React, { useState } from 'react';

import './style.css';

const Tabs = ({ tabs, active, onChange }) => {
    const [activeTab, setActiveTab] = useState(active || tabs[0].id);

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
        onChange && onChange(tabId);
    };

    const tabObj = tabs.find((tab) => tab.id === activeTab) || tabs[0];
    const activeTabContent = tabObj.content();

    return (
        <div className="tabs-container">
            <ul role="tablist">
                {tabs.map((tab) => (
                    <li
                        key={tab.id}
                        role="tab"
                        onClick={() => handleTabChange(tab.id)}
                        className={tab.id === activeTab ? 'active' : ''}
                    >
                        {tab.label}
                    </li>
                ))}
            </ul>
            <div className="tab-content-wrapper">
                {activeTabContent}
            </div>
        </div>
    );
};

export default Tabs;
