import React, { useState, useEffect } from 'react';

import Icon from '../Icon';
import PropTypes from 'prop-types';
import Tile from '../Tile';
import FormatHelper from '../../Utils/Helper/FormatHelper';
import StrengthMeter from '../../Utils/Helper/StrengthMeter';
import './styles.css';
import { SET_MFA_MASKED_LOGIN } from '../../../Store/Slices/Registeration';
import { useDispatch, useSelector } from 'react-redux';

const maskEmail = (email) =>{
  let maskedEmail;
  if (!email)
  maskedEmail=null
  else{
    let split = email.split('@')
    let last = split[0].substr(split[0].length-1,split[0].length);
    maskedEmail = email?.substr(0,1) +'XXXX'+ last +"@" + split[1];
  }
  return maskedEmail
}
const maskPhone = (phone) =>{
  let maskedPhone;
  if (!phone)
    maskedPhone=null
  else{
    maskedPhone = phone.replace(/.(?=.{4})/g, 'x');
  }
  return maskedPhone
}
/*This atom is created to display the information which contains a static text(label), option values to display, option values(values) selected from a different field */
const TextReadOnlyLabel = (props) => {
  const {
    id,
    name,
    label,
    value,
    defaultValue,
    onChange,
    onBlur,
    className,
    classNameWrapper,
    hidden,
    hideElement,

    requiredValidation,
    errorFound,
    placeholder,
    element,
    type,
    disabled,
    formik,
    tile,
    tileInfo,
    content,
    optionValues,
    format,
    valueStyle,
    showEmailForResetPassword,
    showEmailForLogin,
    showPhoneForResetLogin,
    changeLabelDynamically=false,
    phoneEmailOptions=false,
    staticText=false,
    extraValuesFromRedux=false

  } = props;

  const dispatch = useDispatch();
  const {login='', phone='', loginOtpMethod='', preferredChannel='',phoneNumberFromOkta=''} = useSelector((storeState) => storeState.registeration);
  /** phoneSelected: change OTP - MFA login */
  // let phoneSelected = loginOtpMethod !== 'email'
  let phoneSelected = preferredChannel !== 'email' || loginOtpMethod !== 'email' 

  const needMaskedEmail = preferredChannel === 'email' ?
    (showEmailForResetPassword || showEmailForLogin) && !phoneSelected :
    (showEmailForResetPassword || showEmailForLogin) && phoneSelected ;
  const needMaskedPhone = showPhoneForResetLogin && preferredChannel!=='email';
  const maskedEmail = needMaskedEmail && login ? maskEmail(login) :'';
  const maskedPhone = needMaskedPhone ? maskPhone('+18321112233') : '';

  let required =
    element?.validations?.string?.required ||
    element?.validations?.string?.validationRequiredOnTrue;

  const { layout } = content || {};
  const [focus, setFocused] = useState(false);

  /** Set initial focus based on defaultValue */
  useEffect(() => {
    if (defaultValue) {
      onChange &&
        onChange({
          target: {
            name: name || id,
            value: defaultValue,
          },
        });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (element.currentDate && !value) {
      onChange &&
        onChange({
          target: {
            name: name || id,
            value: new Date().toISOString().slice(0, 10),
          },
        });
    }
  }, [element.currentDate]);

  useEffect(() => {
    if (value) {
      setFocused(value != null);
    }
  }, [value]);

  useEffect(() => {
    if (maskedEmail) {
      dispatch(SET_MFA_MASKED_LOGIN(maskedEmail));
    }
  }, [maskedEmail]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleChange = (event) => {
    event.preventDefault();
    onChange && onChange(event);
    props.parentInputAction && props.parentInputAction(event, formik.values);
  };
  const handleBlur = (event) => {
    event.preventDefault();
    props.parentInputBlurAction &&
      element.apiCallOnBlur &&
      props.parentInputBlurAction(event, formik.values, formik);
    if (!props.value) setFocused(false);
    onBlur && onBlur(event);
  };

  let classes = disabled ? `elementDisabled ${className}` : className;
  let additionalClasses = hideElement ? 'hideElement' : classNameWrapper;
  return (
    <div className={'labelClass' + ' ' + additionalClasses} >
      <div>
        {/* {label} {!needMaskedEmail ? value.indexOf('@') > 0 ? optionValues[0] : optionValues[1] : optionValues[0]}  */}
        {label} {
          !staticText ?
            !phoneEmailOptions ? 
              optionValues[0] : 
                needMaskedEmail && !phoneSelected? 
              optionValues[0] : 
              // optionValues[1] :
              !extraValuesFromRedux ?optionValues[1] :optionValues[1] +' '+phoneNumberFromOkta :

           
          optionValues[0]}
        <span  style={valueStyle}>{!phoneSelected? !needMaskedEmail ? value : ' ' + maskedEmail :''}</span>
      </div>
    </div>
  );
};
TextReadOnlyLabel.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  hideElement: PropTypes.string,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  tile: PropTypes.bool,
};
TextReadOnlyLabel.defaultProps = {
  value: '',
  hidden: false,
  className: '',
  classNameWrapper: '',
  type: 'text',
  required: false,
  defaultValue: null,
};

export default TextReadOnlyLabel;
