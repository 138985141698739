import { createSlice } from '@reduxjs/toolkit';
import { apiCallInitiation } from '../../../Middleware/Api/Actions';

const slice = createSlice({
  name: 'loggedUserEntity',
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    apiRequested: (loggedUserEntity, action) => {
      loggedUserEntity.loading = true;
    },

    apiReceived: (loggedUserEntity, action) => {
      loggedUserEntity.data = { ...loggedUserEntity.data, ...action.payload };
      loggedUserEntity.loading = false;
      loggedUserEntity.lastFetch = Date.now();
    },

    apiRequestFailed: (loggedUserEntity, action) => {
      loggedUserEntity.loading = false;
    },
    logoutUserEntity: (loggedUserEntity) => {
      loggedUserEntity.data = {  };
      loggedUserEntity.loading = false;
      loggedUserEntity.lastFetch = null;
    },

  },
});
export const { apiReceived, apiRequested, apiRequestFailed, logoutUserEntity} = slice.actions;
export const callLoggedUserEntity = (authUrl, userLegalEntitiyData) => (dispatch, getState) => {
  return dispatch(
    apiCallInitiation({
      url: authUrl,
      method: 'POST',
      data: userLegalEntitiyData,
      onStart: apiRequested.type,
      onSuccess: apiReceived.type,
      onError: apiRequestFailed.type,
    })
  );
};
export default slice.reducer;
