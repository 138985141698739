import React, { useState } from 'react';
import imageMap from '../../Helper/imageHelper';
import {getFileName} from '../../Helper/commonHelper';

import './styles.css';

const Collapse = (props) => {
    const {
        id,
        title,
        children,
        upArrow,
        className,
        downArrow,
        defaultOpen,
    } = props;

    const [isActive, setIsActive] = useState(defaultOpen);

    const getIcon = (name) => {
        try {
            return imageMap[getFileName(name)];
        } catch (e) {
            return '';
        }
    }
    const upArrowSrc = getIcon(upArrow);
    const downArrowSrc = getIcon(downArrow);

    const openClasses = isActive ? 'dropdown-item-open' : '';
    return (
        <div className={`${className} ${openClasses} collapse-border`}>
            <div id={id} 
                className="accordion-title"
                role="button"
                tabIndex={0}
                onClick={() => setIsActive(!isActive)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Space') {
                        e.preventDefault();
                        setIsActive(!isActive)
                    }
                }}
            >
                <div className="accordion-image-container w-100">
                    <div>
                        <img src={isActive ? upArrowSrc : downArrowSrc} alt="" />
                    </div>
                    <div className="d-flex justify-content-between w-100">
                        <div className="accordion-text-no-image-panel">{title}</div>
                    </div>
                </div>
            </div>

            {isActive && (
                <div className="accordion-content-padding">{children}</div>
            )}
        </div>
    );
};

export default Collapse;
