import React from 'react';

const removeTitles = ['InvolvedActor', 'ProductNameAndContractId'];
const removeCompositions = ['Chosen Package', 'Additional Information', 'Requirement(s)'];
const removeProperties = ['Certificate #:', 'Group Contract  #:', 'Group Contract  Effective Date:', 'Group Contract Owner:', 'Issuing company:'];

const titlesToRemove = ['Requirement(s)', 'Premium Summary:'];

const HistoryCompositeDocument = ({ composition }) => {
    if (removeCompositions.includes(composition?.name) || titlesToRemove.some(item => composition?.title?.includes(item))) {
        return null;
    }
    switch (composition?.className) {
        case 'aWFCompositeDocument': {
            return (
                <div className="history__composite-document">
                    {composition.title && <div className="history__composite-document__title">{composition.title}</div>}
                    {composition?.composedOf?.map((item, index) => (
                        <HistoryCompositeDocument key={`item-${item.name}-${index}`} composition={item} />
                    ))}
                </div>
            );
        }

        case 'aWFTablePropertyDocument': {
            return (
                <div className="history__table-property-document">
                    {composition.title && !removeTitles.includes(composition.name) && <div className="history__table-property-document__title">{composition.title}</div>}
                    {composition?.properties?.filter(item => !removeProperties.includes(item.name.trim())).map((item, index) => (
                        <div key={`item-${item.name}-${index}`} className="history__table-property-document__property">
                            <div className="history__table-property-document__property-label">{item.name}</div>
                            <div className="history__table-property-document__property-value">{item.value}</div>
                        </div>
                    ))}
                </div>
            );
        }

        case 'aWFTableDocument': {
            const cols = composition?.composedOf?.slice(0, composition?.columnNumber);

            const data = composition?.composedOf?.slice(composition?.columnNumber) || [];
            const arrays = [];
            const chunkSize = composition?.columnNumber;

            for (let i = 0; i < data.length; i += chunkSize) {
                const chunk = data.slice(i, i + chunkSize);
                arrays.push(chunk);
            }
            return (
                <div className="history__table-document">
                    {composition.title && <div className="history__table-document__title">{composition.title}</div>}
                    <table className="history__table-document__table">
                        <thead>
                            <tr>
                                {cols?.map((col, index) => (
                                    <th key={`col-${col.title}-${index}`}>{col.title}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {arrays?.map((row, rowIndex) => (
                                <tr key={`row-${rowIndex}`}>
                                    {row?.map((cell, index) => (
                                        <td key={`row-${rowIndex}-${cell.title}-${index}`}>{cell.title}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )
        }

        default: {
            return <pre>{JSON.stringify(composition, null, 2)}</pre>
        }
    }
};

export default HistoryCompositeDocument;
