import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import formFields from '../Content/ProfileConfig.json';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response/index';
import { reduxConstants } from '../../../../Common/Constants/index';
import Loader from '../../../Atoms/Loader';
import { useFormik } from 'formik';
import { retrieveConfig } from '../Actions/retrieveConfig';
import { saveProfile } from '../Actions/saveConfig';
import SuccessPage from '../Common/SuccessPage';
import { CLEAR_ALERT_MESSAGES, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { MessageComponent } from '../../../Atoms';
import { getErrorMessages } from '../../../Molecules/CustomSection/Helper/MappingHelper';
import { Checkbox, TextInput, TextArea, Button, Label } from '../../../Molecules/CustomSection/Atoms';
import '../styles.css';

const EmployeeProfileConfig = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const dispatch = useDispatch();

  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};

  const configDataRedux = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.empConfigInfo
  );

  let configData;
  try {
    configData = {
      middleNameToggle: configDataRedux?.personalInfo[0].middleName?.hideableField || false, // hideableField
      ssnToggle: configDataRedux?.personalInfo[0]?.ssn?.hideableField || false,
      ssnEyeToggle: configDataRedux?.personalInfo[0]?.ssn?.hideableEyeIcon || false,
      primaryEmailToggle: configDataRedux?.contactInfo[0]?.primaryEmail?.hideableField || false,
      primaryPhoneToggle: configDataRedux?.contactInfo[0]?.primaryPhone?.hideableField || false,
      personalEmailAddressToggle: configDataRedux?.contactInfo[0]?.personalEmail?.hideableField || false,
      personalEmailAddressChangedByEmp: configDataRedux?.contactInfo[0]?.personalEmail?.nonEditableField || false,
      personalEmailAddressRequired: configDataRedux?.contactInfo[0]?.personalEmail?.requiredField || false,
      personalPhoneToggle: configDataRedux?.contactInfo[0]?.personalPhone?.hideableField || false,
      personalPhoneChangedByEmp: configDataRedux?.contactInfo[0]?.personalPhone?.nonEditableField || false,
      personalPhoneRequired: configDataRedux?.contactInfo[0]?.personalPhone?.requiredField || false,
      isEmployementVisible: configDataRedux?.employmentInfo[0]?.noGroupStart?.requiredSection || false,
      salaryToggle: configDataRedux?.employmentInfo[0]?.salary?.hideableField || false,
      salaryEditable: configDataRedux?.employmentInfo[0]?.salary?.editableLabelField || false,
      salaryEditableValue: configDataRedux?.employmentInfo[0]?.salary?.editableLabelFieldValue || '',
      payFrequencyToggle: configDataRedux?.employmentInfo[0]?.payFrequency?.hideableField || false,
      payFrequencyEditable: configDataRedux?.employmentInfo[0]?.payFrequency?.editableLabelField || false,
      payFrequencyEditableValue: configDataRedux?.employmentInfo[0]?.payFrequency?.editableLabelFieldValue || '',
      divisionCodeToggle: configDataRedux?.employmentInfo[0]?.divisioncode?.hideableField || false,
      divisionCodeEditable: configDataRedux?.employmentInfo[0]?.divisioncode?.editableLabelField || false,
      divisionCodeEditableValue: configDataRedux?.employmentInfo[0]?.divisioncode?.editableLabelFieldValue || '',
      workLocationToggle: configDataRedux?.employmentInfo[0]?.workLocation?.hideableField || false,
      workLocationEditable: configDataRedux?.employmentInfo[0]?.workLocation?.editableLabelField || false,
      workLocationEditableValue: configDataRedux?.employmentInfo[0]?.workLocation?.editableLabelFieldValue || '',
      employmentStatusToggle: configDataRedux?.employmentInfo[0]?.employmentStatus?.hideableField || false,
      employmentStatusEditable: configDataRedux?.employmentInfo[0]?.employmentStatus?.editableLabelField || false,
      employmentStatusEditableValue: configDataRedux?.employmentInfo[0]?.employmentStatus?.editableLabelFieldValue || '',
      PCsectionToogle: configDataRedux?.communicationInfo[0]?.preferredContact?.optionsField?.isEnabledPC?.hideOption || false,
      hrText: configDataRedux?.employmentInfo[0]?.hrText.text || ''
    };
  } catch (e) {
    configData = {
      middleNameToggle: false, // hideableField
      ssnToggle: false,
      ssnEyeToggle: false,
      primaryEmailToggle: false,
      primaryPhoneToggle: false,
      personalEmailAddressToggle: false,
      personalEmailAddressChangedByEmp: false,
      personalEmailAddressRequired: false,
      personalPhoneToggle: false,
      personalPhoneChangedByEmp: false,
      personalPhoneRequired: false,
      isEmployementVisible: false,
      salaryToggle: false,
      salaryEditable: false,
      salaryEditableValue: '',
      payFrequencyToggle: false,
      payFrequencyEditable: false,
      payFrequencyEditableValue: '',
      divisionCodeToggle: false,
      divisionCodeEditable: false,
      divisionCodeEditableValue: '',
      workLocationToggle: false,
      workLocationEditable: false,
      workLocationEditableValue: '',
      employmentStatusToggle: false,
      employmentStatusEditable: false,
      employmentStatusEditableValue: '',
      PCsectionToogle: false,
      hrText: ''
    };
  }


  const formik = useFormik({
    initialValues: {
      middleNameToggle: configData?.middleNameToggle,
      ssnToggle: configData?.ssnToggle,
      ssnEyeToggle: configData?.ssnEyeToggle,
      primaryEmailToggle: configData?.primaryEmailToggle,
      primaryPhoneToggle: configData?.primaryPhoneToggle,
      personalEmailAddressToggle: configData?.personalEmailAddressToggle,
      personalEmailAddressChangedByEmp: configData?.personalEmailAddressChangedByEmp,
      personalEmailAddressRequired: configData?.personalEmailAddressRequired,
      personalPhoneToggle: configData?.personalPhoneToggle,
      personalPhoneChangedByEmp: configData?.personalPhoneChangedByEmp,
      personalPhoneRequired: configData?.personalPhoneRequired,
      isEmployementVisible: configData?.isEmployementVisible,
      salaryToggle: configData?.salaryToggle,
      salaryEditable: configData?.salaryEditable,
      salaryEditableValue: configData?.salaryEditableValue,
      payFrequencyToggle: configData?.payFrequencyToggle,
      payFrequencyEditable: configData?.payFrequencyEditable,
      payFrequencyEditableValue: configData?.payFrequencyEditableValue,
      divisionCodeToggle: configData?.divisionCodeToggle,
      divisionCodeEditable: configData?.divisionCodeEditable,
      divisionCodeEditableValue: configData?.divisionCodeEditableValue,
      workLocationToggle: configData?.workLocationToggle,
      workLocationEditable: configData?.workLocationEditable,
      workLocationEditableValue: configData?.workLocationEditableValue,
      employmentStatusToggle: configData?.employmentStatusToggle,
      employmentStatusEditable: configData?.employmentStatusEditable,
      employmentStatusEditableValue: configData?.employmentStatusEditableValue,
      PCsectionToogle: configData?.PCsectionToogle,
      hrText: configData?.hrText,
    },

    onSubmit: (values, { setSubmitting }) => {
      formik.resetForm();
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const loginuserName = useSelector((storeState) => storeState?.auth?.userAuth?.data?.user_name);

  let { clientId } = useSelector((storeState) => storeState.auth.userAuth);

  useEffect(() => {
    dispatch(CLEAR_ALERT_MESSAGES([]));
  }, [formik.values]);

  useEffect(() => {
    // //Api Call here
    setShowLoader(true)
    const callAPI = async () => {
      let response = await retrieveConfig(clientId, formFields.apiCall.onload);
      if (!response?.data) {
        setShowSuccess(null);
        setTimeout(() => {
          dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'getClientByIdFromDb',
            show: true,
            page: 'Configurator',
          }));
          setShowSuccess(false);
        }, 50);
      }
      dispatch(
        onLoadAPI({
          empConfigInfo: response?.data ? { ...response?.data[0] } : {},
          isAPICallInProgress: false,
        })
      );
      setShowLoader(false)
    };
    try {
      callAPI();
    } catch (error) {

    }
  }, [loggedUserEntity]);

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  const hasErrors = getErrorMessages('Configurator');

  const breadcrumbs = [
    { to: '', text: 'Employee' },
    { to: '/searchForAnEmployee', text: 'Search for an Employee' },
  ];
  /*removed breadcrumbs and current props */

  const saveProfileConfig = async () => {
    let data = {
      PersonalInformation: {
        middleName: formik?.values?.middleName,
        middleNameToggle: formik?.values?.middleNameToggle,
        ssn: formik?.values?.ssn,
        ssnToggle: formik?.values?.ssnToggle,
        ssnEyeToggle: formik?.values?.ssnEyeToggle,
        primaryEmail: formik?.values?.primaryEmail,

        primaryEmailToggle: formik?.values?.primaryEmailToggle,
        primaryPhoneToggle: formik?.values?.primaryPhoneToggle,
        personalEmail: formik?.values?.personalEmail,
        personalEmailAddressToggle: formik?.values?.personalEmailAddressToggle,
        personalEmailAddressChangedByEmp: formik?.values?.personalEmailAddressChangedByEmp,
        personalEmailAddressRequired: formik?.values?.personalEmailAddressRequired,
        personalPhone: formik?.values?.personalPhone,
        personalPhoneToggle: formik?.values?.personalPhoneToggle,

        personalPhoneChangedByEmp: formik?.values?.personalPhoneChangedByEmp,
        personalPhoneRequired: formik?.values?.personalPhoneRequired,
        isEmployementVisible: formik?.values?.isEmployementVisible,

        salary: formik?.values?.salary,
        salaryToggle: formik?.values?.salaryToggle,
        salaryEditable: formik?.values?.salaryEditable,
        salaryEditableValue: formik?.values?.salaryEditableValue,
        payFrequency: formik?.values?.payFrequency,
        payFrequencyToggle: formik?.values?.payFrequencyToggle,
        payFrequencyEditable: formik?.values?.payFrequencyEditable,
        payFrequencyEditableValue: formik?.values?.payFrequencyEditableValue,
        divisioncode: formik?.values?.divisioncode,
        divisionCodeToggle: formik?.values?.divisionCodeToggle,
        divisionCodeEditable: formik?.values?.divisionCodeEditable,
        divisionCodeEditableValue: formik?.values?.divisionCodeEditableValue,
        workLocation: formik?.values?.workLocation,
        workLocationToggle: formik?.values?.workLocationToggle,

        workLocationEditable: formik?.values?.workLocationEditable,
        workLocationEditableValue: formik?.values?.workLocationEditableValue,
        employmentStatus: formik?.values?.employmentStatus,
        employmentStatusToggle: formik?.values?.employmentStatusToggle,
        employmentStatusEditable: formik?.values?.employmentStatusEditable,
        employmentStatusEditableValue: formik?.values?.employmentStatusEditableValue,
        PCsectionToogleTitle: formik?.values?.PCsectionToogleTitle,
        PCsectionToogle: formik?.values?.PCsectionToogle,
        validationChecking: formik?.values?.employmentStatus ? formik?.values?.employmentStatus : false,
        hrText: formik?.values?.hrText
      },
    };
    //Api Call here
    setShowSuccess(null);
    let saveResponse = await saveProfile(data, clientId, formFields.apiCall.submit, setShowLoader);
    if (saveResponse?.status == 200) {
      setShowSuccess(true);
    } else {
      dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: '520',
        continue: true,
        id: 'createClientIntoDb',
        show: true,
        page: 'Configurator',
      }));
      setShowSuccess(false);
    }
  };

  return (
    <div>
      <MessageComponent currentPage={'Configurator'} setShowLoader={setShowLoader} />
      {/* <SectionHeader title="Employee Profile" style={content.style} /> */}
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper">
          {/* <FormGenerator content={content} {...props} layoutData={layoutData} /> */}
          {!showSuccess && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="config">
                <div className="col-12 px-5">
                  <div class="row py-3 px-3">
                    <h4>Info Text</h4>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label text={formFields.hrText.label} inputId={formFields.hrText.id} />
                    </div>
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextArea
                        id={formFields.hrText.id}
                        name={formFields.hrText.name}
                        placeholder={formFields.hrText.placeholder}
                        type={formFields.hrText.type}
                        {...formik.getFieldProps(formFields.hrText.name)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 px-5">
                  <div class="row py-3 px-3">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dataFieldPersonal.label}
                        inputId={formFields.dataFieldPersonal.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxTogglePersonal.label}
                        inputId={formFields.checkboxTogglePersonal.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.ssnEyeIconPersonal.label}
                        inputId={formFields.ssnEyeIconPersonal.id}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.middleName.label}
                        inputId={formFields.middleName.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.middleNameToggle.id}
                        name={formFields.middleNameToggle.name}
                        checked={formik.values.middleNameToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.middleNameToggle.name)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.ssnTxt.label}
                        inputId={formFields.ssnTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.ssnToggle.id}
                        name={formFields.ssnToggle.name}
                        checked={formik.values.ssnToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.ssnToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.ssnEyeToggle.id}
                        name={formFields.ssnEyeToggle.name}
                        checked={formik.values.ssnEyeToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.ssnEyeToggle.name)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 px-5">
                  <div class="row py-3 px-3">
                    <h4>Contact Information</h4>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dataFieldContactInfo.label}
                        inputId={formFields.dataFieldContactInfo.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxToggleContactInfo.label}
                        inputId={formFields.checkboxToggleContactInfo.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editableFieldContactInfo.label}
                        inputId={formFields.editableFieldContactInfo.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.requiredFieldContactInfo.label}
                        inputId={formFields.requiredFieldContactInfo.id}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.primaryEmailTxt.label}
                        inputId={formFields.primaryEmailTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.primaryEmailToggle.id}
                        name={formFields.primaryEmailToggle.name}
                        checked={formik.values.primaryEmailToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.primaryEmailToggle.name)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.primaryPhoneTxt.label}
                        inputId={formFields.primaryPhoneTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.primaryPhoneToggle.id}
                        name={formFields.primaryPhoneToggle.name}
                        checked={formik.values.primaryPhoneToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.primaryPhoneToggle.name)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.alternateEmailTxt.label}
                        inputId={formFields.alternateEmailTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.personalEmailAddressToggle.id}
                        name={formFields.personalEmailAddressToggle.name}
                        checked={formik.values.personalEmailAddressToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.personalEmailAddressToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.personalEmailAddressChangedByEmp.id}
                        name={formFields.personalEmailAddressChangedByEmp.name}
                        checked={formik.values.personalEmailAddressChangedByEmp}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.personalEmailAddressChangedByEmp.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.personalEmailAddressRequired.id}
                        name={formFields.personalEmailAddressRequired.name}
                        checked={formik.values.personalEmailAddressRequired}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.personalEmailAddressRequired.name)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.alternatePhoneTxt.label}
                        inputId={formFields.alternatePhoneTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.personalPhoneToggle.id}
                        name={formFields.personalPhoneToggle.name}
                        checked={formik.values.personalPhoneToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.personalPhoneToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.personalPhoneChangedByEmp.id}
                        name={formFields.personalPhoneChangedByEmp.name}
                        checked={formik.values.personalPhoneChangedByEmp}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.personalPhoneChangedByEmp.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.personalPhoneRequired.id}
                        name={formFields.personalPhoneRequired.name}
                        checked={formik.values.personalPhoneRequired}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.personalPhoneRequired.name)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 px-5">
                  <div class="row py-3 px-3">
                    <h4>Employment Information</h4>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxSectionOffDisplay.label}
                        inputId={formFields.checkboxSectionOffDisplay.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.isEmployementVisible.id}
                        name={formFields.isEmployementVisible.name}
                        checked={formik.values.isEmployementVisible}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.isEmployementVisible.name)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dataFieldEmploymentInfo.label}
                        inputId={formFields.dataFieldEmploymentInfo.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxToggleEmploymentInfo.label}
                        inputId={formFields.checkboxToggleEmploymentInfo.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editableFieldEmploymentInfo.label}
                        inputId={formFields.editableFieldEmploymentInfo.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editableLabelValEmploymentInfo.label}
                        inputId={formFields.editableLabelValEmploymentInfo.id}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.salaryTxt.label}
                        inputId={formFields.salaryTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.salaryToggle.id}
                        name={formFields.salaryToggle.name}
                        checked={formik.values.salaryToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.salaryToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.salaryEditable.id}
                        name={formFields.salaryEditable.name}
                        checked={formik.values.salaryEditable}
                        className="regularCheckbox"
                        disabled={true}
                        {...formik.getFieldProps(formFields.salaryEditable.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right hideLabel">
                      <TextInput
                        id={formFields.salaryEditableValue.id}
                        name={formFields.salaryEditableValue.name}
                        placeholder={formFields.salaryEditableValue.placeholder}
                        disabled={true}
                        {...formik.getFieldProps(formFields.salaryEditableValue.name)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.payFrequencyTxt.label}
                        inputId={formFields.payFrequencyTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.payFrequencyToggle.id}
                        name={formFields.payFrequencyToggle.name}
                        checked={formik.values.payFrequencyToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.payFrequencyToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.payFrequencyEditable.id}
                        name={formFields.payFrequencyEditable.name}
                        checked={formik.values.payFrequencyEditable}
                        className="regularCheckbox"
                        disabled={true}
                        {...formik.getFieldProps(formFields.payFrequencyEditable.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right hideLabel">
                      <TextInput
                        id={formFields.payFrequencyEditableValue.id}
                        name={formFields.payFrequencyEditableValue.name}
                        placeholder={formFields.payFrequencyEditableValue.placeholder}
                        disabled={true}
                        {...formik.getFieldProps(formFields.payFrequencyEditableValue.name)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.divisionCodeTxt.label}
                        inputId={formFields.divisionCodeTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.divisionCodeToggle.id}
                        name={formFields.divisionCodeToggle.name}
                        checked={formik.values.divisionCodeToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.divisionCodeToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.divisionCodeEditable.id}
                        name={formFields.divisionCodeEditable.name}
                        checked={formik.values.divisionCodeEditable}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.divisionCodeEditable.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right hideLabel">
                      <TextInput
                        id={formFields.divisionCodeEditableValue.id}
                        name={formFields.divisionCodeEditableValue.name}
                        placeholder={formFields.divisionCodeEditableValue.placeholder}
                        {...formik.getFieldProps(formFields.divisionCodeEditableValue.name)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.workLocationTxt.label}
                        inputId={formFields.workLocationTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.workLocationToggle.id}
                        name={formFields.workLocationToggle.name}
                        checked={formik.values.workLocationToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.workLocationToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.workLocationEditable.id}
                        name={formFields.workLocationEditable.name}
                        checked={formik.values.workLocationEditable}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.workLocationEditable.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right hideLabel">
                      <TextInput
                        id={formFields.workLocationEditableValue.id}
                        name={formFields.workLocationEditableValue.name}
                        placeholder={formFields.workLocationEditableValue.placeholder}
                        {...formik.getFieldProps(formFields.workLocationEditableValue.name)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.employmentStatusTxt.label}
                        inputId={formFields.employmentStatusTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.employmentStatusToggle.id}
                        name={formFields.employmentStatusToggle.name}
                        checked={formik.values.employmentStatusToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.employmentStatusToggle.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.employmentStatusEditable.id}
                        name={formFields.employmentStatusEditable.name}
                        checked={formik.values.employmentStatusEditable}
                        disabled={true}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.employmentStatusEditable.name)}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right hideLabel">
                      <TextInput
                        id={formFields.employmentStatusEditableValue.id}
                        name={formFields.employmentStatusEditableValue.name}
                        placeholder={formFields.employmentStatusEditableValue.placeholder}
                        disabled={true}
                        {...formik.getFieldProps(formFields.employmentStatusEditableValue.name)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 px-5">
                  <div class="row py-3 px-3">
                    <h4>Preferred Contact</h4>
                  </div>
                  <div class="row">
                    <div className="col-12 col-md-6 col-lg-3 py-2">
                      <Label
                        text={formFields.checkboxTogglePreferredContactSection.label}
                        inputId={formFields.checkboxTogglePreferredContactSection.id}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 py-2">
                      <Checkbox
                        id={formFields.PCsectionToogle.id}
                        name={formFields.PCsectionToogle.name}
                        checked={formik.values.PCsectionToogle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.PCsectionToogle.name)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row w-100 form-btnGroup py-5">
                  <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 py-2 w-100">
                    {/* <button
                    className="btn btn-primary moreRounded primaryBackground"
                    type="button"                  
                    onClick={saveProfileConfig}
                    disabled={hasErrors}
                  >
                    Submit
                  </button> */}
                    <Button className="btn btn-primary  primaryBackground mr-4" onClick={saveProfileConfig} disabled={hasErrors}>Submit</Button>

                  </div>
                </div>
              </div>
            </form>
          )}
          {showSuccess && <SuccessPage {...formFields.confirmation}></SuccessPage>}
        </div>
      </Suspense>
    </div>
  );
};

export default EmployeeProfileConfig;
