import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import "../../styles.css";
import { useNavigationHook } from '../../Hooks/NavigationHook';
import { getConfigHelper } from '../../../../Molecules/CustomSection/Helper/MappingHelper';
import { formatDisplayValueForAmount } from "../../../../Utils/Helper/ValidationHelper";

const CustomTable = ({ customContent, isCIAflacProduct = false, showWaiveCoverage = true, relationship, ...props }) => {
  const { currentStep } = useNavigationHook();
  const [selected, setSelected] = useState(false);
  const [waiveSelected, setWaiveSelected] = useState(false);
  const { insuredInfo, process, entities } = useSelector((storeState) => storeState);
  let { personBuid = '', coverageDetails = [], displayMode, coverageIndex = '' } = props || {};

  useEffect(() => {
    if (props.selectedValue) {
      setSelected(props.selectedValue.toString());
    } else {
      setSelected(false)
    }
  }, [props.selectedValue]);

  useEffect(() => {
    // Trigger click on tr when component mounts
    if (props?.flowType === 4 && coverageIndex === 1) {
      handleSelect(1, props?.table?.[0]?.costPerPayPeriod)
     
    }
  }, [props?.flowType === 4 && coverageIndex === 1]);

  // Extract dependentCoverageTable from customContent
  const dependentCoverageTable = customContent.tables.find(
    (table) => table.tableId === "dependentCoverageTable"
  );
  //let data = insuredInfo[currentStep]?.coverageBuids?.filter(e => e.personBuid == personBuid);
  //let subscribedCoverageBuid = data?.[0]?.coverageBuid
  const subscribeCoverageBuids = entities?.response?.onLoadResponseList?.[currentStep]?.subscribeCoverageBuids || [];
  let getDetails = props.flowType === 4 ? [subscribeCoverageBuids[coverageIndex]] : subscribeCoverageBuids.filter((e) => e?.personBuid === personBuid)
  let subscribedCoverageBuid = getDetails?.[0]?.coverageBuid;
  // Extract tableHeader and tableRow from multiConfig
  const { tableHeader, tableRow } = dependentCoverageTable.multiConfig;

  useEffect(() => {
    props.waiveOffCoverageSelected(props.waivedCoverage);
  }, [])

  const handleWaiveCoverageChange = (index) => {
    props.waiveOffCoverageSelected(true);
    setSelected(null);
    setWaiveSelected(true);
    props.onCoverageChange(props.personBuid, null, true, subscribedCoverageBuid, '', relationship);
    props.showOrHidePolicyReplacementQues && props.showOrHidePolicyReplacementQues(props.personBuid, null, true);
  };

  const handleSelect = (index, costPerPayPeriod, e) => {
    props.waiveOffCoverageSelected(false);
    setSelected(index);
    setWaiveSelected(false);
    props.onCoverageChange(props.personBuid, index, false, subscribedCoverageBuid, costPerPayPeriod, relationship);
    props.showOrHidePolicyReplacementQues && props.showOrHidePolicyReplacementQues(props.personBuid, index, false);
  };

  useEffect(() => {
    if (props?.tableData?.length > 0 && selected && subscribedCoverageBuid) {
      let coverageData = props?.tableData?.filter(e => selected === e.code)
      if (props.onCoverageChange && coverageData?.length) {
        props.onCoverageChange(props.personBuid, coverageData?.[0]?.code, false, subscribedCoverageBuid, coverageData?.[0]?.costPerPayPeriod, relationship);
      }
    }
  }, [props?.tableData, selected, subscribedCoverageBuid])

  //Configuration for coverage table
  const configDataRedux = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.productConfiguration
      ?._Result
  );

  if (configDataRedux?.premiumFrequency?.isEditable) {
    getConfigHelper(tableHeader, configDataRedux)
  }

  let tobleHeaderValue = props.tableData.length > 0 && props?.tableData?.[0].headerContent ? props?.tableData?.[0]?.headerContent : []
  return (
    <>
      {!props.tableData.length && isCIAflacProduct ? <div className="mb-4">Based on your employee coverage level election, you are not able to elect this benefit at this time.</div> : null}
      <table className="table atomCoverageTable table-mobile-border coverageOptionTable">
        {props.tableData.length || !isCIAflacProduct ? <thead>
          <tr style={tableHeader[0].config.style}>
            <th className="header-no-wrap colHeader d-none d-sm-table-cell" aria-label="Header Label" aria-hidden="true" scope="col">
              <span className="sr-only">Select</span>
            </th>
            {tableHeader.map((header, index) => tobleHeaderValue?.[index]?.presentedName || header.id == 'costPerPayPeriod' ? (
              <th
                key={index}
                className={`header-no-wrap colHeader ${header.config.className}`}
                scope="col"
                style={header.config.style}
              >
                {tobleHeaderValue?.[index]?.presentedName ? tobleHeaderValue?.[index]?.presentedName : header.value}
              </th>
            ) : '')}
          </tr>
        </thead> : null}
        <tbody className="">
          {props.tableData.map((data, index) => (
            <tr key={index}>
              <td className="table-data-no-wrap coverageRadio " style={{ width: "1rem" }}>
                <span>
                  <input
                    aria-label="coverage Radio"
                    tabIndex={0}
                    id={index}
                    className="form-check-radio coverageRadio"
                    type="radio"
                    value=""
                    checked={selected === data.code}
                    onChange={(event) => handleSelect(data.code, data.costPerPayPeriod, event)}
                  />
                </span>
              </td>
              {tableRow.map((row, idx) => props.tableData?.[index]?.headerContent?.[idx] || row.id == 'costPerPayPeriod' ? (
                <td key={idx} className={`table-data-no-wrap ${row.className}`}>

                  {
                    typeof (props.tableData?.[index]?.headerContent?.[idx]?.fieldValue) === 'object'
                      ? props.tableData?.[index]?.headerContent?.[idx]?.fieldValue?.amount ? `${formatDisplayValueForAmount(props.tableData?.[index]?.headerContent?.[idx]?.fieldValue?.amount)}` : ''
                      : props.tableData?.[index]?.headerContent?.[idx]?.fieldValue?.amount? `${formatDisplayValueForAmount(props.tableData?.[index]?.headerContent?.[idx]?.fieldValue?.amount)}` : ''
                        ? props.tableData?.[index]?.headerContent?.[idx]?.fieldValue?.amount? `${formatDisplayValueForAmount(props.tableData?.[index]?.headerContent?.[idx]?.fieldValue?.amount)}` : ''
                        : eval(row.value)
                  }
                </td>
              ) : '')}
            </tr>
          ))}
          {showWaiveCoverage && <tr>
            <td className="atomCoverageTableRadio" colSpan={tableHeader.length + 1} align="center">
              <div className="atomCoverageTableRadio">
                <input
                  aria-label="coverage Radio"
                  tabIndex={0}
                  id="coverageWaive"
                  className=""
                  type="radio"
                  checked={props?.waivedCoverage === true ? true : false}
                  onChange={() => handleWaiveCoverageChange()}
                />
                <label className="ml-3" for="coverageWaive">Waive Coverage</label>
              </div>
            </td>
          </tr>}
        </tbody>
      </table>
    </>
  );
};

export default CustomTable;
