import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.bs-vertical-wizard {
  border-left: 1px solid #eaecf1;
  padding: 2rem 0;
  background-color:${({ theme }) =>
    theme.cells && theme.cells.stepperNavigation && theme.cells.stepperNavigation.bgColor}; 
}

.bs-vertical-wizard ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bs-vertical-wizard ul > li {
  display: block;
  position: relative;
  min-height: 4.25rem;
}
.bs-vertical-wizard ul > li.locked {
  cursor: not-allowed; 
}
.bs-vertical-wizard ul > li > a {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.375rem;
}
.bs-vertical-wizard ul > li > a.stepper-complete {
}
.stepper-locked {
  color: #B3B3B3 !important;
}
.stepper-complete, .stepper-visited, .stepper-current{
  cursor: pointer;
  transition: ease 0.3s color;
}
.stepper-current {
  color: #565656 !important;
}
.stepper-complete:hover, .stepper-visited:hover, .stepper-current:hover {
  text-decoration: none;
}

.stepper-complete:hover:not(.disabled), .stepper-visited:hover:not(.disabled), .stepper-current:hover:not(.disabled) {
  color: #2C6EF2 !important;
}

.bs-vertical-wizard ul > li > a:before {
  content: '';
  position: absolute;
  width: 2px;
  height: calc(100% - 25px);
  background-color: #E2E2E2;
  left: 2.23rem;
  top: 3.55rem;
  z-index: 3;
}

.bs-vertical-wizard ul > li > a .ico {
  pointer-events: none;
  font-size: 1.25rem;
  position: absolute;
  left: 1.65rem;
  top: 1.65rem;
  z-index: 4;
  color: #fff;
}

.bs-vertical-wizard ul > li > a .ico-current {
  pointer-events: none;
  font-size: 1.25rem;
  position: absolute;
  left: 1.8rem;
  top: 1.65rem;
  z-index: 4;
  color: #fff;
}

.bs-vertical-wizard ul > li > a:after {
  content: '';
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 3;
  clip-path: polygon(50% 0,100% 25%,100% 75%,50% 100%,0 75%,0 25%);
}
.bs-vertical-wizard ul > li.complete > a.stepper-complete:after {
border-radius:unset;

}
.bs-vertical-wizard ul > li > a .desc {
  display: block;
  color: #bdc2ce;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.8px;
}

.bs-vertical-wizard ul > li.complete > a{
  color: #1a88e2;
}
.bs-vertical-wizard ul > li.complete > .hexagon{
  background-color:#57C67A;
}

.bs-vertical-wizard ul > li.complete > .hexagon > .hexagonText{
  color:#FFF;
}
.bs-vertical-wizard ul > li.complete > a:after {
  content: '';
  position: absolute;
  border: 2px solid #57C67A;
  background-color: #57C67A;
  top: 1rem;
  left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 3;
}

.bs-vertical-wizard ul>li.visited >a:after {
  content: '';
  cursor: allowed;
  position: absolute;
  background-color: #002C77;
  top: 1rem;
  left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 3;
}

.bs-vertical-wizard ul > li.locked > a:after {
  content: '';
  position: absolute;
  background-color: #C2C2C2;
  top: 1rem;
  left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 3;
}
.bs-vertical-wizard ul > li:last-child > a:before {
  display: none;
}

.bs-vertical-wizard ul > li.complete > a .ico {
  left: 8px;
}

.bs-vertical-wizard ul > li > a .ico.ico-complete {
  pointer-events: none;
  font-size: 1.25rem;
  position: absolute;
  left: 1.65rem;
  top: 1.65rem;
  z-index: 999;
  color: #fff;
}

.bs-vertical-wizard ul > li > a .ico.ico-locked {
  color: #bdc2ce;
}

.bs-vertical-wizard ul > li.locked > a {
  pointer-events: none;
  color: #999999;
}

.bs-vertical-wizard ul > li.current > a:before {
  background-color: #E2E2E2;
  opacity: 1;
}

.bs-vertical-wizard ul > li.current > a:after {
  background-color: #009DE0;
  opacity: 1;
}
.bs-vertical-wizard ul > li.current:after,
.bs-vertical-wizard ul > li.current:before {
  top: 1rem;
  left: 1rem;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.hexagon {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  background-color: #FFFFFF;
  width: 2.25rem;
  height: 2.25rem;
  position: absolute;
  top: 1.125rem;
  left: 1.125rem;
  z-index: 999;
}

.hexagon-complete {
  background-color: #57C67A !important;
}

.hexagonText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100%;
  color: #009DE0;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.313rem;
}

.hexagonText-locked {
  color: #C2C2C2 !important;
}

.hexagonText-visited {
  color: #002C77 !important;
}

.current, .locked, .complete, .visited {
  display: block;
  align-items: center;
}

.stepper-title {
  display: flex;
  align-items: center;
}

.h-100 {
  margin-left: 4rem !important;
  padding-top: 1.5rem;
}

.stepper-subitems {
  margin-top: 1rem;
  justify-content: space-between;
  margin-left: 4.2rem !important;
}

.stepper-subitems > div + div {
  margin-top: 5px;
}

.show-more-less {
  font-size: 12px;
  margin-top: 0.5rem;
  display: inline-block;
}

.sub-content_displayname {
  color: #202020;
  margin-top: 15px;
  transition: ease 0.3s color;
}

.sub-content_displayname.link {
  font-weight: bold;
}

.sub-content_displayname.link .detail {
  font-weight: 400;
}

.sub-content_displayname.link:hover {
  color: #2C6EF2;
  cursor: pointer;
}

.sub-content_displayname .detail {
  color: #767676;
}

.sub-content_displayname .detail:last-child {
  margin-bottom: 15px;
}

.sub-content_displayname.sub-step-locked,
.sub-content_displayname.sub-step-locked .detail {
  color: #C2C2C2 !important;
  cursor: not-allowed;
  pointer-events: none;
}
`;