import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../appConfig/index.js';
import http from '../../../../Common/Services/HttpService/index.js';
import { CHECK_ELIGIBILITY, SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo/index.js';

export const getPetProviders = (formik, currentStep) => async (dispatch) => {
  
  dispatch(UPDATE_LOADING(true)); // Turn on loader

  try {
    const response = await http.post(appConfig.SSP_MID_URL + '/getProviders', {
      additionalInformation: {
        zipCode: formik?.values?.zipCode,
      },
      currentStepInfo: {
        "portalStep": currentStep
      }
    });

    const dogBreeds = response?.data?._Result;

    dispatch(CHECK_ELIGIBILITY(dogBreeds)); // Assuming CHECK_ELIGIBILITY is an action for storing breeds

    return dogBreeds;
  } catch (error) {
    console.error(error);
    // dispatch(SET_ALERT_MESSAGE({
    //   type: 'error',
    //   errorCode: '520',
    //   continue: true,
    //   id: 'getProviders',
    //   show: true,
    //   page: currentStep,
    // }));
    return null;
  } finally {
    dispatch(UPDATE_LOADING(false)); // Turn off loader
  }
};
