import React from 'react';
import { Button, Input } from '../../Atoms';
import classes from './styles.module.css';
import $ from 'jquery';
import { IS_ADMIN, } from '../../../Store/Slices/CallCenter';


const EmployeeListModal = (props) => {
    const {
        dateValue,
        onDateChange,
        backDateMsg,
        employeeEmail,
        cancelModal,
        dispatch,
        handleSubmitAdmin
    } = props;
    return (
        <>
            <div id="adminModal" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                            <div className={classes.modaltitle}>Admin Endorsement</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                // onClick={() => adminEnrollmentModelHide()}

                                onClick={() => {
                                    $('#adminModal').modal('hide');
                                    dispatch(IS_ADMIN(false))

                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className={classes.descprition1}>Please enter the life event date. </p>
                            <p className={classes.descprition2}> Life Event Date <span className={classes.astentik} >*</span></p>
                            <Input type='date' value={dateValue} onChange={onDateChange} />
                            {backDateMsg && <span style={{ color: '#A61003' }}>{`Invalid date. Please select a date equal to or after ${activeEffectActiveDate}.`} </span>}
                        </div>
                        <div className={classes.modalfooter}>
                            <Button className={classes.Button} onClick={() => cancelModal()} >Cancel</Button>
                            <Button className={classes.modalButton} data-dismiss="modal" disabled={!dateValue} name='admin-Endorsement' onClick={() => handleSubmitAdmin()}>Continue</Button>

                        </div>
                    </div>
                </div>
            </div>

            <div id="restUsernamePassword" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                            <div className={classes.modaltitle}>Reset Username/Password</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    $('#restUsernamePassword').modal('hide');
                                    dispatch(IS_ADMIN(false))
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className={classes.descprition4}>Username and Password for this user is successfully reset. User will have to start over creating a new unique username. </p>

                        </div>
                        <div className={classes.modalfooter}>
                            <Button className={classes.Button} data-dismiss="modal" name='restUsernamePasswordButton'>OK</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="employeeNotActive" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                            <div className={classes.modaltitle}>Alert</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    $('#employeeNotActive').modal('hide');
                                    dispatch(IS_ADMIN(false))
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className={classes.descprition3} >Employee not Active. </p>

                        </div>
                        <div className={classes.modalfooter}>
                            <Button className={classes.Button} data-dismiss="modal" name='employeeNotActiveBtn'>OK</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="employeeInfoNotComplete" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                            <div className={classes.modaltitle}>Alert</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    $('#employeeInfoNotComplete').modal('hide');
                                    dispatch(IS_ADMIN(false))
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className="text-center" >Employee Information is Not Complete. </p>

                        </div>
                        <div className={classes.modalfooter}>
                            <Button className={classes.Button} data-dismiss="modal" name='employeeInfoNotCompleteBtn'>OK</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="empNotRegistered" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                            <div className={classes.modaltitle}>Alert</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    $('#empNotRegistered').modal('hide');
                                    dispatch(IS_ADMIN(false))
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className={classes.descprition3} >Employee not registered for MFA login. </p>

                        </div>
                        <div className={classes.modalfooter}>
                            <Button className={classes.Button} data-dismiss="modal" name='userNameNotFoundBtn'>OK</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="empDisabled" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                            <div className={classes.modaltitle}>Alert</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    $('#empDisabled').modal('hide');
                                    dispatch(IS_ADMIN(false))
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className={classes.descprition3} >Employee is Disabled. </p>

                        </div>
                        <div className={classes.modalfooter}>
                            <Button className={classes.Button} data-dismiss="modal" name='empDisabledBtn'>OK</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="userName" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                            <div className={classes.modaltitle}>Retrieve User Name</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    $('#userName').modal('hide');
                                    dispatch(IS_ADMIN(false))
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className={classes.descprition3} >User Name for this user is {employeeEmail} </p>

                        </div>
                        <div className={classes.modalfooter}>
                            <Button className={classes.Button} data-dismiss="modal" name='usernameButton'>OK</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="userNamePasswordReset" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                            <div className={classes.modaltitle}>Status</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    $('#userNamePasswordReset').modal('hide');
                                    dispatch(IS_ADMIN(false))
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className={classes.descprition3} >ALready reset username/password.</p>

                        </div>
                        <div className={classes.modalfooter}>
                            <Button className={classes.Button} data-dismiss="modal" name='usernameButton'>OK</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="userNameNotFound" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                            <div className={classes.modaltitle}>Alert</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    $('#userNameNotFound').modal('hide');
                                    dispatch(IS_ADMIN(false))
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className={classes.descprition3} >Employee Email is Invalid.</p>

                        </div>
                        <div className={classes.modalfooter}>
                            <Button className={classes.Button} data-dismiss="modal" name='userNameNotFoundBtn'>OK</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="networkError" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                            <div className={classes.modaltitle}>Alert</div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    $('#networkError').modal('hide');
                                    dispatch(IS_ADMIN(false))
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body py-3">
                            <p className={classes.descprition3} >Network Error: Request failed with status code 503.</p>

                        </div>
                        <div className={classes.modalfooter}>
                            <Button className={classes.Button} data-dismiss="modal" name='userNameNotFoundBtn'>OK</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmployeeListModal;