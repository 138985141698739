import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ResendOTPBlock from '../../Atoms/ResendOTPBlock/index';
import StickyNote from '../../Atoms/StickyNote';
import { TextInput } from '../../Atoms';
import Button from '../../Atoms/Buttons';
import http from '../../../../../Common/Services/HttpService';
import appConfig from '../../../../../appConfig';
import { getBasicAuthHeaderValue } from '../../Helper/MFAHelper'
import { SET_MFA_BASIC_AUTH_VALUE, SET_LOGIN_OTP_METHOD, GET_EMAIL } from '../../../../../Store/Slices/Registeration';
import ChangeMFAMethod from './ChangeMFAMethod';
import Loader from '../../Atoms/Loader';
import { SHOW_LOADER } from '../../../../../Store/Slices/Loader/index';
import { errorMessages } from '../../Atoms/FormValidation'
import { Link } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    MFASecurityCode: Yup.string()
        .matches(/^[0-9]+$/, 'No special characters allowed')
        .required('Security Code is required'),
});

const VerifyOTP = ({ onNext, onPrev, handleMFAChange }) => {

    const dispatch = useDispatch();
    const { loader } = useSelector((state) => state.loader);
    const { registeration } = useSelector((storeState) => storeState);
    const [selectedMethod, setSelectedMethod] = useState(registeration.preferredChannel);
    const [showChangeMethod, setShowChangeMethod] = useState(false);
    const [errVerifyOTP, setErrVerifyOTP] = useState('');
    const [err, setErr] = useState('');
    const [changeMFAError, setChangeMFAError] = useState('');
    const { legalEntityId = '', appCode = '' } = useSelector((storeState) => storeState?.registeration);


    let verificationText = "To verify your account, please enter the security code sent to your registered phone number.";

    const formik = useFormik({
        initialValues: { MFASecurityCode: '' },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            handleVerifyOTP(values);
        },
    });

    const clearErrMsg = () => {
        setErrVerifyOTP('');
        setChangeMFAError('');
    }

    const handleTimer = async () => {
        dispatch(SHOW_LOADER(true));
        let basicAuthorizationValue = getBasicAuthHeaderValue();
        basicAuthorizationValue && dispatch(SET_MFA_BASIC_AUTH_VALUE(basicAuthorizationValue));

        const reqObj = {
            "employeeData": registeration.employeeInfo,
            "appCode": registeration.appCode,
            "channel": 'call',
        }
        http
            .post(
                appConfig.SSP_AUTH_URL + "/stateful/user-mgmt/retrieve-id-ex/1",
                reqObj,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Basic ${basicAuthorizationValue}`,
                        'X-REQUEST-ID': Math.random(200),
                    }
                }
            )
            .then(async (resp) => {
                if (resp.status === 200)
                    dispatch(SHOW_LOADER(false));
            }).catch((error) => {
                
                setErr(errorMessages(error));
                dispatch(SHOW_LOADER(false));
            });
    }

    const handleVerifyOTP = (values) => {

        dispatch(SHOW_LOADER(true));
        let basicAuthorizationValue = getBasicAuthHeaderValue();
        basicAuthorizationValue && dispatch(SET_MFA_BASIC_AUTH_VALUE(basicAuthorizationValue));
        const reqObj = {
            "otp": values.MFASecurityCode?.trim(),
        }
        http
            .post(
                appConfig.SSP_AUTH_URL + "/stateful/user-mgmt/retrieve-id/2",
                reqObj,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Basic ${basicAuthorizationValue}`,
                        'X-REQUEST-ID': Math.random(200),
                    }
                }
            )
            .then((resp) => {
                if (resp.status === 200) {
                    dispatch(GET_EMAIL(resp.data))
                    onNext(formik.values);
                } else {
                    setErrVerifyOTP(errorMessages(resp));
                }
                dispatch(SHOW_LOADER(false));
            })
            .catch((resp) => {
                setErrVerifyOTP(errorMessages(resp));
                dispatch(SHOW_LOADER(false));
            });
    }

    const handleMethodChange = (method) => {
        setSelectedMethod(method);
        dispatch(SET_LOGIN_OTP_METHOD(method));

    };

    const handleChangeMFA = () => {
        setShowChangeMethod(true);
        clearErrMsg();
    }

    const handleMFAError = (errorMsg) => {
        setChangeMFAError(errorMsg);
    };


    if (showChangeMethod) {
        return (
            <ChangeMFAMethod
                handleMFABack={() => setShowChangeMethod(false)}
                onMethodChange={handleMethodChange}
                selectedMethod={selectedMethod}
                onError={handleMFAError}
            />
        );
    }

    return (
        <>
            {loader && <Loader loading={loader} />}
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="row">
                    <div className="col-12">
                        <h1 className="form-side-header">Retrieve User ID</h1>
                        <p className="form-mandatory mb-0">
                            Fields marked with * are mandatory
                        </p>
                        <p>
                            {verificationText}
                        </p>
                    </div>
                </div>

                <div className="row py-2">
                    <div className="col-sm-12 col-md-6">
                        <TextInput
                            label="Security Code"
                            required={true}
                            placeholder="Enter Security Code"
                            max="9999-12-31"
                            className="w-100 inputField p-3"
                            onInput={clearErrMsg}
                            {...formik.getFieldProps('MFASecurityCode')}
                        />
                        {formik.touched.MFASecurityCode && formik.errors.MFASecurityCode && (
                            <div className="form-mandatory">{formik.errors.MFASecurityCode}</div>
                        )}
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <StickyNote
                            title={'Important Note'}
                            children={`The security code will expire in 5 minutes after you receive it.`}
                        />
                    </div>
                </div>
                {(errVerifyOTP) &&
                    <div className="row">
                        <span className='APIErr'>{errVerifyOTP}</span>
                    </div>
                }
                <ResendOTPBlock duration={45} onResend={handleTimer} />

                <div className="row">
                    <div className="col-12 col-md-12 pt-1">
                        <div>Unable to receive code?</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12 px-1">
                        <Button
                            type="button"
                            className="btn btn-link"
                            currentstep="MFAForgotPassword"
                            onClick={handleChangeMFA}
                        >
                            Try another method
                        </Button>
                    </div>
                </div>
                {changeMFAError &&
                    <div className="row">
                        <span className='APIErr'>{changeMFAError}</span>
                    </div>
                }

                <div className="row py-4 justify-content-between">
                    <div className="col-sm-12 col-md-4 py-2">
                    <Link role="link" to={`/enrollment?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Back to login</Link>
                    </div>
                    <div className="col-sm-12 col-md-6 py-2 mr-4 text-md-right">
                        <Button
                            type="button"
                            className="btn btn-outline-primary primaryOutline mr-4"
                            onClick={onPrev}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            className="btn btn-primary primaryBackground"
                            disabled={(!formik.isValid || !formik.dirty)}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default VerifyOTP;
