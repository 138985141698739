import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import Tippy from '@tippyjs/react';
import { useSelector } from 'react-redux';
import imageMap from '../../Molecules/CustomSection/Helper/imageHelper';
import {getFileName} from '../../Molecules/CustomSection/Helper/commonHelper';
import tooltipLayout from './addressTooltip.json';
const ToolTip = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const { filename, alt="default alt", content, forAddress, toolTipConfig, hidden, hideElement, showOnAPISuccess } = props;
  const { layout } = content || {};
  const { registerationError, stepForm } = useSelector((storeState) => storeState);
  const shouldHideOnAPIFail = showOnAPISuccess && registerationError[stepForm.currentStep]?.hasError;
  let imgSrc = '';

  try {
    imgSrc = imageMap[getFileName(filename)];
  } catch (e) { }

  useEffect(() => {
    const updateWindow = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindow);
    return () => {
      window.removeEventListener('resize', updateWindow);
    };
  }, []);

  const hideToolTip = stepForm.currentStep === 'UserAccountVerifyEmail' && !registerationError.emailVerified; 
  const smallScreen = width <= 375;

  if (!forAddress) {
    return (
      <div className={`${layout?.toolTipStyle?.toolTipClasses?.outerRow} ${hideElement || shouldHideOnAPIFail ? 'hideElement' : ''}`} hidden={hideToolTip}>
        <Tippy
          placement={layout?.toolTipStyle?.toolTipClasses?.placement}
          maxWidth={layout?.toolTipStyle?.toolTipClasses?.maxWidth}
          content={
            <div className={layout?.toolTipStyle?.toolTipClasses?.contentOuterCol}>
              <div className={layout?.toolTipStyle?.toolTipClasses?.titleOuterRow}>
                <div
                  className={layout?.toolTipStyle?.toolTipClasses?.titleInnerCol}
                  style={layout?.toolTipStyle?.tipStyle?.titleInnerCol}
                >
                  <strong className={layout?.toolTipStyle?.toolTipClasses?.titleInnerRow}>
                    {!toolTipConfig || !toolTipConfig.hideLabel ? 'Password Rules' : toolTipConfig.label}
                  </strong>
                </div>
              </div>
              <div className={layout?.toolTipStyle?.toolTipClasses?.tipsOuterRow}>
                <div
                  className={layout?.toolTipStyle?.toolTipClasses?.tipsOuterCol}
                  style={layout?.toolTipStyle?.tipStyle?.tipOuterCol}
                >
                  <ul className={layout?.toolTipStyle?.toolTipClasses?.tipsInnerRow}>
                    {props?.toolTip?.map((tip, index) => (
                      <li key={index}>
                        {!toolTipConfig?.numberedList && <span className={layout?.toolTipStyle?.toolTipClasses?.tipsInnerColPullet}>{'\u2022  '}</span>}
                        {toolTipConfig?.numberedList && <span className={layout?.toolTipStyle?.toolTipClasses?.tipsInnerColPullet}>{index + 1 + '. '}</span>}
                        <span className={layout?.toolTipStyle?.toolTipClasses?.tipsInnerColText}>{tip}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          }
        >
          <img
            src={imgSrc}
            className="row mt-5 justify-content-center tooltipImg"
            alt={alt}
            tabIndex={0}
          />
        </Tippy>
      </div>
    );
  } else {
    return (
      <div className={hideElement ? 'hideElement' : ''}>
        <Tippy
          placement={smallScreen ? tooltipLayout?.toolTipStyle?.toolTipClasses?.smallScreenPlacement : tooltipLayout?.toolTipStyle?.toolTipClasses?.placement}
          maxWidth={tooltipLayout?.toolTipStyle?.toolTipClasses?.maxWidth}
          content={
            <div className={`toolTipBorder ${tooltipLayout?.toolTipStyle?.toolTipClasses?.contentOuterCol}`} style={tooltipLayout?.toolTipStyle?.tipStyle?.tipOuterCol}>
              <div className={tooltipLayout?.toolTipStyle?.toolTipClasses?.tipsOuterRow}>
                <div className={tooltipLayout?.toolTipStyle?.toolTipClasses?.tipsOuterCol}>
                  <div className="pb-2 pt-1">{props?.toolTip}</div>
                </div>
              </div>
            </div>
          }
        >
          <img src={imgSrc} 
            className="toolTip row mt-4 pt-0 px-2 pl-0 justify-content-center forAddress" alt={alt} tabIndex={0}/>
        </Tippy>
      </div>
    );
  }
};

ToolTip.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  label: PropTypes.string,
  size: PropTypes.string,
};

export default ToolTip;