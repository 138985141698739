import React, { Suspense, useEffect, useState } from 'react';
import FormGenerator from '../../../Cells/Form/FormGenerator';
//import { useSelector } from 'react-redux';
// import SectionHeader from '../../../Molecules/SectionHeader';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { callConstants } from '../../../../Store/Slices/Entities/constants/index';
import { constantsList } from '../../../../Common/Constants/index';
import mfaImage from '../../../../Assets/Images/mfa.png'; 


import './styles.css';

const MultiFactorAuthenticationOld = (props) => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, SetContent] = useState({ banner: {} });
  const dispatch = useDispatch();
  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => SetContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);
  const { title, subtitle, panelTitle, banner = {}, icon, layout } = content || {};

  useEffect(() => {
    /* useEffect to get all constants through API call */
    // constantsList.forEach((element) => {
    //   dispatch(callConstants(element));
    // });
  }, []);
  const breadcrumbs = [
    { to: '', text: 'Employee' },
    { to: '/searchForAnEmployee', text: 'Search for an Employee' },
  ];

  //  const [content1, setContent] = useState({
  //    title: '',
  //    subtitle: '',
  //    panelTitle: '',
  //    banner: {},
  //    icon: '',
  //    authUrl: '',
  //    redirectUrl: '',
  //    helpLinks: [],
  //    inputPlaceHolder: '',
  //    passwordPlaceHolder: '',
  //    buttonLable: '',
  //    rememberMeLable: '',
  //  });
  const notify = () =>
    toast.error(
      <div>
        You have exceeded the maximum number (3) of attempts. Please contact Mercer Contact Center @
        (xxx) - (xxx) - (xxxx)' for further assistance.'
      </div>
    );
  return (
    <div>
      {/* <SectionHeader title="Add an Employee" breadcrumbs={breadcrumbs} current="Add an Employee" /> */}
      <Suspense fallback={<div>Loading...</div>}>
        <div class="container-fluid">
          <div class="row py-3 mt-4">
            {/* <div class="form-row py-3 mt-4"> */}
            <div
              className={`  ${layout && layout.dualColumn ? layout.dualColumn[0] : 'col-md-12'} `}
            >
              {/* // <div className="centered"> */}
              <img
                  src={mfaImage}
                  alt="Entering security code"
                  className="img-responsive"
                  id="mfa"
                />
            </div>
            <div
              className={`  ${layout && layout.dualColumn ? layout.dualColumn[1] : 'col-md-12'} `}
              id="dualColumn"
            >
              {/* <button onClick={notify}>Make me a toast</button> */}
              <Toaster
                toastOptions={{
                  className: 'toast-message',
                  duration: 3000,

                  style: {
                    border: '1px solid #E6A7A3',
                    padding: '16px',
                    color: '#AB2F26',
                    background: '#FFE8E6',
                  },
                }}
              />

              <div className="formWrapper">
                {/* <div className="form-row py-3 mt-4"> */}
                <FormGenerator content={content} {...props} />
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </div>
  );
};

export default MultiFactorAuthenticationOld;
