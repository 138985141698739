// Import all potential images:
import IconBackArrow from '../../../../Assets/Images/Icon_Back_Arrow.png';
import IconEdit from '../../../../Assets/Images/IconEdit.png';
import iconarrowup from '../../../../Assets/Images/icon-arrow-up.svg';
import iconarrowdown from '../../../../Assets/Images/icon-arrow-down.svg';
import IconShoppingCart from '../../../../Assets/Images/Icon Shopping Cart.png';
import iconemployeevalidation from '../../../../Assets/Images/icon_employee_validation.png';
import IconSecurity from '../../../../Assets/Images/Icon Security.png';
import IconKey from '../../../../Assets/Images/Icon Key.png';
import iconcircleinfopassword from '../../../../Assets/Images/icon_circle_info_password.png';
import iconVerified from '../../../../Assets/Images/iconVerified.svg';
import smarrowup from '../../../../Assets/Images/sm-arrow-up.svg';
import arrowdown from '../../../../Assets/Images/arrow-down.svg';
import iconimportant from '../../../../Assets/Images/icon-important.png';
import IconInformation from '../../../../Assets/Images/Icon Information.png';
import calendericonmedium from '../../../../Assets/Images/calender_icon_medium.png';
import newhire from '../../../../Assets/Images/new-hire.png';
import welcomebanner from '../../../../Assets/Images/welcome-banner.png';
import calendarhome from '../../../../Assets/Images/calendar_home.png';
import iconwarning from '../../../../Assets/Images/icon-warning.png';
import IconPDF from '../../../../Assets/Images/Icon PDF.png';
import iconvideo from '../../../../Assets/Images/icon-video.png';
import imagebrochure from '../../../../Assets/Images/image-brochure.png';
import imagevideo from '../../../../Assets/Images/image-video.png';
import heroimgemployerdesktop from '../../../../Assets/Images/hero_img_employer_desktop.png';
import IconLogout from '../../../../Assets/Images/Icon_Logout.svg';
import IconSettings from '../../../../Assets/Images/Icon_Settings.svg';
import calendar from '../../../../Assets/Images/calendar.png';
import IconSettingswhite from '../../../../Assets/Images/Icon_Settings-white.svg';
import smarrowupwhite from '../../../../Assets/Images/sm-arrow-up-white.svg';
import smarrowdownwhite from '../../../../Assets/Images/sm-arrow-down-white.svg';
import IconContact from '../../../../Assets/Images/Icon_Contact.svg';


// Create and export a mapping object:
const imageMap = {
  IconBackArrow,
  IconEdit,
  iconarrowup,
  iconarrowdown,
  IconShoppingCart,
  iconemployeevalidation,
  IconSecurity,
  IconKey,
  iconcircleinfopassword,
  iconVerified,
  smarrowup,
  arrowdown,
  iconimportant,
  IconInformation,
  calendericonmedium,
  newhire,
  welcomebanner,
  calendarhome,
  iconwarning,
  IconPDF,
  iconvideo,
  imagebrochure,
  imagevideo,
  heroimgemployerdesktop,
  IconLogout,
  IconSettings,
  calendar,
  IconSettingswhite,
  IconContact,
  smarrowupwhite,
  smarrowdownwhite
};
export default imageMap;
