import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'forgotUser',
  initialState: {
    retrieved: false,
    reset: false
  },
  reducers: {
    // action => action handler
    RETRIEVE_USER: (forgotUser, action) => {
        forgotUser.retrieved= action.payload
    },
    RESET_PASSWORD: (forgotUser, action) => {
      forgotUser.reset= action.payload
  },
  },
});

export const { RETRIEVE_USER, RESET_PASSWORD } = slice.actions;

export default slice.reducer;
