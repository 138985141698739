export const apiConstants = {
  BASE_URL: 'http://localhost:5000',
  HEADERS: {
    'Access-Control-Allow-Origin': '*',
  },
  DEMOGRAPHIC_URL: {
    getPerson: '/getPerson',
    savePerson: '/makesave',
  },
  USER_URL: {
    getBearer: '/bearerToken',
    userLegalEntitiy: '/userLegalEntitiy',
    recaptchaValidator: '/recaptchaValidator',
  },
  CONSTANTS_URL: {
    getConstants: '/getConstants',
  },

  CONSTANTS_SINGLE_URL: {
    getConstants: '/v1/constants',
  },
  

  AUTH_URL: 'http://localhost:3003',
};
