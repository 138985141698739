import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Banner, Checkbox, Button, Linker } from '../../Atoms';
import Panel from '../../Molecules/Panel/Panel';
import './styles.css';
import { callBearer, CONFIG_SSO_LOGIN, setProcessType } from '../../../Store/Slices/Auth/userAuth';
import { processAuthTokenType } from '../../../Store/Slices/AuthToken/userAuthToken';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import APIRequest from '../../Utils/Helper/APIHelper';
import { onLoginAPI } from '../../../Store/Slices/Entities/response/index';
import { updateEnvironment } from '../../../Store/Slices/Auth/userAuth/index';
import dataService from '../../../Common/Services/DataService';
import { APIRequestAction } from '../../../Store/Slices/Entities/response';
import { apiReceived } from '../../../Store/Slices/Auth/loggedUserEntity/index';
import Loader from '../../Atoms/Loader';
import { useSelector } from 'react-redux';
import http from '../../../Common/Services/HttpService/index';
import { CALL_CENTER_LOGIN_EMPLOYEE_DETAILS } from '../../../Store/Slices/CallCenter/index'
import clientLogo from '../../../Assets/Images/mmc-logo.png'; // Directly import the image
import Elementor from '../../Cells/Form/Elementor';
const LoginView = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const { userAuth } = useSelector((storeState) => storeState.auth);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: false,
    environment: '',
  });
  const [content, setContent] = useState({
    title: '',
    subtitle: '',
    panelTitle: '',
    welcomeMessage: '',
    welcomeTitle: '',
    banner: {},
    icon: '',
    authUrl: '',
    redirectUrl: '',
    helpLinks: [],
    inputPlaceHolder: '',
    passwordPlaceHolder: '',
    buttonLable: '',
    rememberMeLable: '',
  });
  const [showLoader, setShowLoader] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    setContent(props.content[props.type]);
  }, [props.content]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    });
    if (event.target.name == 'username') setUsername(event.target.value);
  };

  const handleSubmit = async (event) => {
    /* function to submit login credentials and to call userEntity */
    event.preventDefault();
    dispatch(CONFIG_SSO_LOGIN({configuratorSSOLogin: false}))
    setShowLoader(true);
    setLoginError(false);
    http.setUserType(props.type);
    http.setLanguage(currentLanguage);
    await dispatch(
      updateEnvironment({
        userType: props.type,
      })
    );
    dispatch(setProcessType('oldEnrollment'))
    await dispatch(processAuthTokenType({ authTokenType: 'oldEnrollment'}))
    await dispatch(
      callBearer({
        loginDetails: {
          login: formData.username,
          password: formData.password,
        },
        userType: props.type,
        environment: formData.environment,
      })
    );
    let authReqData = {};
    if (content.authPreUrl) {
      const userName = { loginName: username };
      let authPreRequest = await dispatch(
        APIRequestAction({ userName }, { apiconfig: { url: content.authPreUrl } })
      );
      //Added below line to handle the _Result added in the reponse entry point
      authPreRequest = authPreRequest._Result;
      
      authReqData = authPreRequest && authPreRequest ? authPreRequest[content.authReqElement] : {};
    }

    let loggedUserEntity = await dispatch(
      APIRequestAction({ authReqData }, { apiconfig: { url: content.authUrl } })
    );
    dispatch(CALL_CENTER_LOGIN_EMPLOYEE_DETAILS(loggedUserEntity))
    if (content.onLoginAPICalls) {
      await APIRequest(content.onLoginAPICalls, { loggedUserEntity }).then((data) => {
        if (data) {
          dispatch(onLoginAPI(data));
        }
      });
    }
    if (!loggedUserEntity) {
      setLoginError(true);
    }
    await dispatch(apiReceived(loggedUserEntity));
    setUserAuthenticated(true);
  };

  const {
    title,
    subtitle,
    panelTitle,
    welcomeMessage,
    welcomeTitle,
    banner = {},
    icon,
  } = content || {};

  useEffect(() => {
    if (userAuthenticated) {
      if (userAuth && userAuth.data && userAuth.data.access_token && !loginError) {
        history.push(content.redirectUrl);
      }
      setShowLoader(false);
      setLoginError(true);
      setUserAuthenticated(false);
    }
  }, [userAuthenticated]);

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  return (
    <Banner desktop={banner.desktop} tablet={banner.tablet}>
      <div className="container-fluid loginView">
        <div className="row p-md-5 p-lg-0 p-0">
          <div className="col-lg-7 align-self-end d-none d-md-block py-3">
            <Panel
              className=""
              title={welcomeTitle}
              body={welcomeMessage}
            // img={icon}
            // hasDivider={false}
            ></Panel>
          </div>
          {/* <div className="offset-lg-7 col-lg-4 col-12"></div> */}
          <div className="offset-lg-1 offset-xl-0 col-lg-4  col-12">
            <div className="row py-3">
              <div className="text-center col-12 d-none d-sm-block">
              
                  <img
                    src={clientLogo}
                    alt="Client logo"
                    className="img-responsive"
                    width="60%"
                    id="client-logo"
                  />
              </div>

              <div className="text-center col-12 py-1 pb-0 mb-0">
                <h3 className="loginHeader">{title}</h3>
              </div>
              <div className="text-left col-11 col-md-11 offset-1 pl-1 pl-md-2 pr-4 pr-md-5 pr-lg-4">
                <div className="loginMediumHeader">{subtitle}</div>
              </div>
            </div>
            <div className="row px-lg-0 px-md-5 px-3">
              <div className="container-fluid w-100">
                <form onSubmit={handleSubmit} className="offset-lg-1 d-flex flex-column">
                  {/* <div className="">Password</div> */}
                  User ID
                  <input
                    id="username"
                    name="username"
                    onChange={handleChange}
                    value={formData.username}
                    placeholder={(content && content.inputPlaceHolder) || ''}
                    className="w-100 my-3 p-3"
                  />
                  Password
                  <input
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={formData.password}
                    placeholder={(content && content.passwordPlaceHolder) || ''}
                    className="w-100 my-2 p-3"
                    type="password"
                  />
                  {loginError && <div className="loginError">{content.loginError}</div>}
                  {/* <Checkbox
                    id="rememberMe"
                    onChange={handleChange}
                    checked={formData.rememberMe}
                    label={(content && content.rememberMeLable) || ''}
                    classNameWrapper="pt-2 pb-3"
                  /> */}
                  <div className="row py-2">
                    {content &&
                      content.helpLinks &&
                      content.helpLinks.length > 0 &&
                      content.helpLinks.map((currentLink) => {
                        return (
                          <Linker
                            to={currentLink.to}
                            key={currentLink.text}
                            className={currentLink.className}
                          >
                            {currentLink.text}
                          </Linker>
                        );
                      })}
                  </div>
                  <Button className="btn-block my-3 loginContainerButton" type="submit">
                    {(content && content.buttonLable) || ''}
                  </Button>
                </form>
                <div className="row py-3">
                  <div className="col-6 pl-1">
                    <div className="row d-flex flex-column px-3 offset-lg-1 align-items-left justify-content-md-around justify-content-left text-nowrap">
                      {content?.additionalInfoFirst}
                    </div>
                    <div className="row d-flex  flex-column px-3 offset-lg-1 align-items-left justify-content-md-around justify-content-left text-nowrap">
                      {content?.additionalInfoSecond}
                    </div>
                  </div>

                  <div className="col-4 offset-2 pt-1">
                    {/* <Linker className="btn btn-primary" to="/userRegistration">
                      {(content && content.buttonLabel) || ''}
                    </Linker> */}

                    <Button
                      className="w-100"
                      outline
                      type="submit"
                      data-target={'#' + '/userRegistration'}
                      data-toggle={'/userRegistration'}
                      onClick={() => {
                        history.push('/userRegistration');
                      }}
                    >
                      Register
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}
    </Banner>
  );
};
LoginView.propTypes = {
  type: PropTypes.string,
};

export default LoginView;
