import http from '../HttpService';
import appConfig from '../../../appConfig';
import { useDispatch, useSelector, useStore } from 'react-redux';
// import { getResponseState } from '../../../../Store/Slices/Entities/response';
import configureStore from '../../../Store/configureStore';
import { getValueFromKeyString } from '../../../App/Utils/Helper/GetValueFromKeyString';
import { getValueFromKeyStringAcceptArray } from '../../../App/Utils/Helper/GetValueFromKeyStringAcceptArray';

//const store = configureStore();
const getStorestate = () => {};

const getElements = async (
  formName,
  formElements,
  formLayoutType,
  SetContentData,
  parentFrom,
  currentLanguage = '',
  userType = '',
  clientId
) => {
  // const dataFromRedux = useSelector((storeState) => storeState?.auth?.loggedUserEntity?.data);

  // let clientId = dataFromRedux?._Result?.clientId?.internalClientId;
  try {
    SetContentData((prevContentData) => ({
      ...prevContentData,
      loadingCheck: true,
    }));
    //http.setBearer(getBearer());
    const { data: formElement } = await http.post(appConfig.SSP_MID_URL + '/getForm', {
      form: formName,
      formLayoutType: formLayoutType,
      language: currentLanguage,
      userType,
      clientId: clientId,
    });
    if (parentFrom) {
      let newForm = { ...formElements[parentFrom], [formName]: { ...formElement } };
      formElements = { ...formElements, [parentFrom]: newForm };
    } else {
      formElements = { ...formElements, [formName]: { ...formElement } };
    }
    SetContentData((prevContentData) => ({
      ...prevContentData,
      loadingCheck: false,
    }));
    return formElements;
  } catch (error) {
    
    SetContentData((prevContentData) => ({
      ...prevContentData,
      loadingCheck: false,
    }));
  }
};
// let products = {}
// let arr = []
// let arr2 = []

// let count = [0]
// function add(arr, obj) {
//   const { length } = arr2;
//   const c = length + 1;
//   const found = arr.some(el => (el.firstName === obj.firstName && el.lastName === obj.lastName));
//   if (found)
//  // arr.push({ firstName: obj.firstName, lastName: obj.lastName });
// //else
// arr2.push({ firstName: obj.firstName, lastName: obj.lastName });

//   

//   return arr;
// }

// var arr = [{
//   id: 1,
//   username: 'fred'
// }, {
//   id: 2,
//   username: 'bill'
// }, {
//   id: 3,
//   username: 'ted'
// }];
let arr =[]
let arrError =[]
let count=0
function add(obj) {
  var id = arr.length + 1;        
          if (arr.filter(item=> (item.firstName === obj.firstName && 
            item.lastName === obj.lastName &&
            item.employeeId === obj.employeeId &&
            item.birthDate === obj.birthDate 
            ) ).length === 0){
             arr =[]
             arrError=[]
          arr.push({  firstName: obj.firstName, 
            lastName: obj.lastName,
            employeeId: obj.employeeId,
            birthDate: obj.birthDate

           });
        
          //arrError=[]
      }
      else
      arrError.push({  firstName: obj.firstName, 
        lastName: obj.lastName,
        employeeId: obj.employeeId,
        birthDate: obj.birthDate

       });

return arrError.length
}
let manageLogiConfigFormData = (payloadObj) => {
  let loginInformation = payloadObj?.values?.LoginInformation || {};
  let formData = new FormData();

  if (payloadObj?.additionalInfo?.clientId) {
    formData.append('clientId', payloadObj.additionalInfo.clientId);
  }

  formData.append('titleColor', loginInformation.titleColorByClient);
  formData.append('welcomeMsg', loginInformation.welcomeMsgByClient);
  formData.append('msgColor', loginInformation.msgColorByClient);
  formData.append('welcomeTitle', loginInformation.welcomeTitleByClient);
  formData.append('hideLogo', Boolean(loginInformation.clientLogoToggle));
  formData.append('showChat', Boolean(loginInformation.showChat));
  formData.append('hideRecaptcha', Boolean(loginInformation.hideRecaptcha));
  if (payloadObj.values.fileByClient && payloadObj.values.fileByClient.length) {
    formData.append(
      'file',
      payloadObj.values.fileByClient[0],
      payloadObj.values.fileByClient[0].name
    );
  }

  return formData;
};
// add('ted');
// 
// const solution = products.map((product)=>{
//   return favorites.find(el=>el.product_name === product.product_name) ? 'x' : 'y'
// })
//Api call result on form submit
//function to make api call to node server to get options based on config
const getAPIResponse = async (payloadObj, layout, currentAPI, localState) => {


  let { url = '' } = (layout && layout.apiconfig && layout.apiconfig) || {};
  let payloadDependency = {};

  if (currentAPI) {
    url = (currentAPI.apiconfig && currentAPI.apiconfig.url) || '';
  }
  try {
    let result;

    if (currentAPI?.apiconfig?.isLoginConfiguration) {
      result = await http.post(
        appConfig.SSP_MID_URL + `${url}`,
        manageLogiConfigFormData(payloadObj),
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      );
    } else {
      result = await http.post(appConfig.SSP_MID_URL + `${url}`, payloadObj);
    }
    result = result.data;

    if (currentAPI?.apiconfig?.localStateDependency) {
      for (let i = 0; i < currentAPI?.apiconfig?.localStateDependency?.length; i++) {
        const currentDependency = currentAPI.apiconfig.localStateDependency[i];

        let dependentvalue = getValueFromKeyString(result, currentDependency.responsePath);
        let value = '';
        // getStorestate();
        localState = { ...localState, [currentDependency.keyName]: dependentvalue };
      }
    }
    return result;
  } catch (error) {
   
    // const { data: message } = error.message;
    var data = {};
    data.message = error.message;

    // let errObject = payloadObj['values']; //["EmployeeValidation"]
   
    if (payloadObj?.values?.EmployeeValidation) {
      //  let errorCount = add(payloadObj['values']["EmployeeValidation"] )
      let errorCount = add(payloadObj.values.EmployeeValidation);

     

      if (errorCount) data = { ...data, ...{ errorCount: errorCount } };
    }

    return data;
  }
};
const getAPIResponseMFA = async (payloadObj, layout, currentAPI, localState) => {
  let { url = '' } = (layout && layout.apiconfig && layout.apiconfig) || {};
  let payloadDependency = {};

  if (currentAPI) {
    url = (currentAPI.apiconfig && currentAPI.apiconfig.url) || '';
  }
  try {
    let result;
    let legalEntityId = payloadObj.legalEntityId;
    let appCode = payloadObj.appCode;
    let basicAuthorizationValue = payloadObj.basicAuthorizationValue;
    let token = payloadObj.token;
    let email = payloadObj.email;
    let otp = payloadObj.otp;

    let employeeData = {};
    let obj = {};
    
    if (Object.keys(payloadObj).length > 0) {
      if (currentAPI?.apiconfig.createCustomPayload) {
        for (let i = 0; i < currentAPI?.apiconfig['formikObjectFields'].length; i++) {
          if(currentAPI?.apiconfig['formikObjectFields'][i] !== 'SSN' && currentAPI?.apiconfig['formikObjectFields'][i] !== 'employeeId' ){
            if(currentAPI?.apiconfig.emailOtp || currentAPI?.apiconfig.mobileOtp  ){
              obj['otp'] =  otp
            }
            if(currentAPI?.apiconfig.changePayload && currentAPI?.apiconfig.newPayload && !currentAPI?.apiconfig.emailOtp){
              obj['email'] = email
            }
            if (currentAPI?.apiconfig.needAppCode)
              if (currentAPI?.apiconfig['formikObjectFields'][i] !== 'legalEntityId')
                employeeData[currentAPI?.apiconfig['formikObjectFields'][i]] =
                  getValueFromKeyStringAcceptArray(
                    payloadObj,
                    currentAPI?.apiconfig['formikObjectPath'],
                    currentAPI?.apiconfig['formikObjectFields'][i]
                  );
              else employeeData[currentAPI?.apiconfig['formikObjectFields'][i]] = legalEntityId;
            else if (currentAPI?.apiconfig.needSingleParameter && !currentAPI?.apiconfig.changePayload )
              obj[currentAPI?.apiconfig['formikObjectFields'][i]] = getValueFromKeyStringAcceptArray(
                payloadObj,
                currentAPI?.apiconfig['formikObjectPath'],
                currentAPI?.apiconfig['formikObjectFields'][i]
              );

            // if (currentAPI?.apiconfig.needToken  && currentAPI?.apiconfig['formikObjectFields'][i] !== 'otp' )
            //  obj[currentAPI?.apiconfig['formikObjectFields'][i]] = token
            //  else
            //  obj[currentAPI?.apiconfig['formikObjectFields'][i]] = getValueFromKeyStringAcceptArray(payloadObj, currentAPI?.apiconfig['formikObjectPath'], currentAPI?.apiconfig['formikObjectFields'][i]) ;
            if (
              currentAPI?.apiconfig['formikObjectFields'][i] === 'reason' ||
              currentAPI?.apiconfig['formikObjectFields'][i] === 'token'
            ) {
              if (
                currentAPI?.apiconfig.needReason &&
                currentAPI?.apiconfig['formikObjectFields'][i] === 'reason'
              ) {
                obj['reason'] = 'password'; //urrentAPI?.apiconfig.reason
              }
              if (
                currentAPI?.apiconfig.needToken &&
                currentAPI?.apiconfig['formikObjectFields'][i] === 'token'
              ) {
                obj['token'] = token;
              }
            } else {
              if(!currentAPI?.apiconfig.changePayload && !currentAPI?.apiconfig.emailOtp && !currentAPI?.apiconfig.mobileOtp)
                obj[currentAPI?.apiconfig['formikObjectFields'][i]] = getValueFromKeyStringAcceptArray(
                  payloadObj,
                  currentAPI?.apiconfig['formikObjectPath'],
                  currentAPI?.apiconfig['formikObjectFields'][i]
                );
            }
          }
          else{

            if(currentAPI?.apiconfig['formikObjectFields'][i] === "employeeId"){
              employeeData[currentAPI?.apiconfig['formikObjectFields'][i]] = (
                getValueFromKeyStringAcceptArray(
                  payloadObj,
                  currentAPI?.apiconfig['formikObjectPath'],
                  currentAPI?.apiconfig['formikObjectFields'][i]
                )
              );
            }else {
              employeeData[currentAPI?.apiconfig['formikObjectFields'][i]] = (
                getValueFromKeyStringAcceptArray(
                  payloadObj,
                  currentAPI?.apiconfig['formikObjectPath'],
                  currentAPI?.apiconfig['formikObjectFields'][i]
                )?.replace(/[^\d]/g, '')
              );
              if(employeeData['SSN'])
              employeeData['employeeId']=employeeData['SSN']
            }      
          }
        }
        currentAPI?.apiconfig.needAppCode ?
          (currentAPI?.apiconfig.needChannel )?
            (obj = { employeeData, ...{ appCode: appCode, channel:'sms' } })    
          :
          (obj = { employeeData, ...{ appCode: appCode } })
          : !currentAPI?.apiconfig.needSingleParameter
          ? !currentAPI?.apiconfig.notNeedLegalEntityId
            ? (obj = { employeeData })
            : (obj = obj)
          : (obj = obj);
      }
    }
    currentAPI?.apiconfig.httpMethod && currentAPI?.apiconfig.httpMethod === 'put'
      ? (result = await http.put(appConfig.SSP_AUTH_URL + `${url}`, obj, {
          withCredentials: true,
          headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200)
          },
        }))
      : 
      (result = await http.post(appConfig.SSP_AUTH_URL + `${url}`, obj, {
          withCredentials: true,
          headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200)
          },
        }));
    return result;
  } catch (error) {
    

    var data = {};
    data.message = error.message;

    if (payloadObj?.values?.EmployeeValidation) {
      let errorCount = add(payloadObj.values.EmployeeValidation);
      if (errorCount) data = { ...data, ...{ errorCount: errorCount } };
    }

    return data;
  }
}
export default {
  getElements,
  getAPIResponse,
  getAPIResponseMFA
};