import http from '../../../../Common/Services/HttpService';
import appConfig from '../../../../appConfig';
import { UPDATE_LOADING , UPDATE_ERROR_MESSAGE, UPDATE_SUCCESS_MESSAGE, SET_ALERT_MESSAGE} from '../../../../Store/Slices/InsuredInfo';
import { UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment';
import { get } from 'lodash';
import { removeUnwantedProps } from '../Helper/MappingHelper';
import resumeContent from '../Content/ResumeContent.json';
import { trimPayload } from '../Helper/commonHelper';

const processResponse = (response, dispatch, editInsured) => {
    const message = editInsured ? "Dependent information has been successfully updated." : "Dependent information has been successfully added.";
    const data = get(response, 'data.data.saveInsured', {});
    dispatch(UPDATE_ENROLLMENT_DATA(data));
    dispatch(UPDATE_ERROR_MESSAGE({ message: null, currentStep: "DependentInformation" }));
    dispatch(UPDATE_SUCCESS_MESSAGE({ message: message, currentStep: "DependentInformation" }));
};

const processError = (dispatch, editInsured) => {
    const message = editInsured ? "Failed to Update the record." : "Failed to save the record.";
    dispatch(UPDATE_ERROR_MESSAGE({ message: message, currentStep: "DependentInformation" }));
    dispatch(UPDATE_SUCCESS_MESSAGE({ message: null, currentStep: "DependentInformation" }));
};

export const createOrUpdateInsured = (dependentInfo, storeFromRedux, loggedUserEntity, editInsured, externalStepContext, currentStep='') => async dispatch => {
    if (!dependentInfo) {
        throw new Error('Invalid argument. One or more arguments are null or undefined.');
    }

    dispatch(UPDATE_LOADING(true));

    try {

        const updatedProcessReduxData = removeUnwantedProps(storeFromRedux, resumeContent.resumeUnwantedProps)

        const response = await http.post(appConfig.SSP_MID_URL + "/v1/dependent-information-save", {
            currentReduxState: {callCenter : storeFromRedux.callCenter, employeeProfile: storeFromRedux.employeeProfile, process:updatedProcessReduxData.process},
            data: { "DependentInformation": trimPayload(dependentInfo) },
            loggedUserEntity,
            externalStepContext
        });

        if (response && response.data) {
            processResponse(response, dispatch, editInsured);
        } else {
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: response?.status || '520',
                id: 'createOrUpdateInsured',
                show: false,
                page: currentStep,
              }));
        }
    } catch (error) {
        const message = editInsured ? "Failed to Update the record." : "Failed to save the record.";
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            message: message,
            id: 'createOrUpdateInsured_error',
            show: false,
            page: currentStep
          }));
        
        processError(dispatch, editInsured);
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
};
