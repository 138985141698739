import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import { apiReceivedUserTypeAndLanguage, SET_AUTHENTICATED_USER, SET_PERFORMANCE_USER } from '../../../Store/Slices/Auth/userAuth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onLoadAPI } from '../../../Store/Slices/Entities/response/index';
import Loader from '../../Atoms/Loader';
import { useSelector } from 'react-redux';
import http from '../../../Common/Services/HttpService/index';
import $ from 'jquery';
import appConfig from '../../../appConfig';
import { SET_MFA_API_PARAM } from '../../../Store/Slices/Registeration';
import { CLEAR_SSN, SET_SSN_VALUE } from '../../../Store/Slices/ControlSSN';
import loginImage from '../../../Assets/Images/login_image.png'; // Directly import the image

const PerformanceLogin = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const { userAuth } = useSelector((storeState) => storeState.auth);
  const [formData, setFormData] = useState({
    username: 'testjohn@maildrop.cc',
    clientId: 'KCOBAA',
    rememberMe: false,
    environment: '',
  });
  const [content, setContent] = useState({
    title: '',
    subtitle: '',
    panelTitle: '',
    welcomeMessage: '',
    welcomeTitle: '',
    banner: {},
    icon: '',
    authUrl: '',
    redirectUrl: '',
    helpLinks: [],
    inputPlaceHolder: '',
    clientIdPlaceHolder: '',
    buttonLable: '',
    rememberMeLable: '',
    titleColor: '',
    msgColor: '',
  });
  const [showLoader, setShowLoader] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [username, setUsername] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [invalidEmailClass, setinvalidEmailClass] = useState('');
  const [invalidLogin, setinvalidLogin] = useState('');
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [logo, setLogo] = useState('');
  const recaptchaErrorMessage = 'Something went wrong during login, try again';
  const [clientLogo, setClientLogo] = useState(false);
  const recaptchaRef = React.useRef({});
  const { clientId } = useSelector((storeState) => storeState.auth.userAuth);
  const { loginConfigInfoLogo, loginConfigInfo } = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList);
  const callLoginConfigAPI = async () => {
    if (!clientId) return
    return await http
      .get(appConfig.SSP_MID_URL + `/v1/config/${clientId}`)
      .then(async (resp) => {
        const data = resp?.data?.data
        for (const key in data) {
          let result = { _Result: data[key].data }
          dispatch(onLoadAPI({ [key]: result }));

        }
        return true;
      })
      .catch((e) => {
        return false;
      });
  };

  useEffect(() => {
    if (!loginConfigInfo) callLoginConfigAPI()
  }, [!loginConfigInfo]);

  useEffect(() => {
    const getLoingConfig = async () => {
      try {
        // const { data: result } = await http.get(
        //   `${appConfig.SSP_MID_URL}/loginConfiguration/${clientId}`
        // );
        const result = loginConfigInfo?._Result
        let contentData = props.content[props.type];
        var clientLogodata = result?.hideLogo;
        setClientLogo(clientLogodata);
        if (contentData) {
          if (result.welcomeTitle) {
            contentData['welcomeTitle'] = result.welcomeTitle;
          }
          if (result.welcomeMsg) {
            contentData['welcomeMessage'] = result.welcomeMsg;
          }
          if (result.titleColor) {
            contentData['titleColor'] = result.titleColor;
          }
          if (result.msgColor) {
            contentData['msgColor'] = result.msgColor;
          }
        }

        setContent(contentData);
      } catch (e) {
        setContent(props.content[props.type]);
      }
    };
    getLoingConfig();
  }, [props.content, loginConfigInfo]);

  useEffect(() => {
    const getLogo = async () => {
      try {
        // const { data: result } = await http.get(
        //   `${appConfig.SSP_MID_URL}/loginConfiguration/logo/${clientId}`
        // );
        const result = loginConfigInfoLogo?._Result
        setLogo(result);
      } catch (e) {

      }
    };
    getLogo();
  }, [loginConfigInfoLogo]);
  dispatch(CLEAR_SSN(true));
  dispatch(SET_SSN_VALUE(''));
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    });
    if (event.target.name == 'username') setUsername(event.target.value);
    const regexEmailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(
      $('#username').val()
    );
    setIsValidEmail(regexEmailFormat);
    setinvalidEmailClass(!regexEmailFormat ? '#A61003' : '#DADADA');
  };
  // get param from uri- mfa
  const GetUrlParams = () => {
    const authResult = new URLSearchParams(window.location.search);
    const legalEntityId = authResult.get('legalEntityId');
    const appCode = authResult.get('appCode');
    let paramObject = {
      legalEntityId: legalEntityId,
      appCode: appCode,
    };
    legalEntityId && appCode && dispatch(SET_MFA_API_PARAM(paramObject))
    return paramObject
  };
  GetUrlParams()


  const handleSubmit = async (event) => {
    event.preventDefault();
    
    http.setHeaderValue('REMOTE_USER', `mfa_${formData.username}`)
    http.setUserType('Enrollment')
    http.setAuthTokenUserType('Performance');
    await dispatch(SET_PERFORMANCE_USER(formData))
    await dispatch(SET_AUTHENTICATED_USER(formData.username));
    // dispatch(SET_MFA_LOGIN({ decryptedEmail: tokenDetail.decrypted, email: tokenDetail.email }));
    await dispatch(apiReceivedUserTypeAndLanguage({ userType: props.type, language: currentLanguage }));
    history.push('/enrollment/home')
  };

  const {
    title,
    subtitle,
    panelTitle,
    welcomeMessage,
    welcomeTitle,
    banner = {},
    icon,
    titleColor,
    msgColor,
  } = content || {};

  useEffect(() => {
    if (userAuthenticated) {
      if (userAuth && userAuth.data && userAuth.data.access_token && !loginError) {
        history.push(content.oldRedirectUrl);
      }
      setShowLoader(false);
      setLoginError(true);
      setUserAuthenticated(false);
    }
  }, [userAuthenticated]);

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  const isDisabled = !(!!formData['username'] && !!formData['clientId'] && !!isValidEmail);

  return (
    // <Banner desktop={banner.desktop} tablet={banner.tablet}>
    <div className="container-fluid" >
      <div className="row">
        <div className="col-lg-7 col-xl-7 d-none d-md-block px-0 ">

          <img
            src={loginImage}
            alt="Four Employees Enrolling"
            className="img-responsive"
            width="100%"
            height="100%"
            id="employees-erolling-client-logo"
          />
          <div className="row overlay-content px-3 mx-5 pt-3 ">
            <div className="col-12">
              <div style={{ color: titleColor ? titleColor : '#0065ac' }} className="bannerTitle">
                {welcomeTitle}
              </div>
            </div>
            <div className="col-12 ">
              <p style={{ color: msgColor ? msgColor : '#202020' }} className="bannerBody">
                {welcomeMessage}
              </p>
            </div>
          </div>
          {/* <Panel
            className="panel d-flex flex-column align-items-center justify-content-between rounded px-3 py-4 "
            title={welcomeTitle}
            body={welcomeMessage}
            // img={icon}
            // hasDivider={false}
          ></Panel> */}
        </div>

        {/* </div> */}
        {/* <div class="col-lg-1 col-xlg-1 col-md-1"></div> */}
        <div className="col-lg-5">
          {' '}
          <form
            // role="form"
            aria-label="form"
            onSubmit={handleSubmit}
            className="offset-xl-1  offset-md-2 offset-lg-0 col-md-8 col-lg-11 d-flex flex-column"
          >
            <div className="row pb-3 px-0 ">
              <div className="col-12 pt-0 pb-3 px-0 ">
                <div className="row text-center ">
                  {!clientLogo && logo && (
                    <div className="col-12 ">
                      <img
                        src={logo ? logo : ""}
                        alt="Client logo"
                        className="img-responsive pt-3 logo-fixed-size"
                        id="client-logo"
                      />
                    </div>
                  )}

                  {title && (
                    <div className="col-12 ">
                      <h3 className="loginHeader ">{title}</h3>
                    </div>
                  )}
                  <div className="col-12 px-0 ">
                    <div className="devider "></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              {' '}
              <div className="col-12   ">
                <h1 className="logInTitle  mb-3 mt-4  ">{subtitle}</h1>
              </div>
              <div className="col-12 ">
                <p className="logInTitleDevider mb-4 "></p>
              </div>
              <div className="col-12 ">
                {/* <span className="inputLable">User ID</span> */}
                <label htmlFor="username" className="inputLable">User ID</label>
                <input
                  id="username"
                  name="username"
                  onChange={handleChange}
                  value={formData.username}
                  placeholder={(content && content.inputPlaceHolder) || ''}
                  className={
                    isValidEmail
                      ? ` w-100 inputField my-3 p-3  `
                      : `w-100 inputField mt-3 mb-1 p-3  `
                  }
                  style={{ borderColor: invalidEmailClass }}
                />
              </div>
              {!isValidEmail && (
                <div className="col-12 mb-3 ">
                  <span style={{ color: invalidLogin || invalidEmailClass }}>
                    {content.emailFormatError}{' '}
                  </span>
                </div>
              )}
              <div className="col-12 ">
                <label htmlFor="clientId" className="inputLable">Client Id</label>
                <input
                  id="clientId"
                  name="clientId"
                  onChange={handleChange}
                  value={formData.clientId}
                  placeholder={(content && content.clientIdPlaceHolder) || ''}
                  className="w-100 inputField my-2 p-3  "
                  type="clientId"
                  style={{ borderColor: invalidLogin }}
                />
              </div>
              {loginError && (
                <div className="col-12 mb-3 ">
                  <span style={{ color: invalidLogin }}>{content.loginError} </span>
                </div>
              )}
            </div>

            <div className="col-12 p-0">
              <button
                role="button"
                className="btn btn-primary-contrast btn-block my-3 loginContainerButton inputHeight"
                type="submit"
                disabled={isDisabled}
              >
                {(content && content.buttonLable) || ''}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
PerformanceLogin.propTypes = {
  type: PropTypes.string,
};

export default PerformanceLogin;