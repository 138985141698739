import React, { useEffect, useState } from 'react';
import IFrameGenerator from '../../../IFrameGenerator';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import { formatter } from '../../../../Atoms/Currency/index';
import './style.css';
import { Button } from '../../../../Atoms';
import { possibleLabelConstants } from '../../Helper/continueBtnLabelHelper';

const IFrameGeneratorFlow7 = (props) => {
    const {
        formName,
        content,
        updateFormData,
        elements,
        treeCard,
        formik,
        isMultiple,
        multiConfig,
        contentData,
        SetContentData,
        changeConfig,
        triConfig,
        listElements,
        iFrameElements,
        currentStep,
        innerFormName,
        hasSections,
        hasCustomSection,
        hasCrossSell,
        deleteRecord = () => {},
        editRecord = () => {},
        onChangeCheckBox = () => {},
        showContent = [],
        displayContent = {},
        prevStep,
        onNext
      } = props;

      let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
      let [displayType, setDisplayMode] = useState('');
      const { tableRow, maxAdds, tableHeader } = multiConfig || {};

      useEffect(() => {
        /* useEffect to decide whether add button has to display or not*/
        if (contentData?.displayMode && contentData?.displayMode[formName])
          setDisplayMode(contentData?.displayMode[formName]);
      }, [contentData?.displayMode, formName]);

      const tableRowMapper = (data, rows = [], columns = []) => {
        const definedColumns = columns.length > 0 ? true : false;
        const tableRowsData = rows.length > 0 ? rows : tableRow;
        const filteredRows = [];
        if (definedColumns) {
          tableRowsData.forEach((rowData) => {
            if (!!find(columns, { id: rowData.id })) filteredRows.push(rowData);
          });
        }
        /* function to form table using properties from form josn*/
        let editedtableRow = [];
        const rowsData = definedColumns ? filteredRows : tableRowsData;
        rowsData &&
          rowsData.forEach((item) => {
            if (item.type === 'expression') {
              let { optionsname = '', className = '', valueType = '', format = '' } = item.config || {};
              let displayValue = eval(item.value);
              if (optionsname && constantsList[optionsname]) {
                let obj = constantsList[optionsname].find((o) => o.value === displayValue);
                if (obj) displayValue = obj['text'];
              }
    
              // editedtableRow.push(displayValue);
              if (item.valuetype === 'currency') {
                displayValue = formatter(item.locale, item.currency).format(displayValue);
              }
              editedtableRow.push({
                className: className,
                value: displayValue,
                valueType: valueType,
                format: format,
              });
            } else if (item.type === 'email') {
              let { className = '' } = item.config || {};
              // editedtableRow.push( <a href={`mailto:${data['email']}`}>{data['email']}</a> );
              editedtableRow.push({
                className: className,
                value: <a href={`mailto:${data['email']}`}>{data['email']}</a>,
              });
            } else if (
              item.type === 'text' ||
              item.type === 'checkBox' ||
              item.type === 'select' ||
              item.type === 'readOnly'
            ) {
              let displayValue = '';
              if (item.hasExpression) {
                let { optionsname = '' } = item.config || {};
                displayValue = eval(item.value);
                if (optionsname && constantsList[optionsname]) {
                  let obj = constantsList[optionsname].find((o) => o.value === displayValue);
                  if (obj) displayValue = obj['text'];
                }
    
                if (item.valuetype === 'currency') {
                  displayValue = formatter(item.locale, item.currency).format(displayValue);
                }
              }
              editedtableRow.push({
                ...item,
                value: displayValue,
                valueType: item.type,
              });
            }
          });
        return editedtableRow;
      };

    return (
      <>
        <div className='iframePrntCls'>
            <IFrameGenerator
                // allFormData={formik.values[formName]}
                constantsList={constantsList}
                editRecord={editRecord}
                deleteRecord={deleteRecord}
                onChangeCheckBox={onChangeCheckBox}
                elements={elements}
                iFrameElements={iFrameElements}
                // formMode={displayType}
                maxAdds={maxAdds}
                name={formName}
                tableHeaders={tableHeader}
                tableRowMapper={tableRowMapper}
                updateFormData={updateFormData}
                {...props}
            />
        </div>
        <div className='button-group'>

        <>
          <Button type="button" onClick={prevStep} className="mr-3">
            Back
          </Button>
        </>


        < Button type="button" onClick={onNext}
          className="TestCls"
        >
          {possibleLabelConstants.CONTINUE_TO_SUMMARY}
        </Button>

      </div>
      </>
    );
};

export default IFrameGeneratorFlow7;