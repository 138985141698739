import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Linker } from '../../Atoms';
// import './styles.css';
import { Styles } from './styles';
import { Button } from '../../Atoms';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import Elementor from '../../Cells/Form/Elementor';
import { useSelector, useDispatch } from 'react-redux';
import { reduxConstants } from '../../../Common/Constants/index';
import { getValueFromKeyStringAcceptArray } from '../../Utils/Helper/GetValueFromKeyStringAcceptArray';
import { SET_REDIRECT_TO_CLIENT_SEARCH, IS_HR_CENTER_RE_DIRECTED } from '../../../Store/Slices/CallCenter';
import { apiReceivedMFA, apiReceivedUserTypeAndLanguage, processTypeForSSO, processTypeMFA, RESET_EMP_FILTER, setProcessType, setSSOLoginError, START_PROXY_ENROLL, userTypeMFA } from '../../../Store/Slices/Auth/userAuth';
import { SET_CALL_CENTER_USER, SET_REDIRECT_TO_ENROLLMENT } from '../../../Store/Slices/CallCenter';
// import imageMap from '../../CustomSection/Helper/imageHelper/imageMap';
import imageMap from '../CustomSection/Helper/imageHelper';
import { getFileName } from '../CustomSection/Helper/commonHelper';


const SectionHeader =
  (props) => {
    const {
      title = '',
      rightLink = '',
      breadcrumbs,
      breadcrumbsStepForm = [],
      current,
      sectionHeaderButtons = [],
      sectionElements = [],
      leftColumnClass = '',
      rightColumnClass = '',
      showContent = [],
      mappingObject = {},
      config = {},
      filename,
      specialHeaderWidth,
      className,
      modifyPadding,
      hideLink,
      ...rest
    } = props;

    let history = useHistory();
    let dispatch = useDispatch();

    let username;
    let loggedUserEntity =
      useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.uiPath)) || {};
    const { userAuth } = useSelector((storeState) => storeState?.auth);
    const [initialLoad, setInitialLoad] = useState(false);
    const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
    const currentStep = useSelector((storeState) => storeState?.stepForm?.currentStep);
    const isAdmin = useSelector((storeState) => storeState?.callCenter?.isAdmin);
    let isProxy = false
    if (userAuth?.processType === "proxyEnrollment") {
      isProxy = true
    }
    const isHrCenterReDirected = useSelector((storeState) => storeState?.callCenter?.isHrCenterReDirected);
    const isHrCenter = (isHrCenterReDirected) ? true : false
    // determines the left and right column classes
    const leftClassContainer = leftColumnClass ? leftColumnClass : specialHeaderWidth ? 'col-sm-6 col-lg-6 col-md-12' : 'col-sm-6 col-lg-6';
    const rightClassContainer = rightColumnClass ? rightColumnClass : (isAdmin || isProxy) ? 'col-lg-6 col-md-8 col-sm-12 py-2 col-12 justify-content-end' : 'col-sm-6 col-lg-6 align-self-center text-sm-right';
    // given an array of button configs renders the buttons based on their configurations
    let sectionConfig;
    if (sectionHeaderButtons) {
      sectionConfig = sectionHeaderButtons.map((config, index) => {
        let modalShowHide = () => {
          props.setModelSubmitSuccess && props.setModelSubmitSuccess(false);
          config.target && $("#" + config.target).modal('show');
        }
        return (
          <Button
            key={index}
            stpClick={props.stpClick == false ? false : true}
            rounded
            type="submit"
            data-target={'#' + config.target}
            data-toggle={config.toggle}
            onClick={config.target ? modalShowHide : () => {
              config.toggle ? history.push('#') : history.push(config.url);
            }}
          >
            {config.icon && (
              <img src={imageMap[getFileName(config.icon)]} className="pr-2" alt="" />
            )}
            {config.label}
            {config.carat && <span class="caret"></span>}
          </Button>
        );
      });
    }

    if (sectionElements) {
      sectionConfig = sectionElements.map((element) => {
        return showContent.includes(element.id) || showContent.length === 0 ? (
          <Elementor
            key={element.name}
            element={element}
            showContent={showContent}
            {...props}
          />
        ) : null;
      });
    }

    const links = breadcrumbs.map((breadcrumb, index) => {
      return (
        <li key={index}>
          <Linker to={breadcrumb.to}>{breadcrumb.text}</Linker>
          <span> / </span>
        </li>
      );
    });

    links.push(<li key={-1}>{current}</li>);
    const callCenterEmployeeSearch = (
      (userAuth?.processType === "callCenter" && userAuth?.currentProcessType === "SearchEmployee") ||
      ((userAuth?.processType === "Configurator" || userAuth?.userType === "Configurator") && userAuth?.configuratorSSOLogin))
    const callCenterEmployeeData = userAuth?.processType === "callCenter" && userAuth?.currentProcessType === "EmployeeData"
    callCenterEmployeeSearch && dispatch(SET_REDIRECT_TO_CLIENT_SEARCH(true));
    callCenterEmployeeData && dispatch(RESET_EMP_FILTER(true))

    const breadcrumbsLinks = breadcrumbsStepForm.map((breadcrumb, index) => {
      return (
        <li key={index}>
          {(callCenterEmployeeSearch || callCenterEmployeeData) ? <Linker to={userAuth.currentURL}>{breadcrumb.text}</Linker> : <Linker to={breadcrumb.to}>{breadcrumb.text}</Linker>}
        </li>
      );
    });

    breadcrumbsLinks.push(<li key={-1}>{current}</li>);

    let namesArray = [];
    /** Function to get logged username (first, last) from redux, store names in namesArray */
    const getUsername = () => {
      const fieldsLength = mappingObject?.reduxObjectFields?.length;

      for (let i = 0; i < fieldsLength; i++) {
        namesArray[i] = getValueFromKeyStringAcceptArray(loggedUserEntity, mappingObject?.reduxObjectPath, mappingObject?.reduxObjectFields[i])
      }
    };
    getUsername();
    // const onlyFileName = filename?.substring(0, filename?.lastIndexOf("."));
    // 
    // let imgSrc;
    let imgSrc = imageMap[getFileName(filename)] || '';
    try {
      imgSrc = imgSrc;
    } catch (e) {
      imgSrc = '';
    };

    //Back to call center Home Page
    const handleBackToCallCenterHome = () => {
      dispatch({
        type: 'SETINITIALSTATE'
      })
      dispatch({
        type: 'RESET'
      })
      dispatch(IS_HR_CENTER_RE_DIRECTED(false))
      history.push(userAuth?.redirectUrl);
    };
    return (
      <React.Fragment>
        {!isLoading &&
          <>
            <Styles />
            <div className={modifyPadding ? "w-100  px-3  border-bottom sectionHeader " : " w-100  px-3 px-md-5 border-bottom sectionHeader"} style={config.style} role="region" {...rest}>
              <div className="row">
                <div className={leftClassContainer}>
                  {/*<div className="col-sm-12 col-lg-6">*/}
                  {/* {filename && (
                <img
                  src={imgSrc}
                  className=""
                  alt="settingIcon"
                />
              )} */}
                  {filename && links && (
                    <ul className="breadcrumbs mb-0 pt-2 px-md-0 px-1 ">
                      <li> <img
                        src={imgSrc}
                        className="pr-2"
                        alt="Setting Icon"
                      /></li>
                      {links}

                    </ul>
                  )}
                  {!filename && breadcrumbsStepForm.length > 0 && breadcrumbsLinks && (
                    <ul className="breadcrumbs mb-0 pt-2 px-md-0 px-1 ">

                      {breadcrumbsLinks}

                    </ul>
                  )}

                  <h1 className={`${className}   title`}>{
                    !userAuth.SSOLoginError ? //default value
                      title :
                      "Access denied : User information is incorrect"
                  }{' '}{namesArray[0]}</h1>
                </div>
                {/* <div className={rightClassContainer}> */}
                <div className="col-lg-6 col-md-8 col-sm-12 py-2 col-12 justify-content-end ">

                  {/*<div className="col-sm-12 col-lg-6 align-self-center text-sm-right">*/}
                  {/*  add link here  */}
                  <div className="row" style={{ display: hideLink ? 'none' : 'block' }} >
                    <div id="callCenterHome"
                      className="align-items-center d-flex offset-10 col-2 mr-0 pr-0 float-right mb-0 px-md-0 px-1">
                      {(isAdmin || isProxy) && <button type="button" class="btn btn-link" onClick={handleBackToCallCenterHome}>
                        {isHrCenter ? "HRC Home" : rightLink}
                      </button>}
                    </div>
                  </div>
                  <div className="row" style={{ float: 'right' }}>
                    <div className="align-items-center d-flex pt-2">{sectionConfig}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </React.Fragment>
    );
  };
SectionHeader.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({ to: PropTypes.string, text: PropTypes.string })),
  current: PropTypes.string,
  showContent: PropTypes.array,
};
SectionHeader.defaultProps = {
  breadcrumbs: [],
  showContent: [],
};

export default SectionHeader;
