import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import { Table, Icon, RadioButton, Checkbox, Input, Select, Button } from '../../Atoms';
import $ from 'jquery';
import { get, compact } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOADER } from '../../../Store/Slices/Loader/index';
import {
  RADIO_SELECTED_VALUE,
  RADIO_SELECTED_VALUE_ID_THEFT,
  RADIO_SELECTED_VALUE_SUPPLEMENT_HEALTH_INSURANCE,
  RADIO_SELECTED_VALUE_LEGAL_COVERAGE,
  RADIO_SELECTED_VALUE_TRAVEL,
  RADIO_SELECTED_VALUE_PET_ASSURE,
  RADIO_SELECTED_VALUE_ID_PETPLUS,
  RADIO_SELECTED_VALUE_CRITICAL_ILLNESS,
  RADIO_SELECTED_VALUE_HOSPITAL_INDEMNITY,
  UPDATE_DEPENDENT_BENEFICIARY_INFORMATION,
  UPDATE_BENEFICIARY_ERROR,
  RADIO_SELECTED_VALUE_EXCESS_LIABILITY,
  RADIO_SELECTED_VALUE_EXCESS_LIABILITY_UM_UIM,
  UPDATE_CART_ITEM,
} from '../../../Store/Slices/EmployeeProfile';
import { CHANGE_BUTTON_WIDTH } from '../../../Store/Slices/DisableButton';
import ServiceHelper from '../../Utils/Helper/MappingHelper';
import { unionBy } from 'lodash';

const SaveButton = (props) => {
  const {
    className,
    onClick = () => {},
  } = props;
  return (
    <Button
      id={'saveBtn-changes'}
      onClick={onClick}
      // onChange={handleRowCheck}
      className={className}
      value={"Save Changes"}
      label={"Save Changes"}
      content={'Save Changes'}
  />
  )
}

const TableWrapper = (props) => {
  const {
    className,
    actionsStyle,
    actionsClassName,
    columns,
    dataKey,
    content,
    editRecord,
    deleteRecord,
    onChangeCheckBox = () => {},
    element,
    formName,
    formik,
    hasActions,
    noAdditionalAction,
    hasSelectOption,
    hasWaiveCoverage,
    defaultCollapse,
    hideCollpase,
    mobileColumns,
    mobileRows = [],
    mobileClassName,
    mobilePanel,
    multiConfig = {},
    name,
    hideElement,
    tableRowMapper,
    actionsHeader = '',
    mobileActionsHeader = '',
    editKey = '',
    editModal = '',
    layoutData = {},
    layoutDataMobile = {},
    tableData = '',
    tableExtraData = '',
    hasRadioButton,
    constantsList,
    mobileTableHeaderClass = '',
    showHeaderMobileAction = true,
    showMobileArrow = true,
    backgroundColor,
    showTable = true,
    // tableProps,
    // multipleTablesInCurrentStep,
    // tablesArr
  } = props;
  const { tableHeader, tableHeaderStyle, tableRow } = multiConfig;
  // if (multipleTablesInCurrentStep && element.id !== null && tableProps)
  // {
  //   tableHeader = tableProps[element.id].tableHeader
  //   tableHeaderStyle = tableProps[element.id].tableHeaderStyle
  //   tableRow = tableProps[element.id].tableRow
  // }


 
  const serviceHelper = new ServiceHelper();
  let tableInformation = layoutData[tableData] || [];

  let tableExtraInformation = layoutData[tableExtraData] || [];
  let containsDependent = false;
  let containsBeneficiary = false;
  if ( dataKey === 'beneficiariesTable' ) {
    const hasBeneficiary = tableExtraInformation.find(tableExtraInfo => tableExtraInfo.isDependent === false);
    tableExtraInformation = tableExtraInformation.filter(tableExtraInfo => tableExtraInfo.isDependent === true);
    const hasDependent = tableExtraInformation.find(tableExtraInfo => tableExtraInfo.isDependent === true);
    containsDependent = hasDependent ? true : false;
    containsBeneficiary = hasBeneficiary ? true : false;
  }

  const tableId = (element && element.tableId) || '';

  //Code checking11
  // hasWaiveCoverage &&
  //   (tableInformation = [[], []])
  const [saveChanges, setSaveChanges] = useState(false);
  const [open, setOpenCollapse] = useState([]);
  const [temporaryValues, setTemporaryValues] = useState([]);
  const [optionSelected, setOptionSelected] = useState(-1);
  const [optionSelectedUmUim, setOptionSelectedUmUim] = useState(-1);

  const [primaryError, setPrimaryError] = useState(false);
  const [contingentError, setContingentError] = useState(false);
  const beneficiaryError = useSelector(
    (storeState) => storeState?.employeeProfile?.beneficiaryError
  );

  const radioSelectedValue = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValue
  );

  const radioSelectedValueIDTheft = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueIDTheft
  );
  const radioSelectedValueSupplementalHealthInsurance = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueSupplementalHealthInsurance
  );

  const radioSelectedValueLegalCoverage = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueLegalCoverage
  );

  const radioSelectedValueTravel = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueTravel
  );
  const radioSelectedValuePetPlus = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValuePetPlus
  );
  const radioSelectedValuePetAssure = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValuePetAssure
  );
  const radioSelectedValueCriticalIllness = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueCriticalIllness
  );
  const radioSelectedValueHospitalIndemnity = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueHospitalIndemnity
  );
  const radioSelectedValueExcessLiability = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiability
  );
  const radioSelectedValueExcessLiabilityUmUim = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiabilityUmUim
  );
  // const currentStep= useSelector((storeState) => storeState.stepForm.currentStep);

  let additionalClasses = hideElement ? 'hideElement' : ``;

  // Function to open or close collapse inside of Mobile Table
  const openCollapse = (tableKey) => {
    $(`#collapse-${tableKey}`).toggle();
    const openArray = [];
    let filteredItems = open;
    if (!$(`#collapse-${tableKey}`).is(':hidden')) {
      openArray.push(`collapse-${tableKey}`);
    } else {
      if (open.includes(`collapse-${tableKey}`)) {
        filteredItems = filteredItems.filter(function (item) {
          return item !== `collapse-${tableKey}`;
        });
      }
    }
    setOpenCollapse([...openArray, ...filteredItems]);
  };
  const currentStep = useSelector((storeState) => storeState.stepForm.currentStep);
  
  const isCheckbox =
    useSelector((storeState) => storeState.disableButton[currentStep]?.isCheckbox) || false;

  const {
    dependentsCoverageList,
    dependentsIDTheftCoverageList,
    dependentsSupplementalHealthInsuranceCoverageList,
    dependentsCriticalIllnessCoverageList,
    dependentsLegalCoverageList,
    dependentsPetAssureList,
    dependentsTravelCoverageList,
    dependentsPetPlusList,
    dependentsHospitalIndemnityCoverageList,
    dependentsExcessLiabilityCoverageList,
    dependentsExcessLiabilityCoverageListUmUim,
  } = useSelector((storeState) => storeState.employeeProfile);
  const dispatch = useDispatch();

  let isUmUimCoverageTable = element?.isUmUimCoverageTable;
  useEffect(() => {
    if (currentStep == 'CoverageSelection' &&  radioSelectedValue !== '' && true) {
      setOptionSelected(radioSelectedValue);

    } 
    else if (currentStep == 'CriticalIllnessGeneral' && radioSelectedValueIDTheft !== '') {
      setOptionSelected(radioSelectedValueIDTheft);
    } else if (currentStep == 'IdTheft' && radioSelectedValueIDTheft !== '') {
      setOptionSelected(radioSelectedValueIDTheft);
    } else if (currentStep == 'SupplementalHealthInsurance' && radioSelectedValueSupplementalHealthInsurance !== '') {
      setOptionSelected(radioSelectedValueSupplementalHealthInsurance);
    } else if (currentStep == 'LegalCoverage' && radioSelectedValueLegalCoverage !== '') {
      setOptionSelected(radioSelectedValueLegalCoverage);
    } else if (currentStep == 'Travel' && radioSelectedValueTravel !== '') {
      setOptionSelected(radioSelectedValueTravel);
    } else if (currentStep == 'PetAssure' && radioSelectedValuePetAssure !== '') {
      setOptionSelected(radioSelectedValuePetAssure);
    } else if (currentStep == 'PetPlus' && radioSelectedValuePetPlus !== '') {
      setOptionSelected(radioSelectedValuePetPlus);
    } else if (currentStep == 'CriticalIllness' && radioSelectedValueCriticalIllness !== '') {
      setOptionSelected(radioSelectedValueCriticalIllness);
    } else if (currentStep == 'HospitalIndemnity' && radioSelectedValueHospitalIndemnity !== '') {
      setOptionSelected(radioSelectedValueHospitalIndemnity);
    } else if (
      currentStep == 'ExcessLiability' &&
      radioSelectedValueExcessLiability !== '' &&
      !isUmUimCoverageTable
    ) {
      setOptionSelected(radioSelectedValueExcessLiability);
    } else if (
      currentStep == 'ExcessLiability' &&
      radioSelectedValueExcessLiabilityUmUim !== '' &&
      isUmUimCoverageTable
    ) {
      // setOptionSelectedUmUim(radioSelectedValueExcessLiabilityUmUim)
      setOptionSelected(radioSelectedValueExcessLiabilityUmUim);
    } else {
      setOptionSelected(optionSelected);
    }
  }, []);

  useEffect(() => {
    if (currentStep == 'CoverageSelection') {
      dispatch(RADIO_SELECTED_VALUE(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.accidentInsurance,
          radioSelectedValue: optionSelected,
          coverageList: dependentsCoverageList,
        })
      );
    }

    if (currentStep == 'CriticalIllnessGeneral') {
      dispatch(RADIO_SELECTED_VALUE(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.accidentInsurance,
          radioSelectedValue: optionSelected,
          coverageList: dependentsCoverageList,
        })
      );
    }

    if (currentStep == 'IdTheft') {
      dispatch(RADIO_SELECTED_VALUE_ID_THEFT(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.accidentInsurance,
          radioSelectedValue: optionSelected,
          coverageList: dependentsIDTheftCoverageList,
        })
      );
    }
    if (currentStep == 'SupplementalHealthInsurance') {
      dispatch(RADIO_SELECTED_VALUE_SUPPLEMENT_HEALTH_INSURANCE(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.accidentInsurance,
          radioSelectedValue: optionSelected,
          coverageList: dependentsSupplementalHealthInsuranceCoverageList,
        })
      );
    }
    if (currentStep == 'LegalCoverage') {
      dispatch(RADIO_SELECTED_VALUE_LEGAL_COVERAGE(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.accidentInsurance,
          radioSelectedValue: optionSelected,
          coverageList: dependentsLegalCoverageList,
        })
      );
    }
    if (currentStep == 'Travel') {
      dispatch(RADIO_SELECTED_VALUE_TRAVEL(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.accidentInsurance,
          radioSelectedValue: optionSelected,
          coverageList: dependentsTravelCoverageList,
        })
      );
    }
    if (currentStep == 'PetPlus') {
      dispatch(RADIO_SELECTED_VALUE_ID_PETPLUS(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.PetPlus,
          radioSelectedValue: optionSelected,
          coverageList: dependentsPetPlusList,
        })
      );
    }
    if (currentStep == 'PetAssure') {
      dispatch(RADIO_SELECTED_VALUE_PET_ASSURE(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.petAssure,
          radioSelectedValue: optionSelected,
          coverageList: dependentsPetAssureList,
        })
      );
    }
    if (currentStep == 'CriticalIllness') {
      dispatch(RADIO_SELECTED_VALUE_CRITICAL_ILLNESS(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.accidentInsurance,
          radioSelectedValue: optionSelected,
          coverageList: dependentsCriticalIllnessCoverageList,
        })
      );
    }

    if (currentStep == 'HospitalIndemnity') {
      dispatch(RADIO_SELECTED_VALUE_HOSPITAL_INDEMNITY(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.hospitalIndemnity,
          radioSelectedValue: optionSelected,
          coverageList: dependentsHospitalIndemnityCoverageList,
        })
      );
    }
    if (currentStep == 'ExcessLiability' && !isUmUimCoverageTable) {
      dispatch(RADIO_SELECTED_VALUE_EXCESS_LIABILITY(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.excessLiability,
          excessLiability: "ExcessLiability",
          radioSelectedValue: optionSelected,
          coverageList: dependentsExcessLiabilityCoverageList,
        })
      );
    }
    if (currentStep == 'ExcessLiability' && isUmUimCoverageTable) {
      dispatch(RADIO_SELECTED_VALUE_EXCESS_LIABILITY_UM_UIM(optionSelected));
      dispatch(
        UPDATE_CART_ITEM({
          id: props.currentStep,
          name: props.formData?.excessLiability,
          radioSelectedValue: optionSelected,
          excessLiability: "ExcessLiabilityUim",
          coverageList: dependentsExcessLiabilityCoverageListUmUim,
        })
      );
    }
  }, [optionSelected, optionSelectedUmUim]);

  // Code to validate percentages in beneficiary
  useEffect(() => {
    if (temporaryValues.length > 0 && tableId === 'beneficiaryTable') {
      const selectedValues = temporaryValues.filter((value) => value.beneficiarySelected === true);
      const beneficiaryTypes = selectedValues.reduce((group, product) => {
        const { beneficiaryType } = product;
        group[beneficiaryType] = group[beneficiaryType] ?? [];
        group[beneficiaryType].push(product);
        return group;
      }, {});
      let primaryError = false;
      let contingentError = false;
      Object.keys(beneficiaryTypes).forEach((type) => {
        let totalType = 0;
        beneficiaryTypes[type].forEach((item) => {
          const percentage = item && item.beneficiaryPercentage ? parseInt(item.beneficiaryPercentage) : 0;
          totalType = totalType + percentage;
        });
        if ((totalType > 100 || totalType < 100) && type === 'Primary') {
          primaryError = true;
        } else if ((totalType > 100 || totalType < 100) && type === 'Contingent') {
          contingentError = true;
        }
      });
      setPrimaryError(primaryError);
      setContingentError(contingentError);
      if (primaryError || contingentError) {
        dispatch(UPDATE_BENEFICIARY_ERROR(true));
      } else {
        dispatch(UPDATE_BENEFICIARY_ERROR(false));
      }
    }
  }, [temporaryValues]);

  const getHeaderData = (headerArray = [], columns) => {
    const newHeaderArray = headerArray.map((header) => {
      if (header && header.id && columns.includes(header.id)) return header;
    });
    return newHeaderArray.filter((e) => e) || [];
  };

  const createHeader = (
    headerArray,
    actionsTitle = '',
    hasMobilePanel = false,
    showMobileAction = true
  ) => {
    let headersWithAction = [];
    if (hasMobilePanel) {
      headersWithAction = [
        {
          value: '',
          config: {
            className: '',
            style: actionsStyle,
          },
        },
      ];
    }
    if ((dataKey === 'beneficiariesTable' && !containsDependent) || (dataKey === 'beneficiariesTable' && containsDependent && containsBeneficiary)) {
      const selectColumn = [
        {
          value: '',
          config: {
            className: '',
            style: actionsStyle,
          },
        },
      ];
      headersWithAction = [...headersWithAction, ...selectColumn];
    }
    if (tableInformation.length > 0) {
      headersWithAction = [...headersWithAction, ...headerArray];
    } else {
      headersWithAction = [...headerArray];
    }
    if (showMobileAction) {
      if (hasActions && tableInformation.length > 0) {
        headersWithAction = [
          ...headersWithAction,
          {
            value: actionsTitle,
            config: {
              className: 'd-none d-sm-table-cell  col-lg-1',
              style: actionsStyle,
            },
          },
        ];
      } else if (noAdditionalAction) {
      } else {
        headersWithAction = [
          {
            value: '',
            config: {
              className: 'd-none d-sm-table-cell',
              style: actionsStyle,
            },
          },
          ...headersWithAction,
        ];
      }
    }
    return compact(headersWithAction);
  };
  let edit=true
  if(currentStep=='PetAssureNation'){
    edit=false
  }
  const renderExtraInformation = (tableExtraInformation = [], tableRow, showActions = true) => {
    let tableExtraRows = [];
    if (tableExtraInformation.length > 0) {
      tableExtraRows = tableExtraInformation.map((tableInfo, tableKey) => {
        let row = tableRowMapper(tableInfo, tableRow);
        if (hasActions && tableInformation.length > 0 && showActions) {

          row.push({
            className: 'd-none d-sm-table-cell',
            value: <span></span>,
          });
        }
        return row;
      });
    }
    return tableExtraRows;
  };
  let deletedRowIndex = -1;

  const renderTable = () => {
    const tableHeaderArray = getHeaderData(tableHeader, columns);
    let optionalHeader = hasActions ? actionsHeader : '';
    const headers = createHeader(tableHeaderArray, optionalHeader);
    if (
      (formik?.touched[name] && Object.keys(formik?.touched[name]).length > 0) ||
      formName !== undefined ||
      formik == undefined
    ) {
      let tableRows = tableInformation.map((tableInfo, tableKey) => {
        let row = tableRowMapper(tableInfo, tableRow);
        if (hasActions) {
          const extraClass = tableId === 'beneficiaryTable' ? 'align-middle' : '';
          row.push({
            className: `d-none d-sm-table-cell ${extraClass}`,
            value: (
              <span>
                 {edit && <Icon
                  icon={['fas', 'pen']}
                  onClick={() =>
                    editRecord(
                      tableInfo.id,
                      tableInfo.mainBuid,
                      formName,
                      formik,
                      tableInfo.data,
                      editModal
                    )
                  }
                  className="action mr-3"
                />}
                <Icon
                  icon={['fas', 'trash-alt']}
                  // onClick={() => deleteRecord(tableInfo.mainBuid, formName, formik)}
                  onClick={() => {
                 
                    if (currentStep == 'PetAssureNation') {
                      $('#myModal').modal('show');
                      deletedRowIndex = tableKey;
                      var my_id_value = $(this).data(tableInfo.mainBuid, formName, formik);
                      $('.modal-body #hiddenValue').val(my_id_value);
                      // return(<ConfirmModal data-toggle="modal" data-target="#myModal" />)
                    } else {
                      deleteRecord(tableInfo.mainBuid, formName, formik);
                    }
                  }}
                  className="action"
                />
              </span>
            ),
          });
        }

        if (hasRadioButton) {
          row = [
            {
              className:
                currentStep == 'PetAssure' ||
                currentStep == 'PetPlus' ||
                currentStep == 'LegalCoverage' ||
                currentStep == 'ExcessLiability'
                  ? 'd-none d-sm-table-cell pet-table-allign'
                  : 'd-none d-sm-table-cell',
              value: (
                <span>
                  <input
                    id={tableKey}
                    name={row.value}
                    value={row.value}
                    className="form-check-radio"
                    type="radio"
                    checked={optionSelected == tableKey}
                    onChange={() => {
                      setOptionSelected(tableKey);

                      let waiveObject = {
                        status: false,
                        waiveType: 'radio',
                        isRadio: false,
                        isCheckbox: isCheckbox,
                        isUmUimCoverageTable: isUmUimCoverageTable,
                      };
                      dispatch(CHANGE_BUTTON_WIDTH({ currentStep: currentStep, waiveObject }));
                    }}
                  />
                </span>
              ),
            },
            ...row,
          ];
        }
        return row;
      }
  
      );


      if (tableExtraInformation.length > 0) {
        const tableExtraRows = renderExtraInformation(tableExtraInformation, tableRow);
        tableRows = [...tableRows, ...tableExtraRows];
      }

      tableRows = (tableRows.length > 0 && tableRows.filter((value) => value)) || [];
      const tableData = [...tableInformation, ...tableExtraInformation];
      return (
        tableRows.length > 0 && (
          <>
            <div className="member-container">
              <div id="myModal" className="modal" tabIndex="-1" data-id="my_id_value">
                <div className="modal-dialog deleteConfirmDialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title">Alert!</div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          $('#myModal').modal('hide');
                          // dispatch(CLEAR_REGISTERATION({ email: '', phone: '' }));
                          // history.push('/userRegistration');
                        }}
                      >
                        X
                      </button>
                    </div>
                    <div className="modal-body py-3">
                      <p>Are you sure you want to delete the pet?</p>
                    </div>
                    <div className="modal-footer">
                      <Button
                        // className="w-100 "
                        outline
                        type="submit"
                        // data-target={'#' + '/userRegistration'}
                        // data-toggle={'/userRegistration'}
                        onClick={() => {
                          $('#myModal').modal('hide');
                          // dispatch(CLEAR_REGISTERATION({ email: '', phone: '' }));
                          // history.push('/userRegistration');
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        // className="w-100 "
                        // outline
                        type="submit"
                        // data-target={'#' + '/userRegistration'}
                        // data-toggle={'/userRegistration'}
                        onClick={
                          () => {
                            deleteRecord(
                              tableInformation[deletedRowIndex]?.mainBuid,
                              formName,
                              formik
                            );

                            $('#myModal').modal('hide');
                          }
                          // dispatch(CLEAR_REGISTERATION({ email: '', phone: '' }));
                          // history.push('/userRegistration');
                        }
                      >
                        Yes
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Table
              headers={headers}
              className={'table-mobile-border'}
              hideElement={hideElement}
              body={tableRows}
              tableDataInfo={tableData}
              tableHeaderStyle={tableHeaderStyle}
              classNameWrapper={(element && element.className) || className || ''}
              hasWaiveCoverage={hasWaiveCoverage}
              setOptionSelected={setOptionSelected}
              optionSelected={optionSelected}
              optionSelectedUmUiM={optionSelectedUmUim}
              isUmUimCoverageTable={isUmUimCoverageTable}
              setOptionSelectedUmUim={setOptionSelectedUmUim}
              currentStep={currentStep}
              formik={formik}
              constantsList={constantsList}
              tableId={tableId}
              onChangeCheckBox={onChangeCheckBox}
              temporaryValues={temporaryValues}
              setTemporaryValues={setTemporaryValues}
              backgroundColor={backgroundColor}
              setSaveChanges={setSaveChanges}
            />
            { tableId === 'beneficiaryTable' && saveChanges && (
              <SaveButton
                className={'mx-3 mb-2 mt-3'}
                saveChanges={saveChanges}
                onClick={() => {
                  dispatch(SHOW_LOADER(true));
                  dispatch(UPDATE_DEPENDENT_BENEFICIARY_INFORMATION(temporaryValues));
                  setTimeout(() => {
                    dispatch(SHOW_LOADER(false));
                  }, 35000);
                  setSaveChanges(false);
                }}
              />
            )}
            {beneficiaryError && tableId === 'beneficiaryTable' && (
              <div>
                <span
                  style={{
                    color: '#f44336',
                    fontFamily: 'NotoSansRegular',
                    fontSize: '0.75',
                    paddingLeft: '1rem',
                  }}
                >
                  The total Primary Beneficiary or Contingent Beneficiary should be 100% to
                  continue.
                </span>
              </div>
            )}
          </>
        )
      );
    }
  };

  const renderMobileTable = () => {
    const tableHeaderArray = getHeaderData(tableHeader, mobileColumns);
    const mobileColumnsArray = mobileRows.length > 0 ? mobileRows : mobileColumns;
    const tableRowsArray = getHeaderData(tableHeader, mobileColumnsArray);
    const tableCollapseHeaderArray = getHeaderData(tableHeader, mobilePanel);


    const headers = createHeader(
      tableHeaderArray,
      mobileActionsHeader,
      mobilePanel.length > 0,
      showHeaderMobileAction
    );
    const tableHeaderClass = mobileTableHeaderClass
      ? mobileTableHeaderClass
      : 'd-flex flex-wrap py-4 headerRadius';
    if (
      (formik?.touched[name] && Object.keys(formik?.touched[name]).length > 0) ||
      formName !== undefined ||
      formik == undefined
    ) {
      let tableRows = tableInformation.map((tableInfo, tableKey) => {
        const row = [];
        const collapseRow = [];
        row.push(...tableRowMapper(tableInfo, tableRow, tableHeaderArray));
        if (hasActions) {
          row.push({
            className: 'd-none d-sm-table-cell',
            value: (
              <span>
              {  edit &&  <Icon
                  icon={['fas', 'pen']}
                  onClick={() =>
                    editRecord(
                      tableInfo.id,
                      tableInfo.mainBuid,
                      formName,
                      formik,
                      tableInfo.data,
                      editModal
                    )
                  }
                  className="action mr-2"
                />}
                <Icon
                  icon={['fas', 'trash-alt']}
                  // onClick={() => deleteRecord(tableInfo.mainBuid, formName, formik)}

                  onClick={() => {
                  
                    if (currentStep == 'PetAssureNation') {
                      $('#myModal').modal('show');
                      deletedRowIndex = tableKey;
                      var my_id_value = $(this).data(tableInfo.mainBuid, formName, formik);
                      $('.modal-body #hiddenValue').val(my_id_value);
                      // return(<ConfirmModal data-toggle="modal" data-target="#myModal" />)
                    } else {
                      deleteRecord(tableInfo.mainBuid, formName, formik);
                    }
                  }}
                  
                  className="action"
                />
              </span>
            ),
          });
        }
        if (mobilePanel.length > 0) {
          collapseRow.push({
            className: 'd-none d-sm-table-cell',
            value: <span></span>,
          });
          collapseRow.push({
            className: 'd-none d-sm-table-cell',
            value: (
              <div className="d-flex flex-column">
                <span>Gender</span>
                <span>Male</span>
              </div>
            ),
          });
        }
        return row;
      });
      if (tableExtraInformation.length > 0) {
        const tableExtraRows = renderExtraInformation(tableExtraInformation, tableRow, false);
        tableRows = [...tableRows, ...tableExtraRows];
      }
      tableRows = (tableRows.length > 0 && tableRows.filter((value) => value)) || [];
      //Mobile fix
      let colWidthMobile = 'col-3';
      const tableMobileData = [...tableInformation, ...tableExtraInformation];
      return (
        tableRows.length > 0 && (
          <div
            className={
              (additionalClasses
                ? additionalClasses
                : (element && element.mobileClassName) || mobileClassName) || ''
            }
          >
            <div className={tableHeaderClass} style={tableHeaderStyle}>
              {headers.map((header, key) => {
                colWidthMobile = header.mobileClassName;
                const mobileHeaderClassName = header.mobileHeaderClassName || '';
                return (
                  <div
                    className={
                      mobileHeaderClassName
                        ? mobileHeaderClassName
                        : currentStep == 'PetAssure' || currentStep == 'PetPlus'
                        ? ' pet-cost-per-day '
                        : currentStep == 'CoverageSelection'
                        ? (header && header.config && header.config.className) || 'col-1'
                        : currentStep == 'PetAssureNation'
                        ? (header && header.config && header.config.className) || ''
                        : (header && header.config && header.config.className) || 'col-2'
                    }
                    key={key}
                    style={header && header.config && header.config.style}
                  >
                    <span className="text-uppercase">{get(header, 'value', '')}</span>
                  </div>
                );
              })}
            </div>
            <div className="d-flex flex-wrap mobileTableBorder">
              <div className="d-flex flex-wrap">
                {tableMobileData.map((tableInfo, tableKey) => {
                  const arrowIcon = open.includes(`collapse-${tableKey}`)
                    ? 'angle-up'
                    : 'angle-down';
                  let mobileRows = tableRowMapper(tableInfo, tableRow, tableRowsArray);
                  let rowClass = false;
                  let mobileCollapseTemp = tableRowMapper(
                    tableInfo,
                    tableRow,
                    tableCollapseHeaderArray
                  );
                  if (
                    (hasWaiveCoverage && mobileRows[1]?.value == 'Low') ||
                    mobileRows[1]?.value == 'High' ||
                    mobileCollapseTemp[0]?.value == 'High' ||
                    mobileCollapseTemp[0]?.value == 'Low' ||
                    mobileRows[1]?.value == 'Pro' ||
                    mobileRows[1]?.value == '$15,000.00'
                  ) {
                    rowClass = true;
                  }

                  let hideCollapse = false;
                  if (currentStep == 'PetAssureNation') {
                    hideCollapse = true;
                  }

                  if (tableInfo && tableInfo.showActions) {
                    mobileRows.push({
                      className: 'mx-4 mt-3',
                      value: (
                         <span>
                         {edit && <Icon
                            icon={['fas', 'pen']}
                            onClick={() =>
                              editRecord(
                                tableInfo.id,
                                tableInfo.mainBuid,
                                formName,
                                formik,
                                tableInfo.data,
                                editModal
                              )
                            }
                            className="action mr-2"
                          />}
                          <Icon
                            icon={['fas', 'trash-alt']}
                            // onClick={() => deleteRecord(tableInfo.mainBuid, formName, formik)}
                            onClick={() => {                           
                              if (currentStep == 'PetAssureNation') {
                                $('#myModal').modal('show');
                                deletedRowIndex = tableKey;
                                var my_id_value = $(this).data(tableInfo.mainBuid, formName, formik);
                                $('.modal-body #hiddenValue').val(my_id_value);
                                // return(<ConfirmModal data-toggle="modal" data-target="#myModal" />)
                              } else {
                                deleteRecord(tableInfo.mainBuid, formName, formik);
                              }
                            }}
                            className="action"
                          />
                        </span>
                      ),
                    });
                  }
                  return (
                    <div
                      className={
                        rowClass
                          ? 'd-flex flex-wrap col-12 py-3 px-0 mobileTableBorderBottom coverage-data'
                          : 'd-flex flex-wrap col-12 py-3 px-0 mobileTableBorderBottom'
                      }
                    >
                      <div
                        className={
                          currentStep == 'IdTheft' ||
                          currentStep == 'SupplementalHealthInsurance' ||
                          currentStep == 'CoverageSelection' ||
                          currentStep == 'CriticalIllnessGeneral' ||
                          currentStep == 'CriticalIllness' ||
                          currentStep == 'PetAssure' ||
                          currentStep == 'PetPlus' ||
                          currentStep == 'LegalCoverage' ||
                          currentStep == 'LegalCoverage' ||
                          currentStep == 'ExcessLiability'
                            ? 'col-1'
                            : currentStep == 'PetAssureNation' ? null: 'col-2'
                        }
                      >
                        {hasWaiveCoverage ? (
                          <span>
                            {' '}
                            <input
                              id={tableKey + `-${tableData}`}
                              className="form-check-radio"
                              type="radio"
                              checked={optionSelected === tableKey}
                              onChange={() => setOptionSelected(tableKey)}
                            />
                          </span>
                        ) : showMobileArrow ? (
                          <span>
                            <Icon
                              icon={['fas', arrowIcon]}
                              onClick={() => openCollapse(tableKey + `-${tableData}`)}
                              className="action mr-2"
                            />
                          </span>
                        ) : null}
                      </div>
                      {mobileRows.map((row, indexRow) => {
                        const isDependent = tableInfo && tableInfo.isDependent ? true : false;
                        // const isSelected = isDependent
                        //   ? tableInfo && tableInfo.beneficiarySelected
                        //   : tableInfo && tableInfo.data && tableInfo.data.beneficiarySelected;
                        // let tempValue = temporaryValues.find(
                        //   (temp) => temp.id === tableInfo.mainBuid
                        // );

                        let tempValue = temporaryValues.find(temp => temp.mainBuid === tableInfo.mainBuid);
                        const isSelected = tempValue && tempValue.beneficiarySelected ? tempValue.beneficiarySelected : tableInfo && tableInfo.data && tableInfo.data.beneficiarySelected;
                  
                        if (row && row.type === 'checkBox') {
                          return (
                            <Checkbox
                              id={indexRow}
                              key={indexRow}
                              onChange={(e) => {
                                const newSelectObj = {...tableInfo};
                                const isChecked = e.currentTarget.checked;
                                if ( isChecked ) {
                                  newSelectObj.beneficiarySelected = true;
                                } else {
                                  newSelectObj.beneficiarySelected = false;
                                }
                                const hasTemporaryValue = temporaryValues.find(temp => temp.mainBuid === newSelectObj.mainBuid);
                                if ( !hasTemporaryValue ) {
                                  setTemporaryValues([...temporaryValues, newSelectObj])
                                } else {
                                  const newTempValues = [];
                                  temporaryValues.forEach(temp => {
                                    if ( temp.mainBuid === newSelectObj.mainBuid) {
                                      newTempValues.push(newSelectObj);
                                    } else {
                                      newTempValues.push(temp);
                                    }
                                  });
                                  setSaveChanges(true);
                                  setTemporaryValues(newTempValues);
                                }
                                // onChangeCheckBox(e, tableInfo.mainBuid, isDependent)
                              }}
                              // onChange={(e) => {
                              //   onChangeCheckBox(e, tableInfo.mainBuid, isDependent)
                              // }}
                              value={row.value}
                              label={row.label}
                              checked={isSelected || false}
                            />
                          );
                        } else if (row && row.type === 'select') {
                          return (
                            <Select
                              key={row.name}
                              id={row.id}
                              label={row.mobileTableLabel}
                              multipleSelect={row.multipleSelect}
                              requiredVal={
                                row?.validations?.string?.required ||
                                row?.validations?.string?.validationRequiredOnTrue
                              }
                              containerClassName={row.mobileRowClassName}
                              className={row.className}
                              defaultValue={row.defaultValue ? row.defaultValue : ''}
                              selectedValue={tempValue && tempValue[row.id]}
                              onChange={(event) => {
                                const newValue = event.target.value;
                                let newSelectionObj = {...tableInfo};
                                const hasTempValue = temporaryValues.find(temp => temp.mainBuid === newSelectionObj.mainBuid);
                
                                if ( hasTempValue ) {
                                  newSelectionObj = {...tempValue};
                                  const newValues = [];
                                  temporaryValues.forEach(temp => {
                                    if ( temp.mainBuid === tempValue.mainBuid ) {
                                      newSelectionObj.beneficiaryType = newValue;
                                      newValues.push(newSelectionObj);
                                    } else {
                                      newValues.push(temp);
                                    }
                                  });
                                  setSaveChanges(true);
                                  setTemporaryValues(newValues);
                                }

                                // const newValue = event.target.value;

                                // if (tempValue && tempValue.hasOwnProperty(row.id)) {
                                //   tempValue[row.id] = newValue;
                                //   const newValues = [];
                                //   temporaryValues.forEach((temp) => {
                                //     if (temp.id === tempValue.id) {
                                //       newValues.push(tempValue);
                                //     } else {
                                //       newValues.push(temp);
                                //     }
                                //   });
                                //   setTemporaryValues(newValues);
                                //   if (tableId === 'beneficiaryTable') {
                                //     dispatch(UPDATE_DEPENDENT_BENEFICIARY_INFORMATION(tempValue));
                                //   }
                                // }
                              }}
                              disabled={isSelected ? false : true}
                              options={
                                row.populateDataConfig
                                  ? serviceHelper.generateDataList(formik, row.populateDataConfig)
                                  : row.options
                                  ? row.options
                                  : constantsList[row.optionsname]
                                  ? constantsList[row.optionsname]
                                  : []
                              }
                              {...props}
                              value={tempValue && tempValue[row.id]}
                              currentStep={currentStep}
                            />
                          );
                        } else if (row && row.type === 'text') {
                          return (
                            <Input
                              element={row}
                              key={row.id}
                              id={row.id}
                              nId={row.id}
                              type={row.type}
                              format={row.format}
                              hidden={row.hidden}
                              tile={row.tile}
                              tileInfo={row.tileInfo}
                              classNameWrapper={row.classNameWrapper}
                              containerClassName={row.mobileRowClassName}
                              label={row.mobileTableLabel}
                              placeholder={row.placeholder}
                              name={row.name}
                              value={tempValue && tempValue[row.id]}
                              shouldBeReadOnly={row.shouldBeReadOnly}
                              className={row.className}
                              style={row.style}
                              payload={row.payload}
                              specialIconLayout={row.specialIconLayout}
                              formik={formik}
                              disabled={isSelected ? false : true}
                              requiredValidation={true}
                              onChange={(event) => {
                                const newValue = event.target.value;
                                let newSelectionObj = {...tableInfo};
                                const hasTempValue = temporaryValues.find(temp => temp.mainBuid === newSelectionObj.mainBuid);
                
                                if ( hasTempValue ) {
                                  newSelectionObj = {...tempValue};
                                  const newValues = [];
                                  temporaryValues.forEach(temp => {
                                    if ( temp.mainBuid === tempValue.mainBuid ) {
                                      newSelectionObj.beneficiaryPercentage = newValue;
                                      newValues.push(newSelectionObj);
                                    } else {
                                      newValues.push(temp);
                                    }
                                  });
                                  setSaveChanges(true);
                                  setTemporaryValues(newValues);
                                }
                                // const newValue = event.target.value;
                                // if (tempValue && tempValue.hasOwnProperty(row.id)) {
                                //   tempValue[row.id] = newValue;
                                //   const newValues = [];
                                //   temporaryValues.forEach((temp) => {
                                //     if (temp.id === tempValue.id) {
                                //       newValues.push(tempValue);
                                //     } else {
                                //       newValues.push(temp);
                                //     }
                                //   });
                                //   setTemporaryValues(newValues);
                                // }
                              }}
                              // onBlur={() => {
                              //   if (tableId === 'beneficiaryTable') {
                              //     dispatch(UPDATE_DEPENDENT_BENEFICIARY_INFORMATION(tempValue));
                              //   }
                              // }}
                            />
                          );
                        }
                        return (
                          // <div className={hasWaiveCoverage&&indexRow==1?'col-4 subtitleCollapse':'col-4'}>{row && row.value}</div>
                          // <div className={hasWaiveCoverage&&indexRow==1?'col-4 subtitleCollapse': currentStep =="IdTheft" ? 'col-3':'col-4'}>{row && row.value}</div>
                          <div
                            className={
                              hasWaiveCoverage && indexRow == 1
                                ? currentStep == 'PetAssure' ||
                                  currentStep == 'PetPlus' ||
                                  currentStep == 'LegalCoverage' // || currentStep == 'ExcessLiability'
                                  ? 'col-6 col-lg-3 col-md-3 pet-mobile subtitleCollapse'
                                  : currentStep == 'CoverageSelection'
                                  ? 'col-5 subtitleCollapse text-right '
                                  : 'col-4 subtitleCollapse'
                                : currentStep == 'IdTheft' || currentStep == 'SupplementalHealthInsurance' || currentStep == 'PetAssureNation'
                                ? 'col-3'
                                : colWidthMobile
                                ? colWidthMobile
                                : row && row.className
                                ? `${row.className}`
                                : 'col-4'
                            }
                          >
                            {row && row.value}
                          </div>
                          // <div className={hasWaiveCoverage&&indexRow==1 && currentStep =="IdTheft"?'col-4 subtitleCollapse':'col-3'}>{row && row.value}</div>
                        );
                      })}
                      {hasActions && tableExtraInformation.length === 0 && (
                        <div className="col-2 px-0">
                          <span>
                           {  edit && <Icon
                              icon={['fas', 'pen']}
                              onClick={() =>
                                editRecord(
                                  tableInfo.id,
                                  tableInfo.mainBuid,
                                  formName,
                                  formik,
                                  tableInfo.data,
                                  editModal
                                )
                              }
                              className="action mr-2"
                            />}
                            <Icon
                              icon={['fas', 'trash-alt']}
                              // onClick={() => deleteRecord(tableInfo.mainBuid, formName, formik)}
                              onClick={() => {

                               
                                if (currentStep == 'PetAssureNation') {
                                  $('#myModal').modal('show');
                                  deletedRowIndex = tableKey;
                                  var my_id_value = $(this).data(tableInfo.mainBuid, formName, formik);
                                  $('.modal-body #hiddenValue').val(my_id_value);
                                  // return(<ConfirmModal data-toggle="modal" data-target="#myModal" />)
                                } else {
                                  deleteRecord(tableInfo.mainBuid, formName, formik);
                                }
                              }}
                              className="action"
                            />
                          </span>
                        </div>
                      )}
                      {mobilePanel.length > 0 && (
                        <div
                          className="col-12 py-3 px-0"
                          id={`collapse-${tableKey}-${tableData}`}
                          style={
                            hasWaiveCoverage || defaultCollapse
                              ? { display: '' }
                              : { display: 'none' }
                          }
                        >
                          <div className="d-flex flex-wrap">
                            <div
                              className={currentStep == 'CoverageSelection' ? 'col-1' : 'col-2'}
                            ></div>
                            {/* <div className='col-2'></div> */}
                            {mobilePanel.map((panel, panelKey) => {
                              const header = compact(
                                tableCollapseHeaderArray.map((headInfo) => {
                                  if (headInfo && headInfo.id && headInfo.id === panel)
                                    return headInfo;
                                })
                              );
                              let mobileCollapse = tableRowMapper(
                                tableInfo,
                                tableRow,
                                tableCollapseHeaderArray
                              );               
                              const headerInfo =
                                (header && header.length > 0 && header[0] && header[0].value) || '';
                              const mobileClassName =
                                (header &&
                                  header.length > 0 &&
                                  header[0] &&
                                  header[0].mobileClassName) ||
                                '';

                              const collapseInfo =
                                (mobileCollapse &&
                                  mobileCollapse.length > 0 &&
                                  mobileCollapse[panelKey] &&
                                  mobileCollapse[panelKey].value) ||
                                '';
                              return (
                                <div
                                  className={
                                    mobileClassName
                                      ? mobileClassName
                                      : headerInfo == 'Preferred Contact Method'
                                      ? 'col-4 ml-2'
                                      : 'col-4'
                                  }
                                >
                                  <div
                                    className={
                                      headerInfo == 'Preferred Contact Method'
                                        ? 'd-flex flex-column text-nowrap ml-5 mb-2'
                                        : 'd-flex flex-column text-nowrap mb-2'
                                    }
                                  >
                                    <span>{headerInfo}</span>
                                    <span className="subtitleCollapse">{collapseInfo}</span>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="col-2"></div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                {hasWaiveCoverage && (
                  <tr className="waive-coverage">
                    <div className="mx-5 px-4">
                      <input
                        className="form-check-radio"
                        type="radio"
                        onChange={() => setOptionSelected('Waive')}
                        // checked={optionSelected=='Waive'}
                        checked={
                          (currentStep == 'CriticalIllnessGeneral' && radioSelectedValue == 'Waive') ||
                          (currentStep == 'CoverageSelection' && radioSelectedValue == 'Waive') ||
                          (currentStep == 'IdTheft' && radioSelectedValueIDTheft == 'Waive') ||
                          (currentStep == 'SupplementalHealthInsurance' && radioSelectedValueSupplementalHealthInsurance == 'Waive') ||
                          (currentStep == 'PetAssure' && radioSelectedValuePetAssure == 'Waive') ||
                          (currentStep == 'LegalCoverage' &&
                            radioSelectedValueLegalCoverage == 'Waive') ||
                          (currentStep == 'Travel' && radioSelectedValueTravel == 'Waive') ||
                          (currentStep == 'CriticalIllness' &&
                            radioSelectedValueCriticalIllness == 'Waive') ||
                          (currentStep == 'ExcessLiability' &&
                            radioSelectedValueExcessLiability == 'Waive' &&
                            !isUmUimCoverageTable) ||
                          (currentStep == 'ExcessLiability' &&
                            radioSelectedValueExcessLiabilityUmUim == 'Waive' &&
                            isUmUimCoverageTable)
                            ? true
                            : optionSelected == 'Waive'
                        }
                      />
                      <span className="ml-3">Waive Coverage</span>
                    </div>
                  </tr>
                )}
              </div>
            </div>
            { tableId === 'beneficiaryTable' && saveChanges && (
              <SaveButton
                className={'mx-3 mb-2 mt-3'}
                saveChanges={saveChanges}
                onClick={() => {
                  setSaveChanges(false);
                }}
              />
            )}
            {beneficiaryError && tableId === 'beneficiaryTable' && (
              <div>
                <span
                  style={{
                    color: '#f44336',
                    fontFamily: 'NotoSansRegular',
                    fontSize: '0.75',
                    paddingLeft: '1rem',
                  }}
                >
                  The total Primary Beneficiary or Contingent Beneficiary should be 100% to
                  continue.
                </span>
              </div>
            )}
          </div>
        )
      );
    }
  };
  let hideExcessTable = hideElement && element.excessTable;
  return showTable && (
    //added condition to avoide big gab when element is hided
    // <div className={!hideExcessTable ? "addMultiple py-3" : ''}>
    <div className={!hideElement ? 'addMultiple py-3' : ''}>
      {renderTable()}
      {renderMobileTable()}
    </div>
  );
};

TableWrapper.propTypes = {
  elements: PropTypes.array,
  updateFormData: PropTypes.func,
  tableRowMapper: PropTypes.func,
  multiConfig: PropTypes.object,
  hasActions: PropTypes.bool,
  actionsStyle: PropTypes.object,
  actionsClassName: PropTypes.string,
  columns: PropTypes.array,
  mobileColumns: PropTypes.array,
  mobilePanel: PropTypes.array,
};
TableWrapper.defaultProps = {
  elements: {},
  tableHeaders: [],
  setAllFormData: () => {},
  tableRowMapper: () => {},
  multiConfig: {},
  hasActions: true,
  actionsStyle: {},
  actionsClassName: '',
  columns: [],
  mobileColumns: [],
  mobilePanel: [],
};

export default TableWrapper;
