import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.wrapper {
  background-color: #FFFFFF
  padding-right: 1rem;
  padding-left: 1.5rem;
  opacity: 1;
  background-color:  ${({ theme }) =>
  theme.components && theme.components.footer && theme.components.footer.footerBgColor};
  background-color: #FFFFFF

}
.fr-wrapper {
  background-color:  #F0F0F0
  padding-right: 1rem;
  padding-left: 1.5rem;
  opacity: 1;
  background-color:  ${({ theme }) =>
  theme.components && theme.components.footer && theme.components.footer.footerBgColor};
  background-color:  #F0F0F0

}

.title {
  color: #000000;
  opacity: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 0rem;
  font-family:  NotoSansRegular; 
}
.sub-title {
  color: #000000;
  opacity: 1;
  font-style: normal;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0rem;
  font-family:  NotoSansRegular; 
}
.sec-title {
  color: #000000;
  opacity: 1;
  font-style: normal;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0rem;
  font-family:  NotoSansRegular; 
  padding-left: 1rem;
}
.content {
  color: #000000;
  opacity: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  letter-spacing: 0rem;
  font-family:  NotoSansRegular; 
  text-align: left;
  margin-right: 1rem;
}
.en-content {
  background-color: #FFFFFF;
}
.fr-content {
  background-color: #F0F0F0
}
.terms-of-use {
  color: #0065AC;
  font-family:  NotoSansRegular; 
  font-size: 1.5rem;
  padding-bottom: 1rem;
}
.supported-browsers {
  color: #202020;
  font-family:  NotoSansRegular; 
  font-size: 1.5rem;
  padding-bottom: 1rem;
}
.btnClass {
  background-color: #F0FAFF;
  color: #202020;
  padding-right: 0rem;
}
@media only screen and (max-width: 767px) { 
  .btnClass {
   // padding-right: 1rem;
  }
`;
