import React from 'react';
import './styles.css';
import Image from '../Image';
import { useDispatch, useSelector } from 'react-redux';
import reducer from '../../../Store/Slices/reducer';
/*This atom is created to display the information which contains a icon,title and list of items  */
const Sticky = (props) => {
  const {
    children,
    className,
    value,
    image,
    title,
    secondTitle,
    thirdTitle,
    text,
    hideElement,
    hidden,
    classNameWrapper,
    iconClass,
    headerClass,
    listClass,
    olClass,
    notNumbered,
    notArray,
    MultipleTitleStyles,
    shouldShowOnYes,
    color,
    borderColor,
    ...rest
  } = props;

  const currentStep = useSelector((storeState) => storeState.stepForm.currentStep);

  let listItem = '';
  let notes = [];
  // value.forEach(function (listItem, index) {
  const isYesradioDisclosureSelected = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelected
  );
  const isYesradioDisclosureSelectedIdTheft = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelectedIdTheft
  );
  const isYesradioDisclosureSelectedTravel = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelectedTravel
  );
  const isYesradioDisclosureSelectedCriticalIllness = useSelector(
    (storeState) => storeState?.registerationError?.isYesradioDisclosureSelectedCriticalIllness
  );
  const radioSelectedValueCriticalIllness = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueCriticalIllness
  );

  const isYesradioDisclosureSelectedPetNation = useSelector(
    (storeState) => storeState?.employeeProfile?.isYesradioDisclosureSelectedPetNation
  );
  //   notes.push(listItem);
  // });
  let hideStickyNoteField = false;
  if (radioSelectedValueCriticalIllness == 'Waive' || radioSelectedValueCriticalIllness == -1) {
    hideStickyNoteField = true;
  }

  let additionalClasses = hideElement || hideStickyNoteField ? 'hideElement' : classNameWrapper;
  return (
    // <span className={'statusBadge py-1 px-2 ' + className} {...rest}>
    // <div className={(shouldShowOnYes && !isYesradioSelected)? 'hideElement ' : ''} hidden={hidden}>

    <div
      className={
        !notNumbered
          ? 'sticky-note' + '  ' + additionalClasses
          : 'sticky-note mr-2  ' + additionalClasses
      }
      hidden={hidden}
      style={{ 'background-color': color, fontSize: '0.75rem', border: '1px solid ' + borderColor }}
    >
      {/** MFA */}
      {/* <h6>Note</h6> */}
      {!notNumbered ? (
        <>
          {/* <div className="row">
            <div className={iconClass}>
              {' '}
              <Image
                key={Math.random()}
                // className={`card-header-image align-self-end element-image ml-3 mr-2`}
                className={`card-header-image align-self-end element-image`}
                filename={image}
              />
            </div>
            <div className={headerClass + ' stickyNoteHeader '}>{title}</div>
          </div> */}
          {!notArray && (
            <div className="row">
              <h1>Note</h1>
              <div className="col-12">
                <div>
                  {/* <ol className={olClass}> */}
                  {value.map((tip) => (
                    <li className="pt-1">{tip}</li>
                  ))}
                  {/* </ol> */}
                </div>
              </div>
            </div>
          )}

          {notArray && (
            <div className="row">
              <h6>{title}</h6>
              <div className="col-12">
                <div>
                  {/* <ol className={olClass}> */}
                  {value}
                  {/* </ol> */}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        /**registeration*/
        <>
          <div className="mx-0 ">
            {/* <div className={iconClass}>
              {' '}
              <Image
                key={Math.random()}
                // className={`card-header-image align-self-end element-image ml-3 mr-2`}
                className={`card-header-image align-self-end element-image`}
                filename={image}
              />
            </div> */}
            <div className="title">Note</div>
            {/* <div className=""> */}
            <div>
              <div className="">
                {MultipleTitleStyles ? (
                  <div className="">
                    <span className="first-title">{title}</span>
                    <span className="second-title">{secondTitle}</span>
                    <span className="first-title">{thirdTitle}</span>
                  </div>
                ) : (
                  value.map((tip) => <div className="pt-1">{tip}</div>)
                )}
              </div>
            </div>
            {/* </div> */}
          </div>
        </>
      )}
    </div>

    // {/* </span> */}
  );
};

export default Sticky;
