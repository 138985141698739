import { omit } from "lodash";
// This is a helper file that contains the determineButtonLabel function.
import { cloneDeep, get, set, unset } from 'lodash';
import { store } from '../../../../index';
import { form } from "../../ProductTable/processData";
import { filterRequiredPropsFromEnrollmentProcess } from "./reduxHelperFunctions";

const replaceReduxDataForResume = [
  "stepFormNew.stepKeys"
];

// Function to determine the label of a button based on the given props.
export const determineButtonLabel = (props, waiveSelected, enrollmentType) => {
  const nextStepGoingTobe =
    props?.contentData?.stepKeys?.[props?.contentData?.stepKeys?.indexOf(props?.currentStep) + 1] ||
    null;
  if (waiveSelected) {
    if (props.showDisclosureStatement) return 'Continue';
    if (enrollmentType === "openEnrollment") return 'Continue to Summary';
    if (nextStepGoingTobe !== 'EnrollmentSummary') return 'Continue to Next Product';
    return 'Continue to Summary';
  } else {
    if (props.showDisclosureStatement) return 'Continue';
    if (enrollmentType === "openEnrollment") return 'Continue to Summary';
    if (nextStepGoingTobe === 'EnrollmentSummary') return 'Continue to Summary';
    if (props.step !== props.stepsCount) return 'Continue to Next Product';
    return 'Continue to Next Product';
  }
  return "Continue";
};


export function getCoverageBeneficiaryEligibility(response, coverageBuid) {
  if (response === null || response === undefined || response === []) {
    return [];
  }
  const coverageBeneficiaryEligibility = [];
  if (response?.length > 0) {
    for (const item of response) {

      const isByWillOrEstatePresent = item?.allowedBeneficiaryKind && item?.allowedBeneficiaryKind?.includes("cByWillOrEstate");
      const isDefineBeneficiariesPresent = item?.allowedBeneficiaryKind && item?.allowedBeneficiaryKind?.includes("cDefineBeneficiaries");
      const isBeneficiaryEligible = isDefineBeneficiariesPresent;
      const isBeneficiaryOptional = isBeneficiaryEligible && isByWillOrEstatePresent && isDefineBeneficiariesPresent;
      const isBeneficiaryMandatory = isBeneficiaryEligible && !isByWillOrEstatePresent && isDefineBeneficiariesPresent;

      coverageBeneficiaryEligibility.push({
        personBuid: item?.forCoveredPerson?.bUID,
        coverageBuid: coverageBuid, // As I mentioned earlier, you need to replace this with the correct property
        isBeneficiaryEligible,
        isBeneficiaryOptional,
        isBeneficiaryMandatory
      });
    }
  }

  return coverageBeneficiaryEligibility;
}


// export function getCoverageBeneficiaryEligibility(response) {
//     if (response === null || response === undefined || response === []) {
//         return [];
//     }

//     const coverageBeneficiary = {};
//     for (const item of response) {
//       const coverageBuid = item?.coverageBuid || '';
//       const items = omit(item, 'coverageBuid');
//       items && Object.keys(items).forEach(record => {
//         const data = items[record];
//         const isByWillOrEstatePresent = data?.allowedBeneficiaryKind && data?.allowedBeneficiaryKind.includes("cByWillOrEstate");
//         const isDefineBeneficiariesPresent = data?.allowedBeneficiaryKind && data?.allowedBeneficiaryKind.includes("cDefineBeneficiaries");
//         const isBeneficiaryEligible = isDefineBeneficiariesPresent;
//         const isBeneficiaryOptional = isBeneficiaryEligible && isByWillOrEstatePresent && isDefineBeneficiariesPresent;
//         const isBeneficiaryMandatory = isBeneficiaryEligible && !isByWillOrEstatePresent && isDefineBeneficiariesPresent;
//         const forCoveredPersonId = data?.forCoveredPerson?.bUID || '';
//         if ( forCoveredPersonId ) {
//           coverageBeneficiary[forCoveredPersonId] = {
//             coverageBuid,
//             isBeneficiaryEligible,
//             isBeneficiaryOptional,
//             isBeneficiaryMandatory
//           };
//         }
//       }); 
//     }
//     return coverageBeneficiary;
// }



export function convertFormat(originalFormat) {
  const newFormat = {};
  for (const key in originalFormat) {
    newFormat[originalFormat[key].name] = originalFormat[key];
  }
  return newFormat;
}










export const removeUnwantedProps = (obj, removeList) => {
  const storeState = store.getState()
  let eligibleProductList = storeState?.insuredInfo?.selectedProductNames;

  // Create a deep copy of the object to avoid mutation
  let newObj = cloneDeep(obj);

  //remove non selected product data from redux
  let selectedProducts = {};
  if(eligibleProductList && eligibleProductList.length) {
    eligibleProductList.map((item) => {
      if(item === "Accident") {
        selectedProducts["CoverageSelection"] = newObj?.insuredInfo?.CoverageSelection
      } else {
        let identifier = form[item];
        selectedProducts[identifier] = newObj?.insuredInfo?.[identifier]
      }
    })
  }
  //enrollmentprocess inside process unwanted data removal
  let enrollmentProcessData = filterRequiredPropsFromEnrollmentProcess({reduxData: newObj?.process?.enrollmentProcess})
  
  // Iterate through each path in removeList
  removeList.forEach((prop) => {
    // Unset the specified property using lodash's 'unset' function
    unset(newObj, prop);
  });

  newObj = {
    ...newObj,
    insuredInfo: {
      ...newObj.insuredInfo,
      ...selectedProducts
    },
    process: {
      ...newObj.process,
      enrollmentProcess: enrollmentProcessData
    }
  }
  // Return the modified copy of the object
  return newObj;
};


export const replaceDataInState = (state, payloadData, replaceReduxDataForResume) => {
  let newState = cloneDeep(state);

  replaceReduxDataForResume.forEach(path => {
    const valueFromPayload = get(payloadData, path);
    if (valueFromPayload !== undefined) {
      newState = set(newState, path, valueFromPayload);
    }
  });

  return newState;
}

export const replaceWelcomeDataInState = (state, payloadData, replaceReduxDataForResume) => {
  let newState = cloneDeep(state);

  replaceReduxDataForResume.forEach(path => {
    let valueFromPayload = ''
    if (path === 'process.enrollmentProcess') {
      valueFromPayload = [];
    } else {
      valueFromPayload = payloadData;
    }
    newState = set(newState, path, valueFromPayload);
  });

  return newState;
}

export const getHideIrrPlanColBoolean = ({ productFamily }) => {
  const storeState = store.getState()
  const getListOfEligibleProducts = storeState?.process?.productList || [];

  if (getListOfEligibleProducts.length) {
    let productIndex = getListOfEligibleProducts.findIndex((item) => {
      return item.memberOfferedProduct?.product?.productFamily === productFamily
    });

    let additionData = productIndex >= 0 ? getListOfEligibleProducts[productIndex]?.subscribedProduct?.additionalDatas : [];

    let relevantColindex = additionData.length && additionData.findIndex((item) => {
      return item.fieldName === "HIDEIRPLANCOL"
    })

    if (relevantColindex) {
      return getListOfEligibleProducts[productIndex]?.subscribedProduct?.additionalDatas[relevantColindex]?.valueCode
    } else {
      return "No"
    }
  }
  return "No"
}

export const getEmptyKeysInArrOfObjects = (arr) => {

  let keys = Object.keys(arr[0]) || [];
  let filteredKeys = [];

  const allEqual = (arr) => {
    return new Set(arr).size == 1;
  }

  const allEmpty = (arr) => {
    let areEmpty = true;
    arr.map((item) => {
      if (item !== "") {
        areEmpty = false;
        return areEmpty
      }
    })
    return areEmpty
  }

  if (arr.length > 1) {
    keys.map((key) => {
      let tempArr = arr.map((item) => {
        return item[key];
      })
      if (allEmpty(tempArr)) {
        filteredKeys.push(key)
      }
    })
  }
  return filteredKeys
}

export const cleanHeaderNodeFromObj = (arr) => {
  if(arr && arr.length) {
    let tempArr = arr.map((item) => {
        let tempItem = {}
        let headerContent = item.headerContent;
        if(headerContent && headerContent.length) {
            let tempHeaderContent = headerContent.filter((headerItem) => {
                return headerItem.fieldValue !== "";
            })

            tempItem = {
              ...item,
              "headerContent": tempHeaderContent
            }
        }

        return tempItem
    })

    return tempArr;
  }
}

export const cleanTableHeaderNodeFromArr = (arr) => {
  if(arr && arr.length) {
    let tempItem = []
    let tempArr = arr.map((item) => {
        if(item && item.fieldValue !== "") {
            tempItem.push(item)
        }
    })

    return tempItem;
  }
}

export const filterCols = (keysArr, fromArr) => {
  let filteredArr = [];
  filteredArr = fromArr.filter(item => !keysArr.includes(item.id));
  return filteredArr;
}

export const errorLoggerHelper = ({ err }) => {
  console.error(err)
}

export const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value)
}

  export const getErrorMessages = (pageProduct = '') => {
      const storeFromRedux = store.getState();
      const mandatoryErrors = storeFromRedux?.['insuredInfo']?.['apiErrorHandlingList']?.[pageProduct]?.['mandatoryApis'] || [];
      const errors = storeFromRedux?.['insuredInfo']?.['alertMessages'] || [];
      const filterErrors = [];
      errors.forEach(error => {
        if ( error?.page === pageProduct ) {
          filterErrors.push(error?.id);
        }
      });

      let disableContinue = false;
      filterErrors.forEach(error => {
        if ( mandatoryErrors.includes(error)) {
          disableContinue = true;
        }
      });
      
      return disableContinue;   
  };

  export const getConfigHelper = (tableHeader,configDataRedux) => {
    const hasConfigTrue = tableHeader && tableHeader.find((tableHeaderData,i)=>{
      if(tableHeaderData.id=="costPerPayPeriod"){
        tableHeader[i] ={...tableHeaderData, value:configDataRedux?.premiumFrequency?.label};
      } 
    });
  }

// You can add more helper functions here as needed.
