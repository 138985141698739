import React, { useState } from 'react';
import ImageLayout from '../../Atoms/ImageLayout';
import ScreenIllustration from '../../../../../Assets/Images/register-image.png';
import Register from './Register'
import StepForm from '../../Atoms/StepForm';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CLEAR_EMPLOYEE_VALIDATION_ERROR, CLOSE_ERROR, LOCK_CONTINUE_BUTTON, SET_MFA_SERVICE_UNAVAILABLE, SHOW_ALERT } from '../../../../../Store/Slices/RegisterationError';
import { useHistory } from 'react-router-dom';

const RegisterScreen = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const initialSteps = [{ component: Register, updateSteps, onComplete }];
    const [steps, setSteps] = useState([...initialSteps]);

    const { tollNumberFromAuthModule = '' } = useSelector((storeState) => storeState?.registeration);
    const { showAlert = false } = useSelector((storeState) => storeState?.registerationError);

    let tollNumber = tollNumberFromAuthModule ? tollNumberFromAuthModule.substring(tollNumberFromAuthModule.indexOf(':'), tollNumberFromAuthModule.indexOf('H')) : ''
    let tollText = `You have exceeded the maximum number (3) of attempts. Your account will be locked for 15 minutes. Please try again after that time or contact ${tollNumber} if you need further assistance..`

    let generalText = `You have exceeded the maximum number (3) of attempts.`

    let imgSrc;
    try {
        imgSrc = require(`../../../../../Assets/Images/iconError.png`).default;
    } catch (e) {
        imgSrc = '';
    }
    const displayAlert = () => {
        $('#maxWrongTries').show();

        return (
            <div className="col-9 mt-3 mb-0 mx-auto alert alert-danger alert-dismissible fade show" role="alert" id='maxWrongTries'>
                <img
                    src={imgSrc}
                    alt='alt'
                    className='img-fluid pr-2 '
                    id="alert-img"
                />
                <span className="pr-3 ">{tollNumber ? tollText : generalText}</span>
                <button
                    className="close mt-1 "
                    onClick={() => {
                        $('#maxWrongTries').hide()
                        dispatch(SHOW_ALERT(false))
                        dispatch(LOCK_CONTINUE_BUTTON(false))
                        dispatch(CLOSE_ERROR({ hasError: false }))
                        dispatch(CLEAR_EMPLOYEE_VALIDATION_ERROR({ hasError: false, clearCount: true, key: 'EmployeeValidation', count: 0 }))
                    }}
                    style={{ color: "#002C77", fontSize: "0.875rem", fontFamily: "NotoSansMedium" }}>CLOSE
                </button>
            </div>
        )
    }

    function updateSteps(newSteps) {
        setSteps([...initialSteps, ...newSteps])
    }

    function onComplete() {
        history.push('/enrollment');
    }
    return (
        <>
            <div>{showAlert && displayAlert()}</div>
            <ImageLayout image={{ src: ScreenIllustration, alt: "Register Banner" }} content='' className='forRegistration'>
                <StepForm steps={steps} className={'forMFA'} />
            </ImageLayout>
        </>
    )
}
export default RegisterScreen;
