import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './styles.css';
import imageMap from '../../Molecules/CustomSection/Helper/imageHelper';
import {getFileName} from '../../Molecules/CustomSection/Helper/commonHelper';


const Tile = (props) => {
  let { value, style, className, image, config,hidden } = props;
  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);



  ;
  //Abhishek Mahajan removing this piece of code
  // constantsList.InvoiceStatusConstants = [
  //   {
  //     value: 'cipsNotPaid',
  //     text: 'Not Paid',
  //     style: {
  //       fontSize: '11px',
  //       color: '#d51b61',
  //       borderRadius: '4px',
  //       border: 'solid 1px #d51b61',
  //       padding: '4.5px 9.9px 4.5px 9.9px',
  //       position: 'relative',
  //       bottom: '1px',
  //     },
  //   },
  //   {
  //     value: 'cipsPartial',
  //     text: 'Partial',
  //     style: {
  //       fontSize: '11px',
  //       color: '#999900',
  //       borderRadius: '4px',
  //       border: 'solid 1px #999900',
  //       padding: '4.5px 9.9px 4.5px 9.9px',
  //       position: 'relative',
  //       bottom: '1px',
  //     },
  //   },
  //   {
  //     value: 'cipsFullyPaid',
  //     text: 'Fully Paid',
  //     style: {
  //       fontSize: '11px',
  //       color: '#00a190',
  //       borderRadius: '4px',
  //       border: 'solid 1px #00a190',
  //       padding: '4.5px 9.9px 4.5px 9.9px',
  //       position: 'relative',
  //       bottom: '1px',
  //     },
  //   },
  //   {
  //     value: 'caisReceived',
  //     text: 'Received',
  //     style: {
  //       fontSize: '11px',
  //       color: '#00a190',
  //       borderRadius: '4px',
  //       border: 'solid 1px #00a190',
  //       padding: '4.5px 9.9px 4.5px 9.9px',
  //       position: 'relative',
  //       bottom: '1px',
  //     },
  //   },

  //   {
  //     value: 'cActive',
  //     text: 'Active',
  //     style: {
  //       fontSize: '11px',
  //       color: '#00a190',
  //       borderRadius: '4px',
  //       border: 'solid 1px #00a190',
  //       padding: '4.5px 9.9px 4.5px 9.9px',
  //       position: 'relative',
  //       bottom: '1px',
  //     },
  //   },
  // ];

  if (config && config.displayName && constantsList[config.displayName]) {
    let obj = constantsList[config.displayName].find((o) => o.value === value);
   
    if (obj) {
      value = obj['text'];
      style = obj['style'];
    }
  }

  else{
    let obj = value
     style = obj['style'];
  }
  if(config && config.style) {
    style = config.style
  }

  return (
    ((value && !hidden) && (
      <span style={style} className={`${className || `status-style`}`}>
        {image && <img alt="" src={imageMap[getFileName(image)]} className="pr-2" />}
        {value}
      </span>
    )) ||
    null
  );
};
Tile.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  hidden: PropTypes.string,
};
Tile.defaultProps = {
  className: '',
  value: '',
};

export default Tile;
