// enrollmentSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stepInfo: {},
  enrollmentStep: 0,
  enrollmentData: {},
  enrollmentStatus: 'NOT_STARTED', // Possible values: 'NOT_STARTED', 'IN_PROGRESS', 'COMPLETED'
  enrollmentType: "",
  endorsementData: {},
  crossSellObject: {},
  crossSellResponseObject: {},
  enrollmentStepInfo: {},
  currentEnrollmentType: "",
  isCoverageCancelled: false,
  isBackToSummary: false,
  cancelledCoverageBeforeConfirmationBuidArr: [],
  benificiaryProductUpdate: [],
  isQLE: false,
  isEnrolled: []
};

export const enrollmentSlice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    UPDATE_ENROLLMENT_STEP: (state, action) => {
      state.enrollmentStep = action.payload;
    },
    UPDATE_ENROLLMENT_DATA: (state, action) => {
      state.enrollmentData = { ...state.enrollmentData, ...action.payload };
    },
    UPDATE_ENROLLMENT_STATUS: (state, action) => {
      state.enrollmentStatus = action.payload;
    },
    UPDATE_ENROLLMENT_TYPE: (state, action) => {
      state.enrollmentType = action.payload;
    },
    UPDATE_ENDORSMENT_DATA: (state, action) => {
      state.endorsementData = { ...state.endorsementData, ...action.payload };
    },
    UPDATE_EXTERNAL_STEP_INFO: (state, action) => {
      state.enrollmentStepInfo = action.payload;
    },
    UPDATE_STEP_INFO: (state, action) => {
      state.stepInfo = { ...state.stepInfo, ...action.payload };
    },
    STORE_PROCESS_BUID_FOR_CROSS_SELL: (state, action) => {
      state.processBuidForCrossSell = action.payload;
    },

    UPDATE_STATE: (state, action) => {
      // This is unnecessary now, as the rootReducer handles this action
      // but it's mandatory to have a reducer for each action in Redux Toolkit
      return state;
    },
    STORE_DATA_FOR_CROSS_SELL: (state, action) => {
      // state.crossSellObject.nameUseForPrinting = action.payload.nameUseForPrinting ;
      // state.crossSellObject.aknowledgePresentedName = action.payload.aknowledgePresentedName ;
      // state.crossSellObject.expirationPresentedDate = action.payload.expirationPresentedDate ;
      state.crossSellObject.nameUseForPrinting = action.payload.nameUseForPrinting;
      state.crossSellObject.isCrossSellEnabledForEmployee = action.payload.isCrossSellEnabledForEmployee;
      state.crossSellObject.requestID = action.payload.requestID;
    },
    STORE_RESPONSE_DATA_FOR_CROSS_SELL: (state, action) => {
      state.crossSellResponseObject.aknowledgePresentedNameFirst = action.payload.aknowledgePresentedNameFirst;
      state.crossSellResponseObject.aknowledgePresentedNameSecond = action.payload.aknowledgePresentedNameSecond;
      state.crossSellResponseObject.expirationPresentedDate = action.payload.expirationPresentedDate;
    },
    STORE_WHOLE_CROSS_SELL_RESPONSE: (state, action) => {
      state.wholeCrossSellResponse = action.payload;
    },
    EMPLOYEE_ELIGIBLE_FOR_CROSS_SELL: (state, action) => {
      state.employeeEligibleForCrossSell = action.payload;
    },
    UPDATE_CURRENT_ENROLLMENT_TYPE: (state, action) => {
      state.currentEnrollmentType = action.payload;
    },
    IS_COVERAGE_CANCELLED: (state, action) => {
      state.isCoverageCancelled = action.payload;
    },
    IS_BACK_TO_SUMMARY: (state, action) => {
      state.isBackToSummary = action.payload;
    },
    COVERAGE_CANCELLED_BEFORE_CONFIRMATION_BUIDS: (state, action) => {
      const { buid, process } = action.payload;
      if (process === "add") {
        state.cancelledCoverageBeforeConfirmationBuidArr = [
          ...state.cancelledCoverageBeforeConfirmationBuidArr,
          buid
        ]
      } else {
        const index = state.cancelledCoverageBeforeConfirmationBuidArr.indexOf(buid);
        if (index > -1) {
          state.cancelledCoverageBeforeConfirmationBuidArr.splice(index, 1);
        }
      }
    },
    BENIFICIARY_PRODUCT_UPDATE: (state, action) => {
      state.benificiaryProductUpdate = action.payload;
    },
    IS_QLE: (state, action) => {
      state.isQLE = action.payload;
    },
    IS_ENROLLED: (state, action) => {
      state.isEnrolled = [
        ...state.isEnrolled,
        state.isEnrolled = action.payload
      ]
    },
  },
});

export const { UPDATE_ENROLLMENT_STEP, UPDATE_ENROLLMENT_DATA, UPDATE_ENROLLMENT_STATUS, UPDATE_ENROLLMENT_TYPE, UPDATE_ENDORSMENT_DATA,
  STORE_PROCESS_BUID_FOR_CROSS_SELL, STORE_DATA_FOR_CROSS_SELL,
  STORE_WHOLE_CROSS_SELL_RESPONSE,
  EMPLOYEE_ELIGIBLE_FOR_CROSS_SELL,
  STORE_RESPONSE_DATA_FOR_CROSS_SELL,
  UPDATE_STEP_INFO,
  PRODUCT_TABLE_MAP_DETAILS,
  UPDATE_EXTERNAL_STEP_INFO,
  UPDATE_STATE,
  UPDATE_CURRENT_ENROLLMENT_TYPE,
  IS_COVERAGE_CANCELLED,
  IS_BACK_TO_SUMMARY,
  COVERAGE_CANCELLED_BEFORE_CONFIRMATION_BUIDS,
  BENIFICIARY_PRODUCT_UPDATE,
  IS_QLE,
  IS_ENROLLED,
  IS_DECLINED_TRUE
} = enrollmentSlice.actions;

export default enrollmentSlice.reducer;
