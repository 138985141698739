import appConfig from '../../../../appConfig/index.js';
import http from '../../../../Common/Services/HttpService/index.js';
export const retrieveConfig = async (clientId, url) => {
  return await http
    .post(appConfig.SSP_MID_URL + url, {
      additionalInformation: {
        clientId,
      },
    })
    .then((resp) => {
      let data = resp?.data?._Result;
      if (url === '/getProductConfig') {
        data = resp?.data;
      }
      return data;
    }).catch(e => {
      return {};
    });
};
