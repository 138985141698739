import TermsOfUse from '../../../Cells/Footer/FooterStaticPages/termsOfUse';
import PrivacyStatement from '../../../Cells/Footer/FooterStaticPages/privacyStatement';
import SupportedBrowsers from '../../../Cells/Footer/FooterStaticPages/supportedBrowsers';
import PdfComponent from '../../../Cells/Footer/FooterStaticPages/pdfComponent';
import CookieNotice from '../../../Cells/Footer/FooterStaticPages/cookieNotice';
import SessionLayout from '../../../Molecules/CustomSection/SessionLayout';

export default [
  { path: "/termsOfUse", component: TermsOfUse, staticFooter: true },
  { path: "/privacyStatement", component: PrivacyStatement , staticFooter: true },
  { path: "/supportedBrowsers", component: SupportedBrowsers, staticFooter: true  },
  { path:  "/privacyDocument", component: PdfComponent },
  {path: "/cookieNotice", component: CookieNotice, staticFooter: true  },
  {path: "/session-expired", component: SessionLayout, staticFooter: false  },
]