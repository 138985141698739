import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'disableButton',
  initialState: {
    disabledContinueBtn: false,
    disabledSubmitBtn: false,
    codeIsEntered: false,
    disableRegisterationProcess: false,
    disableProductSelection: true,
    changeButtonWidth: false,
    stepsButton: false,
    //CoverageSelection: {status: null, waiveType: ''},
    IdTheft: false,
    SupplementalHealthInsurance: false,
    LegalCoverage: false,
    CriticalIllness: false,
    disableCrossSellBtn: false,
    modalStep:0
  },
  reducers: {
    // action => action handler
    DISABLE_CONTINUE_BUTTON: (disableButton, action) => {
      disableButton.disabledContinueBtn = action.payload.disabled;
    },
    DISABLE_SUBMIT_BUTTON: (disableButton, action) => {
      disableButton.disabledSubmitBtn = action.payload.disabled;
    },
    DISABLE_ENTER_CODE: (disableButton, action) => {
      disableButton.codeIsEntered = action.payload.codeIsEntered;
    },
    DISABLE_ENTER_PHONE: (disableButton, action) => {
      disableButton.phoneIsEntered = action.payload.phoneIsEntered;
    },
    DISABLE_CROSS_SELL: (disableButton, action) => {
      disableButton.disableCrossSellBtn = action.payload.disabled;
    },
    DISABLE_PROCESS: (disableButton, action) => {
      // disableButton.disableRegisterationProcess = action.payload.disabled;
      // disableButton.currentStep = action.payload.currentStep;
     // if(action.payload.disabled)
      disableButton[action.payload.currentStep]= action.payload.disabled;
     // else
      //disableButton[action.payload.currentStep].disabled = false;

   },
   DISABLE_SUBMIT_CODE_BTN: (disableButton, action) => {
    disableButton.disableSubmitEmailBtn = action.payload.disableSubmitEmailBtn;
    disableButton.disableSubmitPhoneBtn = action.payload.disableSubmitPhoneBtn;

 },
    DISABLE_PRODUCT_SELECTION: (disableButton, action) => {
      disableButton.disableProductSelection = action.payload.disabled;
    },
    CHANGE_BUTTON_WIDTH: (disableButton, action) => {
      if (action.payload.currentStep) {
        let key = action.payload.currentStep;
        let UmUimKey = action.payload.currentStep+'UmUim';

        if(!action.payload.isUmUimCoverageTable)
        disableButton[key] =   action.payload.waiveObject;
        else
        disableButton[UmUimKey] =   action.payload.waiveObject;
      }
    },

    CHANGE_BG_COLOR_COLLAPSE: (disableButton, action) => {
      disableButton.changeBGColor = action.payload.changeBGColor;
    },
    MODAL_STEP: (disableButton, action) => {
      disableButton.modalStep = action.payload.modalStep;
    },
    
  },
});

export const {
  DISABLE_CONTINUE_BUTTON,
  DISABLE_SUBMIT_BUTTON,
  DISABLE_ENTER_CODE,
  DISABLE_PROCESS,
  DISABLE_PRODUCT_SELECTION,
  CHANGE_BUTTON_WIDTH,
  CHANGE_BG_COLOR_COLLAPSE,
  MODAL_STEP,
  DISABLE_ENTER_PHONE,
  DISABLE_SUBMIT_CODE_BTN,
  DISABLE_CROSS_SELL
} = slice.actions;

export default slice.reducer;
