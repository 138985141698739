import React, { Suspense } from 'react';
import { NavLink } from 'react-router-dom';
import QuickLinkIcon from '../../../../../Assets/Images/quicklink.png'

import './quickLinks.css'

const QuickLinks = (props) => {

    const links = [
        { title: "Search For An Employee", name: 'employeeSearch', url: '/hrcenter/employeeSearch', },
        { title: "View Reports", name: 'reports', url: '/hrcenter/reports', },
    ]

    return (
        <div className='quicklink-display-area'>
            <span><img src={QuickLinkIcon} alt="quickLink" className="img-responsive quickLink" /></span>
            <div className='quicklink-heading'>Quick Links</div>
            <ul>
                {links?.map(link => (
                    <li>
                        <NavLink key={link.name} to={link.url}>{link.title}</NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default QuickLinks;
