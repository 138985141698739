import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../Atoms";
import { CustomTableBuyUp } from "../../../ProductTable/customTableBuyUp";
import { registerForBuyUpPlan, unformatter, unsubscribeFromPlan, waivedPlanStatusArr } from "../../Helper/buyUpHelper";
import { BeneficiarySection } from "./BeneficiarySection";
import { PolicyReplacementQuestionsSection } from "./PolicyReplacementQuestionSection";
import { continueBtnLabelHelper, screenNames } from "../../Helper/continueBtnLabelHelper";
import { addSubscriptionForChoiceForBuyUp } from "../../Actions/addSubscriptionForChoiceBuyUp";
import { getListOfCoveragePlanDesignConfigurationNewBuyUp } from "../../Actions/getListOfCoveragePlanDesignConfigurationNewBuyUp";
import { getSubscribedCoverageConfigurationNew } from "../../Actions/getSubscribedCoverageConfigurationNew";
import { getSubscribedCoverageBeneficiaryConfigurationNew } from "../../Actions/getSubscribedCoverageBeneficiaryConfigurationNew";
import { errorLoggerHelper, formatCurrency } from "../../Helper/MappingHelper";
import { validateSubscribedProductData } from "../../Actions/validateSubscribedProductData";
import { SET_BASE_PLAN_DECLINED_LOCALLY_SPOUSE, SET_COST_BASKET_DATA, SET_COVERAGE_BUID } from "../../../../../Store/Slices/BuyUp";
import { UPDATE_BASEPLAN_SELECTED_VALUES, UPDATE_BUYUP_SELECTED_VALUES } from "../../../../../Store/Slices/InsuredInfo";
import { useNavigationHook } from '../../Hooks/NavigationHook'; 
import { UPDATE_CART_ITEM_NEW_BUYUP } from "../../../../../Store/Slices/EmployeeProfile";
import { prepareSubscriptionForChoice } from "../../Actions/prepareSubscriptionForChoice";
export const SpouseCoverageBuyUp = (props) => {
    const dispatch = useDispatch();
    const { personBuid = "", flowType, prevStep, nextStep, onNext, productFamily, displayMode, customContent, showDisclosureStatement, spouse, children, dependentsList, allowedBeneficiaryKind, beneficiaryEligibility} = props;
    const { currentStep } = useNavigationHook();
    const [baseCoverageDetails, setBaseCoverageDetails] = useState({});
    const [buyUpCoverageDetails, setbuyUpCoverageDetails] = useState({});
    const [basePlanSelectedValue, setBasePlanSelectedValue] = useState(0);
    const [buyUpPlanSelectedValue, setBuyUpPlanSelectedValue] = useState(null);
    const [isUserActionTaken, setUserActionTakenBoolean] = useState(false)
    const [disableContinueBtn, setContinueBtnAsDisabled] = useState(true);
    const [continueBtnText, setContinueBtnText] = useState("Continue");
    const [totalPolicyQuestionDiff, setTotalPolicyQuestionDiffBoolean] = useState(0)
    const [totalPercent, setTotalPercent] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isBeneficiarySectionValid, setBeneficiarySectionValidBoolean] = useState(false)
    const [policyReplacementQuestionCount, setPolicyReplacementQuestionCount] = useState(0)
    const [canContinueBeneficiaries, setCanContinueBeneficiaries] = useState(false);
    const [currentBeneficieries, setUpdatedBeneficiaryList] = useState([])
    let currentReduxState = useSelector((storeState) => storeState);
    const productIdentifierBuid= useSelector((storeState) => storeState.process?.productFamilyIdentifier[productFamily]);
    const enrollmentProcessBuid= useSelector((storeState) =>  storeState?.enrollment?.enrollmentData?.bUID);
    const buyupBoughtCurrentYearFlag = useSelector((storeState) =>  storeState.buyUp.buyupBoughtCurrentYearFlag);
    const buyUpSelectedValues = useSelector((storeState) =>  storeState.insuredInfo?.[currentStep]?.buyUpSelectedValues);
    const basePlanSelectedValues = useSelector((storeState) =>  storeState.insuredInfo?.[currentStep]?.basePlanSelectedValues);
    const buyUpRequiredCheckBoolean = useSelector((storeState) =>  storeState.buyUp.requiredChecksPassed);
    const buyUpCoverageObj = useSelector((storeState) =>  storeState.buyUp.coverageBuid);
    const getAllPossibleChoicesConfigurationForProduct = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]?.getAllPossibleChoicesConfigurationForProduct?._Result);
    const basePlanCoverageAmount = useSelector((storeState) =>  storeState.buyUp.selectedBasePlanDetails);
    const buyUpCoverageBuid= useSelector((storeState) =>  storeState.buyUp.coverageBuid.spouse);
    const baseplanCoverageArr = useSelector((storeState) => storeState.buyUp.existingCoverageBuid.spouse);
    const coverageDetailsData = useSelector((storeState) => storeState.insuredInfo?.[currentStep]?.coverageDetails?.filter(e => e.personBuid === personBuid));
    const employeePlanCost = useSelector((storeState) => storeState.buyUp.costBasket.employee);
    const existingPlanStatus = useSelector((storeState) => storeState.buyUp.planStatus.spouse) || [];
    
    let selectedValue;
    if(coverageDetailsData?.[0]?.selectedValue) {
        selectedValue = coverageDetailsData?.[0]?.selectedValue
    } else {
        selectedValue = basePlanSelectedValues.spouse
    }
    const coverageDetails = useSelector((storeState) => storeState.insuredInfo?.[currentStep]?.offeredCoverageBuids?.[1]);
    const onLoadResponseList = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList);
    const subscribeCoverageBuids = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.[currentStep]?.subscribeCoverageBuids);
    const subscribedElements = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements);
    const addedBeneficiariedArr = useSelector((storeState) => storeState?.employeeProfile?.UpdatedBeneficiaries)

    let coverageDetailsForPrepare;
    if (props.flowType === 2 || props.flowType === 3) {
        if(currentReduxState?.insuredInfo?.[currentStep]?.offeredCoverageBuids && currentReduxState?.insuredInfo?.[currentStep]?.offeredCoverageBuids.length) {
            coverageDetailsForPrepare = currentReduxState?.insuredInfo?.[currentStep]?.offeredCoverageBuids.filter((e) => e?.coveragePersonBuid?.includes(personBuid))[0];
        }
    }

    const isBeneficiaryMandatory = useSelector((storeState) => {  
        const eligibilityData = storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
        const matchedEligibility = eligibilityData.filter(eligibility => eligibility?.coverageBuid === buyUpCoverageBuid);
        return matchedEligibility[0]?.isBeneficiaryMandatory || false;
      });
      
    const isBeneficiaryOptional = useSelector((storeState) => {  
        const eligibilityData = storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
        const matchedEligibility = eligibilityData.filter(eligibility => eligibility?.coverageBuid === buyUpCoverageBuid);
        return matchedEligibility[0]?.isBeneficiaryOptional || false;
    });

    const continueBtnObj = {
        flowType: flowType,
        screenType: screenNames.COVERAGE_SCREEN,
        hasDisclosureStatement: showDisclosureStatement,
        spouseCount: spouse?.length,
        childrenCount: children?.length,
        isWaivedOffFlag: false,
        productIdentifierBuid: productIdentifierBuid,
        memberIdentifier: "spouse"
    }
    
    let getAllPossibleChoicesConfigurationForProductArr = [];

    if(getAllPossibleChoicesConfigurationForProduct && getAllPossibleChoicesConfigurationForProduct.length) {
        getAllPossibleChoicesConfigurationForProductArr =  getAllPossibleChoicesConfigurationForProduct.map((item) => {
            if(item.possibleInsuredPersons && item.possibleInsuredPersons.length) {
                return item.possibleInsuredPersons.filter((insureds) => {
                    return insureds?.insuredPerson?.bUID === personBuid
                })
            }
        })
    }

    useEffect(() => {
        (async() => {
            if (productIdentifierBuid && getAllPossibleChoicesConfigurationForProductArr.length && !buyUpCoverageBuid) {
                init()
            }
        })()
    }, [productIdentifierBuid, getAllPossibleChoicesConfigurationForProductArr.length])

    const init = async() => {
        //STEP 1 page load API calls
        await dispatch(validateSubscribedProductData({
            enrollmentProcessBuid,
            productIdentifierBuid,
            currentStep: currentStep,
            subscribedElements,
            expectReturn: true
        }));

        let resp = await initAddSubscriptionForChoiceAPICall();
        
        let newCoverageBuid;
        if(resp) {
            newCoverageBuid = await getNewCoverageBuid(resp)
        }
    }

    const initAddSubscriptionForChoiceAPICall = async () => {
        //STEP 2 make API call to get new plan details - buy up
        let addSubscriptionForChoiceForBuyUpExistingData = onLoadResponseList?.addSubscriptionForChoiceForBuyUp || [];
        return await dispatch(addSubscriptionForChoiceForBuyUp({
            payload: {
                enrollmentProcessBuid,
                coverageBuid: coverageDetails?.coverage,
                coveredPersonsBuid: coverageDetails?.coveragePersonBuid[0]
            },
            addSubscriptionForChoiceForBuyUpExistingData: addSubscriptionForChoiceForBuyUpExistingData
        }));
    }

    const getNewCoverageBuid = async (resp) => {
        //STEP 3 abstract buy up new BUID here
        const productList = resp.data._Result?.contract?.subscribedElements.filter((item) => { return item?.subscribedProduct?.productCode?.buid === productIdentifierBuid });
        let newCoverage = productList?.[0]?.coverages?.filter((item) => {
            return item?.insuredPersons[0]?.person?.data?.bUID === coverageDetails?.coveragePersonBuid[0] &&  baseplanCoverageArr.indexOf(item?.bUID) === -1 && waivedPlanStatusArr.indexOf(item?.subscribedOption?.status) === -1
        })
        let newCoverageBuid;
        
        if (newCoverage?.length) {
            newCoverageBuid = newCoverage[0].bUID
            await dispatch(SET_COVERAGE_BUID({
                ...buyUpCoverageObj,
                "spouse": newCoverageBuid
            }))
        }
        
        return newCoverageBuid
    }

    const addPlans = ({baseCoverageDetails, oldBuyUpCoverageDetails}) => {
        // add exiting plan and buy up plan details if buyup has been bought last year
        let basePlan = baseCoverageDetails[0].listOfCoveragePlanDesignConfiguration?._Result[0];
        let buyUpPlan = oldBuyUpCoverageDetails[0].listOfCoveragePlanDesignConfiguration?._Result[0];
        let combinedPlan = [{
            ...basePlan,
            coverageAmount: formatCurrency(unformatter(basePlan.coverageAmount) + unformatter(buyUpPlan.coverageAmount)),
            costPerPayPeriod: formatCurrency(unformatter(basePlan.costPerPayPeriod) + unformatter(buyUpPlan.costPerPayPeriod)),
            coverageLevel: {
                amount: formatCurrency(unformatter(basePlan.amount) + unformatter(buyUpPlan.amount)),
                currency: basePlan.coverageLevel.currency
            },
            limit: formatCurrency(unformatter(basePlan.limit) + unformatter(buyUpPlan.limit))
        }]
        return combinedPlan
    }

    const updateCostBasket = () => {
        if(basePlanSelectedValue >= 0 && basePlanSelectedValue !== null) {
            let cost = 0;
            if(buyUpPlanSelectedValue >= 0 && basePlanSelectedValue !== null) {
                cost = 0;
                if(baseCoverageDetails && baseCoverageDetails.length) {
                    baseCoverageDetails.map((item, index) => {
                        if(index == basePlanSelectedValue) {
                            cost = cost + parseFloat(item?.costPerPayPeriod?.substring(1))
                        }
                    })
                }
                
                if(buyUpCoverageDetails && buyUpCoverageDetails.length) {
                    buyUpCoverageDetails.map((item, index) => {
                        if(index == buyUpPlanSelectedValue) {
                            cost = cost + parseFloat(item?.costPerPayPeriod?.substring(1))
                        }
                    })
                }
            } else {
                cost = 0;
                if(baseCoverageDetails && baseCoverageDetails.length) {
                    baseCoverageDetails.map((item, index) => {
                        if(index == basePlanSelectedValue) {
                            cost = cost + parseFloat(item?.costPerPayPeriod?.substring(1))
                        }
                    })
                }
            }
            dispatch(SET_COST_BASKET_DATA({
                data: {
                    employee: employeePlanCost || 0,
                    spouse: cost
                }
            }))

            dispatch(UPDATE_CART_ITEM_NEW_BUYUP({
                removeProduct: false,
                id:currentStep,
                cost: employeePlanCost + cost,
                name:productFamily
            }))
        } else {
            dispatch(UPDATE_CART_ITEM_NEW_BUYUP({
                removeProduct: false,
                id:currentStep,
                cost: employeePlanCost,
                name:productFamily
            }))

            dispatch(SET_COST_BASKET_DATA({
                data: {
                    employee: employeePlanCost || 0,
                    spouse: 0
                }
            }))
        }
    }

    useEffect(() => {
        updateCostBasket();
    },[baseCoverageDetails, basePlanSelectedValue, buyUpPlanSelectedValue, buyUpCoverageDetails]);

    useEffect( () => {
        (async () => {
            //STEP 4 after buyup buid is found, make rest of the calls
            if(buyUpCoverageBuid) {
                await fetchNecessaryDataForPaintingBuyUpTable()
            }
        })();
    },[buyUpCoverageBuid])

    const fetchNecessaryDataForPaintingBuyUpTable = async () => {
        //Page load API calls
        try {

            if(existingPlanStatus && existingPlanStatus.length) {
                let filteredCoverageBuid = existingPlanStatus.filter((item) => {
                    return item?.coverageBuid === buyUpCoverageBuid
                })

                if(filteredCoverageBuid && filteredCoverageBuid.length) {
                    if(waivedPlanStatusArr.indexOf(filteredCoverageBuid[0]?.status) !== -1) {
                        await init();
                    }
                }
            }

            await dispatch(getListOfCoveragePlanDesignConfigurationNewBuyUp(
                currentStep,
                enrollmentProcessBuid,
                buyUpCoverageBuid,
                personBuid,
                baseplanCoverageArr
            ));
    
            await dispatch(getSubscribedCoverageConfigurationNew(
                currentStep,
                enrollmentProcessBuid,
                buyUpCoverageBuid,
                personBuid
            ))
    
            await dispatch(getSubscribedCoverageBeneficiaryConfigurationNew(
                enrollmentProcessBuid,
                buyUpCoverageBuid,
                personBuid,
                currentStep,
            ));
            
        } catch (e) {
            errorLoggerHelper({
                err: e
            })
        }
    }

    const addBasePlans = ({basePlanCoverageDetails}) => {
        let basePlanCoverages = basePlanCoverageDetails?.[0]?.listOfCoveragePlanDesignConfiguration?._Result;
        let previousSelectedPlan;
        
        if(basePlanCoverages && basePlanCoverages.length) {
            previousSelectedPlan = basePlanCoverages.filter((coverages) => {
                return coverages.coverageLevel.amount === basePlanCoverageAmount.spouse?.[0]?.amount
            })
        }
        
        let combinedPlan = {
            ...previousSelectedPlan?.[0]
        };
        if(basePlanCoverageDetails && basePlanCoverageDetails.length) {
            basePlanCoverageDetails.map((item, index) => {
                if(index > 0) {
                    const listOfCoveragePlanDesignConfiguration = item?.listOfCoveragePlanDesignConfiguration?._Result?.[0]
                    combinedPlan = {
                        ...combinedPlan,
                        coverageAmount : formatCurrency(unformatter(combinedPlan?.coverageAmount) + unformatter(listOfCoveragePlanDesignConfiguration?.coverageAmount)),
                        costPerPayPeriod: formatCurrency(unformatter(combinedPlan?.costPerPayPeriod) + unformatter(listOfCoveragePlanDesignConfiguration?.costPerPayPeriod)),
                        limit: formatCurrency(unformatter(combinedPlan.limit) + unformatter(listOfCoveragePlanDesignConfiguration?.limit)),
                        coverageLevel: {
                            amount: combinedPlan?.coverageLevel?.amount + listOfCoveragePlanDesignConfiguration?.coverageLevel?.amount,
                            currency: combinedPlan?.coverageLevel?.currency
                        },
                        headerContent: [
                            {
                                ...combinedPlan.headerContent?.[0],
                                fieldValue: {
                                    amount: combinedPlan?.coverageLevel?.amount + listOfCoveragePlanDesignConfiguration?.coverageLevel?.amount,
                                    currency: combinedPlan?.coverageLevel?.currency
                                }
                            }
                        ]
                    }
                }
            })
        }

        return [{...combinedPlan}]
    }

    useEffect(() => {
        (async () => {
            //  STEP 5 Since API calls are made now, we can start painting the data
            if (subscribeCoverageBuids && subscribeCoverageBuids.length) {
                let baseCoverageDetails,
                    basePlanCoverageDetails = [],
                    buyUpPlanCoverageDetails = [],
                    oldBuyUpCoverageDetails;

                if(baseplanCoverageArr && baseplanCoverageArr.length) {
                    subscribeCoverageBuids.filter((item) => {
                        if(item.personBuid === personBuid){
                            if(baseplanCoverageArr.indexOf(item?.coverageBuid) !== -1) {
                                basePlanCoverageDetails.push(item)
                            } else {
                                if(item.coverageBuid === buyUpCoverageBuid) {
                                    buyUpPlanCoverageDetails.push(item)
                                }
                            }
                        }
                    });
                }

                let combinedBasePlanDetails = addBasePlans({basePlanCoverageDetails});
                setBaseCoverageDetails(combinedBasePlanDetails)
                setbuyUpCoverageDetails(buyUpPlanCoverageDetails?.[0]?.listOfCoveragePlanDesignConfiguration?._Result)
            }
        })();

    }, [subscribeCoverageBuids, buyUpCoverageBuid])

    useEffect(() => {
        // function to update continue button text and button disability
        (async() => {
            if (basePlanSelectedValue === -1) {
                setContinueBtnText(continueBtnLabelHelper({
                    ...continueBtnObj,
                    isWaivedOffFlag: true
                }));
            } else {
                setContinueBtnText(continueBtnLabelHelper({
                    ...continueBtnObj,
                    isWaivedOffFlag: false
                }));
            }
        })();
    }, [basePlanSelectedValue])

    useEffect(() => {
        (async() => {
            if(selectedValue) {
                await dispatch(UPDATE_BASEPLAN_SELECTED_VALUES({
                    product: currentStep,
                    values: {
                        ...basePlanSelectedValues,
                        spouse: selectedValue
                    }
                }))
            }
        })(); 
    },[selectedValue])

    useEffect(() => {
        // function to update continue button disability and update redux on value selection
        (async() => {
            if(buyUpPlanSelectedValue !== null) {
                await dispatch(UPDATE_BUYUP_SELECTED_VALUES({
                    product: currentStep,
                    values: {
                        ...buyUpSelectedValues,
                        spouse: buyUpPlanSelectedValue
                    }
                }))
            }
        })();
    }, [buyUpPlanSelectedValue])
    
    const submit = async () => {
        //Continue btn click action
        
        if(basePlanSelectedValue === -1) {
            await dispatch(unsubscribeFromPlan({enrollmentProcessBuid, personBuid, coverageBuidArr: baseplanCoverageArr, personType: "spouse", declineType: "mainPlan"}));
            await dispatch(SET_BASE_PLAN_DECLINED_LOCALLY_SPOUSE(true))
            if (showDisclosureStatement) {
                nextStep();
            } else {
                onNext();
            }
        } else {
            if(buyUpCoverageDetails && buyUpCoverageDetails.length && buyUpPlanSelectedValue !== -1) {
                await dispatch(registerForBuyUpPlan({ currentStep, enrollmentProcessBuid, productIdentifierBuid, buyUpCoverageBuid, personBuid, buyUpPlanSelectedValue, displayMode }));
            } else {
                if(isUserActionTaken && buyUpPlanSelectedValue === -1 || (buyUpCoverageDetails && buyUpCoverageDetails.length === 0)) {
                    await dispatch(unsubscribeFromPlan({enrollmentProcessBuid, personBuid, coverageBuidArr: [buyUpCoverageBuid], personType: "spouse", declineType: "buyupPlan"}));
                }      
            }
            await dispatch(SET_BASE_PLAN_DECLINED_LOCALLY_SPOUSE(false))
            nextStep();
        }
    }

    useEffect(() => {
        if(buyUpSelectedValues.spouse !== null) {
            setUserActionTakenBoolean(true);
            setBuyUpPlanSelectedValue(buyUpSelectedValues.spouse)
        }
    }, [buyUpSelectedValues])

    const enableDisableContinueBtn = ({basePlanSelectedValue, buyUpPlanSelectedValue, buyUpCoverageDetails, isUserActionTaken, isBeneficiaryMandatory, currentBeneficieries, canContinueBeneficiaries}) => {
        
        const beneficiarySectionValidation = () => {
            if(isBeneficiaryMandatory || isBeneficiaryOptional) {
                if(canContinueBeneficiaries) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }

        }
        if(basePlanSelectedValue === -1 || buyUpPlanSelectedValue === -1) {
            return false
        } else {
            if(buyUpCoverageDetails && buyUpCoverageDetails.length === 0 || buyUpCoverageDetails === undefined) {
                return beneficiarySectionValidation()
            } else {
                if(isUserActionTaken || buyUpSelectedValues?.spouse >= 0) {
                    return beneficiarySectionValidation()
                } else {    
                    return true;
                }
            }
        }
        
    }
    useEffect(() => {
        setContinueBtnAsDisabled(enableDisableContinueBtn({basePlanSelectedValue, buyUpPlanSelectedValue, buyUpCoverageDetails, isUserActionTaken, isBeneficiaryMandatory, currentBeneficieries, canContinueBeneficiaries}))
    }, [isUserActionTaken, buyUpCoverageDetails, isBeneficiaryMandatory, currentBeneficieries, canContinueBeneficiaries, buyUpPlanSelectedValue])

    return (
        <>
            <div className="row" style={{ fontSize: '1.5rem', color: 'rgb(32, 32, 32)', fontFamily: 'NotoSansRegular' }}>
                <div className="">
                    <div className="label col-12 px-3 undefined" style={{ display: 'inline-block' }}>
                        <div className="row">
                            <div className="px-3 py-3">
                                Existing Coverage for {displayMode}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomTableBuyUp
                basePlanSelectedValue={basePlanSelectedValue}
                buyUpPlanSelectedValue={buyUpPlanSelectedValue}
                existingPlanData={baseCoverageDetails}
                buyUpPlanData={buyUpCoverageDetails}
                customContent={customContent}
                basePlanSelectedValueCallback={setBasePlanSelectedValue}
                buyUpSelectedValueCallback={setBuyUpPlanSelectedValue}
                userActionTakenCallBack={setUserActionTakenBoolean}
                buyUpPreselectedValue={buyUpSelectedValues.spouse}
                isBeneficiarySectionValidCallback={setBeneficiarySectionValidBoolean}
            />
            {
                (basePlanSelectedValue >= 0) && isUserActionTaken && 
                    <>
                        {
                            buyUpCoverageDetails && buyUpCoverageDetails.length && buyUpPlanSelectedValue !== null && buyUpPlanSelectedValue !== -1  ? 
                            <>
                                {(isBeneficiaryMandatory || isBeneficiaryOptional) && <BeneficiarySection 
                                    currentStep={currentStep} 
                                    personBuid={personBuid} 
                                    coverageBuid={buyUpCoverageBuid} 
                                    relation={"spouse"} 
                                    isBuyUpFlow={true} 
                                    basePlanCoverageBuid={baseplanCoverageArr?.[0]}
                                    dependentsList={dependentsList} 
                                    allowedBeneficiaryKind={allowedBeneficiaryKind}
                                    setTotalPercentCallback={setTotalPercent}
                                    setSelectedRowsCallback={setSelectedRows}
                                    isBeneficiaryMandatory= {isBeneficiaryMandatory}
                                    setCanContinueBeneficiaries = {setCanContinueBeneficiaries}
                                    setUpdatedBeneficiaryList= {setUpdatedBeneficiaryList}
                                /> }
                            </> : null
                        }
                    </>
            }
            <div className='button-group'>
                <>
                    <Button type="button" onClick={prevStep} className="mr-3">
                        Back
                    </Button>
                    <Button type="button" onClick={submit} className="mr-3" disabled={disableContinueBtn}>
                        {continueBtnText}
                    </Button>
                </>
            </div>
        </>
    )
}