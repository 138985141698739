import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../appConfig/index.js';
import http from '../../../../Common/Services/HttpService/index.js';
import { CHECK_ELIGIBILITY, SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo/index.js';
import { onLoadProductAPI } from '../../../../Store/Slices/Entities/response/index.js';
export const getPetMedicalSurveyQuestions = (formik,currentStep) => async (dispatch) => {

  

  dispatch(UPDATE_LOADING(true)); // Turn on loader

  try {
    const response = await http.post(appConfig.SSP_MID_URL + '/getMedicalSurveyQuestions', {
      data: {
        PetAssureNation: {
          petAge: formik?.values?.petAge,
          petType: formik?.values?.petType,
          productId:formik?.values?.productId,
          zipCode: formik?.values?.zipCode,
        },
        currentStepInfo: {
          "portalStep": currentStep
        }
      },
    });

    const surveyQuestions = response?.data?._Result;

    // dispatch(CHECK_ELIGIBILITY(surveyQuestions)); // Assuming CHECK_ELIGIBILITY is an action for storing questions
    const currentStepData = {
      [currentStep]: {
        surveyQuestions: { _Result: surveyQuestions},
      },
  };

  dispatch(
      onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false })
  );
    return surveyQuestions;
  } catch (error) {
    console.error(error);
    dispatch(SET_ALERT_MESSAGE({
      type: 'error',
      errorCode: '520',
      continue: true,
      id: 'getMedicalSurveyQuestions',
      show: true,
      page: currentStep,
    }));
    return null;
  } finally {
    dispatch(UPDATE_LOADING(false)); // Turn off loader
  }
};
