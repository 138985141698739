import axios from 'axios';
let unAuthorizedMFA;
let invalidOptCodeMFA;
let invalidLoginCredentialMFA;
let LoginLockedMFA;
let MFAServerNotConnected;
let undefinedValueLegalEntityId;
let passwordConflict;
let LoginLockedOut;
let lockedForWrongOTP;
let forbidden;
let conflict;
let maxAttempt;
let inActiveUser;
let employeeNotFound;
let invalidPassword;

axios.interceptors.response.use(null, (error) => {
  LoginLockedOut =
    error.response &&
    error.response.data &&
    error.response.data?.source === 'Okta' &&
    error.response.data?.message === 'Authentication failed' &&
    error.response.data?.details === 'LOCKED_OUT';
  forbidden =
  error.response &&
  error.response.data &&
  error.response.data?.message === 'Request failed with status code 403';

  undefinedValueLegalEntityId =
    error.response &&
    error.response.data &&
    error.response.data?.message === 'Undefined value legalEntityId';
  passwordConflict =
    error.response &&
    error.response.data &&
    error.response.data === 'Request failed with status code 409' &&
    error.response.status &&
    error.response.status === 409 &&
    error.response.statusText &&
    error.response.statusText === 'Conflict';
  invalidOptCodeMFA =
    error.response &&
    error.response.data &&
    error.response.data?.source === 'Okta' &&
    error.response.data?.message === 'Invalid Passcode/Answer';

  MFAServerNotConnected =
    !error.response ||
    (error.response &&
      error.response.data &&
      error.response.data?.messgae === 'connect ECONNREFUSED 127.0.0.1:5001' &&
      error.response.data?.name === 'Error');

  maxAttempt =
    error.response &&
    error.response.data &&
    error.response.data.message ==='User has exceeded the number of attempts (3)'

  inActiveUser =
    error.response &&
    error.response.data &&
    error.response.data === 'User is not allowed to create a login'

  employeeNotFound =
    error.response &&
    error.response.data &&
    error.response.data?.source === 'Wynsure' &&
    error.response.data?.message === 'Employee not found';

  invalidPassword=
    error.response &&
    error.response.data &&
    error.response.data?.source === 'Okta' &&
    error.response.data?.message === 'Api validation failed: password';
  const expectedError =
    error.response && error.response.status >= 400 && error.response.status < 500;

  if (!expectedError) {
    return Promise.reject(error);
  }
  if (invalidOptCodeMFA)
    return { source: error.response.data?.source, message: 'Invalid Passcode/Answer' };
    if (forbidden)
    return { message:  error?.response?.message };
  else if (invalidLoginCredentialMFA)
    return {
      source: error.response.data?.source,
      status: error.response.data?.status,
      message: 'invalidLoginCredential',
    };
  else if (LoginLockedMFA) return { status: error.response.data?.status, authenticated: false };
  else if (undefinedValueLegalEntityId) return { message: error.response.data?.message };
  else if (MFAServerNotConnected)
    return {
      message: error?.response?.data?.message,
      status: error?.response?.data?.status,
      name: error?.response?.data?.name,
    };
  else if (LoginLockedOut)
    return {
      source: error?.response?.data?.source,
      message: error?.response?.data?.message,
      details: error?.response?.data?.details,
    };
  else if (maxAttempt) return { message: error.response.data.message, tollNumber: error.response.data.tollNumber };
  else if (inActiveUser) return { message: error.response.data };
  else if (employeeNotFound) return {
    source : 'Wynsure' ,
    message: 'Employee not found'
  };
  if (invalidPassword)
    return { source: error.response.data.source, message:error.response.data.message };

  return Promise.reject(error);
});

function setWithCredentials(value) {
 // axios.defaults.withCredentials = value;
}

function setBearer(bearerToken) {
  axios.defaults.headers.common['Authorization'] = bearerToken;
}
function setMidToken(midToken) {
  axios.defaults.headers.common['midToken'] = midToken;
}
function setEnvironment(environment) {
  axios.defaults.headers.common['environment'] = environment;
}
function setUserType(userType) {
  axios.defaults.headers.common['userType'] = userType;
}
function setAuthTokenUserType(authTokenUserType) {
  axios.defaults.headers.common['authTokenUserType'] = authTokenUserType;
}
function setLanguage(language) {
  axios.defaults.headers.common['language'] = language;
}

function setHeaderValue(headerName, value) {
  axios.defaults.headers.common[headerName] = value;
}

function setHeaderAPICookies(value) {
  axios.defaults.headers.common['statefulCookies'] = value;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  request: axios.request,
  setBearer,
  setMidToken,
  setEnvironment,
  setUserType,
  setLanguage,
  setHeaderValue,
  setAuthTokenUserType,
  setWithCredentials,
  setHeaderAPICookies
};
