import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
//Providing the theme as props which contain the data for theming to apply themes on
// current name left card and current address left card
export const Styles = createGlobalStyle`.current-address-left-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  background-color:  ${({ theme }) =>
    theme.components &&
    theme.components.molecules &&
    theme.components.molecules.changeBox &&
    theme.components.molecules.changeBox.changeBoxBgColor};
}

.current-address-right-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
}

.current-name-left-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  background-color:  ${({ theme }) =>
    theme.components &&
    theme.components.molecules &&
    theme.components.molecules.changeBox &&
    theme.components.molecules.changeBox.changeBoxBgColor};
}

.current-name-right-card {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
}

.no-top-border {
  border-top: none !important;
  vertical-align:none;
  text-align:center !important;
}



.changebox-general-items span {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.changebox-general-items-right span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: left;
  color: #00a190;
}

.left-card-style {
  margin-left: -15px;
}

.form-side-header{
  // font-family: Noto Sans;
  // font-size: 32px;
  // font-weight: light;
  // font-stretch: normal;
  // font-style: normal;
  // line-height: 1.73;
  // letter-spacing: normal;
  // text-align: left;
  // color: #1e2e5d;
  
}

.label-header{
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.header-text {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.98;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.error-message{
  margin-top:0rem
  padding-top: 0.001rem
  font-size: 0.75rem;
  font-family: NotoSansRegular
  
}


.hide-scrollbar{
  overflow-x: hidden;
}

@media (max-width: 600px) {
  .column-1-3 {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
.tablet {
  col-12

}


.member-container-pet .modal-content {
  border-radius: 4px 4px 0px 0px !important;
  border: 0;
  background: ${({ theme }) =>
    theme.molecules && theme.molecules.modal && theme.molecules.modal.modalContentBgColor};
}

.member-container-pet .logoutModalDialog .modal-content {
  width: auto;
  top: 10rem !important;
  left: auto;
  right: auto;
 
}
.member-container-pet .qleModalDialog .modal-content {
  width: auto;
  top: 0rem !important;
  left: auto;
  right: auto;
 
}

.member-container-pet .modal-header {
  /* background:  ${({ theme }) =>
    theme.molecules && theme.molecules.modal && theme.molecules.modal.modalHeaderBgColor}; */
  background: #0065AC;
  height: 60px;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  justify-content: space-between;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 16px 32px !important;
}

.modal-body {
  padding: 0px 32px 40px 32px;
}

.member-container-pet .modal-footer {
  justify-content: center;
}

.member-container-pet .button-submit{
  justify-content: center;
}

.member-container-pet .modal-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  color: #ffffff;
}

.closeIcon {
  width: 1rem;
  height: 1rem;
  padding: 0 !important;
  margin: 0 !important;
}

.scrollbar {
  overflow-y: auto;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.model_confirmation_content {
  margin: 0 0 32px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}
.model_confirmation_image {
  margin: 32px 21px 0;
  padding: 5.7px 6.3px 6.3px 5.7px;
  font-family: FontAwesome5Pro-Solid;
  font-size: 110px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #72a800;
}
.logoutModalDialog{
  max-width:26rem !important;
}
.qleModalDialog{
  max-width:45rem !important;
  margin-top:2rem;
}
.modalBodyMessage {
  height: auto;
  //margin-right: 1.875rem;
  margin-top: 1.875rem;
}
.modalDisclaimer {
  padding: 32px 32px 0px 32px;
}
.modalDisclaimerText {
  color: #C53532;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 24px;
}
.modalDescription {
  padding: 0px 32px;
}
.modalDescriptionText {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  margin-bottom: 0px;
}

@media only screen and (max-width: 767px) {
  .logoutModalDialog{
    max-width:23rem !important;
  }
  .qleModalDialog{
    max-width:25rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 767px) {
 
  .qleModalDialog{
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 400px) {
 
  .qleModalDialog{
    max-width:22.5rem !important;
  }
}

.rowClass{
    
}

.title{
    color:#001F52;
    font-size: 0.875rem;
    font-family: NotoSansSemiBold;
  }

  .title-main{
    color:#001F52;
    font-size: 1.5rem;
    font-family: NotoSansLight;
  }
  
  .headerTableTemp{
    background-color: #0065AC;
      color: #FFFFFF;
      text-transform:uppercase;
  }
  .bold-text{
    font-family: NotoSansSemiBold;
    padding-right: 2rem!important;
  }
  .descprition{
    line-height: 0rem;
    margin-top: 1.17rem;
}
.modal-footer-delete{

}
.button {
  height: fit-content;
  background-color: #ffffff !important;
  color: #002C77 !important;
  border: 1px solid #002C77 !important;
  border-radius: 4px;
  padding: 5px 15px;
  opacity: 1;
  margin-left: 10px;
}


.modalfooter{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer-delete{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  margin-bottom: 1.7rem;
  border-top:0px !important
}

.modaltitle{
  font-size: 1.12rem;
  margin-left: 1.12rem;
}
.errCity{
  min-width: 25rem;
}
.petRadio input{
  margin-right:0.5rem;
}
`;



