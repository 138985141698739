// Axios for HTTP requests
import axios from 'axios';

// Define the MIME types for different report formats.
const reportFormats = {
    pdf: 'application/pdf',
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // Add other formats as needed
};

// Default timeout configuration
const TIMEOUT = 600000;

/**
* Asynchronously downloads a report.
* @param {string} url - The URL to send the post request.
* @param {Object} payload - The data to be sent in the post request.
* @param {string} reportFormat - The format of the report (e.g., 'PDF', 'excel').
* @returns {boolean} - True if download is successful, false otherwise.
*/
export const downloadReport = async (url, payload, reportFormat) => {
    try {
        // Validate the report format
        if (!reportFormats[reportFormat]) {
            throw new Error(`Unsupported report format: ${reportFormat}`);
        }

        // Extract reportType and legalEntityId from the payload
        const { reportType, legalEntityId } = payload;

        // Validate reportType and legalEntityId
        if (!reportType) {
            throw new Error('Missing report type');
        }
        if (!legalEntityId) {
            throw new Error('Missing legal entity ID');
        }

        // Send a POST request to the given URL with the payload, expecting a Blob response.
        const response = await axios.post(url, payload, { responseType: 'blob', timeout: TIMEOUT });

        // Determine the file extension and MIME type based on the selected report format.
        const fileExtension = reportFormat === 'excel' ? 'xlsx' : 'pdf';
        const mimeType = reportFormats[reportFormat];

        // Create a Blob from the response data and generate a download URL.
        const blob = new Blob([response.data], { type: mimeType });
        const downloadUrl = window.URL.createObjectURL(blob);

        // Get the current date and time for the file name
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const timestamp = `${day}${month}${year}${hours}${minutes}${seconds}`;

        // Construct the filename with reportType, legalEntityId, timestamp, and file extension
        const filename = `${reportType}_${legalEntityId}_${timestamp}.${fileExtension}`;

        // Create a temporary link element to download the file.
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);

        // Trigger the download and remove the link element afterward.
        link.click();
        link.remove();

        // Release the created object URL.
        window.URL.revokeObjectURL(downloadUrl);

        return true;  // Indicate a successful download.
    } catch (error) {
        // Log the error and provide feedback if necessary
        console.error('Error downloading the report:', error);
        return false;  // Indicate a failed download.
    }
};