import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { TextInput, ToolTip } from '../../Atoms';
import {  SET_PASSWORD, SET_REENTER_PASSWORD } from '../../../../../Store/Slices/Registeration';
import imgSRC from '../../../../../Assets/Images/icon-important.png'


const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(32, 'Password can not be longer than 32 characters')
        .matches(/[a-z]/, 'Password must have at least one lowercase character')
        .matches(/[A-Z]/, 'Password must have at least one uppercase character')
        .matches(/[0-9]/, 'Password must have at least one number')
        .matches(/^[a-zA-Z0-9@.\-_']+$/, 'Password contains invalid characters')
        .test('no-seq-characters', 'Password cannot contain more than three sequential characters', (value) => {
            return !/((?:abc|bcd|cde|def|efg|hij|jkl|mno|nop|pqr|rst|stu|tuv|uvw|vwx|wxy|xyz|zyx|xwv|wvu|uts|tsr|srq|rqp|pon|onm|nml|lkj|kji|jih|ihg|hgf|gfe|fed|edc|dcb|cba|012|123|234|345|456|567|678|789|890|098|987|876|765|654|543|432|321|210)+)/i.test(value);
        })
        .test('no-repeated-characters', 'Password cannot contain more than three repeating characters', (value) => {
            return !/(.)\1{3,}/.test(value);
        })
        // Additional tests like checking against the username, last 10 passwords, and email format need to be implemented separately
        .defined(),

    confirmPassword: Yup
        .string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

const ResetPassword = ({ onPrev, onNext, clrErr, handlePasswordData}) => {

    const dispatch = useDispatch();
    const [strengthColor, setStrengthColor] = useState('');
    const [strengthText, setStrengthText] = useState('');

    const formik = useFormik({
        initialValues: { password: '', confirmPassword: '' },
        validationSchema
    });

    const handlePasswordChange = (e) => {
        const password = e.target.value;

        let strengthVal = 0;

        // 1. Check length
        if (password.length >= 8 && password.length <= 32) strengthVal++;

        // 2. Uppercase
        if (/[A-Z]/.test(password)) strengthVal++;

        // 3. Lowercase
        if (/[a-z]/.test(password)) strengthVal++;

        // 4. Number
        if (/[0-9]/.test(password)) strengthVal++;

        // 5. Special character
        if (/[@\.\-_']/.test(password)) strengthVal++;

        // 6. Check for sequences and repeated characters
        if ((/(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|zyx|yxw|xwv|wvu|vut|uts|tsr|srq|rqp|pqo|pon|onm|nml|mlk|lkj|kji|jih|ihg|hgf|gfe|fed|edc|dcb|cba|ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|ZYX|YXW|XWV|WVU|VUT|UTS|TSR|SRQ|RQP|QPO|PON|ONM|NML|MLK|LKJ|KJI|JIH|IHG|HGF|GHE|FED|EDC|DCB|CBA|012|123|234|345|456|567|678|789|987|876|765|654|543|432|321|210)+/.test(password))) strengthVal--;

        let color = '';
        let text = '';
        switch (strengthVal) {
            case 0: case 1:
                color = 'red';
                text = 'Weak';
                break;
            case 2: case 3:
                color = 'orange';
                text = 'Moderate';
                break;
            case 4:
                color = 'blue';
                text = 'Good';
                break;
            case 5:
                color = 'green';
                text = 'Strong';
                break;
            default:
                color = '';
                text = '';
        }
        setStrengthColor(color);
        setStrengthText(text);
        formik.handleChange(e);
        dispatch(SET_PASSWORD({password:password}))
        
    };

    const handleReenterPasswordChange = (e) => {
        const password = e.target.value;
        dispatch(SET_REENTER_PASSWORD({reenterPassword:password}))
        formik.handleChange(e);
    };

    const validationMsg = (field) => {
        return (formik.touched[field] && formik.errors[field] && (
            <div className="form-mandatory">{formik.errors[field]}</div>
        ))
    };


    return (
        <form onSubmit={(e) => e.preventDefault()} noValidate>
            <div className="row py-2">
                <div className="col-sm-12 col-md-5 col-lg-5 tooltipWrapper register">

                    <TextInput
                        label="Enter Password"
                        type='password'
                        required={true}
                        placeholder="Enter Password"
                        onInput={()=>clrErr()}
                        className="w-100 inputField p-3"
                        onChange={handlePasswordChange}
                        name={formik.getFieldProps('password').name}
                        value={formik.getFieldProps('password').value}
                        onBlur={formik.getFieldProps('password').onBlur}
                    />
                    {formik.values.password && (
                        <div className='strengthBarDiv'>
                            <div className="strength-bar">
                                {[...Array(4)].map((_, i) => {
                                    let bgColor = '';
                                    if (i < 1 && strengthText === 'Weak') bgColor = 'red';
                                    else if (i < 2 && strengthText === 'Moderate') bgColor = 'orange';
                                    else if (i < 3 && strengthText === 'Good') bgColor = 'blue';
                                    else if (i < 4 && strengthText === 'Strong') bgColor = 'green';

                                    return (
                                        <div
                                            key={i}
                                            className={`strength-bar__segment ${strengthColor} ${bgColor ? 'active' : ''}`}
                                            /* style={{ backgroundColor: bgColor }} */
                                        ></div>
                                    );
                                })}
                            </div>
                            <div className={`strength-text ${strengthColor}`}>
                                {strengthText}
                            </div>
                        </div>
                    )}


                    <ToolTip
                        tooltipLabel='Password Rules'
                        instructions={` At least one uppercase letter\nAt least one lowercase letter\nAt least one number\nMinimum of 8 characters\nMaximum of 32 characters\nYour username and password cannot match\nCannot contain more than three sequencial characters (ABC, BCA, 123)\nCannot contain more than three repeating characters (AAA,111)\nMay include only these special characters: @.-_'\nYour password must not be the same as your last 10 previous passwords\nPassword cannot be in email format"`}
                    >
                        <span><img src={imgSRC} alt="Tooltip img" /></span>
                    </ToolTip>
                   {validationMsg('password')}
                </div>
                <div className="col-sm-12 col-md-5">
                    <TextInput
                        label="Confirm Password"
                        required={true}
                        onInput={()=>clrErr()}
                        type='password'
                        placeholder="Confirm Password"
                        className="w-100 inputField p-3"
                        {...formik.getFieldProps('confirmPassword')}
                        onChange={handleReenterPasswordChange}
                        name={formik.getFieldProps('confirmPassword').name}
                        value={formik.getFieldProps('confirmPassword').value}
                        onBlur={formik.getFieldProps('confirmPassword').onBlur}
                    />
                    {validationMsg('confirmPassword')}
                </div>
                
            </div>
           
        </form>
    );
};

export default ResetPassword;
