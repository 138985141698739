import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Banner } from '../../Atoms';
import { Button } from '../CustomSection/Atoms/';
import classes from './styles.module.css';
import { Toaster } from 'react-hot-toast';
import ServiceHelper from '../../Utils/Helper/MappingHelper';
import appConfig from '../../../appConfig';
import { SHOW_CONTENT_LOADER } from '../../../Store/Slices/Loader';
import DependentsTable from './dependentsTable';
import BeneficiariesTable from "./beneficiariesTable"
import EmployeeTable from "./employeeTable"
import CustomTable from "./customTable"
import $ from 'jquery';
import { UPDATE_INSURED, UPDATE_LOADING, REMOVE_CURRENTSTEP_ALERT_MESSAGE, SET_ALERT_MESSAGE } from '../../../Store/Slices/InsuredInfo/index';
import { UPDATE_CART_ENROLLMENT, UPDATE_CART_ENROLLMENT_COVERAGE_LEVEL, DEPENDENT_REPLATIONSHIP } from '../../../Store/Slices/EmployeeProfile';
import { STORE_IN_REDUX, ENRORSEMNT_PRODUCT_LIST_IDENTIFIER, PRODUCT_RESOURCES_COVERAGE_TITLE, SELECTED_PRODUCT_LIST, PRODUCT_LIST, UPDATE_SUBSBSCRIBED_PRODUCTS, UPDATE_BENEFICIARY_DATA, UPDATE_IF_SUBSCRIBED_PRODUCT_HAS_BENEFICIARIES } from '../../../Store/Slices/Process/index';
import { SET_EMPLOYEE_PROFILE } from '../../../Store/Slices/EmployeeProfile';
import processData, { getFormKeyByProductFamily } from './processData';
import {
  PREVIOUS_STEP, SET_EDIT_FROM_ES,
} from '../../../Store/Slices/StepForm';
import { UPDATE_ENROLLMENT_DATA, STORE_DATA_FOR_CROSS_SELL, STORE_RESPONSE_DATA_FOR_CROSS_SELL, IS_COVERAGE_CANCELLED, UPDATE_ENDORSMENT_DATA, IS_ENROLLED, } from '../../../Store/Slices/Enrollment/index';
import { onLoadAPI } from '../../../Store/Slices/Entities/response/index.js';
import http from '../../../Common/Services/HttpService';
import Loader from '../../Atoms/Loader';
import { filterCriticalIllnessProduct, updateOptionCodesOnSummaryScreen, updatePlanListOnSummaryScreen, waivedPlanStatusArr } from '../CustomSection/Helper/buyUpHelper';
import { getErrorMessages, removeUnwantedProps } from '../CustomSection/Helper/MappingHelper'
import resumeContent from '../CustomSection/Content/ResumeContent.json'
import { useNavigationHook } from '../../Molecules/CustomSection/Hooks/NavigationHook';
import constants from '../CustomSection/Content/Constants.json';
import { reformatAmountToDisplayValueExcessLiability } from '../../Utils/Helper/ValidationHelper';
import { getRelationship } from '../../Utils/Form/StepperHelper';
import _ from 'lodash';
import { formatDollarAmount } from '../../Utils/Helper/ValidationHelper';
import { formatDollarAmountWithDecimals } from '../../Utils/Helper/ValidationHelper';


const filterSubscribedProducts = (productData) => async (dispatch) => {
  const unsubscribedStatusArr = ['cDeclined', 'cTerminated', 'cNeverInForce'];
  if (productData && productData.length) {
    let subscribedProducts = productData.filter((product) => {
      return unsubscribedStatusArr.indexOf(product?.subscribedProduct?.status) === -1
    })
    if (subscribedProducts && subscribedProducts.length) {
      let subscribedProductBuids = subscribedProducts.map((product) => {
        return product?.subscribedProduct?.productCode?.buid || ""
      })
      await dispatch(UPDATE_SUBSBSCRIBED_PRODUCTS(subscribedProductBuids))
    }
  }
}

const storeBeneficiaryConfig = (configData) => async (dispatch) => {
  let beneficiaryConfigData = [];
  if (configData && configData.length) {
    configData.map((config) => {
      if (config && config.coverageInformation && config.coverageInformation.length) {
        config.coverageInformation.map((item) => {
          beneficiaryConfigData.push({
            'productBuid': config?.productId,
            'config': item?.allowedBeneficiaryKind
          })
        })
      }
    })

    if (beneficiaryConfigData && beneficiaryConfigData.length) {
      await dispatch(UPDATE_BENEFICIARY_DATA(beneficiaryConfigData))
    }
  }
}

const saveBeneficiaryConfigData = ({ subscribedProducts, beneficiaryConfigData }) => async (dispatch) => {
  let configArr = []
  if (beneficiaryConfigData && beneficiaryConfigData.length && subscribedProducts && subscribedProducts.length) {
    beneficiaryConfigData.map((item) => {
      if (subscribedProducts.indexOf(item.productBuid) > -1) {
        if (item.config && item.config.length) {
          item.config.map((element) => {
            configArr.push(element)
          })
        }
      }
    })
  }
  configArr = _.uniq(configArr)
  if (configArr.indexOf("cDefineBeneficiaries") > -1) {
    await dispatch(UPDATE_IF_SUBSCRIBED_PRODUCT_HAS_BENEFICIARIES(true))
  }
}

const ProductTable = (props) => {
  const { currentStep, previousStep, handleGoToSpecificStep } = useNavigationHook();
  const {
    hideEditButton,
    isSummaryPage
  } = props;
  let history = useHistory();
  const location = useLocation();
  const [CIproductbuid, setCIProductBuid] = useState('');
  const [ULproductbuid, setULProductBuid] = useState('');
  const [buyUpDetails, setBuyUpDetails] = useState('');
  const [ProductfamilyBuid, setProductFamily] = useState('')
  const [procuctNames, setprocuctNames] = useState('')
  const [coverageId, setCoverageId] = useState('')
  const [crossSellApplicable, setcrossSellApplicable] = useState('')
  const [productDetails, setProductDetails] = useState('')
  const [finalEnrollementProcess, SetFinalEnrollementProcess] = useState('')
  const [beneficiaryConfigData, SetBeneficiaryConfigData] = useState([])
  const [updateProcessData, setUpdateProcessData] = useState({})
  const [coverageData, setCoverageData] = useState({});
  const [contractRatingInfo, setContractRatingInfo] = useState({});
  const [isSummaryResponse, setSummaryResponse] = useState(false)
  const dispatch = useDispatch();
  const serviceHelper = new ServiceHelper();
  const storeFromRedux = useSelector((storeState) => storeState);
  let currentReduxState = useSelector((storeState) => storeState);

  const enrollmentData = currentReduxState.enrollment?.enrollmentData
  const currentEnrollmentType = currentReduxState.enrollment?.currentEnrollmentType

  const enrollmentProcessBuid = currentReduxState?.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID
  const currentBenifitendorsementProcessBuid = currentReduxState?.entities?.response?.onLoadResponseList?.initEndorsementProcessData?._Result?.processEntity?.buid
  const isQLEEnrollment = useSelector((storeState) => storeState?.enrollment?.isQLE)

  const currentBenifitendorsementContentData = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.initEndorsementProcessData);
  const qleEndorsementProcessContentData = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.initNewIndividualEndorsementProcess);

  const qleEndorsementProcessBuid = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.initNewIndividualEndorsementProcess?._Result?.processEntity?.buid
  );

  const adminEndorsementProcessBuid = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.AdminEndorsementProcess?._Result?.processEntity?.buid
  );
  const adminEndorsementProcessData = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.AdminEndorsementProcess);
  const reviewUpdateAndQLEBuid = currentBenifitendorsementProcessBuid || qleEndorsementProcessBuid

  const endorsementProcessBuid = reviewUpdateAndQLEBuid || adminEndorsementProcessBuid

  const reviewUpdateAndQLEContent = currentBenifitendorsementContentData || qleEndorsementProcessContentData
  const currentBenifitendorsementContent = reviewUpdateAndQLEContent || adminEndorsementProcessData

  const productList = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.EndorsementListOfEligibleProducts?._Result
  );
  const endorsementProductDetails = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.EndorsementProductDetails
  );

  const productRateInformation = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.ContractRatingInformation
  );
  const previousYearEnrolledRateInformation = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.previousRateInformation);

  const employeeData = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.PersonalInformation?.personalInformation
  );
  const productResourceData = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.ProductResourceData
  );
  const insuredDependents = useSelector((state) => state.enrollment?.enrollmentData?.contract?.insureds);
  const cartItemListCoverageLevel = currentReduxState?.employeeProfile?.cartItemListCoverageLevel || []
  const currentBenifitButton = useSelector(
    (storeState) => storeState?.employeeProfile?.curreneBenifitButton
  );
  const reasonType = useSelector(
    (storeState) => storeState?.employeeProfile?.reasonType
  );
  let getCurrerntYearEnrollments = currentBenifitButton === 'undefined.Get Started' && reasonType === 'AE'? useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.getEnrollmentsAE?._Result?.subscribedElements
  ) : useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.getCurrerntYearEnrollments?._Result?.subscribedElements
  );
  const getCurrerntYearEnrollmentsInsureds = currentBenifitButton === 'undefined.Get Started' && reasonType === 'AE'? useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.getEnrollmentsAE?._Result?.insureds
  ) : useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.getCurrerntYearEnrollments?._Result?.insureds
  );

  const individualcontracts = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.individualcontracts?._Result
  ) || [];

  const GBPDetails = useSelector((storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result);

  const hasWLData = GBPDetails?.contract?.additionalDatas?.find(item => item.fieldCode === "DD_00159" && item.fieldValue === 'Yes');

  const enrollmentType = useSelector(
    (storeState) => storeState?.employeeProfile?.enrollmentType
  );
 
  const currentYearRason = useSelector(
    (storeState) => storeState?.employeeProfile?.currentYearRason
  );

  
  const GBPDetailsWithLastRenewal = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.gbpContractsWithLastRenewal?._Result);

  const subscribedElements = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.subscribedcontracts?._Result[0]?.subscribedElements
  )
  const contractData = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.getMemberActiveContracts?._Result
  );

  const externalClientId = useSelector(
    (storeState) =>
      storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.legalEntity?.identifier?.externalClientId
  );
  const internalClientId = useSelector(
    (storeState) =>
      storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.person?.identifier?.internalClientId
  );
  const additionalDynamicDatas = useSelector(
    (storeState) =>
      storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.employee?.additionalDynamicDatas
  );
  const payPeriodContent = additionalDynamicDatas?.filter(
    (data) => data.value.fieldCode == 'EMPD_00002'
  );
  const payPeriod = payPeriodContent ? payPeriodContent[0]?.value?.valueCode : 12;

  const isCoverageCancelled = useSelector((storeState) => storeState?.enrollment?.isCoverageCancelled)
  const isAdmin = useSelector((storeState) => storeState?.callCenter?.isAdmin);
  const getListOfEligibleProducts = useSelector((storeState) => storeState?.process?.productList);
  let lastRenewalDate = GBPDetails?.contract?.lastRenewalDate;
  let enrollmentPeriodKind = GBPDetails?.currentEnrollmentPeriod?.enrollmentPeriod?.enrollmentPeriodKind;
  const gbpcontractsLinearOrNonLinear = useSelector((storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result?.contract)
  const getGBPResourcesForMember = useSelector(
    (storeState) => storeState?.employeeProfile?.getGBPResourcesForMember
  );
  const { employeeProfile } = useSelector((storeState) => storeState);
  const isDependentModified = employeeProfile?.isDependentModified;
  const isDependentNotModifed = employeeProfile?.isQLEDependentNotModifed
  const { dependentRelationShip } = useSelector((storeState) => storeState.employeeProfile);
  const finalContractData = useSelector((storeState) => storeState?.enrollment?.enrollmentData?.contract?.subscribedElements);
  const finalContractRatingInfo = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.ContractRatingInformation);

  const covergeCost = productRateInformation?.coverageCost
  let previousYearCoverageCost = previousYearEnrolledRateInformation?.coverageCost
  let currentCoverageYearCost = covergeCost?.data
  const productCovergeCost = previousYearCoverageCost ? previousYearCoverageCost : currentCoverageYearCost

  let previousYearAnualCost = previousYearEnrolledRateInformation?.AnualCost
  const anualCost = productRateInformation?.AnualCost
  let currentYearAnualCost = anualCost?.data
  const productAnualCost = previousYearAnualCost ? previousYearAnualCost : currentYearAnualCost
  const preferredContactDetailsFromRedux = useSelector((storeState) => storeState?.employeeProfile?.preferredContactDetails);
  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  const isEnrolled = useSelector((storeState) => storeState?.enrollment?.isEnrolled)
  const subscribedProducts = useSelector((storeState) => storeState?.process?.subscribedProducts)
  const beneficiaryData = useSelector((storeState) => storeState?.process?.beneficiaryData)
  const productDeclinedLocally = useSelector((storeState) => storeState?.buyUp?.basePlanDeclinedLocally)
  const verbiageConfig = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.summaryScreenConfiguration?._Result?.data
  )

  const isSpecialEnrollment = reasonType === 'OP';

  const isWLCoverage = gbpcontractsLinearOrNonLinear?.additionalDatas?.find(data => data?.fieldCode === "DD_00159" && data?.valueCode !== "No") ? true : false;

  useEffect(() => {
  }, [previousStep, currentStep]);

  // Variables for beneficiaries
  const [showBeneficiarySection, setShowBeneficiarySection] = useState(true);

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;
  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
  const productResources = useSelector((storeState) => storeState?.process?.productResources);
  const isQleProductModified = (isDependentModified || isDependentNotModifed) && qleEndorsementProcessBuid ? true : false
  let previousOngoingDate1 = GBPDetails?.contract?.additionalDatas?.filter((elm) => elm?.fieldCode === 'DD_00139')?.[0]?.fieldValue || ""
  let previousOngoingDate2 = GBPDetailsWithLastRenewal?.contract?.additionalDatas?.filter((elm) => elm?.fieldCode === 'DD_00139')?.[0]?.fieldValue || ""
  let previousOngoingDate = previousOngoingDate2 || previousOngoingDate1

  let isReviewOnly = false
  if ((enrollmentPeriodKind === 'cOngoingEnrollment' || enrollmentPeriodKind === 'cAnnualEnrollment') &&
    (Date.parse(today) > Date.parse(previousOngoingDate)) &&
    (Date.parse(today) < Date.parse(lastRenewalDate))
  ) {
    isReviewOnly = true
  }

  let isRateinformation = isReviewOnly && (currentBenifitButton === 'undefined.currentYearReviewUpdate' || currentBenifitButton === '.YesButton')
  let isReviewPageOnly = isRateinformation;
  if(isRateinformation){
    getCurrerntYearEnrollments = getCurrerntYearEnrollments?.filter((product,index) => {
      let coverageEffectiveDate = product?.coverages?.[0]?.subscribedOption?.effectiveDate;
      if (enrollmentPeriodKind === 'cOngoingEnrollment' &&
                (Date.parse(today) > Date.parse(previousOngoingDate)) &&
                (Date.parse(today) < Date.parse(lastRenewalDate))
              ) {
                if ((Date.parse(coverageEffectiveDate) < Date.parse(lastRenewalDate))) {
                  return product
                }
      }
    })
  }
  
  let productListBuids = getCurrerntYearEnrollments?.map((item) => {
    return {
      productBuid: item?.subscribedProduct?.productCode?.buid
    }
  })
  let updatedState = removeUnwantedProps(storeFromRedux, resumeContent.resumeUnwantedProps);
  let activeContractDatas = contractData ? contractData?.filter(e => e.status === 'cActive') : [];

  useEffect(() => {
    (async () => {
      await dispatch(saveBeneficiaryConfigData({
        subscribedProducts: subscribedProducts,
        beneficiaryConfigData: beneficiaryData
      }))
    })()

  }, [subscribedProducts, beneficiaryData])

  //endorsemnt and QLE API call
  const handleReviewAndUpdateOnLoad = async () => {
    dispatch(UPDATE_LOADING(true));
    return await http
      .post(appConfig.SSP_MID_URL + `/v1/endorsementOnLoad`, {
        enrollmentProcessBuid: endorsementProcessBuid,
        productListBuids: productListBuids,
        memberContractBuid: activeContractDatas?.[0]?.contract?.buid,
        externalStepContext: {
          enrollmentProcessBuid: endorsementProcessBuid,
          portalStep: "EnrollmentSummary",
          reduxState: updatedState,
        },
        wLCoverage: isWLCoverage? internalClientId : ''
      })
      .then(async (resp) => {
        const data = resp?.data?.data;
        const summaryresponse = data ? true : false
        setSummaryResponse(summaryresponse)
        dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
        // dispatch(SET_ALERT_MESSAGE({
        //   type: 'error',
        //   errorCode: '520',
        //   continue: true,
        //   id: 'getListOfEligibleProducts',
        //   show: true,
        //   page: currentStep,
        // }));
        for (const key in data) {
          let result = { _Result: data[key].data }
          switch (key) {
            case 'getListOfEligibleProducts':
              if (data[key]?.success) {
                dispatch(onLoadAPI({ 'EndorsementListOfEligibleProducts': result }));
              }
              else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getListOfEligibleProducts',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'productDetails':
              if (data[key]?.success) {
                setProductDetails(data[key].data)
                dispatch(onLoadAPI({ 'EndorsementProductDetails': data[key].data }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'productDetails',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'getContractRatingInformation':
              if (data[key]?.success) {
                dispatch(onLoadAPI({ 'ContractRatingInformation': data[key].data }));
                setContractRatingInfo(data[key].data);
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getContractRatingInformation',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'getCoveredPersonTypeConfiguration':
              if (data[key]?.success) {
                const getCoveredPersonType = data[key].data || [];
                dispatch(DEPENDENT_REPLATIONSHIP(getCoveredPersonType));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getCoveredPersonTypeConfiguration',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'getProductsResourcesFromProcess':
              if (data[key]?.success) {
                dispatch(onLoadAPI({ 'ProductResourceData': data[key].data }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getProductsResourcesFromProcess',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'getSubscribedCoverageBeneficiaryConfiguration':
              if (data[key]?.success) {
                await dispatch(storeBeneficiaryConfig(data[key].data))
                SetBeneficiaryConfigData(data[key].data)
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getSubscribedCoverageBeneficiaryConfiguration',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'searchPolicyProcessResourcesCoverage':
              if (data[key]?.success) {
                const productResourcesCoverageTitle = data[key].data;
                dispatch(PRODUCT_RESOURCES_COVERAGE_TITLE({ productResourcesCoverageTitle }));
                dispatch(onLoadAPI({ productResourcesCoverageTitle }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'searchPolicyProcessResourcesCoverage',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'moveNext':
              if (data[key]?.success) {
                dispatch(onLoadAPI({ 'moveNext': result }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'moveNext',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'executeUntilLastStep':
              if (data[key]?.success) {
                setCoverageData(data[key].data?.contract?.subscribedElements);
                await dispatch(filterSubscribedProducts(data[key].data?.contract?.subscribedElements))
                dispatch(UPDATE_ENROLLMENT_DATA(data[key].data));
                dispatch(UPDATE_ENDORSMENT_DATA(data[key].data))
                dispatch(onLoadAPI({
                  'ValidateContractData': result,
                }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'executeUntilLastStep',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'beneficiariesData':
              if (data[key]?.success) {
                const beneficiariesData = data[key].data;
                dispatch(onLoadAPI({ 'beneficiariesData': beneficiariesData }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'beneficiariesData',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            default:
              dispatch(onLoadAPI({ [key]: result }));
          }
        }
        dispatch(UPDATE_LOADING(false));
        return true;
      })
      .catch((e) => {
        dispatch(UPDATE_LOADING(false));
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'endorsementOnLoadApi',
          show: true,
          page: currentStep,
        }));
        return false;
      });
  };
  //Enrollment API call
  const handlePreConfirmationOnLoad = async () => {
    dispatch(UPDATE_LOADING(true));
    return await http
      .post(appConfig.SSP_MID_URL + `/v1/enrollmentOnLoad`, {
        enrollmentProcessBuid: enrollmentProcessBuid,
        externalStepContext: {
          enrollmentProcessBuid: endorsementProcessBuid,
          portalStep: "EnrollmentSummary",
          reduxState: updatedState,
        },
        wLCoverage: isWLCoverage? internalClientId : ''
      })
      .then(async (resp) => {
        const data = resp?.data?.data;
        const summaryresponse = data ? true : false
        setSummaryResponse(summaryresponse)
        dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
        for (const key in data) {
          let result = { _Result: data[key].data }
          switch (key) {
            case 'getContractRatingInformation':
              if (data[key]?.success) {
                dispatch(onLoadAPI({ 'ContractRatingInformation': data[key].data }));
                setContractRatingInfo(data[key].data);
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getContractRatingInformation',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'getSubscribedCoverageBeneficiaryConfiguration':
              if (data[key]?.success) {
                await dispatch(storeBeneficiaryConfig(data[key].data))
                SetBeneficiaryConfigData(data[key].data)
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getSubscribedCoverageBeneficiaryConfiguration',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'updateProcess':
              if (data[key]?.success) {
                setUpdateProcessData(result)
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'updateProcess',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'executeUntilLastStep':
              if (data[key]?.success) {
                setCoverageData(data[key].data?.contract?.subscribedElements);
                await dispatch(filterSubscribedProducts(data[key].data?.contract?.subscribedElements))
                dispatch(UPDATE_ENROLLMENT_DATA(data[key].data));
                dispatch(onLoadAPI({
                  'ValidateContractData': result,
                }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'executeUntilLastStep',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'searchPolicyProcessResourcesCoverage':
              if (data[key]?.success) {
                const productResourcesCoverageTitle = data[key].data;
                dispatch(PRODUCT_RESOURCES_COVERAGE_TITLE({ productResourcesCoverageTitle }));
                dispatch(onLoadAPI({ productResourcesCoverageTitle }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'searchPolicyProcessResourcesCoverage',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'beneficiariesData':
              if (data[key]?.success) {
                const beneficiariesData = data[key].data;
                dispatch(onLoadAPI({ 'beneficiariesData': beneficiariesData }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'beneficiariesData',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            default:
              dispatch(onLoadAPI({ [key]: result }));
          }
        }
        dispatch(UPDATE_LOADING(false));
        return true;
      })
      .catch((e) => {
        dispatch(UPDATE_LOADING(false));
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'enrollmentOnLoad',
          show: true,
          page: currentStep,
        }));
        return false;
      });
  };
  const rateInformation = async () => {
    dispatch(UPDATE_LOADING(true));
    const resp = await http
      .post(appConfig.SSP_MID_URL + '/rateInformation', {
        additionalInformation: {
          memberContractBuid: activeContractDatas?.[0]?.contract?.buid,
        },
      }).then(async (resp) => {
        if (resp.data && resp.status === 200) {
          dispatch(onLoadAPI({ ...{ [`previousRateInformation`]: resp.data, isAPICallInProgress: false } }));
        }
        dispatch(UPDATE_LOADING(false));
      })
      .catch((e) => {
        dispatch(UPDATE_LOADING(false));
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      if (location.pathname !== '/confirmationScreen') {
        if (endorsementProcessBuid && enrollmentProcessBuid) {
          await handlePreConfirmationOnLoad();
        } else if (endorsementProcessBuid) {
          await handleReviewAndUpdateOnLoad();
        } else if (enrollmentProcessBuid) {
          await handlePreConfirmationOnLoad();
        } else if (isRateinformation) {
          rateInformation();
        }
      }
    };

    fetchData();
  }, [endorsementProcessBuid]);

  // useEffect(() => {
  //   if (typeof updateProcessData === 'object' && Object.keys(updateProcessData)?.length > 0) {
  //     let updateData = updateProcessData
  //     let externalStepContext = JSON?.parse(updateData?.processEntity?.externalStepContext);
  //     dispatch(UPDATE_EXTERNAL_STEP_INFO(externalStepContext));
  //   }

  // }, [typeof updateProcessData !== 'undefined'])

  const beneficiariesProduct = props?.layoutData?.beneficiariesProducts



  useEffect(() => {
    const fetchData = async () => {
      if (productList && productList.length && !productDeclinedLocally?.employee && !productDeclinedLocally?.spouse) {
        let { productBuid, criticalIllnessProduct } = await filterCriticalIllnessProduct({ productList });
        setCIProductBuid(productBuid);
        if (criticalIllnessProduct && criticalIllnessProduct.length) {
          if (history && history.location && history.location.pathname === "/confirmationScreen") {
            let optionCodes = await dispatch(updateOptionCodesOnSummaryScreen({ criticalIllnessProduct, coverageData: finalContractData, contractRatingInfo: finalContractRatingInfo, productBuid }));
            setBuyUpDetails(optionCodes);
          } else {
            let optionCodes = await dispatch(updateOptionCodesOnSummaryScreen({ criticalIllnessProduct, coverageData, contractRatingInfo, productBuid }));
            setBuyUpDetails(optionCodes);
          }

        }

        //set UL product BUID
        productList.map((product) => {
          if (product.memberOfferedProduct?.product?.productFamily === "Universal Life") {
            setULProductBuid(product.memberOfferedProduct?.product?.buid);
          }
        });
      }
    };

    fetchData();
  }, [productList, coverageData, contractRatingInfo, productDeclinedLocally]);




  useEffect(() => {
    dispatch(SET_EMPLOYEE_PROFILE(employeeData));
  }, [employeeData]);



  useEffect(() => {
    if ((productList && productList.length >= 0)) {
      let UpdatedValue = []
      UpdatedValue.push(currentBenifitendorsementContent, { _Result: productList })
      dispatch(PRODUCT_LIST(productList));
      dispatch(STORE_IN_REDUX(UpdatedValue))
    }
    dispatch(SET_EDIT_FROM_ES(false));
  }, [productList])

  let coverageTypeInformation = []
  let dependentEligiblity = []
  const eligibleProducts = getListOfEligibleProducts?.map((prod) => {
    let coverge = prod?.coverages?.[0]
    let memberProductBuid = prod?.memberOfferedProduct?.product?.buid
    const productResource = productResources[memberProductBuid];
    const resource = productResource?.find(item => item?.code === 'PRODTITLE');
    let insuredConfiguration = coverge?.memberOfferedCoverage?.option?.insuredConfiguration
    let multiCovered = insuredConfiguration?.coverageMode
    let coveredDependants = insuredConfiguration?.coveredDependants
    if (resource?.content === 'Critical Illness') {
      let coveredType = multiCovered === 'cmMultiCovered' && (coveredDependants.includes("cCoveredChild") ||
        coveredDependants.includes("cCoveredSpouse") ||
        coveredDependants.includes("cCoveredChild"));
      coverageTypeInformation.push({ coveredInfo: coveredType })
    }
    return prod?.coverages?.map((coverge, index) => {
      let checkInsuredConfigurations = coverge?.memberOfferedCoverage?.option?.insuredConfiguration
      let checkCoveredDependant = checkInsuredConfigurations?.coveredDependants
      let dependentcoveredType = (checkCoveredDependant.includes("cCoveredChild") ||
        checkCoveredDependant.includes("cCoveredSpouse"));
      dependentEligiblity.push({ dependentInfo: dependentcoveredType })

    })
  })
  const getProductDetail = enrollmentData
  const coveredInsuredBuids = getProductDetail?.contract?.insureds
  const gbpContractData = GBPDetails?.subscribedElements;
  let currentBenifitDate = Date.parse(today) > Date.parse(previousOngoingDate) && (Date.parse(today) < Date.parse(lastRenewalDate))
  const checkEvergereenAndCancel = gbpContractData?.map((prod) => {
    let items = prod?.subscribedProduct?.additionalDatas
    const checkevergerrenFalse = items?.some(eachObj => (eachObj?.fieldCode === 'IEGP' && eachObj.fieldValue === false))
    const checkCancelCoverage = items?.some(eachObj => (eachObj?.fieldCode === 'DD_00179' && (eachObj?.valueCode === 'ATAT' || eachObj?.valueCode === 'ATOE')))
    const checkevergerrenTrue = items?.some(eachObj => (eachObj?.fieldCode === 'IEGP' && eachObj.fieldValue === true)) && prod?.subscribedProduct?.status === "cActive"
    const checkEnrollTrue = items?.some(eachObj => (eachObj?.fieldCode === 'IEGP' && eachObj.fieldValue === true)) && prod?.subscribedProduct?.status === "cActive"
    return {
      cancelCovBuid: prod?.memberOfferedProduct?.bUID,
      checkevergerrenFalse: checkevergerrenFalse,
      checkCancelCoverage: checkCancelCoverage,
      checkevergerrenTrue: checkevergerrenTrue,
      checkEnrollTrue: checkEnrollTrue
    }
  })
  const checkEverGreen = productList?.map((product) => {
    const checkProduct = product?.subscribedProduct?.additionalDatas
    const everGreenProductBuid = product?.memberOfferedProduct?.bUID
    const productFamily = product?.memberOfferedProduct?.product?.productFamily
    const filterData = Array.isArray(checkProduct) && checkProduct.find(eachObj => eachObj?.fieldCode === 'IEGP' && eachObj.fieldValue == true);
    const checkevergerrenFalse = checkProduct?.some(eachObj => (eachObj?.fieldCode === 'IEGP' && eachObj.fieldValue === false))
    const checkCancelCoverage = checkProduct?.some(eachObj => (eachObj?.fieldCode === 'DD_00179' && (((eachObj?.valueCode === 'ATOE' && reasonType === "AE" && currentBenifitButton === 'undefined.Get Started')) || (eachObj?.valueCode === 'ATAT'))))
    const checkevergerrenTrue = checkProduct?.some(eachObj => (eachObj?.fieldCode === 'IEGP' && eachObj?.fieldValue === true)) && product?.subscribedProduct?.status === "cActive"
    const checkEnrollTrue = checkProduct?.some(eachObj => (eachObj?.fieldCode === 'IEGP' && eachObj?.fieldValue === true)) && product?.subscribedProduct?.status === "cActive"
    const evergreenAtSubscription = checkProduct?.some(eachObj => (eachObj?.fieldCode === 'DD_00205' && eachObj?.valueCode === 'Yes'))
    const productStatus = product?.subscribedProduct?.status
    return {
      everGreenProductBuid: everGreenProductBuid,
      productName: productFamily,
      checkCancelCoverage: checkCancelCoverage,
      checkevergerrenTrue: checkevergerrenTrue,
      checkevergerrenFalse: checkevergerrenFalse,
      checkEnrollTrue: checkEnrollTrue,
      evergreenAtSubscription: evergreenAtSubscription,
      productStatus: productStatus

    }
  })
  const everGreenProductName = endorsementProductDetails?.map(el => {
    return {
      everGreenProductName: el?.product?.productFamily,
      everGreenProductBuid: el?.product?.buid,
    }
  })

  useEffect(() => {
    if (everGreenProductName?.length > 0) {
      dispatch(ENRORSEMNT_PRODUCT_LIST_IDENTIFIER([...everGreenProductName]));
    }
  }, [productList, endorsementProductDetails])


  const getCoverageDetails = getProductDetail?.contract?.subscribedElements
  let enrollmentInsured = getProductDetail?.contract?.insureds;
  const insureds = isRateinformation ? getCurrerntYearEnrollmentsInsureds : enrollmentInsured
  const form = {
    "Accident": "CoverageSelection",
    "Pet Insurance": "PetAssureNation",
    "Whole Life": "WLCoverage",
    "ID Theft": "IdTheft",
    "Supplemental Health Insurance": "SupplementalHealthInsurance",
    "Legal": "LegalCoverage",
    "Travel Insurance": "Travel",
    "Pet Insurance - PETPlus": "PetPlus",
    "Pet Insurance - Pet Assure": "PetAssure",
    "Hospital Indemnity": "HospitalIndemnity",
    "Critical Illness": "CriticalIllness",
    "Liability": "ExcessLiability",
    "Universal Life": "UniversalLife"
  };

  const productLists = currentReduxState?.process?.productFamilyIdentifier;
  let endrosemntProductFamilyIdentifier = {};
  if (everGreenProductName?.length > 0) {
    everGreenProductName?.map((e) => Object.assign(endrosemntProductFamilyIdentifier, { [e.everGreenProductName]: e.everGreenProductBuid }));
  }

  const costPerCoverage = productCovergeCost?.subscribedElements?.map(element => {
    return element?.coverages?.map(coverage => {
      return {
        amount: `$${coverage?.rate?.amount?.amount?.toFixed(2)}`,
        rateProductIdentifier: coverage?.identifier?.bUID
      }
    })
  });
  let costFlattenedData = costPerCoverage?.flatMap(arr => arr);
  const anualCostPerCoverage = productAnualCost?.subscribedElements?.map(element => {
    return element?.coverages?.map(coverage => {
      return {
        anualAmount: `$${coverage?.rate?.amount?.amount?.toFixed(2)}`,
        anualCostProductIdentifier: coverage?.identifier?.bUID
      }
    })
  });
  let AnualCostFlattenedData = anualCostPerCoverage?.flatMap(arr => arr);
  let filterEverGreenProducts = []
  getCurrerntYearEnrollments?.forEach((obj, index) => {
    let a = getCoverageDetails?.filter((elem) => elem?.subscribedProduct?.productCode?.buid === obj?.memberOfferedProduct?.bUID || elem?.subscribedProduct?.productCode?.buid === obj?.subscribedProduct?.productCode?.buid)
    if (a?.length === 0) { filterEverGreenProducts?.push(obj) }
  });
  let enrolledProductList = getCoverageDetails?.concat(filterEverGreenProducts);
  let enrolledProductDeatils = [];
  if((currentBenifitButton === 'undefined.Get Started' || currentBenifitButton === 'undefined.reviewUpdate') &&  reasonType === 'AE'){
    enrolledProductList?.forEach((obj, index) => {
      let a = getCurrerntYearEnrollments?.filter((elem) => elem?.subscribedProduct?.productCode?.buid === obj?.memberOfferedProduct?.bUID || elem?.subscribedProduct?.productCode?.buid === obj?.subscribedProduct?.productCode?.buid)
      if (a?.length > 0) { 
        enrolledProductDeatils?.push(obj) 
      }else if(obj?.subscribedProduct?.productCode?.productIdentifier?.productCode === "WLVOYA"){ 
        enrolledProductDeatils?.push(obj)
      }
    });
  }else{
    enrolledProductDeatils = enrolledProductList
  }
  
  let uniqueProductCodes = {};
  // Filter the data array based on the unique product codes
  let filteredEligibleAndSubcribedData = enrolledProductDeatils?.filter(item => {
    const productCode = item?.subscribedProduct?.productCode?.productIdentifier?.productCode;
    if (!uniqueProductCodes[productCode]) {
      uniqueProductCodes[productCode] = true;
      return true;
    }
    return false;
  });

  

  let finalEligibleProduct = isRateinformation ? (getCurrerntYearEnrollments || []) : (filteredEligibleAndSubcribedData || [])
  const notEnrolledProducts = getCurrerntYearEnrollments?.map((product) => {
    const isEverGreenTrueFalse = product?.isEvenGreen
    const waivedStatus = product?.subscribedProduct?.status === "cDeclined" || product?.subscribedProduct?.status === "cTerminated" || product?.subscribedProduct.status === "cNeverInForce"
    let productStatus = product?.subscribedProduct?.status === '' || waivedStatus ? true : false
    let productBuid = product?.subscribedProduct?.productCode?.buid
    let productName = product?.subscribedProduct?.productName
    return {
      isNotEnrolledStatus: productStatus,
      notEnrolledBuid: productBuid,
      productName: productName,
      isEverGreenTrueFalse: isEverGreenTrueFalse
    }
  })
  const mappedData2 = {};
  gbpContractData?.forEach((item) => {
    const productCode = item.subscribedProduct?.productCode?.buid;
    mappedData2[productCode] = item;
  });
  let sortOfListOfProducts = [...finalEligibleProduct]?.sort((a, b) => {
    const aBUID = a?.subscribedProduct?.productCode?.buid;
    const bBUID = b?.subscribedProduct?.productCode?.buid;
    const productCodeA = a?.subscribedProduct?.productCode?.productIdentifier?.productCode;

    const aIndex = gbpContractData?.findIndex((item) => item?.memberOfferedProduct?.product?.buid === aBUID);
    const bIndex = gbpContractData?.findIndex((item) => item?.memberOfferedProduct?.product?.buid === bBUID);

    
    if (productCodeA === 'WLVOYA') {
      return 1; // Move product A to the last
    } else {
      return aIndex - bIndex; // Keep the order unchanged
    }
  });

  let filterTravelProductList = sortOfListOfProducts?.filter(item => item?.subscribedProduct?.productCode?.productIdentifier?.productCode !== "TRAVELLIMT#0");
  let findTravelProduct = sortOfListOfProducts?.filter(item => item?.subscribedProduct?.productCode?.productIdentifier?.productCode === "TRAVELLIMT#0");
  let isTravelProduct = findTravelProduct?.[0]?.subscribedProduct?.productCode?.productIdentifier?.productCode === "TRAVELLIMT#0"
  const isFilterTravelProduct = externalClientId?.externalClientId === 'NATCOL' && qleEndorsementProcessBuid && isTravelProduct
  const listOfProducts = isFilterTravelProduct ? filterTravelProductList : sortOfListOfProducts
  let wLAAmount = 0
  let wLCoveragePerPeriod = 0
  let productMapdetails = listOfProducts?.map(item => {
    const checkEvergreenProduct = checkEverGreen?.filter((value) => value?.everGreenProductBuid === item?.subscribedProduct?.productCode?.buid)
    const isEnroll = checkEvergreenProduct?.length > 0 ? true : false
    // const isEvergereenEnroll = checkEvergreenProduct?.find(eachObj => eachObj?.checkEnrollTrue === true) && (item?.subscribedProduct?.status === "cActive") ? true : false
    const filtercancelCoverage = checkEvergereenAndCancel?.filter((value) => value?.cancelCovBuid === item?.subscribedProduct?.productCode?.buid)
    const checkenrollEndorsement = filtercancelCoverage?.find(eachObj => eachObj?.checkEnrollTrue === true) && (item?.subscribedProduct?.status === "") ? true : false
    const checkEnroll = checkEvergreenProduct?.find(eachObj => eachObj?.fieldCode === 'IEGP' && eachObj?.fieldValue === true) ? true : false
    // const notEnrolled = item?.subscribedProduct?.status === '' ? true : false
    const waivedStatus = item?.subscribedProduct?.status === "cDeclined" || item?.subscribedProduct?.status === "cTerminated" || item?.subscribedProduct.status === "cNeverInForce"
    //
    const filtercancelCoverage1 = checkEverGreen?.filter((value) => value?.everGreenProductBuid === item?.subscribedProduct?.productCode?.buid)
    const cancelCoverage = filtercancelCoverage1?.find(eachObj => eachObj?.checkCancelCoverage == true && eachObj?.checkevergerrenFalse == true) ? true : false
    const checkEverGreenAtsubscription = filtercancelCoverage1?.find(eachObj => eachObj?.evergreenAtSubscription === true) ? true : false
    const isEvergereenEdit = filtercancelCoverage1?.find(eachObj => eachObj?.checkevergerrenTrue === true) && (item?.subscribedProduct?.status === "cActive" || waivedStatus) ? true : false

    const checkEvergereenEdit = isEvergereenEdit && !checkEverGreenAtsubscription ? true : false
    //If coverages are empty
    //
    let productbuid = item?.subscribedProduct?.productCode?.buid
    let notEnrolledProductsCheck = notEnrolledProducts?.some(item => item.notEnrolledBuid === productbuid && item.isNotEnrolledStatus);
    //
    const isDependentModifiedTrue = isDependentModified && currentBenifitendorsementProcessBuid ? true : false
    const isEnrolledTrue = isEnrolled?.includes(productbuid) || isDependentModifiedTrue
    let atSubscriptionEnroll = isEnrolledTrue && checkEverGreenAtsubscription
    //newfix
    let isEverGreenEnrollTrueFalse1 = notEnrolledProducts?.some(item => item.notEnrolledBuid === productbuid && item.isEverGreenTrueFalse === true && item.isNotEnrolledStatus === true);
    let isEverGreenEditTrueFalse1 = notEnrolledProducts?.some(item => item.notEnrolledBuid === productbuid && item.isEverGreenTrueFalse === true && checkEvergereenEdit === true);
    let isEditDependent = isDependentModified && item?.coverages?.length > 0  && item?.subscribedProduct?.status === 'cActive' ? true : false
    const notEnrolled = (!isQleProductModified && (!isEnrolledTrue ? isEditDependent? false: (notEnrolledProductsCheck) : false))
    //const notEnrolled = (!isQleProductModified && (!isEnrolledTrue ? (notEnrolledProductsCheck) : false))
    //const isEverGreenEnrollTrueFalse = (!isQleProductModified && (!isEnrolledTrue ? isEverGreenEnrollTrueFalse1 : false))
    let isEverGreenEnrollTrueFalse = (!isQleProductModified && (!isEnrolledTrue ? isEditDependent? false: isEverGreenEnrollTrueFalse1 : false))
    isEverGreenEnrollTrueFalse = !isEnroll ? false : isEverGreenEnrollTrueFalse
    let isEverGreenEditTrueFalse = !isEverGreenEnrollTrueFalse ? isEverGreenEditTrueFalse1 : false
    const memberOfferedProductBuid = getListOfEligibleProducts?.find(obj => obj?.memberOfferedProduct?.bUID === productbuid)?.bUID;

    if (item?.coverages?.length == 0) {
      const petProductStatusProject = item?.subscribedProduct?.status === "cProject"
      const petProductStatusActive = item?.subscribedProduct?.status === "cActive"
      const petProductStatusDeclined = item?.subscribedProduct?.status === "cDeclined" || item?.subscribedProduct?.status === "cTerminated" || item?.subscribedProduct.status === "cNeverInForce"
      const petProductWaviedStatus = (((petProductStatusActive || petProductStatusProject || petProductStatusDeclined))) ? true : false
      const eligibleProductBuids = item?.memberOfferedProduct?.bUID
      let productFamily = "";
      let formName = "";
      Object.keys(productLists).forEach(function (key) {
        if (productLists[key] == item?.subscribedProduct?.productCode?.buid) {
          productFamily = key;
          formName = form[key]
        }
      });
      let everGreenFormName = "";
      let everGreenProductFamily = "";
      let everGreenProductbuid = "";
      Object.keys(endrosemntProductFamilyIdentifier).forEach(function (key, value) {
        if (endrosemntProductFamilyIdentifier[key] === item?.memberOfferedProduct?.bUID || endrosemntProductFamilyIdentifier[key] === item?.subscribedProduct?.productCode.buid || endrosemntProductFamilyIdentifier[key] === eligibleProductBuids) {
          everGreenProductFamily = key;
          everGreenFormName = form[key]
          everGreenProductbuid = item?.memberOfferedProduct?.bUID
        }
      });
      let petProductObj = [{
        productBuid: item?.subscribedProduct?.productCode?.buid || '',
        waived: item?.subscribedProduct?.status != "cDeclined",
        petProductWaviedStatus: petProductWaviedStatus,
        petproductIsActive: (petProductStatusProject || petProductStatusActive) ? true : false,
        productFamily: productFamily,
        everGreenProductFamily: everGreenProductFamily,
        everGreenProductbuid: everGreenProductbuid,
        everGreenFormName: everGreenFormName,
        formName: formName,
        // checkEnroll: checkEnroll,
        checkEnroll: isEverGreenEnrollTrueFalse,
        notEnrolled: notEnrolled,
        declinedProduct: item?.subscribedProduct?.status != "cActive",
        memberOfferedProductBuid
      }]
      return petProductObj;
    }
    else {
      return item?.coverages?.map((el, index) => {
        const subscribedOption = el?.subscribedOption?.additionalDatas
        const coverageBuidCheck = el?.bUID
        const insuredPersons = el?.insuredPersons
        const eligibleProductBuids = item?.memberOfferedProduct?.bUID

        const effectiveDate = getProductDetail?.processEntity?.effectiveDate
        const plannedTerminationDate = el?.subscribedOption?.plannedTerminationDate
        let name = item?.subscribedProduct?.productName
        const secondTermination1 = effectiveDate > plannedTerminationDate ? true : false
        const waived = el?.subscribedOption?.status === "cDeclined" || el?.subscribedOption?.status === "cTerminated" || el?.subscribedOption?.status === "cNeverInForce" ? true : false

        const PetProductDeclinedTerminated = el?.subscribedOption?.status === "cDeclined" || el?.subscribedOption?.status === "cNeverInForce" || el?.subscribedOption?.status === "cTerminated" ? true : false
        const declinedProduct = item?.subscribedProduct?.status === "cDeclined" || item?.subscribedProduct?.status === "cNeverInForce" ? true : false
        let declinedProductTerminated = item?.subscribedProduct?.status === "cTerminated" ? true : false
        if(currentBenifitButton === 'undefined.Get Started' && reasonType === 'AE'){
          declinedProductTerminated =false
        }
        let secondTermination2 = ''
        if ((Date.parse(plannedTerminationDate) < Date.parse(lastRenewalDate)) && el?.subscribedOption?.status === 'cActive') {
          secondTermination2 = ''
        } else if ((Date.parse(plannedTerminationDate) > Date.parse(lastRenewalDate)) && el?.subscribedOption?.status === 'cActive') {
          secondTermination2 = ''
        } else {
          secondTermination2 = item?.subscribedProduct?.status !== 'cActive' ? PetProductDeclinedTerminated || declinedProduct || declinedProductTerminated || waived || el?.subscribedOption?.status === 'cTerminated' : ''
        }
        const secondTermination = isRateinformation ? secondTermination2 : secondTermination1

        const petProductStatusProject = item?.subscribedProduct?.status === "cProject"
        const petProductStatusActive = item?.subscribedProduct?.status === "cActive"
        const petProductStatusDeclined = item?.subscribedProduct?.status === "cDeclined" || item?.subscribedProduct?.status === "cTerminated" || item?.subscribedProduct?.status === "cNeverInForce" || secondTermination
        const waviedTreminated = el?.subscribedOption?.status === 'cTerminated' || secondTermination ? true : false;
        const activeCoverage = (el?.subscribedOption?.status === "cProject" || el?.subscribedOption?.status === "cActive")
        const petSecondTermination = activeCoverage && secondTermination
        const productBuid = item?.subscribedProduct?.productCode?.buid || '';
        const additionalDatas = subscribedOption || [];
        const fieldCodeObj = additionalDatas?.find(data => data?.fieldCode === "PlanDesign");
        let isPlandesignNotPresent = additionalDatas ? fieldCodeObj === undefined && el?.subscribedOption?.status === 'cActive' && item?.subscribedProduct?.status === "cActive" : null;
        let coverageLevel = additionalDatas?.find(data => data?.fieldCode === "DD_00175") || {};
        const petName = additionalDatas?.find(data => data?.fieldCode === "DD_00169") || {};
        const annualEnrollWL = gbpcontractsLinearOrNonLinear?.additionalDatas?.find(data => data?.fieldCode === "DD_00159" && data?.valueCode !== "No") ? true : false;
        const enrollEditWLStatus = enrollmentData?.contract?.contract?.additionalDatas?.find(data => data?.fieldCode === "DD_00180")
        
        const wholeLifeStatusEnrolled = enrollEditWLStatus?.valueCode !== '' ? true : false
        const titleWL = getGBPResourcesForMember?.find((pobj) => pobj.code === 'COVTITLEWL');
        const coverageTableName = el?.subscribedOption?.optionName?.split(" - ")?.[0]
        const productTitleWL = titleWL?.content
        let productFamily = "";
        let formName = "";
        const wLcheck = additionalDatas?.find(data => data?.fieldCode === "DD_00161") || {};
        const wholeLifeStatusNotEnrolled = enrollEditWLStatus?.valueCode === '' ? true : wLcheck?.fieldValue ? true : false
        const wLAnnualAmount = additionalDatas?.find(data => data?.fieldCode === "DD_00164") || {};
        const wLProductName = additionalDatas?.find(data => data?.fieldCode === "DD_00161") || {};
        const wLCoverAmount = additionalDatas?.find(data => data?.fieldCode === "DD_00162") || {};
        const wLStatus = additionalDatas?.find(data => data?.fieldCode === "DD_00163") || {};
        const wLEmpCatStatus = additionalDatas?.find(data => data?.fieldCode === "DD_00217") || {};
        const enrollEnd = additionalDatas?.find(data => data?.fieldCode === "ENROLLENDS") || {};
        if(enrollEnd?.fieldValue){
          const enrollEndDate = new Date(enrollEnd);
          isEverGreenEnrollTrueFalse = (enrollEndDate >= today)? isEverGreenEnrollTrueFalse : false

        }
        if(wLProductName?.fieldValue){
          productFamily = wLProductName?.fieldValue
          formName = 'WLCoverage'
          coverageLevel = wLCoverAmount
          isPlandesignNotPresent = false
          name = wLProductName?.fieldValue
        }
        Object.keys(productLists).forEach(function (key) {
          if (productLists[key] == item?.subscribedProduct?.productCode?.buid) {
            productFamily = key;
            formName = form[key]
          }
        });
        const everGreenData = checkEverGreen?.filter(item => item?.filterData !== undefined);
        const petProductWaviedStatus = (((petProductStatusActive || petProductStatusProject || petProductStatusDeclined || checkenrollEndorsement || notEnrolled) && ((el?.subscribedOption?.status !== "cActive") || (el?.subscribedOption?.status !== "cProject")) && PetProductDeclinedTerminated) || petSecondTermination) ? true : false

        let everGreenFormName = "";
        let everGreenProductFamily = "";
        let everGreenProductbuid = "";
        Object.keys(endrosemntProductFamilyIdentifier).forEach(function (key, value) {

          if (endrosemntProductFamilyIdentifier[key] === item?.memberOfferedProduct?.bUID || endrosemntProductFamilyIdentifier[key] === item?.subscribedProduct?.productCode.buid || endrosemntProductFamilyIdentifier[key] === eligibleProductBuids) {
            everGreenProductFamily = key;
            everGreenFormName = form[key]
            everGreenProductbuid = item?.memberOfferedProduct?.bUID
          }
        });
        let costPerPayPeriod = costFlattenedData?.filter((cost) => cost?.rateProductIdentifier === coverageBuidCheck)?.map(el => el?.amount)
        let anualCost = AnualCostFlattenedData?.filter((cost) => cost?.anualCostProductIdentifier === coverageBuidCheck)?.map(el => el?.anualAmount)
        if(wLAnnualAmount?.fieldValue?.amount && wLProductName?.fieldValue){
          anualCost = [`$${wLAnnualAmount?.fieldValue?.amount?.toFixed(2)}}`]
          wLAAmount = wLAAmount + wLAnnualAmount?.fieldValue?.amount
          costPerPayPeriod = [`$${(wLAnnualAmount?.fieldValue?.amount/payPeriod)?.toFixed(2)}}`]
          wLCoveragePerPeriod = wLCoveragePerPeriod + (wLAnnualAmount?.fieldValue?.amount/payPeriod)

        }
        const productResource = productResources[productBuid] || productResources[everGreenProductbuid] || productResources[eligibleProductBuids];
        const resource = productResource?.find(item => item?.code === 'PRODTITLE');
        const getCoveredMembers = []
        let relationshipWithinsured = [];
        finalEligibleProduct?.forEach((subscribedElement) => {
          let productCode = subscribedElement?.subscribedProduct?.productCode?.buid;
          subscribedElement.coverages?.forEach((coverage) => {
            const subscribedCoverageBUIDs = []

            coverage.insuredPersons?.forEach((insuredPerson) => {
              const bUID = insuredPerson.person?.data.bUID;
              subscribedCoverageBUIDs.push(bUID);
            })

            const insuredPersonRelationship = [];
            if(wLProductName?.fieldValue){
              item?.coverages?.forEach((insured) => {
                const wladditionalDatas = insured?.subscribedOption?.additionalDatas
                const wLEmpCat = wladditionalDatas?.find(data => data?.fieldCode === "DD_00217") || {};
                const wLEmpFristName = wladditionalDatas?.find(data => data?.fieldCode === "DD_00207") || {};
                const wLEmpLastName = wladditionalDatas?.find(data => data?.fieldCode === "DD_00208") || {};
                let fullName = wLEmpFristName?.fieldValue + ' ' + wLEmpLastName?.fieldValue;
                const relationship = wLEmpCat?.fieldValue ? wLEmpCat?.fieldValue : 'Employee';
                if (relationship) {
                  if (waivedPlanStatusArr.indexOf(coverage?.subscribedOption?.status) === -1) {
                    insuredPersonRelationship.push(productCode, { insureRelationShip: `${fullName} (${relationship})`, status: coverage?.subscribedOption?.status, });
                  }
                  relationshipWithinsured.push({
                    buid: coverage.bUID,
                    relationship: relationship
                  })
                }
              })
            }else{
              insureds?.forEach((insured) => {
                const relationshipToInsured = insured.insured?.mainOwnerRelationshipToInsured;
                const bUID = insured.person?.data?.bUID;

                let { shortName: firstName, name: lastName } = insured.person?.data?.actor || {}
                let fullName = firstName + ' ' + lastName;

                const relationship = subscribedCoverageBUIDs.includes(bUID) && getRelationship(relationshipToInsured);

                if (relationship) {
                  if (waivedPlanStatusArr.indexOf(coverage?.subscribedOption?.status) === -1) {
                    insuredPersonRelationship.push(productCode, { insureRelationShip: `${fullName} (${relationship})`, status: coverage?.subscribedOption?.status, });
                  }
                  relationshipWithinsured.push({
                    buid: coverage.bUID,
                    relationship: relationship
                  })
                }
              })
            }
            getCoveredMembers.push(insuredPersonRelationship);
          })
        })
        let coverProductBuid = eligibleProductBuids || productBuid || everGreenProductbuid
        return {
          coverageLevel: coverageLevel?.fieldValue ? coverageLevel?.fieldValue : isPlandesignNotPresent ? 'Due to a change in the eligiblity, please call the Contact Center to complete your enrollment' : fieldCodeObj?.nameDescription,
          petName: petName?.fieldValue,
          costPerPayPeriod: costPerPayPeriod,
          anualCost: anualCost,
          productBuid: productBuid,
          everGreenProductbuid: everGreenProductbuid,
          waived: waived,
          petProductWaviedStatus: petProductWaviedStatus,
          waviedTreminated: waviedTreminated,
          declinedProduct: declinedProduct,
          declinedProductTerminated: declinedProductTerminated,
          productFamily: productFamily,
          relationshipWithinsured: relationshipWithinsured,
          formName: formName,
          everGreenFormName: everGreenFormName,
          getCoveredMembers: getCoveredMembers,
          // checkEvergereenEdit: checkEvergereenEdit,
          checkEvergereenEdit: isEverGreenEditTrueFalse || atSubscriptionEnroll,
          everGreenProductFamily: everGreenProductFamily,
          // checkEnroll: checkEnroll,
          checkEnroll: isEverGreenEnrollTrueFalse,
          cancelCoverage: cancelCoverage,
          coverageBuid: coverageBuidCheck,
          resource: resource?.content || name ,
          eligibleProductBuids: eligibleProductBuids,
          notEnrolled: notEnrolled,
          enrollmentType: enrollmentType,
          currentYearRason: currentYearRason,
          reasonType: reasonType,
          coverageProductBuid: coverProductBuid,
          annualEnrollWL: annualEnrollWL,
          productTitleWL: productTitleWL,
          wholeLifeStatusNotEnrolled: wholeLifeStatusNotEnrolled,
          wholeLifeStatusEnrolled: wholeLifeStatusEnrolled,
          coverageTypeInformation: coverageTypeInformation,
          secondTermination: secondTermination,
          insuredPersons: insuredPersons,
          isPlandesignNotPresent: isPlandesignNotPresent,
          coverageName: coverageTableName,
          memberOfferedProductBuid,
          wLcheck: wLcheck?.fieldValue ? true : false,
          wLStatus: wLStatus?.fieldValue || '',
          wLEmpCatStatus: wLEmpCatStatus?.fieldValue || '',
        }
      })
    }
  });

  //console.log('productMapdetails', productMapdetails)

  
 if(individualcontracts?.length === 0 && hasWLData){
  productMapdetails.push([{
    "coverageLevel": "",
    "productBuid": "318851277_1",
    "everGreenProductbuid": "",
    "waived": false,
    "petProductWaviedStatus": false,
    "waviedTreminated": false,
    "declinedProduct": false,
    "declinedProductTerminated": false,
    "productFamily": "Voya WL",
    "relationshipWithinsured": [],
    "formName": "WLCoverage",
    "everGreenFormName": "",
    "checkEvergereenEdit": false,
    "everGreenProductFamily": "",
    "checkEnroll": false,
    "resource": "Whole Life",
    "notEnrolled": true,
    "enrollmentType": enrollmentType,
    "currentYearRason": currentYearRason,
    "reasonType": reasonType,
    "annualEnrollWL": true,
    "productTitleWL": "Whole Life",
    "wholeLifeStatusNotEnrolled": true,
    "wholeLifeStatusEnrolled": false,
    "isPlandesignNotPresent": false,
    "wLcheck": true,
    "wLStatus": "",
    "wLEmpCatStatus": "",
    "OEWL": reasonType === 'AE'? false : true,

  }])

 }


  const getGrandfatheredProductFlag = (productBuid) => {
    if (subscribedElements && subscribedElements.length) {
      let filteredSubscribedElement = subscribedElements.filter((item) => {
        return item?.memberOfferedProduct.bUID === productBuid
      })

      if (filteredSubscribedElement.length) {
        //if isActive is false, product is grandfathered otherwise not
        return filteredSubscribedElement[0]?.memberOfferedProduct?.product?.isActive ? false : true
      }
    }
  }

  let totalCost = 0.0;
  let totalCovergeAmount = productCovergeCost?.rate?.amount?.amount + wLCoveragePerPeriod
  let totalAmount = `$${totalCovergeAmount?.toFixed(2) || ''}` || ''
  let anualTotalCost = productAnualCost?.rate?.amount?.amount + wLAAmount;
  anualTotalCost = `$${anualTotalCost?.toFixed(2) || ''}` || ''
  const [totalCostMonthly, setCost] = useState(totalAmount);
  const [btnSelected, setSelectedBtn] = useState('annual');
  let dayCostOnClick = (curDocBUID) => {
    if (curDocBUID === 'annual') {
      setCost(anualTotalCost);
      setSelectedBtn('annual');
    } else {
      setCost(totalAmount);
      setSelectedBtn('day');
    }
  };

  useEffect(() => {
    dayCostOnClick()
  }, [totalAmount])
  useEffect(() => {
    dispatch(onLoadAPI({
      ReworkProductEnrollment: '',
    }));

    dispatch(PREVIOUS_STEP({ previousStep: currentStep }));
    // Call the async function

  }, []) // Empty dependency array means this effect runs once on component mount

  let editOnClick = async (currentForm, productId = '', declinedProduct) => {
    dispatch(SHOW_CONTENT_LOADER(true));
    let result = true;
    if (currentForm === 'DependentInformation') {
      const selectedProducts = Object.entries(productLists).map(([productFamily, bUID]) => ({
        productFamily,
        bUID
      }));
      dispatch(SELECTED_PRODUCT_LIST(selectedProducts));
    } else if (currentForm && productId != '' && declinedProduct) {
      let productIdentifierBuid = currentReduxState?.process?.productFamilyIdentifier?.[`${productId}`];

      let subscribedElements = enrollmentData?.contract?.subscribedElements;
      let subscribedElementsData = subscribedElements?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
      let subscribedProductBuid = subscribedElementsData?.[0]?.subscribedProduct?.buid;

      const enrorsemntproductIdentifierBuid = endrosemntProductFamilyIdentifier?.[`${productId}`];
      const enrorsemntSubscribedElements = enrollmentData?.contract?.subscribedElements;
      let enrorsemntSubscribedElementsData = enrorsemntSubscribedElements?.filter(e => e?.subscribedProduct?.productCode?.buid === enrorsemntproductIdentifierBuid);
      let enrorsemntSubscribedProductBuid = enrorsemntSubscribedElementsData?.[0]?.subscribedProduct?.buid;
      if (declinedProduct) {
        /*   let actionDispatched = await dispatch(reworkProductEnrollment({
            enrollmentProcessBuid: enrollmentProcessBuid ? currentReduxState?.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID : endorsementProcessBuid,
            subscribedProductIdentifierBuid: !endorsementProcessBuid ? subscribedProductBuid : enrorsemntSubscribedProductBuid,
            externalStepContext: currentStepInfo
          }));
          if (actionDispatched.status) {
            let resp = actionDispatched.respObj;
            let insuredInfoCopy = processData(productLists, resp?.data?._Result, cartItemListCoverageLevel);
            if (insuredInfoCopy && Object.keys(insuredInfoCopy).length > 0) {
              Object.keys(insuredInfoCopy).forEach((productName) => {
                if (productName && insuredInfoCopy[productName]) {
   
                  dispatch(UPDATE_INSURED({
                    product: productName,
                    data: insuredInfoCopy[productName],
                  }));
                }
              });
            }
   
   
          } */
      } else if (!declinedProduct) {
        let insuredInfoCopy = processData(productLists, enrollmentData, cartItemListCoverageLevel, storeFromRedux);
        if (insuredInfoCopy && Object.keys(insuredInfoCopy).length > 0) {
          Object.keys(insuredInfoCopy).forEach((productName) => {
            if (productName && insuredInfoCopy[productName]) {

              dispatch(UPDATE_INSURED({
                product: productName,
                data: insuredInfoCopy[productName],
              }));
            }
          });
        }
      }

      else {
        result = false
      }
    }
    if (result === true) {
      dispatch(UPDATE_CART_ENROLLMENT(costBasket));
      dispatch(UPDATE_CART_ENROLLMENT_COVERAGE_LEVEL(costBasketCoverageLevel));
      let insuredInfoCopy = processData(productLists, enrollmentData, costBasketCoverageLevel, storeFromRedux);
      if (insuredInfoCopy && Object.keys(insuredInfoCopy).length > 0) {
        Object.keys(insuredInfoCopy).forEach((productName) => {
          if (productName && insuredInfoCopy[productName]) {

            dispatch(UPDATE_INSURED({
              product: productName,
              data: insuredInfoCopy[productName],
            }));
          }
        });
      }
      setTimeout(() => {
        try {
          handleGoToSpecificStep(currentForm);

        } catch (error) {
          console.error("Error in goBackStep", error);
        }
      }, 0);
    }
  };


  const handleEnroll = async (currentForm, productId = '') => {
    let resulttrue = true
    const enrollProductIdentifierBuid = endrosemntProductFamilyIdentifier?.[`${productId}`];
    let subscribedElementsData = getCurrerntYearEnrollments?.filter(e => e?.subscribedProduct?.productCode?.buid === enrollProductIdentifierBuid);
    const eligibleProduct = subscribedElementsData?.map((product) => {
      return {
        bUID: product?.subscribedProduct?.productCode?.buid,
        className: product?.subscribedProduct?.className
      }
    })
    if (currentForm && productId != '') {
      dispatch(UPDATE_LOADING(true));
      return await http
        .post(appConfig.SSP_MID_URL + `/v1/subscribeThisListOfProducts`, {
          enrollmentProcessBuid: enrollmentProcessBuid,
          eligibleProduct: eligibleProduct,
          currentStepInfo: {
            "portalStep": currentForm
          }
        }).then(async (resp) => {
          const data = resp?.data?.data
          dispatch(IS_ENROLLED(enrollProductIdentifierBuid))
          if (data) {
            for (const key in data) {
              let result = { _Result: data[key].data }
              switch (key) {
                case 'subscribeThisListOfProducts':
                  dispatch(UPDATE_CART_ENROLLMENT(costBasket));
                  dispatch(UPDATE_CART_ENROLLMENT_COVERAGE_LEVEL(costBasketCoverageLevel));
                  break;
                case 'validateContract':
                  dispatch(onLoadAPI({
                    'ValidateContractData': result,
                  }));
                  const processInfo = data[key].data ?? {};
                  dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
                  break;
                default:
                  dispatch(onLoadAPI({ [key]: result }));
              }

            }
            setTimeout(() => {
              try {
                handleGoToSpecificStep(currentForm);
                dispatch(UPDATE_LOADING(false));
              } catch (error) {
                console.error("Error in goBackStep", error);
              }
            }, 0);

          } else {
            resulttrue = false
          }
        }).catch((e) => {
          dispatch(UPDATE_LOADING(false));
          return false;
        });
    }
  }



  const handleCancelCoverage = async () => {
    try {
      dispatch(UPDATE_LOADING(true));
      const enrollProductIdentifierBuid = endrosemntProductFamilyIdentifier?.[`${ProductfamilyBuid}`];
      let subscribedElements = enrollmentData?.contract?.subscribedElements;
      let subscribedElementsData = subscribedElements?.filter(e => e.subscribedProduct.productCode.buid === enrollProductIdentifierBuid);
      let subscribedProductBuid = subscribedElementsData?.[0]?.subscribedProduct?.buid;
      let coverageBuid = coverageId

      if (procuctNames && ProductfamilyBuid != '') {
        if (coverageId) {
          let resp = await http.post(appConfig.SSP_MID_URL + `/v1/declinedCoverage`, {
            enrollmentProcessBuid: endorsementProcessBuid,
            subscribedCoverageBuid: coverageBuid
          })
          if (resp.data && resp.status === 200) {
            let declinedCoverageData = { _Result: resp.data?.data }
            dispatch(IS_COVERAGE_CANCELLED(true))
            cancelModal()
            handlePreConfirmationOnLoad()
            dispatch(onLoadAPI({
              declinedCoverage: declinedCoverageData,
              isAPICallInProgress: false,
            }))
            const data =
              (resp &&
                resp.data &&
                resp.data._Result) ||
              {};
            const mergedEnrollmentData = { ...enrollmentData, ...data };
            dispatch(UPDATE_ENROLLMENT_DATA(mergedEnrollmentData));
            dispatch(UPDATE_LOADING(false));
          }
        } else {
          let resp = await serviceHelper.sendApiRequest('/declineProduct', {
            enrollmentProcessBuid: endorsementProcessBuid,
            productIdentifierBuid: enrollProductIdentifierBuid,
            subscribedProductIdentifier: subscribedProductBuid,
            currentStepInfo: {
              "portalStep": procuctNames ? procuctNames : ''
            }
          })
          if (resp.data && resp.status === 200) {
            dispatch(IS_COVERAGE_CANCELLED(true))
            cancelModal()
            handlePreConfirmationOnLoad()
            dispatch(onLoadAPI({
              declinedCoverage: resp.data,
              isAPICallInProgress: false,
            }))
            const data =
              (resp &&
                resp.data &&
                resp.data._Result) ||
              {};
            const mergedEnrollmentData = { ...enrollmentData, ...data };
            dispatch(UPDATE_ENROLLMENT_DATA(mergedEnrollmentData));
            dispatch(UPDATE_LOADING(false));
          }
        }
      }

    } catch (error) {
      dispatch(UPDATE_LOADING(false));
    }


  }

  // confirmEnrollment call
  const handleConfirmEnrollent = async () => {
    dispatch(UPDATE_LOADING(true));
    return await http
      .post(appConfig.SSP_MID_URL + `/v1/finalEnrollment`, {
        enrollmentProcessBuid: enrollmentProcessBuid,
      })
      .then(async (resp) => {
        const data = resp?.data?.data;
        dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
        for (const key in data) {
          let result = { _Result: data[key].data }
          let val = {}
          switch (key) {
            case 'finalEnrollmentProcess':
              if (data[key]?.success) {
                SetFinalEnrollementProcess(data[key].data)
                dispatch(onLoadAPI({
                  finalEnrollmentProcess: data[key].data,
                }));
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'finalEnrollmentProcess',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'getDocreqcontainerforcontract':
              if (data[key]?.success) {
                let requirementRequestsData = data[key].data
                let requestID = requirementRequestsData?.requirementRequests && requirementRequestsData?.requirementRequests[0]?.requirementRequest?.requestID
                setcrossSellApplicable(requestID)
                let nameUseForPrinting = requirementRequestsData?.requirementRequests && requirementRequestsData.requirementRequests[0]?.requirementRequest?.nameUseForPrinting
                let isCrossSellEnabledForEmployee = (
                  (requirementRequestsData?.requirementRequests && requirementRequestsData?.requirementRequests.length === 0) ||
                  (requirementRequestsData?.requirementRequests && requirementRequestsData.requirementRequests[0]?.requirementRequest?.additionalDatas[0].actionStatus !== 'cRSCompleted')
                )
                dispatch(STORE_DATA_FOR_CROSS_SELL({
                  isCrossSellEnabledForEmployee: isCrossSellEnabledForEmployee,
                  requestID: requestID,
                  nameUseForPrinting: nameUseForPrinting
                }))
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getDocreqcontainerforcontract',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            case 'genericrequirement':
              if (data[key]?.success) {
                const dataFromResponse = result
                let aknowledgePresentedNameFirst = dataFromResponse?._Result[0]?.presentedName?.substring(0, dataFromResponse._Result[0].presentedName.indexOf('.') + 1)
                let aknowledgePresentedNameSecond = dataFromResponse?._Result[0]?.presentedName?.substring(dataFromResponse._Result[0].presentedName.indexOf('.') + 1)
                let expirationPresentedDate = dataFromResponse?._Result[1]?.presentedName
                expirationPresentedDate && aknowledgePresentedNameFirst && aknowledgePresentedNameSecond &&
                  dispatch(STORE_RESPONSE_DATA_FOR_CROSS_SELL({
                    aknowledgePresentedNameFirst: aknowledgePresentedNameFirst,
                    aknowledgePresentedNameSecond: aknowledgePresentedNameSecond,
                    expirationPresentedDate: expirationPresentedDate
                  }))
              } else {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'genericrequirement',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            default:
              dispatch(onLoadAPI({ [key]: result }));
          }

        }
        dispatch(UPDATE_LOADING(false));
        return true;
      })
      .catch((e) => {
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'finalEnrollmentApi',
          show: true,
          page: currentStep,
        }));
        dispatch(UPDATE_LOADING(false));
        return false;
      });
  };
  useEffect(() => {
    if (finalEnrollementProcess) {
      if (crossSellApplicable) {
        history.push("/enrollment/crossSell")
      } else {
        history.push("/confirmationScreen");
      }
    }
  }, [crossSellApplicable])
  const handleCancelShowModel = (currentForm, productId, coverageBuid) => {
    $('#covergeModal').modal('show');
    setCoverageId(coverageBuid)
    setProductFamily(productId)
    setprocuctNames(currentForm)
  };
  const cancelModal = () => {
    $('#covergeModal').modal('hide');
  };
  const enrollmentDuringOEOpen = enrollmentType === "editNewEnrollmentDuringOE2" && reasonType === "NH" // window open and enroll still days remaining  can edit 
  const enrollmentDuringOEClosed = (enrollmentType === "editNewEnrollmentDuringOE" && reasonType === "OE" && currentBenifitButton === 'undefined.reviewUpdate')  // window close  cancel covearge and edit the evergereen 
  const previousYearEnrollmentClosed = (enrollmentType === "editNewEnrollmentDuringOE" && reasonType === "OE" && currentBenifitButton === 'undefined.currentYearReviewUpdate')

  const anualEnrollmentCurrentBenifitwindowClosed = (enrollmentType === 'editEnrollmentDuringOE' && reasonType === 'AE' && currentBenifitButton === 'undefined.currentYearReviewUpdate')
  const anualEnrollmentCurrentBenifitwindowOpen = (enrollmentType === "open-reenrollment" && reasonType === "AE" && (currentBenifitButton === 'undefined.currentYearReviewUpdate' || currentBenifitButton === '.YesButton'))
  const case9 = (enrollmentType === "case9" && reasonType === "OE") || (enrollmentType === "case9" && reasonType === "NH")  // only evergreen scenarions
  const newHireenrollment = (enrollmentType === "open-reenrollment" && reasonType === "NH" && (currentBenifitButton === 'undefined.currentYearReviewUpdate' || currentBenifitButton === '.YesButton'))
  const annualEnrollement = (enrollmentType === "open-reenrollment" && reasonType === "AE" && (currentBenifitButton === 'undefined.Get Started' || currentBenifitButton === '.NoButton')) || (enrollmentType === "case9" && reasonType === "AE")
  const openreenrollment = reasonType === 'NH' ? newHireenrollment : reasonType === "AE" ? annualEnrollement : ''
  const annualEnrollemtwindowOpen = enrollmentType === "editEnrollmentDuringOE" && reasonType === "AE" && currentBenifitButton === 'undefined.reviewUpdate' // window open and enroll still days remaining  can edit 
  const openreenrollmentcurrentBenifitNextyear = currentBenifitButton === 'undefined.reviewUpdate' && enrollmentType === "open-reenrollment" && reasonType === "AE"
  const openreenrollmentPreviousYearBenifit = (enrollmentType === "open-reenrollment" && currentEnrollmentType === "OE")
  let openEnrollmentReviewAndUpdate = openreenrollmentPreviousYearBenifit && (currentBenifitButton === 'undefined.currentYearReviewUpdate' || currentBenifitButton === '.YesButton')
  const openreenrollmentcurrentBenifit = ((currentBenifitButton === 'undefined.currentYearReviewUpdate' || currentBenifitButton === '.YesButton') && enrollmentType === "open-reenrollment" && currentEnrollmentType === "NH")

  const enrollmentDuringOE = enrollmentDuringOEOpen || annualEnrollemtwindowOpen
  const wholifeEnrollmentClose = (enrollmentType === 'editEnrollmentDuringOE' && reasonType === 'AE' && currentBenifitButton === 'undefined.reviewUpdate') || (enrollmentType === 'editEnrollmentDuringOE' && reasonType === 'AE' && currentBenifitButton === 'undefined.currentYearReviewUpdate')
  const WholelifeOpenEnrollCurreentBenifit = enrollmentType === "open-reenrollment" && reasonType === "AE" && currentBenifitButton === 'undefined.currentYearReviewUpdate'
  const isDependentEligible = dependentEligiblity?.some((item) => item?.dependentInfo === true) ? true : false
  const wholeLifeNH = enrollmentType === 'case5' && reasonType === "NH"
  let isDependentAllow = currentBenifitendorsementProcessBuid ? isDependentEligible && ((enrollmentDuringOEClosed || previousYearEnrollmentClosed || anualEnrollmentCurrentBenifitwindowClosed || anualEnrollmentCurrentBenifitwindowOpen || openreenrollmentcurrentBenifitNextyear))
    : qleEndorsementProcessBuid ? isDependentEligible
      : isDependentEligible
  const depenetEnrollNH = enrollmentDuringOEOpen || openreenrollmentcurrentBenifitNextyear
  const depenetAnualEnrollment = annualEnrollemtwindowOpen || depenetEnrollNH
  const dependentanualEnrollment = openreenrollment || depenetAnualEnrollment

  const isBenificiaryShow = currentBenifitendorsementProcessBuid ? showBeneficiarySection && enrollmentProcessBuid && (!(enrollmentDuringOEClosed || previousYearEnrollmentClosed || anualEnrollmentCurrentBenifitwindowClosed || anualEnrollmentCurrentBenifitwindowOpen || case9 || openreenrollmentcurrentBenifitNextyear))
    : qleEndorsementProcessBuid ? showBeneficiarySection && enrollmentProcessBuid
      : showBeneficiarySection && enrollmentProcessBuid;

  if (CIproductbuid) {
    updatePlanListOnSummaryScreen({ productMapdetails, CIproductbuid, buyUpDetails })
  }

  const hasErrors = getErrorMessages(currentStep);

  let costBasket = [];
  let costBasketCoverageLevel = [];
  productMapdetails?.map(item => {
    let totalCost = 0
    let checkPetProductWaived = item?.every(ele => ele?.petProductWaviedStatus || item?.[0]?.notEnrolled);
    let allPetProductWaived = checkPetProductWaived ? true : false
    let isWaived = false
    item?.map((cov) => {
      if (!(cov?.waived || cov?.waviedTreminated)) {
        const numAmount = parseFloat(cov?.costPerPayPeriod?.[0]?.substring(1));
        totalCost = totalCost + numAmount;
        costBasketCoverageLevel.push({ coverageBuid: cov?.coverageBuid, amount: cov?.costPerPayPeriod?.[0] })
      }
    })
    let petProductFamily = (item?.[0]?.productFamily === "Pet Insurance" || item?.[0]?.everGreenProductFamily === "Pet Insurance") || item?.[0]?.resource === "Pet Insurance" || item?.[0]?.productFamily === "Pet Insurance - PETPlus" || item?.[0]?.productFamily === "Pet Insurance - Pet Assure"
    if (petProductFamily) {
      if (allPetProductWaived) {
        isWaived = true
      }

    } else if (((item?.[0]?.waived || item?.[0]?.declinedProduct || item?.[0]?.waviedTreminated || item?.[0]?.declinedProductTerminated || item?.[0]?.notEnrolled))) {
      isWaived = true
    }
    if (!isWaived) {
      let name = item?.[0]?.resource ? item?.[0]?.resource : !endorsementProcessBuid ? item?.[0]?.productFamily : item?.[0]?.everGreenProductFamily
      costBasket.push({ id: item?.[0]?.formName, name: name, amount: isNaN(totalCost) ? 0.00 : totalCost })
    }
  })

  const isEverGreen = getListOfEligibleProducts?.map((product) => {
    const checkProduct = product?.subscribedProduct?.additionalDatas
    const everGreenProductBuid = product?.memberOfferedProduct?.bUID
    const checkevergerrenTrue = checkProduct?.some(eachObj => (eachObj?.fieldCode === 'IEGP' && eachObj?.fieldValue === true))
    return {
      everGreenProductBuid: everGreenProductBuid,
      checkevergerrenTrue: checkevergerrenTrue,
    }
  })

  const hasConcernedProducts = (product) => {
    const checkEvergreenProduct = isEverGreen?.filter((value) => value?.everGreenProductBuid === product.productBuid)
    const isEvengreen = checkEvergreenProduct?.length > 0 ? true : false
    const concernedProducts = GBPDetails?.currentEnrollmentPeriod?.concernedProducts || [];
    return isEvengreen || product.checkEvergereenEdit || concernedProducts?.some(item => item.bUID === product.memberOfferedProductBuid);
  };

  const hasConcernedProductsEroll = (product) => {
    const checkEvergreenProduct = isEverGreen?.filter((value) => value?.everGreenProductBuid === product.productBuid)
    const concernedProducts = GBPDetails?.currentEnrollmentPeriod?.concernedProducts || [];
    return checkEvergreenProduct || product.checkEvergereenEdit || concernedProducts?.some(item => item.bUID === product.memberOfferedProductBuid);
  };

  const confirmBtnEnabled = isReviewPageOnly ? false : !isSummaryResponse;

  return (
    <div className={currentBenifitendorsementProcessBuid && ``}>
      {isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />}

      <Toaster
        toastOptions={{
          className: 'toast-message',
          duration: 3000,

          style: {
            border: '1px solid #E6A7A3',
            padding: '16px',
            color: '#AB2F26',
            background: '#FFE8E6',
          },
        }}
      />
      {!hideEditButton && currentBenifitendorsementProcessBuid && (reasonType === "OE") && (
        <>
          <div className={classes.currentbenifittitle}> {`Your current benefits for the year `}</div>
          <div className={classes.currentbenifitdescription}>
            Benefits that are available for enrollment throughout the year can be elected or changed at any time. If the benefit allows for dependent coverage, you will be able to make changes to this information. Simply select the action buttons on the right side of the page to update.
          </div>
        </>
      )}

      {!hideEditButton && currentBenifitendorsementProcessBuid && (reasonType === "AE" || annualEnrollement || annualEnrollemtwindowOpen || isReviewPageOnly) && (
        <>
          {/* <div className='content-bold pb-3'> */}
          <div className={classes.currentbenifitdescription}>  {`Please review your current Employee, Dependent and Benefit enrollment information below. Click on the Edit button to make any updates or adjustments needed.`}</div>
          <div className={classes.currentbenifitdescription}></div>
        </>
      )}
      {!hideEditButton && qleEndorsementProcessBuid && (
        <>
          <div className={classes.currentbenifitdescription}>
            Please review your current Employee, Dependent and Benefit enrollment information below. Click on the Edit button to make any updates or adjustments needed.
          </div>
        </>
      )}
      {!hideEditButton && reasonType === 'NH' && (
        <>
          <div className={classes.currentbenifitdescription}>
            Please review your current Employee, Dependent and Benefit enrollment information below. Click on the Edit button to make any updates or adjustments needed.
          </div>
        </>
      )}
      <Banner>
        <EmployeeTable
          employeeTableData={isRateinformation ? getCurrerntYearEnrollmentsInsureds : enrollmentData?.contract?.insureds}
          editOnClick={editOnClick}
          preferredContactDetailsFromRedux={preferredContactDetailsFromRedux}
          employeeData={employeeData}
          hideEditButton={hideEditButton}
        />
      </Banner>
      <br />
      <>
        {isDependentEligible && (
          <Banner>
            <DependentsTable
              editOnClick={editOnClick}
              isButtonVisible={isDependentAllow}
              hideEditButton={hideEditButton}
              qleEndorsementProcessBuid={qleEndorsementProcessBuid}
              constants={constants}
              insuredDependents={isRateinformation ? getCurrerntYearEnrollmentsInsureds : insuredDependents}
              finalEligibleProduct={finalEligibleProduct}
              checkEverGreen={checkEverGreen}
              checkEvergereenAndCancel={checkEvergereenAndCancel}
              openreenrollment={dependentanualEnrollment}
              isDependentEligible={isDependentEligible}
              currentBenifitendorsementProcessBuid={currentBenifitendorsementProcessBuid}
              reasonType={reasonType}
              isAdmin={isAdmin}
              isSpecialEnrollment={isSpecialEnrollment}
              isQLEEnrollment={isQLEEnrollment}
              enrollmentType={enrollmentType}
              notEnrolledProducts={notEnrolledProducts}
              externalClientId={externalClientId}
            />
          </Banner>
        )}
      </>
      <br />
      <Banner>
        {productMapdetails?.map((elem, index) => {
          let item = elem?.[0];
          let isGrandfathered = getGrandfatheredProductFlag(item?.productBuid);
          const productResource = productResources[item?.productBuid] || productResources[item?.everGreenProductbuid] || productResources[item?.eligibleProductBuids];
          const resource = productResource?.find(item => item?.code === 'PRODTITLE');
          let checkPetProductWaived = elem?.every(item => item?.petProductWaviedStatus || item?.notEnrolled);
          let allPetProductWaived = checkPetProductWaived ? true : false
          let filteredCoverageData = item?.formName === "PetAssure" || item?.formName === "PetPlus" ? elem?.filter(obj => obj?.petName !== '') : elem;
          let petProductFamily = (item?.productFamily === "Pet Insurance" || item?.everGreenProductFamily === "Pet Insurance") || item?.resource === "Pet Insurance" || item?.productFamily === "Pet Insurance - PETPlus" || item?.productFamily === "Pet Insurance - Pet Assure"
          let previousYearEnrollment = (openreenrollmentcurrentBenifit && currentBenifitDate) || (openEnrollmentReviewAndUpdate && currentBenifitDate) || (anualEnrollmentCurrentBenifitwindowClosed && currentBenifitDate) || (previousYearEnrollmentClosed && currentBenifitDate)
          let openEnrollmentPrevisousYear = !previousYearEnrollment ? (openreenrollmentcurrentBenifit && item?.checkEvergereenEdit) || (openEnrollmentReviewAndUpdate && item?.checkEvergereenEdit) || (anualEnrollmentCurrentBenifitwindowClosed && item?.checkEvergereenEdit) || previousYearEnrollmentClosed : false
          let previousYearCancelCoverage = !previousYearEnrollment ? (item?.cancelCoverage && ((openreenrollmentcurrentBenifit && item?.cancelCoverage && !item?.notEnrolled && !item?.checkEvergereenEdit) || (openEnrollmentReviewAndUpdate && item?.cancelCoverage && !item?.notEnrolled && !item?.checkEvergereenEdit) || anualEnrollmentCurrentBenifitwindowClosed || anualEnrollmentCurrentBenifitwindowClosed || (previousYearEnrollmentClosed && item?.checkEvergereenEdit))) : false
          let previousYearEvergreenEnroll = !previousYearEnrollment ? (openreenrollmentcurrentBenifit && item?.checkEnroll) || (openreenrollmentcurrentBenifit && item?.notEnrolled) || (openEnrollmentReviewAndUpdate && item?.checkEnroll) || (previousYearEnrollmentClosed && item?.checkEnroll) || (openreenrollment && item?.notEnrolled) : false
          const WLProduct = ((item?.annualEnrollWL && openreenrollment) || (item?.annualEnrollWL && wholeLifeNH && item?.wholeLifeStatusEnrolled) || (item?.annualEnrollWL && WholelifeOpenEnrollCurreentBenifit && item?.wholeLifeStatusEnrolled) || (item?.annualEnrollWL && wholifeEnrollmentClose && item?.wholeLifeStatusEnrolled))
          const wLcheck = item?.wLcheck
          const OEWL = item?.OEWL || false
          if (filteredCoverageData && filteredCoverageData.length) {
            filteredCoverageData = filteredCoverageData?.map((dataItem) => {
              if (dataItem.formName === "ExcessLiability") {
                const coverageName = dataItem?.coverageName === 'Chubb Personal Excess Liability Insurance'
                  ? "EX. Liab"
                  : dataItem?.coverageName === 'Uninsured and Underinsured Limits'
                    ? "UM/UIM"
                    : '';
                dataItem = {
                  ...dataItem,
                  coverageLevel: `${coverageName} (${reformatAmountToDisplayValueExcessLiability({ amount: dataItem.coverageLevel })})`
                };
              }

              if (dataItem.formName === "UniversalLife") {
                if (dataItem.relationshipWithinsured && dataItem.relationshipWithinsured.length) {
                  dataItem.relationshipWithinsured.map((obj) => {
                    if (dataItem.coverageBuid === obj.buid) {
                      dataItem = {
                        ...dataItem,
                        coverageLevel: dataItem.coverageLevel + ` (${obj.relationship})`
                      }
                    }
                  })
                }
              }
              if (dataItem.formName === "CriticalIllness") {
                if (dataItem.relationshipWithinsured && dataItem.relationshipWithinsured.length) {
                  const uniqueBuids = new Set();
                  const coverageRelationship = [];
                  for (const item of dataItem.relationshipWithinsured) {
                    const { buid, relationship } = item;
                    if (uniqueBuids.has(buid)) {
                      continue;  // Skip duplicates
                    }
                    if (relationship === "Child" && dataItem?.insuredPersons.length > 1) {
                      item.relationship += "(ren)";
                    }
                    uniqueBuids.add(buid);
                    coverageRelationship.push(item);
                  }
                  coverageRelationship?.map((obj) => {
                    if (dataItem.coverageBuid === obj.buid) {

                      let { everGreenFormName, everGreenProductFamily, coverageBuid } = dataItem;
                      if ((adminEndorsementProcessBuid && !(item?.notEnrolled)) && (obj?.relationship === "Child" || obj?.relationship === "Spouse")) {
                        dataItem = {
                          ...dataItem,
                          coverageLevel: dataItem?.coverageTypeInformation?.[0]?.coveredInfo === true ? formatDollarAmount(dataItem.coverageLevel) : (
                            <span>
                              {obj?.relationship} ({formatDollarAmount(dataItem?.coverageLevel)}){' '}

                              <a className={classes.cancelCoverageLink} onClick={() => handleCancelShowModel(everGreenFormName, everGreenProductFamily, coverageBuid)}>Cancel Coverage</a>
                            </span>
                          )
                        };
                      }
                      else if ((!hideEditButton && currentBenifitendorsementProcessBuid && (((currentEnrollmentType !== 'NH' && previousYearCancelCoverage) || case9 || enrollmentDuringOEClosed || openreenrollmentcurrentBenifitNextyear) && !item?.checkEnroll && item?.cancelCoverage && !item?.notEnrolled)) && (obj?.relationship === "Child" || obj?.relationship === "Spouse")) {

                        dataItem = {
                          ...dataItem,
                          coverageLevel: dataItem?.coverageTypeInformation?.[0]?.coveredInfo === true ? dataItem.coverageLevel : (
                            <span>
                              {obj?.relationship} ({formatDollarAmount(dataItem?.coverageLevel)}){' '}
                              <a className={classes.cancelCoverageLink} onClick={() => handleCancelShowModel(everGreenFormName, everGreenProductFamily, coverageBuid)}>Cancel Coverage</a>
                            </span>
                          )
                        };
                      }
                      else {
                        dataItem = {
                          ...dataItem,
                          coverageLevel: dataItem?.coverageTypeInformation?.[0]?.coveredInfo === true ? formatDollarAmount(dataItem.coverageLevel) : obj.relationship + ' ' + ` (${formatDollarAmount(dataItem?.coverageLevel)}) `
                        }
                      }
                    }
                  })
                }
              }
              if (dataItem.formName === "WLCoverage") {
                if (dataItem.relationshipWithinsured && dataItem.relationshipWithinsured.length) {
                  const uniqueBuids = new Set();
                  const coverageRelationship = [];
                  for (const item of dataItem.relationshipWithinsured) {
                    const { buid, relationship } = item;
                    if (uniqueBuids.has(buid)) {
                      continue;  // Skip duplicates
                    }
                    if (relationship === "Child" && dataItem?.insuredPersons.length > 1) {
                      item.relationship += "(ren)";
                    }
                    uniqueBuids.add(buid);
                    coverageRelationship.push(item);
                  }
                  coverageRelationship?.map((obj) => {
                    if (dataItem.coverageBuid === obj.buid) {
                      let relationship = dataItem.wLEmpCatStatus? dataItem.wLEmpCatStatus : "Employee"
                      dataItem = {
                        ...dataItem,
                        coverageLevel: dataItem?.coverageTypeInformation?.[0]?.coveredInfo === true ? formatDollarAmount(dataItem.coverageLevel) : relationship + ' ' + ` (${formatDollarAmount(dataItem?.coverageLevel)}) `
                      }
                    }
                  })
                }
              }
              return dataItem
            })
          }

          return (
            <>
              <div className={classes.card}>
                {!endorsementProcessBuid ? (
                  <div className={'product-title'}>
                    {resource?.content || item?.resource || item?.productFamily}
                  </div>
                ) : (
                  <div className={'product-title'}>
                    {resource?.content || item?.resource || item?.everGreenProductFamily}
                  </div>
                )}


                <>
                  {(petProductFamily) ? (
                    <>
                      {(((enrollmentDuringOE && item?.notEnrolled) || (!isRateinformation && item?.checkEnroll))) && (
                        <Button className={`ml-3 mt-2 ${classes.ButtonEnroll}`} disabled>
                          Available to Enroll
                        </Button>
                      )}
                    </>
                  )

                    : (
                      <>
                        {(!OEWL && ((adminEndorsementProcessBuid && item?.notEnrolled) || (qleEndorsementProcessBuid && item?.checkEnroll) || ((enrollmentDuringOE && item?.notEnrolled) || (openreenrollment && item?.notEnrolled) || (item?.checkEnroll)))) && (
                          <Button className={`ml-3 mt-2 ${classes.ButtonEnroll}`} disabled>
                            Available to Enroll
                          </Button>
                        )}
                      </>
                    )}
                </>

                <>
                  {(petProductFamily) ? (
                    <>
                      {allPetProductWaived && (
                        <Button className={`ml-3 mt-2 ${classes.ButtonWaived}`} disabled>
                          Waived
                        </Button>
                      )}
                    </>
                  )

                    : (
                      <>
                        {((item?.waived || item?.declinedProduct || item?.waviedTreminated || item?.declinedProductTerminated || item?.notEnrolled)) && (
                          <Button className={`ml-3 mt-2 ${classes.ButtonWaived}`} disabled>
                            Waived
                          </Button>
                        )}
                      </>
                    )}
                </>
                <div className='button-align'>
                  {isSpecialEnrollment ? (
                     <>
                     {!hideEditButton && !wLcheck && (item?.notEnrolled) && hasConcernedProducts(item) && <Button className={classes.Button} onClick={() => handleEnroll(item?.formName, item?.productFamily, item?.declinedProduct)}>Edit</Button>}
                     {!hideEditButton && !wLcheck && !(item?.notEnrolled) && hasConcernedProductsEroll(item) && <Button className={classes.Button} onClick={() => editOnClick(item?.formName, item?.productFamily, item?.declinedProduct)}>Edit</Button>}
                     {!hideEditButton && wLcheck && ((((item?.wholeLifeStatusEnrolled && openreenrollment) || (wholifeEnrollmentClose && item?.wholeLifeStatusEnrolled) || (item?.wholeLifeStatusNotEnrolled && !openreenrollment))) || (item?.wholeLifeStatusEnrolled && wholeLifeNH)) && <Button className={classes.Button} onClick={() => editOnClick('WLCoverage', 'Whole Life')}>Edit</Button>}
                     {!hideEditButton && wLcheck && ((currentBenifitendorsementProcessBuid && item?.wholeLifeStatusNotEnrolled && openreenrollment)) && <Button className={classes.Button} onClick={() => editOnClick('WLCoverage', 'Whole Life')}>{'Enroll'}</Button>}
                     </>
                  ) : (
                    adminEndorsementProcessBuid ? (
                      //Admin section condtion buttons
                      <>
                        {!(item?.notEnrolled) && (item?.waived || item?.declinedProduct || item?.declinedProductTerminated || item?.waviedTreminated) ? (
                          <>   {!hideEditButton && !wLcheck && !(item?.notEnrolled) && <Button className={classes.Button} onClick={() => editOnClick(item?.everGreenFormName, item?.everGreenProductFamily)}>{'Edit'}</Button>}</>
                        ) : (
                          <>
                            {!hideEditButton && !wLcheck && !petProductFamily && !(item?.notEnrolled) && <Button className={classes.Button} onClick={() => handleCancelShowModel(item?.everGreenFormName, item?.everGreenProductFamily)}>Cancel Coverage</Button>}
                            {!hideEditButton && !wLcheck && !(item?.notEnrolled) && <Button className={classes.Button} onClick={() => editOnClick(item?.everGreenFormName, item?.everGreenProductFamily)}>{'Edit'}</Button>}
                            {!hideEditButton && !wLcheck && (item?.notEnrolled) && <Button className={classes.Button} onClick={() => handleEnroll(item?.everGreenFormName, item?.everGreenProductFamily)}>{'Enroll'}</Button>}
                            {!hideEditButton && wLcheck && ((currentBenifitendorsementProcessBuid && ((item?.wholeLifeStatusEnrolled && openreenrollment) || (wholifeEnrollmentClose && item?.wholeLifeStatusEnrolled) || (item?.wholeLifeStatusNotEnrolled && !openreenrollment))) || (item?.wholeLifeStatusEnrolled && wholeLifeNH)) && <Button className={classes.Button} onClick={() => editOnClick('WLCoverage', 'Whole Life')}>Edit</Button>}
                            {!hideEditButton && wLcheck && ((currentBenifitendorsementProcessBuid && item?.wholeLifeStatusNotEnrolled && openreenrollment)) && <Button className={classes.Button} onClick={() => editOnClick('WLCoverage', 'Whole Life')}>{'Enroll'}</Button>}
                            
                          </>
                        )}
                      </>

                    ) : (
                      <>
                        {(currentBenifitendorsementProcessBuid && (((isCoverageCancelled) && !petProductFamily && !item?.checkEvergereenEdit && !item?.notEnrolled && (item?.waived || item?.declinedProduct || item?.declinedProductTerminated || item?.waviedTreminated)) && ((!item?.checkEvergereenEdit && item?.secondTermination) && (item?.waived || item?.declinedProduct || item?.waviedTreminated || item?.declinedProductTerminated)))) ? (
                          ""
                        ) : (
                          <>
                            {
                              !isRateinformation && (
                                <>
                                  {!hideEditButton && !wLcheck && !endorsementProcessBuid && <Button className={classes.Button} onClick={() => editOnClick(item?.formName, item?.productFamily, item?.declinedProduct)}>Edit</Button>}
                                  {isGrandfathered ?
                                    <>
                                      {!hideEditButton && !wLcheck && currentBenifitendorsementProcessBuid && <Button className={classes.Button} onClick={() => handleCancelShowModel(item?.everGreenFormName, item?.everGreenProductFamily)}>Cancel Coverage</Button>}
                                    </>
                                    :
                                    <>
                                      {!hideEditButton && !wLcheck && currentBenifitendorsementProcessBuid && (((currentEnrollmentType !== 'NH' && previousYearCancelCoverage) || case9 || enrollmentDuringOEClosed || openreenrollmentcurrentBenifitNextyear) && !item?.checkEnroll && item?.cancelCoverage && !item?.notEnrolled) && <Button className={classes.Button} onClick={() => handleCancelShowModel(item?.everGreenFormName, item?.everGreenProductFamily)}>Cancel Coverage</Button>}
                                      {((!hideEditButton && !wLcheck && ((isQleProductModified && !item?.checkEnroll) || (currentBenifitendorsementProcessBuid && ((enrollmentDuringOE && !item?.notEnrolled && !item?.checkEnroll) || (openreenrollment && !item?.notEnrolled) || (enrollmentDuringOEClosed && item?.checkEvergereenEdit) || openEnrollmentPrevisousYear || (case9 && item?.checkEvergereenEdit)))))) && <Button className={classes.Button} onClick={() => editOnClick(item?.everGreenFormName, item?.everGreenProductFamily, item?.declinedProduct)}>{'Edit'}</Button>}
                                      {!hideEditButton && !wLcheck && ((currentBenifitendorsementProcessBuid && ((!enrollmentDuringOE && item?.checkEnroll) || (case9 && item?.checkEnroll) || (enrollmentDuringOE && item?.checkEnroll) || (enrollmentDuringOE && item?.notEnrolled) || (openreenrollment && item?.notEnrolled && !item?.cancelCoverage) || previousYearEvergreenEnroll || (enrollmentDuringOEClosed && item?.checkEnroll)))) && <Button className={classes.Button} onClick={() => handleEnroll(item?.everGreenFormName, item?.everGreenProductFamily)}>{'Enroll'}</Button>}
                                      {!hideEditButton && wLcheck && !OEWL && ((((item?.wholeLifeStatusEnrolled && openreenrollment) || (wholifeEnrollmentClose && item?.wholeLifeStatusEnrolled) || (item?.wholeLifeStatusNotEnrolled && !openreenrollment))) || (item?.wholeLifeStatusEnrolled && wholeLifeNH)) && <Button className={classes.Button} onClick={() => editOnClick('WLCoverage', 'Whole Life')}>Edit</Button>}
                                      {!hideEditButton && wLcheck && ((currentBenifitendorsementProcessBuid && item?.wholeLifeStatusNotEnrolled && openreenrollment)) && <Button className={classes.Button} onClick={() => editOnClick('WLCoverage', 'Whole Life')}>{'Enroll'}</Button>}
                                    </>
                                  }
                                </>
                              )}
                          </>
                        )}
                      </>
                    )
                  )}
                </div >
                <div className='custom-tableWrapper'>
                  <CustomTable
                    coverageItem={filteredCoverageData}
                    waived={item?.waived}
                    checkEnroll={item?.checkEnroll}
                    allPetProductWaived={allPetProductWaived}
                    index={index}
                    isViewRowDetails={true}
                    getCoveredMembers={item?.getCoveredMembers}
                    declinedProduct={item?.declinedProduct}
                    waviedTreminated={item?.waviedTreminated}
                    declinedProductTerminated={item?.declinedProductTerminated}
                    currentBenifitendorsementProcessBuid={currentBenifitendorsementProcessBuid}
                    notEnrolled={item?.notEnrolled}
                    coverProductBuid={item?.coverageProductBuid}
                    isReviewOnlyData={isRateinformation}
                    isPlandesignNotPresent={item?.isPlandesignNotPresent}
                    isWLProduct= {item?.wLcheck}
                    wLStatus = {item?.wLStatus}
                  />
                </div>
                {(
                  <div id="covergeModal" aria-label="Cancel Coverage Modal" className="modal" tabIndex="-1" data-id="my_id_value">
                    <div className="modal-dialog modal-md">
                      <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#0065AC", color: "#FFFFFF" }}>
                          <div className={classes.modaltitle} name={ProductfamilyBuid} >Cancel Coverage</div>
                          <Button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={() => cancelModal()}
                          >
                            X
                          </Button>
                        </div>
                        <div className="modal-body py-3">
                          <p className={classes.descprition1}>Are you sure you want to cancel the coverage? </p>
                          <p className={classes.descprition2}>You will not be able to undo this action once confirmed, but may have an option to re-enroll at a later date with a qualifying life event.</p>

                        </div>
                        <div className={classes.modalfooter}>
                          <Button className={classes.Button} onClick={() => cancelModal()} >No</Button>
                          <Button className={classes.Button} onClick={() => handleCancelCoverage(item?.everGreenFormName, item?.everGreenProductFamily)}>YES</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* {((currentBenifitendorsementProcessBuid && WLProduct) ||
                (WLProduct)) && (
                  <>
                    <div className={`pt-3 pb-4 ${classes.card}`} >
                      {item?.annualEnrollWL && (
                        <div className={'product-title'}>
                          {item?.productTitleWL}
                        </div>
                      )}
                      <Button className={`ml-3 mt-2 ${classes.ButtonEnroll}`} disabled>
                        Enrollment managed on Voya platform
                      </Button>
                      <div className='button-align'>
                        {!hideEditButton && ((currentBenifitendorsementProcessBuid && ((item?.wholeLifeStatusEnrolled && openreenrollment) || (wholifeEnrollmentClose && item?.wholeLifeStatusEnrolled) || (item?.wholeLifeStatusNotEnrolled && !openreenrollment))) || (item?.wholeLifeStatusEnrolled && wholeLifeNH)) && <Button className={classes.Button} onClick={() => editOnClick('WLCoverage', 'Whole Life')}>Edit</Button>}
                        {!hideEditButton && ((currentBenifitendorsementProcessBuid && item?.wholeLifeStatusNotEnrolled && openreenrollment)) && <Button className={classes.Button} onClick={() => editOnClick('WLCoverage', 'Whole Life')}>{'Enroll'}</Button>}
                      </div>
                    </div>
                  </>
                )} */}
            </>
          )
        })}

        <div className={classes.card}>
          <div className={classes.totalCostCardHeader}>
            <div className={classes.totalCostLabel}>{'Total Cost : '}</div>
            <div className={classes.totalCost}>
              {formatDollarAmountWithDecimals(totalCostMonthly)}
            </div>
          </div>
          <div className={classes.totalCostBtnAlign}>
            {
              location.pathname == '/confirmationScreen' && (
                <span className="col-12 py-3 " style={{ paddingLeft: '0px', paddingRight: '5rem' }}>
                  {verbiageConfig?.deductionVerbiage?.isEditable && (
                    <>
                      {verbiageConfig.deductionVerbiage.label}

                    </>
                  )
                  }
                </span>
              )
            }
            <Button
              className={btnSelected === 'day' ? classes.ButtonSelected : classes.ButtonAnnual}
              onClick={() => dayCostOnClick('day')}
            >
              Per Pay Period
            </Button>
            <Button
              className={btnSelected === 'annual' ? classes.ButtonSelected : classes.ButtonAnnual}
              onClick={() => dayCostOnClick('annual')}
            >
              Annual
            </Button>
          </div>
        </div>
      </Banner >
      <br />



      <>
        {
          <Banner>

            <BeneficiariesTable
              hideActions={true}
              productResourceData={productResourceData}
              productDetails={productDetails}
              beneficiaryConfigData={beneficiaryConfigData}
              beneficiariesProducts={beneficiariesProduct}
              qleEndorsementProcessBuid={qleEndorsementProcessBuid}
              openreenrollment={dependentanualEnrollment}
              currentBenifitendorsementProcessBuid={currentBenifitendorsementProcessBuid}
              reasonType={reasonType}
              isAdmin={isAdmin}
              hideEditButton={hideEditButton}
              isButtonVisible={isBenificiaryShow}
              currentStep={currentStep}
              isSummaryPage={isSummaryPage}
              {...props}
            />
          </Banner>
        }
      </>

      {
        location.pathname !== '/confirmationScreen' && (
          <div className="col-12 py-3 " style={{ paddingLeft: '0px' }}>
            {verbiageConfig?.deductionVerbiage?.isEditable && (
              <>
                {verbiageConfig?.deductionVerbiage?.label}

              </>
            )
            }
          </div>
        )
      }
      {
        location.pathname !== '/confirmationScreen' && (
          <>
            <div>
              <Button
                className={`${classes.confirmbtn} btn btn-primary continue-btn`}
                type="button"
                onClick={() => {
                  if (isReviewPageOnly) {
                    history.push('/enrollment/home');
                  } else {
                    handleConfirmEnrollent();
                  }
                }}
                disabled={hasErrors || confirmBtnEnabled}
                style={{ backgroundColor: '#0051A8' }}
              >
                {!isReviewPageOnly ? "Confirm Enrollment" : "Back to Home"}
              </Button>
            </div>
          </>
        )
      }

    </div >
  );
}


export default ProductTable;