import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Button, RadioButton, TextInput } from '../../Atoms';
import appConfig from '../../../../../appConfig';
import http from '../../../../../Common/Services/HttpService';
import { formatPhone, getBasicAuthHeaderValue } from '../../Helper/MFAHelper'
import { ADD_PHONE, ADD_PHONE_VERIFICATION_METHOD, SET_PREFERRED_CHANNEL } from '../../../../../Store/Slices/Registeration';
import { errorMessages } from '../../Atoms/FormValidation';
import { Link } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('phone number is required'),
    verificationMethod: Yup.string().required('Verificartion method is required')
});

const PhoneInput = ({ initialValues = { phoneNumber: '', verificationMethod: '' }, onNext, onPrev }) => {
    const dispatch = useDispatch();
    const { userAuth } = useSelector((storeState) => storeState?.auth);
    const isHrCenter = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter") ? true : false
    const formik = useFormik({
        initialValues: initialValues || { verificationMethod: '', phoneNumber: '' },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            dispatch(ADD_PHONE(values.phoneNumber))
            dispatch(ADD_PHONE_VERIFICATION_METHOD(values.verificationMethod))
            submitHandler({
                phoneNumber: values.phoneNumber,
                verificationMethod: values.verificationMethod
            });
        },
    });

    const submitHandler = async (data) => {
        const token = getBasicAuthHeaderValue();
        try {
            const resp = await http.post(
                appConfig.SSP_AUTH_URL + "/stateful/user-mgmt/change-phone-number/1",
                data,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Basic ${token}`,
                        'X-REQUEST-ID': Math.random(200),
                    }
                },
            );
            if (resp.status === 200) {
                const channel = resp?.data?.channel;
                if (channel) {
                    dispatch(SET_PREFERRED_CHANNEL(channel))
                    onNext();
                    return;
                }
            } else {
                formik.setFieldError('phoneNumber', errorMessages(resp));
            }
            console.error(resp);
        } catch (e) {
            formik.setFieldError('phoneNumber', errorMessages(e));
            return { error: e.message };
        }
    }

    const validationMsg = (field) => {
        return (formik.touched[field] && formik.errors[field] && (
            <div className="form-mandatory">{formik.errors[field]}</div>
        ))
    };

    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        formik.setFieldTouched(name)
        formik.setFieldValue(name, value);
    }

    const onChangePhone = (e) => {
        const { value, name } = e.target;
        formik.handleChange(e);
        formik.setFieldValue(`${name}_masked`, formatPhone(value));
    }

    const radioOptions = [{ value: 'call', label: 'Call' }, { value: 'sms', label: 'Text' }];

    return (
        <form onSubmit={formik.handleSubmit} noValidate>
            <div className="row">
                <div className="col-12">
                    <h1 className="form-side-header">Change MFA Contact</h1>
                    <p className="form-mandatory mb-0">Fields marked with * are mandatory</p>
                    <p>Please change an existing mobile number.</p>
                </div>
            </div>
            <div className="row py-2">
                <div className="col-sm-12 col-md-6">
                    <TextInput
                        required
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Mobile Number"
                        placeholder="(xxx) xxx-xxxx"
                        className="w-100 inputField p-3"
                        {...formik.getFieldProps('phoneNumber')}
                        value={formik.values?.phoneNumber_masked || ''}
                        onChange={onChangePhone}
                    />
                    {validationMsg('phoneNumber')}
                </div>
                <div className="col-sm-12 offset-md-4"></div>
                <div className='col-12 mt-3'>
                    <p>We must validate your ownership of this mobile number by sending a code.</p>
                </div>
                <div className="col-12 my-3">
                    <RadioButton
                        id="verificationMethod"
                        name="verificationMethod"
                        className="inline register"
                        options={radioOptions}
                        selectedOption={formik.values.verificationMethod}
                        onChange={handleRadioChange}
                        label="How do you like to receive the code?"
                    />
                    {validationMsg('verificationMethod')}
                </div>
                <div className='col-12 mt-3'>
                    <p>* Data rates may apply</p>
                    <Button type="submit" disabled={!formik.isValid}>Send Code</Button>
                </div>
            </div>
            <div className="row py-4 justify-content-between">
                <div className="col-sm-12 col-md-4 py-2">
                    {isHrCenter ? <Link role="link" to={`/hrcenter`}> Back to home</Link> : <Link role="link" to={`/enrollment/welcome`}> Back to home</Link>}
                </div>
                <div className="col-sm-12 col-md-6 py-2 mr-4 text-md-right">
                    <Button type="button" className="btn btn-outline primaryOutline btn-outline-primary-contrast mr-3" onClick={onPrev}>Back</Button>
                    <Button type="submit" className="btn btn-primary primaryBackground btn-primary-contrast" disabled={!formik.isValid}>Continue</Button>
                </div>
            </div>
        </form>
    );
}

export default PhoneInput;
