import React, { useEffect } from 'react';
import PetAssureNationContent from '../Component/Common/PetAssureNationContent';
import { UPDATE_COVERAGE_DETAILS } from '../../../../Store/Slices/InsuredInfo';
import { useDispatch, useSelector } from 'react-redux';
import Summary from '../Component/Common/Summary';
import { UPDATE_CART_ITEM_NEW } from '../../../../Store/Slices/EmployeeProfile';
import { useNavigationHook } from '../Hooks/NavigationHook';
const Flow6 = (props) => {
  const dispatch = useDispatch();
  const { currentStep } = useNavigationHook();
  const {
    step,
    validateSubscribedProductData,
    RadioSelctedValue,
    declineProduct,
    validateUnSubscribedProductData,
  } = props || {};

  const { insuredInfo } = useSelector((storeState) => storeState);
  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);

  const productTitle = useSelector((storeState) => {
    let productTitleContent = storeState.entities?.response?.onLoadResponseList?.[
      `SearchPolicyProcessResources${currentStep}`
    ]?._Result?.filter((data) => data?.code === 'PRODTITLE');
    
    return productTitleContent && productTitleContent.length > 0
      ? productTitleContent[0]?.content
      : '';
  });

  const coverageDetailsData = insuredInfo?.[currentStep]?.petList || [];

  useEffect(() => {
    if (productTitle && coverageDetailsData) {
      updateCostBasket();
    }
  }, [productTitle, coverageDetailsData]);

  if (!step || !currentStep) {
    return null; // return null if step or currentStep is null or undefined
  }

  const handleCoverageChange = (
    personBuid,
    selectedValue,
    waivedCoverage,
    coverageBuid,
    costPerPayPeriod
  ) => {
    if (personBuid && selectedValue && waivedCoverage && coverageBuid && costPerPayPeriod) {
      dispatch(
        UPDATE_COVERAGE_DETAILS({
          personBuid,
          selectedValue,
          waivedCoverage,
          currentStep,
          coverageBuid,
          costPerPayPeriod,
        })
      );
    }
  };

  const updateCostBasket = () => {
    if (currentStep && productTitle && coverageDetailsData) {
      dispatch(
        UPDATE_CART_ITEM_NEW({
          id: currentStep,
          moreThanOne: true,
          name: productTitle,
          coverageList: coverageDetailsData,
        })
      );
    }
  };

  const steps = [
    {
      label: '',
      component: () => (
        <PetAssureNationContent
          stepsCount={steps.length}
          employeeIndex="0"
          displayMode={'All'}
          {...props}
        />
      ),
    },
    {
      label: 'Disclosure Statement',
      component: () => (
        <div className="summaryWrapper">
          <Summary
            RadioSelctedValue={RadioSelctedValue}
            declineProduct={declineProduct}
            showDisclosureStatement={props.showDisclosureStatement}
            validateSubscribedProductData={validateSubscribedProductData}
            validateUnSubscribedProductData={validateUnSubscribedProductData}
            {...props}
          ></Summary>
        </div>
      ),
    }
  ];

  const currentStepInfo = steps[step - 1];
  if (!currentStepInfo) {
    return null;
  }

  return (
    <>
      {currentStepInfo.component()}
    </>
  );
};

export default Flow6;
