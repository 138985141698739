import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faUserTie,
  faIdCard,
  faUsers,
  faCheck,
  faLock,
  faChevronLeft,
  faChevronRight,
  faAngleDown,
  faAngleUp,
  faMale,
  faFemale,
  faPen,
  faTrashAlt,
  faPlus,
  faBars,
  faGlobe,
  faTimes,
  faMinus,
  faAngleLeft,
  faAngleRight,
  faBell,
  faCircle,
  faUserCircle,
  faEllipsisV
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faFacebookF, faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';

library.add(
  faUserTie,
  faIdCard,
  faUsers,
  faFacebookF,
  faTwitter,
  faLinkedin,
  faYoutube,
  faCheck,
  faLock,
  faChevronLeft,
  faAngleDown,
  faAngleUp,
  faCalendarAlt,
  faChevronRight,
  faMale,
  faFemale,
  faPen,
  faTrashAlt,
  faPlus,
  faBars,
  faGlobe,
  faTimes,
  faMinus,
  faAngleLeft,
  faAngleRight,
  faBell,
  faCircle,
  faUserCircle,
  faEllipsisV
);
