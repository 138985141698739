import { dependentCodes } from "../../../../Common/Constants/CommonConstants";
import _ from "lodash";
import HttpService from "../../../../Common/Services/HttpService";
import appConfig from "../../../../appConfig";
import { getBasicAuthHeaderValue } from "./MFAHelper";
import { CipherUtils } from "../../../Utils/Helper/MFAHelper";

export const getDependentDetailsRequiredFlag = (data) => {
    let dependentDetailsRequiredFlag = false;
    if (data.coverages && data.coverages.length) {
        data.coverages.map((item) => {
            const coveredDependants = item?.memberOfferedCoverage?.option?.insuredConfiguration?.coveredDependants;
            if (coveredDependants && coveredDependants.length) {
                if (coveredDependants.some((elem) => dependentCodes.includes(elem))) {
                    dependentDetailsRequiredFlag = true;
                    return
                }
            }
        })
    }
    return dependentDetailsRequiredFlag
}

export const getFileExtension = (filename) => {
    return filename?.slice(((filename?.lastIndexOf('.') - 1) >>> 0) + 2);
};

export const getWLProductInfo = (gbpResources) => {
    const title = gbpResources?.find(item => item.code === 'PRODTITLWL')?.content || '';
    const logo = gbpResources?.find((data) => data.code == 'PRODLOGOWL') || ''
    const description = gbpResources?.find((data) => data.code == 'PRODSHTDWL')?.content || ''
    const base64 = logo?.binaryContent || '';
    const fileType = getFileExtension(logo?.content) || '';
    const image = base64 ? "data:image/" + fileType + ";base64," + (base64) : '';

    return {
        image,
        title,
        logo,
        fileType,
        description,
    }
}

export const getCoverageEligibility = ({ eligibilityArray, personBuid }) => {
    if ( eligibilityArray && Array.isArray(eligibilityArray) && eligibilityArray.length) {
        let memberEligibilityObj = _.findLast(eligibilityArray, (item) => {
          return item.personBuid === personBuid;
        });
        return memberEligibilityObj?.coverageEligiblity;
    }
    return false;
}


export const getFileName = (filename) => {
    return filename?.substring(0, filename?.lastIndexOf(".")).replaceAll(" ", "").replaceAll("-", "").replaceAll("_", "");
};
export const apiCall = async(url, method, payload) =>{
    return await HttpService[method](
        appConfig.SSP_AUTH_URL + url,
        payload,
        {
            withCredentials: true,
            headers: {
                Authorization: `Basic ${getBasicAuthHeaderValue()}`,
                'X-REQUEST-ID': Math.random(200),
            }
        },
    )
}

const getRemoteUserFromResponse = (response) => {
    if (response) {
      let requiredValue = 'remote_user';
      let params = response.split('&');
      for (let i = 0; i < params.length; i++) {
        let value = params[i].split('=');
        if (value[0] === requiredValue) {
          return value[1];
        }
      }
      return false;
    }
  };

export const MFAToken = async (redirectionUrl) => {
    let remote_user = getRemoteUserFromResponse(redirectionUrl)// || userAuthData.authenticatedUser;
    let decrypted;
    let value = decodeURIComponent(remote_user);
    if (remote_user) {
      try {
        decrypted = await CipherUtils.decrypt(value, process.env.REACT_APP_SSP_PRIVATE_KEY);
      } catch (err) {
        console.error('crypto support is disabled!');
      }
      const email = decrypted.slice(4);
      return {
        decrypted : decrypted,
        email : email
      }
    }
  };


  // Trim input values
export const trimPayload = (payload) => {
  const trimValue = (value) => {
    // Trim string values
    if (typeof value === 'string' && value !== null) {
      return value.trim();
    }
    // Recursively trim object values
    if (typeof value === 'object' && value !== null) {
      return trimPayload(value);
    }
    // Return non-string and non-object values as is
    return value;
  };

  // Trim all values in the payload object
  if (typeof payload === 'object' && payload !== null) {
    return Object.keys(payload).reduce((acc, key) => {
      // Skip trimming for certain keys
      const skipKeys = ['fileName', 'fileByClient'];
      acc[key] = (skipKeys.includes(key)) ? payload[key] :trimValue(payload[key]);
      return acc;
    }, {});
  }

  // Return non-object payloads as is
  return payload;
};

