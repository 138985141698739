import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import Loader from '../../../../Atoms/Loader';
import appConfig from '../../../../../appConfig';
import QuickLinks from '../QuickLinks/QuickLinks';
import FlipCard from '../../../CustomSection/Atoms/FlipCard';
import http from '../../../../../Common/Services/HttpService';
import Header from '../../../CustomSection/Component/Header/Header'
import ProductSlider from '../../../CustomSection/Atoms/ProductSlider';

import { SET_CLIENT_NAME } from '../../../../../Store/Slices/CallCenter';
import { onLoadAPI } from '../../../../../Store/Slices/Entities/response';
import { processTypeMFA } from '../../../../../Store/Slices/Auth/userAuth';
import { apiReceived } from '../../../../../Store/Slices/Auth/loggedUserEntity';
import { REMOVE_CURRENTSTEP_ALERT_MESSAGE, SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../../../Store/Slices/InsuredInfo';

import './Home.css'
import FormatHelper from '../../../../Utils/Helper/FormatHelper';

const formatHelper = new FormatHelper();

const Home = (props) => {
    const dispatch = useDispatch();
    let legalEntityId = useSelector((storeState) => storeState.registeration?.legalEntityId);
    const userAuth = useSelector((storeState) => storeState.auth.userAuth);
    const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
    const [contractProducts, setContractProducts] = useState([]);
    const [enrollmentType, setEnrollmentType] = useState('');
    const [employeeInfo, setEmployeeInfo] = useState({ totalCount: 0, enrolledCount: 0, employeeChart: {}, enrolledChart: {} });
    const enrollmentStatus = useSelector((storeState) => storeState.entities.constants?.data?.EnrollmentStatusConstants);

    if (legalEntityId && legalEntityId.includes(':')) {
        legalEntityId = legalEntityId.substring(0, legalEntityId.indexOf(':'))
    }

    useEffect(() => {
        const tempEnrollmentType = enrollmentStatus?.find(item => item.value === enrollmentType)?.text || enrollmentType;
        setEnrollmentType(tempEnrollmentType);
    }, [enrollmentStatus, enrollmentType]);

    const handleHrCenterLegalEntity = async () => {
        dispatch(UPDATE_LOADING(true));
        dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE('HrCenterScreen'));
       
        await http
            .post(appConfig.SSP_MID_URL + `/v1/hrcenter`, {
                legalEntityId: legalEntityId,
                email: userAuth?.data?.user_name
            })
            .then(async (resp) => {
                const data = resp?.data?.data;
                for (const key in data) {
                    let result = { _Result: data[key].data }
                    let val = {}
                    switch (key) {
                        case 'getLoginUser':
                            if (!data[key]?.success) {
                                dispatch(SET_ALERT_MESSAGE({
                                    type: 'error',
                                    errorCode: data[key]?.data?.error?.code || '520',
                                    continue: true,
                                    id: 'getLoginUser',
                                    show: true,
                                    page: 'HrCenterScreen',
                                }));
                            } else {
                                let loginData = data[key].data;
                                Object.assign(val, { person: loginData });
                                dispatch(apiReceived(val));
                            }
                            break;
                        case 'getLegalEntity':
                            if (!data[key]?.success) {
                                dispatch(SET_ALERT_MESSAGE({
                                    type: 'error',
                                    errorCode: data[key]?.data?.error?.code || '520',
                                    continue: true,
                                    id: 'getLegalEntity',
                                    show: true,
                                    page: 'HrCenterScreen',
                                }));
                            } else {
                                const legalEntityData = result?._Result
                                let clientName = `${legalEntityData?.ExClientID}: ${legalEntityData?.LegalName}`;
                                dispatch(
                                    SET_CLIENT_NAME({
                                        userType: 'HrCenter',
                                        clientName: clientName,
                                        clientBuid: legalEntityData?.buid
                                    })
                                );
                            }
                            break;
                        case 'summaryInfo': {
                            if (!data[key]?.success) {
                                dispatch(SET_ALERT_MESSAGE({
                                    type: 'error',
                                    errorCode: data[key]?.data?.error?.code || '500',
                                    continue: true,
                                    id: 'summaryInfo',
                                    show: true,
                                    page: 'HrCenterScreen',
                                }));
                            } else {
                                const summaryInfo = result?._Result;
                                const employeeChart = {};
                                const enrolledChart = {};
                                let totalCount = 0;
                                let enrolledCount = 0;
                                const odsProducts = [];
                                summaryInfo?.forEach(item => {
                                    totalCount += item.EmployeeCount;
                                    employeeChart[item.EmployeeStatus] = (employeeChart[item.EmployeeStatus] || 0) + item.EmployeeCount;
                                    if (item.EmployeeStatus === 'Active' && item.ProductDesc && item.ProductCd !== 'EMPLOYEE COUNT') {
                                        enrolledChart[item.ProductDesc] = item.EmployeeCount;
                                        odsProducts.push({ code: item.ProductCd, name: item.ProductDesc });
                                    }
                                    if (item.ProductCd === 'EMPLOYEE COUNT') {
                                        enrolledCount = item.EmployeeCount;
                                    }
                                })
                                dispatch(onLoadAPI({ 'getListOfEligibleProducts': odsProducts }));
                                setEmployeeInfo({ totalCount, enrolledCount, employeeChart, enrolledChart });
                            }
                            break;
                        }
                        case 'contractInfo': {
                            if (!data[key]?.success) {
                                dispatch(SET_ALERT_MESSAGE({
                                    type: 'error',
                                    errorCode: data[key]?.data?.error?.code || '500',
                                    continue: true,
                                    id: 'getListOfEligibleProducts',
                                    show: true,
                                    page: 'HrCenterScreen',
                                }));
                            } else {
                                const enrollmentType = enrollmentStatus?.find(item => item.value === result?._Result?.enrollmentKind)?.text
                                setContractProducts(result?._Result?.products);
                                setEnrollmentType(enrollmentType || result?._Result?.enrollmentKind);
                            }
                            break;
                        }
                        default:
                    }
                }

                dispatch(UPDATE_LOADING(false));
                return true;
            })
            .catch((e) => {
                dispatch(SET_ALERT_MESSAGE({
                    type: 'error',
                    errorCode: '520',
                    continue: true,
                    id: 'HrCenterScreen',
                    show: true,
                    page: 'HrCenterScreen',
                }));
                dispatch(UPDATE_LOADING(false));
                return false;
            });
    }

    useEffect(() => {
        dispatch(processTypeMFA({ userType: 'HrCenter', processType: 'HrCenter' }))
        handleHrCenterLegalEntity();
    }, [legalEntityId])

    return (
        <div className='hr-dashboard'>
            <Header title={'Dashboard'} />
            {
                isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />
            }
            <div className='products'>
                <div className='section-title'>Products</div>
                <ProductSlider products={contractProducts} count={2} />
            </div>
            <div className='enrollment-info'>
                <div className='section-title'>Enrollments</div>
                <table className='enrollment-info-table'>
                    <tr>
                        <th>Enrollment Period</th>
                        <th>Eligible Employees</th>
                        <th>Enrolled Employees</th>
                    </tr>
                    <tr>
                        <td>{enrollmentType}</td>
                        <td>{formatHelper.numberFormat(employeeInfo?.totalCount)}</td>
                        <td>{formatHelper.numberFormat(employeeInfo?.enrolledCount)}</td>
                    </tr>
                </table>
                <div className='chart-area'>
                    <FlipCard title='Total Employees' data={employeeInfo?.employeeChart} count={employeeInfo?.totalCount} />
                    <FlipCard title='Enrolled Employees' data={employeeInfo?.enrolledChart} count={employeeInfo?.enrolledCount} />
                </div>
            </div >
            <div className='quick-links'>
                <QuickLinks {...props} />
            </div>
        </div>
    )
}

export default Home;
