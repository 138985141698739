import React from 'react';
import { useSelector } from 'react-redux';

export const LeftBannerContent = () => {
    const { loginConfigInfo } = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList);
    const result = loginConfigInfo?._Result
    return <>
    {(result?.welcomeTitle || result?.welcomeMsg) && 
        <div className="row overlay-content px-3 mx-5 pt-3 ">
            <div className="col-12">
                <div className="bannerTitle" style={{ color: result?.titleColor ? result?.titleColor : '#0065ac' }}>
                {result?.welcomeTitle}
                </div>
            </div>
            <div className="col-12 ">
                <p className="bannerBody"  style={{ color: result?.msgColor ? result?.msgColor : '#FF0000' }}>
                {result?.welcomeMsg}
                </p>
            </div>
        </div>
            }
        </>
}