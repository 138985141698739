import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Button, TextInput } from '../../Atoms';
import SendChallenge from '../Actions/SendChallenge';
import { SET_MFA_PRIMARY_LOGIN_PAYLOAD, SET_MFA_MASKED_LOGIN, SET_PREFERRED_CHANNEL } from '../../../../../Store/Slices/Registeration';
import { maskEmail } from '../../Helper/MFAHelper'
import Loader from '../../Atoms/Loader';
import { SHOW_LOADER } from '../../../../../Store/Slices/Loader/index';
import { errorMessages } from '../../Atoms/FormValidation'
import { Link } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  emailId: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});

const ResetPasswordContainer = ({ stepValues = { emailId: '' }, onNext }) => {

  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.loader);
  const [err, setErr] = useState('');
  const { legalEntityId = '', appCode = '', resetPasswordSource } = useSelector((storeState) => storeState?.registeration);
  const { userAuth } = useSelector((storeState) => storeState?.auth);
  const isHrCenter = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter") ? true : false
  const formik = useFormik({
    initialValues: stepValues || {
      emailId: '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      handleResetPassword({ login: values.emailId });
    },
  });

  const clearErrMsg = () => {
    setErr('');
  }

  const handleResetPassword = async (data) => {
    dispatch(SHOW_LOADER(true));
    const sendOtp = await SendChallenge(data.login);
    if (sendOtp.status === 200) {
      const channel = sendOtp?.data?.channel;
      if (channel) {
        dispatch(SET_PREFERRED_CHANNEL(channel))
      }
      dispatch(SET_MFA_PRIMARY_LOGIN_PAYLOAD(data));
      const maskedEmail = maskEmail(data.login);
      dispatch(SET_MFA_MASKED_LOGIN(maskedEmail));
      onNext(formik.values);
      dispatch(SHOW_LOADER(false));
    }
    else {
      dispatch(SHOW_LOADER(false));
      setErr(errorMessages(sendOtp.error));
    }
  }

  const validationMsg = (field) => {
    return (formik.touched[field] && formik.errors[field] && (
      <div className="form-mandatory">{formik.errors[field]}</div>
    ))
  }

  return (
    <>
      {loader && <Loader loading={loader} />}
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="row">
          <div className="col-12">
            <h1 className="form-side-header">Reset Password</h1>
            <p className="form-mandatory mb-0">
              Fields marked with * are mandatory
            </p>
            <p>Enter your registered email ID and continue to initiate password reset.</p>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-sm-12 col-md-6">
            <TextInput
              label="Registered Email ID"
              required={true}
              id="emailId"
              name="emailId"
              placeholder="XXX@domain.com"
              className="w-100 inputField p-3"
              onInput={clearErrMsg}
              {...formik.getFieldProps('emailId')}
            />
            {validationMsg('emailId')}
          </div>
          <div className="col-sm-12 offset-md-4"></div>
        </div>
        {err &&
          <div className="row">
            <span className='APIErr'>{err}</span>
          </div>
        }
        <div className="row mfaBtnWrapper">
          <div className="col-sm-12 col-md-4 py-2">
            {resetPasswordSource === 'login' ?
              <Link role="link" to={`/enrollment?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Back to login</Link>
              :
              isHrCenter ? <Link role="button" to={`/hrcenter`}> Back to home</Link> : <Link role="button" to={`/enrollment/home`}> Back to home</Link>
            }
          </div>
          <div className="col-sm-12 col-md-6 py-2 mr-4 text-md-right">
            <Button type="submit"
              disabled={(!formik.isValid || !formik.dirty)}
              className="btn btn-primary primaryBackground btn-primary-contrast"
            >
              Continue
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default ResetPasswordContainer;
