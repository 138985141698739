import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../appConfig/index.js';
import http from '../../../../Common/Services/HttpService/index.js';
import { CHECK_ELIGIBILITY, SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo/index.js';

export const getPetCoverages = (formik, currentStep) => async (dispatch) => {

  dispatch(UPDATE_LOADING(true)); // Turn on loader

  try {
    const response = await http.post(appConfig.SSP_MID_URL + '/getProductPresentation', {
      data: {
        PetAssureNation: {
          petName: formik?.values?.petName?.trim(),
          petAge: formik?.values?.petAge,
          email: formik?.values?.email,
          petType: formik?.values?.petType,
          petBreed: formik?.values?.petBreed,
          zipCode: formik?.values?.zipCode,
        },
        currentStepInfo: {
          "portalStep": currentStep
        }
      },
    });

    const petCoverages = response?.data?._Result;

    dispatch(CHECK_ELIGIBILITY(petCoverages)); // Assuming CHECK_ELIGIBILITY is an action for storing coverages

    return petCoverages;
  } catch (error) {
    dispatch(SET_ALERT_MESSAGE({
      type: 'error',
      errorCode: '520',
      id: 'getProductPresentation',
      show: false,
      page: currentStep
    }));
    console.error(error);
    return null;
  } finally {
    dispatch(UPDATE_LOADING(false)); // Turn off loader
  }
};
