import React from 'react';
//import './styles.css';
import { Styles } from './styles';
import { Linker } from '../../../Atoms';
const DropdownItems = (props) => {
  const { item } = props;
    const resetDrawer = () => {
      if (props.onClick) {
        props.onClick();
      }
    };

  return (
    <>
      <Styles />
      <li className="menuContainer">
        <div>
          <Linker to={item.to} className="menuItem" onClick={props.onClick}>
          {item.htmlText ? (
            <span dangerouslySetInnerHTML={{__html: item.dropdownLink}} />
          ) : (
            item.dropdownLink
          )}
          </Linker>
          <div className="subItemContainer">
            <ul className="col">
              {item?.dropdownSubLinks?.map((subLink) => (
                <li
                  className={
                    subLink.placement ? `subMenuItem ${subLink.placement} ` : `subMenuItem`
                  }
                  key={subLink.subLinkContent}
                >
                  <Linker to={subLink.to} onClick={resetDrawer}>
                    {subLink.subLinkContent}
                  </Linker>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </li>
    </>
  );
};

export default DropdownItems;
