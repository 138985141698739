import React from 'react';
import PropTypes from 'prop-types';
//  import './styles.css';
 import { Styles } from './styles'
import { Link } from 'react-router-dom';
const Button = (props) => {
  const {
    id,
    name,
    onMouseEnter,
    onMouseLeave,
    className,
    children,
    content,
    loading = false,
    disabled,
    rounded,
    outline,
    dropDown,
    toggle,
    menuList,
    ...rest
  } = props;

  const handleMouseEnter = (event) => {
    onMouseEnter && onMouseEnter(event);
  };

  const handleMouseLeave = (event) => {
    onMouseLeave && onMouseLeave(event);
  };

  let classes = 'btn ';

  if(dropDown){
    classes += ' dropdown-toggle ';
  }
  if (rounded) {
    classes += ' py-2 px-4 moreRounded ';
  }
  if (outline) {
    classes += ' btn-outline-primary primaryOutline ';
  } 

  classes += className;

  return (
    <>
      <Styles />
      <div className="buttonDropDown dropdown">
        <button
          id={name}
          key={id}
          name={name}
          type="button"
          className={classes}
          disabled={loading || disabled}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          data-toggle={toggle || 'dropdown'}
          aria-haspopup="true"
          aria-expanded="false"
          {...rest}
        >
          {content || children}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {menuList.map((menu) => {
            return (
              <>
                {/* <Styles /> */}
                <Link to={menu.to} className="dropdown-item">
                  {menu.linkLabel}
                </Link>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
Button.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  dropDown: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  content: PropTypes.any,
};
Button.defaultProps = {
  disabled: false,
  outline: false,
  rounded: false,
  className: '',
};

export default Button;
