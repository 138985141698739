import React, { useState, useEffect } from 'react';
import EmployeeInsuredQuestionsContent from '../Component/Common/EmployeeInsuredQuestionsContent';
import EmployeeCoverageWrapper from '../Container/EmployeeCoverageWrapper';
import SpouseInsuredQuestionsContent from '../Component/Common/SpouseInsuredQuestionsContent';
import SpouseCoverageWrapper from '../Container/SpouseCoverageWrapper.js';
import ChildCoverageWrapper from '../Container/ChildCoverageWrapper.js';
import { UPDATE_COVERAGE_DETAILS }

  from '../../../../Store/Slices/InsuredInfo';
import Summary from '../Component/Common/Summary'
import { useDispatch, useSelector } from 'react-redux';
import logoAflac from '../../../../Assets/Images/aflac-logo.png'
import imgMetlife from '../../../../Assets/Images/met-life.png'
import { UPDATE_CART_ITEM_NEW } from '../../../../Store/Slices/EmployeeProfile';
import ChildInsuredQuestionsContent from '../Component/Common/ChildInsuredQuestionsContent';
import CONSTANTS from '../Content/Constants.json';

const Flow3 = (props


) => {
  const { currentStep, step, nextStep, prevStep, employee, spouse, children, showDisclosureStatement, validateSubscribedProductData, RadioSelctedValue, declineProduct } = props;
  const dispatch = useDispatch();
  const { insuredInfo } = useSelector((storeState) => storeState);
  const [currentSelectedValue, setCurrentSelectedValue] = useState('');
  const productTitle = useSelector(
    (storeState) => {
      let x =
        storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result?.filter(
          (data) => data.code == 'PRODTITLE'
        );
      if (x == undefined) {
        return '';
      } else {
        return x[0]?.content;
      }
    }
  );
  let coverageDetailsData = insuredInfo[currentStep]?.coverageDetails;

  useEffect(() => {
    if (coverageDetailsData && productTitle) {
      updateCostBasket()
    }
  }, [coverageDetailsData, productTitle]);


  let [coverageWaivedOffArr, setCoverageWaivedOffArr] = useState([]);
  let [childCoveragewaived, setChildCoverageWaiver] = useState();
  const setCoverageWaivedBoolean = (isCoverageWaived, spouseIndex) => {
    if (isCoverageWaived) {
      if (!coverageWaivedOffArr.includes(spouseIndex)) {
        setCoverageWaivedOffArr([...coverageWaivedOffArr, spouseIndex]);
      }
    } else {
      const index = coverageWaivedOffArr.indexOf(spouseIndex);
      if (index > -1) {
        coverageWaivedOffArr.splice(index, 1);
        setCoverageWaivedOffArr([...coverageWaivedOffArr])
      }
    }
  }

  const setChildCoverageWaivedBoolean = (isCoverageWaived, childIndex) => {
    setChildCoverageWaiver(isCoverageWaived)
  }

  const handleCoverageChange = (personBuid, selectedValue, waivedCoverage, coverageBuid, costPerPayPeriod, relationship) => {

    setCurrentSelectedValue(selectedValue)
    dispatch(UPDATE_COVERAGE_DETAILS({ personBuid, selectedValue, waivedCoverage, currentStep, coverageBuid, costPerPayPeriod, relationship }));
  };
  const updateCostBasket = () => {
    dispatch(
      UPDATE_CART_ITEM_NEW({
        id: currentStep,
        moreThanOne: true,
        name: productTitle,
        coverageList: coverageDetailsData,
      })
    );
  };

  const [skipSpouse, setSkipSpouse] = useState([]);
  const skipSpouseFn = (pskipData) => {
    setSkipSpouse([...pskipData]);
  };

  const [skipChild, setSkipChild] = useState([]);
  const skipChildFn = (pskipData) => {
    setSkipChild([...pskipData]);
  };

  const steps = [
    {
      label: '',
      component: () => <div><EmployeeInsuredQuestionsContent employeeIndex="0" displayMode={'Employee'} {...props}></EmployeeInsuredQuestionsContent></div>,
    },
    {
      label: '',
      component: () => <div><EmployeeCoverageWrapper employeeIndex="0" displayMode={'Employee'} handleCoverageChange={handleCoverageChange} {...props}></EmployeeCoverageWrapper></div>,
    },
  ];

  if (spouse && spouse.length) {
    spouse.forEach((sp, index) => {
      const relationship = sp.insured.insured.mainOwnerRelationshipToInsured;
      const displayMode = CONSTANTS.relationship.find(relation => relation.value === relationship)?.label || 'Spouse';

      const skipData = skipSpouse.filter((pobj)=>{
        return pobj.coverageEligiblity === false && pobj.buid === sp.personBuid
      });      
        steps.push(
          {
            label: `Critical Illness - ${displayMode}`,
            logo: <img src={props.flowType === 1 ? imgMetlife : logoAflac} name={'Logo'} className={'img-fluid line-class '} alt={'An image'} />,
            component: () => <div><SpouseInsuredQuestionsContent skipSpouse={skipSpouseFn} setCoverageWaivedBoolean={setCoverageWaivedBoolean} spouseIndex="0" displayMode={displayMode}  {...props}></SpouseInsuredQuestionsContent></div>,
          }
        );

      if(skipData.length == 0){
        if (!coverageWaivedOffArr.length) {
          steps.push(
            {
              label: `Critical Illness - ${displayMode}`,
              logo: <img src={props.flowType === 1 ? imgMetlife : logoAflac} name={'Logo'} className={'img-fluid line-class '} alt={'An image'} />,
              component: () => <div><SpouseCoverageWrapper spouseIndex="0" displayMode={displayMode}   {...props} handleCoverageChange={handleCoverageChange}></SpouseCoverageWrapper></div>,
            }
          )
        } else {
          coverageWaivedOffArr.map((item, i) => {
            if (item !== index) {
              steps.push(
                {
                  label: `Critical Illness - ${displayMode}`,
                  logo: <img src={props.flowType === 1 ? imgMetlife : logoAflac} name={'Logo'} className={'img-fluid line-class '} alt={'An image'} />,
                  component: () => <div><SpouseCoverageWrapper spouseIndex="0" displayMode={displayMode}   {...props} handleCoverageChange={handleCoverageChange}></SpouseCoverageWrapper></div>,
                },
              )
            }
          });
        }
      }
    });
  }

  if (children && children.length) {
    
      steps.push({
        label: `Child Coverage`,
        component: () => <div><ChildInsuredQuestionsContent skipChild={skipChildFn} setChildCoverageWaivedBoolean={setChildCoverageWaivedBoolean} childIndex={'1'} displayMode={`Children`} {...props}></ChildInsuredQuestionsContent></div>,
      });
    if(children.length !== skipChild.length) { 
      if (!childCoveragewaived) {
        steps.push({
          label: `Child Coverage`,
          component: () => <div><ChildCoverageWrapper childIndex={'0'} displayMode={`Child`} {...props} handleCoverageChange={handleCoverageChange}></ChildCoverageWrapper></div>,
        });
      }
    }
  }

  // if (children && children.length) {
  //   children.forEach((child, index) => {
  //     steps.push(
  //       {
  //         label: `Critical Illness - Child ${index + 1}`,
  //         component: () => <div><ChildCoverageWrapper displayMode={`Child ${index + 1}`} {...props} handleCoverageChange={handleCoverageChange}></ChildCoverageWrapper></div>,
  //       },
  //     );
  //   });
  // }

  if (showDisclosureStatement) {
    steps.push({
      label: 'Disclosure Statement',
      component: () => <div className='summaryWrapper'><Summary RadioSelctedValue={RadioSelctedValue} showDisclosureStatement={showDisclosureStatement} validateSubscribedProductData={validateSubscribedProductData} declineProduct={declineProduct} {...props}></Summary></div>,
    });
  }

  const currentStepInfo = steps[step - 1];

  if (!currentStepInfo) {
    return null; // or return some other default UI or error message
  }
  return (
    <>
      {currentStepInfo.component()}
    </>
  );
};

export default Flow3;
