import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { Button } from "../../../Atoms"
import appConfig from '../../../../appConfig';
import CrossSell from "../../../Molecules/CrossSell"
import http from '../../../../Common/Services/HttpService';
import SectionHeader from '../../../Molecules/SectionHeader';
import { SHOW_CONTENT_LOADER } from '../../../../Store/Slices/Loader';
import useOnBack from '../../../Molecules/CustomSection/Hooks/OnBackHook';

import './style.css';

const CrossSellPage = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { crossSellObject = {} } = useSelector((state) => state.enrollment);

    useOnBack('/enrollment/home');

    const elements = [{
        "type": "CrossSellProducts",
        "name": "CrossSellProducts",
        "containerClass": "test",
        "infoText": "Your employer offers other valuable benefits that help you save money. If you’d like to learn more, select the checkbox below and click ‘Continue’, otherwise click ‘Not Interested’."
    },
    {
        "type": "CrossSellContactPreference",
        "name": "CrossSellContactPreference",
        "contactMedias": [
            {
                "label": "Phone",
                "value": "phone"
            },
            {
                "label": "Email",
                "value": "email"
            },
            {
                "label": "Both",
                "value": "both"
            }
        ],
        "emailPreferences": [
            {
                "value": "workEmail",
                "text": "Primary Email"
            },
            {
                "value": "personalEmail",
                "text": "Alternate Email"
            }
        ],
        "phonePreferences": [
            {
                "value": "workPhone",
                "text": "Primary Phone"
            },
            {
                "value": "personalPhone",
                "text": "Alternate Phone"
            }
        ]
    }]

    const formik = useFormik({
        initialValues: { CrossSell: {} },
        validationSchema: Yup.object().shape({
            CrossSell: Yup.object().shape({
                skipSelection: Yup.boolean(),
                selectedProducts: Yup.array().when('skipSelection', (val, schema) => {
                    return !val
                        ? schema.min(1, 'Please choose atleast one product').required('Please choose atleast one product')
                        : Yup.array()
                }),
                contactPreferences: Yup.object().when('selectedProducts', (val, schema) => {
                    return val?.length > 0
                        ? schema.shape({
                            expirationDate: Yup.date().min(new Date('01/01/1900'), 'Invalid date').max(new Date('12/31/9999'), 'Invalid date').required('Please select date'),
                            contactMedia: Yup.string().required('Please select any one media to contact'),
                            acknowledgement: Yup.string().required('Please select'),
                            selectedPhone: Yup.string().when('contactMedia', (val, schema) => {
                                return val === 'both' || val === 'phone'
                                    ? schema.min(10, 'Invalid phone number').required('Please enter phone number')
                                    : Yup.string()
                            }),
                            selectedEmail: Yup.string().when('contactMedia', (val, schema) => {
                                return val === 'both' || val === 'email'
                                    ? schema.matches(/^(?=.{1,256})(?=.{1,64}@.{1,255}$)[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/,'Please enter valid email address').required('Please enter email address')
                                    : Yup.string()
                            })
                        })
                        : undefined
                }).nullable()
            }, ['skipSelection', 'selectedProducts']).required()
        }),
        onSubmit: (values) => {
            handleConfirmEnrollent(values)
        },
    });

    const handleConfirmEnrollent = async (values) => {
        const RequirementRequestReference = {
            "Data": {
                "className": "aSPCB_GenericRequirementRequestData",
                "requirementRequest": {
                    "className": "aSPCB_RequirementWithDynamicData",
                    "ActionStatus": "cRSCompleted",
                    "CompletionDate": "", //
                    "AdditionalDatas": [
                        {
                            "valueCode": "",//
                            "fieldValue": "",
                            "nameDescription": "",
                            "presentedName": "I acknowledge that clicking 'Yes' shall constitute my electronic signature.\n\nBy selecting 'No', I do not wish to provide such consent, but I want to continue enrollment.",
                            "fieldCode": "ACKNOWLEDGEELECTSIGN",
                            "fieldName": "ACKNOWLEDGEELECTSIGN",
                            "codeForPopulationClass": "",
                            "buid": "",
                            "className": "aSPCB_ClassificationValue"
                        },
                        {
                            "fieldValue": "",//
                            "presentedName": "What is the expiration date for your auto or home insurance",
                            "fieldCode": "EXPIRATIONDATEAUTOHO",
                            "fieldName": "EXPIRATIONDATEAUTOHOME",
                            "codeForPopulationClass": "",
                            "buid": "",
                            "className": "aSPCB_AdditionalDataValue_Date"
                        }
                    ]
                }
            },
            "Identifier": {
                "classname": "aSPCB_GenericRequirementRequestIdentifier",
                "requestId": ""//
            }
        }

        if (!values.CrossSell.skipSelection) {
            const { expirationDate, acknowledgement } = values.CrossSell.contactPreferences || {};
            RequirementRequestReference.Data.requirementRequest.AdditionalDatas[0].valueCode = acknowledgement
            RequirementRequestReference.Data.requirementRequest.AdditionalDatas[1].fieldValue = expirationDate
            RequirementRequestReference.Data.requirementRequest.ActionStatus = "cRSCompleted"
        } else {
            RequirementRequestReference.Data.requirementRequest.ActionStatus = "cRSDeleted"
        }
        RequirementRequestReference.Identifier.requestId = crossSellObject.requestID
        const today = moment().format('YYYY-MM-DD')
        RequirementRequestReference.Data.requirementRequest.CompletionDate = today
        const graphqlschema= "{bUID}"

        dispatch(SHOW_CONTENT_LOADER(true));
        return await http.post(appConfig.SSP_MID_URL + '/UpdateRequirementRequest', { RequirementRequestReference, graphqlschema })
            .then((resp) => {
                dispatch(SHOW_CONTENT_LOADER(false));
                if (resp.data && resp.status === 200) {
                    history.push("/confirmationScreen");
                } else {
                    toast.error('Cross sell save failed.');
                }
            }).catch(err => {
                toast.error('Cross sell save failed.');
            })
    }

    const { selectedProducts = [], skipSelection = false } = formik.values.CrossSell || {};

    return (
        <>
            <SectionHeader title="Additional Money Saving Benefits" rightLink="CC Home" breadcrumbsStepForm={[{ "to": "/enrollment/home", "text": "< Home" }]} />
            <form className="cross-sell-form" onSubmit={formik.handleSubmit} noValidate>
                <div>
                    <Toaster
                        toastOptions={{
                            className: 'toast-message',
                            duration: 3000,

                            style: {
                                border: '1px solid #E6A7A3',
                                padding: '16px',
                                color: '#AB2F26',
                                background: '#FFE8E6',
                            },
                        }}
                    />
                </div>
                <CrossSell formName="CrossSell" formik={formik} elements={elements} />
                <div className="footer-action-buttons">
                    <Button type="submit" disabled={selectedProducts.length === 0 && !skipSelection}>Continue</Button>
                </div>
            </form>
        </>
    )
}

export default CrossSellPage;