import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import './styles.css';
import { Styles } from './styles';
import Elementor from '../../Cells/Form/Elementor';
import CardSelector from '../../Cells/Card/CardSelector';

const CardElement = (props) => {
  const { cardData, result = {}, isMultiple = false, mulitpleIndex = false, element } = props;

  return (
    cardData.cardElements &&
    cardData.cardElements.length > 0 &&
    cardData.cardElements.map((data, resultIndex) => {
      const cardStyle =
        data && data.cardConfig && data.cardConfig.style ? data.cardConfig.style : {};
      return (
        // <div className={data?.cardConfig?.additionalClass ? 'col-md-6 col-lg-9' : ''}>
        <div key={`${`panel`}${element.id}${resultIndex}`} className={data.cardConfig.className}>
          <div
            className={`${data.cardConfig.alignmentClass} ${
              data.cardConfig.showBorder === false ? 'image-card-border' : 'contract-card-border'
            }`}
            style={cardStyle}
          >
            {data.cardConfig.showHeader && (
              <div className="row ">
                <div className="col-lg-12 mb-3">
                  <div
                    className="panel-header"
                    style={
                      (data.panelHeader &&
                        data.panelHeader.config &&
                        data.panelHeader.config.style) ||
                      {}
                    }
                  >
                    <div
                      className={`row ${
                        (data.panelHeader &&
                          data.panelHeader.config &&
                          data.panelHeader.config.className) ||
                        'pl-3 py-1'
                      }`}
                    >
                      {data.panelHeader &&
                        data.panelHeader.elements &&
                        data.panelHeader.elements.length > 0 &&
                        data.panelHeader.elements.map((currentelement, index) => {
                          return (
                            <div
                              key={`${currentelement.name} ${index} `}
                              className={currentelement.placement}
                            >
                              <Elementor
                                {...props}
                                key={currentelement.name}
                                element={currentelement}
                                constantsList={{}}
                                image={currentelement.image}
                                value={
                                  (!isMultiple && result[currentelement.name]) ||
                                  '' ||
                                  (result[element.name] &&
                                    result[element.name][mulitpleIndex] &&
                                    result[element.name][mulitpleIndex][currentelement.name]) ||
                                  ''
                                }
                                cardElements={currentelement.cardElements}
                                result={result}
                                resultIndex={resultIndex}
                                parentElementName={element.name}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={` ${
                (data.panelBody && data.panelBody.config && data.panelBody.config.className) || ''
              }`}
              style={
                (data.panelBody &&
                  data.panelBody.config &&
                  data.panelBody.config.style) ||
                {}
              }
            >
              {data.panelBody &&
                data.panelBody.elements &&
                data.panelBody.elements.length > 0 &&
                data.panelBody.elements.map((currentelement, index) => {
                  return (
                    <div
                      key={`${currentelement.name} ${index} `}
                      className={currentelement.placement}
                    >
                      <CardSelector
                        {...props}
                        key={currentelement.name}
                        element={currentelement}
                        constantsList={{}}
                        image={currentelement.image}
                        contentClassName=""
                        value={
                          (!isMultiple && (result[currentelement.name] || '')) ||
                          (result[element.name] &&
                            result[element.name][mulitpleIndex] &&
                            result[element.name][mulitpleIndex][currentelement.name]) ||
                          ''
                        }
                        cardElements={currentelement.cardElements}
                        result={result}
                        resultIndex={resultIndex}
                        parentElementName={element.name}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {data.cardConfig.showFooter && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-header">
                    <div className="row p-3">
                      {data.panelBody &&
                        data.panelFooter.elements &&
                        data.panelFooter.elements.length > 0 &&
                        data.panelFooter.elements.map((currentelement) => {
                          return (
                            <div className={currentelement.placement}>
                              <Elementor
                                {...props}
                                key={currentelement.name}
                                element={currentelement}
                                constantsList={{}}
                                image={currentelement.image}
                                value={
                                  (result[element.name] &&
                                    result[element.name][currentelement.name]) ||
                                  result[element.name] ||
                                  ''
                                }
                                cardElements={element.cardElements}
                                result={result}
                                resultIndex={resultIndex}
                                parentElementName={element.name}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}{' '}
          </div>
      );
    })
  );
};

const PanelCard = (props) => {
  let {
    cardMembers = [],
    element,
    showContent = [],
  } = props;
  cardMembers =
    (element.cardMembers && element.cardMembers.length > 0 && element.cardMembers) || [];
  let [result, setResult] = useState(props.result);
  useEffect(() => {
    setResult(props.result);
  }, [props.result]);
  return (
    <>
      <Styles />
      <>
        <div className="row mb-4">
          {cardMembers &&
            cardMembers.length > 0 &&
            cardMembers.map((cardData, resultIndex) => {
              const showCard = showContent.includes(cardData.id) || showContent.length === 0;
              const hasContainer = (cardData && cardData.hasContainer) || false;
              const containerClassName = (cardData && cardData.containerClassName) || '';
              return showCard ? (
                hasContainer ? (
                  <div className={containerClassName}>
                    <CardElement 
                      result={result}
                      cardData={cardData}
                      resultIndex={resultIndex}
                      isMultiple={props.isMultiple}
                      mulitpleIndex={props.mulitpleIndex}
                      element={element}
                    />
                  </div>
                ) : (
                  <CardElement
                    result={result}
                    cardData={cardData}
                    resultIndex={resultIndex}
                    isMultiple={props.isMultiple}
                    mulitpleIndex={props.mulitpleIndex}
                    element={element}
                  />
                )) : null
            })}
        </div>
      </>
    </>
  );
};
PanelCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};
PanelCard.defaultProps = {
  className: '',
};

export default PanelCard;
