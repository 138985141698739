import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import constants from '../../Content/Constants.json';
import formFields from '../../Content/DependentFormFields.json';
import './dependent-styles.css';
import getInsuredAdditionalDataConfiguration from './Actions/getInsuredAdditionalDataConfiguration';
import { GET_INSURED_ADDITIONAL_DATA_ISDISABLED } from '../../../../../Store/Slices/EmployeeProfile';

const DependentModel = ({

  onSubmit,
  onCancel,
  initialValues,
  alloweddependentKind,
  currentStep = "",
  isEditModal,
  modalAction,
  dependentsTable = [],
}) => {
  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
  const maxDateOfBirth = new Date().toISOString().split("T")[0];
  const [formError, setFormError] = useState('');
  const [showMaskedInput, setShowMaskedInput] = useState(false);
  let disabledField = isEditModal && modalAction ? true : false
  const getInitialShowAddressFields = (initialValues) => ({
    mailingAddress: initialValues && initialValues.mailingAddressOptions === 'yes',
    liveInSameAddress: initialValues?.liveInSameAddress === true,
  });

  const [dependentAge, setDependentAge] = useState(0);
  const [dependentAgeforDisplay, setDependentAgeforDisplay] = useState('');
  //Configuration for dependent
  const configDataRedux = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.dependent?._Result?.data);
  const dependentPersonalInfoConfig = configDataRedux?.dependentPersonalInfo?.[0]
  const dependentAddressInfoConfig = configDataRedux?.dependentAddressInfo?.[0]
  const [showAddressFields, setShowAddressFields] = useState(getInitialShowAddressFields(initialValues));

  const employeeProfile = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile);
  const relationshipBasedQuestion = useSelector((state) => state.entities?.response?.onLoadResponseList?.dependentInformation?.relationshipBasedQuestion);

  const process = useSelector((state) => state?.process);
  const processBUID = process?.enrollmentProcess[0]?.[0]?._Result.processEntity.buid;

  const dispatch = useDispatch();

  const resetAddressFields = () => {
    formik.setFieldValue(formFields.dependentAddressLine1.name, '');
    formik.setFieldValue(formFields.dependentAddressLine2.name, '');
    formik.setFieldValue(formFields.dependentCity.name, '');
    formik.setFieldValue(formFields.dependentState.name, '');
    formik.setFieldValue(formFields.dependentZipCode.name, '');
    formik.setFieldValue(formFields.dependentCountry.name, '');
  };


  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const m = currentDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };



  const handleSSNChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    const formattedValue = value
      .slice(0, 9)
      .replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
        return [p1, p2, p3].filter((part) => part.length > 0).join('-');
      });
    formik.setFieldValue('SSN', formattedValue);
  };

  const handleSSNBlur = (e) => {
    setShowMaskedInput(!formik.errors.SSN);
    formik.handleBlur(e);
  };

  const handleLiveInSameAddressChange = (e) => {
    const checked = e.target.checked;
    formik.setFieldValue(formFields.liveInSameAddress.name, checked);
    setTimeout(() => formik.setFieldTouched(formFields.liveInSameAddress.name, checked))
    setShowAddressFields((prev) => ({ ...prev, liveInSameAddress: checked }));
    if (checked) {
      handleAddressFields(employeeProfile)
    } else {
      resetAddressFields();
    }
  };

  const handleAddressFields = (profile) => {
    formik.setValues(prevValues => ({
      ...prevValues,
      // we use the name to tell Formik which key of `values` to update
      [formFields.dependentAddressLine1.name]: profile?.line1,
      [formFields.dependentAddressLine2.name]: profile?.line2,
      [formFields.dependentCity.name]: profile?.city,
      [formFields.dependentState.name]: profile?.state,
      [formFields.dependentZipCode.name]: profile?.zipCode,
      [formFields.dependentCountry.name]: profile?.country,
      [formFields.liveInSameAddress.name]: true
    })
    )
  };


  const calculateDependentAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age < 1 ? "< 1" : age.toString();
  };

  const handleCountryChange = (e) => {
    formik.handleChange(e);
    const country = e.target.value;

    if (country === 'FC') {
      formik.setValues(prevValues => ({
        ...prevValues,
        // we use the name to tell Formik which key of `values` to update
        [e.target.name]: e.target.value,
        [formFields.dependentZipCode.name]: '99999',
        [formFields.dependentState.name]: '',
      }))
    } else {
      formik.setValues(prevValues => ({
        ...prevValues,
        // we use the name to tell Formik which key of `values` to update
        [e.target.name]: e.target.value,
        [formFields.dependentZipCode.name]: '',
        [formFields.dependentState.name]: '',
      }))
    }
  }
  const validateDependentBirthDate = (birthdate, relationship, disabledCheck) => {
    if (relationship === 'CH') {
      const age = calculateAge(birthdate);
      if (age > 26 && !disabledCheck) {
        return false;
      }
    }
    return true;
  };

  const validationSchema = Yup.object({
    dependentFirstName: Yup.string()
      .matches(/^[a-zA-ZÀ-ž' -]+$/, 'No special characters allowed')
      .required('Please enter your First Name'),

    dependentMiddleName: Yup.string()
      .matches(/^[a-zA-ZÀ-ž' -]+$/, 'No special characters allowed').when([], {
        is: () => dependentPersonalInfoConfig?.dependentMiddleName?.requiredField,
        then: Yup.string()
          .required('Middle Name is required')
      }),

    dependentLastName: Yup.string()
      .matches(/^[a-zA-ZÀ-ž' -]+$/, 'No special characters allowed')
      .required('Please enter your Last Name'),





    SSN: Yup.string()
      .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must be in the format XXX-XX-XXXX')
      .when('dependentBirthDate', (dependentBirthDate, schema) => {
        const age = calculateAge(dependentBirthDate);
        return age < 1 ? schema.notRequired() : schema.required('Please enter your SSN');
      }),





    dependentRelationship: Yup.string().required("Relationship is required").test('is-unique-sp-dp-cup', "Duplicate spouse/partner not allowed.", function (value) {
      if (value === 'SP' || value === 'DP' || value === 'CUP') {
        let spouseOrDomesticPartner = dependentsTable.filter(dependent => dependent.dependentRelationship === 'SP' || dependent.dependentRelationship === 'DP' || dependent.dependentRelationship === 'CUP');
        if (spouseOrDomesticPartner.length > 0) {
          // Check if it's an edit scenario and if the edited dependent is the same as the existing one
          if (modalAction && spouseOrDomesticPartner[0].mainBuid === initialValues.mainBuid) {
            return true; // Allow edit if the dependent being edited is the same as the existing one     
          }
          return false;
        }
      }
      return true;
      // No restrictions for other relationship types 
    }
    ),



    dependentGender: Yup.string()
      .required("Gender is required"),

      dependentBirthDate: Yup.date()
      .required("Please enter your DOB")
      .min(new Date('01/01/1900').toISOString(), 'Invalid DOB')
      .max(new Date().toISOString(), 'Invalid DOB')
      .test('is-child-age-valid', 'Dependent children cannot be older than 26 years', function(value) {
        const  dependentRelationship  = formik?.values?.dependentRelationship
        const dependentDisabled = formik?.values?.dependentDisabled
        return validateDependentBirthDate(value, dependentRelationship, dependentDisabled);
      }),

    dependentAddressLine1: Yup.string().when('liveInSameAddress', (liveInSameAddress, schema) => {
      return !liveInSameAddress
        ? schema.required('Address Line 1 is required').matches(/^[a-zA-Z0-9 ,#/.\-]*$/, 'Address is invalid')
        : Yup.string()
    }),
    dependentAddressLine2: Yup.string().matches(/^[a-zA-Z0-9 ,#/.\-]*$/, 'Address is invalid'),

    dependentCity: Yup.string().when('liveInSameAddress', (liveInSameAddress, schema) => {
      return !liveInSameAddress
        ? schema.required('City is required').matches(/^(?!.*[ ][ ])^[a-zA-Z0-9 -]+$/, "City is Invalid")
        : Yup.string()
    }),
    dependentState: Yup.string().when(['liveInSameAddress', 'dependentCountry'], (liveInSameAddress, dependentCountry, schema) => {
      return !liveInSameAddress && dependentCountry !== 'FC'
        ? schema.required('State is required').matches(/^[a-zA-Z ]+$/, "State is Invalid")
        : Yup.string()
    }),

    dependentCountry: Yup.string().when(['liveInSameAddress', 'dependentAddressInfoConfig.dependentCountry.requiredField'], (liveInSameAddress, requiredField, schema) => {
      return !liveInSameAddress && !requiredField
        ? schema.required('Country is required').matches(/^[a-zA-Z]+$/, "Country is Invalid")
        : Yup.string();
    }),

    dependentZipCode: Yup.string().when('liveInSameAddress', (liveInSameAddress, schema) => {
      return !liveInSameAddress
        ? schema.required('Zip Code is required').matches(/^[0-9]*$/, "Zip Code must all be digits")
        : Yup.string()
    })
  });

  const formik = useFormik({
    initialValues: initialValues || {
      legalName: '',
      dependentIrrevocable: '',
      dependentCollateral: '',
      dependentFirstName: '',
      dependentMiddleName: '',
      dependentLastName: '',
      SSN: '',
      dependentRelationship: '',
      dependentOptions: 'individual',
      dependentPercentage: 0,
      dependentType: 'Primary',
      dependentGender: '',
      dependentBirthDate: '',
      mailingAddressOptions: 'no',
      liveInSameAddress: '',
      dependentAddressLine1: '',
      dependentAddressLine2: '',
      dependentCity: '',
      dependentState: '',
      dependentZipCode: '',
      dependentCountry: '',
      dependentDisabled: '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // 
      onSubmit(values);
      formik.resetForm();
      setSubmitting(false);
    },

    enableReinitialize: true,
  });

 
  useEffect(() => {
    if (formik.values.dependentBirthDate) {
      const age = calculateAge(formik.values.dependentBirthDate);
      const dependentAge = calculateDependentAge(formik.values.dependentBirthDate);
      setDependentAge(age);
      setDependentAgeforDisplay(dependentAge);
    }

    formik.validateField('dependentBirthDate');
    formik.validateForm();
  }, [formik.values.dependentBirthDate]);
  const maskedSSN = showMaskedInput ? `XXX-XX-${formik?.values?.SSN?.slice(-4)}` : '';
  const relationship = formik?.values?.dependentRelationship;
  const isDependentDisabledQuestion = relationshipBasedQuestion?.[relationship] || {};


  const handleFormSubmit = async (e) => {
    await formik.validateForm();
    const hasErrors = Object.keys(formik.errors).length > 0;

    if (!hasErrors) {
      onSubmit(formik.values);
      formik.resetForm();
      setFormError('');
    } else {
      setFormError('Please check form fields');
    }
  };

  const filteredRelationshipOptions = constants?.dependentRelationship.filter((option) =>
    Array.isArray(alloweddependentKind) ?
      alloweddependentKind?.includes(option.value) : []
  );

  useEffect(() => {
    if (initialValues?.SSN)
      setShowMaskedInput(true);
  }, [initialValues?.SSN, setShowMaskedInput]);

  useEffect(() => {
    if (relationship && !isDependentDisabledQuestion.presentedName)
      dispatch(getInsuredAdditionalDataConfiguration(processBUID, relationship, currentStep));
  }, [formik?.values?.dependentRelationship]);

  useEffect(() => {
    const possibleValues = isDependentDisabledQuestion.possibleValues || [];
    dispatch(GET_INSURED_ADDITIONAL_DATA_ISDISABLED(possibleValues));

    if (isDependentDisabledQuestion.presentedName || !formik.values.dependentDisabled) return;

    formik.setFieldValue(formFields.dependentDisabled.name, '');
  }, [isDependentDisabledQuestion.presentedName]);






  const handleRelationshipChange =  (e) => {
    const newValue = e.target.value;
    // Set the value
    formik.setFieldValue(formFields.dependentRelationship.name, newValue);
    // Mark as touched
    formik.setFieldTouched(formFields.dependentRelationship.name, true, false);
    // Validate the field
    formik.validateField(formFields.dependentRelationship.name);
  
};
 



  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div class="row">
        <div class="col-12 sdf">
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-mandatory dependent d-inline-block mb-2">Fields marked with * are Mandatory</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <div class="row">
          <div class="col-12  col-md-6">
            <div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-side-big-header d-inline-block mb-2">Personal Information </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          {/* First Name */}
          <div className="col-12 col-md-4 py-2">
            <div className="floatingLabel">
              {formFields.dependentFirstName.label}
              {formFields.dependentFirstName.isRequired && <span> *</span>}
              <div>
                <label for={formFields.dependentFirstName.id} class="lable-sr-only" aria-label={formFields.dependentFirstName.label}></label>
                <input
                  id={formFields.dependentFirstName.id}
                  name={formFields.dependentFirstName.name}
                  type={formFields.dependentFirstName.type}
                  placeholder={formFields.dependentFirstName.placeholder}
                  disabled={disabledField}
                  className="w-100"
                  {...formik.getFieldProps(formFields.dependentFirstName.name)}
                />
                {formik.touched.dependentFirstName && formik.errors.dependentFirstName ? (
                  <div class="form-mandatory">{formik.errors.dependentFirstName}</div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Middle Name */}
          {!(dependentPersonalInfoConfig?.dependentMiddleName?.hideableField) && <div className="col-12 col-md-4 py-2">
            <div className="floatingLabel">
              {formFields.dependentMiddleName.label}{dependentPersonalInfoConfig?.dependentMiddleName?.requiredField && <span> *</span>}

              <div>
                <label for={formFields.dependentMiddleName.id} class="lable-sr-only" aria-label={formFields.dependentMiddleName.label}></label>
                <input
                  id={formFields.dependentMiddleName.id}
                  name={formFields.dependentMiddleName.name}
                  type={formFields.dependentMiddleName.type}
                  placeholder={formFields.dependentMiddleName.placeholder}
                  disabled={disabledField}
                  className="w-100"
                  {...formik.getFieldProps(formFields.dependentMiddleName.name)}
                />
                {formik.touched.dependentMiddleName && formik.errors.dependentMiddleName ? (
                  <div class="form-mandatory">{formik.errors.dependentMiddleName}</div>
                ) : null}
              </div>
            </div>
          </div>}

          {/* Last Name */}
          <div className="col-12 col-md-4 py-2">
            <div className="floatingLabel">
              {formFields.dependentLastName.label}
              {formFields.dependentLastName.isRequired && <span> *</span>}
              <div>
                <label for={formFields.dependentLastName.id} class="lable-sr-only" aria-label={formFields.dependentLastName.label}></label>
                <input
                  id={formFields.dependentLastName.id}
                  name={formFields.dependentLastName.name}
                  type={formFields.dependentLastName.type}
                  placeholder={formFields.dependentLastName.placeholder}
                  disabled={disabledField}
                  className="w-100"
                  {...formik.getFieldProps(formFields.dependentLastName.name)}
                />
                {formik.touched.dependentLastName && formik.errors.dependentLastName ? (
                  <div class="form-mandatory">{formik.errors.dependentLastName}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {/* SSN */}
          <div className="col-12 col-lg-4 col-md-4 col-sm-6 py-2">



            <div className="floatingLabel">
              {formFields.SSN.label}
              {(dependentAge >= 1 || formik.values.dependentBirthDate=="") && <span> *</span>}

              {/* SSN Input */}
              <div>
                {showMaskedInput ? (
                  <>
                    <label for="maskedSSN" class="lable-sr-only" aria-label={formFields.SSN.label}></label>
                    <input
                      id="maskedSSN"
                      name="maskedSSN"
                      type={formFields.SSN.type}
                      className="w-100 maskedSSN"
                      value={maskedSSN}
                      disabled={disabledField}
                      onFocus={() => setShowMaskedInput(false)}
                    />
                  </>
                ) : (
                  <>
                    <label for={formFields.SSN.id} class="lable-sr-only" aria-label={formFields.SSN.label}></label>
                    <input
                      id={formFields.SSN.id}
                      name={formFields.SSN.name}
                      type={formFields.SSN.type}
                      placeholder={formFields.SSN.placeholder}
                      className="w-100"
                      maxLength={11}
                      value={formik.values.SSN}
                      onChange={handleSSNChange}
                      onBlur={handleSSNBlur}
                    />
                  </>
                )}
                {formik.touched.SSN && formik.errors.SSN ? (
                  <div class="form-mandatory">{formik.errors.SSN}</div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Relationship */}
          <div className="col-12 col-lg-4 col-md-4 col-sm-6 py-2">
            <div className="selectContainer">
            <label for={formFields.dependentRelationship.id}>
                {formFields.dependentRelationship.label}
                {formFields.dependentRelationship.isRequired && <span> *</span>}
            </label>
              {filteredRelationshipOptions.length > 0 && (
            <select
                  id={formFields.dependentRelationship.id}
                  name={formFields.dependentRelationship.name}
                  className="w-100 selectText"
                  value={formik.values.dependentRelationship}
                  {...formik.getFieldProps(formFields.dependentRelationship.name)}
                  onChange={handleRelationshipChange}
                  disabled={disabledField}
            >
            <option value="" hidden disabled></option>
                  {filteredRelationshipOptions.map((option) => (
            <option key={option.value} value={option.value}>
                      {option.label}
            </option>
                  ))}
            </select>
              )}
              {formik.touched.dependentRelationship && formik.errors.dependentRelationship && (
            <div className="form-mandatory">{formik.errors.dependentRelationship}</div>
              )}
             
            </div>
          </div>
          {/* Gender */}

          

          <div className="col-12 col-lg-4 col-md-4 col-sm-6 py-2">
            <div>
              <div className="radioGroup w-100">
                <div className="row">
                  <div className="col-12 py-1 mb-2 pb-2">
                    {formFields.dependentGender.label}
                    {formFields.dependentGender.isRequired && (
                      <span className="form-mandatory"> *</span>
                    )}
                  </div>
                </div>
                <fieldset>
                  <legend class="lable-sr-only" area-hidden='true'></legend>
                  {formFields.dependentGender.options.map((option) => (
                    <div
                      key={option.id}
                      className="custom-control-inline"
                      style={{ marginRight: '0.5rem' }}
                    >
                      <label for={option.id}>
                        <input
                          type={formFields.dependentGender.type}
                          id={option.id}
                          name={formFields.dependentGender.name}
                          value={option.value}
                          checked={formik.values.dependentGender === option.value}
                          disabled={disabledField}
                          onChange={(e) =>
                            formik.setFieldValue(formFields.dependentGender.name, e.target.value)
                          }
                        />
                        <span>{option.label}</span>
                      </label>
                    </div>
                  ))}
                </fieldset>
                {formik.touched.dependentGender && formik.errors.dependentGender ? (
                  <div class="form-mandatory">{formik.errors.dependentGender}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-4 col-md-4 col-sm-3 py-2">
            <div className="floatingLabel">
              {formFields.dependentBirthDate.label}
              {formFields.dependentBirthDate.isRequired && <span> *</span>}
              <div className="DOB">
                <label for={formFields.dependentBirthDate.id} class="lable-sr-only" aria-label={formFields.dependentBirthDate.label}></label>
                <input
                  id={formFields.dependentBirthDate.id}
                  name={formFields.dependentBirthDate.name}
                  type={formFields.dependentBirthDate.type}
                  placeholder={formFields.dependentBirthDate.placeholder}
                  disabled={disabledField || (!formik.values.dependentRelationship )}
                  className="w-100"
                  
                  {...formik.getFieldProps(formFields.dependentBirthDate.name)}
                  onChange={(e) => {
                    // Set the new value
                    formik.setFieldValue(formFields.dependentBirthDate.name, e.target.value);
                    // Mark as touched
                    formik.setFieldTouched(formFields.dependentBirthDate.name, true, false);
                    // Validate the field
                    formik.validateField(formFields.dependentBirthDate.name);
                  }}
                  max={maxDateOfBirth}
                />

                {formik.touched.dependentBirthDate && formik.errors.dependentBirthDate ? (
                  <div class="form-mandatory">{formik.errors.dependentBirthDate}</div>
                ) : null}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="calendar-alt"
                  className="svg-inline--fa fa-calendar-alt fa-w-14 calendarIcon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 py-2">
            <div className="floatingLabel">
              {formFields.dependentAge.label}
              
              <div>
                <label for={formFields.dependentAge.id} class="lable-sr-only" aria-label={formFields.dependentAge.label}></label>
                <input
                  value = {dependentAgeforDisplay}
                  id={formFields.dependentAge.id}
                  name={formFields.dependentAge.name}
                  type={formFields.dependentAge.type}
           
                  disabled={true}
                  className="w-25"
                 
                />
              
              </div>
            </div>
          </div>      


    

          <div className="col-12 col-lg-4 col-md-6 col-sm-12 py-2">
            {!!isDependentDisabledQuestion.presentedName && (
              <div>
                <div className="radioGroup w-100">
                  <div className="row">
                    <div className="col-12 py-3">{isDependentDisabledQuestion.presentedName}</div>
                  </div>
                  {isDependentDisabledQuestion.possibleValues ? (
                    <label for={isDependentDisabledQuestion?.possibleValues?.[0]?.fieldName} class="lable-sr-only" aria-label={isDependentDisabledQuestion.presentedName}></label>
                  ) : ''}
                  {isDependentDisabledQuestion.possibleValues?.map((option) => (
                    <div key={option.fieldValue} className="pb-0 custom-control-inline">
                      <label className={option.labelClassName}>
                        <input
                          type="radio"
                          id={option.fieldName}
                          name={formFields.dependentDisabled.name}
                          value={option.fieldValue}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.dependentDisabled === option.fieldValue}
                          disabled={disabledField}
                        />
                        <span>{option.nameDescription}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </>


      <div class="row address">
        <div class="col-12  col-md-6 address-header">
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-side-big-header d-inline-block mb-2">Dependent's Address </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      {employeeProfile?.primaryAddress && !(dependentAddressInfoConfig?.liveInSameAddress?.hideableField) && (
        <>
          <div className="row mb-3">
            <div className="col-12 sdf">
              <div className="checkboxContainer">
                <input
                  checked={showAddressFields.liveInSameAddress}
                  name={formFields.liveInSameAddress.name}
                  id={formFields.liveInSameAddress.id}
                  type={formFields.liveInSameAddress.type}
                  className="regularCheckbox"
                  style={{
                    width: '1.25rem',
                    height: '1.25rem',
                    borderRadius: '0.25rem',
                    border: '0.0625rem solid rgb(204, 204, 204)',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                  {...formik.getFieldProps(formFields.liveInSameAddress.name)}
                  onChange={handleLiveInSameAddressChange}
                />
                <label
                  className="form-check-label checkboxLabel text-wrap"
                  htmlFor={formFields.liveInSameAddress.id}
                >
                  {formFields.liveInSameAddress.label}
                </label>
              </div>
            </div>
          </div>
        </>
      )}


      {!showAddressFields.liveInSameAddress && (
        <>
          {/* Render address fields here */}

          <div className="row">
            <div className="col-12 col-md-6 py-2">
              <div className="floatingLabel">
                {formFields.dependentAddressLine1.label}
                {formFields.dependentAddressLine1.isRequired && <span> *</span>}
                <div>
                  <label for={formFields.dependentAddressLine1.id} class="lable-sr-only" aria-label={formFields.dependentAddressLine1.label}></label>
                  <input
                    id={formFields.dependentAddressLine1.id}
                    name={formFields.dependentAddressLine1.name}
                    type={formFields.dependentAddressLine1.type}
                    placeholder={formFields.dependentAddressLine1.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.dependentAddressLine1.name)}
                  />
                  {formik.touched.dependentAddressLine1 &&
                    formik.errors.dependentAddressLine1 ? (
                    <div class="form-mandatory">{formik.errors.dependentAddressLine1}</div>
                  ) : null}
                </div>
              </div>
            </div>
            {!(dependentAddressInfoConfig?.dependentAddressLine2?.hideableField) && <div className="col-12 col-md-6 py-2">
              <div className="floatingLabel">
                {formFields.dependentAddressLine2.label}
                <div>
                  <label for={formFields.dependentAddressLine2.id} class="lable-sr-only" aria-label={formFields.dependentAddressLine2.label}></label>
                  <input
                    id={formFields.dependentAddressLine2.id}
                    name={formFields.dependentAddressLine2.name}
                    type={formFields.dependentAddressLine2.type}
                    placeholder={formFields.dependentAddressLine2.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.dependentAddressLine2.name)}
                  />
                  {formik.touched.dependentAddressLine2 &&
                    formik.errors.dependentAddressLine2 ? (
                    <div class="form-mandatory">{formik.errors.dependentAddressLine2}</div>
                  ) : null}
                </div>
              </div>
            </div>}
          </div>

          <div className="row address-last-row">
            {/* City */}
            <div className="col-12 col-lg-3 col-md-4 col-sm-4 py-2">
              <div className="floatingLabel">
                {formFields.dependentCity.label}
                {formFields.dependentCity.isRequired && <span> *</span>}
                <div>
                  <label for={formFields.dependentCity.id} class="lable-sr-only" aria-label={formFields.dependentCity.label}></label>
                  <input
                    id={formFields.dependentCity.id}
                    name={formFields.dependentCity.name}
                    type={formFields.dependentCity.type}
                    placeholder={formFields.dependentCity.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.dependentCity.name)}
                  />
                  {formik.touched.dependentCity && formik.errors.dependentCity ? (
                    <div class="form-mandatory">{formik.errors.dependentCity}</div>
                  ) : null}
                </div>
              </div>
            </div>

            {/* State */}
            <div className="col-12 col-lg-3 col-md-4 col-sm-4 py-2">
              {formik.values.dependentCountry !== 'FC' && (
                <div className="selectContainer">
                  <label for={formFields.dependentState.id}>
                    {formFields.dependentState.label}
                    {formFields.dependentState.isRequired && <span> *</span>}
                  </label>
                  <select
                    id={formFields.dependentState.id}
                    name={formFields.dependentState.name}
                    className="w-100 selectText"
                    {...formik.getFieldProps(formFields.dependentState.name)}
                  >
                    <option value="" hidden disabled />
                    {constantsList['StateConstants']?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                  {formik.touched.dependentState && formik.errors.dependentState ? (
                    <div class="form-mandatory">{formik.errors.dependentState}</div>
                  ) : null}
                </div>
              )}
            </div>

            {/* Zip Code */}
            <div className="col-12 col-lg-3 col-md-4 col-sm-4 py-2">
              <div className="floatingLabel">
                {formFields.dependentZipCode.label}
                {formFields.dependentZipCode.isRequired && <span> *</span>}
                <div>
                  <label for={formFields.dependentZipCode.id} class="lable-sr-only" aria-label={formFields.dependentZipCode.label}></label>
                  <input
                    id={formFields.dependentZipCode.id}
                    name={formFields.dependentZipCode.name}
                    type={formFields.dependentZipCode.type}
                    placeholder={formFields.dependentZipCode.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.dependentZipCode.name)}
                    disabled={formik.values.dependentCountry === 'FC'}
                  />
                  {formik.touched.dependentZipCode && formik.errors.dependentZipCode ? (
                    <div class="form-mandatory">{formik.errors.dependentZipCode}</div>
                  ) : null}
                </div>
              </div>
            </div>

            {/* Country */}
            {!(dependentAddressInfoConfig?.dependentCountry?.hideableField) && <div className="col-12 col-lg-3 col-md-4 col-sm-4 py-2">
              <div className="selectContainer">
                <label for={formFields.dependentCountry.id}>
                  {formFields.dependentCountry.label}
                  {(dependentAddressInfoConfig?.dependentCountry?.requiredField ?? true) && <span> *</span>}
                </label>
                <select
                  id={formFields.dependentCountry.id}
                  name={formFields.dependentCountry.name}
                  className="w-100 selectText"
                  {...formik.getFieldProps(formFields.dependentCountry.name)}
                  onChange={handleCountryChange}
                >
                  <option value="" hidden disabled />
                  <option value="USA">UNITED STATES</option>
                  <option value="FC">FOREIGN COUNTRY</option>
                </select>
                {formik.touched.dependentCountry && formik.errors.dependentCountry ? (
                  <div class="form-mandatory">{formik.errors.dependentCountry}</div>
                ) : null}
              </div>
            </div>}
          </div>
        </>
      )}

      {showAddressFields.liveInSameAddress && (
        <div className="address-body">
          <div>{employeeProfile?.primaryAddressLine1Display}</div>
          <div>{employeeProfile?.primaryAddressLine2Display}</div>
          <div>{employeeProfile?.primaryAddressLine3Display}</div>
        </div>
      )}

      {formError && (
        <div className="error-message">
          <p>{formError}</p>
        </div>
      )}
      <div className="modal-footer-button">
        <button role="button" className="btn btn-primary" type="button" onClick={onCancel}>
          Cancel
        </button>
        <button
          role="button"
          className="btn btn-primary"
          disabled={!formik.isValid || !formik.dirty}
          type="button"
          onClick={formik.submitForm}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default DependentModel;