import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, groupBy } from 'lodash';
const initialState = {
  alertMessages: [],
  isLoading: false,
  loaderCount: 0,
  DependentInformation: [],
  selectedAnswer: '',
  selectedQuestionId: '',
  selectedIndex: '',
  inputId: '',
  selectedoptionId: [],
  selectedoptionIdCriticalIllness: [],
  savedAnswer: [],
  selectedName: '',
  active: [true, false, false],
  waiveInsureCoverage: false,
  waiveInsureCoverageCriticalIllness: false,
  questionsAnsweredForAll: false,
  dependentAnswers: [null],
  employeeEligiblity: {},
  disableAnswersRadioStatus: null,
  count: false,
  eligibilityMessages: [],
  editAnswers: null,
  disableAll: null,
  skipProduct: null,
  notEligibleDependent: null,
  showedError: null,
  setWaiveCoverageNation: "",
  setWaiveCoverageVoya: "",
  nonSharedAdditionalDatas: [],
  continueButtonEnable: false,
  displayPolicyQuestions: [],
  productsToSave: [],
  manageBeneficiarySubscribeElment: [],
  saveBeneficiaries: false,
  DependentInformation: {

    errorMessage: null,
    successMessage: null,
  },
  apiErrorHandlingList: {
    welcomeScreen: {
      mandatoryApis: [
        "gbpcontractsLinearOrNonLinear",
        "getEmploymentInformation",
        "getEmployments",
        "getEnrollments",
        "getGBPResourcesForMember",
        "getLastContractChange",
        "getListOfEligibleProductsForMember",
        "getLoginUser",
        "getMemberActiveContracts",
        "getPersonalInformation",
        "subscribedcontracts",
        "getPendingProcess",
        "welcomeScreen"
      ],
    },
    EmployeeProfile: {
      mandatoryApis: [
        "getLinearOrNonLinear",
        "validateContract",
        // "updateProcess",
        "EmployeeProfile",
        "postUpdatedContactToSalesForce",
        "employeeProfileSubmit",
        "trigger-resume"  
      ]
    },
    CoverageSelection: {
      mandatoryApis: [
        "insureLoad",
        "getAllPossibleChoicesConfigurationForProduct",
        "searchPolicyProcessResources",
        "checkCoveredPersonsEligibilityForCoverage",
        "reworkProductEnrollment",
        "coverageLoad",
        "getListOfCoveragePlanDesignConfiguration",
        "getSubscribedCoverageConfiguration",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "prepareSubscriptionForChoice",
        "validateSubscribedProductData",
        "updateSubscribedCoverageInformationApi",
        "moveNext",
        "updateSubscribedCoverageInformation",
        "validateSubscribedCoverageData",
        "unsubscribeCoverage",
        "unsubscribeCoverageApi",
        "reworkProductEnrollment",
        "reworkLoad",
        "declineProduct",
        "declineProductApi"
      ]
    },
    SupplementalHealthInsurance: {
      mandatoryApis: [
        "insureLoad",
        "getAllPossibleChoicesConfigurationForProduct",
        "searchPolicyProcessResources",
        "checkCoveredPersonsEligibilityForCoverage",
        "reworkProductEnrollment",
        "coverageLoad",
        "getListOfCoveragePlanDesignConfiguration",
        "getSubscribedCoverageConfiguration",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "prepareSubscriptionForChoice",
        "validateSubscribedProductData",
        "updateSubscribedCoverageInformationApi",
        "moveNext",
        "updateSubscribedCoverageInformation",
        "validateSubscribedCoverageData",
        "unsubscribeCoverage",
        "unsubscribeCoverageApi",
        "reworkProductEnrollment",
        "reworkLoad",
        "declineProduct",
        "declineProductApi"
      ]
    },
    CriticalIllness: {
      mandatoryApis: [
        "insureLoad",
        "getAllPossibleChoicesConfigurationForProduct",
        "searchPolicyProcessResources",
        "checkCoveredPersonsEligibilityForCoverage",
        "reworkProductEnrollment",
        "coverageLoad",
        "getListOfCoveragePlanDesignConfiguration",
        "getSubscribedCoverageConfiguration",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "prepareSubscriptionForChoice",
        "validateSubscribedProductData",
        "updateSubscribedCoverageInformationApi",
        "moveNext",
        "updateSubscribedCoverageInformation",
        "validateSubscribedCoverageData",
        "unsubscribeCoverage",
        "unsubscribeCoverageApi",
        "reworkProductEnrollment",
        "reworkLoad",
        "declineProduct",
        "declineProductApi"
      ]
    },
    HospitalIndemnit: {
      mandatoryApis: [
        "insureLoad",
        "getAllPossibleChoicesConfigurationForProduct",
        "searchPolicyProcessResources",
        "checkCoveredPersonsEligibilityForCoverage",
        "reworkProductEnrollment",
        "coverageLoad",
        "getListOfCoveragePlanDesignConfiguration",
        "getSubscribedCoverageConfiguration",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "prepareSubscriptionForChoice",
        "validateSubscribedProductData",
        "updateSubscribedCoverageInformationApi",
        "moveNext",
        "updateSubscribedCoverageInformation",
        "validateSubscribedCoverageData",
        "unsubscribeCoverage",
        "unsubscribeCoverageApi",
        "reworkProductEnrollment",
        "reworkLoad",
        "declineProduct",
        "declineProductApi"
      ]
    },
    IdTheft: {
      mandatoryApis: [
        "insureLoad",
        "getAllPossibleChoicesConfigurationForProduct",
        "searchPolicyProcessResources",
        "checkCoveredPersonsEligibilityForCoverage",
        "reworkProductEnrollment",
        "coverageLoad",
        "getListOfCoveragePlanDesignConfiguration",
        "getSubscribedCoverageConfiguration",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "prepareSubscriptionForChoice",
        "validateSubscribedProductData",
        "updateSubscribedCoverageInformationApi",
        "moveNext",
        "updateSubscribedCoverageInformation",
        "validateSubscribedCoverageData",
        "unsubscribeCoverage",
        "unsubscribeCoverageApi",
        "reworkProductEnrollment",
        "reworkLoad",
        "declineProduct",
        "declineProductApi"
      ]
    }, 
    LegalCoverage: {
      mandatoryApis: [
        "insureLoad",
        "getAllPossibleChoicesConfigurationForProduct",
        "searchPolicyProcessResources",
        "checkCoveredPersonsEligibilityForCoverage",
        "reworkProductEnrollment",
        "coverageLoad",
        "getListOfCoveragePlanDesignConfiguration",
        "getSubscribedCoverageConfiguration",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "prepareSubscriptionForChoice",
        "validateSubscribedProductData",
        "updateSubscribedCoverageInformationApi",
        "moveNext",
        "updateSubscribedCoverageInformation",
        "validateSubscribedCoverageData",
        "unsubscribeCoverage",
        "unsubscribeCoverageApi",
        "reworkProductEnrollment",
        "reworkLoad",
        "declineProduct",
        "declineProductApi"
      ]
    },
    ManageProfile: {
      mandatoryApis: [
        "getLinearOrNonLinear",
        "validateContract",
        // "updateProcess",
        "ManageProfile",
        "postUpdatedContactToSalesForce",
        "employeeProfileSubmit",
        "trigger-resume"
      ]
    },
    ProductSelection: {
      mandatoryApis: [
        "productSelectionLoad",
        "productSelectionSubmit"
      ]
    },
    DependentInformation: {
      mandatoryApis: [
        "dependentInformationLoad",
        "getInsuredAdditionalDataConfiguration",
        "createOrUpdateInsured",
        "createOrUpdateInsured_error",
        "deleteInsured"
      ]
    },
    manageBeneficiaries: {
      mandatoryApis: [
        "moveNext",
        "executeUntilLastStep",
        "getListOfEligibleProducts",
        "searchPolicyProcessResourcesCoverage",
        "getSubscribedCoverageBeneficiaryConfiguration",        
        "beneficiariesData",
        "manage_Beneficiaries_Call",
        "updateSubscribedCoverageInformation",
        "finalEnrollment",
        "updateManageBeneficiariesApi",
        "getSubscribedCoverageBeneficiaryConfigurationApiFailure",
        "getBeneficiariesDataApiFailure"
      ],
    },
    ManageBeneficiaries: {
      mandatoryApis: [
        "moveNext",
        "executeUntilLastStep",
        "getListOfEligibleProducts",
        "searchPolicyProcessResourcesCoverage",
        "getSubscribedCoverageBeneficiaryConfiguration",        
        "beneficiariesData",
        "manage_Beneficiaries_Call",
        "updateSubscribedCoverageInformation",
        "finalEnrollment",
        "updateManageBeneficiariesApi",
        "getSubscribedCoverageBeneficiaryConfigurationApiFailure",
        "getBeneficiariesDataApiFailure"
      ],
    },
    EnrollmentSummary: {
      mandatoryApis: [
        "getContractRatingInformation",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "getListOfEligibleProducts",
        "productDetails",
        // "updateProcess",
        // "validateExecuteLastStep",
        "enrollmentOnLoad",
        "finalEnrollmentApi",
        "genericrequirement",
        "getDocreqcontainerforcontract",
        "finalEnrollmentProcess",
        "executeUntilLastStep",
        "beneficiariesData",
        "updateManageBeneficiariesApi",
        "moveNext",
        "endorsementOnLoadApi",
        "searchPolicyProcessResourcesCoverage"
      ]
    },
    PetAssureNation: {
      mandatoryApis: [
        "validateSubscribedProductData",
        "getAllPossibleChoicesConfigurationForProduct",
        "searchPolicyProcessResources",
        "petInsuranceLoad",
        "getProductPresentation",
        "petInsuranceSave",
        "moveNext",
        "validateSubscribedCoverageData",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "updateSubscribedCoverageInformation",
        "updateSubscribedCoverageInformationApi",
        "unsubscribeCoverage",
        "unsubscribeCoverageApi",
        "pet-insurance-remove",
        "declineProduct",
        "getProviders",
        "getMedicalSurveyQuestions",
        "getCatBreeds",
        "getDogBreeds",
        "checkCoveredPersonsEligibilityForCoverage",
        "reworkProductEnrollment",
        "reworkLoad",
        "declineProductApi"
      ]
    },
    PetPlus: {
      mandatoryApis: [
        "validateSubscribedCoverageData",
        "updateSubscribedCoverageInformation",
        "moveNext",
        "updateSubscribedCoverageInformationApi",
        "PetPlusOnload",
        "getListOfCoveragePlanDesignConfigurationNew",
        "getSubscribedCoverageConfigurationNew",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "prepareSubscriptionForChoice",
        "validateSubscribedProductData",
        'searchPolicyProcessResources',
        "insureLoad",
        "getAllPossibleChoicesConfigurationForProduct",
        "checkCoveredPersonsEligibilityForCoverage",
        "reworkProductEnrollment",
        "getAllPossibleChoicesConfigurationForProduct",
        "moveNext",
        "validateSubscribedCoverageData",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "updateSubscribedCoverageInformation",
        "updateSubscribedCoverageInformationApi",
        "unsubscribeCoverage",
        "unsubscribeCoverageApi",
        "reworkProductEnrollment",
        "reworkLoad",
        "declineProduct",
        "declineProductApi"
      ]
    },
    PetAssure: {
      mandatoryApis: [
        "PetPlusOnload",
        "getListOfCoveragePlanDesignConfigurationNew",
        "getSubscribedCoverageConfigurationNew",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "prepareSubscriptionForChoice",
        "validateSubscribedProductData",
        "insureLoad",
        "getAllPossibleChoicesConfigurationForProduct",
        "searchPolicyProcessResources",
        "checkCoveredPersonsEligibilityForCoverage",
        "reworkProductEnrollment",
        "getAllPossibleChoicesConfigurationForProduct",
        "moveNext",
        "validateSubscribedCoverageData",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "updateSubscribedCoverageInformation",
        "updateSubscribedCoverageInformationApi",
        "unsubscribeCoverage",
        "unsubscribeCoverageApi",
        "reworkProductEnrollment",
        "reworkLoad",
        "declineProduct",
        "declineProductApi"
      ]
    },
    WLCoverage: {
      mandatoryApis: [
        "GetContractConfiguration"
      ]
    },
    Configurator: {
      mandatoryApis: [
        "getProductConfig",
        "saveProductConfing",
        "getDependentInfoByClientId",
        "createClientIntoDbDependent",
        "getClientByIdFromDb",
        "createClientIntoDb",
        "getHeaderInfoFromDb",
        "header",
        "getBeneficiaryInfofromDb",
        "createBeneficiaryInfofromDb",
        "getWelcomeInfofromDb",
        "createWelcomeInfofromDb",
        "getRetriveInfoFromDb",
        "createRetriveInfoFromDb",
        "getLegalEntities",
        "getLoginConfiguration",
        "createLoginConfiguration"
      ]
    },
    ExcessLiability: {
      mandatoryApis: [
        "insureLoad",
        "getAllPossibleChoicesConfigurationForProduct",
        "searchPolicyProcessResources",
        "checkCoveredPersonsEligibilityForCoverage",
        "reworkProductEnrollment",
        "coverageLoad",
        "getListOfCoveragePlanDesignConfiguration",
        "getSubscribedCoverageConfiguration",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "prepareSubscriptionForChoice",
        "validateSubscribedProductData",
        "getSubscribedCoverageBeneficiaryConfiguration",
        "validateSubscribedCoverageData",
        "updateSubscribedCoverageInformation",
        "moveNext",
        "updateSubscribedCoverageInformationApi",
        "reworkProductEnrollment",
        "reworkLoad",
        "declineProduct",
        "declineProductApi"
      ]
    },
  },
  IdTheft: {
    productBUID: '',
    InsuredQuestionAnswers: {},
    policyReplacementQuestions: {},
    disClosureRadioCIGeneral: false,
    coverageDetails: [],
    coverageBuids: [],
    WaiveCoverage: [],
    updatedBeneficiary: {},
    coverageInsuredEligiblity: [],
    ProductLevelEligiblity: {}
  },
  CriticalIllness: {
    productBUID: '',
    InsuredQuestionAnswers: {},
    policyReplacementQuestions: {},
    disClosureRadioCIGeneral: false,
    coverageDetails: [],
    coverageBuids: [],
    WaiveCoverage: [],
    updatedBeneficiary: {},
    coverageInsuredEligiblity: [],
    ProductLevelEligiblity: {},
    buyUpSelectedValues: {
      employee: null,
      spouse: null
    },
    basePlanSelectedValues: {
      employee: null,
      spouse: null
    }
  },
  HospitalIndemnity: {
    productBUID: '',
    InsuredQuestionAnswers: {},
    policyReplacementQuestions: {},
    disClosureRadioCIGeneral: false,
    coverageDetails: [],
    coverageBuids: [],
    WaiveCoverage: [],
    updatedBeneficiary: {},
    coverageInsuredEligiblity: [],
    eligibility: [],
    ProductLevelEligiblity: {}
  },
  CoverageSelection: {
    productBUID: '',
    InsuredQuestionAnswers: {},
    policyReplacementQuestions: {},
    disClosureRadioCIGeneral: false,
    coverageDetails: [],
    coverageBuids: [],
    WaiveCoverage: [],
    updatedBeneficiary: {},
    ProductLevelEligiblity: {},
    eligibility: []
  },
  SupplementalHealthInsurance: {
    productBUID: '',
    InsuredQuestionAnswers: {},
    policyReplacementQuestions: {},
    disClosureRadioCIGeneral: false,
    coverageDetails: [],
    coverageBuids: [],
    WaiveCoverage: [],
    updatedBeneficiary: {},
    ProductLevelEligiblity: {},
    eligibility: []
  },
  UniversalLife: {
    productBUID: '',
    InsuredQuestionAnswers: {},
    policyReplacementQuestions: {},
    disClosureRadioCIGeneral: false,
    coverageDetails: [],
    coverageBuids: [],
    WaiveCoverage: [],
    updatedBeneficiary: {},
    ProductLevelEligiblity: {},
    eligibility: []
  },
  PetAssureNation: {
    petList: [],
    productBUID: '',
    InsuredQuestionAnswers: {},
    policyReplacementQuestions: {},
    disClosureRadioCIGeneral: false,
    coverageDetails: [],
    coverageBuids: [],
    WaiveCoverage: [],
    updatedBeneficiary: {},
    coverageInsuredEligiblity: [],
    ProductLevelEligiblity: {}
  },
  PetAssure: {
    productBUID: '',
    InsuredQuestionAnswers: {},
    policyReplacementQuestions: {},
    disClosureRadioCIGeneral: false,
    coverageDetails: [],
    coverageBuids: [],
    WaiveCoverage: [],
    updatedBeneficiary: {},
    coverageInsuredEligiblity: [],
    ProductLevelEligiblity: {}
  },
  PetPlus: {
    productBUID: '',
    InsuredQuestionAnswers: {},
    policyReplacementQuestions: {},
    disClosureRadioCIGeneral: false,
    coverageDetails: [],
    coverageBuids: [],
    WaiveCoverage: [],
    updatedBeneficiary: {},
    coverageInsuredEligiblity: [],
    ProductLevelEligiblity: {}
  }

}
const slice = createSlice({
  name: 'insuredInfo',
  initialState: cloneDeep(initialState),
  reducers: {
    CLEAR_MESSAGE: (state, action) => {
      const currentStep = action.payload;

      if (state[currentStep]) {
        state[currentStep].successMessage = null;
        state[currentStep].errorMessage = null;
      }
    },

    UPDATE_ERROR_MESSAGE: (state, action) => {
      const { message, currentStep } = action.payload;

      if (!state[currentStep]) {
        state[currentStep] = {};
      }

      state[currentStep].errorMessage = message || null;
    },
    UPDATE_SUCCESS_MESSAGE: (state, action) => {
      const { message, currentStep } = action.payload;

      if (!state[currentStep]) {
        state[currentStep] = {};
      }

      state[currentStep].successMessage = message || null;
    },

    CLEAR_ALERT_MESSAGES: (state, action) => {
      state.alertMessages = [];
    },

    SET_ALERT_MESSAGE: (state, action) => {
      const currentAlertMessages = JSON.parse(JSON.stringify(state.alertMessages || []));
      const newAlertMessages = [];
      if (currentAlertMessages.length === 0) {
        state.alertMessages = [...state.alertMessages, action.payload];
      } else {
        currentAlertMessages.forEach(message => {
          if (message?.id !== action.payload?.id) {
            newAlertMessages.push(action.payload);
          }
        });
        state.alertMessages = [...state.alertMessages, ...newAlertMessages];
      }
    },
    REMOVE_CURRENTSTEP_ALERT_MESSAGE: (state, action) => {
      const currentAlertMessages = JSON.parse(JSON.stringify(state.alertMessages || []));
      if (currentAlertMessages.length > 0) {
        const newAlertMessages = currentAlertMessages.filter((pobj) => {
          return pobj.page !== action.payload;
        });
        state.alertMessages = [...newAlertMessages];
      }
    },
    UPDATE_ALERT_MESSAGE: (state, action) => {
      const currentAlertMessages = JSON.parse(JSON.stringify(state.alertMessages || []));
      const newAlertMessages = [];
      currentAlertMessages.forEach(alert => {
        if (alert?.id !== action.payload) {
          newAlertMessages.push(alert);
        }
      });
      state.alertMessages = newAlertMessages;
    },

    UPDATE_LOADING: (state, action) => {
      const isLoading = action.payload;
      const loaderCount = state.loaderCount || 0
      state.loaderCount = isLoading ? loaderCount + 1 : loaderCount - 1;
      state.isLoading = isLoading || state.loaderCount > 0;
    },
    

    UPDATE_INSURED: (state, action) => {
      const { product, data } = action.payload;
      if (state[product]) {
        state[product] = {
          ...state[product],
          ...data,
        };
      } else {
        state[product] = data;
      }
    },

    // UPDATE_BENEFICIARIES: (state, action) => {
    //   const { personBuid, data, currentStep } = action.payload;

    //   // if the current step is not defined in the state, initialize it
    //   if (!state[currentStep]) {
    //     state[currentStep] = {};
    //   }

    //   // if the updatedBeneficiaries is not defined in the current step, initialize it
    //   if (!state[currentStep].updatedBeneficiaries) {
    //     state[currentStep].updatedBeneficiaries = [];
    //   }

    //   // // Find the index of the person in the updatedBeneficiaries array
    //   // const personIndex = state[currentStep].updatedBeneficiaries.findIndex(obj => obj[personBuid]);

    //   // // If the person is not found, add a new object
    //   // if (personIndex === -1) {
    //   //   state[currentStep].updatedBeneficiaries.push({ [personBuid]: data });
    //   // } else {
    //   //   // If the person is found, update the specific person's data in the beneficiaries list
    //   //   state[currentStep].updatedBeneficiaries[personIndex][personBuid] = data;
    //   // }
    // },


    // UPDATE_PRODUCT_FAMILY_ELIGIBILITY: (insuredInfo, action) => {
    //   const {
    //     productFamily,
    //     product,
    //     productBuid,
    //     policyReplacementQuestionEnable,

    //   } = action.payload;

    //   // Check if the product exists in insuredInfo, if not, create an empty object
    //   if (!insuredInfo[product]) {
    //     insuredInfo[product] = {};
    //   }

    //   // Check if the eligibility array exists in the product, if not, create an empty array
    //   if (!insuredInfo[product].eligibility) {
    //     insuredInfo[product].eligibility = [];
    //   }

    //   // Find the index of the existing object with the given productBuid
    //   const existingIndex = insuredInfo[product].eligibility.findIndex(
    //     (item) => item && item.personBuid === productBuid
    //   );

    //   if (existingIndex !== -1) {
    //     // Update the existing object
    //     insuredInfo[product].eligibility[existingIndex].policyReplacementQuestionEnable = policyReplacementQuestionEnable || null;
    //     insuredInfo[product].eligibility[existingIndex].productFamily = productFamily || null;
    //     insuredInfo[product].eligibility[existingIndex].productBuid = productBuid || null;


    //   } else {
    //     // Add a new object with null check
    //     insuredInfo[product].eligibility.push({
    //       personBuid: productBuid,
    //       policyReplacementQuestionEnable: policyReplacementQuestionEnable || null,
    //       coverageEligiblity: null,
    //       productFamily: productFamily || null,
    //       productBuid: productBuid || null
    //     });
    //   }
    // },

    SET_COVERAGE_BENEFICIARY_ELIGIBILITY: (insuredInfo, action) => {
      const { eligibility, product, productPage = false } = action.payload;

      // Check if the product exists in insuredInfo, if not, create an empty object
      if (!insuredInfo[product]) {
        insuredInfo[product] = {};
      }

      // If product exists in insuredInfo, set or create coverageBeneficiaryEligibility with the eligibility payload
      if (product && insuredInfo[product]) {
        if (!insuredInfo[product].coverageBeneficiaryEligibility) {
          insuredInfo[product].coverageBeneficiaryEligibility = [];
        }
        insuredInfo[product].coverageBeneficiaryEligibility = !productPage ? [...insuredInfo[product].coverageBeneficiaryEligibility, ...eligibility] : eligibility;
      }
    },



    UPDATE_INSURED_QUESTION_ANSWERS: (state, action) => {
      const { buid, questionId, answer, currentStep } = action.payload;

      // Check if the product exists in state, if not, create an empty object
      if (!state[currentStep]) {
        state[currentStep] = {};
      }

      // Check if the InsuredQuestionAnswers array exists in the product, if not, create an empty array
      if (!state[currentStep].InsuredQuestionAnswers) {
        state[currentStep].InsuredQuestionAnswers = {};
      }

      // Update the InsuredQuestionAnswers object
      state[currentStep].InsuredQuestionAnswers[buid] = {
        ...state[currentStep].InsuredQuestionAnswers[buid],
        [questionId]: answer,
      };
    },

    UPDATE_COVERAGE_DETAILS: (insuredInfo, action) => {
      const { personBuid, selectedValue, waivedCoverage, currentStep, coverageBuid, costPerPayPeriod, removeCoverage = false, relationship } = action.payload;

      // Check if CriticalIllness exists, if not, create it
      if (!insuredInfo[currentStep]) {
        insuredInfo[currentStep] = {};
      }


      // Check if coverageDetails exists, if not, create it
      if (!insuredInfo[currentStep].coverageDetails) {
        insuredInfo[currentStep].coverageDetails = [];
      }

      const existingIndex = insuredInfo[currentStep].coverageDetails.findIndex(
        (coverage) => coverage && coverage.coverageBuid === coverageBuid
      );

      if (removeCoverage) {
        insuredInfo[currentStep].coverageDetails.splice(existingIndex, 1)
      } else {
        if (insuredInfo[currentStep].WaiveCoverage?.length > 0 && !waivedCoverage) {
          insuredInfo[currentStep].WaiveCoverage.forEach(item => {
            item.waiveProduct = false;
          })
        }
        if (existingIndex !== -1) {
          // Update the existing coverage detail
          insuredInfo[currentStep].coverageDetails[existingIndex] = {
            personBuid,
            selectedValue,
            waivedCoverage,
            coverageBuid,
            relationship,
            costPerPayPeriod
          };
        } else {
          // Add a new coverage detail
          insuredInfo[currentStep].coverageDetails.push({
            personBuid,
            selectedValue,
            waivedCoverage,
            coverageBuid,
            relationship,
            costPerPayPeriod
          });
        }
      }

    },
    CHECK_ELIGIBILITY: (insuredInfo, action) => {
      const { response, product } = action.payload || {};
      if (product && insuredInfo[product]) {
        insuredInfo[product].coverageInsuredEligiblity = response;
      }
    },
    SET_COVERAGE_BUIDS: (insuredInfo, action) => {
      const { bUIDs, product } = action.payload;
      if (product && insuredInfo[product]) {
        insuredInfo[product].coverageBuids = bUIDs;
      }
    },
    UPDATE_POLICY_REPLACEMENT_QUESTIONS: (insuredInfo, action) => {
      const { currentStep, updatedFormValues } = action.payload;
      insuredInfo[currentStep].policyReplacementQuestions = updatedFormValues;
    },
    // CRITICAL_ILLNESS_GENERAL_TABLE_COVERAGE: (insuredInfo, action) => {
    //   const { currentStep, coverage } = action.payload;
    //   insuredInfo[currentStep].spCovearge = coverage || []
    //   insuredInfo[currentStep].chdCovearge = coverage
    // },
    DISCLOUSRE_RADIO: (insuredInfo, action) => {
      const { currentStep, disClosureRadio } = action.payload;
      insuredInfo[currentStep].disClosureRadio = disClosureRadio
    },



    // SET_INSURED_INFO: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;

    //   insuredInfo[key] = {
    //     ...insuredInfo[key],
    //     dependents: action.payload.data,
    //     active: [true, false, false],
    //     selectedAnswer: '',
    //     selectedQuestionId: '',
    //     selectedIndex: '',
    //     inputId: '',
    //     selectedoptionId: [],
    //     savedAnswer: [],
    //     selectedName: '',
    //     waiveInsureCoverage: false,
    //     questionsAnsweredForAll: false,
    //     dependentAnswers: [null],
    //     employeeEligiblity: {},
    //     disableAnswersRadioStatus: null,
    //     count: false,
    //     eligibilityMessages: [],
    //     editAnswers: null,
    //     disableAll: null,
    //     skipProduct: null,
    //     notEligibleDependent: null,
    //     showedError: null,
    //     nonSharedAdditionalDatas: action.payload.nonSharedAdditionalDatas,
    //     continueButtonEnable: action.payload.continueButtonEnable,
    //   }

    // },
    // SET_INSURED_INFO_THEFT: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   insuredInfo[key] = {
    //     ...insuredInfo[key],
    //     dependents: action.payload.data,
    //     active: [true, false, false],
    //     selectedAnswer: '',
    //     selectedQuestionId: '',
    //     selectedIndex: '',
    //     inputId: '',
    //     selectedoptionId: [],
    //     savedAnswer: [],
    //     selectedName: '',
    //     waiveInsureCoverage: false,
    //     questionsAnsweredForAll: false,
    //     dependentAnswers: [null],
    //     employeeEligiblity: {},
    //     disableAnswersRadioStatus: null,
    //     count: false,
    //     eligibilityMessages: [],
    //     editAnswers: null,
    //     disableAll: null,
    //     skipProduct: null,
    //     notEligibleDependent: null,
    //     showedError: null,
    //     nonSharedAdditionalDatas: action.payload.nonSharedAdditionalDatas,
    //   }
    // },
    // SET_INSURED_INFO_TRAVEL: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   insuredInfo[key] = {
    //     ...insuredInfo[key],
    //     dependents: action.payload.data,
    //     active: [true, false, false],
    //     selectedAnswer: '',
    //     selectedQuestionId: '',
    //     selectedIndex: '',
    //     inputId: '',
    //     selectedoptionId: [],
    //     savedAnswer: [],
    //     selectedName: '',
    //     waiveInsureCoverage: false,
    //     questionsAnsweredForAll: false,
    //     dependentAnswers: [null],
    //     employeeEligiblity: {},
    //     disableAnswersRadioStatus: null,
    //     count: false,
    //     eligibilityMessages: [],
    //     editAnswers: null,
    //     disableAll: null,
    //     skipProduct: null,
    //     notEligibleDependent: null,
    //     showedError: null,
    //     nonSharedAdditionalDatas: action.payload.nonSharedAdditionalDatas,
    //   }
    // },
    // SET_INSURED_INFO_LEGAL_COVERAGE: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   insuredInfo[key] = {
    //     ...insuredInfo[key],
    //     dependents: action.payload.data,
    //     active: [true, false, false],
    //     selectedAnswer: '',
    //     selectedQuestionId: '',
    //     selectedIndex: '',
    //     inputId: '',
    //     selectedoptionId: [],
    //     savedAnswer: [],
    //     selectedName: '',
    //     waiveInsureCoverage: false,
    //     questionsAnsweredForAll: false,
    //     dependentAnswers: [null],
    //     employeeEligiblity: {},
    //     disableAnswersRadioStatus: null,
    //     count: false,
    //     eligibilityMessages: [],
    //     editAnswers: null,
    //     disableAll: null,
    //     skipProduct: null,
    //     notEligibleDependent: null,
    //     showedError: null,
    //     nonSharedAdditionalDatas: action.payload.nonSharedAdditionalDatas
    //   }
    // },
    // SET_INSURED_INFO_CRITICAL_ILLNESS: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   // insuredInfo[key] = action.payload.data;
    //   // insuredInfo[key].active= [true, false, false];
    //   insuredInfo[key] = {
    //     ...insuredInfo[key],
    //     dependents: action.payload.data,
    //     active: [true, false, false],
    //     selectedAnswer: '',
    //     selectedQuestionId: '',
    //     selectedIndex: '',
    //     inputId: '',
    //     selectedoptionId: [],
    //     savedAnswer: [],
    //     selectedName: '',
    //     waiveInsureCoverage: false,
    //     questionsAnsweredForAll: false,
    //     dependentAnswers: [null],
    //     employeeEligiblity: {},
    //     disableAnswersRadioStatus: null,
    //     count: false,
    //     eligibilityMessages: [],
    //     editAnswers: null,
    //     disableAll: null,
    //     skipProduct: null,
    //     notEligibleDependent: null,
    //     showedError: null,
    //     nonSharedAdditionalDatas: action.payload.nonSharedAdditionalDatas
    //   }
    // },
    // SET_INSURED_INFO_PET_PLUS: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   insuredInfo[key] = {
    //     ...insuredInfo[key],
    //     dependents: action.payload.data,
    //     active: [true, false, false],
    //     selectedAnswer: '',
    //     selectedQuestionId: '',
    //     selectedIndex: '',
    //     inputId: '',
    //     selectedoptionId: [],
    //     savedAnswer: [],
    //     selectedName: '',
    //     waiveInsureCoverage: false,
    //     questionsAnsweredForAll: false,
    //     dependentAnswers: [null],
    //     employeeEligiblity: {},
    //     disableAnswersRadioStatus: null,
    //     count: false,
    //     eligibilityMessages: [],
    //     editAnswers: null,
    //     disableAll: null,
    //     skipProduct: null,
    //     notEligibleDependent: null,
    //     showedError: null,
    //     nonSharedAdditionalDatas: []
    //   }
    // },
    // SET_INSURED_INFO_HOSPITAL: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   insuredInfo[key] = {
    //     ...insuredInfo[key],
    //     dependents: action.payload.data,
    //     active: [true, false, false],
    //     selectedAnswer: '',
    //     selectedQuestionId: '',
    //     selectedIndex: '',
    //     inputId: '',
    //     selectedoptionId: [],
    //     savedAnswer: [],
    //     selectedName: '',
    //     waiveInsureCoverage: false,
    //     questionsAnsweredForAll: false,
    //     dependentAnswers: [null],
    //     employeeEligiblity: {},
    //     disableAnswersRadioStatus: null,
    //     count: false,
    //     eligibilityMessages: [],
    //     editAnswers: null,
    //     disableAll: null,
    //     skipProduct: null,
    //     notEligibleDependent: null,
    //     showedError: null,
    //     nonSharedAdditionalDatas: []
    //   }
    // },
    // SET_INSURED_INFO_PET_ASSURE: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   insuredInfo[key] = {
    //     ...insuredInfo[key],
    //     dependents: action.payload.data,
    //     active: [true, false, false],
    //     selectedAnswer: '',
    //     selectedQuestionId: '',
    //     selectedIndex: '',
    //     inputId: '',
    //     selectedoptionId: [],
    //     savedAnswer: [],
    //     selectedName: '',
    //     waiveInsureCoverage: false,
    //     questionsAnsweredForAll: false,
    //     dependentAnswers: [null],
    //     employeeEligiblity: {},
    //     disableAnswersRadioStatus: null,
    //     count: false,
    //     eligibilityMessages: [],
    //     editAnswers: null,
    //     disableAll: null,
    //     skipProduct: null,
    //     notEligibleDependent: null,
    //     showedError: null,
    //     nonSharedAdditionalDatas: []
    //   }
    // },
    // SET_INSURED_INFO_EXCESS_LIABILITY: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   insuredInfo[key] = {
    //     ...insuredInfo[key],
    //     dependents: action.payload.data,
    //     active: [true, false, false],
    //     selectedAnswer: '',
    //     selectedQuestionId: '',
    //     selectedIndex: '',
    //     inputId: '',
    //     selectedoptionId: [],
    //     savedAnswer: [],
    //     selectedName: '',
    //     waiveInsureCoverage: false,
    //     questionsAnsweredForAll: false,
    //     dependentAnswers: [null],
    //     employeeEligiblity: {},
    //     disableAnswersRadioStatus: null,
    //     count: false,
    //     eligibilityMessages: [],
    //     editAnswers: null,
    //     disableAll: null,
    //     skipProduct: null,
    //     notEligibleDependent: null,
    //     showedError: null,
    //     nonSharedAdditionalDatas: []
    //   }
    // },

    SET_INSURED_INFO_FIRST_OPTION: (insuredInfo, action) => {
      insuredInfo.firstOption = action.payload.data;
    },
    SET_INSURED_INFO_SECOND_OPTION: (insuredInfo, action) => {
      insuredInfo.secondOption = action.payload.data;
    },
    SET_INSURED_INFO_THIRD_OPTION: (insuredInfo, action) => {
      let key = "thirdOption";
      insuredInfo[key] = {
        ...insuredInfo[key],
        dependents: action.payload.data,
        active: [true, false, false],
        selectedAnswer: '',
        selectedQuestionId: '',
        selectedIndex: '',
        inputId: '',
        selectedoptionId: [],
        savedAnswer: [],
        selectedName: '',
        waiveInsureCoverage: false,
        questionsAnsweredForAll: false,
        dependentAnswers: [null],
        employeeEligiblity: {},
        disableAnswersRadioStatus: null,
        count: false,
        eligibilityMessages: [],
        editAnswers: null,
        disableAll: null,
        skipProduct: null,
        notEligibleDependent: null,
        showedError: null,
      }
    },
    SET_WAIVE_COVERAGE: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      if (insuredInfo[key]) {
        insuredInfo[key].waiveInsureCoverage = !insuredInfo[key]?.waiveInsureCoverage;
        //Uncomment below line
        insuredInfo[key].waiveInsureCoverage = action.payload.status;
        insuredInfo[key].disableAnswersRadioStatus = action.payload.status;
      }


    },
    SET_WAIVE_COVERAGE_NATION: (insuredInfo, action) => {
      insuredInfo.setWaiveCoverageNation = action.payload.status;
    },
    UPDATE_WAIVE_PRODUCT: (insuredInfo, action) => {
      const { personBuid, waiveProduct, currentStep, relationship } = action.payload;

      // Check if the product exists, if not, create it
      if (!insuredInfo[currentStep]) {
        insuredInfo[currentStep] = {};
      }

      // Check if WaiveCoverage exists, if not, create it
      if (!insuredInfo[currentStep].WaiveCoverage) {
        insuredInfo[currentStep].WaiveCoverage = [];
      }

      const existingIndex = insuredInfo[currentStep].WaiveCoverage.findIndex(
        (coverage) => coverage && coverage.personBuid === personBuid
      );

      if (existingIndex !== -1) {
        // Update the existing coverage detail
        insuredInfo[currentStep].WaiveCoverage[existingIndex] = {
          personBuid,
          waiveProduct, // Update selectedValue to waiveProduct
          relationship
        };
      } else {
        // Add a new coverage detail
        insuredInfo[currentStep].WaiveCoverage.push({
          personBuid,
          waiveProduct, // Update selectedValue to waiveProduct
          relationship
        });
      }
    },
    UPDATE_WAIVE_PRODUCT_WITHOUT_PERSONBUID: (insuredInfo, action) => {
      const { waiveProduct, currentStep } = action.payload;

      // Check if the product exists, if not, create it
      if (!insuredInfo[currentStep]) {
        insuredInfo[currentStep] = {};
      }

      // Replace WaiveCoverage with a new array containing only the new waiveProduct
      insuredInfo[currentStep].WaiveCoverage = [{ waiveProduct }];
    },
    SET_WAIVE_COVERAGE_VOYA: (insuredInfo, action) => {

      insuredInfo.setWaiveCoverageVoya = action.payload.status;

    },
    // SET_QUESTIONS_ANSWERED: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   insuredInfo[key].questionsAnsweredForAll = !insuredInfo[key].questionsAnsweredForAll;
    // },
    // SET_EMPLOYEE_ELIGIBILITY: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   insuredInfo[key].employeeEligiblity = action.payload;
    // },
    DISABLE_ANSWERS_RADIO_STATUS: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      if (
        insuredInfo.currentScenario !== 'cThird' &&
        action.payload.currentStep //=== 'CoverageSelection'
      )
        for (let i = 0; i < insuredInfo[key].length; i++)
          insuredInfo[key][i].editAnswers = action.payload.editStatus;
      else if (insuredInfo.currentScenario === 'cThird')
        for (let i = 0; i < insuredInfo.thirdOption.length; i++)
          insuredInfo.thirdOption[i].editAnswers = action.payload.editStatus;
      insuredInfo[key].disableAnswersRadioStatus = true; //action.payload;
    },
    ENABLE_ANSWERS_RADIO_STATUS: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      insuredInfo[key].disableAnswersRadioStatus = false;
      if (
        (insuredInfo.currentScenario !== 'cThird' || !insuredInfo.currentScenario) &&
        (action.payload.currentStep === 'CoverageSelection' || action.payload.currentStep === 'HospitalIndemnity' || action.payload.currentStep === 'CriticalIllness')
      )
        for (let i = 0; i < insuredInfo[key].dependents.length; i++)
          insuredInfo[key].dependents[i].editAnswers = action.payload.editStatus;
      else if (insuredInfo.currentScenario === 'cThird') {
        for (let i = 0; i < insuredInfo.thirdOption.dependents.length; i++)
          insuredInfo.thirdOption.dependents[i].editAnswers = action.payload.editStatus;
        insuredInfo.thirdOption.disableAnswersRadioStatus = false;
      }
    },
    UPDATE_ANSWERED_QUESTIONS: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      if ( //insuredInfo[key].dependents[action.payload.index].noOfAnsweredQuestions && 
        insuredInfo[key].dependents[action.payload.index].noOfAnsweredQuestions < 4) {
        insuredInfo[key].dependents[action.payload.index].noOfAnsweredQuestions =
          insuredInfo[key].dependents[action.payload.index].noOfAnsweredQuestions + 1;
        insuredInfo[key].dependents[action.payload.index].questionsAnswers[
          action.payload.id
        ].question.answered = action.payload.answered;
        let noOfAnsweredQuestions =
          insuredInfo[key].dependents[action.payload.index].noOfAnsweredQuestions;

        let noOfTotalQuestions =
          insuredInfo[key].dependents[action.payload.index].questionsAnswers.length;
        if (noOfAnsweredQuestions === noOfTotalQuestions) {
          insuredInfo[key].dependents[action.payload.index].answeredAllQuestions = true;
          if (key === "CoverageSelection") {
            insuredInfo[key].dependentAnswers = insuredInfo[key].dependentAnswers.filter(function (el) {
              return el != null;
            });
            insuredInfo[key].dependentAnswers = [
              ...insuredInfo[key].dependentAnswers,
              true
            ]
          } else {
            insuredInfo[key].dependentAnswers[action.payload.index] = true;
          }


        }
        let dependentsQues = insuredInfo[key].dependents.filter(
          (arr) => arr.questionsAnswers?.length > 0
        );
        insuredInfo[key].dependentAnswers.length === dependentsQues.length &&
          insuredInfo[key].dependentAnswers.indexOf(null) === -1 &&
          insuredInfo[key].dependentAnswers.indexOf(false) === -1
          ? (insuredInfo[key].questionsAnsweredForAll = true)
          : (insuredInfo[key].questionsAnsweredForAll = false);
      }
    },
    UPDATE_ANSWERES: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      insuredInfo[key].dependents[action.payload.index].questionsAnswers[
        action.payload.id
      ].question.answered = action.payload.answered;
    },
    ANSWERE_QUESTION: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      if (action.payload.inputId === 0) {
        insuredInfo[key].dependents[action.payload.index].questionsAnswers[
          action.payload.id
        ].answers.inputs[0].selected = true;
        insuredInfo[key].dependents[action.payload.index].questionsAnswers[
          action.payload.id
        ].answers.inputs[1].selected = false;
      } else if (action.payload.inputId === 1) {
        insuredInfo[key].dependents[action.payload.index].questionsAnswers[
          action.payload.id
        ].answers.inputs[1].selected = true;
        insuredInfo[key].dependents[action.payload.index].questionsAnswers[
          action.payload.id
        ].answers.inputs[0].selected = false;
      }
    },
    SET_ANSWER_OPTION: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      insuredInfo[key].selectedAnswer = action.payload.inputValue;
      insuredInfo[key].inputId = action.payload.inputId;
      insuredInfo[key].selectedIndex = action.payload.selectedIndex;
      if (action.payload.selectedQuestionId) insuredInfo[key].selectedQuestionId = action.payload.selectedQuestionId;
      let temp = `${action.payload.selectedIndex}${action.payload.selectedQuestionId}`;

      if (!insuredInfo[key].selectedoptionId.includes(action.payload.selectedoptionId)) {

        insuredInfo[key].selectedoptionId = [
          ...insuredInfo[key].selectedoptionId.filter((arr) => !arr.includes(temp)),
          action.payload.selectedoptionId,
        ];
      }
      insuredInfo[key].selectedName = action.payload.selectedName;
    },
    // SET_ACTIVE: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;

    //   if (insuredInfo[key].active.includes(true) && !insuredInfo[key].active[action.payload.index]) {
    //     for (let i = 0; i < insuredInfo.active.length; i++) {
    //       if (insuredInfo[key].active[i] === true) insuredInfo[key].active[i] = false;
    //     }
    //     insuredInfo[key].active[action.payload.index] = !insuredInfo[key].active[action.payload.index];
    //   } else {
    //     insuredInfo[key].active[action.payload.index] = !insuredInfo[key].active[action.payload.index];
    //   }
    // },
    SET_All_INACTIVE: (insuredInfo, action) => {
      let key = action.payload.currentStep;

      for (let i = 0; i < 3; i++) {
        insuredInfo[key].active[i] = false;
      }
    },
    // SET_FIRST_ACTIVE: (insuredInfo, action) => {
    //   let key = action.payload.currentStep;
    //   for (let i = 0; i < 3; i++) {
    //     if (i === 0) insuredInfo[key].active[i] = true;
    //     else insuredInfo[key].active[i] = false;
    //   }
    // },
    SET_ELIGIBILITY_MESSAGES: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      if (insuredInfo.currentScenario !== 'cThird')
        for (let i = 0; i < action.payload.temp.length; i++) {
          insuredInfo[key].eligibilityMessages[i] = action.payload.temp[i];
        }
      else {
        for (let i = 0; i < action.payload.temp.length; i++) {
          insuredInfo.thirdOption.eligibilityMessages[i] = action.payload.temp[i];
        }
      }
    },
    SET_DEPENDENT_ELIGIBILITY: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      if (
        insuredInfo.currentScenario !== 'cThird' &&
        (action.payload.currentStep === 'CoverageSelection' ||
          action.payload.currentStep === 'HospitalIndemnity' ||
          action.payload.currentStep === 'CriticalIllness')
      ) {
        if (action.payload.temp[0].length === 0) {
          insuredInfo[key].disableAll = false;
          for (let i = 0; i < action.payload.temp.length; i++) {
            insuredInfo[key].dependents[i].eligibleForCoverage =
              action.payload.temp[i].length === 0;
          }
        } else {
          insuredInfo[key].disableAll = true;
          !(insuredInfo[key].waiveInsureCoverage) ?
            insuredInfo[key].skipProduct = true : insuredInfo[key].skipProduct = false;


          for (let i = 0; i < action.payload.temp.length; i++) {
            insuredInfo[key].dependents[i].eligibleForCoverage = false;
          }
        }
      } else if (insuredInfo.currentScenario === 'cThird') {
        if (action.payload.temp[0].length === 0) {
          insuredInfo[key].disableAll = false;
          for (let i = 0; i < action.payload.temp.length; i++) {
            insuredInfo.thirdOption.dependents[i].eligibleForCoverage = action.payload.temp[i].length === 0;
          }
        } else {
          insuredInfo.thirdOption.disableAll = true;
          !(insuredInfo.thirdOption.waiveInsureCoverage) ?
            insuredInfo.thirdOption.skipProduct = true : insuredInfo.thirdOption.skipProduct = false;

          // insuredInfo.thirdOption.skipProduct = true;

          for (let i = 0; i < action.payload.temp.length; i++) {
            insuredInfo.thirdOption.dependents[i].eligibleForCoverage = false;
          }
        }
      }
    },
    SET_EDIT_ANSWERS: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      if (action.payload.currentStep === 'CoverageSelection' ||
        action.payload.currentStep === 'HospitalIndemnity' ||
        action.payload.currentStep === 'CriticalIllness') {
        insuredInfo[key].dependents[action.payload.index].editAnswers = action.payload.editStatus;
      } else {
        if (
          insuredInfo.currentScenario !== 'cThird' &&
          action.payload.currentStep //=== 'CoverageSelection'
        )
          insuredInfo[key].dependents[action.payload.index].editAnswers = action.payload.editStatus;
        else if (insuredInfo.currentScenario === 'cThird')
          insuredInfo.thirdOption.dependents[action.payload.index].editAnswers = action.payload.editStatus;
      }

    },
    SKIP_PRODUCT: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      insuredInfo[key].skipProduct = insuredInfo[key].disableAll;
    },
    NOT_ELIGIBLE_DEPENDENT: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      insuredInfo[key].notEligibleDependent = insuredInfo[key].disableAnswersRadioStatus;
    },
    SHOWED_ERROR: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      insuredInfo[key].showedError = action.payload.status;
    },
    CHANGE_SCENARIO: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      insuredInfo.currentScenario = action.payload.value;
      let DependentInformation;
      if (action.payload.value === 'CoverageSelection')
        DependentInformation = insuredInfo.CoverageSelection;
      else if (action.payload.value === 'IdTheft') DependentInformation = insuredInfo.IdTheft;
      else if (action.payload.value === 'SupplementalHealthInsurance') DependentInformation = insuredInfo.SupplementalHealthInsurance;
      else DependentInformation = null;

      if (action.payload.currentStep === 'CoverageSelection') {
        DependentInformation =
          action.payload.value === 'cFirst'
            ? insuredInfo.firstOption
            : action.payload.currentStep === 'cSecond'
              ? insuredInfo.secondOption
              : action.payload.value === 'cThird'
                ? insuredInfo.thirdOption
                : insuredInfo.CoverageSelection;
      } else if (action.payload.currentStep === 'IdTheft'){
        DependentInformation = insuredInfo.IdTheft;
      } else if (action.payload.currentStep === 'SupplementalHealthInsurance')
        DependentInformation = insuredInfo.SupplementalHealthInsurance;
      else DependentInformation = null;
    },
    DELETE_INSURED_INFO: (insuredInfo, action) => {
      let key = action.payload.stepForm;
      if (key.length > 0) {
        key.map((e) => delete insuredInfo[e])
      }

    },
    UPDATE_ELIGIBLE_ANSWERES: (insuredInfo, action) => {
      let key = action.payload.currentStep;
      insuredInfo[key].nonSharedAdditionalDatas[action.payload.index].setPossibleValues[
        action.payload.id
      ].nameDescription = action.payload.answered;
      insuredInfo[key].nonSharedAdditionalDatas[action.payload.index].setPossibleValues[
        action.payload.id
      ].valueCode = action.payload.answered;
      insuredInfo[key].nonSharedAdditionalDatas[action.payload.index].setPossibleValues[
        action.payload.id
      ].fieldValue = action.payload.fieldValue;
    },
    // DISPLAY_POLICY_QUESTIONS: (insuredInfo, action) => {
    //   insuredInfo.displayPolicyQuestions = action.payload
    // },
    SET_COVERAGE_BUIDS: (insuredInfo, action) => {
      const { bUIDs, product } = action.payload;
      if (product && insuredInfo[product]) {
        insuredInfo[product].coverageBuids = bUIDs;
      }
    },
    // Method to Update Beneficiaries
    SET_BENEFICIARIES_TO_UPDATE: (state, action) => {
      if (!state || !action || !action.payload) return;
      const {
        coverageBuid,
        currentStep,
        data,
      } = action.payload;

      // if the current step is not defined in the state, initialize it
      if (!state[currentStep]) {
        state[currentStep] = {};
      }

      // if the updatedBeneficiaries is not defined in the current step, initialize it
      if (!state[currentStep].updatedBeneficiary) {
        state[currentStep].updatedBeneficiary = {};
      }

      state[currentStep].updatedBeneficiary[coverageBuid] = data;
    },
    UPDATE_BENEFICIARIES_PRODUCTS: (state, action) => {
      if (!state || !action || !action.payload) return;

      const {
        insuredInfo = {},
        productsToUpdate = [],
      } = action.payload;

      if (!insuredInfo || !productsToUpdate) return;

      const productsToSave = [];
      productsToUpdate.forEach(product => {
        if (insuredInfo && insuredInfo[product]) {
          productsToSave.push(product);
          if (state[product]) {
            state[product].updatedBeneficiary = insuredInfo[product].updatedBeneficiary;
            state[product].productBUID = insuredInfo[product].productBUID;
          }
        }
      });
      const newProducts = [...new Set([...state.productsToSave, ...productsToSave])];
      state.productsToSave = newProducts;
    },
    UPDATE_BENEFICIARIES_PRODUCT: (state, action) => {
      const parseInformation = (beneficiaryData = {}) => {
        const types = {
          "Primary": "cPrimary",
          "Contingent": "cContingent",
        };
        const isDependent = beneficiaryData?.isDependent || false;
        const updatedBeneficiary = isDependent ? beneficiaryData : beneficiaryData?.data || beneficiaryData?.beneficiaryInformation;
        const isExistingBeneficiary = Object.keys(beneficiaryData?.fullData || {})?.length > 0 || beneficiaryData?.beneficiaryPersonBuid ? true : false;

        const newBeneficiary = {
          legalName: updatedBeneficiary?.legalName || '',
          beneficiaryIrrevocable: updatedBeneficiary?.beneficiaryIrrevocable || updatedBeneficiary?.irrevocable || '',
          beneficiaryCollateral: updatedBeneficiary?.beneficiaryCollateral || '',
          beneficiaryFirstName: updatedBeneficiary?.beneficiaryFirstName || updatedBeneficiary?.firstName || '',
          beneficiaryMiddleName: updatedBeneficiary?.beneficiaryMiddleName || '',
          beneficiaryLastName: updatedBeneficiary?.beneficiaryLastName || updatedBeneficiary?.lastName || '',
          SSN: isDependent ? updatedBeneficiary?.data?.person?.data?.actor?.sSN : updatedBeneficiary?.SSN || updatedBeneficiary?.sSN || '',
          beneficiaryRelationship: updatedBeneficiary?.beneficiaryRelationship || updatedBeneficiary?.relationToInsured || '',
          beneficiaryOptions: updatedBeneficiary?.beneficiaryOptions ? updatedBeneficiary?.beneficiaryOptions : beneficiaryData?.className === 'aSPLI_BeneficiaryData' || beneficiaryData?.className === 'aSPLI_BeneficiaryData' ? 'individual' : 'trust',
          beneficiaryPercentage: isDependent ? updatedBeneficiary?.beneficiaryPercentage : updatedBeneficiary?.percentage || beneficiaryData?.beneficiaryInformation?.percentage || 0,
          beneficiaryType: isDependent ? types[updatedBeneficiary?.beneficiaryRank] || updatedBeneficiary?.beneficiaryRank : updatedBeneficiary?.beneficiaryRank || beneficiaryData?.beneficiaryInformation?.beneficiaryRank || '',
          beneficiaryGender: isExistingBeneficiary ? beneficiaryData?.fullData?.gender : updatedBeneficiary?.beneficiaryGender,
          beneficiaryBirthDate: isDependent ? updatedBeneficiary?.beneficiaryDateOfBirth : updatedBeneficiary?.beneficiaryBirthDate || updatedBeneficiary?.birthDate || '',
          mailingAddressOptions: updatedBeneficiary?.mailingAddressOptions || '', // Option to add the address
          liveInSameAddress: isDependent ? updatedBeneficiary?.beneficiaryLiveInSameAddress : updatedBeneficiary?.liveInSameAddress || '',
          beneficiaryAddressLine1: isDependent ? updatedBeneficiary?.data?.person?.data?.correspondences?.[0]?.address?.line1 : updatedBeneficiary?.beneficiaryAddressLine1 || '',
          beneficiaryAddressLine2: isDependent ? updatedBeneficiary?.data?.person?.data?.correspondences?.[0]?.address?.line2 : updatedBeneficiary?.beneficiaryAddressLine2 || '',
          beneficiaryCity: isDependent ? updatedBeneficiary?.data?.person?.data?.correspondences?.[0]?.address?.zipCodeAndCity?.city : updatedBeneficiary?.beneficiaryCity || '',
          beneficiaryState: isDependent ? updatedBeneficiary?.data?.person?.data?.correspondences?.[0]?.address?.state : updatedBeneficiary?.beneficiaryState || '',
          beneficiaryZipCode: isDependent ? updatedBeneficiary?.data?.person?.data?.correspondences?.[0]?.address?.zipCodeAndCity?.zipCode : updatedBeneficiary?.beneficiaryZipCode || '',
          beneficiaryCountry: isDependent ? updatedBeneficiary?.data?.person?.data?.correspondences?.[0]?.address?.country : updatedBeneficiary?.beneficiaryCountry || '',
          isEditable: isDependent ? false : true,
          data: updatedBeneficiary,
          beneficiaryBuid: updatedBeneficiary?.id || updatedBeneficiary?.bUID || updatedBeneficiary?.buid || '',
          beneficiaryCustomKey: updatedBeneficiary?.beneficiaryCustomKey || '',
          beneficiarySelected: true,
          beneficiaryPersonBuid: beneficiaryData?.beneficiaryPersonBuid || '',
        };
        return newBeneficiary;
      };

      if (!state || !action || !action.payload) return;
      const {
        insuredInfo = [],
      } = action.payload;
      const productsListNames = {};
      const newProductsObj = {};
      insuredInfo.forEach(product => {
        const productName = product && product.productName || '';
        const productId = product && product.productId || '';
        const beneficiaries = product && product.beneficiaries || {};
        const coverageBuid = product && product.coverageBuid || '';
        productsListNames[productId] = productName;
        const newBeneficiaries = [];
        beneficiaries && Object.keys(beneficiaries).forEach(beneficiaryKey => {
          newBeneficiaries.push(...beneficiaries[beneficiaryKey]);
        });
        const beneficiariesUpdated = {};
        beneficiariesUpdated[coverageBuid] = newBeneficiaries;
        //Update the just the beneficiary type and percentage, remove beneficiary
        let beneficiaryList = {};
        const productSelection = JSON.parse(JSON.stringify(state[productName] || {}));
        if (Object.keys(productSelection).length > 0) {
          if (!productSelection?.updatedBeneficiary) {
            beneficiaryList = {};
          }
          beneficiaryList = productSelection?.updatedBeneficiary;
        }
        let currentBeneficiariesList = JSON.parse(JSON.stringify(beneficiaryList || {}));
        const newProductObj = {};
        currentBeneficiariesList && Object.keys(currentBeneficiariesList).forEach(current => {
          const newBeneficiariesObj = cloneDeep(currentBeneficiariesList[current]);
          const newBeneficiariesArray = [];
          if (beneficiariesUpdated && beneficiariesUpdated[current] && current === coverageBuid) {
            // Validation to add a new record without having records in the selected product
            if (newBeneficiariesObj.length === 0 && beneficiariesUpdated[current].length > 0) {
              newBeneficiariesArray.push(parseInformation(...beneficiariesUpdated[current]));
            } else {
              const existingBeneficiaries = [];
              newBeneficiariesObj.forEach(beneficiaryObj => {
                if (beneficiaryObj && beneficiaryObj.beneficiaryBuid || beneficiaryObj && beneficiaryObj.beneficiaryCustomKey) {
                  const customKey = beneficiaryObj && beneficiaryObj.beneficiaryCustomKey ? beneficiaryObj && beneficiaryObj.beneficiaryCustomKey : beneficiaryObj && beneficiaryObj.beneficiaryBuid;
                  existingBeneficiaries.push(customKey);
                  const hasBeneficiary = beneficiariesUpdated && beneficiariesUpdated[current].find(key => key.bUID === customKey);
                  if (hasBeneficiary) {
                    beneficiaryObj.beneficiaryType = hasBeneficiary && hasBeneficiary.beneficiaryInformation && hasBeneficiary.beneficiaryInformation.beneficiaryRank;
                    beneficiaryObj.beneficiaryPercentage = hasBeneficiary && hasBeneficiary.beneficiaryInformation && hasBeneficiary.beneficiaryInformation.percentage && parseInt(hasBeneficiary.beneficiaryInformation.percentage);
                    if (hasBeneficiary?.edit) {
                      beneficiaryObj.SSN = hasBeneficiary?.beneficiaryInformation?.sSN || '';
                      beneficiaryObj.beneficiaryAddressLine1 = hasBeneficiary?.beneficiaryInformation?.address?.line1 || '';
                      beneficiaryObj.beneficiaryAddressLine2 = hasBeneficiary?.beneficiaryInformation?.address?.line2 || '';
                      beneficiaryObj.beneficiaryBirthDate = hasBeneficiary?.beneficiaryInformation?.birthDate || '';
                      beneficiaryObj.beneficiaryBuid = '';
                      beneficiaryObj.beneficiaryCity = hasBeneficiary?.beneficiaryInformation?.address?.zipCodeAndCity?.city || '';
                      beneficiaryObj.beneficiaryCountry = hasBeneficiary?.beneficiaryInformation?.address?.country || '';
                      beneficiaryObj.beneficiaryFirstName = hasBeneficiary?.beneficiaryInformation?.firstName || '';
                      beneficiaryObj.beneficiaryGender = hasBeneficiary?.edit ? hasBeneficiary?.data?.beneficiaryGender : hasBeneficiary?.beneficiaryInformation?.title === 'ptMr' ? 'gMale' : 'gFemale';
                      beneficiaryObj.beneficiaryLastName = hasBeneficiary?.beneficiaryInformation?.lastName || '';
                      // beneficiaryObj.beneficiaryLastName2 = '';
                      // beneficiaryObj.beneficiaryMiddleName = '';
                      beneficiaryObj.beneficiaryRelationship = hasBeneficiary?.beneficiaryInformation?.relationToInsured || '';
                      beneficiaryObj.beneficiaryState = hasBeneficiary?.beneficiaryInformation?.address?.line2 || '';
                      beneficiaryObj.beneficiaryZipCode = hasBeneficiary?.beneficiaryInformation?.address?.zipCodeAndCity?.zipCode || '';
                      // beneficiaryObj.legalName = '';
                      // beneficiaryObj.liveInSameAddress = '';
                      // beneficiaryObj.mailingAddressOptions = '';
                      beneficiaryObj.id = hasBeneficiary?.bUID || '';
                    }
                    newBeneficiariesArray.push(beneficiaryObj);
                  }
                }
              });

              // Validation to add a new user
              beneficiariesUpdated && beneficiariesUpdated[current].forEach(newUser => {
                const isNewUser = !existingBeneficiaries.includes(newUser.bUID);
                if (isNewUser) {
                  newBeneficiariesArray.push(parseInformation(newUser));
                }
              });
            }
            newProductObj[current] = newBeneficiariesArray;
          }
        })
        const oldProductsList = newProductsObj && newProductsObj[productId] ? newProductsObj[productId] : {};
        newProductsObj[productId] = { ...oldProductsList, ...newProductObj };
      });

      // Function to update Redux Products Lists
      newProductsObj && Object.keys(newProductsObj).forEach(productKey => {
        const productName = productsListNames[productKey];
        state[productName].updatedBeneficiary = newProductsObj[productKey];
      });

      state.saveBeneficiaries = true;
    },

    SET_SAVE_BENEFICIARIES: (state, action) => {
      state.saveBeneficiaries = action.payload;
    },

    UPDATE_SUBSCRIBED_ELEMENTS: (state, action) => {
      state.manageBeneficiarySubscribeElment = action.payload;
    },

    UPDATE_PRODUCT_LEVEL_ELIGIBILITY: (insuredInfo, action) => {
      const {
        product,
        policyReplacementQuestionEnable,
        disclosureStatement,
      } = action.payload;

      // Check if the product exists in insuredInfo, if not, create an empty object
      if (!insuredInfo[product]) {
        insuredInfo[product] = {};
      }

      // Check if the ProductLevelEligiblity object exists, if not, create an empty object
      if (!insuredInfo[product].ProductLevelEligiblity) {
        insuredInfo[product].ProductLevelEligiblity = {};
      }

      // Update the fields if they exist in the payload
      if (policyReplacementQuestionEnable == "Yes") {
        insuredInfo[product].ProductLevelEligiblity.policyReplacementQuestionEnable = policyReplacementQuestionEnable;
      }

      if (disclosureStatement !== undefined) {
        insuredInfo[product].ProductLevelEligiblity.disclosureStatement = disclosureStatement;
      }
    },



    SHOW_WAIVECOVEAGE_BASEDON_INSUREDQUESTIONS: (insuredInfo, action) => {
      const { product, showWaive } = action.payload;

      // If the product doesn't exist in insuredInfo, create an empty object for it
      insuredInfo[product] = insuredInfo[product] || {};

      // Assign the showWaive value to the product in insuredInfo
      insuredInfo[product].showWaive = showWaive;
    },
    OFFERED_COVERAGE_BUIDS: (insuredInfo, action) => {
      const { product, offeredCoverageBuids } = action.payload;

      // If the product doesn't exist in insuredInfo, create an empty object for it
      insuredInfo[product] = insuredInfo[product] || {};

      // Assign the offeredCoverageBuids value to the product in insuredInfo
      insuredInfo[product].offeredCoverageBuids = offeredCoverageBuids;
    },
    SUBSCRIBE_COVERAGE_BUIDS: (insuredInfo, action) => {
      const { product, coverageBuid, personBuid, perpareSubscriptionForChoices, beneficiaryConfiguration = "", subscribedCoverageConfiguration = "", listOfCoveragePlanDesignConfiguration = "", validateSubscribedCoverageData = "" } = action.payload;

      // If the product doesn't exist in insuredInfo, create an empty object for it
      insuredInfo[product] = insuredInfo[product] || {};

      // Check if coverage exists, if not, create it
      if (!insuredInfo[product].subscribeCoverageBuids) {
        insuredInfo[product].subscribeCoverageBuids = [];
      }

      let existingIndex = insuredInfo[product].subscribeCoverageBuids.findIndex(
        (coverage) => coverage && coverage.personBuid === personBuid && coverage.coverageBuid === coverageBuid
      );

      if (existingIndex === -1) {
        // Add a new coverage detail
        insuredInfo[product].subscribeCoverageBuids.push({
          coverageBuid,
          personBuid,
        });
      }
    },

    SUBSCRIBE_COVERAGE_BUYUP_BUIDS: (insuredInfo, action) => {
      const {
        product,
        coverageBuid,
        personBuid
      } = action.payload;

      // If the product doesn't exist in insuredInfo, create an empty object for it
      insuredInfo[product] = insuredInfo[product] || {};

      // Check if coverage exists, if not, create it
      if (!insuredInfo[product].subscribeCoverageBuidsForBuyUp) {
        insuredInfo[product].subscribeCoverageBuidsForBuyUp = [];
      }

      let existingIndex = insuredInfo[product].subscribeCoverageBuidsForBuyUp.findIndex(
        (coverage) => {
          return coverage && coverage.personBuid === personBuid && coverage.coverageBuid === coverageBuid
        }
      );

      if (existingIndex === -1) {
        // Add a new coverage detail
        insuredInfo[product].subscribeCoverageBuidsForBuyUp.push({
          coverageBuid,
          personBuid,
        });
      }
    },

    ADD_PET_LIST: (insuredInfo, action) => {
      const {
        product,
        petList

      } = action.payload;

      // Check if the product exists in insuredInfo, if not, create an empty object
      if (!insuredInfo[product]) {
        insuredInfo[product] = {};
      }

      // Check if the ProductLevelEligiblity object exists, if not, create an empty array
      if (!insuredInfo[product].petList) {
        insuredInfo[product].petList = [];
      }

      if (Array.isArray(petList)) {
        // Combine the existing pet list with the new pet list
        insuredInfo[product].petList = petList;
      }
    },

    DELETE_PET_LIST: (insuredInfo, action) => {
      const { product, buid } = action.payload;

      const currentPetList = insuredInfo[product]?.petList;

      if (currentPetList) {
        const petIndex = currentPetList.findIndex(pet => pet['coverageId'] === buid);

        // If pet with the matching coverageId is found, remove it
        if (petIndex !== -1) {
          currentPetList.splice(petIndex, 1);
        }

        insuredInfo[product].petList = currentPetList;
      }
    },

    DELETE_FULL_PET_LIST: (insuredInfo, action) => {
      const {
        product,
        petList
      } = action.payload;

      if (insuredInfo[product] && insuredInfo[product]?.petList) {
        insuredInfo[product].petList = [];
      }
    },

    SAVE_QUOTE_RES: (insuredInfo, action) => {

      const {
        product,
        quoteList
      } = action.payload;

      // Check if the product exists in insuredInfo, if not, create an empty object
      if (!insuredInfo[product]) {
        insuredInfo[product] = {};
      }

      // Check if the ProductLevelEligiblity object exists, if not, create an empty object
      if (!insuredInfo[product].quoteList) {
        insuredInfo[product].quoteList = [];
      }


      // let quoteList = quoteList;


      // const newPetList = currentPetList.filter(pet => pet['petBUID'] !== buid);
      // 
      insuredInfo[product].quoteList = [...insuredInfo[product].quoteList, quoteList];
      // insuredInfo[product].quoteList = quoteList;
    },

    UPDATE_BUYUP_SELECTED_VALUES: (insuredInfo, action) => {
      const {
        product,
        values
      } = action.payload;

      insuredInfo[product].buyUpSelectedValues = values
    },

    UPDATE_BASEPLAN_SELECTED_VALUES: (insuredInfo, action) => {
      const {
        product,
        values
      } = action.payload;

      insuredInfo[product].basePlanSelectedValues = values
    },

    // RESET_INSUREDINFO: (insuredInfo, action) => {
    //   Object.keys(initialState).forEach(key => {
    //     insuredInfo[key] = cloneDeep(initialState[key])
    //   });
    // },
    REMOVE_ALERT_MESSAGE: (state, action) => {
      const newAlertMessages = [];
      state.alertMessages = newAlertMessages;
    },
    SELECTED_PRODUCT_LIST_BUIDS: (state, action) => {
      state.selectedProductListBuids = action.payload
    },
    SELECTED_PRODUCT_NAMES: (state, action) => {
      state.selectedProductNames = action.payload
    },
    CLEARED_COVERAGE_WAIVE_INFO: (state) => {
      const productKeys = ['IdTheft', 'CriticalIllness', 'UniversalLife', 'HospitalIndemnity', 'CoverageSelection', 'PetAssureNation', 'PetAssure', 'PetPlus', 'SupplementalHealthInsurance']
      productKeys.forEach(key => {
        state[key].coverageDetails = [];
        state[key].WaiveCoverage = [];
      });
    }
  },
});

export const {
  //SET_INSURED_INFO,
  SET_WAIVE_COVERAGE,
  SET_WAIVE_COVERAGE_NATION,
  SET_WAIVE_COVERAGE_VOYA,
  // SET_QUESTIONS_ANSWERED,
  // SET_EMPLOYEE_ELIGIBILITY,
  UPDATE_ANSWERED_QUESTIONS,
  // SET_FIRST_ACTIVE,
  UPDATE_ANSWERES,
  SET_ANSWER_OPTION,
  ANSWERE_QUESTION,
  // SET_ACTIVE,
  SET_All_INACTIVE,
  SET_ANSWERS_RADIO_STATUS,
  ENABLE_ANSWERS_RADIO_STATUS,
  SET_ELIGIBILITY_MESSAGES,
  SET_DEPENDENT_ELIGIBILITY,
  DISABLE_ANSWERS_RADIO_STATUS,
  SET_EDIT_ANSWERS,
  SKIP_PRODUCT,
  NOT_ELIGIBLE_DEPENDENT,
  SHOWED_ERROR,
  SET_INSURED_INFO_THEFT,
  SET_INSURED_INFO_TRAVEL,
  SET_INSURED_INFO_FIRST_OPTION,
  SET_INSURED_INFO_SECOND_OPTION,
  SET_INSURED_INFO_THIRD_OPTION,
  CHANGE_SCENARIO,
  // SET_INSURED_INFO_LEGAL_COVERAGE,
  // SET_INSURED_INFO_CRITICAL_ILLNESS,
  // SET_INSURED_INFO_PET_PLUS,
  // SET_INSURED_INFO_PET_ASSURE,
  // SET_INSURED_INFO_HOSPITAL,
  // SET_INSURED_INFO_EXCESS_LIABILITY,
  DELETE_INSURED_INFO,
  UPDATE_ELIGIBLE_ANSWERES,
  // DISPLAY_POLICY_QUESTIONS,
  UPDATE_COVERAGE_DETAILS,
  UPDATE_WAIVE_PRODUCT,
  SET_COVERAGE_BUIDS,
  UPDATE_POLICY_REPLACEMENT_QUESTIONS,
  UPDATE_INSURED_QUESTION_ANSWERS,
  //CRITICAL_ILLNESS_GENERAL_TABLE_COVERAGE,
  DISCLOUSRE_RADIO,
  UPDATE_BENEFICIARIES_PRODUCTS,
  UPDATE_BENEFICIARIES_PRODUCT,
  CHECK_ELIGIBILITY,
  UPDATE_PRODUCT_FAMILY_ELIGIBILITY,
  UPDATE_SUBSCRIBED_ELEMENTS,
  UPDATE_PRODUCT_LEVEL_ELIGIBILITY,
  SET_COVERAGE_BENEFICIARY_ELIGIBILITY,
  //UPDATE_BENEFICIARIES,
  UPDATE_INSURED,
  SET_SAVE_BENEFICIARIES,
  SHOW_WAIVECOVEAGE_BASEDON_INSUREDQUESTIONS,
  OFFERED_COVERAGE_BUIDS,
  SUBSCRIBE_COVERAGE_BUIDS,
  SUBSCRIBE_COVERAGE_BUYUP_BUIDS,
  UPDATE_LOADING,
  ADD_PET_LIST,
  DELETE_PET_LIST,
  UPDATE_SUCCESS_MESSAGE,
  UPDATE_ERROR_MESSAGE,
  CLEAR_MESSAGE,
  CLEAR_ALERT_MESSAGES,
  SET_ALERT_MESSAGE,
  UPDATE_ALERT_MESSAGE,
  SAVE_QUOTE_RES,
  DELETE_FULL_PET_LIST,
  UPDATE_BUYUP_SELECTED_VALUES,
  UPDATE_BASEPLAN_SELECTED_VALUES,
  // RESET_INSUREDINFO,
  UPDATE_WAIVE_PRODUCT_WITHOUT_PERSONBUID,
  REMOVE_CURRENTSTEP_ALERT_MESSAGE,
  SET_BENEFICIARIES_TO_UPDATE,
  REMOVE_ALERT_MESSAGE,
  SELECTED_PRODUCT_LIST_BUIDS,
  SELECTED_PRODUCT_NAMES,
  CLEARED_COVERAGE_WAIVE_INFO
} = slice.actions;
export default slice.reducer;