import { combineReducers } from 'redux';
import entitiesReducer from './Entities';
import authReducer from './Auth';
import authTokenReducer from './AuthToken';
import languageReducer from './Language';
import themeReducer from './Theme';
import processReducer from './Process';
import loader from './Loader';
import forgotUserReducer from './ForgotUser';
import registerationErrorReducer from './RegisterationError';
import registerationReducer from './Registeration';
import startTimerReducer from './StartTimer';
import disableButtonReducer from './DisableButton'
import employeeProfileReducer from './EmployeeProfile';
import hideShowRowReducer from './HideShowRow';
import stepFormReducer from './StepForm';
import insuredInfoReducer from './InsuredInfo';
import controlSSNReducer from './ControlSSN'
import callCenterReducer from './CallCenter'
import enrollment from './Enrollment'
import buyUpReducer from './BuyUp'
import buttonReducer from './Atoms/button'
import resumeContent from '../../App/Molecules/CustomSection/Content/ResumeContent.json'
import { replaceDataInState, replaceWelcomeDataInState } from '../../App/Molecules/CustomSection/Helper/MappingHelper'
import stepFormNewReducer from './StepFormNew';
import { merge } from 'lodash';
export const MERGE_STATE = 'enrollment/UPDATE_STATE';




const appReducer = combineReducers({
  entities: entitiesReducer,
  auth: authReducer,
  authToken: authTokenReducer,
  language: languageReducer,
  theme: themeReducer,
  process: processReducer,
  // themeValues: themeValuesReducer,
  loader: loader,
  forgotUser: forgotUserReducer,
  registerationError: registerationErrorReducer,
  registeration: registerationReducer,
  startTimer: startTimerReducer,
  disableButton: disableButtonReducer,
  employeeProfile: employeeProfileReducer,
  hideShowRow: hideShowRowReducer,
  stepForm: stepFormReducer,
  insuredInfo: insuredInfoReducer,
  controlSSN: controlSSNReducer,
  callCenter: callCenterReducer,
  enrollment: enrollment,
  stepFormNew :stepFormNewReducer,
  buyUp: buyUpReducer,
  button: buttonReducer,
});

const rootReducer = (state, action) => {
  if (action.type === MERGE_STATE) {  
   const mergedState = merge({}, state, action.payload.data);
   if (action.payload && action.payload.data) {
      return replaceDataInState(mergedState, action.payload.data, resumeContent.replaceReduxDataForResume);
    }

    return mergedState;
  }
  if (action.type === 'SETINITIALSTATE') {
    return appReducer({
      ...state,
      enrollment: undefined,
      disableButton: undefined,
      hideShowRow: undefined,
      controlSSN: undefined,
      //callCenter: undefined,
      loader: undefined,
      forgotUser: undefined,
      stepForm: undefined,
      registerationError: undefined,
      // registeration: undefined,
      startTimer: undefined,
      employeeProfile: undefined,
      insuredInfo: undefined,
      enrollment: undefined,
      buyUp: undefined
    }, action);
  }

  if (action.type === 'RESET') {
    let filteredState = Object.fromEntries(
      Object
      .entries(state)
      .filter(([key, val]) => key !== 'registeration'  && key !== 'callCenter')
    )
    let registerationState = Object.fromEntries(
      Object
      .entries(state)
      .filter(([key, val]) => key === 'registeration')
    )

    let callCenterState = Object.fromEntries(
      Object
      .entries(state)
      .filter(([key, val]) => key === 'callCenter')
    )

    // const mergedState = { ...state };
    const mergedState = { ...filteredState };

    if (action.type) {
      return {...registerationState, ...callCenterState, ...replaceWelcomeDataInState(mergedState, undefined, resumeContent.replaceReduxDataForWelcome)};
    }

    return mergedState;
  }

  return appReducer(state, action);
};



export default rootReducer;