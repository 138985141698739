import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// import { Linker } from '../../../../Atoms';
import { Linker } from '../../Atoms';
import { useSelector } from 'react-redux';
import { reduxConstants } from '../../../../../Common/Constants/index';
import { getValueFromKeyStringAcceptArray } from '../../../../Utils/Helper/GetValueFromKeyStringAcceptArray';
import './style.css';
import { useDispatch } from 'react-redux';
import { apiReceivedMFA, apiReceivedUserTypeAndLanguage, processTypeForSSO, processTypeMFA, RESET_EMP_FILTER, setProcessType, setSSOLoginError, START_PROXY_ENROLL, userTypeMFA } from '../../../../../Store/Slices/Auth/userAuth';
import { SET_CALL_CENTER_USER, SET_REDIRECT_TO_ENROLLMENT, IS_HR_CENTER_RE_DIRECTED } from '../../../../../Store/Slices/CallCenter';
import { useHistory } from 'react-router-dom';
import { Button } from '../../Atoms';
import imageMap from '../../Helper/imageHelper';
import { getFileName } from '../../Helper/commonHelper';
import { SET_REDIRECT_TO_CLIENT_SEARCH } from '../../../../../Store/Slices/CallCenter';

const Header = (props) => {
  const {
    title,
    rightLink,
    breadcrumbs,
    breadcrumbsStepForm,
    current,
    mappingObject,
    config,
    filename,
    modifyPadding,
  } = props;

  let loggedUserEntity = useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.uiPath)) || {};
  const { userAuth } = useSelector((storeState) => storeState?.auth);
  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  const currentStep = useSelector((state) => state.stepFormNew.currentStep);
  const [finishStatus, setfinishStatus] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [hideBackToHomeButton, setHideBackToHomeButton] = useState(false);
  // Determines the left and right column classes
  const leftClassContainer = config?.leftColumnClass ? config?.leftColumnClass : config?.specialHeaderWidth ? 'col-sm-6 col-lg-6 col-md-12' : 'col-sm-6 col-lg-6';
  const rightClassContainer = config?.rightColumnClass ? config?.rightColumnClass : 'col-sm-6 col-lg-6 align-self-center text-sm-right';

  // Create Linker component and change it
  const isAdmin = useSelector((storeState) => storeState?.callCenter?.isAdmin);
  const isHrCenterReDirected = useSelector((storeState) => storeState?.callCenter?.isHrCenterReDirected);
  const isHrCenter = (isHrCenterReDirected) ? true : false
  const isHrCenterType = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter") ? true : false
  const isHrCenteremployee = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter") && userAuth?.currentProcessType === "SearchEmployee" ? true : false;
  const hrCenterEmployeeSearch = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter" && userAuth?.currentProcessType === "SearchEmployee" && isHrCenter) ? true : false;
  const hrCenterEmployeeData = userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter" && userAuth?.currentProcessType === "EmployeeData";
  const callCenterEmployeeSearch = userAuth?.processType === "callCenter" && userAuth?.currentProcessType === "SearchEmployee";
  const callCenterEmployeeData = userAuth?.processType === "callCenter" && userAuth?.currentProcessType === "EmployeeData";

  const isHrcallCenter = isHrCenterType ? (hrCenterEmployeeSearch || hrCenterEmployeeData) : (callCenterEmployeeSearch || callCenterEmployeeData)
  const links = breadcrumbs.map((breadcrumb, index) => {
    const backToHome = hrCenterEmployeeData ? '/hrcenter/employeeSearch' : breadcrumb.to
    return (
      <li key={index}>
        <Linker to={backToHome}>{breadcrumb.text}</Linker>
      </li>
    );
  });

  links.push(<li key={-1}>{current}</li>);
  callCenterEmployeeSearch && dispatch(SET_REDIRECT_TO_CLIENT_SEARCH(true));
  const classStyle = currentStep === "EmployeeData" ? "col-sm-6 col-lg-6" : "col-4"

  const breadcrumbsLinks = breadcrumbsStepForm.map((breadcrumb, index) => {
    return (
      <li key={index}>
        <div className="col-6">
          {isHrCenteremployee ? <></> : isHrcallCenter ?
            <Linker to={userAuth.currentURL}>{breadcrumb.text}</Linker> : <Linker to={breadcrumb.to}>{breadcrumb.text}</Linker>}
        </div>
      </li>
    );
  });

  breadcrumbsLinks.push(<li key={-1}>{current}</li>);

  let namesArray = [];
  /** Function to get logged username (first, last) from redux, store names in namesArray */
  const getUsername = () => {
    const fieldsLength = mappingObject?.reduxObjectFields?.length;
    for (let i = 0; i < fieldsLength; i++) {
      namesArray[i] = getValueFromKeyStringAcceptArray(loggedUserEntity, mappingObject?.reduxObjectPath, mappingObject?.reduxObjectFields[i])
    }
  };
  getUsername();
  let imgSrc;
  try {
    imgSrc = imageMap[getFileName(filename)];
  } catch (e) {
    imgSrc = '';
  };

  let isProxy = false
  if (userAuth?.processType === "proxyEnrollment") {
    isProxy = true
  }

  const handleBackToCallCenterHome = () => {
    dispatch({
      type: 'SETINITIALSTATE'
    })
    dispatch({
      type: 'RESET'
    })
    dispatch(IS_HR_CENTER_RE_DIRECTED(false))
    history.push(userAuth?.redirectUrl);
  };
  return (
    <>
      {!isLoading &&
        <header className={modifyPadding ? "w-100 px-3 border-bottom sectionHeader " : " w-100 px-3 px-md-5 border-bottom sectionHeader"} style={config?.style} >
          <div className="row">
            <div className={classStyle}>
              {/* <div className={leftClassContainer}> */}
              {filename && links && !isHrCenteremployee && (
                <ul className="breadcrumbs mb-0 pt-2 px-md-0 px-1 ">
                  <li>
                    <img
                      src={imgSrc}
                      className="pr-2"
                      alt="Setting Icon"
                    />
                  </li>
                  {links}
                </ul>
              )}
              {!filename && breadcrumbsStepForm.length > 0 && breadcrumbsLinks && (
                <ul className="breadcrumbs mb-0 pt-2 px-md-0 px-1 ">
                  {breadcrumbsLinks}

                </ul>
              )}
              <h1 className={`title ${config?.className}`} aria-hidden="true" >
                {!userAuth.SSOLoginError ? title : "Access denied : User information is incorrect"}{' '}{namesArray[0]}
              </h1>

            </div>
            <div className="breadcrumbs-left offset-6 col-2 mr-0 pr-0 mb-0 pt-2 px-md-0 px-1" >
              {(isAdmin || isProxy) && <button type="button" class="btn btn-link float-right" onClick={handleBackToCallCenterHome}>
                {isHrCenter ? "HRC Home" : rightLink}
              </button>}
            </div>
          </div>
        </header>
      }
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({ to: PropTypes.string, text: PropTypes.string })),
  breadcrumbsStepForm: PropTypes.array,
  current: PropTypes.string,
  showContent: PropTypes.array,
  sectionHeaderButtons: PropTypes.array,
  sectionElements: PropTypes.array,
  config: PropTypes.object,
  modifyPadding: PropTypes.bool,
  filename: PropTypes.string,
  mappingObject: PropTypes.object,
};

Header.defaultProps = {
  title: '',
  breadcrumbs: [],
  breadcrumbsStepForm: [],
  showContent: [],
  sectionHeaderButtons: [],
  sectionElements: [],
  config: {},
  modifyPadding: false,
  filename: '',
  current: '',
  mappingObject: {},
};

export default Header;
