import http from '../../../../../Common/Services/HttpService';
import appConfig from '../../../../../appConfig';
import { getPersonalInformation } from '../../Actions/getPersonalInformation.js'
import { onLoadAPI } from '../../../../../Store/Slices/Entities/response/index';
import { SET_EMPLOYEE_PROFILE } from '../../../../../Store/Slices/EmployeeProfile';
import { SET_ALERT_MESSAGE } from '../../../../../Store/Slices/InsuredInfo';
import { trimPayload } from '../../Helper/commonHelper.js';

const updateEmployeeInformation = (formik = {}, employeeInformation = {}, buid, currentStep = '') => async (dispatch) => {
  let callcenterBuid = employeeInformation?.buid
  const ssn = formik?.ssn?.replace(/[^\d]/g, '');
  const reqBody = {
    ssn,
    privatePhone: formik?.personalPhone,
    personalEmail: formik?.personalEmail,
    line1: employeeInformation?.line1,
    line2: employeeInformation?.line2,
    zipCode: employeeInformation?.zipCode,
    city: employeeInformation?.city,
    state: employeeInformation?.state,
    country: employeeInformation?.country,
    buid: buid ? buid : callcenterBuid
  }

  await http
    .post(appConfig.SSP_MID_URL + `/v1/employee-profile-submit`, trimPayload(reqBody))
    .then(async (resp) => {
      const data = resp?.data?.data?.getPersonalInformation?.data
      const result = { personalInformation: data }
      
      dispatch(
        onLoadAPI({
          PersonalInformation: result,
          isAPICallInProgress: false,
        })
      );
      dispatch(SET_EMPLOYEE_PROFILE(data));

    })
    .catch((e) => {
      //dispatch(UPDATE_LOADING(false));
      dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: e || '520',
        continue: true,
        id: 'employeeProfileSubmit',
        show: true,
        page: currentStep,
      }));
    });

  // const response = await http.post(
  //   `${appConfig.SSP_MID_URL}/updateEmployeeInformation`, reqBody
  // );
  // if (response.data) {
  //   dispatch(getPersonalInformation(buid));
  // }
};

export default updateEmployeeInformation;

