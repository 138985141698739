import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Linker = (props) => {
  const {
    children,
    className,
    onClick,
    parentButtonAction,
    style,
    target,
    to,
  } = props;

  const handleClick = (event) => {
    if (parentButtonAction) {
      parentButtonAction(event, props.element);
    }
    event.stopPropagation();
    onClick && onClick(event);
  };  

  return (
    <Link
      className={className}
      onClick={ handleClick }
      style={style}
      target={target}
      to={to}
    >
      {children}
    </Link>
  );
};

Linker.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  parentButtonAction: PropTypes.func,
  style: PropTypes.object,
  target: PropTypes.string,
  to: PropTypes.string,
};

Linker.defaultProps = {
  className: '',
  onClick: () => {},
  parentButtonAction: () => {},
  style: {},
  target: '',
  to: '',
};

export default Linker;
