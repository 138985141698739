//create header auth - mfa
export const getBasicAuthHeaderValue = () => {
  let authValue = process.env.REACT_APP_MFA_AUTH_USER;
  // Encoding into base64 using btoa(), which is available in browser environments
  let basicAuthHeaderValue;
  basicAuthHeaderValue = authValue ? btoa(authValue) : '';
  return basicAuthHeaderValue;
};

export const maskEmail = (email) => {
  const emailParts = email.split('@');
  if (emailParts.length !== 2) return email;

  const localPart = emailParts[0];
  const domainPart = emailParts[1];

  const maskedLocal = localPart.length > 2 ?
    localPart.charAt(0) + 'X'.repeat(localPart.length - 2) + localPart.charAt(localPart.length - 1) :
    localPart;

  return `${maskedLocal}@${domainPart}`;
}

export const formatPhone = (phone) => {
  if (!phone) return '';

  let value = phone.replace(/\D/g, '').slice(0, 11);
  value = (value.length === 11 && value.startsWith('1')) ? value.slice(1, 11) : value;

  return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || '';
}