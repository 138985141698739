import React, { useState, useEffect } from "react";
import CustomTable from '../Common/Table';
import Beneficiary from "../Beneficiary/Beneficiary";
import { Button } from '../../../../Atoms';
import { useDispatch, useSelector } from 'react-redux';
import { onLoadAPI, onNextAPI, onLoadProductAPI, UPDATE_COVERAGE_INFO } from '../../../../../Store/Slices/Entities/response';
import ServiceHelper from '../../../../Utils/Helper/MappingHelper';
import { SUBSCRIBE_COVERAGE_BUIDS, UPDATE_LOADING, UPDATE_WAIVE_PRODUCT } from '../../../../../Store/Slices/InsuredInfo';
import { SET_COVERAGE_BUIDS, CHECK_ELIGIBILITY, SET_COVERAGE_BENEFICIARY_ELIGIBILITY, UPDATE_PRODUCT_LEVEL_ELIGIBILITY } from '../../../../../Store/Slices/InsuredInfo/index.js';
import { determineButtonLabel, getCoverageBeneficiaryEligibility, getErrorMessages } from '../../Helper/MappingHelper';
import { getSubscribedCoverageBeneficiaryConfigurationNew } from '../../Actions/getSubscribedCoverageBeneficiaryConfigurationNew.js';
import { getListOfCoveragePlanDesignConfigurationNew } from '../../Actions/getListOfCoveragePlanDesignConfigurationNew.js';
import { getSubscribedCoverageConfigurationNew } from '../../Actions/getSubscribedCoverageConfigurationNew.js';
import { prepareSubscriptionForChoice } from '../../Actions/prepareSubscriptionForChoice.js'
import { unsubscribeCoverage } from "../../Actions/unsubscribeCoverage";
import { updateSubscribedCoverageInformationCI } from "../../Actions/updateSubscribedCoverageInformationCI";
import { validateSubscribedProductData } from "../../Actions/validateSubscribedProductData";
import { continueBtnLabelHelper, defaultMemberDetailsObj, screenNames } from "../../Helper/continueBtnLabelHelper";
import { productSpecificIdentifier } from "../../../../../Common/Constants";
import { coverageProductDetails, unsubscribeCoverageDetails, updateSubscribedCoverageInformation } from '../../Actions/productDetails';
import { useNavigationHook } from '../../Hooks/NavigationHook';
const SpouseCoverage = (props) => {
    const serviceHelper = new ServiceHelper();
    const dispatch = useDispatch();
    const { currentStep,previousStep,formsConfig  } = useNavigationHook();
    let { personBuid = "", coverageDetails = [], displayMode } = props || {}
    const [callApiOneTime, setCallApiOneTime] = useState(false);
    const [prepareApiOneTime, setPrepareApiOneTime] = useState(false);
    const [callApiZero, setCallApiZero] = useState(false);
    const [callApiOne, setCallApiOne] = useState(false);
    const [callApiTwo, setCallApiTwo] = useState(false);
    const [callApiThree, setCallApiThree] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [isBeneficiarySectionValid, setBeneficiarySectionValidBoolean] = useState(false)
    const [isCIAflacProduct, setCIAflacProductBoolean] = useState(false)
    const { step, prevStep, nextStep, onBack, currentBuid, coverageBuid, children, showDisclosureStatement } = props;
    currentBuid && currentBuid(personBuid)
    const [showPolicyReplacementQuestions, setShowPolicyReplacementQuestions] = useState(false);

    const showOrHidePolicyReplacementQues = (personBuid, coverageIndex, isWaiveSelected) => {
        setShowPolicyReplacementQuestions(!isWaiveSelected);
    };
    const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);
    const [disableContinue, setDisableContinue] = useState(true);
    const [totalPercent, setTotalPersent] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [continueBtnText, setContinueBtnText] = useState("Continue");
    const [canContinueBeneficiaries, setCanContinueBeneficiaries] = useState(false);
    const { insuredInfo, process, entities } = useSelector((storeState) => storeState);
    let currentReduxState = useSelector((storeState) => storeState);
    
    const callCenterEmployeeData = useSelector(
        (storeState) => storeState?.callCenter?.callCenterEnrollAsEmployeeLoginData
    );
    let productFamily =formsConfig?.forms?.[currentStep]?.productFamily;
    const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
    let beneficiaryList = []
    if (['Spouse', 'Domestic Partner', 'Civil Union Partner', 'Civil Union/Domestic Partner'].includes(displayMode)) {
        if (insuredInfo?.[currentStep]?.updatedBeneficiaries?.length > 0) {
            let spouseBeneficiaries = insuredInfo?.[currentStep]?.updatedBeneficiaries.filter((o) => {
                return o.hasOwnProperty(personBuid) ? o : "";
            });
            if (spouseBeneficiaries.length > 0) {
                beneficiaryList = spouseBeneficiaries[0]?.[personBuid]
            }
        }
    }
    const insuredInfoCurrentStep = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};
    let loggedUserEntity = Object.keys(callCenterEmployeeData).length === 0 ? currentReduxState.auth.loggedUserEntity : callCenterEmployeeData;
    let enrollmentProcessBuid = currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
    const subscribeCoverageBuids = entities?.response?.onLoadResponseList?.[currentStep]?.subscribeCoverageBuids || [];
    let getDetails = subscribeCoverageBuids.filter((e) => e?.personBuid === personBuid)
    let prepareSubscriptionForChoiseResp = insuredInfoCurrentStep?.prepareSubscriptionForChoice?._Result.filter((item) => item.bUID === coverageBuid)
    let subscribedOption = prepareSubscriptionForChoiseResp?.[0]?.subscribedOption
    const getAllPossibleChoicesConfiguration = currentReduxState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]
    //let subscribedElements = currentReduxState.entities?.response?.onLoadResponseList?.[currentStep]?.ValidateSubscribedProductData?._Result?.contract?.subscribedElements;
    const productCode = getAllPossibleChoicesConfiguration?.coverage?.product?.code;
    let subscribedElements = getDetails?.[0]?.perpareSubscriptionForChoices?._Result || [];
    
    let updatedSubscribedElements = subscribedElements.map((item) => {
        if(subscribedOption) {
            if(item.bUID === coverageBuid) {
                return {
                    ...item,
                    subscribedOption: subscribedOption
                }
            } else {
                return item
            }
        } else {
            return item
        }
    })
    let allowedBeneficiaryKind = ""

    let possibleValues = [];
    //const coverageConfiguration = currentReduxState.entities?.response?.onLoadResponseList?.[`getSubscribedCoverageConfigurationFor${currentStep}`];
    let coverageConfiguration = getDetails?.[0]?.subscribedCoverageConfiguration || ''
    if (coverageConfiguration) {
        // let addintionData = coverageConfiguration.filter(e => e.personBuid === personBuid);
        //possibleValues = addintionData?.[0]?.result?.additionalDatas
        possibleValues = coverageConfiguration?._Result?.additionalDatas;
    }
    let coverageDetailsData = insuredInfo[currentStep]?.coverageDetails?.filter(e => e.personBuid === personBuid);
    let selectedValue = coverageDetailsData?.[0]?.selectedValue
    let waivedCoverage = coverageDetailsData?.[0]?.waivedCoverage;

    let getSubscribedCoverageBeneficiary = getDetails?.[0]?.beneficiaryConfiguration || ''
    let beneficiaryConfigurationDetails = getSubscribedCoverageBeneficiary?._Result?.filter((e) => e.forCoveredPerson?.bUID === personBuid)
    let allowedBeneficiaryDetails = beneficiaryConfigurationDetails?.[0]?.allowedBeneficiaryKind || []
    //let allowedBeneficiaryDetails = getSubscribedCoverageBeneficiary?._Result?.allowedBeneficiaryKind || []
    if (allowedBeneficiaryDetails.length > 0) {
        if (beneficiaryList.length > 0 && allowedBeneficiaryDetails.includes("cDefineBeneficiaries")) {
            allowedBeneficiaryKind = 'cDefineBeneficiaries';
        } else {
            allowedBeneficiaryKind = 'cByWillOrEstate';
        }
    }
    const subscribedProductBuid = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements

    let coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids[0];
    if (props.flowType === 2 || props.flowType === 3) {
        coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids.filter((e) => e?.coveragePersonBuid?.includes(personBuid))[0];
    }

    useEffect(() => {
        if(productCode === productSpecificIdentifier.CI_AFLAC) {
            setCIAflacProductBoolean(true)
        }
    },[productCode])

    useEffect(() => {
        if (coverageDetailsForPrepare && subscribedProductBuid && !prepareApiOneTime) {
            setPrepareApiOneTime(true)

            // const fetchData = async () => {
            //     try {
            //         await dispatch(validateSubscribedProductData({
            //             enrollmentProcessBuid: enrollmentProcessBuid,
            //             productIdentifierBuid: productIdentifierBuid,
            //             currentStep: currentStep,
            //             subscribedElements: subscribedProductBuid,
            //             expectReturn: true
            //         }));

            //         const coverageBuid = await dispatch(prepareSubscriptionForChoice(
            //             enrollmentProcessBuid,
            //             coverageDetailsForPrepare?.coverage,
            //             coverageDetailsForPrepare?.coveragePersonBuid,
            //             currentStep,
            //             personBuid,
            //         ));

            //         if (!coverageBuid) {
            //             throw new Error('coverageBuid not received from prepareSubscriptionForChoice');
            //         }

            //         await dispatch(getSubscribedCoverageBeneficiaryConfigurationNew(
            //             enrollmentProcessBuid,
            //             coverageBuid,
            //             personBuid,
            //             currentStep,
            //         ));

            //         await dispatch(getSubscribedCoverageConfigurationNew(
            //             currentStep,
            //             enrollmentProcessBuid,
            //             coverageBuid,
            //             personBuid
            //         ));

            //         await dispatch(getListOfCoveragePlanDesignConfigurationNew(
            //             currentStep,
            //             enrollmentProcessBuid,
            //             coverageBuid,
            //             personBuid
            //         ));
            //     } catch (error) {
            //         console.error('There was an error!', error);
            //     }
            // }

            const fetchData = async () => {
                dispatch(UPDATE_LOADING(true));
                try {
                  let listOfProduct = subscribedProductBuid?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
                  let subscribedPBuid = listOfProduct?.[0]?.bUID
                  await dispatch(coverageProductDetails({
                    enrollmentProcessBuid: enrollmentProcessBuid,
                    flowType: props.flowType,
                    subscribedProductBuid: subscribedPBuid,
                    personBuid: personBuid,
                    prepareCoverageBuid: coverageDetailsForPrepare?.coverage,
                    prepareCoveredPersonBuid: coverageDetailsForPrepare?.coveragePersonBuid,
                    currentStep: currentStep
                  }));
                } catch (e) {
                  console.error(e)
                } finally {
                  dispatch(UPDATE_LOADING(false));
                }
              }

            fetchData();
        }
    }, [prepareApiOneTime, currentStep, getAllPossibleChoicesConfiguration, insuredInfo, enrollmentProcessBuid, personBuid, props.flowType, coverageDetailsForPrepare, subscribedProductBuid]);

    const continueBtnObj = {
        flowType: props.flowType,
        screenType: screenNames.COVERAGE_SCREEN,
        hasDisclosureStatement: props.showDisclosureStatement,
        isWaivedOffFlag: false,
        spouseCount: props.spouse.length,
        childrenCount: children.length,
        memberIdentifier: "spouse",
        productIdentifierBuid,
        memberDetails: {
            count: props.spouse.length,
            index: 0,
            eligible: true,
            buid: personBuid
        }
    }

    useEffect(() => {
        //page load useeffect
        setContinueBtnText(continueBtnLabelHelper({
            ...continueBtnObj,
            //if no value selected sending null to handle properly inside continueBtnLabelHelper
            isWaivedOffFlag: selectedValue ? waivedCoverage : null
        }));
    }, [])

    const waiveOffCoverageSelected = (isChecked) => {
        // if(isChecked && !children.length && !showDisclosureStatement) {
        //     setContinueBtnText(determineButtonLabel(props, isChecked))
        // } else {
        //     setContinueBtnText("Continue")
        // }
        dispatch(UPDATE_WAIVE_PRODUCT({personBuid, "waiveProduct": isChecked, currentStep}))
        setContinueBtnText(continueBtnLabelHelper({
            ...continueBtnObj,
            //if no value selected sending null to handle properly inside continueBtnLabelHelper
            isWaivedOffFlag: isChecked
        }));
    }

    const nextStepCall = async () => {

        if (!waivedCoverage) {
            dispatch(UPDATE_LOADING(true));
            let externalStepContext = {
                "portalStep": currentStep,
                "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
                "reachedPreConfirmation": "no"
            };
            try {
                await dispatch(updateSubscribedCoverageInformation({
                enrollmentProcessBuid,
                subscribedElements: updatedSubscribedElements,
                productIdentifierBuid,
                beneficiaryList,
                allowedBeneficiaryKind,
                selectedValue,
                possibleValues,
                loggedUserEntity,
                currentStep,
                coverageBuid: getDetails?.[0]?.coverageBuid,
                externalStepContext: externalStepContext,
                isMoveNext: false,
                personBuid
                }));

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(UPDATE_LOADING(false));
            }
            //nextStep();
            // setShowLoader(true);
            // let actionDispatched = await dispatch(updateSubscribedCoverageInformationCI({
            //     enrollmentProcessBuid,
            //     subscribedElements,
            //     productIdentifierBuid,
            //     beneficiaryList,
            //     allowedBeneficiaryKind,
            //     selectedValue,
            //     possibleValues,
            //     loggedUserEntity,
            //     //subscribedCoverageBuid
            // }))
            // if (actionDispatched) {

            //     let externalStepContext = {
            //         "portalStep": currentStep,
            //         "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
            //         "reachedPreConfirmation": "no"
            //     };



            //     dispatch(UPDATE_LOADING(true));
            //     try {
            //         let respObj = await serviceHelper.sendApiRequest('/validateSubscribedCoverageData', {
            //             enrollmentProcessBuid: enrollmentProcessBuid,
            //             coverageBuid: getDetails?.[0]?.coverageBuid,
            //             externalStepContext
            //         });
            //         if (respObj.data && respObj.status === 200) {
            //             // Format the new data
            //             dispatch(SUBSCRIBE_COVERAGE_BUIDS({
            //                 coverageBuid: getDetails?.[0]?.coverageBuid,
            //                 personBuid: personBuid,
            //                 product: currentStep,
            //             }));
            //             dispatch(
            //                 UPDATE_COVERAGE_INFO({
            //                     coverageBuid: getDetails?.[0]?.coverageBuid,
            //                     personBuid: personBuid,
            //                     product: currentStep,
            //                     validateSubscribedCoverageData: respObj.data
            //                 })
            //             );
            //         }
            //         setShowLoader(false);
            //     } catch (e) {
            //         console.error(e)
            //     } finally {
            //         dispatch(UPDATE_LOADING(false));
            //     }
            //     nextStep();
            //     //return true;
            // }
        } else if (waivedCoverage === true) {
            setShowLoader(true);
            //let getDetails = subscribeCoverageBuids.filter((e) => e?.personBuid === personBuid)
            //let subscribedCoverageBuid = getDetails?.[0]?.coverageBuid;
            //let enrollmentProcessBuid = process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
            dispatch(UPDATE_LOADING(true));
            try {
                await dispatch(unsubscribeCoverageDetails({
                    enrollmentProcessBuid: enrollmentProcessBuid,
                      coverageBuid: coverageDetailsForPrepare?.coverage,
                      coveredPerson: coverageDetailsForPrepare?.coveragePersonBuid,
                      subscribedCoverageBuid: getDetails?.[0]?.coverageBuid,
                      isPrepare: false,
                      isMoveNext: false,
                      currentStep: currentStep
                  }, "CreateOrUpdateInsuredCIGeneral", true));
                // let actionDispatched = await dispatch(unsubscribeCoverage({
                //     enrollmentProcessBuid, subscribedCoverageBuid
                // }, "CreateOrUpdateInsuredCIGeneral"));
                // if (actionDispatched) {
                //     setShowLoader(false);
                //     dispatch(onLoadAPI({
                //         CreateOrUpdateInsuredCIGeneral: actionDispatched.data,
                //         isAPICallInProgress: false,
                //     }))
                //     //return true;
                // }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(UPDATE_LOADING(false));
            }
            // if (!children.length && !showDisclosureStatement) {
            //     props.onNext()
            // } else {
            //     nextStep();
            // }
        }
        if (!children.length && !showDisclosureStatement) {
            props.onNext()
        } else {
            nextStep();
        }
    }

    const checkBenificiaryFn = (pstatus, pselectedRows) => {
        let primaryTotal = 0;
        let contingentTotal = 0;
        let hasPrimary = false;
        let hasContingent = false;
        insuredInfo[currentStep]?.updatedBeneficiaries?.forEach(pobj => {
            Object.keys(pobj).forEach((pbeneficiary, pind) => {
                const beneficiary = pobj[pbeneficiary][pind];
                if (beneficiary.beneficiaryType === 'Primary') {
                    primaryTotal += beneficiary.beneficiaryPercentage;
                    hasPrimary = true;
                } else {
                    contingentTotal += beneficiary.beneficiaryPercentage;
                    hasContingent = true;
                }
            })
        });
        let lflag = true;
        if (hasPrimary && primaryTotal !== 100) {
            lflag = false;
        }

        if (hasContingent && contingentTotal !== 100) {
            lflag = false;
        }
        if (pstatus == "success") {
            setTotalPersent(100);
        } else {
            const currentPercent = ((primaryTotal > 0 ? primaryTotal : 0) + (contingentTotal > 0 ? contingentTotal : 0)) * 100 / 200;
            setTotalPersent(0);
        }
        setSelectedRows([...pselectedRows]);
    };
    const totalPolicyQuestionDiff = 0;

    const handleDisableFn = () => {
        if (selectedValue === undefined) {
            setDisableContinue(true);
        } else if (selectedValue === null || selectedValue === "") {
            setDisableContinue(false);
        } else if (!props.beneficiaryEligibility && selectedValue && totalPolicyQuestionDiff === 0) {
            setDisableContinue(false);
        } else {
            const isBeneficiaryValid = (props.isBeneficiaryMandatory && selectedRows?.length && canContinueBeneficiaries)
                || (props.beneficiaryEligibility && (!selectedRows?.length || canContinueBeneficiaries));
            if (isBeneficiaryValid && totalPolicyQuestionDiff === 0) {
                setDisableContinue(false);
            } else {
                setDisableContinue(true);
            }
        }
        // else if (props.beneficiaryEligibility
        //     && !props.isBeneficiaryMandatory
        //     && selectedRows?.length > 0
        //     && totalPercent == 100) {
        //     setDisableContinue(false);
        // }
        // else if (props.beneficiaryEligibility
        //     && !props.isBeneficiaryMandatory && selectedRows?.length == 0
        //     && totalPolicyQuestionDiff == 0) {
        //     setDisableContinue(false);
        // }
        // else if (props.isBeneficiaryMandatory
        //     && totalPercent == 100
        //     && totalPolicyQuestionDiff == 0) {
        //     setDisableContinue(false);
        // }
        // else if (!props.beneficiaryEligibility && totalPolicyQuestionDiff == 0) {
        //     setDisableContinue(false);
        // }
        // else {
        //     setDisableContinue(true);
        // }
    };

    useEffect(() => {
        handleDisableFn();
    }, [selectedValue, totalPercent, selectedRows, totalPolicyQuestionDiff]);

    const hasErrors = getErrorMessages(currentStep);
    
    return (

        <>
            <div className="row" style={{ fontSize: '1.5rem', color: 'rgb(32, 32, 32)', fontFamily: 'NotoSansRegular' }}>
                <div className="">
                    <div className="label col-12 px-3 undefined" style={{ display: 'inline-block' }}>
                        <div className="row">
                            <div className="px-3 py-3">
                                Select Coverage Option for {displayMode}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {coverageDetails && (coverageDetails?.length > 0) || isCIAflacProduct ?
                <CustomTable {...props} isCIAflacProduct={isCIAflacProduct}  selectedValue={selectedValue} waivedCoverage={waivedCoverage} tableData={coverageDetails} showOrHidePolicyReplacementQues={showOrHidePolicyReplacementQues} waiveOffCoverageSelected={waiveOffCoverageSelected} relationship="SP"> </CustomTable> : null}



            {(showPolicyReplacementQuestions || selectedValue) && (props.beneficiaryEligibility) &&
                <Beneficiary
                    currentStep={props.currentStep}
                    personBuid={props.personBuid}
                    allowedBeneficiaryKind={props.allowedBeneficiaryKind}
                    relation={'spouse'}
                    dependentsList={props.dependentsList}
                    updateBeneficiary={checkBenificiaryFn}
                    coverageBuid={getDetails?.[0]?.coverageBuid}
                    setCanContinueBeneficiaries={setCanContinueBeneficiaries}
                    isBeneficiarySectionValidCallback={setBeneficiarySectionValidBoolean}
                ></Beneficiary>
            }
            <div className='button-group'>
                <>
                    <Button type="button" onClick={prevStep} className="mr-3">
                        Back
                    </Button>
                </>
                < Button type="button" onClick={nextStepCall} disabled={disableContinue || hasErrors}
                    className=""
                >
                    {continueBtnText}
                </Button>

            </div>


        </>

    );
};

export default SpouseCoverage;
