import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from '../../../Layout/HRCenter/Dashboard/index';
import Home from '../../../Molecules/HRCenter/Components/Home/Home'
import Login from '../../../Layout/Login/Login';
import LoginScreen from '../../../Molecules/CustomSection/MFA/Login';
import EmployeeSearch from '../../../Layout/CallCenter/EmployeeSearch';
import EmployeeData from '../../../Layout/CallCenter/EmployeeData';
import Reports from '../../../Molecules/HRCenter/Components/Reports/index'
export default [
  //{ path: '/hrcenter', component: Dashboard }
  { path: '/hrcenter', component: Home },
  { path: '/hrcenter/employeeSearch', component: EmployeeSearch },
  { path: '/hrcenter/employeeData', component: EmployeeData },
  { path: '/hrcenter/reports', component: Reports },



];
