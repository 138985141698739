import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'employeeProfile',
  initialState: {
    employeeProfile: {},
    dependentsList: [],
    dependentsInfo: {},
    beneficiariesList: [],
    petList: [],
    petCoverageList: [],
    beneficiaryAdded: false,
    beneficiarySelectId: '',
    beneficiariesSelected: [],
    dependentSelectId: '',
    beneficiaryError: false,
    dependentAdded: false,
    dependentIsDeleted: false,
    dependentIsEdit: false,
    radioSelectedValue: '',
    radioSelectedValueIDTheft: '',
    radioSelectedValueSupplementalHealthInsurance: '',
    radioSelectedValueLegalCoverage: '',
    radioSelectedValueTravel: '',
    radioSelectedValueCriticalIllness: '',
    radioSelectedValuePetNation: -1,
    isYesradioVoyaPremier: '',
    radioSelectedValuePetNationData: [],
    radioSelectedValuePetNationDataArray: [],
    editPetNation: false,
    selectedPetData: "",
    selectedPet: "",
    radioSelectedValuePetPlus: '',
    radioSelectedValuePetAssure: '',
    radioSelectedValueHospitalIndemnity: '',
    underWritingQuestionOne: '',
    underWritingQuestionTwo: '',
    underWritingQuestionThree: '',
    underWritingQuestionFour: '',
    underWritingQuestionFive: '',
    hospitalSection: '',
    radioSelectedValueExcessLiability: '',
    radioSelectedValueExcessLiabilityUmUim: '',
    cartItemForPetNation: {},
    beneficiaryTableList: [],
    cartItemForPetNation: {},
    listOfAllProductCoverages: [],
    dependentsIDTheftCoverageList: [],
    dependentsSupplementalHealthInsuranceCoverageList: [],
    dependentsCoverageList: [],
    dependentsCriticalIllnessCoverageList: [],
    dependentsLegalCoverageList: [],
    dependentsPetAssureList: [],
    dependentsTravelCoverageList: [],
    dependentsPetPlusList: [],
    dependentsHospitalIndemnityCoverageList: [],
    dependentsExcessLiabilityCoverageList: [],
    dependentsExcessLiabilityCoverageListUmUim: [],
    preferredContactDetails: {},
    dependentRelationShip: null,
    dependentsBeneficiaryList: [],
    insuredsAsBeneficiaries: [],
    cartItemList: [],
    cartItemListCoverageLevel: [],
    lifeEvent: {},
    UpdatedBeneficiaries: [],
    enrollmentType: "",
    reasonType: "",
    adminReasonType: "",
    currentYearRason: "",
    livechat_url: "",
    skipNextApiCall: [],
    helpdesk_data: "",
    getGBPResourcesForMember: [],
    curreneBenifitButton: "",
    isDependentModified: false,
    isQLEDependentNotModifed: false,
  },
  reducers: {
    UPDATE_INSUREDS_AS_BENEFICIARIES: (state, action) => {
      state.insuredsAsBeneficiaries = action.payload;
    },
    SET_ADD_DEPENDENT: (state, action) => {
      state.dependentAdded = action.payload;
    },
    SET_EMPLOYEE_PROFILE: (state, action) => {
      state.employeeProfile = action.payload;
    },
    GET_DEPENDENT_LIST: (state, action) => {
      state.dependentsList = action.payload;
      state.dependentAdded = false;
    },
    UPDATE_DEPENDENT_LIST: (state, action) => {
      state.dependentsList = action.payload;
      state.dependentAdded = true;
    },
    UPDATE_DEPENDENT_LIST_REMOVE: (state, action) => {
      state.dependentsList = action.payload;
      state.dependentAdded = false;
    },
    IS_DEPENDENT_REMOVE: (state, action) => {
      state.dependentIsDeleted = action.payload;
    },
    IS_DEPENDENT_EDIT: (state, action) => {
      state.dependentIsEdit = action.payload;
    },
    UPDATE_DEPENDENT_INFORMATION: (state, action) => {
      const dependentInfo = {};
      const dependentBuid = action.payload.dependentBuid;
      dependentInfo[dependentBuid] = action.payload.data;
      state.dependentsInfo = { ...state.dependentsInfo, ...dependentInfo };
    },
    GET_BENEFICIARIES_LIST: (state, action) => {
      state.beneficiariesList = action.payload;
      state.beneficiaryAdded = false;
    },
    ADD_BENEFICIARIES_LIST: (state, action) => {
      const beneficiary = action.payload;
      const relationship = {
        CH: 'Child',
        SP: 'Spouse',
      };
      const beneficiaryCount = state.beneficiariesList.length || 0;
      const isTrust = beneficiary && beneficiary.beneficiaryOptions && beneficiary.beneficiaryOptions === 'trust';
      const beneficiaryObj = {};
      beneficiaryObj['beneficiaryName'] = isTrust ? `${beneficiary.legalName}` : `${beneficiary.beneficiaryFirstName} ${beneficiary.beneficiaryLastName}`;
      beneficiaryObj['beneficiaryPercentage'] = '0';
      beneficiaryObj['beneficiaryType'] = 'Primary';
      beneficiaryObj['beneficiarySelected'] = false;
      beneficiaryObj['beneficiaryRelationship'] = isTrust ? 'Trust/Group/Estate' : relationship[beneficiary.beneficiaryRelationship];
      beneficiaryObj['id'] = isTrust ? `${beneficiary.legalName}-${beneficiaryCount}` : `${beneficiary.beneficiaryFirstName}-${beneficiaryCount}`;
      beneficiaryObj['mainBuid'] = isTrust ? `${beneficiary.legalName}-${beneficiaryCount}` : `${beneficiary.beneficiaryFirstName}-${beneficiaryCount}`;
      beneficiaryObj['data'] = { ...beneficiary };
      beneficiaryObj['isTrust'] = isTrust;
      beneficiaryObj['showActions'] = true;
      beneficiaryObj['isDependent'] = false;
      state.beneficiariesList = [...state.beneficiariesList, beneficiaryObj];
      state.beneficiaryAdded = true;
    },
    UPDATE_BENEFICIARIES_LIST: (state, action) => {
      const currentBeneficiariesList = JSON.parse(JSON.stringify(state.beneficiariesList));
      const newBeneficiariesList = currentBeneficiariesList.filter(beneficiary => beneficiary['beneficiaryBUID'] !== action.payload['beneficiaryBUID']);
      state.beneficiariesList = [...newBeneficiariesList, action.payload];
    },
    UPDATE_BENEFICIARY: (state, action) => {
      const currentBeneficiariesList = JSON.parse(JSON.stringify(state.beneficiariesList));
      const newBeneficiariesList = [];
      currentBeneficiariesList.forEach(beneficiary => {
        if (beneficiary.beneficiaryBUID === action.payload.beneficiaryBUID) {
          beneficiary.beneficiarySelected = action.payload.isChecked;
        }
        newBeneficiariesList.push(beneficiary);
      });
      state.beneficiariesList = newBeneficiariesList;
    },
    SET_DEPENDENT_BENEFICIARY_LIST: (state, action) => {
      const { data, isDependent } = action.payload;
      const currentDependentList = JSON.parse(JSON.stringify(state.dependentsBeneficiaryList));
      const dependentsList = data || [];
      const newDependentsList = [];
      if (dependentsList.length > 0) {
        dependentsList.forEach(dependent => {
          const isDependent = dependent && dependent['isDependent'];
          const hasMiddleName = isDependent ? dependent && dependent.dependentMiddleName ? ` ${dependent.dependentMiddleName}` : '' : dependent && dependent.data && dependent.data.beneficiaryMiddleName ? dependent.data.beneficiaryMiddleName : '';
          const dependentObj = {};
          dependentObj['beneficiaryName'] = isDependent ? `${dependent.dependentFirstName}${hasMiddleName} ${dependent.dependentLastName}` : `${dependent.data.beneficiaryFirstName}${hasMiddleName} ${dependent.data.beneficiaryLastName}`;
          dependentObj['id'] = dependent.id;
          dependentObj['bUID'] = dependent.id;
          dependentObj['mainBuid'] = dependent.mainBuid;
          dependentObj['beneficiaryFirstName'] = isDependent ? dependent.dependentFirstName : dependent.data.beneficiaryFirstName;
          dependentObj['beneficiaryMiddleName'] = isDependent ? dependent.dependentMiddleName : dependent.data.beneficiaryMiddleName;
          dependentObj['beneficiaryLastName'] = isDependent ? dependent.dependentLastName : dependent.data.beneficiaryLastName;
          dependentObj['beneficiaryGender'] = isDependent ? dependent.dependentGender : dependent.data.beneficiaryGender;
          dependentObj['beneficiaryDateOfBirth'] = isDependent ? dependent.dependentDateOfBirth : dependent.data.beneficiaryBirthDate;
          dependentObj['beneficiaryLiveInSameAddress'] = isDependent ? dependent.liveInSameAddress : !!dependent.data.liveInSameAddress;
          dependentObj['beneficiaryRelationship'] = isDependent ? dependent.dependentRelationShip : dependent.beneficiaryRelationship;
          dependentObj['beneficiaryType'] = 'Primary';
          dependentObj['beneficiaryRank'] = 'Primary';
          dependentObj['beneficiaryPercentage'] = '0';
          dependentObj['beneficiaryOptions'] = 'individual';
          dependentObj['beneficiarySelected'] = false;
          dependentObj['showActions'] = isDependent ? false : true;
          dependentObj['isDependent'] = isDependent;
          dependentObj['data'] = dependent.data;
          newDependentsList.push(dependentObj);
        });
        if (currentDependentList.length === 0) {
          state.dependentsBeneficiaryList = newDependentsList;
        } else {
          const newCurrentList = isDependent ? [] : currentDependentList;
          const findNoDependent = currentDependentList.filter(beneficiary => beneficiary.isDependent !== true);
          newDependentsList.forEach(dependent => {
            const findInList = currentDependentList.find(currentDependent => currentDependent.mainBuid === dependent.mainBuid);
            if (findInList && Object.keys(findInList).length > 0) {
              const newFindList = { ...dependent };
              newFindList['beneficiaryType'] = findInList['beneficiaryType'];
              newFindList['beneficiaryPercentage'] = findInList['beneficiaryPercentage'];
              newCurrentList.push(newFindList);
            } else {
              newCurrentList.push(dependent);
            }
          });
          const uniq = Object.values(newCurrentList.reduce((acc, cur) => Object.assign(acc, { [cur.mainBuid]: cur }), {}))
          state.dependentsBeneficiaryList = isDependent ? [...newCurrentList, ...findNoDependent] : uniq;
        }
      }
    },
    UPDATE_DEPENDENT_BENEFICIARY: (state, action) => {
      const currentDependentList = JSON.parse(JSON.stringify(state.dependentsBeneficiaryList));
      const newDependentList = [];
      currentDependentList.forEach(dependent => {
        if (dependent.mainBuid === action.payload.mainBuid) {
          dependent.beneficiarySelected = action.payload.isChecked;
        }
        newDependentList.push(dependent);
      });
      state.dependentsBeneficiaryList = newDependentList;
    },
    UPDATE_DEPENDENT_BENEFICIARY_INFORMATION: (state, action) => {
      const currentDependentList = JSON.parse(JSON.stringify(state.dependentsBeneficiaryList));
      const currentBeneficiaryList = JSON.parse(JSON.stringify(state.beneficiariesList));
      const newDependentList = [];
      const newBeneficiaryList = [];
      const updates = action.payload || [];

      // Method to update Dependents/Beneficiaries
      currentDependentList.forEach(dependent => {
        const newDependent = updates.find(update => update.mainBuid === dependent.mainBuid);
        if (newDependent) {
          // if (dependent.mainBuid === action.payload.id) {
          dependent.beneficiaryPercentage = newDependent.beneficiaryPercentage;
          dependent.beneficiaryType = newDependent.beneficiaryType;
          dependent.beneficiarySelected = newDependent.beneficiarySelected;
        }
        newDependentList.push(dependent);
      });

      // Method to update Beneficiaries Only
      currentBeneficiaryList.forEach(beneficiary => {
        const newBeneficiary = updates.find(update => update.mainBuid === beneficiary.mainBuid);
        if (newBeneficiary) {
          // if (dependent.mainBuid === action.payload.id) {
          beneficiary.beneficiaryPercentage = newBeneficiary.beneficiaryPercentage;
          beneficiary.beneficiaryType = newBeneficiary.beneficiaryType;
          beneficiary.beneficiarySelected = newBeneficiary.beneficiarySelected;
        }
        newBeneficiaryList.push(beneficiary);
      });
      state.dependentsBeneficiaryList = newDependentList;
      state.beneficiariesList = newBeneficiaryList;
    },
    DELETE_DEPENDENT_BENEFICIARY_LIST: (state, action) => {
      const currentDependentBeneficiaryList = JSON.parse(JSON.stringify(state.dependentsBeneficiaryList));
      const newBeneficiariesList = currentDependentBeneficiaryList.filter(beneficiary => beneficiary['mainBuid'] !== action.payload);
      state.dependentsBeneficiaryList = newBeneficiariesList;
    },
    DELETE_BENEFICIARY_LIST: (state, action) => {
      const currentBeneficiariesList = JSON.parse(JSON.stringify(state.beneficiariesList));
      const newBeneficiariesList = currentBeneficiariesList.filter(beneficiary => beneficiary['beneficiaryBUID'] !== action.payload);
      state.beneficiariesList = newBeneficiariesList;
    },
    UPDATE_BENEFICIARY_ERROR: (state, action) => {
      state.beneficiaryError = action.payload;
    },
    RADIO_SELECTED_VALUE: (employeeProfile, action) => {
      employeeProfile.radioSelectedValue = action.payload;
    },
    RADIO_SELECTED_VALUE_ID_THEFT: (employeeProfile, action) => {
      employeeProfile.radioSelectedValueIDTheft = action.payload;
    },
    RADIO_SELECTED_VALUE_SUPPLEMENT_HEALTH_INSURANCE: (employeeProfile, action) => {
      employeeProfile.radioSelectedValueSupplementalHealthInsurance = action.payload;
    },
    RADIO_SELECTED_VALUE_LEGAL_COVERAGE: (employeeProfile, action) => {
      employeeProfile.radioSelectedValueLegalCoverage = action.payload;
    },
    RADIO_SELECTED_VALUE_ID_PETPLUS: (employeeProfile, action) => {
      employeeProfile.radioSelectedValuePetPlus = action.payload;
    },
    RADIO_SELECTED_VALUE_PET_ASSURE: (employeeProfile, action) => {
      employeeProfile.radioSelectedValuePetAssure = action.payload;
    },
    RADIO_SELECTED_VALUE_TRAVEL: (employeeProfile, action) => {
      employeeProfile.radioSelectedValueTravel = action.payload;
    },
    RADIO_SELECTED_VALUE_CRITICAL_ILLNESS: (employeeProfile, action) => {
      employeeProfile.radioSelectedValueCriticalIllness = action.payload;
    },
    RADIO_SELECTED_VALUE_HOSPITAL_INDEMNITY: (employeeProfile, action) => {
      employeeProfile.radioSelectedValueHospitalIndemnity = action.payload;
    },
    RADIO_SELECTED_VALUE_PET_NATION: (employeeProfile, action) => {
      employeeProfile.radioSelectedValuePetNation = action.payload;
    },
    RADIO_SELECTED_VALUE_PET_NATION_DATA: (employeeProfile, action) => {

      employeeProfile.radioSelectedValuePetNationData = action.payload;
    },
    RADIO_SELECTED_VALUE_PET_NATION_DATA_ARRAY: (employeeProfile, action) => {



      //check here if the id is already there then don't push just update it
      employeeProfile.radioSelectedValuePetNationDataArray.push(action.payload);

      // const petCoverage = {
      //   ...action.payload,

      // };
      // employeeProfile.radioSelectedValuePetNationDataArray = [...employeeProfile.radioSelectedValuePetNationDataArray, petCoverage];
    },
    EDIT_PET_NATION: (employeeProfile, action) => {
      employeeProfile.editPetNation = action.payload;
    },
    SELECTED_PET_DATA: (employeeProfile, action) => {
      employeeProfile.selectedPetData = action.payload;
    },

    SELECTED_PET: (employeeProfile, action) => {
      employeeProfile.selectedPet = action.payload;
    },
    RADIO_SELECTED_WHOLE_LIFE: (employeeProfile, action) => {
      employeeProfile.isYesradioVoyaPremier = action.payload;
    },


    RADIO_SELECTED_VALUE_EXCESS_LIABILITY: (employeeProfile, action) => {
      employeeProfile.radioSelectedValueExcessLiability = action.payload;
    },
    RADIO_SELECTED_VALUE_EXCESS_LIABILITY_UM_UIM: (employeeProfile, action) => {
      employeeProfile.radioSelectedValueExcessLiabilityUmUim = action.payload;
    },
    UPDATE_CART_ITEM: (employeeProfile, action) => {

      // if (action?.payload?.moreThanOne == true) {
      //   const payload = action?.payload;
      //   let totalCost = 0;


      //   action?.payload?.coverageList?.map((e) => {

      //     const numAmount = parseFloat(e?.costPerPayPeriod?.substring(1));


      //     totalCost = totalCost + numAmount;

      //   });

      //   employeeProfile.cartItemList =
      //     payload.radioSelectedValue > -1
      //       ? [
      //         ...employeeProfile.cartItemList.filter((obj) => obj.id !== payload.id),
      //         { id: payload.id, name: 'Pet Nationwide', amount: totalCost },
      //       ]
      //       : employeeProfile.cartItemList.filter((e) => e.id !== payload.id);
      // }
      // else if (action.payload.wholeLife == true) {
      //   const payload = action.payload;
      //   let totalCost = 0;

      //   action.payload.coverageList?.map((e) => {

      //     let numAmount = parseFloat(e.costPerPayPeriod.substring(1));

      //     totalCost = totalCost + 0;

      //   });

      //   employeeProfile.cartItemList =
      //     payload.radioSelectedValue > -1
      //       ? [
      //         ...employeeProfile.cartItemList.filter((obj) => obj.id !== payload.id),
      //         { id: payload.id, name: 'Whole Life', amount: 0 },
      //       ]
      //       : employeeProfile.cartItemList.filter((e) => e.id !== payload.id);
      // }
      // else if (action.payload.excessLiability == "ExcessLiability" ||
      //   action.payload.excessLiability == "ExcessLiabilityUim") {
      //   const payload = action.payload;
      //   let amount = 0;
      //   const amount1 =
      //     employeeProfile.radioSelectedValueExcessLiability > -1
      //       ? employeeProfile.dependentsExcessLiabilityCoverageList[employeeProfile.radioSelectedValueExcessLiability]?.costPerPayPeriod
      //       : '';
      //   const amount2 =
      //     employeeProfile.radioSelectedValueExcessLiabilityUmUim > -1
      //       ? employeeProfile.dependentsExcessLiabilityCoverageListUmUim[employeeProfile.radioSelectedValueExcessLiabilityUmUim]?.costPerPayPeriod
      //       : '';
      //   if (amount1) {
      //     amount = amount + parseFloat(amount1?.replace('$', ''))
      //   }
      //   if (amount2) {
      //     amount = amount + parseFloat(amount2?.replace('$', ''))
      //   }
      //   employeeProfile.cartItemList =
      //     payload.radioSelectedValue > -1
      //       ? [
      //         ...employeeProfile.cartItemList.filter((obj) => obj.id !== payload.id),
      //         {
      //           id: payload.id,
      //           name: payload.name,
      //           amount: amount,
      //         },
      //       ]
      //       : employeeProfile.cartItemList.filter((e) => e.id !== payload.id);
      // }
      // else {
      //   const payload = action.payload;
      //   const amount =
      //     payload.radioSelectedValue > -1
      //       ? payload.coverageList[payload.radioSelectedValue]?.costPerPayPeriod
      //       : '';
      //   employeeProfile.cartItemList =
      //     payload.radioSelectedValue > -1
      //       ? [
      //         ...employeeProfile.cartItemList?.filter((obj) => obj.id !== payload.id),
      //         {
      //           id: payload.id,
      //           name: payload.name,
      //           amount: parseFloat(amount?.replace('$', '')),
      //         },
      //       ]
      //       : employeeProfile.cartItemList?.filter((e) => e.id !== payload.id);

      // }
    },
    GET_INSURED_ADDITIONAL_DATA_ISDISABLED: (state, action) => {
      state.insuredAdditionalDataIsDisabled = action.payload;
    },
    UNDER_WRITING_QUESTION_ONE: (employeeProfile, action) => {
      employeeProfile.underWritingQuestionOne = action.payload;
    },
    UNDER_WRITING_QUESTION_TWO: (employeeProfile, action) => {
      employeeProfile.underWritingQuestionTwo = action.payload;
    },
    UNDER_WRITING_QUESTION_THREE: (employeeProfile, action) => {
      employeeProfile.underWritingQuestionThree = action.payload;
    },
    UNDER_WRITING_QUESTION_FOUR: (employeeProfile, action) => {
      employeeProfile.underWritingQuestionFour = action.payload;
    },
    UNDER_WRITING_QUESTION_FIVE: (employeeProfile, action) => {
      employeeProfile.underWritingQuestionFive = action.payload;
    },
    HOSPITAL_SECTION: (employeeProfile, action) => {
      employeeProfile.hospitalSection = action.payload;
    },

    GET_PET_LIST: (state, action) => {
      state.petList = action.payload;
      state.petAdded = false;
    },

    ADD_PET_LIST: (state, action) => {

      const petCount = state.petList.length || 0;
      const newPetId = `${action.payload.petName}-${petCount}`;


      const petObj = {
        ...action.payload,
        petBUID: newPetId,
      };
      state.petList = [...state.petList, petObj];
      state.petAdded = true;
    },

    UPDATE_PET_LIST: (state, action) => {
      const currentPetList = JSON.parse(JSON.stringify(state.petList));


      let currentIndex = 0;
      for (let i = 0; i < currentPetList.length; i++) {
        const pet = currentPetList[i];



        if (pet['petBUID'] == action.payload['petBUID']) {
          currentIndex = i;

          break;
        }
      }


      const newPetList = currentPetList.filter(pet => pet['petBUID'] != action.payload['petBUID']);
      newPetList.splice(currentIndex, 0, action.payload);
      // const newPetList = currentPetList.filter(pet => pet['editBUID'] == null);

      state.petList = [...newPetList];
    },
    GET_LIST_OF_ALL_PRODUCT_COVERAGES: (state, action) => {
      state.listOfAllProductCoverages = action.payload
    },

    PRODUCT_TABLE_COVERAGES_DATA: (state, action) => {
      state.dependentsIDTheftCoverageList = action.payload || [];
      state.dependentsSupplementalHealthInsuranceCoverageList = action.payload || [];
      state.dependentsCoverageList = action.payload || [];
      state.dependentsCriticalIllnessCoverageList = action.payload || [];
      state.dependentsLegalCoverageList = action.payload || [];
      state.dependentsTravelCoverageList = action.payload || [];
      state.dependentsPetAssureList = action.payload || [];
      state.dependentsPetPlusList = action.payload || [];
      state.dependentsHospitalIndemnityCoverageList = action.payload || [];
      state.dependentsExcessLiabilityCoverageList = action.payload || [];
      state.dependentsExcessLiabilityCoverageListUmUim = action.payload || [];
    },
    DELETE_PET_LIST: (state, action) => {

      const currentPetList = JSON.parse(JSON.stringify(state.petList));


      const newPetList = currentPetList.filter(pet => pet['petBUID'] !== action.payload);

      state.petList = newPetList;
    },

    DELETE_FULL_PET_LIST: (state, action) => {

      state.petList = [];
    },
    LIFE_EVENT: (employeeProfile, action) => {
      employeeProfile.lifeEvent = action.payload;
    },
    UPDATE_UPDATED_BENEFICIARIES: (state, action) => {
      if (!action || !action.payload || !action.payload.name || !action.payload.data) {
        return;
      }

      const index = state.UpdatedBeneficiaries.findIndex(
        (item) => Object.keys(item)[0] === action.payload.name
      );

      if (index !== -1) {
        // Replace the existing object with the updated data
        state.UpdatedBeneficiaries[index] = { [action.payload.name]: action.payload.data };
      } else {
        // Add a new object with the provided name and data
        state.UpdatedBeneficiaries.push({ [action.payload.name]: action.payload.data });
      }
    },

    DEPENDENT_REPLATIONSHIP: (state, action) => {
      state.dependentRelationShip = action.payload || []
    },

    UPDATE_PREFERRED_CONTACT: (state, action) => {
      state.preferredContactDetails = action.payload
    },

    VOYA_API_RESPONSE: (employeeProfile, action) => {
      employeeProfile.voyaAPIResponse = action.payload;
    },
    VOYA_RADIO_SELECT: (employeeProfile, action) => {
      employeeProfile.voyaRadioSelect = action.payload;
    }, 
    ENROLLMENT_TYPE: (state, action) => {
      state.enrollmentType = action.payload?.enrollmentType || "";
      state.reasonType = action.payload?.reasonType || "";
      state.adminReasonType = action.payload?.adminReasonType || "";
      state.currentYearRason = action.payload?.currentYearRason || "";
    },
    LIVECHAT_URL: (state, action) => {
      state.livechat_url = action.payload;
    },
    SET_SKIP_NEXT_API_CALL: (stepForm, action) => {
      let newValue = [...stepForm.skipNextApiCall, action.payload];
      let unique = newValue.filter((value, index, array) => array.indexOf(value) === index);
      stepForm.skipNextApiCall = unique
    },
    UPDATE_CART_ITEM_NEW_BUYUP: (employeeProfile, action) => {
      if (employeeProfile.cartItemList === 'undefined') {
        employeeProfile.cartItemList = []
      } else {
        let currentCart = employeeProfile.cartItemList;
        let index = currentCart.findIndex((item) => item.id === action.payload?.id)
        if (action.payload?.removeProduct) {
          currentCart.splice(index, 1);
        } else {
          if (index >= 0) {
            currentCart[index].amount = action.payload?.cost;
          } else {
            currentCart = [
              ...currentCart,
              {
                id: action.payload?.id,
                amount: action.payload?.cost,
                name: action.payload?.name
              }]
          }
        }
        employeeProfile.cartItemList = currentCart
      }
    },
    UPDATE_CART_ITEM_NEW: (employeeProfile, action) => {
      if (action.payload.moreThanOne == true) {
        let declineProduct = action.payload?.declineProduct || false
        const payload = action.payload;
        let totalCost = 0;
        let isWaived = action.payload.coverageList?.every(element => element.waivedCoverage === true)
        action.payload.coverageList?.map((e) => {

          if (employeeProfile.cartItemList === 'undefined') {
            employeeProfile.cartItemList = []
          }
          if (e.waivedCoverage === false && e.costPerPayPeriod) {
            // if (e.costPerPayPeriod) {
            let numAmount = ""
            if (typeof e.costPerPayPeriod === "string") {
              numAmount = parseFloat(e?.costPerPayPeriod?.substring(1));
            } else {
              numAmount = parseFloat(e?.costPerPayPeriod);
            }


            totalCost = totalCost + numAmount;
          }
        });

        employeeProfile.cartItemList =
          isWaived === false && !declineProduct
            ? [
              ...employeeProfile.cartItemList?.filter((obj) => obj.id !== payload.id),
              { id: payload.id, name: action.payload.name, amount: totalCost },
            ]
            : employeeProfile.cartItemList?.filter((e) => e.id !== payload.id);
      }
    },
    HELPDESK_DETAILS: (state, action) => {
      state.helpdesk_data = action.payload;
    },
    GBP_RESOURCES_FOR_MEMBER: (state, action) => {
      state.getGBPResourcesForMember = action.payload || []
    },
    UPDATE_CART_ENROLLMENT: (employeeProfile, action) => {
      employeeProfile.cartItemList = action.payload || []
    },
    UPDATE_CART_ENROLLMENT_COVERAGE_LEVEL: (employeeProfile, action) => {
      employeeProfile.cartItemListCoverageLevel = action.payload || []
    },
    WELCOME_SCREEN_WIDGET_BUTTON: (employeeProfile, action) => {
      employeeProfile.curreneBenifitButton = action.payload || []
    },
    RESET_COSTBASKET: (employeeProfile, action) => {
      employeeProfile.cartItemList = []
    },
    IS_DEPENDENT_MODIFY: (state, action) => {
      state.isDependentModified = action.payload
    },
    IS_QLE_DEPENDENT_NOT_MODIFIED: (state, action) => {
      state.isQLEDependentNotModifed = action.payload
    }
  },
});

export const {
  UPDATE_INSUREDS_AS_BENEFICIARIES,
  UPDATE_DEPENDENT_LIST,
  UPDATE_DEPENDENT_INFORMATION,
  GET_DEPENDENT_LIST,
  RADIO_SELECTED_VALUE,
  UPDATE_DEPENDENT_LIST_REMOVE,
  SET_EMPLOYEE_PROFILE,
  RADIO_SELECTED_VALUE_ID_THEFT,
  RADIO_SELECTED_VALUE_SUPPLEMENT_HEALTH_INSURANCE,
  RADIO_SELECTED_VALUE_LEGAL_COVERAGE,
  RADIO_SELECTED_VALUE_ID_PETPLUS,
  RADIO_SELECTED_VALUE_PET_ASSURE,
  RADIO_SELECTED_VALUE_TRAVEL,
  RADIO_SELECTED_VALUE_CRITICAL_ILLNESS,
  RADIO_SELECTED_VALUE_HOSPITAL_INDEMNITY,
  RADIO_SELECTED_VALUE_PET_NATION,
  RADIO_SELECTED_VALUE_PET_NATION_DATA,
  RADIO_SELECTED_VALUE_PET_NATION_DATA_ARRAY,
  EDIT_PET_NATION,
  RADIO_SELECTED_VALUE_EXCESS_LIABILITY,
  RADIO_SELECTED_VALUE_EXCESS_LIABILITY_UM_UIM,
  UPDATE_CART_ITEM,
  GET_INSURED_ADDITIONAL_DATA_ISDISABLED,
  GET_BENEFICIARIES_LIST,
  UPDATE_BENEFICIARIES_LIST,
  UNDER_WRITING_QUESTION_ONE,
  UNDER_WRITING_QUESTION_TWO,
  UNDER_WRITING_QUESTION_THREE,
  UNDER_WRITING_QUESTION_FOUR,
  UNDER_WRITING_QUESTION_FIVE,
  HOSPITAL_SECTION,
  ADD_BENEFICIARIES_LIST,
  DELETE_BENEFICIARY_LIST,
  ADD_PET_LIST,
  SELECTED_PET_DATA,
  UPDATE_PET_LIST,
  DELETE_PET_LIST,
  DELETE_FULL_PET_LIST,
  SELECTED_PET,
  UPDATE_BENEFICIARY,
  UPDATE_DEPENDENT_BENEFICIARY,
  UPDATE_DEPENDENT_BENEFICIARY_INFORMATION,
  UPDATE_BENEFICIARY_ERROR,
  IS_DEPENDENT_REMOVE,
  IS_DEPENDENT_EDIT,
  SET_DEPENDENT_BENEFICIARY_LIST,
  RADIO_SELECTED_WHOLE_LIFE,
  GET_LIST_OF_ALL_PRODUCT_COVERAGES,
  PRODUCT_TABLE_COVERAGES_DATA,
  LIFE_EVENT,
  UPDATE_UPDATED_BENEFICIARIES,
  DELETE_DEPENDENT_BENEFICIARY_LIST,
  UPDATE_PREFERRED_CONTACT,
  VOYA_API_RESPONSE,
  DEPENDENT_REPLATIONSHIP,
  ENROLLMENT_TYPE,
  SET_ADD_DEPENDENT,
  LIVECHAT_URL,
  SET_SKIP_NEXT_API_CALL,
  UPDATE_CART_ITEM_NEW,
  UPDATE_CART_ITEM_NEW_BUYUP,
  HELPDESK_DETAILS,
  GBP_RESOURCES_FOR_MEMBER,
  UPDATE_CART_ENROLLMENT,
  UPDATE_CART_ENROLLMENT_COVERAGE_LEVEL,
  WELCOME_SCREEN_WIDGET_BUTTON,
  RESET_COSTBASKET,
  IS_DEPENDENT_MODIFY,
  IS_QLE_DEPENDENT_NOT_MODIFIED,
  VOYA_RADIO_SELECT
} = slice.actions;

export default slice.reducer;