import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import NotFound from '../../Cells/NotFound/NotFound';
import LandingPage from '../../Layout/LandingPage/LandingPage';

import Header from '../../Cells/Header';
import Footer from '../../Cells/Footer';
import PrivateRoute from './PrivateRoute';
import PrivateRouteNoLogin from './PrivateRouteNoLogin';
import {




  LoginRoutes,

  EnrollmentRoutes,
  ConfiguratorRoutes,
  CallCenterRoutes,
  EmployeeRegistrationRoutes,
  UserRegistrationRoutes,
  FooterRoutes,
  UserInformationRoutes,
  UserPasswordRoutes,
  ConfirmationScreenRoutes,
  ChangeMFAContact,
  HRCenterRoutes,
} from './UserRoutes/index';
import PrivateRouteStaticPages from './PrivateRouteStaticPages';
import MultiFactorAuthenticationRoutes from './UserRoutes/MultiFactorAuthenticationRoutes';
import ManageProfileRoutes from './UserRoutes/ManageProfileRoutes';

export const AppRoutes = () => (

  <>
    {/* <Header /> */}
    <main>
      <div className="main-wrapper">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          {/* <Route exact path="/*" component={LandingPage} /> */}
          {EnrollmentRoutes.map((entry, index) => {
            return <PrivateRoute key={index} exact {...entry} />;
          })}
          {ConfiguratorRoutes.map((entry, index) => {
            return <PrivateRoute key={index} exact {...entry} />;
          })}
          {CallCenterRoutes.map((entry, index) => {
            return <PrivateRoute key={index} exact {...entry} />;
          })}
          {HRCenterRoutes.map((entry, index) => {
            return <PrivateRoute key={index} exact {...entry} />;
          })}
          {EmployeeRegistrationRoutes.map((entry, index) => {
            return <PrivateRoute key={index} exact {...entry} />;
          })}
          {LoginRoutes.map((entry, index) => {
            return <PrivateRouteNoLogin hideHeader={true} key={index} exact {...entry} />;
          })}
          {UserRegistrationRoutes.map((entry, index) => {
            return <PrivateRouteNoLogin hideHeader={true} key={index} exact {...entry} />;
          })}
          {MultiFactorAuthenticationRoutes.map((entry, index) => {
            return <PrivateRouteNoLogin hideHeader={true} key={index} exact {...entry} />;
          })}
          {ManageProfileRoutes.map((entry, index) => {
            return <PrivateRouteNoLogin hideHeader={false} key={index} exact {...entry} />;
          })}



          {FooterRoutes.map((entry, index) => {
            return <PrivateRouteStaticPages key={index} exact {...entry} />;
          })}
          {UserInformationRoutes.map((entry, index) => {
            return <PrivateRouteNoLogin hideHeader={false} key={index} exact {...entry} />;
          })}
          {ConfirmationScreenRoutes.map((entry, index) => {
            return <PrivateRouteNoLogin hideHeader={true} key={index} exact {...entry} />;
          })}
          {UserPasswordRoutes.map((entry, index) => {
            return <PrivateRouteNoLogin hideHeader={false} key={index} exact {...entry} />;
          })}
          {/* {ChangeMFAContact.map((entry, index) => {
          return <PrivateRoute key={index} exact {...entry} />;
        })} */}
          {/* <Route path="*" component={LandingPage} /> */}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
      {/* <Footer /> */}
    </main>
  </>
);
