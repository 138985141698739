import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.header {
  background: ${({ theme }) =>
    theme.components && theme.components.header && theme.components.header.bgColor}; 
  border-bottom: 1px solid ${({ theme }) =>
    theme.components && theme.components.header && theme.components.header.headerBorderColor};
  padding: 0.5rem 0.5rem;
  min-height: 4rem;
}
.header .logo {
  vertical-align: middle;
}

.header .primaryNav {
  text-align: right;
  vertical-align: middle;
  margin-top: 0.5rem;
}
.header .primaryNav li {
  display: inline;
  margin-left: 2.5rem;
  font-family: roboto;
}
.header .primaryNav li a {
  color: #595959;
  font-size: 16px;
  vertical-align: middle;
  font-family: roboto;
}
.header .primaryNav li a:hover {
  color: #0096d6;
  text-decoration: none;
}
.header .primaryNav li a.active {
  color: #0096d6;
}
.header .clearfix {
  clear: both;
}

.header .header-grid {
  margin: 0;
}

@media (min-width: 992px) {
  .header .header-grid {
    margin: -0.5rem -5rem;
  }
}

@media only screen and (min-width: 992px) and (orientation: landscape) {
  .header .header-grid {
    margin: 0;
  }
}

@media only screen and (min-width: 992px) and (orientation: portrait) {
  .header .header-grid {
    margin: 0;
  }
}

#navbarDropdown.dropdown-toggle::after {
  display: none;
}

.userIcon {
  padding-right: 0;
  padding-left: 0;
  margin-left: 1.875em;
  max-width: fit-content;
  weight: light;
  align-self:end;
}
.emailIcon {
  padding-right: 0;
  padding-left: 0;
  margin-left: 2.375em;
  max-width: fit-content;
}

.alertIcon {
  padding-right: 0;
  padding-left: 0;
  margin-left: 1.75em;
  max-width: fit-content;
}

.landingPageFlagIcon {
  width: 1.3125rem;
  height: 1.3125rem;
  max-width: none;
}

@media (max-width: 992px) {
  .landingPageFlagIcon {
    width: 2.125rem;
    height: 2.125rem;
  }
}

.applicationFlagIcon {
  width: 1.4375rem;
  height: 1.4375rem;
  max-width: none;
}

.landingPageFlagIconFunctionality {
  pointer-events: none;
  margin-top: 0.25rem;
}

@media (max-width: 992px) {
  .landingPageFlagIconFunctionality {
    pointer-events: auto;
    margin-top: 0rem;
  }
}

.applicationPageFlagIconFunctionality {
  pointer-events: none;
  margin-top: 0.19rem;
}

@media (max-width: 540px) {
  .applicationPageFlagIconFunctionality {
    pointer-events: auto;
    margin-top: 0.08rem;
  }
}

@media only screen and (max-width: 768px) { 
  .langaugeComponent {
   width:100%
  }
}

.langaugeComponent {
  padding-top: 1.1rem;
}

.themeComponent {
  padding-top: 1.1rem;
}

.selectContainer .language {
  min-width: max-content !important;
  border-bottom: none;
  color: ${({ theme }) =>
    theme.components && theme.components.header && theme.components.header.dropDownColor};
}

.selectContainer .theme {
  min-width: max-content !important;
  border-bottom: none;
  color: ${({ theme }) =>
    theme.components && theme.components.header && theme.components.header.dropDownColor}; ;
}

.landingPageLinks {
  font-size: 0.875em;
  font-weight: 500;
  white-space: nowrap;
 // padding-top: 2em;
  max-width: fit-content;
}

.grayLink {
  color: #333333;
}

.clientLogoa {
  max-width: none;
  width: 14.5rem;
  height: 2.5rem;
  cursor: pointer;
}
.clientLogo {
  // max-width: 4.8rem;
  // height: 2.5rem;
  cursor: pointer;
  vertical-align: middle;
  // max-height: 3rem;
  max-height: 40px
}
.full-width{
  width:100% !important;
}
.headerTitle {
  font-size: 1.5rem;
  opacity: 1;
  white-space: nowrap;
  color: #202020 ;
  padding-left: 1.5rem;
}
.headerDevider {
  margin-bottom: 0.5rem;
  color: #009DE0 ;
  width: 5px;
}
.loggedUser {
  font-size: 1rem;
  font-family:  NotoSansMedium; 
  white-space: nowrap;
  // margin-bottom: 2rem;
  justify_content: start;
}
.dotsMenu {
  weight: light
}
.dropdown-sub-item {
  color: #00000033;
  font-size: 1rem;
  padding-left: 5rem;
}
.dropdown-item {
  color: #002C77 ;
  font-size: 1rem;
}

@media (max-width: 767px) {
  .header {
    padding-top: 1rem !important;
    padding-bottom: 0.75rem !important;
  }
  
  .leftContainer {
    flex-flow: column !important;
  }
  .dividerContainer {
    display: none !important;
  }
  .headerTitle {
    font-size: 1rem !important;
    margin-left:50%;
    margin-top:6%
  }
}

.headerLogin {
  // padding-top: 1.5rem;
  // padding-bottom: 1.5rem;
}
.afterLogin {
  // padding-top: 0.5rem;
  // padding-bottom: 0.5rem;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  align-items: center;
  flex-flow: row;
}
.dividerContainer {
  align-items: center;
  margin: 0px 22px;
}
.divider {
  max-width: 14.4rem;
  width: 1rem;
  height: 2.5rem;
  cursor: pointer;
  min-width: 2px;
  max-width:2px;
  background-color: #009DE0;
  margin: 0 0 1 1 ;
  height: 1.75rem;
  visibility: visible;
}
.titleContainer > .row {
  margin: 0px;
}
@media (max-width: 991px) {
  .header {
    padding-top: 1rem !important;
    padding-bottom: 0.75rem !important;
  }
}
@media (max-width: 767px),  (max-width: 990px) {
  .headerLogin {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .afterLogin {
    padding-top: 0rem;
    padding-bottom: 0.5rem;
  }
  
  
}
@media   (max-width: 414px) {
  .header {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0rem !important;
  }
}
@media   (max-width: 576px) {
  .header {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
.menuLogo {
  max-width: 4.8rem;
  max-height: 2.5rem;
  cursor: pointer;
  vertical-align: middle;
  display:none;
}
  //Mobile
  @media only screen and (max-width: 576px) {

  .menuLogo {
    display:block;
  }
  .menuLogoWrapper {
    justify-content: start;
    padding-left: 1rem;
    padding-top: 0.5rem
  }
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 0rem 3rem;
  }
}
.yesButton {
  color: #002C77
}
.cancelButton {
  color: #FFFFFF
}
`;
