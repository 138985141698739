import React from 'react';
import Pdf from '../../../../Assets/Files/California_Resident_Privacy_Notice.pdf';

const PdfComponent = () => {
    let width = document.body.clientWidth
    let height = window.innerHeight;
    return(
        <object 
            data={Pdf} 
            type="application/pdf"  
            width={ width  } 
            height= {height}>
        </object>
    );
}
export default PdfComponent