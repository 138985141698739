import React, { memo } from 'react';
import Modal from '../Modal';


const ModalContainer = memo((props) => {
  const {
    modals = [],
	content, 
	formik = {},
	formName,
	onCloseModal = () => {},
	modalTitle = '',
  } = props;

  return (
    <div>
    {modals.length > 0 && modals.map((modal) => {
		let modal_Title = '';
		if (modal && modal.modalName === "videoProductSelection") {
			modal_Title = modal.title;
		}
		
	  const modalElements = modal?.modalElements?.flat();
	
			return (
				<Modal
					key={`modal-${modal.modalName}`}
					content={content}
					formik={formik}
					formName={formName}
					onClose={onCloseModal}
					modelData={{
						elements: modalElements,
						modalId: modal.modalName,
						modalTitle: modal_Title ? modal_Title : modalTitle ? modalTitle : modal.title,
						modalDisclaimer: modal.disclaimer,
						modalDescription: modal.description,
						productSelection: modal.productSelection,
						modalDisplayDependentElement: modal.modalDisplayDependentElement,
						modalDialog: 'modalDialog',
						modalSrc:modal.src,
						elementsKeyToDisplay: modal.elementsKeyToDisplay,
						elementsToDisplay: modal.elementsToDisplay,
						excludeValidations: modal.excludeValidations,
					}}
				/>

			);
    })}
  </div>
  );
});

export default ModalContainer;