import React, { useState, useEffect } from "react";
import CustomTable from '../Common/Table';
import Beneficiary from "../Beneficiary/Beneficiary";
import { Button } from '../../Atoms/index';
import { useDispatch, useSelector } from 'react-redux';
import { onLoadAPI, onNextAPI, onLoadProductAPI, UPDATE_COVERAGE_INFO } from '../../../../../Store/Slices/Entities/response';
import ServiceHelper from '../../../../Utils/Helper/MappingHelper';
import { SUBSCRIBE_COVERAGE_BUIDS, UPDATE_COVERAGE_DETAILS, UPDATE_LOADING, UPDATE_WAIVE_PRODUCT } from '../../../../../Store/Slices/InsuredInfo';
import { SET_COVERAGE_BUIDS, CHECK_ELIGIBILITY, SET_COVERAGE_BENEFICIARY_ELIGIBILITY, UPDATE_PRODUCT_LEVEL_ELIGIBILITY } from '../../../../../Store/Slices/InsuredInfo/index.js';
import { determineButtonLabel, getCoverageBeneficiaryEligibility } from '../../Helper/MappingHelper';
import { getSubscribedCoverageBeneficiaryConfigurationNew } from '../../Actions/getSubscribedCoverageBeneficiaryConfigurationNew.js';
import { getListOfCoveragePlanDesignConfigurationNew } from '../../Actions/getListOfCoveragePlanDesignConfigurationNew.js';
import { getSubscribedCoverageConfigurationNew } from '../../Actions/getSubscribedCoverageConfigurationNew.js';
import { prepareSubscriptionForChoice } from '../../Actions/prepareSubscriptionForChoice.js'
import { unsubscribeCoverage } from "../../Actions/unsubscribeCoverage";
import { updateSubscribedCoverageInformationCI } from "../../Actions/updateSubscribedCoverageInformationCI";
import { validateSubscribedProductData } from "../../Actions/validateSubscribedProductData";
import PolicyReplacementQuestions from '../../Container/PolicyQuestionsWrapper.js';
import SurplusFeeWrapper from '../../Container/SurplusFeeWrapper.js';
import { continueBtnLabelHelper, screenNames } from "../../Helper/continueBtnLabelHelper";
import { coverageProductDetails, getProductConfig, unsubscribeCoverageDetails, updateSubscribedCoverageInformation } from '../../Actions/productDetails';
import { useNavigationHook } from '../../Hooks/NavigationHook';
import { formatDisplayValueForCoverageAmount } from "../../../../Utils/Helper/ValidationHelper";

import { getErrorMessages } from '../../Helper/MappingHelper';
export const productIdentifier = {
  'excessLiability': "Liability"
}

const CoverageFlowFour = (props) => {

  const serviceHelper = new ServiceHelper();
  const dispatch = useDispatch();
  const { currentStep, previousStep, formsConfig } = useNavigationHook();
  let { personBuid = "", coverageDetails = [], displayMode, coverageOption, coverageIndex, coverageTableCount } = props || {}
  const [prepareApiOneTime, setPrepareApiOneTime] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { step, prevStep, stepsCount, nextStep, onBack, currentBuid, coverageBuid, children, showDisclosureStatement } = props;
  currentBuid && currentBuid(personBuid)
  const [showPolicyReplacementQuestions, setShowPolicyReplacementQuestions] = useState(false);

  const showOrHidePolicyReplacementQues = (personBuid, coverageIndex, isWaiveSelected) => {
    setShowPolicyReplacementQuestions(!isWaiveSelected);
  };
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);
  const [disableContinue, setDisableContinue] = useState(true);
  const [totalPercent, setTotalPersent] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [canContinueBeneficiaries, setCanContinueBeneficiaries] = useState(false);
  const [continueBtnText, setContinueBtnText] = useState();
  const [formatedCoverageDetails, setFormatedCoverageDetails] = useState([]);

  const { insuredInfo, process, entities } = useSelector((storeState) => storeState);
  let currentReduxState = useSelector((storeState) => storeState);

  const callCenterEmployeeData = useSelector(
    (storeState) => storeState?.callCenter?.callCenterEnrollAsEmployeeLoginData
  );
  let productFamily = formsConfig?.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
  let beneficiaryList = []
  let loggedUserEntity = Object.keys(callCenterEmployeeData).length === 0 ? currentReduxState.auth.loggedUserEntity : callCenterEmployeeData;
  let enrollmentProcessBuid = currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
  const subscribeCoverageBuids = entities?.response?.onLoadResponseList?.[currentStep]?.subscribeCoverageBuids || [];
  let getDetails = subscribeCoverageBuids.filter((e) => e?.personBuid === personBuid)
  const getAllPossibleChoicesConfiguration = currentReduxState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]

  let subscribedElements = getDetails?.[coverageIndex]?.perpareSubscriptionForChoices?._Result || [];
  let allowedBeneficiaryKind = ""

  let possibleValues = [];
  let coverBuid = getDetails?.[coverageIndex]?.coverageBuid

  let coverageConfiguration = getDetails?.[coverageIndex]?.subscribedCoverageConfiguration || ''
  if (coverageConfiguration) {
    possibleValues = coverageConfiguration?._Result?.additionalDatas;
  }
  let coverageDetailsData = insuredInfo[currentStep]?.coverageDetails?.filter((e) => e.coverageBuid === coverBuid)?.[0] || {}

  let selectedValue = coverageDetailsData?.selectedValue



  useEffect(() => {
    if (coverageDetails && coverageDetails.length) {
      setFormatedCoverageDetails(formatDisplayValueForCoverageAmount({ coverageDetails }))
      if (selectedValue) {
        let filteredCoveragePlan = coverageDetails.filter((coverage) => {
          return coverage.code === selectedValue
        })
        if (filteredCoveragePlan?.length === 0) {
          resetCoverageTable()
        }
      }
    }
  }, [coverageDetails])

  const resetCoverageTable = () => {
    dispatch(UPDATE_COVERAGE_DETAILS({
      currentStep: currentStep,
      coverageBuid: coverBuid,
      removeCoverage: true
    }))
  }

  let policyReplacementQuestions =
    currentReduxState?.insuredInfo[currentStep]?.policyReplacementQuestions;
  let policyReplacementAnswer = [];

  if (Object.keys(policyReplacementQuestions ?? {}).length > 0) {
    Object.entries(policyReplacementQuestions).map(([key, value]) =>
      policyReplacementAnswer.push({ fieldName: key, valueCode: value })
    );
  }
  let waivedCoverage = coverageDetailsData?.waivedCoverage;

  let getSubscribedCoverageBeneficiary = getDetails?.[coverageIndex]?.beneficiaryConfiguration || ''
  let beneficiaryConfigurationDetails = getSubscribedCoverageBeneficiary?._Result?.filter((e) => e.forCoveredPerson?.bUID === personBuid)
  let allowedBeneficiaryDetails = beneficiaryConfigurationDetails?.[0]?.allowedBeneficiaryKind || []

  if (allowedBeneficiaryDetails.length > 0) {
    if (beneficiaryList.length > 0 && allowedBeneficiaryDetails.includes("cDefineBeneficiaries")) {
      allowedBeneficiaryKind = 'cDefineBeneficiaries';
    } else {
      allowedBeneficiaryKind = 'cByWillOrEstate';
    }
  }
  const subscribedProductBuid = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements

  let coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids[0];
  let coverageBeneficiaryEligibility = insuredInfo[currentStep]?.coverageBeneficiaryEligibility;
  if (props.flowType === 4) {
    coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids.filter((e) => e?.coverage?.includes(coverageOption))[0];
  }
  let coverageTitleName = ''
  if (subscribedElements?.length > 0) {
    if (subscribedElements?.[0]?.subscribedOption?.optionCode === 'UMUIMCHUBB#0') {
      coverageTitleName = subscribedElements?.[0]?.subscribedOption?.optionName?.replace(/\-.*$/g, "");
    }
  }
  useEffect(() => {
    setPrepareApiOneTime(false)
  }, [coverageOption])
  useEffect(() => {
    if (coverageDetailsForPrepare && subscribedProductBuid && !prepareApiOneTime) {
      setPrepareApiOneTime(true)

      // const fetchData = async () => {
      //   try {
      //     await dispatch(validateSubscribedProductData({
      //       enrollmentProcessBuid: enrollmentProcessBuid,
      //       productIdentifierBuid: productIdentifierBuid,
      //       currentStep: currentStep,
      //       subscribedElements: subscribedProductBuid,
      //       expectReturn: true
      //     }));

      //     const coverageBuid = await dispatch(prepareSubscriptionForChoice(
      //       enrollmentProcessBuid,
      //       coverageDetailsForPrepare?.coverage,
      //       coverageDetailsForPrepare?.coveragePersonBuid,
      //       currentStep,
      //       personBuid,
      //     ));

      //     if (!coverageBuid) {
      //       throw new Error('coverageBuid not received from prepareSubscriptionForChoice');
      //     }

      //     await dispatch(getSubscribedCoverageBeneficiaryConfigurationNew(
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       personBuid,
      //       currentStep,
      //     ));

      //     await dispatch(getSubscribedCoverageConfigurationNew(
      //       currentStep,
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       personBuid
      //     ));

      //     await dispatch(getListOfCoveragePlanDesignConfigurationNew(
      //       currentStep,
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       personBuid
      //     ));
      //   } catch (error) {
      //     console.error('There was an error!', error);
      //   }
      // }

      const fetchData = async () => {
        dispatch(UPDATE_LOADING(true));
        try {
          let listOfProduct = subscribedProductBuid?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
          let subscribedPBuid = listOfProduct?.[0]?.bUID
          await dispatch(coverageProductDetails({
            enrollmentProcessBuid: enrollmentProcessBuid,
            flowType: props.flowType,
            subscribedProductBuid: subscribedPBuid,
            personBuid: personBuid,
            prepareCoverageBuid: coverageDetailsForPrepare?.coverage,
            prepareCoveredPersonBuid: coverageDetailsForPrepare?.coveragePersonBuid,
            currentStep: currentStep
          }));
        } catch (e) {
          console.error(e)
        } finally {
          dispatch(UPDATE_LOADING(false));
        }
      }
      fetchData();
    }
  }, [prepareApiOneTime, currentStep, getAllPossibleChoicesConfiguration, insuredInfo, enrollmentProcessBuid, personBuid, props.flowType, coverageDetailsForPrepare, subscribedProductBuid, coverageOption]);

  const continueBtnObj = {
    flowType: props.flowType,
    screenType: screenNames.COVERAGE_SCREEN,
    hasDisclosureStatement: props.showDisclosureStatement,
    spouseCount: props.spouse.length,
    childrenCount: props.children.length,
    previousStep: previousStep,
    isWaivedOffFlag: false,
    productIdentifierBuid,
    memberIdentifier: "employee",
    coverageTableDetails: {
      coverageTableCount: coverageTableCount,
      coverageIndex: coverageIndex
    }
  }

  useEffect(() => {
    handleDisableFn();
    // (async () => {
    //   const res = await dispatch(getProductConfig({
    //     productName: productIdentifier.excessLiability
    //   }))
    //   setConfig(res?.data)

    // })();

  }, [])

  useEffect(() => {
    setContinueBtnText(continueBtnLabelHelper({
      ...continueBtnObj,
      //changed the logic here from other files primarily because selectedvalue is getting reatined on page load but waivedCoverage turns null 
      isWaivedOffFlag: waivedCoverage
    }));
  }, [waivedCoverage, coverageIndex])

  const waiveOffCoverageSelected = (isChecked) => {
    dispatch(UPDATE_WAIVE_PRODUCT({ personBuid, "waiveProduct": isChecked, currentStep }))
    setContinueBtnText(continueBtnLabelHelper({
      ...continueBtnObj,
      isWaivedOffFlag: isChecked
    }));
  }

  const nextStepCall = async () => {

    if (!waivedCoverage) {
      dispatch(UPDATE_LOADING(true));
      let externalStepContext = {
        "portalStep": currentStep,
        "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
        "reachedPreConfirmation": "no"
      };
      try {
        await dispatch(updateSubscribedCoverageInformation({
          enrollmentProcessBuid,
          subscribedElements,
          productIdentifierBuid,
          beneficiaryList,
          allowedBeneficiaryKind,
          selectedValue,
          possibleValues,
          policyReplacementAnswer,
          loggedUserEntity,
          coverageBeneficiaryEligibility: coverageBeneficiaryEligibility?.length > 0 ? true : false,
          currentStep,
          coverageBuid: coverageDetailsData?.coverageBuid,
          externalStepContext: externalStepContext,
          isMoveNext: false,
          personBuid
        }));

      } catch (e) {
        console.error(e)
      } finally {
        dispatch(UPDATE_LOADING(false));
      }
      if (!showDisclosureStatement && step === stepsCount) {
        props.onNext()
      } else {
        nextStep();
      }
      // setShowLoader(true);
      // let actionDispatched = await dispatch(updateSubscribedCoverageInformationCI({
      //   enrollmentProcessBuid,
      //   subscribedElements,
      //   productIdentifierBuid,
      //   beneficiaryList,
      //   allowedBeneficiaryKind,
      //   selectedValue,
      //   possibleValues,
      //   loggedUserEntity,
      //   //subscribedCoverageBuid
      // }))
      // if (actionDispatched) {

      //   let externalStepContext = {
      //     "portalStep": currentStep,
      //     "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
      //     "reachedPreConfirmation": "no"
      //   };



      //   dispatch(UPDATE_LOADING(true));
      //   try {
      //     let respObj = await serviceHelper.sendApiRequest('/validateSubscribedCoverageData', {
      //       enrollmentProcessBuid: enrollmentProcessBuid,
      //       coverageBuid: getDetails?.[coverageIndex]?.coverageBuid,
      //       externalStepContext
      //     });
      //     if (respObj.data && respObj.status === 200) {
      //       // Format the new data
      //       dispatch(SUBSCRIBE_COVERAGE_BUIDS({
      //         coverageBuid: getDetails?.[coverageIndex]?.coverageBuid,
      //         personBuid: personBuid,
      //         product: currentStep,
      //       }));
      //       dispatch(
      //         UPDATE_COVERAGE_INFO({
      //           coverageBuid: getDetails?.[coverageIndex]?.coverageBuid,
      //           personBuid: personBuid,
      //           product: currentStep,
      //           validateSubscribedCoverageData: respObj.data
      //         })
      //       );
      //     }
      //     setShowLoader(false);
      //   } catch (e) {
      //     console.error(e)
      //   } finally {
      //     dispatch(UPDATE_LOADING(false));
      //   }
      //   if (!showDisclosureStatement && step === stepsCount) {
      //     props.onNext()
      //   } else {
      //     nextStep();
      //   }
      //   //return true;
      // }
    } else if (waivedCoverage === true) {
      setShowLoader(true);
      let getDetails = subscribeCoverageBuids.filter((e) => e?.personBuid === personBuid)
      let subscribedCoverageBuid = getDetails?.[coverageIndex]?.coverageBuid;
      let enrollmentProcessBuid = process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
      dispatch(UPDATE_LOADING(true));
      try {
        await dispatch(unsubscribeCoverageDetails({
          enrollmentProcessBuid: enrollmentProcessBuid,
          coverageBuid: coverageDetailsForPrepare?.coverage,
          coveredPerson: coverageDetailsForPrepare?.coveragePersonBuid,
          subscribedCoverageBuid: subscribedCoverageBuid,
          isPrepare: false,
          isMoveNext: false,
          currentStep: currentStep
        }, "UnsubscribeCoverage"));
        // let actionDispatched = await dispatch(unsubscribeCoverage({
        //   enrollmentProcessBuid, subscribedCoverageBuid
        // }, "CreateOrUpdateInsuredCIGeneral"));
        // if (actionDispatched) {
        //   setShowLoader(false);
        //   dispatch(onLoadAPI({
        //     CreateOrUpdateInsuredCIGeneral: actionDispatched.data,
        //     isAPICallInProgress: false,
        //   }))
        //   //return true;
        // }
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(UPDATE_LOADING(false));
      }
      if (coverageIndex === 0) {
        if (showDisclosureStatement) {
          nextStep()
        } else {
          props.onNext()
        }
      } else {
        if (!showDisclosureStatement && step === stepsCount) {
          props.onNext()
        } else {
          nextStep();
        }
      }
    }
  }

  const checkBenificiaryFn = (pstatus, pselectedRows) => {
    let primaryTotal = 0;
    let contingentTotal = 0;
    let hasPrimary = false;
    let hasContingent = false;
    insuredInfo[currentStep]?.updatedBeneficiaries?.forEach(pobj => {
      Object.keys(pobj).forEach((pbeneficiary, pind) => {
        const beneficiary = pobj[pbeneficiary][pind];
        if (beneficiary.beneficiaryType === 'Primary') {
          primaryTotal += beneficiary.beneficiaryPercentage;
          hasPrimary = true;
        } else {
          contingentTotal += beneficiary.beneficiaryPercentage;
          hasContingent = true;
        }
      })
    });
    let lflag = true;
    if (hasPrimary && primaryTotal !== 100) {
      lflag = false;
    }

    if (hasContingent && contingentTotal !== 100) {
      lflag = false;
    }
    if (pstatus == "success") {
      setTotalPersent(100);
    } else {
      const currentPercent = ((primaryTotal > 0 ? primaryTotal : 0) + (contingentTotal > 0 ? contingentTotal : 0)) * 100 / 200;
      setTotalPersent(0);
    }
    setSelectedRows([...pselectedRows]);
  };
  const totalPolicyQuestionDiff = 0;

  const handleDisableFn = () => {
    if (productIdentifier.excessLiability === productFamily) {
      if (waivedCoverage && coverageIndex === 0) {
        setDisableContinue(false)
      } else {
        if (selectedValue === undefined || selectedValue === "") {
          setDisableContinue(true);
        } else if (selectedValue === null) {
          setDisableContinue(false);
        } else {
          setDisableContinue(false);
        }
      }

    } else {
      if (selectedValue === undefined) {
        setDisableContinue(true);
      }
      else if (selectedValue === null) {
        setDisableContinue(false);
      } else if (!props.beneficiaryEligibility && selectedValue && totalPolicyQuestionDiff === 0) {
        setDisableContinue(false);
      } else {
        const isBeneficiaryValid = (props.isBeneficiaryMandatory && selectedRows?.length && canContinueBeneficiaries)
          || (props.beneficiaryEligibility && (!selectedRows?.length || canContinueBeneficiaries));
        if (isBeneficiaryValid && totalPolicyQuestionDiff === 0) {
          setDisableContinue(false);
        } else {
          setDisableContinue(true);
        }
      }
      
    }
  };

  useEffect(() => {
    handleDisableFn();
  }, [selectedValue, totalPercent, selectedRows, totalPolicyQuestionDiff]);

  const hasErrors = getErrorMessages(currentStep);

  if (insuredInfo?.isLoading) {
    return null;
  }

  return (

    <>
      <div className="row" style={{ fontSize: '1.5rem', color: 'rgb(32, 32, 32)', fontFamily: 'NotoSansRegular' }}>
        <div className="">
          <div className="label col-12 px-3 undefined" style={{ display: 'inline-block' }}>
            <div className="row">
              <div className="px-3 py-3">
                Select Coverage Option {coverageTitleName ? `for ${coverageTitleName}` : ''}
              </div>
            </div>
          </div>
        </div>
      </div>


      {/*showWaiveCoverage={coverageIndex === 0}  This condition is required to test if the user is on first coverage table page or any other. If index is 0, we assume that user is on base plan coverage table*/}
      {formatedCoverageDetails && (formatedCoverageDetails?.length > 0) &&
        <CustomTable {...props} coverageIndex={coverageIndex} selectedValue={selectedValue} waivedCoverage={waivedCoverage} tableData={formatedCoverageDetails} showOrHidePolicyReplacementQues={showOrHidePolicyReplacementQues} waiveOffCoverageSelected={waiveOffCoverageSelected} showWaiveCoverage={coverageIndex === 0}> </CustomTable>}
      {(showPolicyReplacementQuestions || selectedValue) && (props.beneficiaryEligibility) &&
        <Beneficiary currentStep={props.currentStep} personBuid={props.personBuid}
          allowedBeneficiaryKind={props.allowedBeneficiaryKind} relation={'spouse'}
          dependentsList={props.dependentsList} updateBeneficiary={checkBenificiaryFn} setCanContinueBeneficiaries={setCanContinueBeneficiaries}></Beneficiary>}

      {((showPolicyReplacementQuestions || selectedValue) && props.showPolicyReplacementQuestionFlag)
        && (props.isBeneficiaryMandatory && totalPercent == 100 || !props.isBeneficiaryMandatory)
        && (
          <PolicyReplacementQuestions
            {...props}
          />
        )}

      {selectedValue && <SurplusFeeWrapper coverageIndex={coverageIndex} selectedValue={selectedValue} tableData={coverageDetails} />}



      <div className='button-group'>
        <>
          <Button type="button" onClick={prevStep} className="mr-3 btn btn-outline-primary primaryOutline">
            Back
          </Button>
        </>
        < Button type="button" onClick={nextStepCall} disabled={disableContinue || hasErrors}
          className="btn btn-primary continue-btn primaryBackground coverageFour"
        >
          {continueBtnText}
        </Button>

      </div>


    </>

  );
};

export default CoverageFlowFour;
