import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.date-card-element-label {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
  .date-card-element-content {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  }

  .image-card-border {
  border-radius: 5px;
  background-color: #f8fafc;
 border: solid 1px #d1e0ec;
}


  .panel-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0,0,0,.125);
  }

  .panel-title {
    padding: 0rem 0rem 1rem 0rem;
  }

  .panel-text {
    padding: 0 !important;
  }

  .panel-subtext {
    padding: 0.75rem 0rem 1.25rem 0rem;
  }

  .panel-additional-subtext {
    padding: 0.75rem 0rem 0.25rem 0rem;
    font-weight: bold !important;
  }

  .panel-button {
    margin: 3.125rem 0rem 0rem 0rem;
  }

  .list-button {
    // 50px
    margin: 3.125rem 0rem 0rem 0rem;
    margin-top: auto!important;
  }

  .life-event-button {
    // 45px
    //margin-top: 2.813rem !important;
  }
 .lifeEvent, .profileInfo{
  position:relative;
 }

 .lifeEvent .life-event-button,
 .profileInfo button{
  position: absolute;
  left: 1.5rem;
  bottom: 20px;
 }

  .panel-list {
    margin-top: 1.25rem !important;
  }

  .panelSimpleContainer {
    margin: 0 !important;
    padding: 1.25rem !important;
    width: 100% !important;
    height: 100%;
  }

  .panelSimpleContainer > div:has(.homePagePanels) {
    height: 100%;
  }

  .panelContainerCurrentBenefits {
    margin: 0 !important;
    padding: 1.25rem !important;
    width: 100% !important;
  }
  
  .current-benefits-button {

  }

  .current-benefits-get-started {
    margin-top:auto!important;
  }

  //Tablets Breakpoints
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .panel-button {
      margin: 2.25rem 0rem 0rem 0rem;
    }
    .list-button {
      margin: 2.75rem 0rem 0rem 0rem;
    }
    .life-event-button {
      margin-top: 3.125rem !important;
    }
  }

  // Breakpoint for ipad Air
  @media only screen and (min-width: 820px) and (max-width: 850px) {
    .list-button {
      margin-top: 1.875rem !important;
    }
    .life-event-button {
      margin-top: 1.25rem !important;
    }

    .current-benefits-button {
      margin: 0 !important;
    }
  }

  // Breakpoint for ipad Pro
  @media only screen and (min-width: 1024px) and (max-width: 1100px) {
    .list-button {
      margin: 5.438rem 0rem 0rem 0rem;
    }
  }

  @media screen and (max-width: 768px) {
    .reset-padding {
      padding:0;
    }
  }
  
  @media only screen and (max-width: 360px) {
    .panelSimpleContainer {
       min-height: 13.5rem !important;
    }
   }

  @media only screen and (max-width: 374px) {
    .panelContainerCurrentBefefits {
      min-height: 29rem !important;
    }
    .panelContainer {
      min-height: 26rem !important;
    }
  }

  @media only screen and (min-width: 375px) and (max-width: 394px) {
    .panelContainer {
       min-height: 23.5rem !important;
    }
    .current-benefits-get-started {
      margin: 0 !important;
    }
   }

  // Ipad Mini, Ipad Breakpoint 
  @media only screen and (min-width: 767px) and (max-width: 769px) {
    .listContainerTitle {
      padding-bottom: 0!important;
    }
    .lifeEventButton {
      margin-top: 3.625rem !important;
    }
  }
  
  @media only screen and (min-width: 748px) {
    .panelContainer {
    
    }
  }

  @media only screen and (min-width: 1024px) {
    .lifeEventButton {
      margin-top: 2.5rem !important;
    }
    .listContainerTitle {
      font-size: 1.15rem !important;
      padding-bottom: 0!important;
    }
    .listItemsContainer {
      margin-top 0 !important;
    }

  }


  `;
