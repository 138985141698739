import http from '../HttpService';
import appConfig from '../../../appConfig';

//function to make api call to node server to get options based on config
// TODO : need to remove
const getOptions = async (url, obj) => {
  try {
    const { data: formElement } = await http.post(appConfig.SSP_MID_URL + url, {
      objectInformation: obj,
    });
    return formElement;
  } catch (error) {}
};

//function to make api call to node server to submit form

const submitForm = async (formData, bearerToken, SetContentData, url) => {

  try {
    SetContentData((prevContentData) => ({
      ...prevContentData,
      loadingCheck: true,
    }));
    //http.setBearer(getBearer());
    let  submitResponse  = {};
//Code to display success message without making api call
    if (url == "") {
      submitResponse = {"_Result": "success"}
    } else {
      submitResponse = await http.post(appConfig.SSP_MID_URL + url, formData, {
        /*  headers: {
        Authorization: 'bearer ' + bearerToken,
      }, */
      });
    }
    // let response = { ...submitResponse, [formName]: { ...formElement } };
    SetContentData((prevContentData) => ({
      ...prevContentData,
      loadingCheck: false,
      isStepFormSubmitted: true,
    }));

    return submitResponse;
  } catch (error) {
    

    SetContentData((prevContentData) => ({
      ...prevContentData,
      loadingCheck: false,
      isStepFormSubmitted: true,
      apiError: true,
    }));
    return error;
  }
};

export default {
  submitForm,
  getOptions,
};
