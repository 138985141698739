import { React, useEffect, useState } from 'react';
import { Button } from '../../Atoms';
import moment from 'moment';
import classes from './styles.module.css';
const DependentsTable = (props) => {
  const {
    editOnClick = () => { },
    showTable = true,
    hideEditButton = false,
    qleEndorsementProcessBuid,
    constants,
    insuredDependents,
    isButtonVisible,
    finalEligibleProduct,
    checkEvergereenAndCancel,
    checkEverGreen,
    openreenrollment,
    isDependentEligible,
    currentBenifitendorsementProcessBuid,
    reasonType,
    isAdmin,
    isQLEEnrollment,
    enrollmentType,
    notEnrolledProducts,
    externalClientId,
    isSpecialEnrollment
  } = props;

  const [isDependentEvergreen, setIsDependentEvergreen] = useState({})

  const productDetails = finalEligibleProduct?.map((item) => {
    let productBuid = item?.subscribedProduct?.productCode?.buid
    const waivedStatus = item?.subscribedProduct?.status === "cDeclined" || item?.subscribedProduct?.status === "cTerminated" || item?.subscribedProduct.status === "cNeverInForce"
    const checkProduct = item?.subscribedProduct?.additionalDatas
    const checkEnroll = checkProduct?.find(eachObj => eachObj?.fieldCode === 'IEGP' && eachObj?.fieldValue == true) && item?.subscribedProduct?.status === ""
    const filtercancelCoverage1 = checkEverGreen?.filter((value) => value?.everGreenProductBuid === item?.subscribedProduct?.productCode?.buid)
    // const checkEverGreenAtsubscription = filtercancelCoverage1?.find(eachObj => eachObj?.evergreenAtSubscription === true) ? true : false
    // const isEvergereenEdit = filtercancelCoverage1?.find(eachObj => eachObj?.checkevergerrenTrue === true) && (item?.subscribedProduct?.status === "cActive" || item?.subscribedProduct?.status === "cNeverInForce" || item?.subscribedProduct?.status === "cDeclined" || item?.subscribedProduct?.status === "cTerminated") ? true : false
    // const checkEvergereenEdit = isEvergereenEdit && !checkEverGreenAtsubscription ? true : false
    const filtercancelCoverage = checkEvergereenAndCancel?.filter((value) => value?.cancelCovBuid === item?.subscribedProduct?.productCode?.buid)
    const checkenrollEndorsement = filtercancelCoverage?.find(eachObj => eachObj?.checkEnrollTrue === true) && (item?.subscribedProduct?.status === "") ? true : false
    //
    const checkEverGreenAtsubscription = filtercancelCoverage1?.find(eachObj => eachObj?.evergreenAtSubscription === true) ? true : false
    const isEvergereenEdit = filtercancelCoverage1?.find(eachObj => eachObj?.checkevergerrenTrue === true) && (item?.subscribedProduct?.status === "cActive" || waivedStatus) ? true : false

    const checkEvergereenEdit = isEvergereenEdit && !checkEverGreenAtsubscription ? true : false

    let isEverGreenEnrollTrueFalse = notEnrolledProducts?.some(item => item.notEnrolledBuid === productBuid && item.isEverGreenTrueFalse === true && item.isNotEnrolledStatus === true);
    let isEverGreenEditTrueFalse = notEnrolledProducts?.some(item => item.notEnrolledBuid === productBuid && item.isEverGreenTrueFalse === true && checkEvergereenEdit === true);

    return {
      checkEvergereenEdit: isEverGreenEditTrueFalse,
      checkEnroll: isEverGreenEnrollTrueFalse
    }
  })
  useEffect(() => {
    let isAnyCheckEvergereenEditTrue = productDetails?.some(item => item?.checkEvergereenEdit === true);
    let isAnyCheckEvergereenEnrollTrue = productDetails?.some(item => item?.checkEnroll === true);
    setIsDependentEvergreen({ isAnyCheckEvergereenEditTrue, isAnyCheckEvergereenEnrollTrue })
  }, [])
  const dependentsTable = [];
  if (insuredDependents?.length > 0) {
    insuredDependents?.forEach((dependent) => {
      if (!dependent?.insured?.terminationDate) {
        if (dependent?.insured?.mainOwnerRelationshipToInsured) {
          let dependentData = {
            mainBuid: dependent?.bUID,
            id: dependent?.person?.identifier?.bUID || '',
            dependentAddressLine1:
              dependent?.person?.data?.correspondences[0]?.address?.line1 || '',
            dependentAddressLine2:
              dependent?.person?.data?.correspondences[0]?.address?.line2 || '',
            dependentBirthDate: dependent?.person?.data?.actor?.birthDate || '',
            dependentCity:
              dependent?.person?.data?.correspondences[0]?.address?.zipCodeAndCity?.city || '',
            dependentCountry: dependent?.person?.data?.correspondences[0]?.address?.country || '',
            dependentFirstName: dependent?.person?.data?.actor?.shortName || '',
            dependentGender: dependent?.person?.data?.actor?.gender || '',
            dependentLastName: dependent?.person?.data?.actor?.name || '',
            dependentMiddleName: dependent?.person?.data?.actor?.middleName1 || '',
            dependentRelationship: dependent?.insured?.mainOwnerRelationshipToInsured || '',
            dependentState: dependent?.person?.data?.correspondences[0]?.address?.state || '',
            dependentZipCode:
              dependent?.person?.data?.correspondences[0]?.address?.zipCodeAndCity?.zipCode || '',
            data: { ...dependent },
          };
          dependentsTable?.push(dependentData);
        }
      }
    });
  }

  const getRelationshipLabel = (relationshipValue, dependent) => {
    const option = constants?.dependentRelationship?.find(
      (option) => option.value === relationshipValue || option.label === relationshipValue
    );

    return option ? option.label : '';

  };
  const gender = {
    gMale: 'Male',
    gFemale: 'Female',
    gUndisclosed: 'Undisclosed',
  };
  const isAdmintrue = isDependentEligible && isAdmin
  const isqleEnrollment = isQLEEnrollment
  const enrollProcess = isDependentEligible && ((reasonType === 'NH' && !currentBenifitendorsementProcessBuid) || (reasonType === 'AE') || (reasonType === 'OP'))
  const annualEnrollment = openreenrollment
  let ongoingEnrollment = (reasonType === 'OE' && isDependentEligible && (enrollmentType === "case8" || enrollmentType === "case9")) || (reasonType === 'OE' && isDependentEligible && (externalClientId?.externalClientId === "ANDCON" && (isDependentEvergreen.isAnyCheckEvergereenEditTrue || isDependentEvergreen.isAnyCheckEvergereenEnrollTrue))) ? true : false
  let dependentsTableEligible = qleEndorsementProcessBuid || enrollProcess || annualEnrollment || isAdmintrue ? true : dependentsTable?.length > 0
  let isButtonEditable = isButtonVisible && (externalClientId?.externalClientId === "ANDCON" && reasonType === 'OE' && (isDependentEvergreen.isAnyCheckEvergereenEditTrue || isDependentEvergreen.isAnyCheckEvergereenEnrollTrue)) ? true : false
  let isdependentEditable = (annualEnrollment || isButtonEditable || ongoingEnrollment || enrollProcess || qleEndorsementProcessBuid || isAdmintrue || isqleEnrollment || isSpecialEnrollment) ? true : false

  return dependentsTableEligible && (
    <div className={classes.card}>
      <div className={classes.cardHeader}>
        <div className={classes.title}>{'Dependent Information'}</div>
        <>
          {!hideEditButton && isdependentEditable && (
            <Button
              className={classes.Button}
              onClick={() => editOnClick('DependentInformation')}
            >
              Edit
            </Button>
          )}</>

      </div>
      <div className=" table-responsive dependent-table col-md-12 d-md-block d-lg-block d-xl-block ">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>NAME</th>
              <th>RELATIONSHIP</th>
              <th>GENDER</th>
              <th>DATE OF BIRTH</th>
            </tr>
          </thead>
          <tbody className="">
            {dependentsTable?.map((dependent, index) => {
              const isTrust = dependent.dependentOptions === 'trust';
              const dependentName = !isTrust
                ? `${dependent.dependentFirstName} ${` `} ${dependent.dependentLastName}`
                : dependent.legalName;
              let relationShip = !isTrust
                ? getRelationshipLabel(dependent?.dependentRelationship, dependent)
                : '';
              return (
                <tr key={index}>
                  <td>{dependentName}</td>
                  <td>{relationShip}</td>
                  <td>{gender[dependent?.dependentGender]}</td>
                  <td>{moment(dependent?.dependentBirthDate).format('MM/DD/YYYY')}</td>
                </tr>
              )
            })
            }
          </tbody >
        </table>
      </div>
    </div>
  );
}

export default DependentsTable;