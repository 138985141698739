import http from '../../../../../Common/Services/HttpService';
import appConfig from '../../../../../appConfig';
import { getBasicAuthHeaderValue } from '../../Helper/MFAHelper'

const SendChallenge = async (emailId) => {
    let basicAuthorizationValue = getBasicAuthHeaderValue();
    /* basicAuthorizationValue && dispatch(SET_MFA_BASIC_AUTH_VALUE(basicAuthorizationValue)); */

    const reqObj = {
        "login": emailId,
        "reason": 'password'
    }
    try {
        const resp = await http.post(

            appConfig.SSP_AUTH_URL + "/stateful/user-mgmt/send-challenge",
            reqObj,
            {
                withCredentials: true,
                headers: {
                    Authorization: `Basic ${basicAuthorizationValue}`,
                    'X-REQUEST-ID': Math.random(200),
                }
            },
        );
        return resp;
    } catch (e) {
        
        return { error: e };
    }
};

export default SendChallenge;
