import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'startTimer',
  initialState: {
    start: false,
    stop: true,
    
  },
  reducers: {
    // action => action handler
    COUNT_DOWN: (startTimer, action) => {
      startTimer.start= action.payload.start;
      startTimer.stop= action.payload.stop;
    },
    SHOW_RESET_EMAIL_TIMER: (startTimer, action) => {
      startTimer.enableEmailResetBtn= action.payload;
    },
    SHOW_RESET_PHONE_TIMER: (startTimer, action) => {
      startTimer.enablePhoneResetBtn= action.payload;
    }
  },
});

export const { COUNT_DOWN, SHOW_RESET_EMAIL_TIMER, SHOW_RESET_PHONE_TIMER } = slice.actions;

export default slice.reducer;
