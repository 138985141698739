import React, { useEffect } from 'react';
import DynamicComponent from '../index';
import { useNavigationHook } from '../Hooks/NavigationHook';
import SideNavigation from '../Component/SideNavigation/SideNavigation';

import './style.css';
import Header from '../Component/Header/Header';
import { MessageComponent } from '../../../Atoms';

const StepForm = (props) => {
  const {
    handleNextStep,
    handlePreviousStep,
    handleFirstStep,
    handleLastStep,
    handleSetSkipSteps,
    handleSetStepKeys,
    handleSetCurrentStep,
    stepKeys,
    skipSteps,
    currentStep,
    previousStep,
    formsConfig
  } = useNavigationHook();

  const {
    showSideNavigation = true,
    layoutData = {},
  } = props;


  const currentForm = formsConfig?.forms?.[currentStep] || {};
  const headerData = formsConfig?.layout?.sectionHeader;
  const className = showSideNavigation ? 'form-content-wrapper' : 'form-content-wrapper-endorsement';




  return (
    <>
      <MessageComponent currentPage={currentStep}/>
      <Header
        title={currentForm?.['headerTitle'] || currentForm?.['displayName']}
        rightLink={headerData?.rightLink}
        breadcrumbsStepForm={headerData?.breadcrumbs}
        // breadcrumbs={currentForm?.sectionHeader?.breadcrumbs}
        config={headerData?.config}
        modifyPadding={headerData?.modifyPadding}
        filename={headerData?.filename}
      // current={headerData?.current}
      />
      <div className='step-form'>
        <div className={className}>
          <DynamicComponent
            currentStep={currentStep}
            layoutData={layoutData}
          />
          {/* <h1>Current Step: {currentStep}</h1>
          <h1>Previous Step: {previousStep || 'No previous step'}</h1>

          <button onClick={handlePreviousStep}>Previous</button>
          <button onClick={handleNextStep}>Next</button>
          <button onClick={handleFirstStep}>First</button>
          <button onClick={handleLastStep}>Last</button> */}
        </div>
        {showSideNavigation && (<div className='side-nav-wrapper'>
          <SideNavigation />
        </div>)}
      </div>
    </>
  );
};

export default StepForm;
