import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

let tempHistory;
let tempPath = '';
function disableBack() {
    tempHistory.replace(tempPath);
};

function useOnBack(routePath) {

    tempHistory = useHistory();
    tempPath = routePath;

    useEffect(() => {
        window.addEventListener('popstate', disableBack);
        return () => {
            window.removeEventListener('popstate', disableBack);
        };
    }, []);
}

export default useOnBack;
