import React, { useState } from 'react';
import arrowLess from '../../../../../Assets/Images/arrow-up-blue.svg'; 
import arrowMore from '../../../../../Assets/Images/arrow-down-blue.svg'; 

const SubContent = ({ item, status, onClick }) => {
    if (item.displayName) {
        const className = item.clickable ? `link sub-step-${status}` : '';

        return (
            <button className={`sub-content-item ${className}`} disabled={!item.clickable || status === 'draft'} onClick={item.clickable ? onClick : null} id={item.stepKey}>
                <div className="item-displayname">{item.displayName}</div>
                {item.stepDetails && (item.stepDetails.map((detail, index) => (
                    <div className="detail" key={index}>
                        <div className='coverage-name'>{detail.prefix ? `${detail.prefix} - ${detail.title}` : detail.title}</div>
                        <div className='coverage-info'>{detail.info}</div>
                    </div>
                )))}
            </button>
        )
    } else {
        return null;
    }
}

const Step = ({ step, onStepClick }) => {

    const [showMore, setShowMore] = useState(false);
    const onClick = () => {
        onStepClick(step);
    };

    const onSubContentClick = (subStep, e) => {
        e.preventDefault();
        onStepClick(subStep);
    }

    const onShowMore = (e) => {
        e.stopPropagation();
        setShowMore(!showMore);
    }

    return (
        <li id={step.stepId}>
            <button onClick={onClick} className={`step step-${step.status}`} disabled={step.status === 'draft'}>
                <span className={`step-indicator`}>
                    <span>{step.key}</span>
                </span>
                <span className='step-title'>{step.title}</span>
            </button>
            <div className='step-sub-content'>
                {step?.subContent?.slice(0, showMore ? step?.subContent?.length : 3).map((item, index) => {
                    const subStepStatus = step?.subSteps?.[index]?.status;
                    return (
                        <SubContent
                            key={index}
                            item={item}
                            status={subStepStatus}
                            onClick={(e) => onSubContentClick(step?.subSteps?.[index], e)}
                        />
                    )
                })}
                {step?.subContent?.length > 3 && (showMore ? (
                    <button className="show-more-less" onClick={onShowMore}>
                        <img src={arrowLess} alt ={`Show Less ${step.title}`} className="mr-2 justify-content-center align-items-center" />Show Less
                    </button>
                ) : (
                    <button className="show-more-less" onClick={onShowMore}>
                        <img src={arrowMore} alt ={`Show More ${step.title}`} className="mr-2 justify-content-center align-items-center" />Show More
                    </button>
                ))}
            </div>
        </li>
    )
}

export default Step;
