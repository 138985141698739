import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import './styles.css';
import { reduxConstants } from '../../../Common/Constants/index';
import ServiceHelper from '../../../App/Utils/Helper/MappingHelper';
import { dateDiffInDay, dateTimeDiffInDay } from '../../../utils/date';

const Label = (props) => {
  const serviceHelper = new ServiceHelper();
  let loggedUserEntity = useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.uiPath)) || {};
  const { element, value, hidden, subtitle } = props;
  //const style = (element.config && element.config.style) || {};
  const homepageEnrollmentPeriod = useSelector(
    (storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result?.currentEnrollmentPeriod?.enrollmentPeriod
  );
  const enrollmentPeriod = useSelector(
    (storeState) => storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.employee?.additionalDynamicDatas
  );
  const currentEnrollmentPeriod = useSelector(
    (storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result?.currentEnrollmentPeriod?.enrollmentPeriod
  );
  const GBPDetails = useSelector(
    (storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result
  );
  const GBPDetailsWithLastRenewal = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.gbpContractsWithLastRenewal?._Result
  );
  let previousOngoingDate1 = GBPDetails?.contract?.additionalDatas?.filter((elm) => elm?.fieldCode === 'DD_00139')?.[0]?.fieldValue || ""
  let previousOngoingDate2 = GBPDetailsWithLastRenewal?.contract?.additionalDatas?.filter((elm) => elm?.fieldCode === 'DD_00139')?.[0]?.fieldValue || ""
  let previousOngoingDate = previousOngoingDate2 || previousOngoingDate1
  let enrollmentPeriodKind = GBPDetails?.currentEnrollmentPeriod?.enrollmentPeriod?.enrollmentPeriodKind;
  let lastRenewalDate = GBPDetails?.contract?.lastRenewalDate;
  let dateArray = enrollmentPeriod?.filter(e => e.value.fieldName === 'ENROLWINSTDATE' || e.value.fieldName === 'ENROLWINEDDATE');
  const enrollmentType = useSelector(
    (storeState) => storeState?.employeeProfile?.enrollmentType
  );
  // const termOrRenewalDate = useSelector(
  //   (storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result?.contract?.termOrRenewalDate
  // );
  const welcomeTextContainer = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.welcome?._Result?.data?.[0]?.welcomeTextContainer
  );
  const widgetTitlewithCertificate = welcomeTextContainer?.widgetTitleWithcertificateValue && welcomeTextContainer?.widgetTitleWithcertificate? welcomeTextContainer?.widgetTitleWithcertificateValue: 'Your Elected and Eligible Benefits'
  const widgetTitlewithoutCertificate = welcomeTextContainer?.widgetTitleWithoutcertificateValue && welcomeTextContainer?.widgetTitleWithoutcertificate? welcomeTextContainer?.widgetTitleWithoutcertificateValue: 'Your Eligible Benefits'
  const contractData = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.getMemberActiveContracts?._Result
  );
  const declinedCertificate = GBPDetails?.declinedCertificate || false;
  let activeContractDatas = contractData ? contractData?.filter(e => e.status === 'cActive') : [];
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  let isEnrollmentPeriod = dateCheck(dateArray?.[0]?.value?.fieldValue, dateArray?.[1]?.value?.fieldValue, today);
  let isAnnualPeriod = dateCheck(currentEnrollmentPeriod?.effectiveDate, currentEnrollmentPeriod?.endDate, today);
  //let upcomingYear = (Date.parse(today) < Date.parse(lastRenewalDate))? new Date(lastRenewalDate).getFullYear(): (new Date().getFullYear() + 1)
  const otherDate = new Date(lastRenewalDate);
  let upcomingYear = today < otherDate ? new Date(lastRenewalDate).getFullYear(): (new Date().getFullYear() + 1)
  function dateCheck(from, to, check) {
    var fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if ((cDate <= lDate && cDate >= fDate)) {
      return true;
    }
    return false;
  }
  let days= GBPDetails?.contract?.additionalDatas?.filter((data) => data.fieldCode == 'DD_00156');
  let windowDays = days ? days[0]?.fieldValue? days[0]?.fieldValue: 30 : 30;

  const setEnrollmentPerionDetails = () => {
    if (homepageEnrollmentPeriod?.name === '' && (enrollmentType === 'case5' || enrollmentType === 'case6' || enrollmentType === 'editNewEnrollmentDuringOE2')) {
      let currentYear = new Date().getFullYear()
      setDisplayValue(`${currentYear} New Hire Enrollment`);
      setDisplaySubtitle(`${dateTimeDiffInDay(
        new Date().toISOString().slice(0, 10),
        //new Date(dateArray?.[1]?.value?.fieldValue)
        dateArray?.[1]?.value?.fieldValue
      )}  days remaining`);
    } else if (enrollmentType !== 'caseNotEligible' && enrollmentType) {
      if (!homepageEnrollmentPeriod) return;
      if (isEnrollmentPeriod && homepageEnrollmentPeriod.name && homepageEnrollmentPeriod?.enrollmentPeriodKind === 'cAnnualEnrollment') {
        let str = homepageEnrollmentPeriod.name;
        let res = str.replace(/\D/g, "");
        if (isEnrollmentPeriod && activeContractDatas?.length === 0) {
          let currentYear = new Date().getFullYear()
          if(enrollmentType === 'case5' && declinedCertificate){
            setDisplayValue(homepageEnrollmentPeriod.name);
          }else{
            setDisplayValue(`${currentYear} New Hire Enrollment`);
          }
          
        } else if (isEnrollmentPeriod && !isAnnualPeriod) {
          setDisplayValue(`${res} New Hire Enrollment`);
        } else {
          setDisplayValue(homepageEnrollmentPeriod.name);
        }
      } else {
        if(enrollmentType === 'case6' && isEnrollmentPeriod && homepageEnrollmentPeriod.name && homepageEnrollmentPeriod?.enrollmentPeriodKind === 'cOpenEnrollment'){
          let currentYear = new Date().getFullYear()
          setDisplayValue(`${currentYear} New Hire Enrollment`);
        }else{
          setDisplayValue(homepageEnrollmentPeriod.name);
        }

      }
      if (!homepageEnrollmentPeriod.endDate) return;
      if (isEnrollmentPeriod && activeContractDatas?.length === 0 && dateArray?.[1]?.value?.fieldValue) {
        setDisplaySubtitle(`${dateTimeDiffInDay(
          new Date().toISOString().slice(0, 10),
          dateArray?.[1]?.value?.fieldValue
        )}  days remaining`);
      }else{
        setDisplaySubtitle(`${dateTimeDiffInDay(
          new Date().toISOString().slice(0, 10),
          homepageEnrollmentPeriod?.endDate
        )}  days remaining`);
      }
      
    }


  };
  const setEnrollmentPerionDetailsNew = () => {
    if (enrollmentType === 'caseNotEligible') return;
    if (!homepageEnrollmentPeriod) return;
    setDisplayValue(homepageEnrollmentPeriod.name);
    if (!homepageEnrollmentPeriod.endDate) return;
    setDisplaySubtitle(`${dateTimeDiffInDay(
      new Date().toISOString().slice(0, 10),
      homepageEnrollmentPeriod?.endDate
    )}  days left to review your benefits`);

  };
  const [displayValue, setDisplayValue] = useState(value || element.value || '');
  const [displaySubtitle, setDisplaySubtitle] = useState(subtitle || element.subtitle || '');
  //
  useEffect(() => {
    // getting values for existing response

    if (props.element && props.element.valueConfig) {
      let redObj = eval(props.element.valueConfig.reduxObject) || {};
      let currentDispalyValue = serviceHelper.getValue(
        props.element.valueConfig.mapperObject,
        redObj
      );
      let value = currentDispalyValue;
      if (props.element.appendValue && value) {
        let displayVal = element.appendValue + value;
        setDisplayValue(displayVal);
      } else {
        setDisplayValue(currentDispalyValue);
      }
    }


  }, [props.element, loggedUserEntity]);

  useEffect(() => {
    // getting values for existing response
    let value = props.value;
    if (element.appendValue && value) {
      setDisplayValue(eval(element.appendValue));
    }
    else if (value) {

      setDisplayValue(value);
    } else if (element.value) {

      setDisplayValue(element.value);
    }


  }, [props.value, element]);

  useEffect(() => {
    if (element?.id === 'daysLeft' && element?.name === 'daysLeft') {
      let dayscheck = dateTimeDiffInDay(
        new Date().toISOString().slice(0, 10),
        homepageEnrollmentPeriod?.endDate
      )
      if (homepageEnrollmentPeriod?.endDate) {
        let currentYear = new Date().getFullYear()
        if (isEnrollmentPeriod & activeContractDatas?.length === 0 && enrollmentPeriodKind !== 'cAnnualEnrollment') {
          currentYear = currentYear
        } else {
          //currentYear = new Date().getFullYear() + 1
          currentYear = upcomingYear
        }
        let daysCheck = ` Only ${dayscheck}  days left to build your personalized package of benefits for ${currentYear} `
        element.value = daysCheck
        setDisplayValue(daysCheck)
      } else { return () => {} }
    }
    if (props.element?.id === 'daysRemaining' && props.element?.name === 'daysRemaining') {
      setEnrollmentPerionDetails();
    }

    if (element?.id === 'elegibleBenefits' && element?.name === 'elegibleBenefits') {
      //let currentYear = new Date().getFullYear() + 1
      let currentYear = upcomingYear
      let elegibleBenefits = ` ${currentYear} Eligible Benefits`
      element.value = elegibleBenefits
      setDisplayValue(elegibleBenefits)
    }

    if (element?.id === 'newHireEnrollmentCurrent' && element?.name === 'newHireEnrollmentCurrent') {
      let currentYear = new Date().getFullYear()
      let newHireEnrollmentCurrent = ''
      let str = homepageEnrollmentPeriod.name;
      let res = str.replace(/\D/g, "");
      if (isEnrollmentPeriod & activeContractDatas?.length === 0) {
        newHireEnrollmentCurrent = ` ${currentYear} New Hire Enrollment`
      } else {
        //currentYear = new Date().getFullYear() + 1
        currentYear = upcomingYear
        newHireEnrollmentCurrent = ` ${currentYear} New Hire Enrollment`
      }
      element.value = newHireEnrollmentCurrent
      setDisplayValue(newHireEnrollmentCurrent)
    }
    if (element?.id === 'newHireEnrollmentCurrent2' && element?.name === 'newHireEnrollmentCurrent2') {
      let currentYear = new Date().getFullYear()
      let newHireEnrollmentCurrent = ` ${currentYear} New Hire Enrollment`
      element.value = newHireEnrollmentCurrent
      setDisplayValue(newHireEnrollmentCurrent)
    }
    if (element?.id === 'daysLeftNewHire' && element?.name === 'daysLeftNewHire') {
      let dayscheck = ""
      if (homepageEnrollmentPeriod?.name === '' && (enrollmentType === 'case5' || enrollmentType === 'case6' || enrollmentType === 'editNewEnrollmentDuringOE2')) {
        dayscheck = dateTimeDiffInDay(
          new Date().toISOString().slice(0, 10),
          //new Date(dateArray?.[1]?.value?.fieldValue)
          dateArray?.[1]?.value?.fieldValue
        )
      }else if (isEnrollmentPeriod && activeContractDatas?.length === 0 && dateArray?.[1]?.value?.fieldValue) {
        dayscheck = dateTimeDiffInDay(
          new Date().toISOString().slice(0, 10),
          dateArray?.[1]?.value?.fieldValue
        )
      }
       else {
        dayscheck = dateTimeDiffInDay(
          new Date().toISOString().slice(0, 10),
          homepageEnrollmentPeriod?.endDate
        )
      }

      //if (homepageEnrollmentPeriod?.endDate) {
      let currentYear = new Date().getFullYear()
      if (isEnrollmentPeriod & activeContractDatas?.length === 0 && enrollmentPeriodKind !== 'cAnnualEnrollment') {
        currentYear = currentYear
      }else if (isEnrollmentPeriod & activeContractDatas?.length > 0 && enrollmentPeriodKind !== 'cAnnualEnrollment') {
        currentYear = currentYear
      } else {
        //currentYear = new Date().getFullYear() + 1
        if(enrollmentType === 'case5' && !declinedCertificate){
          currentYear = currentYear
        }else{
          currentYear = upcomingYear
        }
      }
      let daysLeftNewHire = ` Only ${dayscheck}  days left to build your personalized package of benefits for ${currentYear} `
      element.value = daysLeftNewHire
      setDisplayValue(daysLeftNewHire)
      //} else { return false }

    }
    if (element?.id === 'reviewCurrentBenefits' && element?.name === 'reviewCurrentBenefits') {
      let currentYear = new Date().getFullYear()
      let reviewCurrentBenefits = activeContractDatas?.length > 0 ?'Review and make changes to your current benefits.': 'Enroll here to start coverage.'
      element.value = reviewCurrentBenefits
      setDisplayValue(reviewCurrentBenefits)
    }
    if (element?.id === 'reviewBenefitsElection' && element?.name === 'reviewBenefitsElection') {
      //let currentYear = new Date().getFullYear() + 1
      let currentYear = upcomingYear
      let reviewBenefitsElection = `You can review and make changes to your benefits election for the year ${currentYear}.`
      element.value = reviewBenefitsElection
      setDisplayValue(reviewBenefitsElection)
    }
    if (element?.id === 'currentYearBenefits' && element?.name === 'reviewCurrentBenefits') {
      let currentYear = new Date().getFullYear()
      if (activeContractDatas?.length > 0 && activeContractDatas?.[0]?.effectiveDate) {
        currentYear = new Date(activeContractDatas?.[0]?.effectiveDate).getFullYear()
      }

      //let reviewCurrentBenefits = enrollmentPeriodKind === 'cOngoingEnrollment'?'Review your current benefits.':`Review and make changes to the current benefits for the year ${currentYear}.`
      let reviewCurrentBenefits = enrollmentPeriodKind === 'cOngoingEnrollment'?'Review your current benefits.':`Review and make changes to your current benefits.`
      element.value = reviewCurrentBenefits
      setDisplayValue(reviewCurrentBenefits)
    }
    if (element?.id === 'currentYearBenefits' && element?.name === 'currentBenefits') {
      let currentBenefits = widgetTitlewithCertificate
      element.value = currentBenefits
      setDisplayValue(currentBenefits)
    }
    if ((element?.id === 'currentYearBenefits2' && element?.name === 'currentBenefits2')
     || (element?.id === 'currentYearBenefits3' && element?.name === 'currentBenefits3')
     || (element?.id === 'currentYearBenefits4' && element?.name === 'currentYearBenefits4')
    ) {
      let widgetTitle = widgetTitlewithoutCertificate
      element.value = widgetTitle
      setDisplayValue(widgetTitle)
    }
    if (element?.id === 'currentBenefits' && element?.name === 'currentBenefits') {
      let currentBenefits = activeContractDatas.length > 0 ? widgetTitlewithCertificate:widgetTitlewithoutCertificate
      element.value = currentBenefits
      setDisplayValue(currentBenefits)
    }
    if (enrollmentType !== 'case14' && element?.id === 'currentYearBenefits4' && element?.name === 'currentYearBenefits4') {
      let currentYear = new Date().getFullYear()
      let currentBenefits = `Your Eligible Benefits`
      element.value = currentBenefits
      setDisplayValue(currentBenefits)
    }
    if ((props.element?.id === 'currentYearBenefits2' && props.element?.name === 'reviewCurrentBenefits2')
     || (props.element?.id === 'legalEntityNotEvengreen' && props.element?.name === 'legalEntityNotEvengreen')
    ) {
        const additionalDatas = GBPDetails?.contract?.additionalDatas;
        let qleApplicable = additionalDatas?.filter(e => e?.fieldCode === 'DD_00200');
        let isQle = false;
        if (qleApplicable?.length > 0 && qleApplicable?.[0]?.fieldValue?.length > 0) isQle = true
        if (!isQle ) {
          let currentBenefits = 'You are eligible to elect or change your benefits during the annual enrollment period.'
          element.value = currentBenefits
          setDisplayValue(currentBenefits)
        }
    }
    if (props.element?.id === 'daysLeftReview' && props.element?.name === 'daysLeftReview') {
      setEnrollmentPerionDetailsNew();
    }
    if (props.element?.id === 'newHireDaysRemaining' && props.element?.name === 'daysLeftReview') {
      setEnrollmentPerionDetails();
    }

    if (props.element?.name === 'LifeEventHeaderInfo') {
      let qleValue = `* You have ${windowDays} days from the date of selected life event to make changes to benefit elections.`
      element.value = qleValue
      setDisplayValue(qleValue)
    }

  }, [homepageEnrollmentPeriod, enrollmentType])


  let currentStyle = (element.config && element.config.style) || {};

  return props.element && props.element.hasSubtitle ? (
    <p style={currentStyle} className={`${element.config && element.config.className}`}>
      {displayValue}
      <span className='px-2 font-weight-normal'>{displaySubtitle}</span>
    </p>
  ) : (
    <span style={currentStyle} className={`${element.config && element.config.className}`}>
      {' '}
      {/* {} /*changed below line to fix display value error*/}
      {/* {(!hidden)&& {displayValue}} */}
      {displayValue}
    </span>
  );
};

Label.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  hidden: PropTypes.bool,
};
Label.defaultProps = {
  className: '',
};

export default Label;
