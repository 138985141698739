import http from '../../../../../../Common/Services/HttpService';
import { RELATIONSHIP_BASED_QUESTION } from '../../../../../../Store/Slices/Entities/response';
import { SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../../../../Store/Slices/InsuredInfo';
import appConfig from '../../../../../../appConfig';

const getInsuredAdditionalDataConfiguration = (processBUID, relationship, currentStep="") => async (dispatch) => {
  dispatch(UPDATE_LOADING(true));
  try{
  const response = await http.post(
    appConfig.SSP_MID_URL + '/getInsuredAdditionalDataConfiguration',
    {
      processBUID,
      relationship,
      currentStepInfo: {
        "portalStep": currentStep
      }
    }
  );

  const question = response?.data?._Result?.[0] || {};
  dispatch(RELATIONSHIP_BASED_QUESTION({ relationship, question }));
  } catch (e){
    console.error(e);
    dispatch(SET_ALERT_MESSAGE({
      type: 'error',
      errorCode: '520',
      continue: true,
      id: 'getInsuredAdditionalDataConfiguration',
      show: true,
      page: currentStep,
    }));
  } finally {
    dispatch(UPDATE_LOADING(false));
  }
};

export default getInsuredAdditionalDataConfiguration;
