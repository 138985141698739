import React from 'react';
import PropTypes from 'prop-types';
//import './styles.css';
import { Styles } from './styles';
import { getValueFromKeyString } from '../../Utils/Helper/GetValueFromKeyString';
import { Modal } from 'bootstrap';
import { Icon } from '../../Atoms';
import $ from 'jquery';
import { useState, useEffect } from 'react';
import Loader from '../../Atoms/Loader';
import appConfig from "../../../appConfig"
import {
  COUNT_DOWN,
  SHOW_RESET_EMAIL_TIMER,
  SHOW_RESET_PHONE_TIMER,
} from '../../../Store/Slices/StartTimer';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_CODE,
  CLEAR_INPUT,
  DISPLAY_EDIT_PHONE_ICON,
  DISPLAY_SUBMIT_CODE_BTN,
  EDIT_ELEMENT_ERROR,
  EDIT_EMAIL,
  EDIT_EMAIL_VALUE,
  EDIT_PHONE,
  EMAIL_BE_READ_ONLY,
  EMAIL_ERROR,
  HIDE_ANOTHER_METHOD,
  HIDE_VERIFY_BUTTON,
  HIDE_VERIFY_EBUTTON,
  HIDE_SEND_CODE_BUTTON,
  PHONE_BE_READ_ONLY,
  SET_PHONE_CODE_SUBMIT,
  SET_SUCCESS_EMAIL_CODE,
  SHOW_PHONE_SUBMIT_CODE_BTN,
  SHOW_SEND_CODE_BTN,
  SHOW_SUBMIT_CODE_BTN,
  HIDE_ERR_MSG,
  SET_EMAIL_VERFIED,
  VERIFIED_EMAIL_CLICKED,
  SUBMIT_EMAIL_CLICKED,
  SUBMIT_PHONE_CLICKED,
  SEND_CODE_PHONE_CLICKED,
  CHANGE_METHOD_CLICKED,
  SHOW_REGISTER_TIMER,
  SET_OTP_ERROR,
  SHOW_REGISTER_TIMER_PHONE,
  CONTINUE_BTN_CLICKED,
  CHANGE_METHOD_PHONE_CLICKED,
  SHOW_PHONE_OTP,
  HIDE_MSG,
  SHOW_EMAIL_OTP,
  EDIT_EMAIL_CLICKED,
  SET_LOGIN_OTP_METHOD,
  SET_PASSWORD_FAILED,
  CLEAR_PASSWORD,
  CLEA_RE_PASSWORD,
  CLEAR_RE_PASSWORD,
  SET_EMAIL_CODE,
  SET_LOGIN_OTP,
  SET_LOGIN_OTP_METHOD_RETRIEVE_USER,
  SET_TEMP_LOGIN_OTP_METHOD,
  RESET_REGISTERATION,
} from '../../../Store/Slices/Registeration';
import {
  CHANGE_BUTTON_WIDTH,
  DISABLE_CONTINUE_BUTTON,
  DISABLE_ENTER_CODE,
  DISABLE_PROCESS,
  DISABLE_SUBMIT_BUTTON,
  DISABLE_SUBMIT_CODE_BTN,
} from '../../../Store/Slices/DisableButton';
import { UPDATE_ENROLLMENT_TYPE, UPDATE_CURRENT_ENROLLMENT_TYPE, IS_QLE } from '../../../Store/Slices/Enrollment/index';
import toast from 'react-hot-toast';
import { CONFIG_SSO_LOGIN, logoutUser, SET_CALL_CENTER_ADMIN } from '../../../Store/Slices/Auth/userAuth';
import { processAuthTokenType } from '../../../Store/Slices/AuthToken/userAuthToken';
import { logoutUserEntity } from '../../../Store/Slices/Auth/loggedUserEntity';
import { APIRequestParallel, logoutUserResponse, onLoadAPI, } from '../../../Store/Slices/Entities/response';
import http from '../../../Common/Services/HttpService/index';
import {
  HIDE_COL,
  HIDE_EMAIL_CODE_INPUT,
  HIDE_ERROR_MESSAGE,
  HIDE_PHONE_CODE_INPUT,
  RESET_EMAIL_CODE_INPUT_ERR,
  RESET_PHONE_CODE_INPUT_ERR,
  SET_EMPLOYEE_VALIDATION_ERROR,
  SET_SEND_EMAIL_CHALLENGE_ERROR,
  SHOW_ALERT,
  SHOW_EMAIL_CODE_INPUT_AFTER_EDIT,
  SHOW_SUBMIT_CODE_BTN_REG,
  SET_EMAIL_EXIST,
  ACTIVATE_PHONE,
  EMAIL_VERIFIED,
  SET_HIDE_ON_LOAD,
  SET_HIDE_PHONE_ON_LOAD,
  SET_MFA_SERVICE_UNAVAILABLE,
  HAS_ERROR,

} from '../../../Store/Slices/RegisterationError';

import { RESET_PASSWORD, RETRIEVE_USER } from '../../../Store/Slices/ForgotUser';
import { HIDE_ROW } from '../../../Store/Slices/HideShowRow';
import {
  SET_DEPENDENT_ELIGIBILITY,
  SET_ELIGIBILITY_MESSAGES,
  DISABLE_ANSWERS_RADIO_STATUS,
  ENABLE_ANSWERS_RADIO_STATUS,
  SET_EDIT_ANSWERS,
  SKIP_PRODUCT,
  NOT_ELIGIBLE_DEPENDENT,
  SHOWED_ERROR,
} from '../../../Store/Slices/InsuredInfo';
import { SET_All_INACTIVE, UPDATE_LOADING, } from '../../../Store/Slices/InsuredInfo';
import { CLEAR_SSN, SET_SSN_VALUE } from '../../../Store/Slices/ControlSSN';
import { IS_DEPENDENT_REMOVE, IS_DEPENDENT_EDIT, WELCOME_SCREEN_WIDGET_BUTTON } from '../../../Store/Slices/EmployeeProfile';
import { useHistory } from 'react-router-dom';
import { SHOW_CONTENT_LOADER } from '../../../Store/Slices/Loader';
import { ENABLE_CONTINUE_BTN, HIDE_SUCCESS, OTP_CODE_SENT, SEND_CODE_CLICKED, SEND_CODE_MOBILE_CLICKED, SHOW_SPECIAL, RESET_CALLCENTER, } from '../../../Store/Slices/CallCenter';
import { STORE_IN_REDUX } from '../../../Store/Slices/Process/index';
import imageMap from '../../Molecules/CustomSection/Helper/imageHelper';
import { getFileName } from '../../Molecules/CustomSection/Helper/commonHelper';

const Button = (props) => {
  const {
    stpClick,
    id,
    name,
    hidden,
    hideElement,
    modalPopUp,
    buttonGroup,
    onClick,
    icon,
    onMouseEnter,
    onMouseLeave,
    className,
    children,
    content,
    loading = false,
    disabled,
    rounded,
    outline,
    setActivateButton,
    result,
    mulitpleIndex,
    noBackground,
    modalName,
    expression,
    formName = '',
    showContent = [],
    link,
    config = {},
    timer,
    shouldCallTimer,
    buttonForEmail,
    buttonForPhone,
    iconButton,
    images,
    buttonName,
    isRegisteration,
    backButton,
    shouldDisableContinue,
    shouldEnableContinue,
    buttonPurpose,
    onClickApiCall,
    errorFound,
    continueBtnSpecial,
    registerOrRetrieve,
    modalButton,
    contentDataForm,
    shouldShowOnYes,
    hideButton,
    flexPlacement,
    mfaButton,
    labelConfig,
    validationElements,
    iconStyle = {},
    showOnModalOnly = false,
    hideOnClickSendCodeBtn = false,
    showImage = false,
    imgStyle = {},
    removePadding = false,
    contrast,
    //currentStep,
    ...rest
  } = props;

  let history = useHistory();
  const dispatch = useDispatch();
  let tempValue = props?.formik?.values?.PetAssureNation?.petName;
  let valueCheck;
  let testAnswers = [];
  // if(name ==='DependentInformation.save') 
  let { disabledSubmitBtn, codeIsEntered, disableCrossSellBtn, disableRegisterationProcess } = useSelector(
    (storeState) => storeState.disableButton
  );

  const { hideRow, isYesradioSelected } = useSelector(
    (storeState) => storeState.registerationError
  );
  const radioSelectedValue = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValue
  );
  const radioSelectedValueIDTheft = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueIDTheft
  );
  const radioSelectedValueSupplementalHealthInsurance = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueSupplementalHealthInsurance
  );
  const radioSelectedValuePetPlus = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValuePetPlus
  );
  const radioSelectedValueLegalCoverage = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueLegalCoverage
  );
  const radioSelectedValuePetAssure = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValuePetAssure
  );
  const radioSelectedValueTravel = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueTravel
  );

  const radioSelectedValueCriticalIllness = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueCriticalIllness
  );
  const radioSelectedValueHospitalIdemnity = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueHospitalIndemnity
  );
  const radioSelectedValueExcessLiability = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiability
  );
  const radioSelectedValueExcessLiabilityUmUim = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiabilityUmUim
  );
  const { isYesradioDisclosureSelected } = useSelector(
    (storeState) => storeState.registerationError
  );
  const { isYesradioDisclosureSelectedIdTheft } = useSelector(
    (storeState) => storeState.registerationError
  ); 
  const { isYesradioDisclosureSelectedSupplementalHealthInsurance } = useSelector(
    (storeState) => storeState.registerationError
  );
  const { isYesradioDisclosureSelectedTravel } = useSelector(
    (storeState) => storeState.registerationError
  );

  const isYesradioVoyaPremier = useSelector(
    (storeState) => storeState?.employeeProfile?.isYesradioVoyaPremier
  );
  const { isYesradioDisclosureSelectedCriticalIllness } = useSelector(
    (storeState) => storeState.registerationError
  );
  const { isYesradioDisclosureSelectedHospitalIndemnity } = useSelector(
    (storeState) => storeState.registerationError
  );
  //const storeFromRedux = useSelector((storeState) => storeState);
  const employeeProfile = useSelector((storeState) => storeState?.employeeProfile);
  const entities = useSelector((storeState) => storeState?.entities);
  const auth = useSelector((storeState) => storeState?.auth);
  const legalEntity = auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.legalEntity?.identifier?.externalClientId?.externalClientId;
  const { isYesradioDisclosureExcessLiability, isYesradioDisclosureSelectedPetNation } =
    useSelector((storeState) => storeState.registerationError);
  const {
    policyRadioClicked,
    coverageRadioClicked,
    coverageRadioHospitalIndemnityClicked,
    coverageRadioExcessLiabilityClicked,
    policyRadioHospitalIndemnityClicked,
    showAlert,
    showSubmitCodeBtnEmailReg,
  } = useSelector((storeState) => storeState.registerationError);
  const beneficiaryError = useSelector(
    (storeState) => storeState?.employeeProfile?.beneficiaryError
  );
  const GBPDetails = useSelector(
    (storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result
  );
  const declinedCertificate = GBPDetails?.declinedCertificate || false;
  const GBPDetailsWithLastRenewal = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.gbpContractsWithLastRenewal?._Result
  );
  const continueBtnState = useSelector((storeState) => storeState.button)
  const { radioSelectedValuePetNation } = useSelector((storeState) => storeState.employeeProfile);

  const {
    underWritingQuestionOne,
    underWritingQuestionTwo,
    underWritingQuestionThree,
    underWritingQuestionFour,
  } = useSelector((storeState) => storeState.employeeProfile);

  const { setWaiveCoverageNation } = useSelector((storeState) => storeState.insuredInfo);
  const { petList } = useSelector((storeState) => storeState.employeeProfile);

  const stepError = useSelector((storeState) => storeState.registerationError);
  const insuredInfo = useSelector((storeState) => storeState?.insuredInfo);
  const { stepId, currentStep, stepKeys } = useSelector((storeState) => storeState.stepForm);
  const disableButton = useSelector((storeState) => storeState?.disableButton);

  let { hideContinueBtn, hideBackBtn } = useSelector((storeState) => storeState.stepForm);
  //let shouldNotDisable = currentStep === 'MFAForgotPassword' || currentStep === 'MultiFactorAuthentication';
  let {
    email,
    phone,
    editEmail,
    editPhone,
    emailError,
    phoneError,
    hideVerifyButton,
    hideVerifyEmailButton,
    hideSendCodeButton,
    displayEditPhoneIcon,
    displaySubmitCodeBtn,
    phoneBeReadOnly,
    emailCodeSubmitted,
    phoneCodeSubmitted,
    hideAnotherMethod,
    showSubmitCodeBtnEmail,
    showSubmitCodeBtnPhone,
    showSendCodeBtnPhone,
    emailVerified,
    phoneVerified,
    emailCode,
  } = useSelector((storeState) => storeState.registeration);
  const { adminEnrollmentClicked = false,
    contactType = '',
    otpCodeSent = false,
    enableContinueBtn = false,
    sendCodeMobileClicked = false,
    mobileBack = false,
    sendCode = false,
    emailOtp = false,
    enableSendCodeBtn,
    showSpecial,
    changeMobileOption,
    mobileOTPEntered,
    otp = ''
  } = useSelector((storeState) => storeState.callCenter);

  const [hideEditButton, setHideEditButton] = useState(false);
  const [isInValid, setIsValid] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  let { isAPICallInProgress } = useSelector((storeState) => storeState.entities.response);
  const registerationError = useSelector((storeState) => storeState?.registerationError);
  const startTimer = useSelector((storeState) => storeState?.startTimer);
  const registeration = useSelector((storeState) => storeState?.registeration);
  const getPendingProcess = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.GetPendingProcess?._Result?.[0]?.bUID
  );
  const eligibleProductsForMember = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.getListOfEligibleProductsForMember
  );
  const enrollmentType = useSelector(
    (storeState) => storeState?.employeeProfile?.enrollmentType
  );
  const reasonType = useSelector(
    (storeState) => storeState?.employeeProfile?.reasonType
  );
  let isEvengreenProduct = eligibleProductsForMember?.length > 0 ? eligibleProductsForMember?.every(element => element.isEvenGreen === true) : false

  const phoneEmptyError = () => toast.error('Sorry, Phone Number is required!', {});
  const previousStep = useSelector((storeState) => storeState.stepForm.previousStep);
  const { processType = '', callCenterAdmin = false, configuratorSSOLogin = false, userType = '' } = useSelector((storeState) => storeState.auth.userAuth);
  const { user_name = '', loggedUser = '' } = useSelector((storeState) => storeState.auth.userAuth?.data)
  useEffect(() => {
    if (name === 'MultiFactorAuthentication.submitButton') {
      //dispatch(SHOW_REGISTER_TIMER_PHONE(registerationError[currentStep]?.hasError));
    }
  }, [registerationError[currentStep]?.hasError === true]);
  useEffect(() => { }, [
    registeration.changeMethodClicked,
    registeration.sendPhoneClicked,
    registerationError.emailExist,
    registerationError.mfaErrorText,
    registeration.hideMsg, registerationError.emailExist,
    registerationError.activateFactor, registerationError.hidePhoneOnLoad, registeration.sendPhoneClicked,
    registerationError.submitPhoneClicked,
    registerationError[currentStep]?.hasError, registerationError.submitPhoneClicked,
    registerationError.phoneIsVerified, codeIsEntered, registerationError.showOtpErr,
    registerationError.mfaErrorText,
    registeration.hideMsg,
    enableSendCodeBtn,
    registeration.emailError,
    registeration.emailEntered,
    sendCodeMobileClicked
  ]);
  useEffect(() => {
    if (name === 'UserAccountVerifyEmail.submitButton') {
      dispatch(SHOW_REGISTER_TIMER(registerationError[currentStep]?.hasError));
    }
  }, [registerationError[currentStep]?.hasError === true]);
  useEffect(() => {
    if (name == 'continueFormButton' && currentStep == 'PetAssureNation') {
      props.formik.errors = {};
    }

    if (
      underWritingQuestionOne == false &&
      underWritingQuestionTwo == false &&
      underWritingQuestionThree == false &&
      underWritingQuestionFour == false
    ) {
      if (props?.formik?.errors) {
        props.formik.errors = {};
      }
    }
  }, [
    underWritingQuestionOne,
    underWritingQuestionTwo,
    underWritingQuestionThree,
    underWritingQuestionFour,
  ]);

  useEffect(() => {
    (name === 'UserAccountVerifyEmail.editEmail' ||
      name === 'MultiFactorAuthentication.editPhone') &&
      setHideEditButton(emailError);
  }, [emailError, buttonName]);

  useEffect(() => {
    if (phone && name === 'MultiFactorAuthentication.editPhone')
      dispatch(DISPLAY_EDIT_PHONE_ICON({ dispalyEditPhoneButton: true }));
    else dispatch(DISPLAY_EDIT_PHONE_ICON({ dispalyEditPhoneButton: false }));
  }, [phone]);
  useEffect(() => { }, [registerationError]);

  useEffect(() => {
    dispatch(
      SET_EMAIL_VERFIED(
        registerationError[currentStep] && !registerationError[currentStep].hasError
      )
    );
  }, [registerationError]);
  // let tempValue = props?.formik?.values?.PetAssureNation?.petName;
  // let valueCheck;
  useEffect(() => {
    if (currentStep == 'PetAssureNation') {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      valueCheck = specialChars.test(tempValue);
      setIsValid(valueCheck)
    }
  }, [tempValue]);

  const disableSendCodeForMobile = name === 'ContactDetails.sendCodeBtnMobile' &&
    (!enableSendCodeBtn) &&
    contactType === 'mobile' && currentStep === 'ContactDetails'
  const disableContinueForMobile = name === 'continueBtn' &&
    disableSendCodeForMobile &&
    contactType === 'mobile' && currentStep === 'ContactDetails'
  const disableForEmpty = showSpecial && !otp
  const disableContinueForEmail = name === 'continueBtn' &&
    (!enableContinueBtn || disableForEmpty) &&
    contactType === 'email' && currentStep === 'ContactDetails'

  let disableBtnForMobile = disableContinueForMobile || disableSendCodeForMobile
  let disableBtnForEmail = disableContinueForEmail
  let disableBtnForContactDetails = disableBtnForMobile || disableBtnForEmail


  let shouldNotDisable =
    currentStep === 'MFAForgotPassword' || currentStep === 'MultiFactorAuthentication' || currentStep === 'ContactDetails';
  let shouldControlRadio = currentStep === 'CoverageSelection' || 'HospitalIndemnity' || false;
  let noNeedToDisable =
    insuredInfo.currentScenario &&
    !(insuredInfo.currentScenario === 'cDefault' || insuredInfo.currentScenario === 'cThird');
  const error = () => toast.error('Please enter security code.');

  let disabledVerifyBtn;
  let label;
  let disabledNewBtn;

  let insuredInfoRadioSelectValue = insuredInfo.CriticalIllness?.waiveInsureCoverage;
  let insuredInfoCoverageSelectionRadioSelectValue =
    insuredInfo.CoverageSelection?.waiveInsureCoverage;
  let insuredInfoHospitalIndemnityRadioSelectValue =
    insuredInfo.HospitalIndemnity?.waiveInsureCoverage;

  const previousOngoingDate1 = GBPDetails?.contract?.additionalDatas?.filter((elm) => elm?.fieldCode === 'DD_00139')?.[0]?.fieldValue || ""
  const previousOngoingDate2 = GBPDetailsWithLastRenewal?.contract?.additionalDatas?.filter((elm) => elm?.fieldCode === 'DD_00139')?.[0]?.fieldValue || ""
  const previousOngoingDate = previousOngoingDate2 || previousOngoingDate1
  const enrollmentPeriodKind = GBPDetails?.currentEnrollmentPeriod?.enrollmentPeriod?.enrollmentPeriodKind;
  const lastRenewalDate = GBPDetails?.contract?.lastRenewalDate;
  const getContractChanges = GBPDetails?.getcontractchanges || false;
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  let isReviewOnly = false
  let planYearDate = ''
  if (lastRenewalDate) {
    const [year, month, date] = lastRenewalDate.split('-');
    planYearDate = month + '/' + date + '/' + year;
  }
  const additionalDatas = GBPDetails?.contract?.additionalDatas;
  let qleApplicable = additionalDatas?.filter(e => e?.fieldCode === 'DD_00200');
  let isQle = false;
  if (qleApplicable?.length > 0 && qleApplicable?.[0]?.fieldValue?.length > 0) isQle = true
  if ((enrollmentPeriodKind === 'cOngoingEnrollment' || enrollmentPeriodKind === 'cAnnualEnrollment') &&
    (Date.parse(today) > Date.parse(previousOngoingDate)) &&
    (Date.parse(today) < Date.parse(lastRenewalDate))
  ) {
    isReviewOnly = true
  }

  if (radioSelectedValue == 'Waive' && name == 'CoverageSelection.continueTableCoverageButton') {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  if (radioSelectedValueIDTheft == 'Waive' && name == 'IdTheft.continueTableCoverageButton') {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  if (radioSelectedValueSupplementalHealthInsurance == 'Waive' && name == 'SupplementalHealthInsurance.continueTableCoverageButton') {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  if (radioSelectedValuePetPlus == 'Waive' && name == 'PetPlus.continueTableCoverageButton') {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  if (
    radioSelectedValueLegalCoverage == 'Waive' &&
    name == 'LegalCoverage.continueTableCoverageButton'
  ) {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  if (radioSelectedValuePetAssure == 'Waive' && name == 'PetAssure.continueTableCoverageButton') {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  if (radioSelectedValueTravel == 'Waive' && name == 'Travel.continueTableCoverageButton') {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  if (
    radioSelectedValueCriticalIllness == 'Waive' &&
    name == 'CriticalIllness.continueTableCoverageButton'
  ) {
    // label = 'Continue to Next Product';
    label = 'Continue to Next Product';

    // disabledNewBtn = false;
  }
  if (
    radioSelectedValueHospitalIdemnity == 'Waive' &&
    name == 'HospitalIndemnity.continueTableCoverageButton'
  ) {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }

  if (
    isYesradioVoyaPremier === false &&
    name == 'WLCoverage.continueProductButton'
  ) {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  if (
    radioSelectedValueExcessLiability == 'Waive' &&
    name == 'ExcessLiability.continueTableCoverageButton'
  ) {
    label = 'Continue to Summary';

    // disabledNewBtn = false;
  }
  if (
    (radioSelectedValueExcessLiabilityUmUim == 'Waive' &&
      name == 'ExcessLiability.continueFormButton') ||
    name == 'ExcessLiability.continueFormButton' //ned to check!!!!!
  ) {
    label = 'Continue to Summary';

    // disabledNewBtn = false;
  }

  if (insuredInfoRadioSelectValue === true && name == 'CriticalIllness.continueProductButton') {
    label = 'Continue to Next Product';
    // label = 'Continue to Summary';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  if (
    insuredInfoCoverageSelectionRadioSelectValue === true &&
    name == 'CoverageSelection.continueProductButton'
  ) {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  if (radioSelectedValuePetNation === 'waive' && name == 'PetAssureNation.petSecondSave') {
    label = 'Save';
    // disabledNewBtn = false;
  }
  else if ((radioSelectedValuePetNation == 0 || radioSelectedValuePetNation == 1) && name == 'PetAssureNation.petSecondSave') {
    label = 'Next';
  }

  if (setWaiveCoverageNation === true && name == 'PetAssureNation.continueProductButton') {
    label = 'Continue to Next Product';
    if (previousStep === 'EnrollmentSummary') {
      label = 'Continue to Summary';
    }
    // disabledNewBtn = false;
  }
  let additionalClasses;

  if (radioSelectedValue == 'Waive') {
  }

  // let additionalClasses = (hideElement|| (shouldShowOnYes && !isYesradioDisclosureSelected)) ? 'hideElement' : classNameWrapper;

  if (
    name == 'CoverageSelection.nextProductButton' &&
    !shouldShowOnYes &&
    isYesradioSelected &&
    (radioSelectedValue != 'Waive' || radioSelectedValue != -1)
  ) {
    additionalClasses = ' ';
  }

  //Disable the button
  //   useEffect(() => {
  //   if((radioSelectedValue != 'Waive' && radioSelectedValue !=-1) && name =='continueTableCoverageButton'){
  //     disabled =true

  //   }
  // },[isYesradioSelected])

  // registerationError.hasError && registerationError.errorCount >= 3 && showAlert()
  let notEligible = false;
  let t;
  let test = [];

  //Disable the button

  let submitError = registerationError[currentStep]?.hasError;
  let emailExist =
    (name === 'UserAccountVerifyEmail.submitButton' &&
      registerationError.mfaErrorText === 'Request failed with status code 500' &&
      currentStep === 'UserAccountVerifyEmail') ||
    false;
  let reason = employeeProfile?.lifeEvent?.disabilityKindVersion;
  let ReasonEventDate = employeeProfile?.lifeEvent?.effectiveDate;
  let contractData = entities.response?.onLoadResponseList?.getMemberActiveContracts?._Result;
  let contractBuid = ""
  let memebercontractData = contractData && contractData
  const ApplyOnlyReworkedProduct = ['editNewEnrollmentDuringOE', 'editEnrollmentDuringOE', 'case10', 'case11', 'case12', 'case13', 'case14'].includes(enrollmentType);
  let activeContractDatas = memebercontractData && memebercontractData.length && memebercontractData?.filter(e => e?.status === 'cActive') || [];
  if (activeContractDatas?.length > 0) {
    contractBuid = activeContractDatas?.[0]?.contract?.buid
  }

  const initEndorsmentProcess = async (reason = '') => {
    dispatch(UPDATE_LOADING(true));
    return await http
      .post(appConfig.SSP_MID_URL + '/initEndorsementProcess', {
        payload: {
          reasonType: employeeProfile?.reasonType,
          enrollmentType: employeeProfile?.enrollmentType,
          contractBuid: contractBuid,
          reason: reason ? reason : employeeProfile?.reasonType,
        }
      })
      .then((resp) => {
        if (resp.data && resp.status === 200) {
          dispatch(onLoadAPI({ ...{ [`initEndorsementProcessData`]: resp.data, isAPICallInProgress: false } }));
        }
        dispatch(UPDATE_LOADING(false));
      }).catch(e => {
        dispatch(UPDATE_LOADING(false));
      });
  };




  const qleInitEndorsmentProcess = async () => {
    dispatch(UPDATE_LOADING(true));
    return await http
      .post(appConfig.SSP_MID_URL + '/initNewIndividualEndorsementProcess', {
        payload: {
          reason: reason,
          ReasonEventDate: ReasonEventDate,
          contractBuid: contractBuid,
          ApplyOnlyReworkedProduct: ApplyOnlyReworkedProduct
        }
      })
      .then((resp) => {
        if (resp.data && resp.status === 200) {
          dispatch(onLoadAPI({ ...{ [`initNewIndividualEndorsementProcess`]: resp.data, isAPICallInProgress: false } }));
        }
        dispatch(UPDATE_LOADING(false));
      }).catch(e => {
        dispatch(UPDATE_LOADING(false));
      });
  };

  const manageBenificiaryEndorsmentProcess = async (reason = '') => {
    dispatch(UPDATE_LOADING(true));
    return await http
      .post(appConfig.SSP_MID_URL + '/initEndorsementProcess', {
        payload: {
          reasonType: employeeProfile?.reasonType,
          enrollmentType: employeeProfile?.enrollmentType,
          contractBuid: contractBuid,
          reason: '1',
        }
      })
      .then((resp) => {
        if (resp.data && resp.status === 200) {
          dispatch(onLoadAPI({ ...{ [`beneficiaryinitEndorsementProcessData`]: resp.data, isAPICallInProgress: false } }));

        }
        dispatch(UPDATE_LOADING(false));
      }).catch(e => {
        dispatch(UPDATE_LOADING(false));
      });
  };
  const initNewEnrollmentProcess = async () => {
    dispatch(UPDATE_LOADING(true));
    return await http
      .post(appConfig.SSP_MID_URL + '/initNewEnrollmentProcess', {
        payload: {
          employeeBuid: auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.bUID,
          contractBuid: entities?.response?.onLoadResponseList?.subscribedcontracts?._Result?.[0]?.contract?.buid,
          reason: employeeProfile?.reasonType
        }
      })
      .then(async (resp) => {
        let UpdatedValue = []
        UpdatedValue.push(resp?.data)
        dispatch(STORE_IN_REDUX(UpdatedValue))
        dispatch(UPDATE_LOADING(false));
      }).catch(e => {
        dispatch(UPDATE_LOADING(false));
      });
  };

  const initNewEnrollmentProcessWithOE = async (reason) => {
    dispatch(UPDATE_LOADING(true));
    return await http
      .post(appConfig.SSP_MID_URL + '/initNewEnrollmentProcess', {
        payload: {
          employeeBuid: auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.bUID,
          contractBuid: entities?.response?.onLoadResponseList?.subscribedcontracts?._Result?.[0]?.contract?.buid,
          reason: reason ? reason : 'OE'
        }
      })
      .then(async (resp) => {
        let UpdatedValue = []
        UpdatedValue.push(resp?.data)
        dispatch(STORE_IN_REDUX(UpdatedValue))
        dispatch(UPDATE_LOADING(false));
      }).catch(e => {
        dispatch(UPDATE_LOADING(false));
      });
  };
  const qleInitNewEnrollmentProcess = async () => {
    dispatch(UPDATE_LOADING(true));
    return await http
      .post(appConfig.SSP_MID_URL + '/initNewEnrollmentProcess', {
        payload: {
          employeeBuid: auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.bUID,
          contractBuid: entities?.response?.onLoadResponseList?.subscribedcontracts?._Result?.[0]?.contract?.buid,
          reason: reason,
          reasonEventDate: ReasonEventDate,
          isqle: true
        }
      })
      .then(async (resp) => {
        let UpdatedValue = []
        UpdatedValue.push(resp?.data)
        dispatch(STORE_IN_REDUX(UpdatedValue))
        dispatch(UPDATE_LOADING(false));
      }).catch(e => {
        dispatch(UPDATE_LOADING(false));
      });
  };
  const getBasicAuthHeaderValue = () => {
    let authValue = process.env.REACT_APP_MFA_AUTH_USER;
    // Encoding into base64 using btoa(), which is available in browser environments
    let basicAuthHeaderValue;
    basicAuthHeaderValue = authValue ? btoa(authValue) : '';
    return basicAuthHeaderValue;
  };

  const logoutSSOUser = (mfa) => {
    let basicAuthorizationValue = getBasicAuthHeaderValue();
    http.get(appConfig.SSP_AUTH_URL + '/stateful/client/ppa/logoutTest', {
      withCredentials: true,
      headers: {
        'Authorization': `Basic ${basicAuthorizationValue}`,
        'X-REQUEST-ID': Math.random(200)
      }
    })
      .then((response) => {
        let urlFromResponse = response.data?.redirectionUrl;
        if (mfa !== 'mfa') {
          urlFromResponse && window.open(urlFromResponse, '_self');
        }

      })
      .catch((error) => {

      })
  }
  const logoutReleaseSession = () => {
    http.get(appConfig.SSP_MID_URL + '/releaseSession')
      .then((response) => {
        http.setHeaderAPICookies('');
        return response;
      })
      .catch((error) => {
        return error;
      })
  }
  function dateCheck(from, to, check) {
    var fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if ((cDate <= lDate && cDate >= fDate)) {
      return true;
    }
    return false;
  }
  const legalEntities = ['KCOBAA', 'KCOBAA2'];
  const welcomePopupText = (legalEntityId, effectiveDate) => {
    // let text = `Select 'No', if you are enrolling in benefits for the annual enrollment period. \n
    // Select 'Yes', if you are electing or updating your current voluntary benefits.`;
    // if (legalEntities.includes(legalEntityId)) {
    //   text = `Select 'No', to enroll/cancel benefits for the spring enrollment period. \n
    //   Select 'Yes', to update your current voluntary benefits.`;
    // }
    let text = `Click "Current Year" to make changes now to your existing benefits. \n
    Click "Annual Enrollment" to make your annual enrollment elections for the future plan year.`;
    return text;
  }
  // let submitError = registerationError[currentStep]?.hasError;
  const handleClick = (event) => {

    const btnName1 = String(event.target.getAttribute('name'));
    const btnName2 = String(event.target.getAttribute('buttonname'));

    if ((btnName1.substring(0, 8) == 'continue') || (btnName2.substring(0, 8) == 'continue') || (event.target.innerHTML === 'Back') || (event.target.innerHTML === 'Edit')) {
      window.scrollTo(0, 0);
    }

    if (props.parentButtonAction) {
      props.parentButtonAction(event, props.element);
    }
    event.stopPropagation();

    /** clear store state on logout */
    if (id === 'logoutNoButton') {
      logoutReleaseSession()
      if (processType === 'eelect' || callCenterAdmin || (userType === "Configurator" && configuratorSSOLogin)) { logoutSSOUser() }
      if (String(user_name).search('mfa_') !== -1 || String(loggedUser).search('mfa_') !== -1) { logoutSSOUser("mfa") }
      dispatch(logoutUser());
      dispatch(logoutUserEntity());
      dispatch(logoutUserResponse());
      dispatch(RETRIEVE_USER(false));
      dispatch(RESET_PASSWORD(false));
      http.setUserType('');
      http.setAuthTokenUserType('');
      http.setHeaderAPICookies('');
      dispatch(processAuthTokenType({ authTokenType: '' }))
      http.setHeaderValue('REMOTE_USER', '');
      dispatch(RESET_CALLCENTER());
      dispatch(CONFIG_SSO_LOGIN({ configuratorSSOLogin: false }))
      dispatch(SET_CALL_CENTER_ADMIN(false));
      dispatch(RESET_REGISTERATION())
    }
    if (id === 'dependentDeleteButton') {
      dispatch(IS_DEPENDENT_REMOVE(true));
    }
    if (id === 'dependentEditButton') {
      dispatch(IS_DEPENDENT_EDIT(true));
    }
    // if (name === 'DependentInformation.cancel' || name === 'DependentInformation.addDependent' || name === 'DependentInformation.save') {
    if (name === 'DependentInformation.addDependent' || name == 'CriticalIllness.addBeneficiary') {
      dispatch(CLEAR_SSN(true));
      dispatch(SET_SSN_VALUE(''));
    }
    if (buttonGroup) {
      setActivateButton(event.target.id);
    }
    if (props.callback) {
      let index = mulitpleIndex ? mulitpleIndex : 0;
      let id = getValueFromKeyString(result, rest?.element?.callback?.valueFrom, index) || '';
      props.callback(id);
    }
    if (modalName) {
      if (modalPopUp) {
        setShowLoader(true);
        {
          showLoader && <Loader loading={showLoader} />;
        }
        $(`#${modalName}`).modal('toggle');
        if (modalName !== 'addBeneficiary' || modalName !== 'addPet') {
          // $('#video1')?.get(0)?.play();
        }

        if (props && props.formik && currentStep !== 'CriticalIllness') {
          props.formik.resetForm();
        }
        // props.formik &&
        //   props.formik.setValues({
        //     [formName]: {
        //       ...props.formik.values[formName],
        //       [`${modalName}Validations`]: true,
        //     },
        // edit: false,
        // ...props.formik.values,
        // }
        // );
      } else {
        if (props.formik && props.formik.values && props.formik.values.hasOwnProperty('edit'))
          delete props.formik.values.edit;
        // setShowLoader(true);
        let propsValue = props.formik.values;
        if (
          props &&
          props.formik &&
          (currentStep === 'CriticalIllness' &&
            modalName === 'addBeneficiary' || currentStep === 'ManageBeneficiaries' &&
            modalName === 'addBeneficiary')
        ) {
          propsValue.CriticalIllness = {
            ...props.formik.values[formName],
            beneficiaryAddressLine1: '',
            beneficiaryAddressLine2: '',
            beneficiaryBirthDate: '',
            beneficiaryCity: '',
            beneficiaryCollateral: '',
            beneficiaryCountry: '',
            beneficiaryFirstName: '',
            beneficiaryGender: '',
            beneficiaryInfo: '',
            beneficiaryIrrevocable: '',
            beneficiaryLastName: '',
            beneficiaryMiddleName: '',
            beneficiaryOptions: 'individual',
            beneficiaryRelationship: 'SP',
            beneficiaryState: '',
            beneficiaryZipCode: '',
            SSN: '',
            mailingAddressOptions: 'no',
            liveInSameAddress: '',
          };
        }
        $(`#${modalName}`).modal('hide');
        props.formik &&
          props.formik.setValues({
            [formName]: {
              ...props.formik.values[formName],
              [`${modalName}Validations`]: false,
            },
            ...propsValue,
          });
        if (currentStep !== 'CriticalIllness') {
          props.formik.resetForm();
        }
      }
    }

    /** Reset timer when user click Send Code Button - Registeration */
    if (shouldCallTimer) {
      !emailError && dispatch(COUNT_DOWN({ start: true }));
    } else {
      dispatch(COUNT_DOWN({ start: false }));
    }

    /** Disable/enable submit code button in registeration */
    if (shouldDisableContinue) dispatch(DISABLE_SUBMIT_BUTTON({ disabled: true }));

    /** pop error when security code not entered */
    //id === 'submitButton' && !codeIsEntered && error();

    /** Clear old code value when user click Send Code Button - Registeration */
    if (buttonForEmail)
      dispatch(EDIT_EMAIL_VALUE({ editEmail: true, shouldClearInput: false, clearCode: '' }));

    if (buttonForPhone) {
      dispatch(EDIT_PHONE({ editPhone: true, shouldClearInput: false, clearCode: '' }));
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
    } else if (registerOrRetrieve) {
      dispatch(DISABLE_CONTINUE_BUTTON({ disabled: false }));
    }
    /** Name should be replaced by flag */
    if (
      name === 'MultiFactorAuthentication.editPhone' ||
      name === 'MFAForgotUsername.continueButton' ||
      name === 'MFAForgotPassword.continueButton'
    ) {
      dispatch(DISABLE_CONTINUE_BUTTON({ disabled: true }));
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
      dispatch(DISABLE_PROCESS({ disabled: true, currentStep: currentStep }));
    }
    if (name === 'MultiFactorAuthentication.sendCodeBtn') {
      dispatch(SET_HIDE_PHONE_ON_LOAD(true));
      dispatch(SUBMIT_PHONE_CLICKED(false));
      dispatch(DISPLAY_SUBMIT_CODE_BTN({ displaySubmitCodeBtn: true }));
    }
    if (name === 'ContactDetails.sendCodeBtnMobile') {
      dispatch(SEND_CODE_MOBILE_CLICKED(true));
      dispatch(SEND_CODE_CLICKED(true));
      dispatch(SET_HIDE_PHONE_ON_LOAD(true));
      dispatch(SUBMIT_PHONE_CLICKED(false));
      dispatch(DISPLAY_SUBMIT_CODE_BTN({ displaySubmitCodeBtn: true }));
      dispatch(DISPLAY_SUBMIT_CODE_BTN({ displaySubmitCodeBtn: true }));
    }

    /** Name should be replaced by flag */
    if (
      name === 'UserAccountVerifyEmail.verifyEmail' ||
      name === 'MultiFactorAuthentication.sendCodeBtn' ||
      name === 'MFAForgotPassword.continueButton' ||
      name === 'MFAForgotUsername.continueButton'
    ) {
      dispatch(CLEAR_INPUT({ shouldClearInput: true }));
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
      !emailError && dispatch(COUNT_DOWN({ start: true }));
      dispatch(HIDE_ERROR_MESSAGE(false));
      dispatch(HIDE_COL(false));
    }
    if (
      name === 'MFAForgotPassword.tryAnotherMethodButton' ||
      name === 'MFAForgotUsername.tryAnotherMethodButton'
    ) {
      dispatch(CLEAR_INPUT({ shouldClearInput: false }));
    }
    if (name === 'MultiFactorAuthentication.sendCodeBtn') {
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
      dispatch(SHOW_REGISTER_TIMER_PHONE(true));
      dispatch(SHOW_REGISTER_TIMER(false));
      dispatch(CHANGE_METHOD_CLICKED(false));
    }
    if (name === 'ContactDetails.sendCodeBtnMobile') {
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
      dispatch(SHOW_REGISTER_TIMER_PHONE(true));
      dispatch(SHOW_REGISTER_TIMER(false));
      dispatch(CHANGE_METHOD_CLICKED(false));
    }

    if (name === 'MFAForgotPassword.cancelButton' || name === 'MFAForgotUsername.cancelButton') {
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
      dispatch(COUNT_DOWN({ start: false }));
      dispatch(HIDE_ERROR_MESSAGE(false));
      dispatch(HIDE_COL(false));
    }
    if (
      name === 'MFAForgotPassword.tryAnotherMethodButton' ||
      name === 'MFAForgotUsername.tryAnotherMethodButton'
    ) {
      dispatch(COUNT_DOWN({ start: false }));
      dispatch(HIDE_ERROR_MESSAGE(true));
      dispatch(HIDE_COL(true));
    }
    name === 'UserAccountVerifyEmail.verifyEmail' &&
      email &&
      !emailError &&
      dispatch(EMAIL_BE_READ_ONLY(true));
    name === 'MultiFactorAuthentication.sendCodeBtn' && phone && dispatch(PHONE_BE_READ_ONLY(true));
    name === 'MFAForgotPassword.continueButton' && dispatch(EMAIL_BE_READ_ONLY(false));
    if (name === 'UserAccountVerifyEmail.verifyEmail' && emailError) dispatch(EMAIL_ERROR(true));
    disabledVerifyBtn = emailError;
    if (
      name === 'DependentInformation.save' ||
      name === 'DependentInformation.cancel' ||
      name === 'DependentInformation.addDependent'
    )
      dispatch(HIDE_ROW(false));
    if (name === 'DependentInformation.save')
      dispatch(SHOW_CONTENT_LOADER(true));
    if (name === 'CriticalIllness.save' || name === 'CriticalIllness.cancel')
      dispatch(HIDE_ROW(false));
    if (
      (name === 'CoverageSelection.continueProductButton' ||
        name === 'HospitalIndemnity.continueProductButton' ||
        name === 'CriticalIllness.continueProductButton') &&
      !noNeedToDisable &&
      stepId > 2
    ) {
      // if (name === 'CoverageSelection.continueProductButton' && !noNeedToDisable ) {

      let temp = [];
      let DependentInformation;
      // DependentInformation = stepId > 2 ? insuredInfo[currentStep].dependents : {};
      DependentInformation = insuredInfo[currentStep].dependents || {};

      let ltempLen = DependentInformation.filter((arr) => arr.questionsAnswers);

      let t = [];
      if (ltempLen.length > 0)
        for (let i = 0; i < ltempLen.length; i++) {
          // t[i] = insuredInfo[currentStep].selectedoptionId.filter(
          //   currentStep !== 'HospitalIndemnity'
          //     ? (arr) => arr.slice(0, 1) === String(i) && arr.includes('No') && !arr.includes('3')
          //     : (arr) => arr.slice(0, 1) === String(i) && arr.includes('Yes') && !arr.includes('3')
          // ); //?

          // temp[i] = insuredInfo[currentStep].selectedoptionId.filter(
          //   currentStep !== 'HospitalIndemnity'
          //     ? (arr) => arr.slice(0, 1) === String(i) && arr.includes('No') && !arr.includes('3')
          //     : (arr) => arr.slice(0, 1) === String(i) && arr.includes('Yes') && !arr.includes('3')
          // );
          //for (let i = 0; i < ltempLen.length; i++) {
          let option = [];
          let totalListOfAnswer = insuredInfo[currentStep]?.nonSharedAdditionalDatas?.[i]?.setPossibleValues
          if (typeof totalListOfAnswer !== "undefined" && totalListOfAnswer.length > 0) {
            totalListOfAnswer.map((e, index) => e.fieldName !== "SMOKSTATUS" && e.valueCode === "No" ? option.push(`${i}${index}${e.valueCode}`) : "")
          }
          t[i] = option;
          temp[i] = option;
          //}
        }
      if (
        t &&
        (t[0]?.length > 0 || t[1]?.length > 0 || insuredInfo[currentStep].waiveInsureCoverage)
      ) {
        shouldControlRadio && dispatch(DISABLE_ANSWERS_RADIO_STATUS({ currentStep: currentStep }));
      }
      if (shouldControlRadio) {
        if (temp.length > 0) {
          dispatch(SET_ELIGIBILITY_MESSAGES({ temp: temp, currentStep: currentStep }));
          !insuredInfo[currentStep].showedError &&
            dispatch(SET_DEPENDENT_ELIGIBILITY({ temp: temp, currentStep: currentStep }));
        }
        dispatch(SHOWED_ERROR({ status: true, currentStep: currentStep }));
      }
    }
    stepId > 2 &&
      insuredInfo[currentStep] &&
      insuredInfo[currentStep].waiveInsureCoverage &&
      shouldControlRadio &&
      dispatch(DISABLE_ANSWERS_RADIO_STATUS({ currentStep: currentStep }));
    if (
      (name === 'CoverageSelection.editInsuredSectionButton' ||
        name === 'HospitalIndemnity.editInsuredSectionButton' ||
        name === 'CriticalIllness.editInsuredSectionButton') &&
      stepId > 2
    ) {
      // name === 'HospitalIndemnity.editInsuredSectionButton') ) {
      let test = [];
      let DependentInformation;
      DependentInformation = stepId > 2 ? insuredInfo[currentStep].dependents : {};
      // DependentInformation =  insuredInfo[currentStep].dependents || {};

      let ltempLen = DependentInformation.filter((arr) => arr.questionsAnswers);
      if (ltempLen.length > 0)
        for (let i = 0; i < ltempLen.length; i++) {
          // insuredInfo[currentStep].selectedoptionId.filter((arr) =>
          //   currentStep !== 'HospitalIndemnity'
          //     ? arr.slice(0, 1) === String(i) && arr.includes('No')
          //       ? (test[i] = 1)
          //       : ''
          //     : arr.slice(0, 1) === String(i) && arr.includes('Yes')
          //     ? (test[i] = 1)
          //     : ''
          // );
          let totalListOfAnswer = insuredInfo[currentStep]?.nonSharedAdditionalDatas?.[i]?.setPossibleValues
          if (typeof totalListOfAnswer !== "undefined" && totalListOfAnswer.length > 0) {
            totalListOfAnswer.map((e, index) => e.fieldName !== "SMOKSTATUS" && e.valueCode === "No" ? (test[i] = 1) : "")
          }
          let t = [];
          // if (currentStep !== 'HospitalIndemnity') {
          //   t = insuredInfo[currentStep].selectedoptionId.filter(
          //     (arr) => arr.slice(0, 1) === String(i) && arr.includes('No')
          //   );
          // } else {
          //   t = insuredInfo[currentStep].selectedoptionId.filter(
          //     (arr) => arr.slice(0, 1) === String(i) && arr.includes('Yes')
          //   );
          // }
          if (typeof totalListOfAnswer !== "undefined" && totalListOfAnswer.length > 0) {
            totalListOfAnswer.map((e, index) => e.fieldName !== "SMOKSTATUS" && e.valueCode === "No" ? t.push(`${i}${index}${e.valueCode}`) : "")
          }
          let t1 = insuredInfo[currentStep].eligibilityMessages.filter(
            (arr) => arr.slice(0, 1) === String(i) && arr.includes('No')
          );
          t.length > 0 &&
            dispatch(
              ENABLE_ANSWERS_RADIO_STATUS({ index: i, editStatus: true, currentStep: currentStep })
            );
        }
      dispatch(SHOWED_ERROR({ status: false, currentStep: currentStep }));
    }
    let toggleEdit = false;
    // if (name !== 'CoverageSelection.continueProductButton' || noNeedToDisable)
    if (
      // !(name === 'CoverageSelection.continueProductButton' || name === 'HospitalIndemnity.continueProductButton' ) ||
      !(
        name === 'HospitalIndemnity.continueProductButton' ||
        name === 'CoverageSelection.continueProductButton' ||
        name === 'CriticalIllness.continueProductButton'
      ) ||
      (insuredInfo[currentStep].waiveInsureCoverage && !mfaButton)
    ) {
      onClick && onClick(event);
    }

    else if (stepId > 2) {
      let DependentInformation;
      // DependentInformation = stepId > 2 ? insuredInfo[currentStep].dependents : {};
      DependentInformation = insuredInfo[currentStep].dependents || {};

      toggleEdit = !toggleEdit;
      let ltempLen = DependentInformation.filter((arr) => arr.questionsAnswers);
      if (ltempLen.length > 0)
        for (let i = 0; i < ltempLen.length; i++) {
          // insuredInfo[currentStep].selectedoptionId.filter((arr) =>
          //   currentStep !== 'HospitalIndemnity'
          //     ? arr.slice(0, 1) === String(0) && arr.includes('No') && !arr.includes('3')
          //       ? (test[i] = 1)
          //       : ''
          //     : arr.slice(0, 1) === String(0) && arr.includes('Yes') && !arr.includes('3')
          //     ? (test[i] = 1)
          //     : ''
          // );
          let totalListOfAnswer = insuredInfo[currentStep]?.nonSharedAdditionalDatas?.[i]?.setPossibleValues
          if (typeof totalListOfAnswer !== "undefined" && totalListOfAnswer.length > 0) {
            totalListOfAnswer.map((e, index) => e.fieldName !== "SMOKSTATUS" && e.valueCode === "No" ? (test[i] = 1) : "")
          }

          dispatch(SET_EDIT_ANSWERS({ index: i, editStatus: false, currentStep: currentStep }));
        }
      dispatch(SET_All_INACTIVE({ currentStep: currentStep }));
      !insuredInfo[currentStep].waiveInsureCoverage &&
        test.length === 0 &&
        dispatch(SKIP_PRODUCT({ currentStep: currentStep }));
      !insuredInfo[currentStep].waiveInsureCoverage &&
        dispatch(NOT_ELIGIBLE_DEPENDENT({ currentStep: currentStep }));
      insuredInfo[currentStep].showedError &&
        onClick &&
        onClick(event, test.length > 0, insuredInfo[currentStep].waiveInsureCoverage);
      //dispatch(CHANGE_BUTTON_WIDTH(false));
    }
    //here
    if (name === 'ContactDetails.sendCodeBtnMobile') {
      dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnPhone: true }));
      dispatch(HIDE_VERIFY_EBUTTON(true));
      dispatch(RESET_PHONE_CODE_INPUT_ERR({ resetPhoneCodeInputErr: true }));
      dispatch(HIDE_PHONE_CODE_INPUT({ hidePhoneCodeInput: false }));
      dispatch(HIDE_SEND_CODE_BUTTON(true));
      dispatch(SEND_CODE_PHONE_CLICKED(true));
      dispatch(SUBMIT_PHONE_CLICKED(false));

      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
      dispatch(SHOW_REGISTER_TIMER_PHONE(true));
      dispatch(SHOW_REGISTER_TIMER(false));
      dispatch(DISPLAY_SUBMIT_CODE_BTN({ displaySubmitCodeBtn: true }));
      dispatch(DISPLAY_SUBMIT_CODE_BTN({ displaySubmitCodeBtn: true }));
      dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnPhone: true }));
      dispatch(RESET_PHONE_CODE_INPUT_ERR({ resetPhoneCodeInputErr: true }));
      dispatch(HIDE_ANOTHER_METHOD(false));
      dispatch(RESET_PHONE_CODE_INPUT_ERR({ resetPhoneCodeInputErr: true }));
      dispatch(HIDE_PHONE_CODE_INPUT({ hidePhoneCodeInput: false }));
      dispatch(
        HIDE_PHONE_CODE_INPUT({
          hidePhoneCodeInput: registerationError[currentStep] && !registerationError[currentStep].hasError,
        })
      );
      dispatch(SHOW_SEND_CODE_BTN({ showSendCodeBtnPhone: false }));
      dispatch(HIDE_SEND_CODE_BUTTON(true));
      dispatch(HIDE_SEND_CODE_BUTTON(true));
      dispatch(SHOW_PHONE_OTP(true));
      dispatch(SET_HIDE_PHONE_ON_LOAD(false));
    }

    if (name === 'MultiFactorAuthentication.editPhone') {
      dispatch(DISPLAY_EDIT_PHONE_ICON({ displayEditPhoneIcon: false }));
      dispatch(HIDE_ERR_MSG(true));
    } else dispatch(DISPLAY_EDIT_PHONE_ICON({ displayEditPhoneIcon: true }));

    if (name === 'UserAccountVerifyEmail.verifyEmail') {
      dispatch(HIDE_VERIFY_EBUTTON(true));
      dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnEmail: true }));
      dispatch(RESET_EMAIL_CODE_INPUT_ERR({ resetEmailCodeInputErr: true }));
      dispatch(HIDE_EMAIL_CODE_INPUT({ hideEmailCodeInput: false }));
      dispatch(DISPLAY_SUBMIT_CODE_BTN({ displaySubmitCodeBtn: true }));
      dispatch(VERIFIED_EMAIL_CLICKED(true));
      dispatch(SUBMIT_EMAIL_CLICKED(false));
      dispatch(SET_OTP_ERROR(false));
      dispatch(SHOW_EMAIL_OTP(true));
      dispatch(EMAIL_VERIFIED(false));
      dispatch(SET_HIDE_ON_LOAD(false))

    }

    if (name === 'UserAccountVerifyEmail.editEmail') {
      dispatch(EDIT_EMAIL_CLICKED(true));
      dispatch(HIDE_VERIFY_EBUTTON(true));
      dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnEmail: false }));
      dispatch(DISABLE_SUBMIT_CODE_BTN({ disableSubmitEmailBtn: false }));
      dispatch(DISPLAY_SUBMIT_CODE_BTN({ displaySubmitCodeBtn: false }));
      dispatch(HIDE_EMAIL_CODE_INPUT({ hideEmailCodeInput: true }));
      dispatch(SHOW_EMAIL_CODE_INPUT_AFTER_EDIT({ showEmailCodeInputAfterEdit: true }));
      dispatch(SET_EMAIL_CODE(''))
      /**handle register-password start*/
      dispatch(HAS_ERROR({ hasError: false }));
      dispatch(SET_EMPLOYEE_VALIDATION_ERROR({ hasError: false, clearCount: false, key: 'UserAccountVerifyEmail' }));
      dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: true, mfaErrorText: '' }));
      dispatch(SET_PASSWORD_FAILED(false))
      dispatch(CLEAR_PASSWORD(true))
      dispatch(CLEAR_RE_PASSWORD(true))
      /** end*/
    }
    if (name === 'MultiFactorAuthentication.sendCodeBtn') {
      dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnPhone: true }));
      dispatch(HIDE_VERIFY_EBUTTON(true));
      dispatch(RESET_PHONE_CODE_INPUT_ERR({ resetPhoneCodeInputErr: true }));
      dispatch(HIDE_PHONE_CODE_INPUT({ hidePhoneCodeInput: false }));
      dispatch(HIDE_SEND_CODE_BUTTON(true));
      dispatch(SEND_CODE_PHONE_CLICKED(true));
      dispatch(SUBMIT_PHONE_CLICKED(false));

      if (!phone) {
        phoneEmptyError();
        dispatch(DISPLAY_EDIT_PHONE_ICON({ displayEditPhoneIcon: false }));
      } else dispatch(DISPLAY_EDIT_PHONE_ICON({ displayEditPhoneIcon: true }));
      dispatch(DISABLE_ENTER_CODE({ codeIsEntered: false }));
      dispatch(SHOW_REGISTER_TIMER_PHONE(true));
      dispatch(SHOW_REGISTER_TIMER(false));
      dispatch(DISPLAY_SUBMIT_CODE_BTN({ displaySubmitCodeBtn: true }));
      dispatch(DISPLAY_SUBMIT_CODE_BTN({ displaySubmitCodeBtn: true }));
      dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnPhone: true }));
      dispatch(RESET_PHONE_CODE_INPUT_ERR({ resetPhoneCodeInputErr: true }));
      dispatch(HIDE_ANOTHER_METHOD(false));
      dispatch(RESET_PHONE_CODE_INPUT_ERR({ resetPhoneCodeInputErr: true }));
      dispatch(HIDE_PHONE_CODE_INPUT({ hidePhoneCodeInput: false }));
      dispatch(
        HIDE_PHONE_CODE_INPUT({
          hidePhoneCodeInput:
            registerationError[currentStep] && !registerationError[currentStep].hasError,
        })
      );
      dispatch(SHOW_SEND_CODE_BTN({ showSendCodeBtnPhone: false }));
      dispatch(HIDE_SEND_CODE_BUTTON(true));
      dispatch(HIDE_SEND_CODE_BUTTON(true));
      dispatch(SHOW_PHONE_OTP(true));

      dispatch(SET_HIDE_PHONE_ON_LOAD(false));


    }






    if (name === 'MultiFactorAuthentication.editPhone') {
      dispatch(SET_PHONE_CODE_SUBMIT(false));
      dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnPhone: false }));
      dispatch(SHOW_SEND_CODE_BTN({ showSendCodeBtnPhone: true }));
      dispatch(HIDE_SEND_CODE_BUTTON(false));
      dispatch(SEND_CODE_PHONE_CLICKED(false));

    }
    if (name === 'MultiFactorAuthentication.tryAnotherMethodBtn') {
      // dispatch(HIDE_ANOTHER_METHOD(true));
      // dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnPhone: false }));
      // dispatch(SHOW_SEND_CODE_BTN({ showSendCodeBtnPhone: true }));
      // dispatch(HIDE_SEND_CODE_BUTTON(false));
      dispatch(CHANGE_METHOD_CLICKED(true));
      dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: '', oktaErrorText: '', showInputErr: false, redirectToLogin: false }));
      dispatch(SHOW_REGISTER_TIMER_PHONE(true))
      dispatch(SET_LOGIN_OTP({ otp: '' }))
      //dispatch(SHOW_PHONE_OTP(false));
    }

    if (name === 'UserAccountVerifyEmail.editEmail') {
      dispatch(HIDE_VERIFY_EBUTTON(false));
      // dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnEmail: true }));
      dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnEmail: false }));

      dispatch(DISABLE_PROCESS({ disabled: true, currentStep: currentStep }));
      dispatch(SET_SUCCESS_EMAIL_CODE({ successEmailCode: false, emailCodeEntered: false }));
      dispatch(HIDE_EMAIL_CODE_INPUT({ hideEmailCodeInput: false }));
      dispatch(
        SET_EMPLOYEE_VALIDATION_ERROR({ hasError: false, clearCount: false, key: currentStep })
      );
    }
    if (name === 'MultiFactorAuthentication.editPhone') {
      dispatch(HIDE_VERIFY_BUTTON(false));
      //dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnPhone: true }));
      dispatch(DISABLE_PROCESS({ disabled: true, currentStep: currentStep }));
      dispatch(SET_SUCCESS_EMAIL_CODE({ successPhoneCode: false, phoneCodeEntered: false }));
      dispatch(HIDE_PHONE_CODE_INPUT({ hidePhoneCodeInput: false }));
      dispatch(
        SET_EMPLOYEE_VALIDATION_ERROR({ hasError: false, clearCount: false, key: currentStep })
      );
    }
    if (name === 'undefined.resendSecurityCodeButton') {
      dispatch(SHOW_RESET_EMAIL_TIMER(false));
      dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnEmail: true }));
    }
    if (name === 'undefined.manageBeneficiary') {
      manageBenificiaryEndorsmentProcess()
      history.push("/enrollment/manageBeneficiaries");
    }

    const openreenrollment = enrollmentType === "open-reenrollment" && reasonType === "AE"

    if (name === 'undefined.reviewUpdate') {
      initEndorsmentProcess()
      dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
      dispatch(WELCOME_SCREEN_WIDGET_BUTTON(name));
      history.push("/enrollment/employeeProfile");
    }
    if (name == '.LifeEventYesButton') {
      qleInitEndorsmentProcess()
      dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
      dispatch(WELCOME_SCREEN_WIDGET_BUTTON(name));
    }
    if (name == '.LifeEventYesButton2') {
      qleInitNewEnrollmentProcess()
      history.push("/enrollment/employeeProfile");
      dispatch(IS_QLE(true))
    }


    if (name == 'undefined.currentYearReviewUpdate') {
      dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
      dispatch(WELCOME_SCREEN_WIDGET_BUTTON(name));
    }
    // if (name === 'undefined.Get Started' && openreenrollment) {
    //   initEndorsmentProcess()
    //   dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
    //   dispatch(WELCOME_SCREEN_WIDGET_BUTTON(name));
    // }
    if (name === '.YesButton') {
      $('#welcomeModel').modal('hide')
      if (enrollmentType === 'open-reenrollment') {
        let enrollmentPeriod = auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.employee?.additionalDynamicDatas
        let dateArray = enrollmentPeriod?.filter(e => e.value.fieldName === 'ENROLWINSTDATE' || e.value.fieldName === 'ENROLWINEDDATE');
        let isEnrollmentPeriod = dateCheck(dateArray?.[0]?.value?.fieldValue, dateArray?.[1]?.value?.fieldValue, today);
        initEndorsmentProcess(isEnrollmentPeriod ? 'NH' : 'OE')
        dispatch(UPDATE_CURRENT_ENROLLMENT_TYPE(isEnrollmentPeriod ? 'NH' : 'OE'));
        dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
        history.push("/enrollment/employeeProfile");
        dispatch(WELCOME_SCREEN_WIDGET_BUTTON(name));
      } else {
        let members = entities?.response?.onLoadResponseList?.getListOfEligibleProductsForMember || []
        members = members?.filter(element => element.isEvenGreen === true)
        if (members.length === 0 && enrollmentType === 'case14' && isQle) {
          history.push("/enrollment/lifeEvent");
        } else {
          initNewEnrollmentProcessWithOE(declinedCertificate && enrollmentType === 'case5' ? 'NH' : '')
          history.push("/enrollment/employeeProfile");
        }

      }
    }
    if (name === '.NoButton') {
      if (enrollmentType === 'open-reenrollment') {
        $('#welcomeModel').modal('hide')
        initEndorsmentProcess()
        dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
        dispatch(WELCOME_SCREEN_WIDGET_BUTTON(name));
        history.push("/enrollment/employeeProfile");
      } else {
        $('#welcomeModel').modal('hide')
        initNewEnrollmentProcess()
        history.push("/enrollment/employeeProfile");
      }
    }
    if (name === 'undefined.Get Started' && !openreenrollment) {
      if ((enrollmentType === 'case14') && !getContractChanges && isQle) {
        $('#welcomeModel').modal('show').find('.modalDialog').removeClass('logoutModalDialog');
        $('#welcomeModel').modal('show').find('.modalDialog').addClass('welcomeDialog');
        $('#welcomeModel').modal('show').find('.welcomeModel').text(welcomePopupText(legalEntity, planYearDate));
      } else if ((enrollmentType === 'case5') && declinedCertificate && !getContractChanges) {
        $('#welcomeModel').modal('show').find('.modalDialog').removeClass('logoutModalDialog');
        $('#welcomeModel').modal('show').find('.modalDialog').addClass('welcomeDialog');
        $('#welcomeModel').modal('show').find('.welcomeModel').text(welcomePopupText(legalEntity, planYearDate));
      } else {
        initNewEnrollmentProcess()
        history.push("/enrollment/employeeProfile");
      }
    }

    if ((name === 'undefined.GetStartedCurrentYear' && openreenrollment) || (name === 'undefined.Get Started' && openreenrollment)) {
      if (name === 'undefined.Get Started' && openreenrollment) {
        if ((enrollmentType === 'open-reenrollment') && !getContractChanges) {
          $('#welcomeModel').modal('show').find('.modalDialog').removeClass('logoutModalDialog');
          $('#welcomeModel').modal('show').find('.modalDialog').addClass('welcomeDialog');
          $('#welcomeModel').modal('show').find('.welcomeModel').text(welcomePopupText(legalEntity, planYearDate));
        } else {
          initEndorsmentProcess()
          dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
          dispatch(WELCOME_SCREEN_WIDGET_BUTTON(name));
          history.push("/enrollment/employeeProfile");
        }
      } else {
        initEndorsmentProcess()
        dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
        dispatch(WELCOME_SCREEN_WIDGET_BUTTON(name));
        history.push("/enrollment/employeeProfile");
      }
    }
    if (name === 'undefined.GetStartedCurrentYear' && !openreenrollment) {
      //initNewEnrollmentProcess()
      initNewEnrollmentProcessWithOE(declinedCertificate && enrollmentType === 'case5' ? 'NH' : '')
      history.push("/enrollment/employeeProfile");
    }
    if (name === 'undefined.currentYearReviewUpdate' && isReviewOnly) {
      dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
      history.push("/enrollment/employeeProfile");
    }
    else if (name === 'undefined.currentYearReviewUpdate' && !isReviewOnly) {
      let enrollmentPeriod = auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.employee?.additionalDynamicDatas
      let dateArray = enrollmentPeriod?.filter(e => e.value.fieldName === 'ENROLWINSTDATE' || e.value.fieldName === 'ENROLWINEDDATE');
      let isEnrollmentPeriod = dateCheck(dateArray?.[0]?.value?.fieldValue, dateArray?.[1]?.value?.fieldValue, today);
      initEndorsmentProcess(isEnrollmentPeriod ? 'NH' : 'OE')
      dispatch(UPDATE_CURRENT_ENROLLMENT_TYPE(isEnrollmentPeriod ? 'NH' : 'OE'));
      dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
      history.push("/enrollment/employeeProfile");
      dispatch(WELCOME_SCREEN_WIDGET_BUTTON(name));
    }

    if (name === 'undefined.resendPhoneSecurityCodeButton') {
      dispatch(SHOW_RESET_PHONE_TIMER(false));
      dispatch(SHOW_PHONE_SUBMIT_CODE_BTN({ showSubmitCodeBtnPhone: true }));
    }

    if (name === 'undefined.Get Started' && !openreenrollment) {
      dispatch(UPDATE_ENROLLMENT_TYPE("newHire"));
    }
    if (name === 'undefined.GetStartedCurrentYear' && !openreenrollment) {
      dispatch(UPDATE_ENROLLMENT_TYPE("newHire"));
      dispatch(WELCOME_SCREEN_WIDGET_BUTTON(name));
    }
    if (name === 'undefined.Resume') {
      dispatch(UPDATE_ENROLLMENT_TYPE("resume"));
    }

    if (
      (name === 'MultiFactorAuthentication.submitButton' &&
        !registerationError[currentStep]?.hasError) ||
      name === 'MultiFactorAuthentication.editPhone'
    ) {
      dispatch(
        SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnPhone: registerationError[currentStep]?.hasError })
      );
    }
    if (name === 'MultiFactorAuthentication.submitButton') {
      dispatch(
        SHOW_SUBMIT_CODE_BTN({
          showSubmitCodeBtnPhone: registerationError.mfaErrorText ? true : false,
        })
      );
      dispatch(DISABLE_PROCESS({ disabled: false, currentStep: currentStep }));
      dispatch(HIDE_ANOTHER_METHOD(!registerationError[currentStep]?.hasError));
      dispatch(SHOW_SEND_CODE_BTN({ showSendCodeBtnPhone: false }));
      dispatch(SUBMIT_PHONE_CLICKED(true));
      dispatch(SHOW_REGISTER_TIMER_PHONE(registerationError[currentStep]?.hasError));
      dispatch(SHOW_PHONE_OTP(false));
      if (registerationError.hasError)
        dispatch(ACTIVATE_PHONE(false));
      else dispatch(ACTIVATE_PHONE(true));

      dispatch(SET_HIDE_PHONE_ON_LOAD(!registerationError[currentStep]?.hasError))
      //dispatch(SEND_CODE_PHONE_CLICKED(false));

    }
    if (name === 'UserAccountVerifyEmail.submitButton') {
      dispatch(DISABLE_PROCESS({ disabled: false, currentStep: currentStep }));
      dispatch(SHOW_SUBMIT_CODE_BTN({ showSubmitCodeBtnEmail: submitError }));
      dispatch(SET_EMAIL_VERFIED(!submitError));
      dispatch(SUBMIT_EMAIL_CLICKED(true));

      dispatch(HIDE_ANOTHER_METHOD(true));
      dispatch(
        SET_SUCCESS_EMAIL_CODE({
          successEmailCode: !submitError,
          emailCodeEntered: true,
        })
      );
      let inputError = registerationError.errorCount && registerationError[currentStep].hasError;
      // dispatch(
      //   HIDE_EMAIL_CODE_INPUT({
      //     hideEmailCodeInput:
      //       registerationError[currentStep] && !registerationError[currentStep].hasError,
      //   })
      // );
      dispatch(SHOW_REGISTER_TIMER(registerationError[currentStep]?.hasError));
      dispatch(SHOW_SEND_CODE_BTN({ showSendCodeBtnEmail: false }));
      dispatch(SHOW_EMAIL_OTP(false));
    }
    if (name === 'UserAccountVerifyEmail.editEmail') {
      dispatch(HIDE_EMAIL_CODE_INPUT({ hideEmailCodeInput: true }));
      dispatch(SHOW_REGISTER_TIMER(false));
    }
    if (name === 'UserAccountVerifyEmail.verifyEmail' || name === 'MultiFactorAuthentication.editPhone') {
      dispatch(SHOW_REGISTER_TIMER(true));
      dispatch(SET_OTP_ERROR(false));
    }
    if (name === 'UserAccountVerifyEmail.submitButton' || name === 'MultiFactorAuthentication.submitButton' ||
      name === 'UserAccountVerifyEmail.submitButton') {
      dispatch(SHOW_REGISTER_TIMER(registerationError[currentStep]?.hasError));
    }
    if ((name === 'MultiFactorAuthenticationLogin.tryAnotherMethodButton' && currentStep === 'MultiFactorAuthenticationLogin') ||
      (name === 'MFAForgotPassword.tryAnotherMethodButton' && currentStep === 'MFAForgotPassword') ||
      (name === 'MFAForgotUsername.tryAnotherMethodButton' && currentStep === 'MFAForgotUsername')
    ) {
      dispatch(CONTINUE_BTN_CLICKED({ tryAnotherMothod: true, currentStep: currentStep })) //managa err msg visibility
    }
    if (
      ((name === 'MultiFactorAuthenticationLogin.cancelButton' || name === 'MultiFactorAuthenticationLogin.continueButton')
        && currentStep === 'MultiFactorAuthenticationLogin') ||
      ((name === 'MFAForgotPassword.cancelButton' || name === 'MFAForgotPassword.continueButton')
        && currentStep === 'MFAForgotPassword') ||
      ((name === 'MFAForgotUsername.cancelButton' || name === 'MFAForgotUsername.continueButton')
        && currentStep === 'MFAForgotUsername')) {
      dispatch(CONTINUE_BTN_CLICKED({ tryAnotherMothod: false, currentStep: currentStep })) //managa err msg visibility
    }
    if (name === 'UserAccountVerifyEmail.verifyEmail') {
      if (currentStep === 'UserAccountVerifyEmail') {
        dispatch(EDIT_EMAIL_CLICKED(false));
      }
    }

    if (name === 'ContactDetails.sendCodeBtnMobile') {
      dispatch(SHOW_SPECIAL(true));
      dispatch(SEND_CODE_MOBILE_CLICKED(true));
    }


    if (name === 'continueBtn' && currentStep === 'ContactDetails') {
      dispatch(HIDE_SUCCESS(false));
      //dispatch(SEND_CODE_MOBILE_CLICKED(true));
    }
    if (name === 'ContactDetails.sendCodeBtnMobile') {
      dispatch(OTP_CODE_SENT(true));
      //dispatch(ENABLE_CONTINUE_BTN(true));
    }
    /** change OTP method for change MFA contact */
    if (name === 'MultiFactorAuthenticationLogin.continueButton' ||
      name === 'MFAForgotPassword.continueButton' //||
      //name === 'MFAForgotUsername.continueButton'
    ) {
      let loginOTPMethod = registeration.tempLoginOtpMethod || 'email'
      dispatch(SET_LOGIN_OTP_METHOD(loginOTPMethod));
    }
    // if (name === 'MFAForgotUsername.continueButton') {
    //   let loginOTPMethod = registeration.preferredChannel || 'call'
    //   dispatch(SET_LOGIN_OTP_METHOD(loginOTPMethod));
    // }
    // if (name === 'MFAForgotUsername.continueButton' || name === 'undefined.resendPhoneSecurityCodeButton') {
    //   alert('MFAForgotUsername continue ')
    //   let loginOTPMethod = registeration.tempLoginOtpMethod || registeration.preferredChannel || 'call'
    //   dispatch(SET_LOGIN_OTP_METHOD_RETRIEVE_USER(loginOTPMethod));
    // }
    if (name === 'MultiFactorAuthenticationLogin.continueButton')
      dispatch(COUNT_DOWN({ start: true }));

    if (name === 'MultiFactorAuthentication.sendCodeBtn') {
      dispatch(SHOW_REGISTER_TIMER_PHONE(true));
      dispatch(SET_LOGIN_OTP({ otp: '' }));
    }

  };
  const handleMouseEnter = (event) => {
    onMouseEnter && onMouseEnter(event);
  };

  const handleMouseLeave = (event) => {
    onMouseLeave && onMouseLeave(event);
  };

  let classes = ' btn ';
  let buttonId = buttonGroup ? { id: id } : '';
  if (rounded) {
    classes += ' py-2 px-4 moreRounded ';
  }
  if (outline) {
    classes += ' btn-outline-primary primaryOutline ';
  }
  else if (contrast) {
    classes += ' btn-primary-contrast ';
  }
  //added this for button as link
  else if (link) {
    if (removePadding)
      classes += ' btn btn-link px-0 '
    else
      classes += ' btn btn-link ';
    // classes += 'btn btn-link'
  } else if (iconButton) {
    classes += ' btn ';
    // classes += 'btn btn-link'
  } else if (noBackground) {
    classes += ' ';
  } else if (!buttonGroup) {
    classes += ' btn-primary primaryBackground ';
  }

  if (disabled) {
    classes += ' disabledButton ';
  }
  classes += className;

  if (result && Object.keys(result).length != 0 && rest?.element?.conditionalRender) {
    let index = mulitpleIndex ? mulitpleIndex : 0;
    let value = getValueFromKeyString(result, rest.element.conditionalRender.valueFrom, index);
    let hide = rest.element.conditionalRender.hide;
    if (value == hide) {
      return null;
    }
  }

  let imgSrc;
  try {
    imgSrc = imageMap[getFileName(images.filename)] || '';
  } catch (e) {
    imgSrc = '';
  }
  /** Determine if continue button disbaled/not based on input  */
  const disableContinueForRetrieveUser =
    currentStep === 'EmployeeValidationForgotUserID' && registeration.disableContinueBtn;
  const disableBtn = ((disableButton[currentStep] || showAlert) && currentStep !== 'MFAForgotPassword' ||
    !registerationError.phoneIsVerified && currentStep === 'MultiFactorAuthentication')
  const disabledSubmitButton =
    name === 'MultiFactorAuthentication.submitButton' && disabledSubmitBtn;
  const disabledContinueButton =
    // (name === 'registerationBtn' || name === 'continueBtn') && (disableRegisterationProcess || showAlert);
    name === 'registerationBtn' && disableBtn;
  // (disableButton[currentStep] || showAlert);
  const disableSendCodeBtn =
    name === 'MultiFactorAuthentication.sendCodeBtn' &&
    currentStep === 'MultiFactorAuthentication' &&
    !phone;
  const shouldDisableButton =
    disabledVerifyBtn ||
    disabledSubmitButton ||
    disabledContinueButton ||
    ((name === 'CoverageSelection.continueProductButton' ||
      name === 'CriticalIllness.continueProductButton' ||
      name === 'HospitalIndemnity.continueProductButton') &&
      stepId > 2 &&
      !(
        insuredInfo[currentStep]?.waiveInsureCoverage ||
        insuredInfo[currentStep]?.continueButtonEnable ||
        insuredInfo[currentStep]?.questionsAnsweredForAll
      )) ||
    (name === 'CoverageSelection.continueTableCoverageButton' &&
      !(
        (policyRadioClicked !== null && coverageRadioClicked !== null) ||
        radioSelectedValue === 'Waive' ||
        !radioSelectedValue == -1
      )) ||
    (name === 'HospitalIndemnity.continueTableCoverageButton' &&
      !(
        (policyRadioHospitalIndemnityClicked !== null &&
          coverageRadioHospitalIndemnityClicked !== null) ||
        radioSelectedValueHospitalIdemnity === 'Waive' ||
        !radioSelectedValueHospitalIdemnity == -1
      )) ||
    (name === 'IdTheft.continueTableCoverageButton' && radioSelectedValueIDTheft == -1) ||
    (name === 'SupplementalHealthInsurance.continueTableCoverageButton' && radioSelectedValueSupplementalHealthInsurance == -1) ||
    (name === 'PetPlus.continueTableCoverageButton' && radioSelectedValuePetPlus == -1) ||
    (name === 'LegalCoverage.continueTableCoverageButton' &&
      radioSelectedValueLegalCoverage == -1) ||
    (name === 'Travel.continueTableCoverageButton' && radioSelectedValueTravel == -1) ||
    (name === 'CriticalIllness.continueTableCoverageButton' &&
      radioSelectedValueCriticalIllness == -1) ||
    (name === 'CriticalIllness.continueTableCoverageButton' && beneficiaryError) ||
    (name === 'PetAssure.continueTableCoverageButton' && radioSelectedValuePetAssure == -1) ||
    (name === 'Travel.continueTableCoverageButton' && radioSelectedValueTravel == -1) ||
    (name === 'ExcessLiability.continueTableCoverageButton' &&
      radioSelectedValueExcessLiability == -1) ||
    (name === 'ExcessLiability.continueFormButton' &&
      radioSelectedValueExcessLiabilityUmUim == -1) ||
    (name === 'CoverageSelection.continueFormButton' &&
      !(isYesradioDisclosureSelected === true || isYesradioDisclosureSelected == false)) ||
    (name === 'IdTheft.continueFormButton' &&
      !(
        isYesradioDisclosureSelectedIdTheft === true ||
        isYesradioDisclosureSelectedIdTheft === false
      )) ||
    (name === 'SupplementalHealthInsurance.continueFormButton' &&
      !(
        isYesradioDisclosureSelectedSupplementalHealthInsurance === true ||
        isYesradioDisclosureSelectedSupplementalHealthInsurance === false
      )) ||
    (name === 'CriticalIllness.continueFormButton' &&
      !(
        isYesradioDisclosureSelectedCriticalIllness === true ||
        isYesradioDisclosureSelectedCriticalIllness === false
      )) ||
    //name="HospitalIndemnity.continueProductButton"

    (name === 'HospitalIndemnity.continueFormButton' &&
      !(
        isYesradioDisclosureSelectedHospitalIndemnity === true ||
        isYesradioDisclosureSelectedHospitalIndemnity === false
      )) ||
    (name === 'HospitalIndemnity.continueFormButton' &&
      !(
        isYesradioDisclosureSelectedHospitalIndemnity === true ||
        isYesradioDisclosureSelectedHospitalIndemnity === false
      )) ||
    (name === 'PetAssureNation.petSecondSave' &&
      (radioSelectedValuePetNation === -1 ||
        radioSelectedValuePetNation === '' ||
        radioSelectedValuePetNation === undefined)) ||
    (name === 'PetAssureNation.petThirdSave' && props.formik.values.PetAssureNation.underwritingQuestionOne === '') ||
    (name === 'PetAssureNation.petThirdSave' && props.formik.values.PetAssureNation.underwritingQuestionTwo === '') ||
    (name === 'PetAssureNation.petThirdSave' && props.formik.values.PetAssureNation.underwritingQuestionThree === '') ||
    (name === 'PetAssureNation.petThirdSave' && props.formik.values.PetAssureNation.underwritingQuestionFour === '') ||
    (name === 'PetAssureNation.petThirdSave' && props.formik.values.PetAssureNation.petHospital === '') ||
    (name === 'PetAssureNation.continueFormButton' &&
      isYesradioDisclosureSelectedPetNation === '') ||
    (name === 'PetAssureNation.petFirstSave' &&
      props.formik.values.PetAssureNation.petName == '') ||
    (name === 'PetAssureNation.petFirstSave' &&
      props.formik.values.PetAssureNation.petType == '') ||
    (name === 'PetAssureNation.petFirstSave' &&
      props.formik.values.PetAssureNation.petBreed == '') ||
    (name === 'PetAssureNation.petFirstSave' &&
      (props.formik.values.PetAssureNation.petAge <= 0 ||
        props.formik.values.PetAssureNation.petAge > 20)) ||
    (name === 'PetAssureNation.petFirstSave' && isInValid) ||
    (name === 'PetAssureNation.petFirstSave' && props.formik.values.PetAssureNation.petAge == '') ||
    (name === 'PetAssureNation.continueProductButton' &&
      setWaiveCoverageNation == '' &&
      petList.length < 1) ||
    (name === 'PetAssureNation.continueFormButton' &&
      setWaiveCoverageNation == '' &&
      petList.length < 1) ||
    (name === 'WLCoverage.continueProductButton' &&
      (
        isYesradioVoyaPremier === ""

      )) ||
    (name === 'continueBtn' && disabled) ||
    (name === 'UserAccountVerifyEmail.verifyEmail' && (emailError || !email)) ||
    (name === 'continueBtn' && currentStep === 'CrossSell' && disableCrossSellBtn) ||
    (name === 'UserAccountVerifyEmail.submitButton' &&
      (!emailCodeSubmitted || disableButton.disableSubmitEmailBtn)) ||
    (name === 'MultiFactorAuthentication.submitButton' &&
      (!phoneCodeSubmitted || disableButton.disableSubmitPhoneBtn)) ||
    (name === 'registerationBtn' && disabled) ||
    (!phoneVerified && currentStep === 'MultiFactorAuthentication' && name === 'registerationBtn') ||
    ((registeration.clearPassword || registeration.clearRePassword) && currentStep === 'UserAccountVerifyEmail' && name === 'registerationBtn') ||

    disableSendCodeBtn;

  let addClasses;
  let adClasses;

  if (name == 'CoverageSelection.continueTableCoverageButton' && radioSelectedValue) {
    additionalClasses = 'hideElement ';
  }
  {
    /** bellow logic not clear , need to discuss with dev, */
  }

  if (
    name !== 'UserAccountVerifyEmail.editEmail' &&
    name !== 'MultiFactorAuthentication.editPhone' &&
    name !== 'UserAccountVerifyEmail.submitButton' &&
    name !== 'MultiFactorAuthentication.submitButton' &&
    hideElement
  )
    additionalClasses = ' hideElement ';
  else if (
    name !== 'UserAccountVerifyEmail.editEmail' &&
    name !== 'MultiFactorAuthentication.editPhone' &&
    name !== 'UserAccountVerifyEmail.submitButton' &&
    name !== 'MultiFactorAuthentication.submitButton' &&
    !hideElement
  )
    additionalClasses = classes;
  else if (
    (name === 'UserAccountVerifyEmail.editEmail' ||
      name === 'MultiFactorAuthentication.editPhone' ||
      name === 'UserAccountVerifyEmail.submitButton' ||
      name === 'MultiFactorAuthentication.submitButton') &&
    !hideElement
  )
    additionalClasses = classes;
  else if (
    (name === 'UserAccountVerifyEmail.submitButton' ||
      name === 'MultiFactorAuthentication.submitButton') &&
    hideElement
  )
    additionalClasses = classes;

  adClasses = classes;
  // else {
  //   adClasses = ' hideElement ';
  // }

  if (name == 'CriticalIllness.addBeneficiary') {
    if (radioSelectedValueCriticalIllness == 'Waive' || radioSelectedValueCriticalIllness == -1) {
      additionalClasses = 'hideElement ';
    } else {
      additionalClasses = additionalClasses;
    }
  }

  /** Customized disable button for registeration, reset password, retrieve userid  */
  let disablesStyle = { color: '#949494', backgroundColor: '#DADADA', borderColor: '#DADADA' };

  let hideVerifyBtn =
    (hideVerifyButton && name === 'UserAccountVerifyEmail.editEmail') ||
    (emailError && name === 'UserAccountVerifyEmail.verifyEmail');
  let hideEditPhone = !displayEditPhoneIcon && name === 'MultiFactorAuthentication.editPhone';
  let disableVerifyBtn = emailError;
  let hideEmailSubmitCodeBtn =
    registerationError[currentStep] && registerationError[currentStep].hasError
      ? false
      : registerationError.hideEmailCodeInput && name === 'UserAccountVerifyEmail.submitButton';
  let codeSubmitted = codeIsEntered && name === 'UserAccountVerifyEmail.submitButton';
  let codeSubmittedWithErr = registerationError[currentStep]?.hasError || codeSubmitted;

  let shouldHideEmailCode =
    registerationError[currentStep] &&
    !registerationError[currentStep].hasError &&
    name === 'UserAccountVerifyEmail.submitButton' &&
    emailCode;
  let mfaError = registerationError.oktaErrorText;
  let hideSubmitPhoneOtp =
    (name === 'MultiFactorAuthentication.submitButton' &&
      !registeration.showPhoneOtp &&
      !registerationError['MultiFactorAuthentication']?.hasError) ||
    '';
  let hideSendBtn1 =
    name === 'MultiFactorAuthentication.sendCodeBtn' &&
    !registeration.changeMethodClicked === undefined;
  let hideSendBtn2 =
    name === 'MultiFactorAuthentication.sendCodeBtn' && registeration.changeMethodClicked !== true;
  let hideSendBtn3 =
    name === 'MultiFactorAuthentication.sendCodeBtn' &&
    !registeration.sendPhoneClicked === undefined;
  let hideSendBtn4 =
    name === 'MultiFactorAuthentication.sendCodeBtn' && !registeration.sendPhoneClicked === true;
  let hideSendBtn = hideSendBtn1;
  let hideSendBtnw = hideSendBtn2;
  let hideSendBtnn = hideSendBtn || hideSendBtnw;
  let hideSendBtn22 = hideSendBtn3 || hideSendBtn4; //|| (hideSendBtn3 || hideSendBtn4)
  let hideSendBtn23 = hideSendBtn || hideSendBtn22; //|| (hideSendBtn3 || hideSendBtn4)
  let hideRegError = !registerationError['UserAccountVerifyEmail']?.hasError;
  let hidebtn = registerationError.emailExist || hideRegError || registerationError.emailVerified
  let hidebtn1 = registerationError.emailExist || registerationError.emailVerified || registerationError.hideOnLoad

  let formHasErr = registerationError[currentStep]?.hasError

  let showInput1 = registerationError.phoneIsVerified || (registerationError.hidePhoneOnLoad)
  let showInput3 = registerationError.phoneIsVerified
  let noEr1 = !registerationError.hasError || !formHasErr
  let noEr = noEr1 || showInput3
  let submitWoErr = !registerationError[currentStep]?.hasError && registerationError.submitPhoneClicked
  let hideBtnPhoneOtp = ((currentStep === 'MultiFactorAuthentication' && showInput1 &&
    (noEr)) || (currentStep === 'MultiFactorAuthentication' && registeration.changeMethodClicked))


  if (getPendingProcess && !declinedCertificate) {
    if (name === 'undefined.Get Started') {
      return true;
    }
  } else {
    if (name === 'undefined.Resume') {
      return true;
    }
  }

  // if (name === 'undefined.Resume') {

  //   return true;
  // }

  if (isReviewOnly) {
    label = name == 'undefined.currentYearReviewUpdate' ? 'Review' : label
  }




  // The following code is responsible for removing the "Resume" button in all scenarios.

  return showContent.includes(id) || showContent.length === 0 ? (
    <>
      <Styles />
      {/* <div className={((shouldShowOnYes && !isYesradioSelected) || (radioSelectedValue!='Waive' || radioSelectedValue != -1) )? 'hideElement ' + additionalClasses : 'floatingLabel  '+ additionalClasses} hidden={hidden}> */}
      <button
        role="button"
        {...buttonId}
        style={!shouldNotDisable ?
          !noNeedToDisable
            ? !shouldDisableButton
              ? (config && config.style) || {}
              : disablesStyle
            : {}
          : shouldNotDisable ? {} : {}
        }
        name={name || id}
        // id ={id}
        buttonPurpose={buttonPurpose}
        buttonName={buttonName}
        type="button"
        className={additionalClasses || addClasses}
        disabled={
          !disableContinueForRetrieveUser ?
            !noNeedToDisable && buttonPurpose
              ? false
              : !continueBtnSpecial
                ? loading ||
                disabled ||
                (currentStep === 'LifeEvent' && name === 'continueBtn' && continueBtnState.disable) ||
                (name === 'continueBtn' &&
                  currentStep === 'MultiFactorAuthenticationLogin' &&
                  registerationError.oktaErrorText) ||
                (name === 'UserAccountVerifyEmail.verifyEmail' && (emailError || !email)) ||
                (name === 'MultiFactorAuthentication.sendCodeBtn' && (phoneError || !phone)) ||
                (name === 'UserAccountVerifyEmail.submitButton' &&
                  (!emailCodeSubmitted || disableButton.disableSubmitEmailBtn || !registeration.emailCode)) ||
                (name === 'MultiFactorAuthentication.submitButton' &&
                  (!phoneCodeSubmitted || disableButton.disableSubmitPhoneBtn)) ||
                (name === 'undefined.resendSecurityCodeButton' && !startTimer.enableEmailResetBtn) ||
                (name === 'undefined.resendPhoneSecurityCodeButton' &&
                  !startTimer.enablePhoneResetBtn) ||
                (name === 'continueBtn' &&
                  currentStep === 'ContactDetails' &&
                  contactType === 'email' &&
                  registeration.emailError &&
                  !registeration.emailEntered) ||
                disableBtnForContactDetails
                : loading ||
                disabled ||
                disabledNewBtn ||
                disabledVerifyBtn ||
                disabledSubmitButton ||
                disabledContinueButton ||
                (name === 'MultiFactorAuthentication.tryAnotherMethodBtn' &&
                  (registeration.hideMsg === false || registerationError.mfaErrorText === "Request failed with status code 429"))
            : false
        }
        onClick={loading || stpClick ? undefined : shouldDisableButton ? undefined : handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        hidden={name !== 'MultiFactorAuthentication.sendCodeBtn' ?
          ((showOnModalOnly && !adminEnrollmentClicked)) ||
          ((!phone || editPhone) && name === 'MultiFactorAuthentication.editPhone') ||
          ((!email || editEmail) && name === 'UserAccountVerifyEmail.editEmail') ||
          (hideVerifyEmailButton && name === 'UserAccountVerifyEmail.verifyEmail') ||
          ((registeration.submitEmailClicked ||
            registeration.changeMethodClicked ||
            registerationError.emailExist ||
            registerationError.hideOnLoad) &&
            name === 'UserAccountVerifyEmail.submitButton' &&
            (hidebtn1)) ||
          (name === 'MultiFactorAuthentication.tryAnotherMethodBtn' && (!registeration.sendPhoneClicked ||
            registeration.changeMethodClicked || registerationError.phoneIsVerified || registerationError.wrongMobile)) ||
          (hideVerifyEmailButton && name === 'UserAccountVerifyEmail.verifyEmail') ||
          (!registeration.showSubmitCodeBtnEmail && name === 'UserAccountVerifyEmail.submitButton' && !registerationError[currentStep].hasError) ||
          hidden ||
          (name === 'ContactDetails.sendCodeBtnMobile' && hideOnClickSendCodeBtn && sendCodeMobileClicked) ||
          ((insuredInfo[currentStep]?.waiveInsureCoverage ||
            !insuredInfo[currentStep]?.disableAnswersRadioStatus) &&
            (name === 'CoverageSelection.editInsuredSectionButton' ||
              name === 'HospitalIndemnity.editInsuredSectionButton' ||
              name === 'CriticalIllness.editInsuredSectionButton') &&
            stepId > 2)
          ||
          (
            (hideBtnPhoneOtp || registerationError.wrongMobile || (!registeration.showTimerPhone && currentStep === 'MultiFactorAuthentication')) &&
            name === 'MultiFactorAuthentication.submitButton'
          ) : ''

        }
        {...rest}
      >
        {/* {icon && <Icon icon={['fas', 'plus']} />} */}
        {icon && <Icon icon={icon} style={iconStyle} />}

        {/** Render image on edit/verify email/mobile - Registeration */}
        {(buttonForEmail || buttonForPhone) && (
          <img src={imgSrc} className={'img-fluid '} alt="img" />
        )}
        {showImage && (
          <img src={imageMap[getFileName(props.imgSrc)]} className={'img-fluid '} style={imgStyle} alt="img" />
        )}
        {/* {label ? label : content || children} */}
        {label ? label : content || children}
      </button>
      {/* </div> */}
      {/* {
          showLoader && <Loader loading={showLoader} />
        } */}
    </>
  ) : null;
};
Button.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  buttonGroup: PropTypes.bool,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  setActivateButton: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  content: PropTypes.any,
  showContent: PropTypes.array,
  showImage: PropTypes.bool,
};
Button.defaultProps = {
  disabled: false,
  outline: false,
  rounded: false,
  showImage: false,
  className: '',
  showContent: [],
};

export default Button;
