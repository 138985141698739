import React, { useRef, useState, useEffect } from 'react';
import { Button } from '../../Atoms';
import SectionHeader from '../../Molecules/SectionHeader';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import ProductTable from '../../Molecules/ProductTable';
import ManageBeneficiariesTable from '../../Molecules/ManageBeneficiariesTable'
import $ from 'jquery';
import http from '../../../Common/Services/HttpService';
import appConfig from '../../../appConfig';
import { onLoadAPI } from '../../../Store/Slices/Entities/response';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { relationship } from '../../../utils/relationship';
import { cloneDeep } from 'lodash';
import { IS_HR_CENTER_RE_DIRECTED } from '../../../Store/Slices/CallCenter'
import { getFormKeyByProductFamily } from '../../Molecules/ProductTable/processData';
import Header from '../../Cells/Header';
import IconThumbsUp from '../../../Assets/Images/Icon Thumbs Up.png';
import useOnBack from '../../Molecules/CustomSection/Hooks/OnBackHook';

const ConfirmationScreen = (props) => {
  const componentRef = useRef();
  const dispatch = useDispatch();

  useOnBack('/enrollment/home');

  // Variable to save the information about beneficiaries
  const [beneficiariesProducts, setBeneficiariesProducts] = useState([]);
  const [layoutData, setLayoutData] = useState({});
  const [dependentsTable, setDependentsTable] = useState([]);
  const [hideBackToHomeButton, setHideBackToHomeButton] = useState(false);
  const { userAuth } = useSelector((storeState) => storeState.auth);
  let currentReduxState = useSelector((storeState) => storeState);
  const currentBenifitButton = currentReduxState?.employeeProfile?.curreneBenifitButton;

  let finalEnrollmentProcess =
    currentReduxState?.entities?.response?.onLoadResponseList?.finalEnrollmentProcess || {};
  const currentBenifitendorsementContentData = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.initEndorsementProcessData
  );
  const qleEndorsementProcessContentData = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.initNewIndividualEndorsementProcess
  );
  const adminEndorsementProcessData = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.AdminEndorsementProcess);
  const endorsementProcess =
    currentBenifitendorsementContentData || qleEndorsementProcessContentData || adminEndorsementProcessData;
  const dataFromRedux = useSelector((storeState) => storeState?.auth?.loggedUserEntity?.data);
  const subscribedElements =
    currentReduxState?.enrollment?.enrollmentData?.contract?.subscribedElements;
  const process = useSelector((storeState) => storeState?.process);
  const insuredDependents = currentReduxState?.enrollment?.enrollmentData?.contract?.insureds || [];
  const benificiaryProductUpdate = useSelector((storeState) => storeState?.enrollment?.benificiaryProductUpdate)
  let beneficiariesEligibleProducts = { beneficiariesProducts: benificiaryProductUpdate }

  const beneficiariesFullData = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.beneficiariesData
  ) || {};
  const hasTrueBeneficiaries = beneficiariesEligibleProducts?.beneficiariesProducts?.some(product => product?.hasBeneficiaries);
  let beneficiariesTableEligible = hasTrueBeneficiaries ? true : false

  // const petNationWide = currentReduxState?.process?.selectedProductList?.filter(
  //   (data) => data?.product?.productFamily == 'Pet Insurance'
  // )

  const petNationWide =
    currentReduxState?.process?.enrollmentProcess[0]?.[1]?._Result?.filter(
      (data) => data?.memberOfferedProduct?.product?.productFamily == 'Pet Insurance'
    ) ||
    currentReduxState?.process?.selectedProductList?.filter(
      (data) => data?.product?.productFamily == 'Pet Insurance'
    ) ||
    currentReduxState?.process?.productList?.filter(
      (data) => data?.memberOfferedProduct?.product?.productFamily == 'Pet Insurance'
    );
  const coverageTitle = useSelector(
    (storeState) => storeState?.process?.productResourcesCoverageTitle
  );
  const isAdmin = useSelector((storeState) => storeState?.callCenter?.isAdmin);
  const isHrCenterReDirected = useSelector((storeState) => storeState?.callCenter?.isHrCenterReDirected);
  const isHrCenter = (isHrCenterReDirected) ? true : false
  // Method to split the information based on beneficiaries type
  const getBeneficiaryType = (beneficiaryArray = []) => {
    const contingentArray = [];
    const primaryArray = [];
    const beneficiaryObj = {
      Primary: [],
      Contingent: [],
    };
    if (beneficiaryArray.length > 0) {
      beneficiaryArray.forEach((beneficiary) => {
        if (
          beneficiary &&
          beneficiary.beneficiaryInformation &&
          beneficiary.beneficiaryInformation.beneficiaryRank === 'cPrimary'
        ) {
          primaryArray.push(beneficiary);
        } else if (
          beneficiary &&
          beneficiary.beneficiaryInformation &&
          beneficiary.beneficiaryInformation.beneficiaryRank === 'cContingent'
        ) {
          contingentArray.push(beneficiary);
        }
      });
    }
    beneficiaryObj['Primary'] = primaryArray;
    beneficiaryObj['Contingent'] = contingentArray;
    return {
      beneficiaryObj,
      hasBeneficiaries: primaryArray.length > 0 || contingentArray.length > 0 ? true : false,
    };

  };

  // Get Beneficiaries Full Data
  const getPersonalInformation = async () => {
    const resp = await http.post(appConfig.SSP_MID_URL + '/v1/getBeneficiariesData', {
      subscribedElements: subscribedElements,
    }).then((resp) => {
      if (resp?.status === 200) {
        dispatch(onLoadAPI({ 'beneficiariesData': resp?.data?.data?.beneficiariesData?.data }));
      }
    });
  };

  useEffect(() => {
    getPersonalInformation();
  }, [])


  // Methods to fill the information about beneficiaries
  useEffect(() => {
    if (subscribedElements && subscribedElements.length > 0 && process && process.productFamilyIdentifier && Object.keys(process.productFamilyIdentifier).length > 0 && Object.keys(beneficiariesFullData)?.length > 0) {
      const productsArray = [];
      let i = 0;
      const effectiveDate = currentReduxState?.enrollment?.enrollmentData?.processEntity?.effectiveDate
      subscribedElements?.forEach(product => {
        const productId = product && product.bUID || '';
        const productCode = product?.subscribedProduct?.productCode?.buid || '';
        const productBuid = product && product.subscribedProduct && product.subscribedProduct.productCode && product.subscribedProduct.productCode.buid || '';
        const coverages = product && product.coverages || [];
        const productName = Object.keys(process.productFamilyIdentifier).find(key => process.productFamilyIdentifier[key] === productBuid);
        const productNameTitle = getFormKeyByProductFamily(productName);
        if (coverages.length > 0) {
          coverages?.forEach(coverage => {
            const plannedTerminationDate = coverage?.subscribedOption?.plannedTerminationDate
            const secondTermination1 = effectiveDate >= plannedTerminationDate ? true : false
            if (!secondTermination1) {
              const productNameDescription = (coverage && coverage?.subscribedOption && coverage?.subscribedOption.optionName) || '';
              const coverageBuid = coverage && coverage.bUID;
              const newInsureArray = [];
              let newPersonBuid = '';
              coverage && coverage?.insuredPersons?.forEach(insure => {
                const personBuid = insure && insure.person && insure.person.identifier && insure.person.identifier.bUID || '';
                newPersonBuid = personBuid;
                const insuredPerson = insure && insure.beneficiariesDescription && insure.beneficiariesDescription.beneficiaries || [];
                insuredPerson.forEach(insurePerson => {
                  const newInsurePerson = cloneDeep(insurePerson);
                  newInsurePerson['personBuid'] = personBuid;
                  newInsurePerson['fullData'] = beneficiariesFullData[newInsurePerson?.beneficiary?.identifier?.bUID] || {};
                  newInsureArray.push(newInsurePerson);
                });
              });
              const productObj = {};
              const beneficiariesInformation = getBeneficiaryType(newInsureArray);
              let titleObj = {};
              coverageTitle && coverageTitle?.find(coverage => {
                if (coverage?.productId === productCode && coverage?.insuredPersonId === newPersonBuid && beneficiariesInformation.hasBeneficiaries) {
                  titleObj = coverage;
                }
              });
              productObj['personBuid'] = newPersonBuid;
              productObj['productId'] = productBuid;
              productObj['productBuid'] = productId;
              productObj['productName'] = beneficiariesInformation.hasBeneficiaries ? productNameTitle : '';
              productObj['productTitle'] = titleObj?.content || '';
              productObj['productNameDescription'] = productNameDescription;
              productObj['coverageBuid'] = coverageBuid;
              productObj['beneficiaries'] = beneficiariesInformation && beneficiariesInformation.beneficiaryObj;
              productObj['hasBeneficiaries'] = beneficiariesInformation && beneficiariesInformation.hasBeneficiaries;
              productsArray.push(productObj);
            }
          });
        }
      });
      setBeneficiariesProducts(productsArray);
    }
  }, [subscribedElements, process && process.productFamilyIdentifier, coverageTitle, beneficiariesFullData]);
  // Methods to fill the information about beneficiaries

  useEffect(() => {
    if (insuredDependents.length > 0) {
      const principalBuid = dataFromRedux && dataFromRedux.person && dataFromRedux.person.bUID;
      const tableData = [];

      const gender = {
        gMale: 'Male',
        gFemale: 'Female',
        gUndisclosed: 'Undisclosed',
      };

      insuredDependents.forEach((dependent) => {
        const dependentData = {};
        const dependentBuid = dependent.person.identifier.bUID;
        const insuredBuid = dependent.bUID;
        const additionalData = dependent.insured.additionalData || [];
        const dependentInfo =
          (dependent && dependent.person && dependent.person.data && dependent.person.data.actor) ||
          {};
        const isStudent = additionalData.find(
          (additional) => additional.fieldName === 'ISCHFTSTUDENT'
        );
        let isDisabled = 'yes';
        if (dependent?.insured?.additionalData?.[0]?.valueCode) {
          isDisabled =
            dependent?.insured?.additionalData?.[0]?.valueCode.toLocaleLowerCase() == 'yes'
              ? 'yes'
              : 'no';
        }
        if (!dependent.insured.mainOwnerRelationshipToInsured) {
          return;
        }
        const liveInSameAddress =
          dependentInfo && dependentInfo.preferredContactChoice === 'pcActorContact' ? true : false;
        dependentData['mainBuid'] = insuredBuid;
        dependentData['id'] = dependentBuid;
        dependentData['dependentFirstName'] = (dependentInfo && dependentInfo.shortName) || '';
        dependentData['dependentMiddleName'] = (dependentInfo && dependentInfo.middleName1) || '';
        dependentData['dependentLastName'] = (dependentInfo && dependentInfo.name) || '';
        dependentData['dependentRelationShip'] =
          relationship[dependent.insured.mainOwnerRelationshipToInsured];
        dependentData['dependentGender'] = gender[dependentInfo && dependentInfo.gender];
        dependentData['dependentDateOfBirth'] = moment(
          dependentInfo && dependentInfo.birthDate
        ).format('MM-DD-YYYY');
        dependentData['liveInSameAddress'] = liveInSameAddress;
        dependentData['isDependent'] = true;
        dependentData['data'] = {
          ...dependent,
          isStudent: isStudent ? 'yes' : 'no',
          isDisabled: isDisabled,
        };
        tableData.push(dependentData);
      });
      setDependentsTable(tableData);
    }
  }, [insuredDependents]);

  useEffect(() => {
    setLayoutData({
      ...dataFromRedux,
      dependentsTable,
      beneficiariesProducts,
    });
  }, [dataFromRedux, dependentsTable, beneficiariesProducts]);

  const handleClickAll = (id) => {
    // Get all the div elements with id "product-table-to-display-products"
    // const myDivs = document.querySelectorAll('#product-table-to-display-products');
    const myDivs = document.querySelectorAll('#product-table-beneficiaries');
    // Simulate a click on each of these divs
    myDivs.forEach((div) => {
      div.click();
    });

    const myDivs2 = document.querySelectorAll('#table-row');
    myDivs2.forEach((div) => {
      div.click();
    });
    // After a delay of 500 milliseconds, get all the elements with the specified id
    setTimeout(() => {
      const elements = document.querySelectorAll(`[id*='${id}']`);
      // Simulate a click on each of these elements
      elements.forEach((el) => el.click());
    }, 500);
  };

  const formatDateNow = (dateString) => {
    //To format date as mm/dd/yyyy to pass it to new date --To fix previous date dispaly issue
    var hypenIndex = dateString.indexOf('-');
    const yearValue = dateString.substring(0, hypenIndex);
    dateString = dateString.substring(++hypenIndex);
    hypenIndex = dateString.indexOf('-');
    const monthValue = dateString.substring(0, hypenIndex);
    const dayValue = dateString.substring(++hypenIndex);
    dateString = monthValue + '-' + dayValue + '-' + yearValue;
    // let date = new Date(value);
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  // Define a variable handlePrint that uses the useReactToPrint hook to create a function
  // that can be called to print a componentRef
  const handlePrint = useReactToPrint({
    // Pass a content prop that is a function that returns the componentRef.current
    content: () => componentRef.current,
  });

  const handleClick = () => {
    // Call handleClickAll() function with argument 'to-show-more-details'
    handleClickAll('to-show-more-details');

    // Set hideBackToHomeButton state to true
    setHideBackToHomeButton(true);

    // Select all table headers and change their font color to light gray
    const tableHeaders = document.querySelectorAll('th');
    tableHeaders.forEach((header) => {
      header.style.color = '#9b9b9b';
    });

    // Wait for one second before continuing
    setTimeout(() => {
      // Call handlePrint() function
      handlePrint();

      // Set hideBackToHomeButton state back to false
      setHideBackToHomeButton(false);

      // Change font color of all table headers back to white
      tableHeaders.forEach((header) => {
        header.style.color = 'white';
      });

      // Call handleClickAll() function again with argument 'to-show-more-details'
      handleClickAll('to-show-more-details');
    }, 1000);
  };

  let history = useHistory();
  let date = new Date();

  let uuid = date.getTime();

  let todaysDate = formatDate(date.toString());

  function formatDate(dateVal) {
    var newDate = new Date(dateVal);

    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sSec = padValue(newDate.getSeconds());
    var sAMPM = 'AM';

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = 'PM';
      sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      sHour = '12';
    }

    sHour = padValue(sHour);

    return (
      sMonth +
      '/' +
      sDay +
      '/' +
      sYear +
      ', ' +
      sHour +
      ':' +
      sMinute +
      ':' +
      sSec +
      ' ' +
      sAMPM +
      ', ' +
      'ET'
    );
  }

  function padValue(value) {
    return value < 10 ? '0' + value : value;
  }

  //let showBenefitEffectiveDate = finalEnrollmentProcess?.contract?.subscribedElements;
  let showBenefitEffectiveDate = subscribedElements
  // once the enrollment API  Issue is fixed need to remove the condtion make avalible enrollment and endorsemnt without condition
  const effectivedate = finalEnrollmentProcess?.contract?.contract?.effectiveDate
    ? formatDateNow(finalEnrollmentProcess?.contract?.contract?.effectiveDate)
    : 'N/A';
  const effectiveDateEndrorsemnt = finalEnrollmentProcess?.contractChange?.contractChange
    ?.effectiveDateOfChange
    ? formatDateNow(finalEnrollmentProcess?.contractChange?.contractChange?.effectiveDateOfChange)
    : 'N/A';
  let subscribedContract =
    petNationWide &&
    petNationWide[0] &&
    // (finalEnrollmentProcess?.contract?.subscribedElements?.filter(
    //   (e) =>
    //     e.subscribedProduct.productCode.buid ===
    //     petNationWide[0]?.memberOfferedProduct?.product?.buid ||
    //     e.subscribedProduct.productCode.buid === petNationWide[0]?.product?.buid
    // )[0]?.coverages ??
    //   null);
    (subscribedElements?.filter(
      (e) =>
        e.subscribedProduct.productCode.buid ===
        petNationWide[0]?.memberOfferedProduct?.product?.buid ||
        e.subscribedProduct.productCode.buid === petNationWide[0]?.product?.buid
    )[0]?.coverages ??
      null);

  //Checking status of pet Mationwide
  let petStatusActive = false
  for (let i = 0; i < subscribedContract?.length; i++) {
    const element = subscribedContract[i]?.subscribedOption?.status;
    const filteredResults =
      element == 'cActive' ||
      element == 'cProject';
    if (filteredResults) {
      petStatusActive = true
      break;
    }

  }

  let lastElement = subscribedContract && subscribedContract[subscribedContract?.length - 1];
  const petEffectivedate = lastElement?.subscribedOption?.effectiveDate
    ? formatDateNow(lastElement?.subscribedOption?.effectiveDate)
    : 'N/A';
  let hideEditButton = true;
  let backgroundcolor = true;
  function dateCheck(from, to, check) {
    var fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);
    if (cDate <= lDate && cDate >= fDate) {
      return true;
    }
    return false;
  }
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const currentEnrollmentPeriod =
    currentReduxState?.process?.contractsLinearOrNonLinear?._Result?.currentEnrollmentPeriod
      ?.enrollmentPeriod;
  let isAnnualPeriod = dateCheck(
    currentEnrollmentPeriod?.effectiveDate,
    currentEnrollmentPeriod?.endDate,
    today
  );
  useEffect(() => {
    if (
      isAnnualPeriod &&
      (currentBenifitButton === '.LifeEventYesButton' ||
        currentBenifitButton === 'undefined.currentYearReviewUpdate')
    ) {
      $('#ConfimationScreen').modal('show');
    }
  }, [isAnnualPeriod]);
  const handleBackToHome = () => {
    if (userAuth.processType === 'callCenter' && isAdmin) {
      dispatch({
        type: 'SETINITIALSTATE'
      })
      dispatch({
        type: 'RESET'
      })
      history.push('/callcenter/home');
    }
    else if (isHrCenter && isAdmin) {
      dispatch({
        type: 'SETINITIALSTATE'
      })
      dispatch({
        type: 'RESET'
      })
      dispatch(IS_HR_CENTER_RE_DIRECTED(false))
      history.push('/hrcenter')
    }
    else {
      history.push('/enrollment/home');
    }
  };

  return (
    <React.Fragment>
      <div ref={componentRef} className="container-fluid login-view">
        <Header />
        <SectionHeader
          title="Enrollment Confirmation Statement"
          rightLink="CC Home"
          hideLink={hideBackToHomeButton}
          //   style={content.style}
          //   breadcrumbs={content.breadcrumbs}
          //   filename={content.filename}
          //   specialHeaderWidth={content.specialHeaderWidth}
          className="col-12 col-lg-12 col-md-12"
          leftColumnClass="col-sm-6 col-lg-6 col-md-12"
          modifyPadding="true"
        />

        <div className="row mt-3 ">
          <div className="col-12 px-4">
            <div>
              Confirmation number provided below will be required for any questions regarding your
              enrollment
            </div>
            <div className="tri-confirmation-container-box d-sm-flex">
              <img
                src={IconThumbsUp}
                alt=""
                id="IconThumbsUp"
              />

              <div>
                <div className="col-12 ml-3 confirmation_message d-flex justify-content-md-start justify-content-lg-start">
                  Confirmation
                </div>
                <div className="col-12 ml-3">
                  {finalEnrollmentProcess &&
                    finalEnrollmentProcess.contractChange &&
                    finalEnrollmentProcess.contractChange.contractChange && (
                      <>
                        Your Confirmation Number is:{' '}
                        <span className="content-bold">
                          {finalEnrollmentProcess.contractChange.contractChange.transactionId ||
                            'N/A'}
                        </span>{' '}
                        | Date of Enrollment:{' '}
                        <span className="content-bold">
                          {finalEnrollmentProcess.contractChange.contractChange.transactionDate
                            ? formatDateNow(
                              finalEnrollmentProcess.contractChange.contractChange.transactionDate
                            )
                            : 'N/A'}{' '}
                          {` `}{' '}
                        </span>{' '}
                        |
                        {showBenefitEffectiveDate.length == 1 && petNationWide?.length > 0 && petStatusActive && (

                          <>
                            Pet Insurance Benefit Effective Date:{' '}
                            <span className="content-bold">{petEffectivedate} </span>
                          </>
                        )}
                        {showBenefitEffectiveDate.length == 1 && petNationWide?.length == 0 && (
                          <>
                            Benefit Effective Date:{' '}
                            <span className="content-bold">
                              {!endorsementProcess ? effectivedate : effectiveDateEndrorsemnt}{' '}
                            </span>
                          </>
                        )}
                        {/* <h2>
                          :{showBenefitEffectiveDate.length}:xxx--- {petNationWide?.length}
                        </h2> */}
                        {showBenefitEffectiveDate?.length > 1 && petNationWide?.length > 0 && (
                          <>
                            Benefit Effective Date:{' '}
                            <span className="content-bold">
                              {!endorsementProcess ? effectivedate : effectiveDateEndrorsemnt}{' '}
                            </span>
                            {petStatusActive && <> Pet Insurance Benefit Effective Date:{' '}
                              <span className="content-bold">{petEffectivedate} </span></>}
                          </>
                        )}
                        {showBenefitEffectiveDate?.length > 1 && petNationWide?.length == 0 && (
                          <>
                            Benefit Effective Date:{' '}
                            <span className="content-bold">
                              {!endorsementProcess ? effectivedate : effectiveDateEndrorsemnt}{' '}
                            </span>
                          </>
                        )}
                      </>
                    )}
                </div>
                <div></div>
              </div>
            </div>
            <div className="col-12 px-0 ">
              <div
                id="printConfirmation"
                style={{ display: hideBackToHomeButton ? 'none' : 'block' }}
              >
                <button
                  className="btn  btn-outline-primary primaryOutline"
                  onClick={handleClick}
                  outline
                  type="button"
                >
                  Print Confirmation
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 py-3">
            {' '}
            <ProductTable
              hideEditButton={hideEditButton}
              backgroundcolor={backgroundcolor}
              layoutData={layoutData}
            />
            {/* <div>
              {beneficiariesTableEligible && (
                <div className={'card'}>
                  <div className={'cardHeader'}>
                    <div className={"beneficiariestitle"}>{'Beneficiary Information'}</div>
                    <ManageBeneficiariesTable
                      hideActions={true}
                      layoutData={beneficiariesEligibleProducts}
                    />
                  </div>
                </div>
              )}
            </div> */}
          </div>

          {/* <div className="col-12 py-3 ">
              {' '}
              Actual payroll deduction may vary due to rounding differences.
                  </div>  */}

          <div className="col-12 text-right mb-3">
            <div id="backToHomeButton" style={{ display: hideBackToHomeButton ? 'none' : 'block' }}>
              <Button outline type="button" onClick={handleBackToHome}>
                {isAnnualPeriod &&
                  (currentBenifitButton === '.LifeEventYesButton' ||
                    currentBenifitButton === 'undefined.currentYearReviewUpdate')
                  ? 'Click to enroll for upcoming year'
                  : 'Back to Home Page'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfirmationScreen;
