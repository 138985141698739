/** Visual Strength Meter Functionalties - By Nisreen and Gabriel 2022  */

class  StrengthMeterHelper {
    /** input: password, output: passed criteria object */
    handlePassword = (passwordValue) => {

        const strengthChecks = {
          length: 0,
          regexUppercase: false,
          regexLowercase: false,
          regexNum: false,
          regexSpecialCharacter: false,
          regexNoThreeTimes: false,
          regexNoSecuencial: false, 
          regexNoEmailFormat: false
        };

        strengthChecks.length           = (passwordValue.length >= 8 ) ? true : false;
        strengthChecks.regexUppercase   = /[A-Z]+/.test(passwordValue);
        strengthChecks.regexLowercase   = /[a-z]+/.test(passwordValue);
        strengthChecks.regexNum         = /\d/.test(passwordValue);
        strengthChecks.regexSpecialCharacter  =  /[@\.\-_']/.test(passwordValue);
        strengthChecks.regexNoThreeTimes      = !(/([0-9]|[aA-zZ]|[@\.\-_'])\1\1/.test(passwordValue));
        strengthChecks.regexNoSecuencial      = !(/(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|zyx|yxw|xwv|wvu|vut|uts|tsr|srq|rqp|pqo|pon|onm|nml|mlk|lkj|kji|jih|ihg|hgf|gfe|fed|edc|dcb|cba|ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|ZYX|YXW|XWV|WVU|VUT|UTS|TSR|SRQ|RQP|QPO|PON|ONM|NML|MLK|LKJ|KJI|JIH|IHG|HGF|GHE|FED|EDC|DCB|CBA|012|123|234|345|456|567|678|789|987|876|765|654|543|432|321|210)+/.test(passwordValue));
        //strengthChecks.regexNoEmailFormat     = !(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(passwordValue));
        strengthChecks.regexNoEmailFormat = !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(passwordValue));

        let verifiedList = Object.values(strengthChecks).filter((value) => value);
        return verifiedList;
    };

    /** input: passed criteria object, output: message */
    getPasswordStrength = (verifiedList) => {
        let strength =
        verifiedList.length === 8
          ? "Strong"
          : verifiedList.length >= 4
          ? "Medium" 
          : "Weak";
        return strength;
    };

    /** input: passed criteria object, output: bars color object */
    getBarColor = (verifiedList, content) => {
        let contentPasswordBarStyle = content?.layout?.passwordBarStyle;
        let defaultColor = contentPasswordBarStyle?.barColors?.defaultColor;
        let weakColor    = contentPasswordBarStyle?.barColors?.weakColor;
        let mediumColor  = contentPasswordBarStyle?.barColors?.mediumColor;
        let strongColor  = contentPasswordBarStyle?.barColors?.strongColor;
        let width        = contentPasswordBarStyle?.width;
        let height       = contentPasswordBarStyle?.height
        let passwordBarStyle ={
            barColors : {
                firstBarColor  : defaultColor,
                secondBarColor : defaultColor,
                thirdBarColor  : defaultColor,
                fourthBarColor : defaultColor
            },
            width : width, 
            height: height
        }
        switch (verifiedList.length) {
          case 0 :
          case 1 :
            {
              passwordBarStyle.barColors.firstBarColor  = weakColor;
              passwordBarStyle.barColors.secondBarColor = defaultColor;
              passwordBarStyle.barColors.thirdBarColor  = defaultColor;
              passwordBarStyle.barColors.fourthBarColor = defaultColor;
              passwordBarStyle.width = width;
              passwordBarStyle.height = height;
              break;
            }
          case 2 :
          case 3 :
          case 4:
            {
              passwordBarStyle.barColors.firstBarColor  = weakColor;
              passwordBarStyle.barColors.secondBarColor = weakColor;
              passwordBarStyle.barColors.thirdBarColor  = defaultColor;
              passwordBarStyle.barColors.fourthBarColor = defaultColor;
              break;
            }
         
          case 5 : 
          case 6 :
          case 7:
            {
              passwordBarStyle.barColors.firstBarColor  = mediumColor;
              passwordBarStyle.barColors.secondBarColor = mediumColor;
              passwordBarStyle.barColors.thirdBarColor  = mediumColor;
              passwordBarStyle.barColors.fourthBarColor = defaultColor;
              break;
            }
        
          case 8: 
            {
              passwordBarStyle.barColors.firstBarColor  = strongColor;
              passwordBarStyle.barColors.secondBarColor = strongColor;
              passwordBarStyle.barColors.thirdBarColor  = strongColor;
              passwordBarStyle.barColors.fourthBarColor = strongColor;
              break;
            }
          default:
            {
              passwordBarStyle.barColors.firstBarColor  = defaultColor;
              passwordBarStyle.barColors.secondBarColor = defaultColor;
              passwordBarStyle.barColors.thirdBarColor  = defaultColor;
              passwordBarStyle.barColors.fourthBarColor = defaultColor;
            }
        };
        return passwordBarStyle;
    };

    /** change bar and message color according to password strength */
    getActiveColor = (type) => {
      if (type === "Strong") return "#8BC926";
      if (type === "Medium") return "#FEBD01";
      if (type === "Weak")   return "#FF0054";
      return "#DADADA";
    };


   

  getAnswerBarColor = (verifiedList, content) => {
      let contentAnswerBarStyle = content?.layout?.answerBarStyle;
      let defaultColor = contentAnswerBarStyle?.barColors?.defaultColor;
      let progressColor    = contentAnswerBarStyle?.barColors?.progressColor;

      // let weakColor    = contentPasswordBarStyle?.barColors?.weakColor;
      // let mediumColor  = contentPasswordBarStyle?.barColors?.mediumColor;
      // let strongColor  = contentPasswordBarStyle?.barColors?.strongColor;
      let width        = contentAnswerBarStyle?.width;
      let height       = contentAnswerBarStyle?.height
      let answerBarStyle ={
          barColors : {
              firstBarColor  : defaultColor,
              secondBarColor : defaultColor,
              thirdBarColor  : defaultColor,
              fourthBarColor : defaultColor
          },
          width : width, 
          height: height
      }
      switch (verifiedList) {
        case 0 :
          {
            answerBarStyle.barColors.firstBarColor  = defaultColor;
            answerBarStyle.barColors.secondBarColor = defaultColor;
            answerBarStyle.barColors.thirdBarColor  = defaultColor;
            answerBarStyle.barColors.fourthBarColor = defaultColor;
            answerBarStyle.width = width;
            answerBarStyle.height = height;
            break;
          }
        case 1 :
          {
            answerBarStyle.barColors.firstBarColor  = progressColor;
            answerBarStyle.barColors.secondBarColor = defaultColor;
            answerBarStyle.barColors.thirdBarColor  = defaultColor;
            answerBarStyle.barColors.fourthBarColor = defaultColor;
            answerBarStyle.width = width;
            answerBarStyle.height = height;
            break;
          }
        case 2 :
          {
            answerBarStyle.barColors.firstBarColor  = progressColor;
            answerBarStyle.barColors.secondBarColor = progressColor;
            answerBarStyle.barColors.thirdBarColor  = defaultColor;
            answerBarStyle.barColors.fourthBarColor = defaultColor;
            break;
          }
       
        case 3:
          {
            answerBarStyle.barColors.firstBarColor  = progressColor;
            answerBarStyle.barColors.secondBarColor = progressColor;
            answerBarStyle.barColors.thirdBarColor  = progressColor;
            answerBarStyle.barColors.fourthBarColor = defaultColor;
            break;
          }
      
        case 4: 
          {
            answerBarStyle.barColors.firstBarColor  = progressColor;
            answerBarStyle.barColors.secondBarColor = progressColor;
            answerBarStyle.barColors.thirdBarColor  = progressColor;
            answerBarStyle.barColors.fourthBarColor = progressColor;
            break;
          }
        default:
          {
            answerBarStyle.barColors.firstBarColor  = defaultColor;
            answerBarStyle.barColors.secondBarColor = defaultColor;
            answerBarStyle.barColors.thirdBarColor  = defaultColor;
            answerBarStyle.barColors.fourthBarColor = defaultColor;
          }
      };
      return answerBarStyle;
  };

}
export default StrengthMeterHelper;