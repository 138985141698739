import React from 'react';

const ImageLayout = ({ image, className = '', children, content }) => {

    return (
        <div className={`container-fluid`}>
            <div className={'row py-3 mt-4'}> 
                <div className={className ? (className === 'forRegistration' ? 'col-lg-5 col-md-5 px-0' : className ) : 'col-lg-5 col-md-5 px-5'}>
                    <img src={image.src} {...image} className={className !== 'forRegistration' ? 'MFALoginImage':'MFAimage'}/>
                    {content}
                </div>
                <div className={className ?  (className === 'forRegistration' ? 'col-lg-7 col-md-7 pl-lg-5' : className ): 'offset-md-1 col-lg-6 col-md-6 pl-lg-5'}>
                    {children}
                </div>
            </div>
        </div> 
    );
};

export default ImageLayout;
