import { createSlice } from '@reduxjs/toolkit';
import appConfig from '../../../appConfig';

const slice = createSlice({
  name: 'process',
  initialState: {
    enrollmentProcess: [],
    productList: [],
    selectedProductList: [],
    productFamilyIdentifier: {},
    updateContractInformtion: [],
    wholeLife: {},
    medicalSurvey: {},
    contractsLinearOrNonLinear: [],
    lastContractChange: {},
    productTitle: "",
    subscribedProducts: [],
    beneficiaryData: [],
    subscribedProductsHaveBeneficiaries: false,
    productResources: {}
  },
  reducers: {
    // action => action handler
    STORE_IN_REDUX: (process, action) => {
      return {
        ...process,
        enrollmentProcess: [...process.enrollmentProcess, action.payload],
      };
    },

    STORE_IN_REDUX_WL: (process, action) => {
      return {
        ...process,
        wholeLife: action.payload,
      };
    },
    STORE_IN_REDUX_MEDICAL_SURVEY: (process, action) => {
      return {
        ...process,
        medicalSurvey: action.payload,
      };
    },
    PRODUCT_LIST: (process, action) => {
      process.productList = action.payload;
      process.productFamilyIdentifier = {};
      if (action.payload.length > 0) {
        action.payload.map((e) => Object.assign(process.productFamilyIdentifier, { [e?.details?.product?.productFamily]: e?.details?.bUID }));
      }
    },
    SELECTED_PRODUCT_LIST: (process, action) => {
      return { ...process, selectedProductList: action.payload };
    },
    PRODUCT_LIST_IDENTIFIER: (process, action) => {
      process.productFamilyIdentifier = {};
      if (action?.payload?.length > 0) {
        action?.payload?.map((e) => {
          const key = e?.details?.product?.productFamily || e?.productSelection?.productFamily;
          const value = e?.details?.bUID || e?.productSelection?.productBUID;
          return Object.assign(process.productFamilyIdentifier, { [key]: value });
      });

      }
    },
    ENRORSEMNT_PRODUCT_LIST_IDENTIFIER: (process, action) => {
      process.productFamilyIdentifier = {};
      if (action.payload.length > 0) {
        action.payload.map((e) => Object.assign(process.productFamilyIdentifier, { [e.everGreenProductName]: e.everGreenProductBuid }));
      }
    },
    UPDATE_CONTRACT_INFORMATION: (process, action) => {
      return {
        ...process,
        updateContractInformtion: action.payload
      }
    },
    CONTRACT_LINIAR_OR_NON_LINIAR: (process, action) => {
      return {
        ...process,
        contractsLinearOrNonLinear: action.payload
      }
    },
    LAST_CONTRACT_CHANGE: (process, action) => {
      return {
        ...process,
        lastContractChange: action.payload
      }
    },
    PRODUCT_TITLE: (process, action) => {
      process.productTitle = action.payload;
    },
    PRODUCT_RESOURCES: (process, action) => {
      process.productResources = action.payload.productResourceList
    },
    PRODUCT_RESOURCES_COVERAGE_TITLE: (process, action) => {
      process.productResourcesCoverageTitle = action.payload.productResourcesCoverageTitle
    },
    UPDATE_SUBSBSCRIBED_PRODUCTS : (process, action) => {
      process.subscribedProducts = action.payload
    },
    UPDATE_BENEFICIARY_DATA : (process, action) => {
      process.beneficiaryData = action.payload
    },
    UPDATE_IF_SUBSCRIBED_PRODUCT_HAS_BENEFICIARIES: (process, action) => {
      process.subscribedProductsHaveBeneficiaries = action.payload
    },
    PRODUCT_RESOURCES_PRODUCT_TITLE: (process, action) => {
      process.productResourcesList = action.payload.productResourcesList
    }
  },
});

export const { UPDATE_CONTRACT_INFORMATION, STORE_IN_REDUX, STORE_IN_REDUX_WL, STORE_IN_REDUX_MEDICAL_SURVEY, PRODUCT_LIST, SELECTED_PRODUCT_LIST, PRODUCT_LIST_IDENTIFIER, CONTRACT_LINIAR_OR_NON_LINIAR, LAST_CONTRACT_CHANGE, ENRORSEMNT_PRODUCT_LIST_IDENTIFIER, PRODUCT_TITLE, PRODUCT_RESOURCES, PRODUCT_RESOURCES_COVERAGE_TITLE, PRODUCT_RESOURCES_PRODUCT_TITLE, UPDATE_SUBSBSCRIBED_PRODUCTS, UPDATE_BENEFICIARY_DATA, UPDATE_IF_SUBSCRIBED_PRODUCT_HAS_BENEFICIARIES } = slice.actions;

export default slice.reducer;