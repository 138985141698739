import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'stepForm',
  initialState: {
    hideContinueBtn: false,
    hideBackBtn: false,
    productData:{},
    currentStep:"",
    stepKeys:[],
    previousStep:"",
    editFromEnrollmentSummery:false,
    stepContent: {},
    skipDependentStep : false,
    currentStepNb:""
  },
  reducers: {
    // action => action handler
    HIDE_CONTINUE_BUTTON: (stepForm, action) => {
      stepForm.hideContinueBtn= action.payload.hideContinueBtn;
    },
    HIDE_BACK_BUTTON: (stepForm, action) => {
      stepForm.hideBackBtn= action.payload.hideBackBtn;
    },
    GET_DATA: (stepForm, action) => {
      stepForm.productData = action.payload.productData
    },
    CURRENT_STEP: (stepForm, action) => {
      if (action.payload.currentStep) stepForm.currentStep = action.payload.currentStep;
      if (action.payload.stepId) stepForm.stepId = action.payload.stepId;
    },
    STEP_KEYS: (stepForm, action) => {
      stepForm.stepKeys = action.payload.stepKeys
    },
    PREVIOUS_STEP: (stepForm, action) => {
      stepForm.previousStep = action.payload.previousStep;
    },
    SET_STEP_CONTENT: (stepForm, action) => {
      const {content, step} = action.payload;
      const prevContent = stepForm.stepContent[step]
      stepForm.stepContent[step] = content?.map(item => {
        const prevItem = prevContent?.find(pc => pc.stepKey === item.stepKey);
        return { ...item, stepDetails: prevItem?.stepDetails || [] };
      })
    },
    SET_STEP_SUB_CONTENT: (stepForm, action) => {
      const {step, stepKey, stepDetails} = action.payload;
      const content = stepForm.stepContent[step];
      const subContentItem = content?.find(item => item.stepKey === stepKey);
      if(subContentItem) {
        subContentItem.stepDetails = stepDetails;
      }
      
    },
    SET_EDIT_FROM_ES: (stepForm, action) => {
      stepForm.editFromEnrollmentSummery = action.payload;
    },
    PRODUCT_SELECTION_CHANGED: (stepForm, action) => {
      stepForm.productSelectionChanged = action.payload;
    },
    SET_SKIP_DEPENDENT_SCREEN: (stepForm, action) => {
      stepForm.skipDependentStep = action.payload;
    },
    SET_CURRENT_STEP_NO: (stepForm, action) => {
      stepForm.currentStepNb = action.payload;
    },
  },
});

export const {  PRODUCT_SELECTION_CHANGED,  HIDE_CONTINUE_BUTTON, HIDE_BACK_BUTTON,GET_DATA,CURRENT_STEP,STEP_KEYS,PREVIOUS_STEP, SET_STEP_CONTENT, SET_STEP_SUB_CONTENT, SET_EDIT_FROM_ES  , SET_SKIP_DEPENDENT_SCREEN, SET_CURRENT_STEP_NO} = slice.actions;

export default slice.reducer;
