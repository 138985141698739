import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Banner, Checkbox, Button, Linker } from '../../Atoms';
import Panel from '../../Molecules/Panel/Panel';
import './styles.css';
import {
  callBearer,
  callRecaptchaValidator,
  logoutUser,
  userTypeMFA,
  setProcessType,
  apiReceivedMFA,
  apiReceivedUserTypeAndLanguage,
  SET_AUTHENTICATED_USER,
} from '../../../Store/Slices/Auth/userAuth';
import { processAuthTokenType } from '../../../Store/Slices/AuthToken/userAuthToken';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import APIRequest from '../../Utils/Helper/APIHelper';
import { onLoginAPI, logoutUserResponse, onLoadAPI } from '../../../Store/Slices/Entities/response/index';
import { SHOW_LOADER } from '../../../Store/Slices/Loader/index';
import { updateEnvironment } from '../../../Store/Slices/Auth/userAuth/index';
import dataService from '../../../Common/Services/DataService';
import { APIRequestAction } from '../../../Store/Slices/Entities/response';
import { apiReceived, logoutUserEntity } from '../../../Store/Slices/Auth/loggedUserEntity/index';
import Loader from '../../Atoms/Loader';
import { useSelector } from 'react-redux';
import http from '../../../Common/Services/HttpService/index';
import $ from 'jquery';
import Recaptcha from '../../Molecules/Recaptcha';
import appConfig from '../../../appConfig';
import {
  CLEAR_REGISTERATION,
  SET_USER_TYPE_LANGUAGE,
  SET_MFA_API_PARAM,
  SET_MFA_BASIC_AUTH_VALUE,
  SET_MFA_PRIMARY_LOGIN_PAYLOAD,
  SET_MFA_TOKEN,
  SET_MFA_URL,
  RESET_REGISTERATION,
  NEW_REGISTERATION,
  SET_MFA_LOGIN,
  SET_PREFERRED_CHANNEL,
  SET_PHONE_FROM_OKTA,
} from '../../../Store/Slices/Registeration';
import { CLEAR_SSN, SET_SSN_VALUE } from '../../../Store/Slices/ControlSSN';
import axios from 'axios';
import { RESET_PASSWORD, RETRIEVE_USER } from '../../../Store/Slices/ForgotUser';
import { array } from 'yup';
import { NEW_REGISTERATION_ERROR, SET_MFA_SERVICE_UNAVAILABLE } from '../../../Store/Slices/RegisterationError';
import { CipherUtils } from '../../Utils/Helper/MFAHelper';
import newLoginImage from '../../../Assets/Images/new_login_image.png'; // Directly import the image

const Customlogin = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const ur = useSelector((storeState) => storeState?.registeration?.url);
  const redirectToLogin = useSelector((storeState) => storeState?.registeration?.redirectToLogin);
  const loginRedirect = useSelector((storeState) => storeState?.registeration?.loginRedirect);
  const { loginConfigInfoLogo, loginConfigInfo } = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList);

  const { userAuth } = useSelector((storeState) => storeState.auth);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: false,
    environment: '',
  });
  const [content, setContent] = useState({
    title: '',
    subtitle: '',
    panelTitle: '',
    welcomeMessage: '',
    welcomeTitle: '',
    banner: {},
    icon: '',
    authUrl: '',
    redirectUrl: '',
    helpLinks: [],
    inputPlaceHolder: '',
    passwordPlaceHolder: '',
    buttonLable: '',
    rememberMeLable: '',
    titleColor: '',
    msgColor: '',
    noAuthorizedMFA: '',
    authFailedMFA: '',
  });
  const [showLoader, setShowLoader] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [username, setUsername] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [invalidEmailClass, setinvalidEmailClass] = useState('');
  const [invalidLogin, setinvalidLogin] = useState('');
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [logo, setLogo] = useState('');
  const recaptchaErrorMessage = 'Something went wrong during login, try again';
  const [clientLogo, setClientLogo] = useState(false);
  const recaptchaRef = React.useRef({});
  const { clientId } = useSelector((storeState) => storeState.auth.userAuth);

  const [resp, setResp] = useState({});
  const [err, setErr] = useState({});

  const accountLockedMsg = 'Your account is locked. Please wait for 15 mins for the account to unlock';
  const incorrectCredentialMsg = 'Email address or Password incorrect';
  const serverUnavailable = 'Server unavailable'//'Request failed with status code 503';
  const timeOutExceeded = 'timeout of 30000ms exceeded'


  useEffect(() => {
    if (content && content.redirectUrl)
      setShowLoader(false);
    else
      setShowLoader(true);
  }, [content]);

  useEffect(() => {
    dispatch(NEW_REGISTERATION());
    dispatch(NEW_REGISTERATION_ERROR());
  }, []);

  const callLoginConfigAPI = async () => {
     if (!paramObj.legalEntityId) return
    return await http
      .get(appConfig.SSP_MID_URL + `/v1/config/${paramObj.legalEntityId.substring(0, paramObj.legalEntityId.indexOf(':'))}`)
      .then(async (resp) => {
        const data = resp?.data?.data
        for (const key in data) {
          let result = { _Result: data[key].data }
          dispatch(onLoadAPI({ [key]: result }));
        }
        return true;
      })
      .catch((e) => {
        return false;
      });
  };

  useEffect(() => {
    if (!paramObj.legalEntityId) return () => {};
    if (!loginConfigInfo) callLoginConfigAPI()
  }, [!loginConfigInfo]);

  dispatch(CLEAR_SSN(true));
  dispatch(SET_SSN_VALUE(''));
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    });
    if (event.target.name == 'username') setUsername(event.target.value);
    const regexEmailFormat = /^(?=.{1,256})(?=.{1,64}@.{1,255}$)[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(
      $('#username').val()
    );
    setIsValidEmail(regexEmailFormat);
    setinvalidEmailClass(!regexEmailFormat ? '#A61003' : '#DADADA');
  };
  // get param from uri- mfa
  const GetUrlParams = () => {
    const authResult = new URLSearchParams(window.location.search);
    const legalEntityId = authResult.get('legalEntityId');
    const appCode = authResult.get('appCode');
    let paramObject = {
      legalEntityId: legalEntityId,
      appCode: appCode,
    };
    if (legalEntityId && appCode) {
      dispatch(SET_MFA_API_PARAM(paramObject));
      dispatch(SET_MFA_URL(`?${authResult}`));
    }
    return paramObject;
  };
  let paramObj = GetUrlParams();

  const hideRecaptcha = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.loginConfigInfo?._Result?.hideRecaptcha
  );
  useEffect(() => {
    const getLoingConfig = async () => {
      if (!paramObj.legalEntityId || !props.content) return
      try {
        // const { data: result } = await http.get(
        //   `${appConfig.SSP_MID_URL}/loginConfiguration/${paramObj.legalEntityId.substring(0, paramObj.legalEntityId.indexOf(':'))}`
        // );
        // dispatch(onLoadAPI({ loginConfigInfo: {_Result: result} }));
        const result = loginConfigInfo?._Result
        let contentData = props.content[props.type];
        var clientLogodata = result?.hideLogo;
        setClientLogo(clientLogodata);
        if (contentData) {
          if (result.welcomeTitle) {
            contentData['welcomeTitle'] = result.welcomeTitle;
          }
          if (result.welcomeMsg) {
            contentData['welcomeMessage'] = result.welcomeMsg;
          }
          if (result.titleColor) {
            contentData['titleColor'] = result.titleColor;
          }
          if (result.msgColor) {
            contentData['msgColor'] = result.msgColor;
          }
        }

        setContent(contentData);
      } catch (e) {
        setContent(props.content[props.type]);
      }
    };
    getLoingConfig();
  }, [props.content, paramObj.legalEntityId, loginConfigInfo]);

  useEffect(() => {
    const getLogo = async () => {
      if (!paramObj.legalEntityId) return () => {}
      try {
        // const { data: result } = await http.get(
        //   `${appConfig.SSP_MID_URL}/loginConfiguration/logo/${paramObj.legalEntityId.substring(0, paramObj.legalEntityId.indexOf(':'))}`
        // );
        const result = loginConfigInfoLogo?._Result
        setLogo(result);
      } catch (e) {
       
      }
    };
    getLogo();
  }, [paramObj.legalEntityId, loginConfigInfoLogo]);

  //vreate header auth - mfa
  const getBasicAuthHeaderValue = () => {
    let authValue = process.env.REACT_APP_MFA_AUTH_USER;
    
    // Encoding into base64 using btoa
    let basicAuthHeaderValue = authValue ? btoa(authValue) : '';
    
    return basicAuthHeaderValue;
};


  let basicAuthorizationValue = getBasicAuthHeaderValue();
  basicAuthorizationValue && dispatch(SET_MFA_BASIC_AUTH_VALUE(basicAuthorizationValue));
  let errArray = [];
  let bearerResponse;
  // get remote_user from login api resp
  const getRemoteUserFromResponse = (response) => {
    if (response) {
      let requiredValue = 'remote_user';
      let params = response.split('&');
      for (let i = 0; i < params.length; i++) {
        let value = params[i].split('=');
        if (value[0] === requiredValue) {
          return value[1];
        }
      }
      return false;
    }
  };
  const submitMFAToken = async (redirectionUrl) => {
    let remote_user = getRemoteUserFromResponse(redirectionUrl)// || userAuthData.authenticatedUser;
    let decrypted;
    let value = decodeURIComponent(remote_user);

    if (remote_user) {
      try {
        decrypted = await CipherUtils.decrypt(value, process.env.REACT_APP_SSP_PRIVATE_KEY);
      } catch (err) {
        console.error('crypto support is disabled!');
      }
      const email = decrypted.slice(4);
      dispatch(SET_MFA_LOGIN({ decryptedEmail: decrypted, email: email }));
      await dispatch(apiReceivedMFA(decrypted));
      /** Second API call - getUserBUID */
      const userName = { loginName: decrypted }//||'mfa_'+localStorage.getItem("login")};
      await dispatch(apiReceivedUserTypeAndLanguage({ userType: props.type, language: currentLanguage }));
      // let authPreRequest = dispatch(
      //   APIRequestAction({ userName }, { apiconfig: { url: '/getLoginUser' } })
      // );
      // // if (!authPreRequest) {
      // //   setLoginError(true);
      // // }
      // authPreRequest.then(async (result) => {
      //   let data = {};
      //   result = result?._Result;
      //   Object.assign(data, { person: result });
      //   /** store reponse object in store */
      //   await dispatch(apiReceived(data));
      //   await dispatch(apiReceivedUserTypeAndLanguage({ userType: props.type, language: currentLanguage }));
      //   let authReqData;
      //   authReqData = result && result ? { buid: result.bUID } : {};
      //   //additionalInformation = result && result ? { employeebuid: result.bUID } : {};

      //   /** Third API call - getUserDetaile */
      //   let loggedUserEntity = await dispatch(
      //     //APIRequestAction({ authReqData }, { apiconfig: { url: '/getEmployments' } })
      //     //APIRequestAction({ authReqData }, { apiconfig: { url: '/getEmployments' } })
      //     APIRequestAction({ authReqData }, { apiconfig: { url: '/getEmployments' } })
      //   );
      //   let onLoginApiCalls = [
      //     {
      //       apiconfig: {
      //         url: '/gbpForLegalEntity',
      //         APIfn: 'getAPIResponse',
      //         objectName: 'gbpForLegalEntity',
      //         saveInRedux: true,
      //       },
      //     },
      //   ];
      //   Object.assign(data, { personDetails: loggedUserEntity });
      //   if (onLoginApiCalls) {
      //     await APIRequest(onLoginApiCalls, { loggedUserEntity }).then((data) => {
      //       if (data) {
      //         dispatch(onLoginAPI(data));
      //       }
      //     });
      //   }
      //   // if (!loggedUserEntity) {
      //   //   setLoginErrorMFA(true);
      //   // }
      //   /** store reponse object in store */
      //   await dispatch(apiReceived(data));
      //   // setUserAuthenticated(true);
      // });
    }
  };

  const handleSubmitWithRecaptch = async() =>{
    const captchaToken = await recaptchaRef.current.executeAsync();
    if (captchaToken) {
      const recaptchaResponse = await dispatch(
        callRecaptchaValidator(captchaToken)
      );
      const successCaptcha = recaptchaResponse && recaptchaResponse.data && recaptchaResponse.data._Result && recaptchaResponse.data._Result.success;
      if ( successCaptcha ) {
    setRecaptchaError(false);
    /* function to submit login credentials and to call userEntity */
    setShowLoader(true);
    dispatch(SHOW_LOADER(true));
    setLoginError(false);
    http.setUserType(props.type);
    http.setLanguage(currentLanguage);
    await dispatch(
      updateEnvironment({
        userType: props.type,
      })
    );
    dispatch(SET_USER_TYPE_LANGUAGE({ userType: props.type, language: currentLanguage }));
    props.type && dispatch(userTypeMFA(props.type));
    dispatch(setProcessType('non-sso'))
   
    let data = {
      login: formData.username,
      password: formData.password,
      legalEntityId: paramObj.legalEntityId,
      appCode: paramObj.appCode,
    };
    formData.username &&
      formData.password &&
      dispatch(
        SET_MFA_PRIMARY_LOGIN_PAYLOAD({ login: formData.username, password: formData.password })
      );
    let url = '/stateful/sign-in/1';

    if (content.redirectUrl) {
      bearerResponse = await http
        .post(appConfig.SSP_AUTH_URL + `${url}`, data, {
          withCredentials: true,
          headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200),
          },
        })
        .then((res) => {

          let authenticated = res?.data?.authenticated;

          if (res?.data?.token) {
            res?.data?.token && dispatch(SET_MFA_TOKEN({ token: res.data.token }));
            dispatch(SET_MFA_TOKEN({ token: res.data.token }));
            if (!authenticated) {
              history.push(content.redirectUrl);
            }
          }
          /**handle authenticated user */
          if (res?.data?.redirectionUrl && paramObj?.appCode !== 'ssp') {
            const urlFromResponse = res.data.redirectionUrl;
            urlFromResponse && window.open(urlFromResponse, '_self');
          }




          const yourFunction = async () => {
            if (res?.data?.redirectionUrl && paramObj?.appCode === 'ssp') {
              dispatch(SET_AUTHENTICATED_USER(formData.username));
              try {
                await submitMFAToken(res.data.redirectionUrl);
                const urlFromResponse = res.data.redirectionUrl.substring(0, res.data.redirectionUrl.indexOf('?'));
                const splittedUrl = urlFromResponse.split('/');
                let welcomePageUrl = splittedUrl[splittedUrl.length - 2] + '/' + splittedUrl[splittedUrl.length - 1];
                history.push(welcomePageUrl);
              } catch (error) {
                console.error('An error occurred:', error);
                // Handle the error appropriately
              }
            }
          }
          yourFunction();
         


    


          setResp(res);
          setErr({});
          setinvalidLogin(res?.details ? '#A61003' : '');
          setinvalidEmailClass(res?.details ? '#A61003' : '#DADADA');
          let channel = res?.data?.channel;
          channel && dispatch(SET_PREFERRED_CHANNEL(channel))

          let phoneNumber = res?.data?.phoneNumber;
          phoneNumber && dispatch(SET_PHONE_FROM_OKTA(phoneNumber))
        })
        .catch((error) => {
         
          setErr(error);
          setinvalidLogin('#A61003');
          setinvalidEmailClass('#A61003');
          error.message === 'Request failed with status code 401'
            ? setResp({ message: 'Authentication failed', details: 'ACTIVE', source: 'Okta' })
          : error.message === 'Request failed with status code 503'
            ? setResp({ message: serverUnavailable })
          : error.message === 'Request failed with status code 500'
           ? setResp({ message: timeOutExceeded })
          : setResp({ message: incorrectCredentialMsg });
        });
    }
    err && setShowLoader(false);
   
    } else {
      setRecaptchaError(true);
      recaptchaRef.current.reset();
    }
    }

    else if(captchaToken && props.type == "Configurator"){

    }
  }

  const handleSubmitWithOutRecaptch =async() =>{
  
    setRecaptchaError(false);
    /* function to submit login credentials and to call userEntity */
    setShowLoader(true);
    dispatch(SHOW_LOADER(true));
    setLoginError(false);
    http.setUserType(props.type);
    http.setLanguage(currentLanguage);
    await dispatch(
      updateEnvironment({
        userType: props.type,
      })
    );
    dispatch(SET_USER_TYPE_LANGUAGE({ userType: props.type, language: currentLanguage }));
    props.type && dispatch(userTypeMFA(props.type));
    dispatch(setProcessType('non-sso'))
   
    let data = {
      login: formData.username?.trim() ?? '',
      password: formData.password?.trim() ?? '',
      legalEntityId: paramObj.legalEntityId?.trim() ?? '',
      appCode: paramObj.appCode?.trim() ?? '',
    };
    formData.username &&
      formData.password &&
      dispatch(
        SET_MFA_PRIMARY_LOGIN_PAYLOAD({ login: formData.username, password: formData.password })
      );
    let url = '/stateful/sign-in/1';

    if (content.redirectUrl) {
      bearerResponse = await http
        .post(appConfig.SSP_AUTH_URL + `${url}`, data, {
          withCredentials: true,
          headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200),
          },
        })
        .then((res) => {

          let authenticated = res?.data?.authenticated;
      
          if (res?.data?.token) {
            res?.data?.token && dispatch(SET_MFA_TOKEN({ token: res.data.token }));
            dispatch(SET_MFA_TOKEN({ token: res.data.token }));
            if (!authenticated) {
              history.push(content.redirectUrl);
            }
          }
          /**handle authenticated user */
          if (res?.data?.redirectionUrl && paramObj?.appCode !== 'ssp') {
            const urlFromResponse = res.data.redirectionUrl;
            urlFromResponse && window.open(urlFromResponse, '_self');
          }
          if (res?.data?.redirectionUrl && paramObj?.appCode === 'ssp') {
            dispatch(SET_AUTHENTICATED_USER(formData.username))
            submitMFAToken(res.data.redirectionUrl);

            const urlFromResponse = res.data.redirectionUrl.substring(0, res.data.redirectionUrl.indexOf('?'));
            const splittedUrl = urlFromResponse.split('/');
            let welcomePageUrl = splittedUrl[splittedUrl.length - 2] + '/' + splittedUrl[splittedUrl.length - 1];
            history.push(welcomePageUrl)
          }

          setResp(res);
          setErr({});
          setinvalidLogin(res?.details ? '#A61003' : '');
          setinvalidEmailClass(res?.details ? '#A61003' : '#DADADA');
          let channel = res?.data?.channel;
          channel && dispatch(SET_PREFERRED_CHANNEL(channel))

          let phoneNumber = res?.data?.phoneNumber;
          phoneNumber && dispatch(SET_PHONE_FROM_OKTA(phoneNumber))
        })
        .catch((error) => {
        
          setErr(error);
          setinvalidLogin('#A61003');
          setinvalidEmailClass('#A61003');
          error.message === 'Request failed with status code 401'
            ? setResp({ message: 'Authentication failed', details: 'ACTIVE', source: 'Okta' })
          : error.message === 'Request failed with status code 503'
            ? setResp({ message: serverUnavailable })
          : error.message === 'Request failed with status code 500'
           ? setResp({ message: timeOutExceeded })
          : setResp({ message: incorrectCredentialMsg });
        });
    }
    err && setShowLoader(false);
   
  }

  const handleSubmit = async (event) => {
    // 
    event.preventDefault();
    if(process.env.REACT_APP_RECAPTCHA_AVAILIBLE === 'true' && !hideRecaptcha ){
      handleSubmitWithRecaptch()
    }
    else{
      handleSubmitWithOutRecaptch()
    } //handle submit w/o recaptcha}
    //recaptchaRef.current &&  recaptchaRef.current.reset();

    // const captchaToken = await recaptchaRef.current.executeAsync();
    // if (captchaToken) {
    //   const recaptchaResponse = await dispatch(
    //     callRecaptchaValidator(captchaToken)
    //   );
    //   const successCaptcha = recaptchaResponse && recaptchaResponse.data && recaptchaResponse.data._Result && recaptchaResponse.data._Result.success;
    //   alert('successCaptcha '+ successCaptcha)
    //   if ( successCaptcha ) {
    // setRecaptchaError(false);
    // /* function to submit login credentials and to call userEntity */
    // setShowLoader(true);
    // dispatch(SHOW_LOADER(true));
    // setLoginError(false);
    // http.setUserType(props.type);
    // http.setLanguage(currentLanguage);
    // await dispatch(
    //   updateEnvironment({
    //     userType: props.type,
    //   })
    // );
    // dispatch(SET_USER_TYPE_LANGUAGE({ userType: props.type, language: currentLanguage }));
    // props.type && dispatch(userTypeMFA(props.type));
    // dispatch(setProcessType('non-sso'))
   
    // let data = {
    //   login: formData.username,
    //   password: formData.password,
    //   legalEntityId: paramObj.legalEntityId,
    //   appCode: paramObj.appCode,
    // };
    // formData.username &&
    //   formData.password &&
    //   dispatch(
    //     SET_MFA_PRIMARY_LOGIN_PAYLOAD({ login: formData.username, password: formData.password })
    //   );
    // let url = '/stateful/sign-in/1';

    // if (content.redirectUrl) {
    //   bearerResponse = await http
    //     .post(appConfig.SSP_AUTH_URL + `${url}`, data, {
    //       withCredentials: true,
    //       headers: {
    //         Authorization: `Basic ${basicAuthorizationValue}`,
    //         'X-REQUEST-ID': Math.random(200),
    //       },
    //     })
    //     .then((res) => {

    //       let authenticated = res?.data?.authenticated;
    //       
    //       if (res?.data?.token) {
    //         res?.data?.token && dispatch(SET_MFA_TOKEN({ token: res.data.token }));
    //         dispatch(SET_MFA_TOKEN({ token: res.data.token }));
    //         if (!authenticated) {
    //           history.push(content.redirectUrl);
    //         }
    //       }
    //       /**handle authenticated user */
    //       if (res?.data?.redirectionUrl && paramObj?.appCode !== 'ssp') {
    //         const urlFromResponse = res.data.redirectionUrl;
    //         urlFromResponse && window.open(urlFromResponse, '_self');
    //       }
    //       if (res?.data?.redirectionUrl && paramObj?.appCode === 'ssp') {
    //         dispatch(SET_AUTHENTICATED_USER(formData.username))
    //         submitMFAToken(res.data.redirectionUrl);

    //         const urlFromResponse = res.data.redirectionUrl.substring(0, res.data.redirectionUrl.indexOf('?'));
    //         const splittedUrl = urlFromResponse.split('/');
    //         let welcomePageUrl = splittedUrl[splittedUrl.length - 2] + '/' + splittedUrl[splittedUrl.length - 1];
    //         history.push(welcomePageUrl)
    //       }

    //       setResp(res);
    //       setErr({});
    //       setinvalidLogin(res?.details ? '#F44336' : '');
    //       setinvalidEmailClass(res?.details ? '#F44336' : '#DADADA');
    //       let channel = res?.data?.channel;
    //       channel && dispatch(SET_PREFERRED_CHANNEL(channel))
    //     })
    //     .catch((error) => {
    //       
    //       setErr(error);
    //       setinvalidLogin('#F44336');
    //       setinvalidEmailClass('#F44336');
    //       error.message === 'Request failed with status code 401'
    //         ? setResp({ message: 'Authentication failed', details: 'ACTIVE', source: 'Okta' })
    //       : error.message === 'Request failed with status code 503'
    //         ? setResp({ message: serverUnavailable })
    //       : error.message === 'Request failed with status code 500'
    //        ? setResp({ message: timeOutExceeded })
    //       : setResp({ message: incorrectCredentialMsg });
    //     });
    // }
    // err && setShowLoader(false);
   
    // } else {
    //   setRecaptchaError(true);
    //   recaptchaRef.current.reset();
    // }
    // }

    // else if(captchaToken && props.type == "Configurator"){

   // }
  //}//else
  };
  //  let changeUrl =ur;
  //     window.location =changeUrl ?  window.location.pathname+  decodeURIComponent(ur) :'/enrollment'
  const {
    title,
    subtitle,
    panelTitle,
    welcomeMessage,
    welcomeTitle,
    banner = {},
    icon,
    titleColor,
    msgColor,
  } = content || {};

  // useEffect(() => {
  //   if (userAuthenticated) {
  //     if (userAuth && userAuth.data && userAuth.data.access_token && !loginError) {
  //       history.push(content.redirectUrl);
  //     }
  //     setShowLoader(false);
  //     setLoginError(true);
  //     setUserAuthenticated(false);
  //   }
  // }, [userAuthenticated]);

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  // let render errMsg =
  const isDisabled = !(!!formData['username'] && !!formData['password'] && !!isValidEmail);
  return (
    // <Banner desktop={banner.desktop} tablet={banner.tablet}>
    <div className="container-fluid" role="main">
      <div className="row">
        <div className="col-lg-7 col-xl-7 d-none d-md-block px-0 " >
         

            <img
              src={newLoginImage}
              alt="Four Employees Enrolling"
              className="img-responsive"
              width="100%"
              height="100%"
              id="employees-erolling-client-logo"
            />
          <div className="row overlay-content px-3 mx-5 pt-3 ">
            <div className="col-12">
              <div style={{ color: titleColor ? titleColor : '#0065ac' }} className="bannerTitle">
                {welcomeTitle}
              </div>
            </div>
            <div className="col-12 ">
              <p style={{ color: msgColor ? msgColor : '#202020' }} className="bannerBody">
                {welcomeMessage}
              </p>
            </div>
          </div>
          {/* <Panel
            className="panel d-flex flex-column align-items-center justify-content-between rounded px-3 py-4 "
            title={welcomeTitle}
            body={welcomeMessage}
            // img={icon}
            // hasDivider={false}
          ></Panel> */}
        </div>

        {/* </div> */}
        {/* <div class="col-lg-1 col-xlg-1 col-md-1"></div> */}
        <div className="col-lg-5">
          {' '}
          <form
            onSubmit={handleSubmit}
            className="offset-xl-1  offset-md-2 offset-lg-0 col-md-8 col-lg-11 d-flex flex-column"
          >
            <div className="row pb-3 px-0 ">
              <div className="col-12 pt-0 pb-3 px-0 ">
                <div className="row text-center ">
                  {!clientLogo && logo && (
                    <div className="col-12 ">
                      <img
                        src={logo ? logo : ""}
                        alt="Client Logo"
                        className="img-responsive pt-3 logo-fixed-size"
                        id="client-logo"
                      />
                    </div>
                  )}

                  {title && (
                    <div className="col-12 ">
                      <h3 className="loginHeader ">{title}</h3>
                    </div>
                  )}
                  <div className="col-12 px-0 ">
                    <div className="devider "></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              {' '}
              <div className="col-12   ">
                <p className="logInTitle  mb-3 mt-4  ">{subtitle}</p>
              </div>
              <div className="col-12 ">
                <p className="logInTitleDevider mb-4 "></p>
              </div>
              <div className="col-12 ">
                <span className="inputLable">User ID</span>
                <input
                  id="username"
                  name="username"
                  onChange={handleChange}
                  value={formData.username}
                  placeholder={(content && content.inputPlaceHolder) || ''}
                  className={
                    isValidEmail
                      ? ` w-100 inputField my-3 p-3  `
                      : `w-100 inputField mt-3 mb-1 p-3  `
                  }
                  style={{ borderColor: invalidEmailClass }}
                />
              </div>
              {!isValidEmail && (
                <div className="col-12 mb-3 ">
                  <span style={{ color: invalidLogin || invalidEmailClass }}>
                    {content.emailFormatError}{' '}
                  </span>
                </div>
              )}
              <div className="col-12 ">
                <span className="inputLable">Password</span>
                <input
                  id="password"
                  name="password"
                  onChange={handleChange}
                  value={formData.password}
                  placeholder={(content && content.passwordPlaceHolder) || ''}
                  className="w-100 inputField my-2 p-3  "
                  type="password"
                  style={{ borderColor: invalidLogin }}
                />
              </div>
              {resp && resp.message && Object.keys(resp).length > 0 && (
                <div className="col-12 mb-3 ">
                  <span style={{ color: invalidLogin }}>
                    {
                      resp.details ?
                        resp.details === 'LOCKED_OUT' ?
                          accountLockedMsg :
                          resp.details === 'ACTIVE' && resp.message === 'Authentication failed' ?
                            incorrectCredentialMsg :
                            resp.message + ' - ' + resp.details :
                        resp.message
                    }
                  </span>
                </div>
              )}
              {err &&
                err.message &&
                // Object.keys(err).length > 0 && !resp?.details && (
                Object.keys(err).length > 0 && !resp && (

                  //Object.keys(resp).length === 0 && (
                  <div className="col-12 mb-3 ">
                    <span style={{ color: invalidLogin }}>{err.message} </span>
                  </div>
                )}
            </div>
            <div className="row py-2 ">
              {content &&
                content.helpLinks &&
                content.helpLinks.length > 0 &&
                content.helpLinks.map((currentLink) => {
                  return (
                    <Linker
                      to={currentLink.to}
                      key={currentLink.text}
                      className={`${currentLink.className} currentLink`}
                      onClick={() => {
                        dispatch(logoutUser());
                        dispatch(logoutUserEntity());
                        dispatch(logoutUserResponse());
                        dispatch(RETRIEVE_USER(false));
                        dispatch(RESET_PASSWORD(false));
                        dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: '', redirectToLogin: false }));
                        http.setUserType('');
                        http.setHeaderValue('REMOTE_USER','');
                        http.setAuthTokenUserType('');
                        dispatch(processAuthTokenType({ authTokenType: ''}))
                      }}
                    >
                      {currentLink.text}
                    </Linker>
                  );
                })}
            </div>
            <div className="row ">
              {recaptchaError && (
                <div className="col-12">
                  <span style={{ color: 'rgb(244, 67, 54)' }}>{recaptchaErrorMessage}</span>
                </div>
              )}
              <div className="col-12 ">
                <Button
                  className="btn-block my-3 loginContainerButton inputHeight "
                  type="submit"
                  disabled={isDisabled}
                >
                  {(content && content.buttonLable) || ''}
                </Button>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-8 px-lg-1 px-xl-0 col-8 col-md-8 col-sm-8 py-3"></div> */}
              <div className="col-lg-8 mx-lg-0 px-xl-3 col-8 col-md-8 col-sm-8 py-3 ">
                <div className=" additionalInfoFirst ">{content?.additionalInfoFirst}</div>
                <div className=" additionalInfoSecond ">{content?.additionalInfoSecond}</div>
              </div>
              <div className="col-lg-4 col-4 col-md-4 col-sm-3 py-3 ">
                <Button
                  className="w-100 "
                  outline
                  type="submit"
                  data-target={'#' + '/userRegistration'}
                  data-toggle={'/userRegistration'}
                  onClick={() => {
                    dispatch(CLEAR_REGISTERATION({ email: '', phone: '' }));
                    dispatch(SET_MFA_SERVICE_UNAVAILABLE({ serviceUnavailable: false, mfaErrorText: '', redirectToLogin: false }));
                    history.push('/userRegistration');
                    dispatch(logoutUser());
                    dispatch(logoutUserEntity());
                    dispatch(logoutUserResponse());
                    dispatch(RETRIEVE_USER(false));
                    dispatch(RESET_PASSWORD(false));
                    http.setUserType('');
                    http.setHeaderValue('REMOTE_USER','');
                    http.setAuthTokenUserType('');
                    dispatch(processAuthTokenType({ authTokenType: ''}))
                  }}
                >
                  Register
                </Button>
              </div>
            </div>
            {process.env.REACT_APP_RECAPTCHA_AVAILIBLE === 'true' && !hideRecaptcha ? (
              <Recaptcha
                recaptchaRef={recaptchaRef}
                siteKey={appConfig.RECAPTCHA_KEY}
                isVisible={false}
              />
            ) : (
              ''
            )}
          </form>
          <div>
            {/* <Linker className="btn btn-primary" to="/userRegistration">
                      {(content && content.buttonLabel) || ''}
                    </Linker> */}
          </div>
        </div>
        {/* <div class="col-lg-1 col-xlg-1 col-md-1"></div> */}
      </div>
    </div>
    // </Banner>
  );
};
Customlogin.propTypes = {
  type: PropTypes.string,
};

export default Customlogin;
