// helpers.js
import constants from '../Content/Constants.json';
import DateHelper from './DateHelper';

const getPayCyclesPerYear = (payFrequency) => {
    if (!payFrequency) return 0;
    const frequencyItem = constants?.frequencyListNew.find(frequency => frequency.valueCode === payFrequency);
    return frequencyItem ? (frequencyItem.conversionValue) : 0;
  }

export  const  roundTo = (annual, digits) => {
    if (digits === undefined) {
      digits = 0;
    }
    var multiplicator = Math.pow(10, digits);
    annual = parseFloat((annual * multiplicator).toFixed(11));
    var test =(Math.round(annual) / multiplicator);
    return +(test.toFixed(digits));
  }


// helpers.js
export const  getCostPerPayPeriod= (annual,frequency) =>{
  let payCyclesPerYear = getPayCyclesPerYear(frequency);
  let result = roundTo((annual / (parseInt(payCyclesPerYear))), 2).toFixed(2);
  const [integerPart,decimalPart] = result?.split('.')
  const formattedResult= decimalPart?.length===1?`${integerPart}.${decimalPart}0`:result
return  (formattedResult);
 } 
  

  // helpers.js
export const createPetListPayload = (coverages, employmentInformation, processInfo ) => {
    let petListTableData = [];
   

  
    if (!coverages ||  !employmentInformation ) {
      return { petList: petListTableData };
    }
  
    for (let i = 0; i < coverages.length; i++) {
      const annualCost = coverages[i]?.subscribedOption?.additionalDatas?.filter(
        (data) => data.fieldCode == 'DD_00164'
      )[0]?.fieldValue?.amount;
  
      let payCyclesPerYear =  employmentInformation?.payFreqeuncyValueCode ? (employmentInformation?.payFreqeuncyValueCode) : 0;

  
      let costPerPayPeriod = 0;
      if (payCyclesPerYear > 0 && annualCost) {
        // costPerPayPeriod = parseFloat((annualCost / payCyclesPerYear).toFixed(2));
        costPerPayPeriod = '$'+(getCostPerPayPeriod(annualCost,payCyclesPerYear ));

      }
  
      let petList = {
        petName: coverages[i]?.subscribedOption?.additionalDatas?.filter(
          (data) => data.fieldCode == 'DD_00169'
        )[0]?.fieldValue,
        petType: coverages[i]?.subscribedOption?.additionalDatas?.filter(
          (data) => data.fieldCode == 'DD_00168'
        )[0]?.fieldValue,
        coverageLevel: coverages[i]?.subscribedOption?.additionalDatas?.filter(
          (data) => data.fieldCode == 'DD_00175'
        )[0]?.fieldValue,
        planCode : coverages[i]?.subscribedOption?.additionalDatas?.filter(
          (data) => data.fieldCode == 'DD_00174'
        )[0]?.fieldValue,
        petId : coverages[i]?.subscribedOption?.additionalDatas?.filter(
          (data) => data.fieldCode == 'DD_00167'
        )[0]?.fieldValue,


        

        

        costPerPayPeriod: costPerPayPeriod,
        coverageId: coverages[i]?.bUID,
        waivedCoverage: false,
        CoverageIdNew: coverages[i]?.bUID,
        coverageStatus : coverages[i]?.subscribedOption?.status 
      };
       // if coverage is Cactive and plannedTerminationDate > effectiveDate -> coverage  Not Waived
       // effectiveDate > plannedTerminationDate ->  coverage is Waived
       const effectiveDate = processInfo?.processEntity?.effectiveDate;
       const plannedTerminationDate = coverages[i]?.subscribedOption?.plannedTerminationDate;
      if (
        (coverages[i]?.subscribedOption?.status == 'cProject' ||  coverages[i]?.subscribedOption?.status == 'cActive' ) &&
        !(effectiveDate &&  plannedTerminationDate &&  DateHelper.isGreater(effectiveDate, plannedTerminationDate)) ) {
        petListTableData.push(petList);
      }
  
    
    }
  
    return petListTableData ;
  };


/**
 * This function checks an array of coverage objects, 
 * and determines if coverage should be waived based on certain conditions.
 * @param {Array} coverages - The coverages array to check.
 * @returns {boolean} - Returns true if coverage should be waived, false otherwise.
 */


export const shouldWaiveCoverage = (coverages, processInfo) => {
  // Get the effective date from the processInfo
  const effectiveDate = processInfo?.processEntity?.effectiveDate;

  // Filter the coverages array to include only coverages with a status of 'cProject' or 'cActive'
  // and where effectiveDate is not greater than plannedTerminationDate
  let filteredCoverages = coverages?.filter(coverage => {
    const plannedTerminationDate = coverage?.subscribedOption?.plannedTerminationDate;
    let status = coverage?.subscribedOption?.status;
    return (status === 'cProject' || status === 'cActive') &&
      !(effectiveDate && plannedTerminationDate && DateHelper.isGreater(effectiveDate, plannedTerminationDate));
  });

  // Check if the filtered coverages array is empty or not
  // If it's empty, return true (indicating coverage should be waived)
  // If it's not empty, return false (indicating coverage should not be waived)
  return !(filteredCoverages?.length > 0);
}


/**
 * Determine the disclosure radio value based on the product status
 * @param {string} productStatus - The current status of the product
 * @returns {string} - The disclosure radio value
 */

export const  getDisclosureRadioValue = (productStatus)  =>{
  if(productStatus ===  'cDeclined' || productStatus === 'cTerminated' || productStatus === 'cNeverInForce')  {
      return "No";
  } else {  
      return "Yes";
  }
}
  
export const  isActiveProduct = (productStatus)  =>{
  if(productStatus ===  'cDeclined' || productStatus === 'cTerminated' || productStatus === 'cNeverInForce')  {
      return false
  } else {  
      return true
  }
}

export const  isWaivedProduct = (productStatus)  =>{
  if(productStatus ===  'cDeclined' || productStatus === 'cTerminated' || productStatus === 'cNeverInForce')  {
      return true
  } else {  
      return false
  }
}


export const coverageLevelMapping = {
  'VB25050': 'My Pet Protection® 50%',
  'VBWL525050': 'My Pet Protection® 50% With Wellness Coverage',
  'VB25070': 'My Pet Protection® 70%',
  'VBWL525070': 'My Pet Protection® 70% With Wellness Coverage'
};  


export const petCoverageList = 
['VB25050','VBWL525050','VB25070', 'VBWL525070',
'CVB25050','CVBWL525050','CVB25070', 'CVBWL525070',
'DVB25050','DVBWL525050','DVB25070', 'DVBWL525070'
];
 
  
export const  showHideDeleteButton = ['2','3','4'];


//Function to get first 5 digits of zipcode
export const  getFirstFiveDigits=(value)=> {
  let stringValue = String(value);
  return stringValue?.substring(0, 5);
}


  


  