import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response/index';
import { reduxConstants } from '../../../../Common/Constants/index';
import Loader from '../../../Atoms/Loader';
import formFields from '../Content/SummaryScreenConfig.json';
import { retrieveConfig } from '../Actions/retrieveConfig';
import { useFormik } from 'formik';
import { saveProfile } from '../Actions/saveConfig';
import SuccessPage from '../Common/SuccessPage';
import { CLEAR_ALERT_MESSAGES, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { MessageComponent } from '../../../Atoms';
import { getErrorMessages } from '../../../Molecules/CustomSection/Helper/MappingHelper';
import { Checkbox, TextInput, TextArea, Button, Label } from '../../../Molecules/CustomSection/Atoms';
import '../styles.css';
const SummaryScreenConfig = (props) => {
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const dispatch = useDispatch();

    let loggedUserEntity =
        useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};
    const loginuserName = useSelector((storeState) => storeState?.auth?.userAuth?.data?.user_name);

    let { clientId } = useSelector((storeState) => storeState.auth.userAuth);

    const configDataRedux = useSelector(
        (storeState) => {
            return storeState?.entities?.response?.onLoadResponseList?.preConfirmationConfigInfo;
        }
    );

    let configData = {};

    try {
        configData = {
            deductionVerbiagePlaceholder: configDataRedux?.deductionVerbiagePlaceholder || false,
            deductionVerbiagePlaceholderValue: configDataRedux?.deductionVerbiagePlaceholderValue || "",
        };
    } catch (e) {
        configData = {
            deductionVerbiagePlaceholder: false,
            deductionVerbiagePlaceholderValue: "",
        };
    }


    const formik = useFormik({
        initialValues: {
            deductionVerbiagePlaceholder: configData?.deductionVerbiagePlaceholder,
            deductionVerbiagePlaceholderValue: configData?.deductionVerbiagePlaceholderValue,
        },

        onSubmit: (values, { setSubmitting }) => {
            formik.resetForm();
            setSubmitting(false);
        },
        enableReinitialize: true,
    });



    const userName = { loginName: loginuserName };

    useEffect(() => {
        // //Api Call here
        setShowLoader(true)
        const callAPI = async () => {
            let response = await retrieveConfig(clientId, formFields.apiCall.onload);
            console.log("response=====", response)
            if (!response?.preConfirmationConfigInformation) {
                setShowSuccess(null);
                setTimeout(() => {
                    dispatch(SET_ALERT_MESSAGE({
                        type: 'error',
                        errorCode: '520',
                        continue: true,
                        id: 'getProductConfig',
                        show: true,
                        page: 'Configurator',
                    }));
                    setShowSuccess(false);
                }, 50);
            }
            dispatch(
                onLoadAPI({
                    preConfirmationConfigInfo: response?.preConfirmationConfigInformation
                        ? { ...response?.preConfirmationConfigInformation } : {},
                    isAPICallInProgress: false,
                })
            );
            setShowLoader(false)
        };
        try {
            callAPI();
        } catch (error) {

        }
    }, [loggedUserEntity]);

    const savePreConfirmationConfig = async () => {
        let data = {
            deductionVerbiage: {
                isEditable: formik?.values?.deductionVerbiagePlaceholder,
                label: formik?.values?.deductionVerbiagePlaceholderValue,
            }
        };
        setShowSuccess(null);
        let saveResponse = await saveProfile(data, clientId, formFields.apiCall.submit, setShowLoader);
        if (saveResponse?.status == 200) {
            console.log("save response", saveResponse)
            setShowSuccess(true);
        } else {
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: '520',
                continue: true,
                id: 'saveProductConfing',
                show: true,
                page: 'Configurator',
            }));
            setShowSuccess(false);
        }
    };
    useEffect(() => {
        dispatch(CLEAR_ALERT_MESSAGES([]));
    }, [formik.values]);

    if (showLoader) {
        /* loader to show untill API call gets completed */
        return <Loader loading={showLoader} />;
    }

    const hasErrors = getErrorMessages('Configurator');



    return (
        <div>
            <MessageComponent currentPage={'Configurator'} setShowLoader={setShowLoader} />
            <Suspense fallback={<div>Loading...</div>}>
                <div className="formWrapper">
                    {!showSuccess && (
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="config">
                                <div className="col-12 px-5">
                                    <div class="row py-3 px-3">
                                        <h4>Pre-Confirmation Configuration</h4>
                                    </div>
                                    <div class="row">
                                        <div className="col-3 py-2 no-padding-right">
                                            <Label
                                                text={formFields.dataFieldProduct.label}
                                                inputId={formFields.dataFieldProduct.id}
                                            />
                                        </div>
                                        <div className="col-3 py-2 no-padding-right">
                                            <Label
                                                text={formFields.editablePlaceholderLabelProduct.label}
                                                inputId={formFields.editablePlaceholderLabelProduct.id}
                                            />
                                        </div>
                                        <div className="col-3 py-2 no-padding-right">
                                            <Label
                                                text={formFields.editablePlaceholderLabelValueProduct.label}
                                                inputId={formFields.editablePlaceholderLabelValueProduct.id}
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col-3 py-2 no-padding-right">
                                            <Label
                                                text={formFields.deductionVerbiage.label}
                                                inputId={formFields.deductionVerbiage.id}
                                            />
                                        </div>
                                        <div className="col-3 py-2 no-padding-right">
                                            <Checkbox
                                                id={formFields.deductionVerbiagePlaceholder.id}
                                                name={formFields.deductionVerbiagePlaceholder.name}
                                                checked={formik.values.deductionVerbiagePlaceholder}
                                                className="regularCheckbox"
                                                {...formik.getFieldProps(formFields.deductionVerbiagePlaceholder.name)}
                                            />
                                        </div>
                                        <div className="col-3 py-2 inputConfig">
                                            <TextArea
                                                id={formFields.deductionVerbiagePlaceholderValue.id}
                                                name={formFields.deductionVerbiagePlaceholderValue.name}
                                                placeholder={formFields.deductionVerbiagePlaceholderValue.placeholder}
                                                {...formik.getFieldProps(formFields.deductionVerbiagePlaceholderValue.name)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row w-100 form-btnGroup py-5">
                                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 py-2 w-100">
                                        <Button className="btn btn-primary  primaryBackground mr-4"
                                            onClick={savePreConfirmationConfig} disabled={hasErrors}>
                                            Submit
                                        </Button>

                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                    {showSuccess && <SuccessPage {...formFields.confirmation}></SuccessPage>}
                </div>
            </Suspense>
        </div>
    );
};

export default SummaryScreenConfig;