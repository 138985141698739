import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import $ from 'jquery';
import {
  CLEAR_ALERT_MESSAGES,
  SET_ALERT_MESSAGE,
  UPDATE_ALERT_MESSAGE,
} from '../../../Store/Slices/InsuredInfo';
import iconError from '../../../Assets/Images/iconError.png';
import iconSuccess from '../../../Assets/Images/icon_success.png';
import iconWarning from '../../../Assets/Images/icon-warning.png';
import iconImportant from '../../../Assets/Images/icon-important.png';
import MessageCustomComponent from '../MessageCustomComponent';
import { useHistory } from 'react-router-dom';
import { IS_HR_CENTER_RE_DIRECTED } from '../../../Store/Slices/CallCenter/index'

const getType = (type) => {
  let alertClass = '';
  let Icon;
  switch (type) {
    case 'error':
      alertClass = `alert-danger`;
      Icon = iconError;
      break;
    case 'success':
      alertClass = `alert-success`;
      Icon = iconSuccess;
      break;
    case 'info':
      alertClass = `alert-warning`;
      Icon = iconWarning;
      break;
    default:
      alertClass = `alert-primary`;
      Icon = iconImportant;
      break;
  }
  return {
    alertClass,
    Icon,
  };
};

const getErrorCode = (errorCode) => {
  let newMessage = '';
  switch (errorCode) {
    case '400':
      newMessage = `Unable to process your request at this time. Please try again later.`;
      break;
    case '401':
      newMessage = `Unable to process your request at this time. Please try again later.`;
      break;
    case '403':
      newMessage = `Unable to process your request at this time. Please try again later.`;
      break;
    case '404':
      newMessage = `Unable to process your request at this time. Please try again later.`;
      break;
    case '429':
      newMessage = `Unable to process your request at this time. Please try again later.`;
      break;
    case '500':
      newMessage = `Unable to process your request at this time. Please try again later.`;
      break;
    case '501':
      newMessage = `Unable to process your request at this time. Please try again later.`;
      break;
    case '502':
      newMessage = `Unable to process your request at this time. Please try again later.`;
      break;
    case '503':
      newMessage = `Unable to process your request at this time. Please try again later.`;
      break;
    default:
      newMessage = `Unable to process your request at this time. Please try again later.`;
      break;
  }
  return newMessage;
};

const MessageComponent = (props) => {
  const {
    config = [],
    currentPage = ''
  } = props;

  const dispatch = useDispatch();

  const history = useHistory();

  const { alertMessages } = useSelector((storeState) => storeState.insuredInfo);
  const [alerts, setAlerts] = useState([]);
  const { userAuth } = useSelector((storeState) => storeState?.auth);

  const isAdmin = useSelector((storeState) => storeState?.callCenter?.isAdmin);
  let isProxy = false
  if (userAuth?.processType === "proxyEnrollment") {
    isProxy = true
  }
  const isHrCenterType = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter") ? true : false
  const isHrCenterReDirected = useSelector((storeState) => storeState?.callCenter?.isHrCenterReDirected);
  let isHrCenter = (isHrCenterReDirected) ? true : false
  const getUniqueObjectsByKey = (arr, key) => {
    const seen = new Set();
    return arr.filter((item) => {
      const itemKey = item[key];
      if (!seen.has(itemKey)) {
        seen.add(itemKey);
        return true;
      }
      return false;
    });
  }

  const handleBackToCallCenterHome = () => {
    dispatch({
      type: 'SETINITIALSTATE'
    })
    dispatch({
      type: 'RESET'
    })
    dispatch(IS_HR_CENTER_RE_DIRECTED(false))
    history.push(userAuth?.redirectUrl);
  };

  useEffect(() => {
    if (alertMessages?.length > 0) {
      const newAlertsArray = [];
      let filteredMessages = alertMessages.filter((pobj) => {
        return pobj.page === currentPage;
      });
      filteredMessages = getUniqueObjectsByKey(filteredMessages, 'id');
      filteredMessages.forEach(conf => {
        let newAlertData = {};
        let newMessage = '';
        let alertClass = '';
        let Icon;
        const newMessageArray = [];
        // Change to Manage multiple messages in 1 bannner
        if (conf?.data?.length > 0) {
          conf?.data?.forEach(message => {
            let errorMessage = '';
            if (message?.errorCode) {
              errorMessage = getErrorCode(message?.errorCode);
            } else if (message?.message) {
              errorMessage = message?.message || '';
            }
            newMessageArray.push(errorMessage);
          });
        } else {
          if (conf?.errorCode) {
            newMessage = getErrorCode(conf?.errorCode);
          } else {
            newMessage = conf?.message || '';
          }
        }

        newAlertData = getType(conf?.type);
        alertClass = newAlertData?.alertClass;
        Icon = newAlertData?.Icon;

        newAlertsArray.push({
          message: newMessage,
          arrayMessages: newMessageArray,
          alertClass,
          Icon,
          show: conf?.show,
          id: conf?.id,
        });
      });

      setAlerts(newAlertsArray);
    } else if (alerts?.length > 0) {
      setAlerts([]);
    }
  }, [alertMessages, currentPage]);

  const getModalBodyFn = (alert) => {
    // if(alert?.arrayMessages?.length > 0){
    //   return alert?.arrayMessages[0];
    // } else if(alert?.arrayMessages?.length === 0){
    //   return alert?.message;
    // }
    return 'Something went wrong. Please try again later';
  };

  return (
    <div style={{ maxHeight: '120px', overflow: 'auto' }} className={currentPage}>
      {
        alerts && currentPage === 'welcomeScreen' && alerts.map((alert, ind) => {
          return (<>
            {
              ind === 0 && (
                <div className={`modal fade ${true ? 'show' : ''} error-welcome`}
                  style={{ display: 'block' }}>
                  <div className="modal-dialog modal-lg error-dialog" role="document"
                    style={{ maxWidth: '800px', maxHeight: '600px' }}>
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">{'Oops...!'}</h5>
                      </div>
                      <div className={"modal-body " + alert.id} style={{ textAlign: 'center', paddingTop: '2rem' }}>
                        {
                          getModalBodyFn(alert)

                        }
                        {
                          isProxy && (<div><button type="button" class="btn btn-link" onClick={handleBackToCallCenterHome}>
                            {isHrCenter ? 'HRC Home' : 'CC Home'}
                          </button></div>)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </>)
        })
      }
      {alerts && currentPage == 'PetAssureNation' && alerts[0] && alerts[0]?.id === "petInsuranceSave" &&
        <MessageCustomComponent
          alerts={alerts}
          currentPage={currentPage}
        />}
      {alerts && currentPage !== 'welcomeScreen' && alerts[0] && alerts[0]?.id !== "petInsuranceSave" && alerts.map(alert => {
        return alert?.show && (
          <div className={`col-9 mt-2 mb-2 mx-auto alert alert-dismissible fade show ${alert?.alertClass}`} role="alert" id={alert?.id}>
            <img
              src={alert?.Icon}
              className='img-fluid pr-2 '
              id="alert-img"
              style={{ width: '26px' }}
              alt="alert_img"
            />
            {alert?.arrayMessages?.length > 0 && (
              alert?.arrayMessages.map((message, index) => {
                return (
                  <span className={`pr-3 ${index !== 0 ? 'd-block pl-4' : ''}`}>{message}</span>
                )
              })
            )}
            {alert?.arrayMessages?.length === 0 && (
              <span className="pr-3 ">{alert?.message}</span>
            )}
            <button
              type="button"
              className="close mt-1 error-close"
              onClick={() => {
                $(`#${alert?.id}`).hide();
                dispatch(UPDATE_ALERT_MESSAGE(alert?.id));
                dispatch(CLEAR_ALERT_MESSAGES([]));
                if (props?.setShowLoader) {
                  props.setShowLoader(null);
                }
                setTimeout(() => {
                  if (currentPage !== 'Configurator') {
                    isAdmin ? history.push(userAuth?.redirectUrl) : history.push('/enrollment/home');
                  } else {
                    if (props?.setShowLoader) {
                      props.setShowLoader(false);
                    }
                  }
                }, 500);
              }}
              style={{ color: "#002C77", fontSize: "0.875rem", fontFamily: "NotoSansMedium" }}>CLOSE
            </button>
          </div>
        );
      })}

    </div>
  )
};
MessageComponent.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  hidden: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  show: PropTypes.bool,
};
MessageComponent.defaultProps = {
  className: '',
  value: '',
  type: '',
  message: '',
  show: false,
};

export default MessageComponent;
