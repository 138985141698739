import React, { useEffect, useState, useMemo } from 'react';
import AccordionItem from '../Accordion/AccordionItem';
import { useSelector, useDispatch } from 'react-redux';
import {
  UPDATE_INSURED_QUESTION_ANSWERS, SHOW_WAIVECOVEAGE_BASEDON_INSUREDQUESTIONS
} from '../../../../../Store/Slices/InsuredInfo';
import Constants from '../../Content/Constants.json'
import { useNavigationHook } from '../../Hooks/NavigationHook'; // Replace with your actual file path

const InsuredQuestions = ({ displayMode, currentBuid, personBuid, checkAnswer, updatedInsureds, disableAccordian, coverageError = [], setHasEligibilityQuestions = () => { }, flowType,memberEligible }) => {
  const dispatch = useDispatch();
  // const [buildsArr, setBuidsArr] = useState([]);
  let dependentsList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsList
  );
  const { currentStep } = useNavigationHook();






  const getAdditionalQuestionsForPerson = (additionalQuestions) => {
    const additionalDatasForPerson = [];

    if (additionalQuestions) {
      additionalQuestions.forEach(additionalQuestion => {
        if (additionalQuestion && additionalQuestion.possibleInsuredPersons) {
          additionalQuestion.possibleInsuredPersons.forEach(possibleInsuredPerson => {
            if (
              possibleInsuredPerson &&
              possibleInsuredPerson.insuredPerson &&

              possibleInsuredPerson.additionalDatas
            ) {
              additionalDatasForPerson.push(possibleInsuredPerson);
            }
          });
        }
      });
    }
    return additionalDatasForPerson;
  };

  let additionalQuestions = useSelector((storeState) => {
    const onLoadResponseList = storeState?.entities?.response?.onLoadResponseList;
    const currentStepProperty = `InsuredAdditionalDataFor${currentStep}`;
    const questions = onLoadResponseList?.[currentStep]?.getAllPossibleChoicesConfigurationForProduct?._Result;
    return getAdditionalQuestionsForPerson(questions);
  });

  let eligibileInsured = useSelector((storeState) => {
    const onLoadResponseList = storeState?.entities?.response?.onLoadResponseList;
    const currentStepProperty = `InsuredAdditionalDataFor${currentStep}`;
    return onLoadResponseList?.[currentStep]?.getAllPossibleChoicesConfigurationForProduct?._Result;
  }) || [];

  const [status, setStatus] = useState("onLoad");





  let InsuredQuestionAnswers = useSelector((storeState) => {
    const insuredInfo = storeState?.insuredInfo;
    const currentFormInfo = insuredInfo?.[currentStep];
    return currentFormInfo?.InsuredQuestionAnswers;
  });

  const { setWaiveCoverageNation } = useSelector((storeState) => storeState.insuredInfo);




  const handleAnswerChange = (buid, questionId, answer) => {
    dispatch(UPDATE_INSURED_QUESTION_ANSWERS({ buid, questionId, answer, currentStep }));

  };



  const sortDependents = (dependents, questions) => {
    const sorted = { employee: null, spouse: null, children: [] };

    dependents.forEach((dependent) => {
      const buid = dependent?.person?.identifier?.bUID;
      const question = questions?.find((item) => item?.insuredPerson?.bUID === buid);

      if (question) {
        const relation = dependent?.insured?.mainOwnerRelationshipToInsured;
        if (!relation) {
          sorted.employee = dependent;
        } else if (Constants.SPOUSE_OR_PARTNER_RELATIONSHIPS.includes(relation)) {
          sorted.spouse = dependent;
        } else if (relation === 'CH') {
          sorted.children.push(dependent);
        }
      }
    });

    return sorted;
  };

  const checkInsuredEligibility = (updatedInsureds) => {
    const lsortedDependent = {};
    let flag = "initial";
    const ldependentKeys = Object.keys(updatedInsureds);
    let lpossibleInsuredPersons = eligibileInsured[0]?.possibleInsuredPersons || [];
    if (displayMode !== 'All' && displayMode !== 'Employee') {
      eligibileInsured?.forEach(pobj => {
        const currentPossibleInsured = pobj?.possibleInsuredPersons || [];
        lpossibleInsuredPersons = [...lpossibleInsuredPersons, ...currentPossibleInsured];
      });
    }
    ldependentKeys.forEach((pkey) => {
      if (!lsortedDependent[pkey]) {
        lsortedDependent[pkey] = [];
      }
      updatedInsureds[pkey].forEach(pupdateInsured => {
        const updateBuid = pupdateInsured.personBuid;
        const findPerson = lpossibleInsuredPersons.find(possiblePerson => {
          return possiblePerson.insuredPerson.bUID === updateBuid;
        });
        if (findPerson) {
          //lsortedDependent[pkey].push(findPerson);
          lsortedDependent[pkey].push(pupdateInsured);
          flag = "Update"
        }
      });
    });
    if (flag === "Update" && status !== "Update") {
      setStatus("Update")
    }
    return lsortedDependent;
  }




  const sortedDependents = checkInsuredEligibility(updatedInsureds);
  useEffect(() => {
    let insuredsToCheck = [];

    switch (displayMode) {
      case 'All':
        insuredsToCheck = [...sortedDependents.employee, ...sortedDependents.spouse, ...sortedDependents.children];
        break;
      case 'Employee':
        insuredsToCheck = sortedDependents.employee ?? [];
        break;
      case 'Spouse':
      case 'Domestic Partner':
      case 'Civil Union Partner':
      case 'Civil Union/Domestic Partner':
        insuredsToCheck = sortedDependents.spouse ?? [];
        break;
      case 'Child':
      case 'Children':
        insuredsToCheck = sortedDependents.children ?? [];
        break;
      default:
        break;
    }

    const hasEligibilityQuestions = insuredsToCheck.some(insured => {
      let questions = additionalQuestions ? additionalQuestions.filter(
        (item) => item?.insuredPerson?.bUID === insured?.personBuid && item?.additionalDatas?.length
      ) : [];
      return questions.length > 0;
    });
    // Dispatch the appropriate action
    if (hasEligibilityQuestions && (memberEligible === undefined || memberEligible===true)) {
      setHasEligibilityQuestions(true)
      dispatch(SHOW_WAIVECOVEAGE_BASEDON_INSUREDQUESTIONS({ showWaive: true, product: currentStep }));
    }
    else if(hasEligibilityQuestions && memberEligible === false) {
      dispatch(SHOW_WAIVECOVEAGE_BASEDON_INSUREDQUESTIONS({ showWaive: false, product: currentStep }));
    } 
    else {
      setHasEligibilityQuestions(false)
      dispatch(SHOW_WAIVECOVEAGE_BASEDON_INSUREDQUESTIONS({ showWaive: false, product: currentStep }));
    }

  }, [status,memberEligible,sortedDependents]);





  const computeStrengthMeterPercentage = (questionsAnswered, totalQuestions) => {
    return (questionsAnswered / totalQuestions) * 100;
  };
  const buildsArr = [];
  const buidDisplayArr = [];
  if (displayMode === 'Employee') {
    if (sortedDependents?.employee?.length > 0) {
      sortedDependents?.employee?.forEach((pobj) => {
        let temp = {};
        if (pobj?.person?.identifier?.bUID) {
          buildsArr.push(pobj?.person?.identifier?.bUID);
          temp = {
            buid: pobj?.person?.identifier?.bUID,
            displayMode,
            person: "employee"
          }
        } else {
          buildsArr.push(pobj?.personBuid);
          temp = {
            buid: pobj?.personBuid,
            displayMode,
            person: "employee"
          }
        }
        buidDisplayArr.push(temp);
      })
    }
  } else if (['Spouse', 'Domestic Partner', 'Civil Union Partner', 'Civil Union/Domestic Partner'].includes(displayMode)) {
    if (sortedDependents?.spouse?.length > 0) {
      sortedDependents?.spouse?.forEach((pobj) => {
        let temp = {};
        if (pobj?.person?.identifier?.bUID) {
          buildsArr.push(pobj?.person?.identifier?.bUID);
          temp = {
            buid: pobj?.person?.identifier?.bUID,
            displayMode,
            person: "spouse"
          }
        } else {
          buildsArr.push(pobj?.personBuid);
          temp = {
            buid: pobj?.personBuid,
            displayMode,
            person: "spouse"
          }
        }
        buidDisplayArr.push(temp);
      })
    }
  }
  else if (displayMode === 'Child') {
    if (sortedDependents?.children?.length > 0) {
      sortedDependents?.children?.forEach((pobj) => {
        let temp = {};
        if (pobj?.person?.identifier?.bUID) {
          buildsArr.push(pobj?.person?.identifier?.bUID);
          temp = {
            buid: pobj?.person?.identifier?.bUID,
            displayMode,
            person: "children"
          }
        }
        else if (pobj?.personBuid === personBuid) {
          buildsArr.push(pobj?.personBuid);
          temp = {
            buid: pobj?.personBuid,
            displayMode,
            person: "children"
          }
        }
        buidDisplayArr.push(temp);
      })
    }
  }
  else if (displayMode === 'Children') {
    if (sortedDependents?.children?.length > 0) {
      if (flowType === 3) {
        sortedDependents?.children?.forEach((pobj) => {
          buildsArr.push(pobj?.personBuid);
          buidDisplayArr.push({
            buid: pobj?.personBuid,
            displayMode,
            person: "children"
          });
        });
      }
    }
  }
  else if (displayMode === 'All') {
    if (sortedDependents?.employee?.length > 0) {
      sortedDependents?.employee?.forEach((pobj) => {
        let temp = {};
        if (pobj?.person?.identifier?.bUID) {
          buildsArr.push(pobj?.person?.identifier?.bUID);
          temp = {
            buid: pobj?.person?.identifier?.bUID,
            displayMode,
            person: "employee"
          }
        } else {
          buildsArr.push(pobj?.personBuid);
          temp = {
            buid: pobj?.personBuid,
            displayMode,
            person: "employee"
          }
        }
        buidDisplayArr.push(temp);
      })
    }
    if (sortedDependents?.spouse?.length > 0) {
      sortedDependents?.spouse?.forEach((pobj, pind) => {
        let temp = {};
        if (pobj?.person?.identifier?.bUID) {
          buildsArr.push(pobj?.person?.identifier?.bUID);
          temp = {
            buid: pobj?.person?.identifier?.bUID,
            displayMode,
            person: "spouse"
          };
          buidDisplayArr.push(temp);
        }
        else {
          if (pind === 0) {
            buildsArr.push(pobj?.personBuid);
            temp = {
              buid: pobj?.personBuid,
              displayMode,
              person: "spouse"
            }
            buidDisplayArr.push(temp);
          }
        }
      })
    }
    if (sortedDependents?.children?.length > 0) {
      sortedDependents?.children?.forEach((pobj) => {
        let temp = {};
        if (pobj?.person?.identifier?.bUID) {
          buildsArr.push(pobj?.person?.identifier?.bUID);
          temp = {
            buid: pobj?.person?.identifier?.bUID,
            displayMode,
            person: "children"
          }
        }
        else {
          buildsArr.push(pobj?.personBuid);
          temp = {
            buid: pobj?.personBuid,
            displayMode,
            person: "children"
          }
        }
        buidDisplayArr.push(temp);
      })
    }
  }




  const { total, answered } = useMemo(() => {
    let total = 0;
    let answered = 0;
    buildsArr.forEach(pbuid => {
      const ladditionalQuestions = additionalQuestions?.filter(
        (item) => item?.insuredPerson?.bUID === pbuid && item?.additionalDatas?.length
      ) ?? [];
      total += ladditionalQuestions[0]?.additionalDatas?.length > -1
        ? ladditionalQuestions[0]?.additionalDatas?.length
        : 0;
      const currentDependentAnswers = InsuredQuestionAnswers?.[pbuid] ?? {};
      answered += Object.values(currentDependentAnswers).filter((answer) => answer).length;
    });
    return { total, answered };
  }, [additionalQuestions, InsuredQuestionAnswers, buildsArr]);

  // const questionsAnswered = additionalQuestions?.filter((question) => question?.answer)?.length ?? 0;

  const strengthMeterPercentage = computeStrengthMeterPercentage(answered, total);

  if (checkAnswer) {
    checkAnswer(strengthMeterPercentage, buildsArr, total, buidDisplayArr);
  }

  return (
    <div className="">

      {displayMode === 'Employee' && (
        <div className="row">
          <div className="col-12  px-xl-3 px-lg-3 pl-md-0  mt-2 pr-md-3 ">
            {sortedDependents.employee && (
              <AccordionItem
                insured={sortedDependents.employee?.[0]}
                additionalQuestions={additionalQuestions}
                handleAnswerChange={handleAnswerChange}
                InsuredQuestionAnswers={InsuredQuestionAnswers}
                currentBuid={currentBuid}
                displayMode={displayMode}
                disableAccordian={disableAccordian}
                coverageError={coverageError}
                defaultOpen="Yes"
              />
            )}
          </div>
        </div>
      )}

      {['Spouse', 'Domestic Partner', 'Civil Union Partner', 'Civil Union/Domestic Partner'].includes(displayMode) && (
        <div className="row">
          <div className="col-12  px-xl-3 px-lg-3 pl-md-0  pr-md-3 ">
            {sortedDependents.spouse && (
              <AccordionItem
                insured={sortedDependents.spouse?.[0]}
                additionalQuestions={additionalQuestions}
                handleAnswerChange={handleAnswerChange}
                InsuredQuestionAnswers={InsuredQuestionAnswers}
                currentBuid={currentBuid}
                displayMode={displayMode}
                dependent={sortedDependents.spouse}
                disableAccordian={disableAccordian}
                coverageError={coverageError}
                defaultOpen="Yes"
              />
            )}
          </div>
        </div>
      )}

      {displayMode === 'Child' && (
        <div className="row">
          <div className="col-12  px-xl-3 px-lg-3 pl-md-0  pr-md-3 ">
            {sortedDependents.children && (
              <>
                {
                  sortedDependents.children.map(pobj => (
                    <>
                      {
                        pobj?.personBuid === personBuid && (
                          <AccordionItem
                            insured={pobj}
                            additionalQuestions={additionalQuestions}
                            handleAnswerChange={handleAnswerChange}
                            InsuredQuestionAnswers={InsuredQuestionAnswers}
                            currentBuid={currentBuid}
                            displayMode={displayMode}
                            dependent={sortedDependents.children}
                            disableAccordian={disableAccordian}
                            coverageError={coverageError}
                            defaultOpen="Yes"
                          />
                        )
                      }
                    </>
                  ))
                }
              </>
            )}

          </div>
        </div>
      )}


      {displayMode === 'Children' && (
        <div className="row">
          <div className="col-12  px-xl-3 px-lg-3 pl-md-0  pr-md-3 ">
            {sortedDependents.children && (
              <>
                {
                  sortedDependents.children.map(pobj => (
                    <>
                      {
                        <AccordionItem
                          insured={pobj}
                          additionalQuestions={additionalQuestions}
                          handleAnswerChange={handleAnswerChange}
                          InsuredQuestionAnswers={InsuredQuestionAnswers}
                          currentBuid={currentBuid}
                          displayMode={displayMode}
                          dependent={sortedDependents.children}
                          disableAccordian={disableAccordian}
                          coverageError={coverageError}
                          defaultOpen="Yes"
                        />
                      }
                    </>
                  ))
                }
              </>
            )}

          </div>
        </div>
      )}



      {displayMode === 'All' && (
        <>
          <div className="row">
            <div className="col-12  px-xl-3 px-lg-3 pl-md-0  pr-md-3 ">
              {(
                <AccordionItem
                  insured={sortedDependents.employee?.[0]}
                  additionalQuestions={additionalQuestions}
                  handleAnswerChange={handleAnswerChange}
                  InsuredQuestionAnswers={InsuredQuestionAnswers}
                  currentBuid={currentBuid}
                  disableAccordian={disableAccordian}
                  coverageError={coverageError}
                  displayMode={displayMode !== "All" ? displayMode : 'Employee'}
                  defaultOpen="Yes"
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12  px-xl-3 px-lg-3 pl-md-0  pr-md-3 ">
              {sortedDependents?.spouse?.length > 0 && (
                <AccordionItem
                  insured={sortedDependents.spouse?.[0]}
                  additionalQuestions={additionalQuestions}
                  handleAnswerChange={handleAnswerChange}
                  InsuredQuestionAnswers={InsuredQuestionAnswers}
                  disableAccordian={disableAccordian}
                  coverageError={coverageError}
                  defaultOpen="Yes"
                //    displayMode={displayMode!=="All"?displayMode:sortedDependents.spouse.insured.mainOwnerRelationshipToInsured =='SP'?'Spouse':'Child'}
                />
              )}


            </div>
          </div>

          {sortedDependents.children && (
            sortedDependents.children.map((child) => (
              <div className="row" key={child.bUID}>
                <div className="col-12 px-xl-3 px-lg-3 pl-md-0 pr-md-3">
                  <AccordionItem
                    insured={child}
                    additionalQuestions={additionalQuestions}
                    handleAnswerChange={handleAnswerChange}
                    InsuredQuestionAnswers={InsuredQuestionAnswers}
                    displayMode="Child"
                    disableAccordian={disableAccordian}
                    coverageError={coverageError}
                    defaultOpen="Yes"
                  />
                </div>
              </div>
            ))
          )}

        </>
      )}
      {/* Remove child-related code */}
    </div>
  );
};

export default InsuredQuestions;


