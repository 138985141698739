import React, { useState, useRef } from 'react';
import './style.css';

function ToolTip({ children, tooltipLabel, instructions }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="tooltipContainer"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div className="tooltipContent">
          <ul className='tooltipLabel'>{tooltipLabel}
            {instructions.split('\n').map((line, index) => (
              <li key={index}><span>•  </span>{line}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ToolTip;