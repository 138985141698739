
import axios from 'axios';
import React from 'react';
import appConfig from '../../../../appConfig/index.js';
import http from '../../../../Common/Services/HttpService/index.js';
import { CHECK_ELIGIBILITY, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo/index.js';

export const CheckCoveredPersonsEligibilityForCoverage = async (validateContractSM, enrolmentId, CIBuid, currentStep="", pbuids = [], flow1buids=[], flowType, currentOfferedCoverageBuids ) => {
    const filteredSubscriptionManager = validateContractSM.filter(sub => sub?.product?.bUID === CIBuid);
            // {bUID: "318844252_7719", coverageBuid: "318850411_13516"}
        
            let subscriptionChoices = filteredSubscriptionManager[0]?.coveredInfos || [];
            let storebUIDs = [];
            let requestBuids = [];
            let newData = [];
            let lcoverageRecords = [];
            if(flowType === 1){
                flow1buids.forEach(pobj=>{
                    requestBuids.push({
                        bUID: pobj?.person?.identifier?.bUID,
                        coverageBuid: currentOfferedCoverageBuids[0]?.coverage.bUID
                    });
                })
            } else if(flowType === 3) {
                pbuids.forEach(pobj=>{
                    const filteredRecords = currentOfferedCoverageBuids.filter(pinrObj=>{ return pobj === pinrObj?.possibleInsuredPersons[0]?.insuredPerson?.bUID });
                    lcoverageRecords = lcoverageRecords.concat(filteredRecords);
                });
              
                for (const choice of lcoverageRecords) {
                    choice.possibleInsuredPersons.map((person, index) => {
                       
                        const insured = person.insuredPerson?.bUID;
                        const subscribedOption = choice?.coverage?.bUID;
                        const bUID = { "personBuid": insured?.bUID, "coverageEligiblity": false, "policyReplacementEligiblity" : false,
                            "beneficiaryEligiblity" :false 
                        };

                        requestBuids.push({
                            bUID: insured,
                            coverageBuid: subscribedOption
                        });
                        storebUIDs.push(bUID);
                    })  
                }
            }
            else if(flowType === 6) {   
                        const insured = currentOfferedCoverageBuids[0]?.possibleInsuredPersons?.[0]?.insuredPerson?.bUID;
                        const subscribedOption = currentOfferedCoverageBuids[0]?.coverage?.bUID;
                        const bUID = { "personBuid": insured?.bUID, "coverageEligiblity": false, "policyReplacementEligiblity" : false,
                            "beneficiaryEligiblity" :false 
                        };
                        requestBuids.push({
                            bUID: insured,
                            coverageBuid: subscribedOption
                        });             
                        storebUIDs.push(bUID);      
            }
            else {
               
                pbuids.forEach(pobj=>{
                    const filteredRecords = currentOfferedCoverageBuids.filter(pinrObj=>{ return pobj === pinrObj?.possibleInsuredPersons[0]?.insuredPerson?.bUID });
                    lcoverageRecords = lcoverageRecords.concat(filteredRecords);
                });
                for (const choice of lcoverageRecords) {
                    const insured = choice.possibleInsuredPersons[0]?.insuredPerson?.bUID;
                    const subscribedOption = choice?.coverage?.bUID;
                    const bUID = { "personBuid": insured?.bUID, "coverageEligiblity": false, "policyReplacementEligiblity" : false,
                        "beneficiaryEligiblity" :false 
                    }; 
                    requestBuids.push({
                        bUID: insured,
                        coverageBuid: subscribedOption
                    });
                    storebUIDs.push(bUID);
                }
            }
            
            const respArr = [];
            const promises = [];           
                return await http
                .post(appConfig.SSP_MID_URL + '/CheckCoveredPersonsEligibilityForCoverage', {
                    EnrollmentProcessIdentifier: enrolmentId,
                    CoveredPersons: requestBuids,
                    OfferedCoverage: requestBuids,
                    currentStepInfo: {
                        "portalStep": currentStep
                    }
                })
                .then((resp) => {
                    const CoverageDetails =
                        (resp &&
                            resp.data)
                    if (CoverageDetails) {  
                        newData = CoverageDetails.map((pobj)=>{
                            return {
                              "personBuid": pobj?.personBuid,
                              "coverageEligiblity": pobj?.isEligible,
                              //"coverageEligiblity": false,
                              "policyReplacementEligiblity" : false,
                              "beneficiaryEligiblity" :false,
                              "coverageBuid": pobj?.coverageBuid,
                              "eligibilityComment": pobj?.eligibilityComment
                            }
                        });  
                    } else {
                        // dispatch(SET_ALERT_MESSAGE({
                        //     type: 'error',
                        //     errorCode: resp?.status || '520',
                        //     id: 'checkCoveredPersonsElegibilityForCoverage',
                        //     show: false,
                        //   }));
                    }
                    return newData; 
                });
                

}