import React, { useState, useEffect } from 'react';
import NavItem from '../../Molecules/Navigation/NavItem';
import Dropdown from '../../Molecules/Navigation/Dropdown';
import SideDrawer from '../../Molecules/Navigation/SideDrawer';
import { Icon, Backdrop } from '../../Atoms';
import { useSelector } from 'react-redux';
// import './styles.css';
import { Styles } from './styles';
import { Linker } from '../../Atoms';

const SideNavBar = ({ openDrawer, setOpenDrawer, userName, contactUsContent }) => {
  const [content, setContent] = useState({});  useEffect(() => {
    import(`./Content/side.json`)
        .then((module) => {
          setContent(module.default);
        })
        .catch(error => console.error("Failed to load content:", error));
}, []);
  const [openDropdown, setOpenDropdown] = useState({
    navBarState: content.navItemState,
  });
useEffect(() => {
  let navBarLinks = content?.navBarLinks;
  if (navBarLinks && navBarLinks.length) {
    const contactUsNavBarIndex = navBarLinks.findIndex((ele) => ele.name === 'contactUsNavBar');
    if (contactUsNavBarIndex >= 0) {
      const contactUsNavBar = navBarLinks[contactUsNavBarIndex];
      if (
        contactUsNavBar?.dropdownMenu &&
        contactUsNavBar.dropdownMenu.length
      ) {
        let contactUsDropdown=contactUsNavBar.dropdownMenu
        const contactUsDropdownIndex = contactUsDropdown.findIndex(
          (ele) => ele.name === 'contactUsDropdown'
        );
        if (contactUsDropdownIndex >= 0) {
          contactUsDropdown[contactUsDropdownIndex]['dropdownLink'] =
            contactUsContent;
        }
      }
    }
  }
}, [contactUsContent]);

  useEffect(() => {
    setOpenDropdown({
      navBarState: content.navItemState,
    });
  }, [content]);
  // navBarState: [
  //   { name: 'GROUP INFORMATION', isExpanded: false },
  //   { name: 'BILLING & PAYMENTS', isExpanded: false },
  //   { name: 'EMPLOYEES', isExpanded: false },
  //   { name: 'QUOTES', isExpanded: false },
  //   { name: 'REPORTS', isExpanded: false },
  //   { name: 'FORMS', isExpanded: false },
  // ]

  const [navContent, setNavContent] = useState(null);

  let isExpanded;
  let menuList = null;
  let backdrop;
  let navBarContent;
  const setExpanded = (curNavItem) => {
    setNavContent(null);
    let tempNavBarState = [...openDropdown.navBarState];

    tempNavBarState.forEach((curNavBarState) => {
      let index = tempNavBarState.indexOf(curNavBarState);
      tempNavBarState[index] = { ...tempNavBarState[index] };
      if (tempNavBarState[index].name === curNavItem) {
        tempNavBarState[index].isExpanded = !tempNavBarState[index].isExpanded;
        if (tempNavBarState[index].isExpanded) {
          setNavContent(curNavItem);
        }
      } else {
        tempNavBarState[index].isExpanded = false;
      }
    });
    setOpenDropdown({ navBarState: tempNavBarState });
  };

  const resetDrawerState = () => {
    setOpenDrawer(false);
    setExpanded();
  };

  if (openDrawer) {
    let content = navContent ? navContent : 'Home';
    backdrop = <Backdrop click={resetDrawerState} />;
    navBarContent = (
      <div className="d-lg-none">
        <NavItem item={content}></NavItem>
      </div>
    );
  }
  const keyDate = new Date().getTime();
  return (
    <React.Fragment>
      <Styles />
      <div className="navbarContainer">
        <div className="d-lg-none">
          <SideDrawer
            userName={userName}
            open={openDrawer}
            onClose={resetDrawerState}
            className="leftToRight"
          >
            <div className="">
              <ul className="">
                {content?.navBarLinks?.map((navBarLink, index) => {
                  menuList = null;
                  isExpanded = undefined;
                  openDropdown?.navBarState?.forEach((curState) => {
                    if (navBarLink?.navLink === curState.name) {
                      menuList = 'true';
                      isExpanded = curState.isExpanded;
                    }
                  });
                  return (
                    <>
                      {menuList && (
                        <NavItem
                          {...navBarLink}
                          key={navBarLink.navLink}
                          item={navBarLink.navLink}
                          sideBarOpen={openDrawer}
                          expanded={isExpanded}
                          handleOnClick={setExpanded}
                        >
                          {navBarLink.dropdownMenu.length > 0 && (
                            <Dropdown
                              key={`${index} navBarLink.dropdownMenu[0].id`}
                              dropdown={navBarLink.dropdownMenu}
                              onClick={resetDrawerState}
                            />
                          )}
                        </NavItem>
                      )}
                      {menuList == null && (
                        <Linker
                          key={navBarLink.navLink}
                          className={'root-level-link'}
                          to={navBarLink.to}
                          onClick={resetDrawerState}
                        >
                          {navBarLink.navLink}
                        </Linker>
                      )}{' '}
                    </>
                  );
                })}
              </ul>
            </div>
          </SideDrawer>
          {backdrop}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SideNavBar;
