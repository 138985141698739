import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput } from '../../../Atoms';
import Button from '../../../Atoms/Buttons';
import StickyNote from '../../../Atoms/StickyNote';
import ResendOTPBlock from '../../../Atoms/ResendOTPBlock';
import { getOTPText, resendOTPApi, validateOTPApi } from './otpHelper';
import ChangeVerificationMethod from './ChangeVerificationMethod';
import { SET_LOGIN_OTP_METHOD } from '../../../../../../Store/Slices/Registeration';
import { errorMessages } from '../../../Atoms/FormValidation'
import Loader from '../../../Atoms/Loader';
import ResetPassword from '../../RetrievePassword/ResetPassword';
import { Link } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    MFASecurityCode: Yup.string()
        .matches(/^[0-9]+$/, 'No special characters allowed')
        .required('Security Code is required'),
});

const VerifyOTP = ({ onNext, title, onPrev, flow, otpMedia }) => {
    const dispatch = useDispatch();
    const { loader } = useSelector((state) => state.loader);
    const registeration = useSelector((storeState) => storeState.registeration);
    const [selectedMethod, setSelectedMethod] = useState(registeration.preferredChannel);
    const [showChangeMethod, setShowChangeMethod] = useState(false);
    const [err, setErr] = useState('');
    const [previousMethod, setPreviousMethod] = useState(registeration.preferredChannel);
    const [changeMFAError, setChangeMFAError] = useState('');
    const { legalEntityId = '', appCode = '' } = useSelector((storeState) => storeState?.registeration);
    const { userAuth } = useSelector((storeState) => storeState?.auth);
    const isHrCenter = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter") ? true : false
    const verificationText = getOTPText(flow, selectedMethod, registeration);

    const formik = useFormik({
        initialValues: { MFASecurityCode: '' },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            handleVerifyOTP(values);
        },
    });

    const onResendOTP = async () => {
        resendOTPApi(flow, { data: registeration, dispatch })
    }

    const handleVerifyOTP = async (values) => {

        const success = (resp) => {

            if (resp.status === 200) {
                onNext();
            } else {
                setErr(errorMessages(resp));
            }
        };

        const error = (err) => {
            setErr(errorMessages(err));
        };

        validateOTPApi(flow, { data: values, dispatch, successHandler: success, errorHandler: error });
    }


    const handleMethodChange = (method) => {
        setSelectedMethod(method);
        dispatch(SET_LOGIN_OTP_METHOD(method));
    };

    const clearErrMsg = () => {
        setErr('');
        setChangeMFAError('');
    }

    const handleChangeMFA = () => {
        clearErrMsg()
        setPreviousMethod(selectedMethod);
        setShowChangeMethod(true);
    }


    const handleMFAError = (errorMsg) => {
        setChangeMFAError(errorMsg);
    };

    if (showChangeMethod) {
        return (
            <ChangeVerificationMethod
                handleMFABack={() => setShowChangeMethod(false)}
                onMethodChange={handleMethodChange}
                selectedMethod={selectedMethod}
                onError={handleMFAError}
                otpMedia={otpMedia}
                flow={flow}
                previousMethod={previousMethod}
            />
        );
    }

    return (
        <>
            {loader && <Loader loading={loader} />}
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="row">
                    <div className="col-12">
                        <h1 className="form-side-header">{title || 'Reset Password'}</h1>
                        <p className="form-mandatory mb-0">Fields marked with * are mandatory</p>
                        <p>{verificationText}</p>
                    </div>
                </div>

                <div className="row py-2">
                    <div className="col-sm-12 col-md-6">
                        <TextInput
                            label="Security Code"
                            required={true}
                            placeholder="Enter Security Code"
                            max="9999-12-31"
                            className="w-100 inputField p-3"
                            onInput={clearErrMsg}
                            {...formik.getFieldProps('MFASecurityCode')}
                        />
                        {formik.touched.MFASecurityCode && formik.errors.MFASecurityCode && (
                            <div className="form-mandatory">{formik.errors.MFASecurityCode}</div>
                        )}
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <StickyNote
                            title={'Important Note'}
                            children={'The security code will expire in 5 minutes after you receive it.'}
                        />
                    </div>
                </div>
                {(err) &&
                    <div className="row">
                        <span className='APIErr'>{err}</span>
                    </div>
                }

                <ResendOTPBlock duration={45} onResend={onResendOTP} />

                <div className="row">
                    <div className="col-12 col-md-12 pt-1">
                        <div>Unable to receive code?</div>
                    </div>
                </div>
                {flow !== 'changeMFAContactEmail' && (
                    <div className="row">
                        <div className="col-12 col-md-12 px-1">
                            <Button
                                type="button"
                                className="btn btn-link"
                                currentstep="MFAForgotPassword"
                                onClick={handleChangeMFA}
                            >
                                Try another method
                            </Button>
                        </div>
                    </div>
                )}
                {changeMFAError &&
                    <div className="row">
                        <span className='APIErr'>{changeMFAError}</span>
                    </div>
                }
                <div className="row py-4 justify-content-between">
                    <div className="col-sm-12 col-md-4 py-2">
                        {isHrCenter ? <Link role="link" to={`/hrcenter/login?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Back to login </Link> :
                            <Link role="link" to={`/enrollment?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Back to login </Link>}
                    </div>
                    <div className="col-sm-12 col-md-6 py-2 mr-4 text-md-right">
                        <Button
                            type="button"
                            className="btn btn-outline-primary primaryOutline btn-outline-primary-contrast mr-4"
                            onClick={onPrev}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            className="btn btn-primary primaryBackground btn-primary-contrast"
                            disabled={(!formik.isValid || !formik.dirty)}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default VerifyOTP;
