import moment from 'moment';

export let DateValidation = (
  Yup,
  indYupper,
  indValidation,
  validationType,
  indElement,
  tempValidations
) => {
  let date = moment().add(
    tempValidations[indElement][validationType][indValidation]['limit'],
    tempValidations[indElement][validationType][indValidation]['unit']
  );
  switch (indValidation) {
    case 'required':
      indYupper = indYupper[indValidation](
        tempValidations[indElement][validationType][indValidation]
      );
      break;
    case 'min':
      indYupper = indYupper[indValidation](
        date,
        tempValidations[indElement][validationType][indValidation]['message']
      );
      break;
    case 'max':
      indYupper = indYupper[indValidation](
        date,
        tempValidations[indElement][validationType][indValidation]['message']
      );

      break;
    default:
    // code block
  }

  return indYupper;
};

export default DateValidation;
