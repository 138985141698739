import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { DISCLOUSRE_RADIO } from "../../../../../Store/Slices/InsuredInfo"
import Elementor from '../../../../Cells/Form/Elementor';
import Content from '../../Content/GenericContent.json'

import { Button } from '../../Atoms/index';
import { StickyNote } from "../../../../Atoms";
import { UPDATE_CART_ITEM_NEW, UPDATE_CART_ITEM_NEW_BUYUP } from '../../../../../Store/Slices/EmployeeProfile';
import { updateStepperNavigatorInfo } from "../../../../Utils/Form/StepperHelper";
import { continueBtnLabelHelper, screenNames } from "../../Helper/continueBtnLabelHelper";
import { useNavigationHook } from '../../Hooks/NavigationHook'; 
import
{
Section
} from '../../../../Atoms';
import { getErrorMessages } from "../../Helper/MappingHelper";
import { COVERAGE_CANCELLED_BEFORE_CONFIRMATION_BUIDS } from "../../../../../Store/Slices/Enrollment";
const Summary = (props) => {

  const { currentStep, previousStep, formsConfig} = useNavigationHook();


  
  //This logic has to be fixed for other flows
  let currentReduxState = useSelector((storeState) => storeState);
  const buid = props.employee[0]?.personBuid;
  const insuredInfoCurrentStep = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);
  const planCost = useSelector((storeState) => storeState.buyUp.costBasket);
  let getDetails = insuredInfoCurrentStep.subscribeCoverageBuids?.filter((item) => item?.personBuid === buid);
  const subscribedProducts = enrollmentData?.contract?.subscribedElements || [];
  const currentProductIndex = subscribedProducts?.findIndex((item) => item?.coverages[0]?.bUID === getDetails?.[0]?.coverageBuid) || 0;
  let productFamily = formsConfig.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
  const [buttonLabel, setButtonLabel] = useState('')
  const productTitle = useSelector(
    (storeState) => {
      let x =
        storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result?.filter(
          (data) => data.code == 'PRODTITLE'
        );
      if (x == undefined) {
        return '';
      } else {
        return x[0]?.content;
      }
    }
  );
  const { insuredInfo, entities } = useSelector((storeState) => storeState);
  const subscribeCoverageBuids = entities?.response?.onLoadResponseList?.[currentStep]?.subscribeCoverageBuids || [];

  let coverageDetailsData = insuredInfo[currentStep]?.petList || insuredInfo[currentStep]?.coverageDetails;
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const { step, nextStep, prevStep, onNext, validateSubscribedProductData, RadioSelctedValue, declineProduct, showDisclosureStatement } = props;
  const label = "Do you agree to the terms and conditions of the disclosure statement?";
  const options = [
    { label: "I agree", value: "Yes", name: "termsAndConditions" },
    { label: "I disagree", value: "No", name: "termsAndConditions" }
  ];


  // const currentStepIndex = stepKeys.findIndex(step => step === currentStep);
  // const nextStepGoingTobe = stepKeys[currentStepIndex + 1];
  // const buttonText = nextStepGoingTobe === 'EnrollmentSummary' ? 'Continue to Summary' : 'Continue to Next Product';

  const [waiveSelected, setWaiveSelected] = useState(false);
  const continueBtnObj = {
    flowType: props.flowType,
    screenType: screenNames.DISCLOSURE_STATEMENT,
    totalProductCount: subscribedProducts.length,
    productIdentifierBuid,
    previousStep: previousStep,
    hasDisclosureStatement: props.showDisclosureStatement,
    isWaivedOffFlag: false
  }

  useEffect(() => {
    if (insuredInfo?.[currentStep]?.disClosureRadio === 'No') {
      dispatch(
        UPDATE_CART_ITEM_NEW({
          id: currentStep,
          moreThanOne:true,
          declineProduct: true
        })
      );
    }
    setButtonLabel(continueBtnLabelHelper({
      ...continueBtnObj
    }));
  }, []);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    dispatch(DISCLOUSRE_RADIO({ disClosureRadio: event.target.value, currentStep }))
    if (event.target.value === 'No') {
      if(props.flowType === 2 && props.isBuyUpFlow) {
        dispatch(UPDATE_CART_ITEM_NEW_BUYUP({
          removeProduct: true,
          id:currentStep,
          cost: 0,
          name:productFamily
        }))
      } else {
        dispatch(
          UPDATE_CART_ITEM_NEW({
            id: currentStep,
            moreThanOne:true,
            declineProduct: true
          })
        );
      }
    }else{
      //planCost
      if(props.flowType === 2 && props.isBuyUpFlow) {
        dispatch(UPDATE_CART_ITEM_NEW_BUYUP({
          removeProduct: false,
          id:currentStep,
          cost: (planCost?.employee + planCost?.spouse) || 0,
          name:productFamily
        }))
      } else {
        dispatch(
          UPDATE_CART_ITEM_NEW({
            id: currentStep,
            moreThanOne: true,
            name: productTitle,
            coverageList: coverageDetailsData,
          })
        );
      }
    }
  };



  const handleDisclosure = async () => {
    if (RadioSelctedValue === 'Yes') {
      switch (currentStep) {
        case 'PetAssureNation':
          updateStepperNavigatorInfo('PetAssureNation', dispatch, { petList: coverageDetailsData })
          break;
        default:
          updateStepperNavigatorInfo('ProductDetailsSelection', dispatch, { insuredInfo, stepKey: currentStep, subscribeCoverageBuids })
          break;
      }
      if(props.flowType === 1) {
        await dispatch(COVERAGE_CANCELLED_BEFORE_CONFIRMATION_BUIDS({
          process: 'remove',
          buid: productIdentifierBuid
        }))
      }
      await validateSubscribedProductData();
    } else if (RadioSelctedValue) {
      await declineProduct();
      if(props.flowType === 1) {
        await dispatch(COVERAGE_CANCELLED_BEFORE_CONFIRMATION_BUIDS({
          process: 'add',
          buid: productIdentifierBuid
        }))
      }
    }
    await onNext();
  };

  let element = Content.disclosureContent;

  const hasErrors = getErrorMessages(currentStep);


  return (

    <>

      {/*  {<Elementor
           element={disClouserSummary}
          
           {...parentProps}

         />} } */}
      <div className="py-5">
      

            <Section
            key={`productsummary-${currentStep}`}
            id={`productsummary-${currentStep}`}
            type={element.type}
            label={element.label}
            style={element.style}
            element={element}
            name={`productsummary-${currentStep}`}
            divScroll={element.divScroll}
            link={element.link}
            linkText={element.linkText}
            disabled={ element.disabled}
            scroll={element.scroll}
            rows={element.rows}
            currentStep={currentStep}
            value={element.value ? element.value : "" }
            hidden={element.hidden}
          ></Section>
        
      </div>
      <div className="radioGroup w-100">
        <div className="row">
          <div className="px-4 px-xl-3 pb-2 py-3">
            {label}
          </div>
        </div>
        <div className="pl-2 px-xl-0 mr-0 custom-control-inline" style={{ fontSize: '0.875rem', color: 'rgb(0, 0, 0)', fontFamily: 'NotoSansRegular' }}>
          {options.map(option => (
            <label key={option.value} className={option.className} style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem', marginLeft: '.3rem', marginRight: '1rem' }}>
              <input type="radio" name={option.name} value={option.value} checked={RadioSelctedValue === option.value} onChange={handleRadioChange} />
              <span style={{ marginLeft: '.7rem' }}>{option.label}</span>
            </label>
          ))}
        </div>

      </div>
      {value === 'No' ? (<StickyNote
        value={"Based on your response, you are not eligible to select this coverage at this time"}
        hidden={false}
        image={'icon-warning.png'}
        singleValue="true"
        notArray="true"
        color="#FFFAEA"
        borderColor="#FFD55A"
        marginTop="0"
      />) : null}

      <div className='button-group'>

        <>
          <Button type="button" onClick={prevStep} className="mr-3 btn btn-outline-primary primaryOutline">
            Back
          </Button>
        </>


        < Button type="button" className="btn btn-primary continue-btn primaryBackground summaryCls" onClick={handleDisclosure}
         disabled={RadioSelctedValue === undefined || RadioSelctedValue === "" || hasErrors}
        >
          {buttonLabel}
        </Button>

      </div>


    </>

  );


};

export default Summary;
