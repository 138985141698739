import { useState } from "react";
import "./customtable.css";
import { buyUpPageConstants } from "../../../Common/Constants/pageConstants";
import { TablePresentation } from "./tablePresentation";

export const CustomTableBuyUp = (props) => {
    const { 
        basePlanSelectedValue,
        buyUpPlanSelectedValue,
        existingPlanData, 
        buyUpPlanData, 
        customContent, 
        basePlanSelectedValueCallback, 
        buyUpSelectedValueCallback, 
        userActionTakenCallBack,
        buyUpPreselectedValue = null } = props;
    const [userActionTaken, setUserActionTaken] = useState(false);
    const [parentSelectedVal, setParentSelectedVal] = useState(0);
    const [parentSelectionDisabled, setParentSelectionDisabled] = useState(true);

    const dependentCoverageTable = customContent.tables.find(
        (table) => table.tableId === "dependentCoverageTable"
    );
    
    const waiveParentPlan = (value) => {
        setParentSelectedVal(-1)
        basePlanSelectedValueCallback(-1)
        setParentSelectionDisabled(false)
        setUserActionTaken(true)
        userActionTakenCallBack(true)
    }

    const waiveBuyUpPlan = (value) => {
        setUserActionTaken(true)
        userActionTakenCallBack(true)
        buyUpSelectedValueCallback(-1)
    }

    const parentSelectedValue = (value) => {
        setParentSelectedVal(value)
        basePlanSelectedValueCallback(value)
        setUserActionTaken(true)
        userActionTakenCallBack(true)
    }

    const buyUpSelectedValue = (value) => {
        setUserActionTaken(true)
        userActionTakenCallBack(true)
        buyUpSelectedValueCallback(value)
    }

    const { tableHeader, tableRow } = dependentCoverageTable.multiConfig;

    return (
        <>
            <TablePresentation id="plan-table" planData={existingPlanData} tableHeader={tableHeader} tableRow={tableRow} setWaiveSelected={waiveParentPlan} selectionDisabled={parentSelectionDisabled} val={basePlanSelectedValue} selectedVal={parentSelectedValue} preselectedVal={parentSelectedVal}/>
            {parentSelectedVal >= 0 ? 
                <>
                    <div className='mb-4'>
                        {buyUpPlanData && buyUpPlanData.length ? buyUpPageConstants.labelForBuyUpTable : buyUpPageConstants.labelForNoPlans}
                    </div>
                    <TablePresentation id="buyup-table" planData={buyUpPlanData} tableHeader={tableHeader} tableRow={tableRow} setWaiveSelected={waiveBuyUpPlan} selectionDisabled={false} val={buyUpPlanSelectedValue} selectedVal={buyUpSelectedValue} waiveCoverageText={"Waive Buy Up"} preselectedVal={buyUpPreselectedValue}/>
                </> 
                : null
            }
        </>
    )
}