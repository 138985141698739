import { useMemo } from 'react';
import { useNavigationHook } from '../../Hooks/NavigationHook';
import Step from './Step';

import './style.css';

const SideNavigation = () => {
    const {
        formsConfig,
        stepKeys,
        currentStep,
        stepContent,
        handleSetCurrentStep,
    } = useNavigationHook();

    const steps = useMemo(() => {
        const currentIndex = stepKeys.indexOf(currentStep);
        return stepKeys.reduce((res, key, index) => {
            const step = formsConfig?.forms?.[key];
            if (step) {
                const status = index < currentIndex ? 'completed' : index > currentIndex ? 'draft' : 'in-progress'
                if (!step?.productFamily) {
                    res.push({
                        status,
                        stepId: key,
                        key: res.length + 1,
                        title: step.displayName,
                        subContent: stepContent[key] || []
                    });
                } else if (step?.group) {
                    const parent = res.find(item => item.stepId === step?.group)
                    if (parent) {
                        if (!parent.subSteps) parent.subSteps = [];
                        parent.subSteps.push({
                            key,
                            status,
                            stepId: key,
                            title: step.displayName,
                        })
                    }
                    if (parent.status === 'completed') parent.status = status;
                }
            }
            return res;
        }, []);
    }, [stepKeys, currentStep, formsConfig?.forms, stepContent]);

    const onStepClick = (step) => {
        if (step.status === 'completed' && !step.subSteps?.length) {
            handleSetCurrentStep(step.stepId);
        }
    }

    return (
        <div className='side-navigation'>
            <ul>
                {steps.map((step) => <Step key={step.key} step={step} onStepClick={onStepClick} />)}
            </ul>
        </div>
    )
}

export default SideNavigation;
