import React,{useEffect,useState} from 'react';
// import "./styles.css";
import { useSelector } from 'react-redux';
import SectionHeader from '../../../Molecules/SectionHeader';
import { Styles } from './styles';

const CookieNotice = () => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, setContent] = useState({});
  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => setContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);

  let titleClassName = content?.staticPages?.titleClassName;
  let cookieTitleClassName = content?.staticPages?.cookieTitleClassName;
  let contentClassName = content?.staticPages?.contentClassName;
  let wrapperClassName = content?.staticPages?.wrapperClassName;
  let subTitleClassName = content?.staticPages?.subTitleClassName;
  let supportedClassName = content?.staticPages?.supportedClassName;
  let enClassName = content?.staticPages?.enClassName;

  let handleClose = () => {
    window.close();
  };
 
  return (
    <React.Fragment>
      <Styles />
        <div className='container-fluid  '>
          <div className='row '>
            <div className='col-12 ' >
              <div className='row  '>
                <div className='col-11 px-0 '>
                  <SectionHeader title="Cookie Notice"  current="Cookie Notice" />
                </div>
                <div className='  col-1 btnClass border-bottom ' >
                  <button type="button" className="close pr-lg-2 "  aria-label="Close" onClick={()=>handleClose()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          <div className={`col-12 px-3 px-md-5  px-lg-5 ${supportedClassName}`}>
          </div>
          <div className={` col-12 px-3 px-md-5  px-lg-5 ${wrapperClassName} `}>
            <div className='row ' >
              {content && content?.staticPages?.cookieNotice?.en?.map((element) => {
                return (
                  <>
                    <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.content &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1'>
                            {element.content} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.secondContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1'>
                            {element.secondContent} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.secTitle &&
                        <div className={`row ${cookieTitleClassName} `}>
                          <div className='col-12 py-1'>
                            {element.secTitle} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.thirdContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1'>
                            {element.thirdContent} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.fourthContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1'>
                            {element.fourthContent} 
                          </div>
                        </div>
                      }
                      </div>
                      {element?.subContent ? element.subContent.map((sContent) => {
                      return (
                        <div className='row content pl-3 ' key={sContent.id}>
                          <div className='col-auto  px-0 mx-0 pl-3 '>{'\u2022'}{' '}</div>
                          <div className='col-11 px-0 mx-0 content pl-2'>{sContent.content}</div>
                        </div>
                      )
                    }) 
                    : ''}
                    <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.thirdTitle &&
                        <div className={`row ${cookieTitleClassName} `}>
                          <div className='col-12 py-1'>
                            {element.thirdTitle} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.fifthContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1'>
                            {element.fifthContent} 
                          </div>
                        </div>
                      }
                       <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.firstSubTitle &&
                        <div className={`row ${subTitleClassName} pl-0 `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.firstSubTitle} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} pl-0 `}>
                      {element?.firstSubContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1'>
                            {element.firstSubContent} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.secondSubTitle &&
                        <div className={`row ${subTitleClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.secondSubTitle} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.secondSubContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.secondSubContent} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.thirdSubTitle &&
                        <div className={`row ${subTitleClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.thirdSubTitle} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.thirdSubContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.thirdSubContent} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.fourthSubTitle &&
                        <div className={`row ${subTitleClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.fourthSubTitle} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.fourthSubContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.fourthSubContent} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.fifthSubTitle &&
                        <div className={`row ${subTitleClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.fifthSubTitle} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.fifthSubContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.fifthSubContent} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.fourthTitle &&
                        <div className={`row ${cookieTitleClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.fourthTitle} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.sixthContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.sixthContent} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.fifthTitle &&
                        <div className={`row ${cookieTitleClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.fifthTitle} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.seventhContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.seventhContent} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.sixthTitle &&
                        <div className={`row ${cookieTitleClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.sixthTitle} 
                          </div>
                        </div>
                      }
                      </div>
                      <div  key={element.id} className={`col-12 ${enClassName} `}>
                      {element?.eigthContent &&
                        <div className={`row ${contentClassName} `}>
                          <div className='col-12 py-1 pl-0'>
                            {element.eigthContent} 
                          </div>
                        </div>
                      }
                      </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          
      </div>
      </div>
    </React.Fragment>
  );
};

export default CookieNotice;