import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const TextContainer = (props) => {
    const {
        config = {},
        descriptionContainerStyle = {},
        descriptionSubtitle = '',
        descriptionSubtitleClassName = '',
        descriptionTitle = '',
        descriptionTitleClassName = '',
        hasList = false,
        id = '',
        listClassName = '',
        listElements = [],
        name = '',
        showContent = [],
        title = '',
        titleClassName = '',
        titleStyle = {},
        listStyle = {},
    } = props;
    return showContent.includes(id) || showContent.length === 0 ? (
        <div id={`textContainer-${name}`} style={(config && config.style) || {}}>
            <p className={titleClassName} style={titleStyle}>{title}</p>
            <div style={descriptionContainerStyle}>
            <p className={descriptionTitleClassName}>{descriptionTitle}</p>
            <p className={descriptionSubtitleClassName}>{descriptionSubtitle}</p>
            {hasList && (
                <ul className={listClassName} style={listStyle}>
                { listElements.map((list, key) => {
                    return showContent.includes(list.id) || showContent.length === 0 ? (
                        <li key={`${list.id}-${key}`} className={list.className} style={list.style}>
                            {list.text}
                        </li>
                    ) : null  
                })}
                </ul>
            )}
            </div>
        </div>
    ) : null;

};

TextContainer.propTypes = {
    config: PropTypes.object,
    descriptionContainerStyle: PropTypes.object,
    descriptionSubtitle: PropTypes.string,
    descriptionSubtitleClassName: PropTypes.string,
    descriptionTitle: PropTypes.string,
    descriptionTitleClassName: PropTypes.string,
    hasList: PropTypes.bool,
    id: PropTypes.string,
    listClassName: PropTypes.string,
    listElements: PropTypes.array,
    name: PropTypes.string,
    showContent: PropTypes.array,
    title: PropTypes.string,
    titleClassName: PropTypes.string,
    titleStyle: PropTypes.object,
};

TextContainer.defaultProps = {
    config: {},
    descriptionContainerStyle: {},
    descriptionSubtitle: '',
    descriptionSubtitleClassName: '',
    descriptionTitle: '',
    descriptionTitleClassName: '',
    hasList: false,
    id: '',
    listClassName: '',
    listElements: [],
    name: '',
    showContent: [],
    title: '',
    titleClassName: '',
    titleStyle: {},
};

export default TextContainer;
