import React, { Suspense } from 'react';
import BaseLayout from '../../../Molecules/CustomSection/SingleFormLayout/index';
import './styles.css';

const HomePage = (props) => {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        {/*  <MessageComponent /> */}
        <div className="formWrapper">
          <BaseLayout formLayoutType={'SearchClient'} formName={'SearchForClient'}/>
        </div>
      </Suspense>
    </div>
  );
};

export default HomePage;