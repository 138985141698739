import React, { Suspense, useEffect, useState } from 'react';
import appConfig from '../../../../appConfig';
import http from '../../../../Common/Services/HttpService';

import FormGenerator from '../../../Cells/Form/FormGenerator';
//import { useSelector } from 'react-redux';
import SectionHeader from '../../../Molecules/SectionHeader';
import { useDispatch, useSelector } from 'react-redux';
import { callConstants } from '../../../../Store/Slices/Entities/constants/index';
import { constantsList } from '../../../../Common/Constants/index';
import { apiConstants } from '../../../../Common/Constants';
import {
  APIRequestParallel,
  APIRequestSequential,
  APIRequestSequential2,
  onLoadAPI,
} from '../../../../Store/Slices/Entities/response/index';
import { reduxConstants } from '../../../../Common/Constants/index';
import Loader from '../../../Atoms/Loader';

const ConfigClient = (props) => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, SetContent] = useState({});
  const [layoutData, setLayoutData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  // const [additionalInformation, SetAdditionalInformation] = useState({});

  const dispatch = useDispatch();

  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};

  let additionalInformation =
    useSelector((store) => eval(reduxConstants.GetAdditionalValues.apiPath)) || {};

  const reduxValues = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList
  );

  const dataFromReduxEmploymentInformation = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList
  );


  /** commented by Nisreen - 09-16, breaks code  */
  // const buid = useSelector(
  //   (storeState) =>storeState?.entities?.response?.onLoadResponseList?.employments._Result[1] &&
  //     storeState?.entities?.response?.onLoadResponseList?.employments?._Result[1]?.bUID
  // );

  const loginuserName = useSelector((storeState) => storeState?.auth?.userAuth?.data?.user_name);

  const dataFromRedux = useSelector((storeState) => storeState?.auth?.loggedUserEntity?.data);

  let clientId = dataFromRedux?._Result?.clientId?.internalClientId;

  //  const dataFromRedux = useSelector((storeState) => storeState?.auth?.loggedUserEntity?.data);

  // const dataFromRedux = useSelector(
  //   (storeState) => storeState?.entities?.response?.onLoadResponseList
  // );
  // useEffect(() => {
  //   setLayoutData({ dataFromRedux: dataFromRedux });
  // }, [dataFromRedux]);

  useEffect(() => {
    setLayoutData({ ...dataFromRedux, employeeInformation: dataFromReduxEmploymentInformation });
  }, [dataFromRedux, dataFromReduxEmploymentInformation]);

  const userName = { loginName: loginuserName };

  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => SetContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);

  // useEffect(() => {
  //   /* useEffect to get all constants through API call */
  //   constantsList.forEach((element) => {
  //     dispatch(callConstants(element));
  //   });
  // }, []);

  useEffect(() => {
    const callAPI = async () => {
      // setShowLoader(true);
      if (content && content?.layout && content?.layout?.onLoadApiCall) {
        let onLoadCalls = content.layout.onLoadApiCall;

        let data = await dispatch(
          APIRequestParallel(onLoadCalls, {
            additionalInformation: {
              clientId
            },
          })
        );
      }
    };
    try {
      callAPI();
      // setLayoutData({ data });
    } catch (error) {
      
    }

    // additionalInformation = useSelector(
    //   (storeState) => storeState?.entities?.response?.onLoadResponseList?.employments
    // );
    // setShowLoader(false);
  }, [content, loggedUserEntity]);

  // useEffect(() => {
  //   /* use effect to get Company Profile layout content dependent on language */
  //   let contentInfo = require(`./Content/${currentLanguage}.json`);
  //   if (contentInfo) SetContent(contentInfo);
  // }, [currentLanguage, props.layout]);
  //  let [result, setResult] = useState({});
  //  useEffect(() => {
  //    /* use effect to get forms which contains element properties */
  //    setResult(props.result);
  //  }, [props.result]);

  // useEffect(() => {
  //   const callAPI = async () => {
  //     // setShowLoader(true);
  //     if (content && content?.layout && content?.layout?.onLoadApiCall) {
  //       let onLoadCalls = content.layout.onLoadApiCall;

  //       let data = await dispatch(
  //         APIRequestSequential2(onLoadCalls, { loggedUserEntity, userName })
  //       );

  //       if (data) {
  //         let directObject = {};
  //         onLoadCalls.forEach((apiCalls) => {
  //           let { objectName } = apiCalls.apiconfig || {};
  //           let curObj = { [objectName]: data[objectName] };
  //           directObject = { ...directObject, ...curObj };
  //           //  props.setResult(directObject);
  //         });
  //         let dataEmployments = data?.employments?._Result || [];
  //         if (dataEmployments.length > 0) {
  //           let buidtemp = dataEmployments[dataEmployments.length - 1]?.bUID;
  //           const resp = await http.post(appConfig.SSP_MID_URL + '/getEmploymentInformation', {
  //             additionalInformation: { buid: buidtemp },
  //           });

  //           // const axios = require('axios');
  //           // const resp = await axios({
  //           //   method: 'post',
  //           //   headers: apiConstants.HEADERS,
  //           //   url: appConfig.SSP_MID_URL + '/getEmploymentInformation',
  //           //   data: { additionalInformation: { buid: buidtemp } },
  //           // });
  //           let tempresponse = { employeeInformation: resp?.data };

  //           let apiResponse = await dispatch(onLoadAPI({ ...tempresponse }));
  //           if (apiResponse) {
  //             setLayoutData({
  //               ...data,
  //               employeeInformation: apiResponse?.payload?.employeeInformation,
  //             });
  //           }
  //         }
  //       }
  //     }
  //   };
  //   try {
  //     callAPI();
  //     setLayoutData({ dataFromRedux, dataFromReduxEmploymentInformation });
  //   } catch (error) {
  //     
  //   }

  //   
  //   // additionalInformation = useSelector(
  //   //   (storeState) => storeState?.entities?.response?.onLoadResponseList?.employments
  //   // );
  //   // setShowLoader(false);
  // }, [content, loggedUserEntity]);

  // setLayoutData({ ...dataFromRedux, dataFromReduxEmploymentInformation });

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  const breadcrumbs = [
    { to: '', text: 'Employee' },
    { to: '/searchForAnEmployee', text: 'Search for an Employee' },
  ];
  /*removed breadcrumbs and current props */

  return (
    <div>
      {/* <SectionHeader title="Employee Profile" style={content.style} /> */}
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper">
          <FormGenerator content={content} {...props} layoutData={layoutData} />
        </div>
      </Suspense>
    </div>
  );
};

export default ConfigClient;
