import { createSlice } from '@reduxjs/toolkit';
import appConfig from '../../../appConfig';

const slice = createSlice({
  name: 'language',
  initialState: {
    availableLanguages: appConfig.language.availableLanguages,
    currentLanguage: appConfig.language.currentLanguage,
  },
  reducers: {
    // action => action handler
    ADD_LANGUAGE: (language, action) => {
      return {
        ...language,
        availableLanguages: [...language.availableLanguages, action.payload],
      };
    },
    CHANGE_LANGUAGE: (language, action) => {
      return { ...language, currentLanguage: action.payload };
    },
  },
});

export const { ADD_LANGUAGE, CHANGE_LANGUAGE } = slice.actions;

export default slice.reducer;
