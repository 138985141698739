import React from 'react';
import Elementor from '../../Form/Elementor/index';

// import default minimal styling or your own styling
const MultipleCard = (props) => {
const { element} = props || {};


   
  return (
    <div>
              {props.element &&
                props.result &&
                props.result[element.name] && props.result[element.name].length > 0 &&
                Array.isArray(props.result[element.name]) && 
                props.result[element.name].map((currentResult ,index) => {
                  return (
                    <Elementor
                      key={`${index} element`}
                      element={element}
                      constantsList={{}}
                      image={element.image}
                      result={currentResult}
                      contentClassName=""
                      isMultiple = {true}                      
                      mulitpleIndex = {index}
                      {...props}
                    />
                  );
                })}
                
          
    </div>
  );
};
MultipleCard.propTypes = {
 
};

export default MultipleCard;
