import React, { useEffect, useState } from 'react';

import { Button } from '../../../../Atoms';
import { useDispatch, useSelector } from 'react-redux';
import {  UPDATE_LOADING } from '../../../../../Store/Slices/InsuredInfo';
import { useNavigationHook } from '../../Hooks/NavigationHook';
import {  unsubscribeCoverageDetails, updateSubscribedCoverageInformation } from '../../Actions/productDetails';
import { getConfigHelper, getErrorMessages } from '../../../../Molecules/CustomSection/Helper/MappingHelper';
import { continueBtnLabelHelper, screenNames } from '../../Helper/continueBtnLabelHelper';
const CoverageFlowFive = (props) => {
  const {
    personBuid,
    customContent,
    coverageBuid,
    tableData,
    onCoverageChange,
    step,
    stepsCount,
    showDisclosureStatement,
    onNext,
    nextStep,
    onBack
  } = props;

  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);
 
  

  const dispatch = useDispatch();
  const { currentStep, formsConfig } = useNavigationHook();
 
  const coverageTable =
    customContent?.tables?.find((table) => table.tableId === 'dependentCoverageTable') || {};
    
  const { tableHeader, tableRow } = coverageTable.multiConfig || {};

  const { auth, process,  insuredInfo, entities } = useSelector((state) => state);
  const callCenterEmployeeData = useSelector(
    (storeState) => storeState?.callCenter?.callCenterEnrollAsEmployeeLoginData
  );


  const productFamily = formsConfig?.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = process?.productFamilyIdentifier[productFamily];
  const enrollmentProcessBuid = process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;

  const loggedUserEntity = Object.keys(callCenterEmployeeData).length === 0 ? auth.loggedUserEntity : callCenterEmployeeData;
  const subscribeCoverageBuids = entities?.response?.onLoadResponseList?.[currentStep]?.subscribeCoverageBuids || [];

  const getDetails = subscribeCoverageBuids.filter(
    (e) => e?.personBuid === personBuid
  );
  const subscribedCoverageBuid = getDetails?.[0]?.coverageBuid;
  const subscribedElements = getDetails?.[0]?.perpareSubscriptionForChoices?._Result || [];

  const coverageDetails = insuredInfo[currentStep]?.coverageDetails;
  const selectedValue = coverageDetails?.[0]?.selectedValue;
  const waivedCoverage = coverageDetails?.[0]?.waivedCoverage;
  const [waiveCoverage, setWaiveCoverage] = useState(waivedCoverage ? waivedCoverage : '');
  const [selectedCoverage, setSelectedCoverage] = useState(selectedValue ? selectedValue : '');
  const [continueBtnLabel, setContinueBtnLabel] = useState('Continue')
  const [moveNextEnable, setMoveNextEnable] = useState(false)
  const beneficiaryList = [];
  let allowedBeneficiaryKind = '';

  useEffect(() => {
    setSelectedCoverage(selectedValue);
  }, [currentStep, selectedValue]);
  useEffect(() => {
    if(moveNextEnable){
      onNext(enrollmentData?.processEntity?.currentStepNb);
      setMoveNextEnable(false);
    }
  }, [enrollmentData?.processEntity?.currentStepNb, moveNextEnable]);

  let getSubscribedCoverageBeneficiary = getDetails?.[0]?.beneficiaryConfiguration || '';
  let allowedBeneficiaryDetails =
    getSubscribedCoverageBeneficiary?._Result?.allowedBeneficiaryKind || [];
  if (allowedBeneficiaryDetails?.length > 0) {
    const index = beneficiaryList.length > 0 ? 1 : 0;
    allowedBeneficiaryKind = allowedBeneficiaryDetails?.[index];
  }

  let possibleValues = [];
  const coverageConfiguration = getDetails?.[0]?.beneficiaryConfiguration || '';
 
  if (coverageConfiguration) {
    possibleValues = coverageConfiguration?._Result?.additionalDatas;
  }

  const policyReplacementAnswer = [];

  let coverageBeneficiaryEligibility =
    insuredInfo[currentStep]?.coverageBeneficiaryEligibility?.length > 0 ? true : false;

  const disableContinueButton = !selectedCoverage && !waiveCoverage;
  let coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids?.[0];

  const updateCoverage = async () => {
    if (!waiveCoverage) {
      dispatch(UPDATE_LOADING(true));
      let externalStepContext = {
        "portalStep": currentStep,
        "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
        "reachedPreConfirmation": "no"
      };
      try {
        await dispatch(updateSubscribedCoverageInformation({
          enrollmentProcessBuid,
          subscribedElements,
          productIdentifierBuid,
          beneficiaryList,
          allowedBeneficiaryKind,
          selectedValue,
          possibleValues,
          policyReplacementAnswer,
          loggedUserEntity,
          coverageBeneficiaryEligibility: coverageBeneficiaryEligibility?.length > 0 ? true : false,
          currentStep,
          coverageBuid: getDetails?.[0]?.coverageBuid,
          externalStepContext: externalStepContext,
          isMoveNext: true,
          personBuid
        }));

      } catch (e) {
        console.error(e)
      } finally {
        dispatch(UPDATE_LOADING(false));
      }
    } else if (waiveCoverage) {
      dispatch(UPDATE_LOADING(true));
      try {
        await dispatch(unsubscribeCoverageDetails({
          enrollmentProcessBuid: enrollmentProcessBuid,
            coverageBuid: coverageDetailsForPrepare?.coverage,
            coveredPerson: coverageDetailsForPrepare?.coveragePersonBuid,
            subscribedCoverageBuid: subscribedCoverageBuid,
            isPrepare: false,
            isMoveNext: false,
        }, "UnsubscribeCoverage", true));
      }
      catch (e) {
        
      } finally {
        dispatch(UPDATE_LOADING(false));
      }
    }
  };
  const handleCoverageSelection = (code, costPerPayPeriod, e) => {
    setSelectedCoverage(code.toString());
    setWaiveCoverage(false);

    if (onCoverageChange) {
      onCoverageChange(personBuid, code, false, coverageBuid, costPerPayPeriod);
      updateButtonLabel({waiveCoverage: false})
    }
  };

  const handleWaiveCoverageSelection = () => {
    setSelectedCoverage(null);
    setWaiveCoverage(true);

    if (onCoverageChange) {
      onCoverageChange(personBuid, null, true, coverageBuid, '');
      updateButtonLabel({waiveCoverage: true})
    }
  };

  const updateButtonLabel = ({waiveCoverage}) => {
    setContinueBtnLabel(continueBtnLabelHelper({
      flowType: props.flowType,
      isWaivedOffFlag: waiveCoverage,
      productIdentifierBuid: productIdentifierBuid,
      hasDisclosureStatement: props.showDisclosureStatement,
      screenType: screenNames.INSURED_QUESTIONNAIRE_SCREEN,
    }));
  }

  const handleBackBtnClick = () => {
    onBack();
  };

  const handleContinueBtnClick = async () => {
    if (!showDisclosureStatement && step === stepsCount) {
      await updateCoverage();
      setMoveNextEnable(true);
      props.validateSubscribedProductData();
    } else {
      await updateCoverage();
      setMoveNextEnable(true);
    }
  };

  useEffect(() => {
    setContinueBtnLabel(continueBtnLabelHelper({
      flowType: props.flowType,
      isWaivedOffFlag: waivedCoverage,
      productIdentifierBuid: productIdentifierBuid,
      hasDisclosureStatement: props.showDisclosureStatement,
      screenType: screenNames.INSURED_QUESTIONNAIRE_SCREEN,
    }));
  },[])


//Configuration for coverage table
const configDataRedux = useSelector(
  (storeState) => storeState?.entities?.response?.onLoadResponseList?.productConfiguration
  ?._Result
);

if(configDataRedux?.premiumFrequency?.isEditable){
  getConfigHelper(tableHeader,configDataRedux)
}

const hasErrors = getErrorMessages(currentStep);
  return (
    <>
      <div
        className="row"
        style={{ fontSize: '1.5rem', color: 'rgb(32, 32, 32)', fontFamily: 'NotoSansRegular' }}
      >
        <div className="">
          <div className="label col-12 px-3 undefined" style={{ display: 'inline-block' }}>
            <div className="row">
              <div className="px-3 py-3">Select Coverage Option</div>
            </div>
          </div>
        </div>
      </div>

      {tableHeader && tableRow && tableData && (
        <div>
          <table className="table atomCoverageTable table-mobile-border coverageOptionTable">
            <thead>
              <tr style={tableHeader[0].config.style}>
              <th className="header-no-wrap colHeader d-none d-sm-table-cell" aria-label='Coverage Option' scope="col">&nbsp;</th>
                {tableHeader.filter(res=>res.value !== '').map((header, index) => (
                  <th
                    key={index}
                    className={`header-no-wrap colHeader ${header.config.className}`}
                    scope="col"
                    colSpan={3}
                    style={header.config.style}
                  >
                    {header.value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {tableData.map((data, index) => (
                <tr key={index}>
                  <td className="table-data-no-wrap coverageRadio " style={{  width :'1rem', textAlign:'' }}>
                    <span>
                      <label for={index} class="lable-sr-only" aria-label={index}></label>
                      <input
                        id={index}
                        className="form-check-radio coverageRadio"
                        type="radio"
                        value=""
                        checked={selectedCoverage === data.code}
                        onChange={(event) =>
                          handleCoverageSelection(data.code, data.costPerPayPeriod, event)
                        }
                      />
                    </span>
                  </td>
                  {tableRow.filter(res => res.value !== '').map((row, idx) => (
                    <td key={idx} className={`table-data-no-wrap ${row.className}`} style={{ width:'100%'}}>
                      {eval(row.value)}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td
                  className="atomCoverageTableRadio"
                  colSpan={tableHeader.length + 1}
                  align="center"
                >
                  <div className="atomCoverageTableRadio">
                    <input
                      className=""
                      type="radio"
                      checked={waivedCoverage === true}
                      onChange={handleWaiveCoverageSelection}
                      id="coverageWaiveId"
                    />
                    <label for="coverageWaiveId" className="ml-3">Waive Coverage</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="button-group">
        <Button type="button" onClick={handleBackBtnClick} className="mr-3">
          Back
        </Button>
        <Button type="button flowfive" onClick={handleContinueBtnClick} disabled={disableContinueButton || hasErrors}>
          {continueBtnLabel}
        </Button>
      </div>
    </>
  );
};

export default CoverageFlowFive;
