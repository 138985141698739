import loginScreenIllustration from '../../../../../Assets/Images/new_login_image.png';
import LoginContainer from './LoginContainer'
import { LeftBannerContent } from './LeftBannerContent';

const ImageLayout = ({ image, className = '', children, content }) => {

    return (
        <div className={`container-fluid`}>
            <div className={'row'}> 
                <div className={'col-lg-7 col-xl-7 d-none d-md-block px-0'}>
                    <img src={image.src} {...image} alt="Four Employees Enrolling" className="img-responsive" width="100%" height="100%" id="client-logo-enrolling"></img>
                    {content}
                </div>
                <div className={'col-lg-5'}>
                    {children}
                </div>
            </div>
        </div> 
    );
};
const LoginScreen = (props) => {
    return (
        <ImageLayout image={{ src: loginScreenIllustration, alt: "Login"}} content={<LeftBannerContent />} className={"col-lg-6 col-xl-6 d-none d-md-block"} >
         <LoginContainer {...props} />
        </ImageLayout>
    )
}
export default LoginScreen;
