import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import Elementor from '../../Cells/Form/Elementor';

const ListComponent = (props) => {
    const {
        elements = [],
        listClassName = '',
        listStyle = {},
        tabIndex = false
    } = props;
    return (
        <ul className={listClassName} style={listStyle} tabIndex={tabIndex}>
            {elements.map((element, elementKey) => {
                return (
                    <li key={elementKey} className={`d-flex flex-row align-items-center ${element.className || ''}`} style={element['style'] || {}}>
                        <Elementor
                            key={element.name}
                            element={element}
                            {...props}
                        />
                    </li>
                )
            })}
        </ul>
    );
};

ListComponent.propTypes = {
    listStyle: PropTypes.object,
    listClassName: PropTypes.string,
    elements: PropTypes.array,
};

ListComponent.defaultProps = {
    listStyle: {},
    listClassName: '',
    elements: [],
    showContent: [],
    id: '',
};

export default ListComponent;
