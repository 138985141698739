
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const formatter = (locale, currency, decimal = 2) => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency: currency,
  minimumFractionDigits: decimal
});

const Currency = (props) => {
  // TODO: Get locale based on browser information and/or redux state
  const { locale, currency, decimal, value } = props;
  const {
    id,
    name,
    label,
    defaultValue,
    onChange,
    onBlur,
    className,
    classNameWrapper,
    hidden,
    required,
    requiredValidation,
    disabled
  } = props;

  const [focus, setFocused] = useState(false);

  /** Set initial focus based on defaultValue */
  useEffect(() => {
    if (defaultValue) {
      onChange &&
        onChange({
          target: {
            name: name || id,
            value: defaultValue,
          },
        });
    }
  }, [defaultValue, onChange, name, id]);

  useEffect(() => {
    if (value) {
      setFocused(value != null);
    }
  }, [value]);

  const handleFocus = () => {
    setFocused(true);
  };

  const getCurrencyNumber = (value, currency, locale) => {
    let decimalcount = 0;
    let parsedValue = "";
    for (let i = 0; i < value.length; i++) {
      let chr = value.charAt(i)
      if (chr.charCodeAt(0) >= 48 && chr.charCodeAt(0) <= 57) {
        parsedValue += chr;
      }
      else if (currency === "EUR" && locale == "fr-FR" && chr.charCodeAt(0) === 44) {
        if (decimalcount >= 1) {
          break;
        }
        parsedValue += ".";
        decimalcount++;
      }
      else if (currency !== "EUR" && locale !== "fr-FR" && chr.charCodeAt(0) === 46) {
        if (decimalcount >= 1) {
          break;
        }
        parsedValue += chr;
        decimalcount++;
      }
    }
    if (parsedValue == ".") {
      parsedValue = "0."
    }
    return parsedValue;
  };

  const useRunAfterUpdate=()=> {
    const afterPaintRef = React.useRef(null);
    React.useLayoutEffect(() => {
      if (afterPaintRef.current) {
        afterPaintRef.current();
        afterPaintRef.current = null;
      }
    });
    const runAfterUpdate = fn => (afterPaintRef.current = fn);
    return runAfterUpdate;
  }

  const runAfterUpdate = useRunAfterUpdate();

  const handleChange = (event) => {
    event.preventDefault();
    const input = event.target;
    let inputValue = event.target.value;

    if (inputValue) {
      let tempValue = "'" + inputValue;
      inputValue = getCurrencyNumber(tempValue, currency, locale);

      let decimalChr = inputValue ? inputValue.substr(inputValue.length - 1) : "";
      if (decimalChr !== ".") {
        decimalChr = "";
      }

      if (inputValue) {
        if (currency === "EUR" && inputValue){
          inputValue = inputValue.replace(",", ".");
        }
        inputValue = formatter(locale, currency, decimal).format(inputValue);
        if (decimalChr && inputValue && currency === "EUR" && locale === "fr-FR"){
          inputValue = inputValue.slice(0, inputValue.length-2) + "," + 
            inputValue.slice(inputValue.length-2, inputValue.length);
        }
        else{
          inputValue = inputValue + decimalChr;
        }
        event.target.value = inputValue;

        if (currency === "EUR" && locale === "fr-FR" && inputValue){
          runAfterUpdate(() => {
            input.selectionStart = inputValue.length;
            input.selectionEnd = inputValue.length-2;
          });
        }

      }
      else {
        event.target.value = "";
      }
    }
    onChange && onChange(event);
  };

  const handleBlur = (event) => {
    event.preventDefault();
    if (!props.value) setFocused(false);
    onBlur && onBlur(event);
  };
  let classes = disabled ? `elementDisabled ${className}` : className
  return <div className={'floatingLabel pb-3 ' + classNameWrapper} hidden={hidden}>
    <input
      id={id || name}
      name={name || id}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      value={value}
      required={required}
      disabled ={disabled}
      // className={classes + className}
      className={
        (value && requiredValidation) || !requiredValidation
          ? 'w-100 ' + classes
          : 'w-100 required ' + classes
      }

    />
    {label && (
      <label className={focus || props.type === 'date' ? 'isFocused' : ''} htmlFor={name || id}>
        {label}
      </label>
    )}
  </div>

};
Currency.propTypes = {
  locale: PropTypes.string,
  currency: PropTypes.oneOf(['USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY']),
  decimal: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
};
Currency.defaultProps = {
  locale: 'en-US',
  currency: 'USD',
  decimal: 2,
  value: 0,
  hidden: false,
  className: '',
  classNameWrapper: '',
  type: 'text',
  required: false,
  defaultValue: null,
};

export default Currency;
