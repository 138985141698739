import React, {Fragment} from "react";
import { useSelector } from "react-redux";
import Loader from "../../../Atoms/Loader";

const LoaderWrapper = ({
    children
}) =>{
    const { loader, contentLoader } = useSelector(({loader}) => loader);
    return (
        <Fragment>
            {
                loader || contentLoader ? <Loader loading={true} /> 
                : children
            }
        </Fragment>
    )
}
export default LoaderWrapper;