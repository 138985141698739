import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callConstants } from '../../../../Store/Slices/Entities/constants/index';
import { constantsList } from '../../../../Common/Constants/index';
import NavigationBar from '../../../Cells/NavigationBar';
import FormGenerator from '../../../Cells/Form/FormGenerator';
import { Linker } from '../../../Atoms';
const ConfigLinks = (props) => {
  /* useEffect to get Home PAge layout content dependent on language */
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((storeState) => {
    return storeState.language;
  });
  const [content, SetContent] = useState({});
  const [divisionOptions] = useState({});



  // useEffect(() => {
  //   /* useEffect to get all constants through API call */
  //   constantsList.forEach((element) => {
  //     dispatch(callConstants(element));
  //   });
  // }, []);

  // //Get the client Id
  // const clientId = useSelector(
  //   (storeState) => storeState?.loggedUserEntity?.data?._Result?.clientId?.internalClientId
  // );

  //Get the client Id
  const clientId = useSelector(
    (storeState) => storeState?.auth?.loggedUserEntity?.data?._Result?.clientId?.internalClientId
  );

  return (
    <>
      {/* <SectionHeader
      title="Search for an Employee"
      breadcrumbs={breadcrumbs}
      current="Search for an Employee"
      secionElements = {content.layout && content.layout.sectionHeader && content.layout.sectionHeader.elements || []}
    ></SectionHeader> */}
      {/* <Suspense fallback={<div>Loading...</div>}>
      {/* <div className="searchEmployeeWrapper"> */}
      {/* <FormGenerator content={content} divisionOptions={divisionOptions} {...props} /> */}
      {/* <div className="row">
          <div className="col-3">Links 1</div>
          <div className="col-3">Links 2</div>
          <div className="col-3">Links 3</div>
        </div> */}

      {/* </div> */}
      {/* </Suspense> */}
      {/* <NavigationBar /> */}

      <div className="container-fluid">
        <div className="row py-3 px-5">
          {/* <div className="col-4">
            <h6>Theming</h6>
            <div> */}
          {' '}
          {/* <a href="/callcenter/employeeSearch">Add Theme</a> */}
          {/* <Linker to="/configurator/theming">Add Theme</Linker>
            </div>
            <div>
              {' '} */}
          {/* <a href="/callcenter/employeeSearch">Update Theme</a> */}
          {/* <Linker to="/configurator/updateTheming">Edit Theme</Linker>
            </div>
          </div> */}
          <div className="col-4">
            {' '}
            <h6>Registration</h6>
            <div>
              {' '}
              {/* <a href="/configurator/registerConfig">Register</a> */}
              <Linker to="/configurator/registerConfig">Register</Linker>
            </div>
            <div>
              {' '}

              <Linker to="/configurator/loginConfig">Login</Linker>
            </div>
            <div>
              {/* <a href="/configurator/employeeProfileConfig">Employee Profile</a> */}
              <Linker to="/configurator/employeeProfileConfig">Employee Profile</Linker>

              {/* <Linker
                key="emp"
                // className={'root-level-link'}
                to="/configurator/employeeProfileConfig"
              >
                emp
              </Linker> */}
            </div>
            <div>
              {' '}
              {/* <a href="/configurator/dependentConfig">Dependent</a> */}
              <Linker to="/configurator/dependentConfig">Dependent</Linker>
            </div>
            <div>
              {' '}
              {/* <a href="/configurator/beneficiaryConfig">Beneficiary</a> */}
              <Linker to="/configurator/beneficiaryConfig">Beneficiary</Linker>
            </div>
          </div>
          <div className="col-4">
            {' '}
            <h6>Common</h6>
            <div>
              {' '}
              {/* <a href="/configurator/welcomeConfig">Welcome</a> */}
              <Linker to="/configurator/welcomeConfig">Welcome</Linker>
            </div>
            <div>
              {' '}
              {/* <a href="/configurator/headerConfig">Header</a> */}
              <Linker to="/configurator/headerConfig">Header</Linker>
            </div>
            <div>
              {' '}
              {/* <a href="/configurator/headerConfig">Header</a> */}
              <Linker to="/configurator/productConfig">Product</Linker>
            </div>
            <div>
              {' '}
              {/* <a href="/configurator/headerConfig">Header</a> */}
              <Linker to="/configurator/summaryScreenConfig">Pre-Confirmation</Linker>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigLinks;
