import React, { useState } from 'react';
import { useFormik } from 'formik';

import appConfig from '../../../../../appConfig';
import ResendOTPBlock from '../../Atoms/ResendOTPBlock';
import { Button, TextInput, RadioButton } from '../../Atoms';
import http from '../../../../../Common/Services/HttpService';
import { errorMessages, registerPhoneSchema } from '../../Atoms/FormValidation';
import { formatPhone, getBasicAuthHeaderValue } from '../../Helper/MFAHelper';
import iconEdit from '../../../../../Assets/Images/icon-edit.png';
import iconVerified from '../../../../../Assets/Images/iconVerified.svg'

const RegisterPhone = ({ initialValues, onSuccess }) => {
    const [sendOtp, setSendOtp] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isEntered, setEntered] = useState(false);
    const options = [{ label: 'Phone Call', value: 'call' }, { label: 'Text', value: 'sms' }];

    const formik = useFormik({
        initialValues: initialValues || {
            phoneNumber: '',
            verificationMethod: ''
        },
        validationSchema: registerPhoneSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            handleSendOtp({ verificationMethod: values.verificationMethod, phoneNumber: values.phoneNumber?.trim() });
        }
    });

    const onRadioChange = (e) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, value);
    }

    const onBlurPhoneInput = (e) => {
        e.target.value && setEntered(true)
    };

    const handleSendOtp = (data) => {
        const basicAuthorizationValue = getBasicAuthHeaderValue();
        http.post(
            appConfig.SSP_AUTH_URL + "/stateful/sign-up/register-phone-number",
            data,
            {
                withCredentials: true,
                headers: {
                    Authorization: `Basic ${basicAuthorizationValue}`,
                    'X-REQUEST-ID': Math.random(200),
                }
            }
        ).then((res) => {
            if (res.status === 200) {
                setSendOtp(true)
            } else {
                formik.setFieldError('phoneNumber', errorMessages(res));
            }
        }).catch((e) => {
          
            formik.setFieldError('phoneNumber', errorMessages(e));
        });
    }

    const handleOtpVerification = () => {
        const otp = formik.values.otp?.trim();
        const basicAuthorizationValue = getBasicAuthHeaderValue();

        http.post(
            appConfig.SSP_AUTH_URL + "/stateful/sign-up/activate-factor",
            { otp },
            {
                withCredentials: true,
                headers: {
                    Authorization: `Basic ${basicAuthorizationValue}`,
                    'X-REQUEST-ID': Math.random(200),
                }
            }
        ).then((res) => {
            if (res.status === 200) {
                setSuccess(true);
                onSuccess(formik.values);
            } else {
                formik.setFieldError('otp', errorMessages(res));
            }
        }).catch((e) => {
            
        });
    }

    const ErrorMessage = (field) => {
        return (
            <>
                {formik.touched[field] && formik.errors[field] ? (
                    <div class="form-mandatory">{formik.errors[field]}</div>
                ) : null}
            </>
        )
    }

    const onEdit = (e) => {
        e.preventDefault();
        setSendOtp(false);
        setEntered(false);
    }

    const onResend = () => {
        handleSendOtp(formik.values);
    }

    const validationMsg = (field) => {
        return (formik.touched[field] && formik.errors[field] && (
            <div className="form-mandatory">{formik.errors[field]}</div>
        ))
    };

    const onChangePhone = async (e) => {
        const { value, name } = e.target;
        formik.setFieldTouched(name, true);
        formik.setFieldValue(`${name}_formatted`, formatPhone(value));
        await formik.setFieldValue(name, value.replace(/\D/g, '').slice(0, 11))
    }

    return (
        <>
            <div className="row py-2">
                <div className="col-sm-12 col-md-6 py-2">
                    {(!sendOtp && !isSuccess && !isEntered) ? (
                        <>
                            <TextInput
                                required
                                id="phoneNumber"
                                name="phoneNumber"
                                label={"Mobile Phone"}
                                className="w-100 inputField"
                                placeholder="(xxx) xxxx xxxx"
                                {...formik.getFieldProps('phoneNumber')}
                                value={formik.values?.phoneNumber_formatted || ''}
                                onBlur={onBlurPhoneInput}
                                onChange={onChangePhone}
                            />
                            {ErrorMessage('phoneNumber')}
                        </>
                    ) : (
                        <div className=''>
                            <label>Mobile Phone <span className='form-mandatory mb-0'>*</span></label>
                            <p style={{ alignItems: 'start', justifyContent: 'start', marginTop: 7, fontSize: 16, fontWeight: 'bold', marginRight: 10 }}>
                                {formatPhone(formik.values.phoneNumber)}
                                <span className='py-2'>
                                    <img alt="edit icon" src={iconEdit} style={{ height: 18, marginTop: -10, marginLeft: 5, cursor: 'pointer' }} onClick={onEdit} />
                                </span>
                                {isSuccess && <img alt="verify icon" src={iconVerified} style={{ height: 18, marginTop: -10, marginLeft: 5 }} />}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {!isSuccess && (
                <>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <p>
                                {!sendOtp ? (
                                    "We must validate your ownership of this mobile number by sending code"
                                ) : (
                                    "Please enter the code we sent to above mobile number."
                                )}
                            </p>
                        </div>
                    </div>
                    {!sendOtp ? (
                        <>
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <RadioButton
                                        options={options}
                                        className={'forMFA register'}
                                        onChange={onRadioChange}
                                        name="verificationMethod"
                                        label="How to you like to receive the code?"
                                        selectedOption={formik.values.verificationMethod}
                                    />
                                    {ErrorMessage('verificationMethod')}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <p>* Datas rates may apply</p>
                                    <Button
                                        type="button"
                                        className="btn btn-outline-primary primaryBackground btn-outline-primary-contrast py-2"
                                        disabled={!formik.isValid}
                                        onClick={formik.handleSubmit}
                                    >
                                        Send Code
                                    </Button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <TextInput
                                        required
                                        id="otp"
                                        name="otp"
                                        label="Enter Code"
                                        placeholder=""
                                        className="w-100 inputField p-3"
                                        {...formik.getFieldProps('otp')}
                                    />
                                    {validationMsg('otp')}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12 py-2">
                                    <Button
                                        type="button"
                                        className="btn btn-primary primaryBackground btn-primary-contrast"
                                        disabled={(!formik.isValid || !formik.dirty)}
                                        onClick={handleOtpVerification}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                            <ResendOTPBlock duration={45} onResend={onResend} />
                            <div>
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div><p>Unable to receive code?</p></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <a href="#" onClick={onEdit}>Change method</a>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default RegisterPhone;
