import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxConstants } from '../../../../Common/Constants/index';
import Loader from '../../../Atoms/Loader';
import formFields from '../Content/BeneficiaryConfig.json';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveConfig } from '../Actions/saveConfig';
import { retrieveConfig } from '../Actions/retrieveConfig';
import SuccessPage from '../Common/SuccessPage';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response';
import '../styles.css';
import { CLEAR_ALERT_MESSAGES, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { getErrorMessages } from '../../../Molecules/CustomSection/Helper/MappingHelper';
import { MessageComponent } from '../../../Atoms';
import { Checkbox, Button, Label } from '../../../Molecules/CustomSection/Atoms';

const { confirmation, formReferenceLinkText } = formFields;
const BeneficiaryConfig = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const dispatch = useDispatch();
  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};

  const configDataRedux = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.beneficiaryConfigInfo
        ?.beneficiaryConfigInformation
  );

  const configData = {
    beneficiaryMiddleNameToggle: configDataRedux?.beneficiaryMiddleNameToggle || false,
    beneficiaryMiddleNameRequired: configDataRedux?.beneficiaryMiddleNameRequired || false,
    beneficiarySSNRequired: configDataRedux?.beneficiarySSNRequired || false,
    beneficiaryTypeIndividual: configDataRedux?.beneficiaryTypeIndividual || false,
    beneficiaryTypeGroupTrust: configDataRedux?.beneficiaryTypeGroupTrust || false,
    addBeneficiary: false,
    beneficiarySameAsEmployeeToggle: configDataRedux?.beneficiarySameAsEmployeeToggle || false,
    beneficiaryStreetAddress1Toggle: configDataRedux?.beneficiaryStreetAddress1Toggle || false,
    beneficiaryStreetAddress2Toggle: configDataRedux?.beneficiaryStreetAddress2Toggle || false,
    beneficiaryCityToggle: configDataRedux?.beneficiaryCityToggle?.hideableField || false,
    beneficiaryStateToggle: configDataRedux?.beneficiaryStateToggle?.hideableField || false,
    beneficiaryZipCodeToggle: configDataRedux?.beneficiaryZipCodeToggle?.hideableField || false,
    beneficiaryCountryToggle: configDataRedux?.beneficiaryCountryToggle?.hideableField || false,
  };

  const formik = useFormik({
    initialValues: {
      beneficiaryMiddleNameToggle: configData?.beneficiaryMiddleNameToggle,
      beneficiaryMiddleNameRequired: configData?.beneficiaryMiddleNameRequired,
      beneficiarySSNRequired: configData?.beneficiarySSNRequired,
      beneficiaryTypeIndividual: configData?.beneficiaryTypeIndividual,
      beneficiaryTypeGroupTrust: configData?.beneficiaryTypeGroupTrust,
      addBeneficiary: false,
      beneficiarySameAsEmployeeToggle: configData?.beneficiarySameAsEmployeeToggle,
      beneficiaryStreetAddress1Toggle: configData?.beneficiaryStreetAddress1Toggle,
      beneficiaryStreetAddress2Toggle: configData?.beneficiaryStreetAddress2Toggle,
      beneficiaryCityToggle: configData?.beneficiaryCityToggle,
      beneficiaryStateToggle: configData?.beneficiaryStateToggle,
      beneficiaryZipCodeToggle: configData?.beneficiaryZipCodeToggle,
      beneficiaryCountryToggle: configData?.beneficiaryCountryToggle,
    },

    onSubmit: (values, { setSubmitting }) => {
      formik.resetForm();
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  let { clientId } = useSelector((storeState) => storeState.auth.userAuth);

  const saveBeneficiaryConfig = async () => {
    let data = {
      beneficiaryConfigInformation: {
        beneficiaryMiddleNameToggle: formik?.values?.beneficiaryMiddleNameToggle,
        beneficiaryMiddleNameRequired: formik?.values?.beneficiaryMiddleNameRequired,
        beneficiarySSNRequired: formik?.values?.beneficiarySSNRequired,
        beneficiaryTypeIndividual: formik?.values?.beneficiaryTypeIndividual,
        beneficiaryTypeGroupTrust: formik?.values?.beneficiaryTypeGroupTrust,
        addBeneficiary: false,
        beneficiarySameAsEmployeeToggle: formik?.values?.beneficiarySameAsEmployeeToggle,
        beneficiaryStreetAddress1Toggle: formik?.values?.beneficiaryStreetAddress1Toggle,
        beneficiaryStreetAddress2Toggle: formik?.values?.beneficiaryStreetAddress2Toggle,
        beneficiaryCityToggle: formik?.values?.beneficiaryCityToggle,
        beneficiaryStateToggle: formik?.values?.beneficiaryStateToggle,
        beneficiaryZipCodeToggle: formik?.values?.beneficiaryZipCodeToggle,
        beneficiaryCountryToggle: formik?.values?.beneficiaryCountryToggle,
      },
    };
    setShowSuccess(null);
    //Api Call here
    let saveResponse = await saveConfig(data, clientId, formFields.apiCall.submit, setShowLoader);
    if (saveResponse?.status == 201) {
      setShowSuccess(true);
    } else {
      dispatch(
        SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'createBeneficiaryInfofromDb',
          show: true,
          page: 'Configurator',
        })
      );
      setShowSuccess(false);
    }
  };

  useEffect(() => {
    dispatch(CLEAR_ALERT_MESSAGES([]));
  }, [formik.values]);

  useEffect(() => {
    // //Api Call here
    const callAPI = async () => {
      setShowLoader(true);
      let response = await retrieveConfig(clientId, formFields.apiCall.onload);
      if (!response?.beneficiaryConfigInformation) {
        setShowSuccess(null);
        setTimeout(() => {
          dispatch(
            SET_ALERT_MESSAGE({
              type: 'error',
              errorCode: '520',
              continue: true,
              id: 'getBeneficiaryInfofromDb',
              show: true,
              page: 'Configurator',
            })
          );
          setShowSuccess(false);
        }, 50);
      }
      dispatch(
        onLoadAPI({
          beneficiaryConfigInfo: response,
          isAPICallInProgress: false,
        })
      );
      setShowLoader(false);
    };
    try {
      callAPI();
    } catch (error) {
      
    }
  }, [loggedUserEntity]);

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  const hasErrors = getErrorMessages('Configurator');

  return (
    <div>
      <MessageComponent currentPage={'Configurator'} setShowLoader={setShowLoader} />
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper">
          {!showSuccess && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="config">
                <div className="col-12 px-5">
                  <div class="row py-3 px-3">
                    <h4>{formFields.panelHeader.label}</h4>
                  </div>

                  <div class="row">
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.dataField.label}
                        inputId={formFields.dataField.label}
                      />
                    </div>
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxToggle.label}
                        inputId={formFields.checkboxToggle.label}
                      />
                    </div>
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.editablePlaceHolderLabel.label}
                        inputId={formFields.editablePlaceHolderLabel.label}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.beneficiaryMiddleName.label}
                        inputId={formFields.beneficiaryMiddleName.label}
                      />
                    </div>
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiaryMiddleNameToggle.id}
                        name={formFields.beneficiaryMiddleNameToggle.name}
                        checked={formik.values.beneficiaryMiddleNameToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiaryMiddleNameToggle.name)}
                      />
                    </div>
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiaryMiddleNameRequired.id}
                        name={formFields.beneficiaryMiddleNameRequired.name}
                        checked={formik.values.beneficiaryMiddleNameRequired}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiaryMiddleNameRequired.name)}
                      />
                    </div>
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right"></div>
                  </div>

                  <div class="row">
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label text={formFields.SSN.label} inputId={formFields.SSN.label} />
                    </div>

                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiarySSNToggle.id}
                        name={formFields.beneficiarySSNToggle.name}
                        checked={formik.values.beneficiarySSNToggle}
                        disabled
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiarySSNToggle.name)}
                      />
                    </div>
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiarySSNRequired.id}
                        name={formFields.beneficiarySSNRequired.name}
                        checked={formik.values.beneficiarySSNRequired}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiarySSNRequired.name)}
                      />
                    </div>
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right"></div>
                  </div>

                  <div class="row">
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.individual.label}
                        inputId={formFields.individual.label}
                      />
                    </div>

                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiaryTypeIndividual.id}
                        name={formFields.beneficiaryTypeIndividual.name}
                        checked={formik.values.beneficiaryTypeIndividual}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiaryTypeIndividual.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.groupTrust.label}
                        inputId={formFields.groupTrust.label}
                      />
                    </div>

                    <div className=" col-4 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiaryTypeGroupTrust.id}
                        name={formFields.beneficiaryTypeGroupTrust.name}
                        checked={formik.values.beneficiaryTypeGroupTrust}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiaryTypeGroupTrust.name)}
                      />
                    </div>
                    <div class="row px-2">
                      <div className="col-12 col-md-6 col-lg-12 py-2">
                        <Label
                          text={formFields.DataField.label}
                          inputId={formFields.DataField.label}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="config">
                <div className="col-12 px-5">
                  <div class="row py-3 px-3">
                    <h4>{formFields.panelAddressHeader.label}</h4>
                  </div>

                  <div className="row">
                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.dataFieldAddress.label}
                        inputId={formFields.dataFieldAddress.label}
                      />
                    </div>
                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxToggleAddress.label}
                        inputId={formFields.checkboxToggleAddress.label}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.liveInSameAddress.label}
                        inputId={formFields.liveInSameAddress.label}
                      />
                    </div>

                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiarySameAsEmployeeToggle.id}
                        name={formFields.beneficiarySameAsEmployeeToggle.name}
                        checked={formik.values.beneficiarySameAsEmployeeToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiarySameAsEmployeeToggle.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.beneficiaryAddressLine1.label}
                        inputId={formFields.beneficiaryAddressLine1.label}
                      />
                    </div>

                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiaryStreetAddress1Toggle.id}
                        name={formFields.beneficiaryStreetAddress1Toggle.name}
                        checked={formik.values.beneficiaryStreetAddress1Toggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiaryStreetAddress1Toggle.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.beneficiaryAddressLine2.label}
                        inputId={formFields.beneficiaryAddressLine2.label}
                      />
                    </div>

                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiaryStreetAddress2Toggle.id}
                        name={formFields.beneficiaryStreetAddress2Toggle.name}
                        checked={formik.values.beneficiaryStreetAddress2Toggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiaryStreetAddress2Toggle.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.beneficiaryCity.label}
                        inputId={formFields.beneficiaryCity.label}
                      />
                    </div>

                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiaryCityToggle.id}
                        name={formFields.beneficiaryCityToggle.name}
                        checked={formik.values.beneficiaryCityToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiaryCityToggle.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.beneficiaryState.label}
                        inputId={formFields.beneficiaryState.label}
                      />
                    </div>

                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiaryStateToggle.id}
                        name={formFields.beneficiaryStateToggle.name}
                        checked={formik.values.beneficiaryStateToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiaryStateToggle.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.beneficiaryZipCode.label}
                        inputId={formFields.beneficiaryZipCode.label}
                      />
                    </div>

                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiaryZipCodeToggle.id}
                        name={formFields.beneficiaryZipCodeToggle.name}
                        checked={formik.values.beneficiaryZipCodeToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiaryZipCodeToggle.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Label
                        text={formFields.beneficiaryCountry.label}
                        inputId={formFields.beneficiaryCountry.label}
                      />
                    </div>

                    <div className=" col-6 col-md-4 col-lg-4 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.beneficiaryCountryToggle.id}
                        name={formFields.beneficiaryCountryToggle.name}
                        checked={formik.values.beneficiaryCountryToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.beneficiaryCountryToggle.name)}
                      />
                    </div>
                  </div>

                  <div className=' className="w-100 form-btnGroup py-5"'>
                    <Button
                      className="btn btn-primary  primaryBackground mr-4"
                      onClick={saveBeneficiaryConfig}
                      disabled={hasErrors}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}

          {showSuccess && <SuccessPage {...confirmation}></SuccessPage>}
        </div>

        <div class="px-md-5 py-5 container-fluid"></div>
      </Suspense>
    </div>
  );
};

export default BeneficiaryConfig;
