/* Theming for buttons primaryBackground,editDetailsButton,primaryOutline and makeHomeButton */
import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.primaryBackground {
  background-color:${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryBgColor};
  border-color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryBgColor};
    color:${({ theme }) => theme.atoms && theme.atoms.button && theme.atoms.primaryColor};
}

.btn-link{
  color: #002C77 !important;
  font-size: 0.875rem !important;
  font-family: NotoSansMedium
}

.btn-link.disabled, .btn-link:disabled {
  color: #b3b3b3 !important;
  pointer-events: none;
}

.editDetailsButton{
  background:${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryGroupOutlineBgColor};
  color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryGroupOutlineColor};
      border-radius: 1.875rem;
  float:left;
  position: relative;
  bottom: 0.6rem;

  width: 12.313rem;
  height: 2.5rem;

  font-size: 1rem;
  font-weight: 500;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.editDetailsButton:hover {
  background: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryGroupOutlineHoverBgColor};
  color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryGroupOutlineHoverColor};
      border-radius: 1.875rem;

  float:left;
  position: relative;
  bottom: 0.6rem;

  width: 12.313rem;
  height: 2.5rem;

  font-size: 1rem;
  font-weight: 500;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}



.primaryBackground:hover {
  background-color:${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryOutlineHoverColor};
  border-color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryBgColorHover};
    color: ${({ theme }) =>
      theme.atoms && theme.atoms.button && theme.atoms.button.primaryBgColorHover};
}

.primaryOutline {
 color: ${({ theme }) => theme.atoms && theme.atoms.button && theme.atoms.button.primaryBgColor};
  border-color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryBgColor};
}


.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
   color:${({ theme }) => theme.atoms && theme.atoms.button && theme.atoms.primaryColor};
  background-color:${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryBgColor};
  border-color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryBgColor};

}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
  box-shadow: 0 0 0 0.2rem ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryBgColor};
}

.primaryOutline:hover {
    color:${({ theme }) =>
      theme.atoms && theme.atoms.button && theme.atoms.button.primaryOutlineHoverColor};
  background-color:${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryOutlineBgColor};
  border-color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryOutlineBgColor};
}

.btn.moreRounded {
  border-radius: 1.875rem !important;
}

.disabledButton {
  pointer-events: none;
}

.makeHomeButton {
  background: #ffffff !important;
  color: #1a88e2 !important;
  border-radius: 1.875rem; 
  
  bottom: 0.6rem;

  font-size: 1rem;
  font-weight: 500;

}

.makeHomeButton:hover {
  background: #1a88e2 !important;
  color: #ffffff !important;
}

.makePaymentButton {
  background: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryGroupOutlineBgColor};
  color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryGroupOutlineColor};
  border-radius: 1.875rem;
  float: right;
  position: relative;
  bottom: 0.6rem;
  width: 11.313rem;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 500;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.makePaymentButton:hover {
  background: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryGroupOutlineColor};
  color:  ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.primaryGroupOutlineBgColor};
}

.closeButton {
  font-weight: 500;
  background-color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.closeButtonBgColor};
  color:  ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.closeButtonColor};
  border-radius: 1.875rem;
  padding: 0.563rem 1.313rem 0.563rem 1.313rem;
}
.closeButton:hover {
  color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.closeButtonBgColor};
  background-color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.closeButtonColor};
}

[buttonName=LifeEventYesButton] 
{
  min-width: 4.5rem;
  min-height: 2.5rem;
}
[buttonName=LifeEventNoButton] 
{
  min-width: 4.3rem;
  min-height: 2.5rem;
}

@media (min-width: 768px) and (max-width: 1366px) {
  .primaryOutline#mfaCancel{
      position: relative;
      margin-top: 100%;
  }

  .primaryBackground#mfaContinue{
    position: relative;
    margin-top: 100%;

  }
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top: 29vh !important;
  }

  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: 29vh !important;
  }
  .primaryOutline#mfaCancelForgotUser{
    position: relative;
    top: 33vh !important;
  }

  .primaryBackground#mfaContinueForgotUser{
    position: relative;
    top: 33vh !important;
  }
}
[name=MFAForgotUsername.cancelButton] {
  position: relative;
    top: 33vh !important;
    color: green
}

[buttonPurpose=mfaCancel] 
{
  position: relative;
  top: 34vh !important;
  min-width: 6rem;
  min-height: 2.5rem;
}
[buttonPurpose=mfaContinue] 
{
  position: relative;
  top: 34vh !important;
  min-width: 7rem;
  min-height: 2.5rem;
}
[buttonPurpose=mfaPasswordCancel] 
{
  position: relative;
  top: 29vh !important;
  min-width: 6rem;
  min-height: 2.5rem;
}
[buttonPurpose=mfaPasswordContinue] 
{
  position: relative;
  top: 29vh !important;
  min-width: 7rem;
  min-height: 2.5rem;
}
/* IPhone SE */
@media only screen and (width: 375px) {
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top: calc(100vh - 30rem) !important;
  }
  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: calc(100vh - 30rem) !important;
  }
  [buttonPurpose=mfaPasswordCancel] 
  {
    position: relative;
    top: 10vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaPasswordContinue] 
  {
    position: relative;
    top: 10vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
}

/* IPhone 12 Pro */
@media only screen and  (width: 390px)  {
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top: calc(100vh - 40rem) !important;
  }
  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: calc(100vh - 40rem) !important;
  }
  [buttonPurpose=mfaPasswordCancel] 
  {
    position: relative;
    top: 23vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaPasswordContinue] 
  {
    position: relative;
    top: 23vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
}


/* Pixel 5 */
@media only screen and  (width: 393px)  {
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top: calc(100vh - 40rem) !important;
  }
  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: calc(100vh - 40rem) !important;
  }
  [buttonPurpose=mfaPasswordCancel] 
  {
    position: relative;
    top: 23vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaPasswordContinue] 
  {
    position: relative;
    top: 23vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
}
/* IPhone XR */
@media only screen and  (width: 414px)  {
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top: calc(100vh - 40rem) !important;
  }
  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: calc(100vh - 40rem) !important;
  }
  [buttonPurpose=mfaPasswordCancel] 
  {
    position: relative;
    top: 29vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaPasswordContinue] 
  {
    position: relative;
    top: 29vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaCancel] 
{
  position: relative;
  top: 28vh !important;
  min-width: 6rem;
  min-height: 2.5rem;
}
[buttonPurpose=mfaContinue] 
{
  position: relative;
  top: 28vh !important;
  min-width: 7rem;
  min-height: 2.5rem;
}
}


/*IPad Mini*/
@media only screen and (width: 768px) and (height: 1024px) and (orientation:portrait) {
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top:23rem;
  }
  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: 23rem;
  }
  [buttonPurpose=mfaPasswordCancel] 
  {
    position: relative;
    top: 46vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaPasswordContinue] 
  {
    position: relative;
    top: 46vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaCancel] 
  {
    position: relative;
    top: 48vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaContinue] 
  {
    position: relative;
    top: 48vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
}
@media all and (width: 1024px) and (height: 768px) and (orientation:landscape){
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top:17rem;
  }
  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: 17rem;
  }
  [buttonPurpose=mfaPasswordCancel] 
  {
    position: relative;
    top: 49vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaPasswordContinue] 
  {
    position: relative;
    top: 49vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaCancel] 
  {
    position: relative;
    top: 52vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaContinue] 
  {
    position: relative;
    top: 52vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
}

/*IPad Air*/
@media only screen and (width: 820px) and (height: 1180px) and (orientation:portrait) {
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top:32rem;
  }
  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: 32rem;
  }
  [buttonPurpose=mfaPasswordCancel] 
  {
    position: relative;
    top: 42vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaPasswordContinue] 
  {
    position: relative;
    top: 42vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaCancel] 
  {
    position: relative;
    top: 53vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaContinue] 
  {
    position: relative;
    top: 53vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
}
@media all and (width: 1180px) and (height: 820px) and (orientation:landscape){
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top:19rem;
  }
  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: 19rem;
  }
  [buttonPurpose=mfaPasswordCancel] 
  {
    position: relative;
    top: 38vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaPasswordContinue] 
  {
    position: relative;
    top: 38vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
}

/*IPad Pro*/
@media only screen and (width: 1024px) and (height: 1366px) and (orientation:portrait) {
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top:49rem;
  }
  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: 49rem;
  }
  [buttonPurpose=mfaPasswordCancel] 
  {
    position: relative;
    top: 58vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaPasswordContinue] 
  {
    position: relative;
    top: 58vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaCancel] 
  {
    position: relative;
    top: 61vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaContinue] 
  {
    position: relative;
    top: 61vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
}
@media all and (width: 1366px) and (height: 1024px) and (orientation:landscape){
  .primaryOutline#mfaCancelForgot{
    position: relative;
    top:30rem;
  }
  .primaryBackground#mfaContinueForgot{
    position: relative;
    top: 30rem;
  }
  [buttonPurpose=mfaPasswordCancel] 
  {
    position: relative;
    top: 50vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaPasswordContinue] 
  {
    position: relative;
    top: 50vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaCancel] 
  {
    position: relative;
    top: 53vh !important;
    min-width: 6rem;
    min-height: 2.5rem;
  }
  [buttonPurpose=mfaContinue] 
  {
    position: relative;
    top: 53vh !important;
    min-width: 7rem;
    min-height: 2.5rem;
  }
}
.modalButton{
  margin-top: 3rem;
}
@media (max-width: 767px) {
  .modalButton{
    margin-top: 0rem;
  }
}


`;
