import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`

.buttonDropDown.dropdown {
  left: unset;
  background-color: unset;
  box-shadow: unset;
  z-index: auto;
   color:  ${({ theme }) =>
     theme.atoms &&
     theme.atoms.buttonDropdown &&
     theme.atoms.buttonDropdown.buttonDropDownItemColor};
}

.dropdown-item {
  color:  ${({ theme }) =>
    theme.atoms &&
    theme.atoms.buttonDropdown &&
    theme.atoms.buttonDropdown.buttonDropDownItemColor};
}

.dropdown-item:active {
  color:  ${({ theme }) =>
    theme.atoms &&
    theme.atoms.buttonDropdown &&
    theme.atoms.buttonDropdown.buttonDropDownItemColor};
}

.buttonDropDown .dropdown-item {
 color:  ${({ theme }) =>
   theme.atoms && theme.atoms.buttonDropdown && theme.atoms.buttonDropdown.buttonDropDownItemColor};
}

.buttonDropDown .dropdown-menu.show {
  box-shadow: rgb(0 123 255 / 50%) 0px 0px 0px 0.2rem;
}
`;