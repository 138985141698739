import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'buttonStates',
  initialState: {
    disable: false
  },
  reducers: {
    //disable continue button 
    CONTINUE_BUTTON_STATE: (state, action) => {
        state.disable= action.payload
    }
  },
});

export const { 
  CONTINUE_BUTTON_STATE
} = slice.actions;

export default slice.reducer;
