import React, { Suspense, useEffect, useState } from 'react';
import FormGenerator from '../../../Cells/Form/FormGenerator';
import { useDispatch, useSelector } from 'react-redux';
import { callConstants } from '../../../../Store/Slices/Entities/constants/index';
import { constantsList } from '../../../../Common/Constants/index';
import './styles.css'

import {
  APIRequestParallel,
} from '../../../../Store/Slices/Entities/response/index';
import { reduxConstants } from '../../../../Common/Constants/index';

const ManageProfile = (props) => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, SetContent] = useState({});
  const [layoutData, setLayoutData] = useState({});
  const [displayContent, setDisplayContent] = useState({ DependentInformation: [] });
 
  const dispatch = useDispatch();


  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};

  const dataFromReduxEmploymentInformation = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList
  );

  const contractBuid = useSelector(
    
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.subscribedcontracts?._Result &&
      storeState?.entities?.response?.onLoadResponseList?.subscribedcontracts?._Result[0]?.contract?.buid
  );

  const zipCode = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.PersonalInformation?.personalInformation
        ?.zipCode
  );


  const dataFromRedux = useSelector((storeState) => storeState?.auth?.loggedUserEntity?.data);


  // ******preferred communication********
  const preferredContactFromRedux = useSelector((storeState) => storeState?.employeeProfile?.preferredContactDetails);


  const manageEmploymentInfo = (dataFromReduxEmploymentInformation, dataFromRedux) => {
    let personalDetails = {};

    for (let key in dataFromReduxEmploymentInformation) {
      personalDetails[key] = dataFromReduxEmploymentInformation[key];

      if (key === 'EmploymentInformation') {
        let employee = dataFromRedux?.personDetails?._Result?.[0]?.employee;

        if (employee) {
          let additionalDynamicDatas = employee?.additionalDynamicDatas;
          if (Array.isArray(additionalDynamicDatas) && additionalDynamicDatas.length) {
            let divCode = additionalDynamicDatas.find((el) => el?.value?.fieldName === 'DIVCODE');

            let workLocation = additionalDynamicDatas.find(
              (el) => el?.value?.fieldName === 'LOCCODE'
            );

            let empInfo = {
              ...dataFromReduxEmploymentInformation[key]?.employmentInformation,
              divisionCode: divCode?.value?.fieldValue || '',
              workLocation: workLocation?.value?.fieldValue || '',
            };
            personalDetails[key] = { employmentInformation: empInfo };
          }
        }
      }
    }
    return personalDetails;
  };

  useEffect(() => {
    setLayoutData({
      ...dataFromRedux,
      employeeInformation: dataFromReduxEmploymentInformation,
      // employeeInformation: manageEmploymentInfo(dataFromReduxEmploymentInformation, dataFromRedux),
      preferredContactFromRedux
    });
  }, [
    dataFromRedux,
    dataFromReduxEmploymentInformation,
  ]);


  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => SetContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);

  // useEffect(() => {
  //   /* useEffect to get all constants through API call */
  //   constantsList.forEach((element) => {
  //     dispatch(callConstants(element));
  //   });
  // }, []);
  let testing = 'testing';
  useEffect(() => {
    const callAPI = async () => {
      if (content && content?.layout && content?.layout?.onLoadApiCall) {
        let onLoadCalls = content.layout.onLoadApiCall;

        let data = await dispatch(
          APIRequestParallel(onLoadCalls, {
            additionalInformation: {
              contractBuid,
              zipCode,
              testing,
            },
          })
        );
      }
    };
    try {
      callAPI();
    } catch (error) {
      
    }
  }, [content, loggedUserEntity]);


  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper manageProfile">
          <FormGenerator
            content={content}
            {...props}
            layoutData={layoutData}
            displayContent={displayContent}
          />
        </div>
      </Suspense>
    </div>
  );
};

export default ManageProfile;