import React, { useState, useEffect } from "react";
import "./customtable.css";
import { useDispatch, useSelector } from 'react-redux';
import { WAIVED_OPTIONS } from "../../../Store/Slices/Enrollment/index";
import _ from "lodash";
import { formatDollarAmountWithDecimals } from "../../Utils/Helper/ValidationHelper";
const CustomTable = (props) => {
    const {
        coverageItem,
        index,
        waived,
        declinedProduct,
        waviedTreminated,
        declinedProductTerminated,
        getCoveredMembers,
        checkEnroll,
        notEnrolled,
        coverProductBuid,
        allPetProductWaived,
        isPlandesignNotPresent,
        isWLProduct,
        wLStatus
    } = props;

    const configDataRedux = useSelector(
        (storeState) => storeState?.entities?.response?.onLoadResponseList?.productConfiguration
            ?._Result
    );
    let hasConfig = false
    if (configDataRedux?.premiumFrequency?.isEditable) {
        hasConfig = true
    }
    let insuredPersons = getCoveredMembers?.filter((item) => {
        return item[0] === coverProductBuid;
    })
    let coveredInsured = insuredPersons?.map((item) => {
        return item?.filter((el) => el !== coverProductBuid)

    })

    let coverdInsuredMember = coveredInsured?.flat();
    if (coverdInsuredMember && coverdInsuredMember.length) {
        coverdInsuredMember = _.uniqBy(coverdInsuredMember, "insureRelationShip")
    }
    const [tablecollpse, setTablecollpse] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const handleExpandRow = () => {
        setIsOpen(!isOpen)
    }

    let showAdditionalContent = false
    if (coverageItem?.[0]?.formName === "PetAssureNation" || coverageItem?.[0]?.resource === "Pet Insurance" || coverageItem?.[0]?.formName === "PetAssure" || coverageItem?.[0]?.formName === "PetPlus") {
        showAdditionalContent = true
    }
    // Table header hide pet name
    let hidePetContent = false
    if (coverageItem?.[0]?.formName === "PetAssure" || coverageItem?.[0]?.formName === "PetPlus") {
        hidePetContent = true
    }
    let petFormName = coverageItem?.[0]?.formName === "PetAssureNation" || coverageItem?.[0]?.resource === "Pet Insurance" || coverageItem?.[0]?.formName === "PetAssure" || coverageItem?.[0]?.formName === "PetPlus"
    let allPetCoverageWaived = showAdditionalContent && (allPetProductWaived);
    let otherWaivedCoverage = waived || checkEnroll || waviedTreminated || declinedProductTerminated || declinedProduct || notEnrolled
    let waivedItems = petFormName ? allPetCoverageWaived : otherWaivedCoverage
    let screenReaderText = "Empty Cell"
    useEffect(() => {
        setTablecollpse((waivedItems))
    }, [waivedItems])

    return (
        <div className="table-responsive scrollbar col-md-12 d-md-block d-lg-block d-xl-block ">
            {!tablecollpse && (
                <table className="additionalColor table atomTable">
                    <thead>
                        <tr style={{ backgroundColor: "#0065AC" }}>
                            <th
                                className="header-no-wrap colHeader col-5 col-md-6 col-lg-5"
                                scope="col"
                                style={{ textTransform: "uppercase", color: "#FFFFFF" }}
                            >
                                COVERAGE OPTION
                            </th>


                            {showAdditionalContent && <th
                                className={`header-no-wrap colHeader col-5 col-md-6 col-lg-2 ${hidePetContent ? 'visually-hidden' : ''} `}
                                scope="col"
                                style={{ textTransform: "uppercase", color: "#FFFFFF" }}
                            >
                                {!hidePetContent ? 'PET NAME' : screenReaderText}
                            </th>}



                            <th
                                className="header-no-wrap colHeader col-4 col-md-4 text-right col-lg-3 "
                                scope="col"
                                style={{ textTransform: "uppercase", color: "#FFFFFF" }}
                            >  {hasConfig ? configDataRedux?.premiumFrequency?.label : `Cost Per Pay Period`}</th>
                            <th
                                className="header-no-wrap colHeader col-4 col-md-2 text-right col-lg-2"
                                scope="col"
                                style={{ textTransform: "uppercase", color: "#FFFFFF" }}
                            >
                                ANNUAL COST

                            </th>
                        </tr>
                    </thead>
                    <tbody className="">


                        {coverageItem?.length > 0 &&
                            coverageItem?.map((cov, index) => {
                                return (
                                    (!cov?.waived) && (
                                        <tr key={index}>
                                            {!showAdditionalContent && <td className="table-data-no-wrap text-wrap">{isPlandesignNotPresent ? <b className="text-danger">{cov.coverageLevel}</b> : cov.coverageLevel}</td>}
                                            {showAdditionalContent && <td className="table-data-no-wrap text-wrap">{cov.coverageLevel}</td>}
                                            {showAdditionalContent && <td className="table-data-no-wrap text-wrap">{!hidePetContent ? (cov.petName == "" ? <span style={{ color: '#A61003' }}>Please delete this empty record</span> : cov.petName) : ''}</td>}
                                            <td className="table-data-no-wrap text-right text-wrap font-weight-bold">{formatDollarAmountWithDecimals(cov.costPerPayPeriod)}</td>
                                            <td className="able-data-no-wrap text-right text-wrap font-weight-bold">{formatDollarAmountWithDecimals(cov.anualCost)}</td>
                                        </tr>
                                    )
                                )
                            })
                        }




                        {!showAdditionalContent && <tr>
                            <td
                                colSpan={4}

                            >
                                {isOpen && (
                                    <div>
                                        <div className="covered-members">Covered Members</div>
                                        {coverdInsuredMember?.map((member, index) => {
                                            return (
                                                <div key={member.index}>
                                                    <p>{member.insureRelationShip}</p>
                                                </div>
                                            )
                                        })}
                                       
                                    </div>
                                )}
                                {/* This id is used in confirmation screen to expand during print */}
                                <a className="view-details" id={`to-show-more-details-${index}`} onClick={handleExpandRow}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === 'Space') {
                                            e.preventDefault();
                                            handleExpandRow();
                                        }
                                    }}
                                    tabIndex={0}
                                >
                                    <span aria-hidden="true">{isOpen ? 'Hide Details' : 'View Details '}</span>
                                </a>
                                { (isWLProduct && wLStatus && isOpen) && <span className="wl-status" aria-hidden="true">Status: {wLStatus}</span>}
                            </td>
                        </tr>}

                    </tbody >
                </table >
            )}



        </div >
    );
};

export default CustomTable;
