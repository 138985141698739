// Action to validate subscribed product data


import axios from 'axios';
import http from '../../../../Common/Services/HttpService';
import appConfig from '../../../../appConfig';
import { onLoadProductAPI } from '../../../../Store/Slices/Entities/response/index.js'; 
import { UPDATE_LOADING, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import {
    UPDATE_ENROLLMENT_DATA
  } from '../../../../Store/Slices/Enrollment/index';

export const validateSubscribedProductData = (params) => async dispatch => {

    // Make API request to validate subscribed product data
    dispatch(UPDATE_LOADING(true));
    const {
        enrollmentProcessBuid, 
        productIdentifierBuid, 
        currentStep, 
        subscribedElements, 
        expectReturn = false, 
        currentStepInfo
    } = params;

    try{

    await new Promise(resolve => setTimeout(resolve, 1000));
    const response = await http.post(appConfig.SSP_MID_URL + '/validateSubscribedProductData', {
        payload: {
            enrollmentProcessBuid: enrollmentProcessBuid,
            productIdentifierBuid: productIdentifierBuid,
            subscribedProductBuid: subscribedElements,
            currentStepInfo
        },
      });
    // If response is successful, update the state
    if (response && response.data) {
        // Format the new data
        const currentStepData = {
            [currentStep]: {
                ValidateSubscribedProductData: response.data
            }
        };

        const processInfo = response?.data?._Result ?? {};
        dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
        // Dispatch an action to update the state with the new data
        dispatch(onLoadProductAPI({data : currentStepData, currentStep , isAPICallInProgress: false  }));        
        if(expectReturn) {
            return true
        }
    } else {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: response?.status || '520',
            id: 'validateSubscribedProductData',
            show: false,
            page: currentStep
          }));
    }
    
    if(expectReturn) {
        return false
    }
} catch(e) {    
    dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: '520',
        id: 'validateSubscribedProductData',
        show: false,
        page: currentStep
      }));
      return false;
} finally {
    dispatch(UPDATE_LOADING(false));
}
};