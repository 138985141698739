import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'registeration',
  initialState: {
    editEmail: true,
    editPhone: true,
    shouldClearInput: false,
    clearCode: false,
    email: '',
    phone: '',
    emailVerified: false,
    phoneVerified: false,
    codeValue: '',
    emailError: false,
    emailBeReadOnly: false,
    phoneBeReadOnly: false,
    elementToCheck: '',
    elementHasError: false,
    hideVerifyButton: false,
    hideVerifyEmailButton: false,
    hideVerifyPhoneButton: false,
    disableVerifyEmailButton: 'UserAccountVerifyEmail.verifyEmail',
    emailError: false,

    displaySubmitCodeBtn: false,
    showSubmitCodeBtnPhone: false,
    emailCodeSubmitted: false,
    phoneCodeSubmitted: false,
    showSendCodeBtnPhone: true,
    hideAnotherMethod: true,
    showSubmitCodeBtnEmail: false,
    changeMethodClicked:false,
    sendPhoneClicked: false,
    loginOtpMethod: 'email',
    preferredChannel: 'email',
    emailEntered: false,
    loginOtpMethodRetrieveUser: 'call',
    userEmail:'',
    employeeInfo:{},
    resetPasswordSource:'',

  },
  reducers: {
    // action => action handler
    EDIT_EMAIL: (registeration, action) => {
      return {
        ...registeration,
        email: action.payload.email,
        editEmail: action.payload.editEmail,
        //inputBeReadOnly: true,
        emailVerified: action.payload.emailVerified,
        shouldClearInput: action.payload.shouldClearInput,
        clearCode: action.payload.clearCode,
      };
    },
    EDIT_PHONE: (registeration, action) => {
      return {
        ...registeration,
        editPhone: action.payload.editPhone,
        shouldClearInput: action.payload.shouldClearInput,
        clearCode: action.payload.clearCode,
      };
    },
    CLEAR_INPUT: (registeration, action) => {
      return {
        ...registeration,
        shouldClearInput: action.payload.shouldClearInput,
      };
    },
    ADD_EMAIL: (registeration, action) => {
      return {
        ...registeration,
        email: action.payload.email,
        inputBeReadOnly: true,
        editEmail: false,
      };
    },
    ADD_PHONE: (registeration, action) => {
      return {
        ...registeration,
        phone: action.payload,
      };
    },

    ADD_PHONE_VERIFICATION_METHOD: (registeration, action) => {
      return {
        ...registeration,
        phoneVerificationMethod: action.payload,
      };
    },
    CODE_VALUE: (registeration, action) => {
      return {
        ...registeration,
        codeValue: action.payload,
      };
    },
    EMAIL_ERROR: (registeration, action) => {
      return {
        ...registeration,
        emailError: action.payload,
      };
    },
    EMAIL_BE_READ_ONLY: (registeration, action) => {
      return {
        ...registeration,
        emailBeReadOnly: action.payload,
        editEmail: false,
      };
    },
    PHONE_BE_READ_ONLY: (registeration, action) => {
      return {
        ...registeration,
        phoneBeReadOnly: action.payload,
        editPhone: false,
      };
    },
    EDIT_EMAIL_VALUE: (registeration, action) => {
      return {
        ...registeration,
        shouldClearInput: false,
        clearCode: '',
        editEmail: true,
      };
    },
    EDIT_ELEMENT_ERROR: (registeration, action) => {
      return {
        ...registeration,
        elementToCheck: action.payload.elementToCheck,
        elementHasError: action.payload.elementHasError,
      };
    },
    HIDE_VERIFY_BUTTON: (registeration, action) => {
      registeration.hideVerifyButton = action.payload;
    },
    HIDE_VERIFY_EBUTTON: (registeration, action) => {
      registeration.hideVerifyEmailButton = action.payload;
    },
    HIDE_SEND_CODE_BUTTON: (registeration, action) => {
      registeration.hideSendCodeButton = action.payload;
    },
    DISABLE_VERIFY_BUTTON: (registeration, action) => {
      registeration.hideVerifyButton = action.payload;
    },
    HIDE_VERIFY_EMAIL_BUTTON: (registeration, action) => {
      registeration.disableVerifyEmailButton = action.payload;
    },
    CLEAR_REGISTERATION: (registeration, action) => {
      return {
        ...registeration,
        email: action.payload.email,
        phone: action.payload.phone,
      };
    },
    DISABLE_CODE_SUBMIT_BTN: (registeration, action) => {
      return {
        ...registeration,
        disableCodeSubmitBtn: action.payload.disableCodeSubmitBtn,
      };
    },
    DISPLAY_EDIT_PHONE_ICON: (registeration, action) => {
      return {
        ...registeration,
        displayEditPhoneIcon: action.payload.displayEditPhoneIcon,
      };
    },
    HIDE_ERR_MSG: (registeration, action) => {
      return {
        ...registeration,
        hideErrMsg: action.payload.hideErrMsg,
      };
    },
    DISPLAY_SUBMIT_CODE_BTN: (registeration, action) => {
      return {
        ...registeration,
        displaySubmitCodeBtn: action.payload.displaySubmitCodeBtn,
      };
    },
    SET_EMAIL_CODE_SUBMIT: (registeration, action) => {
      return {
        ...registeration,
        emailCodeSubmitted: action.payload.emailCodeSubmitted,
      };
    },
    SET_PHONE_CODE_SUBMIT: (registeration, action) => {
      return {
        ...registeration,
        phoneCodeSubmitted: action.payload.phoneCodeSubmitted,
      };
    },
    HIDE_ANOTHER_METHOD: (registeration, action) => {
      return {
        ...registeration,
        hideAnotherMethod: action.payload,
      };
    },
    SET_MFA_API_PARAM: (registeration, action) => {
      return {
        ...registeration,
        legalEntityId: action.payload.legalEntityId,
        appCode: action.payload.appCode,
      };
    },
    SET_CALL_CENTER_HR: (registeration, action) => {
      return {
        ...registeration,
        callcenterHR: action.payload.callcenterHR,
        appCode: action.payload.appCode,
      };
    },

    SET_MFA_TOKEN: (registeration, action) => {
      return {
        ...registeration,
        token: action.payload.token,
      };
    },
    SET_LOGIN_OTP: (registeration, action) => {
      return {
        ...registeration,
        otp: action.payload.otp,
      };
    },
    SET_MFA_BASIC_AUTH_VALUE: (registeration, action) => {
      return {
        ...registeration,
        basicAuthorizationValue: action.payload,
      };
    },
    SHOW_SUBMIT_CODE_BTN: (registeration, action) => {
      return {
        ...registeration,
        showSubmitCodeBtnEmail: action.payload.showSubmitCodeBtnEmail,
        showSubmitCodeBtnPhone: action.payload.showSubmitCodeBtnPhone,
      };
    },
    SHOW_PHONE_SUBMIT_CODE_BTN: (registeration, action) => {
      return {
        ...registeration,
        showSubmitCodeBtnEmail: action.payload.showSubmitCodeBtnEmail,
        showSubmitCodeBtnPhone: action.payload.showSubmitCodeBtnPhone,
      };
    },
    SHOW_SEND_CODE_BTN: (registeration, action) => {
      return {
        ...registeration,
        showSendCodeBtnEmail: action.payload.showSendCodeBtnEmail,
        showSendCodeBtnPhone: action.payload.showSendCodeBtnPhone,
      };
    },
    SET_SUCCESS_EMAIL_CODE: (registeration, action) => {
      return {
        ...registeration,
        successEmailCode: action.payload.successEmailCode,
        emailCodeEntered: action.payload.emailCodeEntered,
      };
    },
    SET_PASSWORD: (registeration, action) => {
      return {
        ...registeration,
        password: action.payload.password,
      };
    },
    SET_REENTER_PASSWORD: (registeration, action) => {
      return {
        ...registeration,
        reenterPassword: action.payload.reenterPassword,
      };
    },
    SET_EMAIL_VERFIED: (registeration, action) => {
      return {
        ...registeration,
        emailVerified: action.payload,
      };
    },
    SET_PHONE_VERFIED: (registeration, action) => {
      return {
        ...registeration,
        phoneVerified: action.payload,
      };
    },
    SET_EMAIL_CODE: (registeration, action) => {
      return {
        ...registeration,
        emailCode: action.payload,
      };
    },
    SET_EMAIL_CODE_ERR: (registeration, action) => {
      return {
        ...registeration,
        emailCodeErr: action.payload,
      };
    },
    VERIFIED_EMAIL_CLICKED: (registeration, action) => {
      return {
        ...registeration,
        verifyEmailClicked: action.payload,
      };
    },
    SUBMIT_EMAIL_CLICKED: (registeration, action) => {
      return {
        ...registeration,
        submitEmailClicked: action.payload,
      };
    },
    SEND_CODE_PHONE_CLICKED: (registeration, action) => {
      return {
        ...registeration,
        sendPhoneClicked: action.payload,
      };
    },
    SUBMIT_PHONE_CLICKED: (registeration, action) => {
      return {
        ...registeration,
        submitPhoneClicked: action.payload,
      };
    },
    CHANGE_METHOD_CLICKED: (registeration, action) => {
      return {
        ...registeration,
        changeMethodClicked: action.payload,
      };
    },
    SET_MFA_LOGIN: (registeration, action) => {
      return {
        ...registeration,
        decryptedEmail: action.payload.decryptedEmail,
        email: action.payload.email,
      };
    },
    SET_MFA_LOGIN_TOKEN: (registeration, action) => {
      return {
        ...registeration,
        mfaLoginToken: action.payload,
      };
    },
    SET_OTP_ERROR: (registeration, action) => {
      return {
        ...registeration,
        otpError: action.payload
      };
    },
    SET_PHONE_OTP_ERROR: (registeration, action) => {
      return {
        ...registeration,
        phoneOtpError: action.payload
      };
    },
    SET_PHONE_CODE_ERR: (registeration, action) => {
      return {
        ...registeration,
        phoneCodeErr: action.payload,
      };
    },
    SHOW_REGISTER_TIMER_PHONE: (registeration, action) => {
      return {
        ...registeration,
        showTimerPhone: action.payload,
      };
    },
    SET_USER_TYPE_LANGUAGE: (registeration, action) => {
      return {
        ...registeration,
        userType: action.payload.userType,
        language: action.payload.language,
      };
    },
    SHOW_REGISTER_TIMER: (registeration, action) => {
      return {
        ...registeration,
        showTimer: action.payload,
      };
    },
    SET_MFA_PRIMARY_LOGIN_PAYLOAD: (registeration, action) => {
      return {
        ...registeration,
        login: action.payload.login,
        password: action.payload.password,
      };
    },
    SET_MFA_RETRIEVE_LOGIN: (registeration, action) => {
      return {
        ...registeration,
        email: action.payload,
      };
    },
    CONTINUE_BTN_CLICKED: (registeration, action) => {
      return {
        ...registeration,
        tryAnotherMothod: action.payload.tryAnotherMothod,
        currentStep: action.payload.currentStep,
      };
    },
    SET_MFA_MASKED_LOGIN: (registeration, action) => {
      return {
        ...registeration,
        maskedEmail: action.payload,
      };
    },
    EDIT_EMAIL_CLICKED: (registeration, action) => {
      return {
        ...registeration,
        editEmailClicked: action.payload,
        emailVerified: false,
        verifyEmailClicked: !action.payload,

      };
    },
    SHOW_EMAIL_OTP: (registeration, action) => {
      return {
        ...registeration,
        showEmailOtp: action.payload,
      };
    },
    SHOW_PHONE_OTP: (registeration, action) => {
      return {
        ...registeration,
        showPhoneOtp: action.payload,
      };
    },
    DISABLE_CONTINUE_BTN: (registeration, action) => {
      return {
        ...registeration,
        disableContinueBtn: action.payload,
      };
    },
    HIDE_MSG: (registeration, action) => {
      return {
        ...registeration,
        hideMsg: action.payload,
      };
    },
    SET_MFA_URL: (registeration, action) => {
      return {
        ...registeration,
        url: action.payload,
      };
    },
    RESET_REGISTERATION: (registeration, action) => {
      return {
        emailError: false,
        emailBeReadOnly: false,
        phoneBeReadOnly: false,
        hideVerifyButton: false,
        hideVerifyEmailButton: false,
        hideSendCodeButton: false,
        disableVerifyEmailButton: false,
        displaySubmitCodeBtn: false,
        showSubmitCodeBtnPhone: false,
        emailCodeSubmitted: false,
        phoneCodeSubmitted: false,
        showSendCodeBtnPhone: true,
        hideAnotherMethod: true,
        emailVerified: false,
        phoneVerified: false,
        loginOtpMethod: 'email',
        preferredChannel: 'email',
        emailEntered: false
      };
    },
    NEW_REGISTERATION: (registeration) => {
      return {
        editEmail:true,
        editPhone:true,
        shouldClearInput:false,
        clearCode:false,
        email:"",
        phone:"",
        phoneVerified:false,
        codeValue:"",
        emailError:false,
        emailBeReadOnly:false,
        phoneBeReadOnly:false,
        elementToCheck:"",
        elementHasError:false,
        hideVerifyButton:false,
        hideVerifyEmailButton:false,
        hideVerifyPhoneButton:false,
        displaySubmitCodeBtn:false,
        showSubmitCodeBtnPhone:false,
        emailCodeSubmitted:false,
        phoneCodeSubmitted:false,
        showSendCodeBtnPhone:true,
        hideAnotherMethod:true,
        showSubmitCodeBtnEmail:false,
        changeMethodClicked:false,
        sendPhoneClicked:false,
        loginOtpMethod: 'email',
        preferredChannel: 'email',
        emailEntered: false,
        loginOtpMethodRetrieveUser: 'sms',
      }
    },
    SET_NEW_EMAIL: (registeration, action) => {
      return {
        ...registeration,
        newEmail: action.payload.newEmail,
      };
    },
    SET_NEW_MOBILE: (registeration, action) => {
      return {
        ...registeration,
        newMobile: action.payload.newMobile,
      };
    },
    SET_TEMP_LOGIN_OTP_METHOD: (registeration, action) => {
      return {
        ...registeration,
        // tempLoginOtpMethod: action.payload,
        // tempLoginOtpMethodBackup: action.payload,
        tempLoginOtpMethod: action.payload,
        tempLoginOtpMethodBackup: action.payload,
        //tempLoginOtpMethodBackup: registeration.tempLoginOtpMethod,
        //preferredChannel: action.payload
      };
    },
    SET_LOGIN_OTP_METHOD: (registeration, action) => {
      return {
        ...registeration,
        loginOtpMethod: action.payload,
        //preferredChannel: action.payload,
        loginOtpMethod: action.payload,
        preferredChannel: action.payload
      };
    },
    SET_RESET_PASSWORD_EMAIL: (registeration, action) => {
      return {
        ...registeration,
        resetPassowrdEmail: action.payload,
      };
    },
    SET_PREFERRED_CHANNEL: (registeration, action) => {
      return {
        ...registeration,
        preferredChannel: action.payload,
        loginOtpMethodRetrieveUser: action.payload
      };
    },
    CLEAR_PASSWORD: (registeration, action) => {
      return {
        ...registeration,
        clearPassword: action.payload,
      };
    },
    SET_PASSWORD_FAILED: (registeration, action) => {
      return {
        ...registeration,
        setPasswordFailed: action.payload,
      };
    },
    SET_PASSWORD_API: (registeration, action) => {
      return {
        ...registeration,
        passwordAPI: action.payload,
      };
    },
    CLEAR_RE_PASSWORD: (registeration, action) => {
      return {
        ...registeration,
        clearRePassword: action.payload,
      };
    },
    TRIGGER_INPUT_ERROR: (registeration, action) => {
      return {
        ...registeration,
        triggeredInputError: action.payload,
      };
    },
    MATCH_LOGIN_TO_PREFERRED: (registeration, action) => {
      return {
        ...registeration,
        loginOtpMethod: registeration.preferredChannel,
      };
    },
    SET_MFA_NEW_LOGIN: (registeration, action) => {
      return {
        ...registeration,
        login: action.payload.login,
      }
    },
    EMAIL_ENTERED: (registeration, action) => {
      return {
        ...registeration,
        emailEntered: action.payload,
      };
    },
    SET_LOGIN_OTP_METHOD_RETRIEVE_USER: (registeration, action) => {
      return {
        ...registeration,
        loginOtpMethodRetrieveUser: action.payload,
        //tempLoginOtpMethod: '',
      };
    },
    RESET_TEMP_LOGIN_OTP_METHOD: (registeration, action) => {
      return {
        ...registeration,
        tempLoginOtpMethod: registeration.tempLoginOtpMethodBackup,
      };
    },
    SET_MFA_CHANNEL: (registeration, action) => {
      return {
        ...registeration,
        mfaChannel: action.payload,
      };
    },
    SET_PHONE_FROM_OKTA: (registeration, action) => {
      return {
        ...registeration,
        phoneNumberFromOkta: action.payload,
      };
    },
    SET_PERSON_BUID_FROM_AUTH_MODULE: (registeration, action) => {
      return {
        ...registeration,
        personBuidFromAuthModule: action.payload,
      };
    },
    SET_TOLL_NUMBER_FROM_AUTH_MODULE: (registeration, action) => {
      return {
        ...registeration,
        tollNumberFromAuthModule: action.payload,
      };
    },
    PASTE_PHONE: (registeration, action) => {
      return {
        ...registeration,
        isPastePhone: action.payload,
      };
    },
    PASTE_SSN: (registeration, action) => {
      return {
        ...registeration,
        isPasteSSN: action.payload,
      };
    },
    ADD_SSN: (registeration, action) => {
      return {
        ...registeration,
        ssn: action.payload,
      };
    },
    SAVE_EMPLOYEE_INFO: (registeration, action) => {
      return {
        ...registeration,
        employeeInfo: action.payload,
      };
    },
    GET_EMAIL: (registeration, action) => {
      return {
        ...registeration,
        userEmail: action.payload,
      };
    },
    RESET_PASSWORD_SOURCE: (registeration, action) => {
      return {
        ...registeration,
        resetPasswordSource: action.payload,
      };
    },
  },
});

export const {
  EDIT_EMAIL,
  EDIT_PHONE,
  CLEAR_INPUT,
  ADD_EMAIL,
  ADD_PHONE,
  CODE_VALUE,
  EMAIL_ERROR,
  DISABLE_VERIFY_BUTTON,
  HIDE_VERIFY_EBUTTON,
  HIDE_VERIFY_BUTTON,
  HIDE_VERIFY_EMAIL_BUTTON,
  EDIT_EMAIL_VALUE,
  EMAIL_BE_READ_ONLY,
  PHONE_BE_READ_ONLY,
  EDIT_ELEMENT_ERROR,
  CLEAR_REGISTERATION,
  DISABLE_CODE_SUBMIT_BTN,
  DISPLAY_EDIT_PHONE_ICON,
  DISPLAY_SUBMIT_CODE_BTN,
  SET_EMAIL_CODE_SUBMIT,
  SET_PHONE_CODE_SUBMIT,
  HIDE_SEND_CODE_BUTTON,
  HIDE_ANOTHER_METHOD,
  SET_MFA_API_PARAM,
  SHOW_SUBMIT_CODE_BTN,
  SHOW_SEND_CODE_BTN,
  RESET_REGISTERATION,
  SET_PASSWORD,
  SET_REENTER_PASSWORD,
  SHOW_PHONE_SUBMIT_CODE_BTN,
  SET_MFA_BASIC_AUTH_VALUE,
  ADD_PHONE_VERIFICATION_METHOD,
  SET_SUCCESS_EMAIL_CODE,
  HIDE_ERR_MSG,
  SET_EMAIL_VERFIED,
  SET_PHONE_VERFIED,
  SET_EMAIL_CODE,
  SET_EMAIL_CODE_ERR,
  VERIFIED_EMAIL_CLICKED,
  SUBMIT_EMAIL_CLICKED,
  SEND_CODE_PHONE_CLICKED,
  SUBMIT_PHONE_CLICKED,
  CHANGE_METHOD_CLICKED,
  SET_LOGIN_OTP,
  SET_MFA_TOKEN,
  SET_MFA_LOGIN,
  SET_MFA_LOGIN_TOKEN,
  SET_OTP_ERROR,
  SHOW_REGISTER_TIMER_PHONE,
  SET_USER_TYPE_LANGUAGE,
  SHOW_REGISTER_TIMER,
  SET_MFA_PRIMARY_LOGIN_PAYLOAD,
  SET_MFA_RETRIEVE_LOGIN,
  CONTINUE_BTN_CLICKED,
  SET_MFA_MASKED_LOGIN,
  EDIT_EMAIL_CLICKED,
  SHOW_EMAIL_OTP,
  SHOW_PHONE_OTP,
  DISABLE_CONTINUE_BTN,
  HIDE_MSG,
  SET_MFA_URL,
  SET_PHONE_CODE_ERR,
  SET_PHONE_OTP_ERROR,
  NEW_REGISTERATION,
  SET_NEW_EMAIL,
  SET_NEW_MOBILE,
  SET_LOGIN_OTP_METHOD,
  SET_TEMP_LOGIN_OTP_METHOD,
  SET_RESET_PASSWORD_EMAIL,
  SET_PREFERRED_CHANNEL,
  CLEAR_PASSWORD,
  SET_PASSWORD_FAILED,
  SET_PASSWORD_API,
  CLEAR_RE_PASSWORD,
  TRIGGER_INPUT_ERROR,
  MATCH_LOGIN_TO_PREFERRED,
  SET_MFA_NEW_LOGIN,
  EMAIL_ENTERED,
  SET_LOGIN_OTP_METHOD_RETRIEVE_USER,
  RESET_TEMP_LOGIN_OTP_METHOD,
  SET_MFA_CHANNEL,
  SET_PHONE_FROM_OKTA,
  SET_PERSON_BUID_FROM_AUTH_MODULE,
  SET_TOLL_NUMBER_FROM_AUTH_MODULE,
  PASTE_PHONE,
  PASTE_SSN,
  ADD_SSN,
  SAVE_EMPLOYEE_INFO,
  GET_EMAIL,
  RESET_PASSWORD_SOURCE,
  SET_CALL_CENTER_HR
} = slice.actions;

export default slice.reducer;