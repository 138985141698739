import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Timer from '../Timer';
import { useDispatch, useSelector } from 'react-redux';

const Linker = (props) => {
  const {
    to,
    navigation,
    target,
    hideElement,
    moveNextStep,
    onClick,
    className,
    children,
    style,
    content,
    contentData,
    skipSteps,
    skipAction,
    stepKeys,
    goForward,
    name,
    currentStep,
    formik,
    ...rest
  } = props;
  const { formData, goBack, cancel, steps } = contentData;

  // let classes = disabled ? `elementDisabled ${className}` : className;
  let additionalClasses = hideElement ? 'hideElement' : '';

  // const { contentData, handleSubmit, currentStep, formik,contentData } = props;
  // const { formData, goForward, goBack, cancel, steps, stepKeys } = contentData;

  const handleClick = (event) => {
  
    if (props.parentButtonAction) {
  
      props.parentButtonAction(event, props.element);
    }
    event.stopPropagation();

    if (props.callback) {
    }
    onClick && onClick(event);

    <Timer style={style} value="10" />;
  };


 
  let navigationCheck = navigation ? navigation : true;
  

  const dispatch = useDispatch();
  const { formValues } = useSelector((storeState) => storeState.entities.response);
  return (
    // <div className={additionalClasses}>
      <Link to={to} className={className} style={style} target={target} onClick={ handleClick }>
        {children}
      </Link>
    // </div>
  );
};
Linker.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,

  content: PropTypes.object,
  currentStep: PropTypes.number,
  skipSteps: PropTypes.array,
  stepKeys: PropTypes.array,

  skipAction: PropTypes.bool,
  formik: PropTypes.object,
  contentData: PropTypes.object,
  onClick: PropTypes.func,
  goForward: PropTypes.func,
};
Linker.defaultProps = {
  to: '',
  className: '',

  content: {},
  currentStep: 0,
  skipSteps: [],
  stepKeys: [],

  skipAction: false,
  formik: {},
  contentData: {},
};

export default Linker;
