import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { HIDE_CONTINUE_BUTTON, HIDE_BACK_BUTTON, PREVIOUS_STEP } from '../../../../Store/Slices/StepForm';

import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import { Styles } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StepSubContent = ({ item, status, onClick }) => {
  if (item.displayName) {
    const className = item.clickable && `link sub-step-${status}`;
    return (
      <div className={`sub-content_displayname ${className}`} onClick={onClick} id={item.stepKey}>
        <div>{item.displayName}</div>
        {item.stepDetails && (item.stepDetails.map((detail, index) => (
          <div className="detail" key={index}>
            <div className='coverage-name'>{detail.title}</div>
            <div className='coverage-info'>{detail.info}</div>
          </div>
        )))}
      </div>
    )
  } else {
    return null;
  }
}

const StepperNavigation = (props) => {
  const { content, stepKeys, goBackStep, checkStatus } = props;
  const [navigationContent, SetNavigationContent] = useState({});
  const [showMore, setShowMore] = useState({});
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const currentStep = useSelector((storeState) => storeState.stepForm.currentStep);
  const enrollmentType = useSelector((storeState) => storeState?.enrollment?.enrollmentType);
  const { stepContent } = useSelector((storeState) => storeState.stepForm)
  const dispatch = useDispatch();
  useEffect(() => {
    SetNavigationContent(content)
  }, [currentLanguage, content]);

  const onShowMore = (e, step) => {
    e.preventDefault();
    setShowMore({ ...showMore, [step]: !showMore[step] })
  }

  useEffect(() => {
    // Check your condition here
    if (enrollmentType == "openEnrollment") {
      // If the condition is met, navigate to the last step
      const lastIndex = stepKeys.length - 1;
      goBackStep(lastIndex);
    }
  }, []);

  let index = 0
  let steps = stepKeys.reduce((res, key) => {
    if (content.forms[key]?.productFamily) {
      res.push({
        key,
        hidden: true,
        stepIndex: stepKeys.indexOf(key)
      })
    } else {
      res.push({ key, index: ++index, disableClick: content.forms[key].disableClick })
    }
    return res;
  }, []);

  if (enrollmentType && enrollmentType === "openEnrollment") {
    let filteredSteps = steps.filter(step => step && step.key === "EnrollmentSummary");
    // Modify the stepIndex of filteredSteps to 1
    if (filteredSteps && filteredSteps.length > 0) {
      filteredSteps = filteredSteps.map(step => step ? { ...step, index: 1 } : null);
      steps = filteredSteps;
    }
  }

  const navigateToStep = (step, skipHideFormButton) => {
    if (!skipHideFormButton) {
      dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
      dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
    }
    if (currentStep === 'EnrollmentSummary') {
      dispatch(PREVIOUS_STEP({ previousStep: currentStep }));
    } else {
      dispatch(PREVIOUS_STEP({ previousStep: '' }));
    }
    if (step === 'ProductSelection') {
      dispatch(PREVIOUS_STEP({ previousStep: currentStep }));
    }


    if (step === 'ProductDetailsSelection') {
      goBackStep(stepKeys.indexOf(step) + 1, stepKeys);
    } else {
      goBackStep(stepKeys.indexOf(step), stepKeys);
    }
  }

  const onSubContentClick = (stepIndex, content) => {
    const index = stepKeys?.indexOf(content?.stepKey)
    if (content.clickable && content?.stepKey && index !== -1) {
      navigateToStep(content.stepKey, true);
    }
  }

  return (
    <React.Fragment>
      <Styles />
      <ul>
        {steps?.map((step) => {
          if (step.hidden) return null;
          const stepStatus = checkStatus(step.key, stepKeys);
          const isSubStepStepInprogress = step.key === 'ProductDetailsSelection' && stepContent[step.key]?.some(item => checkStatus(item.stepKey, stepKeys) === 'current');

          const status = isSubStepStepInprogress ? 'current' : stepStatus
          const iconValue = navigationContent?.statusIcons?.[status];

          const hasMore = stepContent && stepContent[step.key]?.length > 3;
          const subContent = stepContent && stepContent[step.key]?.slice(0, showMore[step.key] ? stepContent[step.key]?.length : 3) || []

          return (
            <li key={step.key} id={step.key} className={status}>
              <div className={`hexagon hexagon-${status}`}>
                {status !== 'complete' &&
                  (<p className={`hexagonText hexagonText-${status}`}>{step.index}</p>)
                }
              </div>
              <a
                onClick={() => !step.disableClick && navigateToStep(step.key)}
                className={`stepper-${status} h-100 ${step.disableClick && 'disabled'}`}
              >
                {content.forms[step.key] && content.forms[step.key].displayName}
                {iconValue && (
                  <FontAwesomeIcon
                    icon={['fas', iconValue]}
                    size="2x"
                    className={`ico ico-${status}`}
                  />
                )}
              </a>
              <div className='stepper-subitems'>
                {stepContent &&
                  subContent.map((item, subIndex) => {
                    const subStepStatus = checkStatus(item.stepKey, stepKeys);
                    return (
                      <StepSubContent
                        key={item.stepKey}
                        item={item}
                        status={subStepStatus}
                        onClick={() => onSubContentClick(subIndex, item)}
                      />
                    )
                  })}
                {hasMore && (showMore[step.key] ? (
                  <a href="#" className="show-more-less" onClick={(e) => onShowMore(e, step.key)}> <b>&and;</b> Show Less</a>
                ) : (
                  <a href="#" className="show-more-less" onClick={(e) => onShowMore(e, step.key)}> <b>&or;</b> Show More</a>
                ))}
              </div>
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
};
StepperNavigation.propTypes = {
  content: PropTypes.object,
  stepKeys: PropTypes.array,
  goBackStep: PropTypes.func,
  checkStatus: PropTypes.func,
};

export default StepperNavigation;
