import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Styles } from './styles';
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = (props) => {
  const {
    onChange = () => {},
    siteKey,
    recaptchaRef,
    isVisible,
  } = props;

  const DELAY = 1500
  let [load, setLoad] = useState(false)
  const extraProps = {};

  if ( !isVisible ) {
    extraProps.size = 'invisible';
  }

  useEffect(()=>{
    setTimeout(()=>{
      setLoad(true)
    }, DELAY)
  }, [])

  return (
    <>
      {load && (
        <>
          <Styles />
          <ReCAPTCHA
          aria-label="recaptcha"
            ref={recaptchaRef}
            sitekey={siteKey}
            onChange={() => onChange()}
            {...extraProps}
          />
        </>
      )}
    </>
  );
};

Recaptcha.propTypes = {
  onChange: PropTypes.func,
  siteKey: PropTypes.string,
  isVisible: PropTypes.bool,
};
Recaptcha.defaultProps = {
    onChange: () => {},
    siteKey: '',
    isVisible: true,
};

export default Recaptcha;
