import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../../Atoms';
// import './Panel.css';
import { Styles } from './styles';

const Panel = (props) => {
  const { className, img, title, body, children, hasDivider, ...rest } = props;
  //Removed bg-white from below line for Global Styles in Panel
  let classes =
    'panel d-flex flex-column align-items-center justify-content-between rounded px-3 py-4 h-100 ';
  if (className) {
    classes += className;
  }

  // let classes =
  //   'panel d-flex flex-column align-items-center justify-content-between rounded px-3 py-4 bg-white h-100 ';
  // if (className) {
  //   classes += className;
  // }

  return (
    <React.Fragment>
      <Styles />
      <div className={classes} {...rest}>
        <div className="d-flex flex-row flex-md-column align-items-md-center justify-content-between">
          <div className="panelTitle">
          <Image
            filename={img}
            alt=""
            label={title}
            classNameWrapper="panelTitle"
            className="panelTitle"
          />
          </div>
          {hasDivider && <div className="d-none d-md-block line" />}
          {/* <p className="panelBody text-left text-md-center ml-3 ml-md-0">{body}</p> */}
          <h2 className="panelBody text-left text-md-center ml-3 ml-md-0">{body}</h2>

        </div>
        {children}
      </div>
    </React.Fragment>
  );
};
Panel.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  title: PropTypes.string,
  body: PropTypes.string,
  hasDivider: PropTypes.bool,
};
Panel.defaultProps = {
  hasDivider: true,
};

export default Panel;
