/*Theming for Table header and Table header color */
import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.atomTable {
  font-size: 0.8125rem;
  border-collapse: separate !important;
  border-spacing: 0;
}

.atomTable thead tr {
  background-color: ${({ theme }) =>
    theme.atoms && theme.atoms.table && theme.atoms.table.headerBgColor};  
}

.atomTable thead tr th {
  border-top: 0.0625rem solid #ccc;
  text-transform: uppercase;
  color: ${({ theme }) => theme.atoms && theme.atoms.table && theme.atoms.table.headerColor};
}

.atomTable td {
  border: none;
  border-bottom: 0.0625rem solid #ccc;
}

.atomTable tr td:first-child,
.atomTable tr th:first-child {
  border-left: 0.0625rem solid #ccc !important;
}

.atomTable tr td:last-child,
.atomTable tr th:last-child {
  border-right: 0.0625rem solid #ccc;
}

.atomTable tr th:first-child {
  border-top-left-radius: 0.25rem;
}

.atomTable tr th:last-child {
  border-top-right-radius: 0.25rem;
}

.atomTable tr:last-child td:last-child {
  border-bottom-right-radius: 0.25rem;
}

.atomTable tr:last-child td:first-child {
  border-bottom-left-radius: 0.25rem;
}

@media (max-width: 767px) {
  .table-mobile-border th,
  .table-mobile-border td {
    border-left: 0.0625rem solid #ccc !important;
    border-right: 0.0625rem solid #ccc !important;
  }
  .table-mobile-border td:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .table-mobile-border th {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .PetAssureNationcol-12 .col-5.text-right{
    text-align: left!important;
  }
  .PetAssureNationcol-12 .col-2.px-0{
    position: absolute;
    right: 0;
    top: 4rem;
    z-index: 1;
  }
  .PetAssureNationcol-12 .mobileTableBorderBottom{
    position: relative;
  }
}

.atomTable td:nth-child(4){
    font-family: 'NotoSansBold';
    // text-align: right;
  }
@media (min-width: 1023px){
  .atomTable td:nth-child(4){
  // padding-right: 1rem;
}


.tempTable td{
  width: 2% !important;
  }

  .tempTable td:nth-child(1){
    width: 5% !important;
    // text-align: right;
  }
  .tempTable td:nth-child(1){
    width: 5% !important;
    // text-align: right;
  }
  


.PetAssureNationcol-12 .atomTable .colHeader:last-of-type,
.PetAssureNationcol-12 .atomTable td:last-of-type{
  text-align: right;
}
.PetAssureNationcol-12 .atomTable td:last-of-type span{
  margin-right: 1rem;
}
.PetAssureNationcol-12 .atomTable .col-lg-1.colHeader:last-of-type{
  max-width:33.33%;
}
.PetAssureNationcol-12 .panel-links{
  padding-bottom: 0.875rem!important;
}

@media (max-width: 992px) {
  .table-tablet-th-border-left {
    border-top-left-radius: 0.25rem;
    border-left: 0.0625rem solid #ccc !important;
  }
  .table-tablet-th-border-right {
    border-top-right-radius: 0.25rem;
    border-right: 0.0625rem solid #ccc !important;
  }
  .table-tablet-td-border-left {
    border-bottom-left-radius: 0.25rem;
    border-left: 0.0625rem solid #ccc !important;
  }
  .table-tablet-td-border-right {
    border-bottom-right-radius: 0.25rem;
    border-right: 0.0625rem solid #ccc !important;
  }
}

.header-no-wrap {
  white-space: nowrap;
}
.custom-style-coverage-selection{
  width:  1rem !important;
}
.table-data-no-wrap {
  white-space: nowrap;
}

.colHeader:hover {
  background-color: ${({ theme }) =>
    theme.atoms && theme.atoms.table && theme.atoms.table.headerHover}; 
  cursor: pointer;
}

.scrollbar {
  overflow-x: auto;
}

.scrollbar::-webkit-scrollbar {
  height: 7px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #ebebeb;
}



.atomCoverageTable  {
  border: 1px solid #DADADA;
  border-radius: 0 0 4px 4px;
  border-collapse: separate;
  border-spacing: 0;
}

.atomCoverageTable td:last-child {
  border-bottom-right-radius: 0.25rem;
  font-family: NotoSansSemiBold;
  font-size: 0.875rem;
  text-align: right;
  border-color: #DADADA;
}
.atomCoverageTableRadio  {
  border-bottom-right-radius: 0.25rem;
  font-family: NotoSansRegular;
  font-size: 0.875rem;
  text-align: center;
  background-color: #F4F4F4;
  border-color: #DADADA;
}
.coverage-data  {
  background-color: #F9F9F9;
  border-color: #DADADA;
}
.tableExpandImg{
  margin: 10px;
}
.tr-coverage-data {
  background-color: #F9F9F9 !important;
  border-color: #DADADA;
}
.additionalColor{
  background-color: #FFFFFF;
}
.tableColorUpdate{
  background-color: #F0FDFF;
}

// .tableColor{
//   background-color: red;
// }
`;
