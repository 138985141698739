import { onLoadAPI } from "../../../../Store/Slices/Entities/response";
import { UPDATE_LOADING, SET_ALERT_MESSAGE } from "../../../../Store/Slices/InsuredInfo";
import ServiceHelper from "../../../Utils/Helper/MappingHelper"

export const addSubscriptionForChoiceForBuyUp = ({payload, addSubscriptionForChoiceForBuyUpExistingData}) => async (dispatch) => {
    const serviceHelper = new ServiceHelper;
    dispatch(UPDATE_LOADING(true));
    try {
        const resp = await serviceHelper.sendApiRequest('/addSubscriptionForChoiceForBuyUp', {
            ...payload
        })

        if (resp.data && resp.status === 200) {
            dispatch(onLoadAPI({
                "addSubscriptionForChoiceForBuyUp": [
                    ...addSubscriptionForChoiceForBuyUpExistingData,
                    {
                        personBuid: payload.coveredPersonsBuid,
                        coverageBuid: payload.coverageBuid,
                        data: resp.data
                    }
                ]
              }))

            return resp
        }
    } catch (e) {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            id: 'addSubscriptionForChoiceForBuyUp',
            show: false,
          }));
        console.error(e)
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}