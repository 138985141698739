import React from 'react';
import { Icon, Linker, Select } from '../../Atoms';
import { SET_EMPLOYEE_DATA } from '../../../Store/Slices/CallCenter';
import { initializeFormsData } from '../../../Store/Slices/StepFormNew';
import classes from './styles.module.css'

const EmployeeListTable = (props) => {
    const { employeesCount,
        paginatedEmployee,
        handleResetUsernamePassword,
        handleProxyEnrollment,
        adminEndorsementModel,
        handleRetrieveUsername,
        isHrCenter,
        maskSSNReadOnly,
        dispatch
    } = props
    return (
        <div className="table-responsive">
            {paginatedEmployee?.length > 0 ? (
                <table className={employeesCount && employeesCount === 1 ? "table mt-5  " : "table "}   >
                    <thead className="headerTableTemp   ">
                        <tr className=' ' style={{ height: '3.8rem' }}>
                            <th style={{ width: '2%' }} aria-hidden="true" scope="col"></th>
                            <th style={{ alignSelf: 'center', width: '10%' }}>EMPLOYEE ID</th>
                            <th style={{ width: '15%' }}>SSN/TIN</th>
                            <th style={{ width: '20%' }}>LAST NAME</th>
                            <th style={{ width: '2rem' }}>FIRST NAME</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedEmployee?.map((data, index) => {
                            return (
                                <tr key={index} className=" " >
                                    <td  >{
                                        <div className='dropright'>
                                            <button
                                                aria-label="dropdownMenuButton"
                                                className="btn btn-link "
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                area-hidden="true"
                                                style={{ cursor: "pointer" }}>
                                                <Icon icon={['fas', 'ellipsis-v']} />
                                            </button>

                                            <div
                                                className="dropdown-menu mt-4"
                                                style={{ boxShadow: "0.5px 0.5px 0.5px 0.5px #76D3FF", cursor: "pointer" }}
                                                aria-labelledby="dropdownMenuButton">
                                                <div
                                                    className="dropdown-item "
                                                    onClick={() => handleResetUsernamePassword(data.buid, data.EmployeeId, data.Status)}
                                                >Reset Username/Password
                                                </div>
                                                <div
                                                    className="dropdown-divider  "
                                                    style={{ borderTopColor: "#76D3FF", backgroundColor: "#76D3FF" }}>
                                                </div>
                                                <div
                                                    className="dropdown-item"
                                                    onClick={() => handleRetrieveUsername(data.buid, data.EmployeeId, data.Status)}
                                                >Retrieve Username
                                                </div>
                                                <div
                                                    className="dropdown-divider  "
                                                    style={{ borderTopColor: "#76D3FF", backgroundColor: "#76D3FF" }}>
                                                </div>
                                                <div className="dropdown-item"  >
                                                    <div
                                                        onClick={() => { handleProxyEnrollment(data.buid, data.EmployeeId, data.FirstName, data.Name, data.Status) }}>Proxy Enrollment
                                                    </div>
                                                </div>
                                                <div
                                                    className="dropdown-divider  "
                                                    style={{ borderTopColor: "#76D3FF", backgroundColor: "#76D3FF" }}>
                                                </div>
                                                <div class="dropdown-item" onClick={() => adminEndorsementModel(data)} >Admin Endorsement</div>
                                            </div>
                                        </div>
                                    }</td>
                                    <td style={{ paddingTop: '1.2rem', textDecorationLine: 'underline' }} >
                                        {isHrCenter ?
                                            <Linker
                                                className={data.EmployeeId ? classes.visuallyshow : classes.visuallyhidden}
                                                to={data.Status === 'esActive' && "/hrcenter/employeeData"}
                                                onClick={() => {
                                                    if (data.Status === 'esActive') {
                                                        dispatch(SET_EMPLOYEE_DATA({ selectedEmployeeData: data }))
                                                        dispatch(initializeFormsData({}));
                                                    }
                                                    else
                                                        $('#employeeNotActive').modal('show')
                                                }}
                                            >{data.EmployeeId}
                                            </Linker> : <Linker
                                                to={data.Status === 'esActive' && "/callcenter/employeeData"}
                                                onClick={() => {
                                                    if (data.Status === 'esActive') {
                                                        dispatch(SET_EMPLOYEE_DATA({ selectedEmployeeData: data }))
                                                        dispatch(initializeFormsData({}));
                                                    }
                                                    else
                                                        $('#employeeNotActive').modal('show')
                                                }}
                                            >{data.EmployeeId}
                                            </Linker>
                                        }
                                    </td>
                                    <td style={{ paddingTop: '1.2rem' }} >{maskSSNReadOnly(data.SSN)}</td>

                                    <td style={{ paddingTop: '1.2rem' }}>{data.Name}</td>
                                    <td style={{ paddingTop: '1.2rem' }}>{data.FirstName}</td>
                                </tr>
                            );
                        })
                        }

                    </tbody>
                </table>
            )
                :
                <div>
                    <span style={{ fontFamily: "NotoSansRegular", fontSize: "1rem", color: "#dc3545" }}>Sorry, no data found</span>
                </div>
            }
        </div>
    )
}
export default EmployeeListTable;