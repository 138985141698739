import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionHeader from '../../../Molecules/SectionHeader';
import CardGenerator from '../../../Cells/Card/CardGenerator/index';
import CryptoJS from 'crypto-js';

//import { callConstants } from '../../../../Store/Slices/Entities/constants/index';
//import { constantsList } from '../../../../Common/Constants/index';
//import FormGenerator from '../../../Cells/Form/FormGenerator';
import FormatHelper from '../../../Utils/Helper/FormatHelper';
import appConfig from '../../../../appConfig';
import http from '../../../../Common/Services/HttpService';
import Loader from '../../../Atoms/Loader';
import toast, { Toaster } from 'react-hot-toast';
//import { md5 } from "crypto-js";
import PrimaryIdetifierHelper from '../../../../App/Utils/Helper/PrimaryIdetifierHelper';
import {
  onLoadAPI,
  getElements,
} from '../../../../Store/Slices/Entities/response';
import {
  apiReceivedUserTypeAndLanguage,
} from '../../../../Store/Slices/Auth/userAuth';
import { apiReceived } from '../../../../Store/Slices/Auth/loggedUserEntity';
import ColorBanner from '../../../Molecules/ColorBanner';
import { MessageComponent } from '../../../Atoms';

import { CONTRACT_LINIAR_OR_NON_LINIAR, LAST_CONTRACT_CHANGE, STORE_IN_REDUX } from "../../../../Store/Slices/Process"

import { UPDATE_PREFERRED_CONTACT, ENROLLMENT_TYPE, HELPDESK_DETAILS, RESET_COSTBASKET, SET_EMPLOYEE_PROFILE, GBP_RESOURCES_FOR_MEMBER } from '../../../../Store/Slices/EmployeeProfile';
import { UPDATE_LOADING, UPDATE_PRODUCT_LEVEL_ELIGIBILITY, RESET_INSUREDINFO, SET_ALERT_MESSAGE, REMOVE_CURRENTSTEP_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { MATCH_LOGIN_TO_PREFERRED } from '../../../../Store/Slices/Registeration';
import { getWLProductInfo } from '../../../Molecules/CustomSection/Helper/commonHelper';
import { decompressResponse } from '../../../Utils/Helper/MFAHelper';

const HomePage = (props) => {
  let buidtemp = '';
  const [content, SetContent] = useState({});
  /* useEffect to get Home PAge layout content dependent on language */
  const [layoutData, setLayoutData] = useState({});
  const [contentData, SetContentData] = useState({
    panelsData: {},
    steps: {},
    stepsList: {},
    stepKeys: [],
    formsList: {},
    loadingCheck: false,
    isStepFormSubmitted: false,
    isSearchForm: false
  });
  const [pendingprocess, setPendingprocess] = useState([]);
  const [panel, setPanel] = useState("");
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((storeState) => {
    return storeState.language;
  });

  const dataEmployments = useSelector(
    (storeState) => storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result
  );
  const { userType, language, legalEntityId = '' } = useSelector((storeState) => storeState.registeration);

  if (dataEmployments?.length > 0) {
    buidtemp = dataEmployments[dataEmployments?.length - 1]?.bUID;
  }

  // const personBuid = useSelector(
  //   (storeState) => storeState?.auth?.loggedUserEntity?.data?.person?.bUID
  // );

  // const legalEntityBuid = useSelector(
  //   (storeState) =>
  //     storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.legalEntity?.identifier
  //       ?.bUID
  //   // (storeState) => storeState?.auth?.loggedUserEntity?.data?.person?.personDetails?._Result[2]
  // );
  const storeFromRedux = useSelector((storeState) => storeState);
  const userAuth = useSelector((storeState) => storeState.auth.userAuth);
  const employeeProfile = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile);
  const GBPDetailsWithLastRenewal = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.gbpContractsWithLastRenewal?._Result);
  const isLoading = useSelector((storeState) => storeState?.insuredInfo?.isLoading) || false;
  const loaderCount = useSelector((storeState) => storeState?.insuredInfo?.loaderCount) || 0;
  //const currentStep = useSelector((storeState) => storeState?.stepForm?.currentStep);


  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => SetContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);


  useEffect(() => {
    dispatch({
      type: 'SETINITIALSTATE'
    })
    dispatch({
      type: 'RESET'
    })
    dispatch(MATCH_LOGIN_TO_PREFERRED())
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      if (storeFromRedux?.auth?.userAuth?.data?.user_name) {
        await callAPI();
      }
    };

    fetchData();
  }, [storeFromRedux?.auth?.userAuth?.data?.user_name]);

  //const GBPDetailsWithLastRenewal = storeFromRedux?.entities?.response?.onLoadResponseList?.gbpContractsWithLastRenewal;
  /* preferred communication */
  /** Create Helper instances */
  //const formatHelper = new FormatHelper();
  const primaryIdetifierHelper = new PrimaryIdetifierHelper();

  //const preferredContactFromRedux = storeFromRedux?.employeeProfile;
  //const dataFromReduxEmploymentInformation = storeFromRedux?.entities?.response?.onLoadResponseList;
  let dataFromReduxPersonalInformation = storeFromRedux?.entities?.response?.onLoadResponseList?.PersonalInformation?.personalInformation;
  dataFromReduxPersonalInformation = (dataFromReduxPersonalInformation == undefined ? employeeProfile : dataFromReduxPersonalInformation)
  const employeeIdFromRedux = storeFromRedux?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.employeeId;

  const legalEntityData = storeFromRedux?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.legalEntity?.identifier?.externalClientId?.externalClientId
  //const empBuid = storeFromRedux?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.bUID;
  const legalEntityDataBackup = storeFromRedux?.registeration?.legalEntityId;

  const oneCode = (/[^:]*/.exec(legalEntityDataBackup)[0]) || legalEntityData


  /* preferred communication */

  //const contractBuid = storeFromRedux?.entities?.response?.onLoadResponseList?.subscribedcontracts
    ?._Result?.[0]?.contract?.buid
  //const employeeBuid = storeFromRedux?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.bUID;
  const enrollmentData = storeFromRedux?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.employee?.additionalDynamicDatas;
  const GBPDetails = storeFromRedux?.process?.contractsLinearOrNonLinear?._Result;
  const eligibleProductsForMember = storeFromRedux?.entities?.response?.onLoadResponseList?.getListOfEligibleProductsForMember;
  const currentEnrollmentPeriod = storeFromRedux?.process?.contractsLinearOrNonLinear?._Result?.currentEnrollmentPeriod?.enrollmentPeriod;
  const lastContractChange = storeFromRedux?.entities?.response?.onLoadResponseList?.getLastContractChange?._Result?.contractChange;
  let previousOngoingDate1 = GBPDetails?.contract?.additionalDatas?.filter((elm) => elm?.fieldCode === 'DD_00139')?.[0]?.fieldValue || ""
  let previousOngoingDate2 = GBPDetailsWithLastRenewal?.contract?.additionalDatas?.filter((elm) => elm?.fieldCode === 'DD_00139')?.[0]?.fieldValue || ""
  let previousOngoingDate = previousOngoingDate2 || previousOngoingDate1
  let enrollmentPeriodKind = GBPDetails?.currentEnrollmentPeriod?.enrollmentPeriod?.enrollmentPeriodKind;
  let lastRenewalDate = GBPDetails?.contract?.lastRenewalDate;
  let getContractChanges = GBPDetails?.getcontractchanges || false;
  const hasWLData = GBPDetails?.contract?.additionalDatas?.find(item => item.fieldCode === "DD_00159" && item.fieldValue === 'Yes');

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;




  const callAPI = async () => {

    dispatch(UPDATE_LOADING(true));
    dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE("welcomeScreen"));
    return await http
      .post(appConfig.SSP_MID_URL + `/v1/welcome`, {
        //email: storeFromRedux?.auth?.userAuth?.data?.user_name
        email: userAuth?.callCenterAdmin ? userAuth?.employeeEmail : userAuth?.data?.user_name
      })
      .then(async (resp) => {
        const decryptedData = decompressResponse(resp?.data?.data)
        const data = decryptedData;
        for (const key in data) {
          let result = { _Result: data[key].data }
          let val = {}
          switch (key) {
            case 'getLoginUser':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getLoginUser',
                  show: true,
                  page: 'welcomeScreen',
                }));
              } else {
                let loginData = data[key].data;
                Object.assign(val, { person: loginData });
                dispatch(apiReceived(val));
                dispatch(apiReceivedUserTypeAndLanguage({ userType: 'Enrollment', language: 'en' }))
              }
              break;
            case 'getEmployments':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getEmployments',
                  show: true,
                  page: 'welcomeScreen',
                }));
              } else {
                Object.assign(val, { personDetails: { _Result: data[key].data } });
                dispatch(apiReceived(val));
              }
              break;
            case 'getPersonalInformation':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getPersonalInformation',
                  show: true,
                  page: 'welcomeScreen',
                }));
              } else {
                const personalInformation = data[key].data;
                dispatch(onLoadAPI({ 'PersonalInformation': { personalInformation } }));
                dispatch(SET_EMPLOYEE_PROFILE(personalInformation));
              }
              break;
            case 'getEmploymentInformation':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getEmploymentInformation',
                  show: true,
                  page: 'welcomeScreen',
                }));
              } else {
                dispatch(onLoadAPI({ 'EmploymentInformation': data[key].data }));
              }
              break;
            case 'getPendingProcess':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getPendingProcess',
                  show: true,
                  page: 'welcomeScreen',
                }));
              } else {
                dispatch(onLoadAPI({ 'GetPendingProcess': result }));
              }
              break;
            case 'getEnrollments':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getEnrollments',
                  show: true,
                  page: 'welcomeScreen',
                }));
              } else {
                dispatch(onLoadAPI({ 'getCurrerntYearEnrollments': result }));
              }
              break;
            case 'gbpcontractsLinearOrNonLinear':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'gbpcontractsLinearOrNonLinear',
                  show: true,
                  page: 'welcomeScreen',
                }));
              } else {
                dispatch(CONTRACT_LINIAR_OR_NON_LINIAR(result))
                // Check WL Coverage is enabled for user
                const additionalDatas = data[key].data?.contract?.additionalDatas || []
                if (additionalDatas?.some(item => item.fieldCode === 'DD_00159' && item.valueCode === 'Yes')) {
                  dispatch(UPDATE_PRODUCT_LEVEL_ELIGIBILITY({
                    product: 'WLCoverage'
                  }));
                }
              }
              break;
            case 'getGBPResourcesForMember':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getGBPResourcesForMember',
                  show: true,
                  page: 'welcomeScreen',
                }));
              } else {
                if (data[key].data?.[0]?.content) {
                  dispatch(HELPDESK_DETAILS(data[key].data?.[0].content));
                }
                if (data[key].data && data[key].success) {
                  dispatch(GBP_RESOURCES_FOR_MEMBER(data[key].data));
                }
              }
              break;
            case 'getListOfEligibleProductsForMember':
              if (!data[key]?.success) {
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'getListOfEligibleProductsForMember',
                  show: true,
                  page: 'welcomeScreen',
                }));
              } else {
                dispatch(onLoadAPI({ 'getListOfEligibleProductsForMember': data[key].data }));
              }
              break;
            case 'declinedCertificate':
              dispatch(onLoadAPI({ 'declinedCertificate': result }));
              break;
            case 'apiCookies':
              http.setHeaderAPICookies(data[key].data);
              break;
            default:
              dispatch(onLoadAPI({ [key]: result }));
          }

        }
        dispatch(UPDATE_LOADING(false));
        toast(<div><strong>Navigation Tip:</strong> Do not use your browser's - back/forward/refresh options. To navigate through your enrollment use only the back/continue buttons in the enrollment application.</div>);
        return true;
      })
      .catch((e) => {
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'welcomeScreen',
          show: true,
          page: 'welcomeScreen',
        }));
        dispatch(UPDATE_LOADING(false));
        return false;
      });
  };



  const contractData = storeFromRedux?.entities?.response?.onLoadResponseList?.getMemberActiveContracts?._Result;
  const declinedCertificate = GBPDetails?.declinedCertificate || false;

  let activeContractDatas = contractData ? contractData?.filter(e => e.status === 'cActive') : [];
  let holdContractDatas = contractData ? contractData?.filter(e => e.status === 'cHold') : [];
  let statusCheck = contractData ? 
  activeContractDatas?.length >0 && holdContractDatas?.length >0
  ? contractData?.filter(e => e.status !== 'cActive' && e.status !== 'cDeclined' && e.status !== 'cHold') 
  : contractData?.filter(e => e.status !== 'cActive' && e.status !== 'cDeclined') 
  : [];

  const managePanelsForEligibleProducts = (panels, eligibleProductsForMember, currentYearProduct) => {
    let colorBannerElements = panels?.HomePageTemp?.colorBanner?.elements;
    if (!colorBannerElements) return

    let listContainerBannerIndex = colorBannerElements.findIndex(
      (bannerEle) => bannerEle.id === 'listContainerBanner'
    );
    if (listContainerBannerIndex < 0) return

    let listContainerBannerEle = colorBannerElements[listContainerBannerIndex]?.elements;
    if (!listContainerBannerEle) return

    let listComponentBannerIndex = listContainerBannerEle.findIndex(
      (ele) => ele.id === 'listComponentBanner'
    );
    if (listComponentBannerIndex < 0) return false

    let listComponentBannerEle = listContainerBannerEle[listComponentBannerIndex]?.elements;

    let fields = {
      id: 'accidentalInsurance',
      type: 'imageText',
      className: 'py-2',
      style: {
        borderBottom: '1px solid #DADADA',
      },
      name: '',
      label: '',
      alt: 'carrierLogo',
      containerClassName: 'px-3 py-0 my-0',
      fileName: 'no-image.png',
      imageStyle: {
        width: '80px',
        height: '30px',
      },
      isListofEligibleMemberForHomePage: true,
      textClassName: 'px-3 py-0 my-0',
    };
    let productsMember = []
    /* TransLegacy Product not exist in eligibleProductsForMember API list */
    const empProfileReasonType = storeFromRedux?.employeeProfile?.reasonType;
    if (empProfileReasonType === 'AE') {
      let isTranslegacy = GBPDetails?.subscribedElements?.filter((elm) => elm?.memberOfferedProduct?.code === 'ULTRANSLEGACY#0') || []
      let translegacyProductID = ""
      if (isTranslegacy?.length > 0) {
        translegacyProductID = isTranslegacy?.[0]?.memberOfferedProduct?.product?.buid
      }
      let isExistTransLeagcy = eligibleProductsForMember?.filter((elm) => elm?.buid === translegacyProductID)?.length > 0 ? true : false
      if (currentYearProduct?.subscribedElements?.length && !isExistTransLeagcy) {
        let transLegacyDetails = currentYearProduct?.subscribedElements?.filter((elm) => elm?.subscribedProduct?.productCode?.buid === translegacyProductID)
        if (transLegacyDetails?.length) {
          let product = transLegacyDetails?.[0]
          const name = product?.subscribedProduct?.productName || ""
          const fileName = product?.subscribedProduct?.logo || "no-image"
          const content = product?.subscribedProduct?.logoName || "no-image.png"
          const id = name.split(' ').join('_');
          let fieldsDetails = { ...fields, name: name, label: name, alt: id + "logo", id: id, logo: fileName, contentType: content };
          productsMember.push(fieldsDetails)
        }
      }
    }
    /* List of eligible product */
    if (Array.isArray(eligibleProductsForMember) && eligibleProductsForMember.length) {
      eligibleProductsForMember.map((product) => {
        const name = product?.name || ""
        const fileName = product?.logo || "no-image"
        const content = product?.logoName || "no-image.png"
        const id = name.split(' ').join('_');
        let fieldsDetails = { ...fields, name: name, label: name, alt: id + "logo", id: id, logo: fileName, contentType: content };
        productsMember.push(fieldsDetails)
      });
    }

    listContainerBannerEle[listComponentBannerIndex].elements = productsMember
  };

  const managePanelsForCurrentBenefits = (panels, enrollmentDetails) => {
    let cardMembersElements = panels?.HomePageTemp?.cards?.[0]?.cardMembers;
    if (!cardMembersElements) return

    let listcardMembersIndex = cardMembersElements.findIndex(
      (bannerEle) => bannerEle.id === 'currentBenefitsPanel'
    );
    if (listcardMembersIndex < 0) return

    let listContainerCardMembersEle = cardMembersElements[listcardMembersIndex]?.cardElements?.[0]?.panelBody?.elements?.[0]?.elements;
    if (!listContainerCardMembersEle) return

    let listComponentBannerIndex = listContainerCardMembersEle.findIndex(
      (ele) => ele.type === 'listComponent'
    );
    if (listComponentBannerIndex < 0) return false

    let listComponentCardMembersEle = listContainerCardMembersEle[listComponentBannerIndex]?.elements;

    let fields = {
      type: "imageText",
      className: "py-2",
      style: {
        "borderBottom": "1px solid #DADADA"
      },
      name: "",
      label: "",
      containerClassName: "px-3 py-0 my-0",
      containerStyle: {
        width: "60%"
      },
      fileName: "no-image.png",
      imageStyle: {
        width: "80px",
        height: "30px"
      },
      textClassName: "px-2 py-0 my-0",
      badge: "",
      subtitleClassName: "px-2 py-0 my-0",
      subtitleStyle: {
        fontFamily: "NotoSansLight"
      },
      subtitle: "",
      badgeStyle: {
        color: "#565656",
        backgroundColor: "#EBEBEB",
        border: "1px solid #DADADA",
        fontSize: "0.625rem",
        borderRadius: "2px"
      },
      bagdeClassName: "py-0 px-2",
      badgeContainerStyle: {
        width: "20%",
        alignSelf: "baseline"
      },
      badgeContainerClassName: "d-flex justify-content-end"
    };

    let productsMember = []
    if (enrollmentDetails?.subscribedElements.length > 0) {
      enrollmentDetails?.subscribedElements?.map((product) => {
        let totalAmount = ""
        // product?.coverages?.map((e) => {
        //   let planDesignAmount = e?.subscribedOption?.additionalDatas?.filter(el => el.fieldCode === 'PlanDesign');
        //   totalAmount = planDesignAmount?.[0]?.nameDescription
        // })
        let coverageEffectiveDate = product?.coverages?.[0]?.subscribedOption?.effectiveDate;
        let plannedTerminationDate = product?.coverages?.[0]?.subscribedOption?.plannedTerminationDate;
        let coverageStatus = product?.coverages?.[0]?.subscribedOption?.status;
        if (enrollmentPeriodKind === 'cAnnualEnrollment') {
          //if ((Date.parse(today) <= Date.parse(previousOngoingDate)) || (Date.parse(today) > Date.parse(previousOngoingDate))) {
          if (product?.coverages?.length > 0 && (Date.parse(coverageEffectiveDate) < Date.parse(lastRenewalDate))) {
            const name = product?.subscribedProduct?.productName || ""
            const fileName = product?.subscribedProduct?.logo || "no-image"
            const content = product?.subscribedProduct?.logoName || "no-image.png"
            const subtitle = totalAmount?.split('||')[0] || ""
            let badge = ""
            if ((Date.parse(plannedTerminationDate) < Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
              badge = ''
            } else if ((Date.parse(plannedTerminationDate) > Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
              badge = ''
            } else {
              badge = product.subscribedProduct.status !== 'cActive' ? 'Waived' : ''
            }

            const id = name.split(' ').join('_');
            let fieldsDetails = { ...fields, name: name, label: name, subtitle: subtitle, alt: id + "logo", badge: badge, logo: fileName, contentType: content };
            productsMember.push(fieldsDetails)
          } else if (product?.coverages?.length === 0) {
            const name = product?.subscribedProduct?.productName || ""
            const fileName = product?.subscribedProduct?.logo || "no-image"
            const content = product?.subscribedProduct?.logoName || "no-image.png"
            const subtitle = totalAmount?.split('||')[0] || ""
            let badge = product.subscribedProduct.status !== 'cActive' ? 'Waived' : ''
            const id = name.split(' ').join('_');
            let fieldsDetails = { ...fields, name: name, label: name, subtitle: subtitle, alt: id + "logo", badge: badge, logo: fileName, contentType: content };
            productsMember.push(fieldsDetails)

          }
          //}
        } else {
          if (enrollmentPeriodKind === 'cOngoingEnrollment' &&
            (Date.parse(today) > Date.parse(previousOngoingDate)) &&
            (Date.parse(today) < Date.parse(lastRenewalDate))
          ) {
            if ((Date.parse(coverageEffectiveDate) < Date.parse(lastRenewalDate))) {
              const name = product?.subscribedProduct?.productName || ""
              const fileName = product?.subscribedProduct?.logo || "no-image"
              const content = product?.subscribedProduct?.logoName || "no-image.png"
              const subtitle = totalAmount?.split('||')[0] || ""
              let badge = ''
              if ((Date.parse(plannedTerminationDate) < Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
                badge = ''
              } else if ((Date.parse(plannedTerminationDate) > Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
                badge = ''
              } else {
                badge = product.subscribedProduct.status !== 'cActive' ? 'Waived' : ''
              }
              const id = name.split(' ').join('_');
              let fieldsDetails = { ...fields, name: name, label: name, subtitle: subtitle, alt: id + "logo", badge: badge, logo: fileName, contentType: content };
              productsMember.push(fieldsDetails)
            }
          } else {
            const name = product?.subscribedProduct?.productName || ""
            const fileName = product?.subscribedProduct?.logo || "no-image"
            const content = product?.subscribedProduct?.logoName || "no-image.png"
            const subtitle = totalAmount?.split('||')[0] || ""
            let badge = ''
            if ((Date.parse(plannedTerminationDate) < Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
              badge = ''
            } else if ((Date.parse(plannedTerminationDate) > Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
              badge = ''
            } else {
              badge = product.subscribedProduct.status !== 'cActive' ? 'Waived' : ''
            }
            const id = name.split(' ').join('_');
            let fieldsDetails = { ...fields, name: name, label: name, subtitle: subtitle, badge: badge, alt: id + "logo", logo: fileName, contentType: content };
            productsMember.push(fieldsDetails)
          }
        }
      });
      //const hasWLData = enrollmentDetails?.contract?.additionalDatas?.find(item => item.fieldCode === "DD_00180" && item.fieldValue !== '');
      if (Array.isArray(productsMember) && productsMember.length > 0 && hasWLData) {
        // const status = hasWLData.fieldValue === "Yes" ? 'Elected' : 'Waived';
        const info = getWLProductInfo(storeFromRedux?.employeeProfile?.getGBPResourcesForMember || []);
        productsMember.push({
          ...fields,
          name: info.title,
          label: info.title,
          alt: info.title + "logo",
          subtitle: '',
          badge: '',
          logo: info?.logo?.binaryContent,
          contentType: info?.logo?.content
        });
      }
    }
    listContainerCardMembersEle[listComponentBannerIndex].elements = productsMember
  };

  const managePanelsForCurrentBenefitsNoCertificat = (panels, productDetails) => {
    let cardMembersElements = panels?.HomePageTemp?.cards?.[0]?.cardMembers;
    if (!cardMembersElements) return

    let listcardMembersIndex = cardMembersElements.findIndex(
      (bannerEle) => bannerEle.id === 'currentBenefitsPanel3'
    );
    if (listcardMembersIndex < 0) return

    let listContainerCardMembersEle = cardMembersElements[listcardMembersIndex]?.cardElements?.[0]?.panelBody?.elements?.[0]?.elements;
    if (!listContainerCardMembersEle) return

    let listComponentBannerIndex = listContainerCardMembersEle.findIndex(
      (ele) => ele.type === 'listComponent'
    );
    if (listComponentBannerIndex < 0) return false

    let listComponentCardMembersEle = listContainerCardMembersEle[listComponentBannerIndex]?.elements;

    let fields = {
      id: 'accidentalInsurance',
      type: 'imageText',
      className: 'py-2',
      style: {
        borderBottom: '1px solid #DADADA',
      },
      name: '',
      label: '',
      containerClassName: 'px-3 py-0 my-0',
      fileName: 'no-image.png',
      imageStyle: {
        width: '80px',
        height: '30px',
      },
      isListofEligibleMemberForHomePage: true,
      textClassName: 'px-3 py-0 my-0',
    };

    let productsMember = []
    if (Array.isArray(productDetails) && productDetails.length) {
      productDetails.map((product) => {
        if (enrollmentPeriodKind === 'cAnnualEnrollment') {
          if ((Date.parse(today) <= Date.parse(previousOngoingDate))) {
            let coverageEffectiveDate = product?.effectiveDate;
            let coverageStatus = product?.status;
            if ((Date.parse(coverageEffectiveDate) < Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
              const name = product?.name || ""
              const fileName = product?.logo || "no-image"
              const content = product?.logoName || "no-image.png"
              const id = name.split(' ').join('_');
              let fieldsDetails = { ...fields, name: name, label: name, id: id, logo: fileName, contentType: content };
              productsMember.push(fieldsDetails)
            }
          }
        } else {
          const name = product?.name || ""
          const fileName = product?.logo || "no-image"
          const content = product?.logoName || "no-image.png"
          const id = name.split(' ').join('_');
          let fieldsDetails = { ...fields, name: name, label: name, id: id, logo: fileName, contentType: content };
          productsMember.push(fieldsDetails)
        }
      });
    }
    listContainerCardMembersEle[listComponentBannerIndex].elements = productsMember
  };

  const managePanelsForopenEnrollmentImagePanel = (panels) => {
    let cardMembersElements = panels?.HomePageTemp?.cards?.[0]?.cardMembers;
    if (!cardMembersElements) return

    let listcardMembersIndex = cardMembersElements.findIndex(
      (bannerEle) => bannerEle.id === 'openEnrollmentImagePanel'
    );
    if (listcardMembersIndex < 0) return
    let listContainerCardMembersEle = cardMembersElements[listcardMembersIndex]?.cardElements?.[0]?.panelBody?.elements;
    let listContainerCardMembersEle2 = cardMembersElements[listcardMembersIndex]?.cardElements?.[0]?.panelBody;
    if (!listContainerCardMembersEle) return


    let listComponentBannerIndex = listContainerCardMembersEle.findIndex(
      (ele) => ele.name === 'contract'
    );
    if (listComponentBannerIndex < 0) return false

    let listComponentCardMembersEle = listContainerCardMembersEle[listComponentBannerIndex];

    let fields = listComponentCardMembersEle
    const labelTitle = `Year ${new Date().getFullYear() + 1} Open Enrollment is Live Now!`
    const value = `Please complete your new hire enrollment and then get on to open enrollment for the year ${new Date().getFullYear() + 1}.`
    let productsMember = [{ ...fields, labelTitle: labelTitle, value: value }]
    listContainerCardMembersEle2.elements = productsMember
  };

  const manageCurrentPanelsForEligibleProducts = (panels, enrollmentDetails) => {
    let colorBannerElements = panels?.HomePageTemp?.colorBanner?.elements;
    if (!colorBannerElements) return

    let listContainerBannerIndex = colorBannerElements.findIndex(
      (bannerEle) => bannerEle.id === 'listContainerBanner'
    );
    if (listContainerBannerIndex < 0) return

    let listContainerBannerEle = colorBannerElements[listContainerBannerIndex]?.elements;
    if (!listContainerBannerEle) return

    let listComponentBannerIndex = listContainerBannerEle.findIndex(
      (ele) => ele.id === 'listComponentBanner2'
    );
    if (listComponentBannerIndex < 0) return false

    let listComponentBannerEle = listContainerBannerEle[listComponentBannerIndex]?.elements;

    let fields = {
      type: "imageText",
      className: "py-2",
      style: {
        "borderBottom": "1px solid #DADADA"
      },
      name: "",
      label: "",
      containerClassName: "px-3 py-0 my-0",
      fileName: "no-image.png",
      containerStyle: {
        width: "60%"
      },
      imageStyle: {
        width: "80px",
        height: "30px"
      },
      textClassName: "px-2 py-0 my-0",
      badge: "",
      subtitleClassName: "px-2 py-0 my-0",
      subtitleStyle: {
        fontFamily: "NotoSansLight"
      },
      badgeStyle: {
        color: "#565656",
        backgroundColor: "#EBEBEB",
        border: "1px solid #DADADA",
        fontSize: "0.625rem",
        borderRadius: "2px"
      },
      bagdeClassName: "py-0 px-2",
      badgeContainerStyle: {
        width: "20%",
        alignSelf: "baseline"
      },
      badgeContainerClassName: "d-flex justify-content-end"
    };

    let productsMember = []
    if (enrollmentDetails?.subscribedElements?.length > 0) {
      enrollmentDetails?.subscribedElements?.map((product) => {
        let coverageEffectiveDate = product?.coverages?.[0]?.subscribedOption?.effectiveDate;
        let coverageStatus = product?.coverages?.[0]?.subscribedOption?.status;
        let plannedTerminationDate = product?.coverages?.[0]?.subscribedOption?.plannedTerminationDate;
        if (enrollmentPeriodKind === 'cAnnualEnrollment') {
          //if ((Date.parse(today) <= Date.parse(previousOngoingDate)) || (Date.parse(today) > Date.parse(previousOngoingDate))) {
          const name = product?.subscribedProduct?.productName || ""
          const fileName = product?.subscribedProduct?.logo || "no-image"
          const content = product?.subscribedProduct?.logoName || "no-image.png"
          let badge = ""
          if ((Date.parse(plannedTerminationDate) < Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
            badge = ''
          } else if ((Date.parse(plannedTerminationDate) > Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
            badge = ''
          } else {
            badge = product.subscribedProduct.status !== 'cActive' ? 'Waived' : ''
          }
          const id = name.split(' ').join('_');
          let fieldsDetails = { ...fields, name: name, label: name, badge: badge, logo: fileName, contentType: content };
          productsMember.push(fieldsDetails)
          //}
        } else if (enrollmentPeriodKind === 'cOngoingEnrollment' &&
          (Date.parse(today) > Date.parse(previousOngoingDate)) &&
          (Date.parse(today) < Date.parse(lastRenewalDate))
        ) {
          const name = product?.subscribedProduct?.productName || ""
          const fileName = product?.subscribedProduct?.logo || "no-image"
          const content = product?.subscribedProduct?.logoName || "no-image.png"
          let badge = ""
          if ((Date.parse(plannedTerminationDate) < Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
            if (product?.subscribedProduct?.productCode?.productIdentifier?.productCode === 'TRAVELLIMT#0' && legalEntityData === 'NATCOL') {
              badge = 'Waived'
            } else {
              badge = ''
            }
          } else if ((Date.parse(plannedTerminationDate) > Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
            badge = ''
          } else {
            badge = product.subscribedProduct.status !== 'cActive' ? 'Waived' : ''
          }
          const id = name.split(' ').join('_');
          let fieldsDetails = { ...fields, name: name, label: name, badge: badge, logo: fileName, contentType: content };
          productsMember.push(fieldsDetails)
        } else {
          const name = product?.subscribedProduct?.productName || ""
          const fileName = product?.subscribedProduct?.logo || "no-image"
          const content = product?.subscribedProduct?.logoName || "no-image.png"
          let badge = ""
          if ((Date.parse(plannedTerminationDate) < Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
            badge = ''
          } else if ((Date.parse(plannedTerminationDate) > Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
            badge = ''
          } else {
            badge = product.subscribedProduct.status !== 'cActive' ? 'Waived' : ''
          }
          const id = name.split(' ').join('_');
          let fieldsDetails = { ...fields, name: name, label: name, badge: badge, logo: fileName, contentType: content };
          productsMember.push(fieldsDetails)
        }

      });
      const empProfileEnrollmentType = storeFromRedux?.employeeProfile?.enrollmentType;
      const empProfileReasonType = storeFromRedux?.employeeProfile?.reasonType;
      if (empProfileReasonType === 'AE') {
        //const hasWLData = enrollmentDetails?.contract?.additionalDatas?.find(item => item.fieldCode === "DD_00180" && item.fieldValue !== '');
        if (Array.isArray(productsMember) && productsMember.length > 0 && hasWLData) {
          const info = getWLProductInfo(storeFromRedux?.employeeProfile?.getGBPResourcesForMember || []);
          productsMember.push({
            ...fields,
            name: info.title,
            label: info.title,
            badge: '',
            logo: info?.logo?.binaryContent,
            contentType: info?.logo?.content
          });
        }
      }
    }
    listContainerBannerEle[listComponentBannerIndex].elements = productsMember
  };

  const managePanelsForCurrentYearNoCertificat = (panels, eligibleProductsForMember, currentYearProduct) => {
    let cardMembersElements = panels?.HomePageTemp?.cards?.[0]?.cardMembers;
    if (!cardMembersElements) return

    let listcardMembersIndex = cardMembersElements.findIndex(
      (bannerEle) => bannerEle.id === 'currentBenefitsPanel3'
    );
    if (listcardMembersIndex < 0) return

    let listContainerCardMembersEle = cardMembersElements[listcardMembersIndex]?.cardElements?.[0]?.panelBody?.elements?.[0]?.elements;
    if (!listContainerCardMembersEle) return

    let listComponentBannerIndex = listContainerCardMembersEle.findIndex(
      (ele) => ele.type === 'listComponent'
    );
    if (listComponentBannerIndex < 0) return false

    let listComponentCardMembersEle = listContainerCardMembersEle[listComponentBannerIndex]?.elements;

    let fields = {
      id: 'accidentalInsurance',
      type: 'imageText',
      className: 'py-2',
      style: {
        borderBottom: '1px solid #DADADA',
      },
      name: '',
      label: '',
      containerClassName: 'px-3 py-0 my-0',
      fileName: 'no-image.png',
      imageStyle: {
        width: '80px',
        height: '30px',
      },
      isListofEligibleMemberForHomePage: true,
      textClassName: 'px-3 py-0 my-0',
    };

    let productsMember = []
    /* TransLegacy Product not exist in eligibleProductsForMember API list */
    const empProfileReasonType = storeFromRedux?.employeeProfile?.reasonType;
    if (empProfileReasonType === 'AE') {
      let isTranslegacy = GBPDetails?.subscribedElements?.filter((elm) => elm?.memberOfferedProduct?.code === 'ULTRANSLEGACY#0') || []
      let translegacyProductID = ""
      if (isTranslegacy?.length > 0) {
        translegacyProductID = isTranslegacy?.[0]?.memberOfferedProduct?.product?.buid
      }
      let isExistTransLeagcy = eligibleProductsForMember?.filter((elm) => elm?.buid === translegacyProductID)?.length > 0 ? true : false
      if (currentYearProduct?.subscribedElements?.length && !isExistTransLeagcy) {
        let transLegacyDetails = currentYearProduct?.subscribedElements?.filter((elm) => elm?.subscribedProduct?.productCode?.buid === translegacyProductID)
        if (transLegacyDetails?.length) {
          let product = transLegacyDetails?.[0]
          const name = product?.subscribedProduct?.productName || ""
          const fileName = product?.subscribedProduct?.logo || "no-image"
          const content = product?.subscribedProduct?.logoName || "no-image.png"
          const id = name.split(' ').join('_');
          let fieldsDetails = { ...fields, name: name, label: name, id: id, logo: fileName, contentType: content };
          productsMember.push(fieldsDetails)
        }
      }
    }
    /* List of eligible product */
    if (Array.isArray(eligibleProductsForMember) && eligibleProductsForMember.length) {
      eligibleProductsForMember.map((product) => {
        const name = product?.name || ""
        const fileName = product?.logo || "no-image"
        const content = product?.logoName || "no-image.png"
        const id = name.split(' ').join('_');
        let fieldsDetails = { ...fields, name: name, label: name, id: id, logo: fileName, contentType: content };
        productsMember.push(fieldsDetails)
      });
    }
    listContainerCardMembersEle[listComponentBannerIndex].elements = productsMember
  };
  const [exampleCase, setExampleCase] = useState('case7'); // no widget
  useEffect(() => {
    const fetchData = async () => {
      /* use effect to get forms which contains element properties */
      if (content.layout && content.layout.viewType) {
        let clientIdByLegalEntity = null;
        if (legalEntityDataBackup) {
          clientIdByLegalEntity = legalEntityDataBackup.split(":")[0];
        }
        let panels = {};
        panels = await dispatch(
          getElements(
            (content.layout && content.layout.viewType && `${content.layout.viewType}`) || '',
            content,
            (content.layout && content.layout.viewType && `${content.layout.viewType}`) || '',
            SetContentData,
            "",
            "",
            "",
            clientIdByLegalEntity
          )
        );
        setPanel(panels);
      }
    };

    /**legalEntityId added by Nisreen to pass clientId to getForms */
    fetchData();
  }, [content.layout && content.layout.viewType, legalEntityId]);

  const getPanels = async () => {
    if (content.layout && content.layout.viewType) {
      /* function to get forms from node using formData */
      let clientIdByLegalEntity = null
      if (legalEntityDataBackup) {
        clientIdByLegalEntity = legalEntityDataBackup.split(":")[0]
      }
      let panels = panel ? panel : {};
      let members = [];
      const empProfileReasonType = storeFromRedux?.employeeProfile?.reasonType;
      if (
        storeFromRedux?.entities?.response?.onLoadResponseList?.subscribedcontracts
          ?._Result?.[0]?.contract?.buid && storeFromRedux?.employeeProfile?.enrollmentType
        && storeFromRedux?.entities?.response?.onLoadResponseList?.getListOfEligibleProductsForMember
      ) {
        //members = await getListOfEligibleProductsForMember();
        members = storeFromRedux?.entities?.response?.onLoadResponseList?.getListOfEligibleProductsForMember
        //const empProfileEnrollmentType = storeFromRedux?.employeeProfile?.enrollmentType;
       
        if (Array.isArray(members) && (empProfileReasonType === 'AE')) {
          const info = getWLProductInfo(storeFromRedux?.employeeProfile?.getGBPResourcesForMember || []);
          if (info?.title) {
            members = [...members, {
              name: info.title,
              logo: info?.logo?.binaryContent,
              logoName: info?.logo?.content
            }]
          }
          //
        }
      }
      if (storeFromRedux?.employeeProfile?.enrollmentType === 'case13' && empProfileReasonType !== 'OP') {
        members = members?.filter(element => element.isEvenGreen === true)
      }
      let enrollmentDetails = "";
      enrollmentDetails = storeFromRedux?.entities?.response?.onLoadResponseList?.getCurrerntYearEnrollments;
      managePanelsForEligibleProducts(panels, members, enrollmentDetails?._Result)
      managePanelsForCurrentYearNoCertificat(panels, members, enrollmentDetails?._Result)
      managePanelsForopenEnrollmentImagePanel(panels)
      if (storeFromRedux?.entities?.response?.onLoadResponseList?.getCurrerntYearEnrollments && activeContractDatas.length > 0
        && activeContractDatas?.[0]?.status === 'cActive' && storeFromRedux?.employeeProfile?.enrollmentType) {
        //enrollmentDetails = await getEnrollments(activeContractDatas?.[0]?.contract?.buid);
        managePanelsForCurrentBenefits(panels, enrollmentDetails?._Result);
        manageCurrentPanelsForEligibleProducts(panels, enrollmentDetails?._Result);
      }
      if (storeFromRedux?.employeeProfile?.enrollmentType === 'editEnrollmentDuringOE') {
        let effectiveDate = lastContractChange?.effectiveDateOfChange;
        let effectiveDateOfChange = currentdateCheck(effectiveDate, today);
        if (effectiveDateOfChange && enrollmentPeriodKind === 'cAnnualEnrollment') {
          let listProduct = enrollmentDetails?._Result?.subscribedElements
          let count = 0
          if (listProduct?.length > 0) {
            listProduct?.map((product) => {
              if ((Date.parse(today) <= Date.parse(previousOngoingDate))) {
                let coverageEffectiveDate = product?.coverages?.[0]?.subscribedOption?.effectiveDate;
                let coverageStatus = product?.coverages?.[0]?.subscribedOption?.status;
                if ((Date.parse(coverageEffectiveDate) < Date.parse(lastRenewalDate))) {
                  count++
                }
              }
            })
          }
          if (getContractChanges === true) {
            panels.HomePageTemp.cases.editEnrollmentDuringOE = panels.HomePageTemp.cases.editEnrollmentDuringOE.filter((val) => {
              return val != 'currentBenefitsPanel' && val != 'currentBenefitsPanel4'
            })
          } else if (count === 0) {
            panels.HomePageTemp.cases.editEnrollmentDuringOE = panels.HomePageTemp.cases.editEnrollmentDuringOE.filter((val) => {
              return val != 'currentBenefitsPanel'
            })
          } else {
            panels.HomePageTemp.cases.editEnrollmentDuringOE = panels.HomePageTemp.cases.editEnrollmentDuringOE.filter((val) => {
              return val != 'currentBenefitsPanel4'
            })
          }
        } else {
          if (getContractChanges === true && enrollmentPeriodKind === 'cAnnualEnrollment') {
            panels.HomePageTemp.cases.editEnrollmentDuringOE = panels.HomePageTemp.cases.editEnrollmentDuringOE.filter((val) => {
              return val != 'currentBenefitsPanel' && val != 'currentBenefitsPanel4'
            })
          } else {
            panels.HomePageTemp.cases.editEnrollmentDuringOE = panels.HomePageTemp.cases.editEnrollmentDuringOE.filter((val) => {
              return val != 'currentBenefitsPanel4'
            })
          }

        }
      }
      if (storeFromRedux?.employeeProfile?.enrollmentType === 'open-reenrollment') {
        //let effectiveDate = lastContractChange?.effectiveDateOfChange;
        //let effectiveDateOfChange = currentdateCheck(effectiveDate, today);
        if (enrollmentPeriodKind === 'cAnnualEnrollment') {
          let listProduct = enrollmentDetails?._Result?.subscribedElements
          let count = 0
          if (listProduct?.length > 0) {
            listProduct?.map((product) => {
              if ((Date.parse(today) <= Date.parse(previousOngoingDate)) || (Date.parse(today) > Date.parse(previousOngoingDate))) {
                let coverageEffectiveDate = product?.coverages?.[0]?.subscribedOption?.effectiveDate;
                let coverageStatus = product?.coverages?.[0]?.subscribedOption?.status;
                if ((Date.parse(coverageEffectiveDate) < Date.parse(lastRenewalDate))) {
                  count++
                }
              }
            })
          }
          if (count === 0) {
            panels.HomePageTemp.cases['open-reenrollment'] = panels.HomePageTemp.cases['open-reenrollment'].filter((val) => {
              return val != 'currentBenefitsPanel'
            })
          } else {
            panels.HomePageTemp.cases['open-reenrollment'] = panels.HomePageTemp.cases['open-reenrollment'].filter((val) => {
              return val != 'currentBenefitsPanel4'
            })
          }
        } else {
            panels.HomePageTemp.cases['open-reenrollment'] = panels.HomePageTemp.cases['open-reenrollment'].filter((val) => {
              return val != 'currentBenefitsPanel4'
            })
        }
      }
      if (storeFromRedux?.employeeProfile?.enrollmentType === 'editNewEnrollmentDuringOE') {
        if (previousOngoingDate && enrollmentPeriodKind === 'cOngoingEnrollment' &&
          (Date.parse(today) > Date.parse(previousOngoingDate)) &&
          (Date.parse(today) < Date.parse(lastRenewalDate))
        ) {
          let listProduct = enrollmentDetails?._Result?.subscribedElements
          let count = 0
          if (listProduct?.length > 0) {
            listProduct?.map((product) => {
              let coverageEffectiveDate = product?.coverages?.[0]?.subscribedOption?.effectiveDate;
              if ((Date.parse(coverageEffectiveDate) < Date.parse(lastRenewalDate))) {
                count++
              }
            })
          }
          if (Date.parse(today) <= Date.parse(lastRenewalDate) && (Date.parse(lastRenewalDate) == Date.parse(activeContractDatas?.[0]?.effectiveDate))) {
            panels.HomePageTemp.cases.editNewEnrollmentDuringOE = panels.HomePageTemp.cases.editNewEnrollmentDuringOE.filter((val) => {
              return val != 'currentBenefitsPanel' && val != 'currentBenefitsPanel4'
            })
          } else if (count === 0) {
            panels.HomePageTemp.cases.editNewEnrollmentDuringOE = panels.HomePageTemp.cases.editNewEnrollmentDuringOE.filter((val) => {
              return val != 'currentBenefitsPanel'
            })
          } else {
            panels.HomePageTemp.cases.editNewEnrollmentDuringOE = panels.HomePageTemp.cases.editNewEnrollmentDuringOE.filter((val) => {
              return val != 'currentBenefitsPanel4'
            })
          }
        } else {
          if (getContractChanges === true && enrollmentPeriodKind === 'cAnnualEnrollment') {
            panels.HomePageTemp.cases.editNewEnrollmentDuringOE = panels.HomePageTemp.cases.editNewEnrollmentDuringOE.filter((val) => {
              return val != 'currentBenefitsPanel' && val != 'currentBenefitsPanel4'
            })
          } else {
            panels.HomePageTemp.cases.editNewEnrollmentDuringOE = panels.HomePageTemp.cases.editNewEnrollmentDuringOE.filter((val) => {
              return val != 'currentBenefitsPanel' && val != 'currentBenefitsPanel4'
            })
          }
        }
      }
      if (storeFromRedux?.employeeProfile?.enrollmentType === 'case14') {
        members = members?.filter(element => element.isEvenGreen === true)
        if (members.length > 0) {
          if (enrollmentPeriodKind === 'cAnnualEnrollment') {
            let count = 0
            members.map((product) => {
              if ((Date.parse(today) <= Date.parse(previousOngoingDate))) {
                let coverageEffectiveDate = product?.effectiveDate;
                let coverageStatus = product?.status;
                if ((Date.parse(coverageEffectiveDate) < Date.parse(lastRenewalDate)) && coverageStatus === 'cActive') {
                  count++
                }
              }
            })
            if (count === 0) {
              panels.HomePageTemp.cases.case14 = panels?.HomePageTemp?.cases?.case14.filter((val) => {
                return val != 'currentBenefitsPanel2' && val != 'currentBenefitsPanel3'
              })
            } else {
              panels.HomePageTemp.cases.case14 = panels?.HomePageTemp?.cases?.case14.filter((val) => {
                return val != 'currentBenefitsPanel2' && val != 'currentBenefitsPanel4'
              })
            }
          } else {
            panels.HomePageTemp.cases.case14 = panels?.HomePageTemp?.cases?.case14.filter((val) => {
              return val != 'currentBenefitsPanel2' && val != 'currentBenefitsPanel4'
            })
          }
          managePanelsForCurrentBenefitsNoCertificat(panels, members);
        } else {
          panels.HomePageTemp.cases.case14 = panels?.HomePageTemp?.cases?.case14.filter((val) => {
            return val != 'currentBenefitsPanel3' && val != 'currentBenefitsPanel4'
          })
        }
      }
      if (storeFromRedux?.employeeProfile?.enrollmentType === 'case15') {
        if (activeContractDatas.length > 0 && activeContractDatas?.[0]?.status === 'cActive') {
          panels.HomePageTemp.cases.case15 = panels.HomePageTemp.cases.case15.filter((val) => {
            return val != 'currentBenefitsPanelLOA'
          })
        } else {
          panels.HomePageTemp.cases.case15 = panels.HomePageTemp.cases.case15.filter((val) => {
            return val != 'currentBenefits' && val != 'reviewCurrentBenefits' && val != 'reviewAndUpdatetBtn' && val != 'listComponentBanner2'
          })
        }
      }
      if (storeFromRedux?.employeeProfile?.enrollmentType === 'case5') {
        if (declinedCertificate) {
          panels.HomePageTemp.cases.case5 = panels.HomePageTemp.cases.case5.filter((val) => {
            return val != 'openEnrollmentImagePanel' && val != 'newHireEnrollmentCurrent'
          })
        } else {
          panels.HomePageTemp.cases.case5 = panels.HomePageTemp.cases.case5.filter((val) => {
            return val != 'currentBenefitsPanel3' && val != 'elegibleBenefits'
          })
        }
      }
      if (panels && exampleCase) {
        let isBeneficiary = enrollmentDetails?._Result?.subscribedElements?.some(el => el?.isBeneficiary === true);
        if (!isBeneficiary) {
          panels.HomePageTemp.cards.map((e, index) => {
            e.cardMembers.map((k, index2) => {
              let cardElements = k.cardElements.filter((t) => {
                return t.name != 'manageBeneficiariesSection'
              })
              panels.HomePageTemp.cards[index].cardMembers[index2].cardElements = cardElements
            })
          })
          panels.HomePageTemp.cases[exampleCase] = panels.HomePageTemp.cases[exampleCase].filter((val) => {
            return val != 'manageBeneficiariesPanel'
          })
        }
      }
      if (panels && exampleCase) {
        const additionalDatas = storeFromRedux?.process?.contractsLinearOrNonLinear?._Result?.contract?.additionalDatas;
        let qleApplicable = additionalDatas?.filter(e => e?.fieldCode === 'DD_00200');
        let isQle = false;
        if (qleApplicable?.length > 0 && qleApplicable?.[0]?.fieldValue?.length > 0) isQle = true
        let effectiveDate = activeContractDatas?.[0]?.effectiveDate;
        let effectiveDateOfChange = currentdateCheck(effectiveDate, today);
        let dateArray = enrollmentData?.filter(e => e?.value?.fieldName === 'ENROLWINSTDATE' || e.value.fieldName === 'ENROLWINEDDATE');
        //New Hire: To know if employee is in New Hire Enrollment Period 
        let isEnrollmentPeriod = dateCheck(dateArray?.[0]?.value?.fieldValue, dateArray?.[1]?.value?.fieldValue, today);
        if (isEnrollmentPeriod || effectiveDateOfChange) {
          panels.HomePageTemp.cases[exampleCase] = panels.HomePageTemp.cases[exampleCase].filter((val) => {
            return val != 'lifeEventPanel'
          })
          panels.HomePageTemp.cases[exampleCase] = panels.HomePageTemp.cases[exampleCase].filter((val) => {
            return val != 'profileInformationPanelGroup'
          })
        } else if (!isQle) {
          panels.HomePageTemp.cases[exampleCase] = panels.HomePageTemp.cases[exampleCase].filter((val) => {
            return val != 'lifeEventPanel'
          })
          panels.HomePageTemp.cases[exampleCase] = panels.HomePageTemp.cases[exampleCase].filter((val) => {
            return val != 'profileInformationPanelGroup'
          })
        } else {
          panels.HomePageTemp.cases[exampleCase] = panels.HomePageTemp.cases[exampleCase].filter((val) => {
            return val != 'profileInformationPanelSingle' && val != 'manageBeneficiariesPanel'
          })
        }
      }
      SetContentData((prevContentData) => ({
        ...prevContentData,
        panelsData: { ...panels },
      }));
    }
  };

  useEffect(() => {
    /* use effect to get forms which contains element properties */
    if (panel && content.layout && storeFromRedux?.entities?.response?.onLoadResponseList?.subscribedcontracts
      ?._Result?.[0]?.contract?.buid && storeFromRedux?.entities?.response?.onLoadResponseList?.getListOfEligibleProductsForMember && storeFromRedux?.employeeProfile?.enrollmentType) {
      const callForms = async () => {
        await getPanels();
      };
      callForms();
    }

  }, [panel && content.layout && storeFromRedux?.entities?.response?.onLoadResponseList?.subscribedcontracts
    ?._Result?.[0]?.contract?.buid && storeFromRedux?.entities?.response?.onLoadResponseList?.getListOfEligibleProductsForMember && storeFromRedux?.employeeProfile?.enrollmentType]);


  // Preferred Communication logic
  const postSalesForceBearerToken = async () => {
    let contactDtailsAPI = {}
    let personalDetails = primaryIdetifierHelper.getPersonalDetails(employeeIdFromRedux, dataFromReduxPersonalInformation, oneCode);
    if (personalDetails.lastName === undefined || personalDetails.lastName === "") {
      return true
    }
    //personalDetails.oneCode = oneCode
    http
      .post(appConfig.SSP_MID_URL + '/getCapturePreferenceAPI', personalDetails, { timeout: content.salesforceAPITimeout })
      .then(async (resp) => {
        if (resp?.data) {
          contactDtailsAPI.personalPhone = resp?.data?.smsPhoneNumber
          contactDtailsAPI.personalEmail = resp?.data?.emailAddress
          contactDtailsAPI.preferredContact = resp?.data?.preferredMethodOfCommunication
          contactDtailsAPI.clientSMSFlag = resp?.data?.clientSMSFlag
          contactDtailsAPI.clientEmailFlag = resp?.data?.clientEmailFlag
          contactDtailsAPI.contactId = resp?.data?.contactId
          contactDtailsAPI.OfferPreferenceUpdate = resp?.data?.OfferPreferenceUpdate
          contactDtailsAPI.contactSMSOptinFlag = resp?.data?.contactSMSOptinFlag
        }
        dispatch(UPDATE_PREFERRED_CONTACT(contactDtailsAPI))

      })
      .catch((e) => {

      });
  };


  useEffect(() => {
    if (employeeIdFromRedux && dataFromReduxPersonalInformation && oneCode !== 'undefined') {
      postSalesForceBearerToken()
    }

    //getHelpdeskInfo()
  }, [dataFromReduxPersonalInformation])

  function dateCheck(from, to, check) {

    var fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if ((cDate <= lDate && cDate >= fDate)) {
      return true;
    }
    return false;
  }

  function currentdateCheck(from, check) {

    var fDate, cDate;
    fDate = Date.parse(from);
    cDate = Date.parse(check);

    if ((fDate > cDate)) {
      return true;
    }
    return false;
  }

  let enrollmentType = '';
  let reasonType = '';


  useEffect(() => {
    if (contractData && currentEnrollmentPeriod && eligibleProductsForMember) {
      let empStatus = ['LOA','PDL', 'LOAPLTFC', 'LOAPLT', 'LOAEXECFC', 'LOAEXEC', 'LOAUGEFC', 'LOAUGE', 'LOAUGEMFC', 'LOAUGEM']
      //const additionalDatas = storeFromRedux?.process?.contractsLinearOrNonLinear?._Result?.contract?.additionalDatas;
      //let allEvenGreen = additionalDatas?.filter(e => e?.fieldCode === 'IEGP');
      //let isEvengreenClient = allEvenGreen?.length > 0 ? allEvenGreen?.every(e => e?.fieldCode === 'IEGP' && e?.fieldValue === true) : false;
      const additionalDatas = storeFromRedux?.process?.contractsLinearOrNonLinear?._Result?.subscribedElements;
      let allEvenGreen = []
      let allEvenGreenSub = []
      additionalDatas?.filter((e) => {
        if (e?.subscribedProduct?.status === 'cActive') {
          let data = e?.subscribedProduct?.additionalDatas.filter(
            (elm) => elm?.fieldCode === "IEGP"
          );
          if (data?.length > 0) {
            allEvenGreen.push(data?.[0])
          }
          let dataSub = e?.subscribedProduct?.additionalDatas.filter(
            (elm) => elm?.fieldCode === "DD_00205"
          );
          if (dataSub?.length > 0) {
            allEvenGreenSub.push(data?.[0])
          }
        }
      });
      let isEvengreenClient = allEvenGreen?.length > 0 ? allEvenGreen?.every(e => e?.fieldCode === 'IEGP' && e?.fieldValue === true) : false;
      let isEvengreenSubClient = allEvenGreenSub?.length > 0 ? allEvenGreenSub?.every(e => e?.fieldCode === 'DD_00205' && e?.fieldValue === 'No') : false;
      let isEvengreenProduct = eligibleProductsForMember?.length > 0 ? eligibleProductsForMember?.every(element => element.isEvenGreen === true) : false
      let isPartialEvengreenProduct = eligibleProductsForMember?.length > 0 ? eligibleProductsForMember?.filter(element => element.isEvenGreen === true) : []
      let enrollmentPeriodKind = currentEnrollmentPeriod?.enrollmentPeriodKind;
      let dateArray = enrollmentData?.filter(e => e.value.fieldName === 'ENROLWINSTDATE' || e.value.fieldName === 'ENROLWINEDDATE');
      let empStatusCat = enrollmentData?.filter(e => e.value.fieldName === 'EMPSTATUSCAT') || [];
      let eligibleStatus = ['INE', 'ERR:Stat', 'EMPDIS', 'STRIKE', 'ACTN', 'ERR']
      let currenteligibleProductsForMember = storeFromRedux?.entities?.response?.onLoadResponseList?.getCurrerntYearEnrollments?._Result?.subscribedElements;
      let currenteligibleIsEvengreenProduct = currenteligibleProductsForMember?.length > 0 ? currenteligibleProductsForMember?.every(element => element.isEvenGreen === true) : false

      ////New Hire: To know if employee is in New Hire Enrollment Period 
      let isEnrollmentPeriod = dateCheck(dateArray?.[0]?.value?.fieldValue, dateArray?.[1]?.value?.fieldValue, today);

      //Annual Period: To know if there is an Annual Enrollment period open in the GBP conrtact
      let isAnnualPeriod = dateCheck(currentEnrollmentPeriod?.effectiveDate, currentEnrollmentPeriod?.endDate, today);

      //Existing Contract: To kwow if there is an existing member contract
      let activeContract = activeContractDatas.length > 0 ? activeContractDatas?.[0]?.status : "cInactive";

      //Already Reenroll: To now of the member already reeroll
      //If EffectiveDateOfChange>currentDate mean the employee already reenroll
      let effectiveDate = lastContractChange?.effectiveDateOfChange;
      let effectiveDateOfChange = currentdateCheck(effectiveDate, today);
      let concernedMemberClasses = storeFromRedux?.process?.contractsLinearOrNonLinear?._Result?.currentEnrollmentPeriod?.concernedMemberClasses?.filter(e => e.valueCode === empStatusCat?.[0]?.value?.valueCode && e.fieldValue === empStatusCat?.[0]?.value?.fieldValue)?.[0]?.valueCode;
      let isSpecialEnrollment = isAnnualPeriod && enrollmentPeriodKind === 'cOpenEnrollment' && concernedMemberClasses? true : false
      if (isAnnualPeriod && enrollmentPeriodKind === 'cAnnualEnrollment') {
        if (activeContract === 'cActive') {
          if (effectiveDateOfChange) {
            enrollmentType = 'editEnrollmentDuringOE'; //Edit Enrollment During OE
            reasonType = 'AE'  //Annual Enrollment
          } else {
            enrollmentType = 'open-reenrollment'; //Open enrollment
            reasonType = 'AE'  //Annual Enrollment
          }
        } else {
          if (isEnrollmentPeriod) {
            enrollmentType = 'case5'; //New Hire
            //reasonType = 'OE'  //Ongoing Enrollment for the 1st enrollment, then Annual Enrollment for the next year reenrollment
            reasonType = declinedCertificate ? 'AE' : 'NH'  //Ongoing Enrollment for the 1st enrollment, then Annual Enrollment for the next year reenrollment
            //else if ((contractData?.length === 0 || statusCheck?.length > 0) && (Date.parse(today) > Date.parse(dateArray?.[1]?.value?.fieldValue))) {
          } else if ((contractData?.length === 0 || statusCheck?.length > 0) && ((Date.parse(today) > Date.parse(dateArray?.[1]?.value?.fieldValue) || !dateArray?.[1]?.value?.fieldValue))) {
            enrollmentType = 'case14'; //No existing certificate and coming to portal for first time during annual enrollment 
            reasonType = 'AE'  //Annual Enrollment
          } else {
            enrollmentType = 'newOE'; //New Enrollment
            reasonType = 'AE'  //Annual Enrollment
          }
        }
      } else {
        if (activeContract === 'cActive') {
          if (isEnrollmentPeriod) {
            if (effectiveDateOfChange && previousOngoingDate && enrollmentPeriodKind === 'cOngoingEnrollment' &&
              (Date.parse(today) > Date.parse(previousOngoingDate)) &&
              (Date.parse(today) < Date.parse(lastRenewalDate))
            ) {
              enrollmentType = 'editNewEnrollmentDuringOE';//Edit enrollment - OE closed
              reasonType = 'OE'  //Ongoing Enrollment
            } else {
              enrollmentType = 'editNewEnrollmentDuringOE2';//Edit enrollment - OE when enrollment period is on
              //reasonType = 'OE'  //Ongoing Enrollment
              reasonType = 'NH'  //Ongoing Enrollment
            }
          } else {
            enrollmentType = 'editNewEnrollmentDuringOE';//Edit enrollment - OE closed
            reasonType = isSpecialEnrollment ? 'OP': 'OE'  //Ongoing Enrollment
          }
        } else if (isEnrollmentPeriod) {
          enrollmentType = 'case6';//New Hire OE Closed
          //reasonType = 'OE'  //Ongoing Enrollment
          reasonType = 'NH'  //Ongoing Enrollment
        } else if (eligibleProductsForMember?.length === 0 && (contractData?.length === 0 || statusCheck?.length > 0) && (Date.parse(today) > Date.parse(dateArray?.[1]?.value?.fieldValue))) {
          enrollmentType = 'case12'; //No existing certificate and coming to portal for first time during ongoing enrollment 
          reasonType = isSpecialEnrollment ? 'OP': 'OE'  //Ongoing Enrollment
        }else if (isSpecialEnrollment) {
            enrollmentType = 'case13'; //No existing certificate during Special enrollment
            reasonType = 'OP'  //Special Enrollment
        }else {
          enrollmentType = 'caseNotEligible';//Not Eligible
          reasonType = 'OE'
        }
      }
      /* checking for evergeen client */
      if (isEvengreenClient) {
        if (activeContract === 'cActive') {
          if (currenteligibleIsEvengreenProduct && isEvengreenSubClient) {
            enrollmentType = 'case9';// EverGeen and have Existing Employee/Certificate
          }
        } else {
          if ((contractData?.length === 0 || statusCheck?.length > 0) && (Date.parse(today) > Date.parse(dateArray?.[1]?.value?.fieldValue))) {
            enrollmentType = 'case13'; //No existing certificate and coming to portal for first time during ongoing enrollment 
          } else {
            enrollmentType = 'case8'; //everGreen and no Existing Employee/Certificate
            reasonType = 'OE'
          }

        }
      } else if (!isAnnualPeriod && enrollmentPeriodKind !== 'cAnnualEnrollment' && isPartialEvengreenProduct.length > 0 && (contractData?.length === 0 || statusCheck?.length > 0) && (Date.parse(today) > Date.parse(dateArray?.[1]?.value?.fieldValue))) {
        enrollmentType = 'case13'; //No existing certificate and coming to portal for first time during ongoing enrollment 
      }
      /* checking for LE- Kaiser and evergeen product */
      if ((!isEnrollmentPeriod && !isEvengreenClient && isEvengreenProduct && dateArray?.[1]?.value?.fieldValue === null) || (!isSpecialEnrollment && !isEnrollmentPeriod && !isEvengreenClient && isEvengreenProduct && isAnnualPeriod && enrollmentPeriodKind === 'cOpenEnrollment')) {
        if (activeContract === 'cActive') {
          if (currenteligibleIsEvengreenProduct) {
            enrollmentType = 'case9';// EverGeen and have Existing Employee/Certificate
          }
        } else {
          enrollmentType = 'case8'; //everGreen and no Existing Employee/Certificate
          reasonType = 'OE'
        }
      }
      /* checking for LOA */
      if (empStatusCat?.length > 0 && empStatus?.includes(empStatusCat?.[0]?.value?.valueCode) && eligibleProductsForMember?.length === 0) {
        enrollmentType = 'case15'; // LOA
      }
      if (empStatusCat?.length > 0 && eligibleStatus.includes(empStatusCat?.[0]?.value?.valueCode)) {
        setExampleCase('caseNotEligible')
        dispatch(ENROLLMENT_TYPE({ enrollmentType: 'caseNotEligible' }))
      } else if (!!dateArray?.[1]?.value?.fieldValue && (Date.parse(dateArray?.[0]?.value?.fieldValue) > Date.parse(today)) && enrollmentType === 'caseNotEligible') {
        setExampleCase('caseNotEligible')
        dispatch(ENROLLMENT_TYPE({ enrollmentType: 'caseNotEligible' }))
      } else {
        if (enrollmentType !== 'caseNotEligible') {
          setExampleCase(enrollmentType)
        }
        dispatch(ENROLLMENT_TYPE({ enrollmentType, reasonType, currentYearRason: reasonType === 'NH' ? 'NH' : 'OE' }))

      }

    }
  }, [contractData && currentEnrollmentPeriod && eligibleProductsForMember]);



  const showContent = (contentData && content && content.layout && content.layout.viewType && contentData.panelsData && contentData.panelsData[content.layout.viewType] && contentData.panelsData[content.layout.viewType].cases && contentData.panelsData[content.layout.viewType].cases[exampleCase]) || [];
  const headerContent = (contentData && content && content.layout && content.layout.viewType && contentData.panelsData && contentData.panelsData[content.layout.viewType] && contentData.panelsData[content.layout.viewType].header) || {};
  return (
    <div>
      <MessageComponent currentPage={"welcomeScreen"} />
      <div>
        <div>
          <Toaster
            position="top-center"
            toastOptions={{
              className: 'toast-message',
              duration: 10000,

              style: {
                border: '1px solid #61d34',
                padding: '5px',
                fontSize: '13px',
                color: '#fff',
                background: '#0077A0',
              },
            }}
          />
        </div>
        {/* <select name="cases" id="cases" onChange={(event) => setExampleCase(event.target.value)}>
          <option value="newOE">New Enrollment</option>
          <option value="editNewEnrollmentDuringOE">Edit enrollment - OE closed</option>
          <option value="open-reenrollment">Open enrollment</option>
          <option value="editEnrollmentDuringOE">Edit Enrollment During OE</option>
          <option value="case5">New Hire</option>
          <option value="case6">New Hire OE Closed</option>
        </select> */}
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {isLoading || loaderCount > 0 ? <Loader loading={isLoading} className="loaderUpdateCls" /> : ""}
        <SectionHeader
          rightLink={(content.view && content.view.rightLink) || ''}
          title={(content.view && content.view.title) || ''}
          showContent={showContent}
          sectionElements={(headerContent && headerContent.elements) || []}
          /* sectionHeaderButtons={
            (content.layout &&
              content.layout.sectionHeader &&
              content.layout.sectionHeader.sectionHeaderButtons) ||
            []
          } */
          current={(headerContent && headerContent.view && content.view.displayName) || ''}
          stpClick={false}
          rightColumnClass={(headerContent && headerContent.rightColumnClass) || ''}
          leftColumnClass={(headerContent && headerContent.leftColumnClass) || ''}
          mappingObject={(content.view && content.view.mappingObject) || {}}
          config={(content.view && content.view.config) || {}}
        />
        <ColorBanner
          contentData={contentData}
          content={content}
          showContent={showContent}
        />
        <div className="groupInfoWrapper">
          <CardGenerator
            key={`${`index`} element`}
            contentData={contentData}
            content={content}
            showContent={showContent}
            {...props}
          />
        </div>
      </Suspense>
    </div>
  );
};

export default HomePage;
