import React from 'react';
import UserInformation from '../../../Layout/UserInformation/GetUserInformation';
import RetrieveUserID from '../../../Molecules/CustomSection/MFA/RetrieveUserID/index';
import ChangeMFAContact from '../../../Molecules/CustomSection/MFA/ChangeMFAContact';

export default [
  // {
  //   path: '/retrieveUserID',
  //   render: (props) => <UserInformation type="EmployeeRegistration" {...props} />,
  // },
  {
    path: '/retrieveUserID',
    render: (props) => <RetrieveUserID type="EmployeeRegistration" {...props} />,
  },
  {
    path: '/changeMFAContact',
    render: (props) => <ChangeMFAContact {...props} />,
  },
];
