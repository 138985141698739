import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import Elementor from '../../Cells/Form/Elementor';

const ListContainer = (props) => {
    const {
        containerStyle = {},
        containerClassName = '',
        elements = [],
        showContent = [],
        id = '',
    } = props;
    return showContent.includes(id) || showContent.length === 0 ?(
        <div className={containerClassName} style={containerStyle}>
            {elements.map((element, key) => {
                return showContent.includes(element.id) || showContent.length === 0 ?(
                    <Elementor
                        key={element.name}
                        element={element}
                        showContent={showContent}
                        {...props}
                    />
                ) : null;
            })}
        </div>
    ) : null;

};

ListContainer.propTypes = {
    containerStyle: PropTypes.object,
    containerClassName: PropTypes.string,
    elements: PropTypes.array,
    showContent: PropTypes.array,
    id: PropTypes.string,
};

ListContainer.defaultProps = {
    containerStyle: {},
    containerClassName: '',
    elements: [],
    showContent: [],
    id: '',
};

export default ListContainer;
