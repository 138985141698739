import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onLoadAPI, onLoadProductAPI } from '../../../../../Store/Slices/Entities/response';
import { UPDATE_LOADING, UPDATE_WAIVE_PRODUCT } from '../../../../../Store/Slices/InsuredInfo';
import { CHECK_ELIGIBILITY } from '../../../../../Store/Slices/InsuredInfo/index.js';
import Loader from '../../../../Atoms/Loader';
import ServiceHelper from '../../../../Utils/Helper/MappingHelper';
import { CheckCoveredPersonsEligibilityForCoverage } from '../../Actions/CheckCoveredPersonsEligibilityForCoverage';
import { createOrUpdateInsuredQuestionAnswer } from '../../Actions/createOrUpdateInsuredQuestionAnswer';
import { prepareSubscriptionForChoice } from '../../Actions/prepareSubscriptionForChoice.js';
import { reworkProductEnrollment } from '../../Actions/reworkProductEnrollment';
import { unsubscribeCoverage } from '../../Actions/unsubscribeCoverage';
import Collapse from '../../Atoms/Collapse';
import { Button, Checkbox } from '../../Atoms/index';
import Content from '../../Content/GenericContent.json';
import { continueBtnLabelHelper, memberTypes, possibleLabelConstants, screenNames } from '../../Helper/continueBtnLabelHelper';
import { unsubscribeCoverageDetails } from '../../Actions/productDetails';
import { useNavigationHook } from '../../Hooks/NavigationHook';
import InsuredQuestions from './InsuredQuestions';
import ProductDescription from './ProductDescription';
import { getErrorMessages } from '../../Helper/MappingHelper';
import { getCoverageEligibility } from '../../Helper/commonHelper';
import { UPDATE_ENROLLMENT_DATA } from '../../../../../Store/Slices/Enrollment';
import http from '../../../../../Common/Services/HttpService';
import appConfig from '../../../../../appConfig';
import { UPDATE_CART_ITEM_NEW } from '../../../../../Store/Slices/EmployeeProfile';
import { SET_BASE_PLAN_DECLINED_LOCALLY_EMPLOYEE, SET_BASE_PLAN_DECLINED_LOCALLY_SPOUSE } from '../../../../../Store/Slices/BuyUp/index.js';

const EmployeeInsuredQuestionsContent = (props) => {
  const { currentStep, stepKeys, formsConfig } = useNavigationHook();

  const serviceHelper = new ServiceHelper();
  const [checkEnableBtn, setCheckEnableBtn] = useState(true);
  const [checkNextStep, setCheckNextStep] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [disableAccordian, setDisableAccordian] = useState(false);
  const [callApi, setCallApi] = useState("onLoad");
  const [currentBuids, setCurrentBuids] = useState([]);
  //const [buttonText, setButtonText] = useState("Continue to Summary");
  //const [continueBtnLabel, setContinueBtnLabel] = useState("Continue");
  const [continueBtnLabel, setContinueBtnLabel] = useState('')
  const [currentBuidsPerson, setCurrentBuidsPerson] = useState([]);
  const [coverageError, setCoverageError] = useState([]);
  const [isCoverageApiError, setIsCoverageApiError] = useState(false);
  const [nextStepFlow, setNextStepFlow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [checkEmpEligible, setCheckEmpEligible] = useState(true);
  const [userAnsweredInsuredQuestionFlag, setUserAnsweredInsuredQuestionFlag] = useState(false)

  const { employeeIndex, handleCoverageChange } = props;

  const buid = props.employee[employeeIndex]?.personBuid;
  const dispatch = useDispatch();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { onBack, nextStep, flowType, onNext } = props;
  const { insuredInfo, process } = useSelector((storeState) => storeState);
  const eligibilityObj = insuredInfo?.[currentStep]?.coverageInsuredEligiblity;


  let coverageEligiblity = getCoverageEligibility({ eligibilityArray: eligibilityObj, personBuid: buid })
  let currentReduxState = useSelector((storeState) => storeState);
  const searchPolicyProcessResource = useSelector((storeState) => storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result)

  const getAllPossibleChoicesConfiguration = currentReduxState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]

  let productFamily = formsConfig.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];

  let insuredQuestionAnswers = insuredInfo[currentStep]?.InsuredQuestionAnswers?.[buid];
  let insuredQuestionAnswersArr = insuredInfo[currentStep]?.InsuredQuestionAnswers;
  let subscribedElements = currentReduxState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]?.ValidateSubscribedProductData?._Result?.contract?.subscribedElements;
  let subscribedElementsData = subscribedElements?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
  let subscribedProductBuid = subscribedElementsData?.[0]?.subscribedProduct?.buid;
  const validateSubscribedElements = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements

  // [PR] This condition is added for Resume functionality to fix reworkProductEnrollment API
  if (!subscribedProductBuid) {
    const validateContractSubscribedElements = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements
    const selectedProduct = validateContractSubscribedElements?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
    subscribedElements = selectedProduct
    subscribedProductBuid = selectedProduct?.[0]?.subscribedProduct?.buid;
  }

  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData?.contract?.subscribedElements);
  let declinedProduct = enrollmentData?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
  let isDeclinedProduct = declinedProduct?.[0]?.subscribedProduct?.status === "cDeclined" || declinedProduct?.[0]?.subscribedProduct?.status === "cNeverInForce" || declinedProduct?.[0]?.subscribedProduct?.status === "cTerminated" ? true : false
  let waiveCoverage = insuredInfo[currentStep]?.WaiveCoverage;
  let isWaiveCoverage = false
  if (waiveCoverage) {
    let waiveCoverageDetails = waiveCoverage.filter(e => e.personBuid === buid);
    if (waiveCoverageDetails.length > 0) {
      isWaiveCoverage = waiveCoverageDetails?.[0]?.waiveProduct ? true : false
    }
  }
  // this is for show/hide waive coverage based on insured questions

  const showWaiveCoverage = insuredInfo[currentStep]?.showWaive;
  let personBuid = buid;

  let coverageDetails = insuredInfo?.[currentStep]?.offeredCoverageBuids?.[0];
  if (props.flowType === 2 || props.flowType === 3) {
    coverageDetails = insuredInfo?.[currentStep]?.offeredCoverageBuids?.filter((e) => e?.coveragePersonBuid?.includes(personBuid))[0];
  }


const spbuid = props.spouse[0]?.personBuid;
const memberSpouseEligible = insuredInfo[currentStep]?.coverageInsuredEligiblity?.find(element =>element?.coveredPerson?.bUID == spbuid)?.isEligible;
let errorData = false;
coverageError.forEach((pobj) => {
  if (pobj.error && pobj.buid === spbuid) {
    errorData = true;
  }
});
  let isEmployeeBuyUpEnabled = false;
  isEmployeeBuyUpEnabled = useSelector((storeState) => storeState.buyUp.eligibility.employee);
  let basePlanBoughtCurrentYear = true;
  basePlanBoughtCurrentYear = useSelector((storeState) => storeState.buyUp.basePlanBoughtCurrentYearFlag.employee);
  let isBuyUpFlow = false;
  let buyUpDeclinedLocally = useSelector((storeState) => storeState.buyUp.basePlanDeclinedLocally.employee) || false
  if(buyUpDeclinedLocally) {
    isBuyUpFlow = true
  } else {
    isBuyUpFlow = isEmployeeBuyUpEnabled ? (basePlanBoughtCurrentYear ? false : true ) : false;
  }
  let costPerPayPeriodArr = [];
  costPerPayPeriodArr = useSelector((storeState) => storeState.buyUp.payPerPeriodCost);
  let buyupProductcoverageBuid = useSelector((storeState) => storeState.buyUp.planBuid)

  

  if(isBuyUpFlow) {
    isWaiveCoverage = buyUpDeclinedLocally
  } 

  const updateCost = (isWaived) => {
    if (isBuyUpFlow) {
      if (costPerPayPeriodArr && costPerPayPeriodArr.length) {
        //updating it for both employee and spouse here
        costPerPayPeriodArr.map((item) => {
          if (isWaived) {
            handleCoverageChange(item?.personBuid, null, true, item?.coverageBuid, '', item?.member)
          } else {
            handleCoverageChange(item?.personBuid, 0, false, item?.coverageBuid, item?.cost, item?.member)
          }
        })
      }
    }
  }

  useEffect(() => {
    if (buyupProductcoverageBuid === productIdentifierBuid) {
      updateCost(isWaiveCoverage)
    }
  }, [isBuyUpFlow])

  const currentStepIndex = stepKeys.findIndex(step => step === currentStep);
  let enrollmentProcessBuid = process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
  const currentOfferedCoverageBuids = currentReduxState?.entities?.response?.onLoadResponseList?.[currentStep]?.getAllPossibleChoicesConfigurationForProduct?._Result || [];
  const vdk = (event) => {
    const waiveProduct = event.target.checked;
    setIsCheckboxChecked(waiveProduct);
    setContinueBtnLabel(continueBtnLabelHelper({
      flowType: props.flowType,
      screenType: screenNames.INSURED_QUESTIONNAIRE_SCREEN,
      isWaivedOffFlag: waiveProduct,
      productIdentifierBuid,
      spouseCount: props.spouse.length,
      childrenCount: props.children.length,
      memberIdentifier: memberTypes.EMPLOYEE,
      showEdit: showEdit,
      memberDetails: {
        count: 1,
        index: 0,
        eligible: coverageEligiblity,
        buid: personBuid
      }
    }))
    updateCost(waiveProduct)
    dispatch(UPDATE_WAIVE_PRODUCT({ personBuid, waiveProduct, currentStep }));
    if(isBuyUpFlow) {
      dispatch(SET_BASE_PLAN_DECLINED_LOCALLY_EMPLOYEE(waiveProduct))
      dispatch(SET_BASE_PLAN_DECLINED_LOCALLY_SPOUSE(waiveProduct))
    } 
  }

  useEffect(() => {
    setIsCheckboxChecked(false)
  }, [productIdentifierBuid])

  const waiveProduct = insuredInfo[currentStep]?.WaiveCoverage?.find(item => item && item.personBuid === personBuid)?.waiveProduct
  useEffect(() => {
    if (waiveProduct) {
      setCheckEnableBtn(false)
    } else {
      if (userAnsweredInsuredQuestionFlag) {
        setCheckEnableBtn(false)
      } else {
        setCheckEnableBtn(true)
      }
    }
  }, [waiveProduct, userAnsweredInsuredQuestionFlag])


  //   useEffect(() => {
  //     setPrepareApiOneTime(false);
  //   }, [currentStep]);
  //   useEffect(() => {
  //     if (!enrollmentProcessBuid || !getAllPossibleChoicesConfiguration || !insuredInfo?.[currentStep]?.offeredCoverageBuids?.length) {
  //         return;
  //     }

  //     let existingIndex = insuredInfo[currentStep]?.subscribeCoverageBuids?.findIndex(
  //         (coverage) => coverage && coverage?.personBuid === personBuid
  //     );

  //     // if ( insuredInfo[currentStep]?.subscribeCoverageBuids?.length && existingIndex !== -1) {
  //     //     return;
  //     // }

  //     let coverageDetails = insuredInfo?.[currentStep]?.offeredCoverageBuids[0];

  //     if (props.flowType === 2 || props.flowType === 3) {
  //         coverageDetails = insuredInfo?.[currentStep]?.offeredCoverageBuids.filter((e) => e?.coveragePersonBuid?.includes(personBuid))[0];
  //     }

  //     if (coverageDetails && !prepareApiOneTime ) {
  //       setPrepareApiOneTime(true)
  //         dispatch(prepareSubscriptionForChoice(
  //             enrollmentProcessBuid,
  //             coverageDetails?.coverage,
  //             coverageDetails?.coveragePersonBuid,
  //             currentStep,
  //             personBuid,
  //         ));
  //     }

  // }, [currentStep, getAllPossibleChoicesConfiguration, insuredInfo, enrollmentProcessBuid, personBuid, props.flowType, prepareApiOneTime]);
  const validateContractInsure = currentReduxState?.entities.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.insureds || []
  let nonSharedAdditionalDataSet = insuredInfo[currentStep]?.nonSharedAdditionalDatas;

  const declinedProductAPICall = async () => {
    dispatch(UPDATE_LOADING(true));
    return await http
      .post(appConfig.SSP_MID_URL + '/declineProduct', {
        payload: {
          enrollmentProcessBuid: enrollmentProcessBuid,
          subscribedProductBuid: subscribedElements,
          productIdentifierBuid: productIdentifierBuid,
          currentStepInfo: {
            "portalStep": currentStep
          }
        }
      })
      .then((resp) => {
        if (resp.data && resp.status === 200) {
          dispatch(onLoadAPI({ ...{ [`declineProduct`]: resp.data } }));
          const processInfo = resp?.data?._Result ?? {};
          props.onNext(processInfo?.processEntity?.currentStepNb)
          dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
          if(isBuyUpFlow) {
            dispatch(SET_BASE_PLAN_DECLINED_LOCALLY_EMPLOYEE(true))
            dispatch(SET_BASE_PLAN_DECLINED_LOCALLY_SPOUSE(true))
          }
          dispatch(
            UPDATE_CART_ITEM_NEW({
              id: currentStep,
              moreThanOne: true,
              declineProduct: true
            })
          );
        }
        dispatch(UPDATE_LOADING(false));
      }).catch((error) => {
        dispatch(UPDATE_LOADING(false));
      })
  }

  const nextStepCall = async () => {
    if (personBuid && isWaiveCoverage === false && insuredQuestionAnswers) {
      setShowLoader(true);
      let actionDispatched = await dispatch(createOrUpdateInsuredQuestionAnswer({
        personBuid,
        productIdentifierBuid,
        insuredQuestionAnswers,
        currentStep,
        currentOfferedCoverageBuids,
        enrollmentProcessBuid,
        validateContractInsure,
        nonSharedAdditionalDataSet
      }));

      if (actionDispatched) {
        await checkCoveragePersonEligibility();
        setShowLoader(false);
        return true
      } else {
        setShowLoader(false);
        return false
      }
      // refactor this code && props.flowType != 1 
    } else if (personBuid && props.flowType !== 1 && isWaiveCoverage === true) {
      //setShowLoader(true);
      //await prepareSubscriptionForChoice()
      await declinedProductAPICall();
      
    } else {
      //return await validateSubscribedProductdata();
      props.nextStep()
      return true
    }


  }
  const reworkProductData = async () => {
    let currentStepInfo = {
      "portalStep": currentStep,
      "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
      "reachedPreConfirmation": "no"
    };
    try {
      await dispatch(reworkProductEnrollment({
        enrollmentProcessBuid: enrollmentProcessBuid,
        subscribedProductIdentifierBuid: subscribedProductBuid,
        externalStepContext: currentStepInfo,
        currentStep: currentStep
      }));

      if (flowType === 1) {
        let coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids[0];
        await dispatch(prepareSubscriptionForChoice(
          enrollmentProcessBuid,
          coverageDetailsForPrepare?.coverage,
          coverageDetailsForPrepare?.coveragePersonBuid,
          currentStep,
          personBuid,
        ));
      }
      await dispatch(UPDATE_WAIVE_PRODUCT({ personBuid, waiveProduct: false, currentStep }))
    } catch (error) {
      console.error('There was an error!', error);
    }
  };
  const hasErrors = getErrorMessages(currentStep);
  // useEffect(() => {
  //   if (!isDeclinedProduct || !invokeReworkAPI || !currentStep || !subscribedProductBuid)  return;

  //   // call it with await inside an async function
  //   const fetchData = async () => {
  //     await reworkProductData();
  //   }
  //   fetchData();

  // }, [isDeclinedProduct, invokeReworkAPI, currentStep, subscribedProductBuid]);
  const unsubscribeCoverageAPICall = async () => {
    let coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids?.[0];
    if (props.flowType === 2 || props.flowType === 3) {
      coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids.filter((e) => e?.coveragePersonBuid?.includes(personBuid))[0];
    }
    await dispatch(unsubscribeCoverageDetails({
      enrollmentProcessBuid: enrollmentProcessBuid,
      coverageBuid: coverageDetailsForPrepare?.coverage,
      coveredPerson: coverageDetailsForPrepare?.coveragePersonBuid,
      subscribedProductBuid: subscribedProductBuid,
      isPrepare: true,
      currentStep: currentStep
      //isMoveNext: previousStep === 'EnrollmentSummary'? false : true
    }));
    return true
  }
  const nextStepCallOne = async () => {
    // nextStep();
    if (personBuid && isWaiveCoverage === false && insuredQuestionAnswersArr) {
      setShowLoader(true);
      let actionDispatched = await dispatch(createOrUpdateInsuredQuestionAnswer({
        productIdentifier: currentStep,
        productIdentifierBuid,
        insuredQuestionAnswers: insuredQuestionAnswersArr,
        currentStep,
        currentOfferedCoverageBuids,
        enrollmentProcessBuid,
        validateContractInsure,
        nonSharedAdditionalDataSet
      }));

      if (actionDispatched) {
        await checkCoveragePersonEligibility();
        setShowLoader(false);
        return true
      } else {
        // asd need to be removed
        // await checkCoveragePersonEligibility();
        setShowLoader(false);
        return false
      }
      // Need to refactor this code props.flowType != 1 
      // } else if (personBuid && isWaiveCoverage === true && props.flowType !== 1) {
    } else if (personBuid && isWaiveCoverage === true) {
      //setShowLoader(true);
      //await prepareSubscriptionForChoiceInComponent()
      await declinedProductAPICall()
    } else {
      //return await validateSubscribedProductdata();
      return true
    }


  }
  const [totalQuestion, setTotalQuestion] = useState(-1);

  const setQuestionAnsweredFlag = ({ pdata, ptotalQuestions }) => {
    if (ptotalQuestions) {
      let covError = !memberSpouseEligible && pdata >=50 ?true:false
      if (pdata >= 100||covError) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const checkEnableBtnFn = (pdata, pbuildsArr, ptotalQuestions, pbuidsPersonArr) => {
    let ltotalQuestions = ptotalQuestions;
    if (pbuildsArr.length > 0 && pbuildsArr[0] !== currentBuids[0] && pbuildsArr.length !== currentBuids.length) {
      setCurrentBuids([...pbuildsArr]);
      setCurrentBuidsPerson([...pbuidsPersonArr]);
    }

    if (pdata === 100) {
      setNextStepFlow(false);
    }
    else if (isNaN(pdata) || ptotalQuestions === 0 || isNaN(ptotalQuestions)) {
      ltotalQuestions = 0;
      setNextStepFlow(true);
    }
    else {
      setNextStepFlow(false);
    }

    setUserAnsweredInsuredQuestionFlag(setQuestionAnsweredFlag({ pdata, ptotalQuestions }));
    setTotalQuestion(ltotalQuestions);
  };
  const [apiCallState, setApiCallState] = useState("");
  useEffect(() => {
    if (currentBuids?.length > 0 && currentOfferedCoverageBuids.length > 0) {
      if (totalQuestion === 0) {
        setApiCallState('onLoad');
      } else {
        setApiCallState('done');
      }
    }

  }, [currentBuids, currentOfferedCoverageBuids, totalQuestion]);

  useEffect(() => {
    if (apiCallState === "onLoad") {
      //checkCoveragePersonEligibility();
    }
  }, [apiCallState])

  const checkCoveragePersonEligibility = async () => {
    // nextStep();
    // onClick={(flowType == "2" || flowType == "3") ? nextStepCall : nextStepCallOne}
    setCheckNextStep(false);
    setCheckEmpEligible(true);
    let productFamily = formsConfig.forms?.[currentStep]?.productFamily;
    let CIBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];

    if (CIBuid) {

      setShowEdit(true);
      let validateContractSM = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateSubscribedContractMoveNextData?._Result?.subscriptionManager || [];
      const enrolmentId = currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
      const flow1buids = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.insureds || [];
      dispatch(UPDATE_LOADING(true));
      try {
        const response = await CheckCoveredPersonsEligibilityForCoverage(validateContractSM, enrolmentId, CIBuid, currentStep, currentBuids, flow1buids, flowType, currentOfferedCoverageBuids);
        if (insuredInfo[currentStep]?.coverageInsuredEligiblity && insuredInfo[currentStep]?.coverageInsuredEligiblity?.length > 0) {
          let currentElibilityData = [...insuredInfo[currentStep]?.coverageInsuredEligiblity];
          response.forEach((pobj) => {
            currentElibilityData.forEach((pinnrObj, pind) => {
              if (pinnrObj.personBuid === pobj.personBuid || pinnrObj.personBuid === undefined) {
                currentElibilityData.splice(pind, 1);
              }
            });
          });
          currentElibilityData = [...currentElibilityData, ...response];
          dispatch(CHECK_ELIGIBILITY({ response: currentElibilityData, product: currentStep }));
        } else {
          dispatch(CHECK_ELIGIBILITY({ response, product: currentStep }));
        }


        let flagCheck = true;
        let lcoverageError = [];
        let checkFlow1EmpEligible = true;

        response.forEach((pobj) => {
          ;
          if (pobj.coverageEligiblity === false) {
            flagCheck = false;
            let temp = {
              error: true,
              buid: pobj.personBuid,
              eligibilityComment: pobj.eligibilityComment
            }
            lcoverageError.push(temp);

            const errorData = currentBuidsPerson.filter((pcurrentData) => {
              return pobj.personBuid === pcurrentData.buid;
            });
            if (errorData[0]?.person === "employee") {
              setCheckEmpEligible(false);
              checkFlow1EmpEligible = false;
              const propData = { ...props };
              if (flowType == 2 || flowType == 3) {
                propData.showDisclosureStatement = false;
              }
            }

          }
        });
        setCoverageError([...lcoverageError]);
        // sucess case
        if (flagCheck && response.length > 0) {
          setCheckNextStep(false);
          setDisableAccordian(true);
          // (flowType == "2" || flowType == "3") ? nextStepCall() : nextStepCallOne()
        }
        // error case      
        else {
          setDisableAccordian(true);
          setCheckNextStep(true);
          setIsCoverageApiError(true);
        }
        if (apiCallState == "onLoad") {
          setCallApi("onDone");
          setShowEdit(false);
          setApiCallState("done");
          if (flagCheck && response.length > 0) {
            setDisableAccordian(false);
            setCheckNextStep(false);
          } else if (flagCheck && response.length === 0) {
            setDisableAccordian(false);
          }
        }
        setCallApi("onDone");
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(UPDATE_LOADING(false));
      }
    }
  };
  // if(flagCheck && response.length > 0){
  //   setCheckNextStep(false);
  //   (flowType == "2" || flowType == "3") ? nextStepCall() : nextStepCallOne()
  // }      
  // else{
  //   setCheckNextStep(true);
  // }
  const validateSubscribedProductdata = async () => {
    let productFamily = formsConfig.forms?.[currentStep]?.productFamily;
    let CIBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
    const subscribedProductBuid = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements
    let resp = await serviceHelper.sendApiRequest('/validateSubscribedProductData', {
      enrollmentProcessBuid: enrollmentProcessBuid,
      subscribedProductBuid: subscribedProductBuid,
      productIdentifierBuid: CIBuid
    });
    if (resp.data && resp.status === 200) {
      const currentStepData = {
        [currentStep]: {
          ValidateSubscribedProductData: resp.data
        }
      }
      dispatch(onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false }));
      return true
    } else {
      return false
    }
  }

  const prepareSubscriptionForChoiceInComponent = async () => {
    let resp = await serviceHelper.sendApiRequest('/prepareSubscriptionForChoice', {
      enrollmentProcessBuid: enrollmentProcessBuid,
      coverageBuid: coverageDetails?.coverage,
      coveredPerson: coverageDetails?.coveragePersonBuid,
    });
    if (resp.data && resp.status === 200) {
      let actionDispatched = await dispatch(unsubscribeCoverage({
        enrollmentProcessBuid,
        subscribedCoverageBuid: resp.data._Result?.[0]?.bUID
      }, "UpdatesubscribeCoverage"));
      setShowLoader(false);
      if (actionDispatched.data && actionDispatched.status === 200) {
        dispatch(onLoadAPI({
          UpdatesubscribeCoverage: actionDispatched.data,
          isAPICallInProgress: false,
        }))
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const editAnswersFn = () => {
    setIsCoverageApiError(false);
    setCallApi("onEdit");
    setDisableAccordian(false);
    setContinueBtnLabel(possibleLabelConstants.CONTINUE)
    //setContinueBtnLabel("Continue");
  };

  const continueFn = async () => {
    if (totalQuestion == 0) {
      if (checkEmpEligible) {
        nextStep();
      } else {
        onNext();
      }
    }
    else if (totalQuestion > 0 && (callApi == "onLoad" || callApi == "onEdit")) {
      (flowType == "2" || flowType == "3") ? await nextStepCall() : await nextStepCallOne()
    }
    else if (totalQuestion > 0 && callApi == "onDone") {
      if (checkEmpEligible) {
        nextStep();
      } else {
        await unsubscribeCoverageAPICall()
        onNext();
      }
    }

    // if (nextStepFlow) {
    //   if(flowType == 1){
    //     if(checkEmpEligible){
    //       nextStep();
    //     } else{
    //       onNext();
    //     }
    //   } else{ 
    //     (flowType == "2" || flowType == "3") ? await nextStepCall() : await nextStepCallOne()       
    //     nextStep();
    //   }
    // } else if (checkNextStep && showEdit) {
    //   if(flowType == 1){
    //     if(checkEmpEligible){
    //       nextStep();
    //     } else{
    //       onNext();
    //     }
    //   }else{
    //     (flowType == "2" || flowType == "3") ? await nextStepCall() : await nextStepCallOne()
    //   }      
    // } else if (!checkNextStep && !showEdit) {
    //   (flowType == "2" || flowType == "3") ? await nextStepCall() : await nextStepCallOne()
    // } else if (callApi) {
    //   setCallApi(false);
    //   (flowType == "2" || flowType == "3") ? await nextStepCall() : await nextStepCallOne()
    // }
    // else {
    //   nextStep();
    // }
  };

  const callOnNext = async () => {
    // Your logic for proceeding to the next product goes here...
    (flowType == "2" || flowType == "3") ? await nextStepCall() : await nextStepCallOne()
  };

  const eligibilityCheckfn = () => {
    if (eligibilityObj && eligibilityObj.length) {
      let tempObj = eligibilityObj.map((item) => {
        return ({
          error: !item?.isEligible,
          buid: item?.coveredPerson?.bUID || item?.personBuid,
          eligibilityComment: item?.eligibilityComment
        })
      })
      setCoverageError([...tempObj])
    }
  }

  // useEffect(() => {
  //   //on coverage waived set label correctly
  //   //specifically on page load

  //   if(isCheckboxChecked) {
  //     const nextStepGoingTobe = stepKeys[currentStepIndex + 1];
  //     setButtonText(nextStepGoingTobe === 'EnrollmentSummary' ? 'Continue to Summary' : 'Continue to Next Product')
  //   }
  // }, [isCheckboxChecked, []])
  useEffect(() => {
    // on page load set continue button label
    // this is default hence no props are required here
    if (isWaiveCoverage) {
      setContinueBtnLabel(continueBtnLabelHelper({
        flowType: props.flowType,
        isWaivedOffFlag: isWaiveCoverage,
        productIdentifierBuid,
        memberDetails: {
          count: 1,
          index: 0,
          eligible: coverageEligiblity,
          buid: personBuid
        },
        showEdit: showEdit,
        memberIdentifier: memberTypes.EMPLOYEE
      }))
    } else {
      setContinueBtnLabel(possibleLabelConstants.CONTINUE)
    }
    // if(coverageEligiblity === false) {
    //   setCheckEnableBtn(false)
    // } else {

    // }
    // if(showEdit) {
    //   if(userAnsweredInsuredQuestionFlag || isWaiveCoverage) {
    //     setCheckEnableBtn(false)
    //   } else {
    //     setCheckEnableBtn(true)
    //   }
    // } else {

    // }
    if ((userAnsweredInsuredQuestionFlag || isWaiveCoverage) ) {
      setCheckEnableBtn(false)
    }
    else if (userAnsweredInsuredQuestionFlag && !memberSpouseEligible && !isWaiveCoverage && errorData) {
      setCheckEnableBtn(false)
    }
    else {
      setCheckEnableBtn(true)
    }
    if (props?.displayMode === "All") {
      eligibilityCheckfn()
    } else {
      setCoverageError([{
        error: false,
        buid: personBuid,
        eligibilityComment: ''
      }])
    }
  }, [errorData,userAnsweredInsuredQuestionFlag,memberSpouseEligible,isWaiveCoverage])

  useEffect(() => {
    if (props?.displayMode === "All") {
      eligibilityCheckfn()
    }
  }, [eligibilityObj])

  useEffect(() => {
    setContinueBtnLabel(continueBtnLabelHelper({
      flowType: props.flowType,
      isWaivedOffFlag: isWaiveCoverage,
      productIdentifierBuid,
      memberDetails: {
        count: 1,
        index: 0,
        eligible: coverageEligiblity,
        buid: personBuid
      },
      showEdit: showEdit,
      memberIdentifier: memberTypes.EMPLOYEE
    }))
  }, [coverageEligiblity, showEdit])

  return (
    <div>

      <>


        {showLoader ? <Loader loading={showLoader} className="loaderUpdateCls" />
          : (<>
            <ProductDescription resources={searchPolicyProcessResource} buid={buid} {...{ ...props.parentProps, ...Content.idealContent }} />
            <div>
              <div className="row panelStyleNoBorder px-1">
                <div className="hideWhenNoChild col-md-12">
                  <div className="row" style={{ fontSize: '1rem', color: 'rgb(0, 0, 0)', fontFamily: 'NotoSansSemiBold' }}>
                    <div className="label col-12 px-0 undefined" style={{ display: 'inline-block' }}>
                      <div className="row">
                        <div className="py-3 mx-xl-2 mx-md-2 mx-1 px-xl-4 px-lg-4 px-md-4 px-4">
                          Let's check your eligibility.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row panelStyleNoBorder px-1">
                <div className="hideWhenNoChild col-md-12">
                  <div className="row" style={{ fontSize: '0.875rem', color: 'rgb(0, 0, 0)', fontFamily: 'NotoSansRegular' }}>
                    <div className="label col-12 px-0 undefined" style={{ display: 'inline-block' }}>
                      <div className="row">
                        <div className="mx-xl-2 mx-md-2 mx-1 px-xl-4 px-lg-4 px-md-4 px-4 pb-4">
                          {/*Please answer any required eligibility questions that may be listed below. Click the arrow beside an insured name to expand or collapse the section.*/}
                          If applicable, please click the arrow beside any name below to answer all required eligibility questions. Then click ‘Continue’ to proceed.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <InsuredQuestions {...props}

              personBuid={buid}
              checkAnswer={checkEnableBtnFn}
              disableAccordian={disableAccordian}
              coverageError={coverageError}

            ></InsuredQuestions>
            {showWaiveCoverage &&
              <div className="hideWhenNoChild col-12 pr-0 pl-0">
                <div className="" style={{ borderBottomRightRadius: '0.25rem', fontFamily: 'NotoSansRegular', fontSize: '0.875rem', textAlign: 'center', backgroundColor: 'rgb(244, 244, 244)', borderColor: 'rgb(218, 218, 218)', minHeight: '2.5rem', paddingTop: '0.5rem' }}>
                  <div className="checkboxContainer">
                    <Checkbox
                      id="insuredWaiveCheckBox"
                      name="insuredWaiveCheckBox"
                      onChange={vdk}
                      className="custom-class"
                      label={Content?.waiveCoverageButton?.label}
                      checked={isWaiveCoverage === true ? true : false}
                    />
                  </div>
                </div>
              </div>



            }
          </>
          )}
      </>



      <div className='button-group'>
        <Button type="button" onClick={onBack} className="mr-3 btn btn-outline-primary primaryOutline">
          Back
        </Button>
        {showEdit && (
          <Button type="button" onClick={editAnswersFn} className="mr-3 btn btn-primary continue-btn primaryBackground">
            Edit Answers
          </Button>
        )}
        <Button
          type="button"
          disabled={checkEnableBtn || hasErrors}
          onClick={isCheckboxChecked ? callOnNext : continueFn}
          className="btn btn-primary continue-btn primaryBackground"
          name="continue-to-next-product"
        >
          {continueBtnLabel}
        </Button>
      </div>
    </div>
  );
};

export default EmployeeInsuredQuestionsContent;
