import React from 'react';
import { useSelector } from 'react-redux';

const SurplusFeeWrapper = (props) => {
  const selectedCoverage = (props.tableData || []).find(item => `${item.code}` === `${props.selectedValue}`)
  const surplusPercentage = selectedCoverage?.feesInformation?.find(item => item.code === 'SLTF')?.percentage;
  const stampingFeePercentage = selectedCoverage?.feesInformation?.find(item => item.code === 'SF')?.percentage;

  const stateName = useSelector(reduxState => reduxState?.entities?.response?.onLoadResponseList?.ExcessLiability?.ValidateSubscribedProductData?._Result?.contract?.contract?.residenceStateCode?.stateName)
  const states = useSelector((storeState) => storeState.entities.constants?.data?.StateConstants);
  const state = states?.find(item => item.value === stateName)?.text;

  const coverageIndex = props.coverageIndex;

  return (
    <div>
      <div class="row panelStyleNoBorder px-1">
        <div class="col-md-12">
          <div class="row">
            <div class=" label  col-12 px-3">
              <div class="row">
                <div class=" px-3 pt-1 pb-2">
                  The Total Costs includes the required {surplusPercentage}% Surplus Lines and {stampingFeePercentage}% Stamping Fees for the State of {state}. {coverageIndex === 1 ? `Uninsured/Underinsured motorist (UM/UIM) is included at no extra charge. Coverage will be capped at the $1,000,000 limit, regardless of limit of liability elected.`:''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="row panelStyleNoBorder px-1">
        <div class="col-md-12  pt-0  p-1 mx-xl-2 px-xl-4   ">
          <div class="row surplus col-md-4 border rounded pt-0 pb-1 ">
            {selectedCoverage?.feesInformation?.map((val, index) => (
              <div class="col-12" key={index}>
                <div class={`row ${index === 0 ? 'borderBottom' : ''}`}>
                  <div class=" col-9 mt-2 mb-1">
                    <span>{val?.name}</span>
                  </div>
                  <div class=" col-3 mt-2 mb-1">
                    <span>{val?.amountHT?.amount?.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SurplusFeeWrapper;
