import React from 'react';
import PropTypes from 'prop-types';
const TextArea = ({ label, name, value, onChange, placeholder, rows }) => {
  return (
<div className="text-area-wrapper">
      {label && <label htmlFor={name}>{label}</label>}
<textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows || 4}
      />
</div>
  );
};
TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
};
export default TextArea;