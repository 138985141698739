import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../appConfig';
import http from '../../../../Common/Services/HttpService';
import { UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo';
import { initializeForms, initializeFormsData } from '../../../../Store/Slices/StepFormNew';
import { useNavigationHook } from '../Hooks/NavigationHook';
import StepForm from './StepForm';

const BaseLayout = (props) => {
    const { formLayoutType, layoutData = {} } = props;
    const dispatch = useDispatch();
    const { handleSetSkipSteps, handleSetCurrentStep, handleSetStepKeys } = useNavigationHook();

    const { enrollmentType } = useSelector((storeState) => storeState.enrollment);
    const { currentLanguage: language } = useSelector((storeState) => storeState.language);
    const clientId = useSelector((storeState) => storeState?.loggedUserEntity?.data?._Result?.legalEntityIdentity?.clientId?.internalClientId || null);
    const isBackToSummary = useSelector((storeState) => storeState?.enrollment?.isBackToSummary)
    const [formsConfig, setFormsConfig] = useState({});
    useEffect(() => {
        import(`./Content/${language}.json`)
            .then((module) => {
                setFormsConfig(module.default);
            })
            .catch(error => console.error("Failed to load content:", error));
    }, [language]);
    useEffect(() => {
        try {
            // Dynamically get content based on currentLanguage.

            // Initializing formsConfig and formsData
            if (formsConfig?.forms && typeof formsConfig?.forms === 'object') {
                const { forms } = formsConfig;
                const keys = Object.keys(forms);
                const skipStepsKeys = keys.filter(key => forms[key].skipStep);

                const loadConfig = async () => {
                    dispatch(UPDATE_LOADING(true));
                    const { data } = await http.post(appConfig.SSP_MID_URL + '/v1/form-data', {
                        clientId,
                        forms: keys.map(form => ({
                            form,
                            language,
                            formLayoutType,
                        }))
                    });

                    if (data?.data) {
                        const formsData = Object.keys(data.data).reduce((res, key) => {
                            if (data.data?.[key]?.success)
                                res[key] = data.data[key].data;

                            return res;
                        }, {});
                        dispatch(initializeFormsData(formsData));
                    }

                    handleSetStepKeys(keys);
                    handleSetSkipSteps(skipStepsKeys);
                    dispatch(initializeForms(formsConfig));
                    if (enrollmentType === 'openEnrollment' || isBackToSummary) {
                        handleSetCurrentStep('EnrollmentSummary');
                    } else {
                        handleSetCurrentStep(keys[0]);
                    }
                    dispatch(UPDATE_LOADING(false));
                }
                loadConfig();
            } else {
                console.error("formsConfig?.forms is not correctly populated dfd");
            }
        } catch (error) {
            console.error(`Failed to load content for language: ${language}`, error);
        }
    }, [formsConfig]);

    return <StepForm showSideNavigation={enrollmentType !== 'openEnrollment'} layoutData={layoutData} />;
};

export default BaseLayout;