import React, { useEffect, useState } from 'react';
// import "./styles.css";
import { Styles } from './styles';
import {  Linker } from '../../Atoms';
import { useSelector } from 'react-redux';

const StaticFooter = () => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const content = import(`./Content/${currentLanguage}.json`);

  let currentYear = (new Date().getFullYear())

  return (
    
    <React.Fragment>
      <Styles />
      <footer className='footer ' >
        <div className='container-fluid pl-0 '>
          <div className= 'row ' style={{height:"5rem"}}>
            <div className='col-12 d-flex align-items-center '>
              <div>
                <p className='copyright px-3 px-md-5 mb-0 '>{content.copyRightsFirstText}{currentYear}{content.copyRightsSecondText}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default StaticFooter;
