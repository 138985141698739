import React, { useStat, memo } from 'react';


import Loader from '../../../Atoms/Loader';
import { useDispatch, useSelector } from 'react-redux';
import SearchEmployeeList from '../../SearchEmployeeList/index.js';
//import SearchEmployeeList from "../Component/SearchEmployeeList/index.js"

const CallCenterSearchEmployee = memo((props) => {

  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  return (
    <div>
      {
      isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />      
      } 
       <SearchEmployeeList></SearchEmployeeList>
    </div>
  );
});
export default CallCenterSearchEmployee;
