import React, {  useEffect } from 'react';
import PropTypes from 'prop-types';
// import './styles.css';
import { Styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { reduxConstants } from '../../../Common/Constants/index';
import Elementor from '../../Cells/Form/Elementor';
import toast, { Toaster } from 'react-hot-toast';
import {HAS_ERROR} from '../../../Store/Slices/RegisterationError';
import { EMAIL_ERROR } from '../../../Store/Slices/Registeration';

const TriBox = (props) => {
  const {
    elements,
    //allFormData,
    formik,
    formName,
    triConfig,
    constantsList,
    content,
    hideElement,
  } = props;
  let errorCount = 0;


  const dispatch = useDispatch();
  const { hideErrorMessage, hideCol,wrongMobile=false} = useSelector((storeState) => storeState.registerationError);

  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.uiPath)) || {};
  const { contactType='', email2='',showAfterSendCode=false, showSpecial=false, sendCodeMobileClicked=false,sendCode=false } = useSelector((storeState) => storeState.callCenter);
  useEffect(() => {

  }, [showSpecial
  ]);

  const formLeftData = (changeType) => {
    /* To map leftcard info in changebox*/
    if (content.layout.leftCardDataPath) {
      return eval(content.layout.leftCardDataPath) || {};
    }
  };

  const formRightData = (changeType) => {
    /* To map rightcard info in changebox */
    if (content.layout.rightCardDataPath) {
      return formik.values[content.layout.rightCardDataPath] || {};
    }
  };
  //toastmessage
  // const notify = () => alert('hello');
  // toast.error(
  //   <div>
  //     You have exceeded the maximum number (3) of attempts. Please contact Mercer Contact Center @
  //     (xxx) - (xxx) - (xxxx)' for further assistance.'
  //   </div>
  // );

  const filter = (elements, leftOrRight) => {
    if (triConfig?.leftCard?.mappings) {
      /* To map leftcard element in changebox*/
      let data = formLeftData(triConfig.leftCard.changeType);
      triConfig?.leftCard?.mappings?.forEach((element) => {
        element.value = eval(element.expression);
      });
    }

    if (triConfig?.rightCard?.mappings) {
      /* To map rightcard element in changebox*/
      let data = formRightData(triConfig?.rightCard.changeType);
      let rightData = formRightData(triConfig.rightCard.changeType);
      triConfig?.rightCard?.mappings?.forEach((element) => {
        element.value = eval(element.expression);
      });
    }

    var finalElements = { leftCard: [], rightCard: [], generalItems: [] };

   elements && elements?.forEach((row) => {
      let conditionalRender = row[0]['conditionalRender']
      //added condition for change MFA contact
      if((conditionalRender && (row[0][contactType] ) )|| !conditionalRender){

      let mapped = row?.map((element, index) => {
        let additionalClass = '';

        let mapLeftObject =
          triConfig?.leftCard.mappings &&
          triConfig?.leftCard.mappings.find(
            (elementExpression) => elementExpression.mapElement === element.name
          );

        let mapRightObject =
          triConfig?.rightCard.mappings &&
          triConfig?.rightCard.mappings?.find(
            (elementExpression) => elementExpression.mapElement === element.name
          );

        if (mapLeftObject) {
          element.value = mapLeftObject.value || '';
          additionalClass = ' changebox-general-items mb-6';
        } else if (mapRightObject) {
          element.value = mapRightObject.value || '';
          additionalClass = ' changebox-general-items-right mb-3';
        }

        if (element.getValuesFrom) {
          additionalClass = 'mb-9';
          if (element.name !== 'effectiveDate') {
            additionalClass = `changebox-general-items-right ${additionalClass}`;
          }
        }

        // if (triConfig?.leftCard?.element[0]?.img) {
        //   
        //   return (
        //     <div className="form-row py-3 mt-4 `col-12 ${element.placement} ${additionalClass}`">
        //       <Elementor
        //         key={element.name}
        //         element={element}
        //         constantsList={constantsList}
        //         {...props}
        //       />
        //       {props.formik.errors[formName] &&
        //       props.formik.errors[formName][element.name] &&
        //       props.formik.touched[formName] &&
        //       props.formik.touched[formName][element.name] ? (
        //         <div>{props.formik.errors[formName][element.name]}</div>
        //       ) : null}
        //     </div>
        //   );
        // } else {

        let errorFound = '';
        if (
          props.formik.errors[formName] &&
          props.formik.errors[formName][element.name] &&
          props.formik.touched[formName] &&
          props.formik.touched[formName][element.name]
        ) {
          // errorFound1 = props.formik.errors[formName][element.name];
          errorFound = true;
          // element.name === 'MultiFactorAuthentication.mobileNumber' && dispatch(HAS_ERROR(errorFound))

        }
       
       ((formName === 'UserAccountVerifyEmail' && element.name === 'email' )|| 

       (formName === 'MultiFactorAuthentication' && element.name === 'mobileNumber'))  &&
          dispatch(EMAIL_ERROR(errorFound))

        
        let colCal = [
          'col-1',
          'col-2',
          'col-3',
          'col-4',
          'col-5',
          'col-6',
          'col-7',
          'col-8',
          'col-9',
          'col-10',
          'col-11',
        ];
        let shouldHide = (element.name === 'SecurityCodeInfo' || element.name === 'securityCode' || element.name === 'securityCodeNote') && hideCol ? true : false;
        let hideColumn = hideCol && shouldHide? 'hideElement' : '';
        //Change MFA Contact
        let hideRead =  ((element.name ==='emailId' || element.name === 'noteEmail' || element.name === 'mobileCodeNote') && (formName !=='MultiFactorAuthentication' && !showSpecial) ) ||
                        ((element.name ==='MFAInfo' ||element.name ==='MFAInfoMobile' || element.name === 'mobilelIdOption'  ) && ( formName !=='MultiFactorAuthentication' && showSpecial) )
         let hideOnClickSendCode = element.type=== 'readOnly' && (
                                    (element.hideOnClickSendCodeBtn && sendCodeMobileClicked) ||
                                     (!element.hideOnClickSendCodeBtn && !sendCodeMobileClicked && element.mobile));
                                    // (element.hideOnClickSendCodeBtn && sendCode) ||
                                    // (!element.hideOnClickSendCodeBtn && !sendCode && element.mobile));
        let hideErrorForContactDetails = element.hideErrorMsgOnHide && !showSpecial 
        let hideOnWrongOtp = (element.hideOnWrongOTP && wrongMobile  && formName ==='MultiFactorAuthentication')

        /** bellow condoition element.type !== 'toolTip' added by Nisreen to fix tooltip overlapping issue, 
         * element.shouldNotBeCol12 : for password and reenter password for retrieve userId 
         * element.imageForPhone : for verfy icon (email, mobile ) for Regiteration*/
        return (
          <div
            key={element.name + index}
            className={
              window.innerWidth > 820 && 
              element.type !== 'toolTip' && !element.shouldNotBeCol12 &&  !element.imageForEmail &&  !element.imageForPhone
                ? element.placement
                  ? `col-12   ${element.placement} ${additionalClass} ${hideColumn}  `
                  : `col-12   ${additionalClass}  `
                : element?.placement?.indexOf(colCal) !== -1
                ? `col-12  ${element.placement}  `
                : `   ${element.placement}  `
            }
            hidden={shouldHide || element.hidden || hideRead ||hideOnClickSendCode || hideOnWrongOtp}
          >
            <Elementor
              key={element.name}
              element={element}
              constantsList={constantsList}
              errorFound={errorFound}
              {...props}
            />
            {props.formik.errors[formName] &&
            props.formik.errors[formName][element.name] &&
            props.formik.touched[formName] &&
            props.formik.touched[formName][element.name] ? (
              // <div className ="error-message">{props.formik.errors[formName][element.name]}</div>
              <div
                className={hideErrorMessage || hideErrorForContactDetails? "hideElement" : "error-message"}
                style={{ color: content.layout.errorColor, fontSize: '0.75rem' }}
              >
                {props.formik.errors[formName][element.name]}
                {/* {props.formik.errors[formName][element.name] && notify} */}
                {/* {props.formik.errors && (
                  <Toaster
                    toastOptions={{
                      className: 'toast-message',
                      duration: 3000,

                      style: {
                        border: '1px solid #E6A7A3',
                        padding: '16px',
                        color: '#AB2F26',
                        background: '#FFE8E6',
                        width: '3px',
                      },
                    }}
                  />
                )} */}
              </div>
            ) : null}
          </div>
        );
        // }
      });
    
      if (leftOrRight && leftOrRight(row?.[0], triConfig) === 'left') finalElements.leftCard.push(mapped);
      else if (leftOrRight(row?.[0], triConfig) === 'right') finalElements.rightCard.push(mapped);
      else finalElements.generalItems.push(mapped);
    }
    });
  //}
    return finalElements;
  };

  const leftOrRight = (element, triConfig) => {
    if (triConfig?.leftCard?.element?.indexOf(element?.label) > -1) {
      return 'left';
    } else if (triConfig?.rightCard?.element?.indexOf(element?.label) > -1) {
      return 'right';
    } else {
      return '';
    }
  };

  /** Function for render form using Elementor component and display error */
  const renderForm = () => {
    const rows = filter(elements, leftOrRight);
    let { leftCard, rightCard } = triConfig || {};

    return (
      <>
        <Styles />
        <div className="left-card-style">
          {rows.generalItems &&
            rows.generalItems.length > 0 &&
            rows.generalItems.map((row, rowIndex) => {
              return (
                <div className="row py-12" key={rowIndex}>
                  {row}
                </div>
              );
            })}
        </div>
        <div className="row">
          {/* <div className={`  ${(leftCard.element && leftCard.className) || ''}`}> */}
          {/* <div className={`  ${(leftCard.element && leftCard.className) || ''}`}>
            {rows.leftCard &&
              rows.leftCard.length > 0 &&
              rows.leftCard.map((row, rowIndex) => {
                return (
                  <div className="form-row " key={rowIndex}>
                    {row}
                  </div>
                );
              })}
          </div> */}
          <div className={` ${rightCard.className}`}>
            {rows.rightCard &&
              rows.rightCard.length > 0 &&
              rows.rightCard.map((row, rowIndex) => {
                let additionalClass = props?.content?.layout?.additionalRowClass ;
                return (
                  <div
                    className={
                      additionalClass
                        ? ' row flex-column flex-lg-row flex-md-row justify-content-end'
                        : ' row '
                    }
                    key={rowIndex}
                  >
                    {row}
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  };
  return <div className="addMultiple container-fluid py-12 ">{renderForm()}</div>;
};
TriBox.propTypes = {
  elements: PropTypes.array,
  allFormData: PropTypes.object,
  updateFormData: PropTypes.func,
  tableRowMapper: PropTypes.func,
  tableHeaders: PropTypes.arrayOf(PropTypes.string),
};
TriBox.defaultProps = {
  elements: {},
  allFormData: {},
  tableHeaders: [],
  setAllFormData: () => {},
  tableRowMapper: () => {},
};

export default TriBox;


