import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../appConfig';
import { CommonConstants } from '../../../../Common/Constants';
import http from '../../../../Common/Services/HttpService';
import { DEPENDENT_REPLATIONSHIP } from '../../../../Store/Slices/EmployeeProfile';
import { UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response';
import { REMOVE_CURRENTSTEP_ALERT_MESSAGE, SET_ALERT_MESSAGE, SELECTED_PRODUCT_LIST_BUIDS, SELECTED_PRODUCT_NAMES, UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo';
import { PRODUCT_LIST, PRODUCT_RESOURCES, SELECTED_PRODUCT_LIST } from '../../../../Store/Slices/Process';
import Loader from '../../../Atoms/Loader';
import resumeContent from '../../../Molecules/CustomSection/Content/ResumeContent.json';
import { updateStepperNavigatorInfo } from '../../../Utils/Form/StepperHelper';
import { Products } from '../Component/Products/Products';
import { getDependentDetailsRequiredFlag, getFileExtension, getWLProductInfo } from '../Helper/commonHelper';
import { getErrorMessages, removeUnwantedProps } from '../Helper/MappingHelper';
import { useNavigationHook } from '../Hooks/NavigationHook';
import _ from 'lodash';

const ProductSelection = memo(() => {
    const dispatch = useDispatch();
    const { currentStep, stepKeys, formsConfig, previousStep, handlePreviousStep, handleNextStep, handleSetStepKeys, handleSetCurrentStep, handleRemoveStepsAndNext } = useNavigationHook();
    const storeFromRedux = useSelector((storeState) => storeState);
    const enrollmentProcessBuid = useSelector((storeState) => storeState?.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID);
    const enrollmentPeriodKind = useSelector((storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result?.currentEnrollmentPeriod?.enrollmentPeriod?.enrollmentPeriodKind);
    const productList = useSelector((storeState) => storeState?.process?.productList || []);
    const selectedProductList = useSelector((storeState) => storeState?.process?.selectedProductList || []);
    const selectedProductListBuids = useSelector((storeState) => storeState?.insuredInfo?.selectedProductListBuids || []);
    const selectedProductNames = useSelector((storeState) => storeState?.insuredInfo?.selectedProductNames || []);
    const isQLEEnrollment = useSelector((storeState) => storeState?.enrollment?.isQLE)
    const clientId = useSelector(
        (storeState) =>
            storeState?.auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.legalEntity?.identifier?.externalClientId?.externalClientId
    );
    const additionalDatas = useSelector((storeState) => storeState.process?.contractsLinearOrNonLinear?._Result?.contract?.additionalDatas);
    const isLinearFlow = additionalDatas?.some((data) => data.fieldValue === 'Linear');
    const hasVoyaWL = additionalDatas?.some((data) => data.fieldCode === 'DD_00159' && data.fieldValue === 'Yes');
    const hasErrors = getErrorMessages(currentStep);

    const subscribedElements = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements);
    let subscribedProduct = []
    if (subscribedElements?.length > 0) {
        subscribedProduct = subscribedElements?.map((product) => {
            return {
                productBuid: product?.subscribedProduct?.productCode?.buid,
                subscribedProductBuid: product?.subscribedProduct?.buid
            }
        })
    }
    let unselectedProducts = [];
    if (subscribedProduct.length > 0) {
        unselectedProducts = subscribedProduct?.filter(newItem =>
            !selectedProductList?.some(oldItem => oldItem?.product?.buid === newItem?.productBuid)
        );
    }
    const isunselectedProducts = unselectedProducts?.length > 0 ? true : false
    useEffect(() => {
        const loadProducts = async () => {
            try {
                dispatch(UPDATE_LOADING(true));
                const reduxState = removeUnwantedProps(storeFromRedux, resumeContent.resumeUnwantedProps);
                const stepContext = { portalStep: currentStep, reduxState: _.omit(reduxState, ["insuredInfo"]) }

                const response = await http.post(appConfig.SSP_MID_URL + '/v1/product-selection-load', { enrollmentProcessBuid, stepContext });
                if (response?.data?.data) {
                    let products = response?.data?.data?.getListOfEligibleProducts?.data || [];
                    products = products.filter(product => {
                        const productbuId = product?.memberOfferedProduct?.bUID;
                        const enrollmentType = storeFromRedux?.enrollment?.enrollmentType;
                        const curreneBenifitButton = storeFromRedux?.employeeProfile?.curreneBenifitButton;
                        const listOfEligibleProducts = storeFromRedux.entities.response.onLoadResponseList.getListOfEligibleProductsForMember;
                        if (curreneBenifitButton === 'undefined.GetStartedCurrentYear' && enrollmentType === 'newHire') {
                            return listOfEligibleProducts.some(item => item.buid === productbuId && item.isEvenGreen);
                        } else if (clientId === "NATCOL" && isQLEEnrollment && product?.subscribedProduct?.productCode?.productIdentifier?.productCode === "TRAVELLIMT#0") {
                            return false
                        }
                        else {
                            return true;
                        }
                    })

                    dispatch(PRODUCT_LIST(products));
                    const productResourceList = products.reduce((res, item) => {
                        res[item?.memberOfferedProduct?.bUID] = item.resources;
                        return res;
                    }, {});
                    dispatch(PRODUCT_RESOURCES({ productResourceList }));
                }
            } catch (e) {
                console.error(e);
                dispatch(SET_ALERT_MESSAGE({
                    type: 'error',
                    errorCode: '520',
                    continue: true,
                    id: "productSelectionLoad",
                    show: true,
                    page: currentStep,
                }));
            } finally {
                dispatch(UPDATE_LOADING(false));
            }
        }
        if (enrollmentProcessBuid) {
            loadProducts();
        }
    }, [enrollmentProcessBuid]);

    const selectAllProducts = (selected) => {
        let selectedItems = [];
        let selectedItemsBuid = [];
        let selectedProductNames = [];
        if (selected) {
            selectedItems = productList.map(product => {
                selectedItemsBuid.push(product?.memberOfferedProduct?.bUID)
                selectedProductNames.push(product?.memberOfferedProduct?.product?.productFamily)
                return product?.memberOfferedProduct
            });
            if (hasVoyaWL && enrollmentPeriodKind === "cAnnualEnrollment") {
                selectedItems.push({ bUID: CommonConstants.VOYA_WL })
                selectedItemsBuid.push(CommonConstants.VOYA_WL)
                selectedProductNames.push("Whole Life")
            }
        }
        dispatch(SELECTED_PRODUCT_LIST_BUIDS([...selectedItemsBuid]))
        dispatch(SELECTED_PRODUCT_NAMES([...selectedProductNames]))
        dispatch(SELECTED_PRODUCT_LIST(selectedItems));
    }

    useEffect(() => {
        if (productList?.length && isLinearFlow) {
            selectAllProducts(true);
        }
    }, [productList, isLinearFlow]);

    useEffect(() => {
        // sort the selectedProducts array based on the buID
        const productListCheck = productList?.map((el) => el?.memberOfferedProduct);
        const selectedDetailedProducts = selectedProductList.filter(item => item.bUID !== CommonConstants.VOYA_WL)
            .sort((item1, item2) => {
                const item1Index = productListCheck?.findIndex((aItem) => aItem?.bUID === item1?.bUID) ?? -1;
                const item2Index = productListCheck?.findIndex((aItem) => aItem?.bUID === item2?.bUID) ?? -1;
                return item1Index - item2Index;
            })
            .map(item => productList.find(prod => prod?.memberOfferedProduct?.bUID === item.bUID));

        const filteredKeys = Object.keys(formsConfig?.forms).filter((key) => {
            const obj = formsConfig?.forms[key];
            if (!obj?.productFamily) {
                return true; // Filter key if productFamily property is not present
            } else if (key === CommonConstants.VOYA_WL) {
                return selectedProductList.some(item => item.bUID === CommonConstants.VOYA_WL); // Filter key if Voya Wholelife dummy product is selected
            }
            return selectedDetailedProducts.some((p) => p?.details?.product?.productFamily === obj.productFamily); // Filter key if productFamily matches with any object in productList
        });
        const slicedArray = filteredKeys.slice(4, filteredKeys.length - 1); // slice the array from index 3 to n-2
        const result = slicedArray.map((key) => {
            return { [key]: formsConfig?.forms[key]?.productFamily ?? null };
        });

        result.sort((a, b) => {
            const aName = Object.values(a)[0];
            const bName = Object.values(b)[0];
            const aIndex = productList.findIndex(p => p?.memberOfferedProduct?.product?.productFamily === aName);
            const bIndex = productList.findIndex(p => p?.memberOfferedProduct?.product?.productFamily === bName);
            return (aIndex === -1 ? 999 : aIndex) - (bIndex === -1 ? 999 : bIndex);
        });

        const keysArray = result.map((obj) => Object.keys(obj)[0]);
        filteredKeys.splice(4, keysArray.length, ...keysArray); // replace the elements in the slicedArray array with the slicedArray

        handleSetStepKeys(filteredKeys);
    }, [selectedProductList])

    const productsToDisplay = useMemo(() => {
        const products = productList?.map((product) => {
            const productBUID = product?.memberOfferedProduct?.bUID;
            const pdfUrl = product?.resources?.find((data) => data.code == 'PRODBROC')?.content;
            const description = product?.resources?.find((data) => data.code == 'PRODSHORTD')?.content;
            const title = product?.resources?.find((data) => data.code == 'PRODTITLE')?.content;

            const base64 = product?.resources?.find((data) => data.code == 'PRODLOGO')?.binaryContent;
            let fileType = getFileExtension(product?.resources?.find((data) => data.code == 'PRODLOGO')?.content);

            if (fileType == "svg") {
                fileType = "svg+xml"
            }

            const image = base64 ? "data:image/" + fileType + ";base64," + (base64) : '';

            const url = product?.resources?.find((data) => data.code == 'FLIMPCODE')?.content || '';
            const videoUrl = url ? (url.includes('?') ? `${url}&autoplay=1` : `${url}?autoplay=1`) : '';
            const checked = selectedProductList.some(item => item?.bUID === productBUID);

            const dependentStatus = getDependentDetailsRequiredFlag(product)

            return {
                name: productBUID,
                productBUID,
                description,
                title,
                image,
                fileType,
                pdfUrl,
                videoUrl,
                checked,
                dependentStatus
            }
        });

        if (hasVoyaWL && products && enrollmentPeriodKind === "cAnnualEnrollment") {

            products.push({
                ...getWLProductInfo(storeFromRedux?.employeeProfile?.getGBPResourcesForMember),
                name: CommonConstants.VOYA_WL,
                productBUID: CommonConstants.VOYA_WL,
                // description: 'Sample description here...',
                // title: 'Voya Wholelife',
                // image: 'Voya_Financial_logo.png',
                // fileType: '',
                pdfUrl: '',
                videoUrl: '',
                checked: selectedProductList.some(item => item?.bUID === CommonConstants.VOYA_WL),
                dependentStatus: false
            })
        }

        return products;
    }, [productList, selectedProductList]);

    // on select individual product
    const onChange = (event) => {
        const { name, checked } = event.target;

        let selectedProduct = null;
        let selectedProductbuid = null;
        let selectedProductName = null;
        if (name === CommonConstants.VOYA_WL) {
            selectedProduct = { bUID: CommonConstants.VOYA_WL };
            selectedProductbuid = CommonConstants.VOYA_WL;
            selectedProductName = "Whole Life"
        } else {
            selectedProduct = productList.find(product => product?.memberOfferedProduct?.bUID === name)?.memberOfferedProduct;
            selectedProductbuid = selectedProduct?.bUID
            selectedProductName = selectedProduct?.product?.productFamily
        }
        if (checked) {
            let index = selectedProductListBuids.indexOf(selectedProductbuid)
            dispatch(SELECTED_PRODUCT_LIST([...selectedProductList, selectedProduct]));
            if (index === -1) {
                dispatch(SELECTED_PRODUCT_LIST_BUIDS([...selectedProductListBuids, selectedProductbuid]))
                dispatch(SELECTED_PRODUCT_NAMES([...selectedProductNames, selectedProductName]))
            }
        } else {
            let updatedSelectedItemsArrr = selectedProductListBuids.filter(function (item) {
                return item !== selectedProductbuid
            })
            let updatedSelectedItemNamesArrr = selectedProductNames.filter(function (item) {
                return item !== selectedProductName
            })
            dispatch(SELECTED_PRODUCT_LIST(selectedProductList.filter(item => item && item.bUID !== name)));
            dispatch(SELECTED_PRODUCT_LIST_BUIDS([...updatedSelectedItemsArrr]))
            dispatch(SELECTED_PRODUCT_NAMES([...updatedSelectedItemNamesArrr]))
        }
    }

    const onSelectAll = (event) => {
        const { checked } = event.target;
        selectAllProducts(checked);
    }

    const onBack = () => {
        if (previousStep === 'EnrollmentSummary') {
            handleSetCurrentStep(previousStep);
        } else {
            handlePreviousStep();
        }
    }

    const proceedToNext = (previousStep) => {
        if (previousStep === 'EnrollmentSummary') {
            handleSetCurrentStep(previousStep);
        } else {
            handleNextStep();
        }
    }

    const onContinue = async () => {
        try {
            dispatch(UPDATE_LOADING(true));
            const { data: response } = await http.post(appConfig.SSP_MID_URL + '/v1/product-selection-submit', {
                enrollmentProcessBuid,
                productData: selectedProductList.filter(item => item.bUID !== CommonConstants.VOYA_WL).map(item => ({
                    bUID: item.bUID,
                    className: 'aSPLI_GBPModelIndividualProduct'
                })),
                subscribedProductBuid: unselectedProducts,
                isunselectedProducts: isunselectedProducts
            });

            updateStepperNavigatorInfo(currentStep, dispatch, {
                selectedProductList,
                productList,
                forms: formsConfig.forms,
                gbpResources: storeFromRedux?.employeeProfile?.getGBPResourcesForMember
            })
            dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
            Object.keys(response).forEach(key => {
                const data = { _Result: response[key]?.data };
                switch (key) {
                    case 'getProcess':
                        if (response[key].success) {
                            dispatch(onLoadAPI({ getProcessResponse: data }));
                            dispatch(UPDATE_ENROLLMENT_DATA(data._Result));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'getProcess',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'getCoveredPersonTypeConfiguration':
                        if (response[key].success) {
                            dispatch(DEPENDENT_REPLATIONSHIP(data._Result));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'getCoveredPersonTypeConfiguration',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'subscribeThisListOfProducts':
                        if (response[key].success) {
                            dispatch(onLoadAPI({ SubscribeResponse: data }));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'subscribeThisListOfProducts',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    case 'validateContract':
                        if (response[key].success) {
                            dispatch(onLoadAPI({ ValidateContractData: data }));
                            dispatch(UPDATE_ENROLLMENT_DATA(data._Result));
                        } else {
                            dispatch(SET_ALERT_MESSAGE({
                                type: 'error',
                                errorCode: data[key]?.data?.error?.code || '520',
                                continue: true,
                                id: 'validateContract',
                                show: true,
                                page: currentStep,
                            }));
                        }
                        break;
                    default: break;
                }
            })

            if (response?.getCoveredPersonTypeConfiguration?.data?.length > 0) {
                // Dependent Information Applicable
                proceedToNext();
            } else {
                // Dependent Information Not Applicable
                // Update coverage selection step information
                const keys = stepKeys.filter(item => item !== 'DependentInformation');
                updateStepperNavigatorInfo('DependentInformation', dispatch, {
                    productList,
                    stepKeys: keys,
                    selectedProductList,
                    forms: formsConfig.forms,
                    gbpResources: storeFromRedux?.employeeProfile?.getGBPResourcesForMember
                });
                handleRemoveStepsAndNext(['DependentInformation']);
            }
        } catch (err) {
            console.error(err);
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: '520',
                continue: true,
                id: 'productSelectionSubmit',
                show: true,
                page: currentStep,
            }));

        } finally {
            dispatch(UPDATE_LOADING(false));
        }
    }

    const hasSelected = productsToDisplay?.some(item => item.checked);
    return (
        <div className="product-selection">
            {storeFromRedux?.insuredInfo?.isLoading && <Loader loading className="loaderUpdateCls" />}
            <Products products={productsToDisplay} disabled={isLinearFlow} onChange={onChange} onSelectAll={onSelectAll} />
            <div className="row">
                <div className="col-12 d-flex justify-content-end">
                    <button className="btn btn-outline-primary primaryOutline ml-3" type="button" onClick={onBack}>Back</button>
                    <button className="btn btn-primary primaryBackground ml-3" type="button" disabled={!hasSelected || hasErrors} onClick={onContinue}>Continue</button>
                </div>
            </div>
        </div>
    )
});
export default ProductSelection;