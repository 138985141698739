
// Utility functions for handling steps in a step form.

// Function to calculate the next step.
// It checks if the next step is in skipSteps. If yes, it increments again.
// Parameters: current step, array of all step keys, array of steps to be skipped
export const nextStep = (currentStep, stepKeys, skipSteps) => {
    let nextIndex = stepKeys.indexOf(currentStep) + 1;
  
    // If next step is in skipSteps, increment again
    while (skipSteps.includes(stepKeys[nextIndex])) {
      nextIndex++;
    }
  
    // Returns the key of the next step.
    return stepKeys[nextIndex];
  };
  
  // Function to calculate the previous step.
  // It checks if the previous step is in skipSteps. If yes, it decrements again.
  // Parameters: current step, array of all step keys, array of steps to be skipped
  export const previousStep = (currentStep, stepKeys, skipSteps) => {
    let previousIndex = stepKeys.indexOf(currentStep) - 1;
  
    // If previous step is in skipSteps, decrement again
    while (skipSteps.includes(stepKeys[previousIndex])) {
      previousIndex--;
    }
  
    // Returns the key of the previous step.
    return stepKeys[previousIndex];
  };
  
  // Function to get the first step. It does not consider skipSteps.
  // Parameters: array of all step keys
  export const goToFirstStep = (stepKeys) => {
    return stepKeys[0];
  };
  
  // Function to get the last step. It does not consider skipSteps.
  // Parameters: array of all step keys
  export const goToLastStep = (stepKeys) => {
    return stepKeys[stepKeys.length - 1];
  };
  
  // Function to go to a specific step. It does not consider skipSteps.
  // Parameters: step to go to, array of all step keys
  export const gotoSpecificStep = (step, stepKeys) => {
    // Returns the key of the specific step if it exists, otherwise null.
    return stepKeys.includes(step) ? step : null;
  };
  