//import { validationTriggered } from '../../../Store/Slices/Entities/response/index'
import { apiConstants } from '../../../Common/Constants';
import appConfig from '../../../appConfig';
import { array, bool } from 'yup';
import FormatHelper from '../../Utils/Helper/FormatHelper';
import http from '../../../Common/Services/HttpService';
import { useDispatch, useSelector } from 'react-redux';
import {
  APIRequestParallel,
  APIRequestSequential,
  APIRequestSequential2,
  onLoadAPI,
} from '../../../Store/Slices/Entities/response/index';
import { isObject } from 'lodash';
import { getValueFromKeyStringAcceptArray } from './GetValueFromKeyStringAcceptArray';

const formatHelper = new FormatHelper();
class ServiceHelper {
  //User already entered data(from other Form), is mapped to configured field while selecting on current Form
  setFormDataMapping(elements, formik, formName, counter, index) {
    elements.forEach((row) => {
      row.forEach((item) => {
        if (
          (item.mappingDataConfig || item.populateDataConfig || item.resetMappingDataConfig) &&
          (item.displayConfig == null ||
            item.displayConfig.formDataMapOnHide == null ||
            item.displayConfig.formDataMapOnHide)
        ) {
          if (item.populateDataConfig) {
            formik.values[formName][counter][item.name] = index;
          } else if (item.resetMappingDataConfig) {
            formik.values[formName][counter][item.name] = '';
          } else {
            formik.values[formName][counter][item.name] =
              formik.values[item.mappingDataConfig.dataSource][index][item.name];
          }
        }
      });
    });
    formik.setValues({ ...formik.values });
  }

  //Mapping the Redux data from store  to input fields and formik
  setFormReduxDataMapping = (elements, elementColor, handleChangeCheck, formik) => {
    if (elements && elementColor) {
      for (let index = 0; index < elements.length; index++) {
        let isEmpty = Object.keys(elementColor).length === 0;

        for (let index1 = 0; index1 < elements[index].length; index1++) {
          if (
            elements[index][index1].update &&
            elements[index][index1].update.reduxPath &&
            isEmpty == false &&
            handleChangeCheck == false
          ) {
            var objName = elements[index][index1].update.reduxPath;
            var key = '';
            var value = elementColor;
            var formikFieldName = '';
            objName &&
              objName.split('.').forEach((reduxPropertyName) => {
                formikFieldName = reduxPropertyName;
                value = value[reduxPropertyName];
              });
            key = elements[index][index1].update.formikPath + '.' + formikFieldName;
            formik.setFieldValue(key, value);
          }
        }
      }
    }
  };

  //Mapping the Redux data from store  to input fields and formik
  dataMappingFromRedux = (elements, dataLayout, handleChangeCheck, formik) => {
    if (elements && elements.length > 0 && Object.keys(dataLayout).length > 0) {
      elements.forEach((element, indexElement) => {
        if (element && element.length > 0) {
          element.forEach((innerElement, indexInner) => {
            const reduxPath = innerElement?.update?.reduxPath || '';
            let value = dataLayout;
            let formikFieldName = '';
            let key = '';
            const pascalCaseSkipArray = innerElement?.update?.pascalCaseSkip;

            if (reduxPath && Array.isArray(reduxPath)) {
              let tempValue = '';
              reduxPath.forEach((eachPathValue) => {
                value = dataLayout;
                let redexKey = '';
                eachPathValue &&
                  eachPathValue.split('.').forEach((reduxPropertyName) => {
                    formikFieldName = reduxPropertyName;

                    value = value && value?.[reduxPropertyName];

                    redexKey = reduxPropertyName;
                  });
                if (value) {
                  if (!pascalCaseSkipArray?.includes(redexKey)) {
                    value = formatHelper.toPascalCase(value);
                  } else {
                  }
                  tempValue += value + ' ';
                }
              });
              if (tempValue) {
                value = tempValue;
              }
              key = innerElement?.update?.formikPath;
            } else {
              reduxPath &&
                reduxPath.split('.').forEach((reduxPropertyName) => {
                  formikFieldName = reduxPropertyName;
                  let redexKey = reduxPropertyName;
                  if (Array.isArray(value)) {
                    value = value[0]?.[reduxPropertyName];
                    // if (!pascalCaseSkipArray?.includes(redexKey)) {
                    //   value = formatHelper.toPascalCase(value);
                    // }
                  } else {
                    value = (value && value?.[reduxPropertyName]) || '';

                    if (
                      value &&
                      typeof value !== 'object' &&
                      value.constructor !== Object &&
                      typeof value === 'string' &&
                      typeof value === 'boolean'
                    ) {
                      if (!pascalCaseSkipArray?.includes(redexKey)) {
                        value = formatHelper.toPascalCase(value);
                      }
                    }
                  }
                });
              key = innerElement?.update?.formikPath + '.' + formikFieldName;
            }
            if (
              innerElement &&
              innerElement?.update &&
              innerElement?.update?.formikPath &&
              formikFieldName &&
              value &&
              !isObject(value)
            ) {
              formik.setFieldValue(key, value);
            }

            if (innerElement?.mapping) {
              innerElement.value = !isObject(value) ? value : '';
              if (
                value === undefined ||
                (value === '' && !innerElement?.modal) ||
                value === 'meUndefined' ||
                value === null
              ) {
                innerElement.disabled = false;
                innerElement.value = '';

                if (!innerElement?.editable) {
                  innerElement.type = 'readOnly';
                }
                if (innerElement?.edit) {
                  innerElement.type = 'text';
                }
              } else if (!innerElement?.modal) {
                //code to edit the field
                if (innerElement?.edit) {
                  innerElement.type = 'text';
                  // innerElement.disabled = false;
                } else {
                  innerElement.type = 'readOnly';
                  innerElement.disabled = false;
                }
                // innerElement.type = 'readOnly';
              }
            }

            formik.setFieldValue(key, value);
          });
        }
      });
    }
  };

  //set read only field value
  setReadOnlyValueMapping = (elements, formik) => {
    for (let index = 0; index < elements?.length; index++) {
      for (let index1 = 0; index1 < elements[index]?.length; index1++) {
        var key = '';
        var value = '';
        if (elements[index][index1].getValuesFrom && elements[index][index1].formikPath) {
          let elementName = elements[index][index1].name;
          let elementNameFinal = elementName.replace('Value' + '');
          value =
            formik.values[elements[index][index1].formikPath][
            elements[index][index1].getValuesFrom
            ];
          key = elements[index][index1].formikPath + '.' + elementNameFinal;
          formik.setFieldValue(key, value);
          elements[index][index1].value = value;
        }
      }
    }
  };

  //User already entered data (from other Form), is removed from configured field while unselecting on current Form
  clearFormDataMapping(elements, dataConfig, formik, formName, counter) {
    if (formik.values[formName][counter][dataConfig.dataField] === '') {
      return null;
    }
    elements.forEach((row) => {
      row.forEach((item) => {
        if (
          (item.mappingDataConfig || item.populateDataConfig || item.resetMappingDataConfig) &&
          (item.displayConfig == null ||
            item.displayConfig.formDataMapOnHide == null ||
            item.displayConfig.formDataMapOnHide)
        ) {
          if (item.resetMappingDataConfig) {
            formik.values[formName][counter][item.name] = true;
          } else {
            formik.values[formName][counter][item.name] = '';
          }
        }
      });
    });
    formik.setValues({ ...formik.values });
  }
  //Check configuration whether to show/hide element
  hideElement(element, formik, formName, counter, isMultiple, valuesArray = []) {
    if (
      element.label == 'Continue to Next Product' &&
      formik?.values?.CoverageSelection?.hideDescription == true &&
      formik?.values?.CoverageSelection?.hideOptions1 == true &&
      formik?.values?.CoverageSelection?.hideTableCoverage == true
    ) {
      return;
    }
    /* To apply element display property in single and multi form*/
    let hideElementFlag = false;
    if (isMultiple && element?.displayConfig?.field) {
      if (
        (formik && formik.values[formName][counter][element.displayConfig.field]) ===
        element.displayConfig.show
      ) {
        hideElementFlag = true;
      }
    } else if (element?.displayConfig?.field) {
      //check for displayconfig
      if (
        (formik && formik.values[formName][element?.displayConfig?.field]) ===
        element.displayConfig.show
      ) {
        hideElementFlag = true;
      }
    } else if (element?.displayConfig?.validation) {
      /* Based on valuesArray length and condtion display of perticular element is dertermined */
      if (
        valuesArray.length > 0 &&
        eval(`${valuesArray.length} ${element.displayConfig.validation.condition}`)
      ) {
        hideElementFlag = element.displayConfig.show;
      }
    } else if (element?.hideElement) {
      hideElementFlag = true;
    }

    return hideElementFlag;
  }
  //Generate data list from data object
  generateDataList(dataObject, dataConfig) {
    let dataList = [];
    let dataCollectionList = dataObject.values[dataConfig.dataSource];
    if (dataCollectionList && Array.isArray(dataCollectionList)) {
      dataList = dataCollectionList.map((data, index) => {
        return { value: index, text: dataConfig.dataFormat ? eval(dataConfig.dataFormat) : data };
      });
    }
    return dataList;
  }

  // handleChange (props, formName, isMultiple){
  //     if (!isMultiple && typeof props.setSkipAndGo == 'function') {
  //       props.setSkipAndGo((prevSkipAndGo) => {
  //         if (!prevSkipAndGo.includes(formName)) {
  //           prevSkipAndGo.push(formName);
  //           dispatch(validationTriggered());
  //         }
  //         return prevSkipAndGo;
  //       });
  //     }
  // };

  // To get options dynamically based on mapper
  getOptions(mapperObject, reduxObject) {
    let options = [];
    mapperObject.forEach((currentSection) => {
      let resultObjects =
        (currentSection['takingDateform'] && reduxObject[currentSection['takingDateform']]) ||
        reduxObject;
      if (Array.isArray(resultObjects)) {
        resultObjects.forEach((currentObject) => {
          let value = eval(currentSection['value']);
          let text = eval(currentSection['text']);
          options.push({
            value: value,
            text: text,
          });
        });
      } else {
        let currentObject = resultObjects;
        options.push({
          value: eval(currentSection['value']),
          text: eval(currentSection['text']),
        });
      }
    });

    return options;
  }
  // To get status using date
  getSatusUsingDate(sourceElements, element) {
    let checkValidDate = (dateValue) => {
      return dateValue instanceof Date;
    };
    let firstValue = (sourceElements[0] && new Date(sourceElements[0])) || '';
    let secondValue = (sourceElements[1] && new Date(sourceElements[1])) || '';

    if (sourceElements.length > 0 && checkValidDate(firstValue) && checkValidDate(secondValue)) {
      let rules = element.valueConfig.conditions;
      for (let i = 0; i < rules.length; i++) {
        if (eval(`${'firstValue'} ${rules[i].rule} ${'secondValue'}`)) {
          return rules[i].value;
        }
      }
    }
    return '';
  }

  // to get values from existing object
  getValue(mapperObject, reduxObject) {
    let currentObject =
      (mapperObject['takingDateform'] && reduxObject[mapperObject['takingDateform']]) ||
      reduxObject;
    let value = eval(mapperObject['value']) || '';

    return value;
  }
  dateDiff(fromDate, ToDate) {
    var date1 = new Date(fromDate);
    var date2 = new Date(ToDate);
    var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
    return diffDays;
  }
  getDays(givenDate) {
    var currentDate = new Date();
    let dateDiffs = this.dateDiff(currentDate, givenDate);
    if (dateDiffs < 0) {
      dateDiffs = 0;
    }
    return dateDiffs + ' days remaining';
  }
  //Execute the template string in variable
  //props is a string or template string Example- "this.data.address" /  "${this.data.address ? `${this.data.address}, ` : ''}""
  //data is an object Example- {address: "abc", city:"", zipCode:""}
  eValCustom(props, data) {
    const dataObject = { data: data };
    let getTemplateValue = function (props, dataObject) {
      if (props.includes('$') === false) {
        props = '${' + props + '}';
      }
      return new Function('return `' + props + '`;').call(dataObject);
    };
    return getTemplateValue(props, dataObject);
  }
  //Replacement to JAVA eval function
  //props is a string or ternary operator string with semicolon separator Example- "address" or  "address ? 'address, ' : ''; city ? 'city ':''"
  //data is an object Example- {address: "abc", city:"Bristol", zipCode:"12345"}
  eVal(props, data) {
    data = data ? data : {};
    props = props ? props : '';
    let eValue = '';
    if (props.includes('?')) {
      let elements = props.split(';');
      elements.forEach((itemElement) => {
        if (itemElement) {
          let itemProps = itemElement.split('?');
          if (itemProps.length >= 2) {
            let dataProps = itemProps[1].split(':');
            if (dataProps.length >= 2) {
              let prop = itemProps[0].replaceAll(' ', '');
              eValue += data[prop]
                ? data[prop] +
                dataProps[0]
                  .replace(prop, '')
                  .replace(' ', '')
                  .replaceAll("'", '')
                  .replace(' ', '')
                : dataProps[1];
            }
          }
        }
      });
    } else {
      eValue = data[props];
    }
    return eValue ? eValue : '';
  }

  // element for API response
  // index to differnciate and identify list
  dynamicAttributeMapping(element, index) {
    switch (element.fieldName) {
      case 'aSPCB_AdditionalData_Desc_Integer':
        return {
          type: 'text',
          name: `${index}${element.fieldTempName}`,
          label: `${element.fieldName}`,
          validations: {},
          placement: 'col-md-6 col-12',
          accordingDisplay: true,
        };
      case 'boolean':
        return {
          type: 'text',
          name: `${index}${element.fieldTempName}`,
          label: `${element.fieldName}`,
          validations: {},
          placement: 'col-md-6 col-12',
          accordingDisplay: true,
        };
      default:
        return {
          type: 'text',
          name: `${index}${element.fieldTempName}`,
          label: `${element.fieldName}`,
          validations: {},
          placement: 'col-md-6 col-12',
          accordingDisplay: true,
        };
    }
  }

  dateSort(srcArray, sortKey) {
    return srcArray.sort((a, b) => {
      return new Date(b[sortKey]) - new Date(a[sortKey]);
    });
  }
  hideOnloadElements(formik, props, element) {
    let value = '';
    let valArray = element?.displayConfig?.validationCheckElement;

    for (let i = 0; i < valArray?.length; i++) {
      value = valArray[i];
    }
    value = eval(value);
    if (value && element?.displayConfig?.checkValues) {
      element.hidden = true;
      return element.hidden;
    }
  }

  //Show Toggle Elements
  showToggleElements(formik, props, element) {
    let validateField = element?.displayConfig?.validateField;
    let validationCheckElement = element?.displayConfig?.validationCheckElement;
    let value1 = formik?.values?.EmployeeProfile?.alternateAddress;

    for (let i = 0; i < validateField?.length; i++) {
      const validationItem = validateField[i];
      if (formik.errors?.[props.formName]?.[validationItem]) {
        formik.setTouched({
          ...formik.touched,
          [props.formName]: {
            ...formik.touched[props.formName],
            ...element.displayConfig?.renderElements,
          },
        });
        return;
      }
    }
    formik.setValues({
      ...formik.values,
      [props.formName]: {
        ...formik.values[props.formName],
        ...element.displayConfig?.valueUpdate,
      },
    });
  }

  //Show Toggle Elements
  showToggleContinueToNetElements(formik, props, element) {
    formik.setValues({
      ...formik.values,
      [props.formName]: {
        ...formik.values[props.formName],
        ...{
          hideDescription: false,
          hideOptions1: false,
          hideTableCoverage: false,
        },
      },
    });
  }

  //Making the api call sequentially by providing the response of previous api payload to next api configured through elements in middleware
  createPayload(urlProp, formik, currentReduxState, respArray) {
    let reduxValue = {};
    let reduxObj = {};

    urlProp?.payload?.forEach((element, index, array) => {
      if (element?.payloadType == 'evalString') {
        let key = element?.payloadKey;

        reduxValue = eval(element?.payloadValue);
        if (key) {
          reduxObj = { ...reduxObj, [key]: reduxValue };
        }
      } else if (element?.payloadType == 'evalObject') {
        element?.payloadValue?.forEach((element, index, array) => {
          reduxValue = eval(element?.reduxValue);
          let key = element?.reduxKey;
          reduxObj = { ...reduxObj, [key]: reduxValue };
        });
      }
    });
    return reduxObj;
  }

  async sendRequest(urlProp, formik, currentReduxState, respArray) {
    let resp = {};
    const payload = this.createPayload(urlProp, formik, currentReduxState, respArray);
    await http
      .post(appConfig.SSP_MID_URL + urlProp?.url, {
        payload: payload,
        currentReduxState: currentReduxState
      })
      .then((response) => {

        resp = response;
        respArray.push(response.data);
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
    return resp;
  }

  async apiCall(formik = {}, props, element, currentReduxState, setResponsePayload) {
    let respArray = [];
    for (let eleInd = 0; eleInd < element?.apiCallURL?.length; eleInd++) {
      const urlProp = element?.apiCallURL[eleInd];
      try {
        await this.sendRequest(urlProp, formik, currentReduxState, respArray);
        // const response = await this.sendRequest(urlProp, formik, currentReduxState, respArray);

        if (eleInd == element?.apiCallURL.length - 1) {
          // setResponsePayload(respArray);
          // const response = await this.sendRequest(urlProp, formik, currentReduxState, respArray);
          // }else if ( response && response.status && response.status === 200) {
          setResponsePayload(respArray);
        }
      } catch (error) {
        
      }
    }
  }

  async apiNextCall(formik = {}, props, element, currentReduxState, setResponsePayload) {
    let respArray = [];
    for (let eleInd = 0; eleInd < element?.apiNextCallURL?.length; eleInd++) {
      const urlProp = element?.apiNextCallURL[eleInd];
      try {
        await this.sendRequest(urlProp, formik, currentReduxState, respArray);

        if (eleInd == element?.apiNextCallURL.length - 1) {
          setResponsePayload(respArray);
        }
      } catch (error) {
       
      }
    }
  }
  async apiSecondCall(formik = {}, props, element, currentReduxState, setResponsePayload) {
    let respArray = [];
    for (let eleInd = 0; eleInd < element?.apiSecondCallURL?.length; eleInd++) {
      const urlProp = element?.apiSecondCallURL[eleInd];
      try {
        await this.sendRequest(urlProp, formik, currentReduxState, respArray);

        if (eleInd == element?.apiSecondCallURL.length - 1) {
          setResponsePayload(respArray);
        }
      } catch (error) {
        
      }
    }
  }

  async apiUnsubscribeCall(formik = {}, props, element, currentReduxState, setResponsePayload) {
    let respArray = [];
    for (let eleInd = 0; eleInd < element?.apiUnsubscribeCallURL?.length; eleInd++) {
      const urlProp = element?.apiUnsubscribeCallURL[eleInd];
      try {
        await this.sendRequest(urlProp, formik, currentReduxState, respArray);

        if (eleInd == element?.apiUnsubscribeCallURL.length - 1) {
          setResponsePayload(respArray);
        }
      } catch (error) {
        
      }
    }
  }

  async apiUpdatesubscribeCall(formik = {}, props, element, currentReduxState, setResponsePayload) {
    let respArray = [];
    for (let eleInd = 0; eleInd < element?.apiUpdatesubscribeCallURL?.length; eleInd++) {
      const urlProp = element?.apiUpdatesubscribeCallURL[eleInd];
      try {
        await this.sendRequest(urlProp, formik, currentReduxState, respArray);

        if (eleInd == element?.apiUpdatesubscribeCallURL.length - 1) {
          setResponsePayload(respArray);
        }
      } catch (error) {
        
      }
    }
  }
  async apiDisclosureAgreeCall(formik = {}, props, element, currentReduxState, setResponsePayload) {
    let respArray = [];
    for (let eleInd = 0; eleInd < element?.apiDisclosureAgreeCallURL?.length; eleInd++) {
      const urlProp = element?.apiDisclosureAgreeCallURL[eleInd];
      try {
        await this.sendRequest(urlProp, formik, currentReduxState, respArray);

        if (eleInd == element?.apiDisclosureAgreeCallURL.length - 1) {
          setResponsePayload(respArray);
        }
      } catch (error) {
        
      }
    }
  }
  async apiDisclosureDisAgreeCall(formik = {}, props, element, currentReduxState, setResponsePayload) {
    let respArray = [];
    for (let eleInd = 0; eleInd < element?.apiDisclosureDisAgreeCallURL?.length; eleInd++) {
      const urlProp = element?.apiDisclosureDisAgreeCallURL[eleInd];
      try {
        await this.sendRequest(urlProp, formik, currentReduxState, respArray);

        if (eleInd == element?.apiDisclosureDisAgreeCallURL.length - 1) {
          setResponsePayload(respArray);
        }
      } catch (error) {
        
      }
    }
  }

  async  sendApiRequest(url, payload) {
    const maxRetries = 3;
    let retries = 0;

    while (retries < maxRetries) {
        try {
            const response = await http.post(appConfig.SSP_MID_URL + url, { payload });
            return response;
        } catch (error) {
            if (error.response && error.response.status === 409) {
                console.error('Conflict error, retrying...');
                retries++;
                await new Promise(r => setTimeout(r, 1000));  // wait for 1 second before retrying
            } else {
                console.error(error);
                return error;
            }
        }
    }

    throw new Error('Max retries exceeded');
}

 

  //making the api call
  // apiCallArray(
  //   formik,
  //   props,
  //   element,
  //   loggedUserEntity,
  //   SearchGBPForLegalEntity,
  //   formName,
  //   currentReduxState,
  //   storePayload = false,
  //   setResponsePayload = () => {}
  // ) {
  //   let edit = props.formik.values.edit;
  //   let formikValue = formik.values[formName][element.assignValueTo];
  //   const sendRequest = async () => {
  //     const resp = await http
  //       .post(
  //         // appConfig.SSP_MID_URL + (formikValue ? element.apiCallURL[0] : element.apiCallURL[1]),
  //         // appConfig.SSP_MID_URL + (formikValue ? element.apiCallURL[0] : element.apiCallURL[1]),
  //         // appConfig.SSP_MID_URL + (formikValue ? element.apiCallURL[0] : element.apiCallURL[1]),
  //         // appConfig.SSP_MID_URL + (formikValue ? element.apiCallURL[1] : element.apiCallURL[0]),
  //         appConfig.SSP_MID_URL + element.apiCallURL[0],

  //         {
  //           data: formik.values,
  //           loggedUserEntity,
  //           SearchGBPForLegalEntity,
  //           currentReduxState,
  //         }
  //       )
  //       .then((resp) => {
  //         if (storePayload) {
  //           setResponsePayload(resp);
  //         }
  //         let value = '';
  //         if (element.expression) {
  //           value = eval(element.expression);
  //         }
  //         let key = props.formName + '.' + element.assignValueTo;
  //         formik.setFieldValue(key, value);
  //       });
  //   };

  //   try {
  //     sendRequest();
  //   } catch (error) {
  //     
  //   }
  // }

  apiCallArray(
    formik = {},
    props,
    element,
    loggedUserEntity,
    subscribedcontracts,
    formName,
    currentReduxState,
    storePayload = false,
    setResponsePayload = () => { },
    legalEntityId = '',
    appCode = '',
    basicAuthorizationValue = ''
  ) {
    let edit = props.formik.values.edit;
    let formikValue = formik.values[formName][element.assignValueTo];
    const sendRequest = async () => {
      try {
        if (!element?.apiConfig?.callAuthModuleDirectly) {
          const resp = await http
            .post(
              // appConfig.SSP_MID_URL + (formikValue ? element.apiCallURL[0] : element.apiCallURL[1]),
              // appConfig.SSP_MID_URL + (formikValue ? element.apiCallURL[0] : element.apiCallURL[1]),
              // appConfig.SSP_MID_URL + (formikValue ? element.apiCallURL[0] : element.apiCallURL[1]),
              // appConfig.SSP_MID_URL + (formikValue ? element.apiCallURL[1] : element.apiCallURL[0]),
              appConfig.SSP_MID_URL + element.apiCallURL[0],

              {
                data: formik.values,
                loggedUserEntity,
                subscribedcontracts,
                currentReduxState,
              }
            )
            .then((resp) => {
              if (storePayload) {
                setResponsePayload(resp);
              }
              let value = '';
              if (element.expression) {
                value = eval(element.expression);
              }
              let key = props.formName + '.' + element.assignValueTo;
              formik.setFieldValue(key, value);
            });
        }
        //mfa
        else {
          let requestObj = {};
          if (element?.apiConfig.createCustomPayload) {
            for (let i = 0; i < element?.apiConfig['formikObjectFields'].length; i++) {
              if (!element?.apiConfig.filterPhoneNumberForOkta)
                requestObj[element?.apiConfig['formikObjectFields'][i]] =
                  getValueFromKeyStringAcceptArray(
                    formik,
                    element?.apiConfig['formikObjectPath'],
                    element?.apiConfig['formikObjectFields'][i]
                  );
              else {
                let getPhone = getValueFromKeyStringAcceptArray(
                  formik,
                  element?.apiConfig['formikObjectPath'],
                  element?.apiConfig['formikObjectFields'][i]
                );
                getPhone = `+1${getPhone.replace(/[^\d]/g, '')}`;
                // phoneNum =`+${phoneNum}`;
                requestObj[element?.apiConfig['formikObjectFields'][i]] = getPhone;
              }
            }
            for (let i = 0; i < element?.apiConfig['formikObjectFields'].length; i++) {
              requestObj[element?.apiConfig['formikObjectFields'][i]] =
                element?.apiConfig.filterPhoneNumberForOkta && i === 0
                  ? `+1${getValueFromKeyStringAcceptArray(
                    formik,
                    element?.apiConfig['formikObjectPath'],
                    element?.apiConfig['formikObjectFields'][i]
                  ).replace(/[^\d]/g, '')}`
                  : getValueFromKeyStringAcceptArray(
                    formik,
                    element?.apiConfig['formikObjectPath'],
                    element?.apiConfig['formikObjectFields'][i]
                  );
            }
          }
          const resp = await http
            .post(appConfig.SSP_AUTH_URL + element.apiCallURL[0], requestObj, {
              withCredentials: true,
              headers: {
                'Authorization': `Basic ${basicAuthorizationValue}`,
                'X-REQUEST-ID': Math.random(200)
              }
            })
            .then((resp) => {
              if (storePayload) {
                setResponsePayload(resp);
              }
              let value = '';
              if (element.expression) {
                value = eval(element.expression);
              }
              let key = props.formName + '.' + element.assignValueTo;
              formik.setFieldValue(key, value);
            })
            .catch((error) =>{
            //setResponsePayload({message:'Request failed with status code 403'});
            setResponsePayload({message:error?.message});
          })
        
        }
      } catch (error) {
        legalEntityId && appCode? setResponsePayload({error: error}) : setResponsePayload();
        
        // const { data: message } = error.message;
        var data = {};
        data.message = error.message;
      }
    };

    try {
      sendRequest();
    } catch (error) {
      
    }
  }

  mfaCallArray(
    formik,
    props,
    element,
    loggedUserEntity,
    subscribedcontracts,
    formName,
    currentReduxState,
    storePayload = false,
    setResponsePayload = () => { },
    email,
    phoneNumber,
    phoneVerificationMethod,
    legalEntityId,
    appCode='',
    basicAuthorizationValue,
    token='',
    newEmail='',
    login='',
    preferredChannel,
    tempLoginOtpMethod,
    loginOtpMethod
  ) {
    const sendRequest = async () => {
      let loginForm = (element?.formName &&(
         element?.formName === 'MultiFactorAuthenticationLogin' || element?.formName === 'MFAForgotPassword') )

      try {
        let requestObj = {};
        if (!element?.apiConfig.noPayload){

        if (!element?.apiConfig.filterPhoneNumberForOkta){
          if( element?.apiConfig['formikObjectFields'][0] === 'otpEmail' ){
            if(element.apiConfig.objectName ==="ContactDetails")
              requestObj['email']= newEmail
            else
              requestObj[element?.apiConfig['formikObjectFields']] = email
          }
        }
        if (element?.apiConfig.filterPhoneNumberForOkta) {
          for (let i = 0; i < element?.apiConfig['formikObjectFields'].length; i++) {
            if (element?.apiConfig['formikObjectFields'][i] === 'phoneNumber') {
              let getPhone = getValueFromKeyStringAcceptArray(
                formik,
                element?.apiConfig['formikObjectPath'],
                element?.apiConfig['formikObjectFields'][i]
              );
              getPhone = `+1${getPhone.replace(/[^\d]/g, '')}`;
              requestObj[element?.apiConfig['formikObjectFields'][i]] = getPhone;
            } else
              requestObj[element?.apiConfig['formikObjectFields'][i]] =
                getValueFromKeyStringAcceptArray(
                  formik,
                  element?.apiConfig['formikObjectPath'],
                  element?.apiConfig['formikObjectFields'][i]
                );
          }
        }

        else
          for (let i = 0; i < element?.apiConfig['formikObjectFields'].length; i++) {
            if(element?.apiConfig.changePayload && element?.apiConfig.newPayload ){
              requestObj['otp'] = getValueFromKeyStringAcceptArray(
                formik,
                element?.apiConfig['formikObjectPath'],
                element?.apiConfig['formikObjectFields'][i]
              );
              }
            if (element?.apiConfig.needAppCode) {
              requestObj[element?.apiConfig['formikObjectFields'][i]] =
                element?.apiConfig.filterPhoneNumberForOkta && i === 0
                  ? `+1${getValueFromKeyStringAcceptArray(
                    formik,
                    element?.apiConfig['formikObjectPath'],
                    element?.apiConfig['formikObjectFields'][i]
                  ).replace(/[^\d]/g, '')}`
                  : getValueFromKeyStringAcceptArray(
                    formik,
                    element?.apiConfig['formikObjectPath'],
                    element?.apiConfig['formikObjectFields'][i]
                  );
            }
            if (
              element?.apiConfig['formikObjectFields'][i] === 'reason' ||
              element?.apiConfig['formikObjectFields'][i] === 'token' ||
              element?.apiConfig['formikObjectFields'][i] === 'login'||
              element?.apiConfig['formikObjectFields'][i] === 'channel'
            ) {
              if (
                element?.apiConfig.needReason &&
                element?.apiConfig['formikObjectFields'][i] === 'reason'
              ) {

                requestObj['reason'] = 'password'; //urrentAPI?.apiconfig.reason
              }
              if (
                element?.apiConfig.needToken &&
                element?.apiConfig['formikObjectFields'][i] === 'token'
              ) {
                requestObj['token'] = token;
              }
              if (
                element?.apiConfig.needLogin &&
                element?.apiConfig['formikObjectFields'][i] === 'login'
              ) {
                requestObj['login'] = login;
              }
              if (
                element?.apiConfig.needChannel  &&
                element?.apiConfig['formikObjectFields'][i] === 'channel'
              ) {
                // requestObj['channel'] = tempLoginOtpMethod || preferredChannel;
                requestObj['channel'] = loginForm ? loginOtpMethod : tempLoginOtpMethod || preferredChannel;

                
              }
            } 
            else{
              if(!element?.apiConfig.changePayload && element?.apiConfig['formikObjectFields'][0] !== 'otpEmail')
                requestObj[element?.apiConfig['formikObjectFields'][i]] = getValueFromKeyStringAcceptArray(
                  formik,
                  element?.apiConfig['formikObjectPath'],
                  element?.apiConfig['formikObjectFields'][i]
                );
            }
          }

        let employeeData = {}
        !element?.apiConfig.emailOnly ?
          element?.apiConfig.needAppCode
            ? (requestObj = { employeeData, ...{ appCode: appCode } })
            : !element?.apiConfig.needSingleParameter
              ? !element?.apiConfig.filterPhoneNumberForOkta
                ? !element?.apiConfig.notNeedLegalEntityId
                  ? (requestObj = { employeeData })
                  : (requestObj = requestObj)
                : (requestObj = requestObj)
              : (requestObj = requestObj)
          : (requestObj = requestObj);
        }
        else
        {}
        //end
        const resp = await http
          .post(appConfig.SSP_AUTH_URL + element.apiCallURL[0], requestObj, {
            withCredentials: true,
            headers: {
              'Authorization': `Basic ${basicAuthorizationValue}`,
              'X-REQUEST-ID': Math.random(200)
            }
          })
          .then((resp) => {
            if (storePayload) {
              setResponsePayload(resp);
            }
            let value = '';
            if (element.expression) {
              value = eval(element.expression);
            }
            let key = props.formName + '.' + element.assignValueTo;
            formik.setFieldValue(key, value);
            setResponsePayload({success:true});

          }) 
          .catch((error)=> {
            setResponsePayload({message:error?.message});
          
          })
      } catch (error) {
        setResponsePayload({error: error});
        
        // const { data: message } = error.message;
        var data = {};
        data.message = error.message;
      }
    };

    try {
      sendRequest();
    } catch (error) {
      
    }
  }

  mfaCallArrayLogin(
    formik,
    props,
    element,
    loggedUserEntity,
    subscribedcontracts,
    formName,
    currentReduxState,
    storePayload = false,
    setResponsePayload = () => { },
    email,
    phoneNumber,
    phoneVerificationMethod,
    legalEntityId,
    appCode,
    basicAuthorizationValue,
    preferredChannel,
    tempLoginOtpMethod,
    loginOtpMethod
  ) {
    const sendRequest = async () => {

      let forgotUserForm = (element?.formName && element?.formName === 'MFAForgotUsername') || formName === 'MFAForgotUsername'
      try {
        //mfa
        let requestObj = {};
        let employeeData = {}
        for (let i = 0; i < element?.apiConfig['formikObjectFields']?.length; i++) {
          if (element?.apiConfig['formikObjectFields'][i] !== 'legalEntityId')
            employeeData[element?.apiConfig['formikObjectFields'][i]] =
              getValueFromKeyStringAcceptArray(
                formik,
                element?.apiConfig['formikObjectPath'],
                element?.apiConfig['formikObjectFields'][i]
              );
          else
            employeeData[element?.apiConfig['formikObjectFields'][i]] = legalEntityId
        }

        (requestObj = { employeeData, ...{ appCode: appCode } })
  
       if (element?.apiConfig.needChannel && preferredChannel && !forgotUserForm)
        (requestObj = { employeeData, ...{ appCode: appCode, channel:preferredChannel } })
        else if (element?.apiConfig.needChannel && tempLoginOtpMethod && !forgotUserForm)
        (requestObj = { employeeData, ...{ appCode: appCode, channel:tempLoginOtpMethod } })
        else if (element?.apiConfig.needChannel && loginOtpMethod && forgotUserForm)
        (requestObj = { employeeData, ...{ appCode: appCode, channel:loginOtpMethod } })
      else
        (requestObj = { employeeData, ...{ appCode: appCode } })
        const resp = await http
          .post(appConfig.SSP_AUTH_URL + element.apiCallURL[0], requestObj, {
            withCredentials: true,
            headers: {
              'Authorization': `Basic ${basicAuthorizationValue}`,
              'X-REQUEST-ID': Math.random(200)
            }

          })
          .then((resp) => {
            if (storePayload) {
              setResponsePayload(resp);
            }
            let value = '';
            if (element.expression) {
              value = eval(element.expression);
            }
            let key = props.formName + '.' + element.assignValueTo;
            formik.setFieldValue(key, value);
          }) .catch((error)=> {
            setResponsePayload({message:error?.message});
          })
          ;
      } catch (error) {
        setResponsePayload();
        
        // const { data: message } = error.message;
        var data = {};
        data.message = error.message;
      }
    };

    try {
      sendRequest();
    } catch (error) {
      
    }
  }
  mfaCallArrayLoginPrimary(
    formik,
    props,
    element,
    loggedUserEntity,
    subscribedcontracts,
    formName,
    currentReduxState,
    storePayload = false,
    setResponsePayload = () => { },
    email,
    phoneNumber,
    phoneVerificationMethod,
    legalEntityId,
    appCode,
    basicAuthorizationValue,
    login,
    password,
  ) {
    const sendRequest = async () => {
      try {
        //mfa
        let requestObj = {};
        let employeeData = {}
        //requestObj[element?.apiConfig['formikObjectFields']] =email
        //start
        for (let i = 0; i < element?.apiConfig['reduxObjectFields']?.length; i++) {
          if (element?.apiConfig['reduxObjectFields'][i] === 'legalEntityId')
            requestObj['legalEntityId'] = legalEntityId
          if (element?.apiConfig['reduxObjectFields'][i] === 'login')
            requestObj['login'] = login
          if (element?.apiConfig['reduxObjectFields'][i] === 'password')
            requestObj['password'] = password
        }
        requestObj['appCode'] = appCode
        //end

        const resp = await http
          .post(appConfig.SSP_AUTH_URL + element.apiCallURL[0], requestObj, {
            withCredentials: true,
            headers: {
              'Authorization': `Basic ${basicAuthorizationValue}`,
              'X-REQUEST-ID': Math.random(200)
            }
          })
          .then((resp) => {
            if (storePayload) {
              setResponsePayload(resp);
            }
            let value = '';
            if (element.expression) {
              value = eval(element.expression);
            }
            let key = props.formName + '.' + element.assignValueTo;
            formik.setFieldValue(key, value);
          });
      } catch (error) {
        setResponsePayload();
        
        // const { data: message } = error.message;
        var data = {};
        data.message = error.message;
      }
    };

    try {
      sendRequest();
    } catch (error) {
      
    }
  }



  //populate value on check box click
  populateValues(element, formik, formName, props, checked, formikInRedux, data) {
    let tempFormik = formik;
    let formikKeyElements = element.populateValue.to;
    let formikKeyValues = element.populateValue.from;
    formikKeyElements.forEach((key, index) => {
      // const value =
      //   formik.values[element.populateValue.formName][formikKeyValues[index]] ||
      //   formikInRedux[formikKeyValues[index]];
      const value =
        Object.keys(data).length > 0
          ? data[formikKeyValues[index]]
          : formikInRedux[formikKeyValues[index]];
      if (checked == 'true') {
        if (value) formik.setFieldValue(key, value);
        else
          formik.setFieldValue(
            key,
            tempFormik.values[element.populateValue.formName][formikKeyValues[index]]
          );
      } else if (checked == 'false') {
        formik.setFieldValue(key, '');
      }
    });
    return Object.keys(data).length > 0 ? data : formikInRedux;
    // return formik.values[element.populateValue.formName] || formikInRedux;
  }

  //Code to make the checkbox true/false based on checing in configuration

  updatingCheckBox(element, formik, formName, props, checked, formikInRedux) {
    let formikKeyValues = element.populateCheckBoxValue.from;
    if (checked == 'false') {
      let key = formName + '.' + 'validationChecking';
      let value = 'true';
      formik.setFieldValue(key, value);
    } else {
      for (let i = 0; i < formikKeyValues.length; i++) {
        const value = formik.values[element.populateCheckBoxValue.formName][formikKeyValues[i]];
        if (element.name != formikKeyValues[i]) {
          if (value == false) {
            let key = formName + '.' + 'validationChecking';
            let value = 'true';
            formik.setFieldValue(key, value);
            break;
          } else {
            let key = formName + '.' + 'validationChecking';
            let value = '';
            formik.setFieldValue(key, value);
          }
        } else {
          let key = formName + '.' + 'validationChecking';
          let value = '';
          formik.setFieldValue(key, value);
        }
      }
    }
  }

  //Check configuration whether to show/hide element
  //  enableElement(element, formik, formName, counter, isMultiple, valuesArray = []) {
  //   /* To apply element display property in single and multi form*/

  //   if (element?.enableElement) {

  //     let  style = element?.enableElement?.elementStyle
  //     return style;
  //   }

  // }

  //Update the field and formik value when options are checked
  updateReadOnly(value, element, formik, formName, props) {
    let optionKey = formName + '.' + element.updateValueTo;

    formik.setFieldValue(optionKey, value);
  }

  getOptionsFromRedux(optionsList) {
    let arr = [
      { value: 'Please Select One', label: 'Please Select One' },
      { value: 'Not one of these', label: 'Not one of these' },
      { value: 'Never Visited', label: 'Never Visited' },
    ];
    let obj = {};
    for (let i = 0; i < optionsList?.length; i++) {
      let text = optionsList[i].Name;

      let value = optionsList[i].Code;
      obj = { label: optionsList[i].Name, value: optionsList[i].Code };

      arr.push(obj);

    }
    return arr;
  }

  getOptionsFromReduxDogList(optionsList) {

    let arr = [];
    let obj = {};
    for (let i = 0; i < optionsList?.length; i++) {
      let text = optionsList[i].name;

      let value = optionsList[i].name;
      obj = { label: optionsList[i].name, value: optionsList[i].name };

      arr.push(obj);
    }
    return arr;
  }
  getUnderWritingQuestions(name, radioLabel, medicalSurveys) {


    let radioLabelValue;
    if (medicalSurveys?.length > 0) {
      if (name == "PetAssureNation.underwritingQuestionOne") {
        radioLabelValue = "1. " + medicalSurveys[0].text
      }
      else if (name == "PetAssureNation.underwritingQuestionTwo") {
        radioLabelValue = "2. " + medicalSurveys[1].text
      }
      else if (name == "PetAssureNation.underwritingQuestionThree") {
        radioLabelValue = "3. " + medicalSurveys[2].text
      }
      else if (name == "PetAssureNation.underwritingQuestionFour") {
        radioLabelValue = "4. " + medicalSurveys[3].text
      }


    }
    return radioLabelValue;
  }

  getUnderWritingQuestionsData(medicalSurveys) {


    let radioLabelValue;
    let tempArray =[];
    for (let i = 0; i < medicalSurveys?.length; i++) {
      // radioLabelValue=  i+1 +". " +medicalSurveys[i].text;
      // 
      tempArray.push({"question": (i+1)+ '. '+ medicalSurveys[i].text,"supplementQuestion":  medicalSurveys[i].supplementalQuestions[0]?.text})
    }
    // if (medicalSurveys?.length > 0) {
    //   if (name == "PetAssureNation.underwritingQuestionOne") {
    //     radioLabelValue = "1. " + medicalSurveys[0].text
    //   }
    //   else if (name == "PetAssureNation.underwritingQuestionTwo") {
    //     radioLabelValue = "2. " + medicalSurveys[1].text
    //   }
    //   else if (name == "PetAssureNation.underwritingQuestionThree") {
    //     radioLabelValue = "3. " + medicalSurveys[2].text
    //   }
    //   else if (name == "PetAssureNation.underwritingQuestionFour") {
    //     radioLabelValue = "4. " + medicalSurveys[3].text
    //   }
    //   radioLabelValue = "1. " + medicalSurveys[0].text

    // }
    return tempArray;
  }



  getSupplementQuestions(name, medicalSurveys) {


    let newLabel;
    if (medicalSurveys?.length > 0) {
      newLabel = medicalSurveys[1]?.supplementalQuestions[0]?.text;


    }
    return newLabel;
  }
  getMedicationQuestions(name, medicalSurveys) {


    let newLabel;
    if (medicalSurveys?.length > 0) {
      if (name == "PetAssureNation.dateAndReason") {
        newLabel = medicalSurveys[2]?.supplementalQuestions[0]?.text
      }
      else if (name == "PetAssureNation.medicationDetails") {
        newLabel = medicalSurveys[3]?.supplementalQuestions[0]?.text
      }


    }
    return newLabel;
  }

  getOptionsForPreferredContact(prefOptionsList) {
    
    let arr = []
    if ((prefOptionsList?.clientSMSFlag) && (prefOptionsList?.clientEmailFlag))
      {
        arr.push({ value: 'Email', text: 'Email'})
        arr.push({ value: 'SMS', text: 'SMS (Text)'})
        arr.push({ value: 'Both', text: 'Both' })
      }
      else if (prefOptionsList?.clientSMSFlag) arr.push({ value: 'SMS', text: 'SMS (Text)'})
      else if (prefOptionsList?.clientEmailFlag) arr.push({ value: 'Email', text: 'Email'})
      arr.push({ value: 'None', text: 'None'})
    return arr;
  }
}

export default ServiceHelper;