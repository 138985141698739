import { React, useEffect, useState } from 'react';
import { Button } from '../../Atoms';
import classes from './styles.module.css';
const EmployeeTable = (props) => {
    const {
        editOnClick = () => { },
        hideEditButton,
        employeeTableData,
        employeeData,
        preferredContactDetailsFromRedux
    } = props;
    function displaypersonalPhone() {
        let phone = employeeData?.personalPhone;
        const preferredContact = preferredContactDetailsFromRedux?.preferredContact;
        const optinFlag = preferredContactDetailsFromRedux?.contactSMSOptinFlag;
        const reduxPhone = preferredContactDetailsFromRedux?.personalPhone;
        if ((['SMS', 'Both'].includes(preferredContact) && optinFlag === 'True') || (preferredContact === 'Email' && !phone)) {
            phone = reduxPhone || phone;
        }
        if (!phone) return '';
        let value = phone.replace(/\D/g, '').slice(0, 11);
        value = (value.length === 11 && value.startsWith('1')) ? value.slice(1, 11) : value;
        return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || '';
    }

    function displaypersonalEmail() {
        let emailAddress = employeeData?.personalEmail;
        const preferredContact = preferredContactDetailsFromRedux?.preferredContact;
        const reduxEmail = preferredContactDetailsFromRedux?.personalEmail;

        if ((['Email', 'Both'].includes(preferredContact) || (preferredContact === 'SMS' && !emailAddress)) && reduxEmail) {
            emailAddress = reduxEmail;
        }

        return emailAddress || '';
    }
    const employeeDetails = employeeTableData?.map((person) => {
        if (person && person?.insured?.mainOwnerRelationshipToInsured === "") {
            let correspondencesDetails = person.person?.data?.correspondences[0]
            let correspondencesAddress = (correspondencesDetails && (correspondencesDetails?.address === null || undefined)) ? " " : correspondencesDetails?.address
            return {
                fullName: person?.person?.data?.actor?.shortName + ' ' + person?.person?.data?.actor?.name,
                phoneNumber: correspondencesDetails?.privatePhone,
                email: correspondencesDetails?.eMail,
                address:
                    (correspondencesAddress?.line1 || ' ') + ' ' +
                    (correspondencesAddress?.line2 || ' ') + ' ' +
                    (correspondencesAddress?.zipCodeAndCity?.city || ' ') + ' ' +
                    (correspondencesAddress?.state || ' ') + ' ' +
                    (correspondencesAddress?.zipCodeAndCity?.zipCode || ' ')
            }
        }
    })

    let EmployeeTable = [
        {
            name: employeeDetails?.[0]?.fullName || '',
            empAddress: employeeDetails?.[0]?.address || '',
            empPhone: displaypersonalPhone(),
            empEmail: displaypersonalEmail(),
            empContact: preferredContactDetailsFromRedux?.preferredContact || '',
        },
    ]


    return (
        <div className={`${classes.card} py-1`}>
            <div className={classes.cardHeader}>
                <div className={`${classes.title} py-1`}>{'Employee Profile'}</div>
                {!hideEditButton && (
                    <button
                        className={classes.Button}
                        onClick={() => editOnClick('EmployeeProfile')}
                    >
                        Edit
                    </button>
                )}
            </div>
            <div className=" table-responsive dependent-table col-md-12 d-md-block d-lg-block d-xl-block ">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th>ADDRESS</th>
                            <th>PHONE NUMBER</th>
                            <th>EMAIL</th>
                            <th>PREFERRED CONTACT METHOD</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {EmployeeTable?.map((emp, index) => {
                            return (
                                <tr key={index}>
                                    <td>{emp.name}</td>
                                    <td>{emp.empAddress}</td>
                                    <td>{emp.empPhone}</td>
                                    <td><b>{emp.empEmail}</b></td>
                                    <td>{emp.empContact}</td>
                                </tr>
                            )
                        })
                        }
                    </tbody >
                </table>
            </div>
        </div>
    );
}

export default EmployeeTable;