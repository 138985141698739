import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextInput } from '../../Atoms';
import VerifyRegistrationOTP from './VerifyRegistrationOTP';
import Loader from '../../Atoms/Loader';
import StickyNote from '../../Atoms/StickyNote';
import iconEdit from '../../../../../Assets/Images/icon-edit.png'
import iconVerified from '../../../../../Assets/Images/iconVerified.svg'
import { EDIT_EMAIL} from '../../../../../Store/Slices/Registeration';
import { validateEmail } from '../../../../Utils/Helper/ValidationHelper';
import { apiCall } from '../../Helper/commonHelper';
import { SHOW_LOADER } from '../../../../../Store/Slices/Loader/index';
import {errorMessages} from '../../Atoms/FormValidation'
import { Link } from 'react-router-dom';
import { SET_PASSWORD, SET_REENTER_PASSWORD } from '../../../../../Store/Slices/Registeration';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
});

const VerifyEmail = ({ stepValues = { email: '' }, onNext, onPrev, onSave }) => {

    const dispatch = useDispatch();
    const { registeration } = useSelector((storeState) => storeState);
    const [showOTPBlock, setShowOTPBlock] = useState(false);
    
    const [showEmail, setShowEmail] = useState(true);
    const [emailError, setEmailError] = useState(false);
    const [passwordData, setPasswordData] = useState({password:'', confirmPassword:''});
    const [err, setErr] = useState('');
    const { loader } = useSelector((state) => state.loader);
    const { legalEntityId = '', appCode = ''} = useSelector((storeState) => storeState?.registeration);
    const formik = useFormik({
        initialValues: stepValues || {
            email: '',
        },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            onSave(values);
            if(registeration.password && registeration.reenterPassword){
                handleChangePassword();
            } else {
                handleEmailVerification(values)
            }
        },
    });

    const validationMsgEmail = (field) => {
        return (<div className="form-mandatory">Invalid email address</div>)
    };

    const validationMsg = (field) => {
        return (formik.touched[field] && formik.errors[field] && (
            <div className="form-mandatory">{formik.errors[field]}</div>
        ))
    };

    const handleEmailVerification = async(values) => {
        setErr('')
        const reqObj = {
            "email": values.email?.trim()
        }
        dispatch(SHOW_LOADER(true));
        apiCall("/stateful/sign-up/send-email-challenge", 'post', reqObj)
        .then((res) => {
            if (String(res.status).charAt(0) ==='2'){
                setShowOTPBlock(true)
                dispatch(EDIT_EMAIL({editEmail: false, email: values.email}))
            } else {
                setErr(errorMessages(res)) 
            }
            dispatch(SHOW_LOADER(false));
        })
        .catch((error) => {
            setErr(errorMessages(error))
            dispatch(SHOW_LOADER(false));
        });
    }

    const handleImgClick = () => {
        setShowEmail(true)
       setShowOTPBlock(false)
       setErr('');
       dispatch(EDIT_EMAIL({editEmail: true}))
    }

    const  handleBlur= () => {
        if(formik.values.email){
            let isValidEmail = validateEmail(formik.values.email)
            setShowEmail(!isValidEmail  ? true : false)
            !isValidEmail && validationMsgEmail('email')
            setEmailError(!isValidEmail)
        }
        else 
            setEmailError(true)
    }

    const handleChangePassword = async () => {
        setErr('')
        dispatch(SHOW_LOADER(true));
        if(registeration.password && registeration.reenterPassword){
            const reqObj = {
                "password": registeration.password?.trim(),
            }
            apiCall("/stateful/sign-up/set-password", 'put', reqObj)
            .then((res) => {
                if (String(res.status).charAt(0) ==='2'){
                    onNext(formik.values)
                }else {
                    setErr(errorMessages(res)) 
                }
                dispatch(SHOW_LOADER(false));
                dispatch(SHOW_LOADER(false));
            })
            .catch((e) => {
                setErr(errorMessages(e))
                dispatch(SHOW_LOADER(false));
            });
            dispatch(SET_REENTER_PASSWORD({reenterPassword:''}))
            dispatch(SET_PASSWORD({password:''}))
        } else {
            setErr(errorMessages({message:'Please enter valid password.'}))
        }
    }
    const clearErrMsg = () =>{
        setErr('');
    }
    const handlePasswordData = (data) =>{
        setPasswordData(data);
    }

    return (
        <>
        {loader && <Loader loading={loader} />}
        <form onSubmit={(e) => e.preventDefault()} noValidate>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1 className="form-side-header">User Registration</h1>
                        <p className="form-mandatory mb-0">
                            Fields marked with * are mandatory
                        </p>
                        <div className="col-11 panel-sid-cont pl-xl-0 mb-2">
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAgCAYAAAB6kdqOAAAABHNCSVQICAgIfAhkiAAAAg9JREFUWEftl9FVwjAUhm/Qd2ECGQEnAMoAsoE4gTABYQLZAJxAeFeECXQDYQLruzT+N0kt1FbaQ23rOfacnlPSknz5c/PfXEElu0TJeMgAtWSdKnTLT7irOUOuMd4LeTSgpVwLC/NcAEh43i6gLgR15IwUXeLtCg1dULq5KmRWZ4oxm1ivuSBHvml1PKrlDuPP3EC94ieWzJFKty9ksQFuOUoMlGvgxA8WKFQ6oP8YCi1JqqDuyC5s4QY+wU5O8K0lndCIHuQys5VODOTICQbtxQw8gl3ITKASAbEyiu7tgAOoxI5ahYkx4FC3V6gdqZRvuNG0LiZS23uVCMjRS9LEPUAH41AHrMyQ7Z4eAR6+nCFSkDiL5lHvtBjtJ/GEQMbFo9JKYPfZuPxfBTpiyWzSzjSGdoNaiD5t1dwEsrjC3je7KzaofyOGeMC2nCJwARB55bztfQajVN/uOG5dQRlZjDHGSHOwOXMfCg7+xmM4XQgcxs1z4yuNEM38A/oeZKY+ZGDSHPz1AZ2rhoPKxX3wow/5gcwuvNWx49IpVPFscq1ArQ+tVhVJdqyLBEV39CR7GQDZ7bnrxmkO/i3JuY0Lhe/5KSld4PqboAziGFF0rWVPe/BP+/0uKMMImuiY1GWQplOQPy4RJp3msd8h4XqiHpTSJhYa6Pb82K5T/n+jd+8WRxoUqcXWYhHkpQP6BLRCJB2CMuyFAAAAAElFTkSuQmCC"
                                name=""
                                className="img-fluid "
                                alt="User Icon"
                                style={{
                                    minWidth: '2.25rem',
                                    minHeight: '2rem',
                                    marginRight: 10
                                }}
                                onClick={()=> setShowOTPBlock(false)}
                            />
                            <span>
                                <div className="panel-side-header-img d-inline">
                                    Let's create your user account
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row py-2">
                    <div className="col-sm-12 col-md-6">
                        
                        {/* { !showOTPBlock ?  */}
                        {(showEmail && !showOTPBlock)?
                        <> 
                        <TextInput
                            required
                            id="email"
                            name="email"
                            label="Email for Registration"
                            type={ `${showOTPBlock ? 'label' : 'text'}`}
                            placeholder="XXX@domain.com"
                            onInput={()=>setErr('')}
                            className="w-100 inputField p-3"
                            {...formik.getFieldProps('email')}
                            onBlur={ ()=>handleBlur()}
                        />
                        {validationMsg('email')}
                        
                        </>
                        :
                        <div className=''>
                            <div className='mb-3'>Email for Registration <span className='form-mandatory mb-0'>*</span></div>
                            <p style={{ alignItems:'start', justifyContent:'start', marginTop:7, fontSize:16, fontWeight:'bold', marginRight:10}}>{formik.values.email} 
                            <span className='py-2'>
                                 <img alt='Edit icon' src={iconEdit} style={{height:18, marginTop:-10, marginLeft:5}}  
                                 onClick={()=> handleImgClick()  } /> 
                            </span>
                            <span className={registeration.emailVerified ? 'py-2' : 'hideElement'}>
                                <img alt='Verify icon'  src={iconVerified} style={{height:18, marginTop:-10, marginLeft:5}} /> 

                            </span>
                            
                            </p>
                            
                        </div>
                        }
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <StickyNote
                            title={''}
                            children={'Registered email will be your user ID at login.'}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className={!showOTPBlock ? "col-12 col-md-12" : "hideElement"}>
                        <p>We must validate the above email id before accepting as a registered email.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <Button
                            type="button"
                            className={!showOTPBlock ? "btn btn-primary primaryBackground btn-primary-contrast" : "hideElement"}
                            disabled={(!formik.isValid)}
                            onClick={formik.submitForm}
                        >
                            Verify Email
                        </Button>
                    </div>
                </div> 
                {showOTPBlock ?  <VerifyRegistrationOTP onNext={onNext} onPrev={onPrev} values={formik.values} clrErr={clearErrMsg} handlePasswordData={handlePasswordData}/> : 
               <> </>}
                {err && 
                    <div className="row">
                        <span style={{ color: '#A61003',  margin:5, paddingLeft:10 }}>{err} </span>
                    </div>
                }
                <div className="row py-4 justify-content-between">
                    <div className="col-sm-12 col-md-4 py-2">
                        <Link role="link" to={`/enrollment?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Back to login </Link>
                    </div>
                    <div className="col-sm-12 col-md-6 py-2 text-md-right">
                        <Button
                            type="button"
                            className="btn btn-outline-primary primaryOutline btn-outline-primary-contrast mr-4"
                            onClick={onPrev}
                        >
                            Back
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-primary primaryBackground btn-primary-contrast mfaBtn"
                            disabled={!registeration.emailVerified || !(showOTPBlock && registeration.password && registeration.reenterPassword && (registeration.password === registeration.reenterPassword))}/* ={(!formik.isValid || !formik.dirty)} */
                            onClick={formik.submitForm}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </form>
        </>
    );
}

export default VerifyEmail;
