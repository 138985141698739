import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _get from 'lodash/get';
import http from '../../../../../Common/Services/HttpService/index';
import appConfig from '../../../../../appConfig';
import Label from './Label';
import ToolTip from '../../../../Atoms/ToolTip';
import updateEmployeeInformation from './updateEmployeeInformation';
import './profile-styles.css';
import { HIDE_CONTINUE_BUTTON, HIDE_BACK_BUTTON, PREVIOUS_STEP, } from '../../../../../Store/Slices/StepForm';
import { UPDATE_PREFERRED_CONTACT, HELPDESK_DETAILS, GBP_RESOURCES_FOR_MEMBER } from '../../../../../Store/Slices/EmployeeProfile';
import { UPDATE_LOADING, SET_ALERT_MESSAGE, REMOVE_CURRENTSTEP_ALERT_MESSAGE } from '../../../../../Store/Slices/InsuredInfo';
import ServiceHelper from '../../../../Utils/Helper/MappingHelper';
import { updateProcess } from '../../Actions/updateProcess';
import { useHistory } from 'react-router-dom';
import { UPDATE_STATE, UPDATE_ENROLLMENT_DATA, UPDATE_EXTERNAL_STEP_INFO } from '../../../../../Store/Slices/Enrollment';
import { updateStepperNavigatorInfo } from '../../../../Utils/Form/StepperHelper';
import { onLoadAPI } from '../../../../../Store/Slices/Entities/response/index.js';
import { getErrorMessages } from '../../../../Molecules/CustomSection/Helper/MappingHelper';

import { mergeState } from '../../../../../Store/Slices/replaceState';
import { useNavigationHook } from '../../Hooks/NavigationHook.js'; // Replace with your actual file path
import { MessageComponent } from '../../../../Atoms';
import { removeUnwantedProps } from '../../../../Molecules/CustomSection/Helper/MappingHelper';
import resumeContent from '../../../../Molecules/CustomSection/Content/ResumeContent.json';
import constants from '../../Content/Constants.json';
import { CONTRACT_LINIAR_OR_NON_LINIAR, PRODUCT_LIST, PRODUCT_RESOURCES, SELECTED_PRODUCT_LIST } from "../../../../../Store/Slices/Process"
import _ from 'lodash';
import {formatDollarAmountWithDecimals} from '../../../../Utils/Helper/ValidationHelper';

const Profile = memo((props) => {


  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
  let oldCurrentStep = useSelector((storeState) => storeState?.stepForm?.currentStep);
  let { skipSteps,
    stepKeys,
    previousStep,
    currentStep, formsConfig, formData, handleNextStep, handlePreviousStep, handleGoToSpecificStep, handleSetStepKeys, handleSetCurrentStep } = useNavigationHook();
  if (window.location.pathname.indexOf('/ManageProfile') !== -1) {
    currentStep = oldCurrentStep;
    formData = props?.contentData?.formsList
  }

  let preferredContactFromRedux = useSelector((storeState) => storeState.employeeProfile.preferredContactDetails);
  const employeeInformation = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.PersonalInformation?.personalInformation);
  const employmentInformation = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.EmploymentInformation?.employmentInformation);

  const buid = useSelector((storeState) => storeState?.auth?.loggedUserEntity?.data?.person?.bUID);
  const enrollmentData = useSelector((storeState) => storeState?.enrollment?.enrollmentData);
  const enrollmentProcessBuid = useSelector((storeState) => storeState?.process?.enrollmentProcess?.[0]?.[0]?._Result.bUID) || '';
  const enrollmentProcess = useSelector((storeState) => storeState?.process?.enrollmentProcess?.[0]?.[0]?._Result) || {};

  const [initialLoad, setInitialLoad] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [errorMsg, setErrMsg] = useState('');
  const [showMaskedInput, setShowMaskedInput] = useState(false);
  const [btnLabel, setBtnLabel] = useState('Continue')
  const dispatch = useDispatch();
  const serviceHelper = new ServiceHelper();
  let history = useHistory();
  const configDataRedux = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.employeeProfile?._Result?.data?.[0]
  );
  const [displayedPhone, setDisplayedPhone] = useState(''); // a new state to hold the displayed phone
  const enrollmentInfo = useSelector(
    (storeState) =>
      storeState?.enrollment
  );
  const storeFromRedux = useSelector((storeState) => storeState);
  const { userAuth } = useSelector((storeState) => storeState.auth);

  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  const initNewEnrollmentProcessBuid = useSelector(
    (storeState) => storeState?.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID
  );
  const isAdmin = useSelector((storeState) => storeState?.callCenter?.isAdmin);

  const ssnRedux = employeeInformation?.ssn

  const isResume = storeFromRedux?.enrollment?.enrollmentType === "resume";
  const isGetStarted = storeFromRedux?.enrollment?.enrollmentType === "newHire";
  const selectedProductBuids = storeFromRedux?.insuredInfo?.selectedProductListBuids || []

  const payFrequency = employmentInformation?.payFreqeuncyValueCode;

  const hasProfileErrors = getErrorMessages(currentStep);

  const configData = {
    "personalEmailAddressToggle": configDataRedux?.contactInfo[0]?.personalEmail?.hideableField || false, // hideableField
    "personalEmailAddressChangedByEmp": configDataRedux?.contactInfo[0]?.personalEmail?.nonEditableField || false, // nonEditableField 
    "personalEmailAddressRequired": configDataRedux?.contactInfo[0]?.personalEmail?.requiredField || false,
    "personalPhoneToggle": configDataRedux?.contactInfo[0]?.personalPhone?.hideableField || false,
    "personalPhoneChangedByEmp": configDataRedux?.contactInfo[0]?.personalPhone?.nonEditableField || false,
    "personalPhoneRequired": configDataRedux?.contactInfo[0]?.personalPhone?.requiredField || false,
    "primaryPhoneToggle": configDataRedux?.contactInfo[0]?.primaryPhone?.hideableField || false,
    "primaryEmailToggle": configDataRedux?.contactInfo[0]?.primaryEmail?.hideableField || false,
    "PCsectionToogle": configDataRedux?.communicationInfo[0]?.preferredContact?.optionsField?.isEnabledPC?.hideOption || false,
    "ssnEyeToggle": configDataRedux?.personalInfo[0]?.ssn || false, //SSN eye icon toggle
    "isEmployementVisible": configDataRedux?.employmentInfo[0]?.noGroupStart?.requiredSection || false,
    "divisionCodeEditable": configDataRedux?.employmentInfo[0]?.divisioncode?.editableLabelField || false,
    "divisionCodeHidable": configDataRedux?.employmentInfo[0]?.divisioncode?.hideableField || false,
    "divisionCodeEditableValue": configDataRedux?.employmentInfo[0]?.divisioncode?.editableLabelFieldValue || false,
    "workLocationEditable": configDataRedux?.employmentInfo[0]?.workLocation?.editableLabelField || false,
    "workLocationHidable": configDataRedux?.employmentInfo[0]?.workLocation?.hideableField || false,
    "workLocationEditableValue": configDataRedux?.employmentInfo[0]?.workLocation?.editableLabelFieldValue || false,
    "payFrequencyHidable": configDataRedux?.employmentInfo[0]?.payFrequency?.hideableField || false,
    "employmentStatusHideable": configDataRedux?.employmentInfo[0]?.employmentStatus?.hideableField || false,
    "salaryHidable": configDataRedux?.employmentInfo[0]?.salary?.hideableField || false,
    "middleNameHidable": configDataRedux?.personalInfo[0]?.middleName?.hideableField || false,
    "ssnHidable": configDataRedux?.personalInfo[0]?.ssn?.hideableField || false,
    "hrText": configDataRedux?.employmentInfo[0]?.hrText?.text || 'Please contact your HR Administrator to correct any inaccurate information. You do not have to wait until the updates are in place prior to enrolling.'
  };

  const hideEmailPhone = ((configData.primaryPhoneToggle === true && configData.primaryEmailToggle === true) ? 'hide-col-4' : '')

  const {
    showSideNavigation = true,
  } = props;

  useEffect(() => {
    if (props.className === 'manageProfileWrapper') {
      setBtnLabel("Submit")
    } else {
      if (previousStep === "EnrollmentSummary") {
        setBtnLabel('Continue to Summary')
      } else {
        setBtnLabel('Continue')
      }
    }
  }, [props.className, previousStep])
  useEffect(() => {

    let externalStepContext;

    if (enrollmentProcess?.processEntity?.externalStepContext) {
      try {
        externalStepContext = JSON.parse(enrollmentProcess.processEntity.externalStepContext);
      } catch (e) {
        console.error('Error parsing externalStepContext:', e);
      }
    }

    const isResume = enrollmentInfo?.enrollmentType === "resume";
    const hasPortalStep = externalStepContext?.portalStep;
    const notEmployeeProfile = externalStepContext?.portalStep !== "EmployeeProfile";
    // const goBackStep = props?.contentData?.goBackStep;
    // let stepKeysIndex = props?.contentData?.stepKeys?.indexOf(externalStepContext?.portalStep);


    const postRequest = async () => {
      if (currentStep && enrollmentProcessBuid && isResume && notEmployeeProfile) {
        try {
          const resp = await http.post(appConfig.SSP_MID_URL + '/getProcess', {
            currentReduxState: storeFromRedux,
          });


          if (resp?.data?._Result) {
            let externalStepContextInfo;

            if (resp.data._Result.processEntity?.externalStepContext) {
              try {
                externalStepContextInfo = JSON.parse(resp.data._Result.processEntity.externalStepContext);
              } catch (e) {
                console.error('Error parsing externalStepContext:', e);
              }
            }


            if (externalStepContext && externalStepContext.portalStep && (externalStepContext.portalStep !== "EmployeeProfile") && (externalStepContext.portalStep !== "ProductSelection") &&
              (externalStepContext?.portalStep !== "DependentInformation")) {
              try {

                let externalStepContext = {
                  "portalStep": currentStep

                };


                const resp = await http.post(appConfig.SSP_MID_URL + '/validateContractData', {
                  enrollmentProcessBuid: enrollmentProcessBuid,
                  externalStepContext
                });


                if (resp && resp.data) {


                  dispatch(onLoadAPI({
                    ValidateContractData: resp.data,
                    isAPICallInProgress: false,
                  }));
                  dispatch(UPDATE_ENROLLMENT_DATA(resp.data?._Result));



                }

              } catch (error) {
                //  dispatch(SHOW_CONTENT_LOADER(false));
                // toast.error('Enrollment failed - Invalid response');
              }
            }

            //  stepKeysIndex = stepKeys?.indexOf(externalStepContext?.portalStep);

            if (externalStepContextInfo?.reduxState) {
              /*    await props.SetContentData((prevContentData) => {
                   const updatedContentData = {
                     ...prevContentData,
                     stepKeys: externalStepContextInfo.reduxState?.stepForm?.stepKeys || []
                   };
                   return updatedContentData;
                 }); */
              await handleSetStepKeys(externalStepContextInfo.reduxState?.stepFormNew?.stepKeys || []);

              await dispatch(UPDATE_STATE({ data: externalStepContextInfo?.reduxState }));
              if (externalStepContext?.portalStep) {

                await handleSetCurrentStep(externalStepContext?.portalStep);
                await (externalStepContext?.portalStep);


              }
            }
          }
        } catch (error) {

        } finally {


        }


      } else {

        if ((!notEmployeeProfile || externalStepContext?.portalStep == "") && currentStep && enrollmentProcessBuid) {
          await updateProcessInformation({
            "portalStep": currentStep
          });
        }

      }
    };
    if (!isResume && currentStep !== 'EmployeeProfile') {
      postRequest();
    }

  }, [currentStep, enrollmentProcessBuid, enrollmentInfo?.enrollmentType]);
  useEffect(() => {
    if (initialLoad) {
      let externalStepContext;

      if (enrollmentProcess?.processEntity?.externalStepContext) {
        try {
          externalStepContext = JSON.parse(enrollmentProcess.processEntity.externalStepContext);
        } catch (e) {
          console.error('Error parsing externalStepContext:', e);
        }
      }

      const isResume = enrollmentInfo?.enrollmentType === "resume";
      const hasPortalStep = externalStepContext?.portalStep;
      const notEmployeeProfile = externalStepContext?.portalStep !== "EmployeeProfile";
      // const goBackStep = props?.contentData?.goBackStep;
      // let stepKeysIndex = props?.contentData?.stepKeys?.indexOf(externalStepContext?.portalStep);

      // let stepKeysIndex = props?.contentData?.stepKeys?.indexOf(externalStepContext?.portalStep);



      const empPostRequest = async () => {
        if (currentStep && enrollmentProcessBuid && isResume && notEmployeeProfile) {
          if (enrollmentProcess) {
            let externalStepContextInfo;

            if (enrollmentProcess.processEntity.externalStepContext) {
              try {
                externalStepContextInfo = JSON.parse(enrollmentProcess.processEntity.externalStepContext);
              } catch (e) {
                console.error('Error parsing externalStepContext:', e);
              }
            }
            //   stepKeysIndex = externalStepContextInfo.reduxState?.stepFormNew?.stepKeys?.indexOf(externalStepContext?.portalStep);
            if (externalStepContextInfo?.reduxState) {
              /*   await props.SetContentData((prevContentData) => {
                  const updatedContentData = {
                    ...prevContentData,
                    stepKeys: externalStepContextInfo.reduxState?.stepForm?.stepKeys || []
                  };
                  return updatedContentData;
                }); */


              if (isResume && externalStepContextInfo && externalStepContextInfo.portalStep &&
                (externalStepContextInfo.portalStep !== "EmployeeProfile") &&
                (externalStepContextInfo.portalStep !== "ProductSelection") &&
                (externalStepContextInfo?.portalStep !== "DependentInformation")) {

                await handleSetStepKeys(externalStepContextInfo.reduxState?.stepFormNew?.stepKeys || []);
              }


              await dispatch(UPDATE_STATE({ data: externalStepContextInfo?.reduxState }));
              if (externalStepContext?.portalStep) {
                await handleSetCurrentStep(externalStepContext?.portalStep);
                await handleGoToSpecificStep(externalStepContext?.portalStep);
              }

            }
          }
          dispatch(UPDATE_LOADING(false));
        } else {
          dispatch(UPDATE_LOADING(false));
        }
      }

      if (isResume && currentStep === 'EmployeeProfile' && previousStep !== "EnrollmentSummary") {
        empPostRequest();
      }
    }

  }, [initialLoad]);





  useEffect(() => {

    const isResume = enrollmentInfo?.enrollmentType === "resume";

    if (isResume && currentStep === 'EmployeeProfile' && previousStep !== "EnrollmentSummary") dispatch(UPDATE_LOADING(true));



    const initialPersonalPhone = displaypersonalPhone(false)
    if (initialPersonalPhone) {
      handlePersonalPhoneChange(initialPersonalPhone)
    }

    const initialPersonalEmail = displaypersonalEmail()
    if (initialPersonalEmail) {
      formik.setFieldValue('personalEmail', initialPersonalEmail);
    }

    dispatch(HIDE_BACK_BUTTON({ hideBackBtn: true }));
  }, []);




  const validationSchema = Yup.lazy((values) =>
    Yup.object().shape({

      preferredContact: !configData.PCsectionToogle && !configData.personalPhoneToggle && !configData.personalEmailAddressToggle && isPreferredContactVisible
        ? Yup.string().required('Preferred contact is required')
        : Yup.string(),

      personalPhone: Yup.string()
        .when('preferredContact', {
          is: (preferredContact) =>
            preferredContact && preferredContact !== 'None' && (preferredContact === 'SMS' || preferredContact === 'Both') && isPreferredContactVisible,
          then: Yup.string()
            .required('Alternate Phone is required')
            .matches(/^\d{10}$/, 'Invalid phone number'),
          otherwise: Yup.string()
            .matches(/^\d{10}$/, 'Invalid phone number'),
        })
        .when([], {
          is: () => configData.personalPhoneRequired,
          then: Yup.string().required('Alternate Phone is required'),
        }),

      personalEmail: Yup.string()
        // .matches(/^(?!.*'.*)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,'Invalid email address')
        .when('preferredContact', {
          is: (preferredContact) =>
            preferredContact && preferredContact !== 'None' && (preferredContact === 'Email' || preferredContact === 'Both') && isPreferredContactVisible,
          then: Yup.string()
            .required('Alternate Email is required')
            .matches(/^(?!.*'.*)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Special characters like \'/#; etc. are not allowed in email')

        })
        .when([], {
          is: () => configData.personalEmailAddressRequired,
          then: Yup.string()
            .required('Alternate Email is required')
            .matches(/^(?!.*'.*)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Special characters like \'/#; etc. are not allowed in email'),
        }),

      ssn: ssnRedux != '' || configData.ssnHidable
        ? Yup.string()
        : Yup.string()

          .required('Please enter your SSN')
          .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must be in the format XXX-XX-XXXX'),

    })

  );
  const formik = useFormik({
    initialValues: {
      personalPhone: displaypersonalPhone(false),
      personalEmail: displaypersonalEmail(),
      preferredContact: preferredContactFromRedux?.preferredContact || '',
      ssn: '',
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      formik.resetForm();
    },
    enableReinitialize: true,
  });
  
const getGBPResourcesForMember = useSelector((storeState) => storeState?.employeeProfile?.getGBPResourcesForMember);
const gbprCheck = (previousStep === "EnrollmentSummary") || getGBPResourcesForMember && getGBPResourcesForMember.length > 0;
const auth = useSelector((storeState) => storeState?.auth);
  useEffect(() => {
    const fetchData = async () => {
      if (initNewEnrollmentProcessBuid && (isGetStarted || isResume || isAdmin || userAuth.processType === 'proxyEnrollment') && currentStep === 'EmployeeProfile') {
        const enrollmentProcess = storeFromRedux?.process?.enrollmentProcess?.[0]?.[0]?._Result || {};
        let externalStepContext = JSON.parse(enrollmentProcess.processEntity.externalStepContext || '{}');
        let updatedState = ""
        const notEmployeeProfile = externalStepContext?.portalStep !== "EmployeeProfile";
        let isValidateContractDataAPI = false
        let isUpdateProcess = false
        let externalStepContextForValidateContract = ""
        let externalStepContextForUpdateProcess = ''
        if (isResume && externalStepContext && externalStepContext.portalStep && (externalStepContext.portalStep !== "EmployeeProfile") && (externalStepContext.portalStep !== "ProductSelection") &&
          (externalStepContext?.portalStep !== "DependentInformation")) {
          isValidateContractDataAPI = true
          externalStepContextForValidateContract = {
            "portalStep": currentStep
          };
        }
        if (!isResume && (!notEmployeeProfile || externalStepContext?.portalStep == "") && currentStep && enrollmentProcessBuid) {
          isUpdateProcess = true
          updatedState = removeUnwantedProps(storeFromRedux, resumeContent.resumeUnwantedProps);
          externalStepContextForUpdateProcess = {
            enrollmentProcessBuid: enrollmentProcessBuid,
            portalStep: currentStep,
            reduxState: _.omit(updatedState, ["insuredInfo"]),
          }
        }
        const empBuid = auth?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.bUID;
        const newBuid = empBuid ? empBuid : employmentInformation?.employeeId;
        const reqObj = {
          enrollmentProcessBuid: enrollmentProcessBuid,
          contractBuid: contractBuid,
          employeeBuid: newBuid,
          externalStepContextForUpdateProcess: externalStepContextForUpdateProcess,
          externalStepContextForValidateContract: externalStepContextForValidateContract,
          isUpdateProcess: isUpdateProcess,
          isValidateContractData: isValidateContractDataAPI,
          checkGBPResourcesForMember: gbprCheck
        }
        dispatch(UPDATE_LOADING(true));
        try {
          if (isResume && enrollmentProcessBuid) {
            const resp = await http.post(appConfig.SSP_MID_URL + '/v1/trigger-resume', {
              enrollmentProcessBuid: enrollmentProcessBuid
            });
            dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
            let data = resp?.data?.data?.getListOfEligibleProducts;

            if (data?.success) {
              let products = data?.data;
              if (products && products.length) {
                let selectedProductResourceList = []
                dispatch(PRODUCT_LIST(products))
                const productResourceList = products.reduce((res, item) => {
                  res[item?.memberOfferedProduct?.bUID] = item.resources;
                  return res;
                }, {});
                if (selectedProductBuids && selectedProductBuids.length) {
                  products.map((item) => {
                    if (selectedProductBuids.indexOf(item?.memberOfferedProduct?.bUID) > -1) {
                      selectedProductResourceList.push(item)
                    }
                  });
                }
                dispatch(SELECTED_PRODUCT_LIST(selectedProductResourceList));
                dispatch(PRODUCT_RESOURCES({ productResourceList }));
              }
            } else {
              dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: data?.data?.error?.code || '520',
                continue: true,
                id: 'trigger-resume',
                show: true,
                page: currentStep,
              }));
            }
          }
        }
        catch (error) {
          dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            id: 'trigger-resume',
            show: true,
            page: currentStep
          }));
        }

        await http
          .post(appConfig.SSP_MID_URL + `/v1/employee-profile-load`, reqObj)
          .then(async (resp) => {
            const data = resp?.data?.data
            for (const key in data) {
              let result = { _Result: data[key].data }
              switch (key) {
                case 'getLinearOrNonLinear':
                  if (!data[key]?.success) {
                    dispatch(SET_ALERT_MESSAGE({
                      type: 'error',
                      errorCode: data[key]?.data?.error?.code || '520',
                      continue: true,
                      id: 'getLinearOrNonLinear',
                      show: true,
                      page: currentStep,
                    }));
                  } else {
                    dispatch(onLoadAPI({ 'Linear': result, isAPICallInProgress: false, }));
                    dispatch(CONTRACT_LINIAR_OR_NON_LINIAR(result))
                  }
                  break;
                case 'getGBPResourcesForMember':
                  if (!data[key]?.success) {
                    dispatch(SET_ALERT_MESSAGE({
                      type: 'error',
                      errorCode: data[key]?.data?.error?.code || '520',
                      continue: true,
                      id: 'getGBPResourcesForMember',
                      show: true,
                      page: 'welcomeScreen',
                    }));
                  } else {
                    if (data[key].data?.[0]?.content) {
                      dispatch(HELPDESK_DETAILS(data[key].data?.[0].content));
                    }
                    if (data[key].data && data[key].success) {
                      dispatch(GBP_RESOURCES_FOR_MEMBER(data[key].data));
                    }
                  }
                  break;
                case 'validateContract':
                  if (!data[key]?.success) {
                    dispatch(SET_ALERT_MESSAGE({
                      type: 'error',
                      errorCode: data[key]?.data?.error?.code || '520',
                      continue: true,
                      id: 'validateContract',
                      show: true,
                      page: currentStep,
                    }));
                  } else {
                    dispatch(onLoadAPI({ 'ValidateContractData': result, isAPICallInProgress: false, }));
                    dispatch(UPDATE_ENROLLMENT_DATA(result._Result));
                  }
                  break;
                case 'updateProcess':
                  if (!data[key]?.success) {
                    dispatch(SET_ALERT_MESSAGE({
                      type: 'error',
                      errorCode: data[key]?.data?.error?.code || '520',
                      continue: true,
                      id: 'updateProcess',
                      show: true,
                      page: currentStep,
                    }));
                  }
                  else {
                    if (result._Result && result._Result.processEntity && result._Result.processEntity.externalStepContext) {
                      let externalStepContext = JSON.parse(result._Result.processEntity.externalStepContext);
                      dispatch(UPDATE_EXTERNAL_STEP_INFO(externalStepContext));
                    }
                    dispatch(onLoadAPI({ key: result, isAPICallInProgress: false, }));
                  }
                  break;
                default:
                  dispatch(onLoadAPI({ [key]: result }));
              }

            }
            setInitialLoad(true)
            dispatch(UPDATE_LOADING(false));

          })
          .catch((e) => {
            dispatch(UPDATE_LOADING(false));
            dispatch(SET_ALERT_MESSAGE({
              type: 'error',
              errorCode: '520',
              continue: true,
              id: currentStep,
              show: true,
              page: currentStep,
            }));
          });
      }
    };

    fetchData();
  }, [initNewEnrollmentProcessBuid, isGetStarted, isResume, currentStep]);

  function displaypersonalPhone(forLabel) {
    let phone = employeeInformation?.personalPhone;
    const preferredContact = preferredContactFromRedux?.preferredContact;
    const optinFlag = preferredContactFromRedux?.contactSMSOptinFlag;
    const reduxPhone = preferredContactFromRedux?.personalPhone;

    if ((['SMS', 'Both'].includes(preferredContact) && optinFlag === 'True') || (preferredContact === 'Email' && !phone)) {
      phone = reduxPhone || phone;
    }

    if (!phone) return '';

    let value = phone.replace(/\D/g, '').slice(0, 11);
    value = (value.length === 11 && value.startsWith('1')) ? value.slice(1, 11) : value;

    if (forLabel) {
      return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return value.slice(0, 10);
  }


  function displaypersonalEmail() {
    let emailAddress = employeeInformation?.personalEmail;
    const preferredContact = preferredContactFromRedux?.preferredContact;
    const reduxEmail = preferredContactFromRedux?.personalEmail;

    if ((['Email', 'Both'].includes(preferredContact) || (preferredContact === 'SMS' && !emailAddress)) && reduxEmail) {
      emailAddress = reduxEmail;
    }

    return emailAddress || '';
  }



  const contractBuid = storeFromRedux?.entities?.response?.onLoadResponseList?.subscribedcontracts?._Result[0]?.contract?.buid;



  const maskedSSN = showMaskedInput ? `XXX-XX-${formik?.values?.ssn?.slice(-4)}` : '';

  const handleSSNChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    const formattedValue = value
      .slice(0, 9)
      .replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
        return [p1, p2, p3].filter((part) => part.length > 0).join('-');
      });
    formik.setFieldValue('ssn', formattedValue);
  };

  const handleSSNBlur = (e) => {
    setShowMaskedInput(!formik.errors.ssn);
    formik.handleBlur(e);
  };

  function convertFormat(originalFormat) {
    const newFormat = {};
    for (const key in originalFormat) {
      let originalFormatNext = originalFormat[key];
      for (const keyNext in originalFormatNext) {
        newFormat[originalFormatNext[keyNext].name] = originalFormatNext[keyNext];
      }
    }
    return newFormat;
  }

  const originalFormat = (currentStep === 'EmployeeProfile') ? formData?.EmployeeProfile?.elements
    : formData?.ManageProfile?.elements;

  const newFormat = convertFormat(originalFormat);

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!phoneRegex.test(value)) {
      return 'Mobile number must be a 10-digit';
    }
    return '';
  };

  const validateEmail = (value) => {
    const emailRegex = /^(?!.*'.*)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    let preferredContact = preferredContactFromRedux?.preferredContact || '';
    if (!value && (preferredContact && preferredContact !== 'None' && (preferredContact === 'Email' || preferredContact === 'Both') && isPreferredContactVisible || configData.personalEmailAddressRequired)) {
      return 'Alternate Email is required';
    }
    if (value && !emailRegex.test(value)) {
      return 'Special characters like \'/#; etc. are not allowed in email';
    }
    else return '';
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    formik.setFieldValue('preferredContact', value);
    setSelectedValue(value);
    formik.handleChange(e);
  };

  const isPreferredContactVisible =
    !configData.personalEmailAddressToggle &&
    !configData.personalPhoneToggle &&
    !configData.personalEmailAddressChangedByEmp &&
    !configData.personalPhoneChangedByEmp &&
    !configData.PCsectionToogle &&
    preferredContactFromRedux?.OfferPreferenceUpdate === true;

  /*   let requiredFlagPhone = false, requiredFlagEmail = false;
  
    const preferredContactVal = formik.values.preferredContact;
    if (isPreferredContactVisible) {
      switch (preferredContactVal) {
        case 'SMS':
          requiredFlagPhone = true;
          break;
        case 'Email':
          requiredFlagEmail = true;
          break;
        case 'Both':
          requiredFlagPhone = true;
          requiredFlagEmail = true;
          break;
      }
    } */

  const isRequiredEmail =
    ((formik?.values?.preferredContact === 'Email' ||
      formik?.values?.preferredContact === 'Both') &&
      isPreferredContactVisible) || configData?.personalEmailAddressRequired;
  //const isRequiredEmail = requiredFlagEmail || configData.personalEmailAddressRequired;

  const isRequiredPhone =
    ((formik?.values?.preferredContact === 'SMS' ||
      formik?.values?.preferredContact === 'Both') &&
      isPreferredContactVisible) || configData?.personalPhoneRequired;
  //const isRequiredPhone = requiredFlagPhone || configData.personalPhoneRequired;

  const primaryPhoneDisplay = configData?.primaryPhoneToggle
  const primaryEmailDisplay = configData?.primaryEmailToggle

  const handlePersonalPhoneChange = (val) => {
    let value = val.replace(/\D/g, ''); // remove non-digit characters

    // if number starts with 1 and is 11 digits long, remove the preceding 1
    if (value.length === 11 && value.startsWith('1')) {
      value = value.slice(1);
    }

    if (value.length > 10) {
      value = value.slice(0, 10); // limit to 10 digits
    }

    formik.setFieldValue('personalPhone', value); // store the plain number format
    const formatted = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // format the value
    setDisplayedPhone(formatted); // store the displayed number

  };

  const handlePersonalEmailChange = (e) => {
    setErrMsg(validateEmail(e.target.value))
    const value = e.target.value;
    formik.setFieldValue('personalEmail', value);
    formik.setFieldError('personalEmail', validateEmail(value));
  };

  const getGender = (gender) => {
    let value;
    let obj = constantsList['GenderConstants']?.find((text) => text.value === gender);
    if (obj) value = obj['text'];

    return value;
  };

  //const newAddress = `${employeeInformation?.line1} ${employeeInformation?.line2}\n${employeeInformation?.city} ${employeeInformation?.state} ${employeeInformation?.zipCode} ${employeeInformation?.country}`;
  function newAddress(employeeInformation) {
    return (
      <div>
        <div>{employeeInformation?.line1}</div>
        <div>{employeeInformation?.line2}</div>
        <div>{employeeInformation?.city} {employeeInformation?.state} {employeeInformation?.zipCode} {employeeInformation?.country}</div>
      </div>
    );
  }

  const formatDateNow = (dateString) => {
    if (!dateString) {
      return '';
    }
    const dateParts = dateString.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    const formattedDate = `${month}-${day}-${year}`;

    return formattedDate;
  };

  function getOptionsForPreferredContact() {
    const options = [];

    if (preferredContactFromRedux?.clientSMSFlag) {
      options.push({ value: 'SMS', text: 'SMS (Text)' });
    }

    if (preferredContactFromRedux?.clientEmailFlag) {
      options.push({ value: 'Email', text: 'Email' });
    }

    options.push({ value: 'None', text: 'None' });

    if (options.length > 1) {
      options.unshift({ value: 'Both', text: 'Both' });
    }

    return options;
  }


  const UpdatedContactToSalesForce = async () => {

    //send updated preferred communication data to salesforce and Wynsure
    await dispatch(updateEmployeeInformation(formik.values, employeeInformation, buid, currentStep));

    const prefMethodOfCommunication = formik.values.preferredContact
      ? formik.values.preferredContact
      : preferredContactFromRedux?.preferredContact;

    // Send updated phone and email to salesforce only if 90 day flag is true
    //if (preferredContactFromRedux?.OfferPreferenceUpdate) {
    const SMSOptin =
      prefMethodOfCommunication === "SMS" || prefMethodOfCommunication === "Both"
        ? "True"
        : "False";
    let personalEmail;
    if (preferredContactFromRedux?.preferredContact === 'SMS') {
      personalEmail = preferredContactFromRedux?.personalEmail
    }
    else {
      personalEmail = formik.values.personalEmail
    }

    let personalPhone;
    if (preferredContactFromRedux?.preferredContact === 'Email') {
      personalPhone = preferredContactFromRedux?.personalPhone
    }
    else {
      personalPhone = formik.values.personalPhone
    }
    const preferredContactDetails = {
      preferredContact: prefMethodOfCommunication,
      personalPhone: personalPhone,
      personalEmail: personalEmail,
      clientSMSFlag: preferredContactFromRedux?.clientSMSFlag,
      clientEmailFlag: preferredContactFromRedux?.clientEmailFlag,
      contactId: preferredContactFromRedux?.contactId,
      OfferPreferenceUpdate: preferredContactFromRedux?.OfferPreferenceUpdate,
      contactSMSOptinFlag: SMSOptin,
    };

    const APIRequestBody = {
      SMSOptin: SMSOptin,
      contactId: preferredContactFromRedux?.contactId,
      prefMethodOfCommunication: prefMethodOfCommunication,
      smsPhoneNum: formik.values.personalPhone,
      emailAddress: formik.values.personalEmail,
    };
    await http
      .post(
        appConfig.SSP_MID_URL + "/postUpdatedContactToSalesForce",
        APIRequestBody
      )
      .then(async (resp) => {
        //Save Preferred Contact Details to Redux
        await dispatch(UPDATE_PREFERRED_CONTACT(preferredContactDetails));
        await updateProcessInformation({
          portalStep: 'EmployeeProfile',
        });
      })
      .catch((e) => {

        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: e || '520',
          continue: true,
          id: 'postUpdatedContactToSalesForce',
          show: true,
          page: currentStep,
        }));
      });
    //}    

    if (currentStep === "EmployeeProfile") {
      handleParentNext();
    } else {

      history.push("/enrollment/home");

    }
  };



  const updateProcessInformation = async (currentStepInfo) => {
    try {
      await dispatch(
        updateProcess(
          {

            currentStepInfo,
          }
        )
      );
    } catch (error) {
      console.error(error);

    }
  };


  const handleParentNext = async () => {
    //if (nextStepGoingTobe !== 'EnrollmentSummary') {
    dispatch(UPDATE_LOADING(true));
    try {

      //}
      updateStepperNavigatorInfo(
        currentStep,
        dispatch,
        { employeeInformation }
      )
      dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
      dispatch(HIDE_BACK_BUTTON({ hideBackBtn: false }));
      if (previousStep === 'EnrollmentSummary') {
        //dispatch(PREVIOUS_STEP({ previousStep: '' }));
        // props?.contentData?.goBackStep(props?.contentData.stepKeys.indexOf(previousStep), stepKeys);

        handleGoToSpecificStep(previousStep);
      } else {
        handleNextStep();
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(UPDATE_LOADING(false));
    }
  };


  return (
    !isLoading && <>
      {/* <MessageComponent /> */}
      <div class={`formWrapper ${props.className}`}>
        <div class="row">
          <div class="col-sm-9 col-md-12 stepFormWrapper col-lg-12 px-2">
            <form onSubmit={(e) => e.preventDefault()}>
              <div class="row">
                <div class="col-12 px-0">
                  <div class="EmployeeProfilecol-12">
                    <div class=" col-12">
                      <div class="row py-2">
                        <div class="col-12">
                          <div class="row panelStyleNoBorder px-1">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-md-12">
                                  <label class="form-mandatory">Fields marked with * are mandatory</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {configData.hrText && (<div class="row panelStyleNoBorder px-1">
                            <div class="col-md-12 col-lg-12 px-3">
                              <div class="sticky-note mr-2 col-12">
                                <div class="row mx-0">
                                  <div class="col-md-auto col-lg-1 col-2 pt-3 pt-lg-1 pt-md-1 customSticky1">
                                    <div class="d-flex flex-column justify-content-center align-items-center false">
                                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAolJREFUSEu1VUFy2kAQnBWqODfjH+BrDGXlBYYXmLzA+AXBhxjdTG6Scwh5QfALIr8g5AWhCjlXyAvi3OyKYdOzq0VCaGVRqWyVSqCdnZ7pme0RVLZevfOo5pyRJA9m/NQT8wm+LcihiGbhbZkLUbjZ9NtE8gp7ePMS3/B/ivd9Ys/7DLivgEgO6e76psjXNkDTH2rn4icJGlLtIaLpyDje9NG67JJ0+rA/wUZE7uN53nYToOWPScozEuIGjvtWx/lQjy77OPMRn6cA6WTPpQAmciHOaRaMC6nz+nUrqKb1K85NKA475rwGMJsc+SzoFddlMML3tyrKOHxdaNPye2DgM+h9T3EAqvErAZjg3SD3wbNHOECR6VjZx2Fxc/De0SCC1xNQdci+BHErOs53cGinhg+q6AjZoZviEIW1LM9v0JOcI5MLdNZIUDNJ3X08qFxUq/dko6mylUwlAHymB2kHbes5011sIOmW7sJuKYZpGFDJGXCPj0vTVjTWUGTud1y6smDWdKLYrjhkAJmtujWy9AI+D7BuWdH5PwDqhosvtFpVrAGntVsGQyU3qga6gKf4c1CpcFVqoO6C8FCrBgCSdIR8Q7Pr6J9rwHLytPcLfj5x45ibvEBK86yGbAClYqY/V5EUdBBNg4UGMBqS3L6tLFRXmNnAAJgBRYKYqsJa01JN0RpyypWnHx/4Ju62NDVzoP/OaloKoA0m8Hr8rC7loXXkLNUCAbazAW6qogJ5iUKbCSUumEdrKmy/3LuCfED8MAFXy24+e9tM5j5mxdzHA7pkpIe8BJhTR1tjHqMNibQmlUxAu65zdH9e9HC4p2jbWoiYgV0xKsvSDpB3qHQeQ2m1vN+lCf4CfSw4o4wcyLEAAAAASUVORK5CYII="
                                        class="img-fluid card-header-image align-self-end element-image"
                                        alt="An image" />
                                    </div>
                                  </div>
                                  <div class="col-10 col-lg-11 col-md-10">
                                    <div class="mb-1">
                                      <div class="pt-1">{configData.hrText}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>)}
                          <div class="row panelStyleNoBorder px-1">
                            <div class="col-md-6 pt-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <label class="form-side-big-header">Personal Information </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row panelStyle mx-1">
                            <Label
                              newFormat={newFormat?.firstName}
                              employeeInformation={employeeInformation?.firstName}
                            />
                            <Label
                              newFormat={newFormat?.middleName}
                              employeeInformation={employeeInformation?.middleName}
                              showField={configData.middleNameHidable}
                            />
                            <Label newFormat={newFormat?.lastName}
                              employeeInformation={employeeInformation?.lastName}
                            />
                            <Label
                              newFormat={newFormat?.gender}
                              employeeInformation={employeeInformation?.gender}
                              displayValue={getGender(employeeInformation?.gender)}
                            />
                            <Label
                              newFormat={newFormat?.dateOfBirth}
                              employeeInformation={formatDateNow(employeeInformation?.dateOfBirth) || ''}
                            />
                            {ssnRedux != '' ?
                              <Label
                                newFormat={newFormat?.ssn}
                                employeeInformation={employeeInformation?.ssn}
                                ssnEyeToggle={configData.ssnEyeToggle.hideableEyeIcon}
                                showField={configData.ssnHidable}
                              /> :
                              <>{!configData.ssnHidable &&
                                <div className="floatingLabel forSSN col-md-2 col-sm-6 col-xs-6 col-6 pb-3 ">
                                  {newFormat?.ssn.label}
                                  {/* SSN Input */}
                                  <>
                                    {showMaskedInput ? (
                                      <input
                                        id="maskedSSN"
                                        name="maskedSSN"
                                        className="w-100 maskedSSN"
                                        value={maskedSSN}
                                        aria-label="SSN"
                                        onFocus={() => setShowMaskedInput(false)}
                                      />
                                    ) : (
                                      <input
                                        id={newFormat?.ssn?.id}
                                        name={newFormat?.ssn?.name}
                                        placeholder={newFormat?.ssn?.placeholder}
                                        className="w-100"
                                        maxLength={11}
                                        aria-label="Search"
                                        value={formik.values.ssn}
                                        onChange={handleSSNChange}
                                        onBlur={handleSSNBlur}
                                      />
                                    )}

                                    {formik.touched.ssn && formik.errors.ssn ? (
                                      <div class="form-mandatory">{formik.errors.ssn}</div>
                                    ) : null}
                                  </>
                                </div>}</>
                            }

                          </div>
                          <div class="row panelStyleNoBorder px-1">
                            <div class="col-md-6 pt-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <label class="form-side-big-header">Contact Information </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row py-2 panelStyle mx-1 panelStyleItem">
                        <div class="col-12">
                          <div class='row primaryAddress'>
                            <div class="col-4">
                              <Label
                                newFormat={newFormat?.primaryAddress}
                                employeeInformation={newAddress(employeeInformation) ?? ''}
                                className1='primaryAddress'
                              />
                            </div>
                            <div class="col-1">
                              <ToolTip
                                filename={newFormat?.primaryAddress?.imageAfter[0]?.image}
                                toolTip={'To change the primary address, contact your HR Administrator'}
                                forAddress={newFormat?.primaryAddress?.imageAfter[0]?.forAddress}
                                alt={"Tooltip for Primary Address"}
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <label class="panel-side-header mt-4 mb-3">Phone and Email Information</label>
                            </div>
                          </div>
                          <div class="row forPhone">

                            <div class={`col-md-4 col-sm-6 col-xs-6 col-6 mb-3 ${hideEmailPhone}`}>
                              {!primaryPhoneDisplay ?
                                <><div class="row">
                                  <div class="col-12">
                                    <label class="floatingLabel">{newFormat?.officePhone?.label}</label>
                                  </div>
                                </div>
                                  <div class="row">
                                    <div class="col-12">{employeeInformation?.officePhone}</div>
                                  </div></> : ''}
                            </div>
                            {!configData.personalPhoneToggle && configData.personalPhoneChangedByEmp ? (
                              <Label
                                newFormat={newFormat?.personalPhone}
                                employeeInformation={displaypersonalPhone(true)}
                                labelForPhone={true}
                              />
                            ) : (!configData.personalPhoneToggle &&


                              <div className={`floatingLabelProfile col-md-4 col-sm-6 col-xs-6 col-6 col-lg-${currentStep === 'ManageProfile' || (currentStep === 'EmployeeProfile' && showSideNavigation === false) ? '3' : '4'} mb-3 }`}>
                                <label style={{ position: 'relative' }} class="floatingLabel"
                                  htmlFor={newFormat?.personalPhone?.id || "personalPhoneId"}>
                                  {newFormat?.personalPhone?.label}
                                </label>
                                {isRequiredPhone && <span style={{ color: '#C53532' }}> *</span>}
                                <input
                                  id={newFormat?.personalPhone?.id || "personalPhoneId"}
                                  name={newFormat?.personalPhone?.name}
                                  type={newFormat?.personalPhone?.type}
                                  placeholder={newFormat?.personalPhone?.placeholder}
                                  value={displayedPhone}
                                  className="w-100"
                                  maxLength={14}
                                  onChange={(e) => handlePersonalPhoneChange(e.target.value)}
                                  onBlur={formik.handleBlur}
                                  disabled={configData.personalPhoneChangedByEmp}
                                />

                                {!configData.personalPhoneChangedByEmp && configData.personalPhoneRequired && (
                                  <div>

                                  </div>
                                )}
                                <div className="form-mandatory">
                                  {formik.touched.personalPhone && formik.errors.personalPhone
                                    ? formik.errors.personalPhone
                                    : ''}
                                </div>
                              </div>
                            )}
                          </div>
                          <div class="row forEmail">
                            <div class={`col-md-4 col-sm-6 col-xs-6 col-6 mb-3 ${hideEmailPhone}`}>
                              {!primaryEmailDisplay ?
                                <><div class="row">
                                  <div class="col-12">
                                    <label class="floatingLabel">{newFormat?.workEmail?.label}</label>
                                  </div>
                                </div>
                                  <div class="row">
                                    <div class="col-12">{employeeInformation?.workEmail}</div>
                                  </div></> : ''}
                            </div>
                            {!configData.personalEmailAddressToggle && configData.personalEmailAddressChangedByEmp ? (
                              <Label
                                newFormat={newFormat?.personalEmail}
                                employeeInformation={displaypersonalEmail()}
                              />
                            ) : (!configData.personalEmailAddressToggle &&
                              <div className={`floatingLabelProfile col-md-4 col-sm-6 col-xs-6 col-6 col-lg-${currentStep === 'ManageProfile' || (currentStep === 'EmployeeProfile' && showSideNavigation === false) ? '3' : '4'}  mb-3 }`}>
                                <label style={{ position: 'relative' }} class="floatingLabel"
                                  htmlFor={newFormat?.personalEmail?.id || "personalEmailId"}>
                                  {newFormat?.personalEmail?.label}
                                </label>
                                {isRequiredEmail && <span style={{ color: '#C53532' }}> *</span>}
                                <input
                                  id={newFormat?.personalEmail?.id || "personalEmailId"}
                                  name={newFormat?.personalEmail?.name}
                                  type={newFormat?.personalEmail?.type}
                                  placeholder={newFormat?.personalEmail?.placeholder}
                                  value={formik?.values?.personalEmail}
                                  className="w-100"
                                  onBlur={formik.handleBlur}
                                  onChange={handlePersonalEmailChange}
                                  disabled={configData.personalEmailAddressChangedByEmp}
                                />
                                <div className="form-mandatory">
                                  {formik.touched.personalEmail && errorMsg ? errorMsg : ''}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="row py-2">
                        <div class="col-12">
                          {!configData.isEmployementVisible && <>
                            <div class="row panelStyleNoBorder px-1">
                              <div class="col-md-6 pt-3">
                                <label class="form-side-big-header">Employment Information </label>
                              </div>
                            </div>
                            <div class="row panelStyle mx-1">
                              <Label
                                newFormat={newFormat?.employerName}
                                employeeInformation={employmentInformation?.employerName}
                              />
                              <Label
                                newFormat={newFormat?.divisioncode}
                                employeeInformation={employmentInformation?.divisionCode}
                                divisionCodeEditable={configData.divisionCodeEditable}
                                divisionCodeEditableValue={configData.divisionCodeEditableValue}
                                showField={configData.divisionCodeHidable}
                              />
                              <Label
                                newFormat={newFormat?.workLocation}
                                employeeInformation={employmentInformation?.workLocation}
                                workLocationEditable={configData.workLocationEditable}
                                workLocationEditableValue={configData.workLocationEditableValue}
                                showField={configData.workLocationHidable}
                              />
                              <Label
                                newFormat={newFormat?.salary}
                                employeeInformation={formatDollarAmountWithDecimals(employmentInformation?.salary)}
                                showField={configData.salaryHidable}
                              />
                              <Label
                                newFormat={newFormat?.payFrequency}
                                employeeInformation={constants?.frequencyListNew.find(frequency => frequency.valueCode === payFrequency)?.UIDisplay}
                                showField={configData.payFrequencyHidable}
                              />
                              <Label
                                newFormat={newFormat?.employmentStatus}
                                employeeInformation={employmentInformation?.employmentStatus}
                                showField={configData.employmentStatusHideable}
                                className1='employmentStatus'
                              />
                            </div> </>}
                          {isPreferredContactVisible ?
                            (preferredContactFromRedux?.OfferPreferenceUpdate === false) ? null :
                              <div>
                                <div class="row panelStyleNoBorder px-1">
                                  <div class="col-md-12 col-12 py-2 pt-3">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <label htmlFor={newFormat?.preferredContact?.id} class='form-side-big-header'>Preferred Contact
                                          <span> *</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row panelStyleNoBorder px-1">
                                  <div class="col-md-12">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <label class="form-side-header-contact">
                                          By checking this box, you acknowledge you would like to receive automated texts from Mercer Health &amp; Benefits Administration LLC (Mercer Benefits Center) at the telephone number provided, regarding your policy, account, benefits, relationship with Mercer, and other products or services offered through Mercer and/or your employer or sponsoring organization. Consent is not a condition of any purchase or to obtain a quote. Checking the box constitutes your electronic signature. If you wish to withdraw your consent in the future, you can reply STOP to any text message or come back to this page and update your communication preferences.</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row panelStyleNoBorder px-1">
                                  <div class="col-lg-3 col-md-3 col-sm-4 mb-3">
                                    <div class="selectContainer">
                                      <select
                                        id={newFormat?.preferredContact?.id}
                                        name={newFormat?.preferredContact?.name}
                                        class="w-100 selectText"
                                        value={formik?.values?.preferredContact}
                                        onChange={handleSelectChange}
                                        onBlur={formik.handleBlur}>
                                        <option value="">{'Select an option'}</option>
                                        {getOptionsForPreferredContact().map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.text}
                                          </option>
                                        ))}
                                      </select>
                                      {formik.touched.preferredContact && formik.errors.preferredContact && (
                                        <div class="form-mandatory">{formik.errors.preferredContact}</div>)}
                                    </div>
                                  </div>
                                </div>
                              </div> : ''
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 form-btnGroup">
                {props.className === 'manageProfileWrapper'
                  ?
                  <button
                    className="btn btn-primary primaryBackground cancelBtn"
                    type="button"
                    onClick={() => {
                      history.push('/enrollment/home');
                    }}
                  >
                    Cancel
                  </button> : ''}
                <button
                  className="btn btn-primary primaryBackground"
                  disabled={
                    // check if preferredContact is visible and empty
                    (isPreferredContactVisible && !configData.PCsectionToogle && formik.values.preferredContact === '') || hasProfileErrors ||
                    // check if the SSN field is visible and not filled out
                    (configData.ssnHidable === false && employeeInformation?.ssn === '' && formik.values.ssn === '') ||
                    // check if the personalEmail and personalPhone are displayed as textboxes and not filled out
                    (configData.personalEmailAddressRequired && !configData.personalEmailAddressChangedByEmp && formik.values.personalEmail === '') ||
                    (configData.personalPhoneRequired && !configData.personalPhoneChangedByEmp && formik.values.personalPhone === '') ||
                    // check if the form is valid and dirty
                    (!formik.isValid) ||
                    errorMsg
                  }
                  type="button"
                  onClick={UpdatedContactToSalesForce}
                >
                  {btnLabel}
                </button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});

export default Profile;

