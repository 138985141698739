
import moment from 'moment';

const DateHelper = {
  formatDate: function(date, format) {
    return moment(date).format(format);
  },

  addDays: function(date, days) {
    return moment(date).add(days, 'days');
  },

  subtractDays: function(date, days) {
    return moment(date).subtract(days, 'days');
  },

  isSameDay: function(date1, date2) {
    return moment(date1).isSame(date2, 'day');
  },

  isEqual: function(date1, date2) {
    return moment(date1).isSame(date2);
  },

  isNotEqual: function(date1, date2) {
    return !this.isEqual(date1, date2);
  },

  isGreater: function(date1, date2) {
    return moment(date1).isAfter(date2);
  },

  isLesser: function(date1, date2) {
    return moment(date1).isBefore(date2);
  },

  isGreaterOrEqual: function(date1, date2) {
    return !this.isLesser(date1, date2);
  },

  isLesserOrEqual: function(date1, date2) {
    return !this.isGreater(date1, date2);
  },

  startOfDay: function(date) {
    return moment(date).startOf('day');
  },

  endOfDay: function(date) {
    return moment(date).endOf('day');
  },

  daysBetween: function(date1, date2) {
    return moment(date1).diff(date2, 'days');
  }
};

// module.exports = DateHelper;
export default DateHelper;
