export const getValueFromKeyString = (valueObject, keyString, index = 0) => {
  if (!valueObject || Object.keys(valueObject).length === 0 || !keyString) {
    return
  }

  let keyList = keyString.split(".")
  if (keyList.length === 1) {
    return valueObject[keyList[0]]
  }

  let key = keyList[0]
  if (key.slice(-2) === "[]") {
    return getValueFromKeyString(valueObject[key.slice(0, -2)] && valueObject[key.slice(0, -2)][index], keyList.slice(1).join("."), index)
  }
  else {
    return getValueFromKeyString(valueObject[key], keyList.slice(1).join("."), index)
  }
}