export const getValueFromKeyStringAcceptArray = (valueObject, keyString, keySubString, index = 0) => {
  if (!valueObject || Object.keys(valueObject).length === 0 || !keyString) {
    return
  }

  let keyList = keyString.split(".")
  if (keyList.length === 1 && keySubString) {
    return valueObject[keyList[0]][keySubString]
  }
  if (keyList.length === 1 && !keySubString) {
    return valueObject[keyList[0]]
  }

  let key = keyList[0]
  if (key.slice(-2) === "[]") {
    return getValueFromKeyStringAcceptArray(valueObject[key.slice(0, -2)] && valueObject[key.slice(0, -2)][index], keyList.slice(1).join("."), keySubString, index)
  }
  else 
  {
    if(key.slice(key.length-3) === '[0]') {
      key=key.slice(0, -3)
      return getValueFromKeyStringAcceptArray(valueObject[key][0], keyList.slice(1).join("."), keySubString, index)
    }
    return getValueFromKeyStringAcceptArray(valueObject[key], keyList.slice(1).join("."), keySubString, index)
  }
}