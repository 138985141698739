import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import appConfig from '../../../appConfig';
import PrimaryIdetifierHelper from '../../../App/Utils/Helper/PrimaryIdetifierHelper';
import ChatIcon from '../../../Assets/Images/chat_icon.png'
import { LIVECHAT_URL } from '../../../Store/Slices/EmployeeProfile';
import "./styles.css";
import axios from 'axios';
import { Link } from 'react-router-dom';

const LiveChat = () => {

  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [FinalredirectURL, SetFinalredirectURL] = useState('')
  const [s1, Sets1] = useState('')
  const [content, SetContent] = useState({});



  const entitiesRedux = useSelector((storeState) => storeState?.entities);
  const authRedux = useSelector((storeState) => storeState?.auth);
  const employeeProfileRedux = useSelector((storeState) => storeState?.employeeProfile);
  const registerationRedux = useSelector((storeState) => storeState?.registeration);
  let dataFromReduxPersonalInformation = entitiesRedux?.response?.onLoadResponseList?.PersonalInformation?.personalInformation
  const dataFromReduxEmployeeInformation = employeeProfileRedux?.employeeProfile
  dataFromReduxPersonalInformation = (dataFromReduxPersonalInformation == undefined ? dataFromReduxEmployeeInformation : dataFromReduxPersonalInformation)
  const employeeIdFromRedux = entitiesRedux?.response?.onLoadResponseList?.EmploymentInformation?.employmentInformation?.employeeId

  const userbUID = authRedux?.loggedUserEntity?.data?.person?.bUID;
  const legalEntityData = authRedux?.loggedUserEntity?.data?.personDetails?._Result?.[0]?.legalEntity?.identifier?.externalClientId?.externalClientId
  const legalEntityDataBackup = registerationRedux?.legalEntityId;
  const livechatRedux_url = employeeProfileRedux?.livechat_url
  const oneCode = legalEntityDataBackup ? (/[^:]*/.exec(legalEntityDataBackup)[0]) : legalEntityData
  const redirectURL = appConfig.CHAT_AVAILABILITY_URL;
  const primaryIdetifierHelper = new PrimaryIdetifierHelper();
  const dispatch = useDispatch();

  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => SetContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);

  const sendChatHandlerReq = async (personalDetails) => {

    let chatStatusCode = ''
    axios.post(appConfig.SSP_MID_URL + '/chat/requestChatAvailability', { "clientId": oneCode }, { timeout: content.salesforceAPITimeout })
      .then((resp) => {
        chatStatusCode = resp.data.StatusCode
        if (chatStatusCode === 'CHT_SUC_100') {
          axios.post(appConfig.SSP_MID_URL + '/chat/encrypt', personalDetails, { timeout: content.salesforceAPITimeout })
            .then(response => {
              const s1 = response.data.encryptedString
              if (s1 !== '') {
                const finalURL = redirectURL +
                  '?LoginName=' + (personalDetails?.[content?.personalDetails?.lName] || '') + ' ' + (personalDetails?.[content?.personalDetails?.fName] || '') +
                  '&Subject=' + (content?.chatParams?.subject || '') +
                  '&p1=' + (content?.chatParams?.p1 || '') +
                  '&p2=' + (oneCode || '') +
                  '&s1=' + s1;

                SetFinalredirectURL(finalURL);
                dispatch(LIVECHAT_URL(finalURL));
                openChat(finalURL);
              } else manageUnAuthenticatedUser()
            })
            .catch(error => {});
        }
      }).catch(error => {});

  }

  const manageAuthenticatedUser = () => {
    if (livechatRedux_url === "") {
      const personalDetails = primaryIdetifierHelper.getPersonalDetails(employeeIdFromRedux, dataFromReduxPersonalInformation, oneCode);
      sendChatHandlerReq(personalDetails)

      /*  if (s1 !== '') {
         const finalURL = redirectURL +
          'LoginName=' + (personalDetails?.[content?.personalDetails?.lName] || '') + ' ' + (personalDetails?.[content?.personalDetails?.fName] || '') +
          '&Subject=' + (content?.chatParams?.subject || '') +
          '&p1=' + (content?.chatParams?.p1 || '') +
          '&p2=' + (oneCode || '') +
          '&s1=' + s1;
         
         SetFinalredirectURL(finalURL);
         dispatch(LIVECHAT_URL(finalURL));
         window.open(finalURL,"_blank");
         } else manageUnAuthenticatedUser() */
    }
    else {
      const finalURL = livechatRedux_url
      SetFinalredirectURL(finalURL)
      openChat(finalURL);
    }
  }

  const manageUnAuthenticatedUser = () => {

    const finalURL = redirectURL +
      '?Subject=' + (content?.chatParams?.subject || '') +
      '&p1=' + (content?.chatParams?.p1 || '') +
      '&p2=' + (oneCode || '') +
      '&p3=' + (content?.chatParams?.p3 || '');

    SetFinalredirectURL(finalURL);
    openChat(finalURL);
  }

  function openChat(finalURL){
    let link = document.createElement('a');
    link.href = finalURL;
    link.target = '_blank';
    link.click();
  }

  function onChatClick(e) {
    e.preventDefault();
    if ((userbUID && oneCode && employeeIdFromRedux)) {
      manageAuthenticatedUser()
    } else {
      manageUnAuthenticatedUser()
    }
  }

  return (
    <aside role="complementary">
    <Link to={FinalredirectURL} target="_blank" className='chat-link' onClick={onChatClick}>
      <img className='chat-icon' src={ChatIcon} alt='Chat Icon' />
    </Link>
    </aside>
  );
};
export default LiveChat;
