import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Linker } from '../../../Atoms';
import Modal from '../../../Molecules/Modal/index';
import {
  APIRequestParallel,
  APIRequestSequential,
  APIRequestParallelMFA,
} from '../../../../Store/Slices/Entities/response/index';
import SearchForm from './SearchForm';
import { reduxConstants } from '../../../../Common/Constants/index';
import { isEmpty } from 'lodash';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';
import { SET_EMPLOYEE_VALIDATION_ERROR } from '../../../../Store/Slices/RegisterationError';
import {
  CURRENT_STEP,
  STEP_KEYS,
  PREVIOUS_STEP,
  SET_SKIP_DEPENDENT_SCREEN
} from '../../../../Store/Slices/StepForm';

import http from '../../../../Common/Services/HttpService/index';
import appConfig from '../../../../appConfig';
import { LIFE_EVENT, DEPENDENT_REPLATIONSHIP } from '../../../../Store/Slices/EmployeeProfile';

import toast from 'react-hot-toast';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response/index.js';
import { ADD_STEP, CONTINUE_EMAIL_CLICKED, MOBILE_BACK, SEND_CODE_MOBILE_CLICKED, SEND_OTP_EMAIL, SET_MFA_CONTENT, SHOW_SPECIAL,
         MOBILE_OTP_ENTERED, REQUEST_CHANGE_EMAIL, RESET_CALLCENTER } from '../../../../Store/Slices/CallCenter';
import { getValues } from '../../../Utils/Helper/ValidationHelper';

import { UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment/index';
import { updateStepperNavigatorInfo } from '../../../Utils/Form/StepperHelper';
import { SET_PASSWORD_FAILED, SET_PERSON_BUID_FROM_AUTH_MODULE } from '../../../../Store/Slices/Registeration';
import { CLEAR_ALERT_MESSAGES } from '../../../../Store/Slices/InsuredInfo';

function StepForm(props) {
  const [showModal, setShowModal] = useState(false);

  const {
    showErrorModel,
    errorModelElements = [],
    content: {
      isUserInfo,
      isRegisteration,
      modalName = '',
      modalPopUp = false,
      lifeEvent,
      isUserID,
      isPassword,
      isMFAChangeContact,
      forms: { CoverageSelection: { hasSpecialXPadding, specialXPadding } = {} } = {},
      layout: { errorModelName = '', hideLayoutFormButtons = false } = {},
    } = {},
  } = props;

  useEffect(() => {
    let hasError = showErrorModel ? true : false;
    setShowModal(hasError);
  }, [showErrorModel]);

  useEffect(() => {

  }, []);

  /** Created to render Back To Login link for mobile device, it is hidden in registeration screen */
  let lifeEventModal = modalName || '';
  let lifeEventModalPopup = modalPopUp || false;

  const history = useHistory();
  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};
  const { registerationError } = useSelector((storeState) => storeState);
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);
  const { registeration } = useSelector((storeState) => storeState);
  const { callCenter } = useSelector((storeState) => storeState);
  const { userAuth } = useSelector((storeState) => storeState.auth);
  const { contentData, handleSubmit, currentStep, formik } = props;
  const { formData, goForward, goBack, cancel, steps, stepKeys, goBackStep } = contentData;

  const dispatch = useDispatch();

  const customizeBackBtnWidth = props.content.customizeBackBtnWidth;
  const customizeContinueBtnWidth = props.content.customizeContinueBtnWidth;
  const radioSelectedValue = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValue
  );
  const { formValues } = useSelector((storeState) => storeState.entities.response);
  const { disabledSubmitBtn, codeIsEntered, disableRegisterationProcess } = useSelector(
    (storeState) => storeState.disableButton
  );
  const enrollmentProcessData = useSelector((state) => state.process?.enrollmentProcess);
  const { stepForm } = useSelector((storeState) => storeState);
  const {
    legalEntityId = '',
    appCode = '',
    basicAuthorizationValue = '',
    token = '',
    otp = '',
    url = '',
    login = '',
    password = '',

  } = useSelector((storeState) => storeState.registeration);


  const skipDependentStep = useSelector((storeState) => storeState.stepForm.skipDependentStep);
  const dependentRelationShipRedux = useSelector((storeState) => storeState?.employeeProfile?.dependentRelationShip);
  const storeFromRedux = useSelector((storeState) => storeState);
  
  const subscribeCoverageBuids = storeFromRedux?.entities?.response?.onLoadResponseList?.[currentStep]?.subscribeCoverageBuids || [];

  //let shouldNotContinue =  registerationError[stepForm.currentStep]?.hasError && stepForm.currentStep === 'EmployeeValidation';
  let shouldNotContinue = [];
  shouldNotContinue[currentStep] = registerationError[stepForm.currentStep]?.hasError;
  let errorCount = registerationError.errorCount || 0;
  const error = (text) =>
    toast.error(`Sorry,${text}`, {
      timeout: 10000,
    });

  let empDataNotFound;
  //temproary commented for development purpose
  useEffect(() => {
    if (currentStep > 0 && registerationError[stepForm.stepKeys[currentStep - 1]]?.hasError) {
      empDataNotFound = true;
      props.content.layout.backRedirect ? handleBack() : goBack(props.skipSteps);
      dispatch(
        SET_EMPLOYEE_VALIDATION_ERROR({
          hasError: true,
          clearCount: false,
          key: stepForm.stepKeys[currentStep - 1],
          count: errorCount,
        })
      );
    }
  }, [currentStep, registerationError.errorCount, registerationError.serviceUnavailable]);

  useEffect(() => {
    //if (currentStep > 0 && registerationError[stepForm.stepKeys[currentStep - 1]]?.hasError) {
    if (props.currentForm === 'ContactDetails' && registerationError[props.currentForm]?.hasError) {
      let mfaStep = callCenter.steps.length > 1 && callCenter.steps[callCenter.steps.length - 1]
      if (mfaStep === 'email')
        dispatch(SHOW_SPECIAL(false));
    }


    //props.content.layout.backRedirect ? handleBack() : goBack(props.skipSteps);


  }, [registerationError[props.currentForm]]);


  const enrollmentType = useSelector(
    (storeState) => storeState?.employeeProfile?.enrollmentType
  );

  function handleCancel() {
    cancel(props.content.layout.cancelRedirect);
  }

  function handleBack() {
    cancel(props.content.layout.backRedirect);
  }
  function handleRegisterationBack() {
    goBack(props.content.layout.backRedirect);
  }
  let { disableProductSelection } = useSelector((storeState) => storeState.disableButton);

  let { hideFormButtons } = useSelector((storeState) => storeState.stepForm);

  let { hideContinueBtn, hideBackBtn } = useSelector((storeState) => storeState.stepForm);
  const previousStep = useSelector((storeState) => storeState.stepForm.previousStep);

  const currentStepRedux = useSelector((storeState) => storeState.stepForm.currentStep);

  const enrollmentProcessBuid = useSelector(
    (storeState) => storeState?.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID
  );
  const productIdentifierBuid = useSelector(
    (storeState) => storeState?.process?.productFamilyIdentifier["Accident"]
  );
  //const productIdentifierBuid = "318844143_1"
  const contractMemberBuid = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.SubscribeResponse?._Result?.contract
        ?.contract?.buid
  );

  const preferredContactDetailsFromRedux = useSelector((storeState) => storeState?.employeeProfile?.preferredContactDetails);
  const selectedProductList = useSelector((storeState) => storeState?.process?.selectedProductList) || [];
  const productList = useSelector((storeState) => storeState?.process?.productList || []);

  const enrollmentBuid = useSelector(storeState => storeState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID) ?? null;

  const processEntity = enrollmentProcessData?.[0]?.[0]?._Result?.processEntity?.buid

  const eligibleProduct = selectedProductList?.map((product) => {
    return {
      bUID: product?.product?.buid,
      className: product?.product?.className
    }
  })
  function innerFormElement(currentStep) {

  }
  const currentForm = stepKeys[currentStep];
  const hasErrors = !isEmpty(formik.errors[currentForm]);

  const handleEnrollmentSummarySumbit = async () => {

    try {
   //   dispatch(SHOW_CONTENT_LOADER(true));
      const resp = await http.post(appConfig.SSP_MID_URL + '/finalEnrollmentProcess', {
        enrollmentProcessBuid: storeFromRedux.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID
      });


      if (resp && resp.data && resp.data._Result) {
        const result = resp.data._Result;

        dispatch(onLoadAPI({
          finalEnrollmentProcess: result,
          isAPICallInProgress: false,
        }));

        const processInfo = resp?.data?._Result ?? {};
        dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
        

     //   dispatch(SHOW_CONTENT_LOADER(false));
        history.push("/confirmationScreen");
      } else {
        toast.error('Enrollment failed');
      }

    } catch (error) {
  //    dispatch(SHOW_CONTENT_LOADER(false));
      toast.error('Enrollment failed - Invalid response');
    }
  }

  const validateContractData = async () => {

    try {

      let currentStepInfo = {
        "portalStep": currentStep,
        "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ?  JSON.stringify( enrollmentData?.processEntity?.currentStepNb) : "",
        "reachedPreConfirmation": "no"
      };
  
      const resp = await http.post(appConfig.SSP_MID_URL + '/validateContractData', {
        enrollmentProcessBuid: enrollmentBuid,
        "externalStepContext": currentStepInfo
      });


      if (resp && resp.data) {


       await dispatch(onLoadAPI({
          ValidateContractData: resp.data,
          isAPICallInProgress: false,
        }));

        const processInfo = resp?.data?._Result ?? {};
      await  dispatch(UPDATE_ENROLLMENT_DATA(processInfo));


      } else {
        //  toast.error('Enrollment failed');
      }

    } catch (error) {
    //  dispatch(SHOW_CONTENT_LOADER(false));
      // toast.error('Enrollment failed - Invalid response');
    }
  }
  const handleLifeEvent = () => {
    if(enrollmentType === 'case12' || enrollmentType === 'case13' || enrollmentType === 'case14'){
      dispatch(LIFE_EVENT(formik.values?.LifeEvent));
      dispatch(PREVIOUS_STEP({ previousStep: '' }));
      $('#userConfirmationTwo').modal('show'); //No existing certificate and coming to portal for first time during ongoing enrollment 
    }else{
      dispatch(LIFE_EVENT(formik.values?.LifeEvent));
      $('#userConfirmation').modal('show');
    }
  };
  let showFormButton = props?.formik?.values[stepKeys[currentStep]]?.hideOptions;
  let showFormButton1 = props?.formik?.values[stepKeys[currentStep]]?.hideOptions1;
  let showBackButton = props?.formik?.values[stepKeys[currentStep]]?.continueProductButton;

  let height = isPassword || isUserID || isRegisteration || lifeEvent || isMFAChangeContact ? '60vh' : '';
  // let height = isPassword || isUserID || isRegisteration || lifeEvent  ? '60vh' : 
  // isMFAChangeContact ?'80vh' :'';

  //Dispatching the current step to store
  // stepKeys[currentStep]

  useEffect(() => {
    dispatch(STEP_KEYS({ stepKeys: stepKeys }));
  }, [stepKeys])
  
  useEffect(() => {
    dispatch(CURRENT_STEP({ currentStep: currentForm, stepId: currentStep }));
  }, [currentStep, currentForm])

  useEffect(() => {
    if ( previousStep !== currentStep ) {
      dispatch(CLEAR_ALERT_MESSAGES());
    }

  }, [currentStep, previousStep])

  const renderNotFoundError = () => {
    if (registerationError.errorCount &&
      registerationError[currentForm]?.hasError &&
      !registerationError.showAlert && !registerationError.mfaErrorText &&
      (currentForm === 'EmployeeValidation' ||
        currentForm === 'EmployeeValidationForgotUserID'))
      return (
        <div>
          <span
            style={{
              color: '#f44336',
              fontFamily: 'NotoSansRegular',
              fontSize: '0.75',
              paddingLeft: '1rem',
            }}
          >
            Sorry, Employee Data Not Matched
          </span>
        </div>
      );
    else

      return null;
  };

  const updateContentBasedOnRelationShip = async (previousStep) => {
   
  //  dispatch(SHOW_CONTENT_LOADER(true));
    return await http
      .post(appConfig.SSP_MID_URL + '/updateContractInformation', {
        ...{
          updateContractEnrollmentBuid: enrollmentBuid,
          contractMemberBuid,
        },
      })
      .then((resp) => {
        const data = resp?.data;
        // we already dispactched the same in layout 
        // dispatch(UPDATE_CONTRACT_INFORMATION(data));
      }).catch((error) => {
       
      })
  };

  const renderBadRequetsError = () => {
    if (
      registerationError[currentForm]?.hasError &&
      !registerationError.showAlert &&
      !registerationError.emailExist &&
      !registerationError.showInputErr &&

      (currentForm === 'EmployeeValidation' || currentForm === 'UserAccountVerifyEmail' || currentForm === 'EmployeeValidationForgotUserID'))

      return (
        <div>
          <span
            style={{
              color: '#f44336',
              fontFamily: 'NotoSansRegular',
              fontSize: '0.75',
              paddingLeft: '1rem',
            }}
          >
            {currentForm !== 'UserAccountVerifyEmail' ?
              currentForm !== 'MultiFactorAuthenticationLogin' ? 
                registerationError.mfaErrorText || (registerationError.wynsureErrorText && !registerationError.employeeDataNotFound): 
              registerationError.newOTPText :
            registerationError.mfaErrorText !== 'Invalid Passcode/Answer' ?
              registerationError.mfaErrorText 
            :''
            }
          </span>
        </div>
      );
    else return null;
  };

  const renderInvalidOPTMethodError = () => {
    if (
      !registeration.tryAnotherMothod &&
      (registerationError.mfaErrorText === 'Request failed with status code 401' ||
        registerationError.mfaErrorText === 'Request failed with status code 400') &&
      !registerationError.showAlert &&
      (currentForm === 'MultiFactorAuthenticationLogin' || currentForm === 'MFAForgotPassword' || currentForm === 'MFAForgotUsername'))

      return (
        <div>
          <span
            style={{
              color: '#f44336',
              fontFamily: 'NotoSansRegular',
              fontSize: '0.75',
              paddingLeft: '1rem',
            }}
          >
            {registerationError.oktaErrorText}
          </span>
        </div>
      );
    else return null;
  };

  const renderTooManyRequestsError = () => {
    if (
      !registeration.tryAnotherMothod &&
      (registerationError.mfaErrorText === 'Request failed with status code 429' &&
        registerationError.oktaErrorText === 'Please wait 30 seconds before trying again.') &&
      !registerationError.showAlert &&
      (currentForm === 'MultiFactorAuthenticationLogin' || currentForm === 'MFAForgotUsername')){
        $("#tooManyRrqErrorMsg").delay(30000).hide(1);

      return (
        <div id='tooManyRrqErrorMsg' >
        <span
            style={{
              color: '#f44336',
              fontFamily: 'NotoSansRegular',
              fontSize: '0.75',
              paddingLeft: '1rem',
            }}
          >
            {registerationError.oktaErrorText}
          </span>
        </div>
      );
          }
    else return null;
  };

  const renderInvalidPasswordError = () => {
    if (registerationError.mfaErrorText === 'Password does not match policy, please modify and try again'  &&
        !registerationError.showAlert &&
        currentForm === 'PasswordValidation'
      ){
      return (
        <div  >
          <span
            style={{
              color: '#f44336',
              fontFamily: 'NotoSansRegular',
              fontSize: '0.75',
              paddingLeft: '1rem',
            }}
          >
            {registerationError.mfaErrorText}
          </span>
        </div>
      );
    }
    else return null;
  };

  let shouldDisableForApiErr =
    (props.currentForm == 'UserAccountVerifyEmail' ||
      props.currentForm == 'MultiFactorAuthentication') &&
    registerationError[stepForm.currentStep]?.hasError;


  /** customize screen hight to 65vh if form for Registeration, reset password, or retrieve userID, Nisreen 2022 */
  //let heightt = isPassword || isUserID || isRegisteration ? '60vh' : '';

  let disableBtn = props.currentForm === 'EmployeeValidationForgotPassword' ||
    props.currentForm === 'MFAForgotUsername' ||
    props.currentForm === 'EmployeeValidationForgotUserID' ||
    props.currentForm === 'MFAForgotPassword' ||
    props.currentForm === 'EmployeeValidationForgotUserID' ||
    props.currentForm === 'ContactDetails';
  let changeUrl = (props.currentForm === 'EmployeeValidationForgotPassword' ||
    props.currentForm === 'EmployeeValidationForgotUserID' ||
    props.currentForm === 'EmployeeValidation' ||
    props.currentForm === 'UserAccountVerifyEmail' ||
    props.currentForm === 'MultiFactorAuthentication' ||
    props.currentForm === 'MFAForgotPassword' ||
    props.currentForm === 'MFAForgotUsername' ||
    props.currentForm === 'MultiFactorAuthenticationLogin') && url



// Then you can conditionally do something based on currentForm
const hideEnrollBtn = currentForm === 'EnrollmentSummary' || currentForm === 'EmployeeProfile' || currentForm === 'ManageProfile';

  
  let changeMFAContactUser = userAuth.processType === "non-sso"
  let passwordValidationForm = currentForm === 'PasswordValidation'
  let changeUrlForPassword = passwordValidationForm || changeUrl

  return (
    <React.Fragment>
      <div className="row" style={{ minHeight: height }}>
        {/* /* Add below if needed */}
        <div className={!hasSpecialXPadding ? 'col-12 px-0' : ` col-12 ${specialXPadding}`}>
          {/* <div className= "col-12 px-0 " > */}

          {/* <div className="col-12 "> */}
          {(props.content.forms[stepKeys[currentStep]] &&
            props.content.forms[stepKeys[currentStep]].innerForm &&
            innerFormElement(currentStep)) ||
            (props.content.forms[stepKeys[currentStep]] &&
              props.content.forms[stepKeys[currentStep]].searchForm && (
                <SearchForm {...props} innerLayout={true}>
                  {' '}
                </SearchForm>
              )) ||
            (steps[stepKeys[currentStep]] && steps[stepKeys[currentStep]][0])}
        </div>
      </div>
      {renderNotFoundError()}
      {renderBadRequetsError()}
      {renderInvalidOPTMethodError()}
      {renderTooManyRequestsError()}
      {renderInvalidPasswordError()}

      {/* below condition: currentForm !== 'ExcessLiability' added by Nisreen, need to replace with better one*/}
      {steps[stepKeys[currentStep]] && currentForm !== 'ExcessLiability' && (
        <div
          className={
            isPassword
              ? 'row topPadding mt-auto mb-1 pt-auto text-left stepBtnWrap px-lg-2 pl-xl-3 pr-xl-0 mr-lg-0 px-xl-0 mr-xl-1 '
              : isUserID
                ? 'row topPadding mt-auto mb-1 pt-auto text-left stepBtnWrap px-lg-2 pl-xl-3 pr-xl-0 mr-lg-0 px-xl-0 mr-xl-1 '
                : isRegisteration
                  ? 'row topPadding mt-4 text-left stepBtnWrap px-lg-2 pl-xl-3 pl-md-0 pr-xl-2 mr-lg-0 px-xl-0  pr-md-3 mr-xl-1  justify-content-between px-4 '
                  : lifeEvent
                    ? 'row topPadding my-4 text-center stepBtnWrap px-lg-2 px-xl-3 mx-0 mx-md-5 mx-lg-3 pr-md-2 '
                    : isMFAChangeContact
                      ? 'row topPadding mt-auto mb-1 pt-auto text-left stepBtnWrap px-lg-2 pl-xl-0 pr-xl-0 mr-lg-0 px-xl-0 mr-xl-1 '
                      : 'row my-4 text-center stepBtnWrap px-lg-2 px-xl-0'
          }
        >
          {/* <div className='col-md-3 ml-1 align-self-center d-none d-sm-block'></div> */}
          {/** render step buttons and hide Back To login link for Mobile view */}
          {!isUserID && !isPassword && !isRegisteration && (
            <div className="col-12 col-lg-3 col-md-4 py-2 align-self-center d-none d-sm-block">
              {props.content?.layout?.bottomLink && (
                <Linker to={changeUrl ? props.content?.layout?.bottomLink?.url + decodeURIComponent(url) : props.content?.layout?.bottomLink?.url}>
                  {props.content?.layout?.bottomLink?.text}
                </Linker>
              )}
            </div>
          )}
          {/** render step buttons and show Back To login link for Mobile view - Nisreen */}
          {/* {(isUserInfo || isRegisteration) && !showFormButton && <div  */}
          {isRegisteration && !showFormButton && (
            <div className="col-12 col-lg-auto col-xl-3 col-md-auto py-2 align-self-center d-sm-block px-xl-0 px-md-4 ml-xl-3 ml-lg-0 ml-md-2  px-2  pr-xl-5 pr-0 pl-md-3 pl-2 ml-md-3 mr-md-auto  ">
              {/* // className= "col-12 col-lg-auto col-xl-3 col-md-auto py-2 align-self-center d-sm-block px-xl-0 px-md-4 ml-xl-2 ml-lg-0 ml-md-0  px-2  pr-xl-5 pr-0 pl-md-4 pl-3 ml-3 ">  */}
              {props.content?.layout?.bottomLink && (
                <Linker
                  to={changeUrl ? props.content?.layout?.bottomLink?.url + decodeURIComponent(url) : props.content?.layout?.bottomLink?.url}
                // onClick={isMFAChangeContact ? dispatch(ENABLE_CONTINUE_BTN()):''}
                >
                  {props.content?.layout?.bottomLink?.text}
                </Linker>
              )}
            </div>
          )}
          {/** render step buttons and show Back To login link for Mobile view - Nisreen */}
          {isUserID && !showFormButton && (
            <div
              // need fix className="col-12 col-lg-auto col-xl-3 col-md-auto py-2 align-self-center d-sm-block px-xl-0 px-md-4  ml-lg-0 ml-md-2  px-2  pr-xl-5 pr-0 pl-md-3 pl-3 ml-xl-3 ml-3 mr-md-0   ">
              className="col-12 col-lg-auto col-xl-3 col-md-auto py-2 align-self-center d-sm-block px-xl-0 px-md-4  ml-lg-0 ml-md-2  px-2  pr-xl-5 pr-0 pl-md-3 pl-3 ml-xl-3 ml-4 mr-lg-auto mr-md-0    "
            >
              {/* //  className="col-12 col-lg-auto col-xl-3 col-md-auto py-2 align-self-center d-sm-block px-xl-0 px-md-4  ml-lg-0 ml-md-2  px-2  pr-xl-5 pr-0 pl-md-3 pl-3 ml-xl-3 ml-3 mr-md-auto   "> */}
              {props.content?.layout?.bottomLink && (
                <Linker to={changeUrl ? props.content?.layout?.bottomLink?.url + decodeURIComponent(url) : props.content?.layout?.bottomLink?.url}>
                  {props.content?.layout?.bottomLink?.text}
                </Linker>
              )}
            </div>
          )}
          {isPassword && !showFormButton && (
            <div className="col-12 col-lg-auto col-xl-3 col-md-auto py-2 align-self-center d-sm-block px-xl-0 px-md-4  ml-lg-0 ml-md-2  px-2  pr-xl-5 pr-0 pl-md-3 pl-3 ml-xl-3 ml-3 mr-md-auto   ">
              {props.content?.layout?.bottomLink && (
                <Linker to={
                  !changeMFAContactUser ?
                    changeUrlForPassword  ? 
                      props.content?.layout?.bottomLink?.url + decodeURIComponent(url) : 
                    props.content?.layout?.bottomLink?.url :
                  props.content?.layout?.bottomLink?.homeURL
                  }
                  onClick={()=>{userAuth.userType && http.setUserType(userAuth.userType) }}
                >
                  { !changeMFAContactUser ? 
                    props.content?.layout?.bottomLink?.text :
                    props.content?.layout?.bottomLink?.homeText}
                </Linker>
              )}
            </div>
          )}
          {/**need to remove */}
          {/* {isRegisteration && <div 
          className= "col-12 col-lg-4 col-xl-3 col-md-4 py-2 px-md-0 ml-md-2 ml-lg-2 ml-xl-3 align-self-center  d-sm-block "> 

            {props.content?.layout?.bottomLink && (
              <Linker to={props.content?.layout?.bottomLink?.url}>
                {props.content?.layout?.bottomLink?.text}
              </Linker>
            )}
          </div>} */}
          {/* <div className="col-sm-12 col-md-3  col-lg-3 col-xl-2 py-2"></div> */}

          {!showFormButton && !hideBackBtn && !hideEnrollBtn && (
            <div
              className={` ${props.content?.layout?.stepButtonClasses?.goBack ||
                `col-12 offset-lg-3 col-lg-2 col-md-4 py-2 `
                }`}
            >
              {/* commmented below condition to show the back button for employee profile screen */}
              {/* {!props.isFirst && ( */}
              {/* <Button className="w-100" outline onClick={() => goBack(props.skipSteps)}></Button> */}
              {/* Added handleback to navigate*/}
              {(!props.isFirst || props?.content?.layout?.showBackButton) && (
                <Button
                  isRegisteration={props.content.isRegisteration}
                  backButton={true}
                  className="w-100"
                  hidden={hideBackBtn}
                  outline
                  backBtnWidth={customizeBackBtnWidth}
                  onClick={() => {
                    //dispatch(ENABLE_SEND_CODE_BTN(false))
                    // temp commented dispatch(SEND_CODE_MOBILE_CLICKED(false));
                    if (previousStep === 'EnrollmentSummary' && 
                      (currentStepRedux === 'ProductSelection' || currentStepRedux === 'DependentInformation')
                    ) {
                      dispatch(PREVIOUS_STEP({ previousStep: '' }));
                      goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                    } else if (callCenter.contactType === 'mobile' && callCenter.sendCodeMobileClicked) {
                      dispatch(SEND_CODE_MOBILE_CLICKED(false));
                      dispatch(MOBILE_BACK(true));
                    } else if (callCenter.contactType === 'email' && callCenter.showSpecial) {
                      dispatch(SHOW_SPECIAL(false));
                      dispatch(MOBILE_BACK(true));
                      dispatch(REQUEST_CHANGE_EMAIL(false))
                      // dispatch(NO_API_CALL(true));
                    }
                    else {
                      props.content.layout.backRedirect ?
                        changeUrl ? history.push(props.content.layout.backRedirect + decodeURIComponent(url)) :
                          handleBack() : goBack(props.skipSteps)
                    }
                    //props.content.layout.dispatchOnClickBack ?
                    // dispatch(SHOW_SPECIAL(false))//: '';

                  }

                    //props.content.layout.backRedirect ? handleBack() : goBack(props.skipSteps)
                  }
                >
                  {/* // onClick={() => props.content.layout.backRedirect? handleBack():
              // !props.content.isRegisteration ? goBack(props.skipSteps) :
              // handleRegisterationBack() */}

                  {props.content.layout.stepButtonLabels &&
                    props.content.layout.stepButtonLabels.goBack
                    ? props.content.layout.stepButtonLabels.goBack
                    : 'Go Back'}
                </Button>
              )}
            </div>
          )}
          {!hideLayoutFormButtons && (
            <div
              className={` ${props.content?.layout?.stepButtonClasses?.cancel ||
                ` col-sm-12 offset-md-3 col-md-3 offset-lg-3 col-lg-3 offset-xl-6 col-xl-2 py-2 `
                }`}
            >
              {/* <Button className="w-100" rounded onClick={() => handleCancel()} outline> */}
              <Button
                className="w-100"
                backBtnWidth={customizeBackBtnWidth}
                onClick={() => handleCancel()}
                outline
              >
                {props.content.layout.stepButtonLabels && props.content.layout.stepButtonLabels.cancel
                  ? props.content.layout.stepButtonLabels.cancel
                  : 'Cancel'}
              </Button>
            </div>
          )}
          {!hideLayoutFormButtons && !hideContinueBtn && !hideEnrollBtn && (
            <div
              className={` ${props.content?.layout?.stepButtonClasses?.next || `col-12 col-md-3 col-lg-2 py-2`
                }`}
            >
              {/* {(!props.isLast )&& ( */}

              {(!showFormButton || showFormButton1) && (
                <Button
                  name={isRegisteration ? 'registerationBtn' : 'continueBtn'}
                  modalName={lifeEventModal}
                  modalPopUp={lifeEventModalPopup}
                  contrast="true"
                  // disabled={(props.currentForm =="ProductSelection") ? disableProductSelection : hasErrors }
                  disabled={
                    disableBtn ? false :
                      props.currentForm == 'ProductSelection'
                        ? disableProductSelection
                        : shouldDisableForApiErr
                          ? true
                          : hasErrors
                  }
                  hidden={hideContinueBtn}
                  className="w-100"
                  type='submit'

                  // type={props.isLast ? 'submit' : 'button'}
                  continueBtnWidth={customizeContinueBtnWidth}
                  continueBtnSpecial={isUserID || isPassword || isRegisteration}
                  onClick={(e) => {
                    e.preventDefault();
                    // below blocks for change MFA contact
                    // dispatch(SEND_CODE_MOBILE_CLICKED(true));
                    //dispatch(MOBILE_BACK(false));

                    if (callCenter.contactType === 'mobile' && props.currentForm === 'ContactTypes') {
                      dispatch(CONTINUE_EMAIL_CLICKED(false));
                      dispatch(SHOW_SPECIAL(false))
                    }
                    // if(callCenter.contactType === 'mobile') dispatch(SET_MFA_CONTENT('en'))
                    else if (callCenter.contactType === 'email') {
                      if (callCenter.showSpecial) {
                        dispatch(SEND_OTP_EMAIL(true))
                        dispatch(ADD_STEP({ step: 'otp' }))
                      }
                      else
                        dispatch(ADD_STEP({ step: 'email' }))
                      dispatch(SET_MFA_CONTENT('enEmail'))
                      if (callCenter.backButton) {
                        dispatch(SHOW_SPECIAL(true));
                        dispatch(MOBILE_BACK(false));
                      }
                    }
                    if (callCenter.contactType === 'mobile') {
                      //if(callCenter.showSpecial)
                      dispatch(SEND_CODE_MOBILE_CLICKED(callCenter.showSpecial));

                    }
                    //else  dispatch(SET_MFA_CONTENT('enTypes'))
                    if (callCenter.contactType === 'email' && !callCenter.showSpecial && props.currentForm === 'ContactDetails') {
                      dispatch(SHOW_SPECIAL(true));
                    }
                    // change MFA contact block end

                    lifeEvent && handleLifeEvent();
                    updateStepperNavigatorInfo(
                      currentForm,
                      dispatch,
                      { selectedProductList, productList, props, subscribeCoverageBuids }
                    );
                    if (props.isLast) {
                      if (props.finalSubmitValue) {
                        formik.setValues({
                          ...formik.values,
                          [stepKeys[currentStep]]: {
                            ...formik.values[stepKeys[currentStep]],
                            ...props.finalSubmitValue,
                          },
                        });
                      }
                      if (props.additionalFormikValue && props.finalSubmitValue) {
                        formik.setValues({
                          ...formik.values,
                          [stepKeys[currentStep]]: {
                            ...formik.values[stepKeys[currentStep]],
                            ...props.finalSubmitValue,
                          },
                          additionalValues: props.additionalFormikValue,
                        });
                      }
                      handleSubmit(stepKeys[currentStep], formData);
                    } else if (
                      stepKeys[currentStep] &&
                      props.content &&
                      props.content.forms &&
                      props.content.forms
                    ) {
                      // dispatch(GET_DATA({ data: props }));
                      if (
                        props.content.forms[stepKeys[currentStep]] &&
                        (props.content.forms[stepKeys[currentStep]].onNextApiCall ||
                          props.content.forms[stepKeys[currentStep]].onNextApiCallSeq)
                      ) {
                        let onNextCalls = props.content.forms[stepKeys[currentStep]].onNextApiCall;
                        let callsForMFA = props.content.forms[stepKeys[currentStep]].callsForMFA;
                        let isSetPasswordAPI = props.content.forms[stepKeys[currentStep]].onNextApiCall.resetPassword;

                        let values = formik.values;
                        if (props.additionalFormikValue) {
                          values = {
                            ...values,
                            ...{ additionalValues: props.additionalFormikValue },
                          };
                        }
                        if (callsForMFA) {
                          if (values?.EmployeeValidation?.SSN) {
                            values.EmployeeValidation.employeeId = values.EmployeeValidation.SSN;
                          }
                          if (values?.EmployeeValidationForgotUserID?.SSN) {
                            values.EmployeeValidationForgotUserID.employeeId =
                              values.EmployeeValidationForgotUserID.SSN;
                          }
                        }
                        
                        if (onNextCalls) {

                          let externalStepContext = {
                            "portalStep": stepKeys[currentStep],
                            "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ?  JSON.stringify( enrollmentData?.processEntity?.currentStepNb) : "",
                            "reachedPreConfirmation": "no"
                          };
                      
                          let callAPIRequestParallel = async () => {
                            let data = await dispatch(
                              // APIRequestParallel(onNextCalls, { values, loggedUserEntity })
                              !callsForMFA
                                ? APIRequestParallel(onNextCalls, {
                                  values,
                                  processEntity,
                                  eligibleProduct,
                                  loggedUserEntity,
                                  externalStepContext,
                                  ...{
                                    enrollmentProcessBuid,
                                    productIdentifierBuid,
                                    "residenceStateCode": {
                                      "stateLabel": "Situs State",
                                      "stateName": "California",
                                      "stateCode": "CA"
                                    },
                                    contractMemberBuid,
                                    
                                  },
                                })
                                : APIRequestParallelMFA(onNextCalls, {
                                  values,
                                  loggedUserEntity,
                                  legalEntityId,
                                  appCode,
                                  basicAuthorizationValue,
                                })
                            );
                            if (data) {
                              /** get personBuid from auth module */
                              if(currentStepRedux === 'EmployeeValidation')
                                dispatch(SET_PERSON_BUID_FROM_AUTH_MODULE(data[currentStepRedux]?.data?.personBuid || ''))

                              if (
                                  previousStep === 'EnrollmentSummary' &&
                                  (currentStepRedux === 'EmployeeProfile' ||
                                  currentStepRedux === 'ProductSelection' ||
                                  currentStepRedux === 'DependentInformation')
                              ) {
                                dispatch(PREVIOUS_STEP({ previousStep: '' }));
                                updateContentBasedOnRelationShip(previousStep );
                              } else {
                                if (currentStepRedux === 'ProductSelection') {
                                  updateContentBasedOnRelationShip();
                                } else {
                                  let goNext =
                                    props.skipAction && props.skipAction(stepKeys[currentStep], data);
                                  !goNext &&
                                    goForward(
                                      stepKeys[currentStep],
                                      props.content,
                                      formik.dirty,
                                      formik.errors,
                                      formik.setTouched,
                                      formik.touched,
                                      formValues,
                                      props.skipSteps
                                    );
                                }

                              }
                            }
                          };
                          callAPIRequestParallel();
                        } else {
                          let onNextAPISeq =
                            props.content.forms[stepKeys[currentStep]].onNextApiCallSeq;
                          let callAPIRequestSequential = async () => {
                            let data = await dispatch(
                              APIRequestSequential(onNextAPISeq, { values, loggedUserEntity })
                            );
                            if (data) {
                              let goNext =
                                props.skipAction && props.skipAction(stepKeys[currentStep], data);
                              !goNext &&
                                goForward(
                                  stepKeys[currentStep],
                                  props.content,
                                  formik.dirty,
                                  formik.errors,
                                  formik.setTouched,
                                  formik.touched,
                                  formValues,
                                  props.skipSteps
                                );
                            }
                          };
                          callAPIRequestSequential();
                        }
                      } else {
                        if (
                          previousStep === 'EnrollmentSummary' &&
                          (currentStepRedux === 'EmployeeProfile' ||
                            currentStepRedux === 'DependentInformation')
                        ) {
                          dispatch(PREVIOUS_STEP({ previousStep: '' }));
                          goBackStep(props?.contentData.stepKeys.indexOf(previousStep));
                        } else {
                          let goNext =
                            props.skipAction && props.skipAction(stepKeys[currentStep], {});
                          !goNext &&
                            goForward(
                              stepKeys[currentStep],
                              props.content,
                              formik.dirty,
                              formik.errors,
                              formik.setTouched,
                              formik.touched,
                              formValues,
                              props.skipSteps
                            );
                        }
                      }
                    }

                    //send preferred communication updated data to salesforce
                    if (currentStepRedux === 'EmployeeProfile') {
                      const prefMethodOfCommunication = props.formik.values.EmployeeProfile.preferredContact;

                      const SMSOptin = (prefMethodOfCommunication === "SMS" || prefMethodOfCommunication === "Both") ? "True" : "False";
                      const APIRequestBody = {
                        "SMSOptin": SMSOptin,
                        "contactId": preferredContactDetailsFromRedux?.contactId,
                        "prefMethodOfCommunication": prefMethodOfCommunication,
                        "smsPhoneNum": props.formik.values.EmployeeProfile.personalPhone,
                        "emailAddress": props.formik.values.EmployeeProfile.personalEmail,
                      }
                      http
                        .post(appConfig.SSP_MID_URL + '/postUpdatedContactToSalesForce', APIRequestBody)
                        .then(async (resp) => {
                         
                        })
                        .catch((e) => {
                         
                        });
                    }

                    if (currentStepRedux === 'ManageProfile') {
                      const prefMethodOfCommunication = props.formik.values.ManageProfile.preferredContact;

                      const SMSOptin = (prefMethodOfCommunication === "SMS" || prefMethodOfCommunication === "Both") ? "True" : "False";
                      const APIRequestBody = {
                        "SMSOptin": SMSOptin,
                        "contactId": preferredContactDetailsFromRedux?.contactId,
                        "prefMethodOfCommunication": prefMethodOfCommunication,
                        "smsPhoneNum": props.formik.values.ManageProfile.personalPhone,
                        "emailAddress": props.formik.values.ManageProfile.personalEmail,
                      }
                      http
                        .post(appConfig.SSP_MID_URL + '/postUpdatedContactToSalesForce', APIRequestBody)
                        .then(async (resp) => {
                         
                        })
                        .catch((e) => {
                        
                        });
                    }

                    if (currentStepRedux === 'EnrollmentSummary') {
                      handleEnrollmentSummarySumbit();
                    }

                    if(currentForm =="MultiFactorAuthentication"){
                      history.push(props.content.layout.successRedirect);
                    }

                  }}
                >
                  {props.isLast
                    ? props.content.layout.stepButtonLabels &&
                      props.content.layout.stepButtonLabels.submit
                      ? props.content.layout.stepButtonLabels.submit
                      : 'Submit'
                    : props.content.layout.stepButtonLabels &&
                      props.content.layout.stepButtonLabels.next
                      ? props.content.layout.stepButtonLabels.next
                      : 'Next'}
                </Button>
              )}
              {/* )}   */}
            </div>)}
        </div>
      )}
      {/* <div>
        Step {currentStep + 1} of {stepKeys.length}
      </div> */}

      {showModal ? (
        <Modal
          {...props}
          modelSubmitSuccess={false}
          modelData={{
            elements: errorModelElements,
            modalId: `errorModal`,
            modalBody: `mt-4`,
            modalTitle: errorModelName || `Error Messages`,
          }}
        >
          {' '}
        </Modal>
      ) : null}
    </React.Fragment>
  );
}

export default StepForm;