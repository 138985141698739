import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles.css';
import { useNavigationHook } from '../../Hooks/NavigationHook';
const PolicyReplacementQuestions = ({
  initialFormValues,
  policyReplacementQuestions,
  onUpdatePolicyReplacementQuestions
}) => {

  const { currentStep } = useNavigationHook();
  const policyReplacementAnswer = useSelector((storeState) =>
    storeState?.insuredInfo?.[currentStep]?.policyReplacementQuestions
  );
  /*    policyReplacementQuestions  =  [
     {
       possibleValues: [
         {
           valueCode: 'Yes',
           fieldValue: 'Is REPLACEMENTQ',
           nameDescription: 'Yes',
           presentedName: '',
           fieldCode: 'REPL_Q1',
           fieldName: 'REPLACEMENTQ',
           codeForPopulationClass: '',
           buid: '',
           className: 'aSPCB_ClassificationValue'
         },
         {
           valueCode: 'No',
           fieldValue: 'Is Not REPLACEMENTQ',
           nameDescription: 'No',
           presentedName: '',
           fieldCode: 'REPL_Q1',
           fieldName: 'REPLACEMENTQ',
           codeForPopulationClass: '',
           buid: '',
           className: 'aSPCB_ClassificationValue'
         }
       ],
       defaultValue: null,
       useDefaultValue: false,
       isCalculated: false,
       typename: '',
       presentedName: '',
       fieldCode: 'REPL_Q1',
       fieldName: 'REPLACEMENTQ',
       codeForPopulationClass: '',
       buid: '',
       className: 'aSPCB_Classification'
     },
     {
       possibleValues: [
         {
           valueCode: 'Yes',
           fieldValue: 'Is REPLACEMENTQ2',
           nameDescription: 'Yes',
           presentedName: 'Does this coverage replace or change any existing insurance? ',
           fieldCode: 'REPL_Q2',
           fieldName: 'REPLACEMENTQ2',
           codeForPopulationClass: '',
           buid: '',
           className: 'aSPCB_ClassificationValue'
         },
         {
           valueCode: 'No',
           fieldValue: 'Is Not REPLACEMENTQ2',
           nameDescription: 'No',
           presentedName: 'Does this coverage replace or change any existing insurance? ',
           fieldCode: 'REPL_Q2',
           fieldName: 'REPLACEMENTQ2',
           codeForPopulationClass: '',
           buid: '',
           className: 'aSPCB_ClassificationValue'
         }
       ],
       defaultValue: null,
       useDefaultValue: false,
       isCalculated: false,
       typename: '',
       presentedName: 'Does this coverage replace or change any existing insurance? ',
       fieldCode: 'REPL_Q2',
       fieldName: 'REPLACEMENTQ2',
       codeForPopulationClass: '',
       buid: '',
       className: 'aSPCB_Classification'
     },
     {
       sizeMax: 256,
       defaultValue: '',
       useDefaultValue: false,
       isCalculated: false,
       typename: '',
       presentedName: 'CARRIER',
       fieldCode: 'REPL_CARRIER',
       fieldName: 'CARRIER',
       codeForPopulationClass: '',
       buid: '',
       className: 'aSPCB_AdditionalData_Desc_CString'
     },
     {
       defaultValue: {
         amount: 0,
         currency: 'USD'
       },
       useDefaultValue: false,
       isCalculated: true,
       typename: '',
       presentedName: 'PREMIUMAMT',
       fieldCode: 'DD_00177',
       fieldName: 'PREMIUMAMT',
       codeForPopulationClass: '',
       buid: '',
       className: 'aSPCB_AdditionalData_Desc_Amount'
     },
     {
       possibleValues: [
         {
           valueCode: '1',
           fieldValue: 'Plan Design 1',
           nameDescription: '$30,000.00',
           presentedName: 'Plan Design',
           fieldCode: 'PlanDesign',
           fieldName: 'Plan Design',
           codeForPopulationClass: '',
           buid: '',
           className: 'aSPCB_ClassificationValue'
         },
         {
           valueCode: '2',
           fieldValue: 'Plan Design 2',
           nameDescription: '$35,000.00',
           presentedName: 'Plan Design',
           fieldCode: 'PlanDesign',
           fieldName: 'Plan Design',
           codeForPopulationClass: '',
           buid: '',
           className: 'aSPCB_ClassificationValue'
         },
         {
           valueCode: '3',
           fieldValue: 'Plan Design 3',
           nameDescription: '$40,000.00',
           presentedName: 'Plan Design',
           fieldCode: 'PlanDesign',
           fieldName: 'Plan Design',
           codeForPopulationClass: '',
           buid: '',
           className: 'aSPCB_ClassificationValue'
         }
       ],
       defaultValue: null,
       useDefaultValue: false,
       isCalculated: false,
       typename: '',
       presentedName: 'Plan Design',
       fieldCode: 'PlanDesign',
       fieldName: 'Plan Design',
       codeForPopulationClass: '',
       buid: '',
       className: 'aSPCB_Classification'
     }
   ] */
  const filteredQuestions = policyReplacementQuestions?.filter(
    (question) => question?.fieldCode !== 'PlanDesign' && question?.fieldName !== 'PREMIUMAMT'
  ) ?? [];

  const [formValues, setFormValues] = useState(initialFormValues ?? {});

  const [showTextBoxes, setShowTextBoxes] = useState(false);

  useEffect(() => {
    if (policyReplacementAnswer) {
      setFormValues(policyReplacementAnswer);
    }
  }, [policyReplacementAnswer]);
  useEffect(() => {
    const anyYesSelected = Object.values(formValues)?.includes('Yes');
    setShowTextBoxes(anyYesSelected);
  }, [formValues]);
  const handleChange = (fieldCode, value) => {
    const updatedFormValues = {
      ...formValues,
      [fieldCode]: value?.trim(),
    };
    setFormValues(updatedFormValues);
    onUpdatePolicyReplacementQuestions?.(updatedFormValues);
  };

  // Call the new prop with the updated form values
  // onUpdatePolicyReplacementQuestions?.(updatedFormValues);


  return (
    <div className="policyReplacementQ mt-3">
      <div className='policy-replacemnt-heading'>Policy Replacement Questions</div>

      {filteredQuestions.map((question) => {
        if (question?.possibleValues) {
          return (
            <div key={question.fieldCode}>
              {/* <label className={question.fieldCode}>{question.presentedName}</label> */}
              <div className={`${question.fieldCode} question-title`}>{question.presentedName}</div>
              <div className="radioGroup">
                <fieldset>
                  <legend class="lable-sr-only" area-hidden='true'></legend>
                  {question.possibleValues.map((option) => (
                    <label key={option?.valueCode}>
                      <input
                        type="radio"
                        name={question.fieldCode}
                        value={option.valueCode}
                        checked={policyReplacementAnswer?.[question.fieldCode] === option.valueCode}
                        onChange={() => handleChange(question.fieldCode, option.valueCode)}
                      />
                      <span>{option.nameDescription}</span>
                    </label>
                  ))}
                </fieldset>
              </div>
            </div>
          );
        } else {
          if (!showTextBoxes) return null;

          let label = question?.presentedName;
          if (question.presentedName === 'CARRIER') {
            label = 'Carrier Name';
          }
          //check with APi structure Add policy Number
          else if (question?.fieldCode === 'REPL_POLICYNUMBER') {
            label = 'policy Number';
          }
          return (
            <div key={question.fieldCode} className="policyDetails">
              <div className='policyDetails-heading'>{label}</div>
              <input
                aria-label={label}
                type="text"
                value={formValues[question.fieldCode] ?? ''}
                onChange={(e) => handleChange(question.fieldCode, e.target.value)}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

export default PolicyReplacementQuestions;