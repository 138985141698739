import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Styles } from './Styles';
import { AppRoutes } from './Utils/Router';
import InactivityModal from './InactivityModal';
import defaultTheme from '../Theming/Themes/defaultTheme';

import './App.css';
import './Utils/FontAwesome';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const restrictedPaths = [
    '/userregistration',
    '/retrieveuserid',
    '/retrievepassword',
    '/changemfacontact',
    '/hrCenter',
    '/enrollment',
    '/oldenrollment',
    '/performance-login',
    '/multifactorauthenticationold',
    '/multifactorauthentication'
  ];

  const shouldShowConsentBanner = !restrictedPaths.includes(location.pathname.toLowerCase());

  const ur = useSelector((storeState) => storeState?.registeration?.url);
  const user_name = useSelector((storeState) => storeState?.auth?.userAuth?.data?.user_name);

  let theme = defaultTheme;

  useEffect(() => {
    let script;

    if (shouldShowConsentBanner) {
      const existingScript = document.querySelector('script[src="https://consent.trustarc.com/v2/notice/t4abp0"]');

      if (!existingScript) {
        script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://consent.trustarc.com/v2/notice/t4abp0';
        document.body.appendChild(script);
        
      }
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [shouldShowConsentBanner]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Styles />
        <AppRoutes />
      </ThemeProvider>

      <InactivityModal ur={ur} dispatch={dispatch} user_name={user_name} />

      {shouldShowConsentBanner && <div id="consent-banner">  </div>}
    </div>
  );
};

export default App;
