import React, { useState, useEffect } from 'react';
import Icon from '../Icon/';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ServiceHelper from '../../../App/Utils/Helper/MappingHelper';

import './styles.css';

const TextArea = (props) => {
  const {
    id,
    name,
    label,
    value,
    defaultValue,
    onChange,
    onBlur,
    className,
    classNameWrapper,
    hidden,
    required,
    requiredValidation,
    element,
    rows,
    type,
    disabled,
    formik,
    hideElement,
    noFloatingLabel,
    shouldShowOnYes,
    placeholder,
    currentStep,
    updateLabel,
  } = props;

  const [focus, setFocused] = useState(false);
  const serviceHelper = new ServiceHelper();
  let additionalClasses = hideElement ? 'hideElement' : '';
  const {
    underWritingQuestionTwo,
    underWritingQuestionThree,
    underWritingQuestionFour,
    hospitalSection,
  } = useSelector((storeState) => storeState?.employeeProfile);

  const medicalSurveys = useSelector(
    (storeState) => storeState?.process?.medicalSurvey[0]?._Result.medicalSurveys
  );

  let newLabel
  if (name=="PetAssureNation.dateAndReason" ||name=="PetAssureNation.medicationDetails") {
    newLabel   = serviceHelper.getMedicationQuestions(name,medicalSurveys);
  
    // props.radioLabel = optionsNew;
    // props = {...props, radioLabel = optionsNew};
  }

  /** Set initial focus based on defaultValue */
  useEffect(() => {
    if (defaultValue) {
      onChange &&
        onChange({
          target: {
            name: name || id,
            value: defaultValue,
          },
        });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      setFocused(value != null);
    }
  }, [value]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleChange = (event) => {
    event.preventDefault();
    onChange && onChange(event);
    props.parentTextAreaAction && props.parentTextAreaAction(event, formik.values);
  };

  const handleBlur = (event) => {
    event.preventDefault();
    if (!props.value) setFocused(false);
    onBlur && onBlur(event);
  };
  let classes = disabled ? `elementDisabled ${className}` : className;
  let showElement = false;
  if (underWritingQuestionThree && name == 'PetAssureNation.dateAndReason') {
    showElement = true;
  }
  if (underWritingQuestionFour && name == 'PetAssureNation.medicationDetails') {
    showElement = true;
  }
  return (
    <>
      {!noFloatingLabel && (
        <div
          className={'floatingLabel pb-3 displayContent ' + classNameWrapper + additionalClasses}
          hidden={hidden}
        >
          <textarea
            id={id || name}
            name={name || id}
            onChange={handleChange}
            type={type}
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder={placeholder}
            value={value}
            rows={rows}
            required={required}
            disabled={disabled}
            // className={classes + className}
            className={
              (value && requiredValidation) || !requiredValidation
                ? 'w-100 ' + classes
                : 'w-100 required ' + classes
            }
          />
          {label && (
            <label className={focus ? 'isFocused' : ''} htmlFor={name || id}>
              {label}
            </label>
          )}
        </div>
      )}

      {noFloatingLabel && (
        <div
          className={
            shouldShowOnYes && !showElement
              ? 'hideElement '
              : 'pb-3 displayContent ' + classNameWrapper + additionalClasses
          }
          hidden={hidden}
        >
          {label && (
            <label className={focus ? 'isFocused' : ''} htmlFor={name || id}>
              {updateLabel ? newLabel :label}
              {requiredValidation && label && <span className="validation"> *</span>}
            </label>
          )}
          <textarea
            id={id || name}
            name={name || id}
            onChange={handleChange}
            type={type}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={value}
            placeholder={placeholder}
            rows={rows}
            required={required}
            disabled={disabled}
            // className={classes + className}
            className={
              (value && requiredValidation) || !requiredValidation
                ? 'w-100 ' + classes
                : 'w-100 required ' + classes
            }
          />
        </div>
      )}
    </>
  );
};
TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
};
TextArea.defaultProps = {
  value: '',
  hidden: false,
  className: '',
  classNameWrapper: '',
  type: 'text',
  required: false,
  defaultValue: null,
};

export default TextArea;