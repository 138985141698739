import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import Image from '../Image';


const ImageText = (props) => {
    const {
        badge = '',
        badgeStyle = {},
        bagdeClassName = '',
        badgeContainerClassName = '',
        badgeContainerStyle= {},
        containerClassName = '',
        containerStyle = {},
        fileName = '',
        imageClassName = '',
        imageStyle = {},
        label = '',
        name = '',
        subtitle = '',
        subtitleClassName = '',
        subtitleStyle = {},
        textClassName = '',
        textStyle = {},
        isListofEligibleMemberForHomePage=false,
        logoBase64='',
        contentType='',
        alt="Image"
    } = props;
    return (
        <>
            <Image
                filename={fileName}
                logoBase64={logoBase64}
                contentType={contentType}
                style={imageStyle}
                alt={alt}
                isListofEligibleMemberForHomePage={isListofEligibleMemberForHomePage}
            />
            {label && (
                <div className={containerClassName} style={containerStyle}>
                    <p className={textClassName} style={textStyle}>{label}</p>
                    {subtitle && (<p className={subtitleClassName} style={subtitleStyle}>{subtitle}</p>)}
                </div>
            )}
            {badge && (
                <div className={badgeContainerClassName} style={badgeContainerStyle}>
                    <span className={bagdeClassName} style={badgeStyle}>{badge}</span>
                </div>
            )}
        </>
    );
};

ImageText.propTypes = {
    badge: PropTypes.string,
    badgeStyle: PropTypes.object,
    bagdeClassName: PropTypes.string,
    badgeContainerClassName: PropTypes.string,
    badgeContainerStyle: PropTypes.object,
    containerClassName: PropTypes.string,
    containerStyle: PropTypes.object,
    fileName: PropTypes.string,
    imageClassName: PropTypes.string,
    imageStyle: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleClassName: PropTypes.string,
    subtitleStyle: PropTypes.object,
    textClassName: PropTypes.string,
    textStyle: PropTypes.object,
};

ImageText.defaultProps = {
    badge: '',
    badgeStyle: {},
    bagdeClassName: '',
    badgeContainerClassName: '',
    badgeContainerStyle: {},
    containerClassName: '',
    containerStyle: {},
    fileName: '',
    imageClassName: '',
    imageStyle: {},
    label: '',
    name: '',
    subtitle: '',
    subtitleClassName: '',
    subtitleStyle: {},
    textClassName: '',
    textStyle: {},
};

export default ImageText;
