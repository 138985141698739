import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`

.panel {
  box-shadow: -0.21875rem 0.21875rem 0.0625rem 0.00625rem rgba(51, 51, 51, 0.07);
  background-color: ${({ theme }) =>
    theme.molecules && theme.molecules.panel && theme.molecules.panel.panelBgColor};
    opacity:0.8;
    // width:45rem;
}

.panel .panelBody {
  font-family:NotoSansRegular
  color: #000000;
  font-size: 0.875rem;
}

.panel .line {
  margin: 1rem 0;
  border-bottom: 1px solid #d9d5cd;
  width: 90%;
}




`;
