import http from '../../../Common/Services/HttpService';
import * as actions from './Actions';
import { apiConstants } from '../../../Common/Constants';
import appConfig from '../../../appConfig';

const Api =
  ({ dispatch }) =>
    (next) =>
      async (action) => {
        if (action.type !== actions.apiCallInitiation.type) return next(action);
        const { url, method, data, onStart, onSuccess, onError } = action.payload;
        if (onStart) dispatch({ type: onStart });
        next(action);
        try {
          const response = await http.request({
            baseURL: appConfig.SSP_MID_BASE_URL,
            url: appConfig.SSP_MID_BASE_PATH + url,
            method,
            headers: apiConstants.HEADERS,
            data,
          });
          // General
          dispatch(actions.apiCallSuccess(response.data));
          // Specific
          if (onSuccess) {
            dispatch({
              type: onSuccess,
              payload: response.data,
            });
            return response
          }
        } catch (error) {
          // General
          dispatch(actions.apiCallFailure(error.message));
          // Specific
          if (onError) dispatch({ type: onError, payload: error.message });
        }
      };

export default Api;
