import React from 'react';
import CoverageFlowFour from '../Component/Flow1/coverageFlowFour';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationHook } from '../Hooks/NavigationHook';
const CoverageFlowFourWrapper = (props) => {
  const { coverageOption, coverageIndex } = props;
  const personBuid = props.employee?.[0]?.personBuid;
  const { currentStep } = useNavigationHook();
  const insuredInfoCurrentStep =  useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};
  // Null check before filtering subscribeCoverageBuids
  const filteredSubscribeCoverageBuids = insuredInfoCurrentStep.subscribeCoverageBuids?.filter(item => item.personBuid === personBuid) || [];
  //
  // Null check before assuming that we have at least one object after filtering
  const matchedCoverageBuidObj = filteredSubscribeCoverageBuids.length > 0 ? filteredSubscribeCoverageBuids[coverageIndex] : null;
 // Extract required details with null checks
 const listOfCoveragePlanDesignConfigurationResponse = matchedCoverageBuidObj?.listOfCoveragePlanDesignConfiguration?._Result || [];
 const subscribedCoverageConfigurationResponse = matchedCoverageBuidObj?.subscribedCoverageConfiguration?._Result || {};
 const beneficiaryConfigurationResponse = matchedCoverageBuidObj?.beneficiaryConfiguration?._Result?.[0] || {};
 const prepareSubscriptionForChoicesResponse = matchedCoverageBuidObj?.prepareSubscriptionForChoices?._Result || {};
 const coverageBuid = matchedCoverageBuidObj?.coverageBuid || "";
 
  const dependentsList = useSelector(
    (storeState) => storeState?.employeeProfile?.insuredsAsBeneficiaries
  );
  const filteredInsuredsAsBeneficiaries = dependentsList.filter(
    (beneficiary) => beneficiary.mainBuid !== personBuid
  );
  var beneficiaryObj;
  let updatedBeneficiaryList = [...filteredInsuredsAsBeneficiaries];
  let beneficiaryList = [];

  updatedBeneficiaryList.forEach((beneficiary) => {
    beneficiaryObj = { ...beneficiary };
    if (beneficiaryObj?.beneficiaryRelationship == '') {
      beneficiaryObj.beneficiaryRelationship = 'SP';
    }
    beneficiaryList = [...beneficiaryList, beneficiaryObj];
  });

  const beneficiaryEligibility = useSelector((storeState) => {
    const eligibilityData =
      storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
    const matchedEligibility = eligibilityData.filter(
      (eligibility) => eligibility?.coverageBuid === coverageBuid
    );
    return matchedEligibility[0]?.isBeneficiaryEligible || false;
  });

  const isBeneficiaryMandatory = useSelector((storeState) => {
    const eligibilityData =
      storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
    const matchedEligibility = eligibilityData.filter(
      (eligibility) => eligibility?.coverageBuid === coverageBuid
    );
    return matchedEligibility[0]?.isBeneficiaryMandatory || false;
  });

  const isBeneficiaryOptional = useSelector((storeState) => {
    const eligibilityData =
      storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
    const matchedEligibility = eligibilityData.filter(
      (eligibility) => eligibility?.coverageBuid === coverageBuid
    );
    return matchedEligibility[0]?.isBeneficiaryOptional || false;
  });
  const showPolicyReplacementQuestionFlag = useSelector((storeState) =>
    storeState?.insuredInfo?.[currentStep]?.ProductLevelEligiblity?.policyReplacementQuestionEnable ?? false
  );
  
  return (
    <div>
      <CoverageFlowFour


        {...props}
        displayMode={props.displayMode}
        onCoverageChange={props.handleCoverageChange}
        coverageDetails={listOfCoveragePlanDesignConfigurationResponse} 
        personBuid={personBuid} 
        allowedBeneficiaryKind = {beneficiaryConfigurationResponse} 
        coverageBuid = {coverageBuid} 
        beneficiaryEligibility = {beneficiaryEligibility}
        dependentsList = {beneficiaryList}
        subscribedCoverageConfigurationResponse = {subscribedCoverageConfigurationResponse}
        prepareSubscriptionForChoicesResponse = {prepareSubscriptionForChoicesResponse}
        isBeneficiaryMandatory={isBeneficiaryMandatory}
        isBeneficiaryOptional={isBeneficiaryOptional}
        coverageOption={coverageOption}
        coverageIndex= {coverageIndex}
        showPolicyReplacementQuestionFlag={showPolicyReplacementQuestionFlag}
      />
    </div>
  );
};

export default CoverageFlowFourWrapper;
