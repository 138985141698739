import React, { Suspense, useState, useEffect } from 'react';
import './Tabs.css'
import { useDispatch,useSelector } from 'react-redux';
import { SET_CALL_CENTER_USER, SET_REDIRECT_TO_ENROLLMENT, IS_HR_CENTER_RE_DIRECTED } from '../../../../../Store/Slices/CallCenter';
import { useHistory } from 'react-router-dom';

import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
const Tabs = (props) => {
    const tabs = [
        { name: 'home', label: 'HOME', url: '/hrcenter', exact: true },
        { name: 'employeeSearch', label: 'SEARCH AN EMPLOYEE ', url: '/hrcenter/employeeSearch', exact: false },
        { name: 'reports', label: 'VIEW REPORTS', url: '/hrcenter/reports', exact: false }
    ];
    const [resetActive, setResetActive] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { userAuth } = useSelector((storeState) => storeState?.auth);
    let userType = useSelector((storeState) => storeState.registeration?.userType);
    let callcenterHR = useSelector((storeState) => storeState.registeration?.callcenterHR);

    const handleNavLinkClick = () => {
        setResetActive(true);
    };

    const handleBackToCallCenterHome = () => {
        dispatch({
          type: 'SETINITIALSTATE'
        })
        dispatch({
          type: 'RESET'
        })
        dispatch(IS_HR_CENTER_RE_DIRECTED(false))
        history.push("/callcenter/home");
      };

    return (
        <>
           {callcenterHR && <div className="right-align-container pr-5">
                <button type="button" className="btn btn-link" onClick={handleBackToCallCenterHome}>{'CC Home'}</button>
            </div>}
            <div className="tabs">
                <Suspense fallback={<div>Loading...</div>}>
                    {tabs?.map(tab => (
                        <NavLink
                            key={tab.name}
                            to={tab.url}
                            exact={tab.exact}
                            activeClassName={resetActive ? '' : 'isactive'}
                            onClick={handleNavLinkClick}
                        >
                            {tab.label}
                        </NavLink>
                    ))}
                </Suspense>
            </div>
        </>
    );

};

export default Tabs