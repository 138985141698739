import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserIdSchema } from '../../Atoms/FormValidation'
import '../styles.css';
import { TextInput } from '../../Atoms';
import http from '../../../../../Common/Services/HttpService';
import appConfig from '../../../../../appConfig';
import { getBasicAuthHeaderValue } from '../../Helper/MFAHelper'
import { SET_MFA_BASIC_AUTH_VALUE, SAVE_EMPLOYEE_INFO } from '../../../../../Store/Slices/Registeration';
import Loader from '../../Atoms/Loader';
import { SHOW_LOADER } from '../../../../../Store/Slices/Loader/index';
import { errorMessages } from '../../Atoms/FormValidation'
import { Link } from 'react-router-dom';

const RetreiveUserIDContainer = ({ stepValues, onNext, ...props }) => {

  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.loader);
  const { registeration } = useSelector((storeState) => storeState);
  const [showMaskedInput, setShowMaskedInput] = useState(false);
  const [SSN, setSSN] = useState('');
  const [err, setErr] = useState('');
  const { legalEntityId = '', appCode = '' } = useSelector((storeState) => storeState?.registeration);
  const maxDateOfBirth = new Date().toISOString().split("T")[0];
  const empInfoConfig = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.register?._Result?.data?.[0]
  );

  const empConfigData = {
    "employeeIDToggle": empInfoConfig?.employeeId?.switchField || false,
    "employeeIDeditableLabelField": empInfoConfig?.employeeId?.editableLabelField || false,
    "employeeIDeditableLabelPlacerholderValue": empInfoConfig?.employeeId?.editableLabelPlacerholderValue || false,
    "birthDateToggle": empInfoConfig?.birthDate?.hideableField || false,
  }
  let employeeIDLabel = empConfigData.employeeIDeditableLabelField ? empConfigData.employeeIDeditableLabelPlacerholderValue : null;

  const formik = useFormik({
    initialValues: stepValues || {
      firstName: '',
      lastName: '',
      employeeId: '',
      dateofBirth: '',
      ssn: ''
    },
    validationSchema: resetUserIdSchema(empConfigData),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      handleRetrieveUserID({ fname: values.firstName, lname: values.lastName, dateofBirth: values.dateofBirth, employeeId: values.employeeId, ssn: SSN });
    },
  });

  const isAllFieldsFilled = () => {
    const requiredFields = ['firstName', 'lastName', 'dateofBirth'];

    if (empConfigData.employeeIDToggle) {
      requiredFields.push('ssn');
    } else {
      requiredFields.push('employeeId');
    }

    return requiredFields.every(field => !!formik.values[field]);
  };

  const handleSSNChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    setSSN(value)
    const formattedValue = value
      .slice(0, 9)
      .replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
        return [p1, p2, p3].filter((part) => part.length > 0).join('-');
      });
    formik.setFieldValue('ssn', formattedValue);
  };

  const handleSSNBlur = (e) => {
    if (formik.values.ssn.replace(/-/g, '').length === 9 && !formik.errors.ssn) {
      setShowMaskedInput(true);
    } else {
      setShowMaskedInput(false);
    }
    formik.handleBlur(e);
  };

  const clearErrMsg = () => {
    setErr('');
  }


  const handleRetrieveUserID = async (data) => {

    dispatch(SHOW_LOADER(true));
    let basicAuthorizationValue = getBasicAuthHeaderValue();
    basicAuthorizationValue && dispatch(SET_MFA_BASIC_AUTH_VALUE(basicAuthorizationValue));

    let employeeData = {};
    employeeData.firstName = data.fname ? data.fname?.trim() : '';
    employeeData.lastName = data.lname ? data.lname?.trim() : '';
    employeeData.birthDate = data.dateofBirth ? data.dateofBirth?.trim() : '';
    employeeData.employeeId = empConfigData.employeeIDToggle ? (data.ssn ? data.ssn?.trim() : '') : (data.employeeId ? data.employeeId?.trim() : '');
    employeeData.legalEntityId = registeration.legalEntityId

    const reqObj = {
      "employeeData": employeeData,
      "appCode": registeration.appCode,
      "channel": "sms"
    }
    dispatch(SAVE_EMPLOYEE_INFO(employeeData));

    http
      .post(
        appConfig.SSP_AUTH_URL + "/stateful/user-mgmt/retrieve-id-ex/1",
        reqObj,
        {
          withCredentials: true,
          headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200),
          }
        }
      )
      .then((resp) => {
        if (resp.status === 200) {

          onNext(formik.values);
        } else {
          setErr(errorMessages(resp));
        }
        dispatch(SHOW_LOADER(false));
      }).catch((error) => {
        setErr(errorMessages(error));
        dispatch(SHOW_LOADER(false));
      });
  }

  const ErrorMessage = (field) => {
    return (
      <>
        {formik.touched[field] && formik.errors[field] ? (
          <div class="form-mandatory">{formik.errors[field]}</div>
        ) : null}
      </>
    )
  }

  return (
    <>
      {loader && <Loader loading={loader} />}
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1 className="form-side-header">Retrieve User ID</h1>
              <p className="form-mandatory mb-0">
                Fields marked with * are mandatory
              </p>
              <p>Please enter the following information and continue.</p>
            </div>
          </div>
          <div className="row py-2">
            <div className="col-sm-12 col-md-6">
              <TextInput
                label={"First Name"}
                required={true}
                id="firstName"
                name="firstName"
                placeholder="Enter First Name"
                className="w-100 inputField p-3"
                onInput={clearErrMsg}
                {...formik.getFieldProps('firstName')}
              />
              {ErrorMessage('firstName')}
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                label={"Last Name"}
                required={true}
                id="lastName"
                name="lastName"
                placeholder="Enter Last Name"
                className="w-100 inputField p-3"
                onInput={clearErrMsg}
                {...formik.getFieldProps('lastName')}
              />
              {ErrorMessage('lastName')}
            </div>
          </div>

          <div className="row py-2">
            <div className="col-sm-12 col-md-6 py-2">
              {!empConfigData.employeeIDToggle && <TextInput
                label={employeeIDLabel ? employeeIDLabel : "Employee ID"}
                required={true}
                id="employeeId"
                name="employeeId"
                placeholder={employeeIDLabel ? employeeIDLabel : "Enter Employee ID"}
                className="w-100 inputField"
                onInput={clearErrMsg}
                maxLength={14}
                {...formik.getFieldProps('employeeId')}
              />}
              {ErrorMessage('employeeId')}
              {empConfigData.employeeIDToggle && <TextInput
                label={employeeIDLabel ? employeeIDLabel : "SSN"}
                required={true}
                id="ssn"
                name="ssn"
                placeholder="Enter SSN"
                className="w-100 inputField"
                maxLength={11}
                value={showMaskedInput ? `XXX-XX-${formik?.values?.ssn?.slice(-4)}` : formik.values.ssn}
                onFocus={() => setShowMaskedInput(false)}
                onChange={handleSSNChange}
                onInput={clearErrMsg}
                onBlur={handleSSNBlur}
              /*  {...formik.getFieldProps('ssn')} */
              />}

              {ErrorMessage('ssn')}
            </div>
            {!empConfigData.birthDateToggle &&
              <div className="col-sm-12 col-md-6 py-2">
                <TextInput
                  label={"Date of Birth"}
                  required={true}
                  type='date'
                  id="dateofBirth"
                  name="dateofBirth"
                  max={maxDateOfBirth}
                  placeholder=""
                  className="w-100 inputField"
                  onInput={clearErrMsg}
                  {...formik.getFieldProps('dateofBirth')}
                />
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar-alt" class="svg-inline--fa fa-calendar-alt fa-w-14 calendarIcon retrieveID" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg>
                {ErrorMessage('dateofBirth')}
              </div>}
          </div>
          {err &&
            <div className="row">
              <span className='APIErr'>{err}</span>
            </div>
          }
          <div className="row py-2 justify-content-between mfaBtnWrapper">
            <div className="col-sm-12 col-md-4 py-2">
            <Link role="link" to={`/enrollment?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Back to login</Link>
            </div>
            <div className="col-sm-12 col-md-6 py-2 text-md-right">
              <button
                name="registerationBtn"
                type="submit"
                className="btn btn-primary primaryBackground"
                disabled={!isAllFieldsFilled() || !formik.isValid}
                role="button"
              >
                Continue
              </button>
            </div>
          </div>

        </div>
      </form>
    </>
  )

}
export default RetreiveUserIDContainer;
