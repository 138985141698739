import { createSlice } from '@reduxjs/toolkit';
import appConfig from '../../../appConfig';
import { apiCallInitiation } from '../../Middleware/Api/Actions';
import { apiConstants } from '../../../Common/Constants';
import defaultTheme from '../../../Theming/Themes/defaultTheme';

const slice = createSlice({
  name: 'theme',
  initialState: {
    selectedThemeData: defaultTheme,
    selectedThemeDataToUpdate: {},
    currentTheme: appConfig.theme.currentTheme,
    configuredTheme: appConfig.theme.currentTheme,
    availableThemes: [],
  },

  reducers: {
    // action => action handler
    apiRequested: (theme, action) => {
      theme.loading = true;
    },

    apiReceivedForThemeData: (theme, action) => {
      let payload = action.payload;
      theme.selectedThemeData = { ...theme.data, ...payload };
      // theme.availableThemes = { ...theme.availableThemes, ...payload };
      theme.loading = false;
      theme.lastFetch = Date.now();
    },

    apiReceivedForThemeNames: (theme, action) => {
      let payload = action.payload._Result;
      // theme.selectedThemeData = { ...theme.selectedThemeData, ...payload };
      theme.availableThemes = { ...theme.data, ...payload };
      theme.loading = false;
      theme.lastFetch = Date.now();
    },

    apiReceivedForUpdateTheme: (theme, action) => {
      let payload = action.payload._Result;
      // theme.selectedThemeData = { ...theme.selectedThemeData, ...payload };
      theme.selectedThemeDataToUpdate = { ...theme.data, ...payload };
      theme.loading = false;
      theme.lastFetch = Date.now();
    },

    apiReceivedForConfigureTheme: (theme, action) => {
      let payload = action.payload;
      // theme.selectedThemeData = { ...theme.selectedThemeData, ...payload };
      theme.configuredTheme = payload;
      theme.loading = false;
      theme.lastFetch = Date.now();
    },

    apiRequestFailed: (theme, action) => {
      theme.loading = false;
    },

    CHANGE_THEME: (theme, action) => {
      return { ...theme, currentTheme: action.payload };
    },
    UPDATE_THEME: (theme, action) => {
      return { ...theme, updateTheme: action.payload };
    },
    CONFIGURE_THEME: (theme, action) => {
      return { ...theme, configureTheme: action.payload };
    },
  },
});


export const {
  apiReceivedForThemeData,
  apiReceivedForThemeNames,
  apiReceivedForUpdateTheme,
  apiReceivedForConfigureTheme,
  apiRequested,
  apiRequestFailed,
  CHANGE_THEME,
  UPDATE_THEME,
  CONFIGURE_THEME,
} = slice.actions;

export default slice.reducer;
