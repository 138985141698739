import ServiceHelper from '../../../Utils/Helper/MappingHelper';
import { SUBSCRIBE_COVERAGE_BUIDS, UPDATE_LOADING, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { UPDATE_COVERAGE_INFO } from '../../../../Store/Slices/Entities/response';

const serviceHelper = new ServiceHelper();
export const getListOfCoveragePlanDesignConfigurationNew = (currentStep, enrollmentProcessBuid, coverageBuid, personBuid) => async dispatch => {
    if ( !currentStep || !enrollmentProcessBuid || !coverageBuid || !personBuid) {
        console.error("getListOfCoveragePlanDesignConfigurationNew called with missing parameters");
        return;
    }
    dispatch(UPDATE_LOADING(true));
    try{
    let resp = await serviceHelper.sendApiRequest('/getListOfCoveragePlanDesignConfigurationNew', {
        enrollmentProcessBuid: enrollmentProcessBuid,
        coverageBuid: coverageBuid
    });    
    if (resp && resp.data && resp.status === 200) {
        await dispatch(SUBSCRIBE_COVERAGE_BUIDS({
            coverageBuid: coverageBuid,
            personBuid: personBuid,
            product: currentStep,
        }));
        await dispatch(
            UPDATE_COVERAGE_INFO({
                coverageBuid: coverageBuid,
                personBuid: personBuid,
                product: currentStep,
                listOfCoveragePlanDesignConfiguration: resp.data
            })
        );
    } else {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: resp?.status || '520',
            id: 'getListOfCoveragePlanDesignConfigurationNew',
            show: false,
            page: currentStep,
          }));
        console.error("getListOfCoveragePlanDesignConfigurationNew received unexpected response", resp);
    }
}
catch (e){
    console.error(e)
} finally {
    dispatch(UPDATE_LOADING(false));
}
}
