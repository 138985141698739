import React from 'react';

import EmployeeCoverage from '../Component/Flow2/employeeCoverage';

import { useDispatch, useSelector } from 'react-redux';
import { EmployeeCoverageBuyUp } from '../Component/BuyUp/employeeCoverageBuyUp';
import { useNavigationHook } from '../Hooks/NavigationHook';
const EmployeeCoverageWrapper = (props) => {
  const { employeeIndex, handleCoverageChange    } = props;
  const { currentStep,previousStep,formsConfig  } = useNavigationHook();
  const personBuid = props.employee[employeeIndex]?.personBuid;


  const insuredInfoCurrentStep =  useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};

  let isEmployeeBuyUpEnabled = false;
  isEmployeeBuyUpEnabled = useSelector((storeState) => storeState.buyUp.eligibility.employee);
  let basePlanBoughtCurrentYear = true;
  basePlanBoughtCurrentYear = useSelector((storeState) => storeState.buyUp.basePlanBoughtCurrentYearFlag.employee);
  let productFamily =formsConfig?.forms?.[currentStep]?.productFamily
  let isBuyUpFlow = false;
  let buyUpDeclinedLocally = useSelector((storeState) => storeState.buyUp.basePlanDeclinedLocally.employee) || false
  if(buyUpDeclinedLocally) {
    isBuyUpFlow = true
  } else {
    isBuyUpFlow = isEmployeeBuyUpEnabled ? (basePlanBoughtCurrentYear ? false : true ) : false;
  }
  let costPerPayPeriodArr = [];
  costPerPayPeriodArr = useSelector((storeState) => storeState.buyUp.payPerPeriodCost);


  // Null check before filtering subscribeCoverageBuids
  let filteredSubscribeCoverageBuids = [],
      listOfCoveragePlanDesignConfigurationResponse = [],
      matchedCoverageBuidObj = null;

  // if(isEmployeeBuyUpEnabled) {
  //   filteredSubscribeCoverageBuidsBuyUp = insuredInfoCurrentStep.subscribeCoverageBuidsForBuyUp?.filter(item => item.personBuid === personBuid) || [];
  //   filteredSubscribeCoverageBuids = insuredInfoCurrentStep.subscribeCoverageBuids?.filter(item => item.personBuid === personBuid) || [];

  //   matchedCoverageBuidObj = filteredSubscribeCoverageBuids.length > 0 ? filteredSubscribeCoverageBuids[0] : null;
  //   matchedCoverageBuidObjBuyUp = filteredSubscribeCoverageBuidsBuyUp.length > 0 ? filteredSubscribeCoverageBuidsBuyUp[0] : null;

  //   listOfCoveragePlanDesignConfigurationResponse = matchedCoverageBuidObj?.listOfCoveragePlanDesignConfiguration?._Result || [];
  //   listOfCoveragePlanDesignConfigurationResponseBuyUp= matchedCoverageBuidObjBuyUp?.listOfCoveragePlanDesignConfiguration?._Result || [];
  // } else {
    filteredSubscribeCoverageBuids = insuredInfoCurrentStep.subscribeCoverageBuids?.filter(item => item.personBuid === personBuid) || [];
    matchedCoverageBuidObj = filteredSubscribeCoverageBuids.length > 0 ? filteredSubscribeCoverageBuids[0] : null;
    listOfCoveragePlanDesignConfigurationResponse = matchedCoverageBuidObj?.listOfCoveragePlanDesignConfiguration?._Result || [];
  // }

  // Null check before assuming that we have at least one object after filtering
  

  // Extract required details with null checks
  const subscribedCoverageConfigurationResponse = matchedCoverageBuidObj?.subscribedCoverageConfiguration?._Result || {};
  const beneficiaryConfigurationResponse = matchedCoverageBuidObj?.beneficiaryConfiguration?._Result?.[0] || {};
  const prepareSubscriptionForChoicesResponse = matchedCoverageBuidObj?.prepareSubscriptionForChoices?._Result || {};
  const coverageBuid = matchedCoverageBuidObj?.coverageBuid || "";

  const dependentsList = useSelector(
    (storeState) => storeState?.employeeProfile?.insuredsAsBeneficiaries,
  );
  const filteredInsuredsAsBeneficiaries = dependentsList.filter(
    beneficiary => beneficiary.mainBuid !== personBuid
  );

const beneficiaryEligibility = useSelector((storeState) => {
    const eligibilityData = storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
    const matchedEligibility = eligibilityData.filter(eligibility => eligibility?.coverageBuid === coverageBuid);
    return matchedEligibility[0]?.isBeneficiaryEligible || false;
  });

const isBeneficiaryMandatory = useSelector((storeState) => {  
  const eligibilityData = storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
  const matchedEligibility = eligibilityData.filter(eligibility => eligibility?.coverageBuid === coverageBuid);
  return matchedEligibility[0]?.isBeneficiaryMandatory || false;
});

const isBeneficiaryOptional = useSelector((storeState) => {  
  const eligibilityData = storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility || [];
  const matchedEligibility = eligibilityData.filter(eligibility => eligibility?.coverageBuid === coverageBuid);
  return matchedEligibility[0]?.isBeneficiaryOptional || false;
});


const showPolicyReplacementQuestionFlag = useSelector((storeState) =>
  storeState?.insuredInfo?.[currentStep]?.ProductLevelEligiblity?.policyReplacementQuestionEnable ?? false
);

  return (
    <div>
      {isBuyUpFlow ? 
        <EmployeeCoverageBuyUp
          flowType={props.flowType}
          showDisclosureStatement={props.showDisclosureStatement}
          spouse={props.spouse}
          children={props.children}
          personBuid={personBuid}
          customContent={props.customContent}
          displayMode={props.displayMode}
          nextStep={props.nextStep}
          prevStep={props.prevStep}
          onNext={props.onNext}
          dependentsList={filteredInsuredsAsBeneficiaries}
          allowedBeneficiaryKind={beneficiaryConfigurationResponse}
          productFamily={productFamily}
          beneficiaryEligibility={beneficiaryEligibility}
          isBeneficiaryMandatory={isBeneficiaryMandatory}
          isBeneficiaryOptional={isBeneficiaryOptional}
        />
      : <EmployeeCoverage
          {...props}
          onCoverageChange={props.handleCoverageChange}
          coverageDetails={listOfCoveragePlanDesignConfigurationResponse}
          personBuid={personBuid}
          allowedBeneficiaryKind={beneficiaryConfigurationResponse}
          coverageBuid={coverageBuid}
          beneficiaryEligibility={beneficiaryEligibility}
          showPolicyReplacementQuestionFlag={showPolicyReplacementQuestionFlag}
          dependentsList={filteredInsuredsAsBeneficiaries}
          subscribedCoverageConfigurationResponse={subscribedCoverageConfigurationResponse}
          prepareSubscriptionForChoicesResponse={prepareSubscriptionForChoicesResponse}
          isBeneficiaryMandatory={isBeneficiaryMandatory}
          isBeneficiaryOptional={isBeneficiaryOptional}

        />
      }
    </div>


  );
};

export default EmployeeCoverageWrapper;
