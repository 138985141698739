import CryptoJS from 'crypto-js';
import pako from 'pako';
import { Buffer } from 'buffer';

export const CipherUtils = {
  hash(password) {
    const hash = CryptoJS.SHA256(password);
    return CryptoJS.enc.Base64.stringify(hash);
  },

  encrypt(bytes, secretKey) {
    // Convert the secretKey to a WordArray for CryptoJS
    const keyWordArray = CryptoJS.enc.Utf8.parse(secretKey);

    // Generate a random IV (16 bytes for AES-128-CBC)
    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    // Encrypt the data with the given secretKey and IV
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(bytes), keyWordArray, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    // Concatenate IV with the encrypted data
    const combined = CryptoJS.lib.WordArray.create(iv.words.concat(encrypted.ciphertext.words));

    // Return the concatenated array encoded in base64
    return CryptoJS.enc.Base64.stringify(combined);
  },
  
  decrypt(encoded, secretKey) {
    // Convert the secretKey to a WordArray for CryptoJS
    const keyWordArray = CryptoJS.enc.Utf8.parse(secretKey);

    // Decode the base64 encoded string
    const decodedBytes = CryptoJS.enc.Base64.parse(encoded);

    // Extract the IV (first 16 bytes for AES-128-CBC) and ciphertext from the decoded bytes
    const iv = CryptoJS.lib.WordArray.create(decodedBytes.words.slice(0, 4)); // IV is 4 words (16 bytes)
    const ciphertext = CryptoJS.lib.WordArray.create(decodedBytes.words.slice(4)); // Rest is ciphertext

    // Decrypt the data with the given secretKey and IV
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, keyWordArray, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    // Return the decrypted data as a UTF-8 string
    
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
};



export const GetUrlParams = () => {
  const authResult = new URLSearchParams(window.location.search);
  const processType = authResult.get('processType');
  const userFromUrl = authResult.get('email');
  const SSOloginError = authResult.get('error');
  const clientId = authResult.get('clientId') || '';
  const userPrefix = authResult.get('entrytype') || '';
  let paramObject = {
    processType: processType,
    userFromUrl: userFromUrl,
    SSOloginError: SSOloginError,
    clientId: clientId,
    userPrefix: userPrefix
  };
  return paramObject;
};

export const decryptResponse = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_SSO_PRIVATE_KEY);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData
};
const compressResqest = (data) => {

  // Convert JSON object to a string
  const jsonString = JSON.stringify(data);
  
  // Compress JSON string
  const compressed = pako.deflate(jsonString);
  
  // To handle binary data, we can convert the compressed data to Base64
  const compressedBase64 = Buffer.from(compressed).toString('base64');

  return CryptoJS.AES.encrypt(compressedBase64, process.env.REACT_APP_SSO_PRIVATE_KEY).toString()
}

export const decompressResponse = (responseData) => {
  const bytes = CryptoJS.AES.decrypt(responseData, process.env.REACT_APP_SSO_PRIVATE_KEY);
  const compressed = bytes.toString(CryptoJS.enc.Utf8);
  const decompressed = pako.inflate(Buffer.from(compressed, 'base64'), { to: 'string' });

  return JSON.parse(decompressed)
}

function checkSecretKey(secretKey) {
  if (secretKey.length !== 16) throw new Error(`invalid key size: "${secretKey}"`);
}