import React, { useStat, memo } from 'react';
import Profile from "../Component/Profile/Profile.js"

import Loader from '../../../Atoms/Loader';
import {  useSelector } from 'react-redux';
const ProfileManagement = memo((props) => {

  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  const { enrollmentType } = useSelector((storeState) => storeState.enrollment);
  return (
    <div>
       {
      isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />      
      } 
       <Profile {...props} showSideNavigation={enrollmentType !== 'openEnrollment'}></Profile>
    </div>
  );
});
export default ProfileManagement;
