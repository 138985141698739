import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.footer {
  min-height:6rem; 
  box-shadow: 0rem -1rem 0rem var(--mercer-gray-300-dadada-);
  background: #FAFAFA ;
  box-shadow: 0rem -0.062rem 0rem #DADADA;
  background-color:  #FAFAFA
  opacity: 1;
  background-color:  ${({ theme }) =>
  theme.components && theme.components.footer && theme.components.footer.footerBgColor};
  background-color:  #FAFAFA
}

.footer .clientLogo  {
  cursor: pointer;
  min-width: 6rem;
  min-height: 1rem;
  vertical-align: top;
}

.footer .list-items  {
  &:after{
    content: '|'; 
    background: var(--mercer-gray-400-b3b3b3-) 0% 0% no-repeat padding-box;
  }
  vertical-align: sub;
  color: #002C77;
  text-align: left;
  letter-spacing: 0rem;
  opacity: 1;
  font-style: normal;
  font-weight: normal;
  font-size:0.875rem;
  font-family:  NotoSansRegular; 
  color: ${({ theme }) =>
  theme.components && theme.components.footer && theme.components.footer.footerLinkFontColor};
}

.footer .list-items-last  {
  vertical-align: sub;
  color: #002C77;
  text-align: left;
  letter-spacing: 0rem;
  opacity: 1;
  font-style: normal;
  font-weight: normal;
  font-size:0.875rem;
  font-family:  NotoSansRegular; 
  color: ${({ theme }) =>
  theme.components && theme.components.footer && theme.components.footer.footerLinkFontColor};
}

.copyright {
  background-color:  #FAFAFA
  text-align: right;
  letter-spacing: 0rem;
  color: #4F4F4F;
  opacity: 1;
  font-style: normal;
  font-weight: normal;
  font-size:0.8rem;
  line-spacing: 1rem;
  font-family:  NotoSansRegular;
  background-color:${({ theme }) =>
  theme.components && theme.components.footer && theme.components.footer.footerBgColor};
  color:${({ theme }) =>
  theme.components &&
  theme.components.footer &&
  theme.components.footer.footerCopyrightFontColor};
}
.span {
  color: #002C77; 
  cursor: pointer;
}

.span:hover {
  color: #444444; 
  text-decoration: underline;
}

.footerClass .container-fluid{
  padding-right: 6rem!important;
}

@media only screen and (max-width: 767px) { 
  .footer .clientLogo  {
    vertical-align: middle;
  }
}

/* Extra small devices () */
 @media only screen and (max-width: 576px) {
  .copyright {
    font-size: 0.5rem;
    padding-top: 0.55rem;
  }
  .divLinks {
    justify-content: center!important;
  }
  .sec-row {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
 }

 /* Tablet devices (768px and up) */
 @media only screen and (min-width: 768px) {
  .copyright {
    font-size: 0.75rem;
  }
  .divLinks {
    justify-content: end!important;
  }
  .sec-row {
    padding-top: 1.5rem;
  }
 }

 /* Desktop devices ( 1366px and up) */
 @media only screen and (min-width: 1366px) {
  .copyright {
    font-size: 0.75rem;
  }
  .divLinks {
    justify-content: end!important;
  }
  .sec-row {
    padding-top: 1.25rem;
  }
 }
`;
