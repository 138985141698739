import { isString } from "lodash";
import toast from "react-hot-toast";
import appConfig from "../../../appConfig";
import constants from '../../Molecules/CustomSection/Content/Constants.json';
import HttpService from "../../../Common/Services/HttpService";
import { onLoadAPI } from "../../../Store/Slices/Entities/response";
import { CommonConstants } from '../../../Common/Constants/CommonConstants';
import { CLEARED_COVERAGE_WAIVE_INFO } from "../../../Store/Slices/InsuredInfo";
import { SET_STEP_CONTENT, SET_STEP_SUB_CONTENT } from "../../../Store/Slices/StepFormNew";
import { reformatAmountToDisplayValueExcessLiability,formatDisplayValueForAmount } from "../Helper/ValidationHelper";

const getPrefix = (hasMultipleCoverages, relationship) => {
    return hasMultipleCoverages && getRelationship(relationship);
}

const getPlanDesign = (stepKey, info, hasMultipleCoverages, relationship, coverage) => {
    if (info) {
        switch (stepKey) {
            case 'Travel':
            case 'PetAssure':
            case 'PetPlus':
            case 'HospitalIndemnity':
            case 'LegalCoverage':
            case 'IdTheft':
            case 'SupplementalHealthInsurance':
            case 'CoverageSelection': {
                const { coverageLevel, planType, typeOfCoverage } = info;
                return { title: coverageLevel, prefix: getPrefix(hasMultipleCoverages, relationship), info: `${planType} ${typeOfCoverage}` };
            }
            case 'ExcessLiability': {
                const coverageName = coverage?.perpareSubscriptionForChoices?._Result?.[0]?.subscribedOption?.optionName || '';
                const covName = coverageName.includes('Chubb Personal Excess Liability Insurance') ? "EX. Liab" : coverageName.includes('Uninsured and Underinsured Limits') ? "UM/UIM" : '';

                const { coverageLevel, coverageAmount = '' } = info;
                const amount = isString(coverageLevel) ? coverageLevel : coverageAmount;

                const title = `${covName ? '(' + covName + ')' : ''} ${reformatAmountToDisplayValueExcessLiability({ amount })}`;
                return { title };
            }

            case 'UniversalLife':
            case 'CriticalIllness': {
                const { coverageLevel, coverageAmount = '' } = info;
                const title = isString(coverageLevel) ? coverageLevel : formatDisplayValueForAmount(coverageLevel?.amount);
                const amount = title === coverageLevel ? coverageAmount.split('||').pop() : '';
                return { title, prefix: getPrefix(hasMultipleCoverages, relationship), info: amount };
            }
            default: {
                return null;
            };
        }
    } else {
        return null;
    }
}

export const updateStepperNavigatorInfo = (step, dispatch, additionalInfo) => {
    const content = [];

    switch (step) {
        case 'EmployeeProfile': {
            const empInfo = additionalInfo.employeeInformation
            if (empInfo && empInfo.firstName && empInfo.lastName) {
                content.push({
                    displayName: `${empInfo.firstName} ${empInfo.lastName}`,
                });
                dispatch(SET_STEP_CONTENT({ step, content }));
            }
            break;
        }

        case 'ProductSelection': {
            if (additionalInfo.selectedProductList) {
                const products = additionalInfo.selectedProductList.map((product) => {
                    const buId = product.bUID
                    let displayName = '';
                    if (buId === CommonConstants.VOYA_WL) {
                        displayName = additionalInfo.gbpResources?.find(item => item.code === 'PRODTITLWL')?.content;
                    } else {
                        const detailedProduct = additionalInfo.productList.find(item => item.memberOfferedProduct.bUID === buId);
                        displayName = detailedProduct?.resources?.find(item => item.code === 'PRODTITLE')?.content;
                    }
                    return { displayName };
                });
                dispatch(SET_STEP_CONTENT({ step, content: products }));
            }
            break;
        }

        case 'DependentInformation': {
            const { dependentsTable, forms, stepKeys, productList, selectedProductList } = additionalInfo;
            if (dependentsTable) {

                const dependents = dependentsTable.map((row) => {
                    const relationship = constants.dependentRelationship.find(item => item.value === row.dependentRelationship)?.label || '';
                    return { displayName: `${row.dependentFirstName} ${row.dependentLastName} (${relationship})` };
                });
                dispatch(SET_STEP_CONTENT({ step, content: dependents }));
            }

            if (forms && stepKeys) {
                const products = stepKeys.reduce((res, key) => {
                    const product = forms[key]?.productFamily && productList?.find(p => p?.details?.product?.productFamily === forms[key].productFamily);
                    const isSelected = selectedProductList?.some(item => item => item.bUID === product?.memberOfferedProduct?.bUID);
                    if (isSelected) {
                        if (product) {
                            const resource = product?.resources?.find(item => item.code === 'PRODTITLE');
                            res.push({
                                stepKey: key,
                                clickable: true,
                                product,
                                productFamily: forms[key].productFamily,
                                displayName: resource?.content,
                            });
                        } else if (key === 'WLCoverage') {
                            res.push({
                                stepKey: key,
                                clickable: true,
                                product: {},
                                productFamily: forms[key]?.productFamily,
                                displayName: additionalInfo.gbpResources?.find(item => item.code === 'PRODTITLWL')?.content,
                            });
                        }
                    }
                    return res;
                }, []);
                dispatch(SET_STEP_CONTENT({ step: 'ProductDetailsSelection', content: products }));
                dispatch(CLEARED_COVERAGE_WAIVE_INFO());
            }
            break;
        }

        case 'EnrollmentSummary': {
            break;
        }

        case 'WLCoverage': {
            const stepDetails = [{ title: additionalInfo.isYesradioVoyaPremier ? 'Elected' : 'Waived' }];
            dispatch(SET_STEP_SUB_CONTENT({ step: 'ProductDetailsSelection', stepKey: step, stepDetails }))
            break;
        }

        case 'PetPlus': {
            const stepDetails = [];

            const { petList = [], WaiveCoverage = [], disClosureRadio } = additionalInfo || {};
            if (disClosureRadio === 'No' || WaiveCoverage?.some(item => item.waiveProduct)) {
                stepDetails.push({ title: 'Waived' })
            }
            else if (Array.isArray(petList)) {
                petList.forEach(item => {
                    stepDetails.push({ title: `${item.petName} (${item.petType})`, info: item.waivedCoverage ? 'Waived' : item.coverageLevel });
                });
            }
            dispatch(SET_STEP_SUB_CONTENT({ step: 'ProductDetailsSelection', stepKey: step, stepDetails }))
            break;
        }

        case 'PetAssure': {
            const stepDetails = [];

            const { petList = [], WaiveCoverage = [], disClosureRadio } = additionalInfo || {};
            if (disClosureRadio === 'No' || WaiveCoverage?.some(item => item.waiveProduct)) {
                stepDetails.push({ title: 'Waived' })
            } else if (Array.isArray(petList)) {
                petList.forEach(item => {
                    stepDetails.push({ title: `${item.petName} (${item.petType})`, info: item.waivedCoverage ? 'Waived' : item.coverageLevel });
                });
            }
            dispatch(SET_STEP_SUB_CONTENT({ step: 'ProductDetailsSelection', stepKey: step, stepDetails }))
            break;
        }

        case 'PetAssureNation': {
            const stepDetails = [];

            const { petList = [], WaiveCoverage = [], disClosureRadio } = additionalInfo || {};
            if (disClosureRadio === 'No' || WaiveCoverage?.some(item => item.waiveProduct)) {
                stepDetails.push({ title: 'Waived' })
            } else if (Array.isArray(petList)) {
                petList.forEach(item => {
                    stepDetails.push({ title: `${item.petName} (${item.petType})`, info: item.waivedCoverage ? 'Waived' : item.coverageLevel });
                });
            }
            dispatch(SET_STEP_SUB_CONTENT({ step: 'ProductDetailsSelection', stepKey: step, stepDetails }))
            break;
        }

        case 'ProductDetailsSelection': {
            const insuredInfo = additionalInfo?.insuredInfo?.[additionalInfo?.stepKey];
            const stepDetails = []
            const productWaived = insuredInfo?.WaiveCoverage?.[0]?.waiveProduct;
            const coverageLevelWaive = insuredInfo.coverageDetails?.length && insuredInfo.coverageDetails?.every(item => item.waivedCoverage)
            if (insuredInfo?.disClosureRadio === 'No' || (productWaived && additionalInfo?.stepKey === 'CriticalIllness') || (coverageLevelWaive) || productWaived) {
                stepDetails.push({ title: 'Waived' });
                dispatch(SET_STEP_SUB_CONTENT({ step, stepKey: additionalInfo?.stepKey, stepDetails }))
            } else if (insuredInfo?.coverageDetails?.length) {
                insuredInfo.coverageDetails.forEach(item => {
                    let planDesign;
                    if (item.waivedCoverage) {
                        planDesign = { title: 'Waived', prefix: additionalInfo?.stepKey === 'ExcessLiability' ? '' : getPrefix(additionalInfo?.hasMultipleCoverages, item.relationship) };
                    } else {
                        const subscribeCoverageBuids = additionalInfo?.subscribeCoverageBuids || [];
                        const coverage = subscribeCoverageBuids?.find(sc => (item.coverageBuid === sc.coverageBuid));
                        const info = coverage?.listOfCoveragePlanDesignConfiguration?._Result?.find(pd => pd.code === item.selectedValue);
                        planDesign = getPlanDesign(additionalInfo?.stepKey, info, additionalInfo?.hasMultipleCoverages, item.relationship, coverage);
                    }
                    if (planDesign) stepDetails.push(planDesign)
                })
                dispatch(SET_STEP_SUB_CONTENT({ step, stepKey: additionalInfo?.stepKey, stepDetails }))
            }
            break;
        }
    }

}

export const submitEnrollment = async (dispatch, store) => {
    try {
        const resp = await HttpService.post(appConfig.SSP_MID_URL + '/finalEnrollmentProcess', {
            enrollmentProcessBuid: store?.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID
        });

        if (resp && resp.data && resp.data._Result) {
            const result = resp.data._Result;

            dispatch(onLoadAPI({
                finalEnrollmentProcess: result,
                isAPICallInProgress: false,
            }));

            return result;
        } else {
            toast.error('Enrollment failed');
            return null;
        }
    } catch (error) {
        toast.error('Enrollment failed - Invalid response');
        return null;
    }
}

export const filterSelectedSteps = (forms, selectedProducts, productList, insuredInfo) => {
    let filteredKeys = Object.keys(forms).filter((key) => {
        const obj = forms[key];
        if (!obj?.productFamily) {
            return true; // Filter key if productFamily property is not present
        } 
        // else if (key === 'WLCoverage') {
        //     return !!insuredInfo[key];
        // }
        return selectedProducts.some((p) => p?.productFamily === obj.productFamily || p?.product?.productFamily === obj.productFamily); // Filter key if productFamily matches with any object in productList
    });
    let slicedArray = filteredKeys.slice(4, filteredKeys.length - 1); // slice the array from index 3 to n-2
    const result = slicedArray.map((key) => {
        return { [key]: forms[key]?.productFamily ?? null };
    });

    result.sort((a, b) => {
        const aName = Object.values(a)[0];
        const bName = Object.values(b)[0];
        const aIndex = productList.findIndex(p => p?.memberOfferedProduct?.product?.productFamily === aName);
        const bIndex = productList.findIndex(p => p?.memberOfferedProduct?.product?.productFamily === bName);
        return aIndex - bIndex;
    });

    const keysArray = result.map((obj) => Object.keys(obj)[0]);
    if(selectedProducts.filter((response)=> response.productFamily === 'WLCoverage').length > 0 )
    {
    filteredKeys.splice(filteredKeys.length - 1, 0, 'WLCoverage') // add 'WLCoverage' to the last previous element of the array
    }
    filteredKeys.splice(4, keysArray.length, ...keysArray); // replace the elements in the slicedArray array with the slicedArray
    // to remove dependents if the productfamily is  ID Theft
    return filteredKeys;
}


export const getWaivedSteps = (forms, stepKeys, insuredInfo, isDependentModified, isQLE) => {
    if (isQLE) return [];

    const waivedSteps = [];
    if (stepKeys?.length) {
        stepKeys.forEach((stepKey, index) => {
            const form = forms[stepKey];
            const prodInfo = insuredInfo?.[stepKey]?.WaiveCoverage || []
            const isProductWaived = prodInfo.every((product) => product.waiveProduct);
            const coverageInfo = insuredInfo?.[stepKey]?.coverageDetails || []
            const isCoverageWaived = form.productFamily && (!insuredInfo?.[stepKey]?.productBUID || coverageInfo?.find(prod => prod.waivedCoverage || insuredInfo?.[stepKey]?.disClosureRadio === 'No'));
            if (isCoverageWaived || isProductWaived) {
                waivedSteps.push(index);
            }
        })
    }
    return waivedSteps;
}


export const getRelationship = (relationshipToInsured) => {
    switch (relationshipToInsured) {
        case 'SP':
            return 'Spouse';
        case 'CH':
            return 'Child';
        case 'DP':
            return 'Domestic Partner';
        case 'CUP':
            return 'Civil Union Partner';
        default:
            return 'Employee';
    }
}
