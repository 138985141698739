import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxConstants } from '../../../../Common/Constants/index';
import Loader from '../../../Atoms/Loader';
import formFields from '../Content/WelcomeConfig.json';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveConfig } from '../Actions/saveConfig';
import { retrieveConfig } from '../Actions/retrieveConfig';
import SuccessPage from '../Common/SuccessPage';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response';
import {
  Checkbox,
  TextInput,
  TextArea,
  Button,
  Label,
} from '../../../Molecules/CustomSection/Atoms';
import '../styles.css';
import { CLEAR_ALERT_MESSAGES, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { getErrorMessages } from '../../../Molecules/CustomSection/Helper/MappingHelper';
import { MessageComponent } from '../../../Atoms';

const { confirmation, formReferenceLinkText } = formFields;
const WelcomeConfig = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const dispatch = useDispatch();

  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};
  const configDataRedux = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.welcomeConfigInfo?.[0]
  );

  const configData = {
    welcomeMsgByClientEdit: configDataRedux?.welcomeTextContainer?.editableMsgField || false, // hideableField
    welcomeTitleByClientEditValue: configDataRedux?.welcomeTextContainer?.editableTitleValue || '',
    welcomeDescTitleByClientEdit: configDataRedux?.welcomeTextContainer?.editableDescField || false,
    welcomeDescTitleByClientEditValue:
      configDataRedux?.welcomeTextContainer?.editableDescriptionValue || '',
    stepsToEnrollEdit: configDataRedux?.welcomeTextContainer?.editableSubTitle || false,
    stepsToEnrollEditValue: configDataRedux?.welcomeTextContainer?.editableSubTitleValue || '',
    enterStepsTitleEdit: configDataRedux?.welcomeTextContainer?.stepsField || false,
    enterStepsTitleEditValue: configDataRedux?.welcomeTextContainer?.stepsFieldValue || '',
    widgetTitleWithcertificateEdit: configDataRedux?.welcomeTextContainer?.widgetTitleWithcertificate || false,
    widgetTitleWithcertificateEditValue: configDataRedux?.welcomeTextContainer?.widgetTitleWithcertificateValue || '',
    widgetTitleWithoutcertificateEdit: configDataRedux?.welcomeTextContainer?.widgetTitleWithoutcertificate || false,
    widgetTitleWithoutcertificateEditValue: configDataRedux?.welcomeTextContainer?.widgetTitleWithoutcertificateValue || '',
  };

  const formik = useFormik({
    initialValues: {
      // welcomeMsgByClientToggle: configData?.welcomeMsgByClientToggle,
      welcomeMsgByClientEdit: configData?.welcomeMsgByClientEdit,
      welcomeTitleByClientEditValue: configData?.welcomeTitleByClientEditValue,
      welcomeDescTitleByClientEdit: configData?.welcomeDescTitleByClientEdit,
      welcomeDescTitleByClientEditValue: configData?.welcomeDescTitleByClientEditValue,
      stepsToEnrollEdit: configData?.stepsToEnrollEdit,
      stepsToEnrollEditValue: configData?.stepsToEnrollEditValue,
      enterStepsTitleEdit: configData?.enterStepsTitleEdit,
      enterStepsTitleEditValue: configData?.enterStepsTitleEditValue,
      widgetTitleWithcertificateEdit: configData?.widgetTitleWithcertificateEdit,
      widgetTitleWithcertificateEditValue: configData?.widgetTitleWithcertificateEditValue,
      widgetTitleWithoutcertificateEdit: configData?.widgetTitleWithoutcertificateEdit,
      widgetTitleWithoutcertificateEditValue: configData?.widgetTitleWithoutcertificateEditValue,
    },

    // validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      formik.resetForm();
      setSubmitting(false);
    },

    enableReinitialize: true,
  });

  let { clientId } = useSelector((storeState) => storeState.auth.userAuth);

  const saveWelcomeConfig = async () => {
    let data = {
      welcomeConfigInformation: {
        // noOfDaysChangeField: formik?.values?.stepsToEnrollEdit,
        // noOfDaysChangeFieldValue: formik?.values?.stepsToEnrollEditValue,
        // qleWidgetToggle: formik?.values?.stepsToEnrollToggle,
        welcomeMsgByClient: formik?.values?.welcomeMsgByClientEdit,
        welcomeTitleByClient: formik?.values?.welcomeTitleByClientEditValue,
        welcomeDescriptionByClient: formik?.values?.welcomeDescTitleByClientEditValue,
        enterStepsTitleEdit: formik?.values?.welcomeDescTitleByClientEdit,
        // welcomeDescTitleByClient: formik?.values?.welcomeTitleByClientEditValue,
        stepsTitleByClient: formik?.values?.stepsToEnrollEdit,
        stepsToEnrollEditValue: formik?.values?.stepsToEnrollEditValue,
        stepsToFieldValueByClient: formik?.values?.enterStepsTitleEditValue,
        stepsToFieldByClient: formik?.values?.enterStepsTitleEdit,
        widgetTitleWithcertificate: formik?.values?.widgetTitleWithcertificateEdit,
        widgetTitleWithcertificateValue: formik?.values?.widgetTitleWithcertificateEditValue,
        widgetTitleWithoutcertificate: formik?.values?.widgetTitleWithoutcertificateEdit,
        widgetTitleWithoutcertificateValue: formik?.values?.widgetTitleWithoutcertificateEditValue,
      },
    };
    setShowSuccess(null);
    //Api Call here
    let saveResponse = await saveConfig(data, clientId, formFields.apiCall.submit, setShowLoader);
    if (saveResponse?.status == 201) {
      setShowSuccess(true);
    } else {
      dispatch(
        SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'createClientIntoDbDependent',
          show: true,
          page: 'Configurator',
        })
      );
      setShowSuccess(false);
    }
  };

  useEffect(() => {
    // //Api Call here
    setShowLoader(true);
    const callAPI = async () => {
      let response = await retrieveConfig(clientId, formFields.apiCall.onload);
      if (response?.status !== 200) {
        setShowSuccess(null);
        setTimeout(() => {
          dispatch(
            SET_ALERT_MESSAGE({
              type: 'error',
              errorCode: '520',
              continue: true,
              id: 'getWelcomeInfofromDb',
              show: true,
              page: 'Configurator',
            })
          );
          setShowSuccess(false);
        }, 50);
      }
      dispatch(
        onLoadAPI({
          welcomeConfigInfo: response?.data,
          isAPICallInProgress: false,
        })
      );
      setShowLoader(false);
    };
    try {
      callAPI();
    } catch (error) {
      
    }
  }, [loggedUserEntity]);

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  const hasErrors = getErrorMessages('Configurator');

  return (
    <div>
      <MessageComponent currentPage={'Configurator'} setShowLoader={setShowLoader} />
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper">
          {!showSuccess && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="config">
                <div className="col-12 px-lg-5 px-md-5 px-sm-0">
                  <div class="row py-3 px-3">
                    <h4>{formFields.panelHeader.label}</h4>
                  </div>

                  <div class="row">
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dataField.label}
                        inputId={formFields.dataField.label}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxToggle.label}
                        inputId={formFields.checkboxToggle.label}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editablePlaceHolderLabel.label}
                        inputId={formFields.editablePlaceHolderLabel.label}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editablePlaceHolderLabelValue.label}
                        inputId={formFields.editablePlaceHolderLabelValue.label}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.welcomeTextContainer.label}
                        inputId={formFields.welcomeTextContainer.label}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.welcomeMsgByClientToggle.id}
                        name={formFields.welcomeMsgByClientToggle.name}
                        checked={formik.values.welcomeMsgByClientToggle}
                        disabled
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.welcomeMsgByClientToggle.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.welcomeMsgByClientEdit.id}
                        name={formFields.welcomeMsgByClientEdit.name}
                        checked={formik.values.welcomeMsgByClientEdit}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.welcomeMsgByClientEdit.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextArea
                        id={formFields.welcomeTitleByClientEditValue.id}
                        name={formFields.welcomeTitleByClientEditValue.name}
                        placeholder={formFields.welcomeTitleByClientEditValue.placeholder}
                        type={formFields.welcomeTitleByClientEditValue.type}
                        {...formik.getFieldProps(formFields.welcomeTitleByClientEditValue.name)}
                      />

                      {formik.touched.welcomeTitleByClientEditValue &&
                      formik.errors.welcomeTitleByClientEditValue ? (
                        <div class="form-mandatory">
                          {formik.errors.welcomeTitleByClientEditValue}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.welcomeDescTitleByClient.label}
                        inputId={formFields.welcomeDescTitleByClient.label}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.welcomeDescTitleByClientToggle.id}
                        name={formFields.welcomeDescTitleByClientToggle.name}
                        checked={formik.values.welcomeDescTitleByClientToggle}
                        disabled
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.welcomeDescTitleByClientToggle.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.welcomeDescTitleByClientEdit.id}
                        name={formFields.welcomeDescTitleByClientEdit.name}
                        checked={formik.values.welcomeDescTitleByClientEdit}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.welcomeDescTitleByClientEdit.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextArea
                        id={formFields.welcomeDescTitleByClientEditValue.id}
                        name={formFields.welcomeDescTitleByClientEditValue.name}
                        placeholder={formFields.welcomeDescTitleByClientEditValue.placeholder}
                        type={formFields.welcomeDescTitleByClientEditValue.type}
                        {...formik.getFieldProps(formFields.welcomeDescTitleByClientEditValue.name)}
                      />

                      {formik.touched.welcomeDescTitleByClientEditValue &&
                      formik.errors.welcomeDescTitleByClientEditValue ? (
                        <div class="form-mandatory">
                          {formik.errors.welcomeDescTitleByClientEditValue}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.stepsToEnrollTitle.label}
                        inputId={formFields.stepsToEnrollTitle.label}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.stepsToEnrollToggle.id}
                        name={formFields.stepsToEnrollToggle.name}
                        checked={formik.values.stepsToEnrollToggle}
                        disabled
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.stepsToEnrollToggle.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.stepsToEnrollEdit.id}
                        name={formFields.stepsToEnrollEdit.name}
                        checked={formik.values.stepsToEnrollEdit}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.stepsToEnrollEdit.name)}
                      />
                    </div>

                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextArea
                        id={formFields.stepsToEnrollEditValue.id}
                        name={formFields.stepsToEnrollEditValue.name}
                        placeholder={formFields.stepsToEnrollEditValue.placeholder}
                        type={formFields.stepsToEnrollEditValue.type}
                        {...formik.getFieldProps(formFields.stepsToEnrollEditValue.name)}
                      />

                      {formik.touched.stepsToEnrollEditValue &&
                      formik.errors.stepsToEnrollEditValue ? (
                        <div class="form-mandatory">{formik.errors.stepsToEnrollEditValue}</div>
                      ) : null}
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.enterStepsTitle.label}
                        inputId={formFields.enterStepsTitle.label}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.enterStepsTitleToggle.id}
                        name={formFields.enterStepsTitleToggle.name}
                        checked={formik.values.enterStepsTitleToggle}
                        disabled
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.enterStepsTitleToggle.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.enterStepsTitleEdit.id}
                        name={formFields.enterStepsTitleEdit.name}
                        checked={formik.values.enterStepsTitleEdit}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.enterStepsTitleEdit.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextArea
                        id={formFields.enterStepsTitleEditValue.id}
                        name={formFields.enterStepsTitleEditValue.name}
                        placeholder={formFields.enterStepsTitleEditValue.placeholder}
                        type={formFields.enterStepsTitleEditValue.type}
                        {...formik.getFieldProps(formFields.enterStepsTitleEditValue.name)}
                      />

                      {formik.touched.enterStepsTitleEditValue &&
                      formik.errors.enterStepsTitleEditValue ? (
                        <div class="form-mandatory">{formik.errors.enterStepsTitleEditValue}</div>
                      ) : null}
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.widgetTitleWithcertificate.label}
                        inputId={formFields.widgetTitleWithcertificate.label}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.widgetTitleWithcertificateToggle.id}
                        name={formFields.widgetTitleWithcertificateToggle.name}
                        checked={formik.values.widgetTitleWithcertificateToggle}
                        disabled
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.widgetTitleWithcertificateToggle.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.widgetTitleWithcertificateEdit.id}
                        name={formFields.widgetTitleWithcertificateEdit.name}
                        checked={formik.values.widgetTitleWithcertificateEdit}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.widgetTitleWithcertificateEdit.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextArea
                        id={formFields.widgetTitleWithcertificateEditValue.id}
                        name={formFields.widgetTitleWithcertificateEditValue.name}
                        placeholder={formFields.widgetTitleWithcertificateEditValue.placeholder}
                        type={formFields.widgetTitleWithcertificateEditValue.type}
                        {...formik.getFieldProps(formFields.widgetTitleWithcertificateEditValue.name)}
                      />

                      {formik.touched.widgetTitleWithcertificateEditValue &&
                      formik.errors.widgetTitleWithcertificateEditValue ? (
                        <div class="form-mandatory">{formik.errors.widgetTitleWithcertificateEditValue}</div>
                      ) : null}
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.widgetTitleWithoutcertificate.label}
                        inputId={formFields.widgetTitleWithoutcertificate.label}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.widgetTitleWithoutcertificateToggle.id}
                        name={formFields.widgetTitleWithoutcertificateToggle.name}
                        checked={formik.values.widgetTitleWithoutcertificateToggle}
                        disabled
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.widgetTitleWithoutcertificateToggle.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.widgetTitleWithoutcertificateEdit.id}
                        name={formFields.widgetTitleWithoutcertificateEdit.name}
                        checked={formik.values.widgetTitleWithoutcertificateEdit}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.widgetTitleWithoutcertificateEdit.name)}
                      />
                    </div>
                    <div className="col-3 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextArea
                        id={formFields.widgetTitleWithoutcertificateEditValue.id}
                        name={formFields.widgetTitleWithoutcertificateEditValue.name}
                        placeholder={formFields.widgetTitleWithoutcertificateEditValue.placeholder}
                        type={formFields.widgetTitleWithoutcertificateEditValue.type}
                        {...formik.getFieldProps(formFields.widgetTitleWithoutcertificateEditValue.name)}
                      />

                      {formik.touched.widgetTitleWithoutcertificateEditValue &&
                      formik.errors.widgetTitleWithoutcertificateEditValue ? (
                        <div class="form-mandatory">{formik.errors.widgetTitleWithoutcertificateEditValue}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row w-100 form-btnGroup py-5">
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 py-2 w-100">
                      <Button
                        className="btn btn-primary  primaryBackground mr-4"
                        onClick={saveWelcomeConfig}
                        disabled={hasErrors}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}

          {showSuccess && <SuccessPage {...confirmation}></SuccessPage>}
        </div>

        <div class="px-md-5 py-5 container-fluid"></div>
      </Suspense>
    </div>
  );
};

export default WelcomeConfig;
