import React from 'react';
import UserPassword from '../../../Layout/UserPassword/GetPassword';
import RetrievePassword from '../../../Molecules/CustomSection/MFA/RetrievePassword/index';

export default [
  // {
  //   path: '/retrievePassword',
  //   render: (props) => <UserPassword type="EmployeeRegistration" {...props} />,
  // },
  {
    path: '/retrievePassword',
    render: (props) => <RetrievePassword type="EmployeeRegistration" {...props} />,
  },
];

