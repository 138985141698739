import { Button } from '../../Atoms';
import imgSRC from '../../../../../Assets/Images/success-mfa.svg'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Success = ({ type, onContinue, hasContinue = true, successImg = imgSRC }) => {

    const { userAuth } = useSelector((storeState) => storeState?.auth);
    const userEmail = useSelector(
        (storeState) => storeState?.registeration.userEmail
    );
    const { legalEntityId = '', appCode = '' } = useSelector((storeState) => storeState?.registeration);
    const isHrCenter = (userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter") ? true : false
    let formHeader;
    let successMessage;
    switch (type) {
        case 'password':
            formHeader = 'Reset Password';
            successMessage = 'You have successfully changed your password.'
            break;
        case 'UserID':
            formHeader = 'Reset User ID';
            successMessage = (<> Your User ID is<br /> <p>{userEmail.login} </p></>);
            break;
        case 'MFAContactEmail':
            formHeader = 'Change MFA Contact';
            successMessage = 'You have successfully changed your email ID for MFA Contact.'
            break;
        case 'MFAContactPhone':
            formHeader = 'Change MFA Contact';
            successMessage = 'You have successfully changed your phone for MFA Contact.'
            break;
        case 'Register':
            formHeader = 'User Registration';
            successMessage = 'You have successfully completed the user registration process!'
            break;
        default: break;
    }

    return (
        <div>
            <h1 className="form-side-header">{formHeader}</h1>
            <div class="tri-confirmation-container mb-5 mt-4 py-3 px-md-0 px-lg-3 ">
                <div class="d-flex flex-column justify-content-center align-items-center false">
                    <img src={successImg} alt="Success Icon" />
                </div>
                <div>
                    <h2 class="custom_confirmation_message">{successMessage}</h2>
                    <div class="pt-1 ">
                        {isHrCenter ? <Link role="button" className="linker" to={`/hrcenter/login?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Click here to login</Link> : <Link role="button" className="linker" to={`/enrollment?legalEntityId=${legalEntityId}&appCode=${appCode}`}> Click here to login</Link>}

                    </div>
                </div>
            </div>
            {hasContinue && <Button onClick={onContinue} className="btn btn-primary primaryBackground btn-primary-contrast" type="submit">Continue</Button>}
        </div>
    )
}

export default Success;
