import React, { useEffect, useState } from "react";
import { Button } from "../../../../Atoms";
import { useDispatch, useSelector } from 'react-redux';
import appConfig from "../../../../../appConfig";
import http from "../../../../../Common/Services/HttpService/index";
import { SET_ALERT_MESSAGE, UPDATE_LOADING } from "../../../../../Store/Slices/InsuredInfo";
import { VOYA_API_RESPONSE,VOYA_RADIO_SELECT } from "../../../../../Store/Slices/EmployeeProfile";
import { UPDATE_ENROLLMENT_DATA } from "../../../../../Store/Slices/Enrollment";
import { getErrorMessages } from "../../Helper/MappingHelper";

const WLCoverageQuestion = (props) => {
    const { nextStep, onBack,onNext } = props;

    const dispatch = useDispatch();
    let dateOfBirth = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile?.dateOfBirth);
    let ssn = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile?.ssn);
    let employeeId = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList?.EmploymentInformation?.employmentInformation?.employeeId);
    let isAdminEndorse = useSelector((storeState) => storeState?.callCenter?.isAdmin);

    const [options, setoptions] = useState([]);
    let radioValue = useSelector((storeState) => storeState?.employeeProfile?.voyaRadioSelect);
    const [RadioSelctedValue, setRadioSelectedValue] = useState("");

    const [apiCall, setApiCall] = useState({ "GetContractConfiguration": false })

    const enrollmentBuid = useSelector((storeState) => storeState?.process?.enrollmentProcess?.[0]?.[0]?._Result?.processEntity?.buid);
    const additionalDatas = useSelector((storeState) => storeState?.enrollment?.enrollmentData?.contract?.contract?.additionalDatas);
    const additionalDataBuid = useSelector((storeState) => storeState?.enrollment?.enrollmentData?.contract?.contract?.buid);

    useEffect(() => {
        setRadioSelectedValue(radioValue);
    }, [radioValue]);

    if(isAdminEndorse){
        employeeId=useSelector((storeState) => storeState?.callCenter?.selectedEmployeeData?.EmployeeId);
      }

    useEffect(() => {
        if (apiCall.GetContractConfiguration === false) {
            setApiCall({ ...apiCall, GetContractConfiguration: true });
            callGetContractCongurationApiFn(enrollmentBuid);
        }
    }, [enrollmentBuid]);

    const callGetContractCongurationApiFn = async (enrollmentBuid) => {
        dispatch(UPDATE_LOADING(true));
        return await http.post(appConfig.SSP_MID_URL + '/GetContractConfiguration', {
            EnrollmentProcessIdentifier: enrollmentBuid
        }).then((resp) => {
            dispatch(UPDATE_LOADING(false));
            const radioOptionsData =
                (resp &&
                    resp.data && resp?.data?.additionalDatas && resp.data.additionalDatas?.length > 0)
            if (radioOptionsData) {

                setoptions([...resp.data.additionalDatas[0].possibleValues]);
                const getSelectRadio = resp.data.additionalDatas.filter((pobj) => {
                    return pobj?.fieldName === "ENROLLINWLCOV"
                });

                // if (getSelectRadio?.length > 0) {
                //     setRadioSelectedValue(getSelectRadio[0]?.fieldValue);
                // }
            }
        }).catch((error) => {
            dispatch(UPDATE_LOADING(false));
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: '520',
                id: 'GetContractConfiguration',
                show: false,
                page: props.currentStep,
            }));
        });
    };

    const updateContractInfoApiFn = async (additionalData) => {
        dispatch(UPDATE_LOADING(true));
        return await http.post(appConfig.SSP_MID_URL + '/updateContractInformationWL', {
            EnrollmentProcessIdentifier: enrollmentBuid,
            additionalDatas: additionalData,
            buid: additionalDataBuid
        }).then((resp) => {
            dispatch(UPDATE_LOADING(false));
            if (resp?.data) {
                dispatch(UPDATE_ENROLLMENT_DATA(resp?.data));
                if (RadioSelctedValue==="Yes"){
                    getVoyaContentApiFn();
                } 
                else if (RadioSelctedValue==="No"){
                     onNext();
                }
               
            }
        }).catch((error) => {
            dispatch(UPDATE_LOADING(false));
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: '520',
                id: 'updateContractInformationWL',
                show: false,
                page: props.currentStep,
            }));
        });
    };

    const getVoyaContentApiFn = () => {
        dispatch(UPDATE_LOADING(true));
        http.post(appConfig.SSP_MID_URL + '/getVoyaContent', {
            dateOfBirth: dateOfBirth,
            ssn: ssn,
            employeeId: employeeId
        }).then((resp) => {
            if (resp.status == 200) {
                // window.location ="https://www.EnrollVB.com/BayStateHealth?employeeId=1234567898&dob=01%2F13%2F1965";
                dispatch(VOYA_API_RESPONSE(resp));
                dispatch(UPDATE_LOADING(false));
                nextStep();
            }
            // setShowLoader(false);
        }).catch((error) => {
            dispatch(UPDATE_LOADING(false));
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: '520',
                id: 'getVoyaContent',
                show: false,
                page: props.currentStep,
            }));
        });
    };

    const handleRadioChange = (event) => {
        setRadioSelectedValue(event.target.value);
        dispatch(VOYA_RADIO_SELECT(event.target.value));
    };

    const handlePremiumCoverage = () => {
        const getSelectedValue = options.filter((pobj) => {
            return pobj.fieldValue === RadioSelctedValue;
        });
        let getOtherAdditionalData = additionalDatas.filter((pobj) => {
            return pobj.fieldName !== "ENROLLINWLCOV"
        });
        getOtherAdditionalData.splice(0, 0, getSelectedValue[0]);
        updateContractInfoApiFn(getOtherAdditionalData);
    };

    const hasErrors = getErrorMessages(props.currentStep);

    return (
        <>
            <div className="radioGroup w-100">
                <div className="row">
                    <div className="px-4 px-xl-3 pb-2 py-3">
                        {options[0]?.presentedName || ""}
                    </div>
                </div>
                <div className="pl-2 px-xl-0 mr-0 custom-control-inline" style={{ fontSize: '0.875rem', color: 'rgb(0, 0, 0)', fontFamily: 'NotoSansRegular' }}>
                    {options.map(option => (
                        <label key={option.fieldValue} className={option.className}
                            style={{
                                display: 'flex', alignItems: 'center', marginBottom: '1rem',
                                marginLeft: '.3rem'
                            }}
                        >
                            <input type="radio" name={option.fieldName} value={option.fieldValue}
                                checked={RadioSelctedValue === option.fieldValue} onChange={handleRadioChange}
                            />
                            <span style={{ marginLeft: '.7rem' }}>{option.nameDescription}</span>
                        </label>
                    ))}
                </div>
            </div>

            <div class="row panelStyleNoBorder px-1" style={{ paddingTop: '0.5rem' }}>
                <div class="col-md-12 col-lg-12 px-3-old" style={{ paddingLeft: '0.6rem', paddingRight: '1rem' }}>
                    <div class="sticky-note mr-2 col-12">
                        <div class="row mx-0">
                            <div class="col-md-auto col-lg-1 col-2 pt-3 pt-lg-1 pt-md-1 customSticky1">
                                <div class="d-flex flex-column justify-content-center align-items-center false">
                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAolJREFUSEu1VUFy2kAQnBWqODfjH+BrDGXlBYYXmLzA+AXBhxjdTG6Scwh5QfALIr8g5AWhCjlXyAvi3OyKYdOzq0VCaGVRqWyVSqCdnZ7pme0RVLZevfOo5pyRJA9m/NQT8wm+LcihiGbhbZkLUbjZ9NtE8gp7ePMS3/B/ivd9Ys/7DLivgEgO6e76psjXNkDTH2rn4icJGlLtIaLpyDje9NG67JJ0+rA/wUZE7uN53nYToOWPScozEuIGjvtWx/lQjy77OPMRn6cA6WTPpQAmciHOaRaMC6nz+nUrqKb1K85NKA475rwGMJsc+SzoFddlMML3tyrKOHxdaNPye2DgM+h9T3EAqvErAZjg3SD3wbNHOECR6VjZx2Fxc/De0SCC1xNQdci+BHErOs53cGinhg+q6AjZoZviEIW1LM9v0JOcI5MLdNZIUDNJ3X08qFxUq/dko6mylUwlAHymB2kHbes5011sIOmW7sJuKYZpGFDJGXCPj0vTVjTWUGTud1y6smDWdKLYrjhkAJmtujWy9AI+D7BuWdH5PwDqhosvtFpVrAGntVsGQyU3qga6gKf4c1CpcFVqoO6C8FCrBgCSdIR8Q7Pr6J9rwHLytPcLfj5x45ibvEBK86yGbAClYqY/V5EUdBBNg4UGMBqS3L6tLFRXmNnAAJgBRYKYqsJa01JN0RpyypWnHx/4Ju62NDVzoP/OaloKoA0m8Hr8rC7loXXkLNUCAbazAW6qogJ5iUKbCSUumEdrKmy/3LuCfED8MAFXy24+e9tM5j5mxdzHA7pkpIe8BJhTR1tjHqMNibQmlUxAu65zdH9e9HC4p2jbWoiYgV0xKsvSDpB3qHQeQ2m1vN+lCf4CfSw4o4wcyLEAAAAASUVORK5CYII=" name="" class="img-fluid card-header-image align-self-end element-image" alt="An image" />
                                </div>
                            </div>
                            <ul class="col-10 col-lg-11 col-md-10"
                                style={{ listStyleType: 'disc', paddingLeft: '1.5rem', marginBottom: '0rem' }}>
                                <li class="pt-1 mb-1">
                                    To enroll in coverage for the first time, select “YES”.
                                </li>
                                <li class="pt-1 mb-1">
                                    To add additional coverage amount or add additional dependent coverage to your in-force amount, select “YES”.
                                </li>
                                <li class="pt-1 mb-1">
                                    To leave existing coverage in place without changes select “No”.
                                </li>
                                <li class="pt-1 mb-1">
                                    To waive, drop or cancel/surrender existing coverage you will need to call Voya direct at 800.537.5024
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='button-group'>
                <Button type="button" onClick={onBack} className="mr-3">Back</Button>

                <Button
                    type="button"
                    onClick={handlePremiumCoverage}
                    className="TestCls"
                    disabled={hasErrors || RadioSelctedValue === undefined || RadioSelctedValue === ""}
                >
                    {RadioSelctedValue === "No" ? "Continue to Summary": "Continue"}
                </Button>
            </div>
        </>
    );
};
export default WLCoverageQuestion;
