import { createSlice } from '@reduxjs/toolkit';
import { apiCallInitiation } from '../../../Middleware/Api/Actions';
import { apiConstants } from '../../../../Common/Constants';
import http from '../../../../Common/Services/HttpService';


let userType = '';
let environment = '';
const slice = createSlice({
  name: 'userAuth',
  initialState: {
    // data: {},
    data: { access_token: "test" },

    clientId: '',
    loading: false,
    lastFetch: null,
    userType: '',
    environment: 'Acceptance 6.0 French',
    SSOLoginError: false
  },
  reducers: {
    SET_CONFIGURATOR_CLIENT_ID: (userAuth, action) => {
      userAuth.clientId = action.payload.clientId;
    },
    apiRequested: (userAuth, action) => {
      userAuth.loading = true;
    },

    updateEnvironment: (response, action) => {
      return {
        ...response,
        ...action.payload,
      };
    },
    apiReceived: (userAuth, action) => {
      //code change  for adding _Result in response
      userAuth.data = action?.payload?._Result;
      if (userAuth?.data) {
        if (userAuth.data.access_token) {
          http.setBearer(userAuth.data.access_token);
        }
        if (userAuth?.data?.mid_token) {
          http.setMidToken(userAuth.data.mid_token);
        }
      }
      //http.setBearer(`bearer ${userAuth.data.access_token}` );
      userAuth.loading = false;
      userAuth.userType = userType;
      userAuth.environment = environment;
    },
    apiReceivedMFA: (userAuth, action) => {
      userAuth.data.access_token = action.payload;
      http.setHeaderValue('REMOTE_USER', action.payload)
      // http.setHeaderValue('X-REQUEST-ID', 200)
      userAuth.loading = false;
      userAuth.userType = userType//'Enrollment'; // will update later
      userAuth.environment = environment;
      userAuth.data.user_name = action.payload//"mfa_nicolas.chanti@mailinator.com"// action.payload;
      userAuth.data.loggedUser = action.payload;

    },

    userTypeMFA: (userAuth, action) => {

      userAuth.userType = action.payload
    },
    setProcessType: (userAuth, action) => {
      userAuth.processType = action.payload
    },
    setSSOLoginError: (userAuth, action) => {
      userAuth.SSOLoginError = action.payload
    },
    apiReceivedUserTypeAndLanguage: (userAuth, action) => {
      userAuth.userType = action.payload.userType;
      userAuth.language = action.payload.language;
    },
    apiRequestFailed: (userAuth, action) => {
      userAuth.loading = false;
    },
    logoutUser: (userAuth) => {
      //code change  for adding _Result in response
      userAuth.data = { access_token: "test" };
      userAuth.lastFetch = null;
      userAuth.loading = false;
      userAuth.userType = '';
      userAuth.processType = '';
      userAuth.environment = 'Acceptance 6.0 French';
    },
    RESET_USER_TYPE: (userAuth, action) => {
      userAuth.userType = '';
    },
    SET_AUTHENTICATED_USER: (userAuth, action) => {
      userAuth.authenticatedUser = action.payload;
    },
    SET_PERFORMANCE_USER: (userAuth, action) => {
      userAuth.data = {
        access_token: `mfa_${action.payload.username}`,
        user_name: `mfa_${action.payload.username}`,
        loggedUser: `mfa_${action.payload.username}`
      }
      userAuth.clientId = '';
      userAuth.loading = false;
      userAuth.lastFetch = null;
      userAuth.userType = 'Enrollment';
      userAuth.environment = '';
      userAuth.SSOLoginError = false;
      userAuth.processType = 'non-sso';
      userAuth.language = 'en';
    },
    CHANGE_REMOTE_USER: (userAuth, action) => {
      userAuth.data.access_token = action.payload;
      http.setHeaderValue('REMOTE_USER', action.payload)
      userAuth.data.user_name = action.payload;
    },

    //call center only
    SET_SSO_LOGIN: (userAuth, action) => {
      userAuth.ssoFirstName = action.payload.firstName;
      userAuth.ssoLastName = action.payload.lastName;
      userAuth.ssoEmail = action.payload.email;
      userAuth.ssoURL = action.payload.url;
      userAuth.data.access_token = action.payload.email;
      userAuth.data.loggedUser = action.payload.email;
      userAuth.ssoLogin = true;
    },
    apiReceivedMFAUpdate: (userAuth, action) => {
      userAuth.data.proxy_user = userAuth.data.access_token;
      userAuth.data.access_token = action.payload;
      userAuth.data.loggedUser = action.payload;
      http.setHeaderValue('REMOTE_USER', action.payload)
      // http.setHeaderValue('X-REQUEST-ID', 200)
      userAuth.loading = false;
      userAuth.userType = userType//'Enrollment'; // will update later
      userAuth.environment = environment;
    },
    apiReceivedMFAFirstName: (userAuth, action) => {
      userAuth.firstName = action.payload;
    },

    SELECTED_EMPLOYEE_INFO: (userAuth, action) => {
      userAuth.employeeEmail = action.payload.email;
      userAuth.employeeFirstName = action.payload.firstName;
      userAuth.employeeLastName = action.payload.lastName;
    },
    CLEAR_EMPLOYEE_INFO: (userAuth, action) => {
      userAuth.employeeEmail = '';
      userAuth.employeeFirstName = '';
      userAuth.employeeLastName = '';
    },
    UPDATE_USERNAME_EMAIL: (userAuth, action) => {
      http.setHeaderValue('REMOTE_USER', action.payload)
      userAuth.data.user_name = action.payload;
      userAuth.data.employeeEmail = action.payload
    },

    apiReceivedMFALastName: (userAuth, action) => {
      userAuth.lastName = action.payload;
    },
    apiReceivedMFAprocessType: (userAuth, action) => {
      userAuth.processType = action.payload;
    },


    //call center only
    SET_SSO_LOGIN: (userAuth, action) => {
      userAuth.ssoFirstName = action.payload.firstName;
      userAuth.ssoLastName = action.payload.lastName;
      userAuth.ssoEmail = action.payload.email;
      userAuth.ssoURL = action.payload.url;
      userAuth.data.access_token = action.payload.email;
      userAuth.data.loggedUser = action.payload.email;
      userAuth.ssoLogin = true;
    },

    apiReceivedMFAFirstName: (userAuth, action) => {
      userAuth.firstName = action.payload;
    },

    SSO_LOGIN_ERROR: (userAuth, action) => {
      userAuth.ssoLoginError = action.payload;
    },
    processTypeMFA: (userAuth, action) => {
      if (action.payload.currentProcessType)
        userAuth.currentProcessType = action.payload.currentProcessType;
      userAuth.processType = action.payload.processType;
      userAuth.userType = action.payload.userType;
      userAuth.language = action.payload.language;
    },
    SET_CALL_CENTER_ADMIN: (userAuth, action) => {
      userAuth.callCenterAdmin = action.payload;
    },
    SET_REDIRECT_FROM_EMP_SEARCH: (userAuth, action) => {
      userAuth.redirectFromEmpSearch = action.payload;
    },
    START_PROXY_ENROLL: (userAuth, action) => {
      userAuth.startProxyEnroll = action.payload;
    },
    SET_CURRENT_URL: (userAuth, action) => {
      userAuth.currentURL = action.payload;
    },
    SET_REDIRECT_URL: (userAuth, action) => {
      userAuth.redirectUrl = action.payload;
    },
    SET_CURRENT_PROCESS_TYPE: (userAuth, action) => {
      userAuth.currentProcessType = action.payload;
    },
    CHECK_LOGIN: (userAuth, action) => {
      userAuth.isValidLogin = action.payload;
    },
    START_ADMIN_ENDOSEMENT: (userAuth, action) => {
      userAuth.startProxyEnroll = action.payload;
    },
    RESET_EMP_FILTER: (userAuth, action) => {
      userAuth.resetEmpFilter = action.payload;
    },
    CONFIG_SSO_LOGIN: (userAuth, action) => {
      userAuth.configuratorSSOLogin = action.payload.configuratorSSOLogin
      userAuth.userType = ''
    },

  },
});
export const { apiReceived, apiRequested, apiRequestFailed, updateEnvironment, logoutUser, apiReceivedMFA, userTypeMFA,
  apiReceivedUserTypeAndLanguage, RESET_USER_TYPE, SET_CONFIGURATOR_CLIENT_ID, setProcessType, setSSOLoginError, SET_AUTHENTICATED_USER,
  CHANGE_REMOTE_USER, SET_PERFORMANCE_USER,
  SET_SSO_LOGIN, processTypeMFA, apiReceivedMFAUpdate, apiReceivedMFAFirstName, apiReceivedMFALastName, apiReceivedMFAprocessType,
  SELECTED_EMPLOYEE_INFO, SSO_LOGIN_ERROR, CLEAR_EMPLOYEE_INFO, UPDATE_USERNAME_EMAIL, SET_CALL_CENTER_ADMIN, SET_REDIRECT_FROM_EMP_SEARCH,
  START_PROXY_ENROLL, SET_CURRENT_URL, SET_REDIRECT_URL, SET_CURRENT_PROCESS_TYPE,
  CHECK_LOGIN, START_ADMIN_ENDOSEMENT, RESET_EMP_FILTER, CONFIG_SSO_LOGIN } =
  slice.actions;


export const callBearer = (bearerData) => (dispatch, getState) => {
  userType = bearerData.userType;
  environment = bearerData.environment;
  return dispatch(
    apiCallInitiation({
      url: apiConstants.USER_URL.getBearer,
      method: 'POST',
      data: bearerData.loginDetails,
      onStart: apiRequested.type,
      onSuccess: apiReceived.type,
      onError: apiRequestFailed.type,
    })
  );
};

export const callPerformance = (bearerData) => (dispatch, getState) => {
  userType = bearerData.userType;
  environment = bearerData.environment;
  return dispatch(
    apiCallInitiation({
      url: apiConstants.USER_URL.performanceLogin,
      method: 'POST',
      data: bearerData.loginDetails,
      onStart: apiRequested.type,
      onSuccess: apiReceived.type,
      onError: apiRequestFailed.type,
    })
  );
};

export const callRecaptchaValidator = (token) => (dispatch) => {
  return dispatch(
    apiCallInitiation({
      url: apiConstants.USER_URL.recaptchaValidator,
      method: 'POST',
      data: { key: token },
      onStart: apiRequested.type,
      onSuccess: apiReceived.type,
      onError: apiRequestFailed.type,
    })
  );
};
export default slice.reducer;