/** Input Format Functionalities 2022  */

class FormatHelper {
  isDeleteOrBackspace = (event) => {
    if (event.keyCode === 46 || event.keyCode === 8) 
      return true
    else 
      return false;
  };

  shouldCursorJump = (isEdit, event) => {
    let len = event.target.value.length;
    let caret = event.target.selectionStart;
       // if ((  isEdit ||  len>= 11) && (caret <= 3 || caret <= 14)) 
    if ((  isEdit ||  len>= 12) && (caret <= 14)) 

        return(false)
      else 
        return(true)
  };
  //123-45-6789
  shouldCursorJumpSSN = (isEdit, event) => {
    let len = event.target.value.length;
    let caret = event.target.selectionStart;
    // if ((  isEdit ||  len>= 9) && (caret <= 3 || caret <= 9)) 
    if ((  isEdit ||  len>= 9) && ( caret <= 11)) 

        return(false)
      else 
        return(true)
  };
  
  /** 1(234)567 8910 => handle 234 */
  handleMobileSecondGroup = (event, shouldCursorJump) => {
    let caret = event.target.selectionStart;
    const element = event.target;
    if (
      (element.selectionStart >= 4 &&  !shouldCursorJump) || 
      (element.selectionStart >= 4 && !shouldCursorJump) ||
      (caret < 4 )
    ) 
      return true;
    else
      return false;
  };

  handleSSNSecondGroup = (event, shouldCursorJump) => {
    let caret = event.target.selectionStart;
    const element = event.target;
        if (
      (element.selectionStart >= 4 &&  !shouldCursorJump) || 
      (caret < 4 )
    ) 
      return true;
    else
      return false;
  };

 
  /** (1(234)567 8910 => handle 567 */
  handleMobileThirdGroup = (event, shouldCursorJump) => {
    let caret = event.target.selectionStart;
    const element = event.target;
    if (
      (event.target.value.length < 14) && (
       (caret >= 3  && shouldCursorJump) || 
       (element.selectionStart >= 2 && shouldCursorJump)
      )
    ) 
      return true;
    else
      return false;
  };
  handleSSNThirdGroup = (event, shouldCursorJump) => {
    let caret = event.target.selectionStart;
    const element = event.target;
    if (
      (event.target.value.length < 11) && (
       (caret >= 3  && shouldCursorJump) || 
       (element.selectionStart >= 2 && shouldCursorJump)
      )
    ) 
      return true;
    else
      return false;
  };
  // shouldCursorJump = (isEdit, event) => {
  //   let len = event.target.value.length;
  //   let caret = event.target.selectionStart;
  //   

  //   if ((  isEdit ||  len>= 13) && (caret <= 4 || caret <= 16)) 
  //       return(false)
  //     else 
  //       return(true)
  // };
  
  /** 1 (234) 567 8910 => handle 234 */
  // handleMobileSecondGroup = (event, shouldCursorJump) => {
  //   let caret = event.target.selectionStart;
  //   const element = event.target;
  //   if (
  //     (element.selectionStart >= 4 &&  !shouldCursorJump) || 
  //     (caret > 7  )
  //   ) 
  //     return true;
  //   else
  //     return false;
  // };

   /** 1 (234) 567 8910 => handle 567 */
  //  handleMobileThirdGroup = (event, shouldCursorJump) => {
  //   let caret = event.target.selectionStart;
  //   const element = event.target;
  //   if (
  //     (caret >= 7  && shouldCursorJump) || 
  //     (element.selectionStart >= 4 && shouldCursorJump)
  //   ) 
  //     return true;
  //   else
  //     return false;
  // };
  
  /** input: phone number, output: formatted phone number  => 1 (234) 567 8910 */
  // getFormatPhoneNumber = (input) => {
  //   if (!input) return input;
  //   let phoneNum = input.replace(/[^\d]/g, '');
  //   let phoneNumLen = phoneNum.length;
  //   if (phoneNumLen < 2) return phoneNum;
  //   if (phoneNumLen < 4) return phoneNum;
  //   if (phoneNumLen < 7) {
  //     return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
  //   }
  //   return `${phoneNum.slice(0, 1)} (${phoneNum.slice(1, 4)}) ${phoneNum.slice(
  //     4,
  //     7
  //   )} ${phoneNum.slice(7, 11)}`;
  // };
  /** input: phone number, output: formatted phone number  => (123)456-7899 */
  getFormatPhoneNumber = (input, isPaste) => {
    if (!input) return input;
    let phoneNum = input.replace(/[^\d]/g, '');
    let phoneNumLen = phoneNum.length;

    if(phoneNumLen === 10 && isPaste){
      let first = phoneNum.slice(0, 3)
      let second = phoneNum.slice(3, 6)
      let third = phoneNum.slice(6, 10)
      let final = (`(${first}) ${second}-${third}`);
      return final
    }
    
    else{
      if (phoneNumLen < 4) return phoneNum;
      if (phoneNumLen < 7) {
        return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
      }
      return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6, 10)}`;
    }
  };
  getFormatMaskedPhoneNumber = (input) => {
    if (!input) return input;
    let phoneNum = input;
    let phoneNumLen = phoneNum.length;
    if (phoneNumLen < 4) return phoneNum;
    if (phoneNumLen < 7) {
      return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
    }
    return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(6, 10)}`;
  };
  getFormatSSN = (input) => {
    if (!input) return input;

    let ssnTemp = input.replace(/[^\d]/g, '');

    let ssnTempLen = ssnTemp.length;

    if (ssnTempLen < 3) return ssnTemp;

    if (ssnTempLen < 6) {
      return `${ssnTemp.slice(0, 3)}-${ssnTemp.slice(2)}`;
    }

    return `${ssnTemp.slice(0, 3)}-${ssnTemp.slice(3, 5)}-${ssnTemp.slice(5, 9)}`;
  };

  //To convert the values to Pascalcase
  toPascalCase = (value) => {
    if (value != 'meUndefined') {
      const pascalValue = value.replace(
        /\w\S*/g,
        (m) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase()
      );
      return pascalValue;
    } else {
      return value;
    }
  };

  getFormatSSN = (input) => {
    if (!input) return input;
    let val = input.replace(/[^\d]/g, '');
    val = val.replace(/^(\d{3})(\d{1,2})/, '$1-$2');
    val = val.replace(/^(\d{3})-(\d{2})(.+)/, '$1-$2-$3');
   return(val.substring(0, 11))
  };

  numberFormat = (num) => {
    if (!num) return 0;
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
};

export default FormatHelper;
