export const constantsList = [
  'AccountTypeConstants',
  'AddressTypeConstants',
  'CountryConstants',
  'CurrencyConstants',
  'PreferredContactConstants',
  'StateConstants',
  'BaseSalaryFrequencyConstants',
  'BonusFrequencyConstants',
  'CommissionsFrequencyConstants',
  'EmploymentStatusConstants',
  'EnrollmentStatusConstants',
  'FrequencyConstants',
  'GenderConstants',
  'MaritalStatusConstants',
  'NationalityConstants',
  'PersonalTitleConstants',
  'WorkingCategoryConstants',
  'QuoteStatusConstants',
  'QuoteTypeConstants',
  'InvoiceTransactionTypeConstants',
  'InvoiceStatusConstants',
  'PaymentStatusConstants',
  'ACtypeConstants',
  'ShortStateConstants',
  'StatusConstants',
  'DisabilityConstants',
  'ContactChoice',
  'PolicyChangeConstants',
  'RelationshipConstants',
  'DependentRelationshipConstants'
];
