import React from 'react';
import './styles.css';

const Loader = (props) => {
  const { loading, className } = props;
  return (
    <React.Fragment>
      {loading && (
        <div className="container-fluid loadingWrapper">
          <div className="row">
            <div className="col-12">
              <div id="preloader" className={className}>
                <div id="loader"></div>
                <div className="d-flex justify-content-center loading">loading...</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Loader;
