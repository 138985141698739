import React from 'react';
import MultiFactorAuthentication from '../../../Layout/MultiFactorAuthentication/MultiFactorAuthentication';
import Mfa from '../../../Molecules/CustomSection/MFA/MultiFactorAuthentication';
import MultiFactorAuthenticationOld from '../../../Layout/MultiFactorAuthenticationOld/MultiFactorAuthenticationOld';
import MultiFactorAuthenticationOldHrCenter from '../../../Layout/MultiFactorAuthenticationOldHrCenter/MultiFactorAuthenticationOld';
export default [
  // { path: '/client', render: (props) => <UserRegistration type="Client" {...props} /> },
  // { path: '/employer', render: (props) => <UserRegistration type="Employer" {...props} /> },
  // { path: '/employee', render: (props) => <UserRegistration type="Employee" {...props} /> },
  // { path: '/broker', render: (props) => <UserRegistration type="Broker" {...props} /> },
  // { path: '/customer', render: (props) => <UserRegistration type="Customer" {...props} /> },
  // { path: '/enrollment', render: (props) => <UserRegistration type="Enrollment" {...props} /> },
  // { path: '/configurator', render: (props) => <UserRegistration type="Configurator" {...props} /> },
  // {
  //   path: '/multiFactorAuthentication',
  //   render: (props) => <MultiFactorAuthentication type="Enrollment" {...props} />,
  // },
  {
    path: '/multiFactorAuthentication',
    render: (props) => <Mfa type="Enrollment" {...props} />,
  },
  {
    path: '/multiFactorAuthenticationOld',
    render: (props) => <MultiFactorAuthenticationOld type="Enrollment" {...props} />,
  },
  {
    path: '/multiFactorAuthenticationOldHrCenter',
    render: (props) => <MultiFactorAuthenticationOldHrCenter type="HrCenter" {...props} />,
  },
 
];
