import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import './styles.css';
import { SET_WAIVE_COVERAGE, SET_WAIVE_COVERAGE_NATION } from '../../../Store/Slices/InsuredInfo';
import { CHANGE_BUTTON_WIDTH } from '../../../Store/Slices/DisableButton';
import {
  UPDATE_CART_ITEM,
  DELETE_FULL_PET_LIST,
  RADIO_SELECTED_VALUE_PET_NATION,
} from '../../../Store/Slices/EmployeeProfile';
const Checkbox = (props) => {
  const {
    id,
    name,
    label,
    element,
    checked,
    value,
    onChange,
    className = '',
    visibility,
    hidden,
    selectBlock,
    classNameWrapper = '',
    classNameContainer = '',
    classNameComponent = '',
    disabled,
    elementStyle = {},
    isForInsuredWaive = false,
    hideElement,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const [tempValue, setTempValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const { radioSelectedValuePetNation } = useSelector((storeState) => storeState.employeeProfile);
  const { insuredInfo } = useSelector((storeState) => storeState);
  const { stepId, currentStep } = useSelector((storeState) => storeState.stepForm);
  const isRadio =
    useSelector((storeState) => storeState.disableButton[currentStep]?.isRadio) || false;
  const { petList } = useSelector((storeState) => storeState.employeeProfile);
  //   useEffect(() => {
  //   if (
  //     name === 'CoverageSelection.hideDescription'
  //   ) {
  //     if(value == true){
  //       // dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: true }));
  //       dispatch(HIDE_CONTINUE_BUTTON({ hideContinueBtn: false }));
  //     }
  //   }
  // }, [name === 'CoverageSelection.hideDescription' ]);
  //To get linear values
  // const linearValue = useSelector(
  //   (storeState) =>
  //     storeState?.entities?.response?.onLoadResponseList?.Linear?.linearNonLinear?.linearValue
  // );

  let checkedTemp = false;
  useEffect(() => {
    if (radioSelectedValuePetNation == 'waive') {
      checkedTemp = true;
      setTempValue(true);
    }
  }, [radioSelectedValuePetNation]);

  //Handle change function
  const handleChange = async (event) => {
    if (
      currentStep == 'PetAssureNation' &&
      name == 'PetAssureNation.waiveCoverage' &&
      event.target.checked == true
    ) {
      dispatch(DELETE_FULL_PET_LIST());
      // dispatch(UPDATE_CART_ITEM([]))
      setTimeout(() => {
        dispatch(UPDATE_CART_ITEM([]));
      }, 1000);
      dispatch(
        SET_WAIVE_COVERAGE_NATION({ status: event.target.checked, currentStep: currentStep })
      );
      dispatch(RADIO_SELECTED_VALUE_PET_NATION('waive'));
    }
    setTempValue(false);
    onChange && onChange(event);
    if (stepId > 2 && currentStep == 'PetAssureNation') {
      dispatch(
        SET_WAIVE_COVERAGE_NATION({ status: event.target.checked, currentStep: currentStep })
      );
    } else
      stepId > 2 &&
        dispatch(SET_WAIVE_COVERAGE({ status: event.target.checked, currentStep: currentStep }));
    let waiveObject = {
      status: event.target.checked,
      waiveType: 'checkBox',
      isRadio: isRadio,
      isCheckbox: event.target.checked,
    };
    stepId > 2 &&
      (name === 'CoverageSelection.waiveCoverage' ||
        name === 'CriticalIllness.waiveCoverage' ||
        name === 'HospitalIndemnity.waiveCoverage' ||
        name === 'PetAssureNation.waiveCoverage') &&
      dispatch(CHANGE_BUTTON_WIDTH({ currentStep: currentStep, waiveObject }));
  };

  const styles = {
    width: '1.25rem',
    height: '1.25rem',
    borderRadius: '.25rem',
    border: '0.0625rem solid #ccc',
    backgroundColor: '#fff',
  };
  let additionalClasses = hideElement ? 'hideElement' : ``;
  let checkBoxSelected;
  if (currentStep == 'PetAssureNation') {
    checkBoxSelected = insuredInfo.setWaiveCoverageNation;
  } else {
    checkBoxSelected = checked;
  }

  return (
    <div style={elementStyle ?? ''} className={`${additionalClasses} ${classNameContainer}`}>
      <div
        className={
          'checkBoxAndLabel ' + additionalClasses + classNameComponent ?? 'checkboxContainer ' + classNameWrapper
        }
        hidden={hidden}
      >
        {value === true || checked === true ? (
          <input
            name={name ?? id}
            id={id || element?.name}
            visibility={visibility}
            hidden={hidden}
            type="checkbox"
            checked="checkBoxSelected"
            onChange={handleChange}
            className={'regularCheckbox' + className}
            style={styles}
            disabled={disabled}
            {...rest}
            aria-label={label || id }
          />
        ) : (
          <input
            name={name ?? id}
            id={id}
            type="checkbox"
            checked={checkBoxSelected}
            onChange={handleChange}
            disabled={disabled}
            className={'regularCheckbox' + className}
            style={styles}
            // aria-label ={name}
            {...rest}
            aria-label={label || id }
          />
        )}
        <div
          htmlFor={id}
          className={
            value
              ? 'checkboxLabel form-check-label checked text-wrap d-inline-block '
              : 'form-check-label checkboxLabel text-wrap d-inline-block '
          }
        // htmlFor={name ?? id}

        >
          {label}
        </div>
      </div>
    </div>
  );
};
Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  visibility: PropTypes.string,
  hidden: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
  disabled: PropTypes.bool,
  elementStyle: PropTypes.object,
  isForInsuredWaive: PropTypes.bool,
  hideElement: PropTypes.bool,
};

Checkbox.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: false,
  visibility: '',
  hidden: false,
  onChange: null,
  className: '',
  classNameWrapper: '',
  disabled: false,
  elementStyle: {},
  isForInsuredWaive: false,
  hideElement: false,
};
export default Checkbox;
