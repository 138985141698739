import React, { Suspense,useState } from 'react';
import Loader from '../../../Atoms/Loader';
import './styles.css';
import BaseLayout from '../../../Molecules/CustomSection/SingleFormLayout/index';

const EmployeeSearch = (props) => {
  const [showLoader, setShowLoader] = useState(false);

  if (showLoader) {
      return <Loader loading={showLoader} />;
  }
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        {/*  <MessageComponent /> */}
        <div className="formWrapper">
          <BaseLayout formLayoutType={'SearchEmployee'} formName={'SearchForAnEmployee'}/>
        </div>
      </Suspense>
    </div>
  );
};

export default EmployeeSearch;