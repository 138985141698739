import React from 'react';
import PropTypes from 'prop-types';
import './style.css'
const TextInput = ({ label, name, placeholder, value, onChange, onBlur,type = 'text', className, ...props }) => {
  return (
    <div className="text-input">
      <label htmlFor = {name || label}>{label}</label>{props.required ? <span className='form-mandatory'> * </span> : ''}
      <input
        id={name || label}
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`${type === 'label' ? 'inputLabel' : ''}`}
        {...props}
      />
    </div>
  );
};
TextInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
}
export default TextInput;
