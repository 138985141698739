import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'hideShowRow',
  initialState: {
    dependentAddress: {},
    liveAtSameAddress: {},
    hideEl: false,
    hideBeneficiaryAddress: false,
    readOnlyDataLine1:'',
    readOnlyEditDataLine1: [],
    readOnlyDataLine2: [],
    readOnlyEditDataLine2: [],
    hideFields: [],
    city: '',
    state:'',
    zipCode:'',
    cityEdit: '',
    stateEdit:'',
    zipCodeEdit:'',
  },
  reducers: {
    STORE_DEPENDENT_ADDRESS: (hideShowRow, action) => {
      hideShowRow.dependentAddress =  action.payload;
      // hideShowRow.dependentAddress.value=  action.payload.value
    },
    STORE_LIVE_AT_SAME_ADDRESS: (hideShowRow, action) => {
      hideShowRow.liveAtSameAddress =  action.payload;
    },
    HIDE_FIELDS: (hideShowRow, action) => {
      hideShowRow.hideFields = action.payload;
    },

    HIDE_ROW: (hideShowRow, action) => {
      hideShowRow.hideEl=  action.payload
    },

    HIDE_BENEFICIARY_ADDRESS: (hideShowRow, action) => {
      hideShowRow.hideBeneficiaryAddress=  action.payload;
    },

    STORE_LINE1: (hideShowRow, action) => {
      return {
        ...hideShowRow,
        readOnlyDataLine1:  action.payload,
      };
    },
    STORE_EDIT_LINE1: (hideShowRow, action) => {
      return {
        ...hideShowRow,
        readOnlyEditDataLine1:  action.payload,
      };
    },
    STORE_LINE2: (hideShowRow, action) => {
      return {
        ...hideShowRow,
        readOnlyDataLine2:  action.payload,
      };
    },
    STORE_EDIT_LINE2: (hideShowRow, action) => {
      return {
        ...hideShowRow,
        readOnlyEditDataLine2:  action.payload,
      };
    },
    STORE_CITY: (hideShowRow, action) => {
      return {
        ...hideShowRow,
        city: [action.payload],
      };
    },
    STORE_EDIT_CITY: (hideShowRow, action) => {
      return {
        ...hideShowRow,
        cityEdit: action.payload,
      };
    },
    STORE_STATE: (hideShowRow, action) => {
      return {
        ...hideShowRow,
        state: [action.payload],
      };
    },
    STORE_EDIT_STATE: (hideShowRow, action) => {
      return {
        ...hideShowRow,
        stateEdit: action.payload,
      };
    },
    STORE_ZIP_CODE: (hideShowRow, action) => {
      return {
        ...hideShowRow,
        zipCode: action.payload,      };
    },
    STORE_EDIT_ZIP_CODE: (hideShowRow, action) => {
      return {
        ...hideShowRow,
        zipCodeEdit: action.payload,
      };
    },
    CLEAR_ADDRESS: (hideShowRow, action) => {
      return {
        readOnlyDataLine1: [],
        readOnlyDataLine2: [],
        streetNo:'',
        streetName:'',
        city: '',
        state:'',
        zipCode:'',
        country:'',
      }
    },
  },
});

export const {STORE_DEPENDENT_ADDRESS, STORE_LIVE_AT_SAME_ADDRESS, HIDE_ROW, HIDE_BENEFICIARY_ADDRESS, STORE_LINE1, STORE_LINE2, CLEAR_ADDRESS, STORE_CITY, STORE_STATE, STORE_ZIP_CODE,
               STORE_EDIT_ZIP_CODE,STORE_EDIT_STATE,  STORE_EDIT_CITY,STORE_EDIT_LINE1 ,STORE_EDIT_LINE2, HIDE_FIELDS } = slice.actions;

export default slice.reducer;
