import React, { useState, useEffect } from 'react';
import Icon from '../Icon';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import './styles.css';

const Section = (props) => {
  const {
    id,
    name,
    label,
    value,
    defaultValue,
    onChange,
    onBlur,
    className,
    classNameWrapper,
    hidden,
    required,
    requiredValidation,
    element,
    rows,
    type,
    disabled,
    formik,
    hideElement,
    currentStep,
    link,
    linkText,
    divScroll,
    scroll,
    style
  } = props;


  const [focus, setFocused] = useState(false);
  let additionalClasses = hideElement ? 'hideElement' : '';


  const disclosure = useSelector(
    (storeState) =>
    {
      let itemCode = 'DISCSTAT';
      if(currentStep === 'Travel') {
          itemCode = 'TRAVELDISC'
      }
      let x =
        storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result?.filter(
          (data) => data.code == itemCode && data.contenttype != 'crtNone'
        );
      if (x == undefined) {
        return '';
      } else {
        return x[0]?.content;
      }
    }
  );

    

  /** Set initial focus based on defaultValue */
  useEffect(() => {
    if (defaultValue) {
      onChange &&
        onChange({
          target: {
            name: name || id,
            value: defaultValue,
          },
        });
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      setFocused(value != null);
    }
  }, [value]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleChange = (event) => {
    event.preventDefault();
    onChange && onChange(event);
    props.parentTextAreaAction && props.parentTextAreaAction(event, formik.values);
  };

  const handleBlur = (event) => {
    event.preventDefault();
    if (!props.value) setFocused(false);
    onBlur && onBlur(event);
  };
  let classes = disabled ? `elementDisabled ${className}` : className;
  let paragraphContent = value && value.split("#")
       
  return (
    <div
      className={
        'floatingLabel pb-3 py-0 pt-0 mt-0 displayContent ' + classNameWrapper + additionalClasses
      }
      hidden={hidden}
    >
    


  { disclosure&&   <div style={style} className={name == 'IdTheft.disclosureStatement' ? "disclousreIdTheft sectionClass col-12 col-md-12 scrollbar" :
        name == 'Travel.disclosureStatement' ? "disclousreTravel sectionClass col-12 col-md-12 scrollbar" :
          name == 'CriticalIllness.disclosureStatement' ? "disclousreCriticalIllness sectionClass col-12 col-md-12 scrollbar" :
          name == 'PetAssureNation.disclosureStatement' ? "disclousrePetAssureNation sectionClass col-12 col-md-12 scrollbar" :
          name == 'SupplementalHealthInsurance.disclosureStatement' ? "disclousreSupplementalHealthInsurance sectionClass col-12 col-md-12 scrollbar" :
            name == 'CoverageSelection.disclosureStatement' ? "disclousreCoverage sectionClass col-12 col-md-12 scrollbar" : "disclousreHospital sectionClass col-12 col-md-12 scrollbar"}>

      {disclosure&&  <section>
      { currentStep!="PetAssureNation" &&  <h3>Disclosure Statement</h3>}
        
            <div
      dangerouslySetInnerHTML={{__html: disclosure}}
    />
        </section>}
      </div>}

      {label && (
        <label className={focus ? 'isFocused' : ''} htmlFor={name || id}>
          {label}
        </label>
      )}
    </div>
  );
};
Section.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
};
Section.defaultProps = {
  value: '',
  hidden: false,
  className: '',
  classNameWrapper: '',
  type: 'text',
  required: false,
  defaultValue: null,
};

export default Section;
