import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { constantsList, reduxConstants } from '../../../../Common/Constants';
import { callConstants } from '../../../../Store/Slices/Entities/constants';
import Loader from '../../../Atoms/Loader';
import formFields from '../Content/LoginConfig.json';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveLoginConfig } from '../Actions/saveConfig';
import { retrieveConfig } from '../Actions/retrieveConfig';
import SuccessPage from '../Common/SuccessPage';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response';
import {
  Checkbox,
  TextInput,
  TextArea,
  Button,
  Label,
} from '../../../Molecules/CustomSection/Atoms';

import '../styles.css';
import { CLEAR_ALERT_MESSAGES, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { getErrorMessages } from '../../../Molecules/CustomSection/Helper/MappingHelper';
import { MessageComponent } from '../../../Atoms';

const LoginConfiguration = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { confirmation, formReferenceLinkText } = formFields;

  const dispatch = useDispatch();
  const configDataRedux = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.loginConfigInfo?.configuration
  );

  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};

  const { clientId } = useSelector((storeState) => storeState.auth.userAuth);


  const formik = useFormik({
    initialValues: {
      // welcomeMsgByClientToggle: configData?.welcomeMsgByClientToggle,
      // welcomeMsgByClientEdit: configData?.employeeIdPlaceholder,
      welcomeTitleByClientEditValue: configDataRedux?.welcomeTitle || '',
      welcomeDescTitleByClientEditValue: configDataRedux?.welcomeMsg || '',
      titleColorByClient: configDataRedux?.titleColor,
      msgColorByClient: configDataRedux?.msgColor,
      clientLogoToggle: configDataRedux?.hideLogo || false,
      showChat: configDataRedux?.showChat || false,
      hideRecaptcha: configDataRedux?.hideRecaptcha || false,
      fileByClient: configDataRedux?.fileByClient,
      fileName: configDataRedux?.logoName,
    },

    // validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      formik.resetForm();
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const saveRegisterConfig = async () => {
    let data = {
      loginConfigInformation: {
        // noOfDaysChangeField: formik?.values?.stepsToEnrollEdit,
        // noOfDaysChangeFieldValue: formik?.values?.stepsToEnrollEditValue,
        // qleWidgetToggle: formik?.values?.stepsToEnrollToggle,
        welcomeTitleByClientEditValue: formik?.values?.welcomeTitleByClientEditValue,
        welcomeDescTitleByClientEditValue: formik?.values?.welcomeDescTitleByClientEditValue,
        titleColorByClient: formik?.values?.titleColorByClient,
        msgColorByClient: formik?.values?.msgColorByClient,
        clientLogoToggle: formik?.values?.clientLogoToggle,
        showChat: formik?.values?.showChat,
        hideRecaptcha: formik?.values?.hideRecaptcha,
        fileByClient: formik?.values?.fileByClient,
        fileName: formik?.values?.fileByClient,
      },
    };
    setShowSuccess(null);
    //Api Call here
    let saveResponse = await saveLoginConfig(
      data,
      clientId,
      formFields.apiCall.submit,
      setShowLoader
    );
    if (saveResponse?.status == 200) {
      setShowSuccess(true);
    } else {
      dispatch(
        SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'createRetriveInfoFromDb',
          show: true,
          page: 'Configurator',
        })
      );
      setShowSuccess(false);
    }
  };

  useEffect(() => {
    // //Api Call here
    setShowLoader(true);
    const callAPI = async () => {
      let response = await retrieveConfig(clientId, formFields.apiCall.onload);
      if (response?.status !== 200) {
        setShowSuccess(null);
        setTimeout(() => {
          dispatch(
            SET_ALERT_MESSAGE({
              type: 'error',
              errorCode: '520',
              continue: true,
              id: 'getLoginConfiguration',
              show: true,
              page: 'Configurator',
            })
          );
          setShowSuccess(false);
        }, 50);
      }
      dispatch(
        onLoadAPI({
          loginConfigInfo: response,
          isAPICallInProgress: false,
        })
      );
      setShowLoader(false);
    };
    try {
      callAPI();
    } catch (error) {
     
    }
  }, [loggedUserEntity]);

  useEffect(() => {
    dispatch(CLEAR_ALERT_MESSAGES([]));
  }, [formik.values]);

  const handleChange = (event) => {
    const file = event.currentTarget.files;
    formik.setFieldValue('fileByClient', file);
  };

  if (showLoader) {
    // loader to show untill API call gets completed
    return <Loader loading={showLoader} />;
  }

  const hasErrors = getErrorMessages('Configurator');

  return (
    <div>
      <MessageComponent currentPage={'Configurator'} setShowLoader={setShowLoader} />
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper">
          {!showSuccess && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="config">
                <div className="col-12 px-lg-5 px-md-5 px-sm-0">
                  <div class="row py-3 px-3">
                    <h4>{formFields.panelHeader.label}</h4>
                  </div>

                  <div class="row">
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dataField.label}
                        inputId={formFields.dataField.label}
                      />
                    </div>

                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.editablePlaceHolderLabelValue.label}
                        inputId={formFields.editablePlaceHolderLabelValue.label}
                      />
                    </div>
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxToggle.label}
                        inputId={formFields.checkboxToggle.label}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.welcomeTextContainer.label}
                        inputId={formFields.welcomeTextContainer.label}
                      />
                    </div>

                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextArea
                        id={formFields.welcomeTitleByClientEditValue.id}
                        name={formFields.welcomeTitleByClientEditValue.name}
                        placeholder={formFields.welcomeTitleByClientEditValue.placeholder}
                        type={formFields.welcomeTitleByClientEditValue.type}
                        {...formik.getFieldProps(formFields.welcomeTitleByClientEditValue.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.welcomeDescTitleByClient.label}
                        inputId={formFields.welcomeDescTitleByClient.label}
                      />
                    </div>
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextArea
                        id={formFields.welcomeDescTitleByClientEditValue.id}
                        name={formFields.welcomeDescTitleByClientEditValue.name}
                        placeholder={formFields.welcomeDescTitleByClientEditValue.placeholder}
                        type={formFields.welcomeDescTitleByClientEditValue.type}
                        {...formik.getFieldProps(formFields.welcomeDescTitleByClientEditValue.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.titleColorByClientText.label}
                        inputId={formFields.titleColorByClientText.label}
                      />
                    </div>
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextInput
                        id={formFields.titleColorByClient.id}
                        name={formFields.titleColorByClient.name}
                        type={formFields.titleColorByClient.type}
                        {...formik.getFieldProps(formFields.titleColorByClient.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.msgColorByClientText.label}
                        inputId={formFields.msgColorByClientText.label}
                      />
                    </div>
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right inputConfig">
                      <TextInput
                        id={formFields.msgColorByClient.id}
                        name={formFields.msgColorByClient.name}
                        type={formFields.msgColorByClient.type}
                        {...formik.getFieldProps(formFields.msgColorByClient.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.fileByClientText.label}
                        inputId={formFields.fileByClientText.label}
                      />
                    </div>
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label text={configDataRedux?.logoName} inputId={configDataRedux?.logoName} />
                      <TextInput
                        id={formFields.fileByClient.id}
                        name={formFields.fileByClient.name}
                        type={formFields.fileByClient.type}
                        onChange={handleChange}
                        // {...formik.getFieldProps(formFields.fileByClient.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.clientLogoToggleText.label}
                        inputId={formFields.clientLogoToggleText.label}
                      />
                    </div>

                    <div className="col-2 col-md-6 col-lg-2 py-2 no-padding-right"></div>
                    <div className="col-2 col-md-6 col-lg-2 py-2 no-padding-right"></div>
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.clientLogoToggle.id}
                        name={formFields.clientLogoToggle.name}
                        checked={formik.values.clientLogoToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.clientLogoToggle.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-12 col-md-6 col-lg-6 py-2">
                      <Label text={formFields.note.label} inputId={formFields.note.label} />
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.showChatText.label}
                        inputId={formFields.showChatText.label}
                      />
                    </div>
                    <div className="col-2 col-md-6 col-lg-2 py-2 no-padding-right"></div>
                    <div className="col-2 col-md-6 col-lg-2 py-2 no-padding-right"></div>
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.showChat.name.id}
                        name={formFields.showChat.name.name}
                        checked={formik.values.showChat}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.showChat.name)}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Label
                        text={formFields.hideRecaptchaText.label}
                        inputId={formFields.hideRecaptchaText.label}
                      />
                    </div>
                    <div className="col-2 col-md-6 col-lg-2 py-2 no-padding-right"></div>
                    <div className="col-2 col-md-6 col-lg-2 py-2 no-padding-right"></div>
                    <div className="col-4 col-md-6 col-lg-3 py-2 no-padding-right">
                      <Checkbox
                        id={formFields.hideRecaptcha.name.id}
                        name={formFields.hideRecaptcha.name.name}
                        checked={formik.values.hideRecaptcha}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.hideRecaptcha.name)}
                      />
                    </div>
                  </div>

                  <div className="row w-100 form-btnGroup py-5">
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 py-2 w-100">
                      <Button
                        className="btn btn-primary  primaryBackground mr-4"
                        onClick={saveRegisterConfig}
                        disabled={hasErrors}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}

          {showSuccess && <SuccessPage {...confirmation}></SuccessPage>}
        </div>
      </Suspense>
    </div>
  );
};

export default LoginConfiguration;
