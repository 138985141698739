// InactivityModal.js
import React from 'react';
import Modal from './Molecules/CustomSection/Atoms/Modal';
import useInactivityDetection from './Molecules/CustomSection/Hooks/useInactivityDetection';


const InactivityModal = ({ ur, dispatch, user_name }) => {
  const { showModal, handleYesClick, handleNoClick } = useInactivityDetection(user_name, ur, dispatch);
  return (
    <Modal
      showModal={showModal}
      handleClose={handleNoClick}
      title="Session Expiration Warning"
      body={<p className='mb-2'> Your session is about to expire! Do you want to stay signed in?</p>}
      showHeaderLine = {false}
      showFooterLine = {false}
      showCloseIcon = {false}
      footerButtons={[
        {
          text: 'Yes',
          onClick: handleYesClick,
          className: 'btn-primary'
        },
        {
          text: 'No',
          onClick: handleNoClick,
          className: 'btn-secondary'
        }
      ]}
      titlePosition="center" 
    />
  );
};

export default InactivityModal;
