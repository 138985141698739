import React, { useState } from 'react';
import RadioButton from '../../../Atoms/RadioButton';
import Button from '../../../Atoms/Buttons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Atoms/Loader';
import { SHOW_LOADER } from '../../../../../../Store/Slices/Loader/index';
import http from '../../../../../../Common/Services/HttpService';
import appConfig from '../../../../../../appConfig';
import { getBasicAuthHeaderValue } from '../../../Helper/MFAHelper'
import { SET_MFA_BASIC_AUTH_VALUE } from '../../../../../../Store/Slices/Registeration';
import { errorMessages } from '../../../Atoms/FormValidation'
import { event } from 'jquery';

const ChangeMFAMethod = ({ flow, handleMFABack, onMethodChange, selectedMethod, onError, otpMedia, previousMethod }) => {
    const { registeration } = useSelector((storeState) => storeState);
    const { loader } = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: { radioOptions: '' },
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            handleMFAChange();
        },
    });

    const radioOptions = []
    if (!otpMedia || otpMedia === 'email') {
        radioOptions.push({ value: 'email', label: `Email to ${registeration.maskedEmail}` });
    }
    if (!otpMedia || otpMedia === 'phone') {
        radioOptions.push({ value: 'sms', label: 'Text to (xxx) xxx xxxx' })
        radioOptions.push({ value: 'call', label: 'Phone Call to (xxx) xxx xxxx' })
    }
    const handleRadioChange = (e) => {
        onMethodChange(e.target.value)
    };

    const handleCancelClick = () => {
        onMethodChange(previousMethod);
        handleMFABack(); 
    };

    const handleMFAChange = (e) => {
        dispatch(SHOW_LOADER(true));
        let basicAuthorizationValue = getBasicAuthHeaderValue();
        basicAuthorizationValue && dispatch(SET_MFA_BASIC_AUTH_VALUE(basicAuthorizationValue));

        const reqObj = {
            "login": registeration.login,
            "reason": 'password',
            "channel": selectedMethod
        }
        http
            .post(
                appConfig.SSP_AUTH_URL + "/stateful/user-mgmt/send-challenge-ex",
                reqObj,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Basic ${basicAuthorizationValue}`,
                        'X-REQUEST-ID': Math.random(200),
                    }
                }
            )
            .then(async (resp) => {
                dispatch(SHOW_LOADER(false));
                handleMFABack();
            })
            .catch((e) => {
                
                dispatch(SHOW_LOADER(false));
                onError && onError(errorMessages(e));
                handleMFABack();
            });
    };
    return (
        <>
            {loader && <Loader loading={loader} />}
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="container-fluid retrievePassword">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="form-side-header">Reset Password</h1>
                            <RadioButton
                                label={'Change the method of your choice to receive security code.'}
                                options={radioOptions}
                                selectedOption={selectedMethod}
                                onChange={handleRadioChange}
                                className={'forMFA'}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mfaBtnWrapper">
                    <div className="col-sm-12 col-md-11 py-2 mr-4 text-md-right">
                        <Button
                            type="button"
                            className="btn btn-outline-primary primaryOutline btn-outline-primary-contrast mr-4"
                            onClick={handleCancelClick}
                        >
                            {flow === 'resetPassword' ? 'Cancel' : 'Back'}
                        </Button>
                        <Button
                            type="submit"
                            className="btn btn-primary primaryBackground btn-primary-contrast"
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ChangeMFAMethod;
