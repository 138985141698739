import React, { useState } from 'react';
import RadioButton from '../../Atoms/RadioButton';
import Button from '../../Atoms/Buttons';
import Loader from '../../Atoms/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import http from '../../../../../Common/Services/HttpService';
import appConfig from '../../../../../appConfig';
import { getBasicAuthHeaderValue } from '../../Helper/MFAHelper'
import { SET_MFA_BASIC_AUTH_VALUE } from '../../../../../Store/Slices/Registeration';
import { SHOW_LOADER } from '../../../../../Store/Slices/Loader/index';
import { errorMessages } from '../../Atoms/FormValidation'

const ChangeMFAMethod = ({ handleMFABack, onMethodChange, selectedMethod, onPrev, onError }) => {

    const { registeration } = useSelector((storeState) => storeState);
    const { loader } = useSelector((state) => state.loader);
    const [err, setErr] = useState('');
    const dispatch = useDispatch();
    const radioOptions = [
        { value: 'sms', label: 'SMS/Text to (xxx) xxx xxxx' },
        { value: 'call', label: 'Phone Call to (xxx) xxx xxxx' },
    ];

    const formik = useFormik({
        initialValues: { radioOptions: '' },
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            handleMFAChange();
        },
    });


    const handleRadioChange = (e) => {
        onMethodChange(e.target.value);
    };

    const handleMFAChange = (e) => {

        dispatch(SHOW_LOADER(true));
        let basicAuthorizationValue = getBasicAuthHeaderValue();
        basicAuthorizationValue && dispatch(SET_MFA_BASIC_AUTH_VALUE(basicAuthorizationValue));

        const reqObj = {
            "employeeData": registeration.employeeInfo?.trim(),
            "appCode": registeration.appCode?.trim(),
            "channel": registeration.preferredChannel?.trim(),
        }
        http
            .post(
                appConfig.SSP_AUTH_URL + "/stateful/user-mgmt/retrieve-id-ex/1",
                reqObj,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Basic ${basicAuthorizationValue}`,
                        'X-REQUEST-ID': Math.random(200),
                    }
                }
            )
            .then(async (resp) => {
                if(resp.status === 200) handleMFABack();
                dispatch(SHOW_LOADER(false));
            }).catch((error) => {
                onError && onError(errorMessages(error));
                dispatch(SHOW_LOADER(false));
                handleMFABack();
            });
    };

    return (
        <>
            {loader && <Loader loading={loader} />}
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="container-fluid retrievePassword">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="form-side-header">Retrieve User ID</h1>
                            <RadioButton
                                label={'Change the method of your choice to receive security code.'}
                                options={radioOptions}
                                selectedOption={selectedMethod}
                                onChange={handleRadioChange}
                                className={'forMFA'}
                            />
                        </div>
                    </div>
                </div>
                {err &&
                    <div className="row">
                        <span className='APIErr'>{err}</span>
                    </div>
                }
                <div className="row mfaBtnWrapper">
                    <div className="col-sm-12 col-md-11 py-2 mr-4 text-md-right">
                        <Button
                            type="button"
                            className="btn btn-outline-primary primaryOutline btn-outline-primary-contrast mr-4"
                            onClick={handleMFABack}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className="btn btn-primary primaryBackground btn-primary-contrast"
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ChangeMFAMethod;
