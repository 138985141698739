import React, { Suspense, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useField } from 'formik';
import * as Yup from 'yup';
import formFields from '../../Content/FormFields.json';
import './beneficiary-styles.css';
import constants from '../../Content/Constants.json';
import { useDispatch, useSelector } from 'react-redux';
import BeneficiaryCollapse from './BeneficiaryCollapse';

const BeneficiaryModel = ({
  beneficiary,
  onSubmit,
  onSubmitGlobal = () => { },
  onCancel,
  initialValues = {},
  beneficiaryOptions,
  allowedBeneficiaryKind,
  layoutData = {},
  dataSelected = {},
  beneficiaryDataFormatter = () => { },
  actionsFormatted = {},
  isEdit = false,
}) => {
  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
  const employeeProfile = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile);
  //Configuration for beneficiary
  const configDataRedux = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.beneficiary
      ?._Result?.data?.[0]
  );
  const beneficiaryPersonalInfoConfig = configDataRedux?.beneficiaryPersonalInfo[0]
  const beneficiaryAddressInfoConfig = configDataRedux?.beneficiaryAddressInfo[0]
  const maxDateOfBirth = new Date().toISOString().split("T")[0];

  const [formError, setFormError] = useState('');

  // Variable to Manage Global Beneficiaries
  const [beneficiariesToAdd, setBeneficiariesToAdd] = useState([]);


  const getInitialShowAddressFields = (initialValues) => {
    let sameProfileAddress = initialValues?.liveInSameAddress;
    if (initialValues?.beneficiaryAddressLine1 === employeeProfile?.line1
      && initialValues?.beneficiaryAddressLine2 === employeeProfile?.line2
      && initialValues?.beneficiaryCity === employeeProfile?.city
      && initialValues?.beneficiaryState === employeeProfile?.state
      && initialValues?.beneficiaryCountry === employeeProfile?.country
      && initialValues?.beneficiaryZipCode === employeeProfile?.zipCode) {
      sameProfileAddress = true;
    }
    return {
      mailingAddress: initialValues && initialValues.mailingAddressOptions === 'yes',
      liveInSameAddress: sameProfileAddress,
    }
  };

  const [showAddressFields, setShowAddressFields] = useState(
    getInitialShowAddressFields(initialValues)
  );

  const [beneficiaryAddress, setbeneficiaryAddress] = useState('');
  const [showMaskedInput, setShowMaskedInput] = useState(false);



  const getFormatSSN = (input) => {
    if (!input) return input;

    let ssnTemp = input.replace(/[^\d]/g, '');
    let ssnTempLen = ssnTemp.length;

    if (ssnTempLen < 3) return ssnTemp;
    if (ssnTempLen < 5) {
      return `${ssnTemp.slice(0, 3)}-${ssnTemp.slice(3)}`;
    }

    return `${ssnTemp.slice(0, 3)}-${ssnTemp.slice(3, 5)}-${ssnTemp.slice(5)}`;
  };
  const handleSSNChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    const formattedValue = value
      .slice(0, 9)
      .replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
        return [p1, p2, p3].filter((part) => part.length > 0).join('-');
      });
    formik.setFieldValue('SSN', formattedValue);
  };

  const handleSSNBlur = (e) => {
    if (e.target.value != "") {
      setShowMaskedInput(!formik.errors.SSN);
    }
    formik.handleBlur(e);
  };

  const validationSchema = Yup.object({
    beneficiaryFirstName: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual' || pageLoadOptionSelected === 'individual'
        ? schema.matches(/^[a-zA-ZÀ-ž' -]+$/, 'No special characters allowed').required('Please enter your First Name')
        : Yup.string()
    }),
    beneficiaryMiddleName: Yup.string()
      .matches(/^[a-zA-ZÀ-ž' -]+$/, 'No special characters allowed').when([], {
        is: () => beneficiaryPersonalInfoConfig?.beneficiaryMiddleName?.requiredField,
        then: Yup.string()
          .required('Middle Name is required')
      })
    ,
    beneficiaryLastName: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual' || pageLoadOptionSelected === 'individual'
        ? schema.matches(/^[a-zA-ZÀ-ž' -]+$/, 'No special characters allowed').required('Please enter your Last Name')
        : Yup.string()
    }),
    // SSN: Yup.string().when('beneficiaryOptions', (val, schema) => {
    //   return val === 'individual'
    //     ? schema.required('Please enter your SSN').matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must be in the format XXX-XX-XXXX')
    //     : Yup.string()
    // }),
    SSN: formFields.SSN.isRequired ? (Yup.string()
      .required('Please enter your SSN')
      .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must be in the format XXX-XX-XXXX')) : (Yup.string()
        .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must be in the format XXX-XX-XXXX')).when([], {
          is: () => beneficiaryPersonalInfoConfig?.SSN?.requiredField,
          then: Yup.string()
            .required('SSN is required')
        }),
    beneficiaryRelationship: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual' || pageLoadOptionSelected === 'individual'
        ? schema.required("Relationship is required")
        : Yup.string()
    }),
    beneficiaryGender: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual' || pageLoadOptionSelected === 'individual'
        ? schema.required("Gender is required")
        : Yup.string()
    }),
    beneficiaryBirthDate: Yup.date().when('beneficiaryOptions', (val, schema) => {
      return val === 'individual' || pageLoadOptionSelected === 'individual'
        ? schema.required("Please enter your DOB").min(new Date('01/01/1900'), 'Invalid DOB').max(new Date(), 'Invalid DOB')
        : Yup.string()
    }),

    beneficiaryAddressLine1: Yup.string().when(['mailingAddressOptions', 'liveInSameAddress'], (mailingAddressOptions, liveInSameAddress, schema) => {
      return mailingAddressOptions === 'yes' && !liveInSameAddress
        ? Yup.string().required('Address Line 1 is required').matches(/^[a-zA-Z0-9 ,#/.\-]*$/, 'Address is invalid')
        : Yup.string()
    }),
    beneficiaryAddressLine2: Yup.string().matches(/^[a-zA-Z0-9 ,#/.\-]*$/, 'Address is invalid'),

    beneficiaryCity: Yup.string().when(['mailingAddressOptions', 'liveInSameAddress'], (mailingAddressOptions, liveInSameAddress, schema) => {
      return mailingAddressOptions === 'yes' && !liveInSameAddress
        ? schema.required('City is required').matches(/^(?!.*[ ][ ])^[a-zA-Z0-9 -]+$/, "City is Invalid")
        : Yup.string()
    }),
    beneficiaryState: Yup.string().when(['mailingAddressOptions', 'liveInSameAddress'], (mailingAddressOptions, liveInSameAddress, schema) => {
      return mailingAddressOptions === 'yes' && !liveInSameAddress
        ? schema.required('State is required').matches(/^[a-zA-Z ]+$/, "State is Invalid")
        : Yup.string()
    }),
    beneficiaryCountry: Yup.string().when(['mailingAddressOptions', 'liveInSameAddress'], (mailingAddressOptions, liveInSameAddress, schema) => {
      return mailingAddressOptions === 'yes' && !liveInSameAddress
        ? schema.required('Country is required').matches(/^[a-zA-Z]+$/, "Country is Invalid")
        : Yup.string()
    }),
    beneficiaryZipCode: Yup.string().when(['mailingAddressOptions', 'liveInSameAddress'], (mailingAddressOptions, liveInSameAddress, schema) => {
      return mailingAddressOptions === 'yes' && !liveInSameAddress
        ? schema.required('Zip Code is required').matches(/^[0-9]*$/, "Zip Code must all be digits")
        : Yup.string()
    }),
    legalName: Yup.string().when('beneficiaryOptions', (val, schema) => {
      return val === 'trust' || pageLoadOptionSelected === 'trust'
        ? schema.required('Legal Name is required').matches(/^[a-zA-Z\s]+$/, 'No special characters allowed other than space')
        : Yup.string()
    }),
  });

  const formik = useFormik({
    initialValues: initialValues || {
      legalName: '',
      beneficiaryIrrevocable: 'no',
      beneficiaryCollateral: 'no',
      beneficiaryFirstName: '',
      beneficiaryMiddleName: '',
      beneficiaryLastName: '',
      SSN: '',
      beneficiaryRelationship: '',
      beneficiaryOptions: 'individual',
      beneficiaryPercentage: 0,
      beneficiaryType: 'Primary',
      beneficiaryGender: '',
      beneficiaryBirthDate: '',
      mailingAddressOptions: 'no',
      liveInSameAddress: '',
      beneficiaryAddressLine1: '',
      beneficiaryAddressLine2: '',
      beneficiaryCity: '',
      beneficiaryState: '',
      beneficiaryZipCode: '',
      beneficiaryCountry: '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values);
      setBeneficiariesToAdd([]);
      formik.resetForm();
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const handleSaveBeneficiaries = () => {
    onSubmitGlobal(beneficiariesToAdd)
    formik.resetForm();
    setbeneficiaryAddress([]);
  }

  const maskedSSN = showMaskedInput ? `XXX-XX-${formik?.values?.SSN?.slice(-4)}` : '';

  const handleBeneficiaryOptionChange = (e) => {
    formik.setFieldValue(formFields.beneficiaryOptions.name, e.target.value);
  };

  const handleAddressFields = (profile) => {
    formik.setFieldValue(formFields.beneficiaryAddressLine1.name, profile?.line1);
    formik.setFieldValue(formFields.beneficiaryAddressLine2.name, profile?.line2);
    formik.setFieldValue(formFields.beneficiaryCity.name, profile?.city);
    formik.setFieldValue(formFields.beneficiaryState.name, profile?.state);
    formik.setFieldValue(formFields.beneficiaryZipCode.name, profile?.zipCode);
    formik.setFieldValue(formFields.beneficiaryCountry.name, profile?.country);
    formik.setFieldValue(formFields.liveInSameAddress, true);
  };

  const resetAddressFields = () => {
    formik.setFieldValue(formFields.beneficiaryAddressLine1.name, '');
    formik.setFieldValue(formFields.beneficiaryAddressLine2.name, '');
    formik.setFieldValue(formFields.beneficiaryCity.name, '');
    formik.setFieldValue(formFields.beneficiaryState.name, '');
    formik.setFieldValue(formFields.beneficiaryZipCode.name, '');
    formik.setFieldValue(formFields.beneficiaryCountry.name, '');
  };

  const handleAddressOptionChange = (e) => {
    const value = e.target.value;
    formik.setFieldValue(formFields.mailingAddressOptions.name, value);
    setShowAddressFields((prev) => ({ ...prev, mailingAddress: value === 'yes' }));
    if (e.target.value === 'no') {
      formik.setFieldValue(formFields.liveInSameAddress.name, false);
      setShowAddressFields((prev) => ({ ...prev, liveInSameAddress: false }));
      setbeneficiaryAddress('');
      resetAddressFields();
    }
    setTimeout(() => formik.setFieldTouched(formFields.mailingAddressOptions.name, value));
  };

  const handleLiveInSameAddressChange = (e) => {
    const checked = e.target.checked;
    formik.setFieldValue(formFields.liveInSameAddress.name, checked);
    setShowAddressFields((prev) => ({ ...prev, liveInSameAddress: checked }));
    if (checked) {
      setbeneficiaryAddress(employeeProfile.primaryAddress);
      handleAddressFields(employeeProfile);
    } else {
      resetAddressFields();
    }
  };
  const hasErrors = Object.keys(formik.errors).length > 0;
  if (hasErrors) {

  }

  const handleFormSubmit = async (e) => {
    await formik.validateForm();
    const hasErrors = Object.keys(formik.errors).length > 0;

    if (!hasErrors) {
      onSubmit(formik.values);
      formik.resetForm();
      setFormError('');
    } else {
      setFormError('Please check form fields');
    }
  };

  const allowedRelationships =
    allowedBeneficiaryKind &&
      allowedBeneficiaryKind.allowedIndividualBeneficiaryRelationToPrimaryInsured
      ? allowedBeneficiaryKind.allowedIndividualBeneficiaryRelationToPrimaryInsured.split(',')
      : [];
  const filteredRelationshipOptions = constants?.relationship.filter((option) =>
    allowedRelationships.includes(option.value)
  );

  useEffect(() => {
    if (initialValues?.SSN) {
      formik.setFieldValue('SSN', getFormatSSN(initialValues.SSN));
      setShowMaskedInput(true);
    }
  }, [initialValues?.SSN]);

  const beneficiaryRadioOptions = beneficiaryOptions?.options?.length > 0 ? beneficiaryOptions?.options : formFields.beneficiaryOptions.options;

  // Configuration for trust or individual 
  const shouldShowIndividual = beneficiaryPersonalInfoConfig?.individual?.hideableSection;
  const shouldShowGroupTrust = beneficiaryPersonalInfoConfig?.groupTrust?.hideableSection;
  let pageLoadOptionSelected = !shouldShowIndividual ? 'individual' : 'trust';

  if (formik && formik.values && formik.values.beneficiaryOptions) {
    pageLoadOptionSelected = formik.values.beneficiaryOptions
  }


  // Filter the options for trust/individual based on the conditions
  const filteredOptions = beneficiaryRadioOptions.filter(option => {
    if (option.value === "individual" && !shouldShowIndividual) {
      return true;
    }
    if (option.value === "trust" && !shouldShowGroupTrust) {
      return true;
    }
    return false;
  });
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div class="row">
        {/* Beneficiary Options */}
        <div className="col-12 col-lg-12 col-md-12 col-sm-6">
          <div>
            <div className="radioGroup w-100 beneficiary-options">
              <div className="row">
                <div className="col-12 py-3"></div>
              </div>
              <fieldset>
                <legend class="lable-sr-only" area-hidden='true'></legend>
                {filteredOptions.map((option) => (
                  <div key={option.value} className="custom-control-inline">
                    <label for={`${option.id}s`} className={option.labelClassName}>
                      <input
                        type="radio"
                        id={`${option.id}s`}
                        name={formFields.beneficiaryOptions.name}
                        value={option.value}
                        checked={pageLoadOptionSelected === option.value}
                        onChange={handleBeneficiaryOptionChange}
                        tabIndex={0}
                      />

                      <span>{option.text}</span>
                    </label>
                  </div>
                ))}
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      {pageLoadOptionSelected !== 'existing' && (
        <div class="row">
          <div class="col-12 sdf">
            <div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-mandatory beneficiary d-inline-block mb-2">Fields marked with * are mandatory</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {pageLoadOptionSelected === 'trust' && (
        <>
          <div class="row">
            <div class="col-12  col-md-6">
              <div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-side-big-header d-inline-block mb-2">General Information </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            {/* Legal Name */}
            <div className="col-12 col-lg-4 col-md-4 col-sm-4 py-0 py-md-3">
              <div className="floatingLabel">
                {formFields.legalName.label}
                {formFields.legalName.isRequired && <span> *</span>}
                <div>
                  <label for={formFields.legalName.id} className="lable-sr-only" aria-label={formFields.legalName.label}></label>
                  <input
                    id={formFields.legalName.id}
                    name={formFields.legalName.name}
                    type={formFields.legalName.type}
                    placeholder={formFields.legalName.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.legalName.name)}
                  />

                  {formik.touched.legalName && formik.errors.legalName ? (
                    <div class="form-mandatory">{formik.errors.legalName}</div>
                  ) : null}
                </div>
              </div>
            </div>

            {/* Beneficiary Irrevocable */}
            {/* <div className="col-12 col-lg-3 col-md-3 col-sm-2 pt-2 col-xl-3">
              <div>
                <div className="radioGroup w-100">
                  <div className="row">
                    <div className="col-12 py-3">{formFields.beneficiaryIrrevocable.label}</div>
                  </div>
                  {formFields.beneficiaryIrrevocable.options.map((option) => (
                    <div key={option.value} className="pb-0 custom-control-inline">
                      <label className={option.labelClassName}>
                        <input
                          type="radio"
                          id={option.id}
                          name={formFields.beneficiaryIrrevocable.name}
                          value={option.value}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.beneficiaryIrrevocable === option.value}
                        />
                        <span>{option.text}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}

            {/* Beneficiary Collateral */}
            {/* <div className="col-12 col-lg-3 col-md-4 col-sm-3 pt-2">
              <div>
                <div className="radioGroup w-100">
                  <div className="row">
                    <div className="col-12 py-3">{formFields.beneficiaryCollateral.label}</div>
                  </div>
                  {formFields.beneficiaryCollateral.options.map((option) => (
                    <div key={option.value} className="pb-0 custom-control-inline">
                      <label className={option.labelClassName}>
                        <input
                          type="radio"
                          id={option.id}
                          name={formFields.beneficiaryCollateral.name}
                          value={option.value}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.beneficiaryCollateral === option.value}
                        />
                        <span>{option.text}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
          </div>
        </>
      )}

      {pageLoadOptionSelected === 'existing' && (
        <>
          <div>
            <BeneficiaryCollapse
              layoutData={layoutData}
              dataSelected={dataSelected}
              beneficiaryDataFormatter={beneficiaryDataFormatter}
              setBeneficiariesToAdd={setBeneficiariesToAdd}
              actionsFormatted={actionsFormatted}
              isEdit={isEdit}
            />
          </div>
        </>
      )}

      {pageLoadOptionSelected === 'individual' && (
        <>
          <div class="row">
            <div class="col-12  col-md-6">
              <div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-side-big-header d-inline-block mb-2">Beneficiary Information </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            {/* First Name */}
            <div className="col-12 col-md-4 py-2">
              <div className="floatingLabel">
                {formFields.beneficiaryFirstName.label}
                {formFields.beneficiaryFirstName.isRequired && <span> *</span>}
                <div>
                  <label for={formFields.beneficiaryFirstName.id} className="lable-sr-only" aria-label={formFields.beneficiaryFirstName.label}></label>
                  <input
                    id={formFields.beneficiaryFirstName.id}
                    name={formFields.beneficiaryFirstName.name}
                    type={formFields.beneficiaryFirstName.type}
                    placeholder={formFields.beneficiaryFirstName.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.beneficiaryFirstName.name)}
                  />
                  {formik.touched.beneficiaryFirstName && formik.errors.beneficiaryFirstName ? (
                    <div class="form-mandatory">{formik.errors.beneficiaryFirstName}</div>
                  ) : null}
                </div>
              </div>
            </div>

            {/* Middle Name */}
            {!(beneficiaryPersonalInfoConfig?.beneficiaryMiddleName?.hideableField) && <div className="col-12 col-md-4 py-2">
              <div className="floatingLabel">
                {formFields.beneficiaryMiddleName.label}
                {beneficiaryPersonalInfoConfig?.beneficiaryMiddleName?.requiredField && <span> *</span>}
                <div>
                  <label for={formFields.beneficiaryMiddleName.id} className="lable-sr-only" aria-label={formFields.beneficiaryMiddleName.label}></label>
                  <input
                    id={formFields.beneficiaryMiddleName.id}
                    name={formFields.beneficiaryMiddleName.name}
                    type={formFields.beneficiaryMiddleName.type}
                    placeholder={formFields.beneficiaryMiddleName.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.beneficiaryMiddleName.name)}
                  />
                  {formik.touched.beneficiaryMiddleName && formik.errors.beneficiaryMiddleName ? (
                    <div class="form-mandatory">{formik.errors.beneficiaryMiddleName}</div>
                  ) : null}
                </div>
              </div>
            </div>}

            {/* Last Name */}
            <div className="col-12 col-md-4 py-2">
              <div className="floatingLabel">
                {formFields.beneficiaryLastName.label}
                {formFields.beneficiaryLastName.isRequired && <span> *</span>}
                <div>
                  <label for={formFields.beneficiaryLastName.id} className="lable-sr-only" aria-label={formFields.beneficiaryLastName.label}></label>
                  <input
                    id={formFields.beneficiaryLastName.id}
                    name={formFields.beneficiaryLastName.name}
                    type={formFields.beneficiaryLastName.type}
                    placeholder={formFields.beneficiaryLastName.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.beneficiaryLastName.name)}
                  />
                  {formik.touched.beneficiaryLastName && formik.errors.beneficiaryLastName ? (
                    <div class="form-mandatory">{formik.errors.beneficiaryLastName}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* SSN */}
            <div className="col-12 col-lg-4 col-md-4 col-sm-6 py-2">
              <div className="floatingLabel">
                {formFields.SSN.label}
                {/* {formFields.SSN.isRequired && <span> *</span>} */}
                {formFields.SSN.isRequired && <span> *</span>}
                {beneficiaryPersonalInfoConfig?.SSN?.requiredField && <span> *</span>}
                <div>
                  {showMaskedInput ? (
                    <>
                      <label for="maskedSSN" className="lable-sr-only" aria-label={formFields.SSN.label}></label>
                      <input
                        id="maskedSSN"
                        name="maskedSSN"
                        type={formFields.SSN.type}
                        className="w-100 maskedSSN"
                        autocomplete="off"
                        value={maskedSSN}
                        onFocus={() => setShowMaskedInput(false)}
                      />
                    </>
                  ) : (
                    <>
                      <label for={formFields.SSN.id} className="lable-sr-only" aria-label={formFields.SSN.label}></label>
                      <input
                        id={formFields.SSN.id}
                        name={formFields.SSN.name}
                        type={formFields.SSN.type}
                        placeholder={formFields.SSN.placeholder}
                        className="w-100"
                        maxLength={11}
                        autocomplete="off"
                        value={formik.values.SSN}
                        onChange={handleSSNChange}
                        onBlur={handleSSNBlur}
                      />
                    </>

                  )}
                  {formik.touched.SSN && formik.errors.SSN ? (
                    <div class="form-mandatory">{formik.errors.SSN}</div>
                  ) : null}
                </div>
              </div>
            </div>

            {/* Relationship */}
            <div className="col-12 col-lg-4 col-md-4 col-sm-6 py-2">
              <div className="selectContainer">
                <label for={formFields.beneficiaryRelationship.id}>
                  {formFields.beneficiaryRelationship.label}
                  {formFields.beneficiaryRelationship.isRequired && <span> *</span>}
                </label>
                {filteredRelationshipOptions.length > 0 && (
                  <select
                    id={formFields.beneficiaryRelationship.id}
                    name={formFields.beneficiaryRelationship.name}
                    className="w-100 selectText benificiarySelect"
                    {...formik.getFieldProps(formFields.beneficiaryRelationship.name)}
                    tabIndex={0}
                  >
                    <option value=""></option>
                    {filteredRelationshipOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            {/* Gender */}

            <div className="col-12 col-lg-4 col-md-4 col-sm-6 py-2">
              <div>
                <div className="radioGroup w-100">
                  <div className="row">
                    <div className="col-12 py-1 mb-2 pb-2">
                      {formFields.beneficiaryGender.label}
                      {formFields.beneficiaryGender.isRequired && (
                        <span className="form-mandatory"> *</span>
                      )}
                    </div>
                  </div>
                  <fieldset>
                    <legend class="lable-sr-only" area-hidden='true'></legend>
                    {formFields.beneficiaryGender.options.map((option) => (
                      <div
                        key={option.id}
                        className="custom-control-inline"
                        style={{ marginRight: '0.5rem' }}
                      >
                        <label for={`${option.id}s`} >
                          <input
                            type={formFields.beneficiaryGender.type}
                            id={`${option.id}s`}
                            name={formFields.beneficiaryGender.name}
                            value={option.value}
                            checked={formik.values.beneficiaryGender === option.value}
                            onChange={(e) =>
                              formik.setFieldValue(formFields.beneficiaryGender.name, e.target.value)
                            }
                            tabIndex={0}
                          />
                          <span>{option.label}</span>
                        </label>
                      </div>
                    ))}
                  </fieldset>
                  {formik.touched.beneficiaryGender && formik.errors.beneficiaryGender ? (
                    <div class="form-mandatory">{formik.errors.beneficiaryGender}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4 col-md-4 col-sm-3 py-2">
              <div className="floatingLabel">
                {formFields.beneficiaryBirthDate.label}
                {formFields.beneficiaryBirthDate.isRequired && <span> *</span>}
                <div className="DOB">
                  <label for={formFields.beneficiaryBirthDate.id} className="lable-sr-only" aria-label={formFields.beneficiaryBirthDate.label}></label>
                  <input
                    id={formFields.beneficiaryBirthDate.id}
                    name={formFields.beneficiaryBirthDate.name}
                    type={formFields.beneficiaryBirthDate.type}
                    placeholder={formFields.beneficiaryBirthDate.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.beneficiaryBirthDate.name)}
                    max={maxDateOfBirth}
                    min={'01/01/1990'}
                  />

                  {formik.touched.beneficiaryBirthDate && formik.errors.beneficiaryBirthDate ? (
                    <div class="form-mandatory">{formik.errors.beneficiaryBirthDate}</div>
                  ) : null}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="calendar-alt"
                    className="svg-inline--fa fa-calendar-alt fa-w-14 calendarIcon"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {pageLoadOptionSelected !== 'existing' && (
        <>
          <div class="row address">
            <div class="col-12  col-md-6 address-header">
              <div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-side-big-header d-inline-block mb-2">Address </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 py-3">{formFields.mailingAddressOptions.label}</div>
          </div>
          <div className="address-options">
            <fieldset>
              <legend class="lable-sr-only" area-hidden='true'></legend>
              {formFields.mailingAddressOptions.options.map((option) => (
                <div key={option.id} className="custom-control-inline" style={{ marginRight: '0.5rem' }}>
                  <label for={`${option.id}s`} >
                    <input
                      type={formFields.mailingAddressOptions.type}
                      id={`${option.id}s`}
                      name={formFields.mailingAddressOptions.name}
                      value={option.value}
                      checked={formik.values.mailingAddressOptions === option.value}
                      onChange={handleAddressOptionChange}
                      tabIndex={0}
                    />
                    <span>{option.label}</span>
                  </label>
                </div>
              ))}
            </fieldset>
          </div>
        </>
      )}

      {showAddressFields.mailingAddress && !(beneficiaryAddressInfoConfig?.liveInSameAddress?.hideableField) && (
        <>
          <div className="row">
            <div className="col-12 sdf">
              <div className="checkboxContainer">
                <input
                  checked={showAddressFields.liveInSameAddress}
                  name={formFields.liveInSameAddress.name}
                  id={formFields.liveInSameAddress.id}
                  type={formFields.liveInSameAddress.type}
                  className="regularCheckbox"
                  style={{
                    width: '1.25rem',
                    height: '1.25rem',
                    borderRadius: '0.25rem',
                    border: '0.0625rem solid rgb(204, 204, 204)',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                  {...formik.getFieldProps(formFields.liveInSameAddress.name)}
                  onChange={handleLiveInSameAddressChange}
                  tabIndex={0}
                />
                <label
                  className="form-check-label checkboxLabel text-wrap"
                  for={formFields.liveInSameAddress.id}
                >
                  {formFields.liveInSameAddress.label}
                </label>
              </div>
            </div>
          </div>
        </>
      )}

      {!showAddressFields.liveInSameAddress && showAddressFields.mailingAddress && (
        <>
          {/* Render address fields here */}

          <div className="row">
            {!(beneficiaryAddressInfoConfig?.beneficiaryAddressLine1?.hideableField) && <div className="col-12 col-md-6 py-2">
              <div className="floatingLabel">
                {formFields.beneficiaryAddressLine1.label}
                {formFields.beneficiaryAddressLine1.isRequired && <span> *</span>}
                <div>
                  <label for={formFields.beneficiaryAddressLine1.id} className="lable-sr-only" aria-label={formFields.beneficiaryAddressLine1.label}></label>
                  <input
                    id={formFields.beneficiaryAddressLine1.id}
                    name={formFields.beneficiaryAddressLine1.name}
                    type={formFields.beneficiaryAddressLine1.type}
                    placeholder={formFields.beneficiaryAddressLine1.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.beneficiaryAddressLine1.name)}
                  />
                  {formik.touched.beneficiaryAddressLine1 &&
                    formik.errors.beneficiaryAddressLine1 ? (
                    <div class="form-mandatory">{formik.errors.beneficiaryAddressLine1}</div>
                  ) : null}
                </div>
              </div>
            </div>}
            {!(beneficiaryAddressInfoConfig?.beneficiaryAddressLine2?.hideableField) && <div className="col-12 col-md-6 py-2">
              <div className="floatingLabel">
                {formFields.beneficiaryAddressLine2.label}
                <div>
                  <label for={formFields.beneficiaryAddressLine2.id} className="lable-sr-only" aria-label={formFields.beneficiaryAddressLine2.label}></label>
                  <input
                    id={formFields.beneficiaryAddressLine2.id}
                    name={formFields.beneficiaryAddressLine2.name}
                    type={formFields.beneficiaryAddressLine2.type}
                    placeholder={formFields.beneficiaryAddressLine2.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.beneficiaryAddressLine2.name)}
                  />
                  {formik.touched.beneficiaryAddressLine2 &&
                    formik.errors.beneficiaryAddressLine2 ? (
                    <div class="form-mandatory">{formik.errors.beneficiaryAddressLine2}</div>
                  ) : null}
                </div>
              </div>
            </div>}
          </div>

          <div className="row address-last-row">
            {/* City */}
            {!(beneficiaryAddressInfoConfig?.beneficiaryCity?.hideableField) && <div className="col-12 col-lg-3 col-md-4 col-sm-4 py-2">
              <div className="floatingLabel">
                {formFields.beneficiaryCity.label}
                {formFields.beneficiaryCity.isRequired && <span> *</span>}
                <div>
                  <label for={formFields.beneficiaryCity.id} className="lable-sr-only" aria-label={formFields.beneficiaryCity.label}></label>
                  <input
                    id={formFields.beneficiaryCity.id}
                    name={formFields.beneficiaryCity.name}
                    type={formFields.beneficiaryCity.type}
                    placeholder={formFields.beneficiaryCity.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.beneficiaryCity.name)}
                  />
                  {formik.touched.beneficiaryCity && formik.errors.beneficiaryCity ? (
                    <div class="form-mandatory">{formik.errors.beneficiaryCity}</div>
                  ) : null}
                </div>
              </div>
            </div>}

            {/* State */}
            <div className="col-12 col-lg-3 col-md-4 col-sm-4 py-2">
              {!(beneficiaryAddressInfoConfig?.beneficiaryState?.hideableField) && <div className="selectContainer">
                <label for={formFields.beneficiaryState.id}>
                  {formFields.beneficiaryState.label}
                  {formFields.beneficiaryState.isRequired && <span> *</span>}
                </label>
                <select
                  id={formFields.beneficiaryState.id}
                  name={formFields.beneficiaryState.name}
                  className="w-100 selectText benificiarySelect"
                  {...formik.getFieldProps(formFields.beneficiaryState.name)}
                >
                  <option value="">Select State </option>
                  {constantsList['StateConstants']?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
                {formik.touched.beneficiaryState && formik.errors.beneficiaryState ? (
                  <div class="form-mandatory">{formik.errors.beneficiaryState}</div>
                ) : null}
              </div>}
            </div>

            {/* Zip Code */}
            <div className="col-12 col-lg-3 col-md-4 col-sm-4 py-2">
              {!(beneficiaryAddressInfoConfig?.beneficiaryZipCode?.hideableField) && <div className="floatingLabel">
                {formFields.beneficiaryZipCode.label}
                {formFields.beneficiaryZipCode.isRequired && <span> *</span>}
                <div>
                  <label for={formFields.beneficiaryZipCode.id} className="lable-sr-only" aria-label={formFields.beneficiaryZipCode.label}></label>
                  <input
                    id={formFields.beneficiaryZipCode.id}
                    name={formFields.beneficiaryZipCode.name}
                    type={formFields.beneficiaryZipCode.type}
                    placeholder={formFields.beneficiaryZipCode.placeholder}
                    className="w-100"
                    {...formik.getFieldProps(formFields.beneficiaryZipCode.name)}
                  />
                  {formik.touched.beneficiaryZipCode && formik.errors.beneficiaryZipCode ? (
                    <div class="form-mandatory">{formik.errors.beneficiaryZipCode}</div>
                  ) : null}
                </div>
              </div>}
            </div>

            {/* Country */}
            <div className="col-12 col-lg-3 col-md-4 col-sm-4 py-2">
              {!(beneficiaryAddressInfoConfig?.beneficiaryCountry?.hideableField) &&
                <div className="selectContainer">
                  <label for={formFields.beneficiaryCountry.id}>
                    {formFields.beneficiaryCountry.label}
                    {formFields.beneficiaryCountry.isRequired && <span> *</span>}
                  </label>
                  <select
                    id={formFields.beneficiaryCountry.id}
                    name={formFields.beneficiaryCountry.name}
                    className="w-100 selectText benificiarySelect"
                    {...formik.getFieldProps(formFields.beneficiaryCountry.name)}
                  >
                    <option value="">Select Country</option>
                    <option value="USA">UNITED STATES</option>
                    <option value="FC">FOREIGN COUNTRY</option>
                  </select>
                  {formik.touched.beneficiaryCountry && formik.errors.beneficiaryCountry ? (
                    <div class="form-mandatory">{formik.errors.beneficiaryCountry}</div>
                  ) : null}
                </div>}
            </div>
          </div>
        </>
      )}

      {showAddressFields.liveInSameAddress && (
        <div className="address-body">
          <div>{employeeProfile?.primaryAddressLine1Display}</div>
          <div>{employeeProfile?.primaryAddressLine2Display}</div>
          <div>{employeeProfile?.primaryAddressLine3Display}</div>
        </div>
      )}

      {formError && (
        <div className="error-message">
          <p>{formError}</p>
        </div>
      )}
      <div className="modal-footer-button">
        <button role="button" className="btn btn-primary" type="button" onClick={onCancel}>
          Cancel
        </button>
        {pageLoadOptionSelected === 'existing' ? (
          <button
            role="button"
            className="btn btn-primary"
            disabled={beneficiariesToAdd?.length === 0}
            type="button"
            onClick={handleSaveBeneficiaries}
          >
            Ok
          </button>
        ) : (
          <button
            role="button"
            className="btn btn-primary"
            disabled={!formik.isValid || !formik.dirty}
            type="button"
            onClick={formik.submitForm}
          >
            Save
          </button>
        )}
      </div>
    </form>
  );
};

export default BeneficiaryModel;
