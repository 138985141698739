import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SHOW_CONTENT_LOADER } from '../../../../Store/Slices/Loader';
import { CURRENT_STEP } from '../../../../Store/Slices/StepForm';
import { useDispatch, useSelector } from 'react-redux';
const useFormProgress = (props) => {

  const {
    stepKeys: reduxStepkeys = [],
  } = useSelector(({ stepForm }) => stepForm);
  

  const [currentStep, setCurrentStep] = useState(0);
  const [visitedStep, setVisitedStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(()=>{   
    dispatch(CURRENT_STEP({ stepId: currentStep}));
  }, [currentStep])

  const goForward = (
    formName,
    content,
    dirty,
    errors,
    setTouched,
    touched,
    formValues,
    skipSteps,
    stepKeys
  ) => {
    dispatch(SHOW_CONTENT_LOADER(true));
    /* function to go next step*/
    let formErrors = null;
    let tempTouched = { ...touched[formName] };
    let contentData = content.forms ? content.forms[formName] : null;

    if (!(contentData && contentData.skipAndGo && Object.keys(tempTouched).length === 0)) {
      formErrors = errors[formName];
      for (let field in errors[formName]) {
        if (Array.isArray(errors[formName])) {
          for (let fieldUnit in errors[formName][field]) {
            tempTouched = { ...tempTouched, [field]: { ...tempTouched[field], [fieldUnit]: true } };
          }
        } else {
          tempTouched = { ...tempTouched, [field]: true };
        }
      }
      setTouched({ ...touched, [formName]: { ...tempTouched } });
    }
    /* checking wheather form is dirty and contains no error to proceed*/
    if (dirty && !formErrors) {
      setCurrentStep((prevCurrentStep) => {
        let step = prevCurrentStep + 1;
        // Find the next step which is not skipped
        while(skipSteps?.length && skipSteps.includes(step)) {
          step = step + 1
        }
        setVisitedStep(step);
        if (stepKeys) {
         //  stepKeys = stepKeys.length > 0 ? stepKeys : reduxStepkeys;
          dispatch(CURRENT_STEP({ stepId: step, currentStep: stepKeys[step]}));
        }
        return step;
      });
      if (completedSteps.indexOf(formName) === -1) {
        setCompletedSteps((prevCompletedStep) => [...prevCompletedStep, formName]);
      }
      //dispatch(UPDATE_FORM({ formName, updatedValue: formData[formName] }));
    }
    dispatch(SHOW_CONTENT_LOADER(false));
  };

  const goBack = (skipSteps = [],stepKeys) => {    
    dispatch(SHOW_CONTENT_LOADER(true));  
    /* function to go previous step */
    setCurrentStep((prevCurrentStep) => {
      let step = prevCurrentStep - 1;
      setVisitedStep(step);
      while(skipSteps?.length && skipSteps.includes(step)) {
        step = step - 1
      }
      if (stepKeys) {
        //    stepKeys = stepKeys.length > 0 ? stepKeys : reduxStepkeys;
        dispatch(CURRENT_STEP({ stepId: step, currentStep: stepKeys[step]}));
          }
      return step;
    });
    dispatch(SHOW_CONTENT_LOADER(false));  
  };

  const goBackStep = (moveToStep, stepKeys) => {
    /* function to go mentioned step */
    // 
    if (stepKeys) {
  //    stepKeys = stepKeys.length > 0 ? stepKeys : reduxStepkeys;
      dispatch(CURRENT_STEP({ stepId: moveToStep, currentStep: stepKeys[moveToStep]}));
    }
    setCurrentStep(moveToStep);
    dispatch(SHOW_CONTENT_LOADER(false));  
  };

  const cancel = (redirectURL) => {
    history.push(redirectURL);
  };

  const checkStatus = (step, stepKeys) => {
    /* function to check status  */
  
    if (step == stepKeys[currentStep]) {
      return 'current';
    } else if (stepKeys?.indexOf(step) < currentStep || completedSteps.indexOf(step) != -1) {
      return 'complete';
    // Removed to disable the completed steps on resume last steps
    // } else if (visitedStep == stepKeys?.indexOf(step)) {
    //   return 'visited';
    } else {
      return 'locked';
    }
  };
  return [currentStep, goForward, goBack, goBackStep, checkStatus, cancel];
};

export default useFormProgress;
