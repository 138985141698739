import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../Cells/Header';
import Footer from '../../Cells/Footer';
import LiveChat from '../../Cells/LiveChat';

export const PrivateRouteNoLogin = ({ hideHeader, hideChat, component: Component, render: Render = null, ...rest }) => {
  const userToken = useSelector((storeState) => storeState?.auth?.userAuth?.data?.access_token);
  const loader = useSelector((storeState) => storeState?.loader?.loader);
  const { userAuth } = useSelector((storeState) => storeState?.auth);
  const loginConfigInfo = useSelector(
    (state) => state?.entities?.response?.onLoadResponseList?.loginConfigInfo?._Result
  );
  const isHrCenter = userAuth?.userType === "HrCenter"
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <div className='pt-2' style={{ minHeight: '100%' }}>
        {!hideHeader && <Header />}

        <Route
          {...rest}
          render={(props) =>
            //localStorage.getItem('user')
            Render ? (
              Render(props)
            ) : (
              <>
                <Component {...props} />

              </>
            )
          }
        />
        {userAuth.callCenterAdmin || isHrCenter || loginConfigInfo?.showChat ? '' : <LiveChat />}
      </div>

      {!loader ? <Footer /> : null}
    </React.Fragment>
  );
};

export default PrivateRouteNoLogin;
