import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../../appConfig';
import http from '../../../../../Common/Services/HttpService';
import { UPDATE_CART_ITEM_NEW } from '../../../../../Store/Slices/EmployeeProfile';
import { UPDATE_ENROLLMENT_DATA } from '../../../../../Store/Slices/Enrollment';
import { onLoadAPI, onLoadProductAPI } from '../../../../../Store/Slices/Entities/response';
import { ADD_PET_LIST, DELETE_FULL_PET_LIST, OFFERED_COVERAGE_BUIDS, REMOVE_CURRENTSTEP_ALERT_MESSAGE, SET_ALERT_MESSAGE, UPDATE_LOADING, UPDATE_WAIVE_PRODUCT_WITHOUT_PERSONBUID } from '../../../../../Store/Slices/InsuredInfo';
import { CHECK_ELIGIBILITY } from '../../../../../Store/Slices/InsuredInfo/index.js';
import Checkbox from '../../../../Atoms/Checkbox';
import Loader from '../../../../Atoms/Loader';
import resumeContent from '../../../../Molecules/CustomSection/Content/ResumeContent.json';
import ServiceHelper from '../../../../Utils/Helper/MappingHelper';
import { addSubscriptionForChoiceNew } from '../../Actions/addSubscriptionForChoiceNew';
import { CheckCoveredPersonsEligibilityForCoverage } from '../../Actions/CheckCoveredPersonsEligibilityForCoverage';
import { reworkProductEnrollment } from '../../Actions/reworkProductEnrollment';
import { unsubscribeCoverage } from '../../Actions/unsubscribeCoverage';
import { updateProcess } from '../../Actions/updateProcess';
import { validateSubscribedProductData } from '../../Actions/validateSubscribedProductData';
import { Button } from '../../Atoms/index';
import PetComponent from '../../Component/PetAssureNation/PetAssureNationModal';
import PetTable from '../../Component/PetAssureNation/PetAssureNationTable';
import Content from '../../Content/PetAssureNationContent.json';
import { continueBtnLabelHelper, screenNames } from '../../Helper/continueBtnLabelHelper';
import { getErrorMessages, removeUnwantedProps } from '../../Helper/MappingHelper';
import { createPetListPayload, isActiveProduct } from '../../Helper/PetHelper';
import { useNavigationHook } from '../../Hooks/NavigationHook';
import ProductDescription from './ProductDescription';

const PetAssureNationContent = (props) => {
  const serviceHelper = new ServiceHelper();
  const { currentStep, previousStep, formsConfig, stepKeys  } = useNavigationHook();
  const [checkNextStep, setCheckNextStep] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [disableAccordian, setDisableAccordian] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [currentBuids, setCurrentBuids] = useState([]);
  // const [catBreeds, setCatBreeds] = useState([]);
  // const [dogBreeds, setDogBreeds] = useState([]);
  const [coverageError, setCoverageError] = useState([]);
  const [isCoverageApiError, setIsCoverageApiError] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const { employeeIndex } = props;

  const buid = props.employee[employeeIndex]?.personBuid;
  let personBuid = buid;
  const dispatch = useDispatch();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { onBack, nextStep, flowType, onNext } = props;
  const [prepareApiOneTime, setPrepareApiOneTime] = useState(false);
  const [continueBtnLabel, setContinueBtnLabel] = useState("Continue");
  const { insuredInfo, process } = useSelector((storeState) => storeState);
  const enrollmentProcessBuid = process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
  const currentReduxState = useSelector((storeState) => storeState);
  const subscribedProductBuid = currentReduxState?.enrollment?.enrollmentData?.contract?.subscribedElements;
  
  const getAllPossibleChoicesConfiguration =
    currentReduxState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]
      ?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0];
      const {
        radioSelectedValuePetNation
      
      } = useSelector((storeState) => storeState.employeeProfile);
  let productFamily = formsConfig?.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
  let insuredQuestionAnswers = insuredInfo[currentStep]?.InsuredQuestionAnswers?.[buid];

  const coverages = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList[currentStep]?.AddSubscriptionForChoice
        ?.contract?.subscribedElements[0]?.coverages
  );

  const employmentInformation = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.EmploymentInformation
        ?.employmentInformation
  );
  const catBreeds = useSelector(state => state.entities?.response?.onLoadResponseList?.PetAssureNation?.getCatBreeds?._Result);
  const dogBreeds = useSelector(state => state.entities?.response?.onLoadResponseList?.PetAssureNation?.getDogBreeds?._Result);
  
  let subscribedCoverageBuid = [];
  for (let i = 0; i < coverages?.length; i++) {
    subscribedCoverageBuid.push(coverages[i]?.bUID);
  }
  let buidSubscribed = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.[currentStep]
        ?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]?.coverage?.product?.bUID
  );

  const searchPolicyProcessResource = useSelector((storeState) => storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result)
 
 

  let petlist = currentReduxState?.insuredInfo?.[`${currentStep}`]?.petList || [];


  let subscribedElementsDataFromValidateContract = subscribedProductBuid?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
  let subscribedProductBuidFromValidateContract = subscribedElementsDataFromValidateContract?.[0]?.subscribedProduct?.buid;



  const enrollmentData = useSelector(
    (state) => state.enrollment?.enrollmentData?.contract?.subscribedElements
  );
  let declinedProduct = enrollmentData?.filter(
    (e) => e.subscribedProduct.productCode.buid === productIdentifierBuid
  );
   // commenting out this logic snice we may also get cActive status in endorsement only we need to check for the coverage to decide
   //let isDeclinedProduct =
   // declinedProduct?.[0]?.subscribedProduct?.status === 'cDeclined' ? true : false;
    //waiveCoverage Logic
    const waiveCoverage = insuredInfo[currentStep]?.WaiveCoverage;
    // Use optional chaining to access the first element's 'waiveProduct' property, default to 'false' if undefined or null
    const isWaiveCoverage = waiveCoverage?.[0]?.waiveProduct ?? false;
  // this is for show/hide waive coverage based on insured questions
  let continueBtnObj = {
    flowType: props.flowType,
    screenType: screenNames.INSURED_QUESTIONNAIRE_SCREEN,
    hasDisclosureStatement: props.showDisclosureStatement,
    isWaivedOffFlag: false,
  }

  useEffect(() => {
    setContinueBtnLabel(continueBtnLabelHelper({
      ...continueBtnObj,
      productIdentifierBuid
    }));
  },[productIdentifierBuid])

  useEffect(() => {
    setContinueBtnLabel(continueBtnLabelHelper({
      ...continueBtnObj,
      productIdentifierBuid,
      isWaivedOffFlag: isWaiveCoverage
    }))
  },[isWaiveCoverage])

  





  let showButton=false
  if(radioSelectedValuePetNation=="waive"){
    showButton=true;
  }

  let btnContinueDisabled = false;
  if ((petlist?.length === 0 || petlist === undefined) && !isWaiveCoverage) {  
      btnContinueDisabled = true;
  }
  
  const addSubscriptionForChoiceNew1 = async () => {
    try {
      const enrolmentId = currentReduxState?.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
      if (!enrolmentId) {
        console.error("EnrolmentId is not defined");
        return false;
      }
      let res = await dispatch(addSubscriptionForChoiceNew(
        enrolmentId,
        getAllPossibleChoicesConfiguration,
        currentStep
      ));
      if (res) {
        let coverages = res?._Result?.contract?.subscribedElements?.[0]?.coverages;
        if (!coverages) {
          console.error("Coverages is not defined");
          return false;
        }
      }
      return res;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  

  const currentStepIndex = stepKeys.findIndex((step) => step === currentStep);
  const nextStepGoingTobe = stepKeys[currentStepIndex + 1];
  const buttonText =
    nextStepGoingTobe === 'EnrollmentSummary' ? 'Continue to Summary' : 'Continue to Next Product';
  let subCovBuid = [];

  const vdk = async (event) => {
    dispatch(UPDATE_LOADING(true));
    const waiveProduct = event.target.checked;
    setIsCheckboxChecked(waiveProduct);
  
    let subCovBuid = [];
  
    const currentSubscribedCoverageBuid = petlist?.filter(
      pet => pet?.coverageId !== null && pet?.coverageId !== undefined
    )?.map(pet => pet.coverageId);
  
    const subscribedCoverageBuid = currentSubscribedCoverageBuid?.length > 0 ? currentSubscribedCoverageBuid : subCovBuid;
  
    dispatch(UPDATE_WAIVE_PRODUCT_WITHOUT_PERSONBUID({  waiveProduct, currentStep }));
  
    if (waiveProduct === true) {

      let repsonse= await http
      .post(appConfig.SSP_MID_URL + '/declineProduct', {
        payload: {
          enrollmentProcessBuid: enrollmentProcessBuid,
          subscribedProductBuid: subscribedProductBuid,
          productIdentifierBuid: productIdentifierBuid,
          currentStepInfo: {
            "portalStep": currentStep
          }
        }
      })
      .then((resp) => {
        dispatch(UPDATE_LOADING(false));   
        if (resp.data && resp.status === 200) {      
         const payload = {
        product: currentStep,
        petList: [],
      };
       dispatch(DELETE_FULL_PET_LIST(payload));
       dispatch(
        UPDATE_CART_ITEM_NEW({
          id: currentStep,
          moreThanOne:true,
          declineProduct: true
        })
      );

      if ( Array.isArray(resp) && resp?.length > 0) {
            const lastResult = resp[resp.length - 1];
            const processInfo = lastResult?.result || null;
            if (processInfo) {
              const coverages = processInfo?.contract?.subscribedElements?.filter(
                (e) => e.subscribedProduct.productCode.buid == buidSubscribed
              )[0]?.coverages;
              const productStatus = processInfo?.contract?.subscribedElements?.filter(
                (e) => e.subscribedProduct.productCode.buid == buidSubscribed
              )[0]?.subscribedProduct?.status;
              if (coverages && productStatus) {
                const petListPayload = createPetListPayload(coverages,  employmentInformation, processInfo );
                dispatch(ADD_PET_LIST({product: currentStep, petList : petListPayload}));
              }
            }
          dispatch(onLoadAPI({ ...{ [`declineProduct`]: resp.data } }));
          const processInfo1 = resp?.data?._Result ?? {};
          dispatch(UPDATE_ENROLLMENT_DATA(processInfo1));
        }
         dispatch(updateProcess({ currentStepInfo: { "portalStep": currentStep } }))

      } else{
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'declineProduct',
          show: true,
          page: currentStep,
        }));
      }
      dispatch(UPDATE_LOADING(false));
      }).catch((error) => {
        dispatch(UPDATE_LOADING(false));
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'declineProduct',
          show: true,
          page: currentStep,
        }));
        
      })
    } else {
      let responseObj =   await dispatch(reworkProductEnrollment({
        enrollmentProcessBuid,
        subscribedProductIdentifierBuid: subscribedProductBuidFromValidateContract,
        externalStepContext : "",
        currentStep: currentStep
      }));
      const processInfo=responseObj?.respObj?.data?._Result??{};
      if(processInfo &&  processInfo?.contract?.subscribedElements ) 
      {
        await dispatch(validateSubscribedProductData({
          enrollmentProcessBuid: enrollmentProcessBuid,
          productIdentifierBuid: productIdentifierBuid,
          currentStep: currentStep,
          subscribedElements: processInfo?.contract?.subscribedElements,
          expectReturn: true
      }));
      }
      dispatch(updateProcess({ currentStepInfo: { "portalStep": currentStep } }))

      dispatch(UPDATE_LOADING(false));
    }
  };
  





const nextStepCall = async () => {
  try {
    if (personBuid && isWaiveCoverage === false && insuredQuestionAnswers) {
      setShowLoader(true);
      let resp = await serviceHelper.sendApiRequest('/createOrUpdateInsuredQuestionAnswer', {
        currentReduxState,
        personBuid,
        productIdentifierBuid,
        insuredQuestionAnswers,
      });
      if (resp?.data && resp?.status === 200) {
        dispatch(
          onLoadAPI({
            CreateOrUpdateInsuredCIGeneral: resp.data,
            isAPICallInProgress: false,
          })
        );
        setShowLoader(false);
        return true;
      } else {
        setShowLoader(false);
        return false;
      }
    } else if (personBuid && isWaiveCoverage === true && props?.flowType !== 1) {
      setShowLoader(true);
      // await prepareSubscriptionForChoice();
    } else {
      // await addSubscriptionForChoice();
      await validateSubscribedProductdata();
      return true;
    }
  } catch (e) {
    console.error(e);
    setShowLoader(false);
    return false;
  }
};




  


  const currentOfferedCoverageBuids = currentReduxState?.entities?.response?.onLoadResponseList?.[currentStep]?.getAllPossibleChoicesConfigurationForProduct?._Result || [];
  const validateContractInsure = currentReduxState?.entities.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.insureds || []
  const nonSharedAdditionalDataSet = insuredInfo[currentStep]?.nonSharedAdditionalDatas
  const nextStepCallOne = async () => {
    // nextStep();
    if (personBuid && isWaiveCoverage === false && insuredQuestionAnswers) {
      let insuredQuestionAnswers = insuredInfo[currentStep]?.InsuredQuestionAnswers;
      setShowLoader(true);
      let resp = await serviceHelper.sendApiRequest('/createOrUpdateInsuredQuestionAnswer', {
        productIdentifierBuid,
        insuredQuestionAnswers,
        currentStep,
        currentOfferedCoverageBuids,
        enrollmentProcessBuid,
        validateContractInsure,
        nonSharedAdditionalDataSet
      });
      if (resp.data && resp.status === 200) {
        dispatch(
          onLoadAPI({
            CreateOrUpdateInsuredCIGeneral: resp.data,
            isAPICallInProgress: false,
          })
        );
        // await checkCoveragePersonEligibility();
        setShowLoader(false);
        return true;
      } else {
        setShowLoader(false);
        return false;
      }
      // Need to refactor this code props.flowType != 1
    } else if (personBuid && isWaiveCoverage === true && props.flowType !== 1) {
      setShowLoader(true);
      // await prepareSubscriptionForChoice();
      return true;
    } else {
      //return await validateSubscribedProductdata();
      return true;
    }
  };


  const [apiCallState, setApiCallState] = useState('');
  

  useEffect(() => {
    if (currentBuids?.length > 0 && currentOfferedCoverageBuids.length > 0) {
      setApiCallState('onLoad');
    }
  }, [currentBuids, currentOfferedCoverageBuids]);



  useEffect(() => {
    if (currentOfferedCoverageBuids.length > 0) {
      setApiCallState('onLoad');
      // checkCoveragePersonEligibility();
    }
  }, [currentOfferedCoverageBuids]);

  useEffect(() => {
    if(currentStep && enrollmentProcessBuid && subscribedProductBuid?.length > 0){
      getInitialData();
    }
    
  }, [currentStep, enrollmentProcessBuid, subscribedProductBuid?.length > 0]);


  const hasErrors = getErrorMessages(currentStep);

  
  const getInitialData = async () => {
    dispatch(UPDATE_LOADING(true));
    const reduxState = removeUnwantedProps(currentReduxState, resumeContent.resumeUnwantedProps);
    const stepContext = { portalStep: currentStep, reduxState };

    const qleEndorsementProcessBuid = currentReduxState?.entities?.response?.onLoadResponseList?.initNewIndividualEndorsementProcess?._Result?.processEntity?.buid
    const enrollmentValue = currentReduxState?.enrollment?.enrollmentData?.contract?.subscribedElements || [];
    let declinedProduct = enrollmentValue?.filter(e => e?.subscribedProduct?.productCode?.buid === productIdentifierBuid);
    let isDeclinedProduct = declinedProduct?.[0]?.subscribedProduct?.status === "cDeclined" ? true : false
    const enrollmentType = currentReduxState?.employeeProfile?.enrollmentType;
    const reasonType = currentReduxState?.employeeProfile?.reasonType;
    const isAdmin = currentReduxState?.callCenter?.isAdmin;
    const ongoingEnrollmentType = enrollmentType === "editNewEnrollmentDuringOE"
    const ongoingReasonType = reasonType === "OE"
    dispatch(REMOVE_CURRENTSTEP_ALERT_MESSAGE(currentStep));
    try{
    const response = await http.post(appConfig.SSP_MID_URL + '/v1/pet-insurance-load', {
      stepContext,
      enrollmentProcessBuid,
      subscribedProductBuid,
      productIdentifierBuid,
      isRework: isDeclinedProduct || qleEndorsementProcessBuid || isAdmin || ongoingReasonType || ongoingEnrollmentType ? true : false,
    });

    Object.keys(response.data.data).forEach(key => {
      const result = { _Result: response.data.data?.[key]?.data };
      switch(key) {
          case 'getCatBreeds':
          if (response.data.data[key]?.success)
          {
          dispatch(onLoadProductAPI({ data: {[currentStep]: {[key]: result }}, currentStep, isAPICallInProgress: false }))
          }
          else{
            dispatch(SET_ALERT_MESSAGE({
              type: 'error',
              errorCode: response?.data?.data[key]?.data?.error?.code || '520',
              continue: true,
              id: 'getCatBreeds',
              show: true,
              page: currentStep,
            }));
          }
          break;
          case 'getDogBreeds':
            if (response.data.data[key]?.success)
            {
            dispatch(onLoadProductAPI({ data: {[currentStep]: {[key]: result }}, currentStep, isAPICallInProgress: false }))
          }
            else{
              dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: response?.data?.data[key]?.data?.error?.code || '520',
                continue: true,
                id: 'getDogBreeds',
                show: true,
                page: currentStep,
              }));
            }
          break;
          case 'searchPolicyProcessResources':
            if (response.data.data[key]?.success) {
                dispatch(onLoadAPI({ [`SearchPolicyProcessResources${currentStep}`]: result }));
            } else {
              dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: response?.data?.data[key]?.data?.error?.code || '520',
                continue: true,
                id: 'searchPolicyProcessResources',
                show: true,
                page: currentStep,
              }));
            }
            break;
          case 'getAllPossibleChoicesConfigurationForProduct':
            if (response.data.data[key]?.success) {
              let totalList = result?._Result
              let offeredCoverageBuids = []
              let enrollmentSummery = []
              for (let i = 0; i < result?._Result.length; i++) {
                  let personBuid = result?._Result[i]?.possibleInsuredPersons?.map((el) => el?.insuredPerson?.bUID)
                  totalList[i].offeredCoverageBuids = { offeredCoverageBuid: personBuid }
                  offeredCoverageBuids.push({ coverage: result?._Result[i]?.coverage?.bUID, coveragePersonBuid: personBuid })
                  if (previousStep === "EnrollmentSummary" && i === 0) {
                      enrollmentSummery.push({ coverage: result?._Result[i]?.coverage?.bUID, coveragePersonBuid: personBuid })
                  }
              }

              // Format the new data
              const currentStepData = {
                  [currentStep]: {
                      getAllPossibleChoicesConfigurationForProduct: { _Result: totalList }
                  }
              };
              // Dispatch an action to update the state with the new data
              dispatch(onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false }));
              dispatch(OFFERED_COVERAGE_BUIDS({ offeredCoverageBuids: offeredCoverageBuids, product: currentStep }));
          } else {
            dispatch(SET_ALERT_MESSAGE({
              type: 'error',
              errorCode: response?.data?.data[key]?.data?.error?.code || '520',
              continue: true,
              id: 'getAllPossibleChoicesConfigurationForProduct',
              show: true,
              page: currentStep,
            }));
          }
            break;
          case 'validateSubscribedProductData':
            if (response.data.data[key]?.success) {
              const currentStepData = {
                  [currentStep]: {
                      ValidateSubscribedProductData: result
                  }
              };

              const processInfo = result?._Result ?? null;
              if (processInfo) {
                dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
              }
              // Dispatch an action to update the state with the new data
              dispatch(onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false }));
            } else {
              dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: response?.data?.data[key]?.data?.error?.code || '520',
                continue: true,
                id: 'validateSubscribedProductData',
                show: true,
                page: currentStep,
              }));
            }
            break;

            case 'checkCoveredPersonsEligibilityForCoverage': {
              if (response.data.data[key]?.success) {
                const result = response.data.data[key];
                if (
                  insuredInfo[currentStep]?.coverageInsuredEligiblity &&
                  insuredInfo[currentStep]?.coverageInsuredEligiblity?.length > 0
                ) {
                  let currentElibilityData = [...insuredInfo[currentStep]?.coverageInsuredEligiblity];
                  result.forEach((pobj) => {
                    currentElibilityData.forEach((pinnrObj, pind) => {
                      if (pinnrObj.personBuid === pobj.personBuid || pinnrObj.personBuid === undefined) {
                        currentElibilityData.splice(pind, 1);
                      }
                    });
                  });
                  currentElibilityData = [...currentElibilityData, ...result];
                  dispatch(CHECK_ELIGIBILITY({ response: currentElibilityData, product: currentStep }));
                } else {
                  dispatch(CHECK_ELIGIBILITY({ response: result, product: currentStep }));
                }
              } else{
                dispatch(SET_ALERT_MESSAGE({
                  type: 'error',
                  errorCode: response?.data?.data[key]?.data?.error?.code || '520',
                  continue: true,
                  id: 'checkCoveredPersonsEligibilityForCoverage',
                  show: true,
                  page: currentStep,
                }));
              }
              break;
            }

            default: break;
      }
    });

    // let catBreedsData;
    // let dogBreedsData;
  
    // if (!catBreedsDataFromState || catBreedsDataFromState.length === 0) {
    //   catBreedsData = await dispatch(getCatBreeds(currentStep));
    //   // Add null check for catBreedsData
    //   if (!catBreedsData) {
    //     console.error('Error: No data received from getCatBreeds.');
    //   } else {
    //     setCatBreeds(catBreedsData);
    //   }
    // } else {
    //   setCatBreeds(catBreedsDataFromState);
    // }
    
    // if (!dogBreedsDataFromState || dogBreedsDataFromState.length === 0) {
    //   dogBreedsData = await dispatch(getDogBreeds(currentStep));
    //   // Add null check for dogBreedsData
    //   if (!dogBreedsData) {
    //     console.error('Error: No data received from getDogBreeds.');
    //   } else {
    //     setDogBreeds(dogBreedsData);
    //   }
    // } else {
    //   setDogBreeds(dogBreedsDataFromState);
    // }
    dispatch(UPDATE_LOADING(false));
    } catch(e){
      dispatch(UPDATE_LOADING(false));
      dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: '520',
        continue: true,
        id: 'petInsuranceLoad',
        show: true,
        page: currentStep,
      }));
    }
  };
  


  

  const checkCoveragePersonEligibility = async () => {
    // nextStep();
    // onClick={(flowType == "2" || flowType == "3") ? nextStepCall : nextStepCallOne}
    setCheckNextStep(false);
    let productFamily = formsConfig?.forms?.[currentStep]?.productFamily;

    let CIBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];

    if (CIBuid) {
      setShowEdit(true);
      let validateContractSM =
        currentReduxState?.entities?.response?.onLoadResponseList
          ?.ValidateSubscribedContractMoveNextData?._Result?.subscriptionManager || [];
      const enrolmentId = currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
      const flow1buids =
        currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result
          ?.contract?.insureds || [];

      const response = await CheckCoveredPersonsEligibilityForCoverage(
        validateContractSM,
        enrolmentId,
        CIBuid,
        currentStep,
        currentBuids,
        flow1buids,
        flowType,
        currentOfferedCoverageBuids
      );
      if (
        insuredInfo[currentStep]?.coverageInsuredEligiblity &&
        insuredInfo[currentStep]?.coverageInsuredEligiblity?.length > 0
      ) {
        let currentElibilityData = [...insuredInfo[currentStep]?.coverageInsuredEligiblity];
        response.forEach((pobj) => {
          currentElibilityData.forEach((pinnrObj, pind) => {
            if (pinnrObj.personBuid === pobj.personBuid || pinnrObj.personBuid === undefined) {
              currentElibilityData.splice(pind, 1);
            }
          });
        });
        currentElibilityData = [...currentElibilityData, ...response];
        dispatch(CHECK_ELIGIBILITY({ response: currentElibilityData, product: currentStep }));
      } else {
        dispatch(CHECK_ELIGIBILITY({ response, product: currentStep }));
      }

      let flagCheck = true;
      let lcoverageError = [];
      response.forEach((pobj) => {
        if (pobj.coverageEligiblity === false) {
          flagCheck = false;
          let temp = {
            error: true,
            buid: pobj.personBuid,
          };
          lcoverageError.push(temp);
        }
      });
      setCoverageError([...lcoverageError]);
      // sucess case
      if (flagCheck && response.length > 0) {
        setCheckNextStep(false);
        setDisableAccordian(true);
        // (flowType == "2" || flowType == "3") ? nextStepCall() : nextStepCallOne()
      }
      // error case
      else {
        setDisableAccordian(true);
        setCheckNextStep(true);
        setIsCoverageApiError(true);
      }
      if (apiCallState == 'onLoad') {
        setShowEdit(false);
        setApiCallState('done');
        if (flagCheck && response.length > 0) {
          setDisableAccordian(false);
          setCheckNextStep(false);
        } else if (flagCheck && response.length === 0) {
          setDisableAccordian(false);
        }
      }
    }
  };


  const validateSubscribedProductdata = async () => {
    let productFamily = formsConfig?.forms?.[currentStep]?.productFamily;
    let CIBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
    const subscribedProductBuid =
      currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result
        ?.contract?.subscribedElements;
    let resp = await serviceHelper.sendApiRequest('/validateSubscribedProductData', {
      enrollmentProcessBuid: enrollmentProcessBuid,
      subscribedProductBuid: subscribedProductBuid,
      productIdentifierBuid: CIBuid,
    });
    if (resp.data && resp.status === 200) {
      const currentStepData = {
        [currentStep]: {
          ValidateSubscribedProductData: resp.data,
        },
      };
      dispatch(
        onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false })
      );
      return true;
    } else {
      return false;
    }
  };



  const continueFn = async () => {
    nextStep();
  };

  const callOnNext = async () => {
    // Your logic for proceeding to the next product goes here...
 await nextStepCallOne();

    onNext();
  };


  return (
    <div>
    {showLoader ? (
      <Loader loading={showLoader} className="loaderUpdateCls" />
    ) : (
      <>
        {/* {Content?.idealContent && <Elementor element={Content.idealContent} buid={buid} {...props.parentProps} />} */}
        <ProductDescription resources={searchPolicyProcessResource} buid={buid} {...{ ...props.parentProps, ...Content.idealContent }} />
        <PetTable {...props}></PetTable>
        <PetComponent isCheckboxChecked={isWaiveCoverage} catBreeds={catBreeds} dogBreeds={dogBreeds} {...props} />
        {Content?.waiveCoverageButton && (
          <div className="hideWhenNoChild col-12 pr-0 pl-0">
            <div
              className=""
              style={{
                borderBottomRightRadius: '0.25rem',
                fontFamily: 'NotoSansRegular',
                fontSize: '0.875rem',
                textAlign: 'center',
                backgroundColor: 'rgb(244, 244, 244)',
                borderColor: 'rgb(218, 218, 218)',
                minHeight: '2.5rem',
                paddingTop: '0.5rem',
              }}
            >
              <div className="checkboxContainer">
                <Checkbox
                  label={Content.waiveCoverageButton.label}
                  element={Content.waiveCoverageButton}
                  onChange={vdk}
                  buid={buid}
                  checked={isWaiveCoverage === true}
                  {...props.parentProps}
                  className={``}
                ></Checkbox>
              </div>
            </div>
          </div>
        )}
      </>
    )}
  
    <div className="button-group">
      <Button type="button" onClick={onBack} className="mr-3 btn btn-outline-primary primaryOutline">
        Back
      </Button>
  
      { (
        <Button
          type="button"
          onClick={ isWaiveCoverage ? callOnNext : continueFn}
          className="btn btn-primary continue-btn primaryBackground Test"
          name="continue-to-next-product"
          disabled={btnContinueDisabled || hasErrors}
        >
          {continueBtnLabel}
        </Button>
      ) }
    </div>
  </div>
  
  );
};

export default PetAssureNationContent;