import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormGenerator from '../../../Cells/Form/FormGenerator';
import './styles.css';
import $ from 'jquery';
import http from '../../../../Common/Services/HttpService/index.js';
import appConfig from '../../../../appConfig/index.js';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response/index.js';
import {
  SET_DEPENDENT_BENEFICIARY_LIST,
  UPDATE_INSUREDS_AS_BENEFICIARIES,
} from '../../../../Store/Slices/EmployeeProfile';
import { PRODUCT_RESOURCES_COVERAGE_TITLE } from '../../../../Store/Slices/Process/index';
import { getCoverageBeneficiaryEligibility } from '../../../Molecules/CustomSection/Helper/MappingHelper';
import { SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo';
import { SET_COVERAGE_BUIDS, SET_COVERAGE_BENEFICIARY_ELIGIBILITY, UPDATE_PRODUCT_LEVEL_ELIGIBILITY } from '../../../../Store/Slices/InsuredInfo/index.js';
import { cloneDeep } from 'lodash';
import { getFormKeyByProductFamily } from '../../../Molecules/ProductTable/processData';
import { getBeneficiariesData } from '../../../Molecules/CustomSection/Utils/BeneficiariesUtils';
import { MessageComponent } from '../../../Atoms';

const ManageBeneficiaries = (props) => {
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector(({ language }) => language);
  const process = useSelector((storeState) => storeState?.process);
  const [content, setContent] = useState([]);
  const [layoutData, setLayoutData] = useState([]);
  const [globalBeneficiaries, setGlobalBeneficiaries] = useState([]);
  let currentReduxState = useSelector((storeState) => storeState);
  const {
    currentStep,
    previousStep,
  } = useSelector(({ stepForm }) => stepForm);
  const currentBenifitendorsementProcessBuid = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.initEndorsementProcessData?._Result?.processEntity?.buid
  );
  const qleEndorsementProcessBuid = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.initNewIndividualEndorsementProcess?._Result?.processEntity?.buid
  );

  const benefEndorsementProcessBuid = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.
      beneficiaryinitEndorsementProcessData
      ?._Result?.processEntity?.buid
  );

  const beneficiariesFullData = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.beneficiariesData
  ) || {};

  const coverageTitle = useSelector(
    (storeState) =>
      storeState?.process?.productResourcesCoverageTitle
  );
  const endorsementProcessBuid = currentBenifitendorsementProcessBuid || qleEndorsementProcessBuid || benefEndorsementProcessBuid
  const { loggedUserEntity: { data: dataFromRedux } = {} } = useSelector(({ auth }) => auth);
  const {
    dependentsInfo,
    dependentsBeneficiaryList = [],
  } = useSelector(({ employeeProfile }) => employeeProfile);

  const storeFromRedux = useSelector((storeState) => storeState);
  const insuredDependents = storeFromRedux?.enrollment?.enrollmentData?.contract?.insureds || [];

  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => setContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);


  const updateInsuredsAsBeneficiaries = () => {
    const extractedData = insuredDependents.map((insured) => {
      const person = insured?.person?.data?.actor ?? {};
      return {
        beneficiaryName: (person.shortName ?? '') + " " + (person.name ?? ''),
        id: insured?.person?.identifier?.bUID ?? '',
        mainBuid: insured?.person?.identifier?.bUID ?? '',
        beneficiaryFirstName: person.shortName ?? '',
        beneficiaryMiddleName: person.middleName1 ?? '',
        beneficiaryLastName: person.name ?? '',
        beneficiaryGender: person.gender ?? '',
        beneficiaryDateOfBirth: person.birthDate ?? '',
        beneficiaryLiveInSameAddress: false,
        beneficiaryRelationship: insured?.insured.mainOwnerRelationshipToInsured ?? '',
        beneficiaryType: 'Primary',
        beneficiaryPercentage: '0',
        beneficiarySelected: false,
        showActions: false,
        insured: insured,
        beneficiaryOptions: 'individual',
      };
    });
    dispatch(UPDATE_INSUREDS_AS_BENEFICIARIES(extractedData));
  };


  // Function to get the dependents
  useEffect(() => {
    if (insuredDependents.length > 0) {
      updateInsuredsAsBeneficiaries();
      const tableData = [];

      const gender = {
        gMale: 'Male',
        gFemale: 'Female',
        gUndisclosed: 'Undisclosed',
      };

      insuredDependents.forEach((dependent) => {
        const dependentData = {};
        const dependentBuid = dependent?.person?.identifier?.bUID;
        const insuredBuid = dependent?.bUID;
        const additionalData = dependent?.insured?.additionalData || [];
        const dependentInfo = dependent && dependent.person && dependent.person.data && dependent.person.data.actor || {};
        const isStudent = additionalData.find(
          (additional) => additional.fieldName === 'ISCHFTSTUDENT'
        );
        let isDisabled = 'yes';
        if (dependent?.insured?.additionalData?.[0]?.valueCode) {
          isDisabled =
            dependent?.insured?.additionalData?.[0]?.valueCode.toLocaleLowerCase() == 'yes'
              ? 'yes'
              : 'no';
        }
        if (!dependent.insured.mainOwnerRelationshipToInsured) {
          return;
        }
        // if (dependentsInfo[dependentBuid]) {
        const liveInSameAddress = dependentsInfo && dependentsInfo.preferredContactChoice === 'pcActorContact' ? true : false;
        dependentData['mainBuid'] = insuredBuid;
        dependentData['id'] = dependentBuid;
        dependentData['dependentFirstName'] = dependentInfo && dependentInfo.shortName || '';
        dependentData['dependentMiddleName'] = dependentInfo && dependentInfo.middleName1 || '';
        dependentData['dependentLastName'] = dependentInfo && dependentInfo.name || '';
        dependentData['dependentRelationShip'] = dependent?.insured?.mainOwnerRelationshipToInsured || '';
        dependentData['dependentGender'] = gender[dependentInfo && dependentInfo.gender];
        dependentData['dependentDateOfBirth'] = dependentInfo && dependentInfo.birthDate;
        dependentData['dependentSSN'] = dependentInfo?.sSN || '';
        dependentData['liveInSameAddress'] = liveInSameAddress;
        dependentData['isDependent'] = true;
        dependentData['data'] = {
          ...dependent,
          ...dependentsInfo[dependentBuid],
          isStudent: isStudent ? 'yes' : 'no',
          isDisabled: isDisabled,
        };
        tableData.push(dependentData);
      });
      // setDependentsTable(tableData);
      dispatch(SET_DEPENDENT_BENEFICIARY_LIST({ data: tableData, isDependent: true }));
    }
  }, [insuredDependents]);


  const getCoverageResources = (enrorsementProductDetails) => {
    const offProductIdentifierArray = [];
    const productResourceListCoverageTitle = {};
    for (let i = 0; i < enrorsementProductDetails?.length; i++) {
      const buid = enrorsementProductDetails[i]?.product?.buid;
      productResourceListCoverageTitle[buid] = { subscribedbUID: buid }

      const offProductIdentifier = { "bUID": buid, "className": "aSPCB_ProductIdentifier" }
      offProductIdentifierArray.push(offProductIdentifier)
    }
    dispatch(UPDATE_LOADING(true));
    const resp = http
      .post(appConfig.SSP_MID_URL + '/searchPolicyProcessResourcesCoverage', {
        offProductIdentifierArray: offProductIdentifierArray,
        enrollmentProcessBuid: endorsementProcessBuid,
        currentReduxState: currentReduxState
      })
      .then((resp) => {
        if (resp) {
          const productResourcesCoverageTitle = resp?.data;
          dispatch(PRODUCT_RESOURCES_COVERAGE_TITLE({ productResourcesCoverageTitle }));
          dispatch(onLoadAPI({ productResourcesCoverageTitle }));
          dispatch(UPDATE_LOADING(false));
        }
      })
      .catch((error) => {
        dispatch(UPDATE_LOADING(false));
      
      });
  }

  /* CODE TO TEST THE PRODUCT */

  const [beneficiariesProducts, setBeneficiariesProducts] = useState([]);
  const getBeneficiaryType = (beneficiaryArray = []) => {
    const contingentArray = [];
    const primaryArray = [];
    const beneficiaryObj = {
      "Primary": [],
      "Contingent": [],
    };
    if (beneficiaryArray.length > 0) {
      beneficiaryArray.forEach(beneficiary => {
        if (beneficiary && beneficiary.beneficiaryInformation && beneficiary.beneficiaryInformation.beneficiaryRank === 'cPrimary') {
          primaryArray.push(beneficiary);
        } else if (beneficiary && beneficiary.beneficiaryInformation && beneficiary.beneficiaryInformation.beneficiaryRank === 'cContingent') {
          contingentArray.push(beneficiary);
        }
      });
    }
    beneficiaryObj['Primary'] = primaryArray;
    beneficiaryObj['Contingent'] = contingentArray;
    return {
      beneficiaryObj,
      hasBeneficiaries: primaryArray.length > 0 || contingentArray.length > 0 ? true : false,
      totalPrimary: primaryArray.length || 0,
      totalContingent: contingentArray.length || 0,
    };
  };

  const subscribedElements = storeFromRedux?.enrollment?.enrollmentData?.contract?.subscribedElements;

  // Method to update Redux State about Beneficiaries, based on products
  useEffect(() => {
    dispatch(getBeneficiariesData(currentReduxState));
  }, [subscribedElements && subscribedElements.length > 0, process && process.productFamilyIdentifier]);

  useEffect(() => {
    if (subscribedElements && subscribedElements.length > 0 && process && process.productFamilyIdentifier && Object.keys(process.productFamilyIdentifier).length > 0) {
      const dependentsList = storeFromRedux?.employeeProfile?.insuredsAsBeneficiaries;
      const productsArray = [];
      const globalBeneficiaries = [];
      let i = 0;
      subscribedElements.forEach(product => {
        const productWaived = product?.subscribedProduct?.status === 'cDeclined' || product?.subscribedProduct?.status === 'cTerminated' || product?.subscribedProduct?.status === 'cNeverInForce';
        if (!productWaived) {
          const productId = product && product.bUID || '';
          const productCode = product?.subscribedProduct?.productCode?.buid || '';
          const productBuid = product && product.subscribedProduct && product.subscribedProduct.productCode && product.subscribedProduct.productCode.buid || '';
          const coverages = product && product.coverages || [];
          const productName = Object.keys(process.productFamilyIdentifier).find(key => process.productFamilyIdentifier[key] === productBuid);

          if (coverages.length > 0) {
            coverages.forEach(coverage => {
              const productNameDescription = (coverage && coverage.subscribedOption && coverage.subscribedOption.optionName) || '';
              const coverageBuid = coverage && coverage.bUID;
              const newInsureArray = [];
              let newPersonBuid = '';
              coverage && coverage.insuredPersons.forEach(insure => {
                const insuredPerson = insure && insure.beneficiariesDescription && insure.beneficiariesDescription.beneficiaries || [];
                if (insuredPerson?.length > 0) {
                  const personBuid = insure && insure.person && insure.person.identifier && insure.person.identifier.bUID || '';
                  newPersonBuid = personBuid;
                  insuredPerson.forEach(insurePerson => {
                    const isDependent = dependentsList.find(dependent => dependent?.id === insurePerson?.bUID);
                    const newInsurePerson = cloneDeep(insurePerson);
                    const beneficiaryBuid = newInsurePerson?.beneficiary?.identifier?.bUID || newInsurePerson?.bUID || '';
                    newInsurePerson['personBuid'] = personBuid;
                    newInsurePerson['beneficiaryBuid'] = beneficiaryBuid;
                    newInsurePerson['beneficiaryPersonBuid'] = newInsurePerson?.beneficiary?.identifier?.bUID || '';
                    if (beneficiariesFullData && Object.keys(beneficiariesFullData)?.length > 0) {
                      newInsurePerson['fullData'] = beneficiariesFullData[newInsurePerson?.beneficiary?.identifier?.bUID] || {};
                    } else {
                      newInsurePerson['fullData'] = {}
                    }
                    newInsureArray.push(newInsurePerson);
                  });
                }
              });
              globalBeneficiaries.push(...newInsureArray);
              const productObj = {};
              const beneficiariesInformation = getBeneficiaryType(newInsureArray);
              const productNameTitle = getFormKeyByProductFamily(productName);
              let titleObj = {};
              if (Array.isArray(coverageTitle)) {
                const foundCoverage = coverageTitle.find(coverage =>
                  coverage?.productId === productCode && coverage?.insuredPersonId === newPersonBuid && beneficiariesInformation.hasBeneficiaries
                );

                titleObj = foundCoverage || titleObj;
              }

              productObj['data'] = newInsureArray;
              productObj['personBuid'] = newPersonBuid;
              productObj['productId'] = productBuid;
              productObj['productBuid'] = productId;
              productObj['productName'] = productNameTitle;
              productObj['productTitle'] = titleObj?.content || '';
              productObj['productNameDescription'] = productNameDescription;
              productObj['coverageBuid'] = coverageBuid;
              productObj['beneficiaries'] = beneficiariesInformation && beneficiariesInformation.beneficiaryObj;
              productObj['hasBeneficiaries'] = beneficiariesInformation && beneficiariesInformation.hasBeneficiaries;
              productsArray.push(productObj);
            });
          }
        }
      });
      
      // Function to update Global Beneficiaries
      setBeneficiariesProducts(productsArray);
      setGlobalBeneficiaries(globalBeneficiaries);
    }
  }, [subscribedElements, process && process.productFamilyIdentifier, coverageTitle, Object.keys(beneficiariesFullData).length]);

  /* END TO TEST THE PRODUCT*/

  useEffect(() => {
    setLayoutData({
      ...dataFromRedux,
      beneficiariesProducts,
      globalBeneficiaries,
      dependentsList: dependentsBeneficiaryList,
    });
  }, [
    dataFromRedux,
    beneficiariesProducts,
    globalBeneficiaries,
    dependentsBeneficiaryList,
  ]);

  const getSubscribedCoverageBeneficiaryConfiguration = async () => {
    const enrollmentProcessBuid = storeFromRedux?.enrollment?.enrollmentData?.bUID;
    const resp = await http.post(appConfig.SSP_MID_URL + '/getSubscribedCoverageBeneficiaryConfiguration', {
      enrollmentProcessBuid: enrollmentProcessBuid,
      data: beneficiariesProducts,
    }).then((resp) => {
      if (resp?.data && resp?.data?.length > 0) {
        resp?.data.forEach(CoverageDetails => {
          const bUIDs = [];
          bUIDs.push({ "productBuid": CoverageDetails?.productId, "coverageBuid": CoverageDetails?.coverageBuid })
          const newProductFamily = CoverageDetails?.productFamily || '';

          if (bUIDs?.length > 0) {
            dispatch(SET_COVERAGE_BUIDS({ bUIDs, product: newProductFamily }));
          }

          dispatch(onLoadAPI({
            [`GetSubscribedCoverageBeneficiaryConfigurationFor${newProductFamily}`]: CoverageDetails,
            isAPICallInProgress: false,
          }));

          const newCoverageBeneficiaryEligiblity = getCoverageBeneficiaryEligibility(CoverageDetails.coverageInformation, CoverageDetails.coverageBuid);
          dispatch(SET_COVERAGE_BENEFICIARY_ELIGIBILITY({ eligibility: newCoverageBeneficiaryEligiblity, product: newProductFamily, productPage: true }));

        });
      } else {
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'getSubscribedCoverageBeneficiaryConfigurationApiFailure',
          show: true,
          page: currentStep,
        }));
      }
    });
  };

  const getPersonalInformation = async () => {
    const resp = await http.post(appConfig.SSP_MID_URL + '/v1/getBeneficiariesData', {
      subscribedElements: subscribedElements,
    }).then((resp) => {
      if (resp?.status === 200) {
        dispatch(onLoadAPI({ 'beneficiariesData': resp?.data?.data?.beneficiariesData?.data }));
      } else {
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          continue: true,
          id: 'getBeneficiariesDataApiFailure',
          show: true,
          page: currentStep,
        }));
      }
    });
  };

  useEffect(() => {
    if (currentStep === 'ManageBeneficiaries' && previousStep === 'EnrollmentSummary') {
      getSubscribedCoverageBeneficiaryConfiguration();
      getPersonalInformation();
    }
  }, [currentStep, previousStep])


  const onCloseModal = (modalName, formName, formik) => {
    $(`#${modalName}`).modal('hide');
    formik.resetForm();
  };



  return (
    <Fragment>
      
      <MessageComponent currentPage={"ManageBeneficiaries"} />
      <FormGenerator
        content={content}
        layoutData={layoutData}
        onCloseModal={onCloseModal}
      />
    </Fragment>
  );
};

export default ManageBeneficiaries;
