import React from 'react';
import Elementor from '../../Form/Elementor/index';
import MultipleCard from '../MultipleCard/index';
import Loader from '../../../Atoms/Loader';
import { useSelector } from 'react-redux';
const CardSelector = (props) => {
  let { loader } = useSelector((storeState) => storeState.loader);
  const { element } = props || {};
  let { result = {}} = props;
  const {
    showContent = [],
  } = props;
  if (loader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={loader} />;
  }
  
  return result && Object.keys(result).length > 0 ? (
    <div>
      {(element &&
        result &&
        result[element.name] &&
        result[element.name].length > 0 &&
        !element['isNotMultiple'] && (
          <MultipleCard
            {...props}
            key={`${`index`} element`}
            constantsList={{}}
            image={element.image}
            cardMembers={element.cardMembers}
            result={result}
            contentClassName=""
          />
        )) ||
        (result && element.name && (
          <Elementor
            {...props}
            key={`${`index`} element`}
            constantsList={{}}
            image={element.image}
            cardMembers={element.cardMembers}
            result={result}
            contentClassName=""
            showContent={showContent}
          />
        ))}
    </div>
  ) : (
    <Elementor
        key={element.name}
        element={element}
        showContent={showContent}
        {...props}
      />
  );
};
CardSelector.propTypes = {};

export default CardSelector;
