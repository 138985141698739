import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Summary from '../Component/Common/Summary';
import CoverageFlowFiveWrapper from '../Container/CoverageFlowFiveWrapper';
import { UPDATE_COVERAGE_DETAILS, UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo';
import { UPDATE_CART_ITEM_NEW } from '../../../../Store/Slices/EmployeeProfile';
import { useNavigationHook } from '../Hooks/NavigationHook';
import { getPetPlusAssureProductData } from '../Actions/getPetPlusAssureProductData';

const Flow5 = (props) => {
  const { currentStep, formsConfig } = useNavigationHook();
  const {
    step,
    showDisclosureStatement,
    RadioSelctedValue,
    validateSubscribedProductData,
    validateUnSubscribedProductData,
    declineProduct,
    employee,
  } = props || {};

  let { process, entities, insuredInfo } = useSelector((state) => state);
  let currentReduxState = useSelector((storeState) => storeState);

  const dispatch = useDispatch();

  const personBuid = employee[0]?.personBuid;

  const handleCoverageChange = (
    personBuid,
    selectedValue,
    waivedCoverage,
    coverageBuid,
    costPerPayPeriod
  ) => {
    dispatch(
      UPDATE_COVERAGE_DETAILS({
        personBuid,
        selectedValue,
        waivedCoverage,
        currentStep,
        coverageBuid,
        costPerPayPeriod,
      })
    );
    updateCostBasket();
  };

  const productTitle = useSelector((storeState) => {
    let productTitleContent = storeState.entities?.response?.onLoadResponseList?.[
      `SearchPolicyProcessResources${currentStep}`
    ]?._Result?.filter((data) => data?.code === 'PRODTITLE');

    return productTitleContent && productTitleContent.length > 0
      ? productTitleContent[0]?.content
      : '';
  });

  let productFamily = formsConfig?.forms?.[currentStep]?.productFamily;
  let productIdentifierBuid = process?.productFamilyIdentifier[productFamily];

  let enrollmentProcessBuid = process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
  const subscribedProductBuid =
    entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract
      ?.subscribedElements;
  let coverageDetails = insuredInfo?.[currentStep]?.coverageDetails || [];

  const enrollmentValue = currentReduxState?.enrollment?.enrollmentData?.contract?.subscribedElements || [];
  let declinedProduct = enrollmentValue?.filter(e => e?.subscribedProduct?.productCode?.buid === productIdentifierBuid);
  let isDeclinedProduct = declinedProduct?.[0]?.subscribedProduct?.status === "cDeclined" || declinedProduct?.[0]?.subscribedProduct?.status === "cTerminated" || declinedProduct?.[0]?.subscribedProduct?.status === "cNeverInForce" ? true : false
  const qleEndorsementProcessBuid = currentReduxState?.entities?.response?.onLoadResponseList?.initNewIndividualEndorsementProcess?._Result?.processEntity?.buid
  const isAdmin = useSelector((storeState) => storeState?.callCenter?.isAdmin);
  const enrollmentType = useSelector((storeState) => storeState?.employeeProfile?.enrollmentType);
  const reasonType = useSelector((storeState) => storeState?.employeeProfile?.reasonType);
  const ongoingEnrollmentType = enrollmentType === "editNewEnrollmentDuringOE"
  const ongoingReasonType = reasonType === "OE"
  const localProductWaivedArr = useSelector((storeState) => storeState?.enrollment?.cancelledCoverageBeforeConfirmationBuidArr);
  let isProductWaivedLocally = false;
  if (localProductWaivedArr?.indexOf(productIdentifierBuid) > -1) {
    isProductWaivedLocally = true;
  }

  useEffect(() => {
    if (productTitle && coverageDetails.length > 0) {
      updateCostBasket();
    }
  }, [productTitle, coverageDetails]);

  const updateCostBasket = () => {
    if (currentStep && productTitle && coverageDetails) {
      dispatch(
        UPDATE_CART_ITEM_NEW({
          id: currentStep,
          moreThanOne: true,
          name: productTitle,
          coverageList: coverageDetails,
        })
      );
    }
  };

  const petPlusPetAssureData = async () => {
    try {
      dispatch(UPDATE_LOADING(true));
      await dispatch(getPetPlusAssureProductData({
        enrollmentProcessBuid: enrollmentProcessBuid,
        subscribedProductBuid: subscribedProductBuid,
        productIdentifierBuid: productIdentifierBuid,
        currentStep: currentStep,
        subscribedElements: subscribedProductBuid,
        expectReturn: true,
        personBuid: personBuid,
        isRework: isDeclinedProduct || qleEndorsementProcessBuid || isAdmin || ongoingReasonType || ongoingEnrollmentType || isProductWaivedLocally ? true : false,
      }))
      dispatch(UPDATE_LOADING(false));
      return true
    } catch (error) {
      dispatch(UPDATE_LOADING(false));
      console.error('There was an error!', error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await petPlusPetAssureData();
    }
    fetchData();
  }, [enrollmentProcessBuid, subscribedProductBuid, productIdentifierBuid, currentStep, subscribedProductBuid, personBuid])

  const insuredInfoCurrentStep = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};
  let getDetails = insuredInfoCurrentStep.subscribeCoverageBuids?.filter(
    (coverage) => coverage?.personBuid === personBuid
  );
  const coverageBuid = getDetails?.[0]?.coverageBuid;

  const steps = [
    {
      label: '',
      component: () => (
        <CoverageFlowFiveWrapper
          {...props}
          stepsCount={steps.length}
          employeeIndex="0"
          displayMode={'All'}
          handleCoverageChange={handleCoverageChange}
          validateSubscribedProductData={validateSubscribedProductData}
          currentStep={currentStep}
          coverageBuid={coverageBuid}
        />
      ),
    },
  ];

  if (showDisclosureStatement) {
    steps.push({
      label: 'Disclosure Statement',
      component: () => (
        <div className="summaryWrapper">
          <Summary
            {...props}
            RadioSelctedValue={RadioSelctedValue}
            validateSubscribedProductData={validateSubscribedProductData}
            declineProduct={declineProduct}
            validateUnSubscribedProductData={validateUnSubscribedProductData}
          />
        </div>
      ),
    });
  }

  if (!step || !currentStep) {
    return null; // return null if step or currentStep is null or undefined
  }

  const currentStepInfo = steps[step - 1];
  if (!currentStepInfo) {
    return null;
  }

  return (
    <>
      {currentStepInfo.component()}
    </>
  );
};

export default Flow5;
