import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HistoryCard from './HistoryCard';
import appConfig from '../../../appConfig';
import http from '../../../Common/Services/HttpService';
import { SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../Store/Slices/InsuredInfo';

import './style.css';

const EnrollmentHistory = (props) => {

    const [history, setHistory] = useState({ inProgress: [], completed: [] });
    const dispatch = useDispatch();
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (props.contractBuid || props.inProgressBuid) {
            dispatch(UPDATE_LOADING(true));
            http.post(appConfig.SSP_MID_URL + `/v1/enrollment-history`, { contractBuid: props.contractBuid || props.inProgressBuid }).then(res => {
                const inProgress = [];
                const completed = [];

                if (res?.data?.history?.success) {
                    res?.data?.history.data.forEach(item => {
                        if (item.contractChange.className === 'aSPLI_Endorsement') { // Endorsement
                            const change = item.contractChange.descriptionOfChange;
                            const obj = { ...item.contractChange, descriptionOfChange: change ? JSON.parse(change) : null }
                            if (item.contractChange.status === "asActive" || item.contractChange.status === 'asDeclined') completed.push(obj);
                            else inProgress.push(obj);
                        } else if (item.contractChange.className === 'aSPLI_ContractChange') { // Enrollment
                            const change = item.contractChange.descriptionOfChange;
                            const obj = { ...item.contractChange, descriptionOfChange: change ? JSON.parse(change) : null }
                            if (!props.contractBuid && props.inProgressBuid) inProgress.push(obj)
                            else completed.push(obj);
                        }
                    });
                } else {
                    setMessage(res?.message || 'Error fetching enrollment history.')
                }
                if (inProgress.length > 0 || completed.length > 0) {
                    setHistory({ inProgress, completed });
                } else {
                    setMessage('At this point we do not have any history to be displayed.')
                }
                dispatch(UPDATE_LOADING(false));
            }).catch((e) => {
                setMessage(e?.message || 'Error fetching enrollment history.')
                dispatch(UPDATE_LOADING(false));
                return false;
            });
        } else {
            setMessage('At this point we do not have any history to be displayed.');
        }
    }, [props.contractBuid]);

    return (
        <div className="enrollment-history">
            {message ? (
                <div className="row">
                    <span style={{ color: '#A61003', margin: 5, paddingLeft: 10 }}>{message}</span>
                </div>
            ) : (
                <div>
                    <div className="enrollment-history__title">Events in Progress</div>
                    {history.inProgress.map(item => (
                        <HistoryCard history={item} key={item.buid} />
                    ))}

                    <div className="enrollment-history__title mt-4">Events Completed</div>
                    {history.completed.map(item => (
                        <HistoryCard history={item} key={item.buid} />
                    ))}
                </div>
            )}
        </div>
    );
}

export default EnrollmentHistory;
