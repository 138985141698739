import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { createPetListPayload, shouldWaiveCoverage, getDisclosureRadioValue, isWaivedProduct } from '../../Molecules/CustomSection/Helper/PetHelper'
import constants from '../../../App/Molecules/CustomSection/Content/Constants.json';
export const form = {
  "Accident": "CoverageSelection",
  "Pet Insurance": "PetAssureNation",
  "Whole Life": "WLCoverage",
  "ID Theft": "IdTheft",
  "Supplemental Health Insurance": "SupplementalHealthInsurance",
  "Legal": "LegalCoverage",
  "Travel Insurance": "Travel",
  "Pet Insurance - PETPlus": "PetPlus",
  "Pet Insurance - Pet Assure": "PetAssure",
  "Hospital Indemnity": "HospitalIndemnity",
  "Critical Illness": "CriticalIllness",
  "Universal Life": "UniversalLife",
  "Liability": "ExcessLiability",
};


export const getFormKeyByProductFamily = (productFamily) => {
  return form[productFamily] ? form[productFamily] : productFamily ? productFamily.replace(/\s/g, '') : '';
}

// Function to calculate the number of pay cycles in a year based on pay frequency
const getPayCyclesPerYear = (payFrequency = "Monthly") => {
  if (!payFrequency) return 0; // If payFrequency is undefined, return 0
  const frequencyItem = constants?.frequencyList.find(frequency => frequency.fieldValue === payFrequency);
  // Find the pay frequency item from constants.frequencyList and return its valueCode. If not found, return 0.
  return frequencyItem ? parseInt(frequencyItem.valueCode) : 0;
}

const processData = (productLists, enrollmentData, cartItemListCoverageLevel = [], storeFromRedux) => {


  // Get employment information from the Redux store
  const employmentInformation = storeFromRedux?.entities?.response?.onLoadResponseList?.EmploymentInformation?.employmentInformation;
  let isEmployeeBuyUpEnabled = false;
  isEmployeeBuyUpEnabled = storeFromRedux.buyUp.eligibility.employee;
  let basePlanBoughtCurrentYear = true;
  basePlanBoughtCurrentYear = storeFromRedux.buyUp.basePlanBoughtCurrentYearFlag.employee;
  let isBuyUpFlow = false;
  let buyUpDeclinedLocally = storeFromRedux.buyUp.basePlanDeclinedLocally.employee || false
  if(buyUpDeclinedLocally) {
    isBuyUpFlow = true
  } else {
    isBuyUpFlow = isEmployeeBuyUpEnabled ? (basePlanBoughtCurrentYear ? false : true ) : false;
  }
  // const dispatch   = useDispatch();

  // Your code snippet here...

  /* Endorsement  content */
  let insuredInfoCopy = {};

  Object?.entries(productLists || {})?.forEach(([key, value]) => {
    // const newKey = form[key]; // This line has been added
    insuredInfoCopy[key] = {
      InsuredQuestionAnswers: {},
      coverageDetails: [],
      coverageBuids: [],
      PolicyReplacementQuestions: [],
      WaiveCoverage: []
    };
  });

  const insureds = enrollmentData?.contract?.insureds;
  const subscribedElements = enrollmentData?.contract?.subscribedElements;
  const subscriptionManager = enrollmentData?.subscriptionManager;

  if (insureds) {
    insureds.forEach(({ insured, person, nonSharedAdditionalDatas }) => {
      const personBUID = person?.identifier?.bUID;
      if (personBUID) {
        // InsuredQuestionAnswers logic
        nonSharedAdditionalDatas?.forEach(({ productIdentifier, coverageNonSharedAdditionalDatas }) => {
          const productBUID = productIdentifier?.bUID;
          const productName = Object?.keys(productLists || {})?.find(key => productLists[key] === productBUID);

          if (productName) {
            coverageNonSharedAdditionalDatas?.[0]?.nonSharedAdditionalDatas?.forEach(({ fieldName, valueCode }) => {
              if (fieldName) {
                if (!insuredInfoCopy[productName].InsuredQuestionAnswers[personBUID]) {
                  insuredInfoCopy[productName].InsuredQuestionAnswers[personBUID] = {};
                }
                insuredInfoCopy[productName].InsuredQuestionAnswers[personBUID][fieldName] = valueCode;
              }
            });
          }

        });

        // coverageDetails logic
        if (subscribedElements) {
          subscribedElements.forEach(({ subscribedProduct, coverages }) => {
            const productBUID = subscribedProduct?.productCode?.buid;
            const productName = Object.keys(productLists || {}).find(key => productLists[key] === productBUID);

            //NationWide Pet logic -SK
            // Check if the productName is defined and is "Pet Insurance"
            if (productName === "Pet Insurance") {
              // Extract product status
              const productStatus = subscribedProduct?.status;

              // Generate payload
              const petListPayload = createPetListPayload(coverages, employmentInformation, enrollmentData);

              // Update petList in insuredInfoCopy
              insuredInfoCopy[productName] = {
                ...insuredInfoCopy[productName],
                petList: petListPayload,
              };

              // Determine if product should be waived 
              //Modified below condition to make the checkbox checked(waived) if the user waives product for the first time with no coverages
              const waiveProduct = coverages?.length > 0 ? shouldWaiveCoverage(coverages, enrollmentData) : (productStatus ? isWaivedProduct(productStatus) : false);

              // Update WaiveCoverage in insuredInfoCopy
              insuredInfoCopy[productName].WaiveCoverage = [
                { waiveProduct: waiveProduct }
              ];

              // for Nationwide pet Ratio Button
              // we don't no how to handle radiobutton sceanio for review and update
              insuredInfoCopy[productName].disClosureRadio = getDisclosureRadioValue(productStatus);
            }
            else if (productName) {
              if (coverages?.length) {
                coverages?.forEach(({ insuredPersons, subscribedOption }) => {
                  if (insuredPersons?.length) {
                    insuredPersons?.forEach(({ person: coveragePerson }) => {
                      const coveragePersonBUID = coveragePerson?.identifier?.bUID;
                      if (coveragePersonBUID === personBUID) {
                        // let waivedCoverage = true;
                        let selectedValue = null;
                        let costPerPayPeriod = '';
                        let coverageBuid = null;
                        let policyReplacementAnswer = {};
                        let petName = '';
                        let petType = '';
                        let coverageLevel = '';
                        // let policyWaived = true;
                        // once the API issue fixed need to  remove cNeverInForce condtion 
                        
                        let waivedCoverage = false,
                            policyWaived = false;
                        if(isBuyUpFlow) {
                          waivedCoverage = subscribedOption?.status === 'cDeclined' || subscribedOption?.status === 'cTerminated'
                          ? true : false;
                          policyWaived = subscribedOption?.status === 'cDeclined' || subscribedOption?.status === 'cTerminated'
                            ? true : false;
                        } else {
                          waivedCoverage = subscribedOption?.status === 'cDeclined' || subscribedOption?.status === 'cTerminated' || subscribedOption?.status === "cNeverInForce"
                          ? true : false;
                          policyWaived = subscribedOption?.status === 'cDeclined' || subscribedOption?.status === 'cTerminated' || subscribedOption?.status === "cNeverInForce"
                            ? true : false;
                        }

                        subscribedOption?.additionalDatas?.forEach(({ fieldCode, valueCode, fieldValue, nameDescription }) => {
                          if (fieldCode === 'DD_00177') {
                            costPerPayPeriod = `$${fieldValue?.amount}`
                          }
                          if (fieldCode === 'PlanDesign') {
                            selectedValue = valueCode;
                          }
                          if (fieldCode === 'DD_00169') {

                            petName = fieldValue
                          }
                          if (fieldCode === 'DD_00168') {
                            petType = fieldValue
                          }
                          if (fieldCode === 'DD_00174') {
                            coverageLevel = fieldValue
                          }
                          else {
                            policyReplacementAnswer = { ...policyReplacementAnswer, [fieldCode]: valueCode }
                          }
                        });
                        coverageBuid = subscribedOption?.buid

                        subscriptionManager?.forEach(({ product, choosenChoices }) => {
                          if (product?.bUID === productBUID) {

                            choosenChoices?.forEach(({ insureds, subscribedOptions }) => {
                              const hasTargetBUID = insureds?.some(insured => insured.bUID === personBUID);
                              if (hasTargetBUID) {
                                //coverageBuid = subscribedOptions?.[0]?.bUID

                              }
                            });
                          }
                        });
                        let costDetails = cartItemListCoverageLevel?.filter((e) => e.coverageBuid === coverageBuid);
                        let existingIndex = insuredInfoCopy?.[productName]?.coverageDetails?.findIndex((e) => e.coverageBuid === coverageBuid);
                        const obj = {
                          mainOwnerRelationshipToInsured: insured?.mainOwnerRelationshipToInsured || '',
                          relationship: insured?.mainOwnerRelationshipToInsured || '',
                          personBuid: personBUID,
                          selectedValue: waivedCoverage === true && selectedValue ? "" : selectedValue,
                          waivedCoverage,
                          coverageBuid: coverageBuid,
                          costPerPayPeriod: costDetails?.[0]?.amount,
                          petName: petName,
                          petType: petType,
                          coverageLevel: coverageLevel,

                        }
                        if (existingIndex !== -1) {

                          insuredInfoCopy[productName].coverageDetails[existingIndex] = obj;
                        } else {

                          insuredInfoCopy[productName]?.coverageDetails?.push(obj);
                        }

                        const existingCBIndex = insuredInfoCopy[productName].coverageBuids.findIndex(item => item.coverageBuid === coverageBuid);
                        if (existingCBIndex !== -1) {
                          insuredInfoCopy[productName].coverageBuids[existingCBIndex] = { personBuid: personBUID, coverageBuid: coverageBuid };
                        } else {
                          insuredInfoCopy[productName].coverageBuids.push({ personBuid: personBUID, coverageBuid: coverageBuid });
                        }

                        const prQIndex = insuredInfoCopy[productName].PolicyReplacementQuestions.findIndex(item => item.personBuid === personBUID);
                        if (prQIndex !== -1) {
                          insuredInfoCopy[productName].PolicyReplacementQuestions[prQIndex] = { personBuid: personBUID, answers: policyReplacementAnswer }
                        } else {
                          insuredInfoCopy[productName].PolicyReplacementQuestions.push({ personBuid: personBUID, answers: policyReplacementAnswer });
                        }

                        const wcIndex = insuredInfoCopy[productName].WaiveCoverage.findIndex(item => item.personBuid === personBUID)
                        if (wcIndex !== -1) {
                          insuredInfoCopy[productName].WaiveCoverage[wcIndex] = { personBuid: personBUID, waiveProduct: policyWaived };
                        } else {
                          insuredInfoCopy[productName].WaiveCoverage.push({ personBuid: personBUID, waiveProduct: policyWaived });
                        }
                        insuredInfoCopy[productName].disClosureRadio = 'Yes'
                      }
                    });
                  } else {
                    const waiveProduct = isWaivedProduct(subscribedProduct?.status);
                    // Update WaiveCoverage in insuredInfoCopy
                    insuredInfoCopy[productName].WaiveCoverage.push({ waiveProduct: waiveProduct, personBuid: personBUID })
                  }
                });
              } else {
                const waiveProduct = isWaivedProduct(subscribedProduct?.status);
                // Update WaiveCoverage in insuredInfoCopy
                insuredInfoCopy[productName].WaiveCoverage.push({ waiveProduct: waiveProduct, personBuid: personBUID })
              }
            }
          });
        }



        // 
      }
    });
  }
  /* Endorsement  content */
  // Dispatch the results to the appropriate actions

  const updatedInsuredInfoCopy = Object.keys(insuredInfoCopy).reduce((acc, key) => {
    const newKey = form[key];
    if (newKey) {
      acc[newKey] = insuredInfoCopy[key];
    }
    return acc;
  }, {});

  return updatedInsuredInfoCopy;


};
export default processData;
