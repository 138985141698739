import { Suspense, useState, useEffect } from "react";
import "./customtable.css";
export const TablePresentation = ({
    tableHeader,
    tableRow,
    planData,
    setWaiveSelected,
    selectionDisabled,
    val,
    id="table",
    selectedVal,
    waiveCoverageText = "Waive Coverage",
    preselectedVal = -1
}) => {
    let [selectedValue, setSelectedValue] = useState(null);
    let [waived, setWaived] = useState(val === -1);

    const triggerValueSelect = (e) => {
        setWaived(false)
        setWaiveSelected(false)
        setSelectedValue(e.target.value);
        selectedVal(e.target.value);
    }

    const triggerWaiveSelect = (e) => {
        setWaived(true)
        setWaiveSelected(true)
        setSelectedValue(-1)
        selectedVal(-1)
    }

    useEffect(() => {
        if (preselectedVal >= 0) {
            setSelectedValue(preselectedVal)
        }
    }, [preselectedVal])
    let tobleHeaderValue = planData?.length > 0 && planData?.[0].headerContent ? planData?.[0]?.headerContent : []
    const formatDisplayValueForAmount = (amount) => {
        let minimumValueToReformatCoverageAmount = 1000000
       if(amount >= minimumValueToReformatCoverageAmount) {
        let currencyObj = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            notation: 'compact'
        }).formatToParts(amount);
        
        let amountParts = currencyObj.map((currency) => currency.value);
        return amountParts[0] + amountParts[1] + " " + amountParts[2] + "illion";
       }
        return `$${amount}`
    }
    return (
        <Suspense fallback="loading">
            {planData && planData.length ?
                <table className="table atomCoverageTable table-mobile-border coverageOptionTable">
                    <thead>
                        <tr style={tableHeader[0].config.style}>
                            <th className="header-no-wrap colHeader d-none d-sm-table-cell" scope="col" aria-label="Select">
                                <span className="sr-only">Select</span>
                            </th>
                            {tableHeader.map((header, index) => tobleHeaderValue?.[index]?.presentedName || header.id =='costPerPayPeriod'?(
                                <th
                                    key={index}
                                    className={`header-no-wrap colHeader ${header.config.className}`}
                                    scope="col"
                                    style={header.config.style}
                                >
                                    {tobleHeaderValue?.[index]?.presentedName ? tobleHeaderValue?.[index]?.presentedName : header.value}
                                </th>
                            ):'')}
                        </tr>
                    </thead>
                    <tbody className="">
                        {planData.map((data, index) => (
                            <tr key={index}>
                                <td className="table-data-no-wrap coverageRadio " style={{ width: "1rem" }}>
                                    <span>
                                        <input
                                            id={`${id}_select_coverage_${index}`}
                                            className="form-check-radio coverageRadio"
                                            type="radio"
                                            value={index}
                                            checked={parseInt(selectedValue) === index}
                                            disabled={selectionDisabled}
                                            onChange={triggerValueSelect}
                                        />
                                        <label htmlFor={`${id}_select_coverage_${index}`} className="lable-sr-only">Input Label</label>
                                    </span>
                                </td>
                                {tableRow.map((row, idx) => planData?.[index]?.headerContent?.[idx] || row.id =='costPerPayPeriod'?(
                                    <td key={idx} className={`table-data-no-wrap ${row.className}`}>
                                        {
                                            typeof(planData?.[index]?.headerContent?.[idx]?.fieldValue) === 'object'
                                            ? planData?.[index]?.headerContent?.[idx]?.fieldValue?.amount? `${formatDisplayValueForAmount(planData?.[index]?.headerContent?.[idx]?.fieldValue?.amount)}`:''
                                            : planData?.[index]?.headerContent?.[idx]?.fieldValue
                                            ? planData?.[index]?.headerContent?.[idx]?.fieldValue
                                            : eval(row.value)
                                        }
                                    </td>
                                ):'')}
                            </tr>
                        ))}
                        <tr>
                            <td className="atomCoverageTableRadio" colSpan={tableHeader.length + 1} align="center">
                                <div className="atomCoverageTableRadio">
                                    <input
                                        className=""
                                        value={waived}
                                        checked={waived}
                                        type="radio"
                                        onChange={triggerWaiveSelect}
                                        id={`${id}_coverageWaiveId`}
                                    />
                                    <label htmlFor={`${id}_coverageWaiveId`} className="ml-3">{waiveCoverageText}</label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table> : null}
        </Suspense>
    )
}