import React from 'react';
import {Link} from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput } from '../../Atoms';
import {loginSchema} from '../../Atoms/FormValidation'
import Recaptcha from '../../../../Molecules/Recaptcha';
import appConfig from '../../../../../appConfig';
import { RESET_PASSWORD_SOURCE } from '../../../../../Store/Slices/Registeration';


const Login = ({onSubmit, initialValues, hideRecaptcha, recaptchaRef, errMsg, clrErr, clientLogo, logo, title, register}) => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: initialValues || {
          username: '',
          password:''
        },
        validationSchema : loginSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: (values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        },
      });
    
      const ErrorMessage = (field) => {
        return(
        <>
        {formik.touched[field] && formik.errors[field] ? (
          <div className="form-mandatory">{formik.errors[field]}</div>
        ) : null}
        </>
        )
      }
    return (
        <form className="offset-xl-0  offset-md-2 offset-lg-0 col-md-8 col-lg-11 d-flex flex-column" onSubmit={(e) => e.preventDefault()}>
        <div className="row pb-3 px-0 ">
            <div className="col-12 pt-0 pb-3 px-0 ">
            <div className="row text-center ">
                {!clientLogo && logo && (
                <div className="col-12 ">
                    <img
                    src={logo ? logo : ""}
                    alt="Client Logo"
                    className="img-responsive pt-3 logo-fixed-size"
                    id="client-logo"
                    />
                </div>
                )}

                {title && (
                <div className="col-12 ">
                    <h3 className="loginHeader ">{title}</h3>
                </div>
                )}
                <div className="col-12 px-0 ">
                <div className="devider "></div>
                </div>
            </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
            <h1 className="logInTitle  mb-3 mt-4">Log In</h1>
            </div>
            <div className="col-12 ">
            <p className="logInTitleDevider mb-4 " />
            </div>
            <div className="col-12 ">
            {/* <span className="inputLable">User ID</span> */}
            <TextInput
                id="username"
                name="username"
                label="User ID"
                placeholder="Enter your registered email id"
                required="true"
                className=" w-100 inputField my-3 p-3"
                onInput={clrErr}
                style={{borderColor: errMsg ? 'red' : 'rgb(218, 218, 218)'}}
                
                {...formik.getFieldProps('username')}
            />
            {ErrorMessage('username')}
            </div>
            <div className="col-12" style={{marginTop:15}}>
            {/* <span className="inputLable">Password</span> */}
            <TextInput
                id="password"
                name="password"
                label="Password"
                required="true"
                placeholder="Enter password"
                className="w-100 inputField my-2 p-3"
                style={{borderColor: errMsg ? 'red' : 'rgb(218, 218, 218)'}}
                type="password"
                onInput={clrErr}
                onChange={()=>{}}
                {...formik.getFieldProps('password')}
            />
            {ErrorMessage('password')}
            </div>
        </div>
        <div className="row py-2 ">
            <Link role="link" className="col-6 link text-left currentLink" to="/retrieveUserID">
            Forgot User ID?
            </Link>
            <Link role="link" className=" col-6 link text-right currentLink" to="/retrievePassword" onClick={()=> dispatch(RESET_PASSWORD_SOURCE('login'))}>
            Forgot Password?
            </Link>
        </div>
        {errMsg && 
        <div className="row">
            <span style={{ color: '#A61003',  margin:5, paddingLeft:10 }}>{errMsg} </span>
        </div>
        }
        <div className="row ">
            <div className="col-12 ">
            <button
                type="button"
                disabled={!formik.isValid || !formik.dirty}
                onClick={formik.submitForm}
                className=" btn  btn-primary-contrast btn-block my-3 loginContainerButton inputHeight "
            >
                Log In
            </button>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-8 mx-lg-0 px-xl-3 col-8 col-md-8 col-sm-8 py-3 ">
            <div className=" additionalInfoFirst ">DON’T HAVE AN ACCOUNT ?</div>
            <div className=" additionalInfoSecond ">Register for a new account.</div>
            </div>
            <div className="col-lg-4 col-4 col-md-4 col-sm-3 py-3 ">
            <button
                onClick={register}
                type="button"
                className=" btn  btn-outline-primary primaryOutline registerContainerButton w-100 "
                data-target="#/userRegistration"
                data-toggle="/userRegistration"
            >
                Register
            </button>
            </div>
        </div>
        {process.env.REACT_APP_RECAPTCHA_AVAILIBLE === 'true' && !hideRecaptcha ? (
            <Recaptcha
            recaptchaRef={recaptchaRef}
            siteKey={appConfig.RECAPTCHA_KEY}
            isVisible={false}
            />
        ) : (
            ''
        )}
    </form>
    )
}

export default Login;