import React, { useState, useEffect } from "react";
import CustomTable from '../Common/Table'
import { Button } from '../../../../Atoms';
import { useDispatch, useSelector } from 'react-redux';
import { onLoadAPI, onNextAPI, onLoadProductAPI, UPDATE_COVERAGE_INFO } from '../../../../../Store/Slices/Entities/response';
import ServiceHelper from '../../../../Utils/Helper/MappingHelper';
import Beneficiary from "../Beneficiary/Beneficiary";
import { prepareSubscriptionForChoice } from '../../Actions/prepareSubscriptionForChoice.js'
import { SUBSCRIBE_COVERAGE_BUIDS, UPDATE_LOADING, UPDATE_WAIVE_PRODUCT } from '../../../../../Store/Slices/InsuredInfo';
import { SET_COVERAGE_BUIDS, CHECK_ELIGIBILITY, SET_COVERAGE_BENEFICIARY_ELIGIBILITY, UPDATE_PRODUCT_LEVEL_ELIGIBILITY } from '../../../../../Store/Slices/InsuredInfo/index.js';
import { getCoverageBeneficiaryEligibility, getErrorMessages } from '../../Helper/MappingHelper';
import { getSubscribedCoverageBeneficiaryConfigurationNew } from '../../Actions/getSubscribedCoverageBeneficiaryConfigurationNew.js';
import { getListOfCoveragePlanDesignConfigurationNew } from '../../Actions/getListOfCoveragePlanDesignConfigurationNew.js';
import { getSubscribedCoverageConfigurationNew } from '../../Actions/getSubscribedCoverageConfigurationNew.js';
import { determineButtonLabel } from "../../Helper/MappingHelper";

import {
  UPDATE_ENROLLMENT_DATA
} from '../../../../../Store/Slices/Enrollment/index';
import { unsubscribeCoverage } from "../../Actions/unsubscribeCoverage";
import { updateSubscribedCoverageInformationCI } from "../../Actions/updateSubscribedCoverageInformationCI";
import { validateSubscribedProductData } from "../../Actions/validateSubscribedProductData";
import { continueBtnLabelHelper, screenNames } from "../../Helper/continueBtnLabelHelper";
import { coverageProductDetails, unsubscribeCoverageDetails, updateSubscribedCoverageInformation } from '../../Actions/productDetails';
import { useNavigationHook } from '../../Hooks/NavigationHook'; 
const ChildCoverage = (props) => {
  const serviceHelper = new ServiceHelper();
  const dispatch = useDispatch();
  const { currentStep,previousStep,formsConfig } = useNavigationHook();
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);
  let { personBuid = "", coverageDetails = [], displayMode } = props || {}
  const [disableContinue, setDisableContinue] = useState(true);
  const [totalPercent, setTotalPersent] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [prepareApiOneTime, setPrepareApiOneTime] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [canContinueBeneficiaries, setCanContinueBeneficiaries] = useState(false);
  const { step, prevStep, nextStep, onBack, currentBuid, coverageBuid, showDisclosureStatement, children, childIndex } = props;
  currentBuid && currentBuid(personBuid)

  const { insuredInfo, process, entities } = useSelector((storeState) => storeState);
  let currentReduxState = useSelector((storeState) => storeState);
  
  const callCenterEmployeeData = useSelector(
    (storeState) => storeState?.callCenter?.callCenterEnrollAsEmployeeLoginData
  );
  let productFamily = formsConfig?.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
  let beneficiaryList = []
  if (displayMode === 'Child') {
    if (insuredInfo?.[currentStep]?.updatedBeneficiaries?.length > 0) {
      let childBeneficiaries = insuredInfo?.[currentStep]?.updatedBeneficiaries?.filter((o) => {
        return o.hasOwnProperty(personBuid) ? o : "";
      });
      if (childBeneficiaries.length > 0) {
        beneficiaryList = childBeneficiaries[0]?.[personBuid]
      }
    }
  }

  const [showPolicyReplacementQuestions, setShowPolicyReplacementQuestions] = useState(false);

  const showOrHidePolicyReplacementQues = (personBuid, coverageIndex, isWaiveSelected) => {
    setShowPolicyReplacementQuestions(!isWaiveSelected);
  };
  let loggedUserEntity = Object.keys(callCenterEmployeeData).length === 0 ? currentReduxState.auth.loggedUserEntity : callCenterEmployeeData;
  let enrollmentProcessBuid = currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
  const subscribeCoverageBuids = entities?.response?.onLoadResponseList?.[currentStep]?.subscribeCoverageBuids || [];
  let getDetails = subscribeCoverageBuids.filter((e) => e?.personBuid === personBuid)
  //let subscribedElements = currentReduxState.entities?.response?.onLoadResponseList?.[currentStep]?.ValidateSubscribedProductData?._Result?.contract?.subscribedElements;
  let subscribedElements = getDetails?.[0]?.perpareSubscriptionForChoices?._Result || [];
  let allowedBeneficiaryKind = ""
  const getAllPossibleChoicesConfiguration = currentReduxState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]
  let possibleValues = [];

  //const coverageConfiguration = currentReduxState.entities?.response?.onLoadResponseList?.[`getSubscribedCoverageConfigurationFor${currentStep}`];
  let coverageConfiguration = getDetails?.[0]?.subscribedCoverageConfiguration || ''
  if (coverageConfiguration) {
    // let addintionData = coverageConfiguration.filter(e => e.personBuid === personBuid);
    //possibleValues = addintionData?.[0]?.result?.additionalDatas
    possibleValues = coverageConfiguration?._Result?.additionalDatas;
  }
  let coverageDetailsData = insuredInfo[currentStep]?.coverageDetails?.filter(e => e.personBuid === personBuid);
  let selectedValue = coverageDetailsData?.[0]?.selectedValue
  let waivedCoverage = coverageDetailsData?.[0]?.waivedCoverage;

  let getSubscribedCoverageBeneficiary = getDetails?.[0]?.beneficiaryConfiguration || ''
  let beneficiaryConfigurationDetails = getSubscribedCoverageBeneficiary?._Result?.filter((e) => e.forCoveredPerson?.bUID === personBuid)
  let allowedBeneficiaryDetails = beneficiaryConfigurationDetails?.[0]?.allowedBeneficiaryKind || []
  //let allowedBeneficiaryDetails = getSubscribedCoverageBeneficiary?._Result?.allowedBeneficiaryKind || []
  if (allowedBeneficiaryDetails.length > 0) {
    if (beneficiaryList.length > 0 && allowedBeneficiaryDetails.includes("cDefineBeneficiaries")) {
      allowedBeneficiaryKind = 'cDefineBeneficiaries';
    } else {
      allowedBeneficiaryKind = 'cByWillOrEstate';
    }
  }

  const [continueBtnLabel, setContinueBtnLabel] = useState("Continue");

  const continueBtnObj = {
    flowType: props.flowType,
    screenType: screenNames.COVERAGE_SCREEN,
    hasDisclosureStatement: props.showDisclosureStatement,
    isWaivedOffFlag: false,
    memberIdentifier: "children",
    spouseCount: props.spouse.length,
    childrenCount: props.children.length,
    productIdentifierBuid,
    memberDetails: {
      count: children.length,
      index: 0,
      eligible: true,
      buid: personBuid
    }
  }

  useEffect(() => {
    // if(props.flowType === 3) {
    //   if(!showDisclosureStatement) {
    //     setContinueBtnLabel(determineButtonLabel(props, true));
    //   }
    // } else {
    //   if(!showDisclosureStatement && children.length === 1 && (childIndex + 1) === children.length) {
    //     setContinueBtnLabel(determineButtonLabel(props, true));
    //   }
    // }
    if (props.flowType === 3) {
      setContinueBtnLabel(continueBtnLabelHelper({
        ...continueBtnObj,
        isWaivedOffFlag: waivedCoverage,
      }))
    } else {
      setContinueBtnLabel(continueBtnLabelHelper({
        ...continueBtnObj,
        isWaivedOffFlag: waivedCoverage,
        memberDetails: {
          count: children.length,
          index: childIndex,
          eligible: true,
          buid: personBuid
        }
      }))
    }
  }, [])


  const waiveOffCoverageSelected = (isChecked) => {
    // if(props.flowType === 3){
    //   if(isChecked && !showDisclosureStatement) {
    //     setContinueBtnLabel(determineButtonLabel(props, isChecked))
    //   } else {
    //     setContinueBtnLabel("Continue")
    //   }
    // } else {
    //   if(isChecked && !showDisclosureStatement && children.length === 1 && (childIndex + 1) === children.length) {
    //     setContinueBtnLabel(determineButtonLabel(props, isChecked))
    //   } else {
    //     setContinueBtnLabel("Continue")
    //   }
    // }
    dispatch(UPDATE_WAIVE_PRODUCT({personBuid, "waiveProduct": isChecked, currentStep}))
    if (props.flowType === 3) {
      setContinueBtnLabel(continueBtnLabelHelper({
        ...continueBtnObj,
        isWaivedOffFlag: isChecked,
      }))
    } else {
      setContinueBtnLabel(continueBtnLabelHelper({
        ...continueBtnObj,
        isWaivedOffFlag: isChecked,
        memberDetails: {
          count: children.length,
          index: childIndex,
          eligible: true,
          buid: personBuid
        }
      }))
    }
  }


  const subscribedProductBuid = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements

  let coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids[0];
  if (props.flowType === 2 || props.flowType === 3) {
    coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids.filter((e) => e?.coveragePersonBuid?.includes(personBuid))[0];
    if(props.flowType === 3) {
      let insuredInfoDetails = insuredInfo?.[currentStep]?.coverageInsuredEligiblity.filter(item => {
        return item.coverageBuid === coverageDetailsForPrepare.coverage && item.coverageEligiblity
      });
  
      let coveragePersonBuids = [];
  
      if(insuredInfoDetails && insuredInfoDetails.length) {
        insuredInfoDetails.map((item) =>{
          coveragePersonBuids.push(item.personBuid)
        })
      }
  
      coverageDetailsForPrepare = {
        ...coverageDetailsForPrepare,
        coveragePersonBuid: coveragePersonBuids
      }
    }
  }
  
  useEffect(() => {
    if (coverageDetailsForPrepare && subscribedProductBuid && !prepareApiOneTime) {


      let eligibleMembers = [];
      
      insuredInfo?.[currentStep]?.coverageInsuredEligiblity.filter(item => {
        if (item.coverageEligiblity && coverageDetailsForPrepare?.coveragePersonBuid?.includes(item.personBuid)) {
          eligibleMembers.push(item.personBuid)
        }
      });


      setPrepareApiOneTime(true)

      // const fetchData = async () => {
      //   try {
      //     await dispatch(validateSubscribedProductData({
      //       enrollmentProcessBuid: enrollmentProcessBuid,
      //       productIdentifierBuid: productIdentifierBuid,
      //       currentStep: currentStep,
      //       subscribedElements: subscribedProductBuid,
      //       expectReturn: true
      //     }));

      //     const coverageBuid = await dispatch(prepareSubscriptionForChoice(
      //       enrollmentProcessBuid,
      //       coverageDetailsForPrepare?.coverage,
      //       props.flowType === 3 ? eligibleMembers : coverageDetailsForPrepare?.coveragePersonBuid,
      //       currentStep,
      //       personBuid,
      //     ));

      //     if (!coverageBuid) {
      //       throw new Error('coverageBuid not received from prepareSubscriptionForChoice');
      //     }

      //     await dispatch(getSubscribedCoverageBeneficiaryConfigurationNew(
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       personBuid,
      //       currentStep,
      //     ));

      //     await dispatch(getSubscribedCoverageConfigurationNew(
      //       currentStep,
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       personBuid
      //     ));

      //     await dispatch(getListOfCoveragePlanDesignConfigurationNew(
      //       currentStep,
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       personBuid
      //     ));
      //   } catch (error) {
      //     console.error('There was an error!', error);
      //   }
      // }

      const fetchData = async () => {
        dispatch(UPDATE_LOADING(true));
        try {
          let listOfProduct = subscribedProductBuid?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
          let subscribedPBuid = listOfProduct?.[0]?.bUID
          await dispatch(coverageProductDetails({
            enrollmentProcessBuid: enrollmentProcessBuid,
            flowType: props.flowType,
            subscribedProductBuid: subscribedPBuid,
            personBuid: personBuid,
            prepareCoverageBuid: coverageDetailsForPrepare?.coverage,
            prepareCoveredPersonBuid: coverageDetailsForPrepare?.coveragePersonBuid,
            currentStep: currentStep
          }));
        } catch (e) {
          console.error(e)
        } finally {
          dispatch(UPDATE_LOADING(false));
        }
      }

      fetchData();
    }
  }, [prepareApiOneTime, currentStep, getAllPossibleChoicesConfiguration, insuredInfo, enrollmentProcessBuid, personBuid, props.flowType, coverageDetailsForPrepare, subscribedProductBuid]);

  const nextStepCall = async () => {
    if (!waivedCoverage) {
      dispatch(UPDATE_LOADING(true));
      let externalStepContext = {
        "portalStep": currentStep,
        "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
        "reachedPreConfirmation": "no"
      };
      try {
        await dispatch(updateSubscribedCoverageInformation({
          enrollmentProcessBuid,
          subscribedElements,
          productIdentifierBuid,
          beneficiaryList,
          allowedBeneficiaryKind,
          selectedValue,
          possibleValues,
          loggedUserEntity,
          currentStep,
          coverageBuid: getDetails?.[0]?.coverageBuid,
          externalStepContext: externalStepContext,
          isMoveNext: false,
          personBuid
        }));

      } catch (e) {
        console.error(e)
      } finally {
        dispatch(UPDATE_LOADING(false));
      }
      // setShowLoader(true);
      // let actionDispatched = await dispatch(updateSubscribedCoverageInformationCI({
      //   enrollmentProcessBuid,
      //   subscribedElements,
      //   productIdentifierBuid,
      //   beneficiaryList,
      //   allowedBeneficiaryKind,
      //   selectedValue,
      //   possibleValues,
      //   loggedUserEntity,
      //   //subscribedCoverageBuid
      // }))
      // if (actionDispatched) {

      //   let externalStepContext = {
      //     "portalStep": currentStep,
      //     "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
      //     "reachedPreConfirmation": "no"
      //   };

      //   dispatch(UPDATE_LOADING(true));
      //   try {
      //     let respObj = await serviceHelper.sendApiRequest('/validateSubscribedCoverageData', {
      //       enrollmentProcessBuid: enrollmentProcessBuid,
      //       coverageBuid: getDetails?.[0]?.coverageBuid,
      //       externalStepContext
      //     });
      //     if (respObj.data && respObj.status === 200) {
      //       // Format the new data
      //       dispatch(SUBSCRIBE_COVERAGE_BUIDS({
      //         coverageBuid: getDetails?.[0]?.coverageBuid,
      //         personBuid: personBuid,
      //         product: currentStep,
      //       }));
      //       dispatch(
      //         UPDATE_COVERAGE_INFO({
      //           coverageBuid: getDetails?.[0]?.coverageBuid,
      //           personBuid: personBuid,
      //           product: currentStep,
      //           validateSubscribedCoverageData: respObj.data
      //         })
      //       );
      //     }
      //     setShowLoader(false);
      //     //return true;
      //   } catch (e) {
      //     console.error(e);
      //   } finally {
      //     dispatch(UPDATE_LOADING(false));
      //   }
      // }
    } else if (waivedCoverage === true) {
      setShowLoader(true);
      //let getDetails = subscribeCoverageBuids.filter((e) => e?.personBuid === personBuid)
      //let subscribedCoverageBuid = getDetails?.[0]?.coverageBuid;
      dispatch(UPDATE_LOADING(true));
      try {
        await dispatch(unsubscribeCoverageDetails({
          enrollmentProcessBuid: enrollmentProcessBuid,
            coverageBuid: coverageDetailsForPrepare?.coverage,
            coveredPerson: coverageDetailsForPrepare?.coveragePersonBuid,
            subscribedCoverageBuid: getDetails?.[0]?.coverageBuid,
            isPrepare: false,
            isMoveNext: false,
            currentStep: currentStep
        }, "CreateOrUpdateInsuredCIGeneral", true));
        // let actionDispatched = await dispatch(unsubscribeCoverage({
        //   enrollmentProcessBuid,
        //   subscribedCoverageBuid
        // }, "CreateOrUpdateInsuredCIGeneral"));

        // if (actionDispatched) {
        //   setShowLoader(false);
        //   dispatch(onLoadAPI({
        //     CreateOrUpdateInsuredCIGeneral: actionDispatched.data,
        //     isAPICallInProgress: false,
        //   }))
        //   //return true;
        // }
      }
      catch (e) {
        console.error(e)
      } finally {
        dispatch(UPDATE_LOADING(false));
      }
    }
    if (children.length === childIndex + 1 && !showDisclosureStatement) {
        props.onNext()
    } else {
        nextStep();
    }

  }

  const checkBenificiaryFn = (pstatus, pselectedRows) => {
    let primaryTotal = 0;
    let contingentTotal = 0;
    let hasPrimary = false;
    let hasContingent = false;
    insuredInfo[currentStep]?.updatedBeneficiaries?.forEach(pobj => {
      Object.keys(pobj).forEach((pbeneficiary, pind) => {
        const beneficiary = pobj[pbeneficiary][pind];
        if (beneficiary.beneficiaryType === 'Primary') {
          primaryTotal += beneficiary.beneficiaryPercentage;
          hasPrimary = true;
        } else {
          contingentTotal += beneficiary.beneficiaryPercentage;
          hasContingent = true;
        }
      })
    });
    let lflag = true;
    if (hasPrimary && primaryTotal !== 100) {
      lflag = false;
    }

    if (hasContingent && contingentTotal !== 100) {
      lflag = false;
    }
    if (pstatus == "success") {
      setTotalPersent(100);
    } else {
      const currentPercent = ((primaryTotal > 0 ? primaryTotal : 0) + (contingentTotal > 0 ? contingentTotal : 0)) * 100 / 200;
      setTotalPersent(0);
    }
    setSelectedRows([...pselectedRows]);
  };
  const totalPolicyQuestionDiff = 0;

  const handleDisableFn = () => {
    if (selectedValue === undefined) {
      setDisableContinue(true);
    } else if (selectedValue === null || selectedValue === "") {
      setDisableContinue(false);
    } else if (!props.beneficiaryEligibility && selectedValue && totalPolicyQuestionDiff === 0) {
      setDisableContinue(false);
    } else {
      const isBeneficiaryValid = (props.isBeneficiaryMandatory && selectedRows?.length && canContinueBeneficiaries)
                              || (props.beneficiaryEligibility && (!selectedRows?.length || canContinueBeneficiaries));
      if (isBeneficiaryValid && totalPolicyQuestionDiff === 0) {
        setDisableContinue(false);
      } else {
        setDisableContinue(true);
      }
    }
    // else if (props.beneficiaryEligibility
    //   && !props.isBeneficiaryMandatory
    //   && selectedRows?.length > 0
    //   && totalPercent == 100) {
    //   setDisableContinue(false);
    // }
    // else if (props.beneficiaryEligibility
    //   && !props.isBeneficiaryMandatory && selectedRows?.length == 0
    //   && totalPolicyQuestionDiff == 0) {
    //   setDisableContinue(false);
    // }
    // else if (props.isBeneficiaryMandatory
    //   && totalPercent == 100
    //   && totalPolicyQuestionDiff == 0) {
    //   setDisableContinue(false);
    // }
    // else if (!props.beneficiaryEligibility && totalPolicyQuestionDiff == 0) {
    //   setDisableContinue(false);
    // }
    // else {
    //   setDisableContinue(true);
    // }
  };


  useEffect(() => {
    handleDisableFn();
  }, [selectedValue, totalPercent, selectedRows, totalPolicyQuestionDiff]);

  const hasErrors = getErrorMessages(currentStep);

  return (

    <>

      <div className="row" style={{ fontSize: '1.5rem', color: 'rgb(32, 32, 32)', fontFamily: 'NotoSansRegular' }}>
        <div className="">
          <div className="label col-12 px-3 undefined" style={{ display: 'inline-block' }}>
            <div className="row">
              <div className="px-3 py-3">
                Select Coverage Option for Child
              </div>
            </div>
          </div>
        </div>
      </div>
      {coverageDetails && (coverageDetails?.length > 0) &&

        <CustomTable {...props} showOrHidePolicyReplacementQues={showOrHidePolicyReplacementQues} selectedValue={selectedValue} waivedCoverage={waivedCoverage} tableData={coverageDetails} waiveOffCoverageSelected={waiveOffCoverageSelected} relationship="CH"> </CustomTable>}


      {(showPolicyReplacementQuestions || selectedValue) && (props.beneficiaryEligibility) &&
        <Beneficiary
          currentStep={props.currentStep}
          personBuid={props.personBuid}
          allowedBeneficiaryKind={props.allowedBeneficiaryKind}
          dependentsList={props.dependentsList}
          relation={'child'}
          updateBeneficiary={checkBenificiaryFn}
          coverageBuid={getDetails?.[0]?.coverageBuid}
          setCanContinueBeneficiaries={setCanContinueBeneficiaries}
        ></Beneficiary>
      }
      <div className='button-group'>
        <>
          <Button type="button" onClick={prevStep} className="mr-3">
            Back
          </Button>
        </>
        < Button type="button" onClick={nextStepCall} disabled={disableContinue || hasErrors}
          className=""
        >
          {continueBtnLabel}
        </Button>

      </div>
    </>

  );
};

export default ChildCoverage;
