import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../../Atoms';

import './styles.css';
const PanelSideHeader = (props) => {
  const {
    label,
    formSideHeader,
    formMandatory,
    formSideHeaderContact,
    formSideHeaderWithImage,
    formSideHeaderWithImageBackground,
    filename,
    mandatory,
    formSideBigHeader,
    className,
    hideDefaultClass,
    hideElement,
    classNameWrapper,
    imgStyle,
    forRegisteration,
    config = {},
    removePading = false
  } = props;
  let additionalClasses = hideElement ? 'hideElement' : classNameWrapper;
  let currentYear = new Date().getFullYear()
  return (
    <div class={additionalClasses}>
      <div className="row">
        <div class={!removePading ? "col-md-12" : "col-md-12 pl-0"}>
          {!formSideHeader &&
            !formMandatory &&
            !formSideHeaderContact &&
            !formSideHeaderWithImage &&
            !formSideBigHeader &&
            !formSideHeaderWithImageBackground && (

              <>
                {
                  config.id === 'lifeEvent' ?
                    <label className="panel-side-header" style={config}>{`We understand that you have a life event to record for ${currentYear}.`}</label> : <label className="panel-side-header" style={config}>{label}</label>
                }
              </>
            )}

          {formSideHeader && <h1 className="form-side-header" style={config}>{formSideHeader}</h1>}
          {formSideHeaderContact && (
            <div className="form-side-header-contact content">{mandatory && (<span>*</span>)} {formSideHeaderContact}</div>
          )}
          {formSideBigHeader && (
            <div class="form-side-big-header d-inline-block mb-2">
              {formSideBigHeader} {mandatory && <span className='mandatory'>*</span>}
            </div>
          )}
          {/* {formSideBigHeader && mandatory && <label className="form-side-big-header">{formSideBigHeader}</label> <span>*</span>} */}
          {formMandatory && <div class="form-mandatory d-inline-block mb-2">{formMandatory}</div>}
          {formSideHeaderWithImage && (
            <div class="row">
              <div className={`${`col-1 panel-sid-img pr-1 py-1`}`}>
                <Image filename={filename} hideDefaultClass={hideDefaultClass} style={imgStyle} />
              </div>
              <div className={!forRegisteration ? `${`col-11 panel-sid-cont`}` : `${`col-11 panel-sid-cont`} pl-xl-0 `}>
                <span>
                  <label className={!forRegisteration ? "panel-side-header-img" :
                    "panel-side-header-img pl-lg-0 mt-lg-0 pt-lg-2 pl-md-2 mt-md-1 pl-3 mb-0 pt-md-3 my-2 pt-1 "}>
                    {formSideHeaderWithImage}
                  </label>
                </span>
              </div>
            </div>
          )}
          {formSideHeaderWithImageBackground && (
            <div class="row">
              <Image filename={filename} hideDefaultClass={hideDefaultClass} />
              <span>
                <label className="readonly_label_very_big_content">{formSideHeaderWithImage}</label>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
PanelSideHeader.propTypes = {
  label: PropTypes.string,
  formSideHeader: PropTypes.string,
  formMandatory: PropTypes.string,
  filename: PropTypes.string,
  formSideHeaderWithImage: PropTypes.string,
  hideDefaultClass: PropTypes.string
};
PanelSideHeader.defaultProps = {
  className: ''
};

export default PanelSideHeader;
