import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormGenerator from '../../../Cells/Form/FormGenerator';
import { STEP_KEYS } from '../../../../Store/Slices/StepForm';
import './styles.css';

const QLEOpenEnrollment = (props) => {
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector(({ language }) => language);
  const history = useHistory();
  const [content, setContent] = useState([]);
  const [layoutData, setLayoutData] = useState([]);
  const { loggedUserEntity: { data: dataFromRedux } = {} } = useSelector(({ auth }) => auth);
  const {
    dependentsList,
    dependentsInfo,
    dependentsBeneficiaryList = [],
  } = useSelector(({ employeeProfile }) => employeeProfile);

  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => setContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);

  useOnBack(() => {
    history.push('/enrollment/home');
  })

  useEffect(() => {
    setLayoutData({
      ...dataFromRedux,
      dependentsList,
      dependentsInfo,
      dependentsBeneficiaryList,
    });
  }, []);

  const stepKeys = [
    'EmployeeProfile',
    'ProductSelection',
    'DependentInformation',
    'CoverageSelection',
    'IdTheft',
    'SupplementalHealthInsurance',
    'LegalCoverage',
    'Travel',
    'PetPlus',
    'PetAssure',
    'HospitalIndemnity',
    'CriticalIllness',
    'EnrollmentSummary',
  ];

  dispatch(STEP_KEYS({ stepKeys: stepKeys }));
  return (
    <Fragment>
      <FormGenerator content={content} layoutData={layoutData} />
    </Fragment>
  );
};

export default QLEOpenEnrollment;
