import HttpService from "../../../../Common/Services/HttpService";
import { SET_BASE_PLAN_BOUGHT_IN_CURRENT_YEAR_FLAG, SET_BASE_PLAN_DECLINED_LOCALLY_EMPLOYEE, SET_BASE_PLAN_DECLINED_LOCALLY_SPOUSE, SET_BUYUP_BOUGHT_IN_CURRENT_YEAR_FLAG, SET_COST_ANNUAL, SET_COST_BASKET_DATA, SET_COST_PAY_PER_PERIOD, SET_COVERAGE_BUID, SET_ELIGIBILITY, SET_EXISTING_COVERAGE_BUID, SET_PLAN_BUID, SET_PLAN_STATUS_EMPLOYEE, SET_PLAN_STATUS_SPOUSE, SET_SELECTED_BASE_PLAN_DETAILS } from "../../../../Store/Slices/BuyUp";
import { UPDATE_CART_ITEM_NEW } from "../../../../Store/Slices/EmployeeProfile";
import { UPDATE_ENROLLMENT_DATA } from "../../../../Store/Slices/Enrollment";
import { onLoadAPI } from "../../../../Store/Slices/Entities/response";
import { SUBSCRIBE_COVERAGE_BUIDS, UPDATE_LOADING } from "../../../../Store/Slices/InsuredInfo";
import appConfig from "../../../../appConfig";
import { store } from "../../../../index";
import ServiceHelper from "../../../Utils/Helper/MappingHelper";
import { updateSubscribedCoverageInformation } from "../Actions/productDetails";
import { unsubscribeCoverage } from "../Actions/unsubscribeCoverage";
import { updateSubscribedCoverageInformationCI } from "../Actions/updateSubscribedCoverageInformationCI";
import { formatCurrency } from "./MappingHelper";

//Constants for buy up only
export const MEMBER_TYPE = {
    EMPLOYEE: "employee",
    SPOUSE: "spouse"
}

//TODO: fix this function to serve all currency and format types
export const unformatter = (value) => {
    if (value) {
        value = value.replace("$", "");
        value = value.replace(",", "");
        value = parseFloat(value);
        return (value);
    }
};

// ID to enable buyup functionality on defined products
export const eligibleProductListForBuyUp = ['CIAFLAC#0']
export const buyUpIdentifier = 'cCSM_AddUp';
export const employeeIndex = 0;
export const waivedPlanStatusArr = ["cTerminated", "cNeverInForce", "cDeclined"];
const serviceHelper = new ServiceHelper();

// Register a buy up plan for selected buy up plan
export const registerForBuyUpPlan = ({ currentStep, enrollmentProcessBuid, productIdentifierBuid, buyUpCoverageBuid, personBuid, buyUpPlanSelectedValue, currentBeneficieries }) => async (dispatch) => {
    const storeState = store.getState();
    const onLoadResponseList = storeState?.entities?.response?.onLoadResponseList;
    const enrollmentData = storeState?.enrollment?.enrollmentData;
    const insuredInfo = storeState?.insuredInfo;
    const productDetails = onLoadResponseList?.[currentStep];
    const getDetails = productDetails?.subscribeCoverageBuids?.filter((item) => { return item?.personBuid === personBuid && item?.coverageBuid === buyUpCoverageBuid});
    const subscribedElementsArrholder = onLoadResponseList?.addSubscriptionForChoiceForBuyUp.filter((item) => { return item?.personBuid === personBuid });
    const productList = subscribedElementsArrholder[subscribedElementsArrholder.length - 1]?.data?._Result?.contract?.subscribedElements.filter((item) => { return item?.subscribedProduct?.productCode?.buid === productIdentifierBuid });
    const subscribedElements = productList?.[0]?.coverages.filter((item) => item.bUID === buyUpCoverageBuid);
    const increaseIndexbyOne = 1; //used to increase index by 1 to match item.code returned from response
    
    let beneficiaryList = [];
    if ( insuredInfo?.[currentStep]?.updatedBeneficiary?.[buyUpCoverageBuid]?.length > 0 ) {
        const beneficiariesData = insuredInfo?.[currentStep]?.updatedBeneficiary?.[buyUpCoverageBuid];
        beneficiaryList = beneficiariesData.filter(data => data?.beneficiarySelected === true);
    }

    let possibleValues = [];
    const coverageConfiguration = getDetails?.[0]?.subscribedCoverageConfiguration || ''
    if (coverageConfiguration) {
        possibleValues = coverageConfiguration?._Result?.additionalDatas;
    }

    const policyReplacementQuestions = insuredInfo?.[currentStep]?.policyReplacementQuestions
    let policyReplacementAnswer = []
    if (Object.keys(policyReplacementQuestions).length > 0) {
        Object.entries(policyReplacementQuestions).map(([key, value]) => policyReplacementAnswer.push({ fieldName: key, valueCode: value }));
    }

    let allowedBeneficiaryKind = "";
    const getSubscribedCoverageBeneficiary = getDetails?.[0]?.beneficiaryConfiguration || ''
    
    const beneficiaryConfigurationDetails = getSubscribedCoverageBeneficiary?._Result?.filter((e) => e.forCoveredPerson?.bUID === personBuid)
    const allowedBeneficiaryDetails = beneficiaryConfigurationDetails?.[0]?.allowedBeneficiaryKind || []
    
    if (allowedBeneficiaryDetails.length > 0) {
        if (beneficiaryList.length > 0 && allowedBeneficiaryDetails.includes("cDefineBeneficiaries")) {
            allowedBeneficiaryKind = 'cDefineBeneficiaries';
        } else {
            allowedBeneficiaryKind = 'cByWillOrEstate';
        }
    }

    const loggedUserEntity = storeState.auth.loggedUserEntity;

    let externalStepContext = {
        "portalStep": currentStep,
        "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
        "reachedPreConfirmation": "no"
    };

     await dispatch(updateSubscribedCoverageInformation({
        enrollmentProcessBuid: enrollmentProcessBuid,
        subscribedElements,
        productIdentifierBuid: productIdentifierBuid,
        beneficiaryList,
        allowedBeneficiaryKind,
        selectedValue:  parseInt(buyUpPlanSelectedValue) + increaseIndexbyOne,
        possibleValues,
        policyReplacementAnswer,
        loggedUserEntity,
        currentStep: currentStep,
        coverageBuid: buyUpCoverageBuid,
        externalStepContext: externalStepContext,
        isMoveNext: false,
        personBuid
    }));
}

export const declineEntireProduct = ({enrollmentProcessBuid, subscribedElements, productIdentifierBuid, currentStep}) => async (dispatch) => {
    return await HttpService
      .post(appConfig.SSP_MID_URL + '/declineProduct', {
        payload: {
          enrollmentProcessBuid: enrollmentProcessBuid,
          subscribedProductBuid: subscribedElements,
          productIdentifierBuid: productIdentifierBuid,
          currentStepInfo: {
            "portalStep": currentStep
          }
        }
      })
      .then((resp) => {
        if (resp.data && resp.status === 200) {
          dispatch(onLoadAPI({ ...{ [`declineProduct`]: resp.data } }));
          const processInfo = resp?.data?._Result ?? {};
          dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
          dispatch(SET_BASE_PLAN_DECLINED_LOCALLY_EMPLOYEE(true));
          dispatch(SET_BASE_PLAN_DECLINED_LOCALLY_SPOUSE(true))
          dispatch(
            UPDATE_CART_ITEM_NEW({
              id: currentStep,
              moreThanOne: true,
              declineProduct: true
            })
          );
        }
      }).catch((error) => {
        
      })
}

// call unsubscribe API for the passed coverage BUIDS
export const unsubscribeFromPlan = ({ enrollmentProcessBuid, personBuid, coverageBuidArr, personType, declineType }) => async (dispatch) => {
    const storeState = store.getState();
    const coverages = storeState?.buyUp?.coverageBuid
    try {
        dispatch(UPDATE_LOADING(true));
        if (coverageBuidArr.length) {
            let i = 0;
            while (i < coverageBuidArr.length) {
                let actionDispatched = await dispatch(unsubscribeCoverage({
                    enrollmentProcessBuid: enrollmentProcessBuid,
                    subscribedCoverageBuid: coverageBuidArr[i]
                }, "CreateOrUpdateInsuredCIGeneral"));

                if (actionDispatched) {
                    if( declineType === "buyupPlan") {
                        if(personType === "employee") {
                            dispatch(SET_COVERAGE_BUID({
                                ...coverages,
                                employee: ""
                            }))
                        } else {
                            dispatch(SET_COVERAGE_BUID({
                                ...coverages,
                                spouse: ""
                            }))
                        }
                    }
                    
                    dispatch(onLoadAPI({
                        CreateOrUpdateInsuredCIGeneral: actionDispatched.data,
                        isAPICallInProgress: false,
                    }))
                    //return true;
                }
                i++;
            }
        }


        dispatch(UPDATE_LOADING(false));
    }
    catch (e) {
        console.error(e)
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}

// Filter critical illness products using the eligibleProductListForBuyUp array
export const filterCriticalIllnessProduct = ({ productList }) => {
    let productBuid = '',
        criticalIllnessProduct;
    if (productList && productList.length) {

        criticalIllnessProduct = productList.filter((product) => {
            if (eligibleProductListForBuyUp.indexOf(product?.memberOfferedProduct?.code) !== -1 && product.coverages?.[employeeIndex]?.memberOfferedCoverage?.option?.subscriptionMode === buyUpIdentifier) {
                productBuid = product?.memberOfferedProduct?.product?.buid;
                return product;
            }
        })
    }

    return {
        productBuid,
        criticalIllnessProduct
    }
}

// add buyup plans to base plans to show table. If buy up not available, update the table data to show information as per UI designs for CI products 
export const updatePlanListOnSummaryScreen = ({ productMapdetails, CIproductbuid, buyUpDetails }) => {
    let CIPlanDetails = [], productIndex, productSchema;
    productMapdetails && productMapdetails.map((product, index) => {
        if (product.length) {
            if (product[0].coverageProductBuid === CIproductbuid) {
                productIndex = index;
                productSchema = product[0]
            }
        }
    });
    
    if (productMapdetails) {
        const CIProduct = productMapdetails[productIndex];
        if(CIProduct && CIProduct.length) {

            if(buyUpDetails?.employee && buyUpDetails?.employee?.allCoverageDetails?.length) {
                CIPlanDetails.push({
                    ...productSchema,
                    coverageLevel: `${formatCurrency(buyUpDetails?.employee?.coverage || 0)}`,
                    anualCost: [formatCurrency(buyUpDetails?.employee?.annualCost || 0)],
                    costPerPayPeriod: [formatCurrency(buyUpDetails?.employee?.payPerPeriodCost || 0)],
                    coverageBuid: buyUpDetails?.employee?.allCoverageDetails?.[employeeIndex]
                })
            } 
            
            if (buyUpDetails?.spouse && buyUpDetails?.spouse?.allCoverageDetails?.length) {
                CIPlanDetails.push({
                    ...productSchema,
                    coverageLevel: `${formatCurrency(buyUpDetails?.spouse?.coverage || 0)}`,
                    anualCost: [formatCurrency(buyUpDetails?.spouse?.annualCost || 0)],
                    costPerPayPeriod: [formatCurrency(buyUpDetails?.spouse?.payPerPeriodCost || 0)],
                    coverageBuid: buyUpDetails?.spouse?.allCoverageDetails?.[employeeIndex]
                })
            } 
        }

        if(CIPlanDetails && CIPlanDetails.length) {
            CIPlanDetails = CIPlanDetails.map((plan) => {
                let newGetCoveredMembers = []
                if(plan.getCoveredMembers && plan.getCoveredMembers.length) {
                    newGetCoveredMembers = plan.getCoveredMembers.filter((coveredMembers) => {
                        return waivedPlanStatusArr.indexOf(coveredMembers?.[1]?.status) === -1
                    })
                }

                return {
                    ...plan,
                    getCoveredMembers: newGetCoveredMembers
                }
            })
        }
        
        if(CIPlanDetails && CIPlanDetails.length) {
            productMapdetails[productIndex] = CIPlanDetails;
        }
        
    }
}

// update option codes object to get the other details using buyup optioncode for employee and spouse coming from API
export const updateOptionCodesOnSummaryScreen = ({ criticalIllnessProduct, coverageData, contractRatingInfo, productBuid }) => async (dispatch) => {
    let employeeBuyUpEligibility = false,
        spouseBuyUpEligibility = false;

    const storeState = store.getState();
    const productDeclinedLocally = storeState?.buyUp?.basePlanDeclinedLocally
    let buyUpEligibility = storeState?.buyUp?.eligibility || {
        employee: false,
        spouse: false
    };

    let productIdentifier;

    let optionCodes = {
        employee: {
            identifier: "employee",
            basePlan: "",
            buyup: "CIEEAFLACBU#0",
            coverageDetails: [],
            allCoverageDetails: [],
            annualCost: 0,
            coverage: 0,
            payPerPeriodCost: 0,
            coverageAmount: [],
            personBuid: "",
            planStatus: []
        },
        spouse: {
            identifier: "spouse",
            basePlan: "",
            buyup: "CISPAFLACBU#0",
            coverageDetails: [],
            allCoverageDetails: [],
            annualCost: 0,
            coverage: 0,
            payPerPeriodCost: 0,
            coverageAmount: [],
            personBuid: "",
            planStatus: []
        }
    }

    let coverages = criticalIllnessProduct[0].coverages || [];
    
    if (coverages.length) {
        employeeBuyUpEligibility = coverages[0]?.memberOfferedCoverage?.option?.subscriptionMode === "cCSM_AddUp" || false;
        spouseBuyUpEligibility = coverages[1]?.memberOfferedCoverage?.option?.subscriptionMode === "cCSM_AddUp" || false;
    }

    dispatch(SET_PLAN_BUID({
        planBuid: productBuid
    }))
    
    await dispatch(SET_ELIGIBILITY({
        employee: employeeBuyUpEligibility,
        spouse: spouseBuyUpEligibility
    }))

    let CIPlan = coverageData && coverageData.length && coverageData.filter((coverage) => {
        return coverage?.subscribedProduct?.productCode?.buid === productBuid
    });
    let isSpouseCovered = false,
        annualPlanCost = [],
        coveragePlanDates = {
            employee: [],
            spouse: []
        },
        baseCoveragePlanDates = {
            employee: [],
            spouse: []
        },
        buyUpCoveragePlanDates = {
            employee: [],
            spouse: []
        },
        isPlanActive = {
            employee: [],
            spouse: []
        },
        basePlanBoughtThisYearFlag = {
            employee: true,
            spouse: true
        },
        buyUpBoughtCurrentYearFlag = {
            employee: false,
            spouse: false
        },
        payPerPeriodPlanCost = [],
        baseCoveragePlanIndex = 0;
    
    if (CIPlan && CIPlan.length) {
        productIdentifier = CIPlan[0].bUID;
        CIPlan.map((plan) => {
            if(plan.coverages && plan.coverages.length) {
                plan.coverages.map((coverage) => {
                    if(!productDeclinedLocally.employee) {
                        if(coverage?.subscribedOption?.optionCode === optionCodes.employee.buyup && (waivedPlanStatusArr.indexOf(coverage?.subscribedOption?.status) ===  -1) ) {
                            isPlanActive.employee.push(coverage?.subscribedOption?.status) 
                            coveragePlanDates.employee.push(coverage?.subscribedOption?.effectiveDate)
                            
                            if(coverage?.subscribedOption?.status === "cActive") {
                                optionCodes.employee.coverageDetails.push(coverage?.bUID)
                                optionCodes.employee.personBuid = coverage?.insuredPersons?.[0]?.person?.identifier?.bUID;
                            } 
                            
                            if(coverage?.subscribedOption?.status === "cActive" || coverage?.subscribedOption?.status === "cProject") {
                                optionCodes.employee.allCoverageDetails.push(coverage?.bUID)
                                optionCodes.employee.personBuid = coverage?.insuredPersons?.[0]?.person?.identifier?.bUID;
                                optionCodes.employee.planStatus.push({
                                    "coverageBuid" : coverage?.bUID,
                                    "status" : coverage?.subscribedOption?.status
                                })
                            }
                            optionCodes.employee.coverage = optionCodes.employee.coverage + coverage?.coverageAmount?.amount?.amount
                            optionCodes.employee.coverageAmount.push({
                                amount: coverage?.coverageAmount?.amount?.amount,
                                buid: coverage?.coverageAmount?.buid
                            })
                        } else if(coverage?.subscribedOption?.optionCode === optionCodes.employee.buyup){
                            optionCodes.employee.planStatus.push({
                                "coverageBuid" : coverage?.bUID,
                                "status" : coverage?.subscribedOption?.status
                            })
                        }
                    }
                    if(!productDeclinedLocally.spouse) {
                        if(coverage?.subscribedOption?.optionCode === optionCodes.spouse.buyup && (waivedPlanStatusArr.indexOf(coverage?.subscribedOption?.status) ===  -1) ) {
                            isSpouseCovered = true
                            isPlanActive.spouse.push(coverage?.subscribedOption?.status)
                            coveragePlanDates.spouse.push(coverage?.subscribedOption?.effectiveDate)
                            if(coverage?.subscribedOption?.status === "cActive") {
                                optionCodes.spouse.coverageDetails.push(coverage?.bUID)
                                optionCodes.spouse.personBuid = coverage?.insuredPersons?.[0]?.person?.identifier?.bUID;
                            } 
                            
                            if(coverage?.subscribedOption?.status === "cActive" || coverage?.subscribedOption?.status === "cProject") {
                                optionCodes.spouse.allCoverageDetails.push(coverage?.bUID)
                                optionCodes.spouse.personBuid = coverage?.insuredPersons?.[0]?.person?.identifier?.bUID;
                                optionCodes.spouse.planStatus.push({
                                    "coverageBuid" : coverage?.bUID,
                                    "status" : coverage?.subscribedOption?.status
                                })
                            }
                            optionCodes.spouse.coverage = optionCodes.spouse.coverage + coverage?.coverageAmount?.amount?.amount

                            optionCodes.spouse.coverageAmount.push({
                                amount: coverage?.coverageAmount?.amount?.amount,
                                buid: coverage?.coverageAmount?.buid
                            }) 
                        } else if(coverage?.subscribedOption?.optionCode === optionCodes.spouse.buyup){
                            optionCodes.spouse.planStatus.push({
                                "coverageBuid" : coverage?.bUID,
                                "status" : coverage?.subscribedOption?.status
                            })
                        }
                    }
                })
            }
        })
    }

    if(optionCodes.employee.planStatus.length) {
        dispatch(SET_PLAN_STATUS_EMPLOYEE({
            data: optionCodes.employee.planStatus
        }))
    }

    if(optionCodes.spouse.planStatus.length) {
        dispatch(SET_PLAN_STATUS_SPOUSE({
            data: optionCodes.spouse.planStatus
        }))
    }
    
    if(coveragePlanDates.employee.length) {
        coveragePlanDates.employee.map((date, index) => {
            if(index === baseCoveragePlanIndex) {
                baseCoveragePlanDates.employee.push(date)
            } else {
                buyUpCoveragePlanDates.employee.push(date)
            }
        })
        
        if (new Date(buyUpCoveragePlanDates.employee[buyUpCoveragePlanDates.employee.length - 1]).getFullYear() === new Date().getFullYear()) {
            //buyup was bought last year or previous years
            buyUpBoughtCurrentYearFlag.employee = true
        } else {
            //buyup was bought this year
            buyUpBoughtCurrentYearFlag.employee = false
        }
        if(new Date(baseCoveragePlanDates.employee[baseCoveragePlanIndex]) <= new Date() && isPlanActive.employee[baseCoveragePlanIndex] === "cActive") {
            // buyup was bought this year but still eligible since plan is active
            basePlanBoughtThisYearFlag.employee = false
        }
    }

    if(coveragePlanDates.spouse.length) {
        coveragePlanDates.spouse.map((date, index) => {
            if(index === baseCoveragePlanIndex) {
                baseCoveragePlanDates.spouse.push(date)
            } else {
                buyUpCoveragePlanDates.spouse.push(date)
            }
        })

        if (new Date(buyUpCoveragePlanDates.spouse[buyUpCoveragePlanDates.spouse.length - 1]).getFullYear() === new Date().getFullYear()) {
            //buyup was bought last year or previous years
            buyUpBoughtCurrentYearFlag.spouse = true
        } else {
            //buyup was bought this year
            buyUpBoughtCurrentYearFlag.spouse = false
        }
        
        if(new Date(baseCoveragePlanDates.spouse[baseCoveragePlanIndex]) <= new Date() && isPlanActive.spouse[baseCoveragePlanIndex] === "cActive") {
            // buyup was bought this year but still eligible since plan is active
            basePlanBoughtThisYearFlag.spouse = false
        }

    }

    dispatch(SET_BASE_PLAN_BOUGHT_IN_CURRENT_YEAR_FLAG(basePlanBoughtThisYearFlag))
    dispatch(SET_BUYUP_BOUGHT_IN_CURRENT_YEAR_FLAG(buyUpBoughtCurrentYearFlag))
    
    let existingCoverageBuidObj;
    let allExistingCoverageBuidObj;
    if (isSpouseCovered) {
        existingCoverageBuidObj = {
            employee: optionCodes.employee.coverageDetails.length ? optionCodes.employee.coverageDetails : [],
            spouse: optionCodes.spouse.coverageDetails.length && isSpouseCovered ? optionCodes.spouse.coverageDetails : []
        }

        allExistingCoverageBuidObj = {
            employee: optionCodes.employee.allCoverageDetails.length ? optionCodes.employee.allCoverageDetails : [],
            spouse: optionCodes.spouse.allCoverageDetails.length && isSpouseCovered ? optionCodes.spouse.allCoverageDetails : []
        }
    } else {
        existingCoverageBuidObj = {
            employee: optionCodes.employee.coverageDetails.length ? optionCodes.employee.coverageDetails : [],
        }
        allExistingCoverageBuidObj = {
            employee: optionCodes.employee.allCoverageDetails.length ? optionCodes.employee.allCoverageDetails : [],
        }
    }
    
    if(!optionCodes?.employee?.coverageDetails?.length) {
        dispatch(SET_ELIGIBILITY({
            ...buyUpEligibility,
            employee: false
        }))
    }
    
    if(!optionCodes?.spouse?.coverageDetails?.length) {
        dispatch(SET_ELIGIBILITY({
            ...buyUpEligibility,
            spouse: false
        }))
    }

    dispatch(SET_EXISTING_COVERAGE_BUID({ ...existingCoverageBuidObj }));
    dispatch(SET_SELECTED_BASE_PLAN_DETAILS({
        employee: optionCodes?.employee?.coverageAmount,
        spouse: optionCodes?.spouse?.coverageAmount
    }))
    if (contractRatingInfo.AnualCost?.data?.subscribedElements && contractRatingInfo.AnualCost?.data?.subscribedElements.length) {
        contractRatingInfo.AnualCost?.data?.subscribedElements.map((subscribedElement) => {
            if (subscribedElement.coverages && subscribedElement.coverages.length) {
                subscribedElement.coverages?.map((coverage) => {
                    if(allExistingCoverageBuidObj.employee?.indexOf(coverage.identifier?.bUID) >=0) {
                        annualPlanCost.push({
                            coverageBuid: coverage.identifier?.bUID,
                            cost: coverage.rate?.amount?.amount,
                            member: optionCodes.employee.identifier,
                            personBuid: optionCodes.employee.personBuid
                        })
                    }

                    if(allExistingCoverageBuidObj.spouse?.indexOf(coverage.identifier?.bUID) >=0) {
                        annualPlanCost.push({
                            coverageBuid: coverage.identifier?.bUID,
                            cost: coverage.rate?.amount?.amount,
                            member: optionCodes.spouse.identifier,
                            personBuid: optionCodes.spouse.personBuid
                        })
                    }
                })
            }
        })
    }
    if (annualPlanCost.length) {
        dispatch(SET_COST_ANNUAL({
            data: annualPlanCost
        }))
        annualPlanCost.map((plan, index) => {
            if (plan.member === optionCodes.employee.identifier) {
                optionCodes.employee.annualCost = optionCodes.employee.annualCost + plan.cost
            }

            if (isSpouseCovered && plan.member === optionCodes.spouse.identifier) {
                optionCodes.spouse.annualCost = optionCodes.spouse.annualCost + plan.cost
            }

        })
    }

    if (contractRatingInfo.coverageCost?.data?.subscribedElements && contractRatingInfo.coverageCost?.data?.subscribedElements.length) {
        contractRatingInfo.coverageCost?.data?.subscribedElements.map((subscribedElement) => {
            if (subscribedElement.coverages && subscribedElement.coverages.length) {
                subscribedElement.coverages?.map((coverage) => {
                    if(allExistingCoverageBuidObj.employee?.indexOf(coverage.identifier?.bUID) >=0) {
                        payPerPeriodPlanCost.push({
                            coverageBuid: coverage.identifier?.bUID,
                            cost: coverage.rate?.amount?.amount,
                            member: optionCodes.employee.identifier,
                            personBuid: optionCodes.employee.personBuid
                        })
                    }

                    if(allExistingCoverageBuidObj.spouse?.indexOf(coverage.identifier?.bUID) >=0) {
                        payPerPeriodPlanCost.push({
                            coverageBuid: coverage.identifier?.bUID,
                            cost: coverage.rate?.amount?.amount,
                            member: optionCodes.spouse.identifier,
                            personBuid: optionCodes.spouse.personBuid
                        })
                    }
                })
            }
        })
    }
    
    if (payPerPeriodPlanCost.length) {
        dispatch(SET_COST_PAY_PER_PERIOD({
            data: payPerPeriodPlanCost
        }))
        payPerPeriodPlanCost.map((plan, index) => {
            if (plan.member === optionCodes.employee.identifier) {
                optionCodes.employee.payPerPeriodCost = optionCodes.employee.payPerPeriodCost + plan.cost
                
            }
            if (isSpouseCovered && plan.member === optionCodes.spouse.identifier) {
                optionCodes.spouse.payPerPeriodCost = optionCodes.spouse.payPerPeriodCost + plan.cost
            }
        })

        dispatch(SET_COST_BASKET_DATA({
            data: {
                employee: optionCodes.employee.payPerPeriodCost,
                spouse: optionCodes.spouse.payPerPeriodCost
            }
        }))
    }
    return optionCodes;
}