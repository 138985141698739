import { React, useEffect, useState } from 'react';
import { Banner } from '../../Atoms';
import classes from './styles.module.css';
import iconEye from '../../../Assets/Images/icon-eye.png';
import { formatDollarAmountWithDecimals } from '../../Utils/Helper/ValidationHelper';
const EmployeeTable = (props) => {
    const {
        empData,
        empContactData,
        empContactDataInfo,
        empEmploymentData,
        constantsList,
        ssnValue
    } = props;
    const [showFullSsn, setShowFullSsn] = useState(false);
    const getGender = (gender) => {
        let value;
        let obj = constantsList['GenderConstants']?.find((o) => o.value === gender);
        if (obj) value = obj['text'];

        return value;
    };
    let showSSN;
    const formatMaskSSN = (ssn) => {
        const lastFourDigits = ssn?.slice(-4);
        const formattedSSN = '***-**_' + lastFourDigits;

        showSSN = formattedSSN;
        return formattedSSN.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    };
    const imageToggler = () => {
        if (showSSN?.startsWith('**')) {
            setShowFullSsn(!showFullSsn);
        } else {
            setShowFullSsn(!showFullSsn);
            formatMaskSSN();
        }
    };
    //format date
    const formatDateNow = (dateString) => {
        var hypenIndex = dateString?.indexOf('-');
        const year = dateString?.substring(0, hypenIndex);
        dateString = dateString?.substring(++hypenIndex);
        hypenIndex = dateString?.indexOf('-');
        const month = dateString?.substring(0, hypenIndex);
        const day = dateString?.substring(++hypenIndex);
        if (month && day && year) {
            dateString = month + '-' + day + '-' + year;
        }
        let dateNew = new Date(dateString);

        const date = new Date(dateNew);

        return dateString;
    };
    //Code to get employment category status
    const getEmploymentCategoryStatus = (empCategoryStatus) => {
        let empCategoryCode = empCategoryStatus?.additionalDynamicDatas?.filter(
            (data) => data.value.fieldCode == 'EMPSTATUSCAT'
        );
        if (empCategoryCode == undefined) {
            return '';
        } else {
            return empCategoryCode[0]?.value?.fieldValue;
        }
    };
    //Code to get employment status value
    const getEmploymentStatus = (empStatus) => {
        if (!empStatus) return "";
        const additionalDynamicDatas = empStatus?.additionalDynamicDatas || [];
        let empStatusValue = additionalDynamicDatas.filter(
            (data) => data.value.fieldCode == "EMPD_00092"
        );
        return empStatusValue?.[0]?.value?.fieldValue || ""
    };
    //code to get formatted salary
    const getFormattedSalary = (amount) => {
        let formattedSalary = amount?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return formattedSalary;
    };
    //Code to get work location
    const getPayFrequency = (frequency) => {
        let value;
        let obj = constantsList['FrequencyConstants']?.find((o) => o.value === frequency);
        if (obj) value = obj['text'];

        return value;
    };
    //code to get division code
    const getDivisionCode = (divcode) => {
        let divCode = divcode?.additionalDynamicDatas?.filter(
            (data) => data?.value?.fieldCode == 'EMPD_00016'
        );

        let classValue = divCode && divCode[0]?.value?.className;

        if (divCode == undefined) {
            return '';
        } else {
            if (classValue == 'aSPCB_ClassificationValue') {
                return divCode[0]?.value?.fieldValue;
            } else if (classValue == 'aSPCB_AdditionalDataValue_CString') {
                return divCode[0]?.value?.fieldValue;
            }
        }
    };

    //Code to get work location
    const getWorkLocation = (location) => {
        let locCode = location?.additionalDynamicDatas?.filter(
            (data) => data?.value?.fieldCode == 'EMPD_00058'
        );

        let classValue = locCode && locCode[0]?.value?.className;
        if (locCode == undefined) {
            return '';
        } else {
            if (classValue == 'aSPCB_ClassificationValue') {
                return locCode[0]?.value?.fieldValue;
            } else if (classValue == 'aSPCB_AdditionalDataValue_CString') {
                return locCode[0]?.value?.fieldValue;
            }
        }
    };
    //get Pay period
    const getPayPeriod = (empCategoryStatus) => {
        let payPeriodContent = empCategoryStatus?.additionalDynamicDatas?.filter(
            (data) => data.value.fieldCode == 'EMPD_00002'
        );
        if (payPeriodContent == undefined) {
            return '';
        } else {
            return payPeriodContent[0]?.value?.fieldValue;
        }
    };
    //Code to get Gender value
    return (
        <div>
            <div className="summaryTitle">
                <h4>Enrollment Summary</h4>
            </div>
            <Banner>
                <div className={classes.card}>
                    <div >
                        <div>
                            <div className="">
                                <div className="">
                                    <div>
                                        <div className="d-block d-lg-none d-md-none">
                                            <div className={classes.title}>Employee profile</div>
                                            <div className={classes.title}>General Information</div>
                                            {/* <h6>Personal xxx Information</h6> */}
                                            <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ml-2">First Name</div>
                                                        <div className={`col-12 ml-2 ${classes.fontStyle}`}>
                                                            <b>{empData?.shortName}</b>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ml-2 ml-0 pl-0">Middle Name</div>
                                                        <div className={`col-12 ml-2 ${classes.fontStyle}`}>
                                                            <b></b>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ml-2">Last Name</div>
                                                        <div className={`col-12 ml-2 ${classes.fontStyle}`}>
                                                            <b>{empData?.name}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ml-2">Gender</div>
                                                        <div className={`col-12 ml-2 mr-0 pr-0 ${classes.fontStyle}`}>
                                                            <b> {getGender(empData?.gender)}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ml-2">SSN</div>
                                                        <div className={`col-12 ml-2 mr-0 pr-0 ${classes.fontStyle}`}>
                                                            <b>
                                                                {empData?.sSN &&
                                                                    (!showFullSsn ? formatMaskSSN(empData?.sSN) : ssnValue)}
                                                            </b>
                                                            {empData?.sSN && (
                                                                <img
                                                                    src={iconEye}
                                                                    onClick={imageToggler}
                                                                    alt="eyeicon"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ml-2">Date Of Birth</div>
                                                        <div className={`col-12 ml-2 mr-0 pr-0 ${classes.fontStyle}`}>
                                                            <b>{formatDateNow(empData?.birthDate)}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr></hr>

                                            <div className={classes.title}>Contact Information</div>

                                            <div className="row empContentMobile">
                                                <div className="col-12 ">Address</div>
                                                <div className={`col-12 ${classes.fontStyle}`}>
                                                    {' '}
                                                    {empContactData?.line1} {empContactData?.line2}
                                                    {empContactData?.zipCodeAndCity?.city}{' '}
                                                    {empContactData?.zipCodeAndCity?.zipCode}
                                                </div>
                                            </div>
                                            <div className="row empContentMobile">
                                                <div className="col-6">Work Phone</div>
                                                <div className="col-6">Alternate Phone</div>
                                                <div className={`col-6 ${classes.fontStyle}`}>
                                                    {' '}
                                                    {empContactDataInfo?.officePhone}{' '}
                                                </div>
                                                <div className={`col-6 ${classes.fontStyle}`}>
                                                    {' '}
                                                    {empContactDataInfo?.privatePhone}{' '}
                                                </div>
                                            </div>
                                            <div className="row empContentMobile">
                                                <div className="col-6">Work Email</div>
                                                <div className="col-6">Alternate Email</div>
                                            </div>

                                            <div className="row empContentMobile">
                                                <div className={`col-6 ${classes.fontStyle}`}>
                                                    {' '}
                                                    {empContactDataInfo?.optionalEmail}{' '}
                                                </div>
                                                <div className={`col-6 ${classes.fontStyle}`}>
                                                    {' '}
                                                    {empContactDataInfo?.eMail}{' '}
                                                </div>
                                            </div>

                                            <div className={classes.title}>Employment Information</div>

                                            <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ml-2">Employment Category Status </div>
                                                        <div className={`col-12 ${classes.fontStyle}`}>
                                                            <div className={`col-3 ml-2${classes.fontStyle}`}>
                                                                {' '}
                                                                {getEmploymentCategoryStatus(empEmploymentData)}{' '}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ml-0 pl-0">Employment Status</div>
                                                        <div className={`col-12 ${classes.fontStyle}`}>
                                                            {getEmploymentStatus(empEmploymentData)}{' '}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ">Salary</div>
                                                        <div className={`col-12 ${classes.fontStyle}`}>
                                                            {formatDollarAmountWithDecimals(empEmploymentData?.baseSalaryAmount?.amount)}{' '}
                                                            {getPayFrequency(empEmploymentData?.baseSalaryFrequency)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ml-2">Pay Period</div>
                                                        <div className={`col-12 ml-2 mr-0 pr-0 ${classes.fontStyle}`}>
                                                            {getPayPeriod(empEmploymentData)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12">Hire Date</div>
                                                        <div className={`col-12 mr-0 pr-0 ${classes.fontStyle}`}>
                                                            {formatDateNow(empEmploymentData?.hireDate)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12">Division Code</div>
                                                        <div className={`col-12 mr-0 pr-0 ${classes.fontStyle}`}>
                                                            {getDivisionCode(empEmploymentData)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`row classes.rowFont py-3 py-lg-0 py-md-0`}>
                                                <div className="col-4">
                                                    <div class="row">
                                                        <div className="col-12 ml-2">Work Location</div>
                                                        <div className={`col-12 ml-2 mr-0 pr-0 ${classes.fontStyle}`}>
                                                            {getWorkLocation(empEmploymentData)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-none dl-lg-block d-md-block px-2 py-2">
                                            <div key="" className={classes.expanded}>
                                                <div className={classes.title}>Employee Profile</div>
                                                <div className={classes.title}>General Information</div>
                                                <div className="row empContent">
                                                    <div className="col-2 ">First Name</div>
                                                    <div className="col-2 ml-0 pl-0">Middle Name</div>
                                                    <div className="col-2 ml-0 pl-0">Last Name</div>
                                                    <div className="col-2 ml-0 pl-0">Gender</div>
                                                    <div className="col-2 ml-0 pl-0">SSN</div>
                                                    <div className="col-2 ml-0 pl-0">Date of Birth</div>
                                                </div>
                                                <div className="row empContent mb-2">
                                                    <div className={`col-2  ${classes.fontStyle}`}>
                                                        {' '}
                                                        {empData?.shortName}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {empData?.middleName1}{' '}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {empData?.name}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {getGender(empData?.gender)}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {empData?.sSN &&
                                                            (!showFullSsn ? formatMaskSSN(empData?.sSN) : ssnValue)}
                                                        {empData?.sSN && (
                                                            <img
                                                                src={iconEye}
                                                                onClick={imageToggler}
                                                                alt="eyeIcon"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className={`col-1 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {formatDateNow(empData?.birthDate)}
                                                    </div>
                                                </div>

                                                <div className={classes.title}>Contact Information</div>

                                                <div className="row empContent">
                                                    <div className="col-3">Address</div>
                                                    <div className="col-2 ml-0 pl-0">Work Phone</div>
                                                    <div className="col-2 ml-0 pl-0">Alternate Phone</div>
                                                    <div className="col-3 ml-0 pl-0">Work Email</div>
                                                    <div className="col-3 ml-0 pl-0">Alternate Email</div>
                                                </div>
                                                <div className="row empContent mb-2">
                                                    <div className={`col-3 ${classes.fontStyle}`}>
                                                        {' '}
                                                        <div>
                                                            {empContactData?.line1} {empContactData?.line2}
                                                        </div>
                                                        <div>
                                                            {empContactData?.zipCodeAndCity?.city}{' '}
                                                            {empContactData?.state}{' '}
                                                            {empContactData?.zipCodeAndCity?.zipCode}
                                                        </div>
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {empContactDataInfo?.officePhone}{' '}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {empContactDataInfo?.privatePhone}{' '}
                                                    </div>
                                                    <div className={`col-3 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {empContactDataInfo?.optionalEmail}{' '}
                                                    </div>
                                                    <div className={`col-3 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {empContactDataInfo?.eMail}{' '}
                                                    </div>
                                                </div>

                                                <div className={classes.title}>Employment Information</div>
                                                <div className="row empContent">
                                                    <div className="col-3">Employee Category Status</div>
                                                    <div className="col-2 ml-0 pl-0">Employment Status</div>
                                                    <div className="col-2 ml-0 pl-0">Salary</div>
                                                    <div className="col-2 ml-0 pl-0">Pay Period</div>
                                                    <div className="col-2 ml-0 pl-0">Hire Date</div>
                                                    <div className="col-2 ml-0 pl-0">Division Code</div>
                                                    <div className="col-2 ml-0 pl-0">Work Location</div>
                                                </div>
                                                <div className="row empContent mb-2">
                                                    <div className={`col-3 ${classes.fontStyle}`}>
                                                        {getEmploymentCategoryStatus(empEmploymentData)}{' '}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {getEmploymentStatus(empEmploymentData)}{' '}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {formatDollarAmountWithDecimals(
                                                            empEmploymentData?.baseSalaryAmount?.amount
                                                        )}{' '}
                                                        {getPayFrequency(empEmploymentData?.baseSalaryFrequency)}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {getPayPeriod(empEmploymentData)}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {formatDateNow(empEmploymentData?.hireDate)}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {getDivisionCode(empEmploymentData)}
                                                    </div>
                                                    <div className={`col-2 ml-0 pl-0 ${classes.fontStyle}`}>
                                                        {' '}
                                                        {getWorkLocation(empEmploymentData)}
                                                    </div>
                                                </div>

                                                <hr></hr>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Banner>
        </div>
    )
}

export default EmployeeTable;