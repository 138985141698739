import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'controlSSN',
  initialState: {
    clearSSN: null,
    SSNValue: ''
  },
  reducers: {
    // action => action handler
    CLEAR_SSN: (controlSSN, action) => {
      controlSSN.clearSSN = action.payload;
    },
    SET_SSN_VALUE: (controlSSN, action) => {
      controlSSN.SSNValue = action.payload;
    }
  },
});

export const {
  CLEAR_SSN,SET_SSN_VALUE 
} = slice.actions;

export default slice.reducer;
