class PrimaryIdetifierHelper {


  getPersonalDetails(employeeIdFromRedux, dataFromReduxPersonalInformation, onecode) {

    const convertPhoneNumberFormat = (phoneNumber) => {
      let clean = phoneNumber.replace(/\D/g, '');
      return clean.slice(1, 4) + '-' + clean.slice(4, 7) + '-' + clean.slice(7);
    }

    if(employeeIdFromRedux && dataFromReduxPersonalInformation && onecode) {
      const employeeId = employeeIdFromRedux.slice(-4);
      const personalDetails = {
        onecode: onecode,
        employeeId: employeeId,
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        state: '',
        zipCode: '',
        personalPhone: ''
      };
    
      for (let key in personalDetails) {
        if (dataFromReduxPersonalInformation[key]) {
          personalDetails[key] = dataFromReduxPersonalInformation[key];
          if (key === 'personalPhone') personalDetails[key] = convertPhoneNumberFormat(dataFromReduxPersonalInformation[key]) ?? '';
        }
      }
    
      return personalDetails;
    }
    return {};
    
  }

}
export default PrimaryIdetifierHelper;
