import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_LANGUAGE } from '../../../Store/Slices/Language';
import { useHistory } from 'react-router-dom';
import Image from '../Image/';
import Icon from '../Icon/';
import Collapse from '../Collapse';
import './style.css';
import $ from 'jquery';
import { isLength } from 'lodash';

const IconDropdown = (props) => {
  const {
    dropDownType,
    filename,
    alt,
    className,
    classNameWrapper,
    iconPrefix,
    iconName,
    dropdownClassName,
    options = [],
    optionType,
    paddingLeft,
  } = props;
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const dispatch = useDispatch();
  const history = useHistory();

  const { availableLanguages } = useSelector((storeState) => storeState.language);
  const helpDeskData = useSelector(
    (storeState) => storeState?.employeeProfile?.helpdesk_data
  );
  const { userAuth } = useSelector((storeState) => storeState.auth);

  let isCallCenterUser =userAuth.callCenterAdmin;
  let isSSOUser =userAuth.processType === "eelect";
  let isConfigUser =(userAuth.userType === "Configurator" || userAuth.processType === "Configurator") && userAuth.configuratorSSOLogin

  const handleLanguageChange = (event) => {
    dispatch(CHANGE_LANGUAGE(event.target.value));
  };

  let helpDeskContent = [];

  const parser = new DOMParser();
  const helpDeskDataHTML = parser.parseFromString(helpDeskData, "text/html");
  const allDivs = helpDeskDataHTML.querySelectorAll('div');
  const divArray = Array.from(allDivs);
  const titleStyle = "font-weight:bold";
  for (let i = 0; i < divArray.length; i++) {
    const divContent = divArray[i];
    const name = "name" + i;
    let title = "";
    const titleSpan = divContent.querySelector('span[style="' + titleStyle + '"]');
    if (titleSpan) { title = titleSpan.textContent }
    const content = divContent.textContent.replace(title, '').trim();
    helpDeskContent[i] = {
      "name": name,
      "title": title,
      "content": content
    }
  }
  return (
    <div ref={ref}>
      <div
        className="iconLink"
        onClick={() => setIsMenuOpen((oldState) => !oldState)}
      >
        {dropDownType === 'image' ? (
          <Image
            filename={filename}
            alt={alt}
            className={className}
            classNameWrapper={classNameWrapper}
          />
        ) : (
          <Icon
            className={className}
            icon={[iconPrefix, iconName]}
            size={iconName === "ellipsis-v" ? '1x' : '2x'}
          />
        )}
      </div>
      {isMenuOpen && (
        <div className={`${dropdownClassName} show container `} style={{ width: "19.5rem" }} >
          {options.map((option, index) => {
            return (
              <Collapse
                // Hide Security Settings for SSO users 
                className={
                  (
                    (option.hideForCallCenter && (isCallCenterUser ||isConfigUser)) || 
                    (option.hideForEelect && isSSOUser)
                  )? 
                    'hideElement ' 
                    :`${option.className}` 
                }
                key={`collapse-menu-${index}`}
                title={option.text}
                onClick={option.click}
                subOptions={option.subOptions || []}
                link={option.to || ''}
                fileName={option.fileName}
                filename={option.filename}
                upArrow={option.upArrow}
                downArrow={option.downArrow}
                content={option.text == 'Contact Us' ? (helpDeskContent.length > 0 ? helpDeskContent : []) : option.content || []}
                disabledForCallCenterUser={option.disableForCallCenter && (isCallCenterUser || isConfigUser)}
                disableForSSO={option.disableForSSO && isSSOUser}
              />
            );
          })}
        </div>
      )}
    </div>
  );

};

IconDropdown.propTypes = {
  dropDownType: PropTypes.string,
  filename: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
  iconPrefix: PropTypes.string,
  iconName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, text: PropTypes.string, to: PropTypes.string })
  ),
  optionType: PropTypes.string,
};

IconDropdown.defaultProps = {
  dropDownType: '',
  filename: '',
  alt: 'An image',
  className: '',
  classNameWrapper: '',
  iconPrefix: '',
  iconName: '',
  dropdownClassName: '',
  options: [],
  optionType: '',
};

export default IconDropdown;
