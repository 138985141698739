import React, { useState, useEffect } from 'react';




import Elementor from '../../../../Cells/Form/Elementor';

import Checkbox from '../../../../Atoms/Checkbox'
import { useDispatch, useSelector } from 'react-redux';
import { CHECK_ELIGIBILITY, UPDATE_COVERAGE_DETAILS, UPDATE_LOADING, UPDATE_WAIVE_PRODUCT }
  from '../../../../../Store/Slices/InsuredInfo';
import InsuredQuestions from './InsuredQuestions';
import Content from '../../Content/GenericContent.json'
import { Button } from '../../Atoms/index';
import ServiceHelper from '../../../../Utils/Helper/MappingHelper';
import Loader from '../../../../Atoms/Loader'
import { onLoadAPI, onNextAPI } from '../../../../../Store/Slices/Entities/response';
import { CheckCoveredPersonsEligibilityForCoverage } from '../../Actions/CheckCoveredPersonsEligibilityForCoverage';
import {prepareSubscriptionForChoice} from '../../Actions/prepareSubscriptionForChoice.js'
import { determineButtonLabel } from '../../Helper/MappingHelper';
import { createOrUpdateInsuredQuestionAnswer } from '../../Actions/createOrUpdateInsuredQuestionAnswer';
import { unsubscribeCoverage } from '../../Actions/unsubscribeCoverage';
import { continueBtnLabelHelper, screenNames } from '../../Helper/continueBtnLabelHelper';
import { useNavigationHook } from '../../Hooks/NavigationHook';
const ChildInsuredQuestionsContent = (props) => { 
  const serviceHelper = new ServiceHelper();
  const { childIndex, children, flowType } = props;
  /// abc check the values are getting.
  const { currentStep , formsConfig } = useNavigationHook();
  const buid = props.children[childIndex]?.personBuid;
  const [coverageError, setCoverageError] = useState([]);

  const dispatch = useDispatch();
  const [checkEnableBtn, setCheckEnableBtn] = useState(true);
  const [checkNextStep, setCheckNextStep] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [disableAccordian, setDisableAccordian] = useState(false);
  
  const [currentBuids, setCurrentBuids] = useState([]);
  const [isCoverageApiError, setIsCoverageApiError] = useState(false);
  const [nextStepFlow, setNextStepFlow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [currentBuidsPerson, setCurrentBuidsPerson] = useState([]);
  const [skipPerson, setSkipPerson] = useState([]);
  const [callApi, setCallApi] = useState("onLoad");  
  const [userAnsweredInsuredQuestionFlag, setUserAnsweredInsuredQuestionFlag] = useState(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  
  const [hasEligibilityQuestions, setEligibilityQuestionBoolean] = useState(true);
  const { step,  nextStep , prevStep, showDisclosureStatement,onNext } = props;
  const { insuredInfo, process } = useSelector((storeState) => storeState);
  let currentReduxState = useSelector((storeState) => storeState);

  let enrollmentProcessBuid = process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
  const getAllPossibleChoicesConfiguration = currentReduxState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]
  let productFamily = formsConfig.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
  let insuredQuestionAnswers = insuredInfo[currentStep]?.InsuredQuestionAnswers[buid];
  let waiveCoverage = insuredInfo[currentStep]?.WaiveCoverage;
  let isWaiveCoverage = false
  if (waiveCoverage) {
    let waiveCoverageDetails = waiveCoverage.filter(e => e.personBuid === buid);
    if (waiveCoverageDetails.length > 0) {
      isWaiveCoverage = waiveCoverageDetails?.[0]?.waiveProduct ? true : false
    }
  }
  let personBuid = buid;
  const { childIndx, setChildCoverageWaivedBoolean  } = props;
  // const memberEligible = insuredInfo[currentStep].coverageInsuredEligiblity[1].coverageEligiblity;
  // 
  // this is for show/hide waive coverage based on insured questions
  //prepareSubscriptionForChoice api call
//   useEffect(() => {
//     if (!enrollmentProcessBuid || !getAllPossibleChoicesConfiguration || !insuredInfo?.[currentStep]?.offeredCoverageBuids?.length) {
//         return;
//     }

//     let existingIndex = insuredInfo[currentStep]?.subscribeCoverageBuids?.findIndex(
//         (coverage) => coverage && coverage?.personBuid === personBuid
//     );

//     if ( insuredInfo[currentStep]?.subscribeCoverageBuids?.length && existingIndex !== -1) {
//         return;
//     }

//     let coverageDetails = insuredInfo?.[currentStep]?.offeredCoverageBuids[0];

//     if (props.flowType === 2 || props.flowType === 3) {
//         coverageDetails = insuredInfo?.[currentStep]?.offeredCoverageBuids.filter((e) => e?.coveragePersonBuid?.includes(personBuid))[0];
//     }

//     if (coverageDetails) {
//         dispatch(prepareSubscriptionForChoice(
//             enrollmentProcessBuid,
//             coverageDetails?.coverage,
//             coverageDetails?.coveragePersonBuid,
//             currentStep,
//             personBuid,
//         ));
//     }
// }, [currentStep, getAllPossibleChoicesConfiguration, insuredInfo, enrollmentProcessBuid, personBuid, props.flowType]);

  const showWaiveCoverage = insuredInfo[currentStep]?.showWaive;
  const [isChecked, setIsChecked] = useState(isWaiveCoverage);
  const [continueBtnLabel, setContinueBtnLabel] = useState("Continue");
  let coverageDetails = insuredInfo?.[currentStep]?.offeredCoverageBuids?.[0];
  if (props.flowType === 2 || props.flowType === 3) {
      coverageDetails = insuredInfo?.[currentStep]?.offeredCoverageBuids?.filter((e) => e?.coveragePersonBuid?.includes(personBuid))[0];
  }
  
  let memberEligible = true
  if(props.flowType === 3) {
    let childrenBuidArr = []
    props.children.map((child) => {
      childrenBuidArr.push(child.personBuid)
    })
    let filteredEligibilityData = insuredInfo[currentStep]?.coverageInsuredEligiblity?.filter((item) => {
      return childrenBuidArr.indexOf(item.personBuid) > -1 && item.coverageEligiblity === true
    })

    if(!filteredEligibilityData.length) {
        memberEligible = false
    } 
  }

  if(props.flowType === 2) {
    let filteredEligibilityData = insuredInfo[currentStep]?.coverageInsuredEligiblity?.filter((item) => {
      return item.personBuid === props.children[childIndex].personBuid && item.coverageEligiblity === true
    })

    if(!filteredEligibilityData.length) {
        memberEligible = false
    } 
  }


  // insuredInfo[currentStep]?.coverageInsuredEligiblity.map((person) => {

  // })
  const currentOfferedCoverageBuids = currentReduxState?.entities?.response?.onLoadResponseList?.[currentStep]?.getAllPossibleChoicesConfigurationForProduct?._Result || [];
  const validateContractInsure = currentReduxState?.entities.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.insureds || []
  const nonSharedAdditionalDataSet = insuredInfo[currentStep]?.nonSharedAdditionalDatas
  const nextStepCall = async () => {
    // nextStep();
    if (personBuid && insuredQuestionAnswers && isWaiveCoverage === false) {
      setShowLoader(true);

      let actionDispatched = await dispatch(createOrUpdateInsuredQuestionAnswer({
        personBuid,
        productIdentifierBuid,
        insuredQuestionAnswers,
        currentStep,
        currentOfferedCoverageBuids,
        enrollmentProcessBuid,
        validateContractInsure,
        nonSharedAdditionalDataSet
      }));
      

      if(actionDispatched) {
        checkCoveragePersonEligibility();
      }
      setShowLoader(false);
    } else if (personBuid && isWaiveCoverage === true) {
      // let getDetails = insuredInfo?.[currentStep]?.subscribeCoverageBuids?.filter((e) => e?.personBuid === personBuid)
      // let subscribedCoverageBuid = getDetails?.[0]?.coverageBuid;
      // let resp = await serviceHelper.sendApiRequest('/unsubscribeCoverage', {
      //   enrollmentProcessBuid, subscribedCoverageBuid
      // });
      // if (resp.data && resp.status === 200) {
      //   dispatch(onLoadAPI({
      //     UpdatesubscribeCoverage: resp.data,
      //     isAPICallInProgress: false,
      //   }))
      // }
      // setShowLoader(false);
      setShowLoader(true);
      await prepareSubscriptionForChoice()
    }


  }

  const [apiCallState, setApiCallState] = useState("");

  const callOnNext = async () => {
    onNext()
  };

  
  
  useEffect(()=>{
    if(currentBuids?.length > 0 && currentOfferedCoverageBuids.length > 0){
      if(totalQuestion === 0){
        setApiCallState('onLoad');
      }else {
        setApiCallState('done');
      }
    }
  },[currentBuids[0], currentOfferedCoverageBuids]);

  useEffect(() => {
    if (apiCallState === "onLoad") {
      checkCoveragePersonEligibility();
    }
  }, [apiCallState])

  const checkCoveragePersonEligibility = async () => {
    // onClick={(flowType == "2" || flowType == "3") ? nextStepCall : nextStepCallOne}
    setCheckNextStep(false);
    let productFamily = formsConfig.forms?.[currentStep]?.productFamily;
    let CIBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
    if (CIBuid) {
      setShowEdit(true);
      let validateContractSM = currentReduxState?.entities?.response?.onLoadResponseList?.[currentStep]?.ValidateSubscribedProductData?._Result?.subscriptionManager || [];
      const enrolmentId = currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
      dispatch(UPDATE_LOADING(true));
      try{
      const response = await CheckCoveredPersonsEligibilityForCoverage(validateContractSM, enrolmentId, CIBuid, currentStep, currentBuids,[], props.flowType, currentOfferedCoverageBuids);
      if (insuredInfo[currentStep]?.coverageInsuredEligiblity && insuredInfo[currentStep]?.coverageInsuredEligiblity?.length > 0) {
        let currentElibilityData = [...insuredInfo[currentStep]?.coverageInsuredEligiblity];
        response.forEach((pobj) => {
          currentElibilityData.forEach((pinnrObj, pind) => {
            if (pinnrObj.personBuid === pobj.personBuid || pinnrObj.personBuid === undefined) {
              currentElibilityData.splice(pind, 1);
            }            
          });
        });
        currentElibilityData = [...currentElibilityData, ...response];
        dispatch(CHECK_ELIGIBILITY({ response: currentElibilityData, product: currentStep }));
      } else {
        dispatch(CHECK_ELIGIBILITY({ response, product: currentStep }));
      }
      // dispatch(CHECK_ELIGIBILITY({ response, product: currentStep })); 
      let flagCheck = true;
      let lcoverageError = [];
      let skipSteps = [];
      response.forEach((pobj) => {
        if (pobj.coverageEligiblity === false) {
          flagCheck = false;
          let temp = {
            error: true,
            buid: pobj.personBuid,
            eligibilityComment: pobj.eligibilityComment
          }
          lcoverageError.push(temp);
          const errorData = currentBuidsPerson?.filter((pcurrentData)=>{
            return pobj.personBuid === pcurrentData.buid;
          }) || [];
          if(errorData.length > 0){
            errorData[0].coverageEligiblity = false;
            skipSteps.push(errorData[0]);            
          }
        }
      });
      setSkipPerson([...skipSteps]);
      setCoverageError([...lcoverageError]);
      // sucess case
      if (flagCheck && response.length > 0) {
        setCheckNextStep(false);
        setDisableAccordian(true);
        // (flowType == "2" || flowType == "3") ? nextStepCall() : nextStepCallOne()
      }
      // error case      
      else {
        setDisableAccordian(true);
        setCheckNextStep(true);
        // setIsCoverageApiError(true);
      }
      if(apiCallState == "onLoad"){
        setShowEdit(false);
        setCallApi("onDone");
        setApiCallState("done");
        if(flagCheck && response.length > 0){
          setDisableAccordian(false);
          setCheckNextStep(false);
        }
      }
      setCallApi("onDone");
    } catch(e){
    } finally {
      dispatch(UPDATE_LOADING(false));
    }
    }
  };

  const vdk = (event) => {
    const waiveProduct = event.target.checked;
    setIsCheckboxChecked(waiveProduct);
    setIsChecked(event.target.checked);
    setChildCoverageWaivedBoolean(event.target.checked, childIndex)
    dispatch(UPDATE_WAIVE_PRODUCT({ personBuid, waiveProduct, currentStep, relationship: 'CH' }));
  };

  useEffect(() => {
    if(isCheckboxChecked) {
      setCheckEnableBtn(false)
    } else {
      if(userAnsweredInsuredQuestionFlag) {
        setCheckEnableBtn(false)
      } else {
        setCheckEnableBtn(true)
      }
    }
  },[isCheckboxChecked, userAnsweredInsuredQuestionFlag])

  // useEffect(() => {
  //   if(!showDisclosureStatement && children.length === 1 ) {
  //     setContinueBtnLabel(determineButtonLabel(props, isChecked))
  //   }
  // }, [isChecked]);

  useEffect(() => {
    setContinueBtnLabel(continueBtnLabelHelper({
      ...continueBtnObj
    
    }));

  }, [memberEligible])

  const continueBtnObj = {
    flowType: props.flowType,
    screenType: screenNames.INSURED_QUESTIONNAIRE_SCREEN,
    hasDisclosureStatement: props.showDisclosureStatement,
    isWaivedOffFlag: false,
    spouseCount: props.spouse.length,
    childrenCount: props.children.length,
    memberIdentifier: "children",
    productIdentifierBuid,
    memberDetails: {
      count: children.length,
      index: childIndex,
      eligible: memberEligible,
      buid: personBuid
    }
  }

  useEffect(() => {
    continueBtnLabelHelper({
      ...continueBtnObj,
      isWaivedOffFlag: isWaiveCoverage
    })
  }, [])

  useEffect(() => {
    continueBtnLabelHelper({
      ...continueBtnObj,
      isWaivedOffFlag: isChecked
    })
  }, [isChecked])

  const continueFn = () => {
    if(totalQuestion == 0){    
      if(skipPerson.length === 0 || (flowType == 3 && currentBuids.length !== skipPerson.length)){
        nextStep();
      } else{
        if(flowType == 3){
          setCurrentBuids([]);
        } 
        setTimeout(function(){
          props.skipChild(skipPerson);
          nextStep();
        },500);        
      }
    }
    else if(totalQuestion > 0 && (callApi == "onLoad" || callApi == "onEdit")){
      nextStepCall();
    }
    else if(totalQuestion > 0 && callApi == "onDone"){ 
      if(skipPerson.length === 0){
        nextStep();
      } else{
        if(flowType == 3){
          setCurrentBuids([]);
        }        
        setTimeout(function(){
          props.skipChild(skipPerson);
          nextStep();
        },500);
      }
    }
    // if (nextStepFlow) {
    //   nextStepCall();
    //   nextStep();
    // } else if (checkNextStep && showEdit) {
    //   nextStepCall();
    // } else if (!checkNextStep && !showEdit) {
    //   nextStepCall();
    // } else if (callApi) {
    //   setCallApi(false);
    //   nextStepCall();
    // }
    // else {
    //   nextStep();
    // }
  };

  const editAnswersFn = () => {
    setCallApi("onEdit");
    setDisableAccordian(false);
    setIsCoverageApiError(false);
  };

  const setQuestionAnsweredFlag = ({pdata, ptotalQuestions}) => {
    if(ptotalQuestions) {
      if(pdata === 100) {
        return true
      } else {
        return false
      }
    }  else {
      return true
    }  
  }

  const [totalQuestion, setTotalQuestion] = useState(-1);
  const checkEnableBtnFn = (pdata, pbuildsArr, ptotalQuestions, pbuidsPersonArr) => {
    let ltotalQuestions =  ptotalQuestions;
    if (pbuildsArr.length > 0 && pbuildsArr[0] !== currentBuids[0] && (pbuildsArr.length !== currentBuids.length || flowType == 2)) {
      setCurrentBuids([...pbuildsArr]);
      setCurrentBuidsPerson([...pbuidsPersonArr]);
    }
    if (pdata === 100) {
      setNextStepFlow(false);
    }
    else if (isNaN(pdata) || ptotalQuestions === 0 || isNaN(ptotalQuestions)) {
      ltotalQuestions = 0;  
      setNextStepFlow(true);
    }
    else {
      setNextStepFlow(false);
    }
    setUserAnsweredInsuredQuestionFlag(setQuestionAnsweredFlag({pdata, ptotalQuestions}));
    setTotalQuestion(ltotalQuestions);
  };

  const prepareSubscriptionForChoice = async () => {
    
    let obj = {}; 
    let index;
    if(flowType === 3) {
      coverageDetails.map((coverageItem, indx) => {
        coverageItem.coveragePersonBuid?.map((buid) => {
          if(personBuid === buid) {
            index = indx
          }
        })
      })
      obj = {
        enrollmentProcessBuid: enrollmentProcessBuid,
        coverageBuid: coverageDetails[index].coverage,
        coveredPerson: coverageDetails[index].coveragePersonBuid,
      }
    } else {
      obj = {
        enrollmentProcessBuid: enrollmentProcessBuid,
        coverageBuid: coverageDetails?.coverage,
        coveredPerson: coverageDetails?.coveragePersonBuid,
      }
    }


    let resp = await serviceHelper.sendApiRequest('/prepareSubscriptionForChoice', obj);
    if (resp.data && resp.status === 200) {
      let actionDispatched = await dispatch(unsubscribeCoverage({
        enrollmentProcessBuid, 
        subscribedCoverageBuid: resp.data._Result?.[0]?.bUID
      }, "UpdatesubscribeCoverage"));
      setShowLoader(false);
      if (actionDispatched.data && actionDispatched.status === 200) {
        dispatch(onLoadAPI({
          UpdatesubscribeCoverage: actionDispatched.data,
          isAPICallInProgress: false,
        }))
        return true
      }else {
        return false
      }
    }else{
      return false
    }
  }

  const setHasEligibilityQuestions = (boolean) => {
    setEligibilityQuestionBoolean(boolean)
  }


  return (
    <div>
      { showLoader ? <Loader loading={showLoader} className="loaderUpdateCls"/>
        : (<>
      <div>
        <div className="row panelStyleNoBorder px-1">
          <div className="hideWhenNoChild col-md-12">
            <div className="row" style={{ fontSize: '1rem', color: 'rgb(0, 0, 0)', fontFamily: 'NotoSansSemiBold' }}>
              <div className="label col-12 px-0 undefined" style={{ display: 'inline-block' }}>
                <div className="row">
                  <div className="py-3 mx-xl-2 mx-md-2 mx-1 px-xl-4 px-lg-4 px-md-4 px-4">
                    Let’s check your eligibility.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row panelStyleNoBorder px-1">
          <div className="hideWhenNoChild col-md-12">
            <div className="row" style={{ fontSize: '0.875rem', color: 'rgb(0, 0, 0)', fontFamily: 'NotoSansRegular' }}>
              <div className="label col-12 px-0 undefined" style={{ display: 'inline-block' }}>
                <div className="row">
                  <div className="mx-xl-2 mx-md-2 mx-1 px-xl-4 px-lg-4 px-md-4 px-4 pb-4">
                    {/*Please answer any required eligibility questions that may be listed below. Click the arrow beside an insured name to expand or collapse the section.*/}
                    If applicable, please click the arrow beside any name below to answer all required eligibility questions. Then click ‘Continue’ to proceed.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InsuredQuestions {...props} checkAnswer={checkEnableBtnFn}
        disableAccordian={disableAccordian} personBuid={buid} coverageError={coverageError} setHasEligibilityQuestions={setHasEligibilityQuestions} >
      </InsuredQuestions>
      {showWaiveCoverage &&
        <div className="hideWhenNoChild col-12 pr-0 pl-0">
          <div className="" style={{ borderBottomRightRadius: '0.25rem', fontFamily: 'NotoSansRegular', fontSize: '0.875rem', textAlign: 'center', backgroundColor: 'rgb(244, 244, 244)', borderColor: 'rgb(218, 218, 218)', minHeight: '2.5rem', paddingTop: '0.5rem' }}>
            <div className="checkboxContainer">
              <Checkbox
                label={Content?.waiveCoverageButton?.label}
                element={Content.waiveCoverageButton}
                buid={buid}
                onChange={vdk}
                checked={isWaiveCoverage === true ? true : false}
                {...props.parentProps}
                className={``}
              ></Checkbox>


            </div>
          </div>
        </div>



      }
      </>)}
      <div className='button-group'>
        <>
          <Button type="button" onClick={prevStep} className="mr-3 btn btn-outline-primary primaryOutline">
            Back
          </Button>
        </>
        {
          showEdit && (
            <Button type="button" onClick={editAnswersFn} className="mr-3 btn btn-primary primaryBackground">
              Edit Answers
            </Button>
          )
        }
        {flowType === 3 ? 
          // Need to check what isCoverageApiError flag does. We might or might need it for flowType 3
          <Button type="button" onClick={continueFn} 
            className="mr-3 btn btn-primary continue-btn primaryBackground"
            disabled={checkEnableBtn}>
            {continueBtnLabel}
          </Button>:
          < Button type="button"  onClick={!memberEligible ? callOnNext : continueFn}
            className="mr-3 btn btn-primary continue-btn primaryBackground"
            disabled={checkEnableBtn}
          >
          {continueBtnLabel}
        </Button>}

      </div>

    </div>
  );
};

export default ChildInsuredQuestionsContent;
