import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callConstants } from '../../../../Store/Slices/Entities/constants/index';
import { constantsList } from '../../../../Common/Constants/index';
import FormGenerator from '../../../Cells/Form/FormGenerator';
const HomePage = (props) => {
  /* useEffect to get Home PAge layout content dependent on language */
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((storeState) => {
    return storeState.language;
  });
  const [content, SetContent] = useState({});
  const [divisionOptions] = useState({});

  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => SetContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);

  // useEffect(() => {
  //   /* useEffect to get all constants through API call */
  //   constantsList.forEach((element) => {
  //     dispatch(callConstants(element));
  //   });
  // }, []);

  // //Get the client Id
  // const clientId = useSelector(
  //   (storeState) => storeState?.loggedUserEntity?.data?._Result?.clientId?.internalClientId
  // );

  //Get the client Id
  const clientId = useSelector(
    (storeState) => storeState?.auth?.loggedUserEntity?.data?._Result?.clientId?.internalClientId
  );
  
  //Get the current theme form the store
  // const selectedTheme = useSelector((storeState) => storeState.theme.currentTheme['0'].selectedTheme);
  const selectedTheme = useSelector((storeState) => storeState.theme.configuredTheme._Result);

  

  return (
    <div>
    {/* <SectionHeader
      title="Search for an Employee"
      breadcrumbs={breadcrumbs}
      current="Search for an Employee"
      secionElements = {content.layout && content.layout.sectionHeader && content.layout.sectionHeader.elements || []}
    ></SectionHeader> */}
    <Suspense fallback={<div>Loading...</div>}>
      <div className="searchEmployeeWrapper">
        <FormGenerator content={content} divisionOptions={divisionOptions} {...props} />
      </div>
    </Suspense>
  </div>
  );
};

export default HomePage;
