import React, { useEffect, useState } from 'react';
import { Button, Table, Select } from '../../../Atoms';
import { useSelector } from 'react-redux';
import './styles.css';
import { useDispatch } from 'react-redux';
import { formatter } from '../../../Atoms/Currency/index';
import {
  CURRENT_STEP,
  STEP_KEYS,
  PREVIOUS_STEP,
  SET_SKIP_DEPENDENT_SCREEN
} from '../../../../Store/Slices/StepForm';

const SearchForm = (props) => {
  const { contentData, handleSubmit, currentStep, maxLength, setMaxLength, formik } = props;
  let { data } = props;
  const [dataWithSelector, setDataWithSelector] = useState([]); 
  const [areAllChecked, setAreAllChecked] = useState(false);
  const [range, setRange] = useState({
    minRange: 1,
    maxRange: 10,
  });
  let { formsList, steps, stepKeys } = contentData;
  if (props.innerForm) {
    stepKeys = props.stepKeys;
    steps = props.steps;
    formsList = props.formsList;
  }
  const { minRange, maxRange } = range;

  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);
  let table;
  let paginationConfig;
  let resultCount;
  let tableRowData;
  let tableHeader;
  let tableRow;
  let maxNumberOfRecordsReached = false;
  let minNumberOfRecordsReached = false;
  const dispatch = useDispatch();
  const currentForm = stepKeys[currentStep];
  dispatch(STEP_KEYS({ stepKeys: stepKeys }));
  dispatch(CURRENT_STEP({ currentStep: currentForm, stepId: currentStep }));

let hideSearchButton =props?.content?.layout?.hideSearchButton
  const checkAllHandler = () => {
    setAreAllChecked(areAllChecked ? false : true);
  };

  const checkHandler = currentValue => {
    let currentChecked = [...dataWithSelector];
    currentChecked.map((checkbox) => {
      if ((checkbox.buid) === currentValue.target.value){
        checkbox.checked = true;

        let { employeeId = {}, paramEntity = {} } = checkbox;
        let newSearchSelectedValues = {
          employeeBuid: employeeId.buid,
          personBuid: employeeId && employeeId.personId && employeeId.personId.buid,
          legalEntityBuid: employeeId && employeeId.legalEntityId && employeeId.legalEntityId.buid,
          shortName: paramEntity.shortName,
          name: paramEntity.name,
        };
        let oldSearValues =  props.parentForm && formik.values[props.parentForm] && stepKeys[currentStep] &&
        formik.values[props.parentForm][stepKeys[currentStep]] || {};
        formik.setValues({
          ...formik.values,
          [props.parentForm]: {
            ...formik.values[props.parentForm],
            [stepKeys[currentStep]]: {  ...oldSearValues, ...newSearchSelectedValues },
          },
        });

      } else {
        checkbox.checked = false;
      }
      return checkbox;
    })
    setDataWithSelector(currentChecked);
  }
  const setRangeLimit = () => {
    if (maxRange - minRange !== maxLength - 1) {
      setRange({
        minRange: 1,
        maxRange: parseInt(maxLength),
      });
    }
  };

  useEffect(() => {
    setRangeLimit();
  }, [maxLength]);

  useEffect(() => {
  let result =   data &&
    data['_Result'] &&
    data['_Result'].length > 0 && data['_Result'] || [];
    result =  result.map((element)=>{
   
        return {...element, ...{checked :false}}
    });
    setDataWithSelector(result);
  }, [props.data]);

  const onValueChange = (currentValue) => {
    let selectedObject =
      data &&
      data['_Result'] &&
      data['_Result'].filter((currentobj) => {
        return (
          currentobj.buid === (currentValue && currentValue.target && currentValue.target.value)
        );
      });
 
    
  };

  //code to make the table data based on the search result
  const setMinMaxRecord = (length) => {
    if (length <= maxRange) {
      maxNumberOfRecordsReached = true;
    }
    if (minRange <= 1) {
      minNumberOfRecordsReached = true;
    }
  };
  const handleSearchSubmit = (InnerFormName) => {
    let url = '';
    if (props.innerForm) {
      url =
        (props.content.layout &&
          props.content.layout.formSubmit &&
          props.content.layout.formSubmit.url) ||
        '';
    }
    let tempValues =
      (formik.values[props.parentForm] && formik.values[props.parentForm][InnerFormName]) || {};
    let finValues = {
      searchForAnEmployee: tempValues,
    };
    props.submiForm(finValues, true, url);
  };
  if (
    data &&
    data['_Result'] &&
    data['_Result'].length > 0 &&
    formsList[stepKeys[0]] &&
    formsList[stepKeys[0]].searchConfig
  ) {
    paginationConfig = formsList[stepKeys[0]].searchConfig.pagination;
    tableHeader = formsList[stepKeys[0]].searchConfig.tableHeader;
    tableRow = formsList[stepKeys[0]].searchConfig.tableRow;
    if (!maxLength) {
      setMaxLength(paginationConfig.defaultValue);
    }
    resultCount = data['_Result'].length ? data['_Result'].length : 0;
    let rangeData = [];
    setMinMaxRecord(data['_Result'].length);
    for (let i = range.minRange - 1; i < range.maxRange; i++) {
      if (data['_Result'][i]) {
        rangeData = [...rangeData, data['_Result'][i]];
      } else {
        break;
      }
    }
    tableRowData = rangeData.map((pickerData) => {
      let pickerTableRow = [];
      tableRow.map((item) => {
        let { displayName = '', className = '', valueType = '', format = '' } = item.config || {};
        let displayValue = (item.value && eval(item.value)) || '';
        if (displayName) {
          let obj = constantsList[displayName].find((o) => o.value == displayValue);
          if (obj) displayValue = obj['text'];
        }
        if (item.valuetype == 'currency')
          displayValue = formatter(item.locale, item.currency).format(displayValue);

        if (item.type === 'radio') {
          let tempValues =
            (formik.values[props.parentForm] &&
              formik.values[props.parentForm]['SearchForAnEmployee']) ||
            {};
          let diValue = (tempValues && tempValues['employeeBuid']) || '';
       
            displayValue = (
              /*   <Elementor
                  
                  key={item.name}
                  element={item}
                  value = {displayValue}
                  handleChange={onValueChange}
                  name  ={item.name}
                  {...props}
                  formName = {props.parentForm}
                  innerFormName = {props.InnerFormName}
                />  */

              <input
                type="radio"
                defaultChecked={diValue == displayValue}
                value={displayValue}
                name={`radio-box-search`}
                onChange={checkHandler}
              />
            );
       
        }
        pickerTableRow.push({
          className: className,
          value: displayValue,
          valueType: valueType,
          format: format,
        });
      });

      return pickerTableRow;
    });
    //ganerating the table component by paasing row and table data
    table = (
      <div className="container-fluid tableContainer py-5">
        <Table headers={tableHeader} body={tableRowData}></Table>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className="searchComponentContainer container-fluid px-md-5 py-3">
        <div className="">
          <div className="row">
            <div className="col-12 px-0">
              {steps[stepKeys[currentStep]] && steps[stepKeys[currentStep]][0]}
            </div>
          </div>
          {steps[stepKeys[currentStep]] && (
            <div className="row my-4 text-center">
              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 py-2">
                {props.innerForm ? (
                  <Button
                    className="w-100"
                    rounded
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      // popuatePickerData(stepKeys[currentStep], formData);
                      handleSearchSubmit(stepKeys[currentStep]);
                    }}
                  >
                    Search
                  </Button>
                ) : (
                  (!hideSearchButton && (<Button
                    className="w-100"
                    rounded
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      // popuatePickerData(stepKeys[currentStep], formData);
                      handleSubmit(stepKeys[currentStep]);
                    }}
                  >
                    Search
                  </Button>))
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`${props.innerForm ? '' : 'px-md-5 py-5 container-fluid'}`}>
        {table}
      </div>
    </React.Fragment>
  );
};

export default SearchForm;
