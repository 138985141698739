import appConfig from '../../../../appConfig/index.js';
import http from '../../../../Common/Services/HttpService/index.js';
import { trimPayload } from '../../../Molecules/CustomSection/Helper/commonHelper.js';
export const saveConfig = async (data, clientId, url,setShowLoader) => {
  setShowLoader(true);
  return await http
    .post(appConfig.SSP_MID_URL + url, {
      values: trimPayload(data),
      additionalInfo: {
        clientId,
      },
    })
    .then((resp) => {
      setShowLoader(false);
      const data = resp?.data?._Result;
      return data;
    }).catch(e=>{
      setShowLoader(false);
      return {
        error: e
      }
    });
};

export const saveProfile = async (data,clientId,url, setShowLoader) => {
  setShowLoader(true);
  return await http
  .post(appConfig.SSP_MID_URL + url, {
    values: trimPayload(data),
    additionalInfo: {
      clientId,
    },
    "loggedUserEntity": {
      "data": {
        "_Result": {
          "clientId": {
            "internalClientId": clientId
          }
        }
      }
    }           
  })
  .then((resp) => {
    setShowLoader(false);
    const data = resp
      return data; 
  }).catch(e=>{
    setShowLoader(false);
    return {
      error: e
    }
  });
  

}

export const saveLoginConfig = async (data, clientId, url,setShowLoader) => {
  setShowLoader(true);
  return await http
    .post(appConfig.SSP_MID_URL + url, manageLogiConfigFormData(trimPayload(data), clientId), {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((resp) => {
      setShowLoader(false);
      const data = resp;
      return data;
    }).catch(e=>{
      setShowLoader(false);
      return {
        error: e
      }
    });
};

let manageLogiConfigFormData = (payloadObj, clientId) => {
  let loginInformation = payloadObj?.loginConfigInformation || {};
  let formData = new FormData();
  formData.append('clientId', clientId);
  formData.append('titleColor', loginInformation.titleColorByClient);
  formData.append('welcomeMsg', loginInformation.welcomeDescTitleByClientEditValue);
  formData.append('msgColor', loginInformation.msgColorByClient);
  formData.append('welcomeTitle', loginInformation.welcomeTitleByClientEditValue);
  formData.append('hideLogo', Boolean(loginInformation.clientLogoToggle));
  formData.append('showChat', Boolean(loginInformation.showChat));
  formData.append('hideRecaptcha', Boolean(loginInformation.hideRecaptcha));
  if (
    payloadObj?.loginConfigInformation?.fileByClient &&
    payloadObj?.loginConfigInformation?.fileByClient.length
  ) {
    formData.append(
      'file',
      payloadObj?.loginConfigInformation?.fileByClient[0],
      payloadObj?.loginConfigInformation?.fileByClient[0].name
    );
  }
  return formData;
};
