import React from 'react';
import PropTypes from 'prop-types';
import './style.css'

const RadioButton = ({ name, label, options, selectedOption, onChange, className, required }) => {
  return (
    <div className={`radio-button-group ${className}`}>
      <label htmlFor = {name || label} className={'radioTitle'}>{label}</label>{required ? <span className='form-mandatory'>*</span> : null}
      <div className='radio-options-wrapper'>
      {options.map((option) => (
        <div key={option.value}>
          <input
            id={name || label}
            type="radio"
            name={name || label}
            value={option.value}
            checked={selectedOption === option.value}
            onChange={onChange}
          />
          <label>{option.label}</label>
        </div>
      ))}
      </div>
    </div>
  );
};
RadioButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
export default RadioButton;
