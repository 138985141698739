import { useSelector } from 'react-redux';
import constants from '../CustomSection/Content/Constants.json';
import moment from 'moment';

export let beneficiaryInfoContent = {
  title: 'Beneficiary Information',
  titleImage: 'img_bank_info.png',
  elements: [
    [
      {
        name: 'closeButton',
        type: 'button',
        placement: 'col-md-1',
        className: 'closeButton mr-4',
        label: 'Close',
      },
    ],
  ],
  models: [
    {
      title: 'Beneficiary Information',
      collapses: [
        {
          title: 'Critical Illness Insurance',
          hasBeneficiaries: true,
          body: [
            {
              title: 'PRIMARY BENEFICIARY',
            },
          ],
          tables: [
            {
              tableId: 'primaryBeneficiaries',
              isMultiple: true,
              hasActions: true,
              dataKey: 'dependentsInfo',
              actionsHeader: 'Actions',
              mobileActionsHeader: '',
              editKey: 'editDependentValidations',
              editModal: 'addDependent',
              tableData: 'dependentsTable',
              actionsStyle: {
                backgroundColor: '#0065AC',
                color: '#FFFFFF',
              },
              hideTable: true,
              multiConfig: {
                maxAdds: 3,
                tableHeaderStyle: {
                  backgroundColor: '#0065AC',
                },
                tableHeader: [
                  {
                    value: 'PRIMARY BENEFICIARY',
                    id: 'PRIMARYBENEFICIARY',
                    config: {
                      className: 'col-4 pl-4',
                      style: {
                        backgroundColor: '#0065AC',
                        color: '#FFFFFF',
                      },
                    },
                  },
                  {
                    value: 'PERCENTAGE OF ALLOCATION',
                    id: 'PERCENTAGEOFALLOCATION',
                    config: {
                      className: 'col-4 text-right',
                      style: {
                        backgroundColor: '#0065AC',
                        color: '#FFFFFF',
                      },
                    },
                  },
                ],
                tableRow: [],
                tableExpandedRow: [],
              },
            },
            {
              tableId: 'contingentBeneficiaries',
              isMultiple: true,
              hasActions: true,
              dataKey: 'dependentsInfo',
              actionsHeader: 'Actions',
              mobileActionsHeader: '',
              editKey: 'editDependentValidations',
              editModal: 'addDependent',
              tableData: 'dependentsTable',
              actionsStyle: {
                backgroundColor: '#0065AC',
                color: '#FFFFFF',
              },
              hideTable: true,
              multiConfig: {
                maxAdds: 3,
                tableHeaderStyle: {
                  backgroundColor: '#0065AC',
                },
                tableHeader: [
                  {
                    value: 'CONTINGENT BENEFICIARY',
                    id: 'CONTINGENTBENEFICIARY',
                    config: {
                      className: 'col-3 pl-4',
                      style: {
                        backgroundColor: '#0065AC',
                        color: '#FFFFFF',
                      },
                    },
                  },
                  {
                    value: 'PERCENTAGE OF ALLOCATION',
                    id: 'PERCENTAGEOFALLOCATION',
                    config: {
                      className: 'col-5 text-right',
                      style: {
                        backgroundColor: '#0065AC',
                        color: '#FFFFFF',
                      },
                    },
                  },
                ],
                tableRow: [],
                tableExpandedRow: [],
              },
            },
          ],
        },
      ],
    },
  ],
};

export const productNameMappings = {
  "CoverageSelection" : "Accident",
  "PetAssureNation" : "Pet Insurance",
  "WLCoverage" : "Whole Life",
  "IdTheft" : "ID Theft",
  "SupplementalHealthInsurance": "Supplemental Health Insurance",
  "LegalCoverage" : "Legal",
  "Travel" : "Travel Insurance",
  "PetPlus" : "Pet Insurance - PETPlus",
  "PetAssure" : "Pet Insurance - Pet Assure",
  "HospitalIndemnity" : "Hospital Indemnity",
  "CriticalIllness" : "Critical Illness",
  "UniversalLife" : "Universal Life",
  "ExcessLiability" : "Liability"
};

export const beneficiaryOptions = {
  "name": "beneficiaryOptions",
  "options": [
    {
      "id": "existing",
      "value": "existing",
      "text": "From Existing List",
      "labelClassName": "roundLeft mr-3"
    },
    {
      "id": "individual",
      "value": "individual",
      "text": "New Individual",
      "labelClassName": "roundLeft mr-3"
    },
    {
      "id": "trust",
      "value": "trust",
      "text": "New Trust/Group/Estate",
      "labelClassName": "roundRight"
    }
  ]
};

export const beneficiaryEditOptionsData = {
  "name": "beneficiaryOptions",
  "options": [
    {
      "id": "individual",
      "value": "individual",
      "text": "New Individual",
      "labelClassName": "roundLeft mr-3"
    }
  ]
};

export const beneficiaryEditOptionsLegal = {
  "name": "beneficiaryOptions",
  "options": [
    {
      "id": "trust",
      "value": "trust",
      "text": "New Trust/Group/Estate",
      "labelClassName": "roundRight"
    }
  ]
};

export const beneficiaryEditFormatted = (data, validateLiveAtSameAddress = () => {}) => {
  const liveAtSameaddress = validateLiveAtSameAddress(data, 'beneficiaryInformation');
  const beneficiaryInformation = data?.beneficiaryInformation;
  const newObject = {
    legalName: beneficiaryInformation?.name || '',
    beneficiaryIrrevocable: beneficiaryInformation?.irrevocable === false ? 'no' : 'yes',
    beneficiaryCollateral: 'no',
    beneficiaryFirstName: beneficiaryInformation?.firstName || '',
    beneficiaryMiddleName: '',
    beneficiaryLastName: beneficiaryInformation?.lastName || '',
    SSN: beneficiaryInformation?.sSN || '',
    beneficiaryRelationship: beneficiaryInformation?.relationToInsured || '',
    beneficiaryOptions: beneficiaryInformation?.className === 'aSPLI_US_LegalEntityBeneficiary' ? 'trust' : 'individual',
    beneficiaryPercentage: beneficiaryInformation?.percentage || 0,
    beneficiaryType: beneficiaryInformation?.beneficiaryRank === 'cPrimary' ? 'Primary' : 'Contingent',
    beneficiaryGender: data?.fullData?.gender ? data?.fullData?.gender : beneficiaryInformation?.title === 'ptMr' ? 'gMale' : beneficiaryInformation?.title === 'ptMrs' ? 'gFemale' : 'undisclosed',
    beneficiaryBirthDate: beneficiaryInformation?.birthDate || '',
    mailingAddressOptions: beneficiaryInformation?.address?.line1 ? 'yes' : 'no',
    // liveInSameAddress: liveAtSameaddress,
    liveInSameAddress: data?.data?.liveInSameAddress,
    beneficiaryAddressLine1: beneficiaryInformation?.address?.line1 || '',
    beneficiaryAddressLine2: beneficiaryInformation?.address?.line2 || '',
    beneficiaryCity: beneficiaryInformation?.address?.zipCodeAndCity?.city || '',
    beneficiaryState: beneficiaryInformation?.address?.state || '',
    beneficiaryZipCode: beneficiaryInformation?.address?.zipCodeAndCity?.zipCode || '',
    beneficiaryCountry: beneficiaryInformation?.address?.country || '',
  };
  return newObject;
};


// export const beneficiaryDataFormatter = (layoutData, beneficiary = {}, type = '', product = {}, isGlobalBeneficiaries = false, actionsFormatted = {}, isEdit, beneficiaryRules) => {
export const beneficiaryDataFormatter = (props) => {
  const {
    layoutData = {},
    beneficiary = {},
    type = '',
    product = {},
    isGlobalBeneficiaries = false,
    actionsFormatted = {},
    isEdit = false,
    beneficiaryRules   
  } = props;

  const isDependent = beneficiary?.isDependent || false;
  const isEditable = isGlobalBeneficiaries ? false : isEdit;

  const ssnFormater = (ssn = '') => {
    let ssnTemp = ssn.replace(/[^\d]/g, '');
    let ssnTempLen = ssnTemp.length;

    if (ssnTempLen < 3) return ssnTemp;
    if (ssnTempLen < 5) {
      return `${ssnTemp.slice(0, 3)}-${ssnTemp.slice(3)}`;
    }

    return `${ssnTemp.slice(0, 3)}-${ssnTemp.slice(3, 5)}-${ssnTemp.slice(5)}`;
  };

  const getRelationshipLabel = (relationshipValue) => {
    const option = Object.values(constants?.relationship ?? {}).find(
      (option) => option.value === relationshipValue || option.label === relationshipValue
    );
    return option ? option.label : '';
  };

  const calculateGenderFromTitle = (beneficiaryInformation) => {
    if(beneficiaryInformation?.title === 'ptMr') {
      return 'gMale'
    } else if(beneficiaryInformation?.title === 'ptMrs') {
      return 'gFemale'
    } else {
      return 'gUndisclosed'
    }
  }

  const calculateGender = (isDependent, beneficiary) => {
    if(isDependent) {
      return beneficiary?.data?.person?.data?.actor?.gender
    } else {
      if ( beneficiary?.edit) {
        return beneficiary?.data?.beneficiaryGender;
      } else {
        if(beneficiary?.fullData?.gender) {
          if(beneficiary?.fullData?.gender !== "gUndisclosed") {
            return beneficiary?.fullData?.gender
          } else {
            return calculateGenderFromTitle(beneficiary?.beneficiaryInformation)
          }
          
        } else {
          return calculateGenderFromTitle(beneficiary?.beneficiaryInformation)
        }
      }
    }
  } 
  const legalEntityIdentifier = ['aSPLI_US_LegalEntityBeneficiary']
  const updateRelationShipWithInsured = (beneficiary) => {
    if(beneficiary) {
      if(legalEntityIdentifier.indexOf(beneficiary?.beneficiaryInformation?.className) === -1 ) {
        if(beneficiary?.beneficiaryInformation?.relationToInsured) {
          return beneficiary?.beneficiaryInformation?.relationToInsured
        } else if (beneficiary?.beneficiaryRelationship) {
          return beneficiary?.beneficiaryRelationship
        } else {
          const dependentData = layoutData?.dependentsBeneficiaryList || layoutData?.dependentsList || layoutData?.dependentsTable;
          const beneficiaryBuid = beneficiary?.beneficiary?.identifier?.bUID
          if(beneficiaryBuid) {
            if(dependentData && dependentData.length) {
              const filteredDependent = dependentData.filter((dependent) => {
                return (beneficiaryBuid === dependent.bUID) || (beneficiaryBuid === dependent.id)
              })
              
              if(filteredDependent && filteredDependent.length) {
                return filteredDependent[0].beneficiaryRelationship || filteredDependent[0].dependentRelationShip
              } else {
                return ''
              }
            }
          }
        }
      } else {
        return 'Trust'
      }
    }
    return ''
  }

  const newGender = {
    'gMale': 'Male',
    'gFemale': 'Female',
    'gUndisclosed': 'Undisclosed',
  };
  const newBeneficiaryType = {
    'Primary': 'cPrimary',
    'Contingent': 'cContingent',
  };

  //IMPORTANT: At this moment we need gender, middlename and all the data related with the beneficiary
  const beneficiaryInformation = beneficiary?.beneficiaryInformation || {};
  const personBuid = beneficiary?.personBuid || beneficiary?.id || '';
  const SSN = isDependent ? beneficiary?.data?.person?.data?.actor?.sSN : beneficiaryInformation?.sSN;
  const beneficiaryBuid = beneficiaryInformation?.buid || beneficiary?.id || '';
  const beneficiaryPersonBuid = beneficiary?.beneficiaryPersonBuid || '';
  const firstName = beneficiaryInformation?.firstName || beneficiary?.beneficiaryFirstName || '';
  const middleName = beneficiary?.beneficiaryMiddleName || '';
  const lastName = beneficiaryInformation?.lastName || beneficiary?.beneficiaryLastName || '';
  const birthDate = beneficiaryInformation.birthDate || beneficiary?.beneficiaryDateOfBirth || '';
  const relationship = updateRelationShipWithInsured(beneficiary);
  const gender = calculateGender(isDependent ,beneficiary);
  const beneficiaryType = isDependent ? newBeneficiaryType[beneficiary?.beneficiaryRank] || beneficiary?.beneficiaryRank : beneficiaryInformation?.beneficiaryRank;
  const isLegalEntity = beneficiaryInformation.className === 'aSPLI_US_LegalEntityBeneficiary' ? true : false;
  const coverageBuid = product && product.coverageBuid;
  const productRules = beneficiaryRules && beneficiaryRules[`${product.productName}`] && beneficiaryRules[`${product.productName}`]['coverageBeneficiaryEligibility'] || [];
  let newRules = {};
  if (productRules && productRules.hasOwnProperty(personBuid)) {
    if (productRules[personBuid] && productRules[personBuid].coverageBuid === coverageBuid) {
      newRules = productRules[personBuid];
    }
  }

  const beneficiaryHeadersObj = [];

  if (isGlobalBeneficiaries) {
    beneficiaryHeadersObj.push(
      {
        value: '',
        valueType: 'checkBox',
        id: 'BENEFICIARYSELECTION',
        className: 'd-flex',
        classNameContainer: 'pl-4 ml-2 d-flex',
        classNameComponent: 'd-flex justify-content-center align-items-center',
        mainBuid: beneficiary?.bUID || beneficiary?.id || '',
        productId: product?.productId || '',
        productBuid: product?.productBuid || '',
        coverageBuid: product?.coverageBuid || '',
        type: type,
        productRules: newRules || {},
      },
      {
        value: isLegalEntity ? `${beneficiaryInformation?.name}` : `${firstName}${middleName} ${lastName}`,
        valueType: isEditable ? 'beneficiaryActions' : 'expression',
        id: 'BENEFICIARYNAME',
        className: `align-middle`,
        classActionsContainer: 'd-flex pt-3',
        moveActionLabel: beneficiaryType === 'cPrimary' ? 'Contingent' : 'Primary',
        onMoveAction: actionsFormatted['onMoveAction'],
        onDeleteAction: actionsFormatted['onDeleteAction'],
        mainBuid: beneficiary?.bUID || beneficiary?.id || '',
        productId: product?.productId || '',
        productBuid: product?.productBuid || '',
        coverageBuid: product?.coverageBuid || '',
        type: type,
        productRules: newRules || {},
      },
      {
        value: getRelationshipLabel(relationship),
        valueType: 'expression',
        id: 'BENEFICIARYRELATIONSHIP',
        source: 'beneficiaryInformation.relationToInsured',
        className: `col-6 ${!isEditable ? 'align-middle' : ''}`,
        // style: { display: 'flex', justifyContent: 'end' },
      }
    );
  } else {
    beneficiaryHeadersObj.push(
      {
        value: isLegalEntity ? `${beneficiaryInformation?.name}` : `${firstName}${middleName} ${lastName}`,
        valueType: isEditable ? 'beneficiaryActions' : 'expression',
        id: type === 'Primary' ? 'PRIMARYBENEFICIARY' : 'CONTINGENTBENEFICIARY',
        className: 'align-middle d-flex align-items-baseline',
        classActionsContainer: 'd-flex pt-3',
        moveActionLabel: beneficiaryType === 'cPrimary' ? 'Contingent' : 'Primary',
        onMoveAction: actionsFormatted['onMoveAction'],
        onDeleteAction: actionsFormatted['onDeleteAction'],
        onEditAction: actionsFormatted['onEditAction'],
        mainBuid: beneficiary?.bUID || beneficiary?.id || '',
        productId: product?.productId || '',
        productBuid: product?.productBuid || '',
        coverageBuid: product?.coverageBuid || '',
        type: type,
        productRules: newRules || {},
      },
      {
        value: `${beneficiaryInformation?.percentage ? beneficiaryInformation.percentage : beneficiary.beneficiaryPercentage}%`,
        valueType: isEditable ? 'text' : 'expression',
        id: 'beneficiaryPercentage',
        source: isDependent ? 'beneficiaryPercentage' : 'beneficiaryInformation.percentage',
        className: `text-right col-6 ${!isEditable ? 'align-middle' : ''}`,
        style: { display: 'flex', justifyContent: 'end' },
        validations: {
          string: {
            matches: {
              regex: "^(100|[0-9]{1,2})%?$",
              message: "Max percentage allowed"
            }
          }
        }
      }
    );
  }

  let address = '';
  let line1 = '';
  let line2 = '';
  let city = '';
  let state = '';
  let zipCode = '';

  // Address for dependent
  if (isDependent) {
    line1 = beneficiary?.data?.person?.data?.correspondences?.[0]?.address?.line1 || '';
    line2 = beneficiary?.data?.person?.data?.correspondences?.[0]?.address?.line2 ? ` ${beneficiary?.data?.person?.data?.correspondences?.[0]?.address?.line2}` : '';
    city = beneficiary?.data?.person?.data?.correspondences?.[0]?.address?.zipCodeAndCity?.city ? ` ${beneficiary?.data?.person?.data?.correspondences?.[0]?.address?.zipCodeAndCity?.city}` : '';
    state = beneficiary?.data?.person?.data?.correspondences?.[0]?.address?.state ? ` ${beneficiary?.data?.person?.data?.correspondences?.[0]?.address?.state}` : '';
    zipCode = beneficiary?.data?.person?.data?.correspondences?.[0]?.address?.zipCodeAndCity?.zipCode ? ` ${beneficiary?.data?.person?.data?.correspondences?.[0]?.address?.zipCodeAndCity?.zipCode}` : '';
    address = `${line1}${line2}${city}${state}${zipCode}`;
  }

  if (beneficiaryInformation?.address) {
    line1 = beneficiaryInformation?.address?.line1 || '';
    line2 = beneficiaryInformation?.address?.line2 ? ` ${beneficiaryInformation?.address?.line2}` : '';
    city = beneficiaryInformation?.address?.zipCodeAndCity?.city ? ` ${beneficiaryInformation?.address?.zipCodeAndCity?.city}` : '';
    state = beneficiaryInformation?.address?.state ? ` ${beneficiaryInformation?.address?.state}` : '';
    zipCode = beneficiaryInformation?.address?.zipCodeAndCity?.zipCode ? ` ${beneficiaryInformation?.address?.zipCodeAndCity?.zipCode}` : '';
    address = `${line1}${line2}${city}${state}${zipCode}`;
  }

  const beneficiaryContingentObj = {
    firstName: firstName,
    legalName: beneficiaryInformation?.name || '',
    middleName: middleName,
    lastName: lastName || '',
    ssn: ssnFormater(SSN),
    ssnMasked: SSN?.length === 9 ? `XXX-XX-${SSN.slice(-4)}` : '',
    dateOfBirth: birthDate ? moment(birthDate).format('MM-DD-YYYY') : '',
    relationship: getRelationshipLabel(relationship),
    gender: gender ? newGender[gender] : gender,
    address: address || beneficiaryInformation?.address,
    hasAddress: address || beneficiaryInformation?.address ? true : false,
    isLegalEntity: isLegalEntity,
    personBuid: beneficiary.personBuid,
    buid: `${coverageBuid}-${beneficiaryBuid}`,
  };

  return {
    beneficiaryHeadersObj,
    beneficiaryContingentObj,
  };
};