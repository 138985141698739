export const filterRequiredPropsFromEnrollmentProcess = ({reduxData}) => {
    return reduxData.map((enrollmentProcessData, index) => {
        return enrollmentProcessData?.map((data) => {
			let result = data._Result;
            if(Array.isArray(result)) {
                if(result && result.length) {
					let arr = [];
					result.map((item) => {
						arr.push({
							"subscribedProduct": {
								"additionalDatas": [
									...item?.subscribedProduct?.additionalDatas
								]
							},
							"memberOfferedProduct": {
								"product": {
									...item?.memberOfferedProduct?.product
								},
								"bUID": item?.memberOfferedProduct?.bUID || null
							}
						})
					})

					return {
						"_Result": arr
					}
				}
            } else {
                let obj = {
					"bUID": result?.bUID || null,
					"processEntity": {
						...result?.processEntity
					}
				} 
				return {
					"_Result": obj
				}
            }
        });
    });
}