import React from 'react';
import { Button } from '../../Atoms';

const FilterRecord = (props) => {
    const {
        formFields,
        formik,
        handleEmployeeIdChange,
        handleEmployeeIdBlur,
        handleFilterOnEnter,
        showMaskedInput,
        handleSSNChange,
        handleSSNBlur,
        searchByLastName,
        searchByFirstName,
        checkCharOnlyVariable,
        setSearchByLastName,
        setSearchByFirstName,
        handleFilter,
        handleResetFilter,
        setShowMaskedInput
    } = props
    return (

        <>
            <div className="py-3">
                {' '}
                <h4>Filter Employee Records</h4>
            </div>
            <div className="form-row row">
                <div className="col-3 floatingLabel">
                    Search by Employee ID
                    <input
                        id={formFields.employeeId.id}
                        name={formFields.employeeId.name}
                        type={formFields.employeeId.type}
                        placeholder={formFields.employeeId.placeholder}
                        className="w-100"
                        maxLength={11}
                        value={formik.values.employeeId}
                        onChange={handleEmployeeIdChange}
                        onBlur={handleEmployeeIdBlur}
                        aria-label="Search by Employee ID"
                        onKeyDown={handleFilterOnEnter}
                    />
                    {formik.touched.employeeId && formik.errors.employeeId ? (
                        <div class="form-mandatory">{formik.errors.employeeId}</div>
                    ) : null}
                </div>
                <div className="col-3 floatingLabel">
                    {formFields.employeeSSN.label}
                    {/* SSN Input */}
                    {showMaskedInput ? (
                        <input
                            id="maskedSSN"
                            name="maskedSSN"
                            type={formFields.employeeSSN.type}
                            className="w-100 maskedSSN"
                            value={maskedSSN}
                            onFocus={() => setShowMaskedInput(false)}
                            aria-label={formFields.employeeSSN.arealabel}
                            onKeyDown={handleFilterOnEnter}
                        />
                    ) : (
                        <input
                            id={formFields.employeeSSN.id}
                            name={formFields.employeeSSN.name}
                            type={formFields.employeeSSN.type}
                            placeholder={formFields.employeeSSN.placeholder}
                            className="w-100"
                            maxLength={11}
                            value={formik.values.employeeSSN}
                            onChange={handleSSNChange}
                            onBlur={handleSSNBlur}
                            aria-label={formFields.employeeSSN.arealabel}
                            onKeyDown={handleFilterOnEnter}
                        />
                    )}
                    {formik.touched.employeeSSN && formik.errors.employeeSSN ? (
                        <div className="form-mandatory">{formik.errors.employeeSSN}</div>
                    ) : null}
                </div>

                <div className="col-3 floatingLabel">
                    Search by Last Name
                    <input
                        type="text"
                        value={searchByLastName}
                        name="lastName"
                        className="form-control"
                        placeholder="Enter Last Name"
                        onChange={(e) => {
                            let isCharOnly = checkCharOnlyVariable(e.target.value)
                            isCharOnly && setSearchByLastName(e.target.value)
                        }}
                        onKeyDown={handleFilterOnEnter}
                    />
                </div>
                <div className="col-3 floatingLabel">
                    Search by First Name
                    <input
                        type="text"
                        value={searchByFirstName}
                        name="firstName"
                        className="form-control"
                        placeholder="Enter First Name"
                        onChange={(e) => {
                            let isCharOnly = checkCharOnlyVariable(e.target.value)
                            isCharOnly && setSearchByFirstName(e.target.value)
                        }}
                        onKeyDown={handleFilterOnEnter}
                    />
                </div>
            </div>
            <div className='row py-3'>
                <div className="pr-4 pl-3 py-3 ">
                    <Button
                        className=" "
                        outline
                        onClick={() => handleFilter()}
                    >
                        Filter Records
                    </Button>
                </div>
                <div className=" pr-4 py-3 ">
                    <Button
                        className=" "
                        outline
                        type="submit"
                        onClick={() => handleResetFilter()}
                    >
                        Reset Filter
                    </Button>
                </div>
            </div>
        </>
    )

}
export default FilterRecord;