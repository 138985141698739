import { createSlice } from '@reduxjs/toolkit';
import { nextStep, previousStep, goToFirstStep, goToLastStep, gotoSpecificStep } from '../../../App/Molecules/CustomSection/Utils/StepFormUtils';

const stepSlice = createSlice({
  name: 'stepFormNew',
  initialState: {
    skipSteps: [],
    stepKeys: [],
    currentStep: '',
    previousStep: '',
    forms: {},
    formData: {},
    stepContent: {},
    formsConfig: {}
  },
  reducers: {
    initializeForms: (state, action) => {
      state.forms = action.payload;
      state.formsConfig = action.payload;
    },
    initializeFormsData: (state, action) => {
      state.formData = action.payload;
    },

    SET_SKIP_STEPS: (stepForm, action) => {
      stepForm.skipSteps = action.payload;
    },
    SET_STEP_KEYS: (stepForm, action) => {
      stepForm.stepKeys = action.payload;
    },
    SET_CURRENT_STEP: (stepForm, action) => {
      stepForm.previousStep = stepForm.currentStep;
      stepForm.currentStep = action.payload;
    },
    NEXT_STEP: (stepForm) => {
      stepForm.previousStep = stepForm.currentStep;
      stepForm.currentStep = nextStep(stepForm.currentStep, stepForm.stepKeys, stepForm.skipSteps);
    },
    PREVIOUS_STEP: (stepForm) => {
      stepForm.previousStep = stepForm.currentStep;
      stepForm.currentStep = previousStep(stepForm.currentStep, stepForm.stepKeys, stepForm.skipSteps);
    },
    GO_TO_FIRST_STEP: (stepForm) => {
      stepForm.previousStep = stepForm.currentStep;
      stepForm.currentStep = goToFirstStep(stepForm.stepKeys);
    },
    GO_TO_LAST_STEP: (stepForm) => {
      stepForm.previousStep = stepForm.currentStep;
      stepForm.currentStep = goToLastStep(stepForm.stepKeys);
    },
    GO_TO_SPECIFIC_STEP: (stepForm, action) => {
      const step = gotoSpecificStep(action.payload, stepForm.stepKeys);
      if (step) {
        stepForm.previousStep = stepForm.currentStep;
        stepForm.currentStep = step;
      }
    },
    REMOVE_STEPS_AND_CONTINUE: (stepForm, action) => {
      const removeSteps = action.payload;
      stepForm.stepKeys = stepForm.stepKeys.filter(step => !removeSteps.includes(step));
      stepForm.previousStep = stepForm.currentStep;
      stepForm.currentStep = nextStep(stepForm.currentStep, stepForm.stepKeys, stepForm.skipSteps);
    },
    SET_STEP_CONTENT: (stepForm, action) => {
      const { content, step } = action.payload;
      stepForm.stepContent[step] = content?.map(item => ({ ...item, stepDetails: [] }));
    },
    SET_STEP_SUB_CONTENT: (stepForm, action) => {
      const { step, stepKey, stepDetails } = action.payload;
      const content = stepForm.stepContent[step];
      const subContentItem = content?.find(item => item.stepKey === stepKey);
      if (subContentItem) {
        subContentItem.stepDetails = stepDetails;
      }
    }
  },
});

export const {
  initializeForms,
  initializeFormsData,
  SET_SKIP_STEPS,
  SET_STEP_KEYS,
  SET_CURRENT_STEP,
  NEXT_STEP,
  PREVIOUS_STEP,
  GO_TO_FIRST_STEP,
  GO_TO_LAST_STEP,
  GO_TO_SPECIFIC_STEP,
  REMOVE_STEPS_AND_CONTINUE,
  SET_STEP_CONTENT,
  SET_STEP_SUB_CONTENT
} = stepSlice.actions;

export default stepSlice.reducer;
