import React, { useEffect } from 'react';
import CoverageWrapperFlowOne from '../Container/CoverageFlowOneWrapper';
import { UPDATE_COVERAGE_DETAILS } from '../../../../Store/Slices/InsuredInfo';
import { useDispatch, useSelector } from 'react-redux';
import Summary from '../Component/Common/Summary'
import { Button } from '../../../Atoms';
// Import Task from '../task' 
import Content from '../Content/GenericContent.json'
import Elementor from '../../../Cells/Form/Elementor';
import HeaderContent from '../Component/Common/HeaderContent.js'
import { UPDATE_CART_ITEM_NEW } from '../../../../Store/Slices/EmployeeProfile';
import WLCoverageQuestion from '../Component/Flow7/WLCoverageQuestion';
import { FormBuilder } from '../../../Cells/Form/FormBuilder';
import IFrameGeneratorFlow7 from '../Component/Flow7/IFrameGeneratorFlow7';

const Flow7 = (props) => {
  const dispatch = useDispatch();
  const { step, nextStep, prevStep, currentStep, validateSubscribedProductData, RadioSelctedValue, validateUnSubscribedProductData } = props || {};
  const { insuredInfo } = useSelector((storeState) => storeState);
  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  const productTitle = useSelector(
    (storeState) => {
      let x =
        storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result.filter(
          (data) => data.code == 'PRODTITLE'
        );
      if (x == undefined) {
        return '';
      } else {
        return x[0]?.content;
      }
    }
  );
  let coverageDetailsData = insuredInfo[currentStep]?.coverageDetails;

  const prodTitle = useSelector((storeState) => {
    const titleObj = storeState?.employeeProfile?.getGBPResourcesForMember?.filter((pobj) => {
      return pobj.code === 'COVTITLEWL'
    });
    if (titleObj[0]) {
      return titleObj[0]?.content || '';
    }
    return '';
  });

  const prodLogo = useSelector((storeState) => {
    const logoObj = storeState?.employeeProfile?.getGBPResourcesForMember?.filter((pobj) => {
      return pobj.code === 'PRODLOGOWL'
    });
    if (logoObj[0]) {
      return logoObj[0]?.binaryContent || '';
    }
    return '';

  });

  useEffect(() => {
    if (coverageDetailsData && productTitle) {
      // updateCostBasket()
    }
  }, [coverageDetailsData, productTitle]);

  if (step === null || step === undefined) {
    return null; // or return some other default UI or error message
  }

  const handleCoverageChange = (personBuid, selectedValue, waivedCoverage, coverageBuid, costPerPayPeriod) => {
    dispatch(UPDATE_COVERAGE_DETAILS({ personBuid, selectedValue, waivedCoverage, currentStep, coverageBuid, costPerPayPeriod }));

  };
  // const updateCostBasket = () => {
  //   dispatch(
  //     UPDATE_CART_ITEM_NEW({
  //       id: currentStep,
  //       moreThanOne: true,
  //       name: productTitle,
  //       coverageList: coverageDetailsData,
  //     })
  //   );
  // };

  const steps = [
    {
      label: '',
      component: () => <WLCoverageQuestion stepsCount={steps.length} employeeIndex="0" displayMode={'All'} {...props} />,
      // component: () => <PetAssureNationContent  stepsCount={steps.length}  employeeIndex="0" displayMode={'All'} {...props} />,
    },
    {
      label: '',
      component: () => <IFrameGeneratorFlow7 stepsCount={steps.length} employeeIndex="0" displayMode={'All'} {...props} />,
      // component: () => <PetAssureNationContent  stepsCount={steps.length}  employeeIndex="0" displayMode={'All'} {...props} />,
    },

    // {
    //   label: '',
    //   component: () => <CoverageWrapperFlowOne   stepsCount={steps.length}  displayMode={'All'} handleCoverageChange={handleCoverageChange} validateSubscribedProductData={validateSubscribedProductData} {...props}  currentStep = {currentStep}/>,
    // }
  ];
  // if (props.showDisclosureStatement) {
  // steps.push({
  //   label: 'Disclosure Statement',
  //   component: () => <div className='summaryWrapper'><Summary  RadioSelctedValue={RadioSelctedValue} showDisclosureStatement={props.showDisclosureStatement} validateSubscribedProductData={validateSubscribedProductData} validateUnSubscribedProductData={validateUnSubscribedProductData} {...props}></Summary></div>,
  // });
  // }

  const currentStepInfo = steps[step - 1];
  if (!currentStepInfo) {
    return null; // or return some other default UI or error message
  }
  return (
    <>



      {/*<HeaderContent>  </HeaderContent> */}

      <div class="row headerLogo">
        <div class="col-8">
          <div class="row " style={{
            fontSize: '2rem', color: 'rgb(59, 59, 59)',
            fontFamily: 'MMCDisplayRegular'
          }}>
            <div class=" "></div>
            <div class=" label  col-8 px-3  undefined" style={{ display: 'inline-block' }}>
              <div class="row">
                <div class=" px-3 py-3">
                  <div class="productTitle">
                    {prodTitle}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="d-flex flex-column justify-content-center align-items-end carrierLogoClass false">
            {
              prodLogo ? (
                <>
                  <img src={"data:image/png;base64," + prodLogo} name="carrierLogo" class="img-fluid line-class" alt="An image" />
                </>
              ) : (
                <>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=" name="carrierLogo" class="img-fluid line-class" alt="An image" />
                </>
              )
            }
          </div></div></div>

      {currentStepInfo.component()}


    </>
  );

  // const renderStep = () => {
  //   switch (step) {
  //     case 1:
  //       return (
  //         <>
  //           {currentStepInfo.component()}
  //           <div className='button-group'>
  //             <Button type="button" onClick={props.onBack} className="mr-3">  Back  </Button>
  //             <Button type="button" onClick={nextStep} className="mr-3">  Continue  </Button>
  //           </div>
  //         </>
  //       );
  //     case 2:
  //       return (
  //         <>
  //           {currentStepInfo.component()}
  //           <div className='button-group'>

  //             <Button type="button" onClick={prevStep} className="mr-3">  Back  </Button>
  //             <Button type="button" onClick={nextStep} className="mr-3">  Continue  </Button>
  //           </div>
  //         </>
  //       );
  //     case 3:
  //       return (
  //         <>
  //           {currentStepInfo.component()}
  //           <div className='button-group'>

  //             <Button type="button" onClick={prevStep} className="mr-3">  Back  </Button>
  //             <Button type="button" onClick={props.onNext} className="mr-3">  Continue  </Button>
  //           </div>

  //         </>
  //       );

  //     default:
  //       return <div>Invalid step</div>;
  //   }
  // };

  // return <div>{renderStep()}</div>;
};

export default Flow7;
