import React, { useState, useEffect, useMemo } from 'react';
import '../../styles.css';
import { Button } from '../../Atoms';
import { useDispatch, useSelector } from 'react-redux';
import ServiceHelper from '../../../../Utils/Helper/MappingHelper';
import PolicyReplacementQuestions from '../../Container/PolicyQuestionsWrapper.js';
import Beneficiary from '../Beneficiary/Beneficiary';
import { cleanHeaderNodeFromObj, getErrorMessages, cleanTableHeaderNodeFromArr, determineButtonLabel, filterCols, getEmptyKeysInArrOfObjects, getHideIrrPlanColBoolean } from '../../Helper/MappingHelper';
import { prepareSubscriptionForChoice } from '../../Actions/prepareSubscriptionForChoice.js'
import { SUBSCRIBE_COVERAGE_BUIDS, UPDATE_LOADING, UPDATE_WAIVE_PRODUCT } from '../../../../../Store/Slices/InsuredInfo';
import { getSubscribedCoverageBeneficiaryConfigurationNew } from '../../Actions/getSubscribedCoverageBeneficiaryConfigurationNew.js';
import { getListOfCoveragePlanDesignConfigurationNew } from '../../Actions/getListOfCoveragePlanDesignConfigurationNew.js';
import { getSubscribedCoverageConfigurationNew } from '../../Actions/getSubscribedCoverageConfigurationNew.js';
import { validateSubscribedProductData } from '../../Actions/validateSubscribedProductData.js';
import { useNavigationHook } from '../../Hooks/NavigationHook'; // Replace with your actual file path

import { getBeneficiariesData } from "../../Utils/BeneficiariesUtils";

import { unsubscribeCoverage } from '../../Actions/unsubscribeCoverage';
import { updateSubscribedCoverageInformationCI } from '../../Actions/updateSubscribedCoverageInformationCI';
import { UPDATE_COVERAGE_INFO } from '../../../../../Store/Slices/Entities/response';
import { continueBtnLabelHelper, screenNames } from '../../Helper/continueBtnLabelHelper';
import { coverageProductDetails, unsubscribeCoverageDetails, updateSubscribedCoverageInformation } from '../../Actions/productDetails';
import { getConfigHelper } from '../../../../Molecules/CustomSection/Helper/MappingHelper';
import { formatDisplayValueForAmount,formatDollarAmountWithDecimals } from "../../../../Utils/Helper/ValidationHelper";
import { COVERAGE_CANCELLED_BEFORE_CONFIRMATION_BUIDS } from '../../../../../Store/Slices/Enrollment';
import _ from 'lodash';
const CoverageFlowOne = (props) => {
  const { currentStep, formData, formsConfig, previousStep, handleNextStep, handlePreviousStep } = useNavigationHook();

  const [buttonLabel, setButtonLabel] = useState('')
  const [hideIrrColBoolean, setHideIrrColBoolean] = useState(false)
  const [selected, setSelected] = useState(null);
  const [waiveSelected, setWaiveSelected] = useState(false);
  const serviceHelper = new ServiceHelper();
  const dispatch = useDispatch();
  const [disableContinue, setDisableContinue] = useState(false);
  let { personBuid = '', coverageDetails = [], displayMode } = props || {};
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);

  const buid = props.employee[0]?.personBuid;
  personBuid = buid


  const [callApiOneTime, setCallApiOneTime] = useState(false);
  const [prepareApiOneTime, setPrepareApiOneTime] = useState(false);
  const [callApiZero, setCallApiZero] = useState(false);
  const [callApiOne, setCallApiOne] = useState(false);
  const [callApiTwo, setCallApiTwo] = useState(false);
  const [callApiThree, setCallApiThree] = useState(false);
  const { step, prevStep, onNext, nextStep, stepsCount, onBack, currentBuid, coverageBuid, declineProduct } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [canContinueBeneficiaries, setCanContinueBeneficiaries] = useState(false);
  const { insuredInfo, process } = useSelector((storeState) => storeState);
  const [coverageChange, isCoverageChanged] = useState(false)
  const [isBeneficiarySectionValid, setBeneficiarySectionValidBoolean] = useState(false)
  const [currentBeneficieries, setUpdatedBeneficiaryList] = useState([])
  const [policyReplacementQuestionCount, getTotalQuestionCount] = useState(0)
  const [showPolicyReplacementQuestionSection, setShowPolicyReplacementQuestionSection] = useState(false)
  const [pageLoadBoolean, setPageLoadBoolean] = useState(false)
  let currentReduxState = useSelector((storeState) => storeState);

  const currentSubscribedElements = currentReduxState?.enrollment?.enrollmentData?.contract?.subscribedElements;
  const productList = currentReduxState?.process?.productList;

  const callCenterEmployeeData = useSelector(
    (storeState) => storeState?.callCenter?.callCenterEnrollAsEmployeeLoginData
  );
  let productFamily = formsConfig.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
  let beneficiaryList = [];
  if (props.flowType == 1) {
    if (insuredInfo?.[currentStep]?.updatedBeneficiary?.[coverageBuid]?.length > 0) {
      const beneficiariesData = insuredInfo?.[currentStep]?.updatedBeneficiary?.[coverageBuid];
      beneficiaryList = beneficiariesData.filter(data => data?.beneficiarySelected === true);
    }
  }

  let coverageMode = 'cmMonoCovered';
  if (productList && productList.length) {
    let filteredProduct = productList.filter((elem) => {
      return elem?.memberOfferedProduct?.product?.buid === productIdentifierBuid
    })
    if (filteredProduct && filteredProduct.length) {
      coverageMode = filteredProduct?.[0].coverages?.[0]?.memberOfferedCoverage?.option?.insuredConfiguration?.coverageMode
    }
  }

  // if (props.flowType == 1) {
  //   if (insuredInfo?.[currentStep]?.updatedBeneficiaries?.length > 0) {
  //     let employeeBeneficiaries = insuredInfo?.[currentStep]?.updatedBeneficiaries?.filter(
  //       (o) => {
  //         return o.hasOwnProperty('person') ? o : '';
  //       }
  //     );
  //     if (employeeBeneficiaries.length > 0) {
  //       beneficiaryList = employeeBeneficiaries[0]?.person;
  //     }
  //   }
  // }

  // const showPolicyReplacementQuestionFlag = useSelector((storeState) =>
  //   storeState?.insuredInfo?.[currentStep]?.ProductLevelEligiblity?.policyReplacementQuestionEnable ?? false
  // );

  const [totalPercent, setTotalPersent] = useState(0);
  const insuredInfoCurrentStep = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};


  // Null check before filtering subscribeCoverageBuids
  const filteredSubscribeCoverageBuids = insuredInfoCurrentStep.subscribeCoverageBuids?.filter(item => item.personBuid === buid) || [];

  // Null check before assuming that we have at least one object after filtering
  const matchedCoverageBuidObj = filteredSubscribeCoverageBuids.length > 0 ? filteredSubscribeCoverageBuids[0] : null;


  // Extract required details with null checks
  const subscribedCoverageConfigurationResponse = matchedCoverageBuidObj?.subscribedCoverageConfiguration?._Result || {};




  const beneficiaryEligibility = useSelector(
    (storeState) =>
      storeState?.insuredInfo?.[currentStep]?.coverageBeneficiaryEligibility?.[0] || {
        isBeneficiaryEligible: false,
        isBeneficiaryMandatory: false,
        isBeneficiaryOptional: false
      }
  );

  let enrollmentProcessBuid = currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
  let loggedUserEntity = Object.keys(callCenterEmployeeData).length === 0 ? currentReduxState.auth.loggedUserEntity : callCenterEmployeeData;
  let getDetails = insuredInfoCurrentStep.subscribeCoverageBuids?.filter((e) => e?.personBuid === buid)
  let prepareSubscriptionForChoiseResp = insuredInfoCurrentStep?.prepareSubscriptionForChoice?._Result.filter((item) => item.bUID === coverageBuid)
  let subscribedOption = prepareSubscriptionForChoiseResp?.[0]?.subscribedOption
  let insuredPersons = prepareSubscriptionForChoiseResp?.[0]?.insuredPersons
  let subscribedCoverageBuid = getDetails?.[0]?.coverageBuid;
  //let subscribedElements =currentReduxState.entities?.response?.onLoadResponseList?.[currentStep]?.ValidateSubscribedProductData?._Result?.contract?.subscribedElements;
  let subscribedElements = getDetails?.[0]?.perpareSubscriptionForChoices?._Result || [];
  let updatedSubscribedElements = subscribedElements.map((item) => {
    if (subscribedOption) {
      if (item.bUID === coverageBuid) {
        return {
          ...item,
          insuredPersons: insuredPersons,
          subscribedOption: subscribedOption
        }
      } else {
        return item
      }
    } else {
      return item
    }
  })

  const firstCallResponse = useSelector((storeState) =>
    storeState?.entities?.response?.onLoadResponseList?.UpdatesubscribeCoverage?._Result?.contract?.subscribedElements
  );
  let firstCallCoverageResp = firstCallResponse?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid)?.[0]?.coverages || [];
  let allowedBeneficiaryKind = '';
  //let coverageConfiguration = currentReduxState.entities?.response?.onLoadResponseList?.[`getSubscribedCoverageConfigurationFor${currentStep}`] || []
  let coverageConfiguration = getDetails?.[0]?.subscribedCoverageConfiguration || ''
  let possibleValues = [];

  const enrollmentType = useSelector(
    (storeState) =>
      storeState?.enrollment?.enrollmentType,
  );
  if (coverageConfiguration) {
    possibleValues = coverageConfiguration?._Result?.additionalDatas;
  }
  let coverageDetailsData = insuredInfo[currentStep]?.coverageDetails;



  let selectedValue = coverageDetailsData?.[0]?.selectedValue;
  let policyReplacementQuestions =
    currentReduxState?.insuredInfo[currentStep]?.policyReplacementQuestions;
  let policyReplacementAnswer = [];

  if (Object.keys(policyReplacementQuestions ?? {}).length > 0) {
    Object.entries(policyReplacementQuestions).map(([key, value]) =>
      policyReplacementAnswer.push({ fieldName: key, valueCode: value })
    );
  }

  let waivedCoverage = insuredInfo[currentStep]?.WaiveCoverage?.find(item => item.personBuid === personBuid)?.waiveProduct;
  const [isChecked, setIsChecked] = useState(waivedCoverage ? waivedCoverage : false);
  let getSubscribedCoverageBeneficiary = getDetails?.[0]?.beneficiaryConfiguration || ''
  let beneficiaryConfigurationDetails = getSubscribedCoverageBeneficiary?._Result?.filter((e) => e.forCoveredPerson?.bUID === buid)
  let allowedBeneficiaryDetails = beneficiaryConfigurationDetails?.[0]?.allowedBeneficiaryKind || []
  //let allowedBeneficiaryDetails = getSubscribedCoverageBeneficiary?._Result?.allowedBeneficiaryKind || []
  if (allowedBeneficiaryDetails?.length > 0) {
    if (beneficiaryList.length > 0 && allowedBeneficiaryDetails.includes("cDefineBeneficiaries")) {
      allowedBeneficiaryKind = 'cDefineBeneficiaries';
    } else {
      allowedBeneficiaryKind = 'cByWillOrEstate';
    }
  }

  let coverageBeneficiaryEligibility = insuredInfo[currentStep]?.coverageBeneficiaryEligibility;

  let coverageBeneficiaryEligibilityProduct = {
    isBeneficiaryEligible: false,
    isBeneficiaryMandatory: false,
    isBeneficiaryOptional: false
  };

  if (coverageBeneficiaryEligibility && coverageBeneficiaryEligibility.length) {
    coverageBeneficiaryEligibilityProduct = _.findLast(coverageBeneficiaryEligibility, item => item.coverageBuid === getDetails?.[0]?.coverageBuid && item.personBuid === personBuid)
  }


  const getAllPossibleChoicesConfiguration = currentReduxState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]
  const subscribedProductBuid = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements
  let filterByProductBuid = subscribedProductBuid?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
  let subscribedPBuid = filterByProductBuid?.[0]?.bUID
  let coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids?.[0];
  if (props.flowType === 2 || props.flowType === 3) {
    coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids.filter((e) => e?.coveragePersonBuid?.includes(personBuid))[0];
  }

  const apiCall = async () => {
    if (!waivedCoverage) {
      dispatch(UPDATE_LOADING(true));
      let externalStepContext = {
        "portalStep": currentStep,
        "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
        "reachedPreConfirmation": "no"
      };
      try {
        await dispatch(updateSubscribedCoverageInformation({
          enrollmentProcessBuid,
          subscribedElements: coverageMode === 'cmMultiCovered' && coverageChange === true ? firstCallCoverageResp : updatedSubscribedElements,
          productIdentifierBuid,
          beneficiaryList,
          allowedBeneficiaryKind,
          selectedValue,
          possibleValues,
          policyReplacementAnswer,
          loggedUserEntity,
          coverageBeneficiaryEligibility: coverageBeneficiaryEligibility?.length > 0 ? true : false,
          currentStep,
          coverageBuid: getDetails?.[0]?.coverageBuid,
          externalStepContext: externalStepContext,
          isMoveNext: false,
          personBuid
        }));

      } catch (e) {
        console.error(e)
      } finally {
        dispatch(UPDATE_LOADING(false));
      }
      // dispatch(UPDATE_LOADING(true));
      // try {
      //   let actionDispatched = await dispatch(updateSubscribedCoverageInformationCI({
      //     enrollmentProcessBuid,
      //     subscribedElements,
      //     productIdentifierBuid,
      //     beneficiaryList,
      //     allowedBeneficiaryKind,
      //     selectedValue,
      //     possibleValues,
      //     policyReplacementAnswer,
      //     loggedUserEntity,
      //     //subscribedCoverageBuid,
      //     coverageBeneficiaryEligibility: coverageBeneficiaryEligibility?.length > 0 ? true : false,
      //     currentStep
      //   }))
      //   if (actionDispatched) {
      //     let externalStepContext = {
      //       "portalStep": currentStep,
      //       "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
      //       "reachedPreConfirmation": "no"
      //     };
      //     dispatch(UPDATE_LOADING(true));
      //     let resObj = await serviceHelper.sendApiRequest('/validateSubscribedCoverageData', {
      //       enrollmentProcessBuid: enrollmentProcessBuid,
      //       coverageBuid: getDetails?.[0]?.coverageBuid,
      //       externalStepContext
      //     })
      //     if (resObj.data && resObj.status === 200) {
      //       // Format the new data
      //       dispatch(SUBSCRIBE_COVERAGE_BUIDS({
      //         coverageBuid: getDetails?.[0]?.coverageBuid,
      //         personBuid: personBuid,
      //         product: currentStep,
      //       }));
      //       dispatch(
      //         UPDATE_COVERAGE_INFO({
      //           coverageBuid: getDetails?.[0]?.coverageBuid,
      //           personBuid: personBuid,
      //           product: currentStep,
      //           validateSubscribedCoverageData: resObj.data
      //         })
      //       );
      //     }
      //     dispatch(UPDATE_LOADING(false));
      //   }
      // } catch (e) {
      //   console.error(e)
      // } finally {
      //   dispatch(UPDATE_LOADING(false));
      // }
      await dispatch(COVERAGE_CANCELLED_BEFORE_CONFIRMATION_BUIDS({
        process: 'remove',
        buid: productIdentifierBuid
      }))
    } else if (waivedCoverage === true) {
      dispatch(UPDATE_LOADING(true));
      try {
        // await dispatch(unsubscribeCoverageDetails({
        //   enrollmentProcessBuid: enrollmentProcessBuid,
        //     coverageBuid: coverageDetailsForPrepare?.coverage,
        //     coveredPerson: coverageDetailsForPrepare?.coveragePersonBuid,
        //     subscribedCoverageBuid: subscribedCoverageBuid,
        //     isPrepare: false,
        //     isMoveNext: false,
        // }, "UnsubscribeCoverage", true));
        await declineProduct();
        await dispatch(COVERAGE_CANCELLED_BEFORE_CONFIRMATION_BUIDS({
          process: 'add',
          buid: productIdentifierBuid
        }))
        // await dispatch(unsubscribeCoverage({
        //   enrollmentProcessBuid,
        //   subscribedCoverageBuid,
        // }, "UnsubscribeCoverage", true))
      }
      catch (e) {

      } finally {
        dispatch(UPDATE_LOADING(false));
      }
    }
  };


  useEffect(() => {
    if (coverageDetailsForPrepare && subscribedProductBuid && !prepareApiOneTime) {
      setPrepareApiOneTime(true)

      // const fetchData = async () => {
      //   try {
      //     await dispatch(validateSubscribedProductData({
      //       enrollmentProcessBuid: enrollmentProcessBuid,
      //       productIdentifierBuid: productIdentifierBuid,
      //       currentStep: currentStep,
      //       subscribedElements: subscribedProductBuid,
      //       expectReturn: true
      //     }));

      //     const coverageBuid = await dispatch(prepareSubscriptionForChoice(
      //       enrollmentProcessBuid,
      //       coverageDetailsForPrepare?.coverage,
      //       coverageDetailsForPrepare?.coveragePersonBuid,
      //       currentStep,
      //       personBuid,
      //     ));

      //     if (!coverageBuid) {
      //       throw new Error('coverageBuid not received from prepareSubscriptionForChoice');
      //     }

      //     await dispatch(getSubscribedCoverageBeneficiaryConfigurationNew(
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       buid,
      //       currentStep,
      //     ));

      //     await dispatch(getSubscribedCoverageConfigurationNew(
      //       currentStep,
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       buid
      //     ));

      //     await dispatch(getListOfCoveragePlanDesignConfigurationNew(
      //       currentStep,
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       buid
      //     ));
      //   } catch (error) {
      //     console.error('There was an error!', error);
      //   }
      // }
      const fetchData = async () => {
        dispatch(UPDATE_LOADING(true));
        try {
          let listOfProduct = subscribedProductBuid?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
          let subscribedPBuid = listOfProduct?.[0]?.bUID
          await dispatch(coverageProductDetails({
            enrollmentProcessBuid: enrollmentProcessBuid,
            flowType: props.flowType,
            subscribedProductBuid: subscribedPBuid,
            personBuid: personBuid,
            prepareCoverageBuid: coverageDetailsForPrepare?.coverage,
            prepareCoveredPersonBuid: coverageDetailsForPrepare?.coveragePersonBuid,
            currentStep: currentStep
          }));
        } catch (e) {
          console.error(e)
        } finally {
          dispatch(UPDATE_LOADING(false));
        }
      }
      fetchData();
    }
  }, [prepareApiOneTime, currentStep, getAllPossibleChoicesConfiguration, insuredInfo, enrollmentProcessBuid, buid, props.flowType, coverageDetailsForPrepare, subscribedProductBuid]);

  const subscribedProducts = enrollmentData?.contract?.subscribedElements || [];
  const continueBtnObj = {
    flowType: props.flowType,
    screenType: screenNames.COVERAGE_SCREEN,
    totalProductCount: subscribedProducts.length,
    productIdentifierBuid,
    previousStep: previousStep,
    hasDisclosureStatement: props.showDisclosureStatement,
    isWaivedOffFlag: false
  }

  const [showPolicyReplacementQuestions, setShowPolicyReplacementQuestions] = useState(false);

  const handleWaiveCoverageChange = (event) => {
    setSelected(null);
    setWaiveSelected(true);
    setIsChecked(true);
    isCoverageChanged(true)
    if (props.onCoverageChange) {
      props.onCoverageChange(props.personBuid, null, true, subscribedCoverageBuid, '');
    }
    if (props.showOrHidePolicyReplacementQues) {
      props.showOrHidePolicyReplacementQues(props.personBuid, null, true);
    }
    setShowPolicyReplacementQuestions(false);
    dispatch(UPDATE_WAIVE_PRODUCT({ personBuid, "waiveProduct": true, currentStep }))
    setButtonLabel(continueBtnLabelHelper({
      ...continueBtnObj,
      isWaivedOffFlag: true,
    }))
  };

  const handleSelect = async (index, costPerPayPeriod, e) => {
    setPageLoadBoolean(true)
    if (coverageMode === "cmMultiCovered") {
      await dispatch(updateSubscribedCoverageInformation({
        enrollmentProcessBuid,
        subscribedElements: updatedSubscribedElements,
        productIdentifierBuid,
        beneficiaryList,
        allowedBeneficiaryKind,
        selectedValue: index.toString(),
        possibleValues,
        policyReplacementAnswer,
        loggedUserEntity,
        coverageBeneficiaryEligibility: coverageBeneficiaryEligibility?.length > 0 ? true : false,
        currentStep,
        coverageBuid: getDetails?.[0]?.coverageBuid,
        isMoveNext: false,
        personBuid,
        isSubscribeBeneficiary: true
      }));
      await dispatch(getBeneficiariesData(currentReduxState));
    }
    dispatch(UPDATE_WAIVE_PRODUCT({ personBuid, "waiveProduct": false, currentStep }))
    setSelected(index.toString());
    setIsChecked(false)
    setWaiveSelected(false);
    isCoverageChanged(true)
    if (props.onCoverageChange) {
      props.onCoverageChange(props.personBuid, index, false, subscribedCoverageBuid, costPerPayPeriod);
    }
    setShowPolicyReplacementQuestions(true);
    if (props.showOrHidePolicyReplacementQues) {
      props.showOrHidePolicyReplacementQues(props.personBuid, index, false);
    }

    setButtonLabel(continueBtnLabelHelper({
      ...continueBtnObj,
      isWaivedOffFlag: false
    }))
  };

  useEffect(() => {

    isCoverageChanged(false)
    setButtonLabel(continueBtnLabelHelper({
      ...continueBtnObj,
      isWaivedOffFlag: isChecked,
    }))
  }, [])

  const nextStepCall = async () => {
    if (!props.showDisclosureStatement && step === stepsCount) {
      await apiCall();
      onNext();
      props.validateSubscribedProductData();

    } else {
      await apiCall();
      nextStep();
    }

  };

  const callOnNext = async () => {
    await apiCall();
    await onNext();
  };


  useEffect(() => {
    if (selectedValue) {

      setSelected(selectedValue.toString());
    }
  }, [selectedValue]);

  useEffect(() => {

    setCallApiOne(false);
    setCallApiTwo(false);
    setCallApiThree(false);
    setCallApiZero(false);
    setCallApiOneTime(false);
    //  setPrepareApiOneTime(false);
  }, [buid]);

  useEffect(() => {

    setHideIrrColBoolean(getHideIrrPlanColBoolean({ productFamily }));
  }, [productFamily])

  // Extract dependentCoverageTable from customContent
  const dependentCoverageTable = props.customContent?.tables.find(
    (table) => table.tableId === 'dependentCoverageTable'
  );



  // Extract tableHeader and tableRow from multiConfig
  let { tableHeader, tableRow } = dependentCoverageTable.multiConfig;
  let tobleHeaderValue = props.tableData.length > 0 && props?.tableData?.[0].headerContent ? props?.tableData?.[0]?.headerContent : []

  let updatedTableData = props.tableData;
  if (hideIrrColBoolean === "Yes" && updatedTableData.length > 1) {
    const hideColumns = getEmptyKeysInArrOfObjects(updatedTableData)
    if (hideColumns.length) {
      tableHeader = filterCols(hideColumns, tableHeader);
      tableRow = filterCols(hideColumns, tableRow)
    }

    updatedTableData = cleanHeaderNodeFromObj(updatedTableData)
    tobleHeaderValue = cleanTableHeaderNodeFromArr(tobleHeaderValue)
    updatedTableData = updatedTableData.map((item) => {
      if (hideColumns.length && item) {
        let result = _.omit(item, hideColumns);
        return result
      } else {
        return item
      }
    })
  }

  useEffect(() => {
    if (!pageLoadBoolean && updatedTableData?.length > 0 && selected && subscribedCoverageBuid) {

      let coverageData = updatedTableData?.filter(e => selected === e.code)
      if (props.onCoverageChange && coverageData?.length) {
        props.onCoverageChange(props.personBuid, coverageData?.[0]?.code, false, subscribedCoverageBuid, coverageData?.[0]?.costPerPayPeriod);
      }
      setPageLoadBoolean(true)
    }
  }, [pageLoadBoolean, updatedTableData, selected, subscribedCoverageBuid])

  const beneficiaryKinds = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.[
      `GetSubscribedCoverageBeneficiaryConfigurationFor${currentStep}`
      ]?.[0]
  );

  const checkBenificiaryFn = (pstatus, pselectedRows) => {
    let primaryTotal = 0;
    let contingentTotal = 0;
    let hasPrimary = false;
    let hasContingent = false;
    insuredInfo[currentStep]?.updatedBeneficiaries?.forEach(pobj => {
      Object.keys(pobj).forEach((pbeneficiary, pind) => {
        const beneficiary = pobj[pbeneficiary][pind];
        if (beneficiary.beneficiaryType === 'Primary') {
          primaryTotal += beneficiary.beneficiaryPercentage;
          hasPrimary = true;
        } else {
          contingentTotal += beneficiary.beneficiaryPercentage;
          hasContingent = true;
        }
      })
    });
    let lflag = true;
    if (hasPrimary && primaryTotal !== 100) {
      lflag = false;
    }

    if (hasContingent && contingentTotal !== 100) {
      lflag = false;
    }
    if (pstatus == "success") {
      setTotalPersent(100);
    } else {
      const currentPercent = ((primaryTotal > 0 ? primaryTotal : 0) + (contingentTotal > 0 ? contingentTotal : 0)) * 100 / 200;
      setTotalPersent(0);
    }
    setSelectedRows([...pselectedRows]);
  };
  // need to work here to get total questions and got it from policy questions wrapper
  const currentStepProperty = `getSubscribedCoverageConfigurationFor${currentStep}`;
  const subscribedCoverageConfiguration = useSelector((storeState) =>

    storeState?.entities?.response?.onLoadResponseList?.[currentStepProperty]

  );
  let filterByBuid = []
  if (props.flowType == 1) {
    filterByBuid = subscribedCoverageConfiguration;
  } else {
    filterByBuid = subscribedCoverageConfiguration?.filter(e => e.personBuid === personBuid);
  }

  // let policyReplacementData = filterByBuid?.[0]?.result;
  let policyReplacementData = subscribedCoverageConfigurationResponse;

  const totalRadioQuestions = useMemo(() => {
    let policyReplacementTotalQuestions = policyReplacementData?.additionalDatas?.filter(eachObj => eachObj.fieldCode !== "PlanDesign");
    const totalQuestions = policyReplacementTotalQuestions?.filter(
      // (question) => question?.fieldCode !== 'PlanDesign' && question?.fieldCode !== 'DD_00177'
      // Need to check with Deepak - sk
      (question) => question?.fieldCode !== 'PlanDesign' && question.possibleValues
    );
    if (totalQuestions && totalQuestions.length) {
      getTotalQuestionCount(totalQuestions?.length)
    }
    return totalQuestions;
  }, [policyReplacementData])
  let showPolicyReplacementQuestionFlag = totalRadioQuestions?.length > 0 ? true : false
  const totalPolicyQuestionDiff = useMemo(() => {
    const totalQuestions = totalRadioQuestions?.length || 0;
    let totalAnswers = [];
    totalRadioQuestions?.forEach(pquestion => {
      const lanswer = policyReplacementAnswer?.find(panswer => {
        return pquestion.fieldCode === panswer.fieldName
      });
      if (lanswer) {
        totalAnswers.push(lanswer);
      }
    })
    //totalAnswers = policyReplacementAnswer?.length || 0;
    return totalQuestions - totalAnswers?.length;
  }, [policyReplacementAnswer])

  const handleDisableFn = () => {
    // if (selectedValue === undefined) {
    //   setDisableContinue(true);
    // }
    // else if (selectedValue === null || waivedCoverage) {
    //   setDisableContinue(false);
    // } else if (!props.beneficiaryEligibility && selectedValue && totalPolicyQuestionDiff === 0) {
    //   if(coverageBeneficiaryEligibilityProduct?.isBeneficiaryMandatory && !canContinueBeneficiaries) {
    //     setDisableContinue(true);
    //   } else {
    //     setDisableContinue(false);
    //   }

    // } else {
    //   const isBeneficiaryValid = (props.isBeneficiaryMandatory && selectedRows?.length && canContinueBeneficiaries)
    //       || (props.beneficiaryEligibility && (!selectedRows?.length || canContinueBeneficiaries));
    //   if (isBeneficiaryValid && totalPolicyQuestionDiff === 0) {
    //       setDisableContinue(false);
    //   } else {
    //       setDisableContinue(true);
    //   }
    // }
    // else if (beneficiaryEligibility.isBeneficiaryEligible
    //   && !beneficiaryEligibility.isBeneficiaryMandatory
    //   && selectedRows?.length > 0
    //   && totalPercent == 100) {

    //   setDisableContinue(false);
    // }
    // else if (beneficiaryEligibility.isBeneficiaryEligible
    //   && !beneficiaryEligibility.isBeneficiaryMandatory && selectedRows?.length == 0
    //   && totalPolicyQuestionDiff == 0) {

    //   setDisableContinue(false);
    // }
    // else if (beneficiaryEligibility.isBeneficiaryMandatory
    //   && totalPercent == 100
    //   && totalPolicyQuestionDiff == 0) {
    //    setDisableContinue(false);
    // } else if (!beneficiaryEligibility.isBeneficiaryEligible && !showPolicyReplacementQuestionFlag) {
    //   setDisableContinue(false);

    // } else if (!beneficiaryEligibility.isBeneficiaryEligible && showPolicyReplacementQuestionFlag && totalPolicyQuestionDiff === 0) {
    //   setDisableContinue(false);
    // } else {
    //   setDisableContinue(true);
    // }

    const policyReplacementQuestionsSectionCheck = () => {
      if (policyReplacementQuestionCount) {
        setShowPolicyReplacementQuestionSection(true)
        if (totalPolicyQuestionDiff === 0) {
          return false
        } else {
          return true
        }
      } else {
        setShowPolicyReplacementQuestionSection(false)
        return false
      }
    }

    const beneficiarySectionValidation = () => {
      if (coverageBeneficiaryEligibilityProduct?.isBeneficiaryMandatory) {
        if (isBeneficiarySectionValid) {
          return policyReplacementQuestionsSectionCheck()
        } else {
          setShowPolicyReplacementQuestionSection(false)
          return true
        }
      } else {
        if (coverageBeneficiaryEligibilityProduct?.isBeneficiaryOptional) {
          if (selectedRows && selectedRows.length) {
            if (isBeneficiarySectionValid) {
              return policyReplacementQuestionsSectionCheck()
            } else {
              setShowPolicyReplacementQuestionSection(false)
              return true
            }
          } else {
            if (policyReplacementQuestionCount) {
              setShowPolicyReplacementQuestionSection(true)
              return policyReplacementQuestionsSectionCheck();
            } else {
              setShowPolicyReplacementQuestionSection(false)
            }
            return false
          }
        } else {
          return policyReplacementQuestionsSectionCheck()
        }
      }
    }

    if (selectedValue) {
      return beneficiarySectionValidation();
    } else {
      if (waivedCoverage) {
        setShowPolicyReplacementQuestionSection(false)
        return false
      } else {
        return true
      }
    }
  };

  // useEffect(() => {
  //   handleDisableFn();
  // }, [selectedValue, totalPolicyQuestionDiff, totalPercent, selectedRows, beneficiaryEligibility.isBeneficiaryEligible, beneficiaryEligibility.isBeneficiaryMandatory]);
  useEffect(() => {

    setDisableContinue(handleDisableFn());
  }, [selectedValue, totalPercent, selectedRows, policyReplacementQuestionCount, totalPolicyQuestionDiff, canContinueBeneficiaries, isBeneficiarySectionValid]);

  // UseEffect to get all the data about Global Beneficiaries
  useEffect(() => {

    dispatch(getBeneficiariesData(currentReduxState));
  }, [currentSubscribedElements && currentSubscribedElements?.length > 0, process && process?.productFamilyIdentifier])


  const hasErrors = getErrorMessages(currentStep);


  //Configuration for coverage table
  const configDataRedux = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.productConfiguration
      ?._Result
  );
  if (configDataRedux?.premiumFrequency?.isEditable) {
    getConfigHelper(tableHeader, configDataRedux)
  }
  return (
    <>
      <div
        className="row"
        style={{ fontSize: '1.5rem', color: 'rgb(32, 32, 32)', fontFamily: 'NotoSansRegular' }}
      >
        <div className="">
          <div className="label col-12 px-3 undefined" style={{ display: 'inline-block' }}>
            <div className="row">
              <div className="px-3 py-3">Select Coverage Option</div>
            </div>
          </div>
        </div>
      </div>

      <table className="table atomCoverageTable table-mobile-border coverageTable">
        <thead>
          <tr style={tableHeader[0].config.style}>
            <th
              className="header-no-wrap colHeader d-none d-sm-table-cell text-right"
              aria-label="coverage table"
              aria-hidden="true"
              scope="col"></th>
            {tableHeader.map((header, index) => tobleHeaderValue?.[index]?.presentedName || header.id == 'costPerPayPeriod' ? (
              <th
                key={index}
                className={`header-no-wrap colHeader ${header.config.className}`}
                scope="col"
                style={header.config.style}
              >
                {tobleHeaderValue?.[index]?.presentedName ? tobleHeaderValue?.[index]?.presentedName : header.value}
              </th>
            ) : '')}
          </tr>
        </thead>
        <tbody className="">
          {updatedTableData.map((data, index) => (
            <tr key={index}>
              <td className="table-data-no-wrap coverageRadio" style={{ width: '1rem' }}>
                <span>
                  <input
                    aria-label="coverageRadio"
                    id={index}
                    className="form-check-radio coverageRadio"
                    type="radio"
                    value=""
                    checked={selected === data.code}
                    onChange={(event) => handleSelect(data.code, data.costPerPayPeriod, event)}
                  />
                </span>
              </td>
              {tableRow.map((row, idx) => updatedTableData?.[index]?.headerContent?.[idx] || row.id == 'costPerPayPeriod' ? (

                <td
                  key={idx}
                  className={
                    row.className ? `table-data-no-wrap ${row.className}` : 'table-data-no-wrap'
                  }
                >
                  {
                  productFamily === 'ID Theft' ?
                    typeof (updatedTableData?.[index]?.headerContent?.[idx]?.fieldValue) === 'object'
                      ? updatedTableData?.[index]?.headerContent?.[idx]?.fieldValue?.amount ? `${formatDisplayValueForAmount(updatedTableData?.[index]?.headerContent?.[idx]?.fieldValue?.amount)}` : ''
                      : (updatedTableData?.[index]?.headerContent?.[idx]?.fieldValue)
                        ? formatDisplayValueForAmount(updatedTableData?.[index]?.headerContent?.[idx]?.fieldValue)
                        : eval(row.value)
                      :
                      typeof (updatedTableData?.[index]?.headerContent?.[idx]?.fieldValue) === 'object'
                      ? updatedTableData?.[index]?.headerContent?.[idx]?.fieldValue?.amount ? `${formatDisplayValueForAmount(updatedTableData?.[index]?.headerContent?.[idx]?.fieldValue?.amount)}` : ''
                      : formatDollarAmountWithDecimals(updatedTableData?.[index]?.headerContent?.[idx]?.fieldValue)
                        ? formatDisplayValueForAmount(updatedTableData?.[index]?.headerContent?.[idx]?.fieldValue)
                        : eval(row.value)
                  }
                </td>
              ) : '')}
            </tr>
          ))}
          <tr>
            <td className="atomCoverageTableRadio" colSpan={tableHeader.length + 1} align="center">
              <div className="atomCoverageTableRadio">
                <input
                  className=""
                  type="radio"
                  checked={waivedCoverage === true ? true : false}
                  onChange={() => handleWaiveCoverageChange()}
                  id="coverageWaiveId"
                />
                <label className="ml-3" for="coverageWaiveId">Waive Coverage</label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {(showPolicyReplacementQuestions || selectedValue) &&
        coverageBeneficiaryEligibilityProduct?.isBeneficiaryEligible && (
          <Beneficiary
            dependentsList={props.dependentsList}
            relation={'employee'}
            personBuid={"person"}
            allowedBeneficiaryKind={beneficiaryConfigurationDetails?.[0] || {}}
            currentStep={currentStep}
            updateBeneficiary={checkBenificiaryFn}
            setCanContinueBeneficiaries={setCanContinueBeneficiaries}
            coverageBuid={coverageBuid}
            isBeneficiaryMandatory={coverageBeneficiaryEligibilityProduct?.isBeneficiaryMandatory}
            setUpdatedBeneficiaryList={setUpdatedBeneficiaryList}
            isBeneficiarySectionValidCallback={setBeneficiarySectionValidBoolean}
          />
        )}




      {showPolicyReplacementQuestionSection
        //&& (beneficiaryEligibility.isBeneficiaryMandatory && totalPercent == 100 || !beneficiaryEligibility.isBeneficiaryMandatory) &&
        &&
        <PolicyReplacementQuestions
          {...props}
          subscribedCoverageConfigurationResponse={subscribedCoverageConfigurationResponse}
        />
      }
      <div className='button-group'>
        <>
          <Button type="button" onClick={prevStep} className="mr-3 btn btn-outline-primary primaryOutline">
            Back
          </Button>
        </>
        <Button
          type="button"
          onClick={isChecked ? callOnNext : nextStepCall}
          disabled={disableContinue || hasErrors}
          name={isChecked ? "continuetonextproduct" : "continue"}
          className="btn btn-primary continue-btn primaryBackground coverageFlowOneCls"
        >
          {buttonLabel}
        </Button>
      </div>
    </>
  );
};

export default CoverageFlowOne;
