import React, { useState } from 'react';
import FlipIcon from '../../../../../Assets/Images/FlipIcon.svg';
import FormatHelper from '../../../../Utils/Helper/FormatHelper';
import PieChart from '../../../CustomSection/Atoms/PieChart';


import './index.css';

const formatHelper = new FormatHelper();

const FlipCard = (props) => {
    const { title, count, data } = props

    const [isFlipped, setIsFlipped] = useState(false);
    const [pieColors, setStatusColors] = useState({});

    const handleCardClick = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <div className='flicard-display-area'>
            <div className={`flip-card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardClick}>
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={FlipIcon} alt="flip-icon" className='flip-icon ' />
                        <div className='text-center heading '>{title} </div>
                        <div className='chart-data-info'>
                            {data && Object.keys(data).map(key => (
                                <div key={key}>
                                    <span className={'chart-data-indicator'} style={{ backgroundColor: pieColors[key] }}></span>
                                    <span className='chart-data'>{formatHelper.numberFormat(data[key])}</span>
                                    {/* <span className='chart-data'>{Math.round((data[key] * 100) / count)}%</span> */}
                                    <span className='chart-data'>{key}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flip-card-back">
                        <img src={FlipIcon} alt="flip-icon" className='flip-icon ' />
                        <div className='text-center heading '>{title} </div>
                        <div className=' text-center employee-count'>{formatHelper.numberFormat(count)}</div>
                        <PieChart data={data} updateColors={setStatusColors} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FlipCard;
