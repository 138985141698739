import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'registerationError',
  initialState: {
    hasError: false,
    errorCount:0,
    hideErrorMessage: false,
    hideCol: false,
    isYesradioSelected: false,
    showAlert: false,
    employeeBadRequest: false,
    employeeBadRequestObj: {},
    employeeBadRequestCounts: 0,
    policyRadioClicked : null,
    policyRadioHospitalIndemnityClicked:null,
    coverageRadioClicked : null,
    coverageRadioHospitalIndemnityClicked:null,
    //employeeBadRequestCounts: 1,
    lockContinueButton: false,
    isYesradioDisclosureSelectedPetNation:"",
    hideEmailCodeInput:true,
    hidePhonePhoneInput:false,
    showSubmitCodeBtnEmailReg: false,
    coveragePolicyRadioClicked : [],
    hospitalIndemnityRadioClicked : [],
    hideOnLoad: true,
    hidePhoneOnLoad: true,
    activateFactor: false
  },
  reducers: {
    // action => action handler
    // HAS_ERROR: (registerationError, action) => {
    //   return {
    //   ...registerationError,
    //   hasError: action.payload.hasError,
    //   // registerationError.errorCount=registerationError.errorCount + 1;
    //   errorCount: action.payload.errorCount}
    // },
    EMP_BAD_REQUEST: (registerationError, action) => {
      
      //registerationError.employeeBadRequestObj= action.payload;
     //registerationError.employeeBadRequestCounts= registerationError.employeeBadRequestCounts +1

       registerationError.employeeBadRequestCounts= action.payload
    },
    LOCK_CONTINUE_BUTTON: (registerationError, action) => {
      //registerationError.employeeBadRequest= action.payload;
     registerationError.lockContinueButton= action.payload

      // registerationError.errorCount= action.payload.errorCount
    },
    HAS_ERROR: (registerationError, action) => {
      registerationError.hasError= action.payload.hasError;
      registerationError.errorCount=registerationError.errorCount + 1;
    },
    CLOSE_ERROR: (registerationError, action) => {
      registerationError.hasError= action.payload.hasError;
      registerationError.errorCount=0;
    },
    SHOW_ALERT: (registerationError, action) => {
      registerationError.showAlert= action.payload;
    },
    HIDE_ERROR_MESSAGE: (registerationError, action) => {
      return {
     ...registerationError,
      hideErrorMessage: action.payload}
    },
    HIDE_COL: (registerationError, action) => {
      return {
        ...registerationError,
      hideCol: action.payload}
    },
    RADIO_YES_SELECTED: (registerationError, action) => {
      registerationError.isYesradioSelected= action.payload;
    },
    RADIO_DISCLOSURE_SELECTED: (registerationError, action) => {
      registerationError.isYesradioDisclosureSelected= action.payload;
    },
    RADIO_DISCLOSURE_SELECTED_IDTHEFT: (registerationError, action) => {
      registerationError.isYesradioDisclosureSelectedIdTheft= action.payload;
    },
    RADIO_DISCLOSURE_SELECTED_SUPPLEMENT_HEALTH_INSURANCE: (registerationError, action) => {
      registerationError.isYesradioDisclosureSelectedSupplementalHealthInsurance= action.payload;
    },
    RADIO_DISCLOSURE_SELECTED_TRAVEL: (registerationError, action) => {
      registerationError.isYesradioDisclosureSelectedTravel= action.payload;
    },
    RADIO_DISCLOSURE_SELECTED_CRITICAL_ILLNESS: (registerationError, action) => {
      registerationError.isYesradioDisclosureSelectedCriticalIllness= action.payload;
    },
    RADIO_DISCLOSURE_SELECTED_HOSPITAL_INDEMNITY: (registerationError, action) => {
      registerationError.isYesradioDisclosureSelectedHospitalIndemnity= action.payload;
    },
    RADIO_DISCLOSURE_SELECTED_PET_NATION: (registerationError, action) => {
      registerationError.isYesradioDisclosureSelectedPetNation= action.payload;
    },
    COVERAGE_RADIO_CLICKED : (registerationError, action) => {
      registerationError.coverageRadioClicked= action.payload;
    },
    COVERAGE_RADIO_HOSPITAL_INDEMNITY_CLICKED : (registerationError, action) => {
      registerationError.coverageRadioHospitalIndemnityClicked= action.payload;
    },
    POLICY_RADIO_CLICKED : (registerationError, action) => {
      registerationError.policyRadioClicked= action.payload;
    },
    POLICY_RADIO_CLICKED_HOSPITAL_INDEMNITY : (registerationError, action) => {
      registerationError.policyRadioHospitalIndemnityClicked= action.payload;
    },
    //mfa
    SET_EMPLOYEE_VALIDATION_ERROR: (registerationError, action) => {
      let key = action.payload.key;
      registerationError[key] = {
        hasError: action.payload.hasError,
        count: action.payload.count,
        element:  action.payload.element
      }
      
    },
   
    //mfa
    CLEAR_EMPLOYEE_VALIDATION_ERROR: (registerationError, action) => {

      let key = action.payload.key;
      registerationError[key] = {
        hasError: action.payload.hasError,
        count: action.payload.count
      }
    },
    //mfa
    SET_SEND_EMAIL_CHALLENGE_ERROR: (registerationError, action) => {
      let key = action.payload.form;
      registerationError.hideOtpAndSubmitBtn =action.payload.hideOtpAndSubmitBtn
      registerationError[key] = {
        hasError: action.payload.hasError,
        field: action.payload.field,
        form: action.payload.form,
        elementName:action.payload.form+'.'+action.payload.field,
        dependentElementsReadOnly: action.payload.dependentElementsReadOnly,
        dependentElementsInput: action.payload.dependentElementsInput,
        hideEmailCodeInput:false,
        hidePhoneCodeInput:action.payload.hideOtpAndSubmitBtn,
        hideOtpAndSubmitBtn: action.payload.hideOtpAndSubmitBtn
        
      }

    },
    //mfa
    // SET_SERVICE_UNAVAILABLE: (registerationError, action) => {
    //   registerationError.serviceUnavailable= action.payload.serviceUnavailable;
    // },
    // mfa
    SHOW_SERVICE_UNAVAILABLE_ALERT: (registerationError, action) => {
      registerationError.showServiceUnavailableAlert= action.payload;
    },
    //mfa
    SET_WYNSURE_SERVICE_UNAVAILABLE: (registerationError, action) => {
      registerationError.wynsureServiceUnavailable= action.payload.serviceUnavailable;
      registerationError.wynsureErrorText= action.payload.wynsureErrorText;

    },
    //mfa
    SET_MFA_SERVICE_UNAVAILABLE: (registerationError, action) => {
      registerationError.mfaServiceUnavailable= action.payload.serviceUnavailable;
      registerationError.mfaErrorText= action.payload.mfaErrorText;
      if(action.payload.redirectToLogin)
        registerationError.redirectToLogin= action.payload.redirectToLogin;
      else
        registerationError.redirectToLogin= false;
      if(action.payload.showInputErr)
        registerationError.showInputErr= action.payload.showInputErr;
      else
        registerationError.showInputErr= false;
      if(action.payload.oktaErrorText)
        registerationError.oktaErrorText= action.payload.oktaErrorText;
      else
        registerationError.oktaErrorText= '';
      if(action.payload.showError)
        registerationError.showError= action.payload.oktaErrorText;
      if(action.payload.oktaReachLimitText)
        registerationError.oktaReachLimitText= action.payload.oktaReachLimitText;
      else
        registerationError.oktaReachLimitText= ''
      if(action.payload.newOTPText)
        registerationError.newOTPText= action.payload.newOTPText;
       
      else
        registerationError.showError= '';
    },
    HIDE_EMAIL_CODE_INPUT: (registerationError, action) => {
      registerationError.hideEmailCodeInput= action.payload.hideEmailCodeInput;
    },
    SHOW_EMAIL_CODE_INPUT_AFTER_EDIT: (registerationError, action) => {
      registerationError.showEmailCodeInputAfterEdit= action.payload.showEmailCodeInputAfterEdit;
    },
    RESET_EMAIL_CODE_INPUT_ERR: (registerationError, action) => {
      registerationError.resetEmailCodeInputErr= action.payload.resetEmailCodeInputErr;
    },
    HIDE_PHONE_CODE_INPUT: (registerationError, action) => {
      registerationError.hidePhoneCodeInput= action.payload.hidePhoneCodeInput;
    },
    RESET_PHONE_CODE_INPUT_ERR: (registerationError, action) => {
      registerationError.resetPhoneCodeInputErr= action.payload.resetPhoneCodeInputErr;
    },
    EMAIL_IS_VERIFIED: (registerationError, action) => {
      registerationError.emailIsVerified= action.payload;
    },
    SHOW_SUBMIT_CODE_BTN_REG: (registeration, action) => {
      return {
        ...registeration,
        showSubmitCodeBtnEmailReg: action.payload.showSubmitCodeBtnEmail,
        showSubmitCodeBtnPhone: action.payload.showSubmitCodeBtnPhone,
      };
    },
    COVERAGE_POLICY_RADIO_CLICKED : (registerationError, action) => {
      let fieldName = action?.payload?.fieldName;
      let valueCode = action?.payload?.valueCode;
      if(fieldName){
        let coveragePolicyRadioClicked = registerationError.coveragePolicyRadioClicked?.filter(e => e.fieldName !== fieldName)
        registerationError.coveragePolicyRadioClicked = [...coveragePolicyRadioClicked,{valueCode:valueCode,fieldName:fieldName}]
      }
    },
    HI_POLICY_RADIO_CLICKED : (registerationError, action) => {
      let fieldName = action?.payload?.fieldName;
      let valueCode = action?.payload?.valueCode;
      if(fieldName){
        let hospitalIndemnityRadioClicked = registerationError.hospitalIndemnityRadioClicked?.filter(e => e.fieldName !== fieldName)
        registerationError.hospitalIndemnityRadioClicked = [...hospitalIndemnityRadioClicked,{valueCode:valueCode,fieldName:fieldName}]
      }
    },
    ACTIVATE_PHONE: (registerationError, action) => {
      registerationError.activateFactor= action.payload;
    },
    EMAIL_VERIFIED: (registerationError, action) => {
      registerationError.emailVerified= action.payload;
    },
    PHONE_IS_VERIFIED: (registerationError, action) => {
      registerationError.phoneIsVerified= action.payload;
    },
    SET_EMAIL_EXIST: (registeration, action) => {
      return {
        ...registeration,
        emailExist: action.payload,
      };
    },
    SET_HIDE_ON_LOAD: (registeration, action) => {
      return {
        ...registeration,
        hideOnLoad: action.payload,
      };
    },
    SET_HIDE_PHONE_ON_LOAD: (registeration, action) => {
      return {
        ...registeration,
        hidePhoneOnLoad: action.payload,
      };
    },
    SHOW_OTP_ERR: (registeration, action) => {
      return {
        ...registeration,
        showOtpErr: action.payload,
      };
    },
   HIDE_INPUT_N_SUBMIT: (registerationError, action) => {
     registerationError.wrongMobile= action.payload.hideOtpAndSubmitBtn
    
    },
    NEW_REGISTERATION_ERROR: (registerationError, action) => {
      return {
        hasError:false,
        errorCount:0,
        hideErrorMessage:false,
        hideCol:false,
        isYesradioSelected:false,
        showAlert:false,
        employeeBadRequest:false,
        hideEmailCodeInput:true,
        hidePhonePhoneInput:false,
        showSubmitCodeBtnEmailReg:false,
        hideOnLoad:true,
        hidePhoneOnLoad:true,
        activateFactor:false,
        mfaServiceUnavailable:false,
        mfaErrorText:"",
        redirectToLogin:false,
        showInputErr:false,
        oktaErrorText:"",
        showError:""
      };
    },
  },
});


export const { HAS_ERROR, HIDE_ERROR_MESSAGE, HIDE_COL, SHOW_ALERT, EMP_BAD_REQUEST, LOCK_CONTINUE_BUTTON ,
  RADIO_YES_SELECTED,RADIO_DISCLOSURE_SELECTED,COVERAGE_RADIO_CLICKED,POLICY_RADIO_CLICKED,RADIO_DISCLOSURE_SELECTED_IDTHEFT,RADIO_DISCLOSURE_SELECTED_SUPPLEMENT_HEALTH_INSURANCE,
  RADIO_DISCLOSURE_SELECTED_TRAVEL,RADIO_DISCLOSURE_SELECTED_CRITICAL_ILLNESS,COVERAGE_RADIO_HOSPITAL_INDEMNITY_CLICKED,
  RADIO_DISCLOSURE_SELECTED_HOSPITAL_INDEMNITY,POLICY_RADIO_CLICKED_HOSPITAL_INDEMNITY, RADIO_DISCLOSURE_SELECTED_PET_NATION ,CLOSE_ERROR,
  SET_EMPLOYEE_VALIDATION_ERROR,
  SET_SEND_EMAIL_CHALLENGE_ERROR,
  CLEAR_EMPLOYEE_VALIDATION_ERROR,
  SET_WYNSURE_SERVICE_UNAVAILABLE,
  SHOW_SERVICE_UNAVAILABLE_ALERT,
  SET_MFA_SERVICE_UNAVAILABLE,
  HIDE_EMAIL_CODE_INPUT,
  HIDE_PHONE_CODE_INPUT,
  RESET_EMAIL_CODE_INPUT_ERR,
  RESET_PHONE_CODE_INPUT_ERR,
  SHOW_EMAIL_CODE_INPUT_AFTER_EDIT,
  EMAIL_IS_VERIFIED,
  SHOW_SUBMIT_CODE_BTN_REG,
  COVERAGE_POLICY_RADIO_CLICKED,
  HI_POLICY_RADIO_CLICKED,
  ACTIVATE_PHONE,
  EMAIL_VERIFIED,
  PHONE_IS_VERIFIED,
  SET_EMAIL_EXIST,
  SET_HIDE_ON_LOAD,
  SET_HIDE_PHONE_ON_LOAD,
  SHOW_OTP_ERR,
  NEW_REGISTERATION_ERROR,
  HIDE_INPUT_N_SUBMIT
 } = slice.actions;

export default slice.reducer;