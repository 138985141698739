import React from 'react';

const Modal = ({
  showModal,
  handleClose,
  title,
  body,
  footerButtons,
  showHeaderLine = true,
  showFooterLine = true,
  showCloseIcon = true,
  titlePosition = 'center', // Possible values: 'flex-start', 'center', 'flex-end'
}) => {
  const titleStyle = {
    display: 'flex',
    justifyContent: titlePosition,
    width: '100%',
  };

  return (
    <>
      <div
        className={`modal fade ${showModal ? 'show' : ''}`}
        style={{ display: showModal ? 'block' : 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className={`modal-header ${showHeaderLine ? '' : 'border-0'}`}>
              <div style={titleStyle}>
                <div className="modal-title session-modal-title">{title}</div>
              </div>
              {showCloseIcon && (
                <button type="button" className="btn-close" onClick={handleClose} />
              )}
            </div>
            <div className="modal-body">{body}</div>
            <div className={`modal-footer justify-content-center ${showFooterLine ? '' : 'border-0'}`}>
              {footerButtons.map((button, index) => (
                <button key={index} type="button" className={`btn ${button.className}`} onClick={button.onClick}>
                  {button.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show" onClick={handleClose} />}
    </>
  );
};

export default Modal;
