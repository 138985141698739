import React from 'react';
import ManageProfile from '../../../Layout/ManageProfile/ManageProfile';
export default [
  
  {
    path: '/ManageProfile',
    render: (props) => <ManageProfile type="Enrollment" {...props} />,
  }
 
];
