import React, { useState, useEffect } from 'react';
import Flow1 from './Flow1';
import Flow2 from './Flow2';
import Flow3 from './Flow3';
import Flow6 from './Flow6';
import Flow4 from './Flow4';

import Flow5 from './Flow5';

import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../Atoms/Loader';
import appConfig from '../../../../appConfig';
import { nextStep as getNextStep } from '../Utils/StepFormUtils';
import { updateProcess } from '../Actions/updateProcess';
import http from '../../../../Common/Services/HttpService';
import ServiceHelper from '../../../Utils/Helper/MappingHelper';
import { onLoadAPI } from '../../../../Store/Slices/Entities/response';
import { SET_CURRENT_STEP_NO } from '../../../../Store/Slices/StepForm';
import { SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo';
import { UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment/index';
import Flow7 from './Flow7';
import { updateStepperNavigatorInfo } from '../../../Utils/Form/StepperHelper';
import { insureProductDetails, reworkProductDetails } from '../Actions/productDetails';
import { useNavigationHook } from '../Hooks/NavigationHook'; // Replace with your actual file path
import HeaderContent from '../Component/Common/HeaderContent';

const ParentComponent = (props) => {
  const dispatch = useDispatch();
  const serviceHelper = new ServiceHelper();
  const { currentStep, previousStep, formsConfig, handleNextStep, handlePreviousStep, handleGoToSpecificStep, stepKeys, skipSteps } = useNavigationHook();

  const { employee, spouse, children, showDisclosureStatement, flowType, hasMultipleCoverages } = props || {}
  const [step, setStep] = useState(1);
  const [currentBuid, setCurrentBuid] = useState("");
  const [customContent, setCustomContent] = useState({});
  const { insuredInfo, process, entities } = useSelector((storeState) => storeState);
  let currentReduxState = useSelector((storeState) => storeState);
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);

  const subscribeCoverageBuids = entities?.response?.onLoadResponseList?.[currentStep]?.subscribeCoverageBuids || [];
  const enrollmentProcessBuid = useSelector(
    (storeState) =>
      storeState?.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID
  );
  let productFamily = formsConfig.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily]

  let subscribedProductBuid = currentReduxState?.entities?.response?.onLoadResponseList?.[currentStep]?.ValidateSubscribedProductData?._Result?.contract?.subscribedElements || [];

  const RadioSelctedValue = useSelector(
    (storeState) =>
      storeState?.insuredInfo?.[currentStep]?.disClosureRadio
  );

  const currentStepNb = useSelector(
    (storeState) =>
      // storeState?.stepForm?.currentStepNb ||
      storeState?.entities?.response?.onLoadResponseList?.UpdatesubscribeCoverage?._Result?.processEntity?.currentStepNb
  );
  useEffect(() => {
    setStep(1)
  }, [productIdentifierBuid])
  // Common Combine API for Employee flow1, flow2, flow3, flow4
  const validateSubscribedElements = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements
  const enrollmentValue = currentReduxState?.enrollment?.enrollmentData?.contract?.subscribedElements || [];
  let declinedProduct = enrollmentValue?.filter(e => e?.subscribedProduct?.productCode?.buid === productIdentifierBuid);
  let isDeclinedProduct = declinedProduct?.[0]?.subscribedProduct?.status === "cDeclined" || declinedProduct?.[0]?.subscribedProduct?.status === "cTerminated" || declinedProduct?.[0]?.subscribedProduct?.status === "cNeverInForce" ? true : false
  const qleEndorsementProcessBuid = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.initNewIndividualEndorsementProcess?._Result?.processEntity?.buid
  );
  const enrollmentType = useSelector((storeState) => storeState?.employeeProfile?.enrollmentType);
  const reasonType = useSelector((storeState) => storeState?.employeeProfile?.reasonType);
  const ongoingEnrollmentType = enrollmentType === "editNewEnrollmentDuringOE"
  const ongoingReasonType = reasonType === "OE"
  const isAdmin = useSelector((storeState) => storeState?.callCenter?.isAdmin);
  const localProductWaivedArr = useSelector((storeState) => storeState?.enrollment?.cancelledCoverageBeforeConfirmationBuidArr);
  let isProductWaivedLocally = false;
  if (localProductWaivedArr?.indexOf(productIdentifierBuid) > -1) {
    isProductWaivedLocally = true;
  }

  let empID = employee?.[0]?.personBuid
  const insureProductData = async () => {
    try {
      let subscribedElementsData = validateSubscribedElements?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
      const effectiveDate = enrollmentData?.processEntity?.effectiveDate;
      const plannedTerminationDate = subscribedElementsData?.[0]?.coverages?.[0]?.subscribedOption?.plannedTerminationDate;
      const secondTermination1 = effectiveDate > plannedTerminationDate ? true : false
      let subscribedProductBuid = subscribedElementsData?.[0]?.bUID
      const flow1buids = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.insureds || [];
      let personbuids = []
      flow1buids?.forEach(pobj => {
        personbuids.push(pobj?.person?.identifier?.bUID ? pobj?.person?.identifier?.bUID : pobj?.person?.data?.bUID);
      })
      await dispatch(insureProductDetails({
        enrollmentProcessBuid: enrollmentProcessBuid,
        flowType: flowType,
        personBuid: empID,
        productBuid: productIdentifierBuid,
        personbuids: personbuids,
        isRework: isDeclinedProduct || qleEndorsementProcessBuid || isAdmin || ongoingReasonType || ongoingEnrollmentType || isProductWaivedLocally || secondTermination1 ? true : false,
        subscribedProductBuid: subscribedProductBuid,
        isPrepare: isDeclinedProduct || secondTermination1 ? true : false,
        currentStep: currentStep,
        previousStep: previousStep,
        insuredInfo: insuredInfo,
        currentStepInfo: {
          "portalStep": currentStep
        }
      }));
      return true
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const reworkProductAPI = async () => {
    try {
      let coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids?.[0];
      if (flowType === 2 || flowType === 3) {
        coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids.filter((e) => e?.coveragePersonBuid?.includes(empID))[0];
      }
      await dispatch(reworkProductDetails({
        enrollmentProcessBuid: enrollmentProcessBuid,
        flowType: flowType,
        personBuid: empID,
        prepareCoverageBuid: coverageDetailsForPrepare?.coverage,
        prepareCoveredPersonBuid: coverageDetailsForPrepare?.coveragePersonBuid,
        isRework: isDeclinedProduct ? true : false,
        subscribedProductBuid: subscribedProductBuid,
        isPrepare: isDeclinedProduct ? true : false,
        currentStep: currentStep
      }));
      return true
    } catch (error) {
      console.error('There was an error!', error);
    }
  };
  //  useEffect(() => {
  //   setCallApiOneTime(false);
  //  },[currentStep])
  useEffect(() => {
    let totalFlowType = [1, 2, 3, 4]
    if (totalFlowType?.includes(flowType)) {
      if (enrollmentProcessBuid && flowType && currentStep && empID && productIdentifierBuid) {
        const fetchData = async () => {
          await insureProductData();
        }
        fetchData();
      } else if (isDeclinedProduct && previousStep === 'EnrollmentSummary' && enrollmentProcessBuid && flowType && currentStep && empID && productIdentifierBuid) {
        const fetchReworkData = async () => {
          await reworkProductAPI();
        }
        fetchReworkData();
      }
    }

  }, [enrollmentProcessBuid && flowType && empID && productIdentifierBuid, currentStep]);

  // Common API end


  useEffect(() => {
    import(`../Content/${currentStep}.json`)
      .then(module => setCustomContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentStep]);


  const validateSubscribedProductData = async () => {
    // if (currentStep === currentStep) {
    //   return await http
    //     .post(appConfig.SSP_MID_URL + '/validateSubscribedProductData', {
    //       payload: {
    //         enrollmentProcessBuid: enrollmentProcessBuid,
    //         subscribedProductBuid: subscribedProductBuid,
    //         productIdentifierBuid: productIdentifierBuid
    //       }
    //     })
    //     .then((resp) => {
    //       if (resp.data && resp.status === 200) {
    //         dispatch(onLoadAPI({ ...{ [`validateSubscribedProductDataCI`]: resp.data } }));
    //       }
    //     }).catch((error) => {
    //       
    //     })
    // }
    return true;
  }

  const declineProduct = async () => {

    return await http
      .post(appConfig.SSP_MID_URL + '/declineProduct', {
        payload: {
          enrollmentProcessBuid: enrollmentProcessBuid,
          subscribedProductBuid: subscribedProductBuid,
          productIdentifierBuid: productIdentifierBuid,
          currentStepInfo: {
            "portalStep": currentStep
          }
        }
      })
      .then((resp) => {
        if (resp.data && resp.status === 200) {
          dispatch(onLoadAPI({ ...{ [`declineProduct`]: resp.data } }));


          const processInfo = resp?.data?._Result ?? {};
          dispatch(UPDATE_ENROLLMENT_DATA(processInfo));


        } else {
          dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: resp?.data?.error?.code || '520',
            id: 'declineProduct',
            show: true,
            page: currentStep,
          }));
        }
      }).catch((error) => {
        dispatch(SET_ALERT_MESSAGE({
          type: 'error',
          errorCode: '520',
          id: 'declineProductApi',
          show: true,
          page: currentStep,
        }));
        
      })

  }

  const nextStep = () => {
    updateSideNavigation();
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };


  const updateProcessInformation = async () => {
    let currentStepInfo = {
      "portalStep": currentStep
    };
    try {
      // Here, you're waiting for the dispatch function to complete before moving on
      await dispatch(
        updateProcess(
          {

            currentStepInfo,
          }
        )
      );
    } catch (error) {
      console.error(error);
      // handle the error here
    }
  };

  const handleParentNext = async (data = false) => {
    
    let currentStepInfo = {
      "portalStep": currentStep,
      "reachedPreConfirmation": "no"
    };

    //if (nextStepGoingTobe !== 'EnrollmentSummary') {
    dispatch(UPDATE_LOADING(true));
    try {
      let respObj = await serviceHelper.sendApiRequest('/moveNext', {
        externalStepContext: currentStepInfo,
        enrollmentProcessBuid: enrollmentProcessBuid,
        currentStepNb: data ? data : (enrollmentData?.processEntity?.currentStepNb) ? enrollmentData?.processEntity?.currentStepNb : "",
      });
      if (respObj.data && respObj.status === 200) {
        dispatch(onLoadAPI({ moveNext: respObj.data }));
        dispatch(SET_CURRENT_STEP_NO(""));
        const processInfo = respObj?.data?._Result ?? {};
        dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
      }
      updateSideNavigation();
      if (previousStep === 'EnrollmentSummary') {
        handleGoToSpecificStep(previousStep);
      } else {
        const isSummary = getNextStep(currentStep, stepKeys, skipSteps) === 'EnrollmentSummary';
        if (!isSummary && currentStep !== 'PetAssureNation') {
          setStep(1);
        }
        handleNextStep();
      }
    }

    catch (e) {
      console.error(e);
      dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: '520',
        continue: true,
        id: 'moveNext',
        show: true,
        page: currentStep,
      }));
    } finally {
      dispatch(UPDATE_LOADING(false));
    }
  };

  const updateSideNavigation = () => {
    if (currentStep == "PetAssureNation") {
      updateStepperNavigatorInfo(currentStep, dispatch, insuredInfo[currentStep])
    } else {
      updateStepperNavigatorInfo('ProductDetailsSelection', dispatch, { insuredInfo, stepKey: currentStep, subscribeCoverageBuids, hasMultipleCoverages })
    }
  }

  const handleParentBack = () => {
    if (previousStep === 'EnrollmentSummary') {
      handleGoToSpecificStep(previousStep);
    } else {
      handlePreviousStep();
    }
  };
  const renderFlow = () => {
    switch (flowType) {
      case 1:
        return <Flow1
          {...props}
          step={step}
          nextStep={nextStep}
          prevStep={prevStep}
          onNext={handleParentNext}
          onBack={handleParentBack}
          employee={employee}
          spouse={spouse}
          children={children}
          showDisclosureStatement={showDisclosureStatement}
          validateSubscribedProductData={validateSubscribedProductData}
          declineProduct={declineProduct}
          productFamily={productFamily}
          productIdentifierBuid={productIdentifierBuid}
          RadioSelctedValue={RadioSelctedValue}
          currentBuid={setCurrentBuid}
          customContent={customContent}
          currentStep={currentStep} />;
      case 2:
        return (
          <Flow2
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            employee={employee}
            spouse={spouse}
            children={children}
            showDisclosureStatement={showDisclosureStatement}
            validateSubscribedProductData={validateSubscribedProductData}
            declineProduct={declineProduct}
            onNext={handleParentNext}
            onBack={handleParentBack}
            RadioSelctedValue={RadioSelctedValue}
            currentBuid={setCurrentBuid}
            customContent={customContent}

            {...props}
            currentStep={currentStep}
          />
        );
      case 3:
        return (
          <Flow3
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            employee={employee}
            spouse={spouse}
            children={children}
            onNext={handleParentNext}
            onBack={handleParentBack}
            customContent={customContent}
            showDisclosureStatement={showDisclosureStatement}
            validateSubscribedProductData={validateSubscribedProductData}
            declineProduct={declineProduct}

            RadioSelctedValue={RadioSelctedValue}
            currentBuid={setCurrentBuid}

            {...props}
            currentStep={currentStep}
          />
        );
      case 4:
        return (
          <Flow4
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            employee={employee}
            spouse={spouse}
            children={children}
            showDisclosureStatement={showDisclosureStatement}
            validateSubscribedProductData={validateSubscribedProductData}
            declineProduct={declineProduct}
            onNext={handleParentNext}
            onBack={handleParentBack}
            RadioSelctedValue={RadioSelctedValue}
            currentBuid={setCurrentBuid}
            customContent={customContent}

            {...props}
            currentStep={currentStep}
          />
        );
      case 5:
        return (
          <Flow5
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            employee={employee}
            spouse={spouse}
            children={children}
            onNext={handleParentNext}
            onBack={handleParentBack}
            customContent={customContent}
            showDisclosureStatement={showDisclosureStatement}
            validateSubscribedProductData={validateSubscribedProductData}
            declineProduct={declineProduct}
            RadioSelctedValue={RadioSelctedValue}
            currentBuid={setCurrentBuid}
            {...props}
            currentStep={currentStep}
          />
        );
      // ratan changes for flow 7
      case 7:
        return (
          <Flow7
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            employee={employee}
            spouse={spouse}
            children={children}
            onNext={handleParentNext}
            onBack={handleParentBack}
            customContent={customContent}
            showDisclosureStatement={showDisclosureStatement}
            validateSubscribedProductData={validateSubscribedProductData}
            declineProduct={declineProduct}
            RadioSelctedValue={RadioSelctedValue}
            currentBuid={setCurrentBuid}
            {...props}
            currentStep={currentStep}
          />
        );

      case 6:
        return (<Flow6 step={step}
          nextStep={nextStep}
          prevStep={prevStep}
          onNext={handleParentNext}
          onBack={handleParentBack}
          employee={employee}
          spouse={spouse}
          children={children}
          showDisclosureStatement={showDisclosureStatement}
          validateSubscribedProductData={validateSubscribedProductData}
          productFamily={productFamily}
          productIdentifierBuid={productIdentifierBuid}
          RadioSelctedValue={RadioSelctedValue}
          currentBuid={setCurrentBuid}
          declineProduct={declineProduct}
          customContent={customContent}

          {...props}

          currentStep={currentStep} />);
      default:
        return <div>Invalid flow type</div>;
    }
  };

  const resources = useSelector((storeState) => storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result);

  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  return (
    <>
      {
        isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />
      }
      <HeaderContent resources={resources} />
      <div className={`${flowType === 4 ? 'excessLiability criticalIllness' : 'criticalIllness'}`}>{renderFlow()}</div>
    </>
  );
};

export default ParentComponent;
