import dataService from '../../../Common/Services/DataService';
import _ from 'lodash';
import configureStore from '../../../Store/configureStore';
import { param } from 'jquery';

  const getStorestate = () => {
   
  };
let APIRequest = async (apiCalls,  ...args) => {
  let currentArguments = Array.prototype.slice.call(args);
  let changedArguments = {};
  currentArguments.map((curArgument) => {
    changedArguments = { ...changedArguments, ...curArgument };
  });

  var promise = new Promise(function (resolve, reject) {
    const anAsyncFunction = async (item) => {
      return await dataService[item.apiconfig.APIfn](changedArguments, '',item);
    };
    const getData = async () => {
      return await Promise.all(apiCalls.map((item) => anAsyncFunction(item)));
    };
    getData()
      .then((data) => {
        const nameToResult = {};
        for (let i = 0; i < data.length; i++) {
          const name = apiCalls[i].apiconfig.objectName;
          nameToResult[name] = data[i];
        }
        resolve(nameToResult);
      })
      .catch(() => {
        resolve(null);
      });
  });

  return promise;
};

export let APIRequestMFA = async (apiCalls,  ...args) => {
  let currentArguments = Array.prototype.slice.call(args);
  let changedArguments = {};
  currentArguments.map((curArgument) => {
    changedArguments = { ...changedArguments, ...curArgument };
  });

  var promise = new Promise(function (resolve, reject) {
    const anAsyncFunction = async (item) => {
      return await dataService[item.apiconfig.APIfn](changedArguments, '',item);
    };
    const getData = async () => {
      return await Promise.all(apiCalls.map((item) => anAsyncFunction(item)));
    };
    getData()
      .then((data) => {
        const nameToResult = {};
        for (let i = 0; i < data.length; i++) {
          const name = apiCalls[i].apiconfig.objectName;
          nameToResult[name] = data[i];
        }
        resolve(nameToResult);
      })
      .catch(() => {
        resolve(null);
      });
  });

  return promise;
};
export let APIRequestSeqLoad = async (apiCalls, ...args) => {
  let currentArguments = Array.prototype.slice.call(args);
  let changedArguments = {};
  currentArguments.map((curArgument) => {
    changedArguments = { ...changedArguments, ...curArgument };
  });

let localState ={};
  var promise = new Promise(function (resolve, reject) {
    const anAsyncFunction = async (item) => {
      return await dataService[item.apiconfig.APIfn](changedArguments, '', item,localState);
    };
    const getData = async () => {
      return await Promise.all(apiCalls.map((item) => anAsyncFunction(item)));
    };
    getData()
      .then((data) => {
        
        const nameToResult = {};
        for (let i = 0; i < data.length; i++) {
          const name = apiCalls[i].apiconfig.objectName;
          nameToResult[name] = data[i];
        }
       
        resolve(nameToResult);
      })
      .catch(() => {
        resolve(null);
      });
  });

  return promise;
};


export let APIRequestSeq = async (apiCalls, ...args) => {
  /**
   * apiCalls  : list of api call information
   * args : list of arguments to be passed for api calls
   */
  let currentArguments = Array.prototype.slice.call(args);
  let changedArguments = {};
  currentArguments.map((curArgument) => {
    changedArguments = { ...changedArguments, ...curArgument };
  });
  var promise =  new Promise(function (resolve, reject) {
    let fetchAPI = async (apiCal, result) => {
      try {
        if (apiCal.getDataFrom && apiCal.getDataFrom.length > 0) {
          let getCurDataFrom = {};
          apiCal.getDataFrom.forEach((ele) => {
            getCurDataFrom = { ...getCurDataFrom, ...{ [ele.name]: _.get(result, ele.path, '') } };
          });

          changedArguments = { ...changedArguments, ...getCurDataFrom };
        }

        const anAsyncFunction = async (apiCal) => {
          return await dataService[apiCal.APIfn](changedArguments, '', apiCal);
        };

        let data =  await anAsyncFunction(apiCal);

        return data;
      } catch (error) {
       
      }
    };
    let getAPISync = async () => {
      let result = {};
      for (const apiCal of apiCalls) {
        const curAPIResult = await fetchAPI(apiCal, result);   
        result = { ...result, ...{ [apiCal.objectName]: curAPIResult } };
      }
      resolve(result);
    };
    getAPISync();
  });

  return promise;
};

export default APIRequest;
