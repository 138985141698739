import http from '../../../../Common/Services/HttpService';
import appConfig from '../../../../appConfig';
import { UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment';
import { UPDATE_LOADING, UPDATE_ERROR_MESSAGE, UPDATE_SUCCESS_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { get } from 'lodash';
import { SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';

const processResponse = (response, dispatch) => {
    const data = get(response, 'data.data.deleteInsured', {});
    dispatch(UPDATE_ENROLLMENT_DATA(data));
    dispatch(UPDATE_ERROR_MESSAGE({ message: null, currentStep: "DependentInformation" }));
    dispatch(UPDATE_SUCCESS_MESSAGE({ message: "Dependent information has been successfully deleted.", currentStep: "DependentInformation" }));
};

const processError = (dispatch) => {
    dispatch(UPDATE_ERROR_MESSAGE({ message: "Failed to Delete the Record.", currentStep: "DependentInformation" }));
    dispatch(UPDATE_SUCCESS_MESSAGE({ message: null, currentStep: "DependentInformation" }));
};

export const deleteInsured = (enrollmentProcessBuid, dependentBuid, currentStep='') => async (dispatch) => {
    if (!dependentBuid || !enrollmentProcessBuid) {
        throw new Error('Invalid argument. One or more arguments are null or undefined.');
    }

    dispatch(UPDATE_LOADING(true));

    try {
        const response = await http.post(`${appConfig.SSP_MID_URL}/v1/dependent-information-delete`, { enrollmentProcessBuid, dependentBuid });
        if (response?.data?.data?.deleteInsured) {
            processResponse(response, dispatch);
        } else {
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: response?.status || '520',
                id: 'deleteInsured',
                show: false,
                page: currentStep
            }));
        }
    } catch (error) {
        processError(dispatch);
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            id: 'deleteInsured',
            show: false,
            page: currentStep
        }));
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
};
