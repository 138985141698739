import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'buyUp',
    initialState: {
        eligibility: {
            employee: false,
            spouse: false
        },
        planBuid: '',
        existingCoverageBuid: {
            employee: [],
            spouse: []
        },
        coverageBuid: {
            employee: '',
            spouse: ''
        },
        selectedBuyUpPlanIndex: {
            employee: null,
            spouse: null
        },
        selectedBasePlanDetails: {
            employee: [],
            spouse: []
        },
        requiredChecksPassed: {
            beneficiarySection: false,
            policyReplacementQuestions: false
        },
        basePlanBoughtCurrentYearFlag: {
            employee: true,
            spouse: true
        }, 
        buyupBoughtCurrentYearFlag: {
            employee: false,
            spouse: false
        },
        annualCost: [],
        payPerPeriodCost: [],
        costBasket: {
            employee: 0,
            spouse: 0
        },
        planStatus: {
            employee: [],
            spouse: []
        },
        basePlanDeclinedLocally: {
            employee: false,
            spouse: false
        }
    },
    reducers: {
        SET_ELIGIBILITY: (state, action) => {
            state.eligibility = action.payload
        },
        SET_PLAN_BUID: (state, action) => {
            state.planBuid = action.payload.planBuid
        },
        SET_EXISTING_COVERAGE_BUID: (state, action) => {
            state.existingCoverageBuid = action.payload
        },
        SET_COVERAGE_BUID: (state, action) => {
            state.coverageBuid = action.payload
        },
        SET_SELECTED_BASE_PLAN_DETAILS: (state,action) => {
            state.selectedBasePlanDetails = action.payload
        },
        SET_SELECTED_BUY_UP_PLAN_INDEX: (state,action) => {
            state.selectedBuyUpPlanIndex = action.payload
        },
        SET_REQUIRED_CHECKS_PASSED_BOOLEAN: (state, action) => {
            state.requiredChecksPassed = action.payload
        },
        SET_BASE_PLAN_BOUGHT_IN_CURRENT_YEAR_FLAG: (state, action) => {
            state.basePlanBoughtCurrentYearFlag = action.payload
        },
        SET_BUYUP_BOUGHT_IN_CURRENT_YEAR_FLAG: (state, action) => {
            state.buyupBoughtCurrentYearFlag = action.payload
        },
        SET_COST_PAY_PER_PERIOD: (state, action) => {
            state.payPerPeriodCost = action.payload.data
        },
        SET_COST_ANNUAL: (state, action) => {
            state.annualCost = action.payload.data
        },
        SET_COST_BASKET_DATA: (state, action) => {
            state.costBasket = action.payload.data
        },
        SET_PLAN_STATUS_EMPLOYEE: (state, action) => {
            state.planStatus.employee = action.payload.data
        },
        SET_PLAN_STATUS_SPOUSE: (state, action) => {
            state.planStatus.spouse = action.payload.data
        },
        SET_BASE_PLAN_DECLINED_LOCALLY_EMPLOYEE: (state, action) => {
            state.basePlanDeclinedLocally.employee = action.payload
        },
        SET_BASE_PLAN_DECLINED_LOCALLY_SPOUSE: (state, action) => {
            state.basePlanDeclinedLocally.spouse = action.payload
        }
    }
});

export const {
    SET_ELIGIBILITY,
    SET_PLAN_BUID,
    SET_EXISTING_COVERAGE_BUID,
    SET_COVERAGE_BUID,
    SET_SELECTED_BUY_UP_PLAN_INDEX,
    SET_SELECTED_BASE_PLAN_DETAILS,
    SET_REQUIRED_CHECKS_PASSED_BOOLEAN,
    SET_BASE_PLAN_BOUGHT_IN_CURRENT_YEAR_FLAG,
    SET_BUYUP_BOUGHT_IN_CURRENT_YEAR_FLAG,
    SET_COST_PAY_PER_PERIOD,
    SET_COST_ANNUAL,
    SET_COST_BASKET_DATA,
    SET_PLAN_STATUS_EMPLOYEE,
    SET_PLAN_STATUS_SPOUSE,
    SET_BASE_PLAN_DECLINED_LOCALLY_EMPLOYEE,
    SET_BASE_PLAN_DECLINED_LOCALLY_SPOUSE
} = slice.actions;

export default slice.reducer;