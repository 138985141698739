// useInactivityDetection.js
import { useState, useEffect, useRef } from 'react';
import {  useSelector } from 'react-redux';
import { logoutUser } from '../../../../Store/Slices/Auth/userAuth';
import { logoutUserEntity } from '../../../../Store/Slices/Auth/loggedUserEntity';
import { APIRequestParallel, logoutUserResponse, onLoadAPI, } from '../../../../Store/Slices/Entities/response';
import { RESET_PASSWORD, RETRIEVE_USER } from '../../../../Store/Slices/ForgotUser';
import http from '../../../../Common/Services/HttpService/index';
import { RESET_REGISTERATION } from '../../../../Store/Slices/Registeration';
import appConfig from "../../../../appConfig"
const useInactivityDetection = (user_name, ur, dispatch) => {
  const [showModal, setShowModal] = useState(false);
  const { processType = '', callCenterAdmin = false, configuratorSSOLogin=false, userType='' } = useSelector((storeState) => storeState.auth.userAuth);
  const inactiveTimer = useRef(null);

  const startTimer = () => {
    if (inactiveTimer.current) {
      clearTimeout(inactiveTimer.current);
    }
    inactiveTimer.current = setTimeout(() => {
      sessionLogout();
    }, 60 * 5 * 1000); // adjust the time according to your requirements
  };

  const handleActivity = () => {
    if (user_name) {
      startTimer();
    }
  };

  const handleYesClick = () => {
    startTimer();
    setShowModal(false);
  };

  const getBasicAuthHeaderValue = () => {
    let authValue = process.env.REACT_APP_MFA_AUTH_USER;
    
    // Encoding into base64 using btoa
    let basicAuthHeaderValue = authValue ? btoa(authValue) : '';
    
    return basicAuthHeaderValue;
};


  const logoutSSOUser = () => {
    let basicAuthorizationValue = getBasicAuthHeaderValue();
    http.get(appConfig.SSP_AUTH_URL + '/stateful/client/ppa/logoutTest', {
      withCredentials: true,
      headers: {
        'Authorization': `Basic ${basicAuthorizationValue}`,
        'X-REQUEST-ID': Math.random(200)
      }
    })
      .then((response) => {
        let urlFromResponse = response.data?.redirectionUrl;
        urlFromResponse && window.open(urlFromResponse, '_self');
      })
      .catch((error) =>
        {}
      )
  }

  const logoutReleaseSession = () => {
    http.get(appConfig.SSP_MID_URL + '/releaseSession')
      .then((response) => {
        http.setHeaderAPICookies('');
        return response;
      })
      .catch((error) => {
        return error;
      })
  }

  const sessionLogout = () => {
    dispatch(logoutUser());
    dispatch(logoutUserEntity());
    dispatch(logoutUserResponse());
    dispatch(RETRIEVE_USER(false));
    dispatch(RESET_PASSWORD(false));
    dispatch(RESET_REGISTERATION());

    http.setUserType('');
    http.setHeaderValue('REMOTE_USER','');
    setShowModal(false);
    logoutReleaseSession()
    window.location = '/session-expired';
  }

  const handleNoClick = () => {
    logoutReleaseSession()
    if(processType === 'eelect' || callCenterAdmin || (userType ==="Configurator" && configuratorSSOLogin))  logoutSSOUser();
    if(processType === 'non-sso') window.location = ur ? `/enrollment${decodeURIComponent(ur)}` : '/enrollment';
    dispatch(logoutUser());
    dispatch(logoutUserEntity());
    dispatch(logoutUserResponse());
    dispatch(RETRIEVE_USER(false));
    dispatch(RESET_PASSWORD(false));
    dispatch(RESET_REGISTERATION());

    http.setUserType('');
    http.setHeaderValue('REMOTE_USER','');
    http.setHeaderAPICookies('');
    setShowModal(false);
  };

  useEffect(() => {
    if (user_name) {
      startTimer();
      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('keydown', handleActivity);
      window.addEventListener('touchmove', handleActivity);
    }

    return () => {
      clearTimeout(inactiveTimer.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('touchmove', handleActivity);
    };
  }, [user_name]);

  return { showModal, handleYesClick, handleNoClick };
};

export default useInactivityDetection;
