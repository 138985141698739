import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import configureStore from './Store/configureStore';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';
import App from './App/App';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap.bundle';
import { BrowserRouter } from 'react-router-dom';

// import './Assets/Fonts/MMC Display/MMCDisplay_Bd.ttf';
// import './Assets/Fonts/MMC Display/MMCDisplay_Rg.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-Black.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-BoldItalic.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-ExtraBold.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-ExtraBoldItalic.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-ExtraLight.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-ExtraLightItalic.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-Italic.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-Light.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-LightItalic.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-Medium.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-MediumItalic.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-Regular.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-SemiBold.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-SemiBold.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-Thin.ttf';
// import './Assets/Fonts/Noto Sans/NotoSans-ThinItalic.ttf';



export const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}  key={window.location.pathname} >
          <App />
        </BrowserRouter>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
