import ServiceHelper from '../../../Utils/Helper/MappingHelper';
import { SUBSCRIBE_COVERAGE_BUIDS, SUBSCRIBE_COVERAGE_BUYUP_BUIDS, UPDATE_LOADING, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { UPDATE_COVERAGE_INFO } from '../../../../Store/Slices/Entities/response';

const serviceHelper = new ServiceHelper();

export const getSubscribedCoverageConfigurationNew = (currentStep, enrollmentProcessBuid, coverageBuid, personBuid) => async dispatch => {
    if (!dispatch || !currentStep || !enrollmentProcessBuid || !coverageBuid || !personBuid) {
        console.error("getSubscribedCoverageConfigurationNew called with missing parameters");
        return;
    }
    dispatch(UPDATE_LOADING(true));
    try {
    let resp = await serviceHelper.sendApiRequest('/getSubscribedCoverageConfigurationNew', {
        enrollmentProcessBuid: enrollmentProcessBuid,
        coverageBuid: coverageBuid
    });    
    if (resp && resp.data && resp.status === 200) {
        dispatch(SUBSCRIBE_COVERAGE_BUIDS({
            coverageBuid: coverageBuid,
            personBuid: personBuid,
            product: currentStep,
        }));
        
        dispatch(
            UPDATE_COVERAGE_INFO({
                coverageBuid: coverageBuid,
                personBuid: personBuid,
                product: currentStep,
                subscribedCoverageConfiguration: resp.data
            })
        );
    } else {
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: resp?.status || '520',
            id: 'getSubscribedCoverageConfigurationNew',
            show: false,
            page: currentStep,
          }));
        console.error("getSubscribedCoverageConfigurationNew received unexpected response", resp);
    }}
    catch(e){
        console.error(e);
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}
