import React from 'react';
import { Icon, Select } from '../../Atoms';
import { UPDATE_LOADING } from '../../../Store/Slices/InsuredInfo';

const EmployeeRecordPagination = (props) => {
    const {
        dispatch,
        paginatedEmployee,
        pageSize,
        setPageSize,
        options,
        countText,
        currentPage,
        pageCount,
        pagination,
        setPaginatedEmployee,
        setCurrentPage
    } = props
    return (
        <>
            {paginatedEmployee?.length > 0 && (
                <div>

                    <div className='row pb-2 '>
                        <div className='col '>
                            <h4>Employee Records</h4>
                        </div>
                    </div>
                    <div className='row ' >
                        <div className='col-auto ' >
                            <Select
                                label=""
                                defaultValue={pageSize}
                                onChange={(e) => {
                                    dispatch(UPDATE_LOADING(true));
                                    setPageSize(e.target.value);
                                    setPaginatedEmployee(_(employees).slice(0).take(e.target.value).value());
                                    setSelectedNumber(e.target.value)
                                    setCurrentPage(1)
                                    dispatch(UPDATE_LOADING(false));
                                }}
                                options={options}
                                disabled={true}
                            />
                        </div>
                        <div className='col-auto pb-2 ' style={{ alignSelf: 'center' }} >
                            <span style={{ fontFamily: 'NotoSansRegular', color: '#2C6EF2' }}>{countText}</span>
                        </div>
                        <div className='col '  >
                            <div>
                                <ul className="pagination pt-1 my-0">
                                    <li class={currentPage === 1 ? "page-item disabled" : "page-item "}>
                                        <p
                                            className='page-link'
                                            onClick={() => { pagination(1) }}
                                        >
                                            FIRST
                                        </p>
                                    </li>
                                    <li className={currentPage === 1 ? "page-item disabled" : "page-item "} >
                                        <p
                                            className="page-link"
                                            aria-label="Previous"
                                            onClick={() => {
                                                if (currentPage !== 1) { pagination(currentPage - 1) }
                                            }}>
                                            <span>
                                                <Icon icon={['fas', 'chevron-left']} style={currentPage === 1 ? { color: "#a5a7ac" } : { color: "#202020" }} />
                                            </span>
                                            <span className="sr-only">Previous</span>
                                        </p>
                                    </li>
                                    <li className={currentPage === pageCount ? "page-item disabled" : "page-item "}>
                                        <p
                                            className="page-link"
                                            aria-label="Next"
                                            onClick={() => {
                                                if (currentPage !== pageCount) { pagination(currentPage + 1) }
                                            }}>
                                            <span>
                                                <Icon icon={['fas', 'chevron-right']} style={currentPage === pageCount ? { color: "#a5a7ac" } : { color: "#202020" }} />
                                            </span>
                                            <span className="sr-only">Next</span>
                                        </p>
                                    </li>
                                    <li className={currentPage === pageCount ? "page-item disabled" : "page-item "}>
                                        <p
                                            className='page-link'
                                            onClick={() => { pagination(pageCount) }}
                                        >
                                            LAST
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}
export default EmployeeRecordPagination;