import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'loader',
  initialState: {
    loader: false,
    contentLoader: false,
  },
  reducers: {
    SHOW_LOADER: (loader, action) => {
      return { ...loader, loader: action.payload };
    },
    SHOW_CONTENT_LOADER: (contentLoader, action) => {
      return { ...contentLoader, contentLoader: action.payload };
    },
  },
});

export const { SHOW_LOADER, SHOW_CONTENT_LOADER } = slice.actions;

export default slice.reducer;
