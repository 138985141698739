export const reduxConstants = {
  GetLoggedUserLegalEntity: {
    apiPath: 'store.auth.loggedUserEntity',
    uiPath: 'store.auth.loggedUserEntity.data',
    entryPath: 'data',
  },
  GetGbpForLegalEntity: {
    apiPath: 'storeState.entities.response.onLogin.gbpForLegalEntity',
    uiPath: 'storeState.entities.response.onLogin.gbpForLegalEntity?._Result',
    entryPath: '_Result',
  },

  GetAdditionalValues: {
    apiPath: 'store.entities.response.onLoadResponseList.employments',
    uiPath: 'store.entities.response.onLoadResponseList.employments._Result',
    entryPath: '_Result',
  },

 
};
