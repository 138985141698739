import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import $ from 'jquery';
import {
  CLEAR_ALERT_MESSAGES,
  SET_ALERT_MESSAGE,
  UPDATE_ALERT_MESSAGE,
} from '../../../Store/Slices/InsuredInfo';
import iconError from '../../../Assets/Images/iconError.png'; 
import iconSuccess from '../../../Assets/Images/icon_success.png'; 
import iconWarning from '../../../Assets/Images/icon-warning.png'; 
import iconImportant from '../../../Assets/Images/icon-important.png'; 

import { useHistory } from 'react-router-dom';

const getType = (type) => {
  let alertClass = '';
  let Icon;
  switch (type) {
    case 'error':
      alertClass = `alert-danger`;
      Icon = iconError;
      break;
    case 'success':
      alertClass = `alert-success`;
      Icon = iconSuccess;
      break;
    case 'info':
      alertClass = `alert-warning`;
      Icon = iconWarning;
    break;
    default:
      alertClass = `alert-primary`;
      Icon = iconImportant;
      break;
  }
  return {
    alertClass,
    Icon,
  };
};

const getErrorCode = (errorCode) => {
  let newMessage = '';
  switch (errorCode) {
    case '400':
      newMessage = `Oops something went wrong please enter your pet details again.`;
    break;
  
    default:
      newMessage = `Oops something went wrong please enter your pet details again.`;
    break;
  }
  return newMessage;
};

const MessageCustomComponent = (props) => {
  const{
    config = [],
    currentPage = ''
  } = props;

  const dispatch = useDispatch();

  const history = useHistory();

  const { alertMessages } = useSelector((storeState) => storeState.insuredInfo);
  const [alerts, setAlerts] = useState([]);



  const  getUniqueObjectsByKey = (arr, key) => {
    const seen = new Set();
    return arr.filter((item) => {
      const itemKey = item[key];
      if (!seen.has(itemKey)) {
        seen.add(itemKey);
        return true;
      }
      return false;
    });
  }



  useEffect(() => {
    if ( alertMessages?.length > 0 ) {
      const newAlertsArray = [];
      let filteredMessages = alertMessages.filter((pobj) => {
        return pobj.page === currentPage;
      });
      filteredMessages = getUniqueObjectsByKey(filteredMessages, 'id');
      console.log("filteredMessages",filteredMessages)
      filteredMessages.forEach(conf => {
        let newAlertData = {};
        let newMessage = '';
        let alertClass = '';
        let Icon;
        const newMessageArray = [];
        // Change to Manage multiple messages in 1 bannner
        if (conf?.data?.length > 0) {
          conf?.data?.forEach(message => {
            let errorMessage = '';
            if ( message?.errorCode ) {
              errorMessage = getErrorCode(message?.errorCode);
            } else if ( message?.message ) {
              errorMessage = message?.message || '';
            }
            newMessageArray.push(errorMessage);
          });
        } else {
          if ( conf?.errorCode) {
            newMessage = getErrorCode(conf?.errorCode);
          } else {
            newMessage = conf?.message || '';
          }
        }

        newAlertData = getType(conf?.type);
        alertClass = newAlertData?.alertClass;
        Icon = newAlertData?.Icon;

        newAlertsArray.push({
          message: newMessage,
          arrayMessages: newMessageArray,
          alertClass,
          Icon,
          show: conf?.show,
          id: conf?.id,
        });
      });

      setAlerts(newAlertsArray);
    } else if(alerts?.length > 0){
      setAlerts([]);
    }
  }, [alertMessages, currentPage]);

    return (
      <div style={{maxHeight: '120px', overflow: 'auto'}} className={currentPage}>
      


{alerts && currentPage == 'PetAssureNation'  && alerts.map(alert => {
          return alert?.show && (
            <div className={`col-9 mt-2 mb-2 mx-auto alert alert-dismissible fade show ${alert?.alertClass}`} role="alert" id={alert?.id}>  
              <img
                src={alert?.Icon}
                className='img-fluid pr-2 ' 
                id="alert-img"
                style={{width: '26px'}}
              />
              { alert?.arrayMessages?.length > 0 && (
                alert?.arrayMessages.map((message, index) => {
                  return (
                    <span className={`pr-3 ${index !== 0 ? 'd-block pl-4' : ''}`}>{message}</span>
                  )
                })
              )}
              { alert?.arrayMessages?.length === 0 && (
                <span className= "pr-3 ">{alert?.message}</span>
              )}
              <button
                type="button"   
                className="close mt-1 " 
                onClick={()=> {
                  $(`#${alert?.id}`).hide();
                  dispatch(UPDATE_ALERT_MESSAGE(alert?.id));
                  dispatch(CLEAR_ALERT_MESSAGES([]));
                  if(props?.setShowLoader){
                    props.setShowLoader(null);
                  }
                  setTimeout(()=>{
                 
                    if(currentPage == 'PetAssureNation') {
                    history.push('/enrollment/employeeProfile');
                    } else{
                      if(props?.setShowLoader){
                        props.setShowLoader(false);
                      }
                    }
                    },500);                  
                }}
                style={{color: "#002C77", fontSize: "0.875rem", fontFamily: "NotoSansMedium"}}>CLOSE
              </button>           
            </div>
          );
        })}
      
       
      </div>
    )
  };

MessageCustomComponent.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  hidden: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  show: PropTypes.bool,
};
MessageCustomComponent.defaultProps = {
  className: '',
  value: '',
  type: '',
  message: '',
  show: false,
};

export default MessageCustomComponent;
