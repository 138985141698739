import React from 'react';
import { Button } from '../../../Atoms';
import Modal from '../../../Molecules/Modal/index';
import { ElementsCard, Image, Linker } from '../../../Atoms';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_PASSWORD, RETRIEVE_USER } from '../../../../Store/Slices/ForgotUser';
import {
  HIDE_VERIFY_BUTTON,
  HIDE_VERIFY_EBUTTON,
  HIDE_VERIFY_EMAIL_BUTTON,
  RESET_REGISTERATION,
} from '../../../../Store/Slices/Registeration';
import { useHistory } from 'react-router-dom';
import { CLEAR_CHANGE_MFA_SESSION, SEND_CODE_MOBILE_CLICKED, SHOW_SPECIAL } from '../../../../Store/Slices/CallCenter';

function SuccessMessagePage(props) {
  const dispatch = useDispatch();

  let {
    submissionTextBefore,
    submissionTextAfter,
    confirmationMessage,
    confirmationMessageLine2,
    formHeader,
    formReference = [],
    confirmationImage = {},
    redirectURL,
    redirectMessage,
    firstName,
    lastName,
    name,
    triBox,
    selectedTheme,
    tipTitle,
    tipMessage,
    tipImage = {},
    mappingObject = {},
    formReferenceLink,
    confirmationMessageEmail ='',
    forMFA=false
  } = props || {};
  const history = useHistory()
  const { email='',oldEmail='' ,url='' } = useSelector((storeState) => storeState.registeration);
  const {currentStep } = useSelector((storeState) => storeState.stepForm);
  const { contactType='' } = useSelector((storeState) => storeState.callCenter);
  const { userAuth } = useSelector((storeState) => storeState.auth);

  let changeUrl = (currentStep === 'PasswordValidation' || currentStep === 'MFAForgotUsername' || currentStep === 'MultiFactorAuthentication') && url
  formHeader === 'Retrieve User ID' && dispatch(RETRIEVE_USER(true));
  formHeader === 'Reset Password' && dispatch(RESET_PASSWORD(true));
  let shouldShowTip;
  /** Hide Tip for below 3 operations */
  if (
    formHeader === 'Retrieve User ID' ||
    formHeader === 'Reset Password' ||
    formHeader === 'User Registration' ||
    formHeader === 'Change MFA Contact'
  )
    shouldShowTip = false;
  else shouldShowTip = true;

  /** Clear retrieve state in store */
  const handleClick = () => {
    formHeader === 'Retrieve User ID' && dispatch(RETRIEVE_USER(false));
    formHeader === 'Reset Password' && dispatch(RESET_PASSWORD(false));
    dispatch(HIDE_VERIFY_BUTTON(true));
    dispatch(HIDE_VERIFY_EMAIL_BUTTON(true));
    dispatch(HIDE_VERIFY_EBUTTON(true));
    dispatch(RESET_REGISTERATION())
  };

  // const { contentData, handleSubmit, currentStep, content } = props;
  // const { formData, steps, stepKeys } = contentData;
  let currentEmail = oldEmail || email
  let changeMFAContactUser = userAuth.processType === "non-sso"

  return (
    <React.Fragment>
      {triBox && (
        <div className="col-12 ">
          <div class="row">
            {/* <div class="col-sm-12 col-md-12 col-lg-12  pb-6 px-lg-2 px-0   "> */}
            <div
              className={
                formHeader === 'Reset Password'
                  ? 'col-sm-12 col-md-12 col-lg-12  pb-6 px-lg-2 px-0 pl-md-3 ml-md-0  mr-md-0 pr-md-5 pr-2 '
                  : formHeader === 'Retrieve User ID'
                  ? 'col-sm-12 col-md-12 col-lg-12  pb-6 px-lg-2 pr-0 pl-md-3 ml-md-0  mr-md-0 pr-md-5 pr-2 '
                  : 'col-sm-12 col-md-12 col-lg-12  pb-6 px-lg-2 px-0'
              }
            >
              <label class="form-side-header">{formHeader}</label>
              {/* <div className="tri-confirmation-container mb-5 mt-4 py-3"> */}
              <div
                className={
                  formHeader === 'Reset Password'
                    ? 'tri-confirmation-container mb-5 mt-4 py-3 px-md-0 px-lg-3 '
                    : formHeader === 'Retrieve User ID'
                    ? 'tri-confirmation-container mb-5 mt-4 mt-lg-4 mt-md-3 py-3 px-md-0 px-lg-3 '
                    : 'tri-confirmation-container mb-5 mt-4 py-3'
                }
              >
                <Image alt={confirmationImage?.imgAlt} filename={confirmationImage?.imgLink} />
                <div>
                  <h1
                    className={
                      formHeader === 'Retrieve User ID' ||  formHeader === 'Reset Password'
                        ? 'custom_confirmation_message'
                        : 'confirmation_message'
                    }
                  >
                    {
                      forMFA ?
                        contactType !== 'email' ?
                          confirmationMessage :
                        confirmationMessageEmail :
                      confirmationMessage
                    }
                  </h1>{' '}
                  {/** Show email for Retrieve User ID only  */}
                  {currentEmail && !forMFA && formHeader === 'Retrieve User ID' && (
                    <h1 className={  formHeader === 'Retrieve User ID' ||  formHeader === 'Reset Password'
                    ? 'custom_confirmation_message'
                    : 'confirmation_message'}>{currentEmail }</h1>
                  )}{' '}
                  {submissionTextBefore}
                  {!forMFA && 
                    <div className="pt-1 ">
                      <Linker to={changeUrl ? redirectURL +  decodeURIComponent(url) :redirectURL} className="linker" onClick={handleClick}>
                        {redirectMessage ? redirectMessage : `${firstName} ${lastName} ${name}'s`}
                      </Linker>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {shouldShowTip && (
            <div class="row">
              <div class="col-sm-6 col-md-10 col-lg-7  pb-6">
                <div className="tri-tip-container">
                  <Image alt={tipImage?.imgLink} filename={tipImage?.imgLink} />
                  <div className="tip-title-content">
                    <p className="tip-title">{tipTitle}</p>
                    <p className="tip-title-message"> {tipMessage}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(contactType === 'email' || contactType === 'mobile') &&
            <div className ='  col-lg-3 col-4 col-md-2 col-sm-3 ml-auto py-3 pr-0 pl-3 ml-3 '>
                <Button
                  className="w-100 "
                  type="submit"
                  onClick={() => {
                    history.push('/enrollment/home')
                    dispatch(SHOW_SPECIAL(false));
                    dispatch(CLEAR_CHANGE_MFA_SESSION());
                    dispatch(SEND_CODE_MOBILE_CLICKED(false));
                  }}
                >
                  Continue
                </Button>
              </div>
          }
          {/* <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-6 pb-6">
              <div className="tri-tip-container">
                <Image
                  alt={tipImage?.imgAlt}
                  className="tip-title-content"
                  filename={tipImage?.imgLink}
                />
                <div className="tip-title-content">
                  <p className="tip-title">{tipTitle}</p>
                  <p> {tipMessage}</p>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="tri-tip-container">
            <div className="row py-3">
              <div className="col-3">
                {' '}
                <Image alt={tipImage?.imgAlt} filename={tipImage?.imgLink} />
              </div>
              <div className="col-4 px-3">
                <p className="tip-title">{tipTitle}</p>

                {tipMessage}
              </div>
            </div>
          </div> */}
        </div>
      )}

      {!triBox && (
        <div className="col-12 ">
          <div className="confirmation-container">
            <Image alt={confirmationImage.imgAlt} filename={confirmationImage.imgLink} />
            <h1 className="confirmation_message">{confirmationMessage}</h1>{' '}
            <h1 className="confirmation_message">{currentEmail }</h1>{' '}
          </div>
          <div>
            <h1 className="confirmation_link">
              {submissionTextBefore}

              <Linker to={changeUrl ? redirectURL +  decodeURIComponent(url) :redirectURL}>
                {redirectMessage
                  ? redirectMessage
                  : `${firstName} ${lastName} ${name} ${formReferenceLink}'s`}
              </Linker>
              {submissionTextAfter}
            </h1>
          </div>

          {/* <pre style={{ textAlign: 'left' }}>{JSON.stringify(contentData.formData, null, 2)}</pre> */}
        </div>
      )}
      {/* //{' '} */}
      {/* <pre style={{ textAlign: 'left' }}>{JSON.stringify(contentData.formData, null, 2)}</pre> */}
      {/* ); */}
    </React.Fragment>
  );
}

export default SuccessMessagePage;