import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../appConfig';
import http from '../../../../Common/Services/HttpService';
import { UPDATE_LOADING } from '../../../../Store/Slices/InsuredInfo';
import { initializeForms, initializeFormsData } from '../../../../Store/Slices/StepFormNew';
import { useNavigationHook } from '../Hooks/NavigationHook';
import DynamicComponent from '../index';
import Header from '../Component/Header/Header';
import { SET_USER_TYPE_LANGUAGE } from '../../../../Store/Slices/Registeration';
import {
  processTypeMFA
} from '../../../../Store/Slices/Auth/userAuth';
import {
  CURRENT_STEP,
  STEP_KEYS
} from '../../../../Store/Slices/StepForm';
const BaseLayout = (props) => {
  const { formLayoutType, layoutData = {}, formName } = props;
  const dispatch = useDispatch();

  const { currentLanguage: language } = useSelector((storeState) => storeState.language);
  const clientId = useSelector(
    (storeState) =>
      storeState?.loggedUserEntity?.data?._Result?.legalEntityIdentity?.clientId
        ?.internalClientId || null
  );
  // const currentStep = useSelector((storeState) => storeState?.StepForm?.currentStep || null);
  const currentStep = useSelector((storeState) => storeState?.stepFormNew?.currentStep || null);
  const { handleSetSkipSteps, handleSetCurrentStep, handleSetStepKeys } = useNavigationHook();
  const {
    FirstName = 'test',
    Name = 'test',
    EmployeeId = '',
  } = useSelector((storeState) => storeState.callCenter.selectedEmployeeData);
  const { clientName = 'test', clientTitle } = useSelector((storeState) => storeState.callCenter);
  const stepFormNew = useSelector((storeState) => storeState?.stepFormNew);
  const registeration = useSelector((storeState) => storeState?.registeration);
  const { userAuth } = useSelector((storeState) => storeState?.auth);
  const currentForm = formName;

  const [formsConfig, setFormsConfig] = useState({});
  useEffect(() => {
    import(`./Content/${formName}.json`)
      .then((module) => {
        setFormsConfig(module.default);
      })
      .catch((error) => console.error('Failed to load content:', error));
  }, [language]);

  useEffect(() => {
    let isCallCenterUser = window.location.pathname.indexOf('callcenter') !== -1;
    let isEnrollmentUser = window.location.pathname.indexOf('enrollment') !== -1;
    let isConfigUser = window.location.pathname.indexOf('configurator') !== -1;
    let isHrCenter = window.location.pathname.indexOf('hrcenter') !== -1;

    let isMFAFunctionalities =
      stepFormNew.currentStep === 'EmployeeValidation' ||
      stepFormNew.currentStep === 'retrievePassword' ||
      stepFormNew.currentSte === 'retrieveUserID'
    if (isCallCenterUser) {
      dispatch(SET_USER_TYPE_LANGUAGE({
        userType: 'CallCenter',
        language: "en"
      }));
      http.setUserType('CallCenter')
      dispatch(processTypeMFA({ userType: 'callCenter' }))
    } else if (isHrCenter) {
      dispatch(SET_USER_TYPE_LANGUAGE({
        userType: 'HrCenter',
        language: "en"
      }));
      http.setUserType('HrCenter')
      dispatch(processTypeMFA({ userType: 'HrCenter' }))
    }

    else if (isConfigUser) {
      dispatch(SET_USER_TYPE_LANGUAGE({
        userType: 'Configurator',
        language: "en"
      }));
      dispatch(processTypeMFA({
        userType: 'Configurator',
        language: "en"
      }))
      http.setUserType('Configurator')
    }
    else if (isEnrollmentUser) {
      http.setUserType('Enrollment')
      dispatch(SET_USER_TYPE_LANGUAGE({
        userType: 'Enrollment',
        language: "en"
      }));
    }
    else if (isMFAFunctionalities) {
      http.setUserType('')
      dispatch(SET_USER_TYPE_LANGUAGE({
        userType: '',
        language: "en"
      }));
    }
    //submitMFAToken1()
  }, [window.location.pathname])


  const headers = {
    UserType: registeration.userType,
  };

  let formData;

  useEffect(() => {
    
      // Dynamically get content based on currentLanguage.
      // Initializing formsConfig and formsData
      if(Object.keys(formsConfig).length > 0){
        try {
          if (formsConfig?.forms && typeof formsConfig?.forms === 'object') {
            const { forms } = formsConfig;
            const keys = Object.keys(forms);
            const skipStepsKeys = keys.filter((key) => forms[key].skipStep);

            const loadConfig = async () => {
              dispatch(UPDATE_LOADING(true));
              const { data } = await http.post(
                appConfig.SSP_MID_URL + '/v1/form-data',
                {
                  clientId,
                  forms: keys.map((form) => ({
                    form,
                    language,
                    formLayoutType,
                  })),
                },
                { headers }
              );

              if (data?.data) {
                const formsData = Object.keys(data.data).reduce((res, key) => {
                  if (data.data?.[key]?.success) res[key] = data.data[key].data;
                  formData = res;
                  return res;
                }, {});
                dispatch(initializeFormsData(formsData));
              }
              handleSetStepKeys(keys);
              handleSetSkipSteps(skipStepsKeys);
              dispatch(initializeForms(formsConfig));
              handleSetCurrentStep(formName);

              dispatch(STEP_KEYS({
                stepKeys: [
                  stepFormNew.previousStep
                ]
              }));

              dispatch(CURRENT_STEP({ currentStep: currentForm, stepId: formName }));

              dispatch(UPDATE_LOADING(false));
            };
            loadConfig();
          } else {
            console.error('formsConfig?.forms is not correctly populated dfd');
          }
        } catch (error) {
          console.error(`Failed to load content for language: ${language}`, error);
        }
      }

  }, [formsConfig]);


  return (
    <>
      <Header
        title={
          formName === 'EmployeeData'
            ? `${FirstName} ${Name} - ${EmployeeId}`
            : formName === 'SearchForAnEmployee'
              ? clientTitle
              : formsConfig.forms?.[formName]?.headerTitle ||
              formsConfig.forms?.[formName]?.displayName
        }

        breadcrumbsStepForm={formsConfig?.breadcrumbs}
        breadcrumbs={formsConfig?.breadcrumbs}

        filename={formsConfig?.filename}
        current={formsConfig?.current}
      />

      <DynamicComponent currentStep={currentStep} layoutData={props} />
    </>
  );
};

export default BaseLayout;
