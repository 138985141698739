import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.navbarContainer .navbar {
  background-color: ${({ theme }) =>
    theme.components &&
    theme.components.navigationBar &&
    theme.components.navigationBar.navigationBgColor};;
  min-height: 100%;
  padding-bottom: 0;
}

.iconContainer {
  /* height: unset; */
  color: #ffffff;
  margin-bottom: 0.2rem;
  padding-right: 0;
}

.root-level-link{
  color: ${({ theme }) =>
    theme.components &&
    theme.components.navigationBar &&
    theme.components.navigationBar.navigationMainLinksFontColor};
  padding: 1rem 1rem 0.7rem 1rem;
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.configurator-navbar {
  color: #fff !important
}

.root-level-link :hover{
  color: ${({ theme }) =>
    theme.components &&
    theme.components.navigationBar &&
    theme.components.navigationBar.navigationMainLinksFontColor};
 
}
/* @media (max-width: 922px) {
  .navbarContainer .navbar-nav {
    display: none;
  }
} */
`;
