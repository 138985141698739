import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.member-container .modal-content {
  border-radius: 4px 4px 0px 0px !important;
  border: 0;
  background: ${({ theme }) =>
    theme.molecules && theme.molecules.modal && theme.molecules.modal.modalContentBgColor};
}

.member-container .logoutModalDialog .modal-content {
  width: auto;
  top: 10rem !important;
  left: auto;
  right: auto;
 
}
.member-container .qleModalDialog .modal-content {
  width: auto;
  top: 0rem !important;
  left: auto;
  right: auto;
 
}

.member-container .modal-header {
  /* background:  ${({ theme }) =>
    theme.molecules && theme.molecules.modal && theme.molecules.modal.modalHeaderBgColor}; */
  background: #0065AC;
  height: 60px;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  justify-content: space-between;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 16px 32px !important;
}

.modal-body {
  padding: 0px 32px 40px 32px;
}

.member-container .modal-footer {
  justify-content: center;
}

.member-container .modal-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  color: #ffffff;
}

.closeIcon {
  width: 1rem;
  height: 1rem;
  padding: 0 !important;
  margin: 0 !important;
}

.scrollbar {
  overflow-y: auto;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.model_confirmation_content {
  margin: 0 0 32px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}
.model_confirmation_image {
  margin: 32px 21px 0;
  padding: 5.7px 6.3px 6.3px 5.7px;
  font-family: FontAwesome5Pro-Solid;
  font-size: 110px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #72a800;
}
.logoutModalDialog{
  max-width:26rem !important;
}
.qleModalDialog{
  max-width:45rem !important;
  margin-top:2rem;
}
.modalBodyMessage {
  height: auto;
  //margin-right: 1.875rem;
  margin-top: 1.875rem;
}
.modalDisclaimer {
  padding: 32px 32px 0px 32px;
}
.modalDisclaimerText {
  color: #C53532;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 24px;
}
.modalDescription {
  padding: 0px 32px;
}
.modalDescriptionText {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  margin-bottom: 0px;
}

@media only screen and (max-width: 767px) {
  .logoutModalDialog{
    max-width:23rem !important;
  }
  .qleModalDialog{
    max-width:25rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 767px) {
 
  .qleModalDialog{
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 400px) {
 
  .qleModalDialog{
    max-width:22.5rem !important;
  }
}
`;
