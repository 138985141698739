import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../Cells/Header';
import Footer from '../../Cells/Footer';
import StaticFooter from '../../Cells/HyperLinkFooter';

export const PrivateRouteStaticPages = ({ hideHeader, staticFooter, component: Component, render: Render = null, ...rest }) => {
  const userToken = useSelector((storeState) => storeState?.auth?.userAuth?.data?.access_token);
  const loader = useSelector((storeState) => storeState?.loader?.loader);
  const { pathname } = useLocation();
  return (
    <React.Fragment>
     <div >
     {/* { !hideHeader && <Header/>} */}
     <Route
        {...rest}
        render={(props) =>
           (
            Render ? (
              Render(props)
            ) : (
              <Component {...props} />
            )
          ) 
        }
      />
     </div>
     { !loader && staticFooter ? <StaticFooter /> : null}
    </React.Fragment>
  );
};

export default PrivateRouteStaticPages;
