import { React, useEffect, useState } from 'react';
import { Button } from '../../Atoms';
import classes from './styles.module.css';
import { useHistory } from 'react-router-dom';
import ManageBeneficiariesTable from '../ManageBeneficiariesTable';
import { useDispatch, useSelector } from 'react-redux';
import { PRODUCT_RESOURCES } from '../../../Store/Slices/Process/index';
import { SET_COVERAGE_BUIDS, SET_COVERAGE_BENEFICIARY_ELIGIBILITY } from '../../../Store/Slices/InsuredInfo/index';
import { onLoadAPI } from '../../../Store/Slices/Entities/response/index.js';
import { getCoverageBeneficiaryEligibility } from '../CustomSection/Helper/MappingHelper'
import { BENIFICIARY_PRODUCT_UPDATE } from '../../../Store/Slices/Enrollment/index'
const BeneficiariesTable = (props) => {
  const {
    hideActions,
    productResourceData,
    productDetails,
    beneficiaryConfigData,
    beneficiariesProducts,
    openreenrollment,
    currentBenifitendorsementProcessBuid,
    reasonType,
    isAdmin,
    qleEndorsementProcessBuid,
    hideEditButton,
    isSummaryPage = false
  } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  const subscribedProductsHaveBeneficiaries = useSelector((storeState) => storeState?.process?.subscribedProductsHaveBeneficiaries)
  useEffect(() => {
    if (productResourceData?.length > 0 && productDetails?.length > 0) {
      const productResourceList = {};
      const productSelectionResources = productResourceData;
      for (let i = 0; i < productSelectionResources?.length; i++) {
        productResourceList[productDetails?.[i]?.product?.buid] = productSelectionResources[i]?.resources
      }
      dispatch(PRODUCT_RESOURCES({ productResourceList }));
      dispatch(onLoadAPI({ productSelectionResources }));
    }
  }, [productResourceData, productDetails])
  useEffect(() => {
    if ((beneficiaryConfigData && beneficiaryConfigData?.length > 0) && beneficiariesProducts) {
      beneficiaryConfigData.forEach(CoverageDetails => {
        let productIdentifierBuid = CoverageDetails?.productId
        const bUIDs = [];
        bUIDs.push({ "productBuid": CoverageDetails?.productId, "coverageBuid": CoverageDetails?.coverageBuid })
        let matchedProduct = beneficiariesProducts.find(item => item.productId === productIdentifierBuid);
        let newProductFamily = matchedProduct ? matchedProduct.productName : '';
        if (bUIDs?.length > 0) {
          dispatch(SET_COVERAGE_BUIDS({ bUIDs, product: newProductFamily }));
        }
        dispatch(onLoadAPI({
          [`GetSubscribedCoverageBeneficiaryConfigurationFor${newProductFamily}`]: CoverageDetails,
        }));
        const newCoverageBeneficiaryEligiblity = getCoverageBeneficiaryEligibility(CoverageDetails.coverageInformation, CoverageDetails.coverageBuid);
        dispatch(SET_COVERAGE_BENEFICIARY_ELIGIBILITY({ eligibility: newCoverageBeneficiaryEligiblity, product: newProductFamily, productPage: true }));
      });
      // dispatch(BENIFICIARY_PRODUCT_UPDATE(beneficiariesProducts))
    }
  }, [beneficiaryConfigData, beneficiariesProducts])
  const handleRedirect = () => {
    let redirectURL = "/enrollment/manageBeneficiaries";
    history.push(redirectURL);
  }
  let hasTrueBeneficiaries = beneficiariesProducts?.some(product => product?.hasBeneficiaries);

  if(isSummaryPage) {
    hasTrueBeneficiaries = subscribedProductsHaveBeneficiaries ? true : false
  }

  let beneficiariesTableEligible = hasTrueBeneficiaries ? true : false
  const isAdmintrue = beneficiariesTableEligible && isAdmin
  const enrollProcess = (beneficiariesTableEligible && ((reasonType === 'NH' && !currentBenifitendorsementProcessBuid) || (reasonType === 'AE')))
  const annualEnrollment = openreenrollment && hasTrueBeneficiaries
  let isBeneficiariesEditable = (annualEnrollment || enrollProcess || qleEndorsementProcessBuid || isAdmintrue) ? true : false
  return beneficiariesTableEligible && (
    <div>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <div className={classes.title}>{'Beneficiary Information'}</div>
          {!hideEditButton && (
            <Button
              className={classes.Button}
              onClick={() => handleRedirect()}
            >
              Edit
            </Button>
          )}
        </div>
        <ManageBeneficiariesTable
          hideActions={hideActions}
          // showButton={showBeneficiariesButton}
          // buttonOptions={beneficiaryButtonOptions}
          {...props}
        />
      </div>
    </div>
  );
};

export default BeneficiariesTable;