import React, { useEffect } from 'react';
import EmployeeInsuredQuestionsContent from '../Component/Common/EmployeeInsuredQuestionsContent';
import CoverageWrapperFlowOne from '../Container/CoverageFlowOneWrapper';
import { UPDATE_COVERAGE_DETAILS } from '../../../../Store/Slices/InsuredInfo';
import { useDispatch, useSelector } from 'react-redux';
import Summary from '../Component/Common/Summary'
import { UPDATE_CART_ITEM_NEW } from '../../../../Store/Slices/EmployeeProfile';
import { useNavigationHook } from '../Hooks/NavigationHook';

const Flow1 = (props) => {
  const dispatch = useDispatch();
  const { currentStep } = useNavigationHook();

  const { step, validateSubscribedProductData, RadioSelctedValue, declineProduct } = props || {};
  const { insuredInfo } = useSelector((storeState) => storeState);
  const productTitle = useSelector(
    (storeState) => {
      let x =
        storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result?.filter(
          (data) => data.code === 'PRODTITLE'
        );
      if (!x) {
        return '';
      } else {
        return x[0]?.content;
      }
    }
  );

  let coverageDetailsData = insuredInfo[currentStep]?.coverageDetails;

  useEffect(() => {
    if (coverageDetailsData && productTitle) {
      updateCostBasket()
    }
  }, [coverageDetailsData, productTitle]);

  if (step === null || step === undefined) {
    return null; // or return some other default UI or error message
  }

  const handleCoverageChange = (personBuid, selectedValue, waivedCoverage, coverageBuid, costPerPayPeriod, relationship) => {
    dispatch(UPDATE_COVERAGE_DETAILS({ personBuid, selectedValue, waivedCoverage, currentStep, coverageBuid, costPerPayPeriod, relationship }));

  };
  const updateCostBasket = () => {
    dispatch(
      UPDATE_CART_ITEM_NEW({
        id: currentStep,
        moreThanOne: true,
        name: productTitle,
        coverageList: coverageDetailsData,
      })
    );
  };

  const steps = [
    {
      label: '',
      component: () => <EmployeeInsuredQuestionsContent stepsCount={steps.length} employeeIndex="0" displayMode={'All'} {...props} />,
    },

    {
      label: '',
      component: () => <CoverageWrapperFlowOne stepsCount={steps.length} displayMode={'All'} handleCoverageChange={handleCoverageChange} validateSubscribedProductData={validateSubscribedProductData} currentStep={currentStep} {...props} />,
    }
  ];
  if (props.showDisclosureStatement) {
    steps.push({
      label: 'Disclosure Statement',
      component: () => <div className='summaryWrapper'><Summary RadioSelctedValue={RadioSelctedValue} showDisclosureStatement={props.showDisclosureStatement} validateSubscribedProductData={validateSubscribedProductData} declineProduct={declineProduct} {...props}></Summary></div>,
    });
  }

  const currentStepInfo = steps[step - 1];
  if (!currentStepInfo) {
    return null; // or return some other default UI or error message
  }
  return (
    <>
      {currentStepInfo.component()}
    </>
  );
};

export default Flow1;
