import React from 'react';

const Label = ({ text, inputId, className }) => {
    return (
        <label htmlFor={inputId} className={className}>
            {text}
        </label>
    );
};

export default Label;