import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

const Backdrop = (props) => {
  return <div className="backdrop" onClick={props.click} onMouseEnter={props.handleOnHover}></div>;
};

Backdrop.propTypes = {
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  className: PropTypes.string,
};

Backdrop.defaultProps = {
  className: '',
};

export default Backdrop;
