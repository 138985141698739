let execution = {
  contains: (val1, val2) => {
    //search for string
    
    return new RegExp(val2).test(val1);
  },
  in: (val1, val2) => {
    //array
    
    if (val1.indexOf(val2) != -1) {
      return true;
    } else {
      return false;
    }
  },
  greaterThan: (val1, val2) => {
   

    if (parseInt(val1) > parseInt(val2)) {
      return true;
    } else {
      return false;
    }
  },
  lesserThan: (val1, val2) => {
    
    if (parseInt(val1) < parseInt(val2)) {
      return true;
    } else {
      return false;
    }
  },
  equals: (val1, val2) => {
    return val1 == val2;
  },
  notEquals: (val1, val2) => {
    return val1 != val2;
  },
  range: (val1, val2) => {
    var splitValue = val2.split('-');
    
    if (parseInt(val1) >= parseInt(splitValue[0]) && parseInt(val1) <= parseInt(splitValue[1])) {
      return true;
    } else {
      return false;
    }
  },
  addition: (val1, val2) => {
    return val1 + val2;
  },
  subraction: (val1, val2) => {
    return val1 - val2;
  },
};

//var dynFun = new Function(val1,val2,func);
let ManipulationService = {
  operatorSelector: function (val1, val2, operator) {
    switch (operator) {
      case 'contains':
        return execution.contains(val1, val2);
      case 'in':
        return execution.in(val1, val2);
      case 'greaterthan':
        return execution.greaterThan(val1, val2);
      case 'lessthan':
        return execution.lesserThan(val1, val2);
      case 'equal':
        return execution.equals(val1, val2);
      case 'notequal':
        return execution.notEquals(val1, val2);
      case 'range':
        return execution.range(val1, val2);
      case 'addition':
        return execution.addition(val1, val2);
      case 'subraction':
        return execution.subraction(val1, val2);
      default:
        break;
    }
  },
};
export default ManipulationService;
