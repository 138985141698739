import React, { useState, useEffect } from 'react';
import EmployeeInsuredQuestionsContent from '../Component/Common/EmployeeInsuredQuestionsContent';
import EmployeeCoverageWrapper from '../Container/EmployeeCoverageWrapper';
import SpouseInsuredQuestionsContent from '../Component/Common/SpouseInsuredQuestionsContent';
import SpouseCoverageWrapper from '../Container/SpouseCoverageWrapper.js';
import ChildCoverageWrapper from '../Container/ChildCoverageWrapper.js';
import { UPDATE_COVERAGE_DETAILS } from '../../../../Store/Slices/InsuredInfo';
import Summary from '../Component/Common/Summary';
import { useDispatch, useSelector } from 'react-redux';
import logoAflac from '../../../../Assets/Images/aflac-logo.png';
import imgMetlife from '../../../../Assets/Images/met-life.png';
import ChildInsuredQuestionsContent from '../Component/Common/ChildInsuredQuestionsContent';
import { UPDATE_CART_ITEM_NEW } from '../../../../Store/Slices/EmployeeProfile';
import CONSTANTS from '../Content/Constants.json';
import { useNavigationHook } from '../Hooks/NavigationHook';
const Flow2 = (props) => {
  const {

    step,
    nextStep,
    prevStep,
    employee,
    spouse,
    children,
    showDisclosureStatement,
    validateSubscribedProductData,
    RadioSelctedValue,
    declineProduct,
  } = props;
  const { currentStep} = useNavigationHook();

  const dispatch = useDispatch();
  const [currentSelectedValue, setCurrentSelectedValue] = useState('');
  const { insuredInfo, entities } = useSelector((storeState) => storeState);
  let isEmployeeBuyUpEnabled = false;
  isEmployeeBuyUpEnabled = useSelector((storeState) => storeState.buyUp.eligibility.employee);
  let basePlanBoughtCurrentYear = true;
  basePlanBoughtCurrentYear = useSelector((storeState) => storeState.buyUp.basePlanBoughtCurrentYearFlag.employee);
  let isBuyUpFlow = false;
  let buyUpDeclinedLocally = useSelector((storeState) => storeState.buyUp.basePlanDeclinedLocally.employee) || false
  if(buyUpDeclinedLocally) {
    isBuyUpFlow = true
  } else {
    isBuyUpFlow = isEmployeeBuyUpEnabled ? (basePlanBoughtCurrentYear ? false : true ) : false;
  }
  
  const getAllPossibleChoicesConfiguration = entities?.response?.onLoadResponseList?.[`${currentStep}`]?.getAllPossibleChoicesConfigurationForProduct?._Result
  let eligiblePersonsBuidArr = [];
  if(getAllPossibleChoicesConfiguration && getAllPossibleChoicesConfiguration.length) {
      getAllPossibleChoicesConfiguration.map((item) => {
              if(item.possibleInsuredPersons && item.possibleInsuredPersons.length) {
                  item.possibleInsuredPersons.map((person) => {
                      eligiblePersonsBuidArr.push(person.insuredPerson.bUID)
                  })
              }
          
      })
  }
  let coverageDetailsData = insuredInfo[currentStep]?.coverageDetails;
  const productTitle = useSelector(
    (storeState) => {
      let x =
        storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result?.filter(
          (data) => data.code == 'PRODTITLE'
        );
      if (x == undefined) {
        return '';
      } else {
        return x[0]?.content;
      }
    }
  );
  let [coverageWaivedOffArr, setCoverageWaivedOffArr] = useState([]);
  let [childCoverageWaivedOffArr, setChildCoverageWaivedOffArr] = useState([]);
  const setCoverageWaivedBoolean = (isCoverageWaived, spouseIndex) => {
    if (isCoverageWaived) {
      if (!coverageWaivedOffArr.includes(spouseIndex)) {
        setCoverageWaivedOffArr([...coverageWaivedOffArr, spouseIndex]);
      }
    } else {
      const index = coverageWaivedOffArr.indexOf(spouseIndex);
      if (index > -1) {
        coverageWaivedOffArr.splice(index, 1);
        setCoverageWaivedOffArr([...coverageWaivedOffArr])
      }
    }
  }
  const setChildCoverageWaivedBoolean = (isCoverageWaived, childIndex) => {
    if (isCoverageWaived) {
      if (!childCoverageWaivedOffArr.includes(childIndex)) {
        setChildCoverageWaivedOffArr([...childCoverageWaivedOffArr, childIndex]);
      }
    } else {
      const index = childCoverageWaivedOffArr.indexOf(childIndex);
      if (index > -1) {
        childCoverageWaivedOffArr.splice(index, 1);
        setChildCoverageWaivedOffArr([...childCoverageWaivedOffArr])
      }
    }
  }
  useEffect(() => {
    if (coverageDetailsData && productTitle) {
      updateCostBasket()
    }
  }, [coverageDetailsData, productTitle]);

  

  const handleCoverageChange = (personBuid, selectedValue, waivedCoverage, coverageBuid, costPerPayPeriod, relationship) => {
    setCurrentSelectedValue(selectedValue);
    dispatch(UPDATE_COVERAGE_DETAILS({ personBuid, selectedValue, waivedCoverage, currentStep, coverageBuid, costPerPayPeriod, relationship }));
  };
  const updateCostBasket = () => {
    dispatch(
      UPDATE_CART_ITEM_NEW({
        id: currentStep,
        moreThanOne: true,
        name: productTitle,
        coverageList: coverageDetailsData,
      })
    );
  };
  const [skipSpouse, setSkipSpouse] = useState([]);
  const skipSpouseFn = (pskipData) => {
    setSkipSpouse([...pskipData]);
  };

  const [skipChild, setSkipChild] = useState([]);
  const skipChildFn = (pskipData) => {
    setSkipChild([...skipChild,...pskipData]);
  };

  let steps = [];

  if (employee && employee.length) {
    employee.forEach((emp, index) => {
      steps.push(
        {
          component: () => (
            <div>
              <EmployeeInsuredQuestionsContent
                employeeIndex={index}
                displayMode={`Employee`}
                setCoverageWaivedBoolean={setCoverageWaivedBoolean}
                handleCoverageChange={handleCoverageChange}
                {...props}
              ></EmployeeInsuredQuestionsContent>
            </div>
          ),
        },
        {
          component: () => (
            <div>
              <EmployeeCoverageWrapper
                employeeIndex={index}
                displayMode={`Employee`}
                {...props}
                handleCoverageChange={handleCoverageChange}
              ></EmployeeCoverageWrapper>
            </div>
          ),
        }
      );
    });
  }

  if (spouse && spouse.length) {
    spouse.forEach((sp, index) => {
      if(eligiblePersonsBuidArr.indexOf(sp.personBuid) !== -1) {
        const relationship = sp.insured.insured.mainOwnerRelationshipToInsured
        const displayMode = CONSTANTS.relationship.find(relation => relation.value === relationship)?.label || 'Spouse'

        const skipData = skipSpouse.filter((pobj)=>{
          return pobj.coverageEligiblity === false && pobj.buid === sp.personBuid
        });
        
          steps.push(
            {
              label: `Critical Illness - ${displayMode} `,
              logo: (
                <img
                  src={props.flowType === 1 ? imgMetlife : logoAflac}
                  name={'Logo'}
                  className={'img-fluid line-class '}
                  alt={'An image'}
                />
              ),
              component: () => (
                <div>
                  <SpouseInsuredQuestionsContent
                    spouseIndex={index}
                    displayMode={displayMode}
                    setCoverageWaivedBoolean={setCoverageWaivedBoolean}
                    skipSpouse={skipSpouseFn}
                    {...props}
                  ></SpouseInsuredQuestionsContent>
                </div>
              ),
            }
          );
        if(skipData.length == 0){
          if (!coverageWaivedOffArr.length) {
            steps.push(
              {
                label: `Critical Illness - ${displayMode} `,
                logo: (
                  <img
                    src={props.flowType === 1 ? imgMetlife : logoAflac}
                    name={'Logo'}
                    className={'img-fluid line-class '}
                    alt={'An image'}
                  />
                ),
                component: () => (
                  <div>
                    <SpouseCoverageWrapper
                      spouseIndex={index}
                      displayMode={displayMode}
                      {...props}
                      handleCoverageChange={handleCoverageChange}
                    ></SpouseCoverageWrapper>
                  </div>
                ),
              }
            )
          } else {
            coverageWaivedOffArr.map((item, i) => {
              if (item !== index) {
                steps.push(
                  {
                    label: `Critical Illness - ${displayMode} `,
                    logo: (
                      <img
                        src={props.flowType === 1 ? imgMetlife : logoAflac}
                        name={'Logo'}
                        className={'img-fluid line-class '}
                        alt={'An image'}
                      />
                    ),
                    component: () => (
                      <div>
                        <SpouseCoverageWrapper
                          spouseIndex={index}
                          displayMode={displayMode}
                          {...props}
                          handleCoverageChange={handleCoverageChange}
                        ></SpouseCoverageWrapper>
                      </div>
                    ),
                  }
                )
              }
            });
          }
        }
      }
    });
  }

  if (children && children.length) {
    children.forEach((child, index) => {
      if(eligiblePersonsBuidArr.indexOf(child.personBuid) !== -1) {
        const skipData = skipChild.filter((pobj)=>{
          return pobj.coverageEligiblity === false && pobj.buid === child.personBuid
        });
        
          steps.push(
            {
              label: `Critical Illness - ${child?.insured?.person?.data?.actor?.shortName} ${child?.insured?.person?.data?.actor?.name}`,
              logo: (
                <img
                  src={props.flowType === 1 ? imgMetlife : logoAflac}
                  name={'Logo'}
                  className={'img-fluid line-class '}
                  alt={'An image'}
                />
              ),
              component: () => (
                <div>
                  <ChildInsuredQuestionsContent
                    childIndex={index}
                    displayMode={`Child`}
                    skipChild={skipChildFn}
                    {...props}
                    setChildCoverageWaivedBoolean={setChildCoverageWaivedBoolean}
                  ></ChildInsuredQuestionsContent>
                </div>
              ),
            });
        if(skipData.length == 0){
          if (!childCoverageWaivedOffArr.length) {
            steps.push(
              {
                label: `Critical Illness - ${child?.insured?.person?.data?.actor?.shortName} ${child?.insured?.person?.data?.actor?.name}`,
                component: () => (
                  <div>
                    <ChildCoverageWrapper
                      childIndex={index}
                      displayMode={`Child`}
                      {...props}
                      handleCoverageChange={handleCoverageChange}
                      setChildCoverageWaivedBoolean={setChildCoverageWaivedBoolean}
                    ></ChildCoverageWrapper>
                  </div>
                ),
              }
            );
          } else {
            childCoverageWaivedOffArr.map((item, i) => {
              if (item !== index) {
                steps.push(
                  {
                    label: `Critical Illness - ${child?.insured?.person?.data?.actor?.shortName} ${child?.insured?.person?.data?.actor?.name}`,
                    component: () => (
                      <div>
                        <ChildCoverageWrapper
                          childIndex={index}
                          displayMode={`Child`}
                          {...props}
                          handleCoverageChange={handleCoverageChange}
                          setChildCoverageWaivedBoolean={setChildCoverageWaivedBoolean}
                        ></ChildCoverageWrapper>
                      </div>
                    ),
                  }
                );
              }
            });
          }
        }
      }
    });
  }
  
  if (showDisclosureStatement) {
    steps.push({
      label: 'Disclosure Statement',
      component: () => (
        <div className="summaryWrapper">
          <Summary
            RadioSelctedValue={RadioSelctedValue}
            showDisclosureStatement={showDisclosureStatement}
            validateSubscribedProductData={validateSubscribedProductData}
            declineProduct={declineProduct}
            isBuyUpFlow={isBuyUpFlow}
            {...props}
          ></Summary>
        </div>
      ),
    });
  }

  const currentStepInfo = steps[step - 1];

  if (!currentStepInfo) {
    return null; // or return some other default UI or error message
  }
  return (
    <>
      {currentStepInfo.component()}
    </>
  );
};

export default Flow2;
