import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import reducer from "./Slices/reducer";
import Api from "./Middleware/Api/Api";

export default function () {
  return configureStore({
    reducer,
    middleware: [...getDefaultMiddleware({
      serializableCheck: false // to fix non-serializable err
    }), Api],
  });
}
