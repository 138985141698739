import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { resetUserIdSchema } from '../../Atoms/FormValidation'
import { Button, TextInput } from '../../Atoms';
import Loader from '../../Atoms/Loader';
import VerifyEmail from './VerifyEmail';
import RegisterMfa from './RegisterMfa';
import { useDispatch } from 'react-redux';
import http from '../../../../../Common/Services/HttpService';
import appConfig from '../../../../../appConfig';
import { SET_TOLL_NUMBER_FROM_AUTH_MODULE } from '../../../../../Store/Slices/Registeration';
import { HAS_ERROR, SET_EMPLOYEE_VALIDATION_ERROR, SHOW_ALERT } from '../../../../../Store/Slices/RegisterationError';
import { onLoadAPI } from '../../../../../Store/Slices/Entities/response/index';
import { apiCall } from '../../Helper/commonHelper';
import { SHOW_LOADER } from '../../../../../Store/Slices/Loader/index';
import { errorMessages } from '../../Atoms/FormValidation'
import { Success } from '../common';
import '../styles.css';

const Register = ({ stepValues, onNext, updateSteps, onComplete }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [err, setErr] = useState('');

    const { legalEntityId = '', appCode = '' } = useSelector((storeState) => storeState?.registeration);
    const { loader } = useSelector((state) => state.loader);
    const [showMaskedInput, setShowMaskedInput] = useState(false);
    const maxDateOfBirth = new Date().toISOString().split("T")[0];
    const newEntityId = legalEntityId.substring(0, legalEntityId.indexOf(':'));
    useEffect(() => {
        if (newEntityId) {
            http
                .get(appConfig.SSP_MID_URL + `/v1/config/${newEntityId}`)
                .then(async (resp) => {
                    const data = resp?.data?.data
                    for (const key in data) {
                        let result = { _Result: data[key].data }
                        dispatch(onLoadAPI({ [key]: result }));
                    }
                    return true;
                })
                .catch((e) => {
                    return false;
                });
        }
    }, [])
    const empInfoConfig = useSelector(
        (storeState) => storeState?.entities?.response?.onLoadResponseList?.register?._Result?.data?.[0]
    );

    const empConfigData = {
        "employeeIDToggle": empInfoConfig?.employeeId?.switchField || false,
        "employeeIDeditableLabelField": empInfoConfig?.employeeId?.editableLabelField || false,
        "employeeIDeditableLabelPlacerholderValue": empInfoConfig?.employeeId?.editableLabelPlacerholderValue || false,
        "birthDateToggle": empInfoConfig?.birthDate?.hideableField || false,
    }
    let employeeIDLabel = empConfigData.employeeIDeditableLabelField ? empConfigData.employeeIDeditableLabelPlacerholderValue : null;
    const initialValues = () => {
        let initialValues = { firstName: '', lastName: '' };
        if (!empConfigData.employeeIDToggle) {
            initialValues['employeeId'] = '';
        }
        if (empConfigData.employeeIDToggle) {
            initialValues['ssn'] = '';
        }
        if (!empConfigData.birthDateToggle) {
            initialValues['dateofBirth'] = '';
        }
        return initialValues
    }

    const formik = useFormik({
        initialValues: stepValues || initialValues(),
        validationSchema: resetUserIdSchema(empConfigData),
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            let newValues = values;
            if (values.ssn) {
                const ssn = values.ssn.replace(/[^\d]/g, '');;
                newValues = {
                    ...values,
                    ssn,
                    employeeId: ssn
                }
            }
            verifyEmployeeData(newValues)
        }
    });

    const isAllFieldsFilled = () => {
        const requiredFields = ['firstName', 'lastName', 'dateofBirth'];

        if (empConfigData.employeeIDToggle) {
            requiredFields.push('ssn');
        } else {
            requiredFields.push('employeeId');
        }

        return requiredFields.every(field => !!formik.values[field]);
    };

    const ErrorMessage = (field) => {
        return (
            <>
                {formik.touched[field] && formik.errors[field] ? (
                    <div className="form-mandatory">{formik.errors[field]}</div>
                ) : null}
            </>
        )
    }

    const verifyEmpDataDispatch = (hasError, showAlert) => {
        dispatch(HAS_ERROR({ hasError: hasError }));
        dispatch(SET_EMPLOYEE_VALIDATION_ERROR({ hasError: true, clearCount: false, key: 'EmployeeValidation' }));
        dispatch(SHOW_ALERT(showAlert))
    }

    /** verify employee data */
    const verifyEmployeeData = async (values) => {

        const reqObj = {
            "employeeData": {
            "firstName": values.firstName?.trim(),
            "lastName": values.lastName?.trim(),
            "birthDate": values.dateofBirth?.trim(),
            "employeeId": empConfigData.employeeIDToggle ? values.ssn?.trim() : values.employeeId?.trim(),
            "legalEntityId": legalEntityId
            },
            "appCode": appCode
        }
        setErr('');
        dispatch(SHOW_LOADER(true));
        apiCall("/stateful/sign-up/employee-data", 'post', reqObj)
            .then((res) => {
                if (res.message === 'Employee not found') {
                    verifyEmpDataDispatch(true, false)
                    setErr(errorMessages(res))
                } else if (res.message === 'User has exceeded the number of attempts (3)') {
                    verifyEmpDataDispatch(true, true)
                    dispatch(SET_TOLL_NUMBER_FROM_AUTH_MODULE(res?.tollNumber || '123'))
                }
                else if (String(res.status).charAt(0) === '2') {
                    verifyEmpDataDispatch(false, false)
                    updateSteps([
                        { component: VerifyEmail },
                        { component: RegisterMfa },
                        { component: Success, type: 'Register', hasContinue: false }
                    ])
                    onNext(values);
                } else {
                    setErr(errorMessages(res))
                }

                dispatch(SHOW_LOADER(false));
            })
            .catch((error) => {
                if (error.message === 'Request failed with status code 404') {
                    verifyEmpDataDispatch(true, false)
                } else if (error.message === 'User is not allowed to create a login') {
                    verifyEmpDataDispatch(true, false)
                }
                setErr(errorMessages(error))
                dispatch(SHOW_LOADER(false));
            });
    }
    const handleSSNChange = (e) => {
        const value = e.target.value.replace(/[^\d]/g, '');
        const formattedValue = value
            .slice(0, 9)
            .replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
                return [p1, p2, p3].filter((part) => part.length > 0).join('-');
            });
        formik.setFieldValue('ssn', formattedValue);
    };

    const handleSSNBlur = (e) => {
        if (formik.values.ssn?.replace(/-/g, '')?.length === 9 && !formik.errors.ssn) {
            setShowMaskedInput(true);
        } else {
            setShowMaskedInput(false);
        }
        formik.handleBlur(e);
    };

    const clearErrMsg = () => {
        setErr('');
    }
    return (
        <>
            {loader && <Loader loading={loader} />}
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="form-side-header">User Registration</h1>
                            <p className="form-mandatory mb-0">
                                Fields marked with * are mandatory
                            </p>
                            <div className="col-11 panel-sid-cont pl-xl-0 mb-2">
                                <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAgCAYAAAB6kdqOAAAABHNCSVQICAgIfAhkiAAAAg9JREFUWEftl9FVwjAUhm/Qd2ECGQEnAMoAsoE4gTABYQLZAJxAeFeECXQDYQLruzT+N0kt1FbaQ23rOfacnlPSknz5c/PfXEElu0TJeMgAtWSdKnTLT7irOUOuMd4LeTSgpVwLC/NcAEh43i6gLgR15IwUXeLtCg1dULq5KmRWZ4oxm1ivuSBHvml1PKrlDuPP3EC94ieWzJFKty9ksQFuOUoMlGvgxA8WKFQ6oP8YCi1JqqDuyC5s4QY+wU5O8K0lndCIHuQys5VODOTICQbtxQw8gl3ITKASAbEyiu7tgAOoxI5ahYkx4FC3V6gdqZRvuNG0LiZS23uVCMjRS9LEPUAH41AHrMyQ7Z4eAR6+nCFSkDiL5lHvtBjtJ/GEQMbFo9JKYPfZuPxfBTpiyWzSzjSGdoNaiD5t1dwEsrjC3je7KzaofyOGeMC2nCJwARB55bztfQajVN/uOG5dQRlZjDHGSHOwOXMfCg7+xmM4XQgcxs1z4yuNEM38A/oeZKY+ZGDSHPz1AZ2rhoPKxX3wow/5gcwuvNWx49IpVPFscq1ArQ+tVhVJdqyLBEV39CR7GQDZ7bnrxmkO/i3JuY0Lhe/5KSld4PqboAziGFF0rWVPe/BP+/0uKMMImuiY1GWQplOQPy4RJp3msd8h4XqiHpTSJhYa6Pb82K5T/n+jd+8WRxoUqcXWYhHkpQP6BLRCJB2CMuyFAAAAAElFTkSuQmCC"
                                    name=""
                                    className="img-fluid "
                                    alt="User Icon"
                                    style={{
                                        minWidth: '2.25rem',
                                        minHeight: '2rem',
                                        marginRight: 10
                                    }}
                                />
                                <span>
                                    <div className="panel-side-header-img d-inline">
                                        Employee Validation
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row py-2">
                        <div className="col-sm-12 col-md-6">
                            <TextInput
                                label={"First Name"}
                                required={true}
                                id="firstName"
                                name="firstName"
                                placeholder="Enter First Name"
                                className="w-100 inputField p-3"
                                {...formik.getFieldProps('firstName')}
                            />
                            {ErrorMessage('firstName')}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <TextInput
                                label={"Last Name"}
                                required={true}
                                id="lastName"
                                name="lastName"
                                placeholder="Enter Last Name"
                                className="w-100 inputField p-3"
                                {...formik.getFieldProps('lastName')}
                            />
                            {ErrorMessage('lastName')}
                        </div>
                    </div>

                    <div className="row py-2">
                        <div className="col-sm-12 col-md-6 py-2">
                            {!empConfigData.employeeIDToggle && <><TextInput
                                label={employeeIDLabel ?  employeeIDLabel : "Employee ID"}
                                required={true}
                                id="employeeId"
                                name="employeeId"
                                placeholder={employeeIDLabel ? employeeIDLabel : "Enter Employee ID"}
                                className="w-100 inputField"
                                {...formik.getFieldProps('employeeId')}
                            />
                                {ErrorMessage('employeeId')}
                            </>
                            }
                            {empConfigData.employeeIDToggle && <><TextInput
                                label={employeeIDLabel ? employeeIDLabel : "SSN"}
                                required={true}
                                id="ssn"
                                name="ssn"
                                placeholder="Enter SSN"
                                className="w-100 inputField"
                                maxLength={11}
                                value={showMaskedInput ? `XXX-XX-${formik?.values?.ssn?.slice(-4)}` : formik.values.ssn}
                                onFocus={() => setShowMaskedInput(false)}
                                onChange={handleSSNChange}
                                onInput={clearErrMsg}
                                onBlur={handleSSNBlur}
                            //  {...formik.getFieldProps('ssn')}
                            />
                                {ErrorMessage('ssn')}
                            </>
                            }
                        </div>
                        {!empConfigData.birthDateToggle &&
                            <div className="col-sm-12 col-md-6 py-2">
                                <TextInput
                                    label={"Date of Birth"}
                                    required={true}
                                    type='date'
                                    onInput={(e) => e.preventDefault()}
                                    id="dateofBirth"
                                    name="dateofBirth"
                                    value={formik.values.dateofBirth}
                                    max={maxDateOfBirth}
                                    placeholder=""
                                    className="w-100 inputField"
                                    {...formik.getFieldProps('dateofBirth')}
                                />
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar-alt" className="svg-inline--fa fa-calendar-alt fa-w-14 calendarIcon retrieveID" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg>
                                {ErrorMessage('dateofBirth')}
                            </div>}
                    </div>

                    {err &&
                        <div className="row">
                            <span style={{ color: '#A61003', margin: 5, paddingLeft: 10 }}>{err} </span>
                        </div>
                    }
                    <div className="row py-4 justify-content-between">
                        <div className="col-sm-12 col-md-4 py-2">

                        </div>
                        <div className="col-sm-12 col-md-6 py-2 text-md-right">
                            <Button
                                type="button"
                                className="btn btn-outline-primary primaryOutline btn-outline-primary-contrast mr-4"
                                onClick={() => history.push(`/enrollment?legalEntityId=${legalEntityId}&appCode=${appCode}`)}
                            >
                                Back
                            </Button>
                            <Button
                                name="registerationBtn"
                                type="button"
                                className="btn btn-primary primaryBackground btn-primary-contrast mfaBtn"
                                disabled={!isAllFieldsFilled() || !formik.isValid}
                                onClick={() => formik.submitForm()}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
export default Register;
