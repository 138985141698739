import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

import ToolTip from '../ToolTip';
import $ from 'jquery';
import Tippy from '@tippyjs/react';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import Loader from '../../Atoms/Loader';

import imageMap from '../../Molecules/CustomSection/Helper/imageHelper';
import { getFileName } from '../../Molecules/CustomSection/Helper/commonHelper';

const Image = (props) => {
  const {
    filename,
    logoBase64 = '',
    contentType = '',
    hidden,
    alt = "image",
    label,
    className,
    classNameWrapper,
    isLeftLabel,
    hideDefaultClass,
    onClick,
    style,
    toolTip,
    formSideHeaderWithImage,
    name,
    modalPopUp,
    modalName,
    pdfURL,
    hideElement,
    showOnAPISuccess,
    isListofEligibleMemberForHomePage,
    prodLogo,
    productSelLogo,
    imageFileType,
    imageName,
    ...rest
  } = props;



  let { codeIsEntered } = useSelector((storeState) => storeState.disableButton);
  const registerationError = useSelector((storeState) => storeState?.registerationError);
  const { emailVerified, phoneVerified, phoneCodeSubmitted = false, passwordAPI = false } = useSelector((storeState) => storeState.registeration);
  const currentStep = useSelector((storeState) => storeState.stepForm.currentStep);
  const stepForm = useSelector((storeState) => storeState?.stepForm);
  let shouldHideOnAPIFail = showOnAPISuccess &&
    registerationError[stepForm.currentStep]?.hasError &&
    registerationError[stepForm.currentStep]?.dependentElementsReadOnly === name;

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
  }, [registerationError['MultiFactorAuthentication']?.hasError], registerationError.phoneIsVerified);

  const handleClick = (event) => {
    if (modalName) {

      if (modalPopUp) {

        setShowLoader(true);
        {
          showLoader && <Loader loading={showLoader} />;
        }
        $(`#${modalName}`).modal('toggle');
        $('#video1')?.get(0)?.play();
      }
    }
    event.stopPropagation();
    (name == "download") && window.open(pdfURL, '_blank');
    onClick && onClick(event);
  };
  let imgSrc;
  try {
    if (filename == "no-image.png") {
      imgSrc = contentType;
    }
    else {
      imgSrc = imageMap[getFileName(filename)];
    }
  } catch (e) {
    imgSrc = '';
  }
  //  let additionalClasses = hideElement || shouldHideOnAPIFail ? 'hideElement' : classNameWrapper;
  //  let shouldHideEmail =    ( name === 'verifyEmail') &&
  //                           (!emailVerified)
  //                           
  // let shouldHidePhone =    ( name === 'verifyPhone') &&
  //                           (!emailVerified)
  //                                                  

  //  let additionalClasses = hideElement || shouldHideOnAPIFail || shouldHideElement? 'hideElement' : classNameWrapper;
  let additionalClasses = hideElement || shouldHideOnAPIFail ? 'hideElement' : classNameWrapper;

  /** hide verify icon when security code not entered */
  //let verifyIconClasses = ((name === 'verifyPhone' || name === 'verifyEmail') && !codeIsEntered) ? ' hideElement ' : '';
  let verifyIconEmailClasses = ((name === 'verifyEmail') &&
    (registerationError['UserAccountVerifyEmail']?.hasError) && !passwordAPI) ? ' hideElement ' : '';

  let verifyIconPhoneClasses = (name === 'verifyPhone' && stepForm.currentStep === 'MultiFactorAuthentication' &&
    registerationError.showInputErr === true) ? ' hideElement ' : '';
  let verifyIconClasses = stepForm.currentStep === 'UserAccountVerifyEmail' ? verifyIconEmailClasses :
    stepForm.currentStep === 'MultiFactorAuthentication' ? verifyIconPhoneClasses : false
  let isPhoneVerified = registerationError.phoneIsVerified;
  let isEmailVerified = registerationError.emailVerified
  let hideImg = name === 'verifyEmail' && !registerationError.emailVerified

  const getFileExtension = (filename) => {
    return filename?.slice((filename?.lastIndexOf(".") - 1 >>> 0) + 2)
  }

  //To get Logo Image
  //To get title for products

  let productLogo = useSelector(
    (storeState) => {
      let productLogoContent =
        storeState.entities.response?.onLoadResponseList?.[`SearchPolicyProcessResources${currentStep}`]?._Result?.filter(
          (data) => data.code == 'PRODLOGO' && data.contenttype != 'crtNone'
        );

      if (productLogoContent == undefined) {
        return '';
      } else {
        return { "binaryContent": productLogoContent[0]?.binaryContent, "fileType": getFileExtension(productLogoContent[0]?.content) };
      }
    }
  );






  let fileType = productLogo?.binaryContent ? productLogo?.fileType : [];




  let fileExtension
  if (fileType == "jpg" || fileType == "png" || imageFileType == "jpg" || imageFileType == "png") {
    fileExtension = "png"
  }
  else if (fileType == "svg" || imageFileType == "svg") {
    fileExtension = "svg+xml"
  }

  productLogo = (productLogo?.binaryContent || productSelLogo) ? "data:image/" + fileExtension + ";base64," + (productLogo?.binaryContent) : "data:image/png;base64," + "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII="

  let img = ''
  if (logoBase64) {
    if (logoBase64 !== 'no-image') {
      let logoFileType = getFileExtension(contentType);
      let logoFileExtension
      if (logoFileType == "jpg" || logoFileType == "png") {
        logoFileExtension = "png"
      }
      else if (logoFileType == "svg") {
        logoFileExtension = "svg+xml"
      }
      if (logoFileExtension && logoBase64) {
        img = logoBase64 ? "data:image/" + logoFileExtension + ";base64," + logoBase64 : "data:image/png;base64," + "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII="
      }
    } else {
      // img = contentType || '';
      img = imageMap[getFileName(filename)] || '';
    }

  }



  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });
  return (
    // <div
    //   className={
    //     (hideDefaultClass ? '' : 'd-flex flex-column justify-content-center ') +
    //     classNameWrapper
    //   }
    //   {...rest}
    // >

    <div
      className={
        (hideDefaultClass ? '' :

          (name == 'video') ?
            'd-flex flex-column  ' :
            (name == 'carrierLogo') ?
              'd-flex flex-column justify-content-center align-items-end carrierLogoClass ' :
              (name !== 'verifyEmail' && name !== 'verifyPhone') ?
                'd-flex flex-column justify-content-center align-items-center ' :


                'flex-column justify-content-center align-items-center ') +
        classNameWrapper + additionalClasses + verifyIconClasses
      }

      {...rest}
    >
      {/* {formSideHeaderWithImage && <span style={{ padding: '100px' }}>{label} </span>} */}
      {isLeftLabel && <span style={{ padding: '10px' }}>{label} </span>}
      {/** render email icon and other images */}
      {!props?.toolTip && imgSrc && imgSrc !== "no-image.png" && (
        <img
          src={logoBase64 ? img : (prodLogo || productSelLogo) ? productLogo : imgSrc}
          // src="data:image/png;base64,/9j/4AAQSkZJRgABAQEBLAEsAAD/
          style={style}
          name={name || ''}
          className={isListofEligibleMemberForHomePage ? className : 'img-fluid ' + className}
          hidden={hidden || (name === 'verifyPhone' && (!isPhoneVerified)) ||
            ((name === 'verifyEmail' || name === '') && (!isEmailVerified)) || (hideImg)}
          onClick={handleClick}
          alt={alt || imageName || filename}
          role="img"
        />
      )}
      {imgSrc === "no-image.png" && (
        <div className={isListofEligibleMemberForHomePage ? className : 'img-fluid ' + className}>&ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp;</div>
      )}
      {/** render tooltip icon */}
      {imgSrc && props?.toolTip && (
        <div className="row tippy-text mt-0 pt-0 ">
          <Tippy
            placement="right-end"
            maxWidth="250px"
            content={
              <div className="tippy-text mt-5 pt-3 px-2">
                <div className="row mt-5">
                  <div
                    className="col-12 mx-0 pt-3 pr-0 px-0 "
                    style={{
                      color: '#004280',
                      backgroundColor: '#EDF9FC',
                      fontWeight: 'bold',
                      fontSize: '12pt',
                    }}
                  >
                    <strong className="row ml-0 py-0 my-0 pb-1 pl-4 text-left">
                      Password Rules
                    </strong>
                  </div>
                </div>

                <div className=" row ">
                  <div
                    className="col-12 mr-3  pl-0 pr-3 mr-5 pb-3 pt-1"
                    style={{ color: '#202020', backgroundColor: '#EDF9FC' }}
                  >
                    {props?.toolTip?.map((tip, index) => (
                      <div>
                        <ul key={index} className="row mr-2">
                          <div className="col-1 px-0 mx-0">{'\u2022'}</div>
                          <div className="col-11 px-0 mx-0">{tip}</div>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
          >
            {filename !== "no-image.png" && (
              <img
                src={imgSrc}
                style={style}
                hidden={hidden}
                className={'img-fluid ' + className}
                onClick={handleClick}
                alt={alt}
              />
            )}
          </Tippy>
        </div>
      )}

      {/* <span class="tooltiptext">Tooltip text</span> */}
      {isLeftLabel ? null : hideDefaultClass ? (
        <div>{label} </div>
      ) : (
        label && <h5 className={'mt-2 font-weight-bold'}>{label}</h5>
      )}

      {/* <button
        type="button"
        class="btn btn-secondary"
        data-toggle="tooltip"
        data-placement="top"
        title="Tooltip on top"
      >
        Tooltip on top
      </button> */}

      {/* <a href="#" data-toggle="tooltip" title="Some tooltip text!">
        Hover over me
      </a>
      <div class="tooltip bs-tooltip-top" role="tooltip">
        <div class="arrow"></div>
        <div class="tooltip-inner">Some tooltip text!</div>
      </div> */}
    </div>
  );
};
Image.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};
Image.defaultProps = {
  alt: 'An image',
  className: '',
  classNameWrapper: '',
};

export default Image;
