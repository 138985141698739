import React from 'react';
import './styles.css';
import Image from '../Image';
/*This atom is created to display the information which contains a icon,title and list of items  */
const Line = (props) => {
  const {
    children,
    className,
    value,
    image,
    title,
    text,
    hideElement,
    hidden,
    classNameWrapper,
    iconClass,
    headerClass,
    listClass,
    olClass,
    id = "",
    showContent = [],
    color = "",
    ...rest
  } = props;
  let listItem = '';
  let notes = [];
  // value.forEach(function (listItem, index) {
 
  //   notes.push(listItem);
  // });
  let additionalClasses = hideElement ? 'hideElement' : classNameWrapper;
  const showPanel = showContent.includes(id) || showContent.length === 0;

  return showPanel ? (
    <div className={ `${additionalClasses}  ${className}`} hidden={hidden} >
      <hr className ="line-class" color={color ? color : "#86D3E4"} size="100" ></hr>
    </div>
  ) : null;
};

export default Line;
