import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const SelectDropdown = ({ id, label, options, selectedOption, onChange, required }) => {
  return (
    <div className="select-custom-dropdown">
      <label htmlFor={id}>{label}{required && <span className='form-mandatory'> *</span>}</label>
      <select id={id} value={selectedOption} onChange={onChange}>
        {/* Add a placeholder option here */}
        <option value=""  className="placeholder-option">Select</option>

        

        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectDropdown.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool
};

export default SelectDropdown;