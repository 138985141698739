import React, { useStat, memo } from 'react';
import Profile from "../Component/Profile/Profile.js"
import EmployeeData from '../../EmployeeData';


const CallCenterManageDashboard = memo((props) => {


  return (
    <div>
       <EmployeeData {...props}></EmployeeData>
    </div>
  );
});
export default CallCenterManageDashboard;
