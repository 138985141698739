import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.radioButtonGroup {
  float: left;
  bottom: 0;
}

.radioButtonGroup input {
  visibility: hidden;
}

.radioButtonGroup label {
  float: left;
  width: 50%;
  background-color: #fff;
  overflow: auto;
  border: 0.0625rem solid #ccc;
  border-right: none;
  margin: 0;
}
.isRequired {
  border-bottom: 0.0625rem solid #f9b44d !important;
}

.radioButtonGroup label.required {
  border-bottom: 0.0625rem solid #f9b44d !important;
}

.radioButtonGroup label.roundLeft {
  border-radius: 0.375rem 0 0 0.375rem;
}

.radioButtonGroup label.roundRight {
  border-right: 0.0625rem solid #ccc !important;
  border-radius: 0 0.375rem 0.375rem 0;
}

.roundRight span, .roundLeft span{
  margin-left:0.5rem;
}

.roundRight span{
  margin-right: 0.5rem;
}
@media (max-width: 767px) {
  .roundRight span{
    margin-right: 0rem;
  }
  .roundLeft span{
    margin-left: 6px;
  }
  .roundLeft.mr-3{
    margin-right: 0!important;
  }
}
.radioButtonGroup label span {
  text-align: center;
  font-size: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.radioGroup label span {
  margin: 0 0.5rem;
  color: #202020;
  font-size: 0.875rem; 
  font-family: "NotoSansRegular"
}
.radioButtonGroup label input {
  display: none;
}

.radioButtonGroup input:checked + span {
  background-color: ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.radioButtonGroupBgColor};
  color:  ${({ theme }) =>
    theme.atoms && theme.atoms.button && theme.atoms.button.radioButtonGroupColor};
}

.radioGroup input:checked + span {
  color: #000000;
  // font-size: 0.8rem !important;
}

.radioGroup span {
  color: red;
}

`;
