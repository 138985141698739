import React from 'react';

import CoverageFlowOne from '../Component/Flow1/coverageFlowOne';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigationHook } from '../Hooks/NavigationHook'; 


const CoverageWrapperFlowOne = (props) => {
  const { currentStep, formData, handleNextStep, handlePreviousStep} = useNavigationHook();

  const buid = props.employee?.[0]?.personBuid;


  const { insuredInfo } = useSelector((storeState) => storeState);

  const coverageDetails = insuredInfo[currentStep]?.coverageDetails;
  const selectedValue = coverageDetails?.[0]?.selectedValue;

  let currentReduxState = useSelector((storeState) => storeState);
  // let planDesign = currentReduxState.entities
  // ?.response
  // ?.onLoadResponseList
  // ?.[`getPlanDesignConfigurationFor${currentStep}`]
  // ?._Result;
  let filteredSubscribeCoverageBuids = [];
  let matchedCoverageBuidObj = null;

  const insuredInfoCurrentStep =  useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};
  let getDetails = insuredInfoCurrentStep.subscribeCoverageBuids?.filter((e) => e?.personBuid === buid)
  let coverageConfiguration = getDetails?.[0]?.listOfCoveragePlanDesignConfiguration?._Result || [];
  filteredSubscribeCoverageBuids = insuredInfoCurrentStep.subscribeCoverageBuids?.filter(item => item.personBuid === buid) || [];
  matchedCoverageBuidObj = filteredSubscribeCoverageBuids.length > 0 ? filteredSubscribeCoverageBuids[0] : null;
  const coverageBuid = matchedCoverageBuidObj?.coverageBuid || "";
  /*   const coverageresult = coverageConfiguration?.map((item) => ({
      coverageAmount: item?.name,
      coverageLevel: item?.presentedAdditionalData[0]?.fieldValue || '',
      limit: `$${item?.presentedAdditionalData[0]?.fieldValue?.amount}` || '',
      planType: item?.presentedAdditionalData[1]?.fieldValue || '',
      typeOfCoverage: item?.presentedAdditionalData[2]?.fieldValue || '',
      costPerPayPeriod: `$${item?.rateInformation?.rate?.amount?.amount}` || '',
      code: item?.code
    })) || [];
   */


  /* const filteredCoverages = planDesign.find(
    (coverage) => coverage.productBuid === props.productIdentifierBuid
  )?.result ?? []; */



  const dependentsList = useSelector(
    (storeState) => storeState?.employeeProfile?.insuredsAsBeneficiaries,
  );

  const filteredInsuredsAsBeneficiaries = dependentsList.filter(
    beneficiary => beneficiary.mainBuid !== buid
  );

  

  return (
    <div>
      <CoverageFlowOne
        {...props}
        onCoverageChange={props.handleCoverageChange}
        tableData={coverageConfiguration}
        personBuid={buid}
        dependentsList={filteredInsuredsAsBeneficiaries}
        validateSubscribedProductData={props.validateSubscribedProductData}
        declineProduct={props.declineProduct}
        showDisclosureStatement={props.showDisclosureStatement}
        onNext={props.onNext}
        stepKeys={props.stepKeys}
        currentStep={props.currentStep}
        selectedValue={selectedValue}
        coverageBuid={coverageBuid}
      />
    </div>

  );
};

export default CoverageWrapperFlowOne;
