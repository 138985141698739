import { useState } from "react";

const StepForm = ({ steps, className, onStepChange }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [values, setValues] = useState([]);
    const { component: Component, ...others } = steps[currentStep];
    
    const saveValues = (data) => {
        const newValues = [...values];
        newValues[currentStep] = data;
        setValues(newValues);
    }

    const onNext = (data) => {
        saveValues(data);
        setCurrentStep(currentStep + 1);

        if (onStepChange) {
            onStepChange(currentStep);
        }
    }
    const onPrev = () => {
        setCurrentStep(currentStep - 1);
    }

    return (
        <div className={`step-form ${className}`}>
            <Component
                onNext={onNext}
                onPrev={onPrev}
                onSave={saveValues}
                stepValues={values[currentStep]}
                {...others} />
        </div>
    );
};

export default StepForm;
