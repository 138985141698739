import React, { Suspense, useEffect, useState } from 'react';
import appConfig from '../../../../appConfig';
import http from '../../../../Common/Services/HttpService';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
  APIRequestParallel,
  onLoadAPI,
  onLoadProductAPI
} from '../../../../Store/Slices/Entities/response/index';
import { reduxConstants } from '../../../../Common/Constants/index';
import {
  PRODUCT_TABLE_COVERAGES_DATA,
  GET_LIST_OF_ALL_PRODUCT_COVERAGES
} from '../../../../Store/Slices/EmployeeProfile';
import { SET_DEPENDENT_BENEFICIARY_LIST } from '../../../../Store/Slices/EmployeeProfile';
import { UPDATE_LOADING, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { UPDATE_STEP_INFO } from '../../../../Store/Slices/Enrollment/index';
import { UPDATE_ENROLLMENT_DATA } from '../../../../Store/Slices/Enrollment';
import { SHOW_CONTENT_LOADER } from '../../../../Store/Slices/Loader';
import { uniq, intersectionWith, differenceWith, isEqual } from 'lodash';
import { OFFERED_COVERAGE_BUIDS } from '../../../../Store/Slices/InsuredInfo';
import { relationship } from '../../../../utils/relationship';
import { getFormKeyByProductFamily } from '../../../Molecules/ProductTable/processData';
// import { MessageComponent } from '../../../Atoms';
import BaseLayout from '../../../Molecules/CustomSection/FormLayout';
import { useNavigationHook } from '../../../Molecules/CustomSection/Hooks/NavigationHook';
import useOnBack from '../../../Molecules/CustomSection/Hooks/OnBackHook';

const EmployeeProfile = (props) => {
  const { currentStep } = useNavigationHook();
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, SetContent] = useState({});
  const [layoutData, setLayoutData] = useState({});
  const [displayContent, setDisplayContent] = useState({ DependentInformation: [] });
  const [modalTitle, setModalTitle] = useState('');

  const dispatch = useDispatch();

  useOnBack('/enrollment/home');

  $('#addDependent').on("hidden.bs.modal", function () {
    if (modalTitle.length > 0) {
      setModalTitle('');
    }
  });

  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};

  let legalIndentity = useSelector((storeState) => storeState.registeration?.legalEntityId)
  let clientId = legalIndentity ? legalIndentity.split(':')[0] : ""

  const contractBuid = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.subscribedcontracts?._Result &&
      storeState?.entities?.response?.onLoadResponseList?.subscribedcontracts?._Result[0]?.contract?.buid
  );

  const zipCode = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.PersonalInformation?.personalInformation
        ?.zipCode
  );


  const [dependentsCoverageTable, setDependentsCoverageTable] = useState([]);
  const [dependentsCoverageTableUmUim, setDependentsCoverageTableUmUim] = useState([]);

  const [beneficiariesTable, setBeneficiariesTable] = useState([]);
  const [beneficiaryInformation, setBeneficiaryInformation] = useState([]);
  const [petTable, setPetTable] = useState([]);
  const [petInformation, setPetInformation] = useState([]);
  const [beneficiaryTableData, setBeneficiaryTableData] = useState({});

  // Variable to save the information about beneficiaries 
  const [beneficiariesProducts, setBeneficiariesProducts] = useState([]);

  const loginuserName = useSelector((storeState) => storeState?.auth?.userAuth?.data?.user_name);

  const dataFromRedux = useSelector((storeState) => storeState?.auth?.loggedUserEntity?.data);
  const storeFromRedux = useSelector((storeState) => storeState);
  const employeeProfile = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile);
  const dependentsList = useSelector((storeState) => storeState?.employeeProfile?.dependentsList);
  const dependentsInfo = useSelector((storeState) => storeState?.employeeProfile?.dependentsInfo);
  const dependentAdded = useSelector((storeState) => storeState?.employeeProfile?.dependentAdded);
  const process = useSelector((storeState) => storeState?.process);
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);

  const beneficiariesList = useSelector(
    (storeState) => storeState?.employeeProfile?.beneficiariesList
  );

  const petList = useSelector((storeState) => storeState?.employeeProfile?.petList);
  const dependentsBeneficiaryList = useSelector((storeState) => storeState?.employeeProfile?.dependentsBeneficiaryList);

  const dependentsCoverageList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsCoverageList
  );
  const dependentsIDTheftCoverageList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsIDTheftCoverageList
  );
  const dependentsSupplementalHealthInsuranceCoverageList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsSupplementalHealthInsuranceCoverageList
  );
  const dependentsLegalCoverageList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsLegalCoverageList
  );
  const dependentsPetPlusList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsPetPlusList
  );
  const dependentsPetAssureList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsPetAssureList
  );
  const dependentsTravelCoverageList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsTravelCoverageList
  );
  const dependentsCriticalIllnessCoverageList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsCriticalIllnessCoverageList
  );
  const dependentsHospitalIndemnityCoverageList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsHospitalIndemnityCoverageList
  );
  const dependentsExcessLiabilityCoverageList = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsExcessLiabilityCoverageList
  );
  const dependentsExcessLiabilityCoverageListUmUim = useSelector(
    (storeState) => storeState?.employeeProfile?.dependentsExcessLiabilityCoverageListUmUim
  );
  const getListOfEligibleProducts = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.getListOfEligibleProducts
  );
  const dependentIsDeleted = useSelector((storeState) => storeState?.employeeProfile?.dependentIsDeleted);
  const dependentIsEdit = useSelector((storeState) => storeState?.employeeProfile?.dependentIsEdit);
  const previousStep = useSelector((storeState) => storeState.stepForm.previousStep);

  const ValidateMoveNextProcessBuid = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.ValidateSubscribedContractMoveNextData?._Result
        ?.processEntity?.buid
  );

  const validateContractCoverageData = useSelector(
    (storeState) =>
      storeState?.entities?.response?.onLoadResponseList?.ValidateSubscribedContractMoveNextData?._Result?.subscriptionManager
  );

  let validateContractCoverageBuid = validateContractCoverageData?.map((ele) => ({
    coverageBuid: ele?.choosenChoices?.[0]?.subscribedOptions?.[0]?.bUID,
    productBuid: ele?.product?.bUID,
  }));

  const skipNextApiCall = useSelector(
    (storeState) => storeState?.employeeProfile?.skipNextApiCall
  );

  const coverageTitle = useSelector(
    (storeState) =>
      storeState?.process?.productResourcesCoverageTitle
  );

  const accidentCoverageList = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.getPlanDesignConfigurationForCoverageSelection?._Result
  );
  const idTheftCoverageList = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.getPlanDesignConfigurationForIdTheft?._Result
  );
  const supplementalHealthInsuranceCoverageList = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.getPlanDesignConfigurationForSupplementalHealthInsurance?._Result
  );
  const legalCoverageList = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.getPlanDesignConfigurationForLegalCoverage?._Result
  );
  const travelCoverageList = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.getPlanDesignConfigurationForTravel?._Result
  );
  const hospitalIndemnityCoverageList = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.getPlanDesignConfigurationForHospitalIndemnity?._Result
  );
  const criticalIllnessCoverageList = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.getPlanDesignConfigurationForCriticalIllness?._Result
  );
  const excessLiabilityCoverageList = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.getPlanDesignConfigurationForExcessLiability?._Result
  );
  const petAssureCoverageList = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.getPlanDesignConfigurationForPetAssure?._Result
  );
  const petPlusCoverageList = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.getPlanDesignConfigurationForPetPlus?._Result
  );
  const beneficiariesFullData = useSelector(
    (storeState) =>
      storeState.entities.response?.onLoadResponseList?.beneficiariesData
  ) || {};

  const coverageList = currentStep === "CoverageSelection" ?
    accidentCoverageList
    : currentStep === "IdTheft" ?
      idTheftCoverageList
      : currentStep === "LegalCoverage" ?
        legalCoverageList
        : currentStep === "Travel" ?
          travelCoverageList
          : currentStep === "HospitalIndemnity" ?
            hospitalIndemnityCoverageList
            : currentStep === "CriticalIllness" ?
              criticalIllnessCoverageList
              : currentStep === "ExcessLiability" ?
                excessLiabilityCoverageList
                : currentStep === "PetAssure" ?
                  petAssureCoverageList
                  : currentStep === "SupplementalHealthInsurance" ?
                    supplementalHealthInsuranceCoverageList
                    : currentStep === "PetPlus" ? petPlusCoverageList
                    : [];

  const productFamily = content?.forms?.[currentStep]?.productFamily;
  const productBuidCheck = storeFromRedux?.process?.productFamilyIdentifier[productFamily]

  let declinedProduct = enrollmentData?.contract?.subscribedElements?.filter(e => e.subscribedProduct?.productCode?.buid === productBuidCheck);
  let isDeclinedProduct = declinedProduct?.[0]?.subscribedProduct?.status === "cDeclined" ? true : false

  const coverageresult = coverageList?.map((item) => ({
    coverageAmount: item?.name,
    coverageLevel: item?.presentedAdditionalData[0]?.fieldValue || '',
    limit: `$${item?.presentedAdditionalData[0]?.fieldValue?.amount}` || '',
    planType: item?.presentedAdditionalData[1]?.fieldValue || '',
    typeOfCoverage: item?.presentedAdditionalData[2]?.fieldValue || '',
    costPerPayPeriod: `$${item?.rateInformation?.rate?.amount?.amount}` || '',
    code: item?.code,
    productBuid: productBuidCheck
  })) || []

  useEffect(() => {
    if (
      currentStep &&
      (currentStep === 'CoverageSelection' ||
        currentStep === 'IdTheft' ||
        currentStep === 'SupplementalHealthInsurance' ||
        currentStep === 'LegalCoverage' ||
        currentStep === 'Travel' ||
        currentStep === 'PetPlus' ||
        currentStep === 'PetAssure' ||
        currentStep === 'HospitalIndemnity' ||
        currentStep === 'ExcessLiability')
    ) {
      dispatch(PRODUCT_TABLE_COVERAGES_DATA(coverageresult))
      dispatch(GET_LIST_OF_ALL_PRODUCT_COVERAGES(coverageresult));
    }

  }, [currentStep, coverageList])

  // Method to split the information based on beneficiaries type
  const getBeneficiaryType = (beneficiaryArray = []) => {
    const contingentArray = [];
    const primaryArray = [];
    const beneficiaryObj = {
      "Primary": [],
      "Contingent": [],
    };
    if (beneficiaryArray.length > 0) {
      beneficiaryArray.forEach(beneficiary => {
        if (beneficiary && beneficiary.beneficiaryInformation && beneficiary.beneficiaryInformation.beneficiaryRank === 'cPrimary') {
          primaryArray.push(beneficiary);
        } else if (beneficiary && beneficiary.beneficiaryInformation && beneficiary.beneficiaryInformation.beneficiaryRank === 'cContingent') {
          contingentArray.push(beneficiary);
        }
      });
    }
    beneficiaryObj['Primary'] = primaryArray;
    beneficiaryObj['Contingent'] = contingentArray;
    return {
      beneficiaryObj,
      hasBeneficiaries: primaryArray.length > 0 || contingentArray.length > 0 ? true : false,
    };
  };

  const subscribedElements = storeFromRedux?.enrollment?.enrollmentData?.contract?.subscribedElements;
  const updateContractInformtion = storeFromRedux?.process?.updateContractInformtion?._Result;

  useEffect(() => {
    let currentStepInfo = {
      "portalStep": currentStep,
      "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
      "reachedPreConfirmation": "no"
    };
    dispatch(UPDATE_STEP_INFO(currentStepInfo));
  }, [currentStep, enrollmentData]);

  useEffect(() => {
    // && Object.keys(beneficiariesFullData)?.length > 0
    const isCorrectStep = ['HospitalIndemnity', 'CriticalIllness', 'EnrollmentSummary'];
    if (subscribedElements?.length > 0 && Object.keys(process?.productFamilyIdentifier)?.length > 0 && isCorrectStep?.includes(currentStep)) {
      const productsArray = [];
      let i = 0;
      const effectiveDate = enrollmentData?.processEntity?.effectiveDate
      subscribedElements?.forEach(product => {
        const productWaived = product?.subscribedProduct?.status === 'cDeclined' || product?.subscribedProduct?.status === 'cTerminated' || product?.subscribedProduct?.status === 'cNeverInForce';
        if (!productWaived) {
          const productId = product && product.bUID || '';
          const productCode = product?.subscribedProduct?.productCode?.buid || '';
          const productBuid = product && product.subscribedProduct && product.subscribedProduct.productCode && product.subscribedProduct.productCode.buid || '';
          const coverages = product && product.coverages || [];
          const productName = Object.keys(process.productFamilyIdentifier).find(key => process.productFamilyIdentifier[key] === productBuid);
          const productNameTitle = getFormKeyByProductFamily(productName);
          if (coverages.length > 0) {
            coverages?.forEach(coverage => {
              const plannedTerminationDate = coverage?.subscribedOption?.plannedTerminationDate
              const secondTermination1 = effectiveDate > plannedTerminationDate ? true : false
              if (!secondTermination1) {
                const productNameDescription = (coverage && coverage?.subscribedOption && coverage?.subscribedOption.optionName) || '';
                const coverageBuid = coverage && coverage.bUID;
                const newInsureArray = [];
                let newPersonBuid = '';
                coverage && coverage?.insuredPersons?.forEach(insure => {
                  const insuredPerson = insure && insure.beneficiariesDescription && insure.beneficiariesDescription.beneficiaries || [];
                  if (insuredPerson?.length > 0) {
                    const personBuid = insure && insure.person && insure.person.identifier && insure.person.identifier.bUID || '';
                    newPersonBuid = personBuid;
                    insuredPerson.forEach(insurePerson => {
                      const newInsurePerson = cloneDeep(insurePerson);
                      newInsurePerson['personBuid'] = personBuid;
                      newInsurePerson['fullData'] = beneficiariesFullData[newInsurePerson?.beneficiary?.identifier?.bUID] || {};
                      newInsureArray.push(newInsurePerson);
                    });
                  }
                });
                const productObj = {};
                const beneficiariesInformation = getBeneficiaryType(newInsureArray);
                let titleObj = {};
                coverageTitle && coverageTitle?.find(coverage => {
                  if (coverage?.productId === productCode && coverage?.insuredPersonId === newPersonBuid && beneficiariesInformation.hasBeneficiaries) {
                    titleObj = coverage;
                  }
                });
                // let productTitle = coverageTitle[productCode]
                // const resource = productTitle?.find(item => item?.code === 'PRODTITLE');
                productObj['personBuid'] = newPersonBuid;
                productObj['productId'] = productBuid;
                productObj['productBuid'] = productId;
                productObj['productName'] = productNameTitle;
                // productObj['productTitle'] = resource?.content || '';
                productObj['productTitle'] = titleObj?.content || '';
                productObj['productNameDescription'] = productNameDescription;
                productObj['coverageBuid'] = coverageBuid;
                productObj['beneficiaries'] = beneficiariesInformation && beneficiariesInformation.beneficiaryObj;
                productObj['hasBeneficiaries'] = beneficiariesInformation && beneficiariesInformation.hasBeneficiaries;
                productsArray.push(productObj);
              }
            });
          }
        }
      });
      if (productsArray?.length > 0) {
        const presents = intersectionWith(beneficiariesProducts, productsArray, isEqual);
        const dif = differenceWith(beneficiariesProducts, productsArray, isEqual);
        if (beneficiariesProducts?.length === 0 || dif?.length > 0 || presents?.length !== productsArray?.length) {
          setBeneficiariesProducts(productsArray);
        }
      }
    }
  }, [currentStep, coverageTitle, beneficiariesFullData]);

  useEffect(() => {
    if (petInformation.length === 0) {
      const petObj = [
        {
          id: 'pet-0',
          petId: '',
          petName: 'Fezco',

          petType: 'Dog',
          petCoverage: 'My pet Protection 50%',
          petCostperPayPeriod: '$2.08',
        },
        {
          id: 'pet-1',
          petId: '',
          petName: 'Osacar',
          petType: 'Cat',
          petCoverage: 'My pet Protection 70%',
          petCostperPayPeriod: '$3.28',
        },
      ];
      setPetInformation(petObj);
    }
  }, []);

  useEffect(() => {
    setBeneficiariesTable(beneficiariesList);
    dispatch(SET_DEPENDENT_BENEFICIARY_LIST({ data: beneficiariesList, isDependent: false }));
  }, [beneficiariesList]);

  useEffect(() => {
    let newPetList = [];

    if (petList.length > 0) {
      petList.forEach((pet, index) => {
        const petObj = {};
        petObj['petName'] = `${pet.petName} `;
        petObj['petType'] = `${pet.petType} `;
        petObj['petId'] = `pet-${index}`;
        petObj['id'] = `${pet.petName}-${index}`;
        petObj['mainBuid'] = `${pet.petName}-${index}`;
        petObj['petCoverage'] = `${pet.coverageLevel} `;
        petObj['petCostperPayPeriod'] = `${pet.costPerPayPeriod} `;
        petObj['petCoverageId'] = `${pet.coverageId} `;
        petObj['data'] = {
          ...pet,
        };
        newPetList.push(petObj);
      });
    }
    setPetTable(newPetList);
  }, [petList]);





  const getAllPossibleChoicesConfigurationForProduct = async () => {
    if (
      currentStep &&

      (
        currentStep === 'UniversalLife'


      )
    ) {
      let productFamily = content?.forms?.[currentStep]?.productFamily;
      let productIdentifierBuid = storeFromRedux?.process?.productFamilyIdentifier[productFamily];
      dispatch(UPDATE_LOADING(true));
      const resp = await http
        .post(appConfig.SSP_MID_URL + '/getAllPossibleChoicesConfigurationForProduct', {
          enrollmentProcessBuid: storeFromRedux.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID,
          productIdentifierBuid: productIdentifierBuid
        })
        .then((resp) => {
          if (resp && resp.data && resp.status === 200) {
            let totalList = resp.data?._Result
            let offeredCoverageBuids = []
            let enrollmentSummery = []
            for (let i = 0; i < resp.data?._Result.length; i++) {
              let personBuid = resp.data?._Result[i]?.possibleInsuredPersons?.map((el) => el?.insuredPerson?.bUID)
              totalList[i].offeredCoverageBuids = { offeredCoverageBuid: personBuid }
              offeredCoverageBuids.push({ coverage: resp.data?._Result[i]?.coverage?.bUID, coveragePersonBuid: personBuid })
              if (previousStep === "EnrollmentSummary" && i === 0) {
                enrollmentSummery.push({ coverage: resp.data?._Result[i]?.coverage?.bUID, coveragePersonBuid: personBuid })
              }
            }

            // Format the new data
            const currentStepData = {
              [currentStep]: {
                getAllPossibleChoicesConfigurationForProduct: { _Result: totalList }
                //getAllPossibleChoicesConfigurationForProduct : resp.data
              }
            };
            // Dispatch an action to update the state with the new data
            dispatch(onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false }));
            dispatch(OFFERED_COVERAGE_BUIDS({ offeredCoverageBuids: offeredCoverageBuids, product: currentStep }));
          }
        }).catch(e => {
          console.error(e);
          dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'getAllPossibleChoicesConfigurationForProduct',
            show: true,
            page: currentStep,
          }));
        }).finally(() => {
          dispatch(UPDATE_LOADING(false));
        })
    }
  }

  const getDependentList = async () => {
    let processData = storeFromRedux?.process?.enrollmentProcess?.[0]?.[0];
    // Populate enrollment data only when insured information is not empty
    if (processData?.contract?.insureds?.length) {
      const processInfo = processData?._Result ?? {};
      dispatch(UPDATE_ENROLLMENT_DATA(processInfo));
    }
  };


  const searchPolicyProcessResources = async () => {
    if (
      currentStep &&
      (
        currentStep === 'UniversalLife' ||
        currentStep === 'WLCoverage'
      )
    ) {
      let productFamily = content?.forms?.[currentStep]?.productFamily;
      let productIdentifierBuid = storeFromRedux?.process?.productFamilyIdentifier[productFamily];
      dispatch(UPDATE_LOADING(true));
      const resp = await http
        .post(appConfig.SSP_MID_URL + '/searchPolicyProcessResources', {
          enrollmentProcessBuid: storeFromRedux.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID,
          productIdentifierBuid: productIdentifierBuid,
          // currentReduxState: storeFromRedux,
          validateContractCoverageBuid: validateContractCoverageBuid // If this is not required we can remove SK
        })
        .then((resp) => {
          if (resp.data && resp.status === 200) {
            dispatch(onLoadAPI({ ...{ [`SearchPolicyProcessResources${currentStep}`]: resp.data, isAPICallInProgress: false } }));
          }
        }).catch(e => {
          console.error(e);
          dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'searchPolicyProcessResources',
            show: true,
            page: currentStep,
          }));
        }).finally(() => {
          dispatch(UPDATE_LOADING(false));
        })
    }
  }

  useEffect(() => {
    if (typeof storeFromRedux?.process?.enrollmentProcess?.[0]?.[0]?._Result?.processEntity?.buid !== "undefined") {
      getDependentList();
    }
  }, [typeof storeFromRedux?.process?.enrollmentProcess?.[0]?.[0]?._Result?.processEntity?.buid !== "undefined"]);

  useEffect(() => {
    let productFamily = content?.forms?.[currentStep]?.productFamily;
    let productIdentifierBuid = storeFromRedux?.process?.productFamilyIdentifier[productFamily];
    let skipForNext = `${currentStep}ConfigurationForProduct`;
    if (productIdentifierBuid && !skipNextApiCall?.includes(skipForNext)) {
      searchPolicyProcessResources();
      getAllPossibleChoicesConfigurationForProduct();

    }
  }, [currentStep]);

  useEffect(() => {
    if (
      dependentsCoverageList &&
      dependentsCoverageList.length > 0 &&
      currentStep == 'CoverageSelection'
    ) {
      setDependentsCoverageTable(dependentsCoverageList);
    }
    if (
      dependentsIDTheftCoverageList &&
      dependentsIDTheftCoverageList.length > 0 &&
      currentStep == 'IdTheft'
    ) {
      setDependentsCoverageTable(dependentsIDTheftCoverageList);
    }

    if (
      dependentsSupplementalHealthInsuranceCoverageList &&
      dependentsSupplementalHealthInsuranceCoverageList.length > 0 &&
      currentStep == 'SupplementalHealthInsurance'
    ) {
      setDependentsCoverageTable(dependentsSupplementalHealthInsuranceCoverageList);
    }

    if (
      dependentsTravelCoverageList &&
      dependentsTravelCoverageList.length > 0 &&
      currentStep == 'Travel'
    ) {
      setDependentsCoverageTable(dependentsTravelCoverageList);
    }

    if (
      dependentsLegalCoverageList &&
      dependentsLegalCoverageList.length > 0 &&
      currentStep == 'LegalCoverage'
    ) {
      setDependentsCoverageTable(dependentsLegalCoverageList);
    }
    if (
      dependentsCriticalIllnessCoverageList &&
      dependentsCriticalIllnessCoverageList.length > 0 &&
      currentStep == 'CriticalIllness'
    ) {
      setDependentsCoverageTable(dependentsCriticalIllnessCoverageList);
    }
    if (dependentsPetPlusList && dependentsPetPlusList.length > 0 && currentStep == 'PetPlus') {
      setDependentsCoverageTable(dependentsPetPlusList);
    }
    if (
      dependentsPetAssureList &&
      dependentsPetAssureList.length > 0 &&
      currentStep == 'PetAssure'
    ) {
      setDependentsCoverageTable(dependentsPetAssureList);
    }

    if (
      dependentsHospitalIndemnityCoverageList &&
      dependentsHospitalIndemnityCoverageList.length > 0 &&
      currentStep == 'HospitalIndemnity'
    ) {
      setDependentsCoverageTable(dependentsHospitalIndemnityCoverageList);
    }
    if (
      dependentsExcessLiabilityCoverageList &&
      dependentsExcessLiabilityCoverageList.length > 0 &&
      currentStep == 'ExcessLiability'
    ) {

      setDependentsCoverageTable(dependentsExcessLiabilityCoverageList);
    }
    if (
      dependentsExcessLiabilityCoverageListUmUim &&
      dependentsExcessLiabilityCoverageListUmUim.length > 0 &&
      currentStep == 'ExcessLiability'
    ) {

      setDependentsCoverageTableUmUim(dependentsExcessLiabilityCoverageListUmUim);
    }
  }, [
    dependentsCoverageList,
    dependentsIDTheftCoverageList,
    dependentsLegalCoverageList,
    dependentsCriticalIllnessCoverageList,
    dependentsPetAssureList,
    dependentsHospitalIndemnityCoverageList,
    dependentsExcessLiabilityCoverageList,
    dependentsExcessLiabilityCoverageListUmUim,
    currentStep,
  ]);

  const insuredDependents = storeFromRedux?.enrollment?.enrollmentData?.contract?.insureds || [];

  /* Commented code to test that everything is working, before remove the code */
  useEffect(() => {
    if (insuredDependents.length > 0) {
      const principalBuid = dataFromRedux && dataFromRedux.person && dataFromRedux.person.bUID;
      const tableData = [];

      const gender = {
        gMale: 'Male',
        gFemale: 'Female',
        gUndisclosed: 'Undisclosed',
      };

      insuredDependents.forEach((dependent) => {
        const dependentData = {};
        const dependentBuid = dependent?.person?.identifier?.bUID;
        const insuredBuid = dependent?.bUID;
        const additionalData = dependent?.insured?.additionalData || [];
        const dependentInfo = dependent?.person?.data?.actor || {};
        const isStudent = additionalData.find(
          (additional) => additional.fieldName === 'ISCHFTSTUDENT'
        );
        let isDisabled = 'yes';
        if (dependent?.insured?.additionalData?.[0]?.valueCode) {
          isDisabled = dependent?.insured?.additionalData?.[0]?.valueCode.toLocaleLowerCase() == 'yes' ? 'yes' : 'no';
        }
        if (!dependent?.insured?.mainOwnerRelationshipToInsured) {
          return;
        }
        // if (dependentsInfo[dependentBuid]) {
        const liveInSameAddress = dependentsInfo && dependentsInfo.preferredContactChoice === 'pcActorContact' ? true : false;
        dependentData['mainBuid'] = insuredBuid;
        dependentData['id'] = dependentBuid;
        dependentData['dependentFirstName'] = dependentInfo && dependentInfo.shortName || '';
        dependentData['dependentMiddleName'] = dependentInfo && dependentInfo.middleName1 || '';
        dependentData['dependentLastName'] = dependentInfo && dependentInfo.name || '';
        dependentData['dependentRelationShip'] = relationship[dependent.insured.mainOwnerRelationshipToInsured];
        dependentData['dependentGender'] = gender[dependentInfo && dependentInfo.gender];
        dependentData['dependentDateOfBirth'] = moment(dependentInfo && dependentInfo.birthDate).format('MM-DD-YYYY');
        dependentData['liveInSameAddress'] = liveInSameAddress;
        dependentData['isDependent'] = true;
        dependentData['data'] = {
          ...dependent,
          ...dependentsInfo[dependentBuid],
          isStudent: isStudent ? 'yes' : 'no',
          isDisabled: isDisabled,
        };
        tableData.push(dependentData);
      });
      dispatch(SET_DEPENDENT_BENEFICIARY_LIST({ data: tableData, isDependent: true }));
    }
  }, [insuredDependents]);

  const parseContent = (formName) => {
    const elementsToShow = [];
    let currentContent = (displayContent && displayContent[formName]) || [];
    if (dependentsList) {
      if (dependentsList.length > 1) {
        currentContent = currentContent.filter((e) => e !== 'dependentTitle');
        currentContent.push('addedDependentMessage');
      } else {
        currentContent = currentContent.filter((e) => e !== 'addedDependentMessage');
        delete currentContent['addedDependentMessage'];
        currentContent.push('dependentTitle');
      }
    }

    if (dependentAdded) {
      setTimeout(() => {
        dispatch(SHOW_CONTENT_LOADER(false));
      }, 1000);
      currentContent.push('addedDependentTitle');
    } else {
      currentContent = currentContent.filter((e) => e !== 'addedDependentTitle');
    }
    const newContentObj = {};
    newContentObj[formName] = uniq(currentContent);
    setDisplayContent(newContentObj);
  };


  useEffect(() => {
    if (dependentsBeneficiaryList && beneficiariesProducts && dependentsList) {
      parseContent('DependentInformation');
      setLayoutData({
        dependentsBeneficiaryList,
        beneficiariesProducts,
        dependentsList,
      });
    }
  }, [
    dependentsBeneficiaryList,
    beneficiariesProducts,
    dependentsList,
  ]);

  const userName = { loginName: loginuserName };

  useEffect(() => {
    const contingentArray = [];
    const primaryArray = [];
    const beneficiaryObj = {
      "Primary": [],
      "Contingent": [],
    };
    if (dependentsBeneficiaryList.length > 0) {
      dependentsBeneficiaryList.forEach(dependentBeneficiary => {
        if (dependentBeneficiary && dependentBeneficiary.beneficiaryType === 'Primary') {
          primaryArray.push(dependentBeneficiary);
        } else if (dependentBeneficiary && dependentBeneficiary.beneficiaryType === 'Contingent') {
          contingentArray.push(dependentBeneficiary);
        }
      });
    }
    beneficiaryObj['Primary'] = primaryArray;
    beneficiaryObj['Contingent'] = contingentArray;
    setBeneficiaryTableData(beneficiaryObj);
  }, [dependentsBeneficiaryList]);

  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => SetContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);

  let testing = 'testing';
  useEffect(() => {
    const callAPI = async () => {
      if (content && content?.layout && content?.layout?.onLoadApiCall) {
        let onLoadCalls = content.layout.onLoadApiCall;

        let data = await dispatch(
          APIRequestParallel(onLoadCalls, {
            additionalInformation: {
              getListOfEligibleProducts,
              contractBuid,
              zipCode,
              testing,
              clientId,
            },
          })
        );
      }
    };
    try {
      callAPI();
      // setLayoutData({ data });
    } catch (error) {
      
    }
  }, [content, loggedUserEntity]);

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        {/*  <MessageComponent /> */}
        <div className="formWrapper">
          <BaseLayout formLayoutType={'EmployeeProfile'} layoutData={layoutData} />
        </div>
      </Suspense>
    </div>
  );
};

export default EmployeeProfile;