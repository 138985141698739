import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import axios from 'axios';
import Header from '../../../CustomSection/Component/Header/Header';
import * as Yup from 'yup';
import { SelectDropdown, TextInput } from '../../../CustomSection/Atoms';
import Loader from '../../../../Atoms/Loader';
import './styles.css';
import EnrollmentReportForm from './EnrollmentReportForm';
import CensusReportForm from './CensusReportForm';
import { MessageComponent } from '../../../../Atoms';
import { REMOVE_CURRENTSTEP_ALERT_MESSAGE, SET_ALERT_MESSAGE, UPDATE_LOADING } from '../../../../../Store/Slices/InsuredInfo';

const Reports = () => {
    const [activeTab, setActiveTab] = useState('enrollment');
    const [showAlert, setShowAlert] = useState(false); 
    const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
    const dispatch = useDispatch();
    // Function to render the form based on the active tab
    const renderForm = () => {
        switch (activeTab) {
            case 'enrollment':
                return <EnrollmentReportForm setShowAlert={setShowAlert} />;
            case 'census':
                return <CensusReportForm  setShowAlert={setShowAlert}/>;
            // Add cases for future report types here
            default:
                return null;
        }
    };

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
        setShowAlert(false);
    };

    function AlertBox({ onClose }) {
        // You can add your functions here, e.g., for handling the close button click
      
        return (
          <div className="HRReportScreen" style={{ maxHeight: '120px', overflow: 'auto' }}>
            <div className="col-9 mt-2 mb-2 mx-auto alert alert-dismissible fade show alert-danger" role="alert" id="getEnrollmentReport">
              <img 
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAABHNCSVQICAgIfAhkiAAAAflJREFUOE+tVdtRAjEUPYn4+FMrACsQOlgq0A7AP0U/6ABKwBkFP9cKxAqgA7ECoQL1zwcmngvJTlh2ZT/MTGbY5Obcc899oFBgtYCqAd7ugOkmc5Vl0AYOPjU6vIx4X03ZTPg93DG47tFJ+v0a4KVG2wIdGr7zsqc0JqU5BATzEqrWcAP0iX3ubp/AIegKIMFiGp9Cod3/QfxXeM5xlzYPBD3ztglgS4MRoAmDqI8lo01LtIXGmHYxQYU1uXBdlRAZgxHBakXBvDP/VmvUb+YYLwAvqJMmq1tDhm6dA5U9ip4WXhL2wR1mXKQyFuWBRV052k9keBQatRSG5F9mNuseVMC+NCOxmPUttQ6ck+GLYCgRlzXWHDB7oWaudCQEK6ByJ2BMmtqlzmnmLspYLZJhUQk9euAQVM7ywOSutcXkWGrofzBLUgJrS7SUcOQiLUtoTGJdShRtZOjDdCWxCD+rQxKGrkAbZFhLaxhqJndsx0TTfA2XxZmZZatQCROQaGoxzc3yf9WhOGe7Riud4qs9Kzl5Z5mdskj7spcbbL960fZzTTHiu/uVXvbe3bQ54ffaWEoz9GOOIT6GLZs3D6UmX7l7nH8TFvTMlU2Z81E6SibLIR93CSaRJSt3Yn9rNKUlaXAcPiD4MwdJvG0QF5rYed0i50X+U34B+Cb15ZPhFgYAAAAASUVORK5CYII=" 
                className="img-fluid pr-2" 
                id="alert-img" 
                alt="alert_img" 
                style={{ width: '26px',marginTop:'-3px' }}
              />
              <span className="pr-3">Unable to process your request at this time. Please try again later.</span>
              <button 
                type="button" 
                className="close mt-1 error-close" 
                onClick={onClose}
                style={{ color: 'rgb(0, 44, 119)', fontSize: '0.875rem', fontFamily: 'NotoSansMedium' }}
                // onClick={handleClose} // You can define this function to handle the close action
              >
                CLOSE
              </button>
            </div>
          </div>
        );
      }
      
    return (
        <>
            <Header title={"View Reports"} />
            {
                isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />
            }
            {
                showAlert && <AlertBox onClose={() => setShowAlert(false)}  />
            }

           
            <div className='continer-fluid'>

                <div className="reports-tab-container   mx-3 mx-md-5  ">
                    <button type="button" className={`reports-tab ${activeTab === 'enrollment' ? 'active' : ''}`}
                        onClick={() => handleTabChange('enrollment')}>
                        Enrollment Report
                    </button>
                    <button type="button" className={`reports-tab ${activeTab === 'census' ? 'active' : ''}`}
                       onClick={() => handleTabChange('census')}>
                        Census Report
                    </button>
                </div>
                <div className="ml-3 ml-md-5">
                   
                        {renderForm()}
                   
                </div>

            </div>
        </>
    );
};

export default Reports;
