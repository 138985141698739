import React, { useEffect, useState } from 'react';
import { Styles } from './styles';
import { useSelector } from 'react-redux';

const Footer = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, setContent] = useState(null);
  const [imgSrc, setImgSrc] = useState('');

 

  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => {
        setContent(module.default);
        return import(`../../../Assets/Images/${module.default.companyLogo}`);
      })
      .then(module => {
        setImgSrc(module.default);
      })
      .catch(() => {
        setImgSrc('');
      });
  }, [currentLanguage]);


  useEffect(() => {
    window.addEventListener('resize', updateWindow);
    return () => {
      window.removeEventListener('resize', updateWindow);
    };
  }, []);

  const updateWindow = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  
  const size = 'width='+width+',height='+height; 



  const openWindow = (newWindow) => {
    if(newWindow) {
      let updatedWindow;
  
      if (newWindow.linkType === "href" && newWindow.url) {
        updatedWindow = newWindow.url;
      } else if(newWindow.to) {
        updatedWindow = process.env.PUBLIC_URL ?  process.env.PUBLIC_URL + newWindow.to : newWindow.to;
      } else {
        return; // exit if no appropriate link is found
      }
  
      window.open(updatedWindow,'_blank', size)
    }
  }
  

  let currentYear = (new Date().getFullYear())
  if (!content) return null; // Return null if content is not loaded
  return (
    <React.Fragment>
      <Styles />
      <footer className="footer footerClass">
        <div className="container-fluid px-md-5" >
          <div className={content.quickLinksRow} role="link">
          {content.quickLinks.map((quick) => (
          quick && quick.link ?
          (quick.link !== content.quickLinks[content.quickLinks.length - 1].link) ?
          <div key={quick.link} className={content.quickLinksCol}>
            <span className="span" role="button" tabIndex={0} onClick={() => openWindow(quick)} >{quick.link}</span>
          </div>
          :
          <div key={quick.link} className={content.quickLinksColLast} > 
            <span className="span" role="button" tabIndex={0} onClick={() => openWindow(quick)} >{quick.link}</span>
          </div>
          : null
        ))}
          </div>
          <div className={content.companyLogoRow}>
            <div className={content.companyLogoCol} > 
              <img
                src={imgSrc}
                alt="Mercer logo"
                className="clientLogo"
              />
            </div>
            <div className={content.copyRightsTextColClassName} >
              <p 
              style={{color: '#595959'}}
                className={content.copyRightsTextClassName}>
                  &copy; {currentYear}{' '}{content.copyRightsText}
              </p> 
            </div>              
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
