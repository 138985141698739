import React from 'react';
import PropTypes from 'prop-types';
import appConfig from '../../../appConfig';
import { useState, useEffect } from 'react';
import Loader from '../../Atoms/Loader';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../../Common/Services/HttpService';
import { onLoadAPI } from '../../../Store/Slices/Entities/response/index.js';
import _, { indexOf } from 'lodash';
import { SET_EMPLOYEE_INFO, SET_EMPLOYEE_RESULT, SET_FILTER_EMPLOYEE_CLICKED, SET_LOGIN_DATA, SET_REDIRECT_TO_ENROLLMENT, SET_SELECTED_PAGE } from '../../../Store/Slices/CallCenter';
import { CLEAR_EMPLOYEE_INFO, processTypeMFA, SELECTED_EMPLOYEE_INFO, SET_REDIRECT_FROM_EMP_SEARCH, apiReceivedMFAprocessType, START_PROXY_ENROLL, SET_CURRENT_URL, SET_REDIRECT_URL, START_ADMIN_ENDOSEMENT, RESET_EMP_FILTER } from '../../../Store/Slices/Auth/userAuth';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import formFields from './Content/SearchEmployeeData.json';
import PrimaryIdetifierHelper from '../../Utils/Helper/PrimaryIdetifierHelper';
import * as Yup from 'yup';
import { checkCharOnlyVariable } from '../../Utils/Helper/ValidationHelper';
import { UPDATE_LOADING } from '../../../Store/Slices/InsuredInfo';
import { SET_EMPLOYEE_DATA, CALL_CENTER_ENROLL_EMPLOYEE_LOGIN_DATA, IS_ADMIN, IS_HR_CENTER_RE_DIRECTED } from '../../../Store/Slices/CallCenter';
import {
  STORE_IN_REDUX, CONTRACT_LINIAR_OR_NON_LINIAR
} from '../../../Store/Slices/Process/index';
import { UPDATE_ENROLLMENT_TYPE } from '../../../Store/Slices/Enrollment/index';
import {
  SET_EMPLOYEE_PROFILE, UPDATE_PREFERRED_CONTACT
} from '../../../Store/Slices/EmployeeProfile';
import imageMap from '../CustomSection/Helper/imageHelper';
import { getFileName } from '../CustomSection/Helper/commonHelper';
import { initializeForms, initializeFormsData } from '../../../Store/Slices/StepFormNew';
import { apiReceivedUserTypeAndLanguage } from '../../../Store/Slices/Auth/userAuth';
import { processAuthTokenType } from '../../../Store/Slices/AuthToken/userAuthToken';
import EmployeeListModal from './EmployeeListModal';
import EmployeeListTable from './EmployeeListTable';
import FilterRecord from './FilterRecord.js'
import EmployeeRecordPagination from './EmployeeRecordPagination.js'
import { isEMpSsnDiff } from '../../../utils/helpers/isEMpSsnDiff.js';
import { ENROLLMENT_TYPE } from '../../../Store/Slices/EmployeeProfile';

const SearchEmployeeList = (props) => {

  const options = [
    { "value": "25", "text": "25" },
    { "value": "10", "text": "10" },
    { "value": "15", "text": "15" },
    { "value": "20", "text": "20" }
  ];


  const defaultValue = "25"
  const [showLoader, setShowLoader] = useState(false);
  const [employees, setEmployees] = useState();
  const [emp, setEmp] = useState();
  const [paginatedEmployee, setPaginatedEmployee] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(options[0].value);
  const [searchByEmployeeId, setSearchByEmployeeId] = useState('');
  const [searchBySSN, setSearchBySSN] = useState('');
  const [searchByLastName, setSearchByLastName] = useState('');
  const [searchByFirstName, setSearchByFirstName] = useState('');
  const [employeeListArray, setEmployeeListArray] = useState([]);
  const [employeesCount, setEmployeesCount] = useState();
  const [showMaskedInput, setShowMaskedInput] = useState(false);
  // const [selectedNumber, setSelectedNumber] = useState(options[0].value || 0);
  const [selectedNumber, setSelectedNumber] = useState(25);

  const [empData, setEmpData] = useState();
  const [empContactData, setContactData] = useState();
  const [empEmploymentData, setEmploymentData] = useState();
  const [employerName, setEmployerName] = useState();
  const [employeeLegalEntity, setEmployeeLegalEntity] = useState();
  const [employeeId, setEmpolyeeId] = useState();
  const [correspondences, setCorrespondences] = useState();

  const [enrollmentProcess, setEnrollmentProcess] = useState();
  const [endorsemntProcess, setEndorsemntProcess] = useState();
  const [empInformation, setEmpInformation] = useState();
  const [oneForceData, setOneForceData] = useState({})


  const [dateValue, setDate] = useState("");
  const [activeEffectActiveDate, setActiveEffectActiveDate] = useState("");
  const [backDateMsg, setBackDateMsg] = useState(false);

  const employeeBuid = useSelector(
    (storeState) => storeState?.callCenter?.selectedEmployeeData?.buid
  );
  let { data: constantsList } = useSelector((storeState) => storeState.entities.constants);

  const dispatch = useDispatch();
  const history = useHistory();
  const primaryIdetifierHelper = new PrimaryIdetifierHelper();
  localStorage.removeItem("usertType");
  localStorage.removeItem("language");
  window.localStorage.removeItem('usertType');
  const { clientName = '', clientBuid = '', selectedPage, email = '', redirectToEnrollment = false, extension = '', setFilterEmployeeClicked = false,
    setEmployeeResult = false, rediretToClientSearch = false } = useSelector((storeState) => storeState.callCenter);
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const { userType, employeeEmail, resetEmpFilter = false } = useSelector((storeState) => storeState.auth.userAuth);
  const isLoading = useSelector((storeState) => storeState.insuredInfo.isLoading);
  // const appCode = useSelector((storeState) => storeState.registeration.appCode);
  const registerationRedux = useSelector((storeState) => storeState?.registeration);
  const { userAuth } = useSelector((storeState) => storeState.auth);
  const userTypes = userAuth?.userType
  const isHrCenter = userAuth?.userType === "HrCenter" || userAuth?.processType === "HrCenter"
  let employeesListFromAPI;
  let employeesList = [];
  let lastIndex = currentPage * pageSize;
  let firstIndex = lastIndex - pageSize;

  // check all the condition
  const employeeProfile = useSelector((storeState) => storeState?.employeeProfile?.employeeProfile);
  const resetSearchEmplyeeFields = () => {
    formik.setFieldValue(formFields.employeeId.name, '');
    formik.setFieldValue(formFields.employeeSSN.name, '');
    formik.setFieldValue(formFields.employeeLastName.name, '');
    formik.setFieldValue(formFields.employeeFirstName.name, '');
  };

  const handleSSNChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    const formattedValue = value
      .slice(0, 9)
      .replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, (match, p1, p2, p3) => {
        return [p1, p2, p3].filter((part) => part.length > 0).join('-');
      });
    formik.setFieldValue('employeeSSN', formattedValue);
    setSearchBySSN(e.target.value)
  };

  const handleSSNBlur = (e) => {
    e.target.value ?
      setShowMaskedInput(!formik.errors.employeeSSN) :
      setShowMaskedInput(false)
      ;
    formik.handleBlur(e);
  };

  const handleFilterOnEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFilter();
    }
  };

  const handleEmployeeIdChange = (e) => {
    const value = e.target.value;
    formik.setFieldValue('employeeId', value);
    setSearchByEmployeeId(value)
  };

  const handleEmployeeIdBlur = (e) => {
    // e.target.value ?
    // setShowMaskedInput(!formik.errors.employeeSSN) :
    // setShowMaskedInput(false)
    // ;
    formik.handleBlur(e);
  };


  const validationSchema = Yup.object({
    employeeFirstName: Yup.string()
      .matches(/^[a-zA-Z]+$/, 'No special characters allowed'),

    employeeLastName: Yup.string()
      .matches(/^[a-zA-Z]+$/, 'No special characters allowed'),

    employeeSSN: Yup.string()
      .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must be in the format XXX-XX-XXXX'),

    employeeId: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, 'No special characters allowed'),

  });

  const formik = useFormik({
    initialValues: {
      employeeFirstName: '',
      employeeLastName: '',
      employeeSSN: '',
      employeeId: '',
    },
    validationSchema,
    // onSubmit: (values, { setSubmitting }) => {
    //   // 
    //   onSubmit(values);
    //   formik.resetForm();
    //   setSubmitting(false);
    // },

    enableReinitialize: true,
  });

  const maskedSSN = showMaskedInput ? `XXX-XX-${formik?.values?.employeeSSN?.slice(-4)}` : '';
  //const maskedSSNReadOnly = showMaskedReadOnly ? `XXX-XX-${formik?.values?.employeeSSN?.slice(-4)}` : '';


  const maskSSNReadOnly = (ssn) => {
    //ssn ? setShowMaskedInput(true) : setShowMaskedInput(false)
    return ssn ? `XXX-XX-${ssn?.slice(-4)}` : ''
  }


  // Admin endorsemnt code 

  const employeeSearchOnLoad = async () => {
    setShowLoader(true)
    return await http
      .post(appConfig.SSP_MID_URL + `/v1/callcenter-admin-endorcement`, {
        employeeBuid: employeeBuid,
        reasonEventDate: dateValue,

      })
      .then(async (resp) => {
        const data = resp?.data?.data
        for (const key in data) {
          const result = { _Result: data[key].data }
          switch (key) {
            case 'gbpcontractsLinearOrNonLinear':
              dispatch(CONTRACT_LINIAR_OR_NON_LINIAR(result))
              break;
            case 'getEmployeeData':
              setEmpInformation(result?._Result)
              break;
            case 'getMemberActiveContracts':
              let activeContractDatas = result?._Result && result?._Result?.filter(e => e?.status === 'cActive');
              if (activeContractDatas?.length > 0) {
                setActiveEffectActiveDate(activeContractDatas?.[1 - activeContractDatas?.length]?.effectiveDate)
              }
              dispatch(onLoadAPI({ ...{ [`getMemberActiveContracts`]: result } }));
              break;
            case 'subscribedcontracts':
              dispatch(onLoadAPI({ ...{ [`subscribedcontracts`]: result } }));
              break;
            case 'initNewEnrollmentProcess':
              setEnrollmentProcess(data[key].data)
              let UpdatedValue = []
              UpdatedValue.push(result)
              dispatch(STORE_IN_REDUX(UpdatedValue))
              break;
            case 'getEnrollments':
              dispatch(onLoadAPI({ 'getCurrerntYearEnrollments': result }));
              break;
            case 'initEndorsementProcess':
              if (data[key]?.success) {
                setEndorsemntProcess(result)
                dispatch(onLoadAPI({ ...{ [`AdminEndorsementProcess`]: result } }));
                dispatch(ENROLLMENT_TYPE({adminReasonType: 'ADE'}));
                setBackDateMsg(false);
              } else {
                if (String(data[key]?.data?.error?.innererror?.detailsFromException?.error?.errorMessage).search('No Endorsement process found for this contract')) {
                  setBackDateMsg(true);
                }
              }
              break;
            default:
              dispatch(onLoadAPI({ [key]: result }));
          }
        }
        setShowLoader(false)
        return true;
      })
      .catch((e) => {
        setShowLoader(false)
        return false;
      });
  };
  useEffect(() => {
    if (backDateMsg && !showLoader) {
      $('#adminModal').modal('show');
    }
  }, [backDateMsg, showLoader])
  useEffect(() => {
    let personData = empInformation?.person?.data?.actor;;
    let contactData = empInformation?.person?.data?.correspondences[0]?.address;
    let employmentData = empInformation?.employee;
    let employerData = empInformation?.legalEntity?.identifier?.description?.replace(/\(.*?\)/g, '')?.trim()
    let employerId = empInformation?.employeeId
    let data = empInformation?.person?.data
    let externalClientId = empInformation?.legalEntity?.identifier?.externalClientId?.externalClientId
    setCorrespondences(empInformation?.person?.data?.correspondences?.[0])
    setEmpData(personData);
    setContactData(contactData);
    setEmploymentData(employmentData);
    setEmployerName(employerData);
    setEmpolyeeId(employerId);
    setEmployeeLegalEntity(empInformation?.legalEntity)
    let getPayFrequency = employmentData?.additionalDynamicDatas?.find(eachObj => eachObj?.value?.fieldCode === "EMPD_00002") || '';
    let getEmploymentStatus = employmentData?.additionalDynamicDatas?.find(eachObj => eachObj?.value?.fieldCode === "EMPD_00092") || '';
    let payFreqeuncyValueCode = employmentData?.additionalDynamicDatas?.find(eachObj => eachObj?.value?.fieldCode === "EMPD_00002") || '';
    let personalInformation = {
      "firstName": personData?.shortName,
      "middleName": personData?.middleName1 || '',
      "lastName": personData?.name,
      "gender": personData?.gender,
      "ssn": personData?.sSN || '',
      "buid": personData?.buid,
      "dateOfBirth": personData?.birthDate,
      "line1": contactData?.line1,
      "line2": contactData?.line2,
      "zipCode": contactData?.zipCodeAndCity?.zipCode,
      "city": contactData?.zipCodeAndCity?.city,
      "state": contactData?.state,
      "country": contactData?.country,
      "alternateAddressLine1": "",
      "alternateAddressLine2": "",
      "alternateAddressZipCode": "",
      "alternateAddressCity": "",
      "alternateAddressState": "",
      "alternateAddressCountry": "",
      "primaryAddress": contactData?.line1 + ' ' + contactData?.zipCodeAndCity?.city + ' ' + contactData?.state + ' ' + contactData?.zipCodeAndCity?.zipCode + ' ' + contactData?.country,
      "officePhone": empInformation?.person?.data?.correspondences?.[0]?.officePhone,
      "workEmail": empInformation?.person?.data?.correspondences?.[0]?.optionalEmail,
      "registeredEmail": "",
      "personalEmail": empInformation?.person?.data?.correspondences?.[0]?.eMail,
      "personalPhone": empInformation?.person?.data?.correspondences?.[0]?.privatePhone,
      "mediaType": "",
      "liveInSameAddress": personData?.preferredContactChoice,
      "primaryAddressLine1Display": contactData?.line1,
      "primaryAddressLine2Display": "",
      "primaryAddressLine3Display": contactData?.zipCodeAndCity?.city + ' ' + contactData?.state + ' ' + contactData?.zipCodeAndCity?.zipCode + ' ' + contactData?.country,
    }
    let employmentInformation = {
      "employerName": employerData,
      "workLocation": getWorkLocation(employmentData),
      "salary": employmentData?.baseSalaryAmount?.amount,
      "payFrequency": getPayFrequency?.value?.fieldValue,
      "employmentStatus": getEmploymentStatus?.value?.fieldValue,
      "payFreqeuncyValueCode": payFreqeuncyValueCode?.value?.valueCode,
      "employeeId": employeeBuid
    }

    let salesForceData = {
      personalInformation,
      employerId,
      externalClientId
    }
    setOneForceData(salesForceData)
    dispatch(SET_EMPLOYEE_PROFILE(personalInformation));
    dispatch(
      onLoadAPI({
        PersonalInformation: { personalInformation },
        isAPICallInProgress: false,
      })
    );
    dispatch(onLoadAPI({
      EmploymentInformation: { employmentInformation }
    })
    );
    dispatch(CALL_CENTER_ENROLL_EMPLOYEE_LOGIN_DATA({ data: { person: data } }))

  }, [empInformation])
  useEffect(() => {
    if (enrollmentProcess) {
      postSalesForceBearerToken()
      dispatch(initializeForms({}));
      dispatch(initializeFormsData({}));
      history.push("/enrollment/employeeProfile");
      if (isHrCenter) {
        dispatch(IS_HR_CENTER_RE_DIRECTED(true))
      }
    } else if (endorsemntProcess?._Result?.bUID) {
      dispatch(initializeForms({}));
      dispatch(initializeFormsData({}));
      history.push("/enrollment/employeeProfile");
      dispatch(UPDATE_ENROLLMENT_TYPE("openEnrollment"));
      if (isHrCenter) {
        dispatch(IS_HR_CENTER_RE_DIRECTED(true))
      }
    }
  }, [enrollmentProcess, endorsemntProcess])

  //Code to get work location
  const getWorkLocation = (location) => {
    let locCode = location?.additionalDynamicDatas?.filter(
      (data) => data?.value?.fieldCode == 'EMPD_00058'
    );

    let classValue = locCode && locCode[0]?.value?.className;
    if (locCode == undefined) {
      return '';
    } else {
      if (classValue == 'aSPCB_ClassificationValue') {
        return locCode[0]?.value?.fieldValue;
      } else if (classValue == 'aSPCB_AdditionalDataValue_CString') {
        return locCode[0]?.value?.fieldValue;
      }
    }
  };
  //Code to get work location
  const getPayFrequency = (frequency) => {
    let value;
    let obj = constantsList['FrequencyConstants']?.find((o) => o.value === frequency);
    if (obj) value = obj['text'];

    return value;
  };


  //Code to get employment status value
  const getEmploymentStatus = (empStatus) => {
    let value;
    let obj = constantsList['EmploymentStatusConstants']?.find((o) => o.value === empStatus);
    if (obj) value = obj['text'];

    return value;
  };
  const getDivisionCode = (divcode) => {
    let divCode = divcode?.additionalDynamicDatas?.filter(
      (data) => data?.value?.fieldCode == 'EMPD_00016'
    );
    let classValue = divCode && divCode[0]?.value?.className;
    if (divCode == undefined) {
      return '';
    } else {
      if (classValue == 'aSPCB_ClassificationValue') {
        return divCode[0]?.value?.fieldValue;
      } else if (classValue == 'aSPCB_AdditionalDataValue_CString') {
        return divCode[0]?.value?.fieldValue;
      }
    }
  };

  let salesforceAPITimeout = 10000
  const postSalesForceBearerToken = async () => {
    let employeeIdFromRedux = oneForceData?.employerId || employeeBuid
    let dataFromReduxPersonalInformation = oneForceData?.personalInformation
    dataFromReduxPersonalInformation = (dataFromReduxPersonalInformation == undefined ? employeeProfile : dataFromReduxPersonalInformation)
    let legalEntityData = oneForceData?.externalClientId
    let legalEntityDataBackup = registerationRedux?.registeration?.legalEntityId;
    let oneCode = legalEntityDataBackup ? (/[^:]*/.exec(legalEntityDataBackup)[0]) : legalEntityData
    let contactDtailsAPI = {}
    let personalDetails = primaryIdetifierHelper.getPersonalDetails(employeeIdFromRedux, dataFromReduxPersonalInformation, oneCode);
    setShowLoader(true)
    await http
      .post(appConfig.SSP_MID_URL + '/getCapturePreferenceAPI', personalDetails, {
        timeout: salesforceAPITimeout
      })
      .then(async (resp) => {
        contactDtailsAPI.personalPhone = resp.data.smsPhoneNumber
        contactDtailsAPI.personalEmail = resp.data.emailAddress
        contactDtailsAPI.preferredContact = resp.data.preferredMethodOfCommunication
        contactDtailsAPI.clientSMSFlag = resp.data.clientSMSFlag
        contactDtailsAPI.clientEmailFlag = resp.data.clientEmailFlag
        contactDtailsAPI.contactId = resp.data.contactId
        contactDtailsAPI.OfferPreferenceUpdate = resp.data.OfferPreferenceUpdate
        dispatch(UPDATE_PREFERRED_CONTACT(contactDtailsAPI))
        setShowLoader(false)
      })
      .catch((e) => {
        setShowLoader(false)

      });
  };

  const adminEndorsementModel = (data) => {
    setBackDateMsg(false);
    dispatch(SET_EMPLOYEE_DATA({ selectedEmployeeData: data }))
    $('#adminModal').modal('show');
    dispatch(IS_ADMIN(true))
    setDate('')
  };
  const cancelModal = () => {
    setBackDateMsg(false);
    $('#adminModal').modal('hide');
    dispatch(IS_ADMIN(false))
    setDate('')
  };
  const handleSubmitAdmin = () => {
    employeeSearchOnLoad()
  }

  const onDateChange = (event) => {
    setBackDateMsg(false);
    setDate(event.target.value);
  };
  const itemsPerPage = pageSize;
  const totalItems = employeesCount; // Example total items

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  let countText = selectedNumber < employeesCount ?
    `${startItem} of ${endItem}` :
    `${employeesCount} of ${employeesCount}`
  const pagination = (pageNo) => {
    setCurrentPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    const paginatedEmp = _(employees).slice(startIndex).take(pageSize).value(); // data to display/page
    setPaginatedEmployee(paginatedEmp);
  };
  const resetPagination = (pageNo) => {
    setCurrentPage(pageNo);
    // const startIndex = (pageNo -1) * pageSize;
    // const paginatedEmp = _(employees).slice(startIndex).take(pageSize).value(); // data to display/page
    // setPaginatedEmployee(paginatedEmp);
  };
  const getEmployeesAPI = () => {
    setShowLoader(true);
    let empParam;
    let ssn = searchBySSN?.trim() === '' ? `SSN=&` : `SSN=${searchBySSN?.trim()}&`;
    let firstName = searchByFirstName?.trim() === '' ? `FirstName=&` : `FirstName=${searchByFirstName?.trim()}&`;
    let lastName = searchByLastName?.trim() === '' ? `SelectionArgument=&` : `SelectionArgument=${searchByLastName?.trim()}&`;
    let empId = searchByEmployeeId?.trim() === '' ? `employeeId=&` : `employeeId=${searchByEmployeeId?.trim()}&`;
    let buid = clientBuid === '' ? `legalentity=&` : `legalentity=${clientBuid}`;
    empParam = `${ssn}${firstName}${lastName}${empId}${buid}`

    http.setHeaderValue('limitrange', true)

    const res = http
      .post(appConfig.SSP_MID_URL + '/getEmployees', {
        additionalInformation: {
          empParam: empParam
        }
      })
      .then((resp) => {
        if (resp?.data?._Result) {
          employeesListFromAPI = resp?.data?._Result;
          setEmployeesCount(employeesListFromAPI?.length)
          employeesList = employeesListFromAPI
          setEmployeeListArray(employeesList)
          setEmployees(employeesList);
          setEmp(employeesList)
          setPaginatedEmployee(_(employeesList).slice(0).take(pageSize).value());
          setShowLoader(false);
          dispatch(apiReceivedUserTypeAndLanguage({ userType: userTypes }));

        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        console.log(` error in getEmployeesAPI ${error}`)
      });
    // setShowLoader(false);
    // return resp
  };

  const getEmployeesAPIReset = () => {
    setShowLoader(true);
    let empParam;
    let ssn = `SSN=&`;
    let firstName = `FirstName=&`;
    let lastName = `SelectionArgument=&`;
    let empId = `employeeId=&`;
    let buid = clientBuid === '' ? `legalentity=&` : `legalentity=${clientBuid}`;
    empParam = `${ssn}${firstName}${lastName}${empId}${buid}`
    const resp = http
      .post(appConfig.SSP_MID_URL + '/getEmployees', {
        additionalInformation: {
          empParam: empParam
        }
      })
      .then((resp) => {
        if (resp?.data?._Result) {
          employeesListFromAPI = resp?.data?._Result;
          setEmployeesCount(employeesListFromAPI?.length)
          for (let i = 0; i < employeesListFromAPI.length; i++) {
            const element = employeesListFromAPI[i];
            employeesList[i] = element
          }
          setEmployeeListArray(employeesList)
          setEmployees(employeesList);
          setEmp(employeesList)
          setPaginatedEmployee(_(employeesList).slice(0).take(pageSize).value());
          setShowLoader(false);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        console.log(` error in getEmployeesAPI ${error}`)
      });
  };

  useEffect(() => {
    dispatch(UPDATE_LOADING(true));

    getEmployeesAPI();
    dispatch(SET_SELECTED_PAGE(1));
    pagination(1)
    dispatch(CLEAR_EMPLOYEE_INFO())
    if (isHrCenter) {
      dispatch(processTypeMFA({ processType: 'HrCenter', userType: '', currentProcessType: 'SearchEmployee', currentURL: '/hrcenter/employeeSearch', redirectURL: '/hrcenter' }))
      dispatch(apiReceivedMFAprocessType("HrCenter"));
      dispatch(SET_CURRENT_URL('/hrcenter/employeeSearch'))
      dispatch(SET_REDIRECT_URL('/hrcenter'))
      dispatch(IS_HR_CENTER_RE_DIRECTED(false))
    } else {
      dispatch(processTypeMFA({ processType: 'callCenter', userType: '', currentProcessType: 'SearchEmployee', currentURL: '/callcenter/employeeSearch', redirectURL: '/callcenter/home' }))
      dispatch(apiReceivedMFAprocessType("callCenter"));
      dispatch(processAuthTokenType({ authTokenType: 'callCenter' }))
      dispatch(SET_CURRENT_URL('/callcenter/employeeSearch'))
      dispatch(SET_REDIRECT_URL('/callcenter/home'))
    }

    dispatch(SET_REDIRECT_TO_ENROLLMENT({ redirectToEnrollment: false, proxyUser: false }))
    dispatch(SET_REDIRECT_FROM_EMP_SEARCH(true))
    // dispatch(apiReceivedMFAprocessType("CallCenter"));


    dispatch(START_PROXY_ENROLL(false));

    //dispatch(RESET_EMP_FILTER(false))
    dispatch(UPDATE_LOADING(false));
    //setShowLoader(false);

  }, []);

  const pageCount = employees ? Math.ceil(employees.length / pageSize) : 0;
  const pages = _.range(1, pageCount + 1)
  let filename = 'Icon-Menu-Dots-Mercer.png';

  let alt = 'menu'
  let imgSrc;
  try {
    imgSrc = imageMap[getFileName(filename)];
  } catch (e) {
    imgSrc = '';
  };

  const handleFilter = () => {
    getEmployeesAPI();
    dispatch(SET_FILTER_EMPLOYEE_CLICKED(true))
    // dispatch(SET_EMPLOYEE_RESULT(res.length > 0))
    dispatch(RESET_EMP_FILTER(false))

  }

  const handleResetFilter = () => {
    setShowMaskedInput(false)
    resetSearchEmplyeeFields()

    setSearchByEmployeeId('');
    setSearchBySSN('');
    setSearchByLastName('');
    setSearchByFirstName('');
    setPaginatedEmployee([]);
    dispatch(SET_EMPLOYEE_RESULT(true))
    getEmployeesAPIReset();
  }

  const getBasicAuthHeaderValue = () => {
    let authValue = process.env.REACT_APP_MFA_AUTH_USER;

    // Encoding into base64 using btoa
    let basicAuthHeaderValue = authValue ? btoa(authValue) : '';

    return basicAuthHeaderValue;
  };

  const validateAndGetEmail = (log) => {
    for (let i = 0; i < log.length; i++) {
      {/** Register required: 
        login: {eid}_{LE}:FO
        name:mfa_{EID}_{LE}:FO
        email:test@gmail.com  or name
     */}

      if (log[i].loginEntity.name.startsWith(process.env.REACT_APP_LOGIN_PREFIX) || log[i].loginEntity.name.startsWith(process.env.REACT_APP_EMAIL_SSO_PREFIX)) {
        let loginWithoutPrefix = log[i].loginEntity.name.split('_')[1]
        return loginWithoutPrefix; // log[i].loginEntity.name.slice(4)
      }
    }
    return false
  }

  const validateAndGetEmailNotRegistered = (log, eid, legalEntity) => {
    //check if it contains contains the legalentiry id different member id and ssn
    // if (isEMpSsnDiff(legalEntity)) {
    //   return `${eid}_${legalEntity}`;
    // }
    {/** Register not required */ }
    for (let i = 0; i < log.length; i++) {
      if (log[i].loginEntity.email) {
        const splittedLogin = log[i].loginEntity.email.split('_');
        let login = splittedLogin[splittedLogin.length - 2] + '_' + splittedLogin[splittedLogin.length - 1];
        let correctEid = splittedLogin[splittedLogin.length - 2] === eid;
        let correctLE = splittedLogin[splittedLogin.length - 1] === legalEntity;
        let correctLogin = correctEid && correctLE;
        if (correctLogin)
          return login
      }
      if (log[i].loginEntity.name) {
        const splittedName = log[i].loginEntity.name.split('_');
        let name = splittedName[splittedName.length - 2] + '_' + splittedName[splittedName.length - 1];
        let correctEid = splittedName[splittedName.length - 2] === eid;
        let correctLE = splittedName[splittedName.length - 1] === legalEntity;
        let correctLogin = correctEid && correctLE;
        if (correctLogin)
          return name
      }
      if (log[i].loginEntity.fullName) {
        const splittedName = log[i].loginEntity.fullName.split('_');
        let name = splittedName[splittedName.length - 2] + '_' + splittedName[splittedName.length - 1];
        let correctEid = splittedName[splittedName.length - 2] === eid;
        let correctLE = splittedName[splittedName.length - 1] === legalEntity;
        let correctLogin = correctEid && correctLE;
        if (correctLogin)
          return name
      }
      if (isEMpSsnDiff(legalEntity) && log[i].loginEntity.name) {
        let name = log[i].loginEntity.name
        return name
      }
    }
    return false
  }

  const handleResetUsernamePassword = (buid, eid, employmentStatus, legalEntityId) => {
    if (employmentStatus === 'esActive') {
      setShowLoader(true);
      let basicAuthorizationValue = getBasicAuthHeaderValue();
      //1. check if user reset or not.
      http
        .get(appConfig.SSP_AUTH_URL + `/stateful/user-mgmt/get-userstatus`, {
          params: {
            employeeId: eid,
            legalEntityId: clientName.substring(0, clientName.indexOf(':')) + extension,//legalEntityId,
          },
          withCredentials: true,
          headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200),
          },
        })
        .then((res) => {
          if (!res.data.disabled && res.data.username) {
            //2. get user email.
            const resp = http
              .post(appConfig.SSP_MID_URL + '/getEmployeeData', {
                //.post(appConfig.SSP_MID_URL + '/getEmpData', {
                additionalInformation: {
                  //employeebuid: buid
                  personBuid: buid
                }
              })
              .then((resp) => {
                let logins = resp.data._Result.person.data.logins;
                let validatedEmail = validateAndGetEmail(logins)
                if (validatedEmail) {
                  //3. reset username/password.
                  let data = { email: validatedEmail }
                  http
                    .post(appConfig.SSP_AUTH_URL + `/stateful/user-mgmt/delete-user`, data, {
                      withCredentials: true,
                      headers: {
                        Authorization: `Basic ${basicAuthorizationValue}`,
                        'X-REQUEST-ID': Math.random(200),
                      },
                    })
                    .then((res) => {
                      setShowLoader(false);
                    })
                    .catch((error) => {
                      setShowLoader(false);

                    });
                }
                else
                  $('#userNameNotFound').modal('show')
                setShowLoader(false);
                $('#restUsernamePassword').modal('show')
              })
              .catch((error) => {
                setShowLoader(false);

              })
          }
          else
            if (res.data.disabled && res.data.username) {
              setShowLoader(false);
              $('#userNamePasswordReset').modal('show')//userResetDone
            }
        })
        .catch((error) => {
          setShowLoader(false);

          if (error.message === "Request failed with status code 503")
            $('#networkError').modal('show')
          else
            $('#empNotRegistered').modal('show')
        });
    }
    else
      $('#employeeNotActive').modal('show')
  }

  const handleRetrieveUsername = (buid, eid, employmentStatus) => {
    if (employmentStatus === 'esActive') {
      dispatch(UPDATE_LOADING(true));

      let basicAuthorizationValue = getBasicAuthHeaderValue();
      const resp = http
        .get(appConfig.SSP_AUTH_URL + `/stateful/user-mgmt/get-userstatus`, {
          params: {
            employeeId: eid,
            legalEntityId: clientName.substring(0, clientName.indexOf(':')) + extension
          },
          withCredentials: true,
          headers: {
            Authorization: `Basic ${basicAuthorizationValue}`,
            'X-REQUEST-ID': Math.random(200),
          },
        })
        .then((res) => {
          // $('#userName').modal('show')
          if (!res.data.disabled && res.data.username) {
            //2. get employee email
            dispatch(UPDATE_LOADING(true));
            const resp = http
              //  .post(appConfig.SSP_MID_URL + '/getEmpData', {
              .post(appConfig.SSP_MID_URL + '/getEmployeeData', {
                additionalInformation: {
                  // employeebuid: buid
                  personBuid: buid
                }
              })
              .then((resp) => {
                if (resp) {
                  let logins = resp.data._Result.person.data.logins;
                  let validatedEmail = validateAndGetEmail(logins)
                  if (validatedEmail) {
                    dispatch(SET_EMPLOYEE_INFO({ email: validatedEmail, firstName: '', lastName: '' }));
                    dispatch(SELECTED_EMPLOYEE_INFO({ email: validatedEmail, firstName: '', lastName: '' }));
                    setShowLoader(false);
                    $('#userName').modal('show')
                  }
                  else {
                    //setShowLoader(false);
                    $('#userNameNotFound').modal('show')
                  }
                  dispatch(UPDATE_LOADING(false));
                }
              })
              .catch((error) => {

                //setShowLoader(false);
                dispatch(UPDATE_LOADING(false));
              });
            dispatch(UPDATE_LOADING(false));
          }
          else
            if (res.data.disabled) {
              dispatch(UPDATE_LOADING(false));
              $('#empDisabled').modal('show')
            }

        })
        .catch((error) => {

          dispatch(UPDATE_LOADING(false));
          if (error.message === "Request failed with status code 503")
            $('#networkError').modal('show')
          else
            $('#empNotRegistered').modal('show')
        })
    }
    else
      $('#employeeNotActive').modal('show')
  }

  const handleProxyEnrollment = (buid, eid, firstName, lastName, employmentStatus) => {
    if (buid && eid && firstName && lastName)
      if (employmentStatus === 'esActive') {
        setShowLoader(true);
        dispatch(START_PROXY_ENROLL(true));

        let basicAuthorizationValue = getBasicAuthHeaderValue();
        let legalEntity = clientName.substring(0, clientName.indexOf(':')) + extension

        //2. get employee email
        const resp = http
          .post(appConfig.SSP_MID_URL + '/getEmployeeData', {
            additionalInformation: {
              personBuid: buid
            }
          })
          .then((resp) => {
            if (resp) {
              let logins = resp.data?._Result?.person?.data?.logins;
              logins && dispatch(SET_LOGIN_DATA({ logins: logins, eid: eid, legalEntity: legalEntity }))
              let data = resp?.data?._Result?.person?.data

              let validatedEmail = validateAndGetEmailNotRegistered(logins, eid, legalEntity)
              if (validatedEmail) {
                dispatch(SET_EMPLOYEE_INFO({ email: validatedEmail, firstName: firstName, lastName: lastName }));
                dispatch(SELECTED_EMPLOYEE_INFO({ email: validatedEmail, firstName: firstName, lastName: lastName }));
                dispatch(processTypeMFA({ processType: 'proxyEnrollment', userType: 'Enrollment', language: currentLanguage }))
                dispatch(SET_REDIRECT_TO_ENROLLMENT({ redirectToEnrollment: true, proxyUser: true }))
                setShowLoader(false);
                dispatch(CALL_CENTER_ENROLL_EMPLOYEE_LOGIN_DATA({ data: { person: data } }))
                if (isHrCenter) {
                  dispatch(IS_HR_CENTER_RE_DIRECTED(true))
                }
                //dispatch(apiReceivedMFAUpdate('mfa_'+validatedEmail))
                history.push('/enrollment/home')
              }
              else {
                setShowLoader(false);
                dispatch(SET_REDIRECT_TO_ENROLLMENT({ redirectToEnrollment: false, proxyUser: false }))
                $('#userNameNotFound').modal('show')
              }
            }
          })
          .catch((error) => {
            setShowLoader(false);

          });
      }
      else
        $('#employeeNotActive').modal('show')
    else
      $('#employeeInfoNotComplete').modal('show')

  }

  if (showLoader) {
    return <Loader loading={showLoader} />;
  }
  return (
    <>
      {isLoading && <Loader loading={isLoading} className="loaderUpdateCls" />}

      <div>
        <div className="configClassRow">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className='searchComponentContainer container-fluid px-md-5'>
                    <FilterRecord
                      formFields={formFields}
                      formik={formik}
                      handleEmployeeIdChange={handleEmployeeIdChange}
                      handleEmployeeIdBlur={handleEmployeeIdBlur}
                      handleFilterOnEnter={handleFilterOnEnter}
                      showMaskedInput={showMaskedInput}
                      handleSSNChange={handleSSNChange}
                      handleSSNBlur={handleSSNBlur}
                      searchByLastName={searchByLastName} fhandleResetFilter
                      searchByFirstName={searchByFirstName}
                      checkCharOnlyVariable={checkCharOnlyVariable}
                      setSearchByLastName={setSearchByLastName}
                      setSearchByFirstName={setSearchByFirstName}
                      handleFilter={handleFilter}
                      handleResetFilter={handleResetFilter}
                      setShowMaskedInput={setShowMaskedInput}
                    />


                    <EmployeeRecordPagination
                      dispatch={dispatch}
                      paginatedEmployee={paginatedEmployee}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      options={options}
                      countText={countText}
                      currentPage={currentPage}
                      pageCount={pageCount}
                      pagination={pagination}
                      setPaginatedEmployee={setPaginatedEmployee}
                      setCurrentPage={setCurrentPage}
                    />

                    <EmployeeListTable
                      employeesCount={employeesCount}
                      paginatedEmployee={paginatedEmployee}
                      handleResetUsernamePassword={handleResetUsernamePassword}
                      handleProxyEnrollment={handleProxyEnrollment}
                      handleRetrieveUsername={handleRetrieveUsername}
                      adminEndorsementModel={adminEndorsementModel}
                      isHrCenter={isHrCenter}
                      maskSSNReadOnly={maskSSNReadOnly}
                      dispatch={dispatch}
                    />

                    <EmployeeListModal
                      dateValue={dateValue}
                      onDateChange={onDateChange}
                      backDateMsg={backDateMsg}
                      employeeEmail={employeeEmail}
                      cancelModal={cancelModal}
                      handleSubmitAdmin={handleSubmitAdmin}
                      dispatch={dispatch}
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className="col-sm-4"></div>
          </div>
        </div>
      </div>
    </>
  );
};

SearchEmployeeList.propTypes = {
  elements: PropTypes.array,
  allFormData: PropTypes.object,
  updateFormData: PropTypes.func,
  tableRowMapper: PropTypes.func,
  tableHeaders: PropTypes.arrayOf(PropTypes.string),
};
SearchEmployeeList.defaultProps = {
  elements: {},
  allFormData: {},
  tableHeaders: [],
  setAllFormData: () => { },
  tableRowMapper: () => { },
};

export default SearchEmployeeList;