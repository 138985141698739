import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = (props) => {
  const { icon, label, size, isFa, alt, ...rest } = props;

  return (
    <>
      {icon && <FontAwesomeIcon icon={icon} size={size} {...rest} />}
      {label && <h5 className="mt-2">{label}</h5>}
    </>
  );
};
Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  label: PropTypes.string,
  size: PropTypes.string,
};

export default Icon;
