import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import ServiceHelper from '../../../App/Utils/Helper/MappingHelper';
import { useSelector, useDispatch } from 'react-redux';
import { reduxConstants } from '../../../Common/Constants/index';
import $ from 'jquery';
import {
  STORE_COUNTRY,
  STORE_LINE2,
  STORE_STATE,
  STORE_EDIT_STATE,
} from '../../../Store/Slices/HideShowRow';
import {
  HOSPITAL_SECTION,
} from '../../../Store/Slices/EmployeeProfile';
import { CHANGE_SCENARIO, DISABLE_ANSWERS_RADIO_STATUS } from '../../../Store/Slices/InsuredInfo';

import { relationship } from '../../../utils/relationship';

const Select = (props) => {
  const {
    id,
    name,
    label,
    selectedValue,
    onChange,
    onBlur,
    className,
    classNameWrapper,
    containerClassName = '',
    disabled,
    required,
    requiredValidation,
    requiredVal,
    leftTextAddOn,
    element = {},
    multipleSelect,
    shouldShowOnYes,
    shouldHideOnCheckBox,
    isEditModal = false,
    hasEditError = false,
    //currentStep,
    dataFromRedux,
    currentForm
  } = props;
  const dispatch = useDispatch();
  const serviceHelper = new ServiceHelper();
  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.uiPath)) || {};
  let { onLoadResponseList } = useSelector((storeState) => storeState.entities.response);
  let { Providers } = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList);
  let { CatBreeds, DogBreeds } = useSelector((storeState) => storeState?.entities?.response?.onLoadResponseList);
  let { selectedPet, dependentRelationShip } = useSelector((storeState) => storeState.employeeProfile);
  const prefOptionsList = useSelector((storeState) => storeState?.employeeProfile?.preferredContactDetails);
  const lifeEventValues = useSelector(
    (storeState) => storeState?.process?.contractsLinearOrNonLinear?._Result?.contract?.additionalDatas
  );


  // let optionsList = Providers._Result?._Providers


  let optionsList = Providers?._Result?.Providers;
  let optionsListCat = CatBreeds?._Result;
  let optionsListDog = DogBreeds?._Result;

  const insuredInfo = useSelector((storeState) => storeState?.insuredInfo);
  const { stepId, currentStep } = useSelector((storeState) => storeState.stepForm);

  const { hideEl, dependentAddress, liveAtSameAddress } = useSelector((storeState) => storeState.hideShowRow);
  let selectedoptionId;
  let CoverageSelection;
  let IdTheft;
  let SupplementalHealthInsurance;
  const { hospitalSection } = useSelector((storeState) => storeState?.employeeProfile);


  let optionsNew = []

  if (id == "PetAssureNation.petHospital") {


    optionsNew = serviceHelper.getOptionsFromRedux(optionsList);

  }

  if (id == "PetAssureNation.petBreed") {


    let optionListToDisplay;
    if (selectedPet == "cat") {
      optionListToDisplay = optionsListCat
    }
    else if (selectedPet == "dog") {
      optionListToDisplay = optionsListDog
    }
    optionsNew = serviceHelper.getOptionsFromReduxDogList(optionListToDisplay);


  }

  if (id == 'EmployeeProfile.preferredContact' || id == 'ManageProfile.preferredContact') {
    optionsNew = serviceHelper.getOptionsForPreferredContact(prefOptionsList)
  }




  selectedoptionId = stepId > 2 && insuredInfo[currentStep] ? insuredInfo[currentStep].selectedoptionId : null;
  CoverageSelection = stepId > 2 && insuredInfo[currentStep] ? insuredInfo[currentStep] : null;
  IdTheft = stepId > 2 && insuredInfo[currentStep] ? insuredInfo[currentStep] : [];
  SupplementalHealthInsurance = stepId > 2 && insuredInfo[currentStep] ? insuredInfo[currentStep] : [];
  /** Don't float label when the selected option has no display text */
  const [focused, setFocused] = useState(false);
  let [options, setoptions] = useState([]);
  let [defaultValue, setdefaultValue] = useState(props.defaultValue);
  let addOnWrapperClass = '';

  /** Set initial focus based on defaultValue */
  useEffect(() => {
    if (defaultValue) {
      props.formik &&
        onChange &&
        onChange({
          target: { name: name || id, value: selectedValue ? selectedValue : defaultValue },
        });
    }
  }, [defaultValue, selectedValue]);


  // useSelector((storeState) => {
  //   if (currentStep === "DependentInformation") {
  //     
  //   }

  //   return storeState.employeeProfile
  // });

  useEffect(() => {
    setFocused(selectedValue ? true : false);
  }, [selectedValue]);

  let preferredValue = prefOptionsList?.preferredContact

  useEffect(() => {
    if (currentStep === 'EmployeeProfile' || currentStep === 'ManageProfile') {
      defaultValue = preferredValue;
    }
  }, []);
  // for getting options

  useEffect(() => {
    if (props.options && props.options.length > 0) {
      setoptions(props.options);
    } else {
      const { optionKind = '', optionsConfig = {} } = element || {};
      if (!element.options && optionKind === 'apiCall') {
        const { mapperObject, reduxObject } = optionsConfig;
        let reduxObj = eval(reduxObject);
        if (Object.keys(reduxObj).length > 0) {
          let options = serviceHelper.getOptions(mapperObject, reduxObj);
          if (Object.keys(options).length > 0) {
            setoptions(options);
            setdefaultValue(defaultValue);
          }
        }
      }
    }
  }, [props.element, loggedUserEntity, onLoadResponseList, props.options]);

  useEffect(() => {
    if (hideEl && (id === 'DependentInformation.dependentState' || id === 'CriticalIllness.beneficiaryState')) {
      dispatch(STORE_STATE(selectedValue));
    }
  }, [hideEl, id]);
  let showElement = false;


  const handleChange = (event) => {
    if (name == "petHospital" && event.target.value == 'Not one of these') {
      dispatch(HOSPITAL_SECTION(true));
    }
    else if (name == "petHospital") {
      dispatch(HOSPITAL_SECTION(false));
    }

    // if (id === 'scenarios' && stepId > 2) {
    //   dispatch(CHANGE_SCENARIO({ value: event.target.value, currentStep: currentStep }));
    //   let temp = [];
    //   let DependentInformation;

    //   DependentInformation = insuredInfo[currentStep].dependents || null;
    //   let ltempLen = DependentInformation.filter((arr) => arr.questionsAnswers);
    //   let t = [];
    //   if (ltempLen.length > 0){
    //     for (let i = 0; i < ltempLen.length; i++) {
    //       t[i] = selectedoptionId.filter(
    //         (arr) => arr.slice(0, 1) === String(i) && arr.includes('No')
    //       ); 

    //       temp[i] = selectedoptionId.filter(
    //         (arr) => arr.slice(0, 1) === String(i) && arr.includes('No')
    //       );
    //     }
    //   if (t && ((t[0] && t[0].length > 0) ||(t[1] && t[1].length > 0))) {
    //     dispatch(DISABLE_ANSWERS_RADIO_STATUS({ currentStep: currentStep }));
    //   }
    // }
    // }
    onChange && onChange(event);
  };

  if (currentStep == "PetAssureNation" && hospitalSection && name == "State") {


    showElement = true
  }

  const lifeEventOptions = lifeEventValues?.find(eachObj => eachObj.fieldCode === "DD_00200") || '';
  let allOptions;
  /** Convert options to JSX elements */
  if (currentStep === 'LifeEvent' && currentForm === 'LifeEvent') {
    if (
      lifeEventOptions &&
      Object.keys(lifeEventOptions?.fieldValue).length > 0 &&
      dataFromRedux == undefined
    ) {
      allOptions =
        lifeEventOptions?.fieldValue?.map((option) => {
          return (
            <option value={option?.code} key={option?.code}>
              {option?.name}
            </option>
          );
        }) || [];
    }
  } else if (
    currentStep === 'DependentInformation' &&
    name === 'dependentRelationship' &&
    id === 'DependentInformation.dependentRelationship'
  ) {
    allOptions =
      (Array.isArray(dependentRelationShip) &&
        dependentRelationShip.length &&
        dependentRelationShip.map((option) => {
          return (
            <option value={option} key={option}>
              {relationship[option] || option}
            </option>
          );
        })) ||
      [];
  } else {
    if (Object.keys(options).length > 0 && dataFromRedux == undefined) {
      allOptions = options.map((option) => {
        return (
          <option value={option.value} key={option.value} {...option}>
            {option.text}
          </option>
        );
      });
    } else if (Object.keys(optionsNew).length > 0 && dataFromRedux == true) {
      allOptions = optionsNew.map((option) => {
        return (
          <option value={option.value} key={option.value} {...option}>
            {option.text}
          </option>
        );
      });
    }
  }


  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (event) => {
    /* fuction to handle blur event   */
    event.preventDefault();
    if (!props.selectedValue) setFocused(false);
    onBlur && onBlur(event);
  };
  let wrapperClasses = 'selectContainer ';
  let classes = 'w-100 selectText ';
  if (disabled) {
    wrapperClasses += ' isDisabled ';
    classes += 'isDisabled ';
  }
  if (required) {
    classes += 'isRequired ';
  }
  wrapperClasses += classNameWrapper;
  classes += className;
  if (leftTextAddOn) {
    addOnWrapperClass = 'addOnWrapper';
    wrapperClasses = 'selectContainer';
  }
  let multipleSelectId = multipleSelect ? 'multiple' : '';

  const editErrorMessage = element && element.validations && element.validations.string && element.validations.string.validationRequiredOnTrue && element.validations.string.validationRequiredOnTrue.message || '';

  return (
    <>
      {hideEl &&
        (
          ((id === 'DependentInformation.dependentState' || id === 'CriticalIllness.beneficiaryState') && liveAtSameAddress.state) ||
          (id === 'DependentInformation.dependentCountry' || id === 'CriticalIllness.beneficiaryCountry')) ? (
        <div className="readOnlyClass"></div>
      ) : (
        <>
          <div className={`${addOnWrapperClass} ${containerClassName}`}>
            {leftTextAddOn && (
              <div className="input-group-prepend">
                <div className="input-group-text">{leftTextAddOn}</div>
              </div>
            )}
            <div className={(shouldShowOnYes && !showElement) ? 'hideElement ' : wrapperClasses} >
              {/* //  <div className={shouldShowOnYes?  (!(showElement) )    ?'hideElement ' + additionalClasses :'floatingLabel '+ additionalClasses: '' } hidden={hidden}> */}
              {/* </div> */}
              <label htmlFor={id || name}>
                {label} {requiredVal && label && <span>*</span>}
              </label>
              <select
                id={id || name}
                name={id || name}
                multiple={multipleSelect}
                style={hasEditError ? { borderColor: "rgb(244, 67, 54)" } : {}}
                //className={classes}
                className={
                  (selectedValue && requiredValidation) || !requiredValidation
                    ? classes
                    : 'w-100 isRequired ' + classes
                }
                value={selectedValue}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                disabled={disabled}
              //onFocus={() => setFocused(true)}
              // onBlur={() => setFocused(value != null)}
              >
                <option value="" hidden disabled data-testid={name}></option>
                {allOptions}
              </select>
              {/* <label className={focused ? 'selectLabel isFocused' : 'selectLabel'}>{label}</label> */}
            </div>
          </div>
          {isEditModal && hasEditError && (
            <div>
              <span
                style={{
                  color: '#C53532',
                  fontFamily: 'NotoSansRegular',
                  fontSize: '0.75rem',
                  paddingLeft: '0rem',
                }}
              >
                {editErrorMessage}
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};
Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  selectedValue: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, text: PropTypes.string })),
};
Select.defaultProps = {
  options: [],
  className: '',
  classNameWrapper: '',
  defaultValue: null,
};

export default Select;