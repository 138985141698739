import { createGlobalStyle } from 'styled-components';
export const Styles = createGlobalStyle`
.menuContainer {
  position: relative;
  align-items: center;
  padding: 0.5rem;
  list-style: none;
  margin-left: 1.5rem;
  margin-top: 1rem;
}

.menuItem {
  font-family: Roboto;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) =>
    theme.molecules &&
    theme.molecules.navigation &&
    theme.molecules.navigation.navigationDropDownItemsColor} !important;
  margin: 1.5rem 0 1.5rem 0;
}

.subItemContainer {
  position: 'fixed';
  left: 0;
  margin-left: 0;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.subMenuItem {
  font-family: Roboto;
  font-size: 0.85rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1b74bc;
  list-style: none;
  padding: 0.3rem;
}

.sideDrawerContainer.open .menuContainer {
  padding: unset !important;
  margin: unset !important;
}

.sideDrawerContainer.open .subMenuItem a {
  color: #d2d2d2 !important;
  text-decoration: none;
  font-weight: 300;
  list-style: unset;
}
.sideDrawerContainer.open .subItemContainer {
  margin: 0.5rem 1rem;
}
.sideDrawerContainer.open .menuItem {
  font-weight: 300;
  color: #d2d2d2;
}

ul .col {
  padding-left: unset;
}
a:link {
  text-decoration: none;
}

.subMenuItem a {
  color: ${({ theme }) =>
    theme.molecules &&
    theme.molecules.navigation &&
    theme.molecules.navigation.navigationDropDownItemsColor};
}

.menuContainer a:hover {
  font-weight: 500;
  color: #194c75;
}

.sideDrawerContainer.open a:hover {
  color: #ffffff;
  font-weight: bold;
}

.sideDrawerContainer.open .subItemContainer a:hover {
  color: #d2d2d2;
  font-weight: normal;
}
`;
