import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'callCenter',
  initialState: {
    clientName: '',
    clientTitle: '',
    buid: '',
    selectedEmployeeData: '',
    selectedPersonBUID: '',
    selectedPersonLegalEntityBUID: '',
    searchLegalEntity: '',
    defaultContent: 'enTypes',
    mobileContent: 'en',
    emailContent: 'enEmail',
    steps: [],
    callCenterUser: true,
    extension: ":FO",
    callCenterEnrollAsEmployeeLoginData: {},
    callCenterLoginEmployeeDetails: [],
    isAdmin: false,
    isHrCenterReDirected: false
  },
  reducers: {
    SET_CLIENT_NAME: (callCenter, action) => {
      const clientTitle = action?.payload?.clientName?.split(':');
      callCenter.clientName = action.payload.clientName;
      callCenter.clientBuid = action.payload.clientBuid;
      callCenter.clientTitle = clientTitle[1]?.trim() || '';
      callCenter.textOnHeader = ' - Ongoing Enrollment';

    },
    SET_DROP_DOWN_AND_CHECK: (callCenter, action) => {
      callCenter.dropDown = action.payload.dropDown;
      callCenter.checkbox = action.payload.checkbox;
    },
    ADMIN_ENROLLMENT_CLICKED: (callCenter, action) => {
      callCenter.adminEnrollmentClicked = action.payload;
    },
    RETRIEVE_USERNAME_CLICKED: (callCenter, action) => {
      callCenter.retrieveUsenameClicked = action.payload;
    },
    RESET_USER_PASSWORD_CLICKED: (callCenter, action) => {
      callCenter.resetUserPasswordClicked = action.payload;
    },
    SET_SELECTED_PAGE: (callCenter, action) => {
      callCenter.selectedPage = action.payload;
    },
    SET_EMPLOYEE_DATA: (callCenter, action) => {
      callCenter.selectedEmployeeData = action.payload.selectedEmployeeData;

    },
    SET_PERSON_BUID: (callCenter, action) => {
      callCenter.selectedPersonBUID = action.payload.selectedPersonBUID;

    },
    SET_LEGAL_ENTITY_BUID: (callCenter, action) => {
      callCenter.selectedPersonLegalEntityBUID = action.payload.selectedPersonLegalEntityBUID;

    },
    SEARCH_LEGAL_ENTITY: (callCenter, action) => {
      callCenter.searchLegalEntity = action.payload.searchLegalEntity;
    },
    SET_CONTACT_TYPE: (callCenter, action) => {
      callCenter.contactType = action.payload;
    },
    SET_SEND_EMAIL_CODE: (callCenter, action) => {
      callCenter.sendEmailCode = action.payload;
      callCenter.email2 = 'email2';

    },
    SET_OTP: (callCenter, action) => {
      callCenter.otp = action.payload;
    },
    SET_MFA_CONTENT: (callCenter, action) => {
      callCenter.defaultContent = action.payload;
    },
    STORE_CONTENT: (callCenter, action) => {
      callCenter.content = action.payload;
      callCenter.originalContent = action.payload;

    },
    STORE_MOBILE_CONTENT: (callCenter, action) => {
      callCenter.mobileContent = action.payload;
      //callCenter.content.forms= action.payload;
    },
    STORE_EMAIL_CONTENT: (callCenter, action) => {
      callCenter.emailContent = action.payload;
      //callCenter.content.forms= action.payload;
    },
    // UPDATE_CONTENT: (callCenter, action) => {
    //   callCenter.content.forms= action.payload;
    // },
    UPDATE_CONTENT: (callCenter, action) => {
      let key = action.payload.key;
      callCenter.content.forms = action.payload.original//callCenter.originalContent.forms

      callCenter.content.forms[key] = action.payload.data
    },
    SHOW_SPECIAL: (callCenter, action) => {
      if (action.payload === true)
        callCenter.showSpecial = action.payload
      else {
        callCenter.showSpecial = action.payload
        callCenter.blockSendEmailOtp = false;
      }
    },
    HIDE_SUCCESS: (callCenter, action) => {
      callCenter.hideSuccess = action.payload
    },
    SHOW_AFTER_SEND_CODE: (callCenter, action) => {
      callCenter.showAfterSendCode = action.payload
    },
    ENABLE_SEND_CODE_BTN: (callCenter, action) => {
      callCenter.enableSendCodeBtn = action.payload
    },
    OTP_CODE_SENT: (callCenter, action) => {
      callCenter.otpCodeSent = action.payload
    },
    ENABLE_CONTINUE_BTN: (callCenter, action) => {
      callCenter.enableContinueBtn = action.payload
    },
    CHANGE_SUCCESS: (callCenter, action) => {
      callCenter.success = action.payload
    },
    MOBILE_BACK: (callCenter, action) => {
      callCenter.mobileBack = action.payload
    },
    SEND_CODE_MOBILE_CLICKED: (callCenter, action) => {

      callCenter.sendCodeMobileClicked = action.payload
      if (action.payload === true)
        callCenter.sendCode = true
    },
    SEND_CODE_CLICKED: (callCenter, action) => {
      callCenter.sendCodeClicked = action.payload
    },
    CONTINUE_EMAIL_CLICKED: (callCenter, action) => {
      callCenter.continueEmailClicked = action.payload
    },
    NO_API_CALL: (callCenter, action) => {
      callCenter.noAPICall = action.payload
    },
    EMAIL_OTP: (callCenter, action) => {
      callCenter.emailOtp = action.payload
    },
    REQUEST_CHANGE_EMAIL: (callCenter, action) => {
      callCenter.requestChangeEmail = action.payload
    },
    SEND_OTP_EMAIL: (callCenter, action) => {
      callCenter.sendOTPEmail = action.payload
    },
    ADD_STEP: (callCenter, action) => {
      callCenter.steps = [...callCenter.steps, action.payload.step]
    },


    CLEAR_CHANGE_MFA_SESSION: (callCenter, action) => {
      callCenter.enableContinueBtn = false
      callCenter.otpCodeSent = false
      callCenter.enableSendCodeBtn = false
      //callCenter.showSpecial =false

    },

    ENROLLED_PRODUCTS: (callCenter, action) => {
      callCenter.enrolledProducts = action.payload

      //callCenter.showSpecial =false

    },
    NEW_EMAIL_CAHNGE_MFA_CONTACT: (callCenter, action) => {
      callCenter.newEmail = action.payload
    },
    NEW_EMAIL_USED: (callCenter, action) => {
      callCenter.newEmailUsed = action.payload
    },
    EDIT_NEW_EMAIL: (callCenter, action) => {
      callCenter.editNewEmail = action.payload
    },
    NEW_EMAIL: (callCenter, action) => {
      callCenter.newEmail = action.payload
    },
    CALL_CENTER_ENROLL_EMPLOYEE_LOGIN_DATA: (callCenter, action) => {
      callCenter.callCenterEnrollAsEmployeeLoginData = action.payload
    },
    CALL_CENTER_LOGIN_EMPLOYEE_DETAILS: (callCenter, action) => {
      callCenter.callCenterLoginEmployeeDetails = action.payload
    },

    SET_EMPLOYEE_INFO: (callCenter, action) => {
      callCenter.email = action.payload.email;
      callCenter.firstName = action.payload.firstName;
      callCenter.lastName = action.payload.lastName;
    },
    SET_REDIRECT_TO_ENROLLMENT: (callCenter, action) => {
      callCenter.redirectToEnrollment = action.payload.redirectToEnrollment;
      callCenter.proxyUser = action.payload.proxyUser;
    },
    SET_EMPLOYEE_BUID: (callCenter, action) => {
      callCenter.buid = action.payload;
    },
    SET_CALL_CENTER_USER: (callCenter, action) => {
      callCenter.callCenterUser = !callCenter.callCenterUser;
    },
    IS_ADMIN: (callCenter, action) => {
      callCenter.isAdmin = action.payload;
    },
    SET_LOGIN_DATA: (callCenter, action) => {
      callCenter.logsData = action.payload;
    },
    SET_REDIRECT_TO_CLIENT_SEARCH: (callCenter, action) => {
      callCenter.rediretToClientSearch = action.payload;
    },
    CHANGE_MOBILE_OPTION: (callCenter, action) => {
      callCenter.changeMobileOption = action.payload;
    },
    MOBILE_OTP_ENTERED: (callCenter, action) => {
      callCenter.mobileOTPEntered = action.payload;
    },
    EMPTY_EMAIL_OTP: (callCenter, action) => {
      callCenter.emptyEmailOTP = action.payload;
    },
    RESET_CALLCENTER: (callCenter, action) => {
      callCenter.steps = [];
      callCenter.mobileOTPEntered = false;
      callCenter.changeMobileOption = false;
      callCenter.rediretToClientSearch = false;
      callCenter.sendCodeClicked = false;
      callCenter.success = false
      callCenter.hideSuccess = false
      callCenter.contactType = ""
      callCenter.sendEmailCode = false
      callCenter.email2 = "email2"
      callCenter.enableContinueBtn = false
      callCenter.sendCodeMobileClicked = false
      callCenter.otp = ""
      callCenter.mobileBack = false
      callCenter.showSpecial = false
      callCenter.blockSendEmailOtp = false
      callCenter.requestChangeEmail = false
      callCenter.showAfterSendCode = false
      callCenter.mobileOTPEntered = false
      callCenter.sendOTPEmail = false
      callCenter.otpCodeSent = false
      callCenter.enableSendCodeBtn = false
    },
    SET_FILTER_EMPLOYEE_CLICKED: (callCenter, action) => {
      callCenter.setFilterEmployeeClicked = action.payload;
    },
    SET_EMPLOYEE_RESULT: (callCenter, action) => {
      callCenter.setEmployeeResult = action.payload;
    },
    IS_HR_CENTER_RE_DIRECTED: (callCenter, action) => {
      callCenter.isHrCenterReDirected = action.payload;
    },
  }
});

export const {
  SET_CLIENT_NAME,
  SET_DROP_DOWN_AND_CHECK,
  ADMIN_ENROLLMENT_CLICKED,
  RETRIEVE_USERNAME_CLICKED,
  RESET_USER_PASSWORD_CLICKED,
  SET_SELECTED_PAGE,
  SET_EMPLOYEE_DATA,
  SET_PERSON_BUID,
  SET_LEGAL_ENTITY_BUID,
  SEARCH_LEGAL_ENTITY,
  SET_CONTACT_TYPE,
  SET_OTP,
  SET_RENDER,
  SET_MFA_CONTENT,
  STORE_CONTENT,
  STORE_MOBILE_CONTENT,
  STORE_EMAIL_CONTENT,
  UPDATE_CONTENT,
  SET_SEND_EMAIL_CODE,
  SHOW_SPECIAL,
  HIDE_SUCCESS,
  SHOW_AFTER_SEND_CODE,
  ENABLE_SEND_CODE_BTN,
  OTP_CODE_SENT,
  ENABLE_CONTINUE_BTN,
  CHANGE_SUCCESS,
  CLEAR_CHANGE_MFA_SESSION,
  MOBILE_BACK,
  SEND_CODE_MOBILE_CLICKED,
  CONTINUE_EMAIL_CLICKED,
  NO_API_CALL,
  EMAIL_OTP,
  REQUEST_CHANGE_EMAIL,
  SEND_OTP_EMAIL,
  ADD_STEP,
  SEND_CODE_CLICKED,
  ENROLLED_PRODUCTS,
  NEW_EMAIL_CAHNGE_MFA_CONTACT,
  NEW_EMAIL_USED,
  EDIT_NEW_EMAIL,
  NEW_EMAIL,
  CALL_CENTER_ENROLL_EMPLOYEE_LOGIN_DATA,
  CALL_CENTER_LOGIN_EMPLOYEE_DETAILS,
  SET_EMPLOYEE_INFO,
  SET_EMPLOYEE_BUID,
  SET_CALL_CENTER_USER,
  SET_REDIRECT_TO_ENROLLMENT,
  IS_ADMIN,
  SET_LOGIN_DATA,
  SET_REDIRECT_TO_CLIENT_SEARCH,
  CHANGE_MOBILE_OPTION,
  MOBILE_OTP_ENTERED,
  RESET_CALLCENTER,
  EMPTY_EMAIL_OTP,
  SET_FILTER_EMPLOYEE_CLICKED,
  SET_EMPLOYEE_RESULT,
  IS_HR_CENTER_RE_DIRECTED
} = slice.actions;

export default slice.reducer;