import React, { useState } from 'react';
import ImageLayout from '../../Atoms/ImageLayout';
import forgotUserName from '../../../../../Assets/Images/NewForgotUserNameSad.png';
import forgotUserNameHappy from '../../../../../Assets/Images/NewForgotUserNameHappy.png';
import RetreiveUserIDContainer from './RetreiveUserIDContainer';
import StepForm from '../../Atoms/StepForm';
import { Success } from '../common';
import VerifyOTP from './VerifyOTP';

import '../styles.css';

const RetrieveUserID = () => {

    const initialSteps = [
        { component: RetreiveUserIDContainer },
        { component: VerifyOTP, title: 'Retrieve User ID' },
        { component: Success, type: 'UserID', hasContinue: false }
    ];
    const [steps, setSteps] = useState([...initialSteps]);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    return (
        <ImageLayout image={{ src: currentStepIndex === 1 ? forgotUserNameHappy : forgotUserName, alt: "Forgot User Name" }}>
            <StepForm
                steps={steps}
                className={'forMFA'} 
                onStepChange={(stepIndex) => setCurrentStepIndex(stepIndex)}>
            </StepForm>
        </ImageLayout>
    )
}

export default RetrieveUserID;