import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavigationBar from '../NavigationBar';
import Elementor from '../Form/Elementor';
// import './styles.css';
import { Styles } from './styles.js';
import http from '../../../Common/Services/HttpService/index';
import { updateEnvironment } from '../../../Store/Slices/Auth/userAuth/index';
import { constantsList } from '../../../Common/Constants/index';
import { callConstants, callConstantsOneTime } from '../../../Store/Slices/Entities/constants/index';
import appConfig from '../../../appConfig';
import Modal from '../../Molecules/Modal';
import { useHistory } from 'react-router-dom';
import { apiReceived } from '../../../Store/Slices/Auth/userAuth/index';
import { APIRequestParallel, onNextAPI, onLoadAPI } from '../../../Store/Slices/Entities/response';
import dataService from '../../../Common/Services/DataService';
import { reduxConstants } from '../../../Common/Constants/index';
import { getValueFromKeyStringAcceptArray } from '../../Utils/Helper/GetValueFromKeyStringAcceptArray';
import SideNabBar from './SideNavBar';
import { useNavigationHook } from '../../Molecules/CustomSection/Hooks/NavigationHook';
import {
  SET_MFA_API_PARAM,
  SET_MFA_URL,
} from '../../../Store/Slices/Registeration';
const Header = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [headerLogo, setHeaderLogo] = useState(false);
  const [userName, setUserName] = useState('');
  const [portalName, setPortalName] = useState('');
  const [contactUsContent, setContactUsContent] = useState('');
  const [clientLogo, setClientLogo] = useState('');
  const [headerTitle, setHeaderTitle] = useState('');
  const [isConfiDataLoading, setIsConfiDataLoading] = useState(true);
  const [isLogoDataLoading, setIsLogoDataDataLoading] = useState(true);
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  let loggedUserEntity = useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.uiPath)) || {};
  let [isDefaultHeaderSet, setIsDefaultHeaderSet] = useState(false);
  const [content, SetContent] = useState({});

  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then((module => { SetContent(module.default) }))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);

  const elements = content && content?.headerElements;
  elements && elements?.forEach((element) => {
    if (element && element['name'] === 'language') {
      element['defaultValue'] = appConfig.language.currentLanguage;
    }
  });
  //Setting the default value for theme
  elements && elements?.forEach((element) => {
    if (element && element['name'] === 'theme') {
      element['defaultValue'] = appConfig.theme.currentTheme;
    }
  });
  const { userAuth } = useSelector((storeState) => storeState.auth);
  //const { currentStep } = useSelector((storeState) => storeState.stepForm);
  const { currentStep } = useNavigationHook();
  let { data: constantsListInfo } = useSelector((storeState) => storeState.entities.constants);
  // const { clientId } = useSelector((storeState) => storeState.auth.userAuth);
  let legalIndentity = useSelector((storeState) => storeState.registeration.legalEntityId)
  const { loginConfigInfoLogo, loginConfigInfo, headerConfig } = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList);
  const { proxyUser = false, firstName = '', lastName = '', clientName, callCenterLoginEmployeeDetails } = useSelector((storeState) => storeState.callCenter);
  let clientId = legalIndentity ? legalIndentity.split(':')[0] : ""

  const isHrCenter = userAuth?.userType === "HrCenter"
  let callcenterHR = useSelector((storeState) => storeState.registeration.callcenterHR)
  const callCenterLoginName = callCenterLoginEmployeeDetails?._Result?.legalEntityIdentity
  let hideNav;
  let url = window.location.pathname;
  //if (window.location.pathname == '/configurator/home') { updated by Nisreen
  if (window.location.pathname.indexOf('configurator/home') !== -1) {
    hideNav = true
  }
  const contactUsConfiguration = (elements, result) => {
    if (elements && elements.length) {
      let profileDepIndex = elements.findIndex((ele) => ele.name === 'profileDropdown');
      if (profileDepIndex < 0) return;

      let contactUsOptions = elements?.[profileDepIndex]?.options;
      if (contactUsOptions && contactUsOptions.length) {
        let contactUsContentIndex = contactUsOptions.findIndex(
          (opt) => opt.name === 'contactUsContent'
        );
        if (contactUsContentIndex < 0) return;
        const contactUsContent = contactUsOptions[contactUsContentIndex]?.content;
        if (contactUsContent) {
          ['tollFreeNumber', 'primaryTiming', 'secondaryTiming', 'email'].forEach((field) => {
            let index = contactUsContent.findIndex((content) => content.name === field);
            if (result?.[field] && index >= 0) {
              contactUsContent[index]['content'] = result[field];
              contactUsContent[index]['hasValue'] = true;
            }
          });
          contactUsOptions[contactUsContentIndex].content = contactUsContent.filter(
            (field) => field.hasValue === true
          );
        }
      }
    }
  };
  const contactUsConfigurationForMobile = (result) => {
    if (result) {
      let contactResult = '';
      if (result.tollFreeNumber) {
        contactResult += `<b>Toll Free</b>: ${result.tollFreeNumber} <br />`;
      }
      if (result.primaryTiming) {
        contactResult += `${result.primaryTiming} <br />`;
      }
      if (result.secondaryTiming) {
        contactResult += `${result.secondaryTiming} <br /><br />`;
      }
      if (result.email) {
        contactResult += `<b>Email</b>: ${result.email}`;
      }
      setContactUsContent(contactResult);
    }
  };

  const callLoginConfigAPI = async () => {
    if (!clientId) return
    return await http
      .get(appConfig.SSP_MID_URL + `/v1/config/${clientId}`)
      .then(async (resp) => {
        const data = resp?.data?.data
        for (const key in data) {
          let result = { _Result: data[key].data }
          dispatch(onLoadAPI({ [key]: result }));

        }
        return true;
      })
      .catch((e) => {
        return false;
      });
  };

  const GetUrlParams = () => {
    const authResult = new URLSearchParams(window.location.search);
    const user = authResult.get('email');
    return user;
  };
  let user = GetUrlParams() // sso user
  let isClientSearchPage = (window.location.pathname.indexOf('?email') !== -1 ||
    (window.location.pathname.indexOf('callcenter/home') !== -1 && userAuth.callCenterAdmin))
  let isCallCenter = !user && !userAuth.callCenterAdmin
  /**  original : commented to prevent call v1 for call center admin only
  useEffect(() => {
     if (!loginConfigInfo ) callLoginConfigAPI()
  }, [!loginConfigInfo]); */

  useEffect(() => {
    if (
      (!loginConfigInfo && !isCallCenter) || // any user but not call center user
      (!loginConfigInfo && userAuth.processType === "eelect") // sso user
    ) callLoginConfigAPI()
    /**legalIndentity added by Nisreen to call v1/config/client */
  }, [!loginConfigInfo, isCallCenter, userAuth.processType, legalIndentity]);

  useEffect(() => {
    const getHeaderConfig = async () => {
      try {
        // const { data: result } = await http.get(
        //   `${appConfig.SSP_MID_URL}/header/${clientId}`
        // );
        const result = headerConfig?._Result
        setUserName({
          firstName: result?.firstName,
          lastName: result?.lastName,
          fullName: result?.fullName,
        });


        setPortalName({ hideName: result?.displayPortalName });
        if (result?.portalName && content?.['titleConfig']?.Enrollment) {
          content['titleConfig'].Enrollment = result?.portalName;
          (url !== '/callcenter/home') && setHeaderTitle(result.portalName)
        }
        //show configured contact us data
        if (mobileMenuBar) {
          contactUsConfigurationForMobile(result);
        } else {
          contactUsConfiguration(elements, result);
        }
      } catch (e) {

      }
    };
    getHeaderConfig();
  }, [content])

  useEffect(() => {
    const getLogo = async () => {
      try {
        setIsLogoDataDataLoading(true)
        // const { data: result } = await http.get(
        //   `${appConfig.SSP_MID_URL}/loginConfiguration/logo/${clientId}`
        // );
        const result = loginConfigInfoLogo?._Result
        setClientLogo(result);
        setIsLogoDataDataLoading(false)
      } catch (e) {
        setIsLogoDataDataLoading(false)

      }
    };
    getLogo();
  }, [loginConfigInfoLogo]);

  useEffect(() => {

  }, [userAuth.isValidLogin]);
  useEffect(() => {

  }, [userAuth.configuratorSSOLogin]);
  const getHeaderLogoConfig = async () => {
    try {
      setIsConfiDataLoading(true)

      // const { data: result } = await http.get(
      //   `${appConfig.SSP_MID_URL}/loginConfiguration/${clientId}`
      // );
      const result = loginConfigInfo?._Result
      var clientLogodata = result?.hideLogo;
      setHeaderLogo(clientLogodata);
      setIsConfiDataLoading(false)
    } catch (e) {
      setIsConfiDataLoading(false)

    }
  };
  let mobileMenuBar =
    userAuth &&
      userAuth.data &&
      userAuth.data.access_token &&
      userAuth.userType === 'Enrollment' &&
      width <= 576
      ? true
      : false;

  /** Display logged user name */
  userAuth &&
    userAuth.data &&
    userAuth.data.access_token &&
    userAuth.processType !== "proxyEnrollment" && !proxyUser &&
    (userAuth.userType === 'Enrollment' || isHrCenter) &&
    Object.keys(loggedUserEntity).length > 0 &&
    elements?.forEach((element) => {
      if (element && element['name'] === 'loggedUser') {
        let getFirstName = getValueFromKeyStringAcceptArray(
          loggedUserEntity,
          element['reduxObjectPath-1'],
          element['reduxObjectFields'][0]
        );
        let getLastName = getValueFromKeyStringAcceptArray(
          loggedUserEntity,
          element['reduxObjectPath-1'],
          element['reduxObjectFields'][1]
        );
        let firstName = !userName?.firstName ? getFirstName : '';
        let lastName = !userName?.lastName ? getLastName : '';
        let fullName = !userName?.fullName ? firstName + ' ' + lastName : '';
        element['value'] = fullName;
      }
    });


  if (userAuth &&
    userAuth.data &&
    userAuth.data.access_token &&
    userAuth.callCenterAdmin &&
    userAuth.isValidLogin
    //userAuth.processType==="proxyEnrollment" 
  ) {
    //Object.keys(loggedUserEntity).length > 0 &&
    elements?.forEach((element) => {
      if (element && element['name'] === 'loggedUser') {
        let getFirstName = userAuth?.firstName;
        let getLastName = userAuth?.lastName;
        let fullName = getFirstName && getLastName ? getFirstName + ' ' + getLastName : '';
        element['value'] = fullName;
      }
    });
  }
  //  // (userAuth.userType === 'Enrollment' || userAuth.userType === 'CallCenter') &&
  //  userAuth.userType === 'CallCenter'  &&

  //  Object.keys(loggedUserEntity).length > 0 &&
  //  elements.forEach((element) => {
  //    if (element && element['name'] === 'loggedUser') {
  //      let getFirstName = getValueFromKeyStringAcceptArray(
  //        loggedUserEntity,
  //        element['reduxObjectPath-1CallCenter'],
  //        element['reduxObjectFieldsCallCenter'][0]
  //      );
  //      let getLastName = getValueFromKeyStringAcceptArray(
  //        loggedUserEntity,
  //        element['reduxObjectPath-1CallCenter'],
  //        element['reduxObjectFieldsCallCenter'][1]
  //      );
  //      let firstName = !userName?.firstName ? getFirstName : '';
  //      let lastName = !userName?.lastName ? getLastName : '';
  //      let fullName = !userName?.fullName ? firstName + ' ' + lastName : '';
  //      element['value'] = fullName;
  //    }
  //  });


  if (userAuth &&
    userAuth.data &&
    userAuth.data.access_token &&
    userAuth.userType === "CallCenter") {
    Object.keys(loggedUserEntity).length > 0 &&
      elements?.forEach((element) => {
        if (element && element['name'] === 'loggedUser') {
          let getFirstName = callCenterLoginName?.name;
          let getLastName = callCenterLoginName?.shortName;
          let fullName = getFirstName + ' ' + getLastName
          element['value'] = fullName;
        }
      });
  }

  if (userAuth &&
    userAuth.data &&
    userAuth.data.access_token &&
    userAuth.userType === "HrCenter" && !callcenterHR) {
      Object.keys(loggedUserEntity).length > 0 &&
      elements?.forEach((element) => {
        if (element && element['name'] === 'loggedUser') {
          let getFirstName = getValueFromKeyStringAcceptArray(
            loggedUserEntity,
            element['reduxObjectPath-1'],
            element['reduxObjectFields'][0]
          );
          let getLastName = getValueFromKeyStringAcceptArray(
            loggedUserEntity,
            element['reduxObjectPath-1'],
            element['reduxObjectFields'][1]
          );
          let firstName = !userName?.firstName ? getFirstName : '';
          let lastName = !userName?.lastName ? getLastName : '';
          let fullName = !userName?.fullName ? firstName + ' ' + lastName : '';
          element['value'] = fullName;
        }
      });
  }

  /** Display logged user name - Configurator*/
  userAuth &&
    userAuth.data &&
    userAuth.data.access_token &&
    userAuth.userType === 'Configurator' &&
    !userAuth.configuratorSSOLogin &&

    Object.keys(loggedUserEntity).length > 0 &&
    elements?.forEach((element) => {
      if (element && element['name'] === 'loggedUser') {
        let getFirstName = getValueFromKeyStringAcceptArray(
          loggedUserEntity,
          element['reduxObjectPath-1Configurator'],
          element['reduxObjectFieldsConfigurator'][0]
        );
        let getLastName = getValueFromKeyStringAcceptArray(
          loggedUserEntity,
          element['reduxObjectPath-1Configurator'],
          element['reduxObjectFieldsConfigurator'][1]
        );
        let firstName = !userName?.firstName ? getFirstName : '';
        let lastName = !userName?.lastName ? getLastName : '';
        let fullName = !userName?.fullName ? firstName + ' ' + lastName : '';
        element['value'] = fullName;
      }
    });

  /** Display logged user name - Configurator - SSO Login*/
  if (userAuth &&
    userAuth.data &&
    userAuth.data.access_token &&
    (userAuth.processType === "Configurator" && userAuth.configuratorSSOLogin) &&
    userAuth.configuratorSSOLogin) {
    elements?.forEach((element) => {
      if (element && element['name'] === 'loggedUser') {
        let getFirstName = userAuth.firstName;
        let getLastName = userAuth.lastName;
        let fullName = getFirstName + ' ' + getLastName
        element['value'] = fullName;
      }
    });
  }


  useEffect(() => {
    // getHeaderConfig();
    getHeaderLogoConfig();
  }, []);
  let parentOnChange = async (e) => {
    http.setEnvironment(e.target.value);
    await dispatch(
      updateEnvironment({
        environment: e.target.value,
      })
    );
  };

  let changeHeader = async (elements) => {
    setIsDefaultHeaderSet(true);
    elements?.forEach((element) => {
      if (element.defaultValue) {
        http.setHeaderValue(element.name, element.defaultValue);
      }
    });

    if (Object.keys(constantsListInfo).length === 0) {
      dispatch(callConstantsOneTime(''));
    }

    // constantsList.forEach((element) => {
    //   dispatch(callConstants(element));
    // });
  };

  const parentButtonAction = async (e, element) => {
    if (element?.config?.redirectURL) {
      dispatch(onNextAPI({ data: {}, isAPICallInProgress: true }));
      let newBankDetails = await dataService.getAPIResponse({}, content.actionCall.getlogOut);
      await dispatch(apiReceived({ data: {} })).then(() => {
        history.push(element.config.redirectURL);
      });
    }
  };

  useEffect(() => {
    !isDefaultHeaderSet && elements && changeHeader(elements);
  }, [elements]);

  useEffect(() => {
    getHeaderLogoConfig();
  }, [mobileMenuBar]);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    const callAPI = async () => {
      // setShowLoader(true);
      if (content && content?.layout && content?.layout?.onLoadApiCall) {
        let onLoadCalls = content.layout.onLoadApiCall;
        let data = await dispatch(
          APIRequestParallel(onLoadCalls, {
            additionalInformation: {
              clientId,
            },
          })
        );
      }
    };
    try {
      callAPI();
      // setLayoutData({ data });
    } catch (error) {

    }
  }, [content]);

  const onClickHamburger = () => {
    setOpenDrawer(!openDrawer);
  };

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const headerTypeCheck = userAuth && (userAuth.userType === 'Configurator');
  const headerTypeCheckDivider = userAuth && (userAuth.userType === 'Configurator' || userAuth.processType === 'callCenter' || userAuth.processType === 'Configurator' || userAuth.processType === 'oldEnrollment');
  const logInClasses =
    userAuth && userAuth.data && userAuth.data.access_token ? 'afterLogin' : 'headerLogin';
  const hideDevider = headerTypeCheckDivider
    ? (content?.['headerdeviderConfig']?.['hideClassName'] || '')
    : (portalName?.hideName && content?.['headerdeviderConfig']?.['hideClassName']) || '';
  const hideTitle = headerTypeCheck
    ? (content?.['titleConfig']?.['hideClassName'] || '')
    : (portalName?.hideName && content?.['titleConfig']?.['hideClassName']) || '';

  if (!content || Object.keys(content).length === 0) {
    return <div></div>;
  }
  return (
    <>
      <Styles />
      <div className="header col-12 py-1">
        <nav className={`headerContainer ${logInClasses} `}>
          {width <= 576 && (
            <div className="menuLogoWrapper">
              {mobileMenuBar && (
                <>
                  <Elementor
                    element={{ ...content['menuConfig'], onClick: onClickHamburger }}
                    {...props}
                  />
                  <SideNabBar
                    contactUsContent={contactUsContent}
                    userName={userName}
                    openDrawer={openDrawer}
                    setOpenDrawer={onClickHamburger}
                  />
                </>
              )}
            </div>
          )}

          <div className="leftContainer mt-2 ">
            <div>
              {headerTypeCheck ? (
                <>
                  {clientName ? (
                    <div class="row">
                      <div class="loggedUser">{clientName}</div>
                    </div>
                  ) : (
                    ''
                  )}
                  <img src="" alt="" className="clientLogo" id="client-logo" />
                </>
                /** hide logo on Call Center - Client Search Page */
              ) : !headerLogo && clientLogo && clientLogo !== '' && !isClientSearchPage ? (
                <img
                  src={
                    clientLogo
                      ? clientLogo
                      : ""
                  }
                  alt="Client logo"
                  className="clientLogo full-width"
                  id="client-logo"
                />
              ) : !headerLogo && userAuth.userType === 'Enrollment' && clientLogo && clientLogo !== "" ? (
                <Elementor element={content['logoConfig']} {...props} />
              ) : clientLogo && clientLogo !== "" && !isClientSearchPage ? (
                <img
                  src={
                    clientLogo
                      ? clientLogo
                      : ""
                  }
                  alt="Client logo"
                  className="clientLogo full-width"
                  id="client-logo"
                />
              ) : <></>}
            </div>

            {/* // commented by Nisreen June-2023  */}
            {headerTitle && (
              <div className={`d-flex dividerContainer `}>
                <div className={`divider ${hideDevider}`} />
              </div>
            )}
            <div className={`titleContainer ${hideTitle}`}>
              <Elementor element={content['titleConfig']} value={headerTitle} {...props} />
            </div>
          </div>

          {userAuth &&
            userAuth.data &&
            userAuth.data.access_token &&
            (userAuth.userType === 'Enrollment' || userAuth.processType === "callCenter" || userAuth.processType === "hrCenter") &&
            currentStep &&
            (content['cartConfig'].showCartHeader.includes(currentStep) && (url !== '/enrollment/home') && (url !== '/callcenter/home') && (url !== '/callcenter/employeeSearch') && (url !== '/callcenter/employeeData')) &&
            (
              <Elementor element={content['cartConfig']} {...props} />
            )}
          <div>
            <ul className={content['headerRightFlexRow'].className}>
              {elements.map((element) => {
                if (determineRender(element)) {
                  return (
                    <li id={element.name} key={element.name} className={element.liClassName}>
                      <Elementor
                        key={element.name}
                        element={element}
                        constantsList={constantsListInfo}
                        {...props}
                        parentOnChange={parentOnChange}
                      />
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </nav>
      </div>
      {userAuth &&
        (userAuth.userType === 'Configurator' || (userAuth.processType === "Configurator" && userAuth.configuratorSSOLogin)
        ) &&
        userAuth.data &&
        userAuth.data.access_token &&
        (Object.keys(loggedUserEntity).length > 0 || userAuth.configuratorSSOLogin) &&
        content &&
        content.navigationBar &&
        !content.navigationBar.hideNavigation &&
        !hideNav && <NavigationBar />}
      <div>
        {content &&
          content.modals &&
          content.modals.map((modal) => {
            return (
              <Modal
                key={modal.modalId}
                {...props}
                elements={modal.elements}
                modelData={modal}
                parentButtonAction={parentButtonAction}
              />
            );
          })}
      </div>
    </>
  );

  //determines whether or not to render an element based on what page the application is on
  function determineRender(element) {
    // if the render type is all, then it renders
    if (element.renderType === 'all') {
      return true;
    }

    // if the user has not logged in and the render type is landingPage, then it renders
    if (
      (!(userAuth && userAuth.data && userAuth.data.access_token) ||
        !Object.keys(loggedUserEntity).length > 0) &&
      element.renderType === 'landingPage'
    ) {
      return true;
    }

    // if the user has logged in and the render type is application, then it renders
    if (
      userAuth &&
      userAuth.data &&
      userAuth.data.access_token &&
      // (Object.keys(loggedUserEntity).length > 0 || userAuth.processType === 'callCenter' || userAuth.processType === 'CallCenter') &&
      (Object.keys(loggedUserEntity).length > 0 ||
        (userAuth.callCenterAdmin && userAuth.isValidLogin) ||
        isHrCenter ||
        userAuth.userType === "Configurator" ||
        (userAuth.processType === "Configurator" && userAuth.configuratorSSOLogin)
      ) &&
      element.renderType === 'application'
    ) {
      return true;
    }

    return false;
  }

  //determines what to display as the header title
  function determineHeader(element) {
    if (
      !(userAuth && userAuth.data && userAuth.data.access_token) ||
      !Object.keys(loggedUserEntity).length > 0
    ) {
      return element.landingContent;
    }
    // TODO render employee, employee or broker title based on user credentials
    if (
      userAuth &&
      userAuth.data &&
      userAuth.data.access_token &&
      userAuth.userType &&
      Object.keys(loggedUserEntity).length > 0
    ) {
      let type = userAuth.userType;
      return element[type];
    }
  }
};

export default Header;