import React, { useEffect,useState } from 'react';
import Panel from '../../Molecules/Panel/Panel';
import { Banner, Linker } from '../../Atoms';
// import './LandingPage.css';
import { Styles } from './Styles'
import { useDispatch, useSelector } from 'react-redux';
import { RETRIEVE_USER } from '../../../Store/Slices/ForgotUser';
import { CLEAR_SSN, SET_SSN_VALUE } from '../../../Store/Slices/ControlSSN';

const LandingPage = () => {
    /* useEffect to get Landing PAge layout content dependent on language */
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, setContent] = useState({});
  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => setContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);
  const dispatch = useDispatch();
  /** Clear retrieve state in store */
  dispatch(RETRIEVE_USER(false))
  dispatch(CLEAR_SSN(true));
  dispatch(SET_SSN_VALUE(''));
  return (
    <>
    <Styles />
      <Banner
        desktop="hero_img_landing_desktop.png"
        tablet="hero_img_landing_tablet.png"
        mobile="hero_img_landing_mobile.png"
      >
        <div className="landingPage container-fluid py-5">
          <div className="row">
            <div className="offset-lg-4 offset-xl-4 col-lg-8 col-12 px-5">
              <div className="row  justify-content-center">
                <div className="text-center col-12 col-md-9 col-xl-9 mx-4">
                  <p className="mainTitle">{content?.title?.mainTitle}</p>
                  <h3 className="subTitle mb-4">{content?.title?.subTitle}</h3>
                </div>
              </div>
              <div className="row">
                {content?.panels?.map((panel) => {
                  return (
                    <div
                      key={panel?.title}
                      className={`landingPanel col-12 col-xl-4 col-lg-4 col-md-4 my-2 ${
                        panel.config && panel.config.className ? panel.config.className : ''
                      }`}
                    >
                      <Panel {...panel}>
                        <div className="w-100 d-flex flex-column align-items-center">
                          <Linker
                            to={panel.login.to}
                            className="btn btn-primary btn-block my-3 py-2 loginButton"
                          >
                            {panel.login.link}
                          </Linker>
                          <Linker to={panel.register.to}>{panel.register.link}</Linker>
                        </div>
                      </Panel>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Banner>
    </>
  );
};

export default LandingPage;
