import React, { useState } from 'react';
import ImageLayout from '../../Atoms/ImageLayout';
import forgotPassword from '../../../../../Assets/Images/NewForgotPasswordSad.png';
import forgotPasswordHappy from '../../../../../Assets/Images/NewForgotPasswordHappy.png';
import ResetPasswordContainer from './ResetPasswordContainer';
import StepForm from '../../Atoms/StepForm';
import { VerifyOTP, Success } from '../common';
import ResetPassword from './ResetPassword';

import '../styles.css';

const RetrievePassword = () => {

    const steps = [
        { component: ResetPasswordContainer },
        { component: VerifyOTP, flow: 'resetPassword', title: 'Reset Password' },
        { component: ResetPassword },
        { component: Success, type: 'password', hasContinue: false }
    ];

    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    return (
        <ImageLayout image={{ src: currentStepIndex === 2 ? forgotPasswordHappy : forgotPassword, alt: "Forgot Password" }}>
            <StepForm
                steps={steps}
                className={'forMFA'} 
                onStepChange={(stepIndex) => setCurrentStepIndex(stepIndex)}>
            </StepForm>
        </ImageLayout>
    )
}

export default RetrievePassword;