import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from '../Image/';
import imageMap from '../../Molecules/CustomSection/Helper/imageHelper';
import {getFileName} from '../../Molecules/CustomSection/Helper/commonHelper';

const Banner = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const { desktop, tablet, mobile, children, ...rest } = props;
  let img = '';
  try {
    img = imageMap[getFileName(props.desktop)];
  } catch (e) {
    img = '';
  }
  const styles = {
    backgroundImage: width > 992 ? `url(${img})` : null,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
    backgroundColor: '#ffffff',
  };
  return (
    <>
      <div style={styles} {...rest}>
        {width < 768 && <Image filename={mobile} style={{ backgroundColor: '#f1f2f4' }} />}
        {768 <= width && width <= 992 && (
          <Image filename={tablet} style={{ backgroundColor: '#f1f2f4' }} />
        )}
        {children}
      </div>
    </>
  );
};
Banner.propTypes = {
  desktop: PropTypes.string,
  tablet: PropTypes.string,
  mobile: PropTypes.string,
};

export default Banner;
