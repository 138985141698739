export let StringValidation = (
  Yup,
  indYupper,
  indValidation,
  validationType,
  indElement,
  tempValidations
) => {

  switch (indValidation) {
    case 'required':
      indYupper = indYupper[indValidation](
        tempValidations[indElement][validationType][indValidation]
      );
      break;
    case 'validationRequired':
      indYupper = indYupper.when(
        tempValidations[indElement][validationType][indValidation]['value'],
        {
          is: (value) => value === 'true' || value === true,
          then: Yup.string(),
          otherwise: indYupper['required'](
            tempValidations[indElement][validationType][indValidation]['message']
          ),
        }
      );
      break;
    case 'validationRequiredOnTrue':
      indYupper = indYupper.when(
        tempValidations[indElement][validationType][indValidation]['value'],
        {
          is: (value) => value === 'true' || value === true,
          then: indYupper['required'](
            tempValidations[indElement][validationType][indValidation]['message']
          ),

          otherwise: Yup.string(),
          //  otherwise: indYupper['required'](tempValidations[indElement][validationType][indValidation]["message"]),
        }
      );
      break;
    case 'min':
      indYupper = indYupper[indValidation](
        tempValidations[indElement][validationType][indValidation]['regex'],
        tempValidations[indElement][validationType][indValidation]['message']
      );
      break;
    case 'max':
      indYupper = indYupper[indValidation](
        tempValidations[indElement][validationType][indValidation]['limit'],
        tempValidations[indElement][validationType][indValidation]['message']
      );
      break;
    case 'email':
      indYupper = indYupper[indValidation](
        tempValidations[indElement][validationType][indValidation]
      );
      break;
    default:

  }

  return indYupper;
};

export default StringValidation;
