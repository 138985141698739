import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import imageMap from '../../Molecules/CustomSection/Helper/imageHelper';
import { getFileName } from '../../Molecules/CustomSection/Helper/commonHelper';
import { formatDollarAmountWithDecimals } from '../../Utils/Helper/ValidationHelper';

const Cart = (props) => {
  const {
    filename = '',
    upArrow,
    downArrow,
  } = props.element;

  const ref = useRef();
  const [isActive, setIsActive] = useState(false);
  let totalCost = 0.00;
  const { cartItemList } = useSelector((storeState) => storeState.employeeProfile);
  const showItems = cartItemList?.slice().sort((a, b) => a.id - b.id)
  cartItemList?.map(e => totalCost = totalCost + e.amount);

  let imgSrc;
  let upArrowSrc;
  let downArrowSrc;
  try {
    imgSrc = imageMap[getFileName(filename)];
  } catch (e) {
    imgSrc = '';
  }
  try {
    upArrowSrc = imageMap[getFileName(upArrow)];
  } catch (e) {
    upArrowSrc = '';
  }
  try {
    downArrowSrc = imageMap[getFileName(downArrow)];;
  } catch (e) {
    downArrowSrc = '';
  }
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive]);
  let totalAmount = `$${totalCost.toFixed(2)}`
  return (
    <React.Fragment>
      <div className="cart-container">
        <ul className="flex-row d-flex">
          <li className="cart-container-landingPage col-sm-6 col-md-1">
            <div className="row card-icon">
              <div className="row">
                <div className="col-12">
                  <img
                    src={imgSrc}
                    className={'img-fluid'}
                    alt="Cart Information"
                  />
                </div>
              </div>
            </div>
          </li>
          <li className="cart-container-landingPage col-sm-6 col-md-1">
            <div>
              <div className="row">
                <div className="col-12"><div className="text-center mb-2" >Total Cost</div></div>
              </div>
              <div className="row" ref={ref}>
                <div className="col-12 total-label">
                  <div
                    className="total-cost-text"
                    onClick={() => {
                      setIsActive(!isActive);
                    }}
                  >

                    {formatDollarAmountWithDecimals(totalAmount)}
                    {isActive ? (
                      <img src={downArrowSrc} className="arrow-img" alt="notfound" />
                    ) : (
                      <img src={upArrowSrc} className="arrow-img" alt="upArrowSrc" />
                    )}

                  </div>
                  <div className={`dropdown-menu mt-2 cart-dropdown-menu dropdown-container ${isActive ? `show` : ''}`}>
                    {showItems?.map((item, index) => {
                      return (<div className="cart-dropdown-item" key={index}>
                        <div className="cart-accordion-title">
                          <div className="cart-accordion-image-container">
                            <div className="cart-accordion-text">{item.name}</div>
                          </div>
                          <div className="cart-amount-accordion-text">{formatDollarAmountWithDecimals(item.amount)}</div>

                        </div>
                      </div>
                      )
                    })}
                    <div className="cart-dropdown-item-total">
                      <div className="cart-total-accordion-title">
                        <div className="cart-accordion-image-container">
                          <div className="cart-total-accordion-text">Total Cost (Per Pay Period)</div>
                        </div>
                        <div className="cart-total-accordion-text">{formatDollarAmountWithDecimals(totalAmount)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Cart;
