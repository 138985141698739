//import http from '../../../../Common/Services/HttpService/index.js';
import http from '../../../../Common/Services/HttpService';
import appConfig from '../../../../appConfig';
import { UPDATE_COVERAGE_INFO, onLoadProductAPI } from '../../../../Store/Slices/Entities/response/index.js';
import { SUBSCRIBE_COVERAGE_BUIDS, UPDATE_LOADING, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';


export const prepareSubscriptionForChoice = (enrollmentProcessBuid, coverageBuid, coveredPerson, currentStep, personBuid) => async dispatch => {
    if (!enrollmentProcessBuid || !coverageBuid || !coveredPerson || !currentStep || !personBuid) {
        throw new Error('Invalid argument. One or more arguments are null or undefined.');
    }

    // Make API request to validate subscribed product data
    dispatch(UPDATE_LOADING(true));
    try{
        const response = await http.post(appConfig.SSP_MID_URL + "/prepareSubscriptionForChoice", {
            payload:{
                enrollmentProcessBuid: enrollmentProcessBuid,
                coverageBuid: coverageBuid,
                coveredPerson: coveredPerson
            }
        });

        // If response is successful, update the state    
        if (response && response.data) {
            // Format the new data
            const currentStepData = {
                [currentStep]: {
                    prepareSubscriptionForChoice: response.data
                }
            };

            // Dispatch an action to update the state with the new data
            dispatch(SUBSCRIBE_COVERAGE_BUIDS({
                coverageBuid: response.data._Result?.[0]?.bUID,
                personBuid: personBuid,
                product: currentStep,
            }));
            dispatch(
                UPDATE_COVERAGE_INFO({
                    coverageBuid: response.data._Result?.[0]?.bUID,
                    personBuid: personBuid,
                    product: currentStep,
                    perpareSubscriptionForChoices: response.data
                })
            );
            dispatch(onLoadProductAPI({ data: currentStepData, currentStep, isAPICallInProgress: false }));

            // Resolve with coverageBuid
            return response.data._Result?.[0]?.bUID;
        } else {
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: response?.status || '520',
                id: 'prepareSubscriptionForChoice',
                show: false,
              }));
        }
    } catch(e){
        dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: response?.status || '520',
            id: 'prepareSubscriptionForChoice',
            show: false,
          }));
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
};

