import React, { useStat, memo } from 'react';
import Profile from "../Component/Profile/Profile.js"
import SearchClientList from '../../../Molecules/SearchClientList';


const CallCenterManageClientSearch = memo((props) => {


  return (
    <div>
       <SearchClientList {...props}></SearchClientList>
    </div>
  );
});
export default CallCenterManageClientSearch;
