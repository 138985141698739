import $ from 'jquery';
import { useState } from 'react';
import './style.css';
import watchVideo from '../../../../../Assets/Images/icon_watch_video.png';
import downloadBrochure from '../../../../../Assets/Images/icon_download_brochure.png';
import iconVerified from '../../../../../Assets/Images/iconVerified.svg';
import iconNotApplicable from '../../../../../Assets/Images/icon-not-applicable.png';

export const Products = ({ products, disabled, onChange, onSelectAll }) => {
    const selectedAll = products?.every(item => item.checked);
    const [iframeSrc, setIframeSrc] = useState()

    const onWatchVideo = (product) => {
        setIframeSrc(product.videoUrl);
        $('#videoPlayer').modal('show')
    }

    const closeModal = () => {
        setIframeSrc('');
        $('#videoPlayer').modal('hide');
    }

    const onDownloadBrochure = (product) => {
        window.open(product.pdfUrl, '_blank');
    };

    return (
        <div className="products-list">
            <p className="title">Your eligible products for enrollment are listed below.</p>
            <div className="select-all">
                <input type="checkbox" id="selectAll" checked={selectedAll} onChange={onSelectAll} disabled={disabled} />
                <label htmlFor="selectAll">Select All Products</label>
            </div>
            {products?.map((product) => (
                <div key={product.name} className="product">
                    <div className="product-selection">
                        <div className="selection">
                            <label htmlFor={product.name} className="visually-hidden">Product Selection: {product.title}</label>

                            <input type="checkbox" disabled={disabled} name={product.name} id={product.name} onChange={onChange} checked={product.checked || false} value={true} />
                            {product.image && <img width="150" src={product.image} alt={product.title} className="product-img" />}
                        </div>
                        <div className="dependent-info">
                            <img className="dependent-icon" hideDefaultClass="true" alt="Dependent status" src={product.dependentStatus ? iconVerified : iconNotApplicable} />
                            <p>Dependent details {product.dependentStatus ? 'required' : 'not required'}</p>
                        </div>
                    </div>
                    <div className="product-details">
                        <h2 className="title">{product.title}</h2>
                        <p className="description">{product.description}</p>
                        {product.videoUrl && (
                            <button className="icon-button" type="button" onClick={() => onWatchVideo(product)}>
                                <img alt="Watch Video Icon" src={watchVideo} className="mr-2 justify-content-center align-items-center" />
                                Watch Video
                            </button>
                        )}
                        {product.pdfUrl && (
                            <button className="icon-button" type="button" onClick={() => onDownloadBrochure(product)}>
                                <img alt="Download Brochure Icon" src={downloadBrochure} className="mr-2 justify-content-center align-items-center" />
                                Download Brochure
                            </button>
                        )}
                    </div>
                </div>
            ))}

            {/* Modal popup for Video Player */}
            <div id="videoPlayer" className="modal" tabIndex="-1">
                <div className="modal-dialog videoDialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title">Product Video</div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal}>X</button>
                        </div>
                        <div className="modal-body py-3">
                            <iframe
                                id={'iframeProductVideo'}
                                src={iframeSrc}
                                style={{ border: 'none' }}
                                height="300"
                                width="730"
                                allow="autoplay"
                                title="Iframe Example"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
