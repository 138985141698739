import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const RadioButton = (props) => {
  const {
    id = '',
    name,
    buttons,
    className,
    classNameWrapper,
    onChange,
    value,
    inline,
    label,
    required,
    ...rest
  } = props;

  const handleChange = (event) => {
    props.radioButtonParentAction && props.radioButtonParentAction(event);
    onChange && onChange(event);
  };

  const radioButtons = buttons.map((button) => {
    let classes = 'form-check ';
    if (inline) {
      classes += 'form-check-inline ';
    }

    return (
      <div className={classes + className} key={button.value}>
        <input
          id={(id !== true && id!== "" && id != null)? id : button.value }
          name={name || id}
          value={button.value}
          className="form-check-input"
          type="radio"
          checked={value === button.value}
          onChange={handleChange}
        />
        <label className="form-check-label option" htmlFor={(id !== true && id!== "" && id != null) ? id : button.value }>
          {button.label}
        </label>
      </div>
    );
  });

  return (
    <div className={'form-group ' + classNameWrapper} {...rest}>
      {label && (
        <div className="floatingLabel">
          {label} {required && <span> *</span>}
        </div>
      )}
      {radioButtons}
    </div>
  );
};
RadioButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
      readOnly: PropTypes.bool,
    })
  ),
};
RadioButton.defaultProps = {
  className: '',
  classNameWrapper: '',
  buttons: [],
  default: false,
  inline: false,
};

export default RadioButton;
