import React, { Suspense, useEffect, useState } from 'react';
import appConfig from '../../../../appConfig';
import http from '../../../../Common/Services/HttpService';

import FormGenerator from '../../../Cells/Form/FormGenerator';
//import { useSelector } from 'react-redux';
import SectionHeader from '../../../Molecules/SectionHeader';
import { useDispatch, useSelector } from 'react-redux';
import { callConstants } from '../../../../Store/Slices/Entities/constants/index';
import { constantsList } from '../../../../Common/Constants/index';
import { apiConstants } from '../../../../Common/Constants';
import {
  APIRequestParallel,
  APIRequestSequential,
  APIRequestSequential2,
  onLoadAPI,
} from '../../../../Store/Slices/Entities/response/index';
import { reduxConstants } from '../../../../Common/Constants/index';
import Loader from '../../../Atoms/Loader';
import { retrieveConfig } from '../Actions/retrieveConfig';
import formFields from '../Content/DependentConfig.json';
import { useFormik } from 'formik';
import { saveProfile } from '../Actions/saveConfig';
import SuccessPage from '../Common/SuccessPage';
import { CLEAR_ALERT_MESSAGES, SET_ALERT_MESSAGE } from '../../../../Store/Slices/InsuredInfo';
import { getErrorMessages } from '../../../Molecules/CustomSection/Helper/MappingHelper';
import { MessageComponent } from '../../../Atoms';
import { Checkbox, TextInput, Button, Label } from '../../../Molecules/CustomSection/Atoms';
import '../styles.css';

const DependentConfig = (props) => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, SetContent] = useState({});
  const [layoutData, setLayoutData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  // const [additionalInformation, SetAdditionalInformation] = useState({});
  

  const dispatch = useDispatch();

  let loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.apiPath)) || {};

  let additionalInformation =
    useSelector((store) => eval(reduxConstants.GetAdditionalValues.apiPath)) || {};

  const reduxValues = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList
  );

  const dataFromReduxEmploymentInformation = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList
  );

  const configDataRedux = useSelector(
    (storeState) => {
      return storeState?.entities?.response?.onLoadResponseList?.empConfigInfo;
    }
  );

  let configData = {};
  try{
    configData = {
      dependentMiddleNameToggle: configDataRedux?.dependentPersonalInfo?.[0]?.dependentMiddleName?.hideableField || false,
      dependentMiddleNameRequired: configDataRedux?.dependentPersonalInfo?.[0]?.dependentMiddleName?.requiredField || false,
      dependentAddressSameAsEmployeeToggle: configDataRedux?.dependentAddressInfo[0]?.liveInSameAddress?.hideableField || false,
      dependentAddressSameAsEmployeeRequired: configDataRedux?.dependentAddressInfo[0]?.liveInSameAddress?.requiredField || false,
      dependentStreetAddress2Toggle: configDataRedux?.dependentAddressInfo[0]?.dependentAddressLine2?.hideableField || false,
      dependentStreetAddress2Required: configDataRedux?.dependentAddressInfo[0]?.dependentAddressLine2?.requiredField || false,
      dependentCountryToggle: configDataRedux?.dependentAddressInfo[0]?.dependentCountry?.hideableField || false,
      dependentCountryRequired: configDataRedux?.dependentAddressInfo[0]?.dependentCountry?.requiredField || false
    };
  } catch(e){
    configData = {
      dependentMiddleNameToggle: false,
      dependentMiddleNameRequired: false,
      dependentAddressSameAsEmployeeToggle: false,
      dependentAddressSameAsEmployeeRequired: false,
      dependentStreetAddress2Toggle: false,
      dependentStreetAddress2Required: false,
      dependentCountryToggle: false,
      dependentCountryRequired: false
    };
  }

  const formik = useFormik({
    initialValues: {      
      dependentMiddleNameToggle: configData?.dependentMiddleNameToggle,
      dependentMiddleNameRequired: configData?.dependentMiddleNameRequired,
      dependentAddressSameAsEmployeeToggle: configData?.dependentAddressSameAsEmployeeToggle,
      dependentAddressSameAsEmployeeRequired: configData?.dependentAddressSameAsEmployeeRequired,
      dependentStreetAddress2Toggle: configData?.dependentStreetAddress2Toggle,
      dependentStreetAddress2Required: configData?.dependentStreetAddress2Required,
      dependentCountryToggle: configData?.dependentCountryToggle,
      dependentCountryRequired: configData?.dependentCountryRequired,
    },

    onSubmit: (values, { setSubmitting }) => {
      formik.resetForm();
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  /** commented by Nisreen - 09-16, breaks code  */
  // const buid = useSelector(
  //   (storeState) =>storeState?.entities?.response?.onLoadResponseList?.employments._Result[1] &&
  //     storeState?.entities?.response?.onLoadResponseList?.employments?._Result[1]?.bUID
  // );

  const loginuserName = useSelector((storeState) => storeState?.auth?.userAuth?.data?.user_name);

  const dataFromRedux = useSelector((storeState) => storeState?.auth?.loggedUserEntity?.data);
  let { clientId } = useSelector((storeState) => storeState.auth.userAuth);

  //  const dataFromRedux = useSelector((storeState) => storeState?.auth?.loggedUserEntity?.data);

  // const dataFromRedux = useSelector(
  //   (storeState) => storeState?.entities?.response?.onLoadResponseList
  // );
  // useEffect(() => {
  //   setLayoutData({ dataFromRedux: dataFromRedux });
  // }, [dataFromRedux]);


  const userName = { loginName: loginuserName };


  useEffect(() => {
    // //Api Call here
    setShowLoader(true)
    const callAPI = async () => {
      let response = await retrieveConfig(clientId, formFields.apiCall.onload);
      if(!response?.data){
        setShowSuccess(null);
        setTimeout(()=>{
          dispatch(SET_ALERT_MESSAGE({
            type: 'error',
            errorCode: '520',
            continue: true,
            id: 'getDependentInfoByClientId',
            show: true,
            page: 'Configurator',
          }));
          setShowSuccess(false);
        },50);
      }else {
        dispatch(
          onLoadAPI({
            empConfigInfo: response?.data,
            isAPICallInProgress: false,
          })
        );
      }
      // dispatch(
      //   onLoadAPI({
      //     empConfigInfo:  response?.data ? { ...response?.data[0] } : {}, 
      //     isAPICallInProgress: false,
      //   })
      // );
      setShowLoader(false)
    };
    try {
      callAPI();
    } catch (error) {
     
    }
  }, [loggedUserEntity]);

  useEffect(()=>{
    dispatch(CLEAR_ALERT_MESSAGES([]));
  },[formik.values]);

  // useEffect(() => {
  //   /* useEffect to get all constants through API call */
  //   constantsList.forEach((element) => {
  //     dispatch(callConstants(element));
  //   });
  // }, []);

  // useEffect(() => {
  //   const callAPI = async () => {
  //     // setShowLoader(true);
  //     if (content && content?.layout && content?.layout?.onLoadApiCall) {
  //       let onLoadCalls = content.layout.onLoadApiCall;

  //       let data = await dispatch(
  //         APIRequestParallel(onLoadCalls, {
  //           additionalInformation: {
  //             clientId
  //           },
  //         })
  //       );
  //     }
  //   };
  //   try {
  //     callAPI();
  //     // setLayoutData({ data });
  //   } catch (error) {
  //     
  //   }

  //   // additionalInformation = useSelector(
  //   //   (storeState) => storeState?.entities?.response?.onLoadResponseList?.employments
  //   // );
  //   // setShowLoader(false);
  // }, [content, loggedUserEntity]);

  // useEffect(() => {
  //   /* use effect to get Company Profile layout content dependent on language */
  //   let contentInfo = require(`./Content/${currentLanguage}.json`);
  //   if (contentInfo) SetContent(contentInfo);
  // }, [currentLanguage, props.layout]);
  //  let [result, setResult] = useState({});
  //  useEffect(() => {
  //    /* use effect to get forms which contains element properties */
  //    setResult(props.result);
  //  }, [props.result]);

  // useEffect(() => {
  //   const callAPI = async () => {
  //     // setShowLoader(true);
  //     if (content && content?.layout && content?.layout?.onLoadApiCall) {
  //       let onLoadCalls = content.layout.onLoadApiCall;

  //       let data = await dispatch(
  //         APIRequestSequential2(onLoadCalls, { loggedUserEntity, userName })
  //       );

  //       if (data) {
  //         let directObject = {};
  //         onLoadCalls.forEach((apiCalls) => {
  //           let { objectName } = apiCalls.apiconfig || {};
  //           let curObj = { [objectName]: data[objectName] };
  //           directObject = { ...directObject, ...curObj };
  //           //  props.setResult(directObject);
  //         });
  //         let dataEmployments = data?.employments?._Result || [];
  //         if (dataEmployments.length > 0) {
  //           let buidtemp = dataEmployments[dataEmployments.length - 1]?.bUID;
  //           const resp = await http.post(appConfig.SSP_MID_URL + '/getEmploymentInformation', {
  //             additionalInformation: { buid: buidtemp },
  //           });

  //           // const axios = require('axios');
  //           // const resp = await axios({
  //           //   method: 'post',
  //           //   headers: apiConstants.HEADERS,
  //           //   url: appConfig.SSP_MID_URL + '/getEmploymentInformation',
  //           //   data: { additionalInformation: { buid: buidtemp } },
  //           // });
  //           let tempresponse = { employeeInformation: resp?.data };

  //           let apiResponse = await dispatch(onLoadAPI({ ...tempresponse }));
  //           if (apiResponse) {
  //             setLayoutData({
  //               ...data,
  //               employeeInformation: apiResponse?.payload?.employeeInformation,
  //             });
  //           }
  //         }
  //       }
  //     }
  //   };
  //   try {
  //     callAPI();
  //     setLayoutData({ dataFromRedux, dataFromReduxEmploymentInformation });
  //   } catch (error) {
  //     
  //   }

  //  
  //   // additionalInformation = useSelector(
  //   //   (storeState) => storeState?.entities?.response?.onLoadResponseList?.employments
  //   // );
  //   // setShowLoader(false);
  // }, [content, loggedUserEntity]);

  // setLayoutData({ ...dataFromRedux, dataFromReduxEmploymentInformation });

  if (showLoader) {
    /* loader to show untill API call gets completed */
    return <Loader loading={showLoader} />;
  }

  const hasErrors = getErrorMessages('Configurator');

  const saveDependentConfig = async () => {
    let data = {
      PersonalInformation: {
        dependentMiddleNameToggle: formik?.values?.dependentMiddleNameToggle,
        dependentMiddleNameRequired: formik?.values?.dependentMiddleNameRequired,
        dependentAddressSameAsEmployeeToggle: formik?.values?.dependentAddressSameAsEmployeeToggle,
        dependentAddressSameAsEmployeeRequired: formik?.values?.dependentAddressSameAsEmployeeRequired,
        dependentStreetAddress2Toggle: formik?.values?.dependentStreetAddress2Toggle,
        dependentStreetAddress2Required: formik?.values?.dependentStreetAddress2Required,
        dependentCountryToggle: formik?.values?.dependentCountryToggle,
        dependentCountryRequired: formik?.values?.dependentCountryRequired,
      }
    };
    setShowSuccess(null);
    let saveResponse = await saveProfile(data, clientId, formFields.apiCall.submit, setShowLoader);
    if (saveResponse?.status == 200) {
      setShowSuccess(true);
    } else {
      dispatch(SET_ALERT_MESSAGE({
        type: 'error',
        errorCode: '520',
        continue: true,
        id: 'createClientIntoDbDependent',
        show: true,
        page: 'Configurator',
      }));
      setShowSuccess(false);
    }
  }

  const breadcrumbs = [
    { to: '', text: 'Employee' },
    { to: '/searchForAnEmployee', text: 'Search for an Employee' },
  ];
  /*removed breadcrumbs and current props */

  return (
    <div>
      <MessageComponent currentPage={'Configurator'} setShowLoader={setShowLoader}/>
      {/* <SectionHeader title="Employee Profile" style={content.style} /> */}
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper">
          {/* <FormGenerator content={content} {...props} layoutData={layoutData} /> */}
          {!showSuccess && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="config">
                <div className="col-12 px-5">
                  <div class="row py-3 px-3">
                    <h4>Personal Information</h4>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dataFieldPersonal.label}
                        inputId={formFields.dataFieldPersonal.id}
                      />                        
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxTogglePersonal.label}
                        inputId={formFields.checkboxTogglePersonal.id}
                      />                      
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.requiredFieldPersonal.label}
                        inputId={formFields.requiredFieldPersonal.id}
                      />                      
                    </div>
                  </div>
                  <div class="row">
                        <div className="col-3 py-2 no-padding-right">
                          <Label
                            text={formFields.middleName.label}
                            inputId={formFields.middleName.id}
                          />
                        </div>
                        <div className="col-3 py-2 no-padding-right">
                          <Checkbox 
                            id={formFields.dependentMiddleNameToggle.id}
                            name={formFields.dependentMiddleNameToggle.name}
                            checked={formik.values.dependentMiddleNameToggle}
                            className="regularCheckbox"
                            {...formik.getFieldProps(formFields.dependentMiddleNameToggle.name)}
                          />                          
                        </div>
                        <div className="col-3 py-2 no-padding-right">
                          <Checkbox 
                            id={formFields.dependentMiddleNameRequired.id}
                            name={formFields.dependentMiddleNameRequired.name}
                            checked={formik.values.dependentMiddleNameRequired}
                            className="regularCheckbox"
                            {...formik.getFieldProps(formFields.dependentMiddleNameRequired.name)}
                          />                          
                        </div>                      
                      </div>
                </div>
                <div className="col-12 px-5">
                  <div class="row py-3 px-3">
                    <h4>Dependent's Address</h4>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.dataFieldDependent.label}
                        inputId={formFields.dataFieldDependent.id}
                      />                     
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.checkboxToggleDependent.label}
                        inputId={formFields.checkboxToggleDependent.id}
                      />             
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.requiredFieldDependent.label}
                        inputId={formFields.requiredFieldDependent.id}
                      />           
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.liveSameAddress.label}
                        inputId={formFields.liveSameAddress.id}
                      />           
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox 
                        id={formFields.dependentAddressSameAsEmployeeToggle.id}
                        name={formFields.dependentAddressSameAsEmployeeToggle.name}
                        checked={formik.values.dependentAddressSameAsEmployeeToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.dependentAddressSameAsEmployeeToggle.name)}
                      />                      
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox 
                        id={formFields.dependentAddressSameAsEmployeeRequired.id}
                        name={formFields.dependentAddressSameAsEmployeeRequired.name}
                        checked={formik.values.dependentAddressSameAsEmployeeRequired}
                        className="regularCheckbox"
                        disabled={true}
                        {...formik.getFieldProps(formFields.dependentAddressSameAsEmployeeRequired.name)}
                      />                      
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.addressLine2.label}
                        inputId={formFields.addressLine2.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox 
                        id={formFields.dependentStreetAddress2Toggle.id}
                        name={formFields.dependentStreetAddress2Toggle.name}
                        checked={formik.values.dependentStreetAddress2Toggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.dependentStreetAddress2Toggle.name)}
                      />                      
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox 
                        id={formFields.dependentStreetAddress2Required.id}
                        name={formFields.dependentStreetAddress2Required.name}
                        checked={formik.values.dependentStreetAddress2Required}
                        className="regularCheckbox"
                        disabled={true}
                        {...formik.getFieldProps(formFields.dependentStreetAddress2Required.name)}
                      />                      
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-3 py-2 no-padding-right">
                      <Label
                        text={formFields.countryTxt.label}
                        inputId={formFields.countryTxt.id}
                      />
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox 
                        id={formFields.dependentCountryToggle.id}
                        name={formFields.dependentCountryToggle.name}
                        checked={formik.values.dependentCountryToggle}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.dependentCountryToggle.name)}
                      />                         
                    </div>
                    <div className="col-3 py-2 no-padding-right">
                      <Checkbox 
                        id={formFields.dependentCountryRequired.id}
                        name={formFields.dependentCountryRequired.name}
                        checked={formik.values.dependentCountryRequired}
                        className="regularCheckbox"
                        {...formik.getFieldProps(formFields.dependentCountryRequired.name)}
                      />                       
                    </div>
                  </div>
                </div>
                <div className="row w-100 form-btnGroup py-5">
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-2 py-2 w-100">
                  {/* <button
                    className="btn btn-primary moreRounded primaryBackground"
                    type="button"                  
                    onClick={saveDependentConfig}
                    disabled={hasErrors}
                  >
                    Submit
                  </button> */}
                  <Button className="btn btn-primary  primaryBackground mr-4" onClick={saveDependentConfig}   disabled={hasErrors}>Submit</Button>

                  </div>
                </div>
              </div>
            </form>
          )}
          {showSuccess && <SuccessPage {...formFields.confirmation}></SuccessPage>}
        </div>
      </Suspense>
    </div>
  );
};

export default DependentConfig;
