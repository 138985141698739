import { useEffect, useState } from "react";
import * as Yup from 'yup';

import ReadOnly from "../../../Atoms/ReadOnly";
import Checkbox from "../../../Atoms/Checkbox";
import RadioButton from "../../../Atoms/RadioButton";
import './index.css'
import { useDispatch, useSelector } from "react-redux";
import { DISABLE_CROSS_SELL } from '../../../../Store/Slices/DisableButton';

const Product = (props) => {
    const { product, selected, onSelect } = props;

    return (
        <div className="product">
            <Checkbox label={product.title} checked={selected} onChange={(e) => onSelect(product, e)} />
            <ReadOnly element={{}} hideLabel value={product.description} />
        </div>
    )

}

const CrossSellProducts = (props) => {
    const {
        eee,
        formName,
        element = {},
        formik,
        formik: { setFieldValue, values, errors },
    } = props;

    const dispatch = useDispatch();
    const formValues = values && values[formName] || {};
    const crossSellObject = useSelector((state) => state.enrollment.crossSellObject);
    const products = [
        { id: 1, title: crossSellObject.nameUseForPrinting, description: 'Get instant rate and compare rates and discounts from top rated carriers in the nation for policies on auto, home, renters and more. Find out how much you can save' }
        // { id: 2, title: 'Product 2', description: 'The cost of LTC services are expected to increase over the next 10-20 years. Find out how LTC insurance can help you pay the cost of care. These Benefits can help lessen the financial impact on your loved ones and provide peace of mind' },
        // { id: 3, title: 'Product 3', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.' },
        // { id: 4, title: 'Product 4', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.' },
    ]

    const { selectedProducts = [], skipSelection = false } = formValues;
    const onSelectProduct = (product, e) => {
        dispatch(DISABLE_CROSS_SELL({ disabled: true }))
        if (product) {
            setFieldValue(`${formName}.skipSelection`, false)
            if (e.target.checked) {
                setFieldValue(`${formName}.selectedProducts`, [...selectedProducts, product.id])
            } else {
                const updatedProducts = selectedProducts.filter((item) => item !== product.id)
                setFieldValue(`${formName}.selectedProducts`, updatedProducts)
                if (updatedProducts.length === 0) setFieldValue(`${formName}.contactPreferences`, null)
                else if (updatedProducts.length !== 0 && !formValues.contactPreferences) setFieldValue(`${formName}.contactPreferences`, { acknowledgement: null })
            }
        } else {
            setFieldValue(`${formName}.selectedProducts`, [])
            setFieldValue(`${formName}.contactPreferences`, null)
            setFieldValue(`${formName}.skipSelection`, true)
        }
    }

    useEffect(() => {
        if (selectedProducts?.length === 0) {
            dispatch(DISABLE_CROSS_SELL({ disabled: !skipSelection }))
        }
    }, [selectedProducts?.length, skipSelection]);

    return (
        <div className="cross-sell-products">
            <ReadOnly
                element={element}
                name="info-text"
                hideLabel
                value={element.infoText}
            />
            <div className="row products-wrapper">
                {products.map(item => (
                    <Product product={item} onSelect={onSelectProduct} selected={selectedProducts.includes(item.id)} key={item.id} />
                ))}

                <div className="product not-interested">
                    <RadioButton
                        required
                        onChange={() => onSelectProduct()}
                        value={formValues.skipSelection || false}
                        buttons={[{
                            label: 'Not Interested',
                            value: true
                        }]}
                        id='Not Interested'
                    />
                </div>
            </div>
        </div>
    )
}

export default CrossSellProducts;
