import React, { useEffect, useMemo, useState } from "react";
import CustomTable from '../Common/Table';
import PolicyReplacementQuestions from '../../Container/PolicyQuestionsWrapper.js';
import Beneficiary from "../Beneficiary/Beneficiary";
import logoAflac from '../../../../../Assets/Images/aflac-logo.png'
import imgMetlife from '../../../../../Assets/Images/met-life.png'
import { useDispatch, useSelector } from 'react-redux';
import { onLoadAPI, onNextAPI, onLoadProductAPI, UPDATE_COVERAGE_INFO } from '../../../../../Store/Slices/Entities/response';
import ServiceHelper from '../../../../Utils/Helper/MappingHelper';
import { Button } from '../../../../Atoms';
import { SUBSCRIBE_COVERAGE_BUIDS, UPDATE_LOADING, UPDATE_WAIVE_PRODUCT } from '../../../../../Store/Slices/InsuredInfo';
import { SET_COVERAGE_BUIDS, CHECK_ELIGIBILITY, SET_COVERAGE_BENEFICIARY_ELIGIBILITY, UPDATE_PRODUCT_LEVEL_ELIGIBILITY } from '../../../../../Store/Slices/InsuredInfo/index.js';
import { getCoverageBeneficiaryEligibility, getErrorMessages } from '../../Helper/MappingHelper';
import { getSubscribedCoverageBeneficiaryConfigurationNew } from '../../Actions/getSubscribedCoverageBeneficiaryConfigurationNew.js';
import { getListOfCoveragePlanDesignConfigurationNew } from '../../Actions/getListOfCoveragePlanDesignConfigurationNew.js';
import { getSubscribedCoverageConfigurationNew } from '../../Actions/getSubscribedCoverageConfigurationNew.js';
import { prepareSubscriptionForChoice } from '../../Actions/prepareSubscriptionForChoice.js'
import { determineButtonLabel } from "../../Helper/MappingHelper";

import {
  UPDATE_ENROLLMENT_DATA
} from '../../../../../Store/Slices/Enrollment/index';
import { unsubscribeCoverage } from "../../Actions/unsubscribeCoverage";
import { updateSubscribedCoverageInformationCI } from "../../Actions/updateSubscribedCoverageInformationCI";
import { validateSubscribedProductData } from "../../Actions/validateSubscribedProductData";
import { continueBtnLabelHelper, screenNames } from "../../Helper/continueBtnLabelHelper";
import { coverageProductDetails, unsubscribeCoverageDetails, updateSubscribedCoverageInformation } from '../../Actions/productDetails';

import { useNavigationHook } from '../../Hooks/NavigationHook';

import { getBeneficiariesData } from "../../Utils/BeneficiariesUtils";

const EmployeeCoverage = (props) => {
  const serviceHelper = new ServiceHelper();
  const dispatch = useDispatch();
  const { currentStep, formsConfig } = useNavigationHook();
  let { personBuid = "", coverageDetails = [], currentBuid, displayMode, coverageBuid, spouse, children, showDisclosureStatement } = props || {};
  const [callApiOneTime, setCallApiOneTime] = useState(false);
  const [prepareApiOneTime, setPrepareApiOneTime] = useState(false);
  const [callApiZero, setCallApiZero] = useState(false);
  const [callApiOne, setCallApiOne] = useState(false);
  const [callApiTwo, setCallApiTwo] = useState(false);
  const [callApiThree, setCallApiThree] = useState(false);
  const [isBeneficiarySectionValid, setBeneficiarySectionValidBoolean] = useState(false)
  const [showLoader, setShowLoader] = useState(false);
  currentBuid && currentBuid(personBuid)
  const { step, prevStep, nextStep, onBack, onNext } = props;
  const [showPolicyReplacementQuestions, setShowPolicyReplacementQuestions] = useState(false);
  const [continueBtnText, setContinueBtnText] = useState("Continue");
  const [disableContinue, setDisableContinue] = useState(true);
  const [canContinueBeneficiaries, setCanContinueBeneficiaries] = useState(false);
  const [totalPercent, setTotalPersent] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentBeneficieries, setUpdatedBeneficiaryList] = useState([])
  const [showPolicyReplacementQuestionSection, setShowPolicyReplacementQuestionSection] = useState(false)
  
  const [policyReplacementQuestionCount , getTotalQuestionCount] = useState(0)
  const enrollmentData = useSelector((state) => state.enrollment?.enrollmentData);
  const showOrHidePolicyReplacementQues = (personBuid, coverageIndex, isWaiveSelected) => {
    setShowPolicyReplacementQuestions(!isWaiveSelected);
  };
  const { insuredInfo, process, entities } = useSelector((storeState) => storeState);
  const callCenterEmployeeData = useSelector(
    (storeState) => storeState?.callCenter?.callCenterEnrollAsEmployeeLoginData
  );
  let currentReduxState = useSelector((storeState) => storeState);
 
  let productFamily = formsConfig.forms?.[currentStep]?.productFamily;
  const productIdentifierBuid = currentReduxState.process?.productFamilyIdentifier[productFamily];
  let beneficiaryList = []
  if (displayMode === 'Employee') {
    if ( insuredInfo?.[currentStep]?.updatedBeneficiary?.[coverageBuid]?.length > 0 ) {
      const beneficiariesData = insuredInfo?.[currentStep]?.updatedBeneficiary?.[coverageBuid];
      beneficiaryList = beneficiariesData.filter(data => data?.beneficiarySelected === true);
    }
    // if (insuredInfo?.[currentStep]?.updatedBeneficiaries?.length > 0) {
    //   let employeeBeneficiaries = insuredInfo?.[currentStep]?.updatedBeneficiaries?.filter((o) => {
    //     return o.hasOwnProperty(personBuid) ? o : "";
    //   });
    //   if (employeeBeneficiaries.length > 0) {
    //     beneficiaryList = employeeBeneficiaries[0]?.[personBuid]
    //   }
    // }
  }
  const insuredInfoCurrentStep = useSelector((storeState) => storeState.entities?.response?.onLoadResponseList?.[currentStep]) || {};
  let loggedUserEntity = Object.keys(callCenterEmployeeData).length === 0 ? currentReduxState.auth.loggedUserEntity : callCenterEmployeeData;
  let enrollmentProcessBuid = currentReduxState.process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
  const subscribeCoverageBuids = entities?.response?.onLoadResponseList?.[currentStep]?.subscribeCoverageBuids || [];
  let getDetails = subscribeCoverageBuids.filter((e) => e?.personBuid === personBuid)
  let prepareSubscriptionForChoiseResp = insuredInfoCurrentStep?.prepareSubscriptionForChoice?._Result.filter((item) => item.bUID === coverageBuid)
  let subscribedOption = prepareSubscriptionForChoiseResp?.[0]?.subscribedOption
  const getAllPossibleChoicesConfiguration = currentReduxState?.entities?.response?.onLoadResponseList?.[`${currentStep}`]?.getAllPossibleChoicesConfigurationForProduct?._Result?.[0]
  //let subscribedElements = currentReduxState.entities?.response?.onLoadResponseList?.[currentStep]?.ValidateSubscribedProductData?._Result?.contract?.subscribedElements;
  let subscribedElements = getDetails?.[0]?.perpareSubscriptionForChoices?._Result || [];
  
  let updatedSubscribedElements = subscribedElements.map((item) => {
    if(subscribedOption) {
      if(item.bUID === coverageBuid) {
          return {
              ...item,
              subscribedOption: subscribedOption
          }
      } else {
          return item
      }
    } else {
        return item
    }
  })
  let allowedBeneficiaryKind = ""

  let possibleValues = [];
  //const coverageConfiguration = currentReduxState.entities?.response?.onLoadResponseList?.[`getSubscribedCoverageConfigurationFor${currentStep}`];
  let coverageConfiguration = getDetails?.[0]?.subscribedCoverageConfiguration || ''
  if (coverageConfiguration) {
    // let addintionData = coverageConfiguration.filter(e => e.personBuid === personBuid);
    //possibleValues = addintionData?.[0]?.result?.additionalDatas
    possibleValues = coverageConfiguration?._Result?.additionalDatas;
  }
  let coverageDetailsData = insuredInfo[currentStep]?.coverageDetails?.filter(e => e.personBuid === personBuid);
  let selectedValue = coverageDetailsData?.[0]?.selectedValue
  let policyReplacementQuestions = insuredInfo[currentStep]?.policyReplacementQuestions
  let policyReplacementAnswer = []
  if (policyReplacementQuestions && Object.keys(policyReplacementQuestions).length > 0) {
    Object.entries(policyReplacementQuestions).map(([key, value]) => policyReplacementAnswer.push({ fieldName: key, valueCode: value }));
  }

  let waivedCoverage = coverageDetailsData?.[0]?.waivedCoverage;

  let getSubscribedCoverageBeneficiary = getDetails?.[0]?.beneficiaryConfiguration || ''
  let beneficiaryConfigurationDetails = getSubscribedCoverageBeneficiary?._Result?.filter((e) => e.forCoveredPerson?.bUID === personBuid)
  let allowedBeneficiaryDetails = beneficiaryConfigurationDetails?.[0]?.allowedBeneficiaryKind || []
  //let allowedBeneficiaryDetails = getSubscribedCoverageBeneficiary?._Result?.allowedBeneficiaryKind || []
  if (allowedBeneficiaryDetails.length > 0) {
    if (beneficiaryList.length > 0 && allowedBeneficiaryDetails.includes("cDefineBeneficiaries")) {
      allowedBeneficiaryKind = 'cDefineBeneficiaries';
    } else {
      allowedBeneficiaryKind = 'cByWillOrEstate';
    }
  }
  const subscribedProductBuid = currentReduxState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements

  const currentSubscribedElements = currentReduxState?.enrollment?.enrollmentData?.contract?.subscribedElements;


  let coverageBenficiaryEligibilty = insuredInfo[currentStep]?.coverageBeneficiaryEligibility || [];
  let checkBenfMandatory = coverageBenficiaryEligibilty[0]?.isBeneficiaryMandatory;
  
  let coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids?.[0];
  if (props.flowType === 2 || props.flowType === 3) {
    if(insuredInfo?.[currentStep]?.offeredCoverageBuids && insuredInfo?.[currentStep]?.offeredCoverageBuids.length) {
      coverageDetailsForPrepare = insuredInfo?.[currentStep]?.offeredCoverageBuids.filter((e) => e?.coveragePersonBuid?.includes(personBuid))[0];
    }
  }

  const continueBtnObj = {
    flowType: props.flowType,
    screenType: screenNames.COVERAGE_SCREEN,
    hasDisclosureStatement: props.showDisclosureStatement,
    spouseCount: props.spouse.length,
    childrenCount: props.children.length,
    isWaivedOffFlag: false,
    productIdentifierBuid,
    memberIdentifier: "employee"
  }

  const waiveOffCoverageSelected = (isChecked) => {
    // if(isChecked) {
    //   setContinueBtnText("Continue to Summary")
    //   //setContinueBtnText(determineButtonLabel(props, isChecked))
    // } else {
    //   setContinueBtnText(determineButtonLabel(props, isChecked))
    // }
    dispatch(UPDATE_WAIVE_PRODUCT({personBuid, "waiveProduct": isChecked, currentStep}))
    //change btn label as per waiveoff flag
    setContinueBtnText(continueBtnLabelHelper({
      ...continueBtnObj,
      isWaivedOffFlag: isChecked
    }));
  }

  useEffect(() => {
    //page load useeffect
    setContinueBtnText(continueBtnLabelHelper({
      ...continueBtnObj,
      //if no value selected sending null to handle properly inside continueBtnLabelHelper
      isWaivedOffFlag: selectedValue !== "" ? false : waivedCoverage ? waivedCoverage : null
    }));
  }, [])

  useEffect(() => {
    dispatch(getBeneficiariesData(currentReduxState));
  }, [currentSubscribedElements && currentSubscribedElements?.length > 0, process && process?.productFamilyIdentifier])

  useEffect(() => {
    if (coverageDetailsForPrepare && subscribedProductBuid && !prepareApiOneTime) {
      setPrepareApiOneTime(true)

      // const fetchData = async () => {
      //   try {
      //     await dispatch(validateSubscribedProductData({
      //       enrollmentProcessBuid: enrollmentProcessBuid,
      //       productIdentifierBuid: productIdentifierBuid,
      //       currentStep: currentStep,
      //       subscribedElements: subscribedProductBuid,
      //       expectReturn: true
      //     }));

      //     const coverageBuid = await dispatch(prepareSubscriptionForChoice(
      //       enrollmentProcessBuid,
      //       coverageDetailsForPrepare?.coverage,
      //       coverageDetailsForPrepare?.coveragePersonBuid,
      //       currentStep,
      //       personBuid,
      //     ));

      //     if (!coverageBuid) {
      //       throw new Error('coverageBuid not received from prepareSubscriptionForChoice');
      //     }

      //     await dispatch(getSubscribedCoverageBeneficiaryConfigurationNew(
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       personBuid,
      //       currentStep,
      //     ));

      //     await dispatch(getSubscribedCoverageConfigurationNew(
      //       currentStep,
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       personBuid
      //     ));

      //     await dispatch(getListOfCoveragePlanDesignConfigurationNew(
      //       currentStep,
      //       enrollmentProcessBuid,
      //       coverageBuid,
      //       personBuid
      //     ));
      //   } catch (error) {
      //     console.error('There was an error!', error);
      //   }
      // }

      const fetchData = async () => {
        dispatch(UPDATE_LOADING(true));
        try {
          let listOfProduct = subscribedProductBuid?.filter(e => e.subscribedProduct.productCode.buid === productIdentifierBuid);
          let subscribedPBuid = listOfProduct?.[0]?.bUID
          await dispatch(coverageProductDetails({
            enrollmentProcessBuid: enrollmentProcessBuid,
            flowType: props.flowType,
            subscribedProductBuid: subscribedPBuid,
            personBuid: personBuid,
            prepareCoverageBuid: coverageDetailsForPrepare?.coverage,
            prepareCoveredPersonBuid: coverageDetailsForPrepare?.coveragePersonBuid,
            currentStep: currentStep
          }));
        } catch (e) {
          console.error(e)
        } finally {
          dispatch(UPDATE_LOADING(false));
        }
      }

      fetchData();
    }
  }, [prepareApiOneTime, currentStep, getAllPossibleChoicesConfiguration, insuredInfo, enrollmentProcessBuid, personBuid, props.flowType, coverageDetailsForPrepare, subscribedProductBuid]);


  //
  const nextStepCall = async () => {

    if (!waivedCoverage) {
      dispatch(UPDATE_LOADING(true));
      let externalStepContext = {
        "portalStep": currentStep,
        "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
        "reachedPreConfirmation": "no"
      };
      try {
        await dispatch(updateSubscribedCoverageInformation({
          enrollmentProcessBuid,
          subscribedElements: updatedSubscribedElements,
          productIdentifierBuid,
          beneficiaryList,
          allowedBeneficiaryKind,
          selectedValue,
          possibleValues,
          policyReplacementAnswer,
          loggedUserEntity,
          currentStep,
          coverageBuid: getDetails?.[0]?.coverageBuid,
          externalStepContext: externalStepContext,
          isMoveNext: false,
          personBuid
        }));
        if((spouse && spouse.length) || (children && children.length) || showDisclosureStatement) {
          nextStep();
          
        } else {
          onNext()
        }
        
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(UPDATE_LOADING(false));
      }
      //setShowLoader(true);
      // let actionDispatched = await dispatch(updateSubscribedCoverageInformationCI({
      //   enrollmentProcessBuid,
      //   subscribedElements,
      //   productIdentifierBuid,
      //   beneficiaryList,
      //   allowedBeneficiaryKind,
      //   selectedValue,
      //   possibleValues,
      //   policyReplacementAnswer,
      //   loggedUserEntity,
      //   currentStep
      //   //subscribedCoverageBuid
      // }))
      // if (actionDispatched) {

      //   // const processInfo = resp?.data?._Result ?? {};
      //   // dispatch(UPDATE_ENROLLMENT_DATA(processInfo));

      //   let externalStepContext = {
      //     "portalStep": currentStep,
      //     "wynsureStep": (enrollmentData?.processEntity?.currentStepNb) ? JSON.stringify(enrollmentData?.processEntity?.currentStepNb) : "",
      //     "reachedPreConfirmation": "no"
      //   };


      //   dispatch(UPDATE_LOADING(true));
      //   try {
      //     let respObj = await serviceHelper.sendApiRequest('/validateSubscribedCoverageData', {
      //       enrollmentProcessBuid: enrollmentProcessBuid,
      //       coverageBuid: getDetails?.[0]?.coverageBuid,
      //       externalStepContext
      //     });
      //     if (respObj.data && respObj.status === 200) {
      //       // Format the new data
      //       dispatch(SUBSCRIBE_COVERAGE_BUIDS({
      //         coverageBuid: getDetails?.[0]?.coverageBuid,
      //         personBuid: personBuid,
      //         product: currentStep,
      //       }));
      //       dispatch(
      //         UPDATE_COVERAGE_INFO({
      //           coverageBuid: getDetails?.[0]?.coverageBuid,
      //           personBuid: personBuid,
      //           product: currentStep,
      //           validateSubscribedCoverageData: respObj.data
      //         })
      //       );
      //     }
      //     setShowLoader(false);
      //     nextStep();
      //   }
      //   catch (e) {
      //     console.error(e)
      //   } finally {
      //     dispatch(UPDATE_LOADING(false));
      //   }
      //   //return true;
      // }
    } else if (waivedCoverage === true) {
      setShowLoader(true);
      dispatch(UPDATE_LOADING(true));
      try {
        await dispatch(unsubscribeCoverageDetails({
          enrollmentProcessBuid: enrollmentProcessBuid,
            coverageBuid: coverageDetailsForPrepare?.coverage,
            coveredPerson: coverageDetailsForPrepare?.coveragePersonBuid,
            subscribedCoverageBuid: getDetails?.[0]?.coverageBuid,
            isPrepare: false,
            isMoveNext: false,
            currentStep: currentStep
        }, "CreateOrUpdateInsuredCIGeneral", true));
        // let getDetails = subscribeCoverageBuids.filter((e) => e?.personBuid === personBuid)
        // let subscribedCoverageBuid = getDetails?.[0]?.coverageBuid;
        // let enrollmentProcessBuid = process?.enrollmentProcess?.[0]?.[0]?._Result?.bUID;
        // let actionDispatched = await dispatch(unsubscribeCoverage({
        //   enrollmentProcessBuid, subscribedCoverageBuid
        // }, "CreateOrUpdateInsuredCIGeneral"));

        // if (actionDispatched) {
        //   setShowLoader(false);
        //   dispatch(onLoadAPI({
        //     CreateOrUpdateInsuredCIGeneral: actionDispatched.data,
        //     isAPICallInProgress: false,
        //   }))
        //   //return true;
        // }
        setShowLoader(false);
        props.onNext();
      }
      catch (e) {
        console.error(e)
      } finally {
        dispatch(UPDATE_LOADING(false));
        return true;
      }
    }



  }

  const checkBenificiaryFn = (pstatus, pselectedRows) => {
    let primaryTotal = 0;
    let contingentTotal = 0;
    let hasPrimary = false;
    let hasContingent = false;
    insuredInfo[currentStep]?.updatedBeneficiaries?.forEach(pobj => {
      Object.keys(pobj).forEach((pbeneficiary, pind) => {
        const beneficiary = pobj[pbeneficiary][pind];
        if (beneficiary.beneficiaryType === 'Primary') {
          primaryTotal += beneficiary.beneficiaryPercentage;
          hasPrimary = true;
        } else {
          contingentTotal += beneficiary.beneficiaryPercentage;
          hasContingent = true;
        }
      })
    });
    let lflag = true;
    if (hasPrimary && primaryTotal !== 100) {
      lflag = false;
    }

    if (hasContingent && contingentTotal !== 100) {
      lflag = false;
    }
    if (pstatus == "success") {
      setTotalPersent(100);
    } else {
      const currentPercent = ((primaryTotal > 0 ? primaryTotal : 0) + (contingentTotal > 0 ? contingentTotal : 0)) * 100 / 200;
      setTotalPersent(0);
    }
    setSelectedRows([...pselectedRows]);
  };
  const handleDisableFn = () => {
    const policyReplacementQuestionsSectionCheck = () => {
      if(policyReplacementQuestionCount) {
          setShowPolicyReplacementQuestionSection(true)
          if(totalPolicyQuestionDiff === 0) {
              return false
          } else {
              return true
          }
      } else {
          setShowPolicyReplacementQuestionSection(false)
          return false
      }
    }

    const beneficiarySectionValidation = () => {
      if(props.isBeneficiaryMandatory) {
        if(isBeneficiarySectionValid) {
          return policyReplacementQuestionsSectionCheck()
        } else {
          setShowPolicyReplacementQuestionSection(false)
          return true
        }
      } else {
        if(props.isBeneficiaryOptional) {
          if(selectedRows && selectedRows.length) {
            if(isBeneficiarySectionValid) {
              return policyReplacementQuestionsSectionCheck()
            } else {
              setShowPolicyReplacementQuestionSection(false)
              return true
            }
          } else {
            if(policyReplacementQuestionCount) {
              setShowPolicyReplacementQuestionSection(true)
              return policyReplacementQuestionsSectionCheck();
            } else {
              setShowPolicyReplacementQuestionSection(false)
            }
            return false
          }
        } else {
          return policyReplacementQuestionsSectionCheck()
        }
      }
    }
    
    if(selectedValue) {
      return beneficiarySectionValidation();
    } else {
      if(waivedCoverage) {
        setShowPolicyReplacementQuestionSection(false)
        return false
      } else {
        return true
      }
    }

  };

  const currentStepProperty = `getSubscribedCoverageConfigurationFor${currentStep}`;
  const subscribedCoverageConfiguration = useSelector((storeState) =>

    storeState?.entities?.response?.onLoadResponseList?.[currentStepProperty]

  );
  let filterByBuid = []
  if (props.flowType == 1) {
    filterByBuid = subscribedCoverageConfiguration;
  } else {
    filterByBuid = subscribedCoverageConfiguration?.filter(e => e.personBuid === personBuid);
  }

  //let policyReplacementData = filterByBuid?.[0]?.result;
  let policyReplacementData = props?.subscribedCoverageConfigurationResponse || {};

  const totalRadioQuestions = useMemo(() => {
    let policyReplacementTotalQuestions = policyReplacementData?.additionalDatas?.filter(eachObj => eachObj.fieldCode !== "PlanDesign");
    const totalQuestions = policyReplacementTotalQuestions?.filter(
      // (question) => question?.fieldCode !== 'PlanDesign' && question?.fieldCode !== 'DD_00177'
      // Need to check with Deepak - sk
      (question) => question?.fieldCode !== 'PlanDesign' && question.possibleValues
    );
    getTotalQuestionCount(totalQuestions?.length)
    return totalQuestions;
  }, [policyReplacementData])

  const totalPolicyQuestionDiff = useMemo(() => {
    const totalQuestions = totalRadioQuestions?.length || 0;
    // const totalAnswers = policyReplacementAnswer?.length || 0;
    let totalAnswers = [];
    totalRadioQuestions?.forEach(pquestion => {
      const lanswer = policyReplacementAnswer?.find(panswer => {
        return pquestion.fieldCode === panswer.fieldName
      });
      if (lanswer) {
        totalAnswers.push(lanswer);
      }
    })
    // return totalQuestions - totalAnswers;
    return totalQuestions - totalAnswers?.length;
  }, [policyReplacementAnswer])
  let showPolicyReplacementQuestionFlag = totalRadioQuestions?.length > 0 ? true : false

  const hasErrors = getErrorMessages(currentStep);

  useEffect(() => {
    setDisableContinue(handleDisableFn());
  }, [[],selectedValue, totalPercent, selectedRows, policyReplacementQuestionCount, totalPolicyQuestionDiff, canContinueBeneficiaries, isBeneficiarySectionValid]);

  useEffect(() => {
    if(props.isBeneficiaryMandatory) {
      if(currentBeneficieries && currentBeneficieries.length) {
        const selectedBeneficiaries = currentBeneficieries.filter((item) => item.beneficiarySelected === true) || []
        if(selectedBeneficiaries?.length) {
          let primaryTotal = 0;
          let contingentTotal = 0;
          let hasPrimary = false;
          let hasContingent = false;
          
          selectedBeneficiaries.map((beneficiary) =>{
            if (beneficiary.beneficiaryType === 'cPrimary' || beneficiary.beneficiaryType === 'Primary') {
              primaryTotal += beneficiary.beneficiaryPercentage;
              hasPrimary = true;
            } else {
              contingentTotal += beneficiary.beneficiaryPercentage;
              hasContingent = true;
            }
            let lflag = true;
            if (hasPrimary && primaryTotal !== 100) {
              lflag = false;
              setTotalPersent(0)
            } else {
              setTotalPersent(100)
            }

            if (hasContingent && contingentTotal !== 100) {
              lflag = false;
              setTotalPersent(0)
            } else {
              setTotalPersent(100)
            }
          })
        }
      }
    }
  },[currentBeneficieries])
  
  return (
    <>

      <div className="row" style={{ fontSize: '1.5rem', color: 'rgb(32, 32, 32)', fontFamily: 'NotoSansRegular' }}>
        <div className="">
          <div className="label col-12 px-3 undefined" style={{ display: 'inline-block' }}>
            <div className="row">
              <div className="px-3 py-3">
                Select Coverage Option for Employee
              </div>
            </div>
          </div>
        </div>
      </div>

      {coverageDetails && (coverageDetails?.length > 0) &&
        <CustomTable {...props} tableData={coverageDetails} waivedCoverage={waivedCoverage} selectedValue={selectedValue} showOrHidePolicyReplacementQues={showOrHidePolicyReplacementQues} waiveOffCoverageSelected={waiveOffCoverageSelected}> </CustomTable>}

      {(showPolicyReplacementQuestions || selectedValue) && (props.beneficiaryEligibility) &&
        <Beneficiary
          currentStep={props.currentStep}
          personBuid={props.personBuid}
          coverageBuid={coverageBuid}
          dependentsList={props.dependentsList}
          relation={'employee'}
          updateBeneficiary={checkBenificiaryFn}
          allowedBeneficiaryKind={props.allowedBeneficiaryKind}
          setCanContinueBeneficiaries={setCanContinueBeneficiaries}
          isBeneficiaryMandatory= {props.isBeneficiaryMandatory}
          setUpdatedBeneficiaryList={setUpdatedBeneficiaryList}
          isBeneficiarySectionValidCallback={setBeneficiarySectionValidBoolean}
        />
      }
      {
        showPolicyReplacementQuestionSection ? 
        <PolicyReplacementQuestions
          {...props}
        />: null
      }

      <div className='button-group'>
        <>
          <Button type="button" onClick={prevStep} className="mr-3">
            Back
          </Button>
        </>


        < Button type="button" onClick={nextStepCall}
          className=""
          disabled={disableContinue || hasErrors}
        >
          {continueBtnText}
        </Button>
      </div>

    </>
  );
};

export default EmployeeCoverage;
