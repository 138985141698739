import CrossSellProducts from "./Products";
import ContactPreference from "./ContactPreference";

const CrossSell = (props) => {
    return props.elements.map(element => {
        switch (element.type) {
            case 'CrossSellProducts':
                return <CrossSellProducts {...props} element={element} />
            case 'CrossSellContactPreference':
                return <ContactPreference {...props} element={element} />
            default:
                return null;
        }
    })
}

export default CrossSell;
