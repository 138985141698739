import React, { memo } from 'react';
import ProductTable from '../../../Molecules/ProductTable/index'
import { useNavigationHook } from '../../../Molecules/CustomSection/Hooks/NavigationHook.js'; // Replace with your actual file path

const EnrollmentSummary = memo((props) => {
  const { layoutData = {} } = props;
  const { currentStep, formData  } = useNavigationHook();
  let elements = formData?.[currentStep]?.elements|| [];



  return (
    <div>
       <ProductTable elements = {elements} layoutData={layoutData} hideEditButton={false} isSummaryPage={true}></ProductTable>
    </div>
  );
});
export default EnrollmentSummary;
