import React from 'react';
import { Route } from 'react-router-dom';

// import HomePage from '../../../Layout/Configurator/HomePage';

import EmployeeProfileConfig from '../../../Layout/Configurator/EmployeeProfileConfig';
import DependentConfig from '../../../Layout/Configurator/DependentConfig';
import LoginConfiguration from '../../../Layout/Configurator/LoginConfiguration';

import RegisterConfig from '../../../Layout/Configurator/RegisterConfig';
import WelcomeConfig from '../../../Layout/Configurator/WelcomeConfig';
import BeneficiaryConfig from '../../../Layout/Configurator/BeneficiaryConfig';
import HearderConfig from '../../../Layout/Configurator/HeaderConfig';

import ConfigClient from '../../../Layout/Configurator/ConfigClient';

import HomePage from '../../../Layout/Configurator/HomePage';
import ConfigLinks from '../../../Layout/Configurator/ConfigLinks';
import ProductConfig from '../../../Layout/Configurator/ProductConfig';
import SummaryScreenConfig from '../../../Layout/Configurator/SummaryScreenConfig';

export default [
  { path: '/configurator/home', component: HomePage },
  { path: '/configurator/employeeProfileConfig', component: EmployeeProfileConfig },
  { path: '/configurator/dependentConfig', component: DependentConfig },
  { path: '/configurator/registerConfig', component: RegisterConfig },
  { path: '/configurator/loginConfig', component: LoginConfiguration },
  { path: '/configurator/welcomeConfig', component: WelcomeConfig },
  { path: '/configurator/beneficiaryConfig', component: BeneficiaryConfig },
  { path: '/configurator/headerConfig', component: HearderConfig },
  { path: '/configurator/configClient', component: ConfigClient },
  { path: '/configurator/configClient', component: ConfigClient },
  { path: '/configurator/configLinks', component: ConfigLinks },
  { path: '/configurator/productConfig', component: ProductConfig },
  { path: '/configurator/summaryScreenConfig', component: SummaryScreenConfig }
];
