import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Styles } from './styles';
import { useSelector } from 'react-redux';
import { reduxConstants } from '../../../Common/Constants/index';
import Elementor from '../../Cells/Form/Elementor';
import toast, { Toaster } from 'react-hot-toast';
import { forEach, get, lte, truncate } from 'lodash';
import FormatHelper from '../../Utils/Helper/FormatHelper';
import { Button, Linker } from '../../Atoms';
import ServiceHelper from '../../Utils/Helper/MappingHelper';

const formatHelper = new FormatHelper();
const SectionItem = (props) => {
  const {
    contentData,
    constantsList,
    content,
    elements = [],
    formName,
    formik = {},
    headerClass = '',
    index = 0,
    panelArray,
    panelClass = '',
  } = props;

  return (
    <div className={`row   ${panelArray.includes(index) ? headerClass : panelClass}`}>
      {elements &&
        elements[index]?.map((element, elementIndex) => {
          return (
            <div
              className={
                elements[index][elementIndex].hideElement
                  ? 'hideElement'
                  : elements[index][elementIndex].placement
                  ? ` ${elements[index][elementIndex].placement}  `
                  : 'col-12'
              }
              key={elementIndex}
            >
              <Elementor
                key={elements[index][elementIndex]?.name}
                element={elements[index][elementIndex]}
                constantsList={constantsList}
                {...props}
              />
              {formik.errors[formName] &&
              formik.errors[formName][elements[index][elementIndex]?.name] &&
              formik.touched[formName] &&
              formik.touched[formName][elements[index][elementIndex]?.name] ? (
                <div
                  className="error-message"
                  style={{ color: content.layout.errorColor, fontSize: '0.75rem' }}
                >
                  {formik.errors[formName][elements[index][elementIndex]?.name]}
                </div>
              ) : null}
              {elements[index][elementIndex]?.horizontalBar && <hr></hr>}
            </div>
          );
        })}
    </div>
  );
};

const SectionMain = (props) => {
  const { sections, content, panels } = props;
  return sections.map((section) => {
    const groupName = get(section, 'groupName', '');

    const panelClass = groupName ? 'panelStyle mx-1 panelStyleItem' : '';
    return (
      <div className={`row py-2 ${panelClass}`}>
        <div className="col-12">
          <SectionItems
            section={section}
            content={content}
            groupStartKey={groupName ? section['groupStart'] : section['noGroupStart']}
            groupEndKey={groupName ? section['groupEnd'] : section['noGroupEnd']}
            groupName={!!groupName}
            panels={panels}
            {...props}
          />
        </div>
      </div>
    );
  });
};

const SectionItems = (props) => {
  const {
    constantsList,
    elements,
    formName,
    groupEndKey,
    groupName,
    groupStartKey,
    content,
    section,
    contentData,
    panels,
  } = props;
  const { changeButtonWidth = false } = useSelector((storeState) => storeState.disableButton);
  const {stepId, currentStep, stepKeys} = useSelector((storeState) => storeState.stepForm);
  const { insuredInfo } = useSelector((storeState) => storeState);

  const {disableButton} = useSelector((storeState) => storeState); // handle btn width/form
  const radioSelectedValue = useSelector(
    (storeState) => storeState?.employeeProfile?.radioSelectedValue
  );
  const radioSelectedValueExcessLiability = useSelector((storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiability)
  const radioSelectedValueExcessLiabilityUmUim = useSelector((storeState) => storeState?.employeeProfile?.radioSelectedValueExcessLiabilityUmUim)
  const preferredContact = useSelector((storeState) => storeState?.employeeProfile?.preferredContactDetails?.OfferPreferenceUpdate);
  const serviceHelper = new ServiceHelper();
  const hidePreferredContact = useSelector(
    (storeState) => storeState?.entities?.response?.onLoadResponseList?.empInfoByClientId?._Result?.data?.[0]?.communicationInfo?.[0]?.preferredContact?.optionsField?.isEnabledPC?.hideOption
  );

  let noOfProducts = stepKeys?.length;
  let isLastProduct = stepId === noOfProducts - 2;
  const dualColumn = section?.dualColumn || {};
  const dualColumnGroup = section?.dualColumnGroup || [];
  const noRenderGroup = section?.noRender || [];
  const itemsToRender = [];
  const panelClass = groupName ? 'con' : 'panelStyle mx-1';
  let headerClass = (((currentStep === 'ManageProfile' || currentStep === 'EmployeeProfile') && preferredContact==false) ? 'panelStyleNoBorder px-1 hide-element' : 'panelStyleNoBorder px-1');
  if(currentStep === 'EmployeeProfile' && hidePreferredContact){
    headerClass='panelStyleNoBorder px-1 hide-element'
  }

  const getPathValues = (element, formName, props) => {
    const pascalCaseSkipArray = element?.update?.pascalCaseSkip;
    let deleteValue = props.formik.values.delete;
    const { formikPath = [], name, expression = '' } = element;

    const { formik } = props;
    let value = '';
    if (deleteValue && expression) {
      // value = ' ';
    } else if (expression && !deleteValue) {
      value = eval(expression);
      if (!pascalCaseSkipArray) {
        value = formatHelper.toPascalCase(value);
      }
    } else {
      value = formik.values[formName][name];
    }
    return value;
  };
  for (let index = groupStartKey; index <= groupEndKey; index++) {
    // Validation to skip the render part for specific group
    if (noRenderGroup.includes(index)) continue;
    // Validation to see if the section contains dualGroup
    if (Object.keys(dualColumn).length > 0) {
      if (dualColumnGroup.includes(index)) {
        itemsToRender.push(
          <div className={`row `}>
            {Object.keys(dualColumn).length > 0 &&
              Object.keys(dualColumn).map((dualGroup, indexDualGroup) => {
                const groupName = dualColumn[dualGroup];
                return (
                  <>
                    {groupName.map((group, indexGroup) => {
                      return (
                        <div
                          className="col-12 col-md-6"
                          key={`row-group-${indexDualGroup}-${indexGroup}`}
                        >
                          {group.map((groupInfo, indexGroupInfo) => {
                            const groupElement = elements[groupInfo.groupInd][groupInfo.elementInd];
                            return (
                              <div className="row">
                                <div
                                  className={
                                    groupElement?.placement
                                      ? ` ${groupElement.placement}  `
                                      : 'col-12'
                                  }
                                  key={`column-group-info-${indexGroupInfo}`}
                                >
                                  {groupElement.type && (
                                    <Elementor
                                      key={groupElement?.name}
                                      element={groupElement}
                                      constantsList={constantsList}
                                      {...props}
                                      value={getPathValues(groupElement, formName, props)}
                                    />
                                  )}
                                  {props.formik.errors[formName] &&
                                  props.formik.errors[formName][
                                    elements[groupInfo.groupInd][groupInfo.elementInd]?.name
                                  ] &&
                                  props.formik.touched[formName] &&
                                  props.formik.touched[formName][
                                    elements[groupInfo.groupInd][groupInfo.elementInd]?.name
                                  ] ? (
                                    <div
                                      className="error-message"
                                      style={{
                                        color: content.layout.errorColor,
                                        fontSize: '0.75rem',
                                      }}
                                    >
                                      {
                                        props.formik.errors[formName][
                                          elements[groupInfo.groupInd][groupInfo.elementInd]?.name
                                        ]
                                      }
                                    </div>
                                  ) : null}
                                  {elements[groupInfo.groupInd][groupInfo.elementInd]
                                    ?.horizontalBar && <hr></hr>}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </>
                );
              })}
          </div>
        );
      } else {
        itemsToRender.push(
          <SectionItem
            constantsList={constantsList}
            content={content}
            elements={elements}
            formName={formName}
            formik={props.formik}
            headerClass={headerClass}
            index={index}
            panelArray={panels}
            panelClass={panelClass}
          />
        );
      }
    } else {
      itemsToRender.push(
        <div className={`row  ${panels.includes(index) ? headerClass : panelClass} `}>
          {Object.keys(dualColumn).length === 0 &&
            elements &&
            elements[index]?.map((element, elementIndex) => {
              // const extraClasses = elements[index][elementIndex].placement ? elements[index][elementIndex].placement : elements[index][elementIndex].placementMobile ? elements[index][elementIndex].placementMobile : 'col-12';
              return (
                <div
                  className={
                    (stepId > 2 &&  !elements[index][elementIndex].flexPlacement) ?
                    elements[index][elementIndex].hideElement? 'hideElement':
                    !disableButton[currentStep] ?
                      elements[index][elementIndex].placement ? 
                       ` hideWhenNoChild ${elements[index][elementIndex].placement}  `  : 'col-12'
                      :(
                        (((disableButton[currentStep].waiveType === 'checkBox' || disableButton[currentStep].isCheckbox) && 
                        (disableButton[currentStep].status || disableButton[currentStep].isCheckbox ) && 
                        
                        // elements[index][elementIndex].name === 'continueProductButton') || 
                        // ((disableButton[currentStep].waiveType === 'radio' || disableButton[currentStep].isRadio)  && 
                        // (disableButton[currentStep].status || disableButton[currentStep].isRadio ) && 
                        // ((elements[index][elementIndex].name === 'continueTableCoverageButton' && elements[index][elementIndex].specialForExcessLiability &&(radioSelectedValueExcessLiability==='Waive' ))|| 
                        // (elements[index][elementIndex].name === 'continueFormButton' && elements[index][elementIndex].specialForExcessLiability && (radioSelectedValueExcessLiability ==="Waive"|| radioSelectedValueExcessLiabilityUmUim==="Waive"))
                        // ))) 
                        elements[index][elementIndex].name === 'continueProductButton') || 
                        ((disableButton[currentStep].waiveType === 'radio' || disableButton[currentStep].isRadio)  && 
                        (disableButton[currentStep].status || disableButton[currentStep].isRadio ) && 
                        ((elements[index][elementIndex].name === 'continueTableCoverageButton' && !elements[index][elementIndex].forExcess ) 
                        // need to refactor currentStep="ExcessLiability" with last step name
                          ) )) ||  (elements[index][elementIndex].name === 'continueFormButton' && elements[index][elementIndex].forExcess && isLastProduct)  //&& (radioSelectedValueExcessLiabilityUmUim==='Waive' || radioSelectedValueExcessLiabilityUmUim!==-1)) 
                               ||  (elements[index][elementIndex].name === 'continueTableCoverageButton' && elements[index][elementIndex].forExcess && isLastProduct  && radioSelectedValueExcessLiability==='Waive') 
                               &&
                      elements[index][elementIndex].widePlacement)?
                       `hideWhenNoChild ${elements[index][elementIndex].widePlacement}  ` 
                      : ` hideWhenNoChild ${elements[index][elementIndex].placement}  `
                     
                  :
                  stepId > 2  && elements[index][elementIndex].flexPlacement ?
                  ` hideWhenNoChild ${elements[index][elementIndex].editPlacement} col-12 col-md-2 col-lg-auto px-xl-2  ` : 
                  elements[index][elementIndex].placement
                  ? ` hideWhenNoChild ${elements[index][elementIndex].placement}  `
                  : 
                   'col-12'
                }
                  key={elementIndex}
                >
                   {!serviceHelper.hideElement(elements[index][elementIndex], props.formik, formName) &&
                  <Elementor
                    key={elements[index][elementIndex]?.name}
                    element={elements[index][elementIndex]}
                    constantsList={constantsList}
                    {...props}
                  />}
                  {props.formik.errors[formName] &&
                  props.formik.errors[formName][elements[index][elementIndex]?.name] &&
                  props.formik.touched[formName] &&
                  props.formik.touched[formName][elements[index][elementIndex]?.name] ? (
                    <div
                      className="error-message"
                      style={{ color: content.layout.errorColor, fontSize: '0.75rem' }}
                    >
                      {props.formik.errors[formName][elements[index][elementIndex]?.name]}
                    </div>
                  ) : null}
                  {elements[index][elementIndex]?.horizontalBar && <hr></hr>}
                </div>
              );
            })}
        </div>
      );
    }
  }
  return itemsToRender;
};

const InnerComponents = (props) => {
  const { innerColumn, element } = props;

  const rows = [];
  for (let i = 0; i < 5; i++) {
    rows.push(<div>{i}</div>);
  }
  return (
    <>
      <Styles />
      <div className="col-12">{rows}</div>
    </>
  );
};

const SectionPanels = (props) => {
  const {
    SetContentData,
    constantsList,
    content,
    contentData,
    elements,
    formName,
    formik,
    innerFormName,
    sectionPanels,
    triConfig,
    updateFormData,
  } = props;

  const sections = get(sectionPanels, 'sections', []);
  const panels = get(sectionPanels, 'panel', []);
  const { stepKeys } = contentData;
  const [displayType, setDisplayMode] = useState('');

  const loggedUserEntity =
    useSelector((store) => eval(reduxConstants.GetLoggedUserLegalEntity.uiPath)) || {};

  /* function to handle change event of elements */
  const handleChange = (event) => {
    if (event.currentTarget) {
      updateFormData(formName, event.currentTarget.name, event.currentTarget.value);
    }
  };
  return (
    <>
      <Styles />
      <div className=" col-12">
        <SectionMain content={content} panels={panels} sections={sections} {...props} />
        {/* <Button
                isRegisteration={false}
               
                className="w-100"
               
                outline
     
                onClick={() => {}}>Back</Button> */}
      </div>
    </>
  );
};

SectionPanels.propTypes = {
  elements: PropTypes.array,
  allFormData: PropTypes.object,
  updateFormData: PropTypes.func,
  tableRowMapper: PropTypes.func,
  tableHeaders: PropTypes.arrayOf(PropTypes.string),
};
SectionPanels.defaultProps = {
  elements: {},
  allFormData: {},
  tableHeaders: [],
  setAllFormData: () => {},
  tableRowMapper: () => {},
};

export default SectionPanels;
