import { createSlice } from '@reduxjs/toolkit';
import { apiCallInitiation } from '../../../Middleware/Api/Actions';
import { apiConstants } from '../../../../Common/Constants';

const slice = createSlice({
  name: 'constants',
  initialState: {
    data: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    apiRequested: (constants, action) => {
      constants.loading = true;
    },

    apiReceived: (constants, action) => {
      let payload = action.payload;
      constants.data = {...constants.data, ...payload}
      constants.loading = false;
      constants.lastFetch = Date.now();
    },

    apiRequestFailed: (constants, action) => {
      constants.loading = false;
    },
  },
});
export const { apiReceived, apiRequested, apiRequestFailed } = slice.actions;
export const callConstants = (element) => (dispatch, getState) => {
  return dispatch(
    apiCallInitiation({
      url: apiConstants.CONSTANTS_URL.getConstants,
      method: 'POST',
      data: {constantName : element},
      onStart: apiRequested.type,
      onSuccess: apiReceived.type,
      onError: apiRequestFailed.type,
    })
  );
};

export const callConstantsOneTime = (element) => (dispatch, getState) => {
  return dispatch(
    apiCallInitiation({
      url: apiConstants.CONSTANTS_SINGLE_URL.getConstants,
      method: 'GET',
      onStart: apiRequested.type,
      onSuccess: apiReceived.type,
      onError: apiRequestFailed.type,
    })
  );
};
export default slice.reducer;
