import React from 'react';
import { Route } from 'react-router-dom';

import HomePage from '../../../Layout/CallCenter/HomePage';
import EmployeeSearch from '../../../Layout/CallCenter/EmployeeSearch';
import EmployeeData from '../../../Layout/CallCenter/EmployeeData';


import WelcomeConfig from '../../../Layout/Configurator/WelcomeConfig';

export default [
  { path: '/callcenter/home', component: HomePage },
  { path: '/callcenter/employeeSearch', component: EmployeeSearch },
  { path: '/callcenter/employeeData', component: EmployeeData },

  // { path: '/configurator/theming', component: Theming },
  // { path: '/configurator/updateTheming', component: UpdateTheming },
  // { path: '/configurator/portalConfiguration', component: PortalConfiguration },
  // { path: '/configurator/employeeProfileConfig', component: EmployeeProfileConfig },
 
  { path: '/callcenter/welcomeConfig', component: WelcomeConfig },


 
];
