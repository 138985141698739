import React, { Suspense, useEffect, useState } from 'react';
import FormGenerator from '../../../Cells/Form/FormGenerator';
//import { useSelector } from 'react-redux';
import SectionHeader from '../../../Molecules/SectionHeader';
import { useDispatch, useSelector } from 'react-redux';
import { callConstants } from '../../../../Store/Slices/Entities/constants/index';
import { constantsList } from '../../../../Common/Constants/index';
import getDynamicForm from '../../../Utils/Helper/GetDynamicForm'

const EnrollEmployee = (props) => {
  const { currentLanguage } = useSelector((storeState) => storeState.language);
  const [content, SetContent] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    import(`./Content/${currentLanguage}.json`)
      .then(module => SetContent(module.default))
      .catch(error => console.error("Failed to load content:", error));
  }, [currentLanguage]);

  useEffect(() => {
    /* useEffect to get all constants through API call */
    constantsList.forEach(element => {
      dispatch(callConstants(element));
    });
  }, []);
  const breadcrumbs = [
    { to: '', text: 'Employee' },
    { to: '/searchForAnEmployee', text: 'Search for an Employee' },
  ];

  const setContractInformation = async (buid) => {
    return null
  };

  let skipAction = (formName, response) => {
    if (response?.result?.type === "products") {
      (async () => {
        let tempContent = { ...content }
        for (let i = response.result.products.length - 1; i >= 0; i--) {
          let form = getDynamicForm(response.result.products[i])
          tempContent = addForm(formName, form, tempContent)
        }
        SetContent(tempContent)
      })();
    }
  };

  let addForm = (formName, response, content) => {
    let temp = { ...content }
    let tempForms = {}
    if (temp && temp.forms) {
      for (const [key, value] of Object.entries(temp.forms)) {
        tempForms[key] = value
        if (key === formName) {
          tempForms[Object.keys(response)[0]] = response[Object.keys(response)[0]]
        }
      }
    }
    temp.forms = tempForms
    return temp
  }

  return (
    <div>
      <SectionHeader title="Enroll an Employee" breadcrumbs={breadcrumbs} current="Enroll an Employee" />
      <Suspense fallback={<div>Loading...</div>}>
        <div className="formWrapper">
          <FormGenerator
            content={content}
            skipAction={skipAction}
            {...props}
            selectOptions={[
              {
                "value": "318843713_101",
                "label": "Contract Number  2021/4800000509",
                "status": "cProject"
              },
              {
                "value": "318843713_102",
                "label": "Contract Number  2021/4800000513",
                "status": "cActive"
              },
              {
                "value": "318843713_104",
                "label": "Contract Number  2021/4800000520",
                "status": "cProject"
              },
              {
                "value": "318843713_202",
                "label": "Contract Number  2021/4800000524",
                "status": "cProject"
              },
              {
                "value": "318843713_204",
                "label": "Contract Number  2021/4800000528",
                "status": "cProject"
              },
              {
                "value": "318843713_301",
                "label": "Contract Number  2021/4800000535",
                "status": "cActive"
              },
              {
                "value": "318843713_401",
                "label": "Contract Number  2021/4800000539",
                "status": "cActive"
              }
            ]}
            setContractInformation={setContractInformation} />
        </div>
      </Suspense>
    </div>
  );
};

export default EnrollEmployee;
