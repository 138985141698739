import React from 'react';
import icon_error from '../../../../Assets/Images/erroricon.png';
import './styles.css';
const SessionLayout = (props) => {
  
return (
    <div className='session_container'>
        <div className='middle_section'>
            <p className='pageText'>Oops - the page has timed out</p>
            <p className='pageText'>Looks like this session has expired</p>
            <img
                src={icon_error}
                alt="session expired"
                className="img-responsive"
                id="sessionExpiredIcon"
            />
            <p className='pageText contMsg'>To continue, close this page and start again</p>
        </div>
    </div>
)
};

export default SessionLayout;
